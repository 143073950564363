import React, { useEffect, useState } from "react";
import { TenderUserStatus } from "../../__generated__/globalTypes";
import { GetTenderStateForUser } from "../../__generated__/GetTenderStateForUser";
import { useQuery } from "@apollo/client";
import ArchiveTender from "../ArchiveTender";
import LikeTenderButton from "../LikeTenderButton";
import { Disable } from "react-disable";
import { Tooltip } from "@mui/material";
import DislikeTenderButton from "../DislikeTenderButton";
import UnArchiveTenderButton from "../UnArchiveTenderButton";
import ChatButtonTopBar from "../ChatButtonTopBar";
import { QUERY_FULL_WORKLIST_MINIMUM, QUERY_TENDER_STATE_FOR_USER, QUERY_WORKLIST_SPLIT } from "../../graphql/queryDefinitions";
import AvatarReviewed from "../AvatarReviewed";
import ShareTenderButton from "../sharetendercomponent/ShareTenderButton";
import OpenOrCreateProject from "./OpenOrCreateProject";
// import ShareTenderButton from "../ShareTenderButton";

interface Props {
    /**
     * Id of clicked tender
     */
    tender_id: string;
    tender_title: string;
}

const TenderButtonsBasedOnUserState: React.FC<Props> = ({ tender_id, tender_title }) => {
    const [state, setState] = useState<TenderUserStatus | undefined>();
    const [conversations, setConversations] = useState<boolean>(false);

    const { data } = useQuery<GetTenderStateForUser>(QUERY_TENDER_STATE_FOR_USER, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (data && data.tender) {
            setState(data.tender?.userStatus);
            setConversations(data.tender?.hasConversations ? true : false);
        }
    }, [data]);

    const renderSwitch = (tenderState: TenderUserStatus | undefined) => {
        switch (tenderState) {
            case TenderUserStatus.LIKED:
                return (
                    <>
                        <ArchiveTender tender_id={tender_id} buttonSize="medium" title={tender_title} />
                        <Tooltip enterNextDelay={100} placement="top" title={"Reeds als interessant beoordeeld"}>
                            <div>
                                <Disable disabled={true}>
                                    <LikeTenderButton
                                        tender_id={""}
                                        buttonSize="medium"
                                        refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
                                    />
                                </Disable>
                            </div>
                        </Tooltip>
                        <DislikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.DISLIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
                        />{" "}
                    </>
                );

            case TenderUserStatus.DISLIKED:
                return (
                    <>
                        <LikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.LIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
                        />

                        <Tooltip enterNextDelay={100} placement="top" title={"Reeds als niet interessant beoordeeld"}>
                            <div>
                                <Disable disabled={true}>
                                    <DislikeTenderButton
                                        tender_id={""}
                                        buttonSize="medium"
                                        refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
                                    />
                                </Disable>
                            </div>
                        </Tooltip>
                    </>
                );

            case TenderUserStatus.UNREVIEWED:
                return (
                    <>
                        <LikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.LIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
                        />
                        <DislikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.DISLIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
                        />{" "}
                    </>
                );

            case TenderUserStatus.DELETED:
                return (
                    <>
                        <LikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.LIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
                        />
                        <DislikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.DISLIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
                        />{" "}
                    </>
                );

            case TenderUserStatus.ARCHIVED:
                return (
                    <>
                        <UnArchiveTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.LIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
                        />
                    </>
                );

            // Fallback
            default:
                return <></>;
        }
    };

    return (
        <React.Fragment>
            {/* Review component */}
            <div style={{ marginRight: "4px" }}>
                <AvatarReviewed tender_id={tender_id} justifyContent={"flex-end"} />
            </div>

            <OpenOrCreateProject
                tender_id={tender_id}
                tender_title={tender_title}
                buttonSize="small"
                openFromTable
                refetchQueries={[QUERY_TENDER_STATE_FOR_USER, QUERY_FULL_WORKLIST_MINIMUM, QUERY_WORKLIST_SPLIT]}
            />
            {/* like/dislike buttons based on state */}
            {renderSwitch(state)}

            {/* Share tender */}
            <ShareTenderButton tender_id={tender_id} buttonSize="medium" modelType={"App\\Models\\Tender"} title={tender_title} />
            {/* Add/edit note of current tender */}
            <ChatButtonTopBar
                id={tender_id}
                name={tender_title || ""}
                module={"App\\Models\\Tender"}
                notesAvailable={conversations}
                location="Tender"
            />
        </React.Fragment>
    );
};

export default TenderButtonsBasedOnUserState;
