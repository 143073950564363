import React, { useState } from "react";
import { Avatar, AvatarGroup, InputBase, Tooltip } from "@mui/material";
import { Box, CircularProgress, CircularProgressProps, ListItem, ListItemText, ListItemIcon, Typography, IconButton, Button } from "@mui/material";

// Icons used on this page
import { MoreVert, Lock, Edit, Check, Close } from "@mui/icons-material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { GetUserSearchRules, GetUserSearchRules_currentUser_searches } from "../../../__generated__/GetUserSearchRules";
import { useTranslation } from "react-i18next";
import SearchLineSummarySentence from "./SearchLineSummarySentence";
import { ApolloQueryResult, OperationVariables, useMutation } from "@apollo/client";

import { toast } from "react-toastify";
import { DeleteSearch, DeleteSearchVariables } from "../../../__generated__/DeleteSearch";
import { useNavigate } from "react-router-dom";
import { DELETESEARCHRULE, UPDATE_SEARCH_PROPS } from "../../../graphql/mutationDefinitions";
import { QUERY_SEARCHRULES } from "../../../graphql/queryDefCurrentUser";
import { updateTenderSearchProperties, updateTenderSearchPropertiesVariables } from "../../../__generated__/updateTenderSearchProperties";
import Colorpicker from "../../../components/Colorpicker";
import { presetColors } from "./presetColors";
import DistSaver from "./DistSaver";

interface Props {
    color: string;
    searchline: GetUserSearchRules_currentUser_searches;
    open: boolean;
    searchterm: string[];
    handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    progress: number;
    handleOpenStats: () => void;
    handleClickSub: (event: React.MouseEvent<HTMLButtonElement>) => void;
    type: string;
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetUserSearchRules>>;
    handleOpenPicker?: () => void;
    setColor?: (color: string) => void;
    handleClosePicker?: () => void;
    openPicker?: boolean;
    chosenColors: string[];
    admin: boolean;
    showLock: boolean;
}

/**
 * Loader with percentage in middle of circle
 */
const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const SearchLineSettings: React.FC<Props> = ({
    color,
    searchline,
    open,
    searchterm,
    handleChange,
    progress,
    handleOpenStats,
    handleClickSub,
    type,
    refetch,
    handleOpenPicker,
    setColor,
    handleClosePicker,
    openPicker,
    chosenColors,
    admin,
    showLock,
}) => {
    const { t } = useTranslation();
    const bgColor = "#ffffff";
    const green = "#225E4D";
    const blue = "#173357";
    const navigate = useNavigate();
    const [hover, setHovered] = useState<boolean>(false);
    const [editmode, setEditMode] = useState<boolean>(false);
    const [nameOfRule, setNameOfRule] = useState<string>("");

    const [openDistModal, setOpenDistModal] = useState(false);

    const handleClickOpen = () => {
        setOpenDistModal(true);
    };

    const handleClose = () => {
        setOpenDistModal(false);
    };

    /**
     * Mutation to remove tendersearchrule
     */
    const [remove] = useMutation<DeleteSearch, DeleteSearchVariables>(DELETESEARCHRULE);

    /**
     * Mutation to edit name tendersearchrule
     */
    const [update_props] = useMutation<updateTenderSearchProperties, updateTenderSearchPropertiesVariables>(UPDATE_SEARCH_PROPS);

    /**
     * Navigate to reviewpage with searchrule filter active
     * @param id id of clicked searchrule
     */
    const setRtf = (id: number) => {
        sessionStorage.removeItem("rtf");
        sessionStorage.setItem(
            "rtf",
            JSON.stringify({
                searches: [id],
            })
        );
        navigate(`/tenders/reviews`);
    };

    /**
     * Handler to remove a tender searchrule
     */
    const cancel_tndr_search = async (id: string) => {
        try {
            await remove({
                variables: {
                    id: id,
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    /**
     * Handler to update color
     */
    const onUpdateName = async () => {
        try {
            await update_props({
                variables: {
                    id: searchline.id,
                    name: nameOfRule,
                },
                onCompleted: () => {
                    setEditMode(false);
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    /**
     * Handler to update color
     */
    const onUpdateColor = async () => {
        try {
            await update_props({
                variables: {
                    id: searchline.id,
                    color: color,
                },
                onCompleted: () => {
                    setEditMode(false);
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        // if type = tender => blue color, if type = opportunity => green color
        <ListItem
            style={{
                marginBottom: open ? "0px" : "5px",
                borderLeft: type === "tender" ? `5px solid ${blue}` : type === "opportunity" ? `5px solid ${green}` : undefined,
                backgroundColor: bgColor,
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <ListItemIcon
                    sx={{
                        minWidth: "40px",
                        opacity: searchline.state === "PENDING_ACCEPTANCE" ? 0.5 : 1,
                        display: "flex",
                        alignItems: "center",
                    }}
                    style={{ color: color }}
                >
                    {type === "tender" && searchline.owner?.id === localStorage.getItem("user_id") ? (
                        <IconButton onClick={handleOpenPicker} style={{ marginRight: "8px", marginLeft: "-8px" }}>
                            <LocalOfferIcon sx={{ color: color }} />
                        </IconButton>
                    ) : (
                        <LocalOfferIcon sx={{ color: color }} />
                    )}
                </ListItemIcon>
                <ListItemText
                    sx={{
                        color: "#173357",
                        opacity: searchline.state === "PENDING_ACCEPTANCE" ? 0.5 : 1,
                    }}
                    /**
                     * primary = title of searchline
                     */
                    primary={
                        <div>
                            {editmode ? (
                                <>
                                    <InputBase
                                        sx={{ fontSize: "0.875rem", borderBottom: "1px solid #000", height: "21px", maxWidth: "50%" }}
                                        inputProps={{
                                            maxLength: 50,
                                            minLength: 3,
                                        }}
                                        value={nameOfRule}
                                        onChange={(event) => setNameOfRule(event.target.value)}
                                        // onKeyPress={(e) => {
                                        //     if (e.key === "Enter") {
                                        //         handleSubmit();
                                        //     }
                                        // }}
                                        autoFocus
                                    />
                                    {/* Save new name */}
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            onUpdateName();
                                        }}
                                    >
                                        <Check sx={{ width: "12px", height: "12px" }} />
                                    </IconButton>
                                    {/* cancel edit name */}
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setEditMode(false);
                                        }}
                                    >
                                        <Close sx={{ width: "12px", height: "12px" }} />
                                    </IconButton>
                                </>
                            ) : (
                                <Typography
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                    style={{ display: "flex", alignItems: "center", height: "21px" }}
                                >
                                    {searchline.name}
                                    {hover && searchline.owner?.id === localStorage.getItem("user_id") && (
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setNameOfRule(searchline.name);
                                                setEditMode(!editmode);
                                            }}
                                        >
                                            <Edit sx={{ width: "12px", height: "12px" }} />
                                        </IconButton>
                                    )}
                                </Typography>
                            )}
                        </div>
                    }
                    /**
                     * secondary = summary of searchline
                     * TODO, user can edit inline. add regions, add or remove searchterms.
                     * create react fragment to add more features
                     */
                    secondary={
                        <React.Fragment>
                            {type === "tender" && (
                                <SearchLineSummarySentence searchline={searchline} filterValues={searchline.filters_with_labels || null} />
                            )}
                        </React.Fragment>
                    }
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {searchline.state === "PENDING_ACCEPTANCE" && (
                    <Typography variant="button" sx={{ marginRight: "16px", fontSize: "12px", color: "#00000042" }}>
                        Nog niet geaccepteerd
                    </Typography>
                )}
                {/* Avatars => sharing results with those users */}
                {searchline.participants && (
                    <>
                        {searchline.participants.length === 1 && searchline.owner?.id === localStorage.getItem("user_id") ? (
                            // USER IS OWNER ADN THE ONLY PARTICIPANT
                            <Tooltip disableInteractive placement={"right-start"} title={<div>{searchline.owner?.employee.name}</div>}>
                                <AvatarGroup
                                    max={4}
                                    sx={{
                                        cursor: "pointer",
                                        marginRight: "8px",
                                        "& .MuiAvatar-root": {
                                            border: "1px solid #ffffff",
                                            width: (theme) => theme.spacing(3.5),
                                            height: (theme) => theme.spacing(3.5),
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    <Avatar sx={{ backgroundColor: "#173357" }}>
                                        {searchline.owner.employee.name?.slice(0, 1)}
                                        {searchline.owner.employee.familyname?.slice(0, 1)}
                                    </Avatar>
                                </AvatarGroup>
                            </Tooltip>
                        ) : (
                            <>
                                {searchline.participants.length > 0 ? (
                                    // MAP OVER PARTICIPANTS
                                    // BLUE AVATAR => OWNER
                                    // GREY => PARTICIPANTS
                                    <Tooltip
                                        disableInteractive
                                        placement={"right-start"}
                                        title={
                                            <>
                                                <span>Gemaakt door:</span>
                                                <br />
                                                <span>{searchline.owner?.employee.name}</span>
                                                <br />
                                                {searchline.participants.filter((p) => p?.employee.id !== searchline.owner?.employee.id).length >
                                                    0 && (
                                                    <div style={{ marginTop: "4px" }}>
                                                        <span>Gedeeld met:</span>
                                                    </div>
                                                )}
                                                {searchline.participants
                                                    .filter((p) => p?.employee.id !== searchline.owner?.employee.id)
                                                    .map((l, index) => {
                                                        return <div key={l?.id}>{l?.employee.name}</div>;
                                                    })}
                                            </>
                                        }
                                    >
                                        <AvatarGroup
                                            max={4}
                                            sx={{
                                                cursor: "pointer",
                                                marginRight: "8px",
                                                "& .MuiAvatar-root": {
                                                    border: "1px solid #ffffff",
                                                    width: (theme) => theme.spacing(3.5),
                                                    height: (theme) => theme.spacing(3.5),
                                                    fontSize: "0.8rem",
                                                },
                                            }}
                                        >
                                            <Avatar sx={{ backgroundColor: "#173357" }}>
                                                {searchline.owner?.employee?.name?.slice(0, 1)}
                                                {searchline.owner?.employee?.familyname?.slice(0, 1)}
                                            </Avatar>
                                            {/* Map avatar of users that liked tender */}
                                            {searchline.participants
                                                .filter((p) => p?.employee.id !== searchline.owner?.employee.id)
                                                .map((l, index) => {
                                                    return (
                                                        <Avatar key={l?.id}>
                                                            {l?.employee?.name?.slice(0, 1)}
                                                            {l?.employee?.familyname?.slice(0, 1)}
                                                        </Avatar>
                                                    );
                                                })}
                                        </AvatarGroup>
                                    </Tooltip>
                                ) : (
                                    <Tooltip disableInteractive placement={"right-start"} title={<div>{searchline.owner?.employee.name}</div>}>
                                        <AvatarGroup
                                            max={4}
                                            sx={{
                                                cursor: "pointer",
                                                marginRight: "8px",
                                                "& .MuiAvatar-root": {
                                                    border: "1px solid #ffffff",
                                                    width: (theme) => theme.spacing(3.5),
                                                    height: (theme) => theme.spacing(3.5),
                                                    fontSize: "0.8rem",
                                                },
                                            }}
                                        >
                                            <Avatar sx={{ backgroundColor: "#173357" }}>
                                                {searchline.owner?.employee?.name?.slice(0, 1)}
                                                {searchline.owner?.employee?.familyname?.slice(0, 1)}
                                            </Avatar>
                                        </AvatarGroup>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </>
                )}

                {/* On smaller devices -> all actions in submenu. */}
                {/* TODO: change searchline.id to state or percentage*/}
                {searchline.state === "PENDING_ACCEPTANCE" ? (
                    <div style={{ width: "306px" }}>
                        {/* Tender buttonset */}
                        {type === "tender" && (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "16px" }}>
                                <div>
                                    <Button
                                        size="small"
                                        variant="text"
                                        onClick={() => {
                                            cancel_tndr_search(searchline.id);
                                        }}
                                    >
                                        weigeren
                                    </Button>
                                </div>
                                <Button size="small" variant="contained" style={{ marginLeft: "8px" }} onClick={handleClickOpen}>
                                    accepteren
                                </Button>
                            </div>
                        )}
                    </div>
                ) : searchline.id === "" ? (
                    <React.Fragment>
                        <Typography variant="button" style={{ marginRight: 12 }}>
                            {t("fetchSearchrule")}
                        </Typography>
                        <CircularProgressWithLabel value={progress} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div style={{ width: "140px", display: "flex", justifyContent: "flex-end" }}>
                            {searchline.state === "FINISHED" ? (
                                <Button
                                    onClick={() => setRtf(parseInt(searchline.id))}
                                    size="small"
                                    disabled={!Boolean(searchline.participants?.find((u) => u?.id === localStorage.getItem("user_id")))}
                                >
                                    Toon resultaten
                                </Button>
                            ) : searchline.state === "QUEUED" || searchline.state === "RUNNING" ? (
                                <Button disabled={true} size="small">
                                    In uitvoering
                                </Button>
                            ) : (
                                <></>
                            )}
                        </div>
                        <Button
                            disabled={!Boolean(searchline.participants?.find((u) => u?.id === localStorage.getItem("user_id")))}
                            onClick={handleOpenStats}
                            size="small"
                        >
                            {searchline.id === "" ? t("fetchSearchrule") : open ? t("Hide_delivery") : t("Show_delivery")}
                        </Button>

                        {Boolean(searchline.owner?.id !== localStorage.getItem("user_id")) === true && showLock ? (
                            <Tooltip
                                disableInteractive
                                title={`Deze zoekregel is gemaakt door ${searchline.owner?.employee.name}. \n Vraag ${searchline.owner?.employee.name} voor wijzigingen`}
                                placement="left"
                            >
                                <IconButton>
                                    <Lock fontSize="small" sx={{ color: "#b3b3b3" }} />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <IconButton onClick={handleClickSub}>
                                <MoreVert fontSize="small" />
                            </IconButton>
                        )}
                    </React.Fragment>
                )}
            </div>
            {setColor && openPicker && handleClosePicker && (
                <Colorpicker
                    chosenColorArray={chosenColors}
                    color={color}
                    onChange={setColor}
                    presetColors={presetColors}
                    onClosePicker={onUpdateColor}
                    open={openPicker}
                    cancelAndClose={handleClosePicker}
                />
            )}
            {openDistModal && (
                <DistSaver
                    handleClose={handleClose}
                    open={openDistModal}
                    owner={searchline.owner?.id as string}
                    searchID={searchline.id}
                    refetch={refetch}
                />
            )}
        </ListItem>
    );
};

export default SearchLineSettings;
