import React from "react";
import { Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Disable } from "react-disable";
// import { useTranslation } from "react-i18next";
import DescriptionIcon from "@mui/icons-material/Description";
import TenderViewpreferences from "./TenderViewpreferences";
import TenderNotifications from "./tenderSettings/TenderNotifications";
import TenderCalendar from "./TenderCalendar";
// import BackupSearchRuleSettings from "./BackupSearchRulesettings";
import { useTranslation } from "react-i18next";
import { DefaultViewPreferenceInput, TenderModulePreferences } from "../../../__generated__/globalTypes";
import TenderNotificationsMobileView from "./tenderSettings/TenderNotificationsMobileView";
import TenderCalendarMobile from "./tenderCalendarSettings/TenderCalendarMobile";
import TenderViewPreferencesMobile from "./tenderViewSettings/TenderViewPreferencesMobile";

// Temporary import -> change to globaltypes when interface is available

interface Props {
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
    settings: TenderModulePreferences;
    saveSettings: (settings: TenderModulePreferences) => void;
    defaultView: DefaultViewPreferenceInput[];
    setDefaultView: React.Dispatch<React.SetStateAction<DefaultViewPreferenceInput[]>>;
}

const TenderSettings: React.FC<Props> = ({ settings, saveSettings, defaultView, setDefaultView, setChanged }) => {
    // const { t } = useTranslation();
    const { t } = useTranslation();
    const blue = "#173357";
    const theme = useTheme();
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Disable disabled={false}>
            <AccordionDetails sx={{ overflowX: "auto" }}>
                <Grid container>
                    {/* HEAD: general update preferences */}
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "left", margin: mobileOnly ? "8px 0px" : 16 }}>
                        {mobileOnly ? (
                            <TenderNotificationsMobileView settings={settings} saveSettings={saveSettings} setChanged={setChanged} />
                        ) : (
                            <TenderNotifications settings={settings} saveSettings={saveSettings} setChanged={setChanged} />
                        )}
                    </Grid>
                    {/* HEAD: general update preferences */}
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "left", margin: mobileOnly ? "8px 0px" : 16 }}>
                        {mobileOnly ? (
                            <TenderCalendarMobile settings={settings} saveSettings={saveSettings} setChanged={setChanged} />
                        ) : (
                            <TenderCalendar settings={settings} saveSettings={saveSettings} setChanged={setChanged} />
                        )}
                    </Grid>
                    {/*
                     * GRID WITH CARDS FOR DEFAULT VIEW SETTINGS
                     */}
                    <Grid item xs={12} style={{ margin: mobileOnly ? "16px 0px 0px" : "16px 16px 0px " }}>
                        {mobileOnly ? (
                            <TenderViewPreferencesMobile defaultView={defaultView} setDefaultView={setDefaultView} setChanged={setChanged} />
                        ) : (
                            <>
                                <Typography variant="h5" style={{ display: "flex", alignItems: "center" }}>
                                    <DescriptionIcon style={{ color: blue, marginRight: 8, height: 20, width: 20 }} />
                                    {t("DefaultView")}
                                </Typography>
                                <Typography style={{ marginBottom: 24 }}>{t("DefaultViewDescription")}</Typography>
                                <TenderViewpreferences defaultView={defaultView} setDefaultView={setDefaultView} setChanged={setChanged} />
                            </>
                        )}
                    </Grid>
                    {/* <Grid item xs={12} style={{ margin: "32px 16px 0px " }}>
                            <BackupSearchRuleSettings />
                        </Grid> */}
                </Grid>
            </AccordionDetails>
        </Disable>
    );
};

export default TenderSettings;
