import React, { useState } from "react";
import { Button, Popover } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

interface Props {
    children: React.ReactNode;
    label: React.ReactNode;

    /**
     * Called when the popover gets opened
     */
    onOpen?(): void;
    /**
     * Called when the popover gets closed
     */
    onClose?(): void;
    /**
     * disable when length = 0
     */
    disabled: boolean;
}

const OppDetailFilter: React.FC<Props> = ({ children, label, onOpen, onClose, disabled }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (onOpen) onOpen();
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (onClose) onClose();
    };

    // Open state of the popover
    const open = Boolean(anchorEl);
    return (
        <React.Fragment>
            <>
                <Button disabled={disabled} size="small" variant="text" sx={{ display: "inline-flex", alignItems: "center" }} onClick={handleOpen}>
                    <div style={{ fontWeight: "normal", textTransform: "initial" }}>{label}</div>

                    <ArrowDropDown />
                </Button>

                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {children}
                </Popover>
            </>
        </React.Fragment>
    );
};

export default OppDetailFilter;
