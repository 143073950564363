import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { withTranslation, WithTranslation } from "react-i18next";
import "./InViewPagination.scss";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import { useLazyQuery } from "@apollo/client";
import { SortOrder, TenderFilterInput, TenderFilterSource, TenderFilterTarget, TenderSearchInput } from "../__generated__/globalTypes";
import {
    FilterWorklistPagination,
    FilterWorklistPaginationVariables,
    FilterWorklistPagination_filterTenders_results,
} from "../__generated__/FilterWorklistPagination";
import { useNavigate } from "react-router-dom";
import { useWorklistPage } from "./contextProviders/TenderWorklistContext";
import { QUERY_FULL_WORKLIST_PAGINATION } from "../graphql/queryDefinitions";

interface Props extends WithTranslation {
    listName: string;
    bgColor: string;
    /**
     * Id of clicked tender
     */
    tender_id: string;
}

// TODO: if tender_id is not in worklist dont show component
const filters: TenderFilterInput = {};
const defaultSearch: TenderSearchInput = {
    source: TenderFilterSource.WORKLIST,
    target: TenderFilterTarget.SUMMARY,
    filters,
};

const defaultVariables: FilterWorklistPaginationVariables = {
    page: 1,
    count: 1,
    orderField: "updated_at",
    order: SortOrder.DESC,
    search: defaultSearch,
};

const InViewPaginationTenderWorklist: React.FunctionComponent<Props> = ({ t, listName, bgColor, tender_id }) => {
    const { page, setPage } = useWorklistPage();
    // const [page, setPage] = useState(defaultVariables.page || 1);
    const [tendersData, setTendersData] = useState<FilterWorklistPagination_filterTenders_results | null>();
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    // Variables for the GQL query
    const variables: FilterWorklistPaginationVariables = {
        page: page,
        count: defaultVariables.count,
        orderField: defaultVariables.orderField,
        order: defaultVariables.order,
        search: {
            source: defaultSearch.source,
            target: defaultSearch.target,
            filters: defaultSearch.filters,
        },
    };

    // Define the GQL query
    const [run, { error, loading }] = useLazyQuery<FilterWorklistPagination, FilterWorklistPaginationVariables>(QUERY_FULL_WORKLIST_PAGINATION, {
        variables: variables,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.filterTenders) {
                setTendersData(data.filterTenders.results);
                setTotal(data.filterTenders.results?.paginatorInfo.total ? data.filterTenders.results?.paginatorInfo.total : 0);
            }
        },
    });

    // Set page title
    useEffect(() => {
        run({
            variables: {
                page: page,
                count: defaultVariables.count,
                orderField: defaultVariables.orderField,
                order: defaultVariables.order,
                search: {
                    source: defaultSearch.source,
                    target: defaultSearch.target,
                    filters: defaultSearch.filters,
                },
            },
        });
    }, [run, page, t]);

    if (loading) {
        return null;
    }

    if (error || !tendersData || tendersData.data.length === 0) {
        return null;
    }

    // check if currentpage is id of rendered page

    const nextPage = () => {
        setPage(page + 1);
        navigateToNextTender();
    };

    const navigateToNextTender = () => {
        navigate(`/tenders/${tendersData.data[0].id}`);
    };

    return (
        <React.Fragment>
            {tendersData?.paginatorInfo && (
                <div className="InViewPagination">
                    <div className="page-info">
                        <div className="list-type">
                            <Badge
                                sx={{
                                    "& .MuiBadge-badge": {
                                        right: "-5px",
                                        top: "-8px",
                                        fontSize: "9px",
                                        backgroundColor: bgColor,
                                        color: "#ffffff",
                                    },
                                }}
                                badgeContent={total}
                                max={99}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <Typography variant="h4" style={{ marginTop: 0 }}>
                                    {listName}
                                </Typography>
                            </Badge>
                        </div>
                    </div>
                    {tendersData.paginatorInfo.hasMorePages && (
                        <IconButton size="small" onClick={() => nextPage()}>
                            <NavigateNextIcon fontSize="small" />
                        </IconButton>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default withTranslation("translation")(InViewPaginationTenderWorklist);
