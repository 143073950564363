import React from "react";
import { Typography } from "@mui/material";

interface Props {
    Title: string;
    children: React.ReactNode;
}

const TopLinksModule: React.FC<Props> = ({ Title, children }) => {
    return (
        <div>
            <Typography sx={{ margin: "4px 0" }} variant="h4">
                {Title}
            </Typography>

            <div>{children}</div>
        </div>
    );
};

export default TopLinksModule;
