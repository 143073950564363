import React, { useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { usePoll } from "../PollContext";
import FullPageSpinner from "../../../../components/loading/FullPageSpinner";
import HeroDot1 from "../../Hero_GUI/HeroDot1";
import OverlappingDotLeft from "../../Hero_GUI/OverlappingDotLeft";
import OverlappingDotRight from "../../Hero_GUI/OverlappingDotRight";

interface Props {
    endOfPoll: boolean;
    admin?: boolean;
}

const InlineLobby: React.FC<Props> = ({ endOfPoll, admin }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const tendenzRef = useRef<HTMLElement | null>(null);

    const { startPoll } = usePoll();

    return (
        <Box
            id="tendenz"
            ref={tendenzRef}
            py={4}
            sx={{
                height: `70vh`,
                display: "flex",
                flexDirection: "column",
                position: "relative",
                background: `linear-gradient(45deg, #70a4b7, #82b6a0)`,
                overflow: "hidden",
                width: `calc(80vw - 48px)`,
            }}
        >
            <div style={{ flex: "1", position: "relative" }}>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: noMobile ? "32%" : "30%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0",
                        flexDirection: "row",
                        width: "100%",
                        zIndex: 99,
                    }}
                >
                    {endOfPoll && !admin ? (
                        <div style={{ width: "95%" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "2.5rem !important", // Adjust font size as needed
                                            fontWeight: 700, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        Einde
                                    </Typography>
                                    <div style={{ height: "16px" }} />
                                    {/* Text */}
                                    <Typography
                                        sx={{
                                            fontSize: "1.5rem !important", // Adjust font size as needed
                                            fontWeight: 500, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        Bedankt
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ) : admin ? (
                        <div style={{ width: "95%" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "2.5rem !important", // Adjust font size as needed
                                            fontWeight: 700, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        {endOfPoll ? "Einde" : "Welkom"}
                                    </Typography>
                                    <div style={{ height: "16px" }} />
                                    {/* Spinner */}

                                    {!endOfPoll && (
                                        <Button sx={{ zIndex: 99 }} onClick={startPoll} variant="contained" size="large">
                                            Starten
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        // Waiting screen no admin
                        <div style={{ width: "95%" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "2.5rem !important", // Adjust font size as needed
                                            fontWeight: 700, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        Welkom
                                    </Typography>
                                    <div style={{ height: "16px" }} />
                                    {/* Spinner */}
                                    <FullPageSpinner />
                                    {/* Text */}
                                    <Typography
                                        sx={{
                                            fontSize: "1.5rem !important", // Adjust font size as needed
                                            fontWeight: 500, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        Wachten op peiling
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Box>
            </div>

            {/* Dot left */}
            {<HeroDot1 />}
            {/* Dot  overlap left*/}
            {<OverlappingDotLeft top={noMobile ? "200px" : "300px"} left={noMobile ? undefined : "150px"} />}

            {/* Dot */}
            {<OverlappingDotRight top={noMobile ? "400px" : "550px"} left={noMobile ? undefined : "0px"} />}
        </Box>
    );
};

export default InlineLobby;
