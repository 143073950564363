import React from "react";
import TopLinksModule from "../../../../components/organizationComponents/TopLinksModule";
import FaviconBox from "../../../../components/FaviconBox";
import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetTopFiveTenderersForCA, GetTopFiveTenderersForCAVariables } from "../../../../__generated__/GetTopFiveTenderersForCA";
import { Link } from "react-router-dom";
import client from "../../../../graphql/gqlClient";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { QUERY_INFO_ORGANIZATION } from "../../../../graphql/queryDefOrg";
import { QUERY_TOP_FIVE_TENDERERS_FOR_CA } from "../../../../graphql/queryDefCA";

interface Props {
    ca_id: string;
}

const TopFiveMostAwardedTo: React.FC<Props> = ({ ca_id }) => {
    const { trackEvent } = useMatomo();
    const { loading, error, data } = useQuery<GetTopFiveTenderersForCA, GetTopFiveTenderersForCAVariables>(QUERY_TOP_FIVE_TENDERERS_FOR_CA, {
        variables: { id: ca_id },
    });

    if (loading || error || data?.contracting_authority?.most_awarded_to?.length === 0) return <></>;

    // Track event
    const trackMostAwardedCa = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <TopLinksModule Title="meeste opdrachten gegund aan">
            {data?.contracting_authority?.most_awarded_to?.map((c) => {
                return (
                    <li
                        key={c?.client.id}
                        style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2 }}
                        // preload tenderer detailpage data
                        onMouseOver={() =>
                            client.query({
                                query: QUERY_INFO_ORGANIZATION,
                                variables: { id: c?.client.id },
                            })
                        }
                    >
                        {c !== null && <FaviconBox favicon={c.client.favicon_resized ?? c.client.favicon} name={c.client.name} color="#E03660" />}
                        <React.Fragment>
                            <Typography noWrap style={{ marginLeft: 6, display: "inline", maxWidth: "100%", marginRight: 2 }}>
                                <Link
                                    style={{ color: "#465c84", marginRight: 2 }}
                                    to={`/organizations/${c?.client.id}`}
                                    onClick={() => trackMostAwardedCa({ category: "Ca", action: "Click-to-most-awarded-mp-from-ca-detail" })}
                                >
                                    {c?.client.name}
                                </Link>
                                <span style={{ marginLeft: 2, color: "#000", textDecoration: "none !important" }}>({c?.aantal})</span>
                            </Typography>
                        </React.Fragment>
                    </li>
                );
            })}
        </TopLinksModule>
    );
};

export default TopFiveMostAwardedTo;
