import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
// Custom components import
import { useTenderChat } from "./contextProviders/TenderChatContext";
import LikeTenderButton from "./LikeTenderButton";
import { useMatomo } from "@datapunt/matomo-tracker-react";

//Icons used in this component
import MessageIcon from "@mui/icons-material/Message";
import DislikeTenderButton from "./DislikeTenderButton";
import { useLocation } from "react-router-dom";
import ArchiveTender from "./ArchiveTender";
import { Disable } from "react-disable";
import UnArchiveTenderButton from "./UnArchiveTenderButton";
import { TenderUserStatus } from "../__generated__/globalTypes";
import { useQuery } from "@apollo/client";
import { GetLastTenderMessage, GetLastTenderMessageVariables } from "../__generated__/GetLastTenderMessage";
import { GET_LAST_TENDER_MESSAGE } from "../graphql/queryDefinitions";
import ShareTenderButton from "./sharetendercomponent/ShareTenderButton";
import { Badge, useMediaQuery, useTheme } from "@mui/material";
import OpenOrCreateProject from "./tenderDetail/OpenOrCreateProject";

interface Props {
    id: string;
    title: string;
    userStatus: TenderUserStatus;
    hasConversations?: boolean;
    refetchQueries?: any;
}

const SmallTenderMenu: React.FC<Props> = ({ id: tender_id, title: tender_title, userStatus, hasConversations, refetchQueries }) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const history = useLocation();
    const { setOpenModal, setChatTarget } = useTenderChat();
    const reviewpage = history.pathname === "/tenders/reviews";
    const allpage = history.pathname === "/tenders";
    const worklist = history.pathname === "/tenders/worklist";
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { messageSent, openModal } = useTenderChat();

    /**
     * Get message data
     */
    const { data, refetch } = useQuery<GetLastTenderMessage, GetLastTenderMessageVariables>(GET_LAST_TENDER_MESSAGE, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        messageSent === true && openModal === false && refetch();
    }, [refetch, tender_id, messageSent, openModal]);

    // Track event unarchive tender
    const trackChat = () => {
        if (history.pathname === "/dashboard") trackEvent({ category: "Dashboard", action: "open-tender-chat" });
        if (history.pathname.includes("tender")) trackEvent({ category: "Tender", action: "open-tender-chat" });
        if (history.pathname.includes("contracting-authorities")) trackEvent({ category: "Ca", action: "open-tender-chat" });
        if (history.pathname.includes("organizations")) trackEvent({ category: "Organizations", action: "open-tender-chat" });
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            {/* popper to create project */}
            {!allpage && !onlyMobile && (
                <OpenOrCreateProject
                    tender_id={tender_id}
                    tender_title={tender_title}
                    buttonSize="small"
                    openFromTable
                    refetchQueries={refetchQueries}
                />
            )}

            {userStatus === "LIKED" && !allpage && !worklist && (
                <>
                    <ArchiveTender tender_id={tender_id} buttonSize="small" title={tender_title} />
                </>
            )}

            {userStatus === "DISLIKED" && !allpage && !worklist && (
                <>
                    <LikeTenderButton tender_id={tender_id} buttonSize="small" refetchQueries={refetchQueries} />
                    <Tooltip enterNextDelay={100} placement="top" title={"Reeds als niet interessant beoordeeld"}>
                        <div>
                            <Disable disabled={true}>
                                <DislikeTenderButton tender_id={""} buttonSize="small" refetchQueries={refetchQueries} />
                            </Disable>
                        </div>
                    </Tooltip>
                </>
            )}

            {userStatus === "UNREVIEWED" && !allpage && (
                <>
                    <LikeTenderButton tender_id={tender_id} buttonSize="small" refetchQueries={refetchQueries} />
                    <DislikeTenderButton tender_id={tender_id} buttonSize="small" refetchQueries={refetchQueries} />
                </>
            )}

            {userStatus === "DELETED" && reviewpage && (
                <>
                    <LikeTenderButton tender_id={tender_id} buttonSize="small" refetchQueries={refetchQueries} />
                    <DislikeTenderButton tender_id={tender_id} buttonSize="small" refetchQueries={refetchQueries} />
                </>
            )}

            {userStatus === "ARCHIVED" && reviewpage && (
                <>
                    <UnArchiveTenderButton tender_id={tender_id} buttonSize="small" />
                </>
            )}
            {/*
             * Share tender button
             */}
            <ShareTenderButton tender_id={tender_id} buttonSize="small" modelType={"App\\Models\\Tender"} title={tender_title} />

            {/*
             * Button to open chat modal.
             * Available in all tabs
             */}
            <Tooltip enterNextDelay={100} title={t("dashboardPage.Chat") as string} placement="top">
                {/*
                 * Add/edit note of current tender
                 */}
                <Badge
                    sx={{
                        ".MuiBadge-standard": {
                            maxWidth: "16px",
                            minWidth: "16px",
                            minHeight: "16px",
                            maxHeight: "16px",
                            fontSize: "0.6rem",
                            marginRight: "7px",
                            marginTop: "7px",
                        },
                    }}
                    badgeContent={data?.tender?.unreadChatsCount}
                    color="primary"
                    max={999}
                >
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            // Prevent the tab iself being clicked,
                            // we want to execute the click on the close button
                            e.stopPropagation();
                            setChatTarget(tender_id, "App\\Models\\Tender", tender_title || "", "");
                            setOpenModal(true);
                            trackChat();
                        }}
                    >
                        <MessageIcon fontSize="small" sx={{ color: data?.tender?.hasConversations === true ? "grey" : "lightgrey" }} />
                    </IconButton>
                </Badge>
            </Tooltip>
        </div>
    );
};

export default SmallTenderMenu;
