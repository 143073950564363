import React from "react";
import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Typography,
} from "@mui/material";
import { DistributionIntervalInput, NotificationPreferenceInput, TenderModulePreferences } from "../../../../__generated__/globalTypes";
import { Mail, Notifications } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";

interface Props {
    settings: TenderModulePreferences;
    setting: NotificationPreferenceInput | null;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
    saveSettings: (settings: TenderModulePreferences) => void;
    i: number;
}

const NotificationSettingMobile: React.FC<Props> = ({ settings, setting, setChanged, saveSettings, i }) => {
    const { t } = useTranslation();

    return (
        <div key={setting?.event} style={{ marginBottom: "8px" }}>
            <Paper variant="outlined" sx={{ padding: "8px" }}>
                <Typography sx={{ fontWeight: "bold" }}>{t(setting?.event as string)}</Typography>
                <ListItem sx={{ padding: 0 }}>
                    <ListItemIcon style={{ minWidth: 0, marginRight: "8px" }}>
                        <Notifications fontSize="small" />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" primary={"Melding"} />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <FormControlLabel
                            sx={{ marginLeft: 0, marginRight: 0 }}
                            control={
                                <Checkbox
                                    size="small"
                                    disabled
                                    checked={setting?.notification}
                                    onChange={(e) => {
                                        saveSettings(
                                            update(settings, {
                                                notifications: {
                                                    [i]: {
                                                        notification: { $set: e.target.checked },
                                                    },
                                                },
                                            })
                                        );
                                        setChanged(true);
                                    }}
                                    name={setting?.event}
                                />
                            }
                            label=""
                        />
                    </div>
                </ListItem>
                <Divider />
                <ListItem sx={{ padding: 0 }}>
                    <ListItemIcon style={{ minWidth: 0, marginRight: "8px" }}>
                        <Mail fontSize="small" />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" primary={"Mail"} />
                    <FormControlLabel
                        sx={{ marginLeft: 0, marginRight: 0 }}
                        control={
                            <>
                                {setting?.email === true && (
                                    <FormControl variant="standard" margin="dense">
                                        <Select
                                            disableUnderline
                                            labelId="demo-simple-select-outlined-label"
                                            defaultValue={setting?.emailInterval || DistributionIntervalInput.DAILY}
                                        >
                                            <MenuItem
                                                value={"HOURLY"}
                                                onClick={(e) => {
                                                    saveSettings(
                                                        update(settings, {
                                                            notifications: {
                                                                [i]: {
                                                                    emailInterval: { $set: DistributionIntervalInput.HOURLY },
                                                                },
                                                            },
                                                        })
                                                    );
                                                    setChanged(true);
                                                }}
                                            >
                                                Direct
                                            </MenuItem>
                                            <MenuItem
                                                value={"DAILY"}
                                                onClick={(e) => {
                                                    saveSettings(
                                                        update(settings, {
                                                            notifications: {
                                                                [i]: {
                                                                    emailInterval: { $set: DistributionIntervalInput.DAILY },
                                                                },
                                                            },
                                                        })
                                                    );
                                                    setChanged(true);
                                                }}
                                            >
                                                Dagelijks
                                            </MenuItem>
                                            <MenuItem
                                                value={"WEEKLY"}
                                                onClick={(e) => {
                                                    saveSettings(
                                                        update(settings, {
                                                            notifications: {
                                                                [i]: {
                                                                    emailInterval: { $set: DistributionIntervalInput.WEEKLY },
                                                                },
                                                            },
                                                        })
                                                    );
                                                    setChanged(true);
                                                }}
                                            >
                                                Wekelijks
                                            </MenuItem>
                                            <MenuItem
                                                value={"MONTHLY"}
                                                onClick={(e) => {
                                                    saveSettings(
                                                        update(settings, {
                                                            notifications: {
                                                                [i]: {
                                                                    emailInterval: { $set: DistributionIntervalInput.MONTHLY },
                                                                },
                                                            },
                                                        })
                                                    );
                                                    setChanged(true);
                                                }}
                                            >
                                                Maandelijks
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                                <Checkbox
                                    size="small"
                                    checked={setting?.email}
                                    onChange={(e) => {
                                        saveSettings(
                                            update(settings, {
                                                notifications: {
                                                    [i]: {
                                                        email: { $set: e.target.checked },
                                                    },
                                                },
                                            })
                                        );
                                        setChanged(true);
                                    }}
                                    name={setting?.event}
                                />
                            </>
                        }
                        label=""
                    />
                </ListItem>
            </Paper>
        </div>
    );
};

export default NotificationSettingMobile;
