import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Card, Grid, Typography, useMediaQuery } from "@mui/material";
import { CoPresent } from "@mui/icons-material";
import { WebinarData } from "./TendenzModule";
import Meeting from "../../assets/helperImg.png";
import lowgow from "../../assets/tenderguide.svg";

interface Props {
    webinarData: WebinarData;
}

const Webinar: React.FC<Props> = ({ webinarData }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Box py={4} px={4} sx={{ display: "flex", flexDirection: "column", position: "relative", overflow: "hidden" }}>
            <div id="program" />
            <Typography variant="h1" fontWeight={500} gutterBottom align="center" sx={{ margin: 5, fontSize: "3rem !important" }}>
                Meer informatie
            </Typography>

            {noMobile && (
                <Card
                    sx={{
                        position: "absolute",
                        width: "65px",
                        height: "65px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "12px",
                        right: "67px",
                        top: "216px",
                        background: "#fff",
                        zIndex: 25,
                    }}
                    elevation={3}
                >
                    <CoPresent sx={{ fontSize: "2.5rem", color: "#70a4b7" }} />
                </Card>
            )}

            <Grid container spacing={2} style={{ flexDirection: noMobile ? "row" : "column-reverse" }}>
                <Grid
                    item
                    xs={12}
                    md={5}
                    style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center", zIndex: 99 }}
                >
                    {noMobile && <div style={{ height: 150 }} />}
                    {webinarData.image1 && (
                        <Card sx={{ height: 350, width: noMobile ? "70%" : "100%", borderRadius: "16px" }}>
                            <img
                                src={webinarData.image1}
                                alt=""
                                style={{
                                    objectFit: "cover",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    width: "100%",
                                    objectPosition: "100% 100%",
                                }}
                            />
                        </Card>
                    )}

                    {webinarData.image1 && <div style={{ height: "32px" }} />}

                    <Card
                        sx={{
                            height: noMobile ? 350 : "auto",
                            width: noMobile ? "auto" : "100%",
                            backgroundColor: "#ffffff80",
                            borderRadius: "16px",
                            left: noMobile ? 40 : 0,
                            position: "relative",
                        }}
                    >
                        <img
                            src={Meeting}
                            alt=""
                            style={{
                                objectFit: "cover",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                width: "100%",
                                objectPosition: "100% 60%",
                            }}
                        />
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={undefined}
                    md={1}
                    style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
                />

                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ marginBottom: "32px" }}>
                        <img src={lowgow} alt={webinarData.title} width={noMobile ? 400 : "100%"} height="auto" style={{ margin: "16px -26px" }} />

                        <Typography gutterBottom fontWeight={500} variant="h3" style={{ marginTop: "16px", width: noMobile ? "80%" : "100%" }}>
                            {webinarData.subject}
                        </Typography>
                        <Typography variant="h5" sx={{ width: noMobile ? "80%" : "100%" }}>
                            {webinarData.description}
                        </Typography>
                        <div style={{ marginTop: "24px" }}>
                            <Typography variant="h5" sx={{ width: noMobile ? "80%" : "100%", marginBottom: "4px" }}>
                                {webinarData.topicTitle}
                            </Typography>

                            {webinarData.topics.map((topic) => {
                                return (
                                    <div key={topic} style={{ display: "flex" }}>
                                        <div style={{ height: "100%", width: "12px" }}>-</div>
                                        <Typography variant="h5" sx={{ width: noMobile ? "80%" : "100%" }}>
                                            {topic}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </div>

                        {/* Block with google meet text and icons */}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "56px" }}>
                            <img
                                src="https://ssl.gstatic.com/calendar/images/conferenceproviders/logo_meet_2020q4_192px.svg"
                                alt="Google Meet"
                                style={{ width: 24, height: 24, margin: "1px 12px 0px 3px" }}
                            />
                            {/* <VideocamIcon style={{ marginRight: 4, color: lightGrey }} /> */}
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="h5" fontWeight={500}>
                                    {webinarData.environment}
                                </Typography>
                                <Typography style={{ marginTop: -5, marginLeft: 1 }}>
                                    Informatie over Google Meet ontvang je in de bevestigingsmail na aanmelding.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            {noMobile && <div style={{ height: 100 }} />}

            {/* ICON PINPOINT */}
            <div
                style={{
                    position: "absolute",
                    top: "-100px",
                    right: "-400px",
                    height: "800px",
                    width: "800px",
                }}
            >
                <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-m9simb"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="LocationOnIcon"
                >
                    <path
                        fill="#70a4b714"
                        d="M14 12c0 .74-.4 1.38-1 1.72V22h-2v-8.28c-.6-.35-1-.98-1-1.72 0-1.1.9-2 2-2s2 .9 2 2m-2-6c-3.31 0-6 2.69-6 6 0 1.74.75 3.31 1.94 4.4l1.42-1.42C8.53 14.25 8 13.19 8 12c0-2.21 1.79-4 4-4s4 1.79 4 4c0 1.19-.53 2.25-1.36 2.98l1.42 1.42C17.25 15.31 18 13.74 18 12c0-3.31-2.69-6-6-6m0-4C6.48 2 2 6.48 2 12c0 2.85 1.2 5.41 3.11 7.24l1.42-1.42C4.98 16.36 4 14.29 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 2.29-.98 4.36-2.53 5.82l1.42 1.42C20.8 17.41 22 14.85 22 12c0-5.52-4.48-10-10-10"
                    />
                </svg>
            </div>

            {/* DOTS */}
            <div
                style={{
                    position: "absolute",
                    bottom: "60px",
                    left: "110px",
                    height: "500px",
                    width: "500px",
                }}
            >
                <svg id="10015.io" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <pattern
                            id="svg-pattern"
                            x="0"
                            y="0"
                            width="54"
                            height="54"
                            patternUnits="userSpaceOnUse"
                            patternTransform="translate(30, 30) rotate(0) skewX(0)"
                        >
                            <svg width="24" height="24" viewBox="0 0 100 100">
                                <g fill="#70a4b714" opacity="1">
                                    <circle cx="50" cy="50" r="50" />
                                </g>
                            </svg>
                        </pattern>
                    </defs>
                    <rect x="0" y="0" width="100%" height="100%" fill="rgba(255, 255, 255, 0)" />
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#svg-pattern)" />
                </svg>
            </div>
        </Box>
    );
};

export default Webinar;
