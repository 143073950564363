import React from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { GetTypeColumn, GetTypeColumnVariables } from "../../__generated__/GetTypeColumn";
import { GET_TYPE_COLUMN } from "../../graphql/queryDefForColumns";

interface Props {
    tender_id: string;
}

const TypeColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetTypeColumn, GetTypeColumnVariables>(GET_TYPE_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    return <Typography>{tender.translated_type}</Typography>;
};

export default TypeColumn;
