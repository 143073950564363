import React from "react";
import { useQuery } from "@apollo/client";
import { GetStateOfWinner, GetStateOfWinnerVariables } from "../__generated__/GetStateOfWinner";
import { Tooltip } from "@mui/material";
import { Check, Clear, DoneOutline, Help } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { GET_WINNER_STATE_LOTS_BIDS } from "../graphql/queryDefinitions";

interface Props {
    tender_id: string;
    org_id: string;
}

const ToolTipWinnerMP: React.FC<Props> = ({ tender_id, org_id }) => {
    const { t } = useTranslation();

    const renderPage = (content: React.ReactNode) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: 16 }}>{content}</div>
    );

    /**
     * Get message data
     */
    const { data } = useQuery<GetStateOfWinner, GetStateOfWinnerVariables>(GET_WINNER_STATE_LOTS_BIDS, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (data === undefined || data.tender === null) {
        return renderPage(<div style={{ width: 20, height: 20 }} />);
    }

    const lotsToCheck = data.tender.lots.map((lot) => lot.id);

    const getLotsOfCurrentMP =
        data.tender.bids !== null && data?.tender?.bids !== undefined
            ? data.tender.bids.filter((bid) => lotsToCheck.includes(bid?.lot?.id as string)).filter((i) => i.marketParty.id === org_id)
            : [];

    const winner =
        data.tender.status === "OPEN"
            ? "OPEN"
            : data.tender.status === "PRELIMINARY_AWARDED"
            ? "PreliminaryAwarded"
            : data?.tender?.status === "PARTICIPANTS_KNOWN"
            ? "PARTICIPANTS_KNOWN"
            : getLotsOfCurrentMP.filter((i) => i.winner === true).length > 0
            ? "lots"
            : data.tender.bids?.find((i) => i.marketParty.id === org_id)?.preliminaryAwarded
            ? "PreliminaryAwarded"
            : data.tender.bids?.find((i) => i.marketParty.id === org_id)?.winner;

    return (
        <React.Fragment>
            {(() => {
                /**
                 * Switch contains all states a tender could have, for each state a different component.
                 */
                switch (winner) {
                    case false:
                        return renderPage(
                            <Tooltip title={t<string>("Status.AnotherWinner")} placement="right">
                                <Clear fontSize="small" style={{ color: "red", cursor: "pointer" }} />
                            </Tooltip>
                        );
                    case true:
                        return renderPage(
                            <Tooltip title={t<string>("Status.Winner")} placement="right">
                                <Check fontSize="small" style={{ color: "green", cursor: "pointer" }} />
                            </Tooltip>
                        );

                    case "lots":
                        return renderPage(
                            <Tooltip
                                title={
                                    <>
                                        <div> {t<string>("Status.Winner")}</div>
                                        <div>perceel {getLotsOfCurrentMP.map((lot: any) => lot.lot.number).join(", ")}</div>
                                    </>
                                }
                                placement="right"
                            >
                                <Check fontSize="small" style={{ color: "green", cursor: "pointer" }} />
                            </Tooltip>
                        );

                    case "OPEN":
                        return renderPage(
                            <Tooltip
                                title={
                                    <>
                                        <div> {t<string>("Status.Unknown")}</div>
                                    </>
                                }
                                placement="right"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <Help fontSize="small" style={{ color: "orange", cursor: "pointer" }} />
                            </Tooltip>
                        );

                    case "PreliminaryAwarded":
                        return renderPage(
                            <Tooltip title={t<string>("Status.PreliminaryAwarded")} placement="right">
                                <DoneOutline fontSize="small" style={{ color: "green", cursor: "pointer" }} />
                            </Tooltip>
                        );

                    case "PARTICIPANTS_KNOWN":
                        return renderPage(
                            <Tooltip
                                title={
                                    <>
                                        <div>Ingeschreven bij aanbesteding</div>
                                        <div> {t<string>("Status.Unknown")}</div>
                                    </>
                                }
                                placement="right"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <Help fontSize="small" style={{ color: "orange", cursor: "pointer" }} />
                            </Tooltip>
                        );

                    default:
                        return renderPage(
                            <Tooltip
                                title={t<string>("Status.Unknown")}
                                placement="left"
                                arrow
                                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <Help fontSize="small" style={{ color: "orange", cursor: "pointer" }} />
                            </Tooltip>
                        );
                }
            })()}
        </React.Fragment>
    );
};

export default ToolTipWinnerMP;
