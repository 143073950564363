import React from "react";
import OrgTenderFullTable from "./OrgTenderFullTable";
import { MUIDataTableOptions } from "mui-datatables";
import {
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_data,
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_paginatorInfo,
} from "../../../../../__generated__/FilterTendersOverviewOnMarketPartyPageMinimum";

interface Props {
    /**
     * onChange page or rows per page
     */
    onChangePagination(page: number, rowsPerPage: number): void;
    /**
     * change in show/hide columns
     */
    onTableChange: MUIDataTableOptions["onTableChange"];
    data: FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_data[];
    paginatorInfo: FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_paginatorInfo | undefined;
    loading: boolean;
    org_id: string;
}

const OrgTenderFullList: React.FC<Props> = ({ paginatorInfo, data, onChangePagination, onTableChange, loading, org_id }) => {
    return (
        <OrgTenderFullTable
            org_id={org_id}
            loading={loading}
            bids={data}
            paginatorInfo={
                paginatorInfo
                    ? paginatorInfo
                    : {
                          __typename: "PaginatorInfo",
                          count: 0,
                          currentPage: 0,
                          hasMorePages: false,
                          firstItem: 0,
                          lastItem: 0,
                          lastPage: 0,
                          perPage: 0,
                          total: 0,
                      }
            }
            onChangePagination={onChangePagination}
            // onTableChange={handleTableChange}
        />
    );
};

export default OrgTenderFullList;
