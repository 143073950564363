import React, { useState } from "react";
import { Chip, Link, ListItem, ListItemText, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/GetApp";

import moment from "moment";
import MarkedDocIconSwitch from "../../MarkedDocIconSwitch";
import DocumentDrawer from "../../../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { useTranslation } from "react-i18next";
import { GetDocumentsList_tender_lots } from "../../../__generated__/GetDocumentsList";
interface Props {
    label: string;
    icon: string | null;
    date: string;
    link: string;
    file_id: string;
    tender_id: string;
    lotNumber: string | undefined;
    lots: GetDocumentsList_tender_lots[];
}

const MarkedDocument: React.FC<Props> = ({ label, icon, date, link, file_id, tender_id, lotNumber, lots }) => {
    const { t } = useTranslation();
    const [dialog, setDialog] = useState(false);
    const [hovered, setHovered] = useState(false);
    const colorDownload = "#9e9e9e";
    const blue = "#173357";
    const { trackEvent } = useMatomo();

    // Track event
    const trackSingleDownload = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <>
            <ListItem
                sx={{
                    hover: {
                        "&:hover": {
                            cursor: "pointer",
                        },
                    },
                }}
                // button
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={(e) => {
                    // Stop click on listitem to prevent document-viewer to initiate
                    trackSingleDownload({ category: "Tender", action: "Click-download-marked-file", name: `${label}`, href: window.location.href });
                    e.stopPropagation();
                    const element = document.createElement("a");
                    const encodedFilename = label ? encodeURIComponent(label) : "doc";
                    element.setAttribute("download", `${encodedFilename}`);
                    element.setAttribute("href", `${process.env.REACT_APP_API_ROOT}/tenders/${tender_id}/downloadFile/${file_id}`);
                    element.style.display = "none";
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }}
            >
                <MarkedDocIconSwitch icon={icon} />
                <ListItemText
                    primary={
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <Link
                                    sx={{
                                        textDecoration: "none",
                                        hover: {
                                            "&:hover": {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    onClick={(e) => {
                                        if (icon === "pdf" || (icon === "fa-file-pdf-o" && hovered === false)) {
                                            e.stopPropagation();
                                            setDialog(true);
                                        } else {
                                            // Stop click on listitem to prevent document-viewer to initiate
                                            e.stopPropagation();
                                            const element = document.createElement("a");
                                            element.setAttribute("href", `${link}&response-content-disposition=attachment; filename=${label}`);
                                            element.style.display = "none";
                                            document.body.appendChild(element);
                                            element.click();
                                            document.body.removeChild(element);
                                        }
                                    }}
                                    onMouseEnter={() => setHovered(false)}
                                    onMouseLeave={() => setHovered(true)}
                                >
                                    <Typography>{label}</Typography>
                                </Link>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {hovered ? (
                                    <DownloadIcon fontSize="small" style={{ color: `${colorDownload}` }} />
                                ) : (
                                    <Typography>{moment(date).format("LL")}</Typography>
                                )}
                                {lotNumber && (
                                    <Chip
                                        size="small"
                                        label={
                                            <Typography variant="caption" fontWeight={500}>
                                                {t("Lot")} {lots.find((lot) => lot.id === lotNumber)?.number}
                                            </Typography>
                                        }
                                        sx={{ marginLeft: "8px" }}
                                    />
                                )}
                            </div>
                        </div>
                    }
                />
            </ListItem>
            {typeof label === "string" && typeof link === "string" && dialog === true && (
                <DocumentDrawer open={dialog} onClose={() => setDialog(false)} title={label} color={blue} url={link} />
            )}
        </>
    );
};

export default MarkedDocument;
