import React, { useEffect } from "react";
import { GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_data } from "../../../../__generated__/GetAllDocsForOppSearchWizard";
import { Grid, Paper, Typography } from "@mui/material";
import { TrendingUp } from "@mui/icons-material";
import FaviconBox from "../../../../components/FaviconBox";
import moment from "moment";
import CountryFlagBox from "../../../../components/boxes/CountryFlagBox";
import { handleOppHighlighting } from "../../../../components/HighlightOpportunity";
import { ApolloError } from "@apollo/client";

interface Props {
    loading: boolean;
    selectedItem: GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_data | undefined;
    snippets: (string[] | null | undefined)[] | undefined;
    error: ApolloError | undefined;
}

const OppSplitViewer: React.FC<Props> = ({ loading, selectedItem, snippets, error }) => {
    const yellow = "#F57117";

    useEffect(() => {
        handleOppHighlighting();
    }, [selectedItem]);

    /**
     * Loading component to show user something is happening
     */
    if (loading) {
        return (
            <Paper elevation={0} square style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <TrendingUp style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                    <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                        Resultaten ophalen
                    </Typography>
                </div>
            </Paper>
        );
    }

    /**
     * Error component to show user something is happening
     */
    if (error) {
        return (
            <Paper elevation={0} square style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <TrendingUp style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                    <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                        Geen resultaten gevonden
                    </Typography>
                </div>
            </Paper>
        );
    }

    return (
        <Paper elevation={0} square style={{ height: "100%", padding: "48px 48px 16px" }}>
            {selectedItem !== undefined && (
                <Grid container style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    {/* Topbox
                     * Contains status, title and CA of document
                     */}
                    <Grid
                        item
                        sx={{
                            height: "100px",
                            display: "flex",
                            marginBottom: "16px",
                        }}
                    >
                        {/*
                         * Status of opportunity document
                         */}
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div>
                                {/*
                                 * Favicon CA of opportunity document
                                 */}
                                <Typography variant="h5">{selectedItem.name}</Typography>
                                <Typography variant="body1" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 5 }}>
                                    <FaviconBox
                                        marginTop={0}
                                        name={selectedItem.contractingAuthority.name}
                                        favicon={selectedItem.contractingAuthority.favicon_resized ?? selectedItem.contractingAuthority.favicon}
                                        color={yellow}
                                    />
                                    {selectedItem.contractingAuthority.name}
                                </Typography>
                            </div>
                        </div>
                    </Grid>

                    {/*
                     * Center box
                     * Contains all fragments of the opportunity document
                     */}
                    <Grid
                        item
                        sx={{
                            flex: 1 /* 1 and it will fill whole space left if no flex value are set to other children*/,
                            overflow: "auto",
                            marginTop: "16px",
                        }}
                    >
                        <Typography variant="h4" style={{ marginBottom: 16 }}>
                            Gevonden resultaten
                        </Typography>
                        {snippets?.map((snippet) =>
                            snippet?.map((item: any, index: number) => (
                                <Typography
                                    key={index}
                                    style={{ padding: "0px 8px 8px 0" }}
                                    dangerouslySetInnerHTML={{
                                        __html: `...${item}...`,
                                    }}
                                />
                            ))
                        )}
                    </Grid>

                    {/*
                     * Bottom box
                     * Contains all meta data
                     */}
                    <Grid item sx={{ height: "70px" }} container>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                            {/* Publication date */}
                            <div>
                                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    Gepubliceerd
                                </Typography>
                                <Typography>{moment(selectedItem.created_at).format("L LT")},</Typography>
                                <Typography>({moment(selectedItem.created_at).fromNow()})</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                            {/* Document type */}
                            <div>
                                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    Type document
                                </Typography>
                                <Typography>{selectedItem.type?.name}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                            {/* City of CA */}
                            <div>
                                <Typography variant="h4" style={{}}>
                                    Plaats
                                </Typography>
                                <div style={{ display: "flex" }}>
                                    <Typography style={{ marginRight: 4 }}>
                                        <CountryFlagBox code={selectedItem.contractingAuthority.country?.alpha2 || null} marginLeft={0} />
                                    </Typography>
                                    <Typography>{selectedItem.contractingAuthority.city}</Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
};

export default OppSplitViewer;
