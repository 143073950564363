type InputData = {
    contractEnd: any;
    looptijd_einde?: any;
    next_looptijd_einde?: any;
};

export const isAllDataInputsNullExceptStartDate = ({ contractEnd, looptijd_einde, next_looptijd_einde }: InputData): boolean => {
    return (
        contractEnd === null &&
        (looptijd_einde?.[0]?.value === null || looptijd_einde === null) &&
        (next_looptijd_einde?.[0]?.value === null || next_looptijd_einde === null)
    );
};
