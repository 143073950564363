import React, { useState } from "react";
import WidgetBox from "../WidgetBox";
import { GetOppDetailPage_opportunityFilePersonalPages_data } from "../../__generated__/GetOppDetailPage";
import { useQuery } from "@apollo/client";
import {
    GetopportunityPagePersonalOpportunities,
    GetopportunityPagePersonalOpportunitiesVariables,
} from "../../__generated__/GetopportunityPagePersonalOpportunities";

import CollapsableOpportunity from "../../routes/opportunities/detailpage/CollapsableOpportunity";
import FoundResult from "./FoundResult";
import { QUERY_USER_OPPS_OF_PAGE } from "../../graphql/queryDefinitions";

interface Props {
    active: number | boolean;
    data: GetOppDetailPage_opportunityFilePersonalPages_data;
    onPageClick(): void;
    pdfUrl: string | null;
    read: boolean | null;
}

const DocFragment: React.FC<Props> = ({ active, data: dada, onPageClick, pdfUrl, read }) => {
    const [fragment] = useState(dada);

    const { data } = useQuery<GetopportunityPagePersonalOpportunities, GetopportunityPagePersonalOpportunitiesVariables>(QUERY_USER_OPPS_OF_PAGE, {
        variables: { page_id: parseInt(fragment.id) },
        fetchPolicy: "network-only",
    });

    // state to show form to create a opportunity

    const renderComponent = (content: React.ReactNode) => {
        return (
            <WidgetBox header={""} highlight="green" padding={0}>
                {content}
            </WidgetBox>
        );
    };

    /**
     *  If page is set to read = true -> show collapsed version
     */
    if (read === true)
        return renderComponent(
            <CollapsableOpportunity
                onPageClick={onPageClick}
                page_number={fragment.page as number}
                page_id={fragment.id}
                pdfUrl={pdfUrl}
                active={active}
                data={data?.opportunityPagePersonalOpportunities}
                read={read}
            />
        );

    /**
     *  If page is not read -> show "default" version
     */
    return renderComponent(
        <FoundResult
            read={read}
            showPageNumber={true}
            page={fragment.page}
            pdfUrl={pdfUrl}
            active={active}
            onPageClick={onPageClick}
            fragment_id={fragment.id}
            data={data?.opportunityPagePersonalOpportunities}
        />
    );
};

export default DocFragment;
