import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { AccountTree, Add, Groups, TaskAlt } from "@mui/icons-material";
import { Badge, Divider, Tooltip } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import CreateProjectFromScratch from "../createproject/CreateNewProjectFromScratch";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

export enum View {
    Tasks = 0,
    Column = 1,
    Person = 2,
    Split = 3,
}

interface Props {
    setView: (view: View) => void;
    view: View;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
}));

const ProjectViewToggleButtons: React.FC<Props> = ({ setView, view }) => {
    const [openPopper, setOpenPopper] = useState<boolean>(false);
    const [anchorEl, setAnchor] = useState<null | HTMLElement>(null);
    const { pathname } = useLocation();
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };
    const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: View) => {
        if (newAlignment !== null) {
            setView(newAlignment);
            trackevents({ category: "Project", action: "Click-project-view", name: newAlignment.toString() });
        }
    };

    return (
        <Paper
            elevation={0}
            sx={{
                display: "flex",
                border: (theme) => `1px solid ${theme.palette.divider}`,
                flexWrap: "wrap",
            }}
        >
            <StyledToggleButtonGroup size="small" value={view} exclusive onChange={handleAlignment} aria-label="views">
                <ToggleButton disabled value={View.Tasks} aria-label="List">
                    <Tooltip title={"Taken"}>
                        <TaskAlt fontSize="small" />
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value={View.Column} aria-label="Column">
                    <Tooltip title={"Kanban-bord"}>
                        <ViewColumnIcon fontSize="small" />
                    </Tooltip>
                </ToggleButton>
                <ToggleButton disabled value={View.Person} aria-label="Event">
                    <Tooltip title={"Teams"}>
                        <Groups fontSize="small" />
                    </Tooltip>
                </ToggleButton>

                {/* <ToggleButton disabled value={View.Split} aria-label="Split">
                    <VerticalSplitIcon fontSize="small" />
                </ToggleButton> */}
            </StyledToggleButtonGroup>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <StyledToggleButtonGroup
                size="small"
                exclusive
                onChange={(e) => {
                    setOpenPopper(true);
                    setAnchor(e.currentTarget);
                    trackevents({ category: "Project", action: "Click-create-project-from-projectpage" });
                }}
                sx={{ marginLeft: "0px !important" }}
                aria-label="views"
            >
                {/* Disabled on project detailpage */}
                <ToggleButton
                    sx={{
                        padding: "0px 0px 0px 9px",
                        marginLeft: "0px !important",
                    }}
                    value="check"
                    // selected={openPopper}
                    disabled={pathname !== "/projects"}
                >
                    <Tooltip disableInteractive title="Maak project" placement="left" PopperProps={{ style: { zIndex: 1100 } }}>
                        <Badge
                            sx={{
                                cursor: "pointer",
                                marginRight: "12px",
                                "& .MuiBadge-badge": {
                                    right: -2,
                                    top: -2,
                                    border: `1px solid #fff`,
                                    backgroundColor: "#9e9e9e",
                                    padding: "0 2px",
                                    fontSize: "9px",
                                    minWidth: "12px",
                                    height: "16px",
                                },
                            }}
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            badgeContent={
                                <>
                                    <Add sx={{ height: "10px", width: "10px", padding: 0, color: "#ffffff" }} />
                                </>
                            }
                        >
                            <CreateProjectFromScratch
                                createnew={setOpenPopper}
                                anchor={anchorEl}
                                setAnchor={setAnchor}
                                openForNewProject={openPopper}
                                btn={<AccountTree fontSize="small" />}
                            />
                        </Badge>
                    </Tooltip>
                </ToggleButton>
            </StyledToggleButtonGroup>
        </Paper>
    );
};
export default ProjectViewToggleButtons;
