import React from "react";
import { GetTileWidgetData_filterTenders_results_data } from "../../../../__generated__/GetTileWidgetData";
import { Card, Grid, Typography } from "@mui/material";
import HorizontalContractTimeline from "../../../../components/tenderDetail/widgets/contract_timeline/HorizontalContractTimeline";
import BidCard from "./BidCard";
import StatusChip from "../../../../components/StatusChip";
import { Link } from "react-router-dom";
import { OptieEinde, convertToStringArray } from "../../../../utils/contracttimeline";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { GetLikedTendersOfUser } from "../../../../__generated__/GetLikedTendersOfUser";
import { QUERY_USER_LIKED_TENDERS } from "../../../../graphql/queryDefCurrentUser";
import { ThumbUp } from "@mui/icons-material";

interface Props {
    tndr: GetTileWidgetData_filterTenders_results_data;
    total: number;
    index: number;
    opties: OptieEinde[];
}

const InfoBox: React.FC<Props> = ({ tndr, total, index, opties }) => {
    const { data: TNDR } = useQuery<GetLikedTendersOfUser>(QUERY_USER_LIKED_TENDERS, { fetchPolicy: "network-only" });
    const tndrs = TNDR?.currentUser.likedTenders.map((t) => t.id);

    // Step 1: Filter the bids where winner is true
    const winningBids = _.filter(tndr.bids, { winner: true });

    // Step 2: Group the bids by marketParty id
    const groupedBids = _.groupBy(winningBids, (bid) => bid.marketParty.id);

    // Step 3: Merge data for each group
    const mergedBids = _.map(groupedBids, (group, id) => {
        const mergedBid = {
            marketParty: group[0].marketParty,
            lots: group.map((bid) => bid.lot),
            bids: group.map((bid) => ({
                bid: bid.bid,
                lot: bid.lot,
            })),
        };
        return mergedBid;
    });

    return (
        <React.Fragment key={tndr.id}>
            <Card variant="outlined" sx={{ marginBottom: "8px" }}>
                <div style={{ margin: total - 1 !== index ? "24px 16px" : "24px 16px 0px 16px" }}>
                    <Typography gutterBottom variant="h3">
                        {tndrs?.find((i) => i === tndr.id) && (
                            <ThumbUp fontSize="small" sx={{ marginRight: "4px", marginTop: "4px", color: "#173357" }} />
                        )}
                        <Link to={`/tenders/${tndr.id}`}>{tndr.namegiventocontract}</Link>
                    </Typography>
                    {/* ADD timeline when data is available */}
                    <HorizontalContractTimeline
                        start={(tndr.looptijd_start && tndr.looptijd_start[0]?.value) || tndr.datum_gunning}
                        end={tndr.looptijd_einde && tndr.looptijd_einde[0]?.value}
                        undetermined={false}
                        options={convertToStringArray(opties)}
                        datum_gunning_as_start={Boolean(tndr.looptijd_start && tndr.looptijd_start[0]?.value === null)}
                        noEndDateAvailable={Boolean(tndr.looptijd_einde === null || tndr.looptijd_einde[0]?.value === null)}
                    />
                    <div style={{ marginTop: "64px" }}>
                        {/* Map over all tenders of this CA, show winning bids or state of tender if no winning bids are available*/}
                        {tndr.bids && tndr.bids.filter((bid) => bid.winner === true).length > 0 ? (
                            // Card for each winning bid, with lotnumber bid value and name of marketparty
                            <Grid container direction={"row"} spacing={2} marginTop={2} marginLeft={0}>
                                {mergedBids.map((bid, index) => {
                                    return (
                                        <Grid key={index} marginBottom={1} marginRight={1}>
                                            <BidCard bid={bid.marketParty} bids={bid.bids} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ) : (
                            // Div with state of current tender
                            <div style={{ margin: "8px 0px" }}>
                                <StatusChip type={tndr.status} colored={true} />
                            </div>
                        )}
                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default InfoBox;
