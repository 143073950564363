import React from "react";
import {
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    styled,
    ToggleButton,
    ToggleButtonGroup,
    toggleButtonGroupClasses,
    Typography,
} from "@mui/material";
import { DefaultViewPreferenceInput, GridViewInput } from "../../../../__generated__/globalTypes";
import { Description } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { VerticalSplit, List as Listy, Autorenew } from "@mui/icons-material";
import update from "immutability-helper";

interface Props {
    defaultView: DefaultViewPreferenceInput[];
    setDefaultView: React.Dispatch<React.SetStateAction<DefaultViewPreferenceInput[]>>;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
        marginLeft: -1,
        borderLeft: "1px solid transparent",
    },
}));

const TenderViewPreferencesMobile: React.FC<Props> = ({ defaultView, setDefaultView, setChanged }) => {
    const { t } = useTranslation();
    const blue = "#173357";

    return (
        <List sx={{ width: "100%", padding: 0 }}>
            <Typography variant="h3" sx={{ display: "flex", alignItems: "center" }}>
                <Description style={{ color: blue, marginRight: 8, height: 20, width: 20 }} />
                {t("DefaultView")}
            </Typography>

            <Typography variant="caption">Bepaal jouw gewenste weergave</Typography>
            <Paper variant="outlined" sx={{ padding: "8px", width: "100%" }}>
                {/* ****************************************
                 *** REVIEWS
                 ******************************************** */}
                <ListItem sx={{ padding: 0, width: "100%" }}>
                    <ListItemText id="switch-list-label-wifi" primary={"Beoordelingen"} />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div>
                            <Paper
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    border: (theme) => `1px solid ${theme.palette.divider}`,
                                    flexWrap: "wrap",
                                }}
                            >
                                <StyledToggleButtonGroup
                                    size="small"
                                    value={defaultView[1].view}
                                    exclusive
                                    onChange={(_, newValue) => {
                                        setChanged(true);
                                        setDefaultView(
                                            update(defaultView, {
                                                // 1: TENDER_REVIEWS (based on localstate array - settings.tsx)
                                                1: {
                                                    view: { $set: newValue }, // or adjust according to how `view` is set
                                                },
                                            })
                                        );
                                    }}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton size="small" value={GridViewInput.LIST} aria-label="left aligned">
                                        <Listy />
                                    </ToggleButton>
                                    <Divider flexItem orientation="vertical" sx={{ m: 0.5 }} />
                                    <ToggleButton size="small" value={GridViewInput.UPDATE} aria-label="right aligned">
                                        <Autorenew />
                                    </ToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </div>
                    </div>
                </ListItem>

                {/* ****************************************
                 *** WORKLIST
                 ******************************************** */}
                <ListItem sx={{ padding: 0, width: "100%", mt: 0.5 }}>
                    <ListItemText id="switch-list-label-wifi" primary={"Werklijst"} />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div>
                            <Paper
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    border: (theme) => `1px solid ${theme.palette.divider}`,
                                    flexWrap: "wrap",
                                }}
                            >
                                <StyledToggleButtonGroup
                                    size="small"
                                    value={defaultView[0].view}
                                    exclusive
                                    onChange={(_, newValue) => {
                                        setChanged(true);
                                        setDefaultView(
                                            update(defaultView, {
                                                // 0: WORKLIST (based on localstate array - settings.tsx)
                                                0: {
                                                    view: { $set: newValue }, // or adjust according to how `view` is set
                                                },
                                            })
                                        );
                                    }}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton size="small" value={GridViewInput.LIST} aria-label="left aligned">
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                            <Listy />
                                        </div>
                                    </ToggleButton>
                                    <Divider flexItem orientation="vertical" sx={{ m: 0.5 }} />
                                    <ToggleButton size="small" value={GridViewInput.SPLIT} aria-label="right aligned">
                                        <VerticalSplit />
                                    </ToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </div>
                    </div>
                </ListItem>
            </Paper>
        </List>
    );
};

export default TenderViewPreferencesMobile;
