import React from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton } from "@mui/material";
import { People } from "@mui/icons-material";
import TooltipOnNewLines from "../TooltipOnNewLines";
// import { gql, useQuery } from "@apollo/client";
// import { singleTeam, singleTeamVariables } from "../../__generated__/singleTeam";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(): void;
}

/**
 * Display a single notification row for the notification Dialog
 */
const UserAddedToTeam: React.FC<Props> = ({ notification, onClick }) => {
    const grey = "#707070";

    const load = false;

    // const { loading, data: team } = useQuery<singleTeam, singleTeamVariables>(
    //     SINGLE_TEAM,
    //     {
    //         skip: notification === null || notification.data === null,
    //         variables: {
    //             id: notification !== null && notification.data !== null ? (notification.data[0] as string) : "",
    //         },
    //     }
    // );
    // const { loading, data: team } = useQuery<singleTeam, singleTeamVariables>(SINGLE_TEAM, {
    //     skip: notification === null || notification.data === null,
    //     variables: {
    //                     id: notification !== null && notification.data !== null ? (notification.data[0] as string) : "",
    //                 }
    // });

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (load !== false) {
        return (
            <MenuItem style={{ whiteSpace: "normal", maxHeight: 250, opacity: notification.read_at !== null ? 0.5 : undefined }}>
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    return (
        <MenuItem
            onClick={() => onClick()}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: grey }}>
                    <People />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <TooltipOnNewLines
                        placement={"bottom-start"}
                        text={`Je bent toegevoegd aan een team` || ""}
                        variant={"body1"}
                        lines={2}
                        id={`T${notification.id as string}first`}
                        fontweight={notification.read_at === null ? 500 : "normal"}
                        fontSize={14}
                    />
                }
                secondary={
                    <TooltipOnNewLines
                        placement={"top-start"}
                        text={"Bekijk team" || ""}
                        variant={"body1"}
                        lines={1}
                        id={`T${notification.id as string}second`}
                        fontweight={"normal"}
                        fontSize={12}
                    />
                }
            />
        </MenuItem>
    );
};

export default UserAddedToTeam;

// export const SINGLE_TEAM = gql`
//     query singleTeam($id: ID!) {
//         team(id: $id) {
//             id
//             name
//         }
//     }
// `;
