import { Add } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupLogicalOperators } from "./QueryBuilderContext";

interface Props {
    addGroup: (operatorText: "AND" | "NOT" | "OR") => void;
    disabled: boolean;
}

const AddSearchGroup: React.FC<Props> = ({ addGroup, disabled }) => {
    // menu AND | NOT
    const [anchorElquery, setAnchorElquery] = useState<null | HTMLElement>(null);
    const openquery = Boolean(anchorElquery);
    const { t } = useTranslation();
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElquery(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorElquery(null);
    };

    return (
        <>
            <IconButton
                disabled={disabled}
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={handleClickMenu}
                style={{ height: "15px", width: "15px" }}
            >
                <Add sx={{ color: "#ffffff" }} />
            </IconButton>
            {/* ADD NEW GROUP */}
            <Menu open={openquery} onClose={handleCloseMenu} anchorEl={anchorElquery}>
                {GroupLogicalOperators.map((operator) => {
                    return (
                        <MenuItem
                            key={operator.text}
                            value={operator.text}
                            onClick={() => {
                                addGroup(operator.text as "AND" | "NOT" | "OR");
                                handleCloseMenu();
                            }}
                        >
                            <ListItemIcon>{operator.icon}</ListItemIcon>
                            <ListItemText>{t(operator.text)}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default AddSearchGroup;
