import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover, Typography, Box, IconButton, Link, useMediaQuery, useTheme } from "@mui/material";
import KnowledgeBase from "./KnowledgeBase";
import ChatBox from "./ChatBox";
import LaunchIcon from "@mui/icons-material/Launch";
import KnowledgeBaseModal from "./KnowledgeBaseModal";
import { useFaq } from "../contextProviders/FaqOpenerContext";
import MatomoEvent from "../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import Draggable from "react-draggable";
import { Close } from "@mui/icons-material";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

interface Props {
    anchorEl?: Element | null;
    handleClose(): void;
    // State in mainbox with hardcoded timeslots
    supportState: boolean;
}

const UserSupport: React.FC<Props> = ({ anchorEl, handleClose, supportState }) => {
    const theme = useTheme();
    const { trackEvent } = useMatomo();
    const { setView, setOpenFaq, setX, setY, xAxis, yAxis, openChat, setOpenChat, openFaq } = useFaq();

    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const toggleChat = () => {
        setOpenChat(!openChat);
    };
    const [dragging, setDragging] = useState<boolean>(false);
    const language = localStorage.getItem("i18nextLng");
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));
    const loc = useLocation();

    /**
     * Open knowledgebaseitem in expandview based on url params
     */
    if (window.location.search && !openModal) {
        const query = queryString.parse(window.location.search);
        // Open chat via url or open chat + conversation via url
        if (typeof query.kbi === "string" && query.kbi === "small") {
            setView("start");
            setOpenModal(false);
            setOpenFaq(true);

            window.history.replaceState({}, "", loc.pathname);
        } else if (typeof query.kbi === "string") {
            // if kbi = 123small => replace small for "" and open item in small view
            setView(query.kbi.replace("small", ""));
            setOpenModal(!query.kbi.includes("small"));
            setOpenFaq(query.kbi.includes("small"));

            window.history.replaceState({}, "", loc.pathname);
        }
    }

    /**
     * Track help items
     */
    const trackHelp = () => {
        if (openChat) {
            trackEvent({ category: "Help", action: "Click-from-support-send-message" } as MatomoEvent);
        }
        if (!openChat) {
            trackEvent({ category: "Help", action: "Click-from-send-message-to-support" } as MatomoEvent);
        }
    };

    /**
     * Close knowledgebase on button click
     * close knowledgebase and set view back to "start"
     * reset x and y values to 0
     */
    const closeKnowledgeBase = () => {
        handleClose();
        // wait 200ms to prevent reset while knowledgebase is closing
        setTimeout(() => {
            // set view back to start
            setView("start");
            // reset x and y numbers to 0
            setX(0);
            setY(0);
        }, 200);
    };

    /**
     * Handle stop => set X and Y axis number
     * @param _event event
     * @param dragElement x and y number
     */
    const handleStop = (_event: any, dragElement: any) => {
        setDragging(false);
        setX(dragElement.x);
        setY(dragElement.y);
    };

    // useEffect(() => {
    //     const elmnt = document.getElementById("paperelement");
    //     let text = "";
    //     text += "Height with padding: " + elmnt?.clientHeight + "px\n";
    //     text += "Height with padding, border and scrollbar: " + elmnt?.offsetHeight + "px\n";
    //     text += "Width with padding: " + elmnt?.clientWidth + "px\n";
    //     text += "Width with padding, border and scrollbar: " + elmnt?.offsetWidth + "px";
    //     console.log(text);
    // }, []);

    return (
        <Draggable
            cancel="strong"
            // id of draggable item
            handle="#grabber"
            // x and y numbers to position knowledgebase
            position={{ x: xAxis, y: yAxis }}
            onStop={handleStop}
            onDrag={() => {
                setDragging(true);
            }}
        >
            <Popover
                id="popoverdiv"
                // -- Properties to create a draggable popover with interactive backdrop --
                hideBackdrop
                disableEnforceFocus
                disableScrollLock
                // -- End of draggable props --
                anchorEl={anchorEl}
                keepMounted
                open={openFaq && Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: mobileOnly ? "bottom" : "center",
                    horizontal: mobileOnly ? "center" : 70,
                }}
                transformOrigin={{
                    vertical: mobileOnly ? -20 : "top",
                    horizontal: mobileOnly ? "center" : "left",
                }}
                // Width and height for chat/knowledge base.
                slotProps={{
                    paper: {
                        id: "paperelement",
                        style: { width: "500px", height: "640px", pointerEvents: "auto", resize: "both", overflow: "auto", zIndex: 99 },
                    },
                }}
                sx={{
                    pointerEvents: "none",
                    cursor: dragging ? "grabbing" : "grab",
                }}
            >
                <div id="grabber">
                    <div style={{ padding: "22px 16px 4px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {/* User can see which option is open*/}
                        {/* When language is dutch, english, german, chatoption is available. */}
                        {language === "nl" || language === "nl-NL" || language === "en-US" || language === "en" || language === "de" ? (
                            // chatoption available with title change.
                            <Typography variant="h3" style={{ fontSize: 18, fontWeight: 500 }}>
                                {openChat && supportState ? (
                                    t("faq.Support")
                                ) : openChat && !supportState ? (
                                    t("faq.Support")
                                ) : (
                                    <>
                                        {t("faq.Knowledgebase")}{" "}
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenFaq(false);
                                                setOpenModal(!openModal);
                                                trackEvent({ category: "Help", action: "Click-expand-knowledgebase" } as MatomoEvent);
                                            }}
                                        >
                                            <LaunchIcon fontSize={"small"} />
                                        </IconButton>
                                    </>
                                )}
                            </Typography>
                        ) : (
                            // Show this component if language is not nl/en/de.
                            <Typography variant="h3" style={{ fontSize: 18, fontWeight: 500 }}>
                                {openChat ? (
                                    t("faq.Send-message")
                                ) : (
                                    <>
                                        {t("faq.Knowledgebase")}{" "}
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenFaq(false);
                                                setOpenModal(!openModal);
                                                trackEvent({ category: "Help", action: "Click-expand-knowledgebase" } as MatomoEvent);
                                            }}
                                        >
                                            <LaunchIcon fontSize={"small"} />
                                        </IconButton>
                                    </>
                                )}
                            </Typography>
                        )}

                        {/* Show chatoption if language is nl/en */}
                        {/* {language === "nl" || language === "nl-NL" || language === "en-US" || language === "en" ? (
                    <Link component="button" variant="body2" onClick={() => toggleChat()}>
                        {!openChat && supportState
                            ? t("faq.Chat-with-us")
                            : !openChat && !supportState
                            ? t("faq.Send-message")
                            : t("faq.Knowledgebase")}
                    </Link>
                ) : ( */}
                        {/* // if language is not en/ne, show send message only */}
                        <div>
                            <Link
                                underline="none"
                                style={{ marginRight: 4 }}
                                component="button"
                                variant="body2"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    toggleChat();
                                    trackHelp();
                                }}
                            >
                                {!openChat ? t("faq.Support") : t("faq.Knowledgebase")}
                            </Link>
                            {/* Close button  */}
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    closeKnowledgeBase();
                                }}
                                size="small"
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </div>
                        {/* )} */}
                    </div>

                    {/*     box that contains chatcomponent and knowledgebase component
                     *      Shows chatoption if language is en/ne
                     */}
                    {language === "nl" || language === "nl-NL" || language === "en-US" || language === "en" || language === "de" ? (
                        // Chatoption available based on supportstate
                        <Box>{!openChat ? <KnowledgeBase /> : <ChatBox online={supportState} />}</Box>
                    ) : (
                        // Chatoption is set to false.
                        <Box>{!openChat ? <KnowledgeBase /> : <ChatBox online={false} />}</Box>
                    )}
                    <KnowledgeBaseModal
                        handleClose={() => {
                            setOpenModal(!openModal);
                            setView("start");
                        }}
                        open={openModal}
                    />
                </div>
            </Popover>
        </Draggable>
    );
};

export default UserSupport;
