import { CssBaseline } from "@mui/material";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { styled } from "@mui/material/styles";

import CustomDrawer from "./CustomDrawer";
import SortableGrid from "./SortableGrid";
import { useRowsContext } from "./RowsContext";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";

interface Props {
    a?: number;
}
const drawerWidth = 400;

export interface Column {
    id: number;
    widgetId: number;
}

export interface Row {
    id: number;
    columns: Column[];
}

const DashboardBasePlate: React.FC<Props> = (props) => {
    const { open, setOpen, widgets, loading } = useRowsContext();

    if (loading) {
        return <FullPageSpinner loadingText="Dashboard ophalen" />;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <CssBaseline />

            {/* Dashboard */}
            <Main open={open}>
                <SortableGrid editMode={open} />
            </Main>
            {/* Drawer rightside */}
            <CustomDrawer drawerWidth={drawerWidth} onDrawerClose={() => setOpen(false)} open={open} widgets={widgets} />
        </DndProvider>
    );
};

export default DashboardBasePlate;

export const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    margin: 0,
    maxWidth: "100%",
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative",
}));
