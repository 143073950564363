import React from "react";
import Tooltip from "@mui/material/Tooltip";
import AvatarGroup from "@mui/lab/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GetTenderLikes, GetTenderLikesVariables } from "../__generated__/GetTenderLikes";
import { QUERY_TENDERLIKED } from "../graphql/queryDefinitions";

interface Props {
    tender_id: string;
    placement?:
        | "bottom"
        | "left"
        | "right"
        | "top"
        | "bottom-end"
        | "bottom-start"
        | "left-end"
        | "left-start"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | undefined;
    justifyContent?: string;
}

const AvatarReviewed: React.FC<Props> = ({ tender_id, placement, justifyContent = "flex-start" }) => {
    const { t } = useTranslation();

    const { data } = useQuery<GetTenderLikes, GetTenderLikesVariables>(QUERY_TENDERLIKED, { variables: { id: tender_id } });

    if (!data) return <></>;

    return (
        <div style={{ width: "120px", display: "flex", justifyContent: justifyContent }}>
            <Tooltip
                placement={placement ? placement : "left"}
                title={
                    <React.Fragment>
                        {/* Show 'interesting:' when at least 1 user has liked the tender */}
                        {data.tender?.teamLikes && data.tender?.teamLikes?.length > 0 && (
                            <div style={{ fontWeight: "bold" }}>{t("interesting")}:</div>
                        )}
                        {/* Map all users that liked this tender */}
                        {data.tender?.teamLikes &&
                            data.tender?.teamLikes?.map((l) => {
                                return <div key={l?.id}>{l?.employee.name}</div>;
                            })}

                        {/* Show 'not interesting:' when at least 1 user has disliked the tender */}
                        {data.tender?.teamDislikes && data.tender?.teamDislikes?.length > 0 && (
                            <div style={{ fontWeight: "bold", marginTop: 2 }}>{t("not-interesting")}:</div>
                        )}
                        {/* Map all users that disliked this tender */}
                        {data.tender?.teamDislikes?.map((l) => {
                            return <div key={l?.id}>{l?.employee.name}</div>;
                        })}
                    </React.Fragment>
                }
            >
                <AvatarGroup
                    max={4}
                    sx={{
                        "& .MuiAvatar-root": {
                            border: "1px solid #ffffff",
                            width: (theme) => theme.spacing(3.5),
                            height: (theme) => theme.spacing(3.5),
                            fontSize: "0.8rem",
                        },
                    }}
                >
                    {/* Map avatar of users that liked tender */}
                    {data.tender?.teamLikes?.map((l) => {
                        return (
                            <Avatar key={l?.id}>
                                {l?.employee?.name?.slice(0, 1)}
                                {l?.employee?.familyname?.slice(0, 1)}
                            </Avatar>
                        );
                    })}
                    {/* Map avatar of users that disliked tender */}
                    {data.tender?.teamDislikes?.map((l) => {
                        return (
                            <Avatar key={l?.id}>
                                {l?.employee?.name?.slice(0, 1)}
                                {l?.employee?.familyname?.slice(0, 1)}
                            </Avatar>
                        );
                    })}
                </AvatarGroup>
            </Tooltip>
        </div>
    );
};

export default AvatarReviewed;
