import React, { useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, IconButton, Typography } from "@mui/material";
import { FindInPage } from "@mui/icons-material";
import { GetUserSearchRules_currentUser_opportunitySearches } from "../../../../__generated__/GetUserSearchRules";
import { useTranslation } from "react-i18next";
import OppSearchLineSummarySentenceMobile from "./OppSearchLineSummarySentenceMobile";

interface Props {
    searchline: GetUserSearchRules_currentUser_opportunitySearches;
    type: "opportunity" | "tender";
}

const OppMobileViewSearch: React.FC<Props> = ({ searchline, type }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const green = "#225E4D";
    const lightgreen = "#508c79";

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card elevation={0} square sx={{ m: 1, borderTop: `5px solid ${green}` }}>
            <CardHeader
                avatar={
                    <IconButton size="small">
                        <FindInPage sx={{ color: green }} />
                    </IconButton>
                }
                title={searchline.name}
            />
            <CardContent sx={{ padding: "0px 16px 8px" }}>
                <Typography variant="body2" color="text.secondary">
                    <OppSearchLineSummarySentenceMobile searchline={searchline} />
                </Typography>
            </CardContent>
            <CardActions disableSpacing sx={{ padding: "8px 16px", display: "flex", justifyContent: "flex-end" }}>
                <Button size="small" onClick={handleExpandClick}>
                    {expanded ? "Verberg bezorging" : "Toon bezorging"}
                </Button>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{ padding: "0px 16px 8px" }}>
                    <Typography>Bezorging:</Typography>
                    <Typography variant="body1">
                        <span style={{ color: lightgreen, fontWeight: 500, marginRight: 4 }}>{t("AT_PUBLICATION_OPP" as string)}</span>
                        alle resultaten tonen in werklijst
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default OppMobileViewSearch;
