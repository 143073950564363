import moment from "moment";
import { GetColleagues_currentUser_employee_organization_teams } from "../__generated__/GetColleagues";
import { GetConversationGroup_conversationGroup_conversations } from "../__generated__/GetConversationGroup";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../__generated__/GetNotificationsCurrentUser";
import { ContactListItem, ConversationListItem } from "../components/tenderNote/ConversationsList";

/**
 * Determine if the given conversation is private: only containing the current user
 */
export const isPrivateConversation = (conversation: GetConversationGroup_conversationGroup_conversations): boolean => {
    return conversation.users.length === 1 && conversation.users[0].id === localStorage.getItem("user_id");
};

export function getConversationWithLatestMessage(conversations: GetConversationGroup_conversationGroup_conversations[]): string | null {
    let latestConversationId: string | null = null;
    let latestTime: moment.Moment | null = null;

    conversations.forEach((conversation) => {
        conversation.chatMessages.forEach((chatMessage) => {
            const sendAtTime = chatMessage.send_at ? moment(chatMessage.send_at) : null;

            if (sendAtTime && (!latestTime || sendAtTime.isAfter(latestTime))) {
                latestTime = sendAtTime;
                latestConversationId = conversation.id;
            }
        });
    });

    return latestConversationId;
}

/**
 * Determine if the given conversation is private: only containing the current user
 */
export const isNotPrivateConversation = (conversation: GetConversationGroup_conversationGroup_conversations): boolean => {
    return conversation.users.length > 1 && conversation.users[0].id !== localStorage.getItem("user_id");
};

export const userIsNotCu = (contact: ContactListItem): boolean => {
    return contact.id !== localStorage.getItem("user_id");
};

export const userIsCu = (contact: ContactListItem): boolean => {
    return contact.id === localStorage.getItem("user_id");
};

export const userIsTeamMember = (team: GetColleagues_currentUser_employee_organization_teams): boolean => {
    return team.users.some((i) => i.id === (localStorage.getItem("user_id") as string));
};

export const teamChatIsAlreadyInitiated = (team_id: string, conversations: ConversationListItem[]) => {
    return conversations.find((conv) => conv.team_id === team_id)?.id;
};

export const chatIsAlreadyInitiated = (user_id: string, conversations: ConversationListItem[]) => {
    // Get all chats that have 2 people
    const twoPeopleArray = conversations.filter((conv) => conv.userIds.length === 2);
    // Remove all chats that dont have user_id as user
    const getMatch = twoPeopleArray.filter((conv) => conv.userIds.includes(user_id));

    // return undefined
    if (getMatch.length === 0) {
        return undefined;
    }
    // return chat id
    return getMatch[0].id;
};

export const colorOfDialog = (modelType: string): string => {
    return modelType.includes("Tender")
        ? "#173357"
        : modelType.includes("MarketParty")
        ? "#E03660"
        : modelType.includes("ContractingAuthority")
        ? "#F57117"
        : modelType.includes("Opportunity")
        ? "#225E4D"
        : "#707070";
};

export const modelTypeOfNotification = (modelType: string): string => {
    return modelType.includes("Tender")
        ? "App\\Models\\Tender"
        : modelType.includes("MarketParty")
        ? "App\\Models\\MarketParty"
        : modelType.includes("ContractingAuthority")
        ? "App\\Models\\ContractingAuthorityV4"
        : modelType.includes("Opportunity")
        ? "App\\Models\\Opportunity\\File"
        : "";
};

export const notificationIdsWithSameChatId = (chat_id: string, chatMessages: GetNotificationsCurrentUser_currentUser_notifications_data[]) => {
    const arrOfNotIds = chatMessages
        .filter((not) => {
            const ids = not.data ? (not.data[0] as string) : "";

            return chat_id === ids;
        })
        .map((item) => item.id as string);

    return arrOfNotIds;
};

export const notificationsUnread = (chat_id: string, chatMessages: GetNotificationsCurrentUser_currentUser_notifications_data[]) => {
    const amountOfunreadNotifications = chatMessages
        .filter((not) => {
            const ids = not.data ? (not.data[0] as string) : "";
            return chat_id === ids;
        })
        .filter((item) => item.read_at === null).length;

    return amountOfunreadNotifications;
};
