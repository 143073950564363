import React, { useContext } from "react";
import { Avatar, Badge, Box, CardActions, CardContent, Grid, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AccountTree, Groups, Notes, TaskAlt } from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import ConnectedEntitities from "../../projects/columnView/projectcard/ConnectedEntitities";
import { GetAllProjectsDashboard_projects } from "../../../__generated__/GetAllProjectsDashboard";
import MyProjectFaseSelector from "./MyProjectFaseSelector";
import MyProjectFaviconBox from "./MyProjectFaviconBox";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    data: GetAllProjectsDashboard_projects[];
    columnsInRow: number;
}

const MyProjectsWidgetData: React.FC<Props> = ({ data, columnsInRow }) => {
    const { trackEvent } = useMatomo();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const navigate = useNavigate();

    return (
        <Box sx={{ width: "100%", height: { xs: "335px", md: "355px" }, position: "relative", overflow: "auto", padding: "8px" }}>
            {/* Underlaying icon with text  */}
            <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}>
                <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    {<AccountTree style={{ color: "#f2f2f270", height: "9rem", width: "9rem" }} />}
                    <Typography variant="h5" style={{ color: "#f2f2f2" }}>
                        Mijn projecten
                    </Typography>
                </div>
            </div>
            <Grid container spacing={1}>
                {data.map((row) => {
                    // Amount of tasks done in project
                    // const tasksDone = row.tasks
                    //     ? percentage(row.tasks.filter((task) => task.task_completed === true).length, row.tasks.length as number)
                    //     : 0;
                    // Total tasks in project
                    // const totalTasks = row.tasks ? row.tasks.length : 0;

                    /**
                     * Counter to show total amount of tasks bind to current user
                     */
                    const allCuTasks = row.tasks?.filter((task) => task.users?.find((t) => t.id === localStorage.getItem("user_id"))).length || 0;

                    const allCompletedTasks = row?.tasks?.filter((task) => task.task_completed === true).length || 0;

                    return (
                        <Grid item key={row.id} xs={12} sm={columnsInRow === 1 ? 4 : columnsInRow === 2 ? 6 : 12} sx={{ display: "flex" }}>
                            <Paper elevation={0} variant="outlined" sx={{ backgroundColor: "#ffffff", zIndex: 99, flex: 1 }}>
                                <div style={{ margin: "4px 8px" }}>
                                    <ConnectedEntitities project_id={row.id} />
                                </div>
                                <CardContent sx={{ padding: "4px 8px" }}>
                                    <Stack direction="row" justifyContent={"space-between"}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                lineHeight: 1.3,
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                },
                                            }}
                                            onClick={() => {
                                                if (isMobile) {
                                                    navigate(`/projects/${row.id as string}`);
                                                    track({
                                                        category: "Dashboard",
                                                        action: "Click-navigate-to-project-mobile",
                                                        href: window.location.href,
                                                    });
                                                } else {
                                                    changeProject({
                                                        projectID: row.id,
                                                        projectTitle: row.title,
                                                        state: row.state?.id as string,
                                                    });
                                                    track({
                                                        category: "Dashboard",
                                                        action: "Click-open-project-on-dashboard",
                                                        href: window.location.href,
                                                    });
                                                    setOpen(true);
                                                }
                                            }}
                                        >
                                            {row.title}
                                        </Typography>
                                    </Stack>

                                    <MyProjectFaviconBox data={row.contractingAuthorities ?? []} />
                                </CardContent>
                                <CardActions disableSpacing>
                                    <Stack
                                        width={"100%"}
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                        sx={{ margin: "0px" }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <MyProjectFaseSelector currentFase={row.state?.name ?? ""} projectId={row.id} />
                                        </div>
                                        {/*
                                         * Box with small icons and badges
                                         * - badge with amount of users
                                         * - badge with amount of tasks
                                         */}
                                        <Box
                                            sx={{
                                                color: "#9e9e9e",
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                justifyContent: "flex-end",
                                                marginBottom: "4px",
                                                marginTop: "12px",
                                            }}
                                        >
                                            {/* Amount of users in project */}
                                            <Tooltip
                                                disableInteractive
                                                title={
                                                    <>
                                                        {row.users?.map((i) => {
                                                            return (
                                                                <>
                                                                    <span>{i.employee.name}</span>
                                                                    <br />
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                }
                                                placement="top"
                                            >
                                                {row.users && row.users.length === 1 ? (
                                                    <Avatar
                                                        sx={{
                                                            height: "18px",
                                                            width: "18px",
                                                            fontSize: "9px",
                                                            marginRight: "12px",
                                                        }}
                                                    >
                                                        {row.users[0].employee.name
                                                            ?.match(/\b(\w)/g)
                                                            ?.slice(0, 2)
                                                            .join("") || ""}
                                                    </Avatar>
                                                ) : (
                                                    <Badge
                                                        sx={{
                                                            cursor: "pointer",
                                                            marginRight: "12px",
                                                            "& .MuiBadge-badge": {
                                                                right: -2,
                                                                top: 2,
                                                                border: `2px solid #fff`,
                                                                padding: "0 4px",
                                                                fontSize: "9px",
                                                                minWidth: "18px",
                                                                height: "18px",
                                                            },
                                                        }}
                                                        badgeContent={row.amountUsers}
                                                        color="primary"
                                                    >
                                                        <Groups fontSize="small" />
                                                    </Badge>
                                                )}
                                            </Tooltip>

                                            {/* Amount of tasks in project */}
                                            <Tooltip
                                                disableInteractive
                                                placement="top"
                                                title={
                                                    <>
                                                        <>Taken totaal: {row.amountTasks}</>
                                                        <br />
                                                        <>Taken voltooid: {allCompletedTasks}</>
                                                        <br />
                                                        <>Mijn taken: {allCuTasks}</>
                                                    </>
                                                }
                                            >
                                                <Badge
                                                    sx={{
                                                        cursor: "pointer",
                                                        marginRight: "12px",
                                                        "& .MuiBadge-badge": {
                                                            right: -2,
                                                            top: 2,
                                                            border: `2px solid #fff`,
                                                            padding: "0 4px",
                                                            fontSize: "9px",
                                                            minWidth: "18px",
                                                            height: "18px",
                                                        },
                                                    }}
                                                    badgeContent={row.amountTasks}
                                                    color="primary"
                                                >
                                                    <TaskAlt fontSize="small" />
                                                </Badge>
                                            </Tooltip>

                                            {/* Amount of comments in project */}
                                            {row.latestComment === null ? (
                                                <Badge
                                                    sx={{
                                                        cursor: "pointer",
                                                        marginRight: "6px",
                                                        "& .MuiBadge-badge": {
                                                            right: -2,
                                                            top: 2,
                                                            border: `2px solid #fff`,
                                                            padding: "0 4px",
                                                            fontSize: "9px",
                                                            minWidth: "18px",
                                                            height: "18px",
                                                            width: "18px",
                                                        },
                                                    }}
                                                    badgeContent={row.amountNotes}
                                                    color="primary"
                                                >
                                                    <Notes fontSize="small" />
                                                </Badge>
                                            ) : (
                                                <Tooltip
                                                    disableInteractive
                                                    placement="top"
                                                    title={
                                                        <>
                                                            <>Notities: {row.amountNotes}</>
                                                            <br />

                                                            <>Nieuwste: {moment(row.latestComment.created_at).format("LLL")}</>
                                                        </>
                                                    }
                                                >
                                                    <Badge
                                                        sx={{
                                                            cursor: "pointer",
                                                            marginRight: "6px",
                                                            "& .MuiBadge-badge": {
                                                                right: -2,
                                                                top: 2,
                                                                border: `2px solid #fff`,
                                                                padding: "0 4px",
                                                                fontSize: "9px",
                                                                minWidth: "18px",
                                                                height: "18px",
                                                            },
                                                        }}
                                                        badgeContent={row.amountNotes}
                                                        color="primary"
                                                    >
                                                        <Notes fontSize="small" />
                                                    </Badge>
                                                </Tooltip>
                                            )}
                                        </Box>
                                        {/* <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                                    <ExpandMoreIcon />
                                </ExpandMore> */}
                                    </Stack>
                                </CardActions>
                            </Paper>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default MyProjectsWidgetData;
