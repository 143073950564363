import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { useTranslation } from "react-i18next";
import FaviconBox from "../FaviconBox";
import { GetStarredCas_currentUser_contracting_authorities } from "../../__generated__/GetStarredCas";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import TooltipOnNewLines from "../TooltipOnNewLines";

interface Props {
    others: Record<string, React.ReactNode>[];
    starredCAS: GetStarredCas_currentUser_contracting_authorities[];
}

const SecondCA: React.FC<Props> = ({ others, starredCAS }) => {
    const theme = useTheme();
    const starColor = "#9e9e9e";
    const orange = "#F57117";
    const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Typography variant="body1" style={{ marginTop: 4, marginBottom: 6, display: "flex", alignItems: "center" }}>
                {t("tenderPage.AlsoOnBehalfOf")} {others.length} {t("tenderPage.others")}:
            </Typography>
            <div style={{ maxHeight: 150, overflow: "auto", paddingRight: others.length > 2 ? 11 : 16 }}>
                {others.map((ca: any, i) => (
                    <React.Fragment key={ca.id}>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
                            <FaviconBox color={orange} favicon={ca.favicon_resized ?? ca.favicon} name={ca.name} marginTop={0} />
                            <Box
                                sx={{
                                    flex: 1,
                                    width: "80%",
                                    margin: onlySmallScreen ? "0px 10px" : undefined,
                                }}
                            >
                                <ul
                                    style={{
                                        listStyle: "none",
                                        margin: 0,
                                        padding: 0,
                                    }}
                                >
                                    <li>
                                        <Link
                                            to={`/contracting-authorities/${ca.id}`}
                                            style={{ display: "inline-flex", width: "100%", justifyItems: "center", alignItems: "center" }}
                                        >
                                            <TooltipOnNewLines
                                                placement={"top-start"}
                                                text={ca.name}
                                                variant={"body1"}
                                                lines={1}
                                                id={`T${ca.id as string}`}
                                                fontweight={"normal"}
                                                fontSize={14}
                                            />
                                            {starredCAS.find((s) => s.id === ca.id) && (
                                                <StarIcon style={{ color: `${starColor}`, marginLeft: 4, fontSize: "1rem" }} />
                                            )}
                                        </Link>
                                        {ca.lot && (
                                            <Typography sx={{ color: (theme) => `${"#000000"}8a` }}>
                                                {t("Lot")} {`${ca.lot}`}
                                            </Typography>
                                        )}
                                    </li>
                                </ul>
                            </Box>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    );
};

export default SecondCA;
