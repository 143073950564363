import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import TopLinksModule from "../../../../components/organizationComponents/TopLinksModule";
import OrganizationDetails from "../../../../components/organizationComponents/OrganizationDetails";
import OrganizationContactInfo from "../../../../components/organizationComponents/OrganizationContactInfo";
import OrganizationSocials from "../../../../components/organizationComponents/OrganizationSocials";

import FullPageSpinner from "../../../../components/loading/FullPageSpinner";
import { useQuery } from "@apollo/client";

// import ReadMoreComponent from "../../../../components/ReadMoreComponent";
import { GetCAGeneralInfo, GetCAGeneralInfoVariables } from "../../../../__generated__/GetCAGeneralInfo";
import RenderPage from "../../../../components/loading/RenderPage";
import TopFiveTendersIn from "./TopFiveTendersIn";
import TopFiveMostAwardedTo from "./TopFiveMostAwardedTo";
import CaNames from "./CaNames";
import { QUERY_CA_GENERAL_INFO } from "../../../../graphql/queryDefCA";

// Version 2 component
// import MostActiveWidget from "./MostActiveWidget";

interface Props {
    id: string;
}

const GeneralInfoCAS: React.FC<Props> = ({ id }) => {
    const { loading, error, data } = useQuery<GetCAGeneralInfo, GetCAGeneralInfoVariables>(QUERY_CA_GENERAL_INFO, { variables: { id } });
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (loading) {
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    // Errormessage. TODO: fancy error page
    if (error || !data?.contracting_authority) {
        return (
            <RenderPage>
                <p>Fout tijdens laden van Aanbestedende dienst</p>
            </RenderPage>
        );
    }

    const insta = data.contracting_authority.urls.filter((item) => item.type === "INSTAGRAM").length;
    const fb = data.contracting_authority.urls.filter((item) => item.type === "FACEBOOK").length;
    const linkedin = data.contracting_authority.urls.filter((item) => item.type === "LINKEDIN").length;
    const youtube = data.contracting_authority.urls.filter((item) => item.type === "YOUTUBE").length;
    const twitter = data.contracting_authority.urls.filter((item) => item.type === "TWITTER").length;
    const pinterest = data.contracting_authority.urls.filter((item) => item.type === "PINTEREST").length;
    const socialLength = insta + fb + linkedin + youtube + twitter + pinterest;

    return (
        <React.Fragment>
            <Grid container xs={12} style={{ padding: 24 }}>
                {/* LEFT SECTION */}
                <Grid item container xs={12} md={2} direction="column">
                    <Grid style={{ marginBottom: 12, maxWidth: "100%" }}>
                        <OrganizationDetails
                            details={data.contracting_authority.settlings ? data.contracting_authority.settlings.data : undefined}
                            logo={data.contracting_authority.logo || undefined}
                        />
                    </Grid>
                    <Grid style={{ width: "100%" }}>
                        <OrganizationContactInfo
                            phonenumbers={data.contracting_authority.phonenumbers}
                            urls={data.contracting_authority.urls}
                            emails={data.contracting_authority.emails}
                            postal_adresses={data.contracting_authority.postal_addresses}
                        />

                        {socialLength > 0 && <OrganizationSocials urls={data.contracting_authority.urls} />}
                    </Grid>
                </Grid>

                {/* MIDDLE SECTION */}
                <Grid item container xs={12} md={6} sx={{ paddingLeft: onlyMobile ? "0px" : "24px" }}>
                    {/* <Grid item xs={12} style={{ marginBottom: 16 }} sx={{        padding: onlyMobile? :'0px': "0 16px",
        margin: onlyMobile ? "20px auto 0px" :  "0 auto",
        maxHeight: '300px',
        height: onlyMobile ? '246px' :undefined,
        overflow: "auto",}}>
                        <div>
                            <TopLinksModule Title="Omschrijving">
                                <Typography>
                                    <ReadMoreComponent
                                        text={
                                            "Landerd en Uden willen samen één Maashorstgemeente zijn. Een groene gemeente met een stevige ambitie qua dienstverlening, voorzieningen en werkgelegenheid en met sterke kernen rondom natuurgebied De Maashorst. Een gemeente die er toe doet in de regio, die iets te vertellen heeft en zo goed voor haar inwoners kan zorgen. Een gemeente met zes krachtige kernen en een sterke identiteit. Gelegen in en om natuurgebied De Maashorst kan ze zich met recht hart en longen van Noordoost Brabant noemen. Bewoners uit de kernen worden betrokken bij het herindelingsontwerp. Zo ontstaat een gemeente die recht doet aan haar inwoners. Een gemeente waar mensen zich samen inzetten voor goed wonen, werken en recreëren. Waar inwoners, ondernemers, verenigingen en maatschappelijke organisaties de ruimte en kans krijgen om hun omgeving nog fijner te maken. De gemeenteraden van Landerd en van Uden hebben het plan van aanpak voor de vorming van een nieuwe Maashorstgemeente vastgesteld. Dit betekent dat zij vanaf nu samen gaan toewerken naar de definitieve herindeling. Vanaf 1 januari 2022 vormen de gemeente Uden en Landerd samen de nieuwe gemeente Maashorst."
                                        }
                                        length={500}
                                        variant={"body2"}
                                    />
                                </Typography>
                                <Typography>
                                    <Link className={classes.link} to="#">
                                        Bron: deba.nl/over-ons
                                    </Link>
                                </Typography>
                            </TopLinksModule>
                        </div>
                    </Grid> */}

                    <Grid item xs={12} md={6} direction="column">
                        {/*
                         * Top 5 sectors this CA is active in
                         */}
                        <div style={{ paddingBottom: "20px", padding: onlyMobile ? "0px" : "0 16px", listStyle: "none", margin: "0 auto" }}>
                            <TopFiveTendersIn ca_id={id} />
                        </div>
                        {/*
                         * Top 5 tenderers who won a tender of this CA
                         */}
                        <div style={{ padding: onlyMobile ? "0px" : "0 16px", listStyle: "none", margin: onlyMobile ? "0 auto" : "16px auto" }}>
                            <TopFiveMostAwardedTo ca_id={id} />
                        </div>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        direction="column"
                        sx={{
                            marginBottom: onlyMobile ? "20px" : "0px",
                            marginTop: onlyMobile ? "20px" : "0px",
                            paddingLeft: onlyMobile ? "0px" : "16px",
                        }}
                    >
                        {/* <div style={{ listStyle: "none", paddingBottom: 20 }}>
                            <TopLinksModule Title="hoogste concernrelatie">
                                <div style={{ listStyle: "none" }}>
                                    <li>Deba Voertuigtechniek B.V.</li>
                                </div>
                            </TopLinksModule>
                        </div> */}

                        {/*
                         * All names of current ca
                         * active and inactive names
                         */}
                        <CaNames ca_id={id} />

                        {/*
                         * Extra information about the CA
                         * For now its organization number only
                         */}
                        <div style={{ listStyle: "none" }}>
                            <TopLinksModule Title="kerngegevens">
                                <div style={{ listStyle: "none" }}>
                                    {/* Kvk */}

                                    {data.contracting_authority.kvknumber !== null && (
                                        <li>Organisatienummer {data.contracting_authority.kvknumber}</li>
                                    )}
                                    {/* Type CA */}
                                    {data.contracting_authority.types?.map((type, i) => {
                                        return <li key={i}>{type}</li>;
                                    })}

                                    {/* <li>Besloten vennootschap (B.V.)</li>

                                    <li>Opgericht 28 april 1987</li>

                                    <li>Beeïndigd 22 december 2020</li> */}
                                </div>
                            </TopLinksModule>
                        </div>
                    </Grid>
                </Grid>

                {/* RIGHT SECTION */}
                <Grid item container xs={12} md={4} sx={{ paddingLeft: onlyMobile ? "0px" : "24px", maxHeight: onlyMobile ? "500px" : undefined }}>
                    <Grid item xs={12}>
                        {/* Organization mapview
                       //TODO: 4.x  add lat/lon
                       */}
                        {/* <ContractingAuthAddress /> */}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default GeneralInfoCAS;
