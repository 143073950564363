import { TFunction } from "i18next";

const setTitle = (title: string): void => {
    document.title = `${title} - TenderGuide`;
};

export default setTitle;

export const setTitleLoading = (t?: TFunction) => {
    if (t) {
        setTitle(t("loading"));
    } else {
        setTitle("Laden");
    }
};
