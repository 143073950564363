import React, { useContext } from "react";

import {
    Avatar,
    Paper,
    Tooltip,
    TooltipProps,
    Link as Linkie,
    Typography,
    styled,
    tooltipClasses,
    useMediaQuery,
    useTheme,
    // Tooltip,
    //  IconButton,
    //   CircularProgress
} from "@mui/material";
import { AccountTree } from "@mui/icons-material";

import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineItem as MuiTimelineItem,
} from "@mui/lab";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";

// import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import ReadOnlyTask from "./ReadOnlyTask";
import { Priority } from "../../../../__generated__/globalTypes";
// import LogMapperModal from "../LogMapperModal";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import {
    GetUserLogsWithFilters_activities_data,
    GetUserLogsWithFilters_activities_data_subject_UserTask,
} from "../../../../__generated__/GetUserLogsWithFilters";
import { Link } from "react-router-dom";
// import LogModal from "../LogModal";

interface Props {
    item: any;
    log: GetUserLogsWithFilters_activities_data;
}

/**
 * This is used for the advanced search
 * timeline component adds this styling automatically,
 * Now its overruled so its aligned on the left side.
 */
const TimelineItem = withStyles(MuiTimelineItem, {
    missingOppositeContent: {
        "&:before": {
            display: "none",
        },
    },
    content: {
        flex: 20,
    },
    oppositeContent: {
        flex: 3,
    },
});

const StyledLink = styled(Link)`
    cursor: pointer;
    color: #000000;
    text-decoration: underline;
    &:hover {
        background-color: #0000001a;
    }
`;

const StyledLinkTyp = styled(Linkie)`
    cursor: pointer;
    color: #000000;
    text-decoration: underline;
    &:hover {
        background-color: #0000001a;
    }
`;

const UserTaskLog: React.FC<Props> = ({ log, item }) => {
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);

    // const [open, setOpenModal] = useState(false);
    // const handleOpen = () => setOpenModal(true);
    // const handleClose = () => setOpenModal(false);

    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // DELETED TASK LOG
    if (log.action === "deleted") {
        return (
            <>
                {isMobile ? (
                    <Paper variant="outlined" sx={{ padding: "8px", marginTop: "8px", display: "flex", alignItems: "center" }}>
                        <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                            <AccountTree />
                        </Avatar>
                        <div>
                            {/* TEXT FOR EVERY ITEM DEFINED */}
                            <Typography>
                                <span>
                                    {log?.causer?.employee.givenname} {t("has")} taak
                                </span>{" "}
                                {/*
                                 * Link to page
                                 * map item name -> searchrulename
                                 */}
                                {log.description !== null && <span>{t(`log.${log.description as string}`)}</span>}
                            </Typography>
                            {/* TIME */}
                            <Typography variant="caption" color="textSecondary">
                                {moment(log.created_at).format("LL")}, {moment(log.created_at).format("LT")}
                            </Typography>
                        </div>
                    </Paper>
                ) : (
                    <TimelineItem>
                        <TimelineOppositeContent sx={{ flex: 3 }}>
                            <Typography variant="body2" color="textSecondary">
                                {moment(log.created_at).format("LL")}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {moment(log.created_at).format("LT")}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                                <AccountTree />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ flex: 20 }}>
                            <Paper elevation={1} sx={{ padding: "6px 16px", marginTop: "3px" }}>
                                <Typography style={{ display: "inline-block" }}>
                                    <span>
                                        {log?.causer?.employee.givenname} {t("has")} taak
                                    </span>{" "}
                                    {/*
                                     * Link to page
                                     * map item name -> searchrulename
                                     */}
                                    {log.description !== null && <span>{t(`log.${log.description as string}`)}</span>}
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                )}
            </>
        );
    }

    // const parsed_value = JSON.parse(log.properties);
    const task = log.subject as GetUserLogsWithFilters_activities_data_subject_UserTask;

    if (task === null) {
        return <></>;
    }

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip arrow {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.arrow}`]: {
                color: theme.palette.common.white,
                "&::before": {
                    backgroundColor: theme.palette.common.white,
                    border: "1px solid #fff",
                },
            },
            [`& .${tooltipClasses.tooltip}`]: {
                display: "flex",
                backgroundColor: theme.palette.common.white,
                color: "rgba(0, 0, 0, 0.87)",
                padding: "8px",
                boxShadow: theme.shadows[1],
                fontSize: 13,
                width: "100%",
                minWidth: 600,
                maxWidth: "none",
            },
        })
    );

    return (
        <>
            {isMobile ? (
                <Paper variant="outlined" sx={{ padding: "8px", marginTop: "3px", display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                        <AccountTree />
                    </Avatar>
                    <div>
                        <Typography>
                            <span>
                                {log?.causer?.employee.givenname} {t("has")}
                                {/* {checkChanges(log.properties, log.description as string)}  */}
                                {/* {log.description as string} */} taak
                            </span>{" "}
                            {/*
                             * Link to page
                             * map item name -> searchrulename
                             */}
                            <StyledLink to={`/projects/${task.project?.id}`}>{task.task_description}</StyledLink>{" "}
                            {log.description !== null && <span>{t(`log.${log.description as string}`)}</span>}
                        </Typography>

                        {/* TIME */}
                        <Typography variant="caption" color="textSecondary">
                            {moment(log.created_at).format("LL")}, {moment(log.created_at).format("LT")}
                        </Typography>
                    </div>
                </Paper>
            ) : (
                <TimelineItem>
                    <TimelineOppositeContent sx={{ flex: 3 }}>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LL")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LT")}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                            <AccountTree />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ flex: 20 }}>
                        <Paper elevation={1} sx={{ padding: "6px 16px", marginTop: "3px" }}>
                            <Typography style={{ display: "inline-block" }}>
                                <span>
                                    {log?.causer?.employee.givenname} {t("has")}
                                    {/* {checkChanges(log.properties, log.description as string)}  */}
                                    {/* {log.description as string} */} taak
                                </span>{" "}
                                {/*
                                 * Link to page
                                 * map item name -> searchrulename
                                 */}
                                <LightTooltip
                                    arrow
                                    placement="top-start"
                                    title={
                                        <>
                                            <ReadOnlyTask
                                                completed={task.task_completed}
                                                description={task.task_description}
                                                deadline={task.task_deadline}
                                                users={task.users}
                                                id={task.id}
                                                priority={task.task_priority as Priority}
                                            />
                                        </>
                                    }
                                >
                                    <StyledLinkTyp
                                        onClick={() => {
                                            changeProject({
                                                projectID: task.project?.id,
                                                projectTitle: task.project?.title,
                                                state: task.project?.state?.id,
                                            });
                                            setOpen(true);
                                        }}
                                    >
                                        {task.task_description}
                                    </StyledLinkTyp>
                                </LightTooltip>{" "}
                                {log.description !== null && <span>{t(`log.${log.description as string}`)}</span>}
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            )}
        </>
    );
};

export default UserTaskLog;
