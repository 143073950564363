import React from "react";
import AwardInfoPublished from "../../ContractorBidModule/AwardInfoPublished";
import AwardInfoEnriched from "../../ContractorBidModule/AwardInfoEnriched";
import { QUERY_AWARD_INFORMATION_FOR_CURRENT_TENDER } from "../../../graphql/queryDefinitions";
import { useQuery } from "@apollo/client";
import { GetAwardInfoForCurrentTender, GetAwardInfoForCurrentTenderVariables } from "../../../__generated__/GetAwardInfoForCurrentTender";
import { Disable } from "react-disable";
import WidgetBox from "../../WidgetBox";
import { useTranslation } from "react-i18next";

interface Props {
    tender_id: string;
    highlight?: boolean;
    title?: boolean;
}

const AwardInformationWidget: React.FC<Props> = ({ tender_id, highlight, title }) => {
    const { t } = useTranslation();

    const { loading, error, data } = useQuery<GetAwardInfoForCurrentTender, GetAwardInfoForCurrentTenderVariables>(
        QUERY_AWARD_INFORMATION_FOR_CURRENT_TENDER,
        {
            variables: { id: tender_id },
        }
    );

    if (loading)
        return (
            <Disable disabled={true}>
                <WidgetBox
                    loading={true}
                    header={title === false ? "" : t("tenderPage.AwardInformation")}
                    highlight={highlight === false ? undefined : "pink"}
                    padding={0}
                />
            </Disable>
        );
    if (error || !data || !data.tender) {
        return (
            <Disable disabled={true}>
                <WidgetBox
                    header={title === false ? "" : t("tenderPage.AwardInformation")}
                    highlight={highlight === false ? undefined : "pink"}
                    padding={0}
                />
            </Disable>
        );
    }

    /**
     * Shorter constant to use data
     */
    const tender = data.tender;

    // if (tender.status === "EXPIRED") {
    //     return (
    //         <>
    //             <Disable disabled={true}>
    //                 <WidgetBox
    //                     header={title === false ? "" : t("tenderPage.AwardInformation")}
    //                     highlight={highlight === false ? undefined : "pink"}
    //                     padding={0}
    //                     mb={0}
    //                 />
    //             </Disable>
    //             <Paper square style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "8px", marginBottom: "20px" }}>
    //                 <Button onClick={() => setOpenModal(true)}>Deel gunningsinformatie</Button>
    //                 <InfoButtonTooltip
    //                     tooltipText={
    //                         "Door informatie met ons te delen, kunnen wij deze informatie verwerken. Zo bieden we interessantere statistieken van marktpartijen die jij hebt gemarkeerd met ster"
    //                     }
    //                 />
    //             </Paper>
    //             <AwardInformationDialog onClose={() => setOpenModal(false)} open={openModal} dialogTitle={"Informatie delen"} dialogText={""} />
    //         </>
    //     );
    // }
    return (
        <React.Fragment>
            {tender.status === "PARTICIPANTS_KNOWN" ||
            tender.status === "AWARDED" ||
            tender.status === "PRELIMINARY_AWARDED" ||
            tender.status === "PROCUREMENT_DISCONTINUED" ||
            tender.status === "PROCUREMENT_UNSUCCESSFUL" ? (
                <React.Fragment>
                    {/* award information, show module based on numeric (528 = nl)  */}
                    {tender.bids && tender.bids.length > 0 ? (
                        <AwardInfoEnriched
                            startDate={tender.looptijd_start}
                            bids={tender.bids}
                            lots={tender.lots}
                            awardDate={tender.datum_gunning === "2000-01-01 00:00:00" ? null : tender.datum_gunning}
                            endDate={tender.next_looptijd_einde}
                            status={tender.status}
                            lowest_bid={tender.published_lowest_bid}
                            highest_bid={tender.published_highest_bid}
                            published_participants={tender.published_participants}
                            title={title}
                            highlight={highlight}
                        />
                    ) : (
                        <AwardInfoPublished participants={tender.published_participants} status={tender.status} title={title} highlight={highlight} />
                    )}
                </React.Fragment>
            ) : tender.bids && tender.bids.length > 0 ? (
                <AwardInfoEnriched
                    startDate={tender.looptijd_start}
                    bids={tender.bids}
                    lots={tender.lots}
                    awardDate={tender.datum_gunning === "2000-01-01 00:00:00" ? null : tender.datum_gunning}
                    endDate={tender.next_looptijd_einde}
                    status={tender.status}
                    lowest_bid={tender.published_lowest_bid}
                    highest_bid={tender.published_highest_bid}
                    published_participants={tender.published_participants}
                    title={title}
                    highlight={highlight}
                />
            ) : (
                <Disable disabled={true}>
                    <WidgetBox
                        header={title === false ? "" : t("tenderPage.AwardInformation")}
                        highlight={highlight === false ? undefined : "pink"}
                        padding={0}
                    />
                </Disable>
            )}
        </React.Fragment>
    );
};

export default AwardInformationWidget;
