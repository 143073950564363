import React from "react";
import {
    Typography,
    Badge,
    Box,
    Divider,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    TextField,
    Button,
    Theme,
    useTheme,
    createStyles,
} from "@mui/material";
import { useTranslation } from "react-i18next";

//icons in this component
import { ReactComponent as LogoIcon } from "../../assets/tenderguide-icon.svg";
import SendIcon from "@mui/icons-material/Send";
import { withStyles } from "tss-react/mui";

interface Props {
    a?: number;
}

const chat = [
    { message: "hey", user: "user" },
    { message: "Hoe kan ik een inactieve gebruiker verwijderen?", user: "user" },
    { message: "Op de gebruikers & teams pagina is het mogelijk om een gebruiker te verwijderen", user: "support" },
    { message: "Dankjewel", user: "user" },
    { message: "Kan ik je nog ergens anders mee helpen?", user: "support" },
];

//Styling for badge, visual feedback if support is online or not
const StyledBadge = withStyles(Badge, (theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: "#508c79",
            boxShadow: `0 0 0 2px ${"#ffffff"}`,
        },
    })
);

const OnlineChat: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const white = "#ffffff";

    return (
        <React.Fragment>
            {/* list item showing the user an avatar and status support */}
            <ListItem>
                <ListItemAvatar>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        variant="dot"
                    >
                        <Avatar style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <LogoIcon style={{ display: "block", width: "24px", color: white }} />
                        </Avatar>
                    </StyledBadge>
                </ListItemAvatar>
                <ListItemText
                    primary="Support TenderGuide"
                    // secondary={t("FaqChat.Online")}
                />
            </ListItem>
            <Divider style={{ marginBottom: 8 }} />

            {/* Element that contains the chatelements. now filled with dummy data */}
            <div style={{ height: "370px", overflow: "auto" }}>
                {chat.map((chatItem, i) => {
                    return (
                        <React.Fragment key={i}>
                            {chatItem.user === "user" ? (
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                    <Box
                                        boxShadow={2}
                                        sx={{
                                            minHeight: "36px",
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                            margin: "8px",
                                            borderRadius: "10px",
                                            maxWidth: "90%",
                                            backgroundColor: "#ffffff",
                                        }}
                                    >
                                        <Typography style={{ padding: "5px" }}>{chatItem.message}</Typography>
                                    </Box>
                                </div>
                            ) : (
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <Box
                                        boxShadow={2}
                                        sx={{
                                            minHeight: "36px",
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                            margin: "8px",
                                            borderRadius: "10px",
                                            maxWidth: "90%",
                                            backgroundColor: "#f4f4f4",
                                        }}
                                    >
                                        <Typography style={{ padding: "5px" }}>{chatItem.message}</Typography>
                                    </Box>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
            {/* Textfield with a button to send message to support. */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 10 }}>
                <TextField variant="outlined" placeholder={t("FaqChat.TypeText")} fullWidth margin="dense" sx={{ margin: "0px" }} />
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        marginLeft: "6px",
                        padding: "8px",
                        height: "38px",
                        color: "#ffffff",
                        backgroundColor: theme.palette.primary.light,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                        },
                    }}
                >
                    <SendIcon />
                </Button>
            </div>
        </React.Fragment>
    );
};

export default OnlineChat;
