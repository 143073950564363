import React from "react";
import { useTranslation } from "react-i18next";
import WidgetBox from "../WidgetBox";
import { List, Link as Linkie, ListItem, ListItemText, ListItemSecondaryAction, Typography, Tooltip } from "@mui/material";
import { Star } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetStarredCas } from "../../__generated__/GetStarredCas";
import FaviconBox from "../FaviconBox";
import { GetOppDetailPageGeneralInfo_opportunityFile_contractingAuthority } from "../../__generated__/GetOppDetailPageGeneralInfo";
import { GetAmountOfDocsOfSingleCa, GetAmountOfDocsOfSingleCaVariables } from "../../__generated__/GetAmountOfDocsOfSingleCa";
import { SortOrder, OpportunitySearchSource } from "../../__generated__/globalTypes";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { QUERY_AMOUNT_OF_OPPORTUNITY_DOCS_SINGLE_CA } from "../../graphql/opportunityQueries/queryDefinitions";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { QUERY_GETSTARREDCAS } from "../../graphql/queryDefCurrentUser";

interface Props {
    ca_info: GetOppDetailPageGeneralInfo_opportunityFile_contractingAuthority;
}

const CAboxOpportunity: React.FC<Props> = ({ ca_info }) => {
    const { t } = useTranslation();

    const starColor = "#9e9e9e";
    const yellow = "#F57117";
    const { data: starredCas } = useQuery<GetStarredCas>(QUERY_GETSTARREDCAS);
    const organization = ca_info;
    const navigate = useNavigate();
    const { trackEvent } = useMatomo();

    // Track event
    const trackCaClick = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const variables: GetAmountOfDocsOfSingleCaVariables = {
        page: 1,
        first: 1,
        orderField: "year_from",
        order: SortOrder.DESC,
        search: {
            filters: {
                contracting_authorities: [parseInt(ca_info.id)],
            },
        },
        source: OpportunitySearchSource.ALL_OPPORTUNITY_FILES,
    };

    const { data } = useQuery<GetAmountOfDocsOfSingleCa, GetAmountOfDocsOfSingleCaVariables>(QUERY_AMOUNT_OF_OPPORTUNITY_DOCS_SINGLE_CA, {
        variables: variables,
    });

    /**
     * get total amount of found records in query
     */
    const totalDocs = data?.filterOpportunityFiles?.results?.paginatorInfo.total;

    const handleCaClick = () => {
        sessionStorage.setItem("aof", JSON.stringify({}));
        sessionStorage.setItem("ocaf", JSON.stringify(ca_info.name));
        navigate(
            `/opportunities?${queryString.stringify({
                contracting_authorities: ca_info.id,
            })}`
        );
    };

    return (
        <WidgetBox
            mb={0}
            className="ContractingAuthorityBox"
            highlight="yellow"
            padding={0}
            header={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography>{t("contractingAuthority")} </Typography>
                    {/* Show link when contacts is > 0 */}
                    <Typography>
                        <Link
                            id="link-to-contacts"
                            to={`/contracting-authorities/${ca_info.id}/contacts`}
                            onClick={() => trackCaClick({ category: "Opportunity", action: "Click-show-contacts-of-ca" })}
                        >
                            Toon contactpersonen
                        </Link>
                    </Typography>
                </div>
            }
        >
            <List key={organization?.id}>
                {/* Contracting Authority logo if its available*/}
                <ListItem style={{ padding: "8px 16px" }}>
                    {organization?.logo !== null && organization?.logo !== undefined && organization?.logo !== "" && (
                        <div
                            style={{
                                width: 100,
                                height: 50,
                                backgroundImage: `url(${organization.logo.replace("staging-", "")})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                marginRight: 24,
                            }}
                        />
                    )}

                    {/* listitemtext with amount and percentage */}
                    <ListItemText
                        primary={
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    {typeof organization?.logo !== "string" && (
                                        <div style={{ marginRight: 8 }}>
                                            <FaviconBox
                                                marginTop={0}
                                                favicon={(organization?.favicon_resized as string) ?? (organization?.favicon as string)}
                                                name={organization?.name as string}
                                                color={yellow}
                                            />
                                        </div>
                                    )}{" "}
                                    {/* Link to ca detailpage */}
                                    <Link
                                        style={{ maxWidth: "95%" }}
                                        to={`/contracting-authorities/${organization?.id}`}
                                        onClick={() =>
                                            trackCaClick({ category: "Opportunity", action: "Click-from-opportunity-detail-to-ca-detail" })
                                        }
                                    >
                                        <Tooltip
                                            title={organization?.name as string}
                                            placement="top"
                                            componentsProps={{ tooltip: { sx: { marginBottom: "3px" } } }}
                                        >
                                            <Typography noWrap>{organization?.name}</Typography>
                                        </Tooltip>
                                    </Link>
                                </div>

                                {/*
                                 * Amount of docs with correct translation key if value = 1
                                 */}
                                <div style={{ width: "45%" }}>
                                    {totalDocs !== undefined && totalDocs !== 0 && (
                                        <Linkie
                                            // link color
                                            style={{ display: "inline", color: "#1f4769" }}
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                handleCaClick();
                                                trackCaClick({ category: "Opportunity", action: "Click-show-all-docs-of-ca" });
                                            }}
                                        >
                                            {totalDocs} {totalDocs === 1 ? t("document") : t("documents")}
                                        </Linkie>
                                    )}
                                </div>
                            </div>
                        }
                    />

                    {/* Label only visible when CA isn't active anymore */}
                    <ListItemSecondaryAction style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {/* change primary.active to something that shows state instead of a number */}
                        {/* <Chip size="small" label="actief" sx={{        textTransform: "uppercase",
        letterSpacing: 0.15,
        fontSize: "0.7rem",
        fontWeight: 500,}} /> */}

                        {/* show status of CA */}
                        {/* <StatusChip type={organization ? organization.status : null} colored={false} /> */}

                        {/* Show star if CA is starred by current user */}
                        {starredCas?.currentUser.contracting_authorities?.find((s) => s.id === organization?.id) && (
                            <Star style={{ color: `${starColor}`, marginLeft: 8 }} />
                        )}
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </WidgetBox>
    );
};

export default CAboxOpportunity;
