import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import { Typography, Grid, useTheme, useMediaQuery } from "@mui/material";

// Custom components
import GeneralInformationModule from "../GeneralInformationModule";
import CountryFlagBox from "./CountryFlagBox";
import WidgetBox from "../WidgetBox";
import "./ContractingAuthority.scss";

//icons used in this widget
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LanguageIcon from "@mui/icons-material/Language";
import FaviconBox from "../FaviconBox";
import anchorNewTab from "../../utils/anchorNewTab";

interface Props extends WithTranslation {
    CA: string | null;
    person: string | null;
    address: string | null;
    city: string | null;
    postal: string | null;
    phone: string | null;
    mail: string | null;
    host: string | null;
    country: string | null;
    website: string | null;
    phoneType: string | null;
}

const CABoxPublished: React.FC<Props> = ({ t, CA, person, address, city, postal, phone, mail, host, country, phoneType, website }) => {
    const theme = useTheme();
    const yellow = "#F57117";
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const blue = "#465c84";

    function validateText(string: string) {
        if (/(http(s?)):\/\//i.test(string)) {
            return string;
        } else {
            return "https://" + string;
        }
    }

    return (
        <React.Fragment>
            <WidgetBox className="ContractingAuthorityBox" header={t("contractingAuthority")} highlight="yellow" padding={0}>
                <Grid container style={{ display: "flex", flexDirection: "row", padding: "8px 16px" }}>
                    {/* Primary Contracting Authority */}
                    <Grid item xs={12} md={6}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {/* listitemtext with faviconbox and info of CA */}
                            <ListItemText
                                style={{ marginTop: 4 }}
                                primary={
                                    <Typography style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <FaviconBox favicon={null} name={CA} color={yellow} />
                                        {CA}
                                    </Typography>
                                }
                                secondary={
                                    <>
                                        <Typography style={{ display: "flex", alignItems: "center", marginLeft: 27 }}>{address}</Typography>
                                        <Typography style={{ display: "flex", alignItems: "center", marginLeft: 27 }}>
                                            {postal} {city}{" "}
                                            <>
                                                <CountryFlagBox code={country === "België" ? "BE" : country} />
                                            </>
                                        </Typography>
                                    </>
                                }
                            />
                        </div>
                    </Grid>

                    {/* Contact information, same module as in contactTab  */}
                    <Grid item xs={12} md={6}>
                        {/* published address data */}
                        <div style={{ width: "90%" }}>
                            <div>
                                <div>
                                    <div style={{ marginBottom: 8 }}>
                                        {/* person */}
                                        {person && (
                                            <GeneralInformationModule icon={<PersonIcon fontSize="small" />}>
                                                <ul
                                                    style={{
                                                        listStyle: "none",
                                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                        padding: 0,
                                                    }}
                                                >
                                                    <li>
                                                        <Typography noWrap>{person}</Typography>
                                                    </li>
                                                </ul>
                                            </GeneralInformationModule>
                                        )}
                                        {/* Phone */}
                                        {phone && (
                                            <GeneralInformationModule
                                                icon={phoneType === "mobile" ? <PhoneIphoneIcon fontSize="small" /> : <PhoneIcon fontSize="small" />}
                                            >
                                                <ul
                                                    style={{
                                                        listStyle: "none",
                                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                        padding: 0,
                                                    }}
                                                >
                                                    <a href={`tel: ${phone}`} style={{ color: blue }}>
                                                        <li>
                                                            <Typography noWrap>{phone}</Typography>
                                                        </li>
                                                    </a>
                                                </ul>
                                            </GeneralInformationModule>
                                        )}

                                        {/* EMAIL */}
                                        {mail && (
                                            <GeneralInformationModule icon={<MailIcon fontSize="small" />}>
                                                <ul
                                                    style={{
                                                        listStyle: "none",
                                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                        padding: 0,
                                                    }}
                                                >
                                                    <a href={`mailto: ${mail}`} style={{ color: blue }}>
                                                        <li>
                                                            <Typography noWrap>{mail}</Typography>
                                                        </li>
                                                    </a>
                                                </ul>
                                            </GeneralInformationModule>
                                        )}

                                        {/* WEBSITE */}
                                        {website && (
                                            <GeneralInformationModule icon={<LanguageIcon fontSize="small" />}>
                                                <ul
                                                    style={{
                                                        listStyle: "none",
                                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                        padding: 0,
                                                    }}
                                                >
                                                    {/* added https://  */}
                                                    <a {...anchorNewTab} href={validateText(website) || ""}>
                                                        <li>
                                                            <Typography noWrap>{website}</Typography>
                                                        </li>
                                                    </a>
                                                </ul>
                                            </GeneralInformationModule>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </WidgetBox>
        </React.Fragment>
    );
};

export default withTranslation("translation")(CABoxPublished);
