import React, { useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { defaultMentionStyle, defaultStyle } from "./Notes";
import { IconButton, Tooltip } from "@mui/material";
import { Check, Clear } from "@mui/icons-material";

interface Props {
    currentComment: string;
    updateAndReset: (str: string) => void;
    mentionList: { id: string; display: string }[];
    cancelEdit: (bln: boolean) => void;
}

const EditExistingComment: React.FC<Props> = ({ updateAndReset, currentComment, mentionList, cancelEdit }) => {
    const [name, setName] = useState(currentComment);

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
            <MentionsInput singleLine style={defaultStyle} placeholder="Reageren..." value={name} onChange={(e) => setName(e.target.value)}>
                <Mention style={defaultMentionStyle} trigger="@" data={mentionList} />
            </MentionsInput>
            <React.Fragment>
                <Tooltip title="Opslaan" placement="top">
                    <IconButton
                        size="small"
                        onClick={() => {
                            updateAndReset(name);
                            setName("");
                        }}
                    >
                        <Check fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Annuleren" placement="top">
                    <IconButton
                        size="small"
                        onClick={() => {
                            cancelEdit(false);
                            setName(currentComment);
                        }}
                    >
                        <Clear fontSize="small" />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        </div>
    );
};

export default EditExistingComment;
