import React from "react";
import AppBar from "@mui/material/AppBar";
import GeneralInfoTab from "./detailPage/mainWidget/GeneralInfoOrganization";
import SettlingDetailTab from "./detailPage/settlings/SettlingDetailTab";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import OrganizationDetailTabs from "./OrganizationDetailTabs";

interface Props {
    id: string;
    settlingCount: number;
}
const TabMenuModuleOrganizationModule: React.FC<Props> = ({ id, settlingCount }) => {
    const history = useLocation();
    const navigate = useNavigate();

    return (
        <div>
            <AppBar position="static" style={{ background: "white", color: "black", boxShadow: "none" }}>
                <OrganizationDetailTabs value={history.pathname} onChange={(value) => navigate(value)} settlingCount={settlingCount} id={id} />
            </AppBar>

            <Routes>
                {/* General information tab */}
                <Route path={``} element={<GeneralInfoTab id={id} />} />

                {/* settling information tab, listview with all settlings and a map with settlings plotted on map */}
                {settlingCount > 1 && <Route path={`settlings`} element={<SettlingDetailTab id={id} />} />}

                {/* Concern tab, list with relations */}
                <Route path={`corporate-relationships`} element={<div style={{ padding: 24 }}>Concernrelatie tab</div>} />

                {/* certification tab with a listview with all certifications */}
                <Route path={`certifications`} element={<div style={{ padding: 24 }}>certificeringen tab</div>} />
            </Routes>
        </div>
    );
};
export default TabMenuModuleOrganizationModule;
