import React from "react";
import { Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Skeleton } from "@mui/material";

interface Props {
    a?: number;
}

const MyTasksWidgetLoader: React.FC<Props> = (props) => {
    return (
        <List
            style={{
                paddingTop: 5,
                padding: 0,
                overflow: "auto",
                height: "355px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            {[...Array(5)].map((elem, index) => {
                return (
                    <React.Fragment key={index}>
                        <ListItem
                            id="ca-list-item"
                            sx={{
                                paddingLeft: "8px",
                                paddingRight: "8px",
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                }}
                            >
                                <Skeleton variant="circular" width={24} height={24} style={{ marginRight: 8 }} />
                            </ListItemIcon>
                            <ListItemText
                                sx={{
                                    marginRight: "60px",
                                }}
                                primary={<Skeleton animation="wave" />}
                                secondary={<Skeleton animation="wave" />}
                            />
                            <ListItemSecondaryAction>
                                <Skeleton animation="wave" width={30} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        {index !== 4 && <Divider />}
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default MyTasksWidgetLoader;
