import React, { useState } from "react";
// MUI
import { IconButton, Tooltip, MenuItem, Checkbox, Typography, ListItemIcon, List } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";

// translation
import { useTranslation } from "react-i18next";

//imported icons
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { useColumns, useSelectedColumns } from "../contextProviders/ColumnProvider";
import { MUIDataTableColumn } from "mui-datatables";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    columns: MUIDataTableColumn[];
    location: string;
}

// Styled menu with anchors to show menu underneath button
const StyledMenu = (props: MenuProps) => (
    <Menu
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
);

const ColumnChanger: React.FC<Props> = ({ columns, location }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const { items } = useSelectedColumns(columns);
    const { addItem } = useColumns();
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = () => {
        if (location.includes("tender")) {
            trackEvent({ category: "Tender", action: "Click-columns-to-show", name: location } as MatomoEvent);
        }
        if (location.includes("opportunities")) {
            trackEvent({ category: "Opportunity", action: "Click-columns-to-show", name: location } as MatomoEvent);
        }
        if (location.includes("organizations")) {
            trackEvent({ category: "Organizations", action: "Click-columns-to-show", name: location } as MatomoEvent);
        }
        if (location.includes("contracting-authorities")) {
            trackEvent({ category: "Ca", action: "Click-columns-to-show", name: location } as MatomoEvent);
        }
    };

    /**
     * @param event handler to open menu to change columns
     */
    const handleOpenColumnPopper = (event: any) => {
        setAnchorEl(event.currentTarget);
        trackevents();
    };
    /**
     * handler to close menu
     */
    const handleCloseColumnPopper = () => {
        setAnchorEl(null);
    };

    /**
     * Get columns and filter columns that have display: 'excluded' as prop, those are no option for users
     */
    const filteredColumns = items
        .filter((i: any) => i.options.display !== "excluded")
        .filter((i: any) => i.label !== "Actions")
        // .filter((i: any) => i.label !== "Reviews")
        .filter((i: any) => i.label !== "Title");

    return (
        <React.Fragment>
            <Tooltip enterNextDelay={100} placement="top" title={t("Column_Button.ColumnChange") as string}>
                <IconButton aria-label="event" size="small" style={{ margin: 1 }} onClick={handleOpenColumnPopper}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>

            <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseColumnPopper}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "8px 16px 0px",
                    }}
                >
                    <Typography>
                        {t("Column_Button.Columns")}
                        {` (${filteredColumns.filter((i: any) => i.options.display === true).length} ${t("of")} ${filteredColumns.length})`}
                    </Typography>
                    <IconButton size="small" onClick={handleCloseColumnPopper}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
                {/*
                 * List to add maxHeight with overflow to shorten the list
                 */}
                <List style={{ maxHeight: 385, width: 275, overflow: "auto" }}>
                    {items.map((c: any, i: number) => {
                        if (
                            c.options.display === "excluded" ||
                            c.label === "Actions" ||
                            // c.label === "Reviews" ||
                            c.label === "Title" ||
                            c.label === "id" ||
                            c.label === "AD" ||
                            c.label === "favicon" ||
                            c.label === "country" ||
                            c.label === "ca_id"
                        ) {
                            return null;
                        }
                        return (
                            <MenuItem
                                dense
                                key={i}
                                onClick={() => {
                                    addItem({ id: i.toString(), quantity: true });
                                }}
                            >
                                <ListItemIcon style={{ minWidth: 0 }}>
                                    <Checkbox
                                        size="small"
                                        edge="start"
                                        disableRipple
                                        checked={c.options.display}
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                    />
                                </ListItemIcon>
                                <Typography>{t(`Column_Header.${c.label}`)}</Typography>
                            </MenuItem>
                        );
                    })}
                </List>
            </StyledMenu>
        </React.Fragment>
    );
};

export default ColumnChanger;
