import React from "react";
import { SavedTenderEvents_getCalendarEvents_UserEvent } from "../../../__generated__/SavedTenderEvents";
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { Today } from "@mui/icons-material";

interface Props {
    data: SavedTenderEvents_getCalendarEvents_UserEvent;
    openId: string;
    setOpenId: (str: string) => void;
    setActiveTooltip: (bln: any) => void;
    activeTooltip: string | false;
}

const MyUserEvent: React.FC<Props> = ({ data }) => {
    return (
        <ListItem
            id="task-list-item"
            sx={{
                // paddingRight: "140px",
                paddingLeft: "0px",
                backgroundColor: "#ffffff",
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                }}
            >
                <Today />
            </ListItemIcon>
            <ListItemText
                sx={{
                    marginLeft: "4px",
                    marginTop: "3px",
                    marginBottom: "3px",
                    paddingRight: "80px",
                    "& .MuiListItemText-root": {
                        marginRight: "60px",
                    },
                }}
                primary={
                    <Typography variant="h6" noWrap>
                        {data.title}
                    </Typography>
                }
                secondary={
                    // {/* <Tooltip
                    //     placement="bottom"
                    //     sx={{ display: "inline", widgt: "100%" }}
                    //     componentsProps={{ tooltip: { sx: { marginTop: "0px" } } }}
                    //     enterNextDelay={100}
                    //     title={row.project?.title}
                    // > */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Today sx={{ width: "16px", marginRight: "4px", color: "#b3b3b3" }} />
                        <Typography
                            noWrap
                            // onClick={() => {
                            //     if (isMobile) {
                            //         navigate(`/projects/${row.project?.id as string}`);
                            //     } else {
                            //         changeProject({
                            //             projectID: row.project?.id,
                            //             projectTitle: row.project?.title,
                            //             state: row.project?.state?.id as string,
                            //         });
                            //         setOpen(true);
                            //     }
                            // }}
                            sx={{
                                // marginTop: "1px",
                                cursor: "pointer",
                                display: "inline-block",
                                maxWidth: "100%", // Adjust the maximum width based on your parent container width
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: "#1f4769",
                                "&:hover": {
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            {data.description}
                        </Typography>
                    </div>
                }
            />
            <ListItemSecondaryAction sx={{ display: "flex", alignItems: "center" }}>
                {/* {row.task_deadline && (
                    <React.Fragment>
                        <Typography sx={{ marginRight: "4px" }} align="right">
                            {moment(row.task_deadline).format("L")}
                        </Typography>
                    </React.Fragment>
                )}{" "} */}
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default MyUserEvent;
