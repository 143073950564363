import React, { useState, useEffect, useContext } from "react";
import {
    InputAdornment,
    List,
    TextField,
    CircularProgress,
    Popper,
    Autocomplete,
    Typography,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import FaviconBox from "../../../../components/FaviconBox";
// import TooltipOnNewLines from "../../../../components/TooltipOnNewLines";
import { ContractingAuthoritiesFilterSource, SortOrder } from "../../../../__generated__/globalTypes";
import {
    FilterCASForProject,
    FilterCASForProjectVariables,
    FilterCASForProject_filterContractingAuthorities_results_data,
} from "../../../../__generated__/FilterCASForProject";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import { UPDATE_CAS_IN_PROJECT } from "../../../../graphql/mutationDefProjects";
import { updateCA, updateCAVariables } from "../../../../__generated__/updateCA";
import { SINGLE_PROJECT } from "../../../../graphql/queryDefProjects";

interface Props {
    a?: number;
}

// Custom popper
// - disabled flip
const CustomPopper = (props: any) => {
    return (
        <Popper
            {...props}
            placement="bottom"
            disablePortal={false}
            modifiers={{
                flip: {
                    enabled: false,
                },
            }}
        />
    );
};

const AddCaToProject: React.FC<Props> = (props) => {
    const [options, setOptions] = useState<FilterCASForProject_filterContractingAuthorities_results_data[]>([] as any[]);
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState<string>("");
    const [load, setLoad] = useState<boolean>(false);
    const { project, changeProject } = useContext(ProjectToOpenContext);
    const [updateCas] = useMutation<updateCA, updateCAVariables>(UPDATE_CAS_IN_PROJECT);

    const { trackSiteSearch } = useMatomo();

    const addItemToArrayAndUpdate = (id: string) => {
        const newArr = project.ca_ids.concat(id);
        setLoad(true);
        saveNewCasArray(newArr);
    };

    /**
     * Add new task to array
     */
    const saveNewCasArray = (newArr: any[]) => {
        updateCas({
            variables: {
                project_id: project.projectID,
                ca_ids: newArr,
            },
            refetchQueries: [SINGLE_PROJECT],
            onCompleted: () => {
                changeProject({ ca_ids: newArr });
                setLoad(false);
            },
        });
    };

    // Track documentsearch
    const trackDocumentSearch = (q: string) => {
        trackSiteSearch({ keyword: q, category: "Tender", href: window.location.href });
    };

    // Define the GQL query
    const { loading, data } = useQuery<FilterCASForProject, FilterCASForProjectVariables>(QUERY_ALL_CONTRACTING_AUTHORITIES_FOR_PROJECT, {
        skip: query.length <= 3,
        variables: {
            page: 1,
            count: 100,
            orderField: "last_update_date_timestamp",
            order: SortOrder.DESC,
            search: {
                source: ContractingAuthoritiesFilterSource.ALL_CONTRACTING_AUTHORITIES,
                query: query,
            },
        },
        fetchPolicy: "network-only",
    });

    const debouncedSearch = debounce(async (newQuery) => {
        setQuery(newQuery);
    }, 300);

    const handleQueryChange = (newQuery: string) => {
        debouncedSearch(newQuery);
        if (newQuery.length > 2) {
            trackDocumentSearch(newQuery);
        }
    };

    useEffect(() => {
        let active = true;

        if (loading === true) {
            return undefined;
        }

        (async () => {
            if (active && data?.filterContractingAuthorities?.results?.data !== undefined) {
                setOptions(data?.filterContractingAuthorities?.results?.data);
            }
        })();

        return () => {
            active = false;
        };
    }, [data?.filterContractingAuthorities?.results?.data, loading, query]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        // Searchbar with dropdownlist
        <React.Fragment>
            <Autocomplete
                disableCloseOnSelect={false}
                size="small"
                open={open}
                onOpen={() => {
                    if (query.length > 3 && options.length > 0) {
                        setOpen(true);
                    }
                }}
                onClose={() => {
                    setOpen(false);
                }}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option.name as string}
                options={options}
                loading={loading}
                loadingText=""
                noOptionsText={"Geen resultaten gevonden"}
                style={{ flexGrow: 1, margin: "0px" }}
                PopperComponent={CustomPopper}
                renderOption={(props, option, state, ownerState) => {
                    return (
                        <React.Fragment>
                            {/* liststyle when system has matching documents */}
                            <List style={{ flexGrow: 1, width: "100%", padding: 0, margin: 0 }}>
                                <ListItemButton
                                    disabled={load || project.ca_ids.includes(option.id)}
                                    onClick={() => {
                                        if (!project.ca_ids.includes(option.id)) {
                                            addItemToArrayAndUpdate(option.id);
                                        }
                                    }}
                                    style={{ width: "100%", display: "flex", alignItems: "center", margin: 0 }}
                                >
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <FaviconBox favicon={option.favicon_resized ?? option.favicon} name={option.name} color="#F57117" />
                                    </ListItemIcon>
                                    {/* CA name */}
                                    <ListItemText
                                        sx={{ paddingRight: "16px" }}
                                        primary={<Typography noWrap>{option.name}</Typography>}
                                        secondary={
                                            <Typography noWrap variant="caption">
                                                {project.ca_ids.includes(option.id) ? "reeds toegevoegd" : ""}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            </List>
                        </React.Fragment>
                    );
                }}
                // searchIcon in textfield
                renderInput={(params) => {
                    params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    );
                    return (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Aanbestedende dienst zoeken..."
                            onChange={(e) => {
                                handleQueryChange(e.target.value);
                                e.target.value.length <= 3 ? setOpen(false) : setOpen(true);
                            }}
                            sx={{ marginRight: "16px" }}
                            value={query}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    );
                }}
            />
        </React.Fragment>
    );
};

export default AddCaToProject;

//? get all contracting authorities with pagination
export const QUERY_ALL_CONTRACTING_AUTHORITIES_FOR_PROJECT = gql`
    #set to active when generate types, work around for now
    query FilterCASForProject($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: ContractingAuthoritiesSearchInput) {
        filterContractingAuthorities(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                data {
                    id
                    name
                    favicon
                    favicon_resized
                    country {
                        alpha2
                        name
                        numeric
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;
