import React, { useState } from "react";
import OpportunityEditMode from "./OpportunityEditMode";
import OpportunityViewMode from "./OpportunityViewMode";
import { useMutation } from "@apollo/client";
import { Disable } from "react-disable";
import { CreateOpportunity, CreateOpportunityVariables } from "../../__generated__/CreateOpportunity";
import { GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities } from "../../__generated__/GetopportunityPagePersonalOpportunities";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { QUERY_OPPS_OF_CA_DOC_TYPE, QUERY_USER_OPPS_OF_PAGE } from "../../graphql/queryDefinitions";
import { CREATE_OPPORTUNITY } from "../../graphql/mutationDefinitions";

interface Props {
    opportunity: GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities;
    page_id: string;
}

const CreateOpportunityOnFrag: React.FC<Props> = ({ page_id, opportunity }) => {
    const [editMode, setEditMode] = useState<GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities>(opportunity);
    const [editField, setEditField] = useState<Boolean>(false);

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * mutation to rate page of document
     */
    const [createOpp, { loading: loadMutation, error: errorMutation }] = useMutation<CreateOpportunity, CreateOpportunityVariables>(
        CREATE_OPPORTUNITY
    );

    /**
     * handle submit of new opportunity
     */
    const createNewOpportunity = async () => {
        const arrayValue = [] as any[];
        const arrayYear = [] as any[];
        trackevents({ category: "Opportunity", action: "Click-add-opportunity" });
        if (editMode !== undefined && editMode !== null && editMode.parsedValues !== null) {
            for (let i = 0; i < editMode.parsedValues.length; i++) {
                arrayValue.push(editMode.parsedValues[i]?.value);
                arrayYear.push(editMode.parsedValues[i]?.year);
            }
        }

        if (errorMutation) {
        }
        try {
            await createOpp({
                variables: {
                    id: editMode?.id ? editMode?.id : "",
                    page_id: page_id,
                    comment: editMode?.comment || "",
                    values: arrayValue,
                    years: arrayYear,
                },
                refetchQueries: [
                    QUERY_USER_OPPS_OF_PAGE,
                    "GetopportunityPagePersonalOpportunities",
                    QUERY_OPPS_OF_CA_DOC_TYPE,
                    "GetCAOpportunitiesOfType",
                ],
            });
            setEditField(false);
        } catch (e) {}
    };

    return (
        <React.Fragment>
            {editField === true ? (
                <Disable disabled={loadMutation}>
                    {/* Component for editing opportunity */}
                    <OpportunityEditMode opportunity={editMode} onSave={() => createNewOpportunity()} setEditMode={setEditMode} />
                </Disable>
            ) : (
                // Component for viewing created opportunities
                <OpportunityViewMode setEditField={setEditField} setEditMode={setEditMode} opportunity={opportunity} />
            )}
        </React.Fragment>
    );
};

export default CreateOpportunityOnFrag;
