import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    // Active page url, to determine which tab is active
    value: string;
    // Handler when user clicks on a tab
    onChange(url: string): void;
    settlingCount: number;
    id: string;
}

const OrganizationDetailTabs: React.FC<Props> = ({ value, onChange, settlingCount, id }) => {
    const [tabValue, setTabValue] = useState(value);

    useEffect(() => {
        /**
         * Get pathname "/contracting-authorities/{id}"
         * optional: /contacts or /policy
         * Get value after last /
         * If value is id -> set value to empty string
         * else -> set value to /contacts or /policy
         */
        const str = value;
        const n = str.lastIndexOf("/");
        const result = str.substring(n + 1);

        const isnum = /^\d+$/.test(result);
        if (isnum) {
            setTabValue("");
        } else {
            setTabValue(result);
        }
    }, [value]);

    const { trackEvent } = useMatomo();

    // Track event
    const trackOrgTab = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        // tabs inside organization widget
        <Tabs
            value={tabValue}
            onChange={(_, value) => {
                onChange(value);
                setTabValue(value);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            sx={{
                "& .MuiTabs-root": {
                    height: "100%",
                },
                "& .MuiTabs-flexContainer": {
                    height: "100%",
                },
            }}
        >
            {/* Default tabs*/}
            <Tab
                sx={{ minWidth: "150px", maxWidth: "100%", flexBasis: 100, flexShrink: 0 }}
                value={""}
                label="algemeen"
                onClick={() => trackOrgTab({ category: "Organizations", action: "Click-general-tab-organization" })}
            />
            {settlingCount > 1 && (
                <Tab
                    sx={{ minWidth: "150px", maxWidth: "100%", flexBasis: 100, flexShrink: 0 }}
                    value={`settlings` || `/organizations/${id}/settlings`}
                    label="vestigingen"
                    onClick={() => trackOrgTab({ category: "Organizations", action: "Click-settlings-tab-organization" })}
                />
            )}
            {/* <Tab
sx={{        minWidth: '150px',
        maxWidth: "100%",
        flexBasis: 100,
        flexShrink: 0,}}
                value={`corporate-relationships` || `/organizations/${id}/corporate-relationships`}
                label="concernrelaties"
            /> */}
            {/* <Tab sx={{        minWidth: '150px',
        maxWidth: "100%",
        flexBasis: 100,
        flexShrink: 0,}} value={`certifications` || `/organizations/${id}/certifications`} label="certificeringen" /> */}
        </Tabs>
    );
};

export default OrganizationDetailTabs;
