import React from "react";
import ContractingAuthorityBox from "../../boxes/ContractingAuthorityBox";
import CABoxPublished from "../../boxes/CABoxPublished";
import { GET_CONTRACTING_AUTHORITY_DETAILS_WIDGET } from "../../../graphql/queryDefinitions";
import {
    GetCaDataForWidgetOfCurrentTender,
    GetCaDataForWidgetOfCurrentTenderVariables,
} from "../../../__generated__/GetCaDataForWidgetOfCurrentTender";
import { useQuery } from "@apollo/client";
import { Disable } from "react-disable";
import WidgetBox from "../../WidgetBox";

import { useTranslation } from "react-i18next";

interface Props {
    tender_id: string;
}

const CaWidgetBox: React.FC<Props> = ({ tender_id }) => {
    const { t } = useTranslation();

    // Fetch data
    const { loading, error, data } = useQuery<GetCaDataForWidgetOfCurrentTender, GetCaDataForWidgetOfCurrentTenderVariables>(
        GET_CONTRACTING_AUTHORITY_DETAILS_WIDGET,
        {
            variables: { id: tender_id },
            fetchPolicy: "network-only",
        }
    );

    const renderedPage = (content: React.ReactNode) => (
        <Disable disabled={loading || error ? true : false || !data || !data.tender}>
            <WidgetBox loading={true} className="ContractingAuthorityBox" header={t("contractingAuthority")} highlight="yellow" padding={0}>
                {content}
            </WidgetBox>
        </Disable>
    );

    if (loading) return renderedPage("");

    if (error || !data || !data.tender) return renderedPage("");

    const tender = data.tender;

    // Contracting Authorities
    const primaryCA = tender?.clients.find((ca) => ca.pivot?.category === "A");
    const secundaryCAs = tender?.clients.filter((ca) => ca.pivot?.category === "B");
    /**
     * find CA id in organiations and use this info to grab contactdata
     */
    const address = primaryCA?.settlings?.data.find((s) => s.type === "hoofdvestiging");
    const street = address?.visit_address?.street !== null && address?.visit_address?.street !== undefined ? address?.visit_address.street : "";
    const housenumber =
        address?.visit_address?.housenumber !== null && address?.visit_address?.housenumber !== undefined ? address?.visit_address.housenumber : "";
    const suffix =
        address?.visit_address?.housenumber_suffix !== null && address?.visit_address?.housenumber_suffix !== undefined
            ? address?.visit_address.housenumber_suffix
            : "";
    const postal_visit = address?.visit_address?.postal_code;
    const city_visit = address?.visit_address?.city !== null && address?.visit_address?.city !== undefined && address?.visit_address.city;
    const address_visit = `${street}  ${housenumber}${suffix}`;
    return (
        <React.Fragment>
            {tender.clients.length > 0 ? (
                <ContractingAuthorityBox
                    clients={tender.clients}
                    primary={
                        primaryCA !== undefined
                            ? {
                                  id: primaryCA.id,
                                  name: primaryCA.name,
                                  active: primaryCA.status,
                                  website: primaryCA.urls?.find((url) => url.type === "HOME")?.url,
                                  adres_visit: address_visit,
                                  postcode_visit: postal_visit,
                                  plaats_visit: city_visit,
                                  country: primaryCA.country,
                                  lot: primaryCA.pivot?.lot?.number,
                                  logo: primaryCA.logo,
                                  favicon: primaryCA.favicon_resized ?? primaryCA.favicon,
                              }
                            : undefined
                    }
                    phones={primaryCA !== undefined ? primaryCA.phonenumbers : undefined}
                    emails={primaryCA !== undefined ? primaryCA.emails : undefined}
                    // TODO: check if props are correct

                    contact={{
                        website: tender.published_website,
                        name: tender.published_contactpersoon,
                        email: tender.published_email,
                        phone: tender.published_telefoon,
                        phoneType: tender.published_telefoon_type,
                        street: tender.published_adres,
                        city: tender.published_vestigingsplaats,
                        country: tender.country?.alpha2,
                        postalcode: tender.published_postcode,
                    }}
                    others={secundaryCAs.map((ca) => ({
                        id: ca.id,
                        name: ca.name,
                        favicon: ca.favicon_resized ?? ca.favicon,
                        city: "city",
                        postcode: "postcode",
                        address: "address",
                        country: ca.country,
                        logo: ca.logo,
                        lot: ca.pivot?.lot?.number,
                    }))}
                />
            ) : (
                <CABoxPublished
                    CA={tender.published_opdrachtgever}
                    person={tender.published_contactpersoon}
                    address={tender.published_adres}
                    city={tender.published_vestigingsplaats}
                    postal={tender.published_postcode}
                    phone={tender.published_telefoon}
                    phoneType={tender.published_telefoon_type}
                    website={tender.published_website}
                    mail={tender.published_email}
                    host={tender.published_host}
                    country={tender.country?.alpha2 ? tender.country?.alpha2 : null}
                />
            )}
        </React.Fragment>
    );
};

export default CaWidgetBox;
