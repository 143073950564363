import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { QUERY_RELATED_TENDERS_FOR_CURRENT_TENDER } from "../../../graphql/queryDefinitions";
import {
    GetRelatedTendersForCurrentTender,
    GetRelatedTendersForCurrentTenderVariables,
} from "../../../__generated__/GetRelatedTendersForCurrentTender";
import { Disable } from "react-disable";
import WidgetBox from "../../WidgetBox";
import RelatedTenderWidget from "../../RelatedTenders/RelatedTenderWidget";

interface Props {
    tender_id: string;
}

const TndrRelatedTendersWidget: React.FC<Props> = ({ tender_id }) => {
    const { t } = useTranslation();

    const { loading, error, data } = useQuery<GetRelatedTendersForCurrentTender, GetRelatedTendersForCurrentTenderVariables>(
        QUERY_RELATED_TENDERS_FOR_CURRENT_TENDER,
        {
            variables: { id: tender_id },
        }
    );

    if (loading)
        return (
            <Disable disabled={true}>
                <WidgetBox loading={true} header={t("tenderPage.RelatedTenders")} highlight="blue" padding={0} />
            </Disable>
        );

    if (error || !data || !data.tender)
        return (
            <Disable disabled={true}>
                <WidgetBox header={t("tenderPage.RelatedTenders")} highlight="blue" padding={0} />
            </Disable>
        );

    const tender = data.tender;

    return (
        <React.Fragment>
            {tender.related_tenders !== undefined && tender.related_tenders !== null && tender.related_tenders.length > 0 ? (
                <RelatedTenderWidget related_tenders={tender.related_tenders} />
            ) : (
                <Disable disabled={true}>
                    <WidgetBox header={t("tenderPage.RelatedTenders")} highlight="blue" padding={0} />
                </Disable>
            )}
        </React.Fragment>
    );
};

export default TndrRelatedTendersWidget;
