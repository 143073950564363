import React from "react";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { withTranslation, WithTranslation } from "react-i18next";
import "./InViewPagination.scss";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import { useQuery } from "@apollo/client";
import {
    GetOpportunityFilesWithResultsCount,
    GetOpportunityFilesWithResultsCountVariables,
} from "../__generated__/GetOpportunityFilesWithResultsCount";
import { QueryOpportunityFilesWithResultsOrderByColumn, SortOrder } from "../__generated__/globalTypes";
import { useNavigate } from "react-router-dom";
import { useOpportunityWorklistPage } from "./contextProviders/OpportunityWorklistContext";
import { QUERY_OPPWORKLISTLENGTH } from "../graphql/queryDefinitions";

interface Props extends WithTranslation {
    currentPage: number;
    pageCount: number;
    listName: string;
    bgColor: string;
}

// TODO: if tender_id is not in worklist dont show component

const InViewPagination: React.FunctionComponent<Props> = ({ t, currentPage, pageCount, listName, bgColor }) => {
    const navigate = useNavigate();

    const { page, setPage } = useOpportunityWorklistPage();

    const variables: GetOpportunityFilesWithResultsCountVariables = {
        first: 1,
        page: page,
        orderField: QueryOpportunityFilesWithResultsOrderByColumn.CREATED_AT,
        order: SortOrder.DESC,
    };

    const { data } = useQuery<GetOpportunityFilesWithResultsCount, GetOpportunityFilesWithResultsCountVariables>(QUERY_OPPWORKLISTLENGTH, {
        variables: variables,
    });

    const nextPage = () => {
        setPage(page + 1);
        navigateToNextTender();
    };

    const navigateToNextTender = () => {
        navigate(`/opportunities/${data?.opportunityFilesWithResults?.data[0].id}`);
    };

    return (
        <React.Fragment>
            {data?.opportunityFilesWithResults?.paginatorInfo.total !== undefined && data?.opportunityFilesWithResults?.paginatorInfo.total > 0 && (
                <div className="InViewPagination">
                    <div className="page-info">
                        <div className="list-type">
                            <Badge
                                sx={{
                                    "& .MuiBadge-badge": {
                                        right: "-5px",
                                        top: "-8px",
                                        fontSize: "9px",
                                        backgroundColor: bgColor,
                                        color: "#ffffff",
                                    },
                                }}
                                badgeContent={data?.opportunityFilesWithResults?.paginatorInfo.total}
                                max={99}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <Typography variant="h4" style={{ marginTop: 0 }}>
                                    {listName}
                                </Typography>
                            </Badge>
                        </div>
                    </div>
                    {data.opportunityFilesWithResults.paginatorInfo.hasMorePages && (
                        <IconButton size="small" onClick={() => nextPage()}>
                            <NavigateNextIcon fontSize="small" />
                        </IconButton>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default withTranslation("translation")(InViewPagination);
