import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { TextFieldProfile } from "../PersonalDetails";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { useMutation } from "@apollo/client";
import { updateUserPassword, updateUserPasswordVariables } from "../../../../__generated__/updateUserPassword";
import { UPDATEPASSWORD } from "../../../../graphql/mutationDefinitions";
import { toast } from "react-toastify";
import { Close, KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

interface Props {
    a?: number;
}

// Styled Paper component

const PasswordChange: React.FC<Props> = (props) => {
    const [open, setOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [repeatPassword, setRepeatPassword] = useState<string>("");
    const { register, handleSubmit, errors } = useForm();
    const { t } = useTranslation();

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const [updatePassword, { loading }] = useMutation<updateUserPassword, updateUserPasswordVariables>(UPDATEPASSWORD);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        if (loading) return;
        if (!currentPassword || !password || !repeatPassword) {
            toast.error("No input", { autoClose: 1500 });
            return;
        }

        try {
            const authResponse = await updatePassword({
                variables: { current_password: currentPassword, password: password, password_confirmation: repeatPassword },
            });
            if (!authResponse.data) {
                toast.error("Huidige wachtwoord onjuist", { autoClose: 1500 });
            }
            toast.success(t("PASSWORD_UPDATED"), { autoClose: 1500 });
            handleClose();
            setPassword("");
            setRepeatPassword("");
            setCurrentPassword("");
        } catch (e) {
            console.error("Error signing in", e);
            toast.error("Er ging iets mis tijdens het opslaan...", { autoClose: 1500 });

            setPassword("");
            setRepeatPassword("");
            setCurrentPassword("");
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <ListItem disablePadding onClick={handleClickOpen}>
                <ListItemButton sx={{ paddingLeft: "32px" }}>
                    <ListItemText
                        primary={
                            <Typography fontSize={"1rem"} fontWeight={500}>
                                Wachtwoord
                            </Typography>
                        }
                        secondary={<Typography variant="caption">Nieuw wachtwoord instellen voor je account</Typography>}
                    />
                    <Typography sx={{ marginTop: "-3px", marginRight: "16px" }}>Wijzigen</Typography>
                    <KeyboardArrowRight />
                </ListItemButton>
            </ListItem>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <DialogTitle>Wachtwoord wijzigen</DialogTitle>
                    <IconButton size="small" sx={{ marginRight: "8px" }} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </div>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                        <Grid container style={{ height: "100%" }}>
                            <Grid item container>
                                {/* Current password */}
                                <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "left", margin: 4 }}>
                                    <TextFieldProfile
                                        inputRef={register({ required: true })}
                                        name="passwordcurrent"
                                        label={t("Security.old-password")}
                                        error={Boolean(errors.password)}
                                        type="password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                </Grid>

                                {/* New password */}
                                <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "left", margin: 4 }}>
                                    <TextFieldProfile
                                        inputRef={register({ required: true })}
                                        name="password"
                                        label={t("Security.Password")}
                                        error={Boolean(errors.password)}
                                        helperText={password.length > 2 && password.length < 6 ? "Wachtwoord moet minimaal 6 tekens lang zijn" : ""}
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Grid>

                                {/* repeat new password */}
                                <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "left", margin: 4 }}>
                                    <TextFieldProfile
                                        inputRef={register({ required: true })}
                                        name="passwordRepeat"
                                        label={t("Security.RepeatPassword")}
                                        error={Boolean(errors.passwordRepeat)}
                                        type="password"
                                        helperText={
                                            repeatPassword.length > 2 && repeatPassword.length < 6
                                                ? "Wachtwoord moet minimaal 6 tekens lang zijn"
                                                : ""
                                        }
                                        value={repeatPassword}
                                        onChange={(e) => setRepeatPassword(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12} style={{ marginTop: 16, display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                <Button onClick={handleClose}>Sluiten</Button>
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    color="grey"
                                    type="submit"
                                    disabled={password.length < 6 || repeatPassword.length < 6}
                                    onClick={() => trackevents({ category: "Personal", action: "Click-save-new-password" })}
                                >
                                    {t("save")}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PasswordChange;
