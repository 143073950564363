import React from "react";
import { Typography } from "@mui/material";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GetEnddateForMPColumn, GetEnddateForMPColumnVariables } from "../../__generated__/GetEnddateForMPColumn";
import { GET_ENDDATE_OF_TENDER_FOR_MP_COLUMN } from "../../graphql/queryDefForColumns";

interface Props {
    tender_id: string;
    org_id: string;
}

const EndDateOfTenderForMPColumn: React.FC<Props> = ({ tender_id, org_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetEnddateForMPColumn, GetEnddateForMPColumnVariables>(GET_ENDDATE_OF_TENDER_FOR_MP_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    const lotsToCheck = tender.lots.map((lot) => lot.id);

    const getLotsOfCurrentMP =
        tender.bids !== null
            ? tender.bids.filter((bid) => lotsToCheck.includes(bid?.lot?.id as string)).filter((i) => i.marketParty.id === org_id)
            : [];

    const value =
        getLotsOfCurrentMP.filter((mp) => mp.winner === true).length > 0
            ? "lotsWin"
            : getLotsOfCurrentMP.length > 0
            ? "lots"
            : tender.bids?.find((i) => i.marketParty.id === org_id)?.winner
            ? "noLots"
            : "noWin";

    // map lot end_dates
    const getnle: any = getLotsOfCurrentMP
        .map((i: any) => i.lot.next_looptijd_einde)
        .filter(function (el: any) {
            return el !== null;
        });

    // get earliest enddate in array
    const maxDate = new Date(
        Math.max(
            ...getnle.map((element: any) => {
                return new Date(element);
            })
        )
    );

    return (
        <React.Fragment>
            {(() => {
                /**
                 * Switch contains all states a tender could have, for each state a different component.
                 */
                switch (value) {
                    case "lotsWin":
                        return <Typography>{maxDate.toString() !== "Invalid Date" && moment(maxDate).format("L")}</Typography>;
                    case "lots":
                        return <Typography>{maxDate.toString() !== "Invalid Date" && moment(maxDate).format("L")}</Typography>;
                    case "noLots":
                        return (
                            <Typography>
                                {tender.looptijd_einde_incl_opties?.map((i: any) => (i.value !== null ? moment(i.value).format("L") : ""))}
                            </Typography>
                        );

                    case "noWin":
                        return (
                            <Typography>
                                {tender.looptijd_einde_incl_opties?.map((i: any) => (i.value !== null ? moment(i.value).format("L") : ""))}
                            </Typography>
                        );
                    default:
                        return <div />;
                }
            })()}
        </React.Fragment>
    );
};

export default EndDateOfTenderForMPColumn;
