import React from "react";
import { SINGLE_PROJECT_CAS, SINGLE_PROJECT_TENDERS } from "../../../../graphql/queryDefProjects";
import { single_project_cas, single_project_casVariables } from "../../../../__generated__/single_project_cas";
import { single_project_tenders, single_project_tendersVariables } from "../../../../__generated__/single_project_tenders";
import { useQuery } from "@apollo/client";
import { Box, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import FaviconBox from "../../../../components/FaviconBox";
import StatusDot from "../../../../components/StatusDot";
import { useTranslation } from "react-i18next";
import { getRelativeTimeInDays } from "../../../../utils/relativeTimeInDays";

interface Props {
    project_id: string;
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip disableInteractive arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
        "&::before": {
            backgroundColor: theme.palette.common.white,
            border: "1px solid #fff",
        },
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        padding: "12px",
        boxShadow: theme.shadows[1],
        fontSize: 13,
        minWidth: 350,
        maxWidth: 350,
    },
}));

const ConnectedEntitities: React.FC<Props> = ({ project_id }) => {
    const { t } = useTranslation();
    const { data: tenderData } = useQuery<single_project_tenders, single_project_tendersVariables>(SINGLE_PROJECT_TENDERS, {
        variables: {
            id: project_id,
        },
    });

    const { data: casData } = useQuery<single_project_cas, single_project_casVariables>(SINGLE_PROJECT_CAS, {
        variables: {
            id: project_id,
        },
    });

    return (
        <div style={{ width: "100%", display: "flex", height: "6px" }}>
            {tenderData?.project?.tenders && tenderData.project.tenders.length > 0 && (
                <LightTooltip
                    arrow
                    placement="bottom-start"
                    title={
                        <div>
                            {tenderData.project.tenders.map((i) => {
                                return (
                                    <div key={i?.id}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <span style={{ fontSize: "14px", lineHeight: 1.1 }}>{i?.namegiventocontract}</span>
                                            <div style={{ height: 14, width: 14 }}>
                                                <StatusDot type={i?.status as string} placement={"left"} />
                                            </div>
                                        </div>
                                        <div style={{ height: "6px" }} />
                                        {i?.deadline_inschrijving && (
                                            <span style={{ fontWeight: 400 }}>
                                                Deadline: {moment(i.deadline_inschrijving).format("LLL")}, (
                                                {getRelativeTimeInDays(i.deadline_inschrijving, t).show && (
                                                    <>({getRelativeTimeInDays(i.deadline_inschrijving, t).date})</>
                                                )}
                                                )
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    }
                >
                    <Box
                        sx={{
                            width: "30px",
                            height: "6px",
                            borderRadius: "4px",
                            backgroundColor: "#173357",
                            marginRight: "2px",
                            cursor: "pointer",
                        }}
                    />
                </LightTooltip>
            )}

            {casData?.project?.contractingAuthorities && casData.project.contractingAuthorities.length > 0 && (
                <LightTooltip
                    arrow
                    placement="bottom-start"
                    title={
                        <div>
                            {casData.project.contractingAuthorities.map((i) => {
                                return (
                                    <div
                                        key={i?.id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: casData.project?.contractingAuthorities?.length === 1 ? "0px" : "6px",
                                        }}
                                    >
                                        <FaviconBox favicon={null} id={i?.id} name={i?.name} />
                                        <span style={{ fontSize: "14px", lineHeight: 1.1 }}>
                                            {i?.name} <br />
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    }
                >
                    <Box
                        sx={{
                            width: "30px",
                            height: "6px",
                            borderRadius: "4px",
                            backgroundColor: "#F57117",
                            marginRight: "2px",
                            cursor: "pointer",
                        }}
                    />
                </LightTooltip>
            )}
            {/* <Box
            sx={{
                width: "40px",
                height: "8px",
                borderRadius: "4px",
                backgroundColor:"#E03660",
                marginRight: "2px",
            }}
        /> */}
            {/* <Box sx={{ width: "40px", height: "8px", borderRadius: "4px", backgroundColor:"#225E4D" }} /> */}
        </div>
    );
};

export default ConnectedEntitities;
