import React, { useEffect } from "react";
import { DocumentNode, useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useTenderChat } from "../contextProviders/TenderChatContext";
import { GET_LAST_CA_MESSAGE, GET_LAST_MP_MESSAGE, GET_LAST_OPP_MESSAGE } from "../../graphql/queryDefinitions";

interface Props {
    id: string;
    module: "ca" | "mp" | "opportunity";
}

const QUERY_MAP: Record<"ca" | "mp" | "opportunity", DocumentNode> = {
    ca: GET_LAST_CA_MESSAGE,
    mp: GET_LAST_MP_MESSAGE,
    opportunity: GET_LAST_OPP_MESSAGE,
};

const DATA_FIELD_MAP = {
    ca: "contracting_authority",
    mp: "tenderer",
    opportunity: "opportunityFile",
};

const LastMessageColumnType: React.FC<Props> = ({ id, module }) => {
    const { messageSent, openModal } = useTenderChat();
    const query = QUERY_MAP[module];
    const dataField = DATA_FIELD_MAP[module];

    const { data, refetch } = useQuery(query, {
        variables: { id },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (messageSent && !openModal) {
            refetch();
        }
    }, [refetch, messageSent, openModal]);

    const content = data?.[dataField]?.lastPrivateNote || "";

    return <Typography variant="body1">{content}</Typography>;
};

export default LastMessageColumnType;
