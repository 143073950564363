import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, SvgIconTypeMap, Typography } from "@mui/material";
import { gql, useLazyQuery } from "@apollo/client";

import LogTimeLine from "./LogTimeLine";
// import PeriodFilter, { DateRange } from "../../../components/input/PeriodFilter";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import InformationDialog from "../Dialog/InformationDialog";

// Icons used on this page
import EmailModal from "./EmailModal";
import { useTranslation } from "react-i18next";
import setTitle from "../../../utils/setTitle";

import Timeline from "@mui/lab/Timeline";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetUserLogsWithFilters, GetUserLogsWithFiltersVariables } from "../../../__generated__/GetUserLogsWithFilters";
import LogPeriodSelector from "./filters/LogPeriodSelector";
import { DateRange } from "../../../components/TopFilterBar/PeriodFilter";
import moment from "moment";

import LogActionSelector from "./filters/LogActionSelector";
import LogModuleSelector from "./filters/LogModuleSelector";
import { SortOrder } from "../../../__generated__/globalTypes";
import { FormatListBulleted } from "@mui/icons-material";
import { Disable } from "react-disable";

interface Props {
    a?: number;
}

export interface LogItem {
    id: string;
    activity?: string;
    user: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    date: string;
    category: string;
    sentence: string;
    tender?: string;
    from?: string;
    action?: string;
    opportunity?: string;
    userAgent?: string;
    emailSubject?: string;
    emailURL?: string;
}

const Logbook: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState([] as any[]);
    const [date, setDate] = useState<DateRange>({ from: null, to: null });
    const [openDialog, setOpenDialog] = useState(false);
    const [openEmailDialog, setOpenEmailDialog] = useState({ open: false, link: "" });
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [chosenAction, setChosenAction] = useState<string>("");
    const [chosenModule, setChosenModule] = useState<string>("");
    const [initialLoading, setInitialLoading] = useState<boolean>(true);

    // orderBy: {column: "created_at", order:ASC}

    const [variables, setVariables] = useState<GetUserLogsWithFiltersVariables>({
        first: rowsPerPage,
        page: 1,
        createdBetween: undefined,
        action: undefined,
        module: undefined,
        orderBy: { column: "created_at", order: SortOrder.DESC },
    });

    const updateProperties = (newProperties: Partial<GetUserLogsWithFiltersVariables>) => {
        // Create a new object with the existing properties spread and updated properties
        const updatedVariables: GetUserLogsWithFiltersVariables = {
            ...variables,
            ...newProperties,
        };
        setNotifications([]);
        setVariables(updatedVariables); // Set the updated object as the new state
    };

    // Define the GQL query
    const [run, { error, loading, data }] = useLazyQuery<GetUserLogsWithFilters, GetUserLogsWithFiltersVariables>(QUERY_GET_USER_LOGS_WITH_FILTERES, {
        variables: variables,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.activities && data.activities.data) {
                setNotifications(data.activities.data);
                // setNotifications((notifications: any) => [...notifications, ...(data?.activities?.data || [])]);
                if (initialLoading) {
                    setInitialLoading(false);
                }
            }
        },
    });

    //set tab title
    useEffect(() => {
        setTitle(t("Logbook"));

        run({
            variables: {
                orderBy: { column: "created_at", order: SortOrder.DESC },
                first: rowsPerPage,
                page: 1,
            },
        });
    }, [run, rowsPerPage, t]);

    /**
     * handler to open information dialog
     */
    const handleClickOpenInformationDialog = () => {
        setOpenDialog(true);
    };

    /**
     * handler to close the information dialog
     */
    const handleCloseInformationDialog = () => {
        setOpenDialog(false);
    };

    /**
     * handler to open information dialog
     */
    const handleClickOpenEmailDialog = (open: boolean, link: string) => {
        setOpenEmailDialog({ open: open, link: link });
    };

    /**
     * handler to close the information dialog
     */
    const handleCloseEmailDialog = () => {
        setOpenEmailDialog({ open: false, link: "" });
    };

    const noFiltersActive = () => {
        return Boolean(variables.createdBetween === undefined && variables.action === undefined && variables.module === undefined);
    };

    const extractTypeFromString = (input: string | null | undefined): { action: string; label: string }[] => {
        if (input === null || input === undefined) {
            return [];
        }
        if (input.toLowerCase() === "tenders") {
            return [
                { action: "liked", label: "liked" },
                { action: "disliked", label: "disliked" },
                { action: "shared", label: "shared" },
                { action: "archived", label: "archived" },
            ];
        } else if (input.toLowerCase() === "opportunities") {
            return [
                { action: "created", label: "created" },
                { action: "updated", label: "updated" },
            ];
        } else if (input.toLowerCase() === "projects") {
            return [
                { action: "created", label: "created" },
                { action: "updated", label: "updated" },
                { action: "deleted", label: "deleted" },
                // { action: "shared", label: "shared" },
                // { action: "archived", label: "archived" },
            ];
        } else if (input.toLowerCase() === "organizations") {
            return [
                { action: "liked", label: "likedOrg" },
                { action: "disliked", label: "dislikedOrg" },
            ];
        } else if (input.toLowerCase() === "contracting-authorities") {
            return [
                { action: "liked", label: "likedOrg" },
                { action: "disliked", label: "dislikedOrg" },
            ];
        } else {
            return []; // Empty array if input doesn't match "car" or "bike"
        }
    };

    /**
     * renderPage contains a grid with 2 children
     * 1. searchbar and filters
     * 2. Timeline with log items
     */
    const renderPage = (content: React.ReactNode) => (
        <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <LogModuleSelector
                updateProperties={updateProperties}
                setChosenModule={setChosenModule}
                variables={variables}
                chosenModule={chosenModule}
                rowsPerPage={rowsPerPage}
                onReset={() => {
                    setInitialLoading(true);
                    setChosenModule("");
                    updateProperties({
                        first: 100,
                        page: 1,
                        module: undefined,
                    });
                }}
            />

            {variables.module !== undefined && (
                <Disable disabled={variables.module === undefined}>
                    <LogActionSelector
                        updateProperties={updateProperties}
                        setChosenAction={setChosenAction}
                        variables={variables}
                        chosenAction={chosenAction}
                        rowsPerPage={rowsPerPage}
                        actions={extractTypeFromString(variables.module)}
                        onReset={() => {
                            setInitialLoading(true);
                            setChosenAction("");
                            updateProperties({
                                first: 100,
                                page: 1,
                                action: undefined,
                            });
                        }}
                    />
                </Disable>
            )}

            <LogPeriodSelector
                label="Periode"
                variables={variables}
                dateRange={date}
                active={Boolean(date.from !== null && date.to !== null)}
                setDateRange={setDate}
                reset={() => {
                    setInitialLoading(true);
                    setDate({ from: null, to: null });
                    updateProperties({
                        first: 100,
                        page: 1,
                        createdBetween: undefined,
                    });
                }}
                save={() => {
                    updateProperties({
                        first: rowsPerPage,
                        page: 1,
                        createdBetween: { from: moment(date.from as any).format("YYYY-MM-DD"), to: moment(date.to as any).format("YYYY-MM-DD") },
                    });
                }}
            />

            {variables.createdBetween === undefined && variables.module === undefined && variables.action === undefined ? (
                <Button sx={{ marginRight: "8px", marginBottom: "8px" }} disabled size="small">
                    Wis selectie
                </Button>
            ) : (
                <Button
                    sx={{ marginRight: "8px", marginBottom: "8px" }}
                    size="small"
                    onClick={() => {
                        setChosenAction("");
                        setChosenModule("");
                        updateProperties({
                            first: 100,
                            page: 1,
                            createdBetween: undefined,
                            action: undefined,
                            module: undefined,
                        });
                    }}
                >
                    Wis selectie
                </Button>
            )}

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                {/* <span>{data?.activities.paginatorInfo.total}</span> */}
            </Grid>

            {/*
             * Grid with content in return on bottom of component.
             * Now the searchbar and filters wont be rerendered
             */}
            <Grid item xs={12}>
                {content}
            </Grid>
        </Grid>
    );

    if (initialLoading) {
        return renderPage(
            <div style={{ width: "100%", height: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div>
                    {/* <FullPageSpinner sx={{ color: "#d4d4d4" }} /> */}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#d4d4d4" }} />
                    </div>
                    <Typography
                        variant="h5"
                        style={{
                            color: "#d4d4d4",
                            fontSize: "1.3rem",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            marginTop: "8px",
                        }}
                    >
                        Logboek ophalen...
                    </Typography>
                </div>
            </div>
        );
    }

    if (notifications.length === 0 && loading) {
        return renderPage(
            <div style={{ width: "100%", height: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div>
                    {/* <FullPageSpinner sx={{ color: "#d4d4d4" }} /> */}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#d4d4d4" }} />
                    </div>
                    <Typography
                        variant="h5"
                        style={{
                            color: "#d4d4d4",
                            fontSize: "1.3rem",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            marginTop: "8px",
                        }}
                    >
                        Logboek ophalen...
                    </Typography>
                </div>
            </div>
        );
    }

    if (error || (!notifications && notifications === undefined && notifications === null) || data?.activities.paginatorInfo.total === 0) {
        return renderPage(
            <div style={{ width: "100%", height: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div>
                    <FormatListBulleted sx={{ height: "15rem", width: "15rem", color: "#e1e1e1" }} />
                    <Typography
                        variant="h5"
                        style={{ color: "#d4d4d4", fontSize: "1.7rem", display: "flex", alignItems: "center", flexDirection: "column" }}
                    >
                        Geen items gevonden
                    </Typography>

                    {!noFiltersActive && <Button>Verversen</Button>}
                </div>
            </div>
        );
    }

    return renderPage(
        <React.Fragment>
            <Disable disabled={loading}>
                <div id="log-timeline" style={{ marginTop: "16px", height: "70vh" }}>
                    <InfiniteScroll
                        height={"70vh"}
                        /**
                         * Fetch more data if 95% is scrolled
                         */
                        scrollThreshold={0.9}
                        /**
                         * id of menulist to target the menu as window instead of whole window
                         */
                        scrollableTarget="log-timeline"
                        /**
                         * This is important field to render the next data.
                         */
                        dataLength={notifications.length} //This is important field to render the next data
                        /**
                         * function to get next data
                         */
                        next={() => setRowsPerPage(rowsPerPage + 100)}
                        /**
                         * boolean to check if more data is available
                         */
                        hasMore={Boolean(data?.activities?.paginatorInfo.hasMorePages)}
                        /**
                         * loader component
                         */
                        loader={
                            loading && (
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                    <CircularProgress classes={{ root: "spinner" }} size={18} />
                                </div>
                            )
                        }
                        /**
                         * message when bottom is reached
                         */
                        endMessage={<p style={{ textAlign: "center" }} />}
                    >
                        <Timeline position="right">
                            {notifications.map((log, i) => {
                                // const obj = JSON.parse(log?.properties);
                                // const item = Object.entries(obj).map((i) => i[1]);
                                return (
                                    <LogTimeLine
                                        key={i}
                                        item={[]}
                                        log={log}
                                        onClickEmail={handleClickOpenEmailDialog}
                                        onClickOpen={handleClickOpenInformationDialog}
                                    />
                                );
                            })}
                        </Timeline>
                    </InfiniteScroll>
                </div>
            </Disable>
            {/* Loading spinner */}
            <div style={{ display: "flex", justifyContent: "center" }}>
                {loading && (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress classes={{ root: "spinner" }} size={18} />
                    </div>
                )}
            </div>
            {/* Modal to open to read info like user agent etc. */}
            <InformationDialog open={openDialog} onClose={handleCloseInformationDialog} dialogTitle={"User Agent"} dialogText={"text"} />
            {/* Modal to open email */}
            <EmailModal open={openEmailDialog.open} onClose={handleCloseEmailDialog} emailURL={openEmailDialog.link} />
        </React.Fragment>
    );
};

export default Logbook;

export const QUERY_GET_USER_LOGS_WITH_FILTERES = gql`
    query GetUserLogsWithFilters($createdBetween: DateRange, $first: Int!, $page: Int, $module: String, $action: String, $orderBy: OrderByClause!) {
        activities(createdBetween: $createdBetween, first: $first, page: $page, module: $module, action: $action, orderBy: $orderBy) {
            data {
                created_at
                causer {
                    id
                    employee {
                        id
                        givenname
                        middlename
                        familyname
                        name
                    }
                }
                description
                action
                channel
                module
                className

                subject {
                    ### PROJECT ###
                    ... on UserProject {
                        id
                        title
                        state {
                            id
                        }
                        amountUsers
                        amountTasks
                        amountNotes
                    }
                    ### TASKS ###
                    ... on UserTask {
                        id
                        task_completed
                        task_deadline
                        project {
                            id
                            title
                            state {
                                id
                            }
                        }
                        users {
                            id
                            employee {
                                id
                                name
                            }
                        }
                        task_owner {
                            id
                            employee {
                                id
                                name
                            }
                        }
                        task_description
                        task_priority
                        task_completed_at
                    }
                    ### COMMENT ###
                    ... on Comment {
                        id
                        comment
                        project {
                            id
                            title
                            state {
                                id
                            }
                        }
                        user {
                            id
                            employee {
                                id
                                name
                            }
                        }
                    }
                    ### COMMENTREPLY ###
                    ... on CommentReply {
                        id
                        comment
                        project {
                            id
                            title
                            state {
                                id
                            }
                        }
                        user {
                            id
                            employee {
                                id
                                name
                            }
                        }
                    }
                    ### PROJECTSTATE ###
                    ... on ProjectState {
                        id
                        name
                    }

                    ### TENDER ###
                    ... on Tender {
                        id
                        namegiventocontract
                    }
                    ### SEARCH ###
                    ... on Search {
                        id
                        namesearch: name
                    }
                    ### ORGANIZATION ###
                    ... on Organization {
                        id
                        name
                    }
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;
