import { createContext, useContext } from "react";

export type FirstSearchruleContextType = {
    wordModalTourOpen: boolean;
    setWordModalTourOpen: (wordModalTourOpen: boolean) => void;
    domainModalTourOpen: boolean;
    setDomainModalTourOpen: (domainModalTourOpen: boolean) => void;
    resultModalTourOpen: boolean;
    setResultModalTourOpen: (resultModalTourOpen: boolean) => void;
    distModalTourOpen: boolean;
    setDistModalTourOpen: (distModalTourOpen: boolean) => void;
};

export const FirstSearchRuleContext = createContext<FirstSearchruleContextType>({
    wordModalTourOpen: false,
    setWordModalTourOpen: (wordModalTourOpen: boolean) => console.warn("wordModalTourOpen boolean"),
    domainModalTourOpen: false,
    setDomainModalTourOpen: (domainModalTourOpen: boolean) => console.warn("domainModalTourOpen boolean"),
    resultModalTourOpen: false,
    setResultModalTourOpen: (resultModalTourOpen: boolean) => console.warn("resultModalTourOpen boolean"),
    distModalTourOpen: false,
    setDistModalTourOpen: (distModalTourOpen: boolean) => console.warn("distModalTourOpen boolean"),
});
export const useFirstSearchRuleSetter = () => useContext(FirstSearchRuleContext);
