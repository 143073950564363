import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// Material UI components
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

interface Props {
    /**
     * filter is active
     */
    active: boolean;
    /**
     * filter name
     */
    label: string;
    /**
     * setState to filtername
     */
    setOpenFilter(): void;
    /**
     * chosen options
     */
    selected: string[];
    /**
     * number of chosen options
     */
    noItems: number;
    /**
     * loading state
     * true => disabled, false => clickable
     */
    disable: boolean;
    loading: boolean;
}
const FilterTitleLine: React.FC<Props> = ({ label, setOpenFilter, selected, noItems, active, disable, loading }) => {
    const { t } = useTranslation();
    return (
        <ListItem disabled={disable} button onClick={setOpenFilter}>
            {/*
             * name of filter
             * bold style if filter is active
             */}
            <ListItemText primary={<Typography style={{ fontWeight: selected?.length === 0 ? "normal" : 600 }}>{t(`filter.${label}`)}</Typography>} />
            {/*
             * ArrowRight to show user its possible to click item
             * if noItems === 0 dont show arrow (item is also disabled)
             */}
            {loading ? <CircularProgress classes={{ root: "spinner" }} size={18} /> : <KeyboardArrowRightIcon />}
        </ListItem>
    );
};

export default FilterTitleLine;
