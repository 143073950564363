import * as React from "react";
import moment from "moment";
import { Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import TodayIndicator from "./TodayIndicator";
import DateIndicator from "./DateIndicator";
import TimeBetweenDates from "./TimeBetweenDates";
import UndeterminedEndDate from "./UndeterminedEndDate";

interface Props {
    start: string | null;
    end: string | null;
    /**
     * Undetermined period boolean
     */
    undetermined: boolean;
    /**
     * means end date is not set
     */
    noEndDateAvailable: boolean;
    options: string[];
    datum_gunning_as_start: boolean;
}

interface Step {
    date: string;
    title: string;
}

const HorizontalContractTimeline: React.FC<Props> = ({ start, end, undetermined, options, datum_gunning_as_start, noEndDateAvailable }) => {
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // Empty array that will be filled with tender dates through props
    const arrayWithDates = [] as Step[];

    // If startDate is not empty => add to array otherwise return empty array
    const arrWithStart =
        start !== null
            ? arrayWithDates.concat({ date: moment(start).format(), title: datum_gunning_as_start ? "Datum gunning" : "Initiële startdatum" })
            : arrayWithDates;
    // If endDate is not empty => add to array with a possible startdate in it
    const arrWithStartAndEnd = end !== null ? arrWithStart.concat({ date: moment(end).format(), title: "Initiële einddatum" }) : arrWithStart;

    const arrWithOptions =
        options && options.length > 0
            ? arrWithStartAndEnd.concat(
                  options.map((date, index) => ({
                      date: moment(date).format(),
                      title: `Einddatum optie ${index + 1}`,
                  })) || []
              )
            : arrWithStartAndEnd;

    /**
     * If start and end are both null dont show anything
     */
    if (!start) {
        return <></>;
    }

    /**
     * Today objects
     */
    const today = {
        date: moment().format(),
        title: "Nu",
    };

    /**
     * Add today as date in array and sort on date old => new
     * Array is used to map timeline with todayindicator included
     */
    const sortedArray = arrWithOptions
        .concat(today)
        .map((obj, i) => {
            return {
                id: i,
                date: moment(obj.date),
                title: obj.title,
            };
        })
        .sort((a, b) => moment(a.date).diff(moment(b.date)));

    /**
     * Array sorted withotu today object in between to calculate periods between dates (in years)
     */
    const sortedArrayWithoutToday = arrWithOptions
        .map((obj, i) => {
            return {
                id: i,
                date: moment(obj.date),
                title: obj.title,
            };
        })
        .sort((a, b) => moment(a.date).diff(moment(b.date)));

    /**
     * First object in array
     */
    const firstItemInArray = sortedArrayWithoutToday[0];

    /**
     * Index last item in array
     */
    const IndexLastItemInArray = sortedArrayWithoutToday.length - 1;

    /**
     * Last item in array with today included
     */
    const lastItemInArray = sortedArrayWithoutToday[IndexLastItemInArray];

    /**
     * Total length of contract
     */
    const totalLengthOfContract = sortedArrayWithoutToday[IndexLastItemInArray].date.diff(sortedArrayWithoutToday[0].date, "days");
    /**
     * Check difference between todat and first timeline item in days
     */
    const diffToday = moment(today.date).diff(firstItemInArray.date, "days");

    /**
     * Get percentage of length between (today + firstdate) and total length of contract
     */
    const passedDates = (diffToday / totalLengthOfContract) * 100;

    /**
     * Check difference between todat and first timeline item in days
     */
    const diffEnd = moment(sortedArrayWithoutToday.find((i) => i.title === "Initiële einddatum")?.date).diff(firstItemInArray.date, "days");

    /**
     * Get percentage of length between (today + firstdate) and total length of contract
     */
    const endDate = (diffEnd / totalLengthOfContract) * 100;

    /**
     * Check if today is between first and last date
     */
    const todayIsInBetween = moment(today.date).isBetween(moment(firstItemInArray.date).subtract(1, "days"), lastItemInArray.date);

    /**
     * Check if today is after last in array
     */
    const contractInPassed = moment(today.date).isSameOrAfter(lastItemInArray.date);

    /**
     * If a tender has no enddate show timeline with startdate only
     */
    if (noEndDateAvailable && undetermined === false) {
        return <UndeterminedEndDate onlyMobile={onlyMobile} start={start} today={today.date} noEndDateAvailable={true} />;
    }

    /**
     * If a tender has undetermined = true show timeline with startdate and text 'onbepaalde duur' at the end of the line.
     */
    if (undetermined) {
        return <UndeterminedEndDate onlyMobile={onlyMobile} start={start} today={today.date} noEndDateAvailable={false} />;
    }

    /**
     * TODO: scenario: tender has options and undefinite = true
     * Calculate a timeline for available dates and a small bit with text 'opties: onbepaalde duur'
     */

    return (
        <>
            {/*
             * Component to show amount of years between two dates
             */}
            <TimeBetweenDates data={sortedArrayWithoutToday} totalLengthOfContract={totalLengthOfContract} />

            {/*
             * Horizontal timeline items
             */}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "48px",
                    marginLeft: "-8px",
                    position: "relative",
                }}
            >
                <div style={{ width: "90%", display: "flex", flexDirection: "row", position: "absolute" }}>
                    {/*
                     * Base timeline
                     * Blue => in past
                     * Grey => in future
                     */}
                    <div
                        style={{
                            height: "4px",
                            backgroundColor: contractInPassed ? "#173357" : "#dfdfdf",
                            width: "100%",
                            marginLeft: "4px",
                            marginRight: "-10px",
                        }}
                    />

                    {/*
                     * Fill line from start till 'today' with a blue color
                     */}
                    {todayIsInBetween && (
                        <div
                            style={{
                                height: "4px",
                                background: "#173357",
                                width: `${passedDates}%`,
                                marginLeft: "4px",
                                marginRight: "-10px",
                                position: "absolute",
                            }}
                        />
                    )}

                    {/*
                     * Gradient
                     */}
                    {todayIsInBetween && (
                        <div
                            style={{
                                height: "4px",
                                background: `linear-gradient(90deg, rgba(23,51,87,1) 0%,  #dfdfdf 100%)`,
                                width: passedDates > 89 ? 0 : `75px`,
                                // marginLeft: "10px",
                                marginRight: "-10px",
                                position: "absolute",
                                marginLeft: `${passedDates}%`,
                            }}
                        />
                    )}

                    {/*
                     * Show start Date (first item of array)
                     */}
                    <div
                        style={{
                            left: "6px",
                            position: "absolute",
                        }}
                    >
                        {datum_gunning_as_start ? (
                            <Tooltip
                                disableInteractive
                                title="Datum gunning"
                                slotProps={{
                                    popper: {
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, -10],
                                                },
                                            },
                                        ],
                                    },
                                }}
                            >
                                <Typography variant="caption" sx={{ fontStyle: "italic", cursor: "pointer" }}>
                                    {onlyMobile ? moment(firstItemInArray.date).format("DD-MM-YY") : moment(firstItemInArray.date).format("L")}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography variant="caption">
                                {onlyMobile ? moment(firstItemInArray.date).format("DD-MM-YY") : moment(firstItemInArray.date).format("L")}
                            </Typography>
                        )}
                    </div>

                    {/*
                     * Show end Date (last item of array)
                     */}
                    <div
                        style={{
                            position: "absolute",
                            // right: "2px",
                            marginLeft: options.length >= 5 ? `calc(${endDate}% + 4px)` : `calc(${endDate}% - 70px)`,
                            top: options.length >= 5 ? "14px" : "0px",
                        }}
                    >
                        <Typography variant="caption">
                            {onlyMobile
                                ? `${moment(sortedArrayWithoutToday.find((date) => date.title === "Initiële einddatum")?.date).format("DD-MM-YY")}`
                                : `${moment(sortedArrayWithoutToday.find((date) => date.title === "Initiële einddatum")?.date).format("L")}`}
                        </Typography>
                    </div>

                    {/*
                     * options
                     */}
                    {lastItemInArray.title !== "Initiële einddatum" && options.length > 0 && (
                        <div
                            style={{
                                position: "absolute",
                                right: "2px",
                            }}
                        >
                            <Typography variant="caption">
                                {onlyMobile ? `${moment(lastItemInArray.date).format("DD-MM-YY")}` : `${moment(lastItemInArray.date).format("L")}`}
                            </Typography>
                        </div>
                    )}

                    {/*
                     * Map array with dates (today included)
                     */}
                    {sortedArray.map((step, i) => {
                        // Length in days from current step to first day
                        const lengthInDaysBetweenStartAndCurrentStep = moment(step.date).diff(firstItemInArray.date, "days");
                        // Get position in '%'
                        const widthPercentage = (lengthInDaysBetweenStartAndCurrentStep / totalLengthOfContract) * 100;

                        /**
                         *'Today' has another indicator
                         */
                        if (step.title === "Nu" && todayIsInBetween) {
                            return <TodayIndicator key={i} marginLeft={widthPercentage} />;
                        }
                        /**
                         * Empty div when today is not between first and last date
                         */
                        if (step.title === "Nu" && !todayIsInBetween) {
                            return <></>;
                        }

                        /**
                         * Vertical line for each date except 'today'
                         */
                        return (
                            <DateIndicator
                                key={i}
                                bgColor={step.date.isSameOrBefore(today.date) ? "#173357" : "#dfdfdf"}
                                title={step.title}
                                date={step.date}
                                marginLeft={widthPercentage}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};
export default HorizontalContractTimeline;
