import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import React from "react";
import { QUERY_SINGLE_OPPORTUNITY_UNREVIEWED_PAGES } from "../../graphql/opportunityQueries/queryDefinitions";
import { GetSingleOppUnreviewedPages, GetSingleOppUnreviewedPagesVariables } from "../../__generated__/GetSingleOppUnreviewedPages";

interface Props {
    opp_file_id: string;
}

const OppUnreviewedPages: React.FC<Props> = ({ opp_file_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetSingleOppUnreviewedPages, GetSingleOppUnreviewedPagesVariables>(QUERY_SINGLE_OPPORTUNITY_UNREVIEWED_PAGES, {
        variables: { id: opp_file_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.opportunityFile) {
        return <Typography>{""}</Typography>;
    }

    const opp = data.opportunityFile;

    return <Typography>{opp.unreviewed_page_count}</Typography>;
};

export default OppUnreviewedPages;
