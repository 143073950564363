import React from "react";
import { Card, CardActions, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import FaviconBox from "../../components/FaviconBox";
import { QUERY_USER_LIKED_CAS, QUERY_USER_LIKED_ORGS } from "../../graphql/queryDefCurrentUser";
import { QUERY_MY_TENDERERS } from "../../graphql/queryDefOrg";
import { QUERY_MY_CONTRACTING_AUTHORITIES } from "../../graphql/queryDefCA";
import { dataProps } from "./starredOrganizations/CardView";
import CountryFlagBox from "../../components/boxes/CountryFlagBox";
import { NorthEast, Numbers, Update } from "@mui/icons-material";
import moment from "moment";
import SmallOrgMenu from "./SmallOrgMenu";
import SmallCaMenu from "../contractingAuthoritiesModule/SmallCaMenu";
import { useNavigate } from "react-router-dom";

interface Props {
    type: "ca" | "mp";
    data: dataProps;
}

const OrganizationMobileCard: React.FC<Props> = ({ type, data }) => {
    const pink = "#E03660";
    const yellow = "#F57117";
    const currentColor = type === "mp" ? pink : type === "ca" ? yellow : "grey";
    const currentUrl = type === "mp" ? `/organizations/${data.id}` : `/contracting-authorities/${data.id}`;
    const navigate = useNavigate();

    return (
        <Card elevation={0} square sx={{ m: 1, borderTop: `5px solid ${currentColor}` }}>
            <CardHeader
                sx={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    display: "flex",
                    alignItems: "center",
                    ".MuiCardHeader-avatar": {
                        marginRight: 0,
                    },
                }}
                avatar={<FaviconBox favicon={data.favicon} color={currentColor} name={data.name} />}
                action={
                    <IconButton sx={{ padding: 0, paddingRight: "8px" }} onClick={() => navigate(currentUrl)}>
                        <NorthEast sx={{ height: "18px" }} />
                    </IconButton>
                }
                title={
                    <Typography variant="h3" onClick={() => navigate(currentUrl)}>
                        {data.name}
                    </Typography>
                }
            />
            <CardContent sx={{ padding: "0px 8px 8px" }}>
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CountryFlagBox code={data.country} /> <Typography style={{ marginLeft: "8px" }}>{data.place}</Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Numbers sx={{ height: "16px", marginRight: "2px" }} /> <Typography style={{}}>{data.org_number}</Typography>
                    </div>
                </div>
            </CardContent>
            <CardActions disableSpacing sx={{ padding: "8px", display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Update sx={{ height: "14px", marginRight: "2px", color: "grey" }} />{" "}
                    <Typography sx={{ color: "grey" }} variant="caption">
                        {moment(data.last_update).format("DD-MM-YYYY")}
                    </Typography>
                </div>

                {type === "mp" ? (
                    <SmallOrgMenu org_name={data.name} org_id={data.id} refetchQueries={[QUERY_USER_LIKED_ORGS, QUERY_MY_TENDERERS]} />
                ) : (
                    <SmallCaMenu ca_id={data.id} ca_name={data.name} refetchQueries={[QUERY_USER_LIKED_CAS, QUERY_MY_CONTRACTING_AUTHORITIES]} />
                )}
            </CardActions>
        </Card>
    );
};

export default OrganizationMobileCard;
