import React, { useState } from "react";
import { CircularProgress, IconButton, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import OptionCard from "./OptionCard";
import { QrCode2, Send, Lock, Email, Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAuthSettings } from "./AuthSettingsContext";
import { Disable } from "react-disable";

interface Props {
    a?: number;
}

const EnableBothAuthenticationOptions: React.FC<Props> = () => {
    const { enable2FA, passwordIncorrect, data_two_factor, loading, clickedOption, setClickedOption, setActivateBoth, setpw } = useAuthSettings();
    const [password2FA, setPassword2FA] = useState<string>("");
    const { t } = useTranslation();

    return (
        <Disable
            disabled={Boolean(data_two_factor?.currentUser.two_factor_totp_enabled) || Boolean(data_two_factor?.currentUser.two_factor_email_enabled)}
        >
            {clickedOption === "both" &&
            !Boolean(data_two_factor?.currentUser.two_factor_totp_enabled) &&
            !Boolean(data_two_factor?.currentUser.two_factor_email_enabled) ? (
                <OptionCard
                    option="BOTH"
                    align="end"
                    success={Boolean(data_two_factor?.currentUser.two_factor_totp_enabled)}
                    onClick={() => {
                        setClickedOption("");
                        setPassword2FA("");
                    }}
                    disabled={false}
                    icon={
                        loading.loadingState ? (
                            <CircularProgress sx={{ color: "#508c79" }} />
                        ) : (
                            <Lock sx={{ color: "#508c79", width: "56px", height: "56px" }} />
                        )
                    }
                    text={passwordIncorrect ? "Wachtwoord incorrect" : "Vul je wachtwoord in"}
                    wrongPW={passwordIncorrect}
                >
                    <OutlinedInput
                        error={passwordIncorrect}
                        onClick={(e) => e.stopPropagation()}
                        type="password"
                        placeholder={t("auth.password")}
                        value={password2FA}
                        onChange={(e) => setPassword2FA(e.target.value)}
                        fullWidth
                        size="small"
                        autoFocus
                        autoComplete="current-password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    size="small"
                                    onClick={() => {
                                        enable2FA(password2FA);
                                        setActivateBoth(true);
                                        setpw(password2FA);
                                    }}
                                    edge="end"
                                >
                                    {<Send fontSize="small" />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </OptionCard>
            ) : (
                <OptionCard
                    option="BOTH"
                    onClick={() => {
                        if (
                            !Boolean(data_two_factor?.currentUser.two_factor_totp_enabled) &&
                            !Boolean(data_two_factor?.currentUser.two_factor_email_enabled)
                        ) {
                            setClickedOption("both");
                        }
                    }}
                    align="end"
                    success={
                        Boolean(data_two_factor?.currentUser.two_factor_totp_enabled) &&
                        Boolean(data_two_factor?.currentUser.two_factor_email_enabled)
                    }
                    avatarColor="#508c79"
                    disabled={false}
                    icon={
                        loading.loadingState ? (
                            <CircularProgress />
                        ) : (
                            <div
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <QrCode2 style={{ height: "36px", width: "36px", position: "absolute", left: -4, top: -4 }} />
                                <div style={{ borderRadius: "50%", width: 24, height: 24, backgroundColor: "#508c79", zIndex: 99 }}>
                                    <Add />
                                </div>
                                <Email style={{ height: "36px", width: "36px", position: "absolute", right: -4, bottom: -4 }} />
                            </div>
                        )
                    }
                    text={
                        passwordIncorrect
                            ? "Wachtwoord incorrect"
                            : Boolean(data_two_factor?.currentUser.two_factor_totp_enabled)
                            ? "Authenticator app ingeschakeld"
                            : "Authenticator app + e-mail "
                    }
                    wrongPW={passwordIncorrect}
                >
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <Typography textAlign={"center"} variant="body2" sx={{ marginBottom: "8px", color: "text.secondary" }}>
                            Activeer beide opties
                        </Typography>
                    </div>
                </OptionCard>
            )}
        </Disable>
    );
};

export default EnableBothAuthenticationOptions;
