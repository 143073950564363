import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useLocation } from "react-router-dom";

// MUI import
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import ThumbDown from "@mui/icons-material/ThumbDown";

// GQL
import { FetchResult, InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { dislikeTender, dislikeTenderVariables } from "../__generated__/dislikeTender";
import { DISLIKETENDER } from "../graphql/mutationDefinitions";

interface Props {
    tender_id: string;
    buttonSize: "small" | "medium";
    onClick?: () => void;
    refetchQueries?: InternalRefetchQueriesInclude | ((result: FetchResult<dislikeTender>) => InternalRefetchQueriesInclude) | undefined;
}
const DislikeTenderButton: React.FC<Props> = ({ tender_id, buttonSize, onClick, refetchQueries }) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const { pathname } = useLocation();

    // Track event dislike tender
    const trackDislike = () => {
        if (pathname === "/dashboard") trackEvent({ category: "Dashboard", action: "dislike-tender" });
        if (pathname.includes("tender")) trackEvent({ category: "Tender", action: "dislike-tender" });
        if (pathname.includes("contracting-authorities")) trackEvent({ category: "Ca", action: "dislike-tender" });
        if (pathname.includes("organizations")) trackEvent({ category: "Organizations", action: "dislike-tender" });
    };

    const [save, { error, loading }] = useMutation<dislikeTender, dislikeTenderVariables>(DISLIKETENDER);

    // Function to like a tender
    const dislikeTender = async () => {
        if (loading) return;
        if (error) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await save({
                variables: {
                    tenderID: tender_id,
                    reason: "",
                    remark: "",
                },
                // Refetch get_dashboard_worklist to update the worklist
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        <Tooltip enterNextDelay={100} title={t("not-interesting") as string} placement="top">
            <IconButton
                disabled={loading}
                onClick={(e) => {
                    e.stopPropagation();
                    dislikeTender();
                    onClick && onClick();
                    trackDislike();
                }}
                size={buttonSize}
            >
                <ThumbDown fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

export default DislikeTenderButton;
