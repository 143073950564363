import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import StarIcon from "@mui/icons-material/Star";
import IconButton from "@mui/material/IconButton";
import { GetLikedContractingAuthoritiesBack } from "../__generated__/GetLikedContractingAuthoritiesBack";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { likeContractingAuthority, likeContractingAuthorityVariables } from "../__generated__/likeContractingAuthority";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../models/MatomoEvent";
import { LIKECONTRACTING_AUTHORITY } from "../graphql/mutationDefinitions";
import { QUERY_USER_LIKED_CAS } from "../graphql/queryDefCurrentUser";

interface Props {
    buttonSize?: "small" | "medium";
    color?: string;
    selection: any;
    menuItem?: boolean;
    onClose?: () => void;
}

const LikeSelectedCas: React.FC<Props> = ({ selection: selectedArr, color, buttonSize, menuItem, onClose }) => {
    const { trackEvent } = useMatomo();

    // Track event
    const trackLikeCas = (event: MatomoEvent) => {
        trackEvent(event);
    };
    /**
     * Get liked CA's
     */
    const { error: errorQuery, loading: loadQuery, data: dataQuery } = useQuery<GetLikedContractingAuthoritiesBack>(QUERY_USER_LIKED_CAS);
    const [queryData, setQueryData] = useState(dataQuery);
    const { t } = useTranslation();

    useEffect(() => {
        if (loadQuery === false && dataQuery) {
            setQueryData(dataQuery);
        }
    }, [loadQuery, dataQuery]);

    /**
     * mutation to like a CA
     */
    const [like, { error: errorLike, loading: loadingLike }] = useMutation<likeContractingAuthority, likeContractingAuthorityVariables>(
        LIKECONTRACTING_AUTHORITY
    );

    if (errorQuery || queryData?.currentUser === undefined || queryData?.currentUser === null) {
        return null;
    }
    const likedCAS = queryData.currentUser.contracting_authorities;

    /**
     * Like all selected CA's
     */
    const likeSelectedCas = () => {
        const selection = selectedArr;
        const unique = [...new Set(selection.map((item: any) => item))] as any;

        const arr = unique.filter((i: string) => !likedCAS.some((item) => i === item.id));

        /**
         * if arr is empty, then all CA's are already starred.
         * if user clicks on button, then CA's star will be removed.
         * if arr is > 0 then CA's will be starred.
         */
        if (arr.length > 0) {
            arr.forEach(async (item: string) => {
                // Function to like organization
                if (loadingLike) {
                }
                if (errorLike) {
                    toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await like({
                        variables: {
                            ca_id: item,
                        },
                        refetchQueries: [QUERY_USER_LIKED_CAS, "GetLikedContractingAuthoritiesBack"],
                    });
                } catch (e) {
                    toast.error("Fout tijdens opslaan (catch)");
                }
            });
            onClose?.();
        } else {
            onClose?.();
        }
    };

    return (
        <React.Fragment>
            {menuItem ? (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        likeSelectedCas();
                        trackLikeCas({ category: "Tender", action: "Click-like-all-cas-from-tender-detail" });
                    }}
                >
                    {t("AddStar")}
                </MenuItem>
            ) : (
                <IconButton
                    disabled={loadingLike}
                    onClick={(e) => {
                        e.stopPropagation();
                        likeSelectedCas();
                        trackLikeCas({ category: "Tender", action: "Click-like-all-cas-from-tender-detail" });
                    }}
                    size={buttonSize}
                >
                    <StarIcon fontSize="small" style={{ color: color }} />
                </IconButton>
            )}
        </React.Fragment>
    );
};

export default LikeSelectedCas;
