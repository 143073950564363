/* eslint-disable no-loop-func */
import React, { useState } from "react";
import { useTheme } from "@mui/material";
import moment from "moment";
import "./calendar.scss";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { SavedTenderEvents_getCalendarEvents } from "../../__generated__/SavedTenderEvents";
import TenderCalendarEvent from "./TenderCalendarEvent";
import UserCalendarEvent from "./UserCalendarEvent";
import TenderDateEvent from "./TenderDateEvent";

interface Props {
    currentMonth: string;
    selectedDate: string;
    data: (SavedTenderEvents_getCalendarEvents | null)[] | undefined;
}

const MonthView: React.FC<Props> = ({ currentMonth, selectedDate, data }) => {
    const { trackEvent } = useMatomo();

    /**
     * @param event clicked event
     */
    const trackCalendarToTender = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * @param id id of clicked event
     */
    const setId = (id: string) => {
        trackCalendarToTender({ category: "Calendar", action: "Go-from-calendar-to-tender", value: parseInt(id) });
    };

    return (
        <div>
            <div className="calendar">
                <Days />
                <Cells currentMonth={currentMonth} selectedDate={selectedDate} setId={setId} data={data} />
            </div>
        </div>
    );
};

/**
 * @returns day with event(s)
 */
const Days = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <div className="days row">
            {
                // eslint-disable-next-line prefer-spread
                Array.apply(null, Array(7)).map(function (_, i) {
                    return (
                        <div className="col col-center" key={i}>
                            {isMobile ? (
                                <Typography style={{ fontSize: "100%" }}>
                                    {moment(i, "e")
                                        .startOf("week")
                                        .isoWeekday(i + 1)
                                        .format("dd")}
                                </Typography>
                            ) : (
                                <Typography style={{ fontSize: "100%" }}>
                                    {moment(i, "e")
                                        .startOf("week")
                                        .isoWeekday(i + 1)
                                        .format("dddd")}
                                </Typography>
                            )}
                        </div>
                    );
                })
            }
        </div>
    );
};

/**
 * Individual cells in the calendar
 */
const Cells = ({ currentMonth, selectedDate, setId, data }: any) => {
    const monthStart = moment(currentMonth).startOf("month").format();
    const monthEnd = moment(monthStart).endOf("month").format();
    const startDate = moment(monthStart).startOf("week").format();
    const endDate = moment(monthEnd).endOf("week").format();
    const [activeTooltip, setActiveTooltip] = useState<string | false>(false);
    const [openId, setOpenId] = useState<string>("");

    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    let dateValue = "";

    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            formattedDate = moment(day).format("D");
            dateValue = moment(day).format();

            days.push(
                <div
                    className={`col cell height ${
                        !moment(day).isSame(currentMonth, "month")
                            ? "disabled"
                            : moment(day).isSame(selectedDate)
                            ? "selected"
                            : moment().isSame(day, "day")
                            ? "selected"
                            : ""
                    }`}
                    style={{ height: "10.5em !important" }}
                    key={day}
                >
                    <span className="number">{formattedDate}</span>
                    {i === 0 && <span className="weeknumber">wk {moment(day).week()}</span>}
                    {/* TODO: Create component for single item */}
                    <div style={{ width: "100%", height: "100%", marginTop: "24px", overflow: "auto", paddingBottom: "30px" }}>
                        {data.map((item: SavedTenderEvents_getCalendarEvents, index: number) => {
                            /**
                             * TenderEvent component
                             */
                            if (item.__typename === "TenderEvent" && moment(item.planned).isSame(dateValue, "day")) {
                                return (
                                    <TenderCalendarEvent
                                        openId={openId}
                                        setOpenId={setOpenId}
                                        key={item.id}
                                        event={item}
                                        setActiveTooltip={setActiveTooltip}
                                        setId={setId}
                                        activeTooltip={activeTooltip}
                                    />
                                );
                            }
                            /**
                             * Tender date - deadline / start / end
                             */
                            if (item.__typename === "TenderDate" && moment(item.planned).isSame(dateValue, "day")) {
                                return (
                                    <TenderDateEvent
                                        openId={openId}
                                        setOpenId={setOpenId}
                                        key={item.id}
                                        event={item}
                                        setActiveTooltip={setActiveTooltip}
                                        setId={setId}
                                        activeTooltip={activeTooltip}
                                    />
                                );
                            }

                            /**
                             * UserEvent component
                             */
                            if (item.__typename === "UserEvent" && moment(item.start).isSame(dateValue, "day")) {
                                const id = `${item.title}${index}`;
                                return (
                                    <UserCalendarEvent
                                        key={id}
                                        id={id}
                                        event={item}
                                        setActiveTooltip={setActiveTooltip}
                                        setId={setId}
                                        activeTooltip={activeTooltip}
                                    />
                                );
                            }

                            /**
                             * Else return empty div
                             */
                            return <></>;
                        })}
                        <span className="bg">{formattedDate}</span>
                    </div>
                </div>
            );
            day = moment(day).add(1, "d").format();
        }
        rows.push(
            <div className="row" key={day}>
                {days}
            </div>
        );
        days = [];
    }

    return <div className="body">{rows}</div>;
};
export default MonthView;
