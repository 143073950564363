import React from "react";
import { GetSectorsColumn, GetSectorsColumnVariables } from "../../__generated__/GetSectorsColumn";
import { useQuery } from "@apollo/client";
import { GET_SECTORS_COLUMN } from "../../graphql/queryDefForColumns";
import { Typography } from "@mui/material";

interface Props {
    tender_id: string;
}

const SectorColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetSectorsColumn, GetSectorsColumnVariables>(GET_SECTORS_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    // get unique sectornames
    const uniqueSectors = [...new Set(tender.sectors.map((sector) => sector.parent?.name))];

    const names = uniqueSectors.join("\n");

    return <Typography style={{ whiteSpace: "pre-wrap" }}>{names}</Typography>;
};

export default SectorColumn;
