import React, { useState } from "react";

// MUI import
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

// GQL
import { useMutation } from "@apollo/client";
import { dislikeTenderBatch, dislikeTenderBatchVariables } from "../__generated__/dislikeTenderBatch";
import {
    GET_DASHBOARD_WORKLIST,
    QUERY_FULL_WORKLIST_MINIMUM,
    QUERY_TENDERLIKED,
    QUERY_TENDERS_INTERESTING_MINIMUM,
} from "../graphql/queryDefinitions";
import { DISLIKE_SELECTED_TENDERS } from "../graphql/mutationDefinitions";

interface Props {
    setSelection: any;
    selection: any;
    buttonSize: "small" | "medium";
    iconSize: string;
}

const DislikeSelectedTenderButton: React.FC<Props> = ({ setSelection, selection: selectedArr, buttonSize, iconSize }) => {
    const { t } = useTranslation();
    const [isLoading, setIsloading] = useState<boolean>(false);

    /**
     * Get data to check if state is already set
     */
    // const { error: errorQuery, loading: loadQuery, data: dataQuery } = useQuery<GetLikedTendersOfUser>(QUERY_USER_LIKED_TENDERS);
    // const [queryData, setQueryData] = useState(dataQuery);

    // useEffect(() => {
    //     if (loadQuery === false && dataQuery) {
    //         setQueryData(dataQuery);
    //     }
    // }, [loadQuery, dataQuery]);

    const [save, { loading: loadingMutation, error: errorMutation }] = useMutation<dislikeTenderBatch, dislikeTenderBatchVariables>(
        DISLIKE_SELECTED_TENDERS
    );

    // if (errorQuery || queryData?.currentUser === undefined || queryData?.currentUser === null) {
    //     return null;
    // }
    // const likedTenders = queryData.currentUser.likedTenders;

    /**
     * Like all selected CA's
     */
    const dislikeSelectedTenders = () => {
        const selection = selectedArr;
        setIsloading(true);
        // const arr = selection.filter((i: string) => !likedTenders.some((item) => i === item.id));

        /**
         * if arr is empty, then all CA's are already starred.
         * if user clicks on button, then CA's star will be removed.
         * if arr is > 0 then CA's will be starred.
         */
        if (selection.length > 0) {
            selection.forEach(async (id: string) => {
                // Function to like organization
                if (loadingMutation) {
                }
                if (errorMutation) {
                    // toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await save({
                        variables: {
                            tenderID: id,
                            reason: "",
                            remark: "",
                        },
                        refetchQueries: [
                            QUERY_FULL_WORKLIST_MINIMUM,
                            "FilterWorklistMinimumOverview",
                            GET_DASHBOARD_WORKLIST,
                            "getWorklist",
                            QUERY_TENDERLIKED,
                            "GetTenderLikes",
                            QUERY_TENDERS_INTERESTING_MINIMUM,
                            "FilterInterestingMinimumTendersOverview",
                        ],
                        awaitRefetchQueries: true,
                    });
                } catch (e) {
                    // toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    setSelection([]);
                    setIsloading(false);
                }
            });
            // onClose?.();
        }
        // else {
        //     onClose?.();
        // }
    };

    return (
        <React.Fragment>
            <Tooltip enterNextDelay={100} title={t("not-interesting") as string} placement="top">
                <IconButton
                    disabled={loadingMutation || isLoading}
                    onClick={(e) => {
                        e.stopPropagation();
                        dislikeSelectedTenders();
                    }}
                    size={buttonSize}
                >
                    <ThumbDownIcon style={{ fontSize: iconSize }} />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

export default DislikeSelectedTenderButton;

// // ? Get list of liked tenders
// export const QUERY_USER_LIKED_TENDERS = gql`
//     query GetLikedTendersOfUser {
//         currentUser {
//             likedTenders {
//                 id
//             }
//         }
//     }
// `;
