// Hopefully this works

/**
 * //* HTML CODE -> ADD TO PAGE TO GENERATE PDF IN IT
 */
// <WidgetBox highlight="green" header="" padding={0}>
// <div id="pdfViewerDiv" style={{ height: "94vh", overflow: "auto" }}>
//     <div id="viewer" className="pdfViewer" />
//     <div id="annotation-layer" />
// </div>
// </WidgetBox>

interface Options {
    a: string;
    pageNumber?: number;
}

export default async ({ a, pageNumber }: Options) => {
    const PDFJS = window.pdfjsLib;
    const DEFAULT_SCALE = 1.33;
    const url = a;
    // First, we need to load the document using the getDocument utility
    const loadingTask = PDFJS.getDocument(url);
    const pdf = await loadingTask.promise;

    // Number of pages in the documtent
    const { numPages } = pdf;

    const viewer = document.getElementById("viewer");
    for (let i = 0; i < numPages; i++) {
        const page = createEmptyPage(i + 1);
        viewer?.appendChild(page);
    }

    loadPage(pageNumber || 1);

    document?.getElementById("pdfViewerDiv")?.addEventListener("scroll", handleWindowScroll);

    function createEmptyPage(num: any) {
        const page = document.createElement("div");
        const canvas = document.createElement("canvas");
        const wrapper = document.createElement("div");
        const textLayer = document.createElement("div");
        const annotationLayer = document.createElement("div");

        page.className = "page";
        wrapper.className = "canvasWrapper";
        textLayer.className = "textLayer";
        annotationLayer.className = "annotationLayer";

        page.setAttribute("id", `pageContainer${num}`);
        page.setAttribute("data-loaded", "false");
        page.setAttribute("data-page-number", num);
        canvas.setAttribute("id", `page${num}`);

        page.appendChild(wrapper);
        page.appendChild(textLayer);
        page.appendChild(annotationLayer);
        wrapper.appendChild(canvas);

        return page;
    }

    function loadPage(pageNum: number) {
        return pdf
            .getPage(pageNum)
            .then(
                (pdfPage: {
                    getViewport: (arg0: number) => any;
                    render: (arg0: { canvasContext: CanvasRenderingContext2D | null; viewport: any }) => void;
                    getTextContent: () => Promise<any>;
                    getAnnotations: () => Promise<any>;
                }) => {
                    const page = document.getElementById(`pageContainer${pageNum}`) as HTMLCanvasElement;
                    const canvas = page?.querySelector("canvas") as HTMLCanvasElement;
                    const wrapper = page?.querySelector(".canvasWrapper") as HTMLCanvasElement;
                    const container = page?.querySelector(".textLayer") as HTMLCanvasElement;

                    const canvasContext = canvas?.getContext("2d");
                    const viewport = pdfPage.getViewport(DEFAULT_SCALE);

                    canvas.width = viewport.width * 2;
                    canvas.height = viewport.height * 2;
                    page.style.width = `${viewport.width}px`;
                    page.style.height = `${viewport.height}px`;
                    wrapper.style.width = `${viewport.width}px`;
                    wrapper.style.height = `${viewport.height}px`;
                    container.style.width = `${viewport.width}px`;
                    container.style.height = `${viewport.height}px`;

                    pdfPage.render({
                        canvasContext,
                        viewport,
                    });

                    pdfPage.getTextContent().then((textContent) => {
                        PDFJS.renderTextLayer({
                            textContent,
                            container,
                            viewport,
                            textDivs: [],
                        });
                    });

                    page.setAttribute("data-loaded", "true");
                    return pdfPage;
                }
            );
    }

    /**
     * function to handle activate loadpage function to render next page
     */
    function handleWindowScroll() {
        /**
         * Get outer div (pdfViewerDiv)
         */
        const container = document?.getElementById("pdfViewerDiv");
        if (container) {
            // Get scrollTop value and divide by page height to get current page and add 1 to get next pagenumber
            const visiblePageNum = Math.round(container.scrollTop / container.clientHeight) + 1;

            // check if div with visiblePageNum exists and if data-loaded is false (not rendered yet)
            const visiblePage = document.querySelector(`.page[data-page-number="${visiblePageNum}"][data-loaded="false"]`);
            // if visiblePage is true -> run loadPage function
            if (visiblePage) {
                setTimeout(function () {
                    loadPage(visiblePageNum);
                });
            }
        }
    }

    // copy paste functionality for value and description
    // $("#text-layer").mouseup(function (e) {
    //     $("#copyfield").val(
    //         window
    //             .getSelection()
    //             .toString()
    //             .replace(/(\r\n|\n|\r)/gm, "")
    //     );
    // });
    // $(".pastefield").mouseup(function (e) {
    //     if ($("#copyfield").val().length) {
    //         $(this).val($("#copyfield").val());
    //         $("#copyfield").val("");
    //     }
    // });

    // document?.getElementById("pdfViewerDiv")?.addEventListener("scroll", handleWindowScroll);

    // copy text to clipboard with temporary inputfield
    // document?.getElementById("viewer")?.addEventListener("mouseup", function (e) {
    //     const copylayer = document.createElement("input");
    //     copylayer.className = "copylayer";
    //     copylayer.value(
    //         window
    //             ?.getSelection()
    //             ?.toString()
    //             .replace(/(\r\n|\n|\r)/gm, "")
    //     );
    // });
};
