import React, { useState } from "react";
import { Typography } from "@mui/material";
import TradeNameModal from "../../../../components/organizationComponents/TradeNameModal";
import { useQuery } from "@apollo/client";
import TopLinksModule from "../../../../components/organizationComponents/TopLinksModule";
import { GetCaNamesAndInActiveNames, GetCaNamesAndInActiveNamesVariables } from "../../../../__generated__/GetCaNamesAndInActiveNames";
import { QUERY_ALL_NAMES_OF_CA } from "../../../../graphql/queryDefCA";

interface Props {
    ca_id: string;
}

const CaNames: React.FC<Props> = ({ ca_id }) => {
    const [open, setOpen] = useState(false);

    const { loading, error, data } = useQuery<GetCaNamesAndInActiveNames, GetCaNamesAndInActiveNamesVariables>(QUERY_ALL_NAMES_OF_CA, {
        variables: { id: ca_id },
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (loading || error || !data || !data.contracting_authority) return null;

    return (
        <React.Fragment>
            {data.contracting_authority.names.filter((n) => n.type === "trade_name").length > 0 && (
                <div style={{ listStyle: "none", paddingBottom: 20 }}>
                    <TopLinksModule Title="actieve handelsnamen">
                        {data.contracting_authority.names
                            .filter((n) => n.type === "trade_name")
                            .slice(0, 5)
                            .map((i) => {
                                return (
                                    <li key={i.id}>
                                        <Typography>{i.value}</Typography>
                                    </li>
                                );
                            })}
                        {data.contracting_authority.inactive_names.length > 0 ? (
                            <li>
                                <Typography
                                    sx={{
                                        display: "inline",
                                        cursor: "pointer",
                                        color: "#1f4769",
                                        "&:hover": {
                                            textDecoration: "underline",
                                        },
                                    }}
                                    onClick={handleClickOpen}
                                >
                                    Toon alle
                                </Typography>
                            </li>
                        ) : data.contracting_authority.names.length > 5 ? (
                            <li>
                                <Typography
                                    sx={{
                                        display: "inline",
                                        cursor: "pointer",
                                        color: "#1f4769",
                                        "&:hover": {
                                            textDecoration: "underline",
                                        },
                                    }}
                                    onClick={handleClickOpen}
                                >
                                    Toon alle
                                </Typography>
                            </li>
                        ) : null}
                    </TopLinksModule>
                </div>
            )}
            <TradeNameModal
                handleClose={handleClose}
                open={open}
                names={data.contracting_authority.names}
                inactive_names={data.contracting_authority.inactive_names}
            />
        </React.Fragment>
    );
};

export default CaNames;
