import { createContext, useContext } from "react";

export type TenderChatContextType = {
    openModal: boolean;
    setOpenModal: (openModal: boolean) => void;
    chatTitle: string | null;
    modelType: string | null;
    modelId: string | null;
    setChatTarget: (modelId: string, modelType: string, chatTitle: string, conversationIdToOpen: string) => void;
    messageSent: boolean;
    setMessageSent: (bool: boolean) => void;
    conversationIdToOpen: string | null;
};

export const TenderChatContext = createContext<TenderChatContextType>({
    openModal: false,
    chatTitle: null,
    modelType: null,
    modelId: null,
    conversationIdToOpen: null,
    setOpenModal: (openModal) => console.warn("no chatcontext provider"),
    setChatTarget: (modelId, modelType, chatTitle) => console.warn("no chatcontext provider"),
    messageSent: false,
    setMessageSent: (bool: boolean) => console.warn("no chatcontext provider"),
});

export const useTenderChat = () => useContext(TenderChatContext);
