import { ArrowDropDown, Close } from "@mui/icons-material";
import { Button, Divider, IconButton, Popover, Theme, useTheme } from "@mui/material";
import React, { useState } from "react";

interface Props {
    disabled: boolean;

    /**
     * Called when the popover gets closed
     */
    onClose?(): void;

    /**
     * Called when the reset button is clicked
     */
    onReset?(): void;
    /**
     * Called when the save button is clicked
     */
    onSave?(): void;
    borderColor?: keyof Theme["moduleColors"];
    label: string;
    active: boolean;
    allowReset: boolean;
}

const LogOptionSelector: React.FC<Props> = ({ disabled, onSave, onClose, onReset, borderColor, label, active, children, allowReset }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSave = () => {
        if (onSave) onSave();
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (onClose) onClose();
    };

    // Open state of the popover
    const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <Button
                disabled={disabled}
                size="small"
                variant="text"
                sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginRight: "8px",
                    marginBottom: "8px",
                    color: "#000000",
                }}
                onClick={handleOpen}
                style={borderColor ? { borderColor: theme.moduleColors[borderColor] } : undefined}
            >
                <div style={{ fontWeight: active ? 600 : "normal", textTransform: "initial" }}>{label}</div>
                {active ? (
                    <IconButton
                        size="small"
                        sx={{ marginLeft: "2px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (onReset) onReset();
                        }}
                    >
                        <Close style={{ width: "0.8rem", height: "0.8rem" }} />
                    </IconButton>
                ) : (
                    <ArrowDropDown />
                )}
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {children}

                <Divider />

                <div style={{ padding: "8px", display: "flex", justifyContent: "flex-end" }}>
                    <Button disabled={!allowReset} variant="text" onClick={onReset}>
                        Wis selectie
                    </Button>

                    <Button variant="text" onClick={handleSave}>
                        Opslaan
                    </Button>
                </div>
            </Popover>
        </React.Fragment>
    );
};

export default LogOptionSelector;
