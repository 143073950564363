import { createContext, useContext } from "react";

export type ArchiveContextType = {
    openModalArchive: boolean;
    setOpenModalArchive: (openModalArchive: boolean) => void;
    archive_title: string;
    setArchiveTitle: (archive_title: string) => void;
    archive_id: string;
    setArchiveId: (archive_id: string) => void;
};

export const ArchiveContext = createContext<ArchiveContextType>({
    openModalArchive: false,
    archive_title: "",
    archive_id: "",
    setOpenModalArchive: (openModalArchive) => console.warn("no theme provider"),
    setArchiveTitle: (archive_title) => console.warn("no theme provider"),
    setArchiveId: (archive_id) => console.warn("no theme provider"),
});
export const useArchiveDialog = () => useContext(ArchiveContext);
