import React, { useContext, useState } from "react";
import { Grid } from "@mui/material";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";

import moment from "moment";
import { useQuery } from "@apollo/client";
import { DATA_FOR_ENTITY_DOCS, DATA_FOR_ENTITY_SCHEDULE } from "../../../graphql/queryDefProjects";
import { get_entitity_docs, get_entitity_docsVariables, get_entitity_docs_tender_documentDateCount } from "../../../__generated__/get_entitity_docs";
import { get_entitity_schedule, get_entitity_scheduleVariables } from "../../../__generated__/get_entitity_schedule";
import ScheduleTimelineWrapper from "./entities/ScheduleTimelineWrapper";
import Documents, { DocProps } from "./entities/Documents";
import TenderContractWidget from "./entities/TenderContractWidget";
import TenderScopeWidget from "./entities/TenderScopeWidget";
import LabelWidget from "./entities/LabelWidget";
import WidgetChanger from "./entities/WidgetChanger";
import ProjectSummaryWidgetContainer from "./ProjectSummaryWidgetContainer";
import { DefaultSummary } from "../../tenders/TenderDetailContainer";
import { GetDefaultSummary, GetDefaultSummaryVariables, GetDefaultSummary_tender } from "../../../__generated__/GetDefaultSummary";
import { QUERY_DEFAULT_SUMMARY } from "../../../graphql/queryDefinitions";

interface Props {
    a?: number;
}

const ProjectEntities: React.FC<Props> = (props) => {
    const { project } = useContext(ProjectToOpenContext);
    const [docData, setDocData] = useState<get_entitity_docs_tender_documentDateCount[]>([]);
    const [tenderData, setTenderData] = useState<GetDefaultSummary_tender>();
    const [defaultSummary, setDefaultSummary] = useState<DefaultSummary>({
        id: "",
        omschrijving: "",
        omvang: "",
        titel: "",
    });

    useQuery<get_entitity_docs, get_entitity_docsVariables>(DATA_FOR_ENTITY_DOCS, {
        skip: project.tender_ids[0] === undefined,
        variables: {
            id: project.tender_ids[0],
        },
        onCompleted: (data) => {
            if (data && data.tender && data.tender.documentDateCount) {
                setDocData(data.tender.documentDateCount);
            }
        },
    });

    const { data } = useQuery<get_entitity_schedule, get_entitity_scheduleVariables>(DATA_FOR_ENTITY_SCHEDULE, {
        skip: project.tender_ids[0] === undefined,
        variables: {
            id: project.tender_ids[0],
        },
    });

    /**
     * Default tender summary query
     */
    useQuery<GetDefaultSummary, GetDefaultSummaryVariables>(QUERY_DEFAULT_SUMMARY, {
        skip: project.tender_ids[0] === undefined,
        variables: {
            id: project.tender_ids[0],
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.tender) {
                setDefaultSummary({
                    id: data.tender.id || "",
                    omschrijving: data.tender.omschrijving || "",
                    omvang: data.tender.omvang_opdracht || "",
                    titel: data.tender.namegiventocontract || "",
                });
                setTenderData(data.tender);
            }
        },
    });

    const documentDateCountCopy = [...docData] as DocProps[];
    const sortedDocumentDateCount = documentDateCountCopy.sort((a, b) => (moment(b.date).isBefore(moment(a.date)) ? -1 : 1));

    return (
        <Grid container style={{ padding: "16px" }} columnSpacing={1}>
            <Grid item xs={12} md={12}>
                <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <WidgetChanger />
                    </div>
                </div>
            </Grid>

            {/*
             * Summary
             */}
            {project.widgets.summary === true && project.tender_ids[0] !== undefined && tenderData !== undefined && (
                <Grid item xs={12} md={12} style={{ paddingBottom: 0 }}>
                    <ProjectSummaryWidgetContainer tender_id={project.tender_ids[0]} data={tenderData} defaultSummary={defaultSummary} />
                </Grid>
            )}

            {/*
             * Schedule
             */}
            {project.widgets.planning === true && project.tender_ids[0] !== undefined && (
                <ScheduleTimelineWrapper
                    tender_id={project.tender_ids[0]}
                    host={data?.tender?.published_host || null}
                    hostUrl={data?.tender?.original_link || null}
                />
            )}

            {/*
             * Document widget
             */}
            {docData.length > 0 && project.widgets.documents === true && project.tender_ids[0] !== undefined ? (
                <Grid item xs={12} md={6}>
                    <Documents tender_id={project.tender_ids[0]} documentDateArray={sortedDocumentDateCount} />
                </Grid>
            ) : null}

            {/*
             * Contract information widget
             * Show component when tender.contract is not 'null'
             */}
            {project.widgets.contract === true && project.tender_ids[0] !== undefined && <TenderContractWidget tender_id={project.tender_ids[0]} />}

            {/*
             * Scope widget
             * Show scopewidget when tender.scope is not 'null'
             */}
            {project.widgets.scope === true && project.tender_ids[0] !== undefined && <TenderScopeWidget tender_id={project.tender_ids[0]} />}

            {/*
             * Labelwidget
             */}
            {project.widgets.labels === true && project.tender_ids[0] !== undefined && (
                <LabelWidget tender_id={project.tender_ids[0]} elevation={0} />
            )}
        </Grid>
    );
};

export default ProjectEntities;
