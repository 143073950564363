import React, { useState } from "react";
import WidgetBox from "../../../components/WidgetBox";
import MyCalendarData from "./MyCalendarData";
import { QUERY_USER_AND_TENDER_EVENTS } from "../../../graphql/queries/calendarQueries";
import { SavedTenderEvents, SavedTenderEventsVariables } from "../../../__generated__/SavedTenderEvents";
import { useQuery } from "@apollo/client";
import moment, { Moment } from "moment";
import MyCalendarLoader from "./MyCalendarLoader";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MyCalendarWidgetError from "./MyCalendarError";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    a?: number;
}

const MyCalendarWidget: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [activeTooltip, setActiveTooltip] = useState<string | false>(false);
    const [openId, setOpenId] = useState<string>("");
    const [dateRangeState, setDateRange] = useState<{
        from: Moment | null;
        to: Moment | null;
    }>({ from: moment(), to: moment().add(31, "days") });

    const { trackEvent } = useMatomo();

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const renderPage = (content: React.ReactNode) => (
        <WidgetBox
            header={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {/* {t("dashboardPage.UpdatesInterestingTenders")} */}
                        Mijn agenda items
                    </div>
                    <Typography>
                        <Link
                            id="link-to-full-calendar"
                            to="/calendar"
                            onClick={() => {
                                track({ category: "Dashboard", action: "Click-go-to-calendar", href: window.location.href });
                            }}
                        >
                            {t("dashboardPage.ShowAll")}
                        </Link>
                    </Typography>
                </div>
            }
            highlight="grey"
            padding={0}
        >
            {content}
        </WidgetBox>
    );

    /**
     * Variables for the GQL query
     */
    const CustomVariables: SavedTenderEventsVariables = {
        from: dateRangeState.from ? moment(dateRangeState.from).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        till: dateRangeState.to ? moment(dateRangeState.to).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    };

    /**
     * Define the GQL query
     */
    const { loading, data, error } = useQuery<SavedTenderEvents, SavedTenderEventsVariables>(QUERY_USER_AND_TENDER_EVENTS, {
        variables: CustomVariables,
        fetchPolicy: "network-only", // Used for first execution
    });

    if (loading) return renderPage(<MyCalendarLoader />);

    if (!data || error || !data.getCalendarEvents) return <MyCalendarWidgetError errorText={"Mijn agenda-items"} />;

    return renderPage(
        <MyCalendarData
            setDateRange={setDateRange}
            dateRangeState={dateRangeState}
            data={data.getCalendarEvents}
            setActiveTooltip={setActiveTooltip}
            activeTooltip={activeTooltip}
            openId={openId}
            setOpenId={setOpenId}
        />
    );
};

export default MyCalendarWidget;

//? //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////? PARENT COMPONENT /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//? //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
