import React from "react";
import { Divider, TextField } from "@mui/material";

interface Props {
    name: string;
    setName: (value: React.SetStateAction<string>) => void;
}

const Name: React.FC<Props> = ({ name, setName }) => {
    return (
        <div style={{ margin: "48px 0px" }}>
            <div style={{ display: "flex", alignItems: "center", width: "90%" }}>
                {/*
                 * textfield to write title
                 */}
                <TextField
                    style={{ maxWidth: "43ch" }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="standard"
                    InputProps={{
                        sx: {
                            fontSize: "1.2rem",
                            width: "20vw",
                        },
                        disableUnderline: true,
                    }}
                    placeholder="Geef je zoekregel een naam"
                />
            </div>
            <div style={{ width: "90%" }}>
                <Divider style={{ width: "25%" }} />
            </div>
        </div>
    );
};

export default Name;
