import React, { useState } from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import GeneralSelector from "../../../components/input/GeneralSelector";
import { DistributionIntervalInput, SearchDistributionInput, SearchDistributionMoment } from "../../../__generated__/globalTypes";
import DistributionSoloOption from "../../../components/input/DistributionSoloOption";
import { useTranslation } from "react-i18next";
import { chipTrigger } from "../Dialog/TenderDist";
import { acceptCopiedTenderSearch, acceptCopiedTenderSearchVariables } from "../../../__generated__/acceptCopiedTenderSearch";
import { ApolloQueryResult, OperationVariables, gql, useMutation } from "@apollo/client";
import { GetUserSearchRules } from "../../../__generated__/GetUserSearchRules";
import { setSearchDistribution, setSearchDistributionVariables } from "../../../__generated__/setSearchDistribution";
import { SET_SEARCH_DISTRIBUTION } from "../../../graphql/mutationDefinitions";

interface Props {
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetUserSearchRules>>;
    handleClose: () => void;
    open: boolean;
    owner: string;
    searchID: string;
}

const DistSaver: React.FC<Props> = ({ open, owner, searchID, handleClose, refetch }) => {
    const { t } = useTranslation();
    const [optionIsChosen, setChosenOption] = useState<boolean>(true);
    const [moment, setMoment] = useState<SearchDistributionMoment | null>(null);
    const [dist, setDist] = useState<SearchDistributionInput>({
        in_app: false,
        worklist: false,
        email_interval: null,
    });

    const [accept_tndr_search, { loading: loadingMutation, error: errorMutation }] = useMutation<
        acceptCopiedTenderSearch,
        acceptCopiedTenderSearchVariables
    >(ACCEPT_INCOMING_TENDER_SEARCH);

    const [saveDistribution, { loading }] = useMutation<setSearchDistribution, setSearchDistributionVariables>(SET_SEARCH_DISTRIBUTION);

    /**
     * accept incoming tender searchrule
     */
    const acceptTndr_search = async (id: string) => {
        // Function to like organization
        if (loadingMutation) {
        }
        if (errorMutation) {
            // toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await accept_tndr_search({
                variables: {
                    tndr_search_id: parseInt(id),
                },
                onCompleted: () => {
                    saveDist(id);
                },
            });
        } catch (e) {
            // toast.error("Fout tijdens opslaan (catch)");
        } finally {
        }
    };

    /**
     * SetDistribution after accepting search
     */
    const saveDist = async (searchID: string) => {
        // Function to like organization
        if (loadingMutation) {
        }
        if (errorMutation) {
            // toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await saveDistribution({
                variables: {
                    user_id: owner,
                    search_id: searchID,
                    distribution: {
                        in_app: dist.in_app,
                        worklist: dist.worklist,
                        email_interval: dist.email_interval ? (dist.email_interval.toUpperCase() as DistributionIntervalInput) : null,
                    },
                },
                onCompleted: () => {
                    setDist({
                        in_app: false,
                        worklist: false,
                        email_interval: null,
                    });
                    setMoment(null);
                    refetch();
                },
            });
        } catch (e) {
            // toast.error("Fout tijdens opslaan (catch)");
        } finally {
        }
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">Hoe wil je de resultaten ontvangen</DialogTitle>
                <DialogContent>
                    <div style={{ margin: "48px 0px" }}>
                        {moment !== null ? (
                            <Typography variant="h2">
                                {/* activity trigger */}
                                <span>
                                    <GeneralSelector
                                        openStep={false}
                                        type="tender"
                                        values={[
                                            { value: "AT_PUBLICATION", text: t("AT_PUBLICATION") },
                                            { value: "AT_AWARD", text: t("AT_AWARD") },
                                            { value: "AT_CONTRACT_EXPIRATION", text: t("AT_CONTRACT_EXPIRATION") },
                                        ]}
                                        // set distribution moment to chosen moment
                                        onSelect={(value) => {
                                            setMoment(value as SearchDistributionMoment);
                                        }}
                                        selectedValue={t(`${moment}`) as string}
                                        typoVariant="h2"
                                    />
                                </span>{" "}
                                nieuwe resultaten {/* worklist trigger, if false -> dont show text in sentence */}{" "}
                                <span>
                                    {dist.worklist === true && (
                                        <DistributionSoloOption
                                            type="tender"
                                            selectedValue={dist.worklist === true ? "tonen in werklijst" : ""}
                                            onRemove={() => {
                                                setDist({ ...dist, worklist: false });
                                            }}
                                        />
                                    )}
                                </span>
                                {/*
                                 * Notification trigger
                                 */}
                                {dist.worklist === true && !dist.email_interval && <React.Fragment> en per melding sturen</React.Fragment>}
                                {dist.worklist === true && dist.email_interval && <React.Fragment>, per melding sturen</React.Fragment>}
                                {dist.worklist === false && !dist.email_interval && <React.Fragment> per melding sturen</React.Fragment>}
                                {/*
                                 *  Email trigger
                                 */}
                                {dist.email_interval && (
                                    <React.Fragment>
                                        {dist.worklist === true && " en "}
                                        {/* option to choose interval for email */}
                                        <span>
                                            <GeneralSelector
                                                openStep={optionIsChosen}
                                                dataTut="reactour__emailtrigger_interval"
                                                type="tender"
                                                values={[
                                                    { value: "HOURLY", text: t("HOURLY") },
                                                    { value: "DAILY", text: t("DAILY") },
                                                    { value: "WEEKLY", text: t("WEEKLY") },
                                                    { value: "MONTHLY", text: t("MONTHLY") },
                                                ]}
                                                onSelect={(value) => {
                                                    const test = value.toUpperCase();
                                                    setDist({
                                                        ...dist,
                                                        email_interval: test as DistributionIntervalInput,
                                                    });

                                                    setChosenOption(false);
                                                }}
                                                selectedValue={t(`${dist.email_interval.toUpperCase()}`) as string}
                                                typoVariant="h2"
                                            />
                                        </span>{" "}
                                        {/* option to receive mail
                                         * on remove option -> set emails to null
                                         */}
                                        <span>
                                            <DistributionSoloOption
                                                type="tender"
                                                selectedValue={dist.email_interval !== null ? "per e-mail" : ""}
                                                onRemove={() => {
                                                    setDist({ ...dist, email_interval: null });
                                                }}
                                            />{" "}
                                        </span>{" "}
                                        sturen
                                    </React.Fragment>
                                )}
                            </Typography>
                        ) : (
                            /**
                             * Default sentence when no option is chosen
                             */
                            <Typography variant="h2">Zodra dit gebeurt...</Typography>
                        )}
                        <Divider style={{ marginTop: 8 }} />
                        {/* Default helper for user to know what we expect */}
                        {!moment ? <Typography>Kies een van onderstaande opties</Typography> : <Typography />}

                        {/*
                         * Check if chosenTrigger is empty or not
                         * If it is empty, show triggers (activity)
                         */}
                        {moment === null || moment === undefined ? (
                            <div style={{ margin: 8, height: 40 }}>
                                {chipTrigger.map((item, i) => {
                                    return (
                                        <Chip
                                            key={i}
                                            clickable
                                            variant="outlined"
                                            label={t(`${item.value}`) as string}
                                            style={{ margin: 4 }}
                                            onClick={() => {
                                                setMoment(item.value as SearchDistributionMoment);
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            /**
                             * If chosenTrigger is not empty, show send options
                             */
                            <div style={{ margin: 8, height: 40 }}>
                                {dist.worklist === false && (
                                    <Chip
                                        clickable
                                        variant="outlined"
                                        label={"werklijst"}
                                        style={{ margin: 4 }}
                                        onClick={() => {
                                            setDist({ ...dist, worklist: true });
                                        }}
                                    />
                                )}
                                <span>
                                    {!dist.email_interval && (
                                        <Chip
                                            clickable
                                            variant="outlined"
                                            label={"e-mail"}
                                            style={{ margin: 4 }}
                                            onClick={() => {
                                                setDist({ ...dist, email_interval: DistributionIntervalInput.DAILY });
                                            }}
                                        />
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Sluiten</Button>
                    <Button
                        disabled={
                            loading ||
                            loadingMutation ||
                            moment === null ||
                            (dist.in_app === false && dist.worklist === false && dist.email_interval === null)
                        }
                        onClick={() => acceptTndr_search(searchID)}
                        autoFocus
                    >
                        Opslaan
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DistSaver;

export const ACCEPT_INCOMING_TENDER_SEARCH = gql`
    mutation acceptCopiedTenderSearch($tndr_search_id: Int!) {
        acceptCopiedTenderSearch(search_id: $tndr_search_id)
    }
`;
