import React from "react";
import { LinearProgress, Theme, createStyles } from "@mui/material";
import { withStyles } from "tss-react/mui";

interface Props {
    loading: boolean;
    color: "blue" | "green" | "yellow" | "pink" | "grey";
}
// blue
const BlueLinearProgress = withStyles(LinearProgress, (theme: Theme) =>
    createStyles({
        colorPrimary: {
            backgroundColor: `${"#173357"}33`,
        },
        bar: {
            backgroundColor: "#173357",
        },
    })
);

// green
const GreenLinearProgress = withStyles(LinearProgress, (theme: Theme) =>
    createStyles({
        colorPrimary: {
            backgroundColor: `${"#225E4D"}33`,
        },
        bar: {
            backgroundColor: "#225E4D",
        },
    })
);

// pink
const PinkLinearProgress = withStyles(LinearProgress, (theme: Theme) =>
    createStyles({
        colorPrimary: {
            backgroundColor: `${"#E03660"}33`,
        },
        bar: {
            backgroundColor: "#E03660",
        },
    })
);

// yellow
const YellowLinearProgress = withStyles(LinearProgress, (theme: Theme) =>
    createStyles({
        colorPrimary: {
            backgroundColor: `${"#F57117"}33`,
        },
        bar: {
            backgroundColor: "#F57117",
        },
    })
);

// grey
const GreyLinearProgress = withStyles(LinearProgress, (theme: Theme) =>
    createStyles({
        colorPrimary: {
            backgroundColor: `${"#707070"}33`,
        },
        bar: {
            backgroundColor: "#707070",
        },
    })
);

const LinearLoader: React.FC<Props> = ({ loading, color }) => {
    return (
        <React.Fragment>
            {loading ? (
                <React.Fragment>
                    {(() => {
                        /**
                         * Switch contains all states a tender could have, for each state a different component.
                         */
                        switch (color) {
                            case "blue":
                                return <BlueLinearProgress />;

                            case "green":
                                return <GreenLinearProgress />;

                            case "yellow":
                                return <YellowLinearProgress />;

                            case "pink":
                                return <PinkLinearProgress />;

                            case "grey":
                                return <GreyLinearProgress />;

                            default:
                                return null;
                        }
                    })()}
                </React.Fragment>
            ) : (
                <div style={{ height: 4 }} />
            )}
        </React.Fragment>
    );
};

export default LinearLoader;
