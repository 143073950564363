import React from "react";
import StatusChip from "../../../components/StatusChip";
import { GetTenderDetailSplitview_tender, GetTenderDetailSplitview_tender_clientA } from "../../../__generated__/GetTenderDetailSplitview";
import SearchRuleData from "../../../components/SearchRuleData";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import FaviconBox from "../../../components/FaviconBox";
import { ContractingAuthoritySplitView, HighlightObject } from "./SplitView";
import dompurify from "dompurify";
import TenderButtonsBasedOnUserState from "../../../components/tenderDetail/TenderButtonsBasedOnUserState";

interface Props {
    selectedItem: GetTenderDetailSplitview_tender;
    ca: ContractingAuthoritySplitView | undefined;
    currentCA: GetTenderDetailSplitview_tender_clientA | null;
    highlightedData: HighlightObject;
}

const SplitViewTopBar: React.FC<Props> = ({ selectedItem, ca, currentCA, highlightedData }) => {
    const yellow = "#F57117";

    return (
        <div style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                        {/*
                         * Favicon CA of opportunity document
                         */}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {/* status of tender (chip version) */}
                            <StatusChip type={selectedItem.status} colored={true} /> <div style={{ marginRight: 8 }} />
                            {/* Search match(es) */}
                            <SearchRuleData placement="top" tender_id={selectedItem.id} searches={[]} />
                        </div>
                    </div>
                </div>
                {/* Right panel */}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <TenderButtonsBasedOnUserState tender_id={selectedItem.id} tender_title={selectedItem.namegiventocontract || ""} />
                </div>
            </div>

            {/* tender title */}
            <Link
                to={`/tenders/${selectedItem.id}`}
                onClick={(e) => {
                    // Prevent the row iself being clicked,
                    e.stopPropagation();
                }}
            >
                <Typography
                    variant="h5"
                    dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(highlightedData.title, { FORCE_BODY: true }),
                    }}
                />
            </Link>
            <Typography variant="body1" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 5 }}>
                {/* CA favicon */}
                <FaviconBox
                    marginTop={0}
                    name={ca ? ca.name : currentCA ? currentCA.name : selectedItem.published_opdrachtgever}
                    favicon={ca ? ca.favicon_resized ?? ca.favicon : currentCA?.favicon ? currentCA.favicon_resized ?? currentCA.favicon : null}
                    color={yellow}
                />
                {ca ? (
                    <Link
                        style={{ color: "#707070" }}
                        to={`/contracting-authorities/${ca.id}`}
                        onClick={(e) => {
                            // Prevent the row iself being clicked,
                            e.stopPropagation();
                        }}
                    >
                        {ca.name}
                    </Link>
                ) : currentCA ? (
                    <Link
                        style={{ color: "#707070" }}
                        to={`/contracting-authorities/${currentCA.id}`}
                        onClick={(e) => {
                            // Prevent the row iself being clicked,
                            e.stopPropagation();
                        }}
                    >
                        {currentCA.name}
                    </Link>
                ) : (
                    <>{selectedItem.published_opdrachtgever}</>
                )}
            </Typography>
        </div>
    );
};

export default SplitViewTopBar;
