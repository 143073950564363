// Hopefully this works

interface Options {
    a: string;
    pageNumber?: number;
}

export default async ({ a, pageNumber }: Options) => {
    const PDFJS = window.pdfjsLib;
    const url = a;

    // First, we need to load the document using the getDocument utility
    // const loadingTask = PDFJS.getDocument(url);
    // const pdf = await loadingTask.promise;

    // Asynchronous download of PDF
    const loadingTask = PDFJS.getDocument(url);
    loadingTask.promise.then(function (pdf: {
        getPage: (
            arg0: number
        ) => Promise<{ getViewport: (arg0: { scale: number }) => any; render: (arg0: { canvasContext: any; viewport: any }) => any }>;
    }) {
        // Fetch the first page
        pdf.getPage(pageNumber ? pageNumber : 1).then(function (page: {
            getViewport: any;
            render: (arg0: { canvasContext: any; viewport: any }) => any;
        }) {
            const scale = 0.22;
            const viewport = page.getViewport({ scale: scale });

            // Prepare canvas using PDF page dimensions
            const canvas = document.getElementById(`the-canvas${pageNumber}`) as HTMLCanvasElement;
            const context = canvas.getContext("2d");
            canvas.height = 200;
            canvas.width = 140;

            // Render PDF page into canvas context
            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            const renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
                console.log("");
            });
        });
    });
};
