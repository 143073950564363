import React from "react";
import { Tooltip, Typography } from "@mui/material";

interface Props {
    a?: number;
    children: any;
    content: any;
    title?: string;
    placement:
        | "bottom"
        | "left"
        | "right"
        | "top"
        | "bottom-end"
        | "bottom-start"
        | "left-end"
        | "left-start"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | undefined;
}

const InfoTooltip: React.FC<Props> = ({ children, content, title, placement }) => {
    return (
        <Tooltip
            componentsProps={{ tooltip: { sx: { maxWidth: "500px" } } }}
            placement={placement}
            enterNextDelay={100}
            title={
                <React.Fragment>
                    <Typography variant="h4" sx={{ color: "#000" }}>
                        {title}
                    </Typography>
                    <div style={{ padding: 0 }}>{content}</div>
                </React.Fragment>
            }
        >
            {children}
        </Tooltip>
    );
};

export default InfoTooltip;
