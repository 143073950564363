import React from "react";
import { Box } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";

interface Props {
    onClick: () => void;
}

const AddColumnButton: React.FC<Props> = ({ onClick }) => {
    return (
        <Box
            sx={{
                minWidth: "200px",
                height: "32px",
                background: "#e1e1e1BF",
                marginLeft: "8px",
                marginRight: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "8px",
                borderRadius: "6px",
                "&:hover": {
                    cursor: "pointer",
                    background: "#e1e1e1",
                },
            }}
            onClick={onClick}
        >
            <AddIcon color="primary" sx={{ mr: "8px" }} /> <Typography variant="button">Voeg kolom toe</Typography>
        </Box>
    );
};

export default AddColumnButton;
