import React from "react";
import { ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip, Typography } from "@mui/material";
import { FilterWorklistSplitView_filterTenders_results_data } from "../../../__generated__/FilterWorklistSplitView";
import SearchRuleData from "../../../components/SearchRuleData";
import { LocalOfferOutlined } from "@mui/icons-material";
import FaviconBox from "../../../components/FaviconBox";
import StatusDot from "../../../components/StatusDot";
import { ContractingAuthoritySplitView } from "./SplitView";
import ToolTipWinnerMP from "../../../components/ToolTipWinnerMP";
import { useLocation } from "react-router-dom";
interface Props {
    /**
     * @param item id of chosen tender
     * @returns new id of chosen tender
     */
    setSelectedId: (item: string) => void;
    /**
     * Id of chosen tender
     */
    selectedId: string;
    /**
     * Fetched tenderdata of clicked tender
     */
    item: FilterWorklistSplitView_filterTenders_results_data;
    /**
     * custom contracting authority data (optional)
     * now used in ca module only
     * If not set => clients data off tender object will be used
     */
    ca?: ContractingAuthoritySplitView;
    mp?: string;
}

const SplitDetailListItem: React.FC<Props> = ({ selectedId, setSelectedId, item, ca, mp }) => {
    const hoverColor = "#efefef";
    const yellow = "#F57117";
    const { pathname } = useLocation();

    /**
     * Fetch ca data where category is 'A'
     */

    const CA = item.clientA && item.clientA.length > 0 ? item.clientA[0] : null;
    const fallback = item.published_opdrachtgever;

    const caNametoshow = pathname.includes("tender") && CA ? CA.name : pathname.includes("organizations") && CA ? CA.name : ca ? ca.name : fallback;
    const caFavicontoshow =
        pathname.includes("tender") && CA
            ? CA.favicon_resized
            : pathname.includes("organizations") && CA
            ? CA.favicon_resized
            : ca
            ? ca.favicon_resized
            : null;

    return (
        <ListItemButton
            sx={{
                paddingRight: "76px",
            }}
            dense
            key={item.id}
            onClick={() => {
                setSelectedId(item.id);
            }}
            style={{
                backgroundColor: selectedId === item.id ? `${hoverColor}` : undefined,
                display: "flex",
            }}
        >
            {" "}
            {mp && <ToolTipWinnerMP tender_id={item.id} org_id={mp} />}
            <ListItemIcon
                sx={{
                    minWidth: "0px",
                }}
            >
                {/* Favicon of ca */}
                <FaviconBox favicon={caFavicontoshow} name={caNametoshow || ""} color={yellow} />
            </ListItemIcon>
            <ListItemText
                primary={<Typography noWrap dangerouslySetInnerHTML={{ __html: item.namegiventocontract ? item.namegiventocontract : "" }} />}
                secondary={caNametoshow}
            />
            <ListItemSecondaryAction
                style={{
                    height: 14,
                    width: 48,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    {/*
                     * Status of tender (dot version)
                     */}
                    <StatusDot type={item.status} placement="left" />
                </div>
                <div>
                    {/*
                     * Searchrule component
                     */}
                    {item.searchresults.length > 1 ? (
                        <Tooltip placement="left" title="Meerdere resultaten gevonden">
                            <LocalOfferOutlined style={{ color: "#9e9e9e" }} />
                        </Tooltip>
                    ) : (
                        <SearchRuleData placement="left" tender_id={item.id} searches={item.searchresults} showMultiple={false} />
                    )}
                </div>
            </ListItemSecondaryAction>
        </ListItemButton>
    );
};

export default SplitDetailListItem;
