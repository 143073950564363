import React, { useEffect, useState, useRef } from "react";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "chart.js/auto";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { getSearchlineMeta_getSearchesStatistics } from "../../../__generated__/getSearchlineMeta";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetUserSearchRules } from "../../../__generated__/GetUserSearchRules";
import { useMediaQuery, useTheme } from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ThumbDown, ThumbUp, ThumbsUpDown } from "@mui/icons-material";
import { QUERY_SEARCHRULES } from "../../../graphql/queryDefCurrentUser";

interface Props {
    data: getSearchlineMeta_getSearchesStatistics | null | undefined;
}

const PieChartReview: React.FC<Props> = ({ data }) => {
    const navigate = useNavigate();
    const { trackEvent } = useMatomo();
    const theme = useTheme();
    const chartRef = useRef<any>();
    const { data: searchrules } = useQuery<GetUserSearchRules>(QUERY_SEARCHRULES);
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));

    const color = data?.search !== null ? data?.search?.color : "#173357";
    const sum = data !== null && data !== undefined ? data?.liked + data?.disliked + data?.not_reviewed : 0;
    const [dataSet, setDataSet] = useState({
        labels: ["Interessant", "Niet interessant", "Niet beoordeeld"],
        datasets: [
            {
                data: [data?.liked, data?.disliked, data?.not_reviewed],
                backgroundColor: [`${color}`, `${color}66`, `${color}B3`],
                borderWidth: 2,
            },
        ],
    });

    useEffect(() => {
        setDataSet({
            labels: ["Interessant", "Niet interessant", "Niet beoordeeld"],
            datasets: [
                {
                    data: [data?.liked, data?.disliked, data?.not_reviewed],
                    backgroundColor: [`${color}`, `${color}66`, `${color}B3`],
                    borderWidth: 2,
                },
            ],
        });
    }, [setDataSet, data, color]);

    /**
     * highlight piece thats hovered in legend
     */
    const highlight = (colored: string, num: number) => {
        const newState = dataSet.datasets[0].backgroundColor.map((color, index) => {
            if (index !== num) {
                return `${colored}33`;
            }
            return colored;
        });
        setDataSet({ ...dataSet, datasets: [{ ...dataSet.datasets[0], backgroundColor: newState }] });
    };

    /**
     * reset chartcolor to default colorsettings
     */
    const resetHighlight = () => {
        setDataSet({
            ...dataSet,
            datasets: [{ ...dataSet.datasets[0], backgroundColor: [`${color}`, `${color}66`, `${color}B3`] }],
        });
    };

    /**
     * Click handler to set chosen quarter as url param
     */
    const handleClickEvent = (e: any) => {
        const element = getElementAtEvent(chartRef.current, e);
        if (element.length > 0) {
            const index = element[0].index;
            //liked
            if (index === 0) {
                setRtf(1);
            }
            // disliked
            if (index === 1) {
                setRtf(0);
            }
            // unreviewed tenders
            if (index === 2) {
                setRtf(-1);
            }
        }
    };

    const setRtf = (section: number) => {
        sessionStorage.removeItem("rtf");
        /**
         * Use searches filter and review filter
         */
        sessionStorage.setItem(
            "rtf",
            JSON.stringify({
                searches:
                    data?.search !== null ? [parseInt(data?.search?.id as string)] : searchrules?.currentUser.searches.map((i) => parseInt(i.id)),
                reviews: [section],
            })
        );

        /**
         * Navigate to reviewpage
         */
        navigate(`/tenders/reviews`);
    };

    // Track event click through
    const trackClickThrough = (action: string) => {
        trackEvent({ category: "Dashboard", action: action });
    };

    return (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>
                {/*
                 * Custom legend
                 * TODO: on legend hover -> hover piece in chart
                 */}
                <div style={{ display: "flex", marginTop: 8, justifyContent: "end", flexDirection: "column", width: mobileOnly ? "35%" : "25%" }}>
                    {/*
                     * Legend liked tenders -> onHover -> highlighting
                     */}
                    <div>
                        <Typography
                            onClick={() => {
                                // TODO: add param for liked/disliked/not_reviewed
                                setRtf(1);
                                trackClickThrough("click-through-like");
                            }}
                            variant="h2"
                            style={{ margin: "2px 0", cursor: "pointer", display: "inline-block" }}
                            onMouseOver={() => {
                                highlight(`${color}`, 0);
                            }}
                            onMouseOut={() => {
                                resetHighlight();
                            }}
                        >
                            <ThumbUp fontSize="small" style={{ color: `${color}`, marginRight: 8 }} />
                            {data?.liked}
                        </Typography>
                    </div>

                    {/*
                     * Legend disliked tenders -> onHover -> highlighting
                     */}
                    <div>
                        <Typography
                            onClick={() => {
                                // TODO: add param for liked/disliked/not_reviewed
                                setRtf(0);
                                trackClickThrough("click-through-dislike");
                            }}
                            variant="h2"
                            style={{ margin: "2px 0", cursor: "pointer", display: "inline-block" }}
                            onMouseOver={() => {
                                highlight(`${color}`, 1);
                            }}
                            onMouseOut={() => {
                                resetHighlight();
                            }}
                        >
                            <ThumbDown fontSize="small" style={{ color: `${color}66`, marginRight: 8 }} />
                            {data?.disliked}
                        </Typography>
                    </div>
                    {/*
                     * Legend not reviewed tenders -> onHover -> highlighting
                     */}
                    <div>
                        <Typography
                            onClick={() => {
                                // TODO: add param for liked/disliked/not_reviewed
                                setRtf(-1);
                                trackClickThrough("click-through-unreviewed");
                            }}
                            variant="h2"
                            style={{ margin: "2px 0", cursor: "pointer", display: "inline-block" }}
                            onMouseOver={() => {
                                highlight(`${color}`, 2);
                            }}
                            onMouseOut={() => {
                                resetHighlight();
                            }}
                        >
                            <ThumbsUpDown fontSize="small" style={{ color: `${color}B3`, marginRight: 8 }} />
                            {data?.not_reviewed}
                        </Typography>
                    </div>
                </div>

                {/*
                 * Doughnut chart
                 */}
                <div style={{ height: "100%", width: mobileOnly ? "65%" : "75%", display: "flex", justifyContent: "center" }}>
                    {sum > 0 ? (
                        <Doughnut
                            ref={chartRef}
                            onClick={handleClickEvent}
                            id="myChart"
                            data={dataSet}
                            options={{
                                // Mouse hover over element -> change cursor to pointer
                                onHover(event, elements, chart) {
                                    const test = event.native?.target as any;
                                    test.style.cursor = elements[0] ? "pointer" : "default";
                                },
                                maintainAspectRatio: false,
                                plugins: {
                                    datalabels: {
                                        display: true,
                                        color: "white",
                                        align: "center",
                                        padding: {
                                            right: 2,
                                        },
                                        labels: {
                                            title: {
                                                font: {
                                                    weight: "normal",
                                                },
                                            },
                                            value: {
                                                color: "white",
                                            },
                                        },
                                        // show labels in percentage instead of absolute values
                                        formatter: (value, ctx) => {
                                            const percentage = ((value * 100) / sum).toFixed() + "%";
                                            return percentage;
                                        },
                                    },
                                    legend: {
                                        labels: {
                                            usePointStyle: true,
                                        },
                                        display: false,
                                        position: "bottom",
                                    },

                                    title: {
                                        display: false,
                                        text: "not visible",
                                    },
                                },
                            }}
                            // import labels locally
                            plugins={[ChartDataLabels as any]}
                        />
                    ) : (
                        // if sum of data is 0, show placeholder
                        <canvas
                            style={{
                                height: "100%",
                                maxHeight: 280,
                                width: "85%",
                                maxWidth: 280,
                                background: `radial-gradient(circle at center, rgba(0,0,0,0) 0, rgba(0,0,0,0) 35%, rgba(0,0,0,0.05) 33%, rgba(0,0,0,0.05) 67%, rgba(0,0,0,0.05) 64%, rgba(0,0,0,0) 65%,rgba(0,0,0,0) 100%)`,
                            }}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PieChartReview;
