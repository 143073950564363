import React from "react";
import { Checkbox, Link, ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";

//time
import moment from "moment";
import DocIconSwitch from "../../../DocIconSwitch";

interface Props {
    titleParts: string | null;
    fragParts: string | null;
    date: string;
    icon: string | null;
    temp_link: string | undefined;
    document_id: string | undefined;
    tender_id: string;
    file_name: string;
    setDocDrawer: React.Dispatch<React.SetStateAction<OpenProps>>;
    onFileClick?: (folder: number) => void;
    relevantDocumentIds?: number[];
    onFileClickData?: (folder: { id: any; label: any; link: any }) => void;
}

interface OpenProps {
    open: boolean;
    link: string;
    label: string;
    doc_id: string;
    tender_id: string;
}

const DocResultItem: React.FC<Props> = ({
    titleParts,
    fragParts,
    date,
    icon,
    temp_link,
    document_id,
    tender_id,
    file_name,
    setDocDrawer,
    onFileClick,
    relevantDocumentIds,
    onFileClickData,
}) => {
    const greyColor2 = "#707070";

    return (
        <>
            {onFileClick && onFileClickData && relevantDocumentIds ? (
                <ListItemButton style={{ width: "100%", display: "flex", alignItems: "center", margin: 0 }}>
                    <ListItemIcon
                        onClick={() => {
                            onFileClick(parseInt(document_id as string));
                            const file = { id: document_id, label: file_name, link: temp_link };
                            onFileClickData(file);
                        }}
                    >
                        <Checkbox
                            edge="start"
                            checked={relevantDocumentIds.includes(parseInt(document_id as string))}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": document_id }}
                        />
                    </ListItemIcon>
                    {/* icon matches filetype */}

                    {/* document title */}
                    <ListItemText
                        sx={{ paddingRight: "16px" }}
                        primary={
                            <Link
                                underline="none"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                    if (icon === "pdf" || icon === "fa-file-pdf-o") {
                                        e.stopPropagation();
                                        setDocDrawer({
                                            open: true,
                                            link: temp_link as string,
                                            label: file_name,
                                            doc_id: document_id as string,
                                            tender_id: tender_id,
                                        });
                                    } else {
                                        // Stop click on listitem to prevent document-viewer to initiate
                                        e.stopPropagation();
                                        const element = document.createElement("a");

                                        element.setAttribute("href", `${temp_link}&response-content-disposition=attachment; filename=${file_name}`);

                                        element.style.display = "none";
                                        document.body.appendChild(element);
                                        element.click();
                                        document.body.removeChild(element);
                                    }
                                }}
                            >
                                {titleParts && <Typography noWrap style={{ width: "92%" }} dangerouslySetInnerHTML={{ __html: titleParts }} />}
                            </Link>
                        }
                        // document fragment with matching word
                        secondary={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {fragParts && (
                                    <Typography
                                        noWrap
                                        style={{ display: "inline-block", width: "92%", color: `${greyColor2}`, fontSize: "0.8rem" }}
                                        dangerouslySetInnerHTML={{ __html: fragParts }}
                                    />
                                )}
                            </div>
                        }
                    />

                    {/* document date */}
                    <ListItemSecondaryAction
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {moment(date).isSame(moment(), "year") ? (
                            //current year date notation
                            <Typography style={{ fontSize: "0.8rem" }}>{moment(date).format("DD MMM")}</Typography>
                        ) : (
                            //previous or next year date notation
                            <React.Fragment>
                                <Typography style={{ fontSize: "0.8rem" }}>{moment(date).format("DD MMM")}</Typography>

                                <Typography style={{ textAlign: "right", fontSize: "0.8rem" }}>{moment(date).format("YYYY")}</Typography>
                            </React.Fragment>
                        )}
                    </ListItemSecondaryAction>
                </ListItemButton>
            ) : (
                <ListItemButton
                    style={{ width: "100%", display: "flex", alignItems: "center", margin: 0 }}
                    onClick={(e) => {
                        if (icon === "pdf" || icon === "fa-file-pdf-o") {
                            e.stopPropagation();
                            setDocDrawer({
                                open: true,
                                link: temp_link as string,
                                label: file_name,
                                doc_id: document_id as string,
                                tender_id: tender_id,
                            });
                        } else {
                            // Stop click on listitem to prevent document-viewer to initiate
                            e.stopPropagation();
                            const element = document.createElement("a");

                            element.setAttribute("href", `${temp_link}&response-content-disposition=attachment; filename=${file_name}`);

                            element.style.display = "none";
                            document.body.appendChild(element);
                            element.click();
                            document.body.removeChild(element);
                        }
                    }}
                >
                    {/* icon matches filetype */}
                    <ListItemAvatar style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 0, marginLeft: "-16px" }}>
                        <DocIconSwitch icon={icon} />
                    </ListItemAvatar>

                    {/* document title */}
                    <ListItemText
                        style={{ paddingRight: 16 }}
                        primary={
                            <Link underline="none" style={{ cursor: "pointer" }}>
                                {titleParts && <Typography noWrap style={{ width: "92%" }} dangerouslySetInnerHTML={{ __html: titleParts }} />}
                            </Link>
                        }
                        // document fragment with matching word
                        secondary={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {fragParts && (
                                    <Typography
                                        noWrap
                                        style={{ display: "inline-block", width: "92%", color: `${greyColor2}`, fontSize: "0.8rem" }}
                                        dangerouslySetInnerHTML={{ __html: fragParts }}
                                    />
                                )}
                            </div>
                        }
                    />

                    {/* document date */}
                    <ListItemSecondaryAction
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {moment(date).isSame(moment(), "year") ? (
                            //current year date notation
                            <Typography style={{ fontSize: "0.8rem" }}>{moment(date).format("DD MMM")}</Typography>
                        ) : (
                            //previous or next year date notation
                            <React.Fragment>
                                <Typography style={{ fontSize: "0.8rem" }}>{moment(date).format("DD MMM")}</Typography>

                                <Typography style={{ textAlign: "right", fontSize: "0.8rem" }}>{moment(date).format("YYYY")}</Typography>
                            </React.Fragment>
                        )}
                    </ListItemSecondaryAction>
                </ListItemButton>
            )}
        </>
    );
};

export default DocResultItem;
