import moment from "moment";

export function getRelativeTimeInDays(dateString: string, t: any): { date: string; show: boolean } {
    // Specify a date
    const date = moment(dateString);

    // Get the current date
    const now = moment();

    // Calculate the difference in days
    // Set both values to startOf('day')
    const daysDifference = Math.floor(moment.duration(now.startOf("day").diff(date.startOf("day"))).asDays());

    // Format the output
    const relativeTimeInDays =
        daysDifference > 0 ? `${daysDifference} ${t("DLdaysAgo")}` : `${t("DLover")} ${Math.abs(daysDifference)} ${t("DLdays")}`;

    return { date: relativeTimeInDays, show: Boolean(daysDifference < 61) };
}
