import React, { useEffect, useState } from "react";
import dompurify from "dompurify";
import { Tooltip, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

interface Props {
    text: any;
    variant: "inherit" | Variant | undefined;
    lines: number;
    id: string;
    fontweight: string | number;
    fontSize: string | number;
    maxWidth?: string;
    dontSanitize?: boolean;
    showTooltip?: boolean;
    placement:
        | "bottom"
        | "left"
        | "right"
        | "top"
        | "bottom-start"
        | "bottom-end"
        | "left-end"
        | "left-start"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | undefined;
}

const TooltipOnNewLines: React.FC<Props> = ({
    text,
    variant,
    lines,
    id,
    fontweight,
    fontSize,
    placement,
    maxWidth = "300px",
    dontSanitize = false,
    showTooltip = true,
}) => {
    // State tracks if text is clamped or not
    const [clamped, setClamped] = useState(true);
    // Variable style based on lines given to component
    const maxLines = lines * 3;
    // function to check if div contains more text => used to set clamped state
    const isTextClamped = (elm: any) => elm.scrollHeight > elm.clientHeight;

    /**
     * dompurify imported for sanitizing html
     */
    const frag = dompurify.sanitize(text, { FORCE_BODY: true });

    /**
     * Check if text is clamped each render again
     * @id => id of div to check
     * @text => content inside div
     */
    useEffect(() => {
        const getText = document.querySelector(`#${id}`);
        setClamped(isTextClamped(getText));
    }, [id, text]);

    if (clamped) {
        if (showTooltip === false) {
            return (
                <Typography
                    style={{
                        fontSize: fontSize,
                        fontWeight: fontweight,
                    }}
                    id={id}
                    sx={{
                        maxHeight: `${maxLines}em`,
                        lineHeight: "1.5em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: lines,
                        WebkitBoxOrient: "vertical",
                        width: "fit-content",
                        whiteSpace: "pre-wrap",
                    }}
                    variant={variant}
                    dangerouslySetInnerHTML={{ __html: dontSanitize === true ? text : frag }}
                />
            );
        }
        return (
            <>
                <Tooltip
                    title={dontSanitize === true ? text : frag}
                    placement={placement}
                    enterNextDelay={100}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                maxWidth: maxWidth,
                            },
                        },
                    }}
                >
                    <Typography
                        style={{
                            fontSize: fontSize,
                            fontWeight: fontweight,
                        }}
                        id={id}
                        sx={{
                            maxHeight: `${maxLines}em`,
                            lineHeight: "1.5em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: lines,
                            WebkitBoxOrient: "vertical",
                            width: "fit-content",
                            whiteSpace: "pre-wrap",
                        }}
                        variant={variant}
                        dangerouslySetInnerHTML={{ __html: dontSanitize === true ? text : frag }}
                    />
                </Tooltip>
            </>
        );
    } else
        return (
            <div>
                <Typography
                    id={id}
                    style={{
                        fontSize: fontSize,
                        fontWeight: fontweight,
                    }}
                    sx={{
                        maxHeight: "100%",
                        lineHeight: "1.5em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "initial",
                        WebkitBoxOrient: "vertical",
                        width: "fit-content",
                        whiteSpace: "pre-wrap",
                    }}
                    variant={variant}
                    dangerouslySetInnerHTML={{ __html: dontSanitize === true ? text : frag }}
                />
            </div>
        );
};

export default TooltipOnNewLines;
