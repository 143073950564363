export interface OptieEinde {
    value: string[];
    lots: number[];
}

export const mergeOptiesEinde = (opties: any[] | null) => {
    if (!opties) return [];

    const merged: OptieEinde[] = [];

    opties.forEach((optie) => {
        if (!optie) return;

        const existingOptie = merged.find((mergedOptie) => JSON.stringify(mergedOptie.value) === JSON.stringify(optie.value));

        if (existingOptie) {
            const validLots = optie.lots.filter((lot: any) => lot !== null) as number[];
            existingOptie.lots.push(...validLots);
        } else {
            const validLots = optie.lots.filter((lot: any) => lot !== null) as number[];
            merged.push({ ...optie, lots: validLots });
        }
    });

    return merged;
};

export const findLotValue = (opties: OptieEinde[], lotNumber: number) => {
    if (!opties) {
        return null;
    }

    const foundLot = opties.find((optie) => optie && optie.lots.includes(lotNumber));
    return foundLot ? foundLot.value : null;
};

export const convertToStringArray = (opties: OptieEinde[]) => {
    if (opties.length === 0) {
        return [];
    }

    const arr = opties[0].value.map((val) => val);

    if (!arr) {
        return [];
    }
    return arr;
};
