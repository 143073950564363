import React, { useState } from "react";
import { CircularProgress, IconButton, InputAdornment, OutlinedInput, Tooltip, Typography } from "@mui/material";
import OptionCard from "./OptionCard";
import { QrCode2, Send, Lock, ArrowBack, VpnKey, PowerSettingsNew } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAuthSettings } from "./AuthSettingsContext";
import DefaultOption from "./DefaultOption";

interface Props {
    a?: number;
}

const TotpOption: React.FC<Props> = (props) => {
    const { enable2FA, disable2FA, passwordIncorrect, data_two_factor, loading, clickedOption, setClickedOption, getCodes } = useAuthSettings();
    const [password2FA, setPassword2FA] = useState<string>("");
    const [disable, setDisable] = useState<boolean>(false);
    const [requestCodes, setRequestCodes] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <>
            {clickedOption === "2fa" && !Boolean(data_two_factor?.currentUser.two_factor_totp_enabled) ? (
                <OptionCard
                    option="TOTP"
                    align="end"
                    success={Boolean(data_two_factor?.currentUser.two_factor_totp_enabled)}
                    onClick={() => {
                        setClickedOption("");
                        setPassword2FA("");
                    }}
                    disabled={false}
                    icon={
                        loading.loadingState ? (
                            <CircularProgress sx={{ color: "#465c84" }} />
                        ) : (
                            <Lock sx={{ color: "#465c84", width: "56px", height: "56px" }} />
                        )
                    }
                    text={passwordIncorrect ? "Wachtwoord incorrect" : "Vul je wachtwoord in"}
                    wrongPW={false}
                >
                    <OutlinedInput
                        error={passwordIncorrect}
                        onClick={(e) => e.stopPropagation()}
                        type="password"
                        placeholder={t("auth.password")}
                        value={password2FA}
                        onChange={(e) => setPassword2FA(e.target.value)}
                        fullWidth
                        size="small"
                        autoFocus
                        autoComplete="current-password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" size="small" onClick={() => enable2FA(password2FA)} edge="end">
                                    {<Send fontSize="small" />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </OptionCard>
            ) : (
                <OptionCard
                    option="TOTP"
                    onClick={() => {
                        if (!Boolean(data_two_factor?.currentUser.two_factor_totp_enabled)) {
                            setClickedOption("2fa");
                        }
                    }}
                    align="end"
                    success={Boolean(data_two_factor?.currentUser.two_factor_totp_enabled)}
                    avatarColor="#465c84"
                    disabled={false}
                    icon={loading.loadingState ? <CircularProgress /> : <QrCode2 sx={{ width: "56px", height: "56px" }} />}
                    text={
                        passwordIncorrect
                            ? "Wachtwoord incorrect"
                            : Boolean(data_two_factor?.currentUser.two_factor_totp_enabled)
                            ? "Authenticator app ingeschakeld"
                            : "Authenticator app"
                    }
                    wrongPW={passwordIncorrect}
                >
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        {!Boolean(data_two_factor?.currentUser.two_factor_totp_enabled) ? (
                            <Typography textAlign={"center"} variant="body2" sx={{ marginBottom: "8px", color: "text.secondary" }}>
                                Gebruik een app op je telefoon
                            </Typography>
                        ) : (
                            <div>
                                {!disable && !requestCodes ? (
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <DefaultOption option={"TOTP"} disable={!Boolean(data_two_factor?.currentUser.two_factor_totp_enabled)} />
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Tooltip title="Herstelsleutels opvragen" placement="top">
                                                <IconButton color="primary" onClick={() => setRequestCodes(true)}>
                                                    <VpnKey />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Uitschakelen" placement="top">
                                                <IconButton color="error" onClick={() => setDisable(true)}>
                                                    <PowerSettingsNew />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                if (requestCodes) {
                                                    return setRequestCodes(false);
                                                }
                                                if (disable) {
                                                    return setDisable(false);
                                                }
                                            }}
                                        >
                                            <ArrowBack />
                                        </IconButton>
                                        <OutlinedInput
                                            error={passwordIncorrect}
                                            onClick={(e) => e.stopPropagation()}
                                            type="password"
                                            placeholder={t("auth.password")}
                                            value={password2FA}
                                            onChange={(e) => setPassword2FA(e.target.value)}
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            autoComplete="current-password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        size="small"
                                                        onClick={() => {
                                                            if (requestCodes) {
                                                                getCodes(password2FA, true);
                                                                setPassword2FA("");
                                                            }
                                                            if (disable) {
                                                                disable2FA(password2FA);
                                                                setPassword2FA("");
                                                            }
                                                        }}
                                                        edge="end"
                                                    >
                                                        {<Send fontSize="small" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </OptionCard>
            )}
        </>
    );
};

export default TotpOption;
