import { List, ListItemButton, ListItemIcon } from "@mui/material";
import React from "react";
import { GetUserLogsWithFiltersVariables } from "../../../../__generated__/GetUserLogsWithFilters";
import LogOptionSelector from "../LogOptionSelector";
import { AccountTree, Description, Domain, People, TrendingUp } from "@mui/icons-material";

interface Props {
    updateProperties: (newProperties: Partial<GetUserLogsWithFiltersVariables>) => void;
    setChosenModule: (value: string) => void;
    variables: GetUserLogsWithFiltersVariables;
    rowsPerPage: number;
    chosenModule: string;
    onReset?(): void;
}

const LogModuleSelector: React.FC<Props> = ({ updateProperties, setChosenModule, chosenModule, variables, rowsPerPage, onReset }) => {
    return (
        <LogOptionSelector
            disabled={false}
            label={"Module"}
            active={variables.module !== undefined}
            allowReset={variables.module !== undefined ? true : false}
            onClose={() => {
                setChosenModule("");
            }}
            onReset={() => {
                onReset && onReset();
            }}
            onSave={() => {
                updateProperties({
                    first: 30,
                    page: 1,
                    module: chosenModule,
                });
            }}
        >
            <div style={{ width: 240 }}>
                <List sx={{ padding: 0 }}>
                    <ListItemButton
                        sx={{ fontWeight: chosenModule === "tenders" ? 500 : "normal" }}
                        selected={chosenModule === "tenders"}
                        onClick={() => setChosenModule("tenders")}
                    >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <Description sx={{ color: "#173357" }} />
                        </ListItemIcon>
                        Aanbestedingen
                    </ListItemButton>
                    <ListItemButton
                        sx={{ fontWeight: chosenModule === "opportunities" ? 500 : "normal" }}
                        selected={chosenModule === "opportunities"}
                        onClick={() => setChosenModule("opportunities")}
                    >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <TrendingUp sx={{ color: "#225E4D" }} />
                        </ListItemIcon>
                        Kansen
                    </ListItemButton>
                    <ListItemButton
                        sx={{ fontWeight: chosenModule === "projects" ? 500 : "normal" }}
                        selected={chosenModule === "projects"}
                        onClick={() => setChosenModule("projects")}
                    >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <AccountTree sx={{ color: "#707070" }} />
                        </ListItemIcon>
                        Projecten
                    </ListItemButton>
                    <ListItemButton
                        sx={{ fontWeight: chosenModule === "organizations" ? 500 : "normal" }}
                        selected={chosenModule === "organizations"}
                        onClick={() => setChosenModule("organizations")}
                    >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <People sx={{ color: "#E03660" }} />
                        </ListItemIcon>
                        Marktpartijen
                    </ListItemButton>
                    <ListItemButton
                        sx={{ fontWeight: chosenModule === "contracting-authorities" ? 500 : "normal" }}
                        selected={chosenModule === "contracting-authorities"}
                        onClick={() => setChosenModule("contracting-authorities")}
                    >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <Domain sx={{ color: "#F57117" }} />
                        </ListItemIcon>
                        Aanbestedende diensten
                    </ListItemButton>
                </List>
            </div>
        </LogOptionSelector>
    );
};

export default LogModuleSelector;
