// WordModalGuide
import React, { useLayoutEffect } from "react";
import { Button, Chip, IconButton, Typography } from "@mui/material";
import { Assistant, Cancel } from "@mui/icons-material";
import GuideContent from "../elements/GuideContent";
import { useTour } from "../../contextProviders/TourContext";
import { useFirstSearchRuleSetter } from "../../contextProviders/FirstSearchRuleContext";

import { useTranslation } from "react-i18next";

interface Props {
    hasSearchrules: boolean;
    dist: string;
}

/**
 * @param step CurrentStep in tender searchwizard
 * @returns Returns current step as title and a button to open guide/tour
 */
const DistModalGuideInformative: React.FC<Props> = ({ hasSearchrules, dist }) => {
    const { t } = useTranslation();
    //accent color
    const blue = "#173357";
    // first searchrule global state
    const { distModalTourOpen, setDistModalTourOpen } = useFirstSearchRuleSetter();
    // tour variables to set
    // locktour => true means item is interactive and no next/prev button will be shown
    const { setTourVariables, setOpenTour } = useTour();

    const distribution = JSON.parse(dist);

    /**
     * SPLIT IN 2 COMPONENTS
     * BUTTON
     * TOUR TO START
     */
    const steps = [
        /**
         * Step 1: Results
         */
        {
            selector: '[data-tut="reactour__distTitle"]',
            content: () => {
                return (
                    <GuideContent
                        title="Zoekregel naam invoeren"
                        content={<span>Deze naam is voor de herkenning wanneer er een nieuw resultaat is gevonden.</span>}
                    />
                );
            },
            stepInteraction: false,
        },

        /**
         * Step 2: options
         */
        {
            selector: '[data-tut="reactour__distOptions-email-active-trigger"]',
            content: () => {
                return (
                    <GuideContent
                        title="Kies moment"
                        content={
                            <>
                                <span>
                                    Het gekozen moment wanneer een aanbesteding een resultaat moet zijn van je zoekregel. Een ander moment kiezen?
                                    klik op{" "}
                                    <Typography sx={{ color: "#465c84", fontWeight: 600, display: "inline-flex" }}>
                                        {t(`${distribution.moment}`)}
                                    </Typography>{" "}
                                    om het moment te veranderen voor deze zoekregel.
                                </span>
                            </>
                        }
                    />
                );
            },

            stepInteraction: false,
        },

        /**
         * Step 3: email
         */
        {
            selector: '[data-tut="reactour__distOptions-email"]',
            content: () => {
                return (
                    <GuideContent
                        title="Updates in mail"
                        content={
                            <span>
                                Nieuwe resultaten via de mail ontvangen?
                                <br /> Klik op <Chip size="small" variant="outlined" label={"email"} />{" "}
                            </span>
                        }
                    />
                );
            },
            stepInteraction: false,
        },

        /**
         * Step 4: interval change
         */
        {
            selector: '[data-tut="reactour__distOptions-worklist-active-trigger"]',
            content: () => {
                return (
                    <GuideContent
                        title="Interval aanpassen"
                        content={
                            <span>
                                <span>
                                    Deze optie geeft aan dat de resultaten van deze zoekregel in je werklijst worden gezet. beweeg de cursor over
                                    <span>
                                        {" "}
                                        <Typography sx={{ color: "#465c84", fontWeight: 600, display: "inline-flex" }}>
                                            tonen in werklijst
                                        </Typography>{" "}
                                    </span>{" "}
                                    Je kunt vervolgens op
                                    <span>
                                        <IconButton size="small" style={{ marginTop: -2 }}>
                                            <Cancel style={{ fontSize: "1rem" }} />
                                        </IconButton>
                                    </span>{" "}
                                    klikken om deze optie uit te schakelen
                                </span>
                            </span>
                        }
                    />
                );
            },

            stepInteraction: false,
        },

        /**
         * Step 6: email remove
         */
        {
            selector: '[data-tut="reactour__distOptions-email-cancel"]',
            content: () => {
                return (
                    <GuideContent
                        title="Mail uitschakelen"
                        content={
                            <span>
                                Nieuwe resultaten niet via de mail ontvangen?
                                <br /> beweeg de cursor over{" "}
                                <Typography sx={{ color: "#465c84", fontWeight: 600, display: "inline-flex" }}>per mail</Typography> en klik op
                                <span>
                                    <IconButton size="small" style={{ marginTop: -2 }}>
                                        <Cancel style={{ fontSize: "1rem" }} />
                                    </IconButton>
                                </span>
                            </span>
                        }
                    />
                );
            },

            stepInteraction: false,
        },
    ];

    /**
     * Check if user has searchrules and domainModalTourOpen = false
     * true => open tour on default for current step
     */
    useLayoutEffect(() => {
        if (distribution.emails === null) {
            steps.splice(5, 1);
        }

        if (hasSearchrules && !distModalTourOpen) {
            // timeout for smoother transition
            setTimeout(() => {
                setTourVariables(steps, blue, "TenderWizardDistribution");
                setOpenTour(true);
                setDistModalTourOpen(true);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distModalTourOpen, hasSearchrules]);

    return (
        <Button
            data-tut="reactour__tourbtn"
            variant="text"
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                setTourVariables(steps, blue, "TenderWizardDistribution");
                setOpenTour(true);
            }}
            startIcon={<Assistant fontSize="small" />}
        >
            Interactieve assistent
        </Button>
    );
};

export default DistModalGuideInformative;
