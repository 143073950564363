import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//components
import WidgetBox from "../../../components/WidgetBox";
import SearchRuleData from "../../../components/SearchRuleData";
import DeadlineTooltip from "./DeadlineTooltip";
import SmallTenderMenu from "../../../components/SmallTenderMenu";

//Material UI components
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";

//Import Muidatatable
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef } from "mui-datatables";
import { useQuery } from "@apollo/client";
import { GetFullDashboardWorklist, GetFullDashboardWorklistVariables } from "../../../__generated__/GetFullDashboardWorklist";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import moment from "moment";
import TenderTitleWidth from "../../../components/customColumns/TenderTitleWidth";
import { SortOrder, TenderFilterInput, TenderFilterSource, TenderFilterTarget, TenderSearchInput } from "../../../__generated__/globalTypes";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import LastMessageColumn from "../../../components/columns/LastMessageColumn";
import { GET_DASHBOARD_WORKLIST, QUERY_FIVE_LATEST_TENDER_ACTIVITIES } from "../../../graphql/queryDefinitions";
import AvatarReviewed from "../../../components/AvatarReviewed";

interface Props {
    a?: number;
}

const columns: MUIDataTableColumnDef[] = [
    // Id
    // index 0
    {
        label: "id",
        name: "id",
        options: { display: "excluded" },
    },
    // Contracting authority
    // index 1
    {
        label: "AD",
        name: "AD",
        options: { display: "excluded" },
    },
    // Favicon
    // index 2
    {
        label: "",
        name: "favicon",
        options: { display: "excluded" },
    },
    // State of tender
    // index 3
    {
        label: "",
        name: "status",
        options: { display: "excluded" },
    },
    // Tender title
    // index 4
    {
        label: "",
        name: "tender",
        options: {
            customBodyRender(value: any, tableMeta) {
                return <TenderTitleWidth location="Dashboard" tender_name={value} tender_id={tableMeta.rowData[0]} />;
            },
        },
    },
    // Deadline tender with tooltip to show other dates
    // index 5
    {
        label: "",
        name: "deadline",
        options: {
            customBodyRender(value: any, tableMeta) {
                // return tendertitle and second Contracting Authority.
                return (
                    <DeadlineTooltip
                        deadline={value}
                        state={tableMeta.rowData[3]}
                        start={tableMeta.rowData[9]}
                        end={tableMeta.rowData[10]}
                        firstPossibleEnd={tableMeta.rowData[11]}
                    />
                );
            },
        },
    },
    // Search rules label with tooltip
    // index 6
    {
        label: "",
        name: "searches",
        options: {
            customBodyRender(data: any, tableMeta) {
                return (
                    <div style={{ display: "flex", flexDirection: "row", width: "70%" }}>
                        <SearchRuleData placement="top" tender_id={tableMeta.rowData[0]} searches={data} />
                    </div>
                );
            },
            sort: false,
        },
    },

    // User avatars who liked tender
    // index 7
    {
        label: "Reviews",
        name: "reviews",
        options: {
            customBodyRender(tender_id: any, tableMeta) {
                return (
                    <>
                        <AvatarReviewed tender_id={tender_id} justifyContent="flex-end" />
                    </>
                );
            },
        },
    },

    // Actions to like, dislike, share tender
    // index 8
    {
        label: "",
        name: "likes",
        options: {
            customBodyRender(title: any, tableMeta) {
                return (
                    <div>
                        <SmallTenderMenu
                            id={tableMeta.rowData[0]}
                            title={tableMeta.rowData[4]}
                            userStatus={tableMeta.rowData[13]}
                            hasConversations={tableMeta.rowData[14]}
                            refetchQueries={[GET_DASHBOARD_WORKLIST, QUERY_FIVE_LATEST_TENDER_ACTIVITIES]}
                        />
                    </div>
                );
            },
        },
    },
    // Start duration
    // index 9
    {
        label: "",
        name: "looptijdstart",
        options: { display: "excluded" },
    },
    // First possible enddate
    // index 10
    {
        label: "",
        name: "eerstmogelijk",
        options: { display: "excluded" },
    },
    // Enddate
    // index 11
    {
        label: "",
        name: "looptijdeinde",
        options: { display: "excluded" },
    },
    // Ca id
    // index 12
    {
        label: "",
        name: "ca_id",
        options: { display: "excluded" },
    },
    // UserStatus
    // index 13
    {
        label: "",
        name: "userStatus",
        options: { display: "excluded" },
    },
    // HasConversations
    // index 14
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        name: "hasConversations",
        options: {
            display: "excluded",
        },
    },
    // Lastmessage
    // index 15
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        name: "lastPrivateNote",
        options: {
            display: "excluded",
            customBodyRender(id: any, tableMeta) {
                return <LastMessageColumn tender_id={id} />;
            },
        },
    },
];

// Custom styling
const getMuiTheme = (theme: Theme) =>
    createTheme({
        palette: {
            primary: {
                main: "#173357",
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro, system-ui, sans-serif",
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },
        components: {
            // MUIDataTable: {
            //     tableRoot: {
            //         width: "100% !important",
            //     },
            // },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "none !important",
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        display: "none !important",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: 8,
                    },
                },
            },
        },
    });

const filters: TenderFilterInput = {};
const defaultSearch: TenderSearchInput = {
    source: TenderFilterSource.WORKLIST,
    target: TenderFilterTarget.SUMMARY,
    filters,
};
const defaultVariables: GetFullDashboardWorklistVariables = {
    page: 1,
    count: 5,
    orderField: "updated_at",
    order: SortOrder.DESC,
    search: defaultSearch,
};

const WorkListTable: React.FC<Props> = (props) => {
    const { trackEvent } = useMatomo();
    const { t } = useTranslation();
    const [dashboardRows, setDashboardRows] = useState<any[]>([]);

    // Track event
    const goToFullList = () => {
        trackEvent({ category: "Dashboard", action: "go-to-full-worklist" });
    };

    // Fetch first 5 items of worklist to show on dashboardData
    const { error, loading, data } = useQuery<GetFullDashboardWorklist, GetFullDashboardWorklistVariables>(GET_DASHBOARD_WORKLIST, {
        variables: defaultVariables,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (data && data.filterTenders && data.filterTenders.results) {
            setDashboardRows(data.filterTenders.results.data);
        }
    }, [data]);

    // if loading, show widgetbox with spinner inside
    if (loading) {
        return (
            <WidgetBox
                header={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography>{t("dashboardPage.Worklist")}</Typography>
                        <Typography>
                            <Link id="link-to-full-list" to="/tenders/worklist" onClick={goToFullList}>
                                {t("dashboardPage.showFullWorklist")}
                            </Link>
                        </Typography>
                    </div>
                }
                highlight="blue"
                padding={0}
            >
                <div style={{ height: 300, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <FullPageSpinner />
                </div>
            </WidgetBox>
        );
    }

    // If error, or worklist is empty -> show empty widgetbox
    if (error || dashboardRows.length === 0) {
        return (
            <WidgetBox
                header={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography>{t("dashboardPage.Worklist")}</Typography>
                        <Typography>
                            <Link id="link-to-full-list" to="/tenders/worklist" onClick={goToFullList}>
                                {t("dashboardPage.showFullWorklist")}
                            </Link>
                        </Typography>
                    </div>
                }
                highlight="blue"
                padding={0}
            >
                <div style={{ height: 300, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <ThumbUpIcon style={{ height: "11.5rem", width: "11.5rem", color: "#f2f2f2" }} />
                    <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                        {t("empty_worklist")}
                    </Typography>
                </div>
            </WidgetBox>
        );
    }

    /**
     * Data reformat definition, each container is a columnsection in table.
     */
    const convertedData = dashboardRows.map((item) => {
        const container: any = {};
        const id = "id";
        const tender = "tender";
        const AD = "AD";
        const favicon = "favicon";
        const ca_id = "ca_id";
        const deadline = "deadline";
        const status = "status";
        const eerstmogelijk = "eerstmogelijk";
        const looptijdstart = "looptijdstart";
        const looptijdeinde = "looptijdeinde";
        const likes = "likes";
        const userStatus = "userStatus";
        const searches = "searches";
        const hasConversations = "hasConversations";
        const lastPrivateNote = "lastPrivateNote";
        const reviews = "reviews";

        const clientA = item.clients && item.clients.length > 0 ? item.clients[0] : null;

        container[id] = item.id;
        container[tender] = item.namegiventocontract;
        container[AD] = clientA?.name ?? item.published_opdrachtgever ?? "";
        container[ca_id] = clientA?.id;
        container[favicon] = clientA?.favicon_resized ?? clientA?.favicon;
        container[status] = item.status;
        container[hasConversations] = item.hasConversations;
        // container[procedure] = item.type_procedure;
        // container[type] = item.type;
        container[deadline] = item.deadline_inschrijving ? item.deadline_inschrijving : null;
        container[eerstmogelijk] = item.next_looptijd_einde !== null ? moment(item.next_looptijd_einde).format("L") : "";
        container[reviews] = item.id;
        container[looptijdstart] = item.looptijd_start
            ? item.looptijd_start?.map(
                  (data: any) =>
                      `${
                          data.value !== null
                              ? `${moment(data?.value).format("L")} ${
                                    data.lots.length > 0
                                        ? `Perceel: ${[...data.lots]
                                              .sort((a, b) => a - b)
                                              .map((lot: any) => lot)
                                              .join(", ")}`
                                        : ""
                                }`
                              : ""
                      }`
              )
            : "";
        container[looptijdeinde] = item.looptijd_einde
            ? item.looptijd_einde?.map(
                  (data: any) =>
                      `${
                          data.value !== null
                              ? `${moment(data?.value).format("L")} ${
                                    data.lots.length > 0
                                        ? `Perceel: ${[...data.lots]
                                              .sort((a, b) => a - b)
                                              .map((lot: any) => lot)
                                              .join(", ")}`
                                        : ""
                                }`
                              : ""
                      }`
              )
            : "";
        container[likes] = item.likes;
        container[userStatus] = item.userStatus;
        container[searches] = item.searchresults.map((i: any) => i);
        container[lastPrivateNote] = item.id;

        return container;
    });

    const options: MUIDataTableOptions = {
        responsive: "standard",
        elevation: 0,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        filterType: "checkbox",
        draggableColumns: {
            enabled: false,
            transitionTime: 200,
        },
        selectableRows: "none",
        viewColumns: "false",
    };

    return (
        <div id="worklistbox">
            <WidgetBox
                header={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography>{t("dashboardPage.Worklist")}</Typography>
                        <Typography>
                            <Link id="link-to-full-list" to="/tenders/worklist" onClick={goToFullList}>
                                {t("dashboardPage.showFullWorklist")}
                            </Link>
                        </Typography>
                    </div>
                }
                highlight="blue"
                padding={0}
            >
                <ThemeProvider theme={getMuiTheme}>
                    <MUIDataTable title={""} data={convertedData} columns={columns} options={options} />
                </ThemeProvider>
            </WidgetBox>
        </div>
    );
};
export default WorkListTable;
