import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import { Grid, TextField, Select, FormControl, MenuItem, IconButton, Tooltip } from "@mui/material";
import { Add, Check, Close, Euro, Event, Remove } from "@mui/icons-material";
import moment from "moment";
import { GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities } from "../../__generated__/GetopportunityPagePersonalOpportunities";
import update from "immutability-helper";
import { useMutation } from "@apollo/client";
import { CreateOpportunity, CreateOpportunityVariables } from "../../__generated__/CreateOpportunity";
import { Disable } from "react-disable";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { setPageReadState, setPageReadStateVariables } from "../../__generated__/setPageReadState";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { QUERY_DETAILPAGE_OF_OPPORTUNITY, QUERY_OPPS_OF_CA_DOC_TYPE, QUERY_USER_OPPS_OF_PAGE } from "../../graphql/queryDefinitions";
import { CREATE_OPPORTUNITY, SET_PAGE_READ_STATE } from "../../graphql/mutationDefinitions";
interface Props {
    page_id: string;
    page_number: number | null;
    setAddNew?: (opportunity: boolean) => void;
    onFinish?: () => void;
    /**
     * Prop to show button (minus sign) to hide 'empty opportunity field'
     */
    showHideBtn: boolean;
}

const OpportunityEditMode: React.FC<Props> = ({ page_id, page_number, setAddNew, onFinish, showHideBtn }) => {
    // const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const [opportunity, setOpportunity] = useState<GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities>({
        __typename: "PersonalOpportunity",
        id: "",
        comment: "",
        parsedValues: [{ value: null, year: moment().year(), __typename: "PersonalOpportunityValue" }],
        page: {
            __typename: "OpportunityFilePage",
            id: page_id,
            page: page_number,
        },
    });

    /**
     * mutation to rate page of document
     */
    const [createOpp, { loading: loadMutation, error: errorMutation }] = useMutation<CreateOpportunity, CreateOpportunityVariables>(
        CREATE_OPPORTUNITY
    );

    /**
     * mutation to rate page of document
     */
    const [rate] = useMutation<setPageReadState, setPageReadStateVariables>(SET_PAGE_READ_STATE);

    /**
     * handle submit of new opportunity
     */
    const createNewOpportunity = async () => {
        if (errorMutation) {
        }
        try {
            await createOpp({
                variables: {
                    id: "",
                    page_id: page_id,
                    comment: opportunity.comment ? opportunity.comment : "",
                    values:
                        opportunity !== undefined && opportunity.parsedValues
                            ? opportunity.parsedValues.map((value) => (value?.value ? value.value : 0))
                            : [],
                    years:
                        opportunity !== undefined && opportunity.parsedValues
                            ? opportunity.parsedValues.map((value) => (value?.year ? value.year : 0))
                            : [],
                },
                refetchQueries: [
                    QUERY_USER_OPPS_OF_PAGE,
                    "GetopportunityPagePersonalOpportunities",
                    QUERY_OPPS_OF_CA_DOC_TYPE,
                    "GetCAOpportunitiesOfType",
                ],
                onCompleted: () => {
                    // reset for new opportunity
                    setOpportunity({
                        __typename: "PersonalOpportunity",
                        id: "",
                        comment: "",
                        parsedValues: [{ value: null, year: moment().year(), __typename: "PersonalOpportunityValue" }],
                        page: {
                            __typename: "OpportunityFilePage",
                            id: page_id,
                            page: page_number,
                        },
                    });
                },
            });

            setAddNew && setAddNew(false);
            onFinish && onFinish();
            setPageState(page_id, true);
        } catch (e) {}
    };

    /**
     * @param pageid id of page that is being rated
     * @param rated is boolean if rate is true (thumbs up) or false (thumbs down)
     */
    const setPageState = async (pageid: string, rated: boolean) => {
        if (errorMutation) {
        }
        try {
            await rate({
                variables: {
                    id: pageid,
                    status: rated,
                },
                refetchQueries: [QUERY_DETAILPAGE_OF_OPPORTUNITY, "GetOppDetailPage"],
            });
        } catch (e) {}
    };

    const nextYears = () => {
        const years = [];
        const dateStart = moment().add(1, "year");
        const dateEnd = moment().add(6, "y");
        while (dateEnd.diff(dateStart, "years") >= 0) {
            years.push(parseInt(dateStart.format("YYYY")));
            dateStart.add(1, "year");
        }
        return years;
    };

    const prevYears = () => {
        const years = [];
        const dateStart = moment();
        const dateEnd = moment().subtract(1, "y");
        while (dateStart.diff(dateEnd, "years") >= 0) {
            years.push(parseInt(dateStart.format("YYYY")));
            dateStart.subtract(1, "year");
        }
        return years;
    };

    const years = [...prevYears(), ...nextYears()];

    /**
     * Paste text from clipboard
     */
    const pasteText = async () => {
        setLoading(true);
        const field = document.getElementById(`pasteField${page_id}`) as HTMLInputElement;
        navigator.clipboard.readText().then((text) => {
            if (text !== "") {
                field.value += text;
                setOpportunity(update(opportunity, { comment: { $set: opportunity.comment ? opportunity.comment + text : text } }));

                setLoading(false);
            }
            setLoading(false);
        });
    };

    return (
        <Grid container style={{ display: "flex", alignItems: "center" }}>
            <Grid
                xs={12}
                md={12}
                sx={{ padding: "10.5px 10px 10.5px 10px", width: "100%", display: "flex", alignItems: "center", marginBottom: "4px" }}
            >
                <TextField
                    id={`pasteField${page_id}`}
                    autoFocus
                    fullWidth
                    size="small"
                    placeholder="Omschrijving"
                    multiline
                    maxRows={10}
                    variant="outlined"
                    style={{ marginRight: 8, position: "relative" }}
                    InputProps={{
                        endAdornment: (
                            <div style={{ display: "flex", position: "absolute", right: 0, top: 0 }}>
                                <IconButton
                                    disabled={loading}
                                    id="pasteBtn"
                                    style={{ margin: 4, padding: 4 }}
                                    size="small"
                                    aria-label="copy text"
                                    onClick={pasteText}
                                >
                                    <AssignmentIcon fontSize="small" />
                                </IconButton>
                            </div>
                        ),
                    }}
                    value={opportunity.comment ? opportunity.comment : ""}
                    onChange={(event) => setOpportunity({ ...opportunity, comment: event.target.value })}
                />
            </Grid>
            {/* Grid with year selector */}
            <Grid
                xs={12}
                md={12}
                sx={{
                    padding: "10.5px 10px 10.5px 10px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                }}
            >
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {opportunity?.parsedValues?.map((p, index) => {
                        return (
                            <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/*
                                     * widt field to change year
                                     */}
                                    <Event sx={{ marginRight: "10px", color: "#707070", marginLeft: "8px" }} />
                                    <FormControl>
                                        <Select
                                            variant="standard"
                                            displayEmpty
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={p?.year}
                                            onChange={(event) =>
                                                setOpportunity({
                                                    ...opportunity,
                                                    parsedValues: update(opportunity.parsedValues, {
                                                        [index]: { year: { $set: event.target.value as number } },
                                                    }),
                                                })
                                            }
                                            disableUnderline
                                        >
                                            {years
                                                .sort((a, b) => moment(a).diff(b))
                                                .map((m, i) => {
                                                    return (
                                                        <MenuItem key={i} value={m as number}>
                                                            {m}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </div>
                                {/*
                                 * edit field to change value of set year
                                 */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* Grid with value inputfield */}
                                    <Euro sx={{ marginRight: "10px", color: "#707070", marginLeft: "8px" }} />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        inputProps={{ step: "any", pattern: "[0-9]" }}
                                        size="small"
                                        placeholder="Waarde"
                                        style={{ marginRight: 8 }}
                                        value={p?.value ? p.value : ""}
                                        onChange={(event) => {
                                            // const amount = parseFloat(event.target.value).toFixed(2);
                                            const amount = event.target.value;
                                            setOpportunity({
                                                ...opportunity,
                                                parsedValues: update(opportunity.parsedValues, {
                                                    [index]: { value: { $set: Number(amount) } },
                                                }),
                                            });
                                        }}
                                    />
                                </div>
                                {/*
                                 * Add button to add empty object to array
                                 */}
                                {opportunity.parsedValues !== undefined &&
                                    opportunity.parsedValues !== null &&
                                    opportunity?.parsedValues?.length - 1 === index && (
                                        <Tooltip title="" placement="top">
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                onClick={() =>
                                                    setOpportunity({
                                                        ...opportunity,
                                                        parsedValues: update(opportunity.parsedValues, {
                                                            $push: [
                                                                {
                                                                    year: moment().year(),
                                                                    value: 0 as number,
                                                                    __typename: "PersonalOpportunityValue",
                                                                },
                                                            ],
                                                        }),
                                                    })
                                                }
                                            >
                                                <Add />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                {/* remove value button */}
                                {opportunity.parsedValues !== undefined &&
                                    opportunity.parsedValues !== null &&
                                    opportunity?.parsedValues?.length > 1 && (
                                        <Tooltip title="Regel verwijderen" placement="top">
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                onClick={() =>
                                                    setOpportunity({
                                                        ...opportunity,
                                                        parsedValues: update(opportunity.parsedValues, {
                                                            $splice: [[index, 1]],
                                                        }),
                                                    })
                                                }
                                            >
                                                <Close />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </div>
                        );
                    })}
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <Disable disabled={loadMutation}>
                        <Tooltip title={opportunity.comment === "" ? "Voeg omschrijving toe om kans op te slaan" : "Kans opslaan"} placement="top">
                            <span>
                                <IconButton
                                    color="primary"
                                    disabled={opportunity.comment === ""}
                                    size="small"
                                    onClick={() => {
                                        createNewOpportunity();
                                        trackevents({ category: "Opportunity", action: "Click-save-opportunity" });
                                    }}
                                >
                                    <Check />
                                </IconButton>
                            </span>
                        </Tooltip>

                        {showHideBtn && (
                            <Tooltip title="Lege kans weghalen" placement="top">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        if (setAddNew) {
                                            setAddNew(false);
                                        }
                                        if (onFinish) {
                                            onFinish();
                                        }
                                    }}
                                >
                                    <Remove />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Disable>
                </div>
            </Grid>
        </Grid>
    );
};

export default OpportunityEditMode;
