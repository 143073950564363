import React, { useState } from "react";

import { Checkbox, Grow, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Disable } from "react-disable";
import TaskPriorityChip from "../../projectModal/task/TaskPriorityChip";
import { ArrowDropDown, ArrowDropUp, RadioButtonUnchecked, TaskAlt } from "@mui/icons-material";
import TooltipOnNewLines from "../../../../components/TooltipOnNewLines";
import { SINGLE_PROJECT_TASKS } from "../../../../graphql/queryDefProjects";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_COMPLETION } from "../../../../graphql/mutationDefProjects";
import { updateTaskCompletion, updateTaskCompletionVariables } from "../../../../__generated__/updateTaskCompletion";
import { single_project_tasks_project_tasks } from "../../../../__generated__/single_project_tasks";
import MatomoEvent from "../../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
interface Props {
    showTasks: boolean;
    setShowTasks: (bool: boolean) => void;
    tasks: single_project_tasks_project_tasks[];
}

const TaskReadOnly: React.FC<Props> = ({ showTasks, setShowTasks, tasks }) => {
    const [saveTaskCompletion] = useMutation<updateTaskCompletion, updateTaskCompletionVariables>(UPDATE_TASK_COMPLETION);
    const [hoverBox, setHoverBox] = useState<string>("");
    const [clickedBox, setClickedBox] = useState<string>("");
    const { trackEvent } = useMatomo();
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Update complete state of single task
     * @param taskId id of task
     * @param boolean bolean to set task to
     */
    const onChangeBoolean = async (taskId: string, boolean: boolean) => {
        setTimeout(() => {
            setClickedBox(taskId);
        }, 300);
        try {
            await saveTaskCompletion({
                variables: {
                    id: taskId,
                    completed: boolean,
                },
                refetchQueries: [SINGLE_PROJECT_TASKS],
            });
        } catch (e) {}
    };

    return (
        <div>
            <span
                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                onClick={(e) => {
                    e.stopPropagation();
                    setShowTasks(!showTasks);
                }}
            >
                Mijn openstaande taken {!showTasks ? <ArrowDropDown /> : <ArrowDropUp />}
            </span>

            <Grow in={showTasks} unmountOnExit style={{ transformOrigin: "0 0 0" }} {...(showTasks ? { timeout: 500 } : {})}>
                <List sx={{ padding: 0, mb: "8px" }}>
                    {tasks
                        ?.filter((task) => task.task_completed === false)
                        .map((task) => {
                            return (
                                <ListItem
                                    key={task.id}
                                    onClick={(e) => e.stopPropagation()}
                                    disablePadding
                                    dense
                                    sx={{
                                        height: "26px",
                                        border: "1px solid #ffffff",
                                        "&:hover": { border: "1px solid #cecece", borderRadius: "4px" },
                                    }}
                                >
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <Checkbox
                                            onMouseOver={() => setHoverBox(task.id)}
                                            onMouseOut={() => setHoverBox("")}
                                            icon={
                                                clickedBox === task.id ? (
                                                    <TaskAlt fontSize="small" sx={{ color: "#2E76FF" }} />
                                                ) : hoverBox === task.id ? (
                                                    <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                                                ) : (
                                                    <RadioButtonUnchecked fontSize="small" />
                                                )
                                            }
                                            checkedIcon={<TaskAlt fontSize="small" sx={{ color: "#2E76FF" }} />}
                                            edge="start"
                                            size="small"
                                            checked={task.task_completed || false}
                                            disableRipple
                                            sx={{ margin: 0, padding: 0, mr: "4px" }}
                                            // setState to checked value
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                trackClick({ category: "Project", action: "Task-completed-in-projectcard" });
                                                onChangeBoolean(task.id, e.target.checked);
                                            }}
                                        />
                                    </ListItemIcon>

                                    {/* Tasks text */}
                                    <ListItemText
                                        sx={{ textDecoration: task.task_completed ? "line-through" : "none" }}
                                        primary={
                                            <TooltipOnNewLines
                                                text={task.task_description}
                                                variant={"body1"}
                                                lines={1}
                                                id={`task${task.id}`}
                                                fontweight={"400"}
                                                fontSize={"12px"}
                                                placement={"top"}
                                            />
                                        }
                                    />

                                    {/* Prioritychip  */}
                                    <Disable disabled={Boolean(task.task_completed)}>
                                        <TaskPriorityChip dot priority={task.task_priority} disabled={Boolean(task.task_completed)} />
                                    </Disable>
                                </ListItem>
                            );
                        })}
                </List>
            </Grow>
        </div>
    );
};

export default TaskReadOnly;
