import React from "react";
import { IconButton, Paper, ListItem, ListItemAvatar, Avatar, Divider, ListItemText, Tooltip, Typography } from "@mui/material";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useTranslation } from "react-i18next";
import TeamName from "../UserAndTeams/TeamName";
import FaviconBox from "../../../components/FaviconBox";
import { Close } from "@mui/icons-material";
import TouchAppIcon from "@mui/icons-material/TouchApp";

// TODO: use userlist in animation
interface Props {
    toggle: boolean;
    error: boolean;
    loading: boolean;
}

const TeamAnimation: React.FC<Props> = ({ error, loading, toggle }) => {
    const { t } = useTranslation();

    const staticTeam = {
        name: "Team A",
        id: "testteam1",
        users: [
            {
                name: "Josh Janssen",
                id: "jj1",
                avatar: "JJ",
                mail: "josh.janssen@tender.guide",
            },
            {
                name: "Emmie Hagens",
                id: "eh1",
                avatar: "EH",
                mail: "emmie.Hagens@tender.guide",
            },
            // {
            //     name: "Evie Cuppen",
            //     id: "ec1",
            //     avatar: "EC",
            //     mail: "Evie Cuppen",
            // },
        ],
    };
    return (
        <>
            <h2 style={{ margin: 0, fontSize: "1.4rem" }}>
                {toggle
                    ? "Drag & drop gebruikers in het gewenste team"
                    : loading
                    ? "Teams ophalen..."
                    : error
                    ? "Er ging iets mis..."
                    : t("UserAndTeams.No-team-yet")}
            </h2>
            <Typography textAlign={"center"} sx={{ margin: "16px 0px 8px" }}>
                Maak een team aan en geef het team een naam.
                <br />
                Je kunt gebruikers toevoegen aan een team door ze te slepen van de lijst naar het team
                <br />
            </Typography>
            <span style={{ fontWeight: "500" }}>{t("UserAndTeams.First-tip")}</span>

            {/* 
                Section for dynamic animation
            */}

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", opacity: "0.5", margin: "50px", position: "relative" }}>
                <div style={{ height: "100%" }}>
                    <h4 style={{ marginTop: 0 }}>{t("UserAndTeams.Users")}</h4>
                    <Paper square style={{ height: "220px", width: "275px" }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>JJ</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Josh Janssen" secondary="josh.janssen@tender.guide" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>EH</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Emmie Hagens" secondary="emmie.Hagens@tender.guide" />
                        </ListItem>
                        <Divider />
                        <ListItem style={{ opacity: 0.4 }}>
                            <ListItemAvatar>
                                <Avatar>EC</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Evie Cuppen" secondary="evie.cuppen@tender.guide" />
                        </ListItem>
                        <Paper
                            square
                            style={{
                                position: "absolute",
                                width: "275px",
                                right: 0,
                                left: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                                textAlign: "center",
                                bottom: "-10px",
                            }}
                        >
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>EC</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Evie Cuppen" secondary="evie.cuppen@tender.guide" />
                                <TouchAppIcon fontSize="small" />
                            </ListItem>
                        </Paper>
                    </Paper>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ margin: 0 }}>{t("UserAndTeams.Drag")}</p>
                    <ArrowRightAltIcon style={{ fontSize: "5rem" }} />
                </div>
                <div>
                    <h4 style={{ marginTop: 0 }}>{t("UserAndTeams.Team-example")}</h4>
                    <Paper
                        sx={(theme) => ({
                            width: "275px",
                            minHeight: "225px",
                            padding: "16px",
                            marginBottom: " 6px",
                            border: `1px solid ${"#225E4D"} `,
                            borderTop: `3px solid ${"#225E4D"} `,
                            backgroundColor: `${"#508c79"}0D`,
                        })}
                    >
                        <div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between", marginBottom: 24, width: "100%" }}>
                                <span style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                    <Avatar sx={(theme) => ({ width: theme.spacing(4), height: theme.spacing(4), fontSize: "1rem" })}>
                                        {/*
                                         * First character of name & first character of family name
                                         */}
                                        T
                                    </Avatar>
                                    <span style={{ marginLeft: 8 }}>
                                        <TeamName
                                            bossOfTeam={true}
                                            teamName={"Team A"}
                                            teamId={"1"}
                                            setTeamName={() => console.log("")}
                                            editMode={false}
                                            setEditMode={() => console.log("")}
                                        />
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div>
                            {staticTeam.users.map((user) => {
                                return (
                                    <div key={user.id} style={{ marginBottom: 8, display: "flex", alignItems: "center" }}>
                                        <div style={{ display: "flex", alignItems: "center", width: "100%", marginLeft: 7 }}>
                                            <span style={{ marginTop: 0, marginRight: 2 }}>
                                                <FaviconBox marginTop={0} favicon={null} name={user.name} />
                                            </span>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span>{user.name}</span>
                                                <span style={{ color: "#707070", fontSize: 13 }}>
                                                    {user.id === "jj1" ? "Teambeheerder" : "Gebruiker"}
                                                </span>
                                            </div>
                                        </div>

                                        <Tooltip placement="left" title="Verwijder uit team">
                                            <IconButton size="small">
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                );
                            })}
                        </div>
                    </Paper>
                </div>
            </div>
        </>
    );
};

export default TeamAnimation;
