import React, { useState, useEffect } from "react";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { useLazyQuery } from "@apollo/client";
import TenderSearch from "../../../components/TenderSearchComponent/TenderSearch";
import { MUIDataTableState } from "mui-datatables";
import { SortOrder, TenderSearchInput, TenderFilterTarget, TenderFilterInput, TenderFilterSource } from "../../../__generated__/globalTypes";
import setTitle from "../../../utils/setTitle";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import AllTenderTable from "./AllTenderTable";
import { ColumnProvider } from "../../../components/contextProviders/ColumnProvider";
import { TenderColumns } from "./TenderColumns";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
    FilterTendersOverviewMinimum,
    FilterTendersOverviewMinimumVariables,
    FilterTendersOverviewMinimum_filterTenders_filters,
    FilterTendersOverviewMinimum_filterTenders_results,
} from "../../../__generated__/FilterTendersOverviewMinimum";
import { QUERY_TENDERS_MINIMUM } from "../../../graphql/queryDefinitions";

const filters: TenderFilterInput = {};
const defaultSearch: TenderSearchInput = {
    source: TenderFilterSource.ALL_TENDERS,
    target: TenderFilterTarget.SUMMARY,
    filters,
};
const defaultVariables: FilterTendersOverviewMinimumVariables = {
    page: 1,
    count: 10,
    orderField: "updated_at",
    order: SortOrder.DESC,
    search: defaultSearch,
};

const TenderOverview: React.FC = () => {
    const theme = useTheme();
    /**
     * Current page in table
     * default = 1
     */
    const [page, setPage] = useState(defaultVariables.page);
    /**
     * rows per page
     * default = 10 rows
     */
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.count);
    /**
     * Current field to use for sorting table (has to be prop name of tenderObject)
     * default = "updated_at"
     */
    const [orderField, setOrderField] = useState<string>(defaultVariables.orderField);
    /**
     * Current order direction
     * default = descending
     */
    const [orderDir, setOrderDir] = useState<SortOrder>(defaultVariables.order);
    /**
     * Current active filters
     * default = {}
     */
    const [searchInput, setSearchInput] = useState<TenderFilterInput>({});
    /**
     * Current dataset
     * default = all tenders
     */
    const [source, setSource] = useState<TenderFilterSource>(defaultSearch.source);
    /**
     * User input in searchbox
     * default = ''
     */
    const [query, setQuery] = useState<string>("");
    /**
     * Current target to search in
     * default = summary
     */
    const [target, setTarget] = useState<TenderFilterTarget>(defaultSearch.target);
    /**
     * Contains all data for filterbar
     * filterset changes based on other states like: active filters/ query (user input)/ target/ source
     */
    const [filterData, setFilterData] = useState<FilterTendersOverviewMinimum_filterTenders_filters[] | null>([]);
    /**
     * Contains all data to fill datatable
     * dataset changes based on other states like: active filters/ query (user input)/ target/ source
     */
    // const [tendersData, setTendersData] = useState<FilterTendersOverview_filterTenders_results | null>();
    const [tendersData, setTendersData] = useState<FilterTendersOverviewMinimum_filterTenders_results | null>();

    // media query to check if device is a mobile
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    // translation
    const { t } = useTranslation();

    // Variables for the GQL query
    const variables: FilterTendersOverviewMinimumVariables = {
        page: page,
        count: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            source: source,
            target: target,
            query: query,
            filters: searchInput,
        },
    };

    /**
     * Initialize state from url parameters
     */
    useEffect(() => {
        // Check amount amount of rows per view
        if (localStorage.getItem("atr")) {
            // set rows per page to localstorage value or 10 if localstorage isn't set
            setRowsPerPage(parseInt(JSON.parse(localStorage.getItem("atr") || "10")));
        }
        if (sessionStorage.getItem("atf")) {
            // set active filters to sessionstorage or empty if it isn't set
            setSearchInput(JSON.parse(sessionStorage.getItem("atf") || "{}"));
        }
        if (sessionStorage.getItem("atq")) {
            // set query to sessionstorage or "" if isn't set
            setQuery(JSON.parse(sessionStorage.getItem("atq") || ""));
        }
        if (sessionStorage.getItem("atp")) {
            // set current page to sessionstorage value or 1 if sessionstorage isn't set
            setPage(parseInt(JSON.parse(sessionStorage.getItem("atp") || "1")));
        }
        if (window.location.search) {
            const query = queryString.parse(window.location.search);
            // check if url has query params for year, month and likedTender.
            // If so, set the state and filters accordingly.
            if (typeof query.year === "string" && typeof query.month === "string" && typeof query.likedTenders === "string") {
                setSource(TenderFilterSource.ALL_TENDERS);
                setTarget(TenderFilterTarget.SUMMARY);
                setSearchInput({});
            }
            if (typeof query.quarter === "string") {
                setSource(TenderFilterSource.MY_TENDERS);
                setSearchInput({ enddate_contract_quarters: [query.quarter] });
            }
        }
    }, []);

    /**
     * Define the GQL query
     */
    const [run, { error, loading }] = useLazyQuery<FilterTendersOverviewMinimum, FilterTendersOverviewMinimumVariables>(QUERY_TENDERS_MINIMUM, {
        variables: variables,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.filterTenders) {
                setFilterData(data.filterTenders.filters);
                setTendersData(data.filterTenders.results);
            }
        },
    });

    /**
     *   if query is empty, or only whitespace -> set target to summary
     *   relevant docs and all docs will be disabled as filter
     */
    useEffect(() => {
        sessionStorage.setItem("atq", JSON.stringify(query));
        if (typeof query === "string" && query.trim().length === 0) {
            setTarget(TenderFilterTarget.SUMMARY);
        }
    }, [query]);

    /**
     * Set page title
     */
    useEffect(() => {
        setTitle(t("AllTenders"));
        sessionStorage.setItem("atf", JSON.stringify(searchInput));
        sessionStorage.setItem("atp", JSON.stringify(page));
        localStorage.setItem("atr", JSON.stringify(rowsPerPage));
        run({
            variables: {
                page: page,
                count: rowsPerPage,
                orderField: orderField,
                order: orderDir,
                search: {
                    source: source,
                    target: target,
                    query: query,
                    filters: searchInput,
                },
            },
        });
    }, [run, orderDir, orderField, page, rowsPerPage, searchInput, query, target, source, t]);

    // handle pagination change: -page change, -rows per page change
    const handlePaginationChange = (page: number, rows: number) => {
        setPage(page);
        setRowsPerPage(rows);
        if (rows !== rowsPerPage) {
            setPage(1);
        }
    };

    // handle table change: -sort column, -sort direction
    const handleTableChange = (_: string, tableState: MUIDataTableState) => {
        if (tableState.sortOrder.direction) {
            setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
        }
        if (tableState.sortOrder.name) {
            setOrderField(tableState.sortOrder.name);
        }
    };

    /**
     * component with provider and searchbar used for loading, error and data page
     * content is variable for each situation
     */
    const renderPage = (content: React.ReactNode) => (
        <ColumnProvider storeKey="cutc" defaultState={TenderColumns}>
            <Box component="div" sx={{ padding: { xs: "20px 8px", md: "20px" }, overflow: "scroll" }}>
                <TenderSearch
                    columns={TenderColumns}
                    resetFilters={() => {
                        setSearchInput({});
                        setPage(1);
                    }}
                    orderField={orderField}
                    order={orderDir}
                    visible={noMobile ? true : false}
                    loading={loading}
                    activeView={0}
                    query={query}
                    target={target}
                    setQuery={setQuery}
                    setTarget={setTarget}
                    resetPage={() => setPage(1)}
                    filterData={filterData}
                    viewButtons={false}
                    searchInput={searchInput}
                    onChange={setSearchInput}
                    startSearch={() => {
                        setPage(1);
                        run({ variables });
                    }}
                />

                <div style={{ marginTop: 12 }} />

                {content}
            </Box>
        </ColumnProvider>
    );

    // show spinner if no data is available yet
    if (!tendersData && loading) return renderPage(<FullPageSpinner />);

    // empty table with error message
    if (error || !tendersData)
        return renderPage(
            <AllTenderTable
                loading={loading}
                error={error}
                rows={[]}
                paginatorInfo={{
                    count: 0,
                    currentPage: 0,
                    hasMorePages: false,
                    firstItem: 0,
                    lastItem: 0,
                    lastPage: 1,
                    perPage: 10,
                    total: 0,
                    __typename: "PaginatorInfo",
                }}
                onChangePagination={handlePaginationChange}
                onTableChange={handleTableChange}
            />
        );

    return renderPage(
        <AllTenderTable
            loading={loading}
            rows={tendersData?.data}
            paginatorInfo={
                tendersData?.paginatorInfo
                    ? tendersData?.paginatorInfo
                    : {
                          count: 0,
                          currentPage: 0,
                          hasMorePages: false,
                          firstItem: null,
                          lastItem: null,
                          lastPage: 1,
                          perPage: 10,
                          total: 0,
                          __typename: "PaginatorInfo",
                      }
            }
            onChangePagination={handlePaginationChange}
            onTableChange={handleTableChange}
        />
    );
};
export default TenderOverview;
