import React, { useState } from "react";
import MUIDataTable, { ExpandButton, MUIDataTableOptions } from "mui-datatables";
import { Paper, createTheme, Theme, ThemeProvider, TableRow, TableCell, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Disable } from "react-disable";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { useColumnSetter } from "../../../components/contextProviders/ColumnContext";
import { intOppColumns } from "./OppInterestingcolumns";
import LinearLoader from "../../../components/loading/LinearLoader";

import { useSelectedColumns } from "../../../components/contextProviders/ColumnProvider";
import currencyFormatDE from "../../../utils/currencyFormatter";
import { Euro, Event } from "@mui/icons-material";
import _ from "lodash";
import {
    GetRatedDocsForOpportunities_filterOpportunityFiles_results_data,
    GetRatedDocsForOpportunities_filterOpportunityFiles_results_paginatorInfo,
    GetRatedDocsForOpportunities_filterOpportunityFiles_snippets,
} from "../../../__generated__/GetRatedDocsForOpportunities";
import MobileTableNavigation from "../../../components/MobileTableNavigation";
import TableNavigation from "../../../components/TableNavigation";

interface Props {
    /**
     * Data to be displayed in table
     */
    rows: GetRatedDocsForOpportunities_filterOpportunityFiles_results_data[];
    /**
     * snippet Data
     */

    snippets: (GetRatedDocsForOpportunities_filterOpportunityFiles_snippets | null)[] | null;
    /**
     * Pagination info
     */
    paginatorInfo: GetRatedDocsForOpportunities_filterOpportunityFiles_results_paginatorInfo;
    /**
     * onChange page or rows per page
     */
    onChangePagination(page: number, rowsPerPage: number): void;
    /**
     * chang ein show/hide columns
     */
    onTableChange: MUIDataTableOptions["onTableChange"];
    /**
     * Loading state
     */
    loading: boolean;
    /**
     * Apollo error
     */
    error?: ApolloError | undefined;
}

// Custom override styling for table
const getMuiTheme = (theme: Theme) =>
    createTheme({
        palette: {
            primary: {
                main: "#225E4D",
            },
        },

        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro, system-ui, sans-serif",
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },

        components: {
            // MUIDataTable: {
            //     tableRoot: {
            //         width: "100% !important",
            //     },
            // },

            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "none !important",
                    },
                },
            },

            // Custom hovercolor: opacity 7% -> add 12 to hexcode
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: `${"#225E4D"}12 !important`,
                        },
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        textAlign: "start",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: 8,
                    },
                },
            },
            // MUIDataTableHeadCell: {
            //     data: {
            //         textAlign: "start",
            //     },
            // },
            // MUIDataTableSelectCell: {
            //     expandDisabled: {
            //         // Soft hide the button.
            //         visibility: "hidden",
            //     },
            // },
        },
    });

const OpportunityWorklistTable: React.FC<Props> = ({ rows, paginatorInfo, onChangePagination, loading, snippets }) => {
    const { t } = useTranslation();
    const { setSelectedRows, selectedRows } = useColumnSetter();
    const { items } = useSelectedColumns(intOppColumns);
    const [rowsExpanded, setRowsExpanded] = useState<number[]>([0]);
    const navigate = useNavigate();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    /**
     * navigate to page with page as query
     */
    const handlePageClick = (id: string, page: number) => {
        navigate(
            `/opportunities/${id}?${queryString.stringify({
                page: page,
            })}`
        );
    };

    /**
     * Data reformat definition, each container is a columnsection in table.
     */
    const convertedData: GetRatedDocsForOpportunities_filterOpportunityFiles_results_data[] = rows.map((i, index) => {
        const container: any = {};
        const id = "id";
        const filetype = "filetype";
        // const provincie = "provincie";
        const date = "date";
        const favicon = "favicon";
        const ad_id = "ad_id";
        const AD = "AD";
        const ad_country = "ad_country";
        const doc_name = "doc_name";
        const ca_type = "ca_type";
        const searchrules = "searchrules";
        const found_res = "found_res";
        const last_review = "last_review";
        const createdOpps = "createdOpps";
        const year_from = "year_from";
        const city = "city";
        const smallmenu = "smallmenu";

        // const not_rated_count = "not_rated_count";
        // const review_type = "review_type";
        // latest created at on personalOpportunities array

        // const uniqueSearchIds = [...new Map(i?.personalResults?.map((m) => [m?.search?.id, m])).values()];

        container[id] = i.id;
        container[ad_id] = i.contractingAuthority.id;
        container[filetype] = i.type?.name;
        // container[provincie] = "provincie";
        container[date] = i.id;
        container[favicon] = i.contractingAuthority.favicon_resized ?? i.contractingAuthority.favicon;
        container[AD] = i.contractingAuthority.name;
        container[ad_country] = i.contractingAuthority.country?.alpha2;
        container[doc_name] = i.name;
        container[ca_type] = i?.contractingAuthority?.types?.map((i) => i).join(", ");
        container[searchrules] = i.id;
        container[found_res] = i.id;
        // latest created at on personalOpportunities array
        container[last_review] = i.id;
        // container[not_rated_count] = "count";
        container[createdOpps] = i.id;
        container[year_from] = i.id;
        container[city] = i.contractingAuthority.city;
        container[smallmenu] = i.id;

        // container[review_type] = "review_type";

        return container;
    });

    /**
     * Fuction to get the selected rows and add id's of item inside row to context provider. now its possible to use the data inside to export,archive etc.
     */
    const onRowSelectionChange = (currentRowsSelected: Array<any>, allRowsSelected: Array<any>, rowsSelected: Array<any> | undefined) => {
        if (rowsSelected !== undefined) {
            const ids = rowsSelected.map((index) => convertedData[index].id);
            setSelectedRows(ids);
        }
    };

    const options: MUIDataTableOptions = {
        elevation: 0,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        filterType: "checkbox",
        draggableColumns: {
            enabled: false,
            transitionTime: 200,
        },
        serverSide: true,
        selectableRowsOnClick: false,
        selectToolbarPlacement: "none",
        onRowSelectionChange: onRowSelectionChange,
        rowsSelected: selectedRows.map((id: string) => convertedData.findIndex((item) => item.id === id)),
        // onColumnSortChange: onOrderChange,
        fixedSelectColumn: false,
        responsive: "standard",
        selectableRows: "none",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,

        /**
         *  if statement possible to set expandableRow to true or false
         */
        isRowExpandable: (dataIndex, expandedRows) => {
            if (rows[dataIndex].personalOpportunities !== null) return true;
            // Prevent row from being expandable if dataIndex doesnt have a opportunity

            return false;
        },

        /**
         * Textual helpers with translation
         * Header tooltip text
         */
        textLabels: {
            body: {
                noMatch: t("TableHelpers.NoMatch"),
                toolTip: t("TableHelpers.Sort"),
                columnHeaderTooltip: (column) => `${t(`Column_Header.${column.label}`)}`,
            },
        },

        /**
         * rowsExpanded -> state with indexnumber of expanded row.
         * defaultstate = index 0 (first row)
         */
        rowsExpanded: rowsExpanded,

        /**
         * Custom expanded row with created personal opportunities
         */
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            // Group array by page number
            const result = _.chain(rows[rowMeta.dataIndex].personalOpportunities)
                .groupBy((row) => row?.page?.page)
                .map((data, row) => ({ row, data })) //using ES6 shorthand to generate the objects
                .value();
            const snippet = snippets?.find((snippet) => snippet?.id === convertedData[rowMeta.dataIndex].id);

            return (
                <TableRow>
                    <TableCell colSpan={colSpan} style={{ padding: "10px 80px" }}>
                        <div>
                            {result
                                ?.sort((a, b) => (a.row > b.row ? 1 : -1))
                                .map((item, i: number) => {
                                    return (
                                        <div style={{ padding: "0px 8px 8px 0" }} key={i}>
                                            <Typography
                                                onClick={() => handlePageClick(rows[rowMeta.dataIndex].id, parseInt(item?.row))}
                                                variant="h6"
                                                style={{ fontSize: "0.9rem", cursor: "pointer", display: "inline-block" }}
                                            >
                                                Pagina {item?.row}
                                            </Typography>
                                            {item?.data?.map((item, i) => {
                                                const valueAndYear = item?.parsedValues?.map((val, i) => {
                                                    return (
                                                        <div style={{ display: "flex", alignItems: "center" }} key={i}>
                                                            {val?.year && (
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <Event
                                                                        sx={{
                                                                            marginRight: "4px",
                                                                            height: "0.8em",
                                                                            width: "0.8em",
                                                                            color: "#707070",
                                                                        }}
                                                                    />
                                                                    <Typography style={{ marginRight: 8 }}>{val?.year}</Typography>
                                                                </div>
                                                            )}
                                                            {val?.value !== null && val?.value !== undefined && (
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <Euro
                                                                        sx={{
                                                                            marginRight: "4px",
                                                                            height: "0.8em",
                                                                            width: "0.8em",
                                                                            color: "#707070",
                                                                        }}
                                                                    />
                                                                    <Typography>{currencyFormatDE(val?.value)}</Typography>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                });
                                                return (
                                                    <div style={{ paddingBottom: 8 }} key={i}>
                                                        {item?.comment !== "" && item?.comment !== null && item?.comment !== undefined && (
                                                            <Typography
                                                                dangerouslySetInnerHTML={{
                                                                    __html: `${item.comment}`,
                                                                }}
                                                            />
                                                        )}
                                                        <div>{valueAndYear}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                        </div>
                        <div>
                            {snippet?.snippets?.map((snippet, i) => {
                                return (
                                    <Typography
                                        key={i}
                                        style={{ padding: "0px 8px 8px 0" }}
                                        dangerouslySetInnerHTML={{
                                            __html: `...${snippet}...`,
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </TableCell>
                </TableRow>
            );
        },
        /**
         * Expand a single row
         */
        onRowExpansionChange: (_, allRowsExpanded) => {
            // setRowsexpanded to clicked row (expanded)
            // setRowsExpanded(allRowsExpanded.slice(-1).map((item) => item.index));
            setRowsExpanded(allRowsExpanded.map((item) => item.index));
        },

        customFooter() {
            if (onlyMobile) {
                return (
                    <MobileTableNavigation
                        perPage={paginatorInfo.perPage}
                        total={paginatorInfo.total}
                        firstItemIndex={paginatorInfo.firstItem as number}
                        lastItemIndex={paginatorInfo.lastItem as number}
                        currentPage={paginatorInfo.currentPage}
                        onChangePagination={onChangePagination}
                    />
                );
            }
            return (
                <TableNavigation
                    perPage={paginatorInfo.perPage}
                    total={paginatorInfo.total}
                    firstItemIndex={paginatorInfo.firstItem as number}
                    lastItemIndex={paginatorInfo.lastItem as number}
                    currentPage={paginatorInfo.currentPage}
                    onChangePagination={onChangePagination}
                />
            );
        },
        // onTableChange,
    };

    const components = {
        ExpandButton: function (props: any) {
            // if (props.dataIndex > 3) return <div style={{ width: "24px" }} />;
            return <ExpandButton {...props} />;
        },
    };

    return (
        <React.Fragment>
            <LinearLoader loading={loading} color="green" />
            <Disable disabledOpacity={0.6} disabled={loading}>
                <Paper square>
                    {/* Added paper element around table to use the square variant instead of the default, 
            - in MuidatatableOptons: elevation -> 0 */}
                    <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable title="Interessante kansen" data={convertedData} columns={items} options={options} components={components} />
                    </ThemeProvider>
                </Paper>
            </Disable>
        </React.Fragment>
    );
};

export default OpportunityWorklistTable;
