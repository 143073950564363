import React, { useContext } from "react";
import { Avatar, Link as Linkie, Paper, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { AccountTree } from "@mui/icons-material";

import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineItem as MuiTimelineItem,
} from "@mui/lab";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";

// import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
// import LogMapperModal from "../LogMapperModal";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import {
    GetUserLogsWithFilters_activities_data,
    GetUserLogsWithFilters_activities_data_subject_UserProject,
} from "../../../../__generated__/GetUserLogsWithFilters";
import { Link } from "react-router-dom";

// import LogModal from "../LogModal";

interface Props {
    item: any;
    log: GetUserLogsWithFilters_activities_data;
}

/**
 * This is used for the advanced search
 * timeline component adds this styling automatically,
 * Now its overruled so its aligned on the left side.
 */
const TimelineItem = withStyles(MuiTimelineItem, {
    missingOppositeContent: {
        "&:before": {
            display: "none",
        },
    },
    content: {
        flex: 20,
    },
    oppositeContent: {
        flex: 3,
    },
});

const StyledLink = styled(Link)`
    cursor: pointer;
    color: #000000;
    text-decoration: underline;
    &:hover {
        background-color: #0000001a;
    }
`;

const StyledLinkTyp = styled(Linkie)`
    cursor: pointer;
    color: #000000;
    text-decoration: underline;
    &:hover {
        background-color: #0000001a;
    }
`;

const UserProjectLog: React.FC<Props> = ({ log, item }) => {
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // const [open, setOpenModal] = useState(false);
    // const handleOpen = () => setOpenModal(true);
    // const handleClose = () => setOpenModal(false);

    const { t } = useTranslation();

    if (log.subject === null) {
        return <></>;
    }

    // const parsed_value = JSON.parse(log.properties);
    const project = log.subject as GetUserLogsWithFilters_activities_data_subject_UserProject;

    // const checkChanges = (jsonStr: string, type: string) => {
    //     const data = JSON.parse(jsonStr);
    //     const attributes = data.attributes;

    //     // If type is "created", return directly
    //     if (type === "created") {
    //         return "";
    //     }

    //     // If type is "deleted", return directly
    //     if (type === "deleted") {
    //         return "";
    //     }

    // const changedAttributes = Object.keys(attributes);

    // if (changedAttributes.length > 1) {
    //     return `${t("log.multiple_data")} van`;
    // } else if (changedAttributes.length === 1) {
    //     const changedAttr = changedAttributes[0];
    //     return `${t(`log.${changedAttr}`)} van`; // return key
    //     // return `${changedAttr}: ${attributes[changedAttr]}`; => return key + value
    // } else {
    //     return "No attributes changed";
    // }
    // };

    return (
        <>
            {isMobile ? (
                <Paper variant="outlined" sx={{ padding: "8px", marginTop: "8px", display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                        <AccountTree />
                    </Avatar>
                    <div>
                        {/* TEXT FOR EVERY ITEM DEFINED */}
                        <Typography>
                            <span>
                                {log?.causer?.employee.givenname} {t("has")} project
                                {/* {checkChanges(log.properties, log.description as string)} */}
                            </span>{" "}
                            {/*
                             * Link to page
                             * map item name -> searchrulename
                             */}
                            <StyledLink to={`/projects/${project.id}`}>{project.title}</StyledLink>
                            {log.description !== null && <span> {t(`log.${log.description as string}`)}</span>}
                        </Typography>
                        {/* TIME */}
                        <Typography variant="caption" color="textSecondary">
                            {moment(log.created_at).format("LL")}, {moment(log.created_at).format("LT")}
                        </Typography>
                    </div>
                </Paper>
            ) : (
                <TimelineItem>
                    <TimelineOppositeContent sx={{ flex: 3 }}>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LL")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LT")}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                            <AccountTree />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ flex: 20 }}>
                        <Paper elevation={1} sx={{ padding: "6px 16px", marginTop: "3px" }}>
                            <Typography style={{ display: "inline-block" }}>
                                <span>
                                    {log?.causer?.employee.givenname} {t("has")} project
                                    {/* {checkChanges(log.properties, log.description as string)} */}
                                </span>{" "}
                                {/*
                                 * Link to page
                                 * map item name -> searchrulename
                                 */}
                                <StyledLinkTyp
                                    onClick={() => {
                                        changeProject({
                                            projectID: project.id,
                                            projectTitle: project.title,
                                            state: project.state?.id,
                                        });
                                        setOpen(true);
                                    }}
                                >
                                    {project.title}
                                </StyledLinkTyp>
                                {log.description !== null && <span> {t(`log.${log.description as string}`)}</span>}
                            </Typography>
                            {/* {log.description !== "created" && (
                        <Typography
                            onClick={handleOpen}
                            sx={{
                                marginLeft: "8px",
                                display: "inline-block",
                                fontStyle: "italic",
                                cursor: "pointer",
                                "&:hover": {
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            Bekijk details
                        </Typography>
                    )} */}
                        </Paper>
                        {/* <LogMapperModal onClose={handleClose} open={open} properties={log.properties} /> */}
                    </TimelineContent>
                </TimelineItem>
            )}
        </>
    );
};

export default UserProjectLog;
