import React from "react";
import { Tabs, Tab, Typography, Divider, tabsClasses } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseButton from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import ProjectViewToggleButtons, { View } from "./topbar/ProjectViewToggleButtons";
import { useLocation } from "react-router-dom";

// import { useMatch } from "react-router";

export interface DefaultTab {
    id: number;
    label: string;
    route: string;
}

export interface DynamicTab {
    value: string;
    label: string;
}

interface Props {
    // Active page url, to determine which tab is active
    value: string;
    // Handler when user clicks on a tab
    onChange(url: string): void;
    // Handler when user wants to close a tab
    onClose(url: string): void;
    // All tabs
    tabs: DynamicTab[];
    // handler to close all tabs
    onCloseAll(): void;
    // default tabs
    defaultTabs: DefaultTab[];
    // page
    page?: "Project";
    setView: (view: View) => void;
    view: View;
}

const ProjectTabs: React.FC<Props> = ({ value, onChange, onClose, tabs, onCloseAll, defaultTabs, page = "Project", view, setView }) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const history = useLocation();

    // Track event
    const TrackTabs = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                width: `calc(100vw - 68px)`,
            }}
        >
            <Tabs
                // value={match?.pathnameBase || props.value}
                id="defaultTabBar"
                value={value}
                onChange={(_, value) => {
                    onChange(value);
                }}
                indicatorColor="primary"
                textColor="primary"
                sx={{
                    minWidth: "140px",
                    maxWidth: "140px",

                    "& .MuiTabs-root": {
                        height: "100%",
                    },
                    "& .MuiTabs-flexContainer": {
                        height: "100%",
                    },
                }}
            >
                {/* Map over default tabs */}
                {defaultTabs.map((tab) => {
                    return (
                        <Tab
                            key={tab.id}
                            sx={{
                                minWidth: "140px",
                                maxWidth: "140px",
                            }}
                            value={tab.route}
                            label={t(tab.label)}
                            onClick={() =>
                                TrackTabs({
                                    category: page,
                                    action: `click-tab-${page}-${tab.label}`,
                                })
                            }
                        />
                    );
                })}
            </Tabs>

            {/* Add vertical divider on open tab */}
            {tabs.length > 0 && <Divider orientation="vertical" flexItem variant="middle" style={{ margin: "12px 0px" }} />}

            {/* Dynamic tabbar with scrollable buttons */}
            <Tabs
                value={value.replace("/contacts", "").replace("/policy", "").replace("/settlings", "")}
                onChange={(_, value) => onChange(value)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons={tabs.length > 2 ? "auto" : false}
                style={{ flexGrow: 1 }}
                sx={{
                    "& .MuiTabs-root": {
                        height: "100%",
                    },
                    "& .MuiTabs-flexContainer": {
                        height: "100%",
                    },
                    [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 },
                    },
                }}
            >
                {/* All open tabs (dynamic) */}
                {tabs.map((tab, i) => (
                    <Tab
                        key={i}
                        value={tab.value}
                        // classes={{ wrapper: classes.defaultTabWrapper }}
                        sx={{ flexDirection: "row" }}
                        label={
                            <>
                                {/* Label of the tab, truncated using css styles */}
                                <Tooltip title={tab.label}>
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "200px",
                                            whiteSpace: "nowrap",
                                            display: "inline-block",
                                        }}
                                    >
                                        {tab.label}
                                    </span>
                                </Tooltip>

                                {/* Close button */}
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        // Prevent the tab iself being clicked,
                                        // we want to execute the click on the close button
                                        e.stopPropagation();
                                        onClose(tab.value);
                                    }}
                                    sx={{ marginLeft: "4px" }}
                                >
                                    <CloseButton fontSize="small" />
                                </IconButton>
                            </>
                        }
                    />
                ))}
            </Tabs>

            {/* Show close all button when open tabs is 2 or more */}
            <div style={{ display: "flex", justifyContent: "space-between", minWidth: history.pathname === "/projects" ? "330px" : "" }}>
                {tabs.length >= 2 ? (
                    <>
                        <Divider orientation="vertical" flexItem variant="middle" style={{ margin: "12px 0px" }} />
                        <Tooltip placement="bottom" title={"Alle tabbladen sluiten"}>
                            <div
                                onClick={() => {
                                    onCloseAll();
                                    TrackTabs({
                                        category: page,
                                        action: `Click-close-all-${page.toLowerCase()}-tabs`,
                                    });
                                }}
                                style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0px 16px", cursor: "pointer" }}
                            >
                                <Typography variant="button" sx={{ color: (theme) => `${"#000000"}8a` }}>
                                    Sluiten
                                </Typography>
                                {/* Close button */}
                                <IconButton size="small" style={{ marginLeft: "4px" }}>
                                    <CloseButton fontSize="small" />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </>
                ) : (
                    <div />
                )}
                {history.pathname === "/projects" && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "16px",
                            }}
                        >
                            <div style={{ marginLeft: "8px" }}>
                                <ProjectViewToggleButtons setView={setView} view={view} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProjectTabs;
