import React from "react";
import { Typography } from "@mui/material";

interface Props {
    barColor: string;
    percentage: number;
    cuAnswer: boolean;
    count: number;
    answerText?: string;
    admin?: boolean;
}

const PercentageBar: React.FC<Props> = ({ barColor, percentage, cuAnswer, count, answerText = "", admin = false }) => {
    return (
        <div style={{ display: "flex", padding: "8px 8px 0px", width: "100%", alignItems: "center", flexDirection: "column" }}>
            <div style={{ display: "flex", width: "100%" }}>
                <div
                    style={{
                        marginRight: "4px",
                        color: "#fff",
                        backgroundColor: admin ? `${barColor}` : cuAnswer ? `${barColor}` : `${barColor}3D`,
                        minWidth: admin ? "64px" : "46px",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: admin ? "64px" : "46px",
                    }}
                >
                    <Typography variant="h1" align="center" sx={{ padding: "12px 0px" }} fontWeight={cuAnswer ? "bold" : 500}>
                        {count}
                    </Typography>
                </div>
                <div
                    style={{
                        width: `calc(${percentage}% + 20px)`,
                        maxWidth: "100%",
                        backgroundColor: admin ? `${barColor}` : cuAnswer ? `${barColor}` : `${barColor}3D`,
                        borderRadius: "6px",
                        transition: "width 0.5s ease-in-out",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        padding: "12px 0px",
                        minWidth: admin ? "64px" : "60px",
                        height: admin ? "64px" : "46px",
                    }}
                >
                    {count > 0 && (
                        <Typography variant="h1" fontWeight={cuAnswer ? "bold" : 500} sx={{ color: "#fff" }}>
                            {percentage.toFixed(0)}%
                        </Typography>
                    )}
                </div>
            </div>
            {admin && (
                <div
                    style={{
                        color: `${barColor}`,
                        minWidth: "100%",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        height: "46px",
                    }}
                >
                    <Typography variant="h1" align="right" sx={{ paddingBottom: "12px" }} fontWeight={cuAnswer ? "bold" : 500}>
                        {answerText}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default PercentageBar;
