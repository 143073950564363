import React, { useState } from "react";
import { AvatarGroup } from "@mui/lab";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Popover,
    PopoverOrigin,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import { Group, PersonAddAltRounded } from "@mui/icons-material";
import truncate from "truncate";
import { withStyles } from "tss-react/mui";

export interface User {
    id: string;
    name: string;
    initials: string;
}
interface Team {
    id: string;
    name: string;
    initials: string;
    users: {
        id: string;
        name: string;
        familyName: string;
    }[];
}

interface Props {
    users: User[];
    editMode: boolean;
    setUsers?: (users: User[]) => void;
    size: "small" | "medium";
    amounToShow: number;
    chosenUsers: User[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    teams: Team[];
    loading: boolean;
}

const StyledAvatar = withStyles(Avatar, (theme: Theme) => ({
    root: {
        width: "28px",
        height: "28px",
        fontSize: "0.8rem",
        border: "2px solid #fff",
    },
}));

const Avatars: React.FC<Props> = ({
    chosenUsers,
    users,
    editMode,
    setUsers,
    size,
    amounToShow = 4,
    teams,
    anchorOrigin = {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin = {
        vertical: "top",
        horizontal: "center",
    },
    loading,
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [checked, setChecked] = useState<number[]>([parseInt(localStorage.getItem("user_id") || "")]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    /**
     * @param value user id
     * @returns return checked or unchecked state of user
     */
    const handleToggle = (e: any, value: number) => {
        e.stopPropagation();
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleTeamToggle = (e: any, team: Team) => {
        e.stopPropagation();
        const usersInTeam = team.users.map((i) => i.id);

        console.log("team", usersInTeam);
    };

    const saveSelectedUsers = () => {
        setUsers && setUsers(users.filter((u) => checked.includes(parseInt(u.id))));
    };

    return (
        <React.Fragment>
            {editMode && chosenUsers.length === 0 ? (
                <Tooltip title="Toewijzen" placement="top">
                    <IconButton style={{ padding: 0 }} size={size} onClick={(e) => handleClick(e)}>
                        <Avatar sx={{ width: "28px", height: "28px" }}>
                            <PersonAddAltRounded sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(3) }} />
                        </Avatar>
                    </IconButton>
                </Tooltip>
            ) : editMode ? (
                <AvatarGroup
                    onClick={(e: any) => handleClick(e)}
                    max={amounToShow}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    {chosenUsers.map((user) => {
                        return <StyledAvatar key={user.id}>{user.initials}</StyledAvatar>;
                    })}
                </AvatarGroup>
            ) : (
                <Tooltip
                    title={chosenUsers.map((user) => (
                        <span key={user.id}>
                            {user.name}
                            <br />
                        </span>
                    ))}
                    placement="top"
                >
                    <AvatarGroup
                        max={amounToShow}
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        {chosenUsers.map((user) => {
                            return <StyledAvatar key={user.id}>{user.initials}</StyledAvatar>;
                        })}
                    </AvatarGroup>
                </Tooltip>
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                slotProps={{
                    paper: {
                        sx: {
                            width: "300px",
                        },
                    },
                }}
            >
                {loading ? (
                    <Box style={{ height: "310px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            {<Group style={{ color: "#f2f2f270", height: "9rem", width: "9rem" }} />}
                            <Typography variant="h5" style={{ color: "#f2f2f2" }}>
                                Laden
                            </Typography>
                        </div>
                    </Box>
                ) : (
                    <div style={{ maxHeight: "310px", overflow: "auto" }}>
                        <Box>
                            {teams.map((team) => {
                                const userList = team.users.map((i) => i.id);
                                /**
                                 * UserListItem component for details of user with edit and delete option
                                 */
                                return (
                                    <>
                                        <ListItemButton key={team.id} onClick={(e) => handleTeamToggle(e, team)} dense disableRipple>
                                            {/* Listitem with userdetails. */}
                                            <ListItemAvatar sx={{ mt: 0, minWidth: 0, marginRight: "16px" }}>
                                                <StyledAvatar>
                                                    <Typography>{team.name.match(/\b(\w)/g)?.slice(0, 2)}</Typography>
                                                </StyledAvatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                /**
                                                 * Combination of user givenname and familyname. line through when user is inactive
                                                 */
                                                primary={
                                                    <>
                                                        <Typography>{truncate(team.name ? team.name : "", 40)}</Typography>
                                                    </>
                                                }
                                                secondary={team.users?.map((i) => i.name).join(", ")}
                                            />
                                            <Checkbox
                                                size="small"
                                                edge="start"
                                                disableRipple
                                                checked={checked.every((v) => userList.includes(v.toString()))}
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                            />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                );
                            })}
                        </Box>
                        <Box>
                            {users.map((user) => {
                                /**
                                 * UserListItem component for details of user with edit and delete option
                                 */
                                return (
                                    <>
                                        <ListItemButton key={user.id} onClick={(e) => handleToggle(e, parseInt(user.id))} dense disableRipple>
                                            {/* Listitem with userdetails. */}
                                            <ListItemAvatar sx={{ mt: 0, minWidth: 0, marginRight: "16px" }}>
                                                <Avatar sx={{ width: "28px", height: "28px" }}>
                                                    <Typography>{user.initials}</Typography>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                /**
                                                 * Combination of user givenname and familyname. line through when user is inactive
                                                 */
                                                primary={
                                                    <>
                                                        <Typography>{truncate(user.name ? user.name : "", 40)}</Typography>
                                                    </>
                                                }
                                            />
                                            <Checkbox
                                                size="small"
                                                edge="start"
                                                disableRipple
                                                checked={checked.indexOf(parseInt(user.id)) !== -1}
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                            />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                );
                            })}
                        </Box>
                    </div>
                )}
                {/* Buttons to cancel or share */}
                <Box style={{ margin: 8, display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        size="small"
                        variant="text"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClose();
                        }}
                    >
                        Annuleren
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            e.stopPropagation();
                            saveSelectedUsers();
                            handleClose();
                        }}
                    >
                        Opslaan
                    </Button>
                </Box>
            </Popover>
        </React.Fragment>
    );
};

export default Avatars;
