import React from "react";
import { useTenderChat } from "./contextProviders/TenderChatContext";
import { IconButton, Tooltip } from "@mui/material";
// import { useTranslation } from "react-i18next";
import { Message } from "@mui/icons-material";
import queryString from "query-string";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../models/MatomoEvent";
import { useLocation } from "react-router-dom";
interface Props {
    id: string;
    name: string;
    module: string;
    notesAvailable: boolean;
    location: "Dashboard" | "Calendar" | "Tender" | "Opportunity" | "Ca" | "Organizations" | "Help" | "Personal";
    buttonSize?: "small" | "medium" | "large";
}

const ChatButtonTopBar: React.FC<Props> = ({ id, name, module, notesAvailable, location, buttonSize = "medium" }) => {
    const { setOpenModal, setChatTarget, openModal } = useTenderChat();
    // const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const loc = useLocation();

    /**
     * Open dialog with params in url
     */
    if (window.location.search && !openModal) {
        const query = queryString.parse(window.location.search);
        // Open chat via url or open chat + conversation via url
        if (typeof query.cto === "string") {
            setChatTarget(id, module, name, query.cto !== "open" ? query.cto : "");
            setOpenModal(true);
            window.history.replaceState({}, "", loc.pathname);
        }
    }

    // Track event
    const trackChatEvent = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        /**
         * Iconbutton to open chatdialog to chat with employees or create a private note
         * Tooltip text and icon color will be changed whenever a message or note is available
         */
        <Tooltip title={"Chatten"} placement="top" disableInteractive>
            <IconButton
                size={buttonSize}
                onClick={(e) => {
                    trackChatEvent({ category: location, action: `click-open-${location.toLowerCase()}-chat` });
                    // Prevent the tab iself being clicked,
                    // we want to execute the click on the close button
                    e.stopPropagation();
                    setChatTarget(id, module, name, "");
                    setOpenModal(true);
                }}
            >
                <Message fontSize="small" sx={{ color: notesAvailable ? "#808080" : "#b3b3b3" }} />
            </IconButton>
        </Tooltip>
    );
};

export default ChatButtonTopBar;
