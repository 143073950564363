import React from "react";
import setTheme from "../utils/setTheme";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";

import MainLayout from "../components/layout/MainLayout";
import { ThemeProvider } from "@mui/material/styles";
import { greyTheme } from "../assets/styles";

import { Tabs, Tab } from "@mui/material";

import PersonalInformation from "./profile/personalInfo/PersonalInformation";
import Settings from "./profile/preferences/Settings";
import SearchLineLayout from "./profile//SearchRule/SearchLineLayout";
import { useTranslation } from "react-i18next";
import LogbookLayout from "./profile/Logbook/LogbookLayout";
import UsersAndTeams from "./profile/UserAndTeams/UsersAndTeams";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../models/MatomoEvent";
import { withStyles } from "tss-react/mui";

// ELements
const profileRoutes = [
    { route: "personal", label: "PersonalInfo", component: <PersonalInformation /> },
    // { route: "users", label: "UserAndTeams.Users", component: <UsersAndTeams /> },
    { route: "users-teams", label: "UsersAndTeams", component: <UsersAndTeams /> },
    { route: "search-rules", label: "SearchRules", component: <SearchLineLayout /> },
    { route: "settings", label: "Preferences", component: <Settings /> },
    // { route: "license", label: "LicenseAndPayment", component: <BillingOverview /> },
    { route: "log", label: "Logbook", component: <LogbookLayout /> },
];

// Links (topbar and sidebar links)
export const profileLinks = [
    { route: "/profile/personal", label: "PersonalInfo", category: "Personal", action: "Go-to-personal" },
    // { route: "/profile/users", label: "UserAndTeams.Users" },
    { route: "/profile/users-teams", label: "UsersAndTeams", category: "Personal", action: "Go-to-teams" },
    { route: "/profile/search-rules", label: "SearchRules", category: "Personal", action: "Go-to-searchrules" },
    { route: "/profile/settings", label: "Preferences", category: "Personal", action: "Go-to-preferences" },
    // { route: "/profile/license", label: "LicenseAndPayment" },
    { route: "/profile/log", label: "Logbook", category: "Personal", action: "Go-to-log" },
    { route: "/tendenz", label: "TendenZ", category: "Personal", action: "Go-to-tendenz" },
];

const styles = {
    root: { height: "100%" },
    flexContainer: { height: "100%" },
};

// const StyledBadge = withStyles((theme: Theme) =>
//     createStyles({
//         badge: {
//             right: -3,
//             height: 6,
//             minWidth: 6,
//         },
//     })
// )(Badge);

const TopBarTabs = withStyles(Tabs, styles);

const Profile: React.FC = () => {
    const history = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [currentProfilePage] = history.pathname.match(/\/[\w-]+\/[\w-]+/) || [];
    setTheme("darkGray");

    const { trackEvent, trackPageView } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // Track page view
    trackPageView({
        documentTitle: "Profile", // optional
        href: `https://tool.tender.guide${history.pathname}`, // optional
    });

    // const getMuiTheme = (theme: Theme) =>
    //     createTheme({
    //         breakpoints: {
    //             values: {
    //                 xs: 0,
    //                 sm: 600,
    //                 md: 960,
    //                 lg: 1280,
    //                 xl: 1920,
    //                 xxl: 2500,
    //             },
    //         },
    //         palette: {
    //             ...theme.palette,
    //             primary: {
    //                 main: materialTheme.moduleColors?.grey || materialTheme.palette.primary.main,
    //                 light: "#9e9e9e" || materialTheme.palette.primary.light,
    //             },
    //             secondary: {
    //                 main: materialTheme.palette.error.main || materialTheme.palette.secondary.main,
    //             },
    //         },
    //         typography: {
    //             fontSize: 14,
    //             fontFamily: "Maven Pro, system-ui, sans-serif, Material Icons",
    //             h1: {
    //                 fontSize: "1.8rem",
    //                 "@media (max-width: 800px)": {
    //                     fontSize: "1.4rem",
    //                 },
    //             },
    //             h2: {
    //                 fontSize: "1.6rem",
    //                 "@media (max-width: 800px)": {
    //                     fontSize: "1.2rem",
    //                 },
    //             },
    //             h3: {
    //                 fontSize: "1.4rem",
    //                 "@media (max-width: 800px)": {
    //                     fontSize: "1.0rem",
    //                 },
    //             },
    //             h4: {
    //                 fontSize: "0.8rem",
    //                 spacing: "0.06em",
    //                 "@media (max-width: 800px)": {
    //                     fontSize: "0.7rem",
    //                 },
    //             },
    //             h5: {
    //                 fontSize: "1.2rem",
    //                 "@media (max-width: 800px)": {
    //                     fontSize: "0.9rem",
    //                 },
    //             },
    //             h6: {
    //                 fontSize: "0.95rem",
    //                 "@media (max-width: 800px)": {
    //                     fontSize: "0.8rem",
    //                 },
    //             },
    //             body1: {
    //                 fontSize: "0.875rem",
    //                 "@media (max-width: 800px)": {
    //                     fontSize: "0.8rem",
    //                 },
    //             },
    //         },
    //         components: {
    //             // MUIDataTable: {
    //             //     tableRoot: {
    //             //         width: "100% !important",
    //             //     },
    //             // },

    //             // Hide toolbar
    //             // MuiToolbar: {
    //             //     styleOverrides: {
    //             //         root: {
    //             //             display: "none !important",
    //             //         },
    //             //     },
    //             // },

    //             // Custom hovercolor: opacity 7% -> add 12 to hexcode
    //             MuiTableRow: {
    //                 styleOverrides: {
    //                     root: {
    //                         "&:hover": {
    //                             backgroundColor: `${"#173357"}12 !important`,
    //                         },
    //                     },
    //                 },
    //             },
    //             MuiTableHead: {
    //                 styleOverrides: {
    //                     root: {
    //                         textAlign: "start",
    //                     },
    //                 },
    //             },
    //             MuiTableCell: {
    //                 styleOverrides: {
    //                     root: {
    //                         padding: 8,
    //                     },
    //                 },
    //             },
    //             // MUIDataTableHeadCell: {
    //             //     data: {
    //             //         textAlign: "start",
    //             //     },
    //             // },
    //         },
    //     });

    return (
        <ThemeProvider theme={greyTheme}>
            <MainLayout
                header={
                    <TopBarTabs
                        value={currentProfilePage}
                        onChange={(_, value) => navigate(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {profileLinks.map((route) => (
                            <Tab
                                sx={{
                                    minWidth: "160px",
                                    maxWidth: "160px",
                                }}
                                onClick={() => trackevents({ category: "Personal", action: route.action })}
                                key={route.route}
                                label={
                                    <React.Fragment>
                                        {/* {route.route === "/profile/personal" ? (
                                            <StyledBadge color="secondary" variant="dot">
                                                <>{t(`${route.label}`)}</>
                                            </StyledBadge>
                                        ) : route.route === "/profile/search-rules" ? (
                                            <StyledBadge color="secondary" variant="dot">
                                                <>{t(`${route.label}`)}</>
                                            </StyledBadge>
                                        ) : ( */}
                                        <>{t(`${route.label}`)}</>
                                        {/* )} */}
                                    </React.Fragment>
                                }
                                value={route.route}
                            />
                        ))}
                    </TopBarTabs>
                }
            >
                <Routes>
                    <Route path="" element={<PersonalInformation />} />
                    {profileRoutes.map((route) => (
                        <Route key={route.route} path={route.route} element={route.component} />
                    ))}
                </Routes>
            </MainLayout>
        </ThemeProvider>
    );
};

export default Profile;
