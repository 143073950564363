import React, { useContext, useState } from "react";
import {
    Grid,
    IconButton,
    List,
    Paper,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
    Checkbox,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { AddTask, Close, RadioButtonUnchecked, TaskAlt } from "@mui/icons-material";
import EditableTaskField from "../EditableTaskField";
import TaskDatePicker from "./TaskDatePicker";
import TaskPriorityChip from "./TaskPriorityChip";
import { Disable } from "react-disable";
import TaskProgressBar from "./TaskProgressBar";
import { Priority, ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import {
    DELETE_PROJECT_TASK,
    UPDATE_TASK_COMPLETION,
    UPDATE_TASK_DEADLINE,
    UPDATE_TASK_DESCRIPTION,
    UPDATE_TASK_PRIORITY,
} from "../../../../graphql/mutationDefProjects";
import { updateTaskCompletion, updateTaskCompletionVariables } from "../../../../__generated__/updateTaskCompletion";
import { updateTaskPriority, updateTaskPriorityVariables } from "../../../../__generated__/updateTaskPriority";
import { SINGLE_PROJECT_TASKS } from "../../../../graphql/queryDefProjects";
import { single_project_tasks, single_project_tasksVariables } from "../../../../__generated__/single_project_tasks";
import { updateTaskDescription, updateTaskDescriptionVariables } from "../../../../__generated__/updateTaskDescription";
import { updateTaskDeadline, updateTaskDeadlineVariables } from "../../../../__generated__/updateTaskDeadline";
import UserForTask from "./UserForTask";
import CreateTaskForEachUser from "./CreateTaskForEachUser";
import TaskMenu from "./TaskMenu";
import RemovedTask from "./RemovedTask";
import { deleteTask, deleteTaskVariables } from "../../../../__generated__/deleteTask";
import MatomoEvent from "../../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MobileTask from "./MobileTask";

interface Props {
    a?: number;
}

export interface Task {
    id: string;
    task_description: string;
    task_completed: boolean;
    task_deadline: any;
    task_priority: Priority;
    users: any[];
}

const Tasks: React.FC<Props> = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [hoverBox, setHoverBox] = useState<string>("");
    const [clickedBox] = useState<string>("");
    // Show textfield with add button
    const [addNewTask, showAddNewTask] = useState<boolean>(false);
    // State has all created tasks
    const { project } = useContext(ProjectToOpenContext);
    const [chosentasks, setChosenTasks] = useState<string>("Taken");

    const { trackEvent } = useMatomo();
    // track navigation items
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Open menu to filter searchrules on type
     * @param type => "opportunies" || "tenders" || "all"
     */
    const handleClickMenu = (type: string) => {
        setChosenTasks(type);
    };

    // Complete task
    const [saveTaskCompletion] = useMutation<updateTaskCompletion, updateTaskCompletionVariables>(UPDATE_TASK_COMPLETION);
    // Change priority of task
    const [saveTaskPriority] = useMutation<updateTaskPriority, updateTaskPriorityVariables>(UPDATE_TASK_PRIORITY);
    // Change description of task
    const [saveTaskDescription] = useMutation<updateTaskDescription, updateTaskDescriptionVariables>(UPDATE_TASK_DESCRIPTION);
    // Change deadline of task
    const [saveTaskDeadline] = useMutation<updateTaskDeadline, updateTaskDeadlineVariables>(UPDATE_TASK_DEADLINE);
    // soft delete task
    const [deleteTask] = useMutation<deleteTask, deleteTaskVariables>(DELETE_PROJECT_TASK);

    const { data, loading, error, refetch } = useQuery<single_project_tasks, single_project_tasksVariables>(SINGLE_PROJECT_TASKS, {
        variables: {
            id: project.projectID,
        },
        onCompleted: (data) => {
            if (data && data.project && data.project.tasks && data.project.tasks.length === 0) {
                showAddNewTask(true);
            }
        },
        fetchPolicy: "network-only",
    });

    // Check taskdone to percentage
    const percentage = (num1: number, num2: number) => {
        return (num1 / num2) * 100;
    };

    /**
     * Update complete state of single task
     * @param taskId id of task
     * @param boolean bolean to set task to
     */
    const onChangeBoolean = async (taskId: string, boolean: boolean) => {
        try {
            await saveTaskCompletion({
                variables: {
                    id: taskId,
                    completed: boolean,
                },
                onCompleted: () => {
                    refetch();
                    trackClick({ category: "Project", action: "Change-task-completion" });
                },
            });
        } catch (e) {}
    };

    /**
     * Delete task
     * @param taskId id of task
     */
    const onDelete = async (taskId: string) => {
        try {
            await deleteTask({
                variables: {
                    id: taskId,
                },
                onCompleted: () => {
                    refetch();
                    trackClick({ category: "Project", action: "Delete-task" });
                },
            });
        } catch (e) {}
    };

    /**
     * Update priority of task
     * @param taskId id of task
     * @param priority priority of task
     */
    const onChangePriority = async (taskId: string, priority: Priority) => {
        try {
            await saveTaskPriority({
                variables: {
                    id: taskId,
                    priority: priority,
                },

                onCompleted: () => {
                    refetch();
                    trackClick({ category: "Project", action: "Change-task-priority", name: priority.toString() });
                },
            });
        } catch (e) {}
    };

    /**
     * Update priority of task
     * @param taskId id of task
     * @param priority priority of task
     */
    const onChangesaveTaskDescription = async (taskId: string, description: string) => {
        try {
            await saveTaskDescription({
                variables: {
                    id: taskId,
                    description: description,
                },
                onCompleted: () => {
                    refetch();
                    trackClick({ category: "Project", action: "Change-task-description", name: "description" });
                },
            });
        } catch (e) {}
    };

    /**p
     * Update priority of task
     * @param taskId id of task
     * @param priority priority of task
     */
    const onChangesaveTaskDeadline = async (taskId: string, deadline: moment.Moment | null) => {
        if (deadline !== null) {
            try {
                saveTaskDeadline({
                    variables: {
                        id: taskId,
                        deadline: moment(deadline).format("YYYY-MM-DD"),
                    },
                    onCompleted: () => {
                        refetch();
                        trackClick({ category: "Project", action: "Change-task-deadline", name: deadline.format() });
                    },
                });
            } catch (e) {}
        }
    };

    const renderpage = (tasksDone: number, totalTasks: number, content: React.ReactNode) => {
        return (
            <Grid item style={{ marginTop: 40 }}>
                <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between" }}>
                    {/* Component title */}
                    <TaskMenu openMenu={handleClickMenu} menuText={chosentasks} />
                    {/* <Typography variant="h6">Taken</Typography> */}
                    {/* Tooltip to show what the action does */}
                    <Tooltip title="Taak toevoegen" placement="left">
                        <IconButton disabled={addNewTask} size="small" onClick={() => showAddNewTask(true)}>
                            <AddTask fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>

                <TaskProgressBar done={tasksDone} totalAmount={totalTasks} />

                <Paper elevation={0} variant="outlined" style={{ marginBottom: "16px" }}>
                    {content}
                </Paper>
                {/*
                 * add new task(s) component
                 */}
                {addNewTask && <CreateTaskForEachUser users={project.users} showAddNewTask={showAddNewTask} />}
            </Grid>
        );
    };

    if (loading) {
        return renderpage(0, 0, <div />);
    }

    if (error || !data || !data.project || !data.project.tasks) {
        return renderpage(0, 0, <div>er ging iets mis...</div>);
    }
    // shortcut to use
    const tasks = [...data.project.tasks];
    const deleted_tasks = data?.project?.deletedTasks ? [...data.project.deletedTasks] : [];
    const allCuTasks = data?.project?.tasks?.filter((task) => task.users?.find((t) => t.id === localStorage.getItem("user_id"))) || [];
    // Amount of tasks done in project
    const tasksDone = percentage(tasks.filter((task) => task.task_completed === true).length, tasks.length as number);
    // Total tasks in project
    const totalTasks = tasks.length;
    // tasks to show based on selection in dropdown
    const tasksToShow = chosentasks === "Mijn taken" ? [...allCuTasks] : chosentasks === "Verwijderde taken" ? deleted_tasks : tasks;

    return renderpage(
        tasksDone,
        totalTasks,
        <List style={{ padding: 0 }}>
            {tasksToShow
                .sort((a, b) => (moment(a.task_deadline).isBefore(moment(b.task_deadline)) ? -1 : 1))
                .map((task) => {
                    const taskOfCu = Boolean(task.users && task.users.filter((u) => u.id === localStorage.getItem("user_id")).length > 0);
                    if (chosentasks === "Verwijderde taken") {
                        return <RemovedTask key={task.id} task={task} />;
                    }
                    if (isMobile) {
                        return (
                            <MobileTask
                                key={task.id}
                                task={task}
                                hoverBox={hoverBox}
                                setHoverBox={setHoverBox}
                                taskOfCu={taskOfCu}
                                clickedBox={""}
                                onChangeBoolean={onChangeBoolean}
                                onChangesaveTaskDeadline={onChangesaveTaskDeadline}
                                onChangesaveTaskDescription={onChangesaveTaskDescription}
                                onChangePriority={onChangePriority}
                                onDelete={onDelete}
                            />
                        );
                    }
                    return (
                        <ListItem
                            key={task.id}
                            disablePadding
                            dense
                            sx={{
                                paddingRight: "44px",
                            }}
                        >
                            {/*
                             * Icon to show
                             * - Disable state if task is not for current user
                             */}
                            <ListItemIcon>
                                {!taskOfCu ? (
                                    <Tooltip
                                        disableInteractive
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: "offset",
                                                        options: {
                                                            offset: [0, -16],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                        title="Deze taak is niet aan jou toegewezen"
                                        placement="right"
                                    >
                                        <div>
                                            <Disable disabled>
                                                <Checkbox
                                                    edge="start"
                                                    size="small"
                                                    checked={task.task_completed}
                                                    sx={{ marginLeft: "4px" }}
                                                    icon={
                                                        task.task_completed === true ? (
                                                            <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                                                        ) : (
                                                            <RadioButtonUnchecked fontSize="small" sx={{ color: "#cccccc" }} />
                                                        )
                                                    }
                                                    checkedIcon={<TaskAlt sx={{ color: "#2E76FF" }} />}
                                                />
                                            </Disable>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        disableInteractive
                                        title="Taak voltooien"
                                        placement="right"
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: "offset",
                                                        options: {
                                                            offset: [0, -16],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                    >
                                        <Checkbox
                                            onMouseOver={() => setHoverBox(task.id)}
                                            onMouseOut={() => setHoverBox("")}
                                            icon={
                                                clickedBox === task.id ? (
                                                    <TaskAlt fontSize="small" sx={{ color: "#2E76FF" }} />
                                                ) : hoverBox === task.id ? (
                                                    <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                                                ) : (
                                                    <RadioButtonUnchecked fontSize="small" />
                                                )
                                            }
                                            checkedIcon={<TaskAlt sx={{ color: "#2E76FF" }} />}
                                            edge="start"
                                            size="small"
                                            checked={task.task_completed}
                                            disableRipple
                                            sx={{ marginLeft: "4px" }}
                                            // setState to checked value
                                            onChange={(e) => {
                                                if (task.users && task.users.filter((u) => u.id === localStorage.getItem("user_id"))) {
                                                    onChangeBoolean(task.id, e.target.checked);
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </ListItemIcon>

                            {/* Tasks text */}
                            <ListItemText
                                sx={{ textDecoration: task.task_completed ? "line-through" : "none" }}
                                primary={
                                    <EditableTaskField
                                        disabled={task.task_completed}
                                        tasktext={task.task_description}
                                        setTasktext={(txt) => {
                                            if (taskOfCu) {
                                                onChangesaveTaskDescription(task.id, txt);
                                            }
                                        }}
                                        taskID={task.id}
                                        bossOfProject={taskOfCu}
                                    />
                                }
                            />

                            {/* DatePicker */}
                            <Disable disabledOpacity={task.task_completed ? 0.6 : 0.8} disabled={task.task_completed || !taskOfCu}>
                                <TaskDatePicker
                                    date={task.task_deadline !== null ? moment(task.task_deadline) : null}
                                    updateDate={(date) => {
                                        if (taskOfCu) {
                                            onChangesaveTaskDeadline(task.id, date);
                                        }
                                    }}
                                />
                            </Disable>
                            <div style={{ minWidth: "8px" }} />

                            {/* Users linked to task */}
                            <Disable
                                disabledOpacity={project.users.length === 1 ? 1 : 0.7}
                                disabled={task.task_completed || project.users.length === 1}
                            >
                                <UserForTask chosenUserId={task.users as any} task_id={task.id} />
                            </Disable>
                            <div style={{ minWidth: "8px" }} />

                            {/* Prioritychip  */}
                            <Disable disabledOpacity={task.task_completed ? 0.6 : 0.8} disabled={task.task_completed}>
                                <TaskPriorityChip
                                    dot={isMobile ? true : false}
                                    disabled={task.task_completed || !taskOfCu}
                                    priority={task.task_priority}
                                    updatePrio={(prio) => {
                                        if (taskOfCu) {
                                            onChangePriority(task.id, prio as Priority);
                                        }
                                    }}
                                />
                            </Disable>

                            {/* Delete task 
                            // TODO add mutation to delete a task
                            */}
                            <ListItemSecondaryAction
                                sx={{
                                    right: isMobile ? "8px" : "16px",
                                }}
                            >
                                <Tooltip placement="left" title="Verwijder taak" disableInteractive>
                                    <IconButton
                                        sx={{ "&:hover": { color: (theme) => theme.palette.error.main } }}
                                        size="small"
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            onDelete(task.id);
                                        }}
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
        </List>
    );
};

export default Tasks;
