import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
    GetDatesOfTenderOrLots,
    GetDatesOfTenderOrLots_tender_lots,
    GetDatesOfTenderOrLotsVariables,
} from "../../../__generated__/GetDatesOfTenderOrLots";
import { GET_DATES_OF_TENDER_OR_LOTS } from "../../../graphql/queryDefinitions";
interface Props {
    tender_id: string;
    mp?: string;
}
const SplitDetailMetaStartEndDate: React.FC<Props> = ({ tender_id, mp }) => {
    const { t } = useTranslation();
    /**
     * @param content Variable content to show
     * @returns Component
     */
    const renderPage = (content: React.ReactNode) => <div>{content}</div>;
    /**
     * Get dates
     */
    const { data, loading, error } = useQuery<GetDatesOfTenderOrLots, GetDatesOfTenderOrLotsVariables>(GET_DATES_OF_TENDER_OR_LOTS, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });
    /**
     * loader
     */
    if (loading) {
        return renderPage(
            <div>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("PeriodOfTender")}
                </Typography>
            </div>
        );
    }
    /**
     * check if error occured or no data is available
     */
    if (data === undefined || data.tender === null || error) {
        return renderPage(
            <>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("PeriodOfTender")}
                </Typography>
                <Typography>-</Typography>
            </>
        );
    }
    /**
     * Shorter constant to use in component
     */
    const tndr = data.tender;

    /**
     * @param arr array to check for unique values
     * @param props string array that contains fields that have to be unique
     * @returns new array with unique values
     */
    const unique = (arr: any, props = [] as string[]) => [
        ...new Map(arr.map((entry: any) => [props.map((k) => entry[k]).join("|"), entry])).values(),
    ];
    /**
     * If mp is given => check lots if mp is winner of 1 or more lots
     * Take the first start date to fill in 'looptijd start' & 'looptijd einde' if this data is available
     */
    if (mp && tndr.lots.length > 0) {
        const lotsToCheck = data.tender.lots.map((lot) => lot.id);
        const getLotsOfCurrentMP =
            data.tender.bids !== null && data?.tender?.bids !== undefined
                ? data.tender.bids.filter((bid) => lotsToCheck.includes(bid?.lot?.id as string)).filter((i) => i.marketParty.id === mp)
                : [];
        const sortedArray = getLotsOfCurrentMP.sort((a, b) => moment(a.lot?.looptijd_start).valueOf() - moment(b.lot?.looptijd_start).valueOf());

        return renderPage(
            <>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("PeriodOfTender")}
                </Typography>
                {sortedArray[0] !== undefined && (
                    <Typography>
                        {sortedArray[0].lot?.looptijd_start ? moment(sortedArray[0].lot?.looptijd_start).format("L") : ""}
                        {sortedArray[0].lot?.looptijd_start ||
                        (sortedArray[0].lot?.looptijd_start && sortedArray[0].lot?.looptijd_einde) ||
                        sortedArray[0].lot?.looptijd_einde
                            ? " tot "
                            : ""}
                        {sortedArray[0].lot?.looptijd_einde ? moment(sortedArray[0].lot?.looptijd_einde).format("L") : "-"}
                    </Typography>
                )}
            </>
        );
    }
    /**
     * Array with unique values based on "looptijd_start" & "looptijd_einde"
     */
    const newArr = unique(tndr.lots, ["looptijd_start", "looptijd_einde"]) as GetDatesOfTenderOrLots_tender_lots[];
    /**
     * Check if looptijd_start is not null
     */
    const checkStart = newArr.filter((i) => i.looptijd_start !== null).map((j) => j.looptijd_start);

    /**
     * Check if looptijd_einde is not null
     */
    const checkEnd = newArr.filter((i) => i.looptijd_einde !== null).map((j) => j.looptijd_einde);

    /**
     * If tender has lots and start & end date => show lot version
     */
    if (tndr.lots.length > 0 && checkStart.length === 1 && checkEnd.length === 1) {
        return renderPage(
            <>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("PeriodOfTender")}
                </Typography>
                <Typography>
                    {checkStart.length === 1 ? moment(checkStart[0]).format("L") : ""}
                    {checkStart.length === 1 || (checkStart.length === 1 && checkEnd.length === 1) || checkEnd.length === 1 ? " tot " : ""}
                    {checkEnd.length === 1 ? moment(checkEnd[0]).format("L") : "-"}
                </Typography>
            </>
        );
    }
    /**
     * When new array contains multiple unique values => show message
     */
    if ((tndr.lots.length > 0 && checkStart.length > 1) || (tndr.lots.length > 0 && checkEnd.length > 1)) {
        return renderPage(
            <>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("PeriodOfTender")}
                </Typography>
                <Typography>Afwijkende looptijden percelen</Typography>
            </>
        );
    }

    const start = tndr.looptijd_start && tndr.looptijd_start.filter((date) => date?.value !== null).length > 0;
    const end = tndr.looptijd_einde && tndr.looptijd_einde.filter((date) => date?.value !== null).length > 0;
    const nextEnd = tndr.next_looptijd_einde && tndr.next_looptijd_einde.filter((date) => date?.value !== null).length > 0;

    /**
     * Return default version where data of tender object will be used instead of lots object
     */
    return renderPage(
        <>
            <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                {t("PeriodOfTender")}
            </Typography>
            <Typography>
                {start
                    ? tndr.looptijd_start?.map((date) => moment(date?.value).format("L"))
                    : tndr.published_looptijd_start
                    ? moment(tndr.published_looptijd_start).format("L")
                    : ""}
                {start || (start && nextEnd) || end || tndr.published_looptijd_einde ? " tot " : ""}
                {nextEnd
                    ? tndr.next_looptijd_einde?.map((date) => moment(date?.value).format("L"))
                    : end
                    ? tndr.looptijd_einde?.map((date) => moment(date?.value).format("L"))
                    : tndr.published_looptijd_einde
                    ? moment(tndr.published_looptijd_einde).format("L")
                    : "-"}
            </Typography>
        </>
    );
};
export default SplitDetailMetaStartEndDate;
