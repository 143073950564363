import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import anchorNewTab from "../../utils/anchorNewTab";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import WidgetBox from "../WidgetBox";
import WidgetBoxMenu from "../WidgetBoxMenu";
import LegalFrameworkTooltip from "../LegalFrameworkTooltip";
import SectorIconLoader from "../boxes/SectorIconLoader";
import _ from "lodash";
import Summary from "./Summary";
import LotSummary from "./LotSummary";
import {
    GetDefaultSummary_tender_lots,
    GetDefaultSummary_tender_sectors,
    GetDefaultSummary_tender_omvang_opdracht_source,
    GetDefaultSummary_tender_omschrijving_source,
} from "../../__generated__/GetDefaultSummary";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { Launch } from "@mui/icons-material";
import SummaryExpandModal from "../boxes/SummaryExpandModal";
import { useReRunHighlighting } from "../contextProviders/HighlightContext";
import { getRelativeTimeInDays } from "../../utils/relativeTimeInDays";

interface Props {
    id: string;
    description: string;
    qualitativeScope: string;
    subSectors: GetDefaultSummary_tender_sectors[];
    host: string | null;
    hostUrl: string | null;
    deadline: string;
    lastUpdate: string;
    firstDispatch: string;
    awardCrit: string | null;
    procedureType: string | null;
    legalFramework: string | null;
    legalBase: string | null;
    type: string | null;
    omvang_opdracht_source: GetDefaultSummary_tender_omvang_opdracht_source | null;
    omschrijving_source: GetDefaultSummary_tender_omschrijving_source | null;
    lots: GetDefaultSummary_tender_lots[];
    tender_country: string | null;
    elevation?: number;
}

const SummaryWidget: React.FC<Props> = ({
    id,
    description,
    qualitativeScope,
    subSectors,
    host,
    hostUrl,
    deadline,
    lastUpdate,
    firstDispatch,
    awardCrit,
    procedureType,
    legalFramework,
    legalBase,
    type,
    omvang_opdracht_source,
    omschrijving_source,
    tender_country,
    lots,
    elevation,
}) => {
    const { t } = useTranslation();

    //submenu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showSource, setShowSource] = useState(false);
    const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));
    const { trackEvent } = useMatomo();
    const [openModal, setOpenModal] = useState(false);
    const { rerunHighlight, setRerunHighlight } = useReRunHighlighting();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // close submenu, set time out, change showsource to opposite
    // with sleep 100 user doesnt notice that word changes before closing submenu.
    const handleSource = async () => {
        setAnchorEl(null);
        await sleep(100);
        setShowSource((s) => !s);
    };

    // const source = omschrijving_source !== null && omschrijving_source !== undefined ? gqlParser.parseSource(omschrijving_source) : null;

    const uniqueParentIds = _.uniqBy(subSectors, function (e) {
        return e?.parent?.id;
    });

    // Track event
    const trackSummarySource = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const checkNumberOfLot = lots.length === 1 && lots.some((item) => item.number === "0");

    return (
        <React.Fragment>
            <WidgetBox
                elevation={elevation}
                padding={0}
                header={t("summary")}
                highlight="blue"
                actions={
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setOpenModal(!openModal);
                                setRerunHighlight(!rerunHighlight);
                            }}
                        >
                            <Launch fontSize={"small"} />
                        </IconButton>
                        <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                            <MenuItem
                                onClick={() => {
                                    handleSource();
                                    trackSummarySource({ category: "Tender", action: "Click-source-summary" });
                                }}
                            >
                                {showSource ? t("HideSource") : t("ShowSource")}
                            </MenuItem>
                        </WidgetBoxMenu>
                    </div>
                }
            >
                {/*
                 * Show component based on lots length
                 */}
                <Grid container spacing={0}>
                    <Grid item md={8} sm={12} style={{ overflow: "auto", maxHeight: 400 }}>
                        {/*
                         * Check if lots exist on tender, if they do -> show lotSummary
                         * LotSummary has tabs with general description as first (and active) tab */}
                        {lots.length === 0 || checkNumberOfLot ? (
                            <Summary
                                tender_id={id}
                                padding={20}
                                description={description}
                                qualitativeScope={qualitativeScope}
                                omvang_opdracht_source={omvang_opdracht_source}
                                omschrijving_source={omschrijving_source}
                                showSource={showSource}
                            />
                        ) : (
                            <LotSummary
                                tender_id={id}
                                lots={lots}
                                showSource={showSource}
                                description={description}
                                qualitativeScope={qualitativeScope}
                                omvang_opdracht_source={omvang_opdracht_source}
                                omschrijving_source={omschrijving_source}
                            />
                        )}
                    </Grid>
                    {/* Right column with meta data */}
                    <Grid
                        item
                        md={4}
                        sm={12}
                        style={{ paddingLeft: 24, paddingTop: 20, paddingRight: 20, paddingBottom: 20, maxHeight: 400, overflow: "auto" }}
                    >
                        {/*
                         * Deadline with relative time added,
                         * First publication,
                         * last update
                         * procedure  GQL ELEMENTS MISSING
                         * Source with link if available
                         * awardcriteria
                         * sectors mapped
                         */}
                        <div style={{ marginBottom: 8 }}>
                            <div>
                                <table>
                                    <tbody>
                                        {/* deadline */}
                                        {deadline !== null && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    {t("tenderPage.Deadline")}:
                                                </td>
                                                <td>
                                                    {getRelativeTimeInDays(deadline, t).show ? (
                                                        <>
                                                            {moment(deadline).format("LLL")}, ({getRelativeTimeInDays(deadline, t).date})
                                                        </>
                                                    ) : (
                                                        <>{moment(deadline).format("LLL")}</>
                                                    )}
                                                </td>
                                            </tr>
                                        )}

                                        {/* First dispatch */}
                                        {firstDispatch && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    {t("tenderPage.FirstPublication")}:
                                                </td>
                                                <td>
                                                    {moment(firstDispatch).format("LL")},{" "}
                                                    {host && (
                                                        <>
                                                            {Boolean(hostUrl) ? (
                                                                <a {...anchorNewTab} href={hostUrl || ""}>
                                                                    {host}
                                                                </a>
                                                            ) : (
                                                                <>{host}</>
                                                            )}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        )}

                                        {/* Last update */}
                                        {lastUpdate && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    {t("tenderPage.LastUpdate")}:
                                                </td>
                                                <td>{moment(lastUpdate).format("LL")}</td>
                                            </tr>
                                        )}

                                        {/* Procedure */}
                                        {procedureType !== null && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    {t("procedure")}:
                                                </td>
                                                <td>
                                                    {procedureType}
                                                    {type !== "" && `, ${type}`}
                                                    {legalFramework ? (
                                                        <>
                                                            <LegalFrameworkTooltip framework={legalFramework} />
                                                        </>
                                                    ) : legalBase !== null ? (
                                                        <>
                                                            <Tooltip
                                                                title={
                                                                    <Typography variant="caption">
                                                                        {t(`legal_basis_tooltip.${legalBase}`).toString()}
                                                                    </Typography>
                                                                }
                                                            >
                                                                <Typography style={{ marginLeft: 4 }}>
                                                                    ({legalBase !== null ? t(`legal_basis.${legalBase}`) : null})
                                                                </Typography>
                                                            </Tooltip>
                                                        </>
                                                    ) : null}
                                                    <br />
                                                </td>
                                            </tr>
                                        )}

                                        {/* Lots */}
                                        {lots.length > 0 && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("Lots")}:</td>
                                                <td>
                                                    <Tooltip
                                                        title={lots.map((i) => {
                                                            return (
                                                                <div key={i.id} style={{ marginBottom: 4 }}>
                                                                    <Typography style={{ lineHeight: 1.3 }} variant="caption">
                                                                        {`${t("tenderPage.Lot")} ${i.number} - ${i.title}`}
                                                                    </Typography>
                                                                </div>
                                                            );
                                                        })}
                                                    >
                                                        <Typography style={{ display: "inline", cursor: "pointer" }}>{lots.length}</Typography>
                                                    </Tooltip>

                                                    <br />
                                                </td>
                                            </tr>
                                        )}

                                        {/* Award criteria in Typography component with pre-wrap */}
                                        {awardCrit && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    {t("awardCriteria")}:
                                                </td>
                                                <td>
                                                    <Typography style={{ whiteSpace: "pre-wrap" }}>
                                                        {awardCrit?.replace(/<br\s*[\\/]?>/gi, "\n")}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        )}

                                        {/* Map sectors, could be multiple items, dutch tenders */}
                                        {subSectors && tender_country === "Netherlands" && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    {subSectors.length > 1 ? t("subsectors") : t("subsector")}:
                                                </td>
                                                <td>
                                                    <>
                                                        {subSectors.map((s) => (
                                                            <div key={s.id} style={{ display: "flex", alignItems: "center", marginLeft: -3 }}>
                                                                <SectorIconLoader sector_id={s.id} />
                                                                <Typography style={{ marginLeft: 8 }}>{s.name}</Typography>
                                                            </div>
                                                        ))}
                                                    </>
                                                </td>
                                            </tr>
                                        )}
                                        {/* Map sectors, could be multiple items, other countries */}
                                        {uniqueParentIds && tender_country !== "Netherlands" && (
                                            <tr>
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    {uniqueParentIds.length > 1 ? t("HeadSectors") : t("HeadSector")}:
                                                </td>
                                                <td>
                                                    <>
                                                        {uniqueParentIds.map((s) => (
                                                            <div key={s.id} style={{ display: "flex", alignItems: "center" }}>
                                                                <Typography>{s.parent?.name}</Typography>
                                                            </div>
                                                        ))}
                                                    </>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </WidgetBox>
            <SummaryExpandModal
                handleClose={() => {
                    setOpenModal(!openModal);
                }}
                deadline={deadline}
                host={host}
                firstDispatch={firstDispatch}
                lastUpdate={lastUpdate}
                procedureType={procedureType}
                awardCrit={awardCrit}
                subSectors={subSectors}
                tender_country={tender_country}
                uniqueParentIds={uniqueParentIds}
                tender_id={id}
                lots={lots}
                description={description}
                qualitativeScope={qualitativeScope}
                omvang_opdracht_source={omvang_opdracht_source}
                omschrijving_source={omschrijving_source}
                open={openModal}
                hostUrl={hostUrl}
                legalFramework={legalFramework}
                legalBase={legalBase}
                type={type}
            />
        </React.Fragment>
    );
};

export default SummaryWidget;
