import React from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { useQuery } from "@apollo/client";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton, Typography } from "@mui/material";
import { Business } from "@mui/icons-material";
import { GetTenderNotificationData, GetTenderNotificationDataVariables } from "../../__generated__/GetTenderNotificationData";
import { CA_DATA_NOTIFICATION, TENDER_DATA_NOTIFICATION } from "../../graphql/queryDefNotifications";
import { GetCANotificationData, GetCANotificationDataVariables } from "../../__generated__/GetCANotificationData";
import FaviconBox from "../FaviconBox";
import TooltipOnNewLines from "../TooltipOnNewLines";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(e: any): void;
    keyIndex: number;
}

/**
 * Display a single notification row for the notification Dialog
 */
const NewTenderForContractingAuthorityNotification: React.FC<Props> = ({ notification, onClick, keyIndex }) => {
    const yellow = "#F57117";
    const blue = "#173357";

    // notification.data[0] -> tender id
    // notification.data[1] -> organization id

    const { loading, data: tender } = useQuery<GetTenderNotificationData, GetTenderNotificationDataVariables>(TENDER_DATA_NOTIFICATION, {
        skip: notification === null || notification.data === null,
        variables: {
            tender_id: notification !== null && notification.data !== null ? (notification.data[0] as string) : "",
        },
    });

    const { loading: loadCA, data: ca } = useQuery<GetCANotificationData, GetCANotificationDataVariables>(CA_DATA_NOTIFICATION, {
        skip: notification === null || notification.data === null,
        variables: {
            ca_id: notification !== null && notification.data !== null ? (notification.data[1] as string) : "",
        },
    });

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (loading || loadCA) {
        return (
            <MenuItem key={keyIndex} style={{ whiteSpace: "normal", maxHeight: 250, opacity: notification.read_at !== null ? 0.5 : undefined }}>
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    return (
        <MenuItem
            key={keyIndex}
            onClick={() => onClick(ca?.contracting_authority?.id as string)}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: yellow }}>
                    <Business />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <TooltipOnNewLines
                        placement={"bottom-start"}
                        text={`Nieuw: ${tender?.tender?.namegiventocontract}` || ""}
                        variant={"body1"}
                        lines={2}
                        id={`T${notification.id as string}`}
                        fontweight={notification.read_at === null ? 500 : "normal"}
                        fontSize={14}
                    />
                }
                secondary={
                    // Show message
                    ca?.contracting_authority?.name && (
                        <Typography
                            style={{
                                fontSize: 12,
                                // When the nofitication is unread, the date-part of the notification is bold and accent colored
                                color: notification?.read_at ? blue : undefined,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <FaviconBox favicon={ca?.contracting_authority?.favicon} name={ca?.contracting_authority?.name} color={yellow} />{" "}
                            {/*
                             * Show ca name with tooltip if its truncated
                             */}
                            <TooltipOnNewLines
                                placement={"top-start"}
                                text={ca?.contracting_authority?.name || ""}
                                variant={"body1"}
                                lines={1}
                                id={`T${notification.id as string}${ca.contracting_authority.id}`}
                                fontweight={"normal"}
                                fontSize={12}
                            />
                        </Typography>
                    )
                }
            />
        </MenuItem>
    );
};

export default NewTenderForContractingAuthorityNotification;
