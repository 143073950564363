import React from "react";
import { useColumnSetter } from "../../../components/contextProviders/ColumnContext";
import { Disable } from "react-disable";
import ColumnChanger from "../../../components/tableComponents/ColumnChanger";
import { allOppColumns } from "../allOpportunities/AllOppColumns";
import { intOppColumns } from "../interestingpage/OppInterestingcolumns";
import { workOppColumns } from "../worklistpage/Oppcolumns";
import RowOpportunityExportButton from "../../../components/tableComponents/RowOpportunityExportButton";
import { GridInput, OpportunityFilterInput, SortOrder } from "../../../__generated__/globalTypes";
import { IconButton } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

interface Props {
    worklist: boolean;
    reviewPage: boolean;
    allpage: boolean;
    loading: boolean;
    total?: number;
    searchInput: OpportunityFilterInput;
    order: SortOrder;
    orderField: string;
    query: string;
    columns: any[];
}
// const useStyles = makeStyles({
//     startIcon: {
//         marginRight: 4,
//     },
// });

const OpportunityTableActions: React.FC<Props> = ({
    worklist,
    reviewPage,
    allpage,
    loading,
    total,
    searchInput,
    order,
    orderField,
    query,
    columns,
}) => {
    const { selectedRows } = useColumnSetter();

    return (
        //! if loading is true => Disable will be activated. => not possible to click on any button until loading === false
        <Disable disabled={loading} disabledOpacity={1}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {/* buttons show up on worklist page */}
                {worklist && selectedRows !== undefined && selectedRows.length > 0 && (
                    <React.Fragment>
                        {/* Remove from worklist */}
                        {/* <Button
                            classes={{ startIcon: classes.startIcon }}
                            startIcon={<CheckBoxIcon style={{ color: "#0000008a" }} />}
                            style={{ padding: "4px 8px", fontSize: "0.775rem" }}
                        >
                            Verwijder uit werklijst
                        </Button> */}
                    </React.Fragment>
                )}

                {/* Buttons to show on review page */}
                {reviewPage && (
                    <React.Fragment>
                        {loading ? (
                            <Disable disabled>
                                <IconButton disabled={true} aria-label="event" size="small" style={{ margin: 1 }}>
                                    <CloudDownloadIcon />
                                </IconButton>
                            </Disable>
                        ) : (
                            <RowOpportunityExportButton
                                columnExport={GridInput.OPPORTUNITY_REVIEWS}
                                total={total || 0}
                                order={order}
                                orderField={orderField}
                                searchInput={searchInput}
                                query={query}
                                columns={columns}
                            />
                        )}
                        {/*
                         * If selected rows > 0 => show archive button and delete button
                         */}
                        {selectedRows !== undefined && selectedRows.length > 0 && (
                            <React.Fragment>
                                {/*
                                 * Archive button
                                 */}
                                {/* <RowArchiveButton /> */}

                                {/*
                                 * Remove button
                                 */}
                                {/* <RemoveRowButton /> */}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {/* Button shows up on every page, user can click this button to show/hide columns */}
                {/* {reviewPage && <RowExportButton columnExport={GridInput.OPPORTUNITY_REVIEWS} />}
                {worklist && <RowExportButton columnExport={GridInput.OPPORTUNITY_WORKLIST} />} */}
                {/* Component to hide/show columns */}
                {allpage && <ColumnChanger columns={allOppColumns} location={"/opportunities"} />}
                {reviewPage && <ColumnChanger columns={intOppColumns} location={"/opportunities/reviews"} />}
                {worklist && <ColumnChanger columns={workOppColumns} location={"/opportunities/worklist"} />}
            </div>
        </Disable>
    );
};

export default OpportunityTableActions;
