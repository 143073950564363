import React from "react";
import { Button, Typography, Grid } from "@mui/material";
import { Get_available_demo_timeslots_meetingTimeslots } from "../../__generated__/Get_available_demo_timeslots";
import moment from "moment";
interface Props {
    chosenTimeSlot: string;
    setChosenTimeSlot: React.Dispatch<React.SetStateAction<string>>;
    availableSlots: (Get_available_demo_timeslots_meetingTimeslots | null)[];
}

const TimeSlots: React.FC<Props> = ({ chosenTimeSlot, setChosenTimeSlot, availableSlots }) => {
    const blue = "#173357";
    const white = "#ffffff";
    // this gives an object with dates as keys
    const groups = availableSlots.reduce((groups: any, time) => {
        const date = moment(time?.start).format("YYYY-MM-DD");
        if (!groups[date] as any) {
            groups[date] = [];
        }
        groups[date].push(time);
        return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
        return {
            date,
            times: groups[date],
        };
    });

    return (
        <React.Fragment>
            <div>
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: 500,
                        marginBottom: "16px",
                    }}
                >
                    Selecteer het gewenste tijdstip
                </Typography>
                {groupArrays.map((group) => {
                    const day = moment(group.date).format("dddd D MMMM");
                    const dayCapitalized = day.charAt(0).toUpperCase() + day.slice(1);
                    return (
                        <React.Fragment key={group.date}>
                            <Typography sx={{ fontWeight: 600, marginBottom: "8px" }}>{dayCapitalized}</Typography>

                            <Grid xs={10} container style={{ marginBottom: 10 }}>
                                {group.times.map((time: any) => {
                                    return (
                                        // <Grid item key={time.hash} md={3}>
                                        <Button
                                            key={time.hash}
                                            sx={{
                                                marginRight: "12px",
                                                minWidth: "130px",
                                                backgroundColor: time.hash === chosenTimeSlot ? `${blue}F2` : white,
                                                color: time.hash === chosenTimeSlot ? white : `${blue}F2`,
                                                ":hover": {
                                                    color: time.hash === chosenTimeSlot ? white : white,
                                                    backgroundColor: time.hash === chosenTimeSlot ? `${blue}F2` : `${blue}c2`,
                                                },
                                            }}
                                            variant={"outlined"}
                                            onClick={() => {
                                                if (chosenTimeSlot !== time.hash) {
                                                    setChosenTimeSlot(time.hash);
                                                } else {
                                                    setChosenTimeSlot("");
                                                }
                                            }}
                                        >
                                            {`${moment(time.start).format("LT")} - ${moment(time.end).format("LT")}`}
                                        </Button>
                                        // </Grid>
                                    );
                                })}
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default TimeSlots;
