import { Divider, IconButton, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Typography } from "@mui/material";
import React from "react";
import { SavedTenderEvents_getCalendarEvents_TenderDate } from "../../../__generated__/SavedTenderEvents";
import moment from "moment";
import StatusDot from "../../../components/StatusDot";
import { Today } from "@mui/icons-material";
import TenderDateTooltip from "../../calendar/TenderDateTooltip";
import { Link } from "react-router-dom";
import MatomoEvent from "../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    data: SavedTenderEvents_getCalendarEvents_TenderDate;
    openId: string;
    setOpenId: (str: string) => void;
    setActiveTooltip: (bln: any) => void;
    activeTooltip: string | false;
}

const MyTenderDate: React.FC<Props> = ({ data, openId, activeTooltip, setOpenId, setActiveTooltip }) => {
    const { trackEvent } = useMatomo();

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <>
            <div style={{ zIndex: 95 }}>
                <TenderDateTooltip
                    placement="bottom"
                    openId={openId}
                    setOpenId={(str) => {
                        setOpenId(str);
                        track({ category: "Dashboard", action: "Click-open-tender-date-on-dashboard", href: window.location.href });
                    }}
                    setActiveTooltip={setActiveTooltip}
                    setId={() => console.log("")}
                    content={
                        <ListItemButton
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                padding: "8px",
                                paddingRight: "80px",
                                backgroundColor: "#ffffff",
                                "&:hover": {
                                    backgroundColor: "#465c8433",
                                },
                            }}
                        >
                            <Stack alignItems={"center"} justifyContent={"center"} padding={"4px"} minWidth={"50px"} maxWidth={"50px"}>
                                <Typography align="center">{moment(data.planned).format("MMM").toUpperCase().replace(".", "")}</Typography>
                                <Typography align="center" variant="h2" fontWeight={600} lineHeight={1}>
                                    {moment(data.planned).format("D")}
                                </Typography>
                            </Stack>

                            <ListItemText
                                sx={{
                                    marginLeft: "16px",
                                    marginTop: "3px",
                                    marginRight: "60px",

                                    marginBottom: "3px",
                                    "& .MuiListItemText-root": {
                                        marginRight: "60px",
                                    },
                                }}
                                primary={
                                    <div>
                                        <Typography variant="h6" noWrap marginBottom={"5px"}>
                                            {data.timeSignificant && <span style={{ fontWeight: 700 }}>{moment(data.planned).format("LT")}</span>}{" "}
                                            {data.name.replace(/ percelen: 0/g, "")}
                                        </Typography>
                                    </div>
                                }
                                secondary={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div>
                                            <StatusDot type={data.tender?.status as string} placement={"right"} size={12} />
                                        </div>
                                        <Typography
                                            noWrap
                                            // onClick={() => {
                                            //     if (isMobile) {
                                            //         navigate(`/projects/${row.project?.id as string}`);
                                            //     } else {
                                            //         changeProject({
                                            //             projectID: row.project?.id,
                                            //             projectTitle: row.project?.title,
                                            //             state: row.project?.state?.id as string,
                                            //         });
                                            //         setOpen(true);
                                            //     }
                                            // }}
                                            sx={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                                display: "inline-block",
                                                maxWidth: "100%", // Adjust the maximum width based on your parent container width
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                color: "#1f4769",
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                        >
                                            <Link to={`/tenders/${data.tender?.id}`}>{data.tender?.namegiventocontract}</Link>
                                        </Typography>
                                    </div>
                                }
                            />
                            <ListItemSecondaryAction sx={{ display: "flex", alignItems: "center" }}>
                                <IconButton>
                                    <Today />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    }
                    data={data}
                />
            </div>
            <Divider />
        </>
    );
};

export default MyTenderDate;
