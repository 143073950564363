import { gql } from "@apollo/client";

//? fetch organization to edit
export const QUERY_ORGANIZATIONS = gql`
    query GetOrganizationsAdminOverview($page: Int, $count: Int, $orderField: String!, $order: SortOrder!) {
        organizations(page: $page, first: $count, orderBy: [{ column: $orderField, order: $order }]) {
            paginatorInfo {
                count
                currentPage
                hasMorePages
                firstItem
                lastItem
                lastPage
                perPage
                total
            }
            data {
                id
                name
                kvknumber
                created_at
                updated_at
            }
        }
    }
`;

//? get some data of tenderer (organization) to show/hide tabs
export const QUERY_ORGANIZATIONNAME = gql`
    query GetOrganizationNameOnly($id: ID!) {
        tenderer(id: $id) {
            id
            favicon
            favicon_resized
            name
            status
            settlings(first: 10, page: 1) {
                data {
                    id
                    type
                }
            }
            bids {
                id
            }
        }
    }
`;

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            id
            name
            kvknumber
            favicon
            favicon_resized
            created_at
            updated_at
            status
            website
            has_urls
            urls {
                id
            }
            logo
            phonenumbers {
                id
            }
            emails {
                id
            }
            postal_addresses {
                id
            }
            #   websites_publications
            country {
                alpha2
                name
                numeric
            }
            settlings {
                actief
                id
                name
                visit_address {
                    id
                    city
                }
                settlingnumber
                type
            }
        }
    }
`;

//? query all tenderes (organizations) with pagiation
export const QUERY_ALL_ORGANIZATIONS = gql`
    query FilterOrganizationOverview($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderersSearchInput) {
        filterTenderers(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    name
                    kvknumber
                    logo
                    favicon
                    favicon_resized
                    last_update_date
                    country {
                        alpha2
                        name
                        numeric
                    }

                    #settlings
                    settlings(first: 10, page: 1) {
                        data {
                            id
                            visit_address {
                                id
                                city
                            }
                            type
                        }
                    }

                    #postal address
                    postal_addresses {
                        postal_code
                        street
                        city
                        housenumber
                        housenumber_suffix
                    }

                    # bids
                    bids {
                        id
                        updated_at
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get detailed information of clicked organization
export const QUERY_INFO_ORGANIZATION = gql`
    query GetOrganizationDetail($id: ID!) {
        tenderer(id: $id) {
            id
            name
            kvknumber
            country {
                alpha2
                name
                numeric
            }
            logo
            favicon
            favicon_resized
            # emailadresses
            emails {
                id
                email
                type
            }

            # web urls
            urls {
                id
                url
                type
            }

            # postal addresses
            postal_addresses {
                id
                street
                housenumber
                housenumber_suffix
                country
                city
                postal_code
            }
            settlings(first: 500, page: 1) {
                data {
                    id
                    name
                    faxnumber
                    type
                    actief
                    phonenumber
                    visit_address {
                        id
                        city
                        street
                        country
                        housenumber
                        postal_code
                        housenumber_suffix
                    }
                }
            }

            # phonenumbers
            phonenumbers {
                id
                phonenumber
                type
            }
        }
    }
`;

//? get detailed information of clicked organization
export const QUERY_TOP_FIVE_MOST_WON_FROM_MP = gql`
    query GetTopFiveMostWonFrom($id: ID!) {
        tenderer(id: $id) {
            id

            # most active for
            most_active_for {
                aantal
                client {
                    id
                    name
                    favicon
                    favicon_resized
                }
            }
        }
    }
`;

//? get detailed information of clicked organization
export const QUERY_ORGANIZATION_NAMES_AND_INACTIVE_NAMES = gql`
    query GetOrganizationAllNames($id: ID!) {
        tenderer(id: $id) {
            id

            # tradenames
            names {
                id
                type
                value
            }

            # inactive tradenames
            inactive_names {
                id
                type
                value
            }
        }
    }
`;

//? get detailed information of clicked organization
export const QUERY_TOP_FIVE_MOST_ACTIVE_IN_MP = gql`
    query GetTopFiveMostActiveIn($id: ID!) {
        tenderer(id: $id) {
            id
            # most active in
            most_active_in {
                sector {
                    id
                    name
                }
                aantal
            }

            # most active for
            # most_active_for {
            #     aantal
            #     client {
            #         id
            #         name
            #        favicon
            #     }
            #  }
        }
    }
`;

//? get settlingdata of tenderer
export const QUERY_SETTLINGS = gql`
    query GetSettlingsDetails($id: ID!, $settlingAmount: Int!, $SettlingPage: Int) {
        tenderer(id: $id) {
            id
            name
            amountBids
            bids {
                winner
                updated_at
            }
            settlings(first: $settlingAmount, page: $SettlingPage) {
                data {
                    actief
                    bids {
                        id
                    }
                    id
                    name
                    type
                    visit_address {
                        id
                        city
                        street
                        country
                        housenumber
                        postal_code
                        housenumber_suffix
                    }
                    status
                    wonBids {
                        id
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get all starred tenderers, with pagination
export const QUERY_MY_TENDERERS = gql`
    query FilterMyTenderers($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderersSearchInput) {
        filterTenderers(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    name
                    kvknumber
                    logo
                    favicon
                    favicon_resized
                    last_update_date
                    country {
                        alpha2
                        name
                        numeric
                    }

                    #settlings
                    settlings(first: 10, page: 1) {
                        data {
                            id
                            visit_address {
                                id
                                city
                            }
                            type
                        }
                    }

                    #postal address
                    postal_addresses {
                        postal_code
                        street
                        city
                        housenumber
                        housenumber_suffix
                    }

                    # bids
                    bids {
                        id
                        updated_at
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get five last activities of tenderers to show on dashboard
export const QUERY_FIVE_LATEST_MARKET_PARTY_ACTIVITIES = gql`
    #set to active when generate types, work around for now
    query GetFiveLatestMPActivities($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderersSearchInput) {
        filterTenderers(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                data {
                    id
                    name
                    kvknumber
                    logo
                    favicon
                    favicon_resized
                    country {
                        alpha2
                        name
                        numeric
                    }
                    last_update_text
                    last_update_date
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

// ? Get top5 tenderers of current user
export const QUERY_TOP_FIVE_MP_SUGGESTIONS = gql`
    query top5MP {
        getTopMarketParties {
            id
            name
            favicon
            favicon_resized
        }
    }
`;
