import React, { useState } from "react";
import { Link, Typography } from "@mui/material";
import { OpenProps } from "./tenderDetail/Summary";
import moment from "moment";
import DocumentDrawer from "../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import anchorNewTab from "../utils/anchorNewTab";

interface Props {
    sourceType: string;
    sourceObject: any;
    tender_id: string;
    fontStyle?: string;
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline";
    host?: string | null;
    hostUrl?: string | null;
}

const ShowTenderSourceText: React.FC<Props> = ({ sourceType, sourceObject, tender_id, fontStyle = "italic", variant = "caption", host, hostUrl }) => {
    const [open, setOpen] = useState<OpenProps>({ open: false, link: "", label: "", doc_id: "" });
    const blue = "#173357";
    const closeDrawer = () => {
        setOpen({ open: false, link: "", label: "", doc_id: "" });
    };

    const getSource = () => {
        switch (sourceType) {
            case "Document":
                return (
                    <>
                        {sourceObject.extension === "pdf" ? (
                            <Link
                                underline="none"
                                component="button"
                                style={{ fontStyle: fontStyle }}
                                variant={variant}
                                onClick={() => {
                                    setOpen({
                                        link: sourceObject.temporary_link,
                                        open: true,
                                        label: sourceObject.filename,
                                        doc_id: sourceObject.id,
                                    });
                                }}
                            >
                                {sourceObject.filename}
                            </Link>
                        ) : (
                            <Link
                                underline="none"
                                component="button"
                                style={{ fontStyle: fontStyle }}
                                variant={variant}
                                onClick={(e) => {
                                    // Stop click on listitem to prevent document-viewer to initiate
                                    e.stopPropagation();
                                    const element = document.createElement("a");

                                    element.setAttribute(
                                        "href",
                                        `${sourceObject.temporary_link}&response-content-disposition=attachment; filename=${sourceObject.filename}`
                                    );
                                    element.style.display = "none";
                                    document.body.appendChild(element);
                                    element.click();
                                    document.body.removeChild(element);
                                }}
                            >
                                {sourceObject.filename}
                            </Link>
                        )}
                    </>
                );
            case "Publication":
                return (
                    <Typography style={{ fontStyle: fontStyle }} variant={variant}>
                        {moment(sourceObject?.dispatchdate).format("LL")} - {sourceObject?.host}, {sourceObject?.publication_type.name}
                    </Typography>
                );
            case "ExternalDate":
                return (
                    <>
                        {Boolean(host) && (
                            <>
                                {Boolean(hostUrl) ? (
                                    <Link
                                        underline="none"
                                        style={{ fontStyle: fontStyle }}
                                        variant={variant}
                                        component="button"
                                        {...anchorNewTab}
                                        onClick={(e) => {
                                            // Stop click on listitem to prevent document-viewer to initiate
                                            e.stopPropagation();
                                            const element = document.createElement("a");

                                            element.setAttribute("href", `${hostUrl}`);
                                            element.setAttribute("target", "_blank");
                                            element.style.display = "none";
                                            document.body.appendChild(element);
                                            element.click();
                                            document.body.removeChild(element);
                                        }}
                                    >
                                        {host}
                                    </Link>
                                ) : (
                                    <>{host}</>
                                )}
                            </>
                        )}
                    </>
                );
            default:
                return <React.Fragment />;
        }
    };

    return (
        <React.Fragment>
            {getSource()}
            {/* Drawer that contains clicked pdf file */}
            {typeof open.link === "string" && open.open === true && typeof open.label === "string" && (
                <DocumentDrawer
                    open={open.open}
                    onClose={closeDrawer}
                    title={open.label}
                    color={blue}
                    url={open.link}
                    doc_id={open.doc_id}
                    module_item_id={tender_id}
                />
            )}
        </React.Fragment>
    );
};

export default ShowTenderSourceText;
