import React from "react";
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, List, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { withStyles } from "tss-react/mui";

const Accordion = withStyles(MuiAccordion, {
    root: {
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },

    expanded: {
        margin: "0 !important",
    },
});

interface DocumentGroupProps {
    label: any;
    amountDocuments: number | undefined;
    defaultExpanded?: boolean;
    tender_id: string;
}
const DocumentGroup: React.FC<DocumentGroupProps> = ({ label, amountDocuments, children, defaultExpanded = false, tender_id }) => {
    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            TransitionProps={{
                timeout: 100,
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    {/* Download button with data as label */}
                    <Typography
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            paddingLeft: "4px",
                            marginLeft: "-4px",
                            fontSize: "0.785rem",
                            marginBottom: "-1px",
                        }}
                    >
                        {label}
                    </Typography>

                    {/* amount of files  */}
                    <Typography color="textSecondary">
                        {amountDocuments} {amountDocuments === 1 ? "document" : "documenten"}
                    </Typography>
                </div>
            </AccordionSummary>
            {/* Files/folders inside date */}
            <AccordionDetails style={{ paddingTop: 0, paddingBottom: 0 }}>
                <List dense style={{ width: "100%", padding: 0, marginBottom: 10 }}>
                    {children}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

export default DocumentGroup;
