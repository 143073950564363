import React, { useEffect, useState } from "react";
import { Popover, MenuList, MenuItem, ListItemIcon, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useTranslation } from "react-i18next";
import { gql, useQuery } from "@apollo/client";
import { GetSubscriptionAdminState } from "../../../__generated__/GetSubscriptionAdminState";
import { People } from "@mui/icons-material";

// import { Share } from "@mui/icons-material";

interface Props {
    openMenu: (item: string) => void;
    menuText: string;
}

const SearchRuleMenu: React.FC<Props> = ({ openMenu, menuText }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [btnText, setBtnText] = useState<string>("mySearchRules");
    const { t } = useTranslation();

    /**
     * Define the GQL query
     */
    const { data } = useQuery<GetSubscriptionAdminState>(QUERY_ADMIN_ROLE, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        setBtnText(menuText);
    }, [menuText]);

    /**
     *
     * @param event Open popover underneath button
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    /**
     * close popover
     */
    const handleClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    return (
        <React.Fragment>
            <Typography
                sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    paddingLeft: "4px",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    margin: "16px 0px",
                    "&:hover": {
                        backgroundColor: "#ececec",
                        borderRadius: "4px",
                    },
                }}
                onClick={(e) => {
                    handleClick(e);
                }}
            >
                {t(`${btnText}`)} <ArrowDropDownIcon />
            </Typography>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                    handleClose(e);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuList autoFocusItem={open} style={{ padding: 0 }}>
                    <MenuItem
                        selected={btnText === "mySearchRules"}
                        onClick={(e) => {
                            openMenu("my");
                            setBtnText("mySearchRules");
                            handleClose(e);
                        }}
                    >
                        <ListItemIcon style={{ minWidth: 24 }} />
                        <Typography style={{ padding: "4px 8px" }}>{t("mySearchRules")}</Typography>
                    </MenuItem>

                    <MenuItem
                        selected={btnText === "TenderSearchRules"}
                        onClick={(e) => {
                            openMenu("tenders");
                            setBtnText("TenderSearchRules");
                            handleClose(e);
                        }}
                    >
                        <ListItemIcon style={{ minWidth: 24 }}>
                            <LocalOfferIcon fontSize="small" style={{ color: "#173357" }} />
                        </ListItemIcon>
                        <Typography style={{ padding: "4px 8px" }}>{t("tenders")}</Typography>
                    </MenuItem>

                    <MenuItem
                        selected={btnText === "OpportunitySearchRules"}
                        onClick={(e) => {
                            openMenu("opportunities");
                            setBtnText("OpportunitySearchRules");
                            handleClose(e);
                        }}
                    >
                        <ListItemIcon style={{ minWidth: 24 }}>
                            <FindInPageIcon fontSize="small" style={{ color: "#225E4D" }} />
                        </ListItemIcon>
                        <Typography style={{ padding: "4px 8px" }}>{t("opportunities")}</Typography>
                    </MenuItem>

                    {Boolean(data?.currentUser.subscription_admin) && (
                        <MenuItem
                            selected={btnText === "AllSearchRules"}
                            onClick={(e) => {
                                openMenu("All");
                                setBtnText("AllSearchRules");
                                handleClose(e);
                            }}
                        >
                            <ListItemIcon style={{ minWidth: 24 }}>
                                <People fontSize="small" style={{ color: "#b8b8b8" }} />
                            </ListItemIcon>
                            <Typography style={{ padding: "4px 8px" }}>{t("AllSearchRules")}</Typography>
                        </MenuItem>
                    )}
                    {/* <MenuItem
                        selected={btnText === "SharedSearchRules"}
                        onClick={(e) => {
                            openMenu("SharedSearchRules");
                            setBtnText("SharedSearchRules");
                            handleClose(e);
                        }}
                    >
                        <ListItemIcon style={{ minWidth: 24 }}>
                            <Share fontSize="small" style={{ color: grey }} />
                        </ListItemIcon>
                        <Typography style={{ padding: "4px 8px" }}>{t("SharedSearchRules")}</Typography>
                    </MenuItem> */}
                </MenuList>
            </Popover>
        </React.Fragment>
    );
};

export default SearchRuleMenu;

//? get all tenders in platform, sorted column: updated_at
export const QUERY_ADMIN_ROLE = gql`
    query GetSubscriptionAdminState {
        currentUser {
            id
            subscription_admin
        }
    }
`;
