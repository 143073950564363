import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
    GetSearchParamMatchesWithLabels,
    GetSearchParamMatchesWithLabelsVariables,
    GetSearchParamMatchesWithLabels_GetSearchTenderMatch,
} from "../../__generated__/GetSearchParamMatchesWithLabels";
import { ReRunContext } from "../contextProviders/HighlightContext";
import { GetDefaultSummary_tender_searchresults } from "../../__generated__/GetDefaultSummary";
import { QUERY_GETLABELMATCHPARAMS_WITH_LABELS } from "../../graphql/queryDefSearchAndHighlighting";

interface Props {
    tender_id: string;
    backup: GetDefaultSummary_tender_searchresults[];
}
const handleHighlighting = (searches: any[]) =>
    searches.forEach((label: any) => {
        const collection = document.getElementsByClassName(`search_${label?.search?.id}`) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < collection.length; i++) {
            // styling on render
            collection[i].style.textDecoration = `underline 3px ${label?.search?.color}`;
            collection[i].style.cursor = "pointer";
            collection[i].style.fontStyle = "normal";

            // styling on mouse enter (hover)
            collection[i].onmouseenter = () => {
                for (let k = 0; k < collection.length; k++) {
                    collection[k].style.textDecoration = `underline 5px ${label?.search?.color}`;
                    collection[k].style.fontWeight = "500";
                    collection[k].style.fontStyle = "normal";
                }
            };

            // styling on mouse leave
            collection[i].onmouseleave = () => {
                for (let k = 0; k < collection.length; k++) {
                    collection[k].style.textDecoration = `underline 3px ${label?.search?.color}`;
                    collection[k].style.fontWeight = "normal";
                    collection[k].style.fontStyle = "normal";
                }
            };
        }
    });

const HighlightInTenderDetail: React.FC<Props> = ({ children, tender_id, backup }) => {
    const [rerunHighlight, setRerunHighlight] = useState(false);
    const [searchRules, setSearchRules] = useState<(GetSearchParamMatchesWithLabels_GetSearchTenderMatch | null)[] | null>([]);

    useQuery<GetSearchParamMatchesWithLabels, GetSearchParamMatchesWithLabelsVariables>(QUERY_GETLABELMATCHPARAMS_WITH_LABELS, {
        variables: { tender_id: parseInt(tender_id) },
        fetchPolicy: "network-only",
        onCompleted: (SearchRuleData) => {
            setSearchRules(SearchRuleData.GetSearchTenderMatch);
            if (SearchRuleData.GetSearchTenderMatch !== null) {
                handleHighlighting(SearchRuleData.GetSearchTenderMatch);
            }
        },
    });

    useEffect(() => {
        if (searchRules === null || searchRules.length === 0) {
            handleHighlighting(backup || []);
        } else {
            handleHighlighting(searchRules || []);
        }
    });

    return (
        <ReRunContext.Provider value={{ rerunHighlight, setRerunHighlight }}>
            <React.Fragment>{children}</React.Fragment>
        </ReRunContext.Provider>
    );
};

export default HighlightInTenderDetail;
