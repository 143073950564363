import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccordionSummary, Divider, Grid, Typography } from "@mui/material";

import { useLazyQuery } from "@apollo/client";
import {
    GetAllDocsForOpportunitiesOnTender,
    GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_results,
    GetAllDocsForOpportunitiesOnTenderVariables,
} from "../../__generated__/GetAllDocsForOpportunitiesOnTender";
import { QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE } from "../../graphql/opportunityQueries/queryDefinitions";
import { OpportunityFilterInput, OpportunitySearchInput, OpportunitySearchSource, SortOrder } from "../../__generated__/globalTypes";
import FullPageSpinner from "../loading/FullPageSpinner";
import { GetCasForOpportunityDocs_tender_clients } from "../../__generated__/GetCasForOpportunityDocs";
import { useTour } from "../contextProviders/TourContext";
import { Link } from "react-router-dom";
import FaviconBox from "../FaviconBox";

interface Props {
    caIDS: GetCasForOpportunityDocs_tender_clients[];
    oppGuideOpen: boolean;
}

const filters: OpportunityFilterInput = {};
const defaultSearch: OpportunitySearchInput = { filters };
const defaultVariables: GetAllDocsForOpportunitiesOnTenderVariables = {
    page: 1,
    first: 50,
    orderField: "year_from",
    order: SortOrder.DESC,
    search: defaultSearch,
    source: OpportunitySearchSource.MY_WORKLIST,
};

const OpportunityWorklistOnTenderDetail: React.FC<Props> = ({ caIDS, oppGuideOpen }) => {
    const { t } = useTranslation();
    const [orderField] = useState<string>(defaultVariables.orderField);
    const [orderDir] = useState<SortOrder>(defaultVariables.order);
    const [searchInput, setSearchInput] = useState<OpportunityFilterInput>({});
    const [page] = useState(defaultVariables.page);
    const [rowsPerPage] = useState(defaultVariables.first);
    const [oppData, setOppData] = useState<GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_results | null>();

    const { setCurrentStep } = useTour();

    /**
     * Variables for the GQL query
     */
    const variables: GetAllDocsForOpportunitiesOnTenderVariables = {
        source: defaultVariables.source,
        page: page,
        first: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            query: "",
            filters: searchInput,
        },
    };

    useEffect(() => {
        const ids = caIDS.map((ca) => parseInt(ca.id));
        const uniqueChars = [...new Set(ids)];

        setSearchInput({ contracting_authorities: uniqueChars });
    }, [caIDS]);

    /**
     * GQL query to get all opportunities, on complete
     * -> set filters to state and data to another state
     */
    const [run, { loading, error }] = useLazyQuery<GetAllDocsForOpportunitiesOnTender, GetAllDocsForOpportunitiesOnTenderVariables>(
        QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE,
        {
            variables: variables,
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterOpportunityFiles) {
                    setOppData(data.filterOpportunityFiles.results);

                    // setStaticLoad(true);
                    if (oppGuideOpen) {
                        setTimeout(() => {
                            setCurrentStep(1);
                        }, 300);
                    }
                }
            },
        }
    );

    /**
     * On each state change run GQL query again with updated variables
     */
    useEffect(() => {
        run({
            variables: {
                source: defaultVariables.source,
                page: page,
                first: rowsPerPage,
                orderField: orderField,
                order: orderDir,
                search: {
                    query: "",
                    filters: searchInput,
                },
            },
        });
    }, [run, t, page, rowsPerPage, orderField, orderDir, searchInput]);

    /**
     * @param content opportunity documents
     */
    const renderPage = (content: React.ReactNode) => <span data-tut="reactour__searchbar_opp_widgetbox">{content}</span>;

    if (loading) {
        return renderPage(
            <div style={{ height: 40, padding: 8, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <FullPageSpinner />
            </div>
        );
    }

    if (error || !oppData || oppData.data.length === 0) {
        return renderPage(
            <div style={{ height: 40, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography color="textSecondary">Geen resultaten gevonden.</Typography>
            </div>
        );
    }
    return renderPage(
        <div style={{ maxHeight: 425, overflow: "auto" }}>
            {oppData.data.map((opp, i) => {
                return (
                    <div key={i}>
                        <AccordionSummary expandIcon={<></>}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        {/* name of doc and contracting authority  */}
                                        <Link
                                            to={`/opportunities/${opp.id}`}
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                        >
                                            <Typography style={{ display: "flex", alignItems: "center", color: "#000" }}>
                                                <FaviconBox favicon={opp.contractingAuthority.favicon} name={opp.name} color={"#F57117"} />
                                                {opp.name} - {opp.contractingAuthority.name || ""}
                                            </Typography>
                                        </Link>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography align="left">Gevonden resultaten: {opp.personalResultsCount}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography align="left">Te beoordelen pagina`s: {opp.unreviewed_page_count}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </AccordionSummary>

                        <Divider />
                    </div>
                );
            })}
        </div>
    );
};

export default OpportunityWorklistOnTenderDetail;
