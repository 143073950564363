import React from "react";
import { List, Typography } from "@mui/material";
import { TenderModulePreferences } from "../../../../__generated__/globalTypes";
import { ThumbUp } from "@mui/icons-material";
import DeadlineReminder from "./DeadlineReminder";
import EndDateReminder from "./EndDateReminder";
import NotificationSettingMobile from "./NotificationSettingMobile";

interface Props {
    settings: TenderModulePreferences;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
    saveSettings: (settings: TenderModulePreferences) => void;
}

const TenderNotificationsMobileView: React.FC<Props> = ({ settings, setChanged, saveSettings }) => {
    const blue = "#173357";

    return (
        <List sx={{ width: "100%", padding: 0 }}>
            <Typography variant="h3" sx={{ display: "flex", alignItems: "center" }}>
                <ThumbUp fontSize="small" sx={{ marginRight: "8px", color: blue }} /> Notificaties
            </Typography>
            <Typography variant="caption">Bepaal jouw gewenste notificaties</Typography>
            {settings?.notifications?.map((setting, i) => {
                return (
                    <NotificationSettingMobile
                        settings={settings}
                        setting={setting}
                        setChanged={setChanged}
                        saveSettings={saveSettings}
                        i={i}
                        key={setting?.event}
                    />
                );
            })}

            {/* REMINDERS */}
            {settings?.reminders
                ?.filter((f) => f?.event !== "STARTDATE_CONTRACT")
                ?.map((reminder, i) => {
                    return (
                        <div key={reminder?.event} style={{ marginBottom: "8px" }}>
                            {reminder?.event === "DEADLINE" ? (
                                <DeadlineReminder reminder={reminder} saveSettings={saveSettings} setChanged={setChanged} settings={settings} i={i} />
                            ) : reminder?.event === "NEXT_ENDDATE_CONTRACT" ? (
                                <EndDateReminder reminder={reminder} saveSettings={saveSettings} setChanged={setChanged} settings={settings} i={i} />
                            ) : (
                                <div>TOFILL</div>
                            )}
                        </div>
                    );
                })}
        </List>
    );
};

export default TenderNotificationsMobileView;
