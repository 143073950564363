import React from "react";

//Import Material UI
import { Icon, IconButton, Typography } from "@mui/material";

//icons in this widget
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { GetOrganizationDetail_tenderer_urls } from "../../__generated__/GetOrganizationDetail";
interface Props {
    urls: GetOrganizationDetail_tenderer_urls[];
    title?: boolean;
}

const SocialWidget: React.FC<Props> = ({ urls, title = true }) => {
    return (
        <React.Fragment>
            <div style={{ width: "100%" }}>
                {title && (
                    <Typography variant="h4" sx={{ margin: "4px 0" }}>
                        Socials
                    </Typography>
                )}

                {/* Linkedin*/}
                {urls?.map((url) => {
                    if (url.type === "LINKEDIN")
                        return (
                            <IconButton key={url.id} href={url.url} target="_blank" size="small">
                                <LinkedInIcon fontSize="small" />
                            </IconButton>
                        );
                    if (url.type === "YOUTUBE")
                        return (
                            <IconButton key={url.id} href={url.url} target="_blank" size="small">
                                <YouTubeIcon fontSize="small" />
                            </IconButton>
                        );
                    if (url.type === "TWITTER")
                        return (
                            <IconButton key={url.id} href={url.url} target="_blank" size="small">
                                <Icon fontSize="small" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img
                                        alt="x"
                                        style={{ width: "15px", height: "15px" }}
                                        src="https://storage.googleapis.com/tenderguide-mail-assets/x_Logo.svg"
                                    />
                                </Icon>
                            </IconButton>
                        );
                    if (url.type === "FACEBOOK")
                        return (
                            <IconButton key={url.id} href={url.url} target="_blank" size="small">
                                <FacebookIcon fontSize="small" />
                            </IconButton>
                        );
                    if (url.type === "INSTAGRAM")
                        return (
                            <IconButton key={url.id} href={url.url} target="_blank" size="small">
                                <InstagramIcon fontSize="small" />
                            </IconButton>
                        );
                    if (url.type === "PINTEREST")
                        return (
                            <IconButton key={url.id} href={url.url} target="_blank" size="small">
                                <PinterestIcon fontSize="small" />
                            </IconButton>
                        );
                    return null;
                })}
            </div>
        </React.Fragment>
    );
};

export default SocialWidget;
