import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import React from "react";
import { QUERY_SINGLE_YEAR_FROM } from "../../graphql/opportunityQueries/queryDefinitions";
import { GetSingleOppYearFrom, GetSingleOppYearFromVariables } from "../../__generated__/GetSingleOppYearFrom";

interface Props {
    opp_file_id: string;
}

const OppYearFrom: React.FC<Props> = ({ opp_file_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetSingleOppYearFrom, GetSingleOppYearFromVariables>(QUERY_SINGLE_YEAR_FROM, {
        variables: { id: opp_file_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.opportunityFile) {
        return <Typography>{""}</Typography>;
    }

    const opp = data.opportunityFile;

    return <Typography>{opp.year_from}</Typography>;
};

export default OppYearFrom;
