import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import OrganizationMobileCard from "../OrganizationMobileCard";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";

interface Props {
    data: dataProps[];
    paginatorInfo: any;
    onChangePagination(page: number, rowsPerPage: number): void;
    loading: boolean;
    type: "mp" | "ca";
}

export interface dataProps {
    id: string;
    name: string;
    favicon: string;
    country: string;
    org_number: string;
    last_update: string;
    place: string;
}

const CardView: React.FC<Props> = ({ data, paginatorInfo, onChangePagination, loading, type }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            {loading && (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Grid container>
                {data.map((row) => {
                    return (
                        <Grid key={row.id} xs={12} md={3}>
                            <OrganizationMobileCard data={row} type={type} />
                        </Grid>
                    );
                })}
            </Grid>
            <div>
                <Button
                    sx={{ justifySelf: "center" }}
                    disabled={!paginatorInfo.hasMorePages}
                    onClick={() => onChangePagination(paginatorInfo.currentPage, paginatorInfo.perPage + 10)}
                >
                    Laad meer
                </Button>
            </div>
        </Box>
    );
};

export default CardView;
