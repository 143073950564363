import React, { useState } from "react";
import { Button, Popover, useTheme, Theme, Divider, IconButton } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    children: React.ReactNode;
    label: React.ReactNode;
    borderColor?: keyof Theme["moduleColors"];
    allowReset?: boolean;
    amountSelectedItems?: number;
    /**
     * Called when the popover gets opened
     */
    onOpen?(): void;
    /**
     * Called when the popover gets closed
     */
    onClose?(): void;

    /**
     * Called when the reset button is clicked
     */
    onReset?(): void;
    /**
     * Called when the save button is clicked
     */
    onSave?(): void;
    /**
     * disable when length = 0
     */
    disabled: boolean;
}

const FilterDropdownTemplate: React.FC<Props> = ({
    children,
    label,
    borderColor,
    onOpen,
    onClose,
    onReset,
    onSave,
    allowReset,
    amountSelectedItems,
    disabled,
}) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (onOpen) onOpen();
    };

    const handleSave = () => {
        if (onSave) onSave();
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (onClose) onClose();
    };

    // Open state of the popover
    const open = Boolean(anchorEl);

    return (
        <>
            <Button
                disabled={disabled}
                size="small"
                variant="text"
                sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginRight: "8px",
                    marginBottom: "8px",
                    color: "#000000",
                }}
                onClick={handleOpen}
                style={borderColor ? { borderColor: theme.moduleColors[borderColor] } : undefined}
            >
                <div style={{ fontWeight: "normal", textTransform: "initial" }}>{label}</div>
                {amountSelectedItems !== undefined && amountSelectedItems > 0 ? (
                    <IconButton
                        size="small"
                        sx={{ marginLeft: "2px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (onReset) onReset();
                        }}
                    >
                        <CloseIcon style={{ width: "0.8rem", height: "0.8rem" }} />
                    </IconButton>
                ) : (
                    <ArrowDropDown />
                )}
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {children}

                <Divider />

                <div style={{ padding: "8px", display: "flex", justifyContent: "flex-end" }}>
                    {allowReset && (
                        <Button variant="text" onClick={onReset}>
                            Wis selectie
                        </Button>
                    )}
                    <Button variant="text" onClick={handleSave}>
                        Opslaan
                    </Button>
                </div>
            </Popover>
        </>
    );
};

export default FilterDropdownTemplate;
