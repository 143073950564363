import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";

// Translation
import { useTranslation } from "react-i18next";

// import icons
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TenderExportModal from "./TenderExportModal";
import { GridInput, SortOrder, TenderFilterInput, TenderFilterTarget } from "../../__generated__/globalTypes";
import { useLocation } from "react-router-dom";
import { useSelectedColumns } from "../contextProviders/ColumnProvider";
import { Disable } from "react-disable";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    columnExport: GridInput;
    total: number;
    order: SortOrder;
    orderField: string;
    searchInput: TenderFilterInput;
    query: string;
    target: TenderFilterTarget;
    columns: any[];
}

const RowTenderExportButton: React.FC<Props> = ({ columnExport, total, order, orderField, searchInput, query, target, columns }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const { items } = useSelectedColumns(columns);
    const { trackEvent } = useMatomo();

    /**
     * handler to close the information dialog
     */
    const handleCloseExport = () => {
        setOpenDialog(false);
    };
    // total is greater than 0 and smaller than 500
    const disabledVersion = total === 0 || total > 499;

    // location is /tenders/reviews or /tenders/worklist
    const tenderlocation = pathname === "/tenders/reviews" || pathname === "/tenders/worklist";

    // Track event
    const trackClickExport = () => {
        if (pathname === "/tenders/reviews") {
            trackEvent({ category: "Tender", action: "Click-export-tender-reviewed" } as MatomoEvent);
        }
        if (pathname === "/tenders/worklist") {
            trackEvent({ category: "Tender", action: "Click-export-tender-worklist" } as MatomoEvent);
        }
    };

    return (
        <React.Fragment>
            {disabledVersion ? (
                <Tooltip
                    enterNextDelay={100}
                    placement="top"
                    title={
                        total === 0 ? (
                            <React.Fragment>
                                <span>Export niet mogelijk</span>
                                <br />
                                <span>Geen rijen om te exporteren</span>
                            </React.Fragment>
                        ) : total > 499 ? (
                            <React.Fragment>
                                <span>Export niet mogelijk</span>
                                <br />
                                <span>Gebruik filters om de export te verkleinen tot maximaal 499 rijen</span>
                            </React.Fragment>
                        ) : (
                            ""
                        )
                    }
                >
                    <div>
                        <Disable disabled={true}>
                            <IconButton aria-label="event" size="small" style={{ margin: 1 }}>
                                <CloudDownloadIcon />
                            </IconButton>
                        </Disable>
                    </div>
                </Tooltip>
            ) : (
                <Tooltip enterNextDelay={100} placement="top" title={t("Column_Button.Export") as string}>
                    <IconButton
                        disabled={disabledVersion}
                        aria-label="event"
                        size="small"
                        onClick={() => {
                            setOpenDialog(true);
                            trackClickExport();
                        }}
                        style={{ margin: 1 }}
                    >
                        <CloudDownloadIcon />
                    </IconButton>
                </Tooltip>
            )}
            {openDialog === true && tenderlocation === true && (
                <TenderExportModal
                    columns={items}
                    open={openDialog}
                    columnExport={columnExport}
                    onClose={handleCloseExport}
                    dialogTitle={"Exporteren"}
                    searchInput={searchInput}
                    order={order}
                    orderField={orderField}
                    query={query}
                    target={target}
                    dialogText="Kies de kolommen die je wilt exporteren"
                />
            )}
        </React.Fragment>
    );
};

export default RowTenderExportButton;
