import { List, ListItemButton } from "@mui/material";
import React from "react";
import { GetUserLogsWithFiltersVariables } from "../../../../__generated__/GetUserLogsWithFilters";
import LogOptionSelector from "../LogOptionSelector";
import { useTranslation } from "react-i18next";

interface Props {
    updateProperties: (newProperties: Partial<GetUserLogsWithFiltersVariables>) => void;
    setChosenAction: (value: string) => void;
    variables: GetUserLogsWithFiltersVariables;
    chosenAction: string;
    rowsPerPage: number;
    actions: {
        action: string;
        label: string;
    }[];
    onReset?(): void;
}

const LogActionSelector: React.FC<Props> = ({ updateProperties, setChosenAction, variables, chosenAction, rowsPerPage, actions, onReset }) => {
    const { t } = useTranslation();
    return (
        <LogOptionSelector
            disabled={false}
            label={"Actie"}
            active={variables.action !== undefined}
            allowReset={variables.action !== undefined ? true : false}
            onClose={() => {
                setChosenAction("");
            }}
            onReset={() => {
                onReset && onReset();
            }}
            onSave={() => {
                updateProperties({
                    first: rowsPerPage,
                    page: 1,
                    action: chosenAction,
                });
            }}
        >
            <div style={{ maxWidth: 400 }}>
                <List sx={{ padding: 0 }}>
                    {actions.map((action) => {
                        return (
                            <ListItemButton
                                key={action.action}
                                sx={{ fontWeight: chosenAction === action.action ? 500 : "normal" }}
                                selected={chosenAction === action.action}
                                onClick={() => setChosenAction(action.action)}
                            >
                                {t(`action.${action.label}`)}
                            </ListItemButton>
                        );
                    })}
                </List>
            </div>
        </LogOptionSelector>
    );
};

export default LogActionSelector;
