import { RadioButtonUnchecked, TaskAlt } from "@mui/icons-material";
import { Checkbox, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Disable } from "react-disable";
import TaskDatePicker from "../../../projects/projectModal/task/TaskDatePicker";
import moment from "moment";
import UserForTask from "../../../projects/projectModal/task/UserForTask";
import TaskPriorityChip from "../../../projects/projectModal/task/TaskPriorityChip";
import { Priority } from "../../../../__generated__/globalTypes";
import { GetUserLogs_getActivities_data_subject_UserTask_users } from "../../../../__generated__/GetUserLogs";

interface Props {
    completed: boolean;
    description: string;
    deadline: string | null;
    users: GetUserLogs_getActivities_data_subject_UserTask_users[] | null;
    id: string;
    priority: Priority;
}

const ReadOnlyTask: React.FC<Props> = ({ id, completed, description, deadline, users, priority }) => {
    return (
        <React.Fragment>
            <ListItem disablePadding dense>
                {/*
                 * Icon to show
                 * - Disable state if task is not for current user
                 */}
                <ListItemIcon>
                    <Disable disabled disabledOpacity={0.8}>
                        <Checkbox
                            edge="start"
                            size="small"
                            checked={completed}
                            sx={{ marginLeft: "4px" }}
                            icon={
                                completed ? (
                                    <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                                ) : (
                                    <RadioButtonUnchecked fontSize="small" sx={{ color: "#cccccc" }} />
                                )
                            }
                            checkedIcon={<TaskAlt sx={{ color: "#2E76FF" }} />}
                        />
                    </Disable>
                </ListItemIcon>

                {/* Tasks text */}
                <ListItemText sx={{ textDecoration: completed ? "line-through" : "none" }} primary={description} />

                {/* DatePicker */}
                <Disable disabled disabledOpacity={0.8}>
                    <TaskDatePicker
                        date={deadline !== null ? moment(deadline) : null}
                        updateDate={() => {
                            console.log();
                        }}
                    />
                </Disable>

                <div style={{ minWidth: "8px" }} />

                {/* Users linked to task */}
                <Disable disabled disabledOpacity={0.8}>
                    <UserForTask chosenUserId={users ?? []} task_id={id} />
                </Disable>

                {/* Prioritychip  */}
                <Disable disabled disabledOpacity={0.8}>
                    <TaskPriorityChip dot={false} disabled priority={priority} />
                </Disable>
            </ListItem>
        </React.Fragment>
    );
};

export default ReadOnlyTask;
