import React from "react";
import SplitDetailMetaStartEndDate from "./SplitDetailMetaStartEndDate";
import SplitMetaStartEndNewFormat from "./SplitMetaStartEndNewFormat";

interface Props {
    tender_id: string;
    mp?: string;
}

const SplitMetaStartEndDateWrapper: React.FC<Props> = ({ tender_id, mp = undefined }) => {
    const renderSwitch = () => {
        switch (true) {
            case mp !== undefined:
                return <SplitDetailMetaStartEndDate tender_id={tender_id} mp={mp} />;

            // Fallback
            default:
                return <SplitMetaStartEndNewFormat tender_id={tender_id} />;
        }
    };

    return <div style={{ height: "100%", width: "100%" }}>{renderSwitch()}</div>;
};

export default SplitMetaStartEndDateWrapper;
