import React from "react";
import { GET_MARKET_PARTY_COLUMN } from "../../graphql/queryDefForColumns";
import { useQuery } from "@apollo/client";
import { Tooltip, Typography } from "@mui/material";
import { GetMarketPartyColumn, GetMarketPartyColumnVariables } from "../../__generated__/GetMarketPartyColumn";
import { Link } from "react-router-dom";

interface Props {
    tender_id: string;
}

const MarketPartyColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetMarketPartyColumn, GetMarketPartyColumnVariables>(GET_MARKET_PARTY_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    if (tender.bids === null || tender.bids.length === 0) {
        const jsonData = JSON.parse(tender.published_participants);
        return (
            <Typography>
                {jsonData.length > 0 &&
                    jsonData?.map((row: any) =>
                        row.contractors?.map((int: any, i: number) => {
                            return (
                                <div key={i}>
                                    <Tooltip placement="right" title={row?.perceel_nummer || ""}>
                                        <span>{int?.name}</span>
                                    </Tooltip>
                                </div>
                            );
                        })
                    )}
            </Typography>
        );
    }

    // Filter bid winner(s)
    const onlyValueOfWinners = tender.bids.filter((bid) => bid.winner === true);

    return (
        <Typography style={{ display: "flex", flexDirection: "column" }}>
            {onlyValueOfWinners.map((mp, i) => {
                return (
                    <Tooltip key={i} title={mp.lot ? `Perceel ${mp.lot?.number}` : ""} placement="right">
                        <Link
                            style={{
                                maxHeight: "3em",
                                lineHeight: "1.5em",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                width: "fit-content",
                            }}
                            id="tender-title"
                            to={`/organizations/${mp.marketParty.id}`}
                            dangerouslySetInnerHTML={{
                                __html: mp.marketParty.name || "",
                            }}
                        />
                    </Tooltip>
                );
            })}
        </Typography>
    );
};

export default MarketPartyColumn;
