import React from "react";
import { GetDateStartColumn, GetDateStartColumnVariables } from "../../__generated__/GetDateStartColumn";
import { GET_DATE_START_COLUMN } from "../../graphql/queryDefForColumns";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import LotDateField from "../LotDateField";

interface Props {
    tender_id: string;
}

const DateStartColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetDateStartColumn, GetDateStartColumnVariables>(GET_DATE_START_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;
    return <LotDateField data={tender.looptijd_start} />;
};

export default DateStartColumn;
