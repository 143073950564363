import React from "react";
import { Checkbox, Divider, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { TenderModulePreferences } from "../../../../__generated__/globalTypes";
import { EventAvailable, ThumbUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";

interface Props {
    settings: TenderModulePreferences;
    saveSettings: (settings: TenderModulePreferences) => void;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const TenderCalendarMobile: React.FC<Props> = ({ settings, saveSettings, setChanged }) => {
    const { t } = useTranslation();
    const blue = "#173357";

    return (
        <List sx={{ width: "100%", padding: 0 }}>
            <Typography variant="h3" sx={{ display: "flex", alignItems: "center" }}>
                <ThumbUp fontSize="small" sx={{ marginRight: "8px", color: blue }} /> {t("dashboardPage.Agenda")}
            </Typography>

            <Typography variant="caption">Bepaal jouw gewenste agenda-items</Typography>
            <Paper variant="outlined" sx={{ padding: "8px" }}>
                {/* <Typography sx={{ fontWeight: "bold" }}>
                   no title needed
                </Typography> */}
                {settings.reminders?.map((reminder, i) => {
                    return (
                        <>
                            <ListItem sx={{ padding: 0 }}>
                                <ListItemIcon style={{ minWidth: 0, marginRight: "8px" }}>
                                    <EventAvailable fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={t(reminder?.event as string)} />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <FormControlLabel
                                        sx={{ marginLeft: 0, marginRight: 0 }}
                                        control={
                                            <Checkbox
                                                size="small"
                                                disabled
                                                checked={reminder?.notification}
                                                // TODO:change to reminder -> notification
                                                onChange={(e) => {
                                                    saveSettings(
                                                        update(settings, {
                                                            reminders: {
                                                                [i]: {
                                                                    notification: { $set: e.target.checked },
                                                                },
                                                            },
                                                        })
                                                    );
                                                    setChanged(true);
                                                }}
                                                name={reminder?.event}
                                            />
                                        }
                                        label=""
                                    />
                                </div>
                            </ListItem>
                            <Divider />
                        </>
                    );
                })}
                <ListItem sx={{ padding: 0 }}>
                    <ListItemIcon style={{ minWidth: 0, marginRight: "8px" }}>
                        <EventAvailable fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("STARTDATE_CONTRACT")} />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <FormControlLabel sx={{ marginLeft: 0, marginRight: 0 }} control={<Checkbox size="small" disabled checked />} label="" />
                    </div>
                </ListItem>
            </Paper>
        </List>
    );
};

export default TenderCalendarMobile;
