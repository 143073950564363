import React from "react";
import { useQuery } from "@apollo/client";
import client from "../../../../graphql/gqlClient";
import FaviconBox from "../../../../components/FaviconBox";
import { Typography } from "@mui/material";
import TopLinksModule from "../../../../components/organizationComponents/TopLinksModule";
import { GetTopFiveMostWonFrom, GetTopFiveMostWonFromVariables } from "../../../../__generated__/GetTopFiveMostWonFrom";
import { Link } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { QUERY_CA_GENERAL_INFO } from "../../../../graphql/queryDefCA";
import { QUERY_TOP_FIVE_MOST_WON_FROM_MP } from "../../../../graphql/queryDefOrg";
interface Props {
    mp_id: string;
}

const MPMostAwardedFrom: React.FC<Props> = ({ mp_id }) => {
    const { trackEvent } = useMatomo();
    const { loading, error, data } = useQuery<GetTopFiveMostWonFrom, GetTopFiveMostWonFromVariables>(QUERY_TOP_FIVE_MOST_WON_FROM_MP, {
        variables: { id: mp_id },
    });

    // Track event
    const trackMostAwardedMP = (event: MatomoEvent) => {
        trackEvent(event);
    };

    if (loading || error || data?.tenderer?.most_active_for?.length === 0) return <></>;
    return (
        <TopLinksModule Title="meeste opdrachten van">
            {data?.tenderer?.most_active_for?.map((m) => {
                return (
                    <li
                        key={m?.client.id}
                        style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2 }}
                        // preload tenderer detailpage data
                        onMouseOver={() =>
                            client.query({
                                query: QUERY_CA_GENERAL_INFO,
                                variables: { id: m?.client.id },
                            })
                        }
                    >
                        {m !== null && <FaviconBox favicon={m.client.favicon_resized ?? m.client.favicon} name={m.client.name} color="#F57117" />}
                        <React.Fragment>
                            <Typography style={{ display: "inline", marginLeft: 6, maxWidth: "100%", marginRight: 2 }}>
                                <Link
                                    style={{ color: "#465c84" }}
                                    to={`/contracting-authorities/${m?.client.id}`}
                                    onClick={() =>
                                        trackMostAwardedMP({
                                            category: "Organizations",
                                            action: "Click-to-most-assignments-from-organization-to-ca-detail",
                                            value: parseInt(m?.client.id as string),
                                            name: m?.client.name as string,
                                        })
                                    }
                                >
                                    {m?.client.name}{" "}
                                </Link>
                                <span style={{ marginLeft: 2, color: "#000", textDecoration: "none !important" }}>({m?.aantal})</span>
                            </Typography>
                        </React.Fragment>
                    </li>
                );
            })}
        </TopLinksModule>
    );
};

export default MPMostAwardedFrom;
