import React from "react";
import { Message, Send } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Skeleton, TextField, Typography } from "@mui/material";
import FullPageSpinner from "../../loading/FullPageSpinner";

interface Props {
    textualHelper: string;
    loading: boolean;
}

const ConversationLoader: React.FC<Props> = ({ loading, textualHelper }) => {
    const lightBGcolor = "#f4f4f4";
    const greyishColor = "#000000";

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", backgroundColor: lightBGcolor }}>
            {/* Form to send a message in clicked chat */}
            <React.Fragment>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 8,
                        padding: 10,
                        minHeight: 70,
                        maxHeight: 70,
                    }}
                >
                    <Skeleton variant="circular" width={32} height={32} />
                </div>
                {/*
                 * Show icon with text when no messages are received/sent to fill empty space
                 */}
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Box sx={{ height: "9rem", width: "9rem" }}>
                        {loading ? <FullPageSpinner /> : <Message sx={{ height: "9rem", width: "9rem", color: `${greyishColor}14` }} />}
                    </Box>
                    <Typography variant="h5" style={{ color: "#dcdcdc" }}>
                        {textualHelper}
                    </Typography>
                </div>
            </React.Fragment>
            <div style={{ backgroundColor: "white", padding: "8px", display: "flex", flexDirection: "row", alignContent: "center" }}>
                <TextField
                    disabled
                    size={"small"}
                    variant="outlined"
                    sx={{
                        width: "100%",
                        marginRight: "10px",
                        borderColor: "#173357",
                    }}
                    placeholder="Type hier een notitie..."
                    multiline
                    maxRows={10}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    type="submit"
                                    sx={{
                                        color: "#707070",
                                    }}
                                    disabled={true}
                                    size="small"
                                >
                                    <Send fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default ConversationLoader;
