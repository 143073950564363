import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import LoadingProjectCard from "./projectcard/LoadingProjectCard";
import { Check } from "@mui/icons-material";
import AddColumnButton from "./AddColumnButton";
import uniqueId from "../../../utils/uniqueId";
import ColumnName from "./ColumnName";
import update from "immutability-helper";

interface Props {
    chosenColumns: Col[];
    setChosenColumns: (cols: Col[]) => void;
}

interface Col {
    id?: string;
    name: string;
    order: number;
}

const CustomColumns: React.FC<Props> = ({ chosenColumns, setChosenColumns }) => {
    const [newColumn, setNewColumn] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<string | false>(false);
    const [newCol, setNewCol] = useState<Col>({
        id: "x",
        name: "",
        order: chosenColumns.length + 1,
    });

    /**
     * Add new team to teams array
     */
    const handleNewColumn = () => {
        setNewColumn(true);
        const columnId = uniqueId().toString();
        setNewCol({
            id: columnId,
            name: "",
            order: chosenColumns.length + 1,
        });
    };

    /**
     * @param name given name for column
     * @param col order and id
     */
    const setNameAndAdd = (name: string, col: Col) => {
        if (name !== "") {
            setChosenColumns([
                ...chosenColumns,
                {
                    id: col.id,
                    name: name,
                    order: col.order + 1,
                },
            ]);
            setNewColumn(false);
        }
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div style={{ height: "80px" }}>
                        <Typography variant="h3" style={{ fontWeight: 500, marginBottom: "12px" }}>
                            Voorbeeldweergave
                        </Typography>
                        <Typography variant="body1" style={{ fontWeight: 400, lineHeight: "18px" }}>
                            Selecteer de gewenste kolommen.
                            <br />
                            Toevoegen van extra kolommen en het wijzigen van de namen kan in het overzicht.
                        </Typography>
                    </div>
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            overflowX: "auto",
                            paddingBottom: "24px",
                            marginTop: "32px",
                        }}
                    >
                        <Box
                            component={"div"}
                            sx={{
                                "&:hover": {
                                    cursor: "pointer",
                                    background: "#e7e7e7",
                                },
                                minWidth: "250px",
                                maxWidth: "250px",
                                background: "#e7e7e7",
                                marginLeft: "8px",
                                marginRight: "8px",
                                padding: "12px",
                                height: "100%",
                                minHeight: "200px",
                                borderRadius: "6px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                        <Typography variant="h5">Nieuw</Typography>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "16px", height: "50px" }}>
                                    <div>
                                        <Typography>Hier komen standaard de nieuwste projecten</Typography>
                                    </div>
                                </div>
                                {/* Static loading cards  */}
                                <LoadingProjectCard maxWidth={200} minWidth={100} staticMode />
                                <LoadingProjectCard maxWidth={200} minWidth={100} staticMode />
                                <LoadingProjectCard maxWidth={200} minWidth={100} staticMode />
                            </div>
                            <div style={{ textAlign: "end" }}>
                                <Check style={{ color: "green" }} />
                            </div>
                        </Box>
                        {chosenColumns.map((column, itemIndex) => {
                            return (
                                <Box
                                    key={column.id}
                                    component={"div"}
                                    sx={{
                                        "&:hover": {
                                            cursor: "pointer",
                                            background: "#ececec",
                                        },
                                        minWidth: "250px",
                                        maxWidth: "250px",
                                        background: "#f4f4f4",
                                        marginLeft: "8px",
                                        marginRight: "8px",
                                        padding: "12px",
                                        height: "100%",
                                        minHeight: "200px",
                                        borderRadius: "6px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <ColumnName
                                                columnName={column.name || ""}
                                                setColumnName={(newValue) => {
                                                    setChosenColumns(update(chosenColumns, { [itemIndex]: { name: { $set: newValue } } }));
                                                }}
                                                editMode={editMode === column.id}
                                                setEditMode={(e) => {
                                                    editMode === column.id ? setEditMode(false) : setEditMode(column.id as string);
                                                }}
                                                amount={0}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "end" }}>
                                        <Check style={{ color: "green" }} />
                                    </div>
                                </Box>
                            );
                        })}
                        {newColumn === true && (
                            <Box
                                sx={{
                                    border: "1px solid #f4f4f4",
                                    minWidth: "350px",
                                    maxWidth: "350px",
                                    background: "#ececec",
                                    marginLeft: "8px",
                                    marginRight: "8px",
                                    padding: "12px",
                                    borderRadius: "6px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                        <ColumnName
                                            columnName={newCol.name || ""}
                                            setColumnName={(newValue) => {
                                                setNameAndAdd(newValue, newCol);
                                            }}
                                            editMode={newColumn}
                                            setEditMode={setNewColumn}
                                            amount={0}
                                        />
                                    </div>
                                </div>
                            </Box>
                        )}
                        <AddColumnButton onClick={handleNewColumn} />
                    </div>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
};

export default CustomColumns;
