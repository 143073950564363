import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
    onChangePagination: (page: number, rowsPerPage: number) => void;
    currentPage: number;
    perPage: number;
    total: number;
    lastItemIndex: number;
    firstItemIndex: number;
}

const MobileTableNavigation: React.FC<Props> = ({ onChangePagination, currentPage, perPage, total, lastItemIndex, firstItemIndex }) => {
    const { t } = useTranslation();
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px" }}>
            <div>
                <Select
                    variant="standard"
                    value={perPage}
                    onChange={(e) => onChangePagination(currentPage, e.target.value as number)}
                    style={{ marginRight: 4 }}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
                per pagina
            </div>
            <div>
                {total === 0 ? 0 : firstItemIndex?.toLocaleString()}-{lastItemIndex?.toLocaleString()} {t("of")} {total?.toLocaleString()}{" "}
            </div>
            <div>
                <IconButton disabled={currentPage === 1} size="small" onClick={() => onChangePagination(currentPage - 1, perPage)}>
                    <ChevronLeft />
                </IconButton>
                <IconButton size="small" onClick={() => onChangePagination(currentPage + 1, perPage)}>
                    <ChevronRight />
                </IconButton>
            </div>
        </div>
    );
};

export default MobileTableNavigation;
