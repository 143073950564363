import React from "react";
import { Paper, Typography } from "@mui/material";

interface Props {
    title: string | null;
    text: string | null | undefined;
    onItemClick(): void;
}

const KnowledgeBaseItem: React.FC<Props> = ({ title, text, onItemClick }) => {
    return (
        <Paper
            sx={{
                padding: "8px 16px 8px 16px",
                margin: "0px 16px 8px 16px",
                "&:hover": {
                    cursor: "pointer",
                    margin: "0px 16px 8px 20px",
                },
            }}
            elevation={2}
            onClick={onItemClick}
        >
            <Typography
                variant="body1"
                sx={{
                    fontWeight: 400,
                    color: "#707070",
                }}
            >
                {title}
            </Typography>
            <Typography>{text}</Typography>
        </Paper>
    );
};

export default KnowledgeBaseItem;
