import React, { useState } from "react";
import { InputAdornment, List, TextField, Popper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

//import highlighting
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

// icon for this component
import SearchIcon from "@mui/icons-material/Search";
import KnowledgeBaseSearchItem from "./KnowledgeBaseSearchItem";
import { getAllArticles_articles } from "../../__generated__/getAllArticles";
import { useTranslation } from "react-i18next";
import { useFaq } from "../contextProviders/FaqOpenerContext";

interface Props {
    articles: getAllArticles_articles[];
}

// Custom popper
// - disabled flip
const CustomPopper = (props: any) => {
    return (
        <Popper
            {...props}
            placement="bottom"
            disablePortal={false}
            modifiers={{
                flip: {
                    enabled: false,
                },
            }}
        />
    );
};

const KnowledgeBaseSearchBar: React.FC<Props> = ({ articles }) => {
    const { t } = useTranslation();
    const { setView } = useFaq();
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState<string>("");

    const handleQueryChange = (newQuery: string) => {
        setQuery(newQuery);
    };

    return (
        // strong is used to disable dragging
        <strong>
            <Autocomplete
                noOptionsText="Geen artikelen gevonden"
                open={open}
                onOpen={() => {
                    if (query.length > 0) {
                        setOpen(true);
                    }
                }}
                onClose={() => {
                    setOpen(false);
                }}
                size="small"
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                options={articles}
                PopperComponent={CustomPopper}
                renderOption={(props, option, { inputValue }) => {
                    const titleMatches = match(option.title, inputValue);
                    const titleParts = parse(option.title, titleMatches);

                    return (
                        <React.Fragment>
                            {/* liststyle when system has matching documents */}
                            <List style={{ flexGrow: 1, width: "100%", padding: 0, margin: 0 }}>
                                <KnowledgeBaseSearchItem
                                    titleParts={titleParts}
                                    secondary={t(`faq.${option.module}`)}
                                    category={option.category}
                                    onItemClick={() => setView(option.id)}
                                />
                            </List>
                        </React.Fragment>
                    );
                }}
                style={{ flexGrow: 1, margin: "8px 0 8px 8px" }}
                // searchIcon in textfield
                renderInput={(params) => {
                    params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    );
                    return (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Type zoekwoord..."
                            onChange={(e) => {
                                handleQueryChange(e.target.value);
                                e.target.value.length === 0 ? setOpen(false) : setOpen(true);
                            }}
                            value={query}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: null,
                                // (
                                //     <React.Fragment>
                                //         {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                //         {params.InputProps.endAdornment}
                                //     </React.Fragment>
                                // ),
                            }}
                        />
                    );
                }}
            />
        </strong>
    );
};

export default KnowledgeBaseSearchBar;
