import React, { useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Lock } from "@mui/icons-material";
import SixDigits from "./SixDigits";
import RecoveryCode from "./RecoveryCode";
import MailCode from "./MailCode";
import { useMutation } from "@apollo/client";
import { sendTwoFactorCode, sendTwoFactorCodeVariables } from "../../__generated__/sendTwoFactorCode";
import { SEND_CODE_TO_MAIL } from "../../graphql/twoFAdefinitions";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: () => void;
    email: string;
    password: string;
    modes: { mode: string; active: boolean }[];
    defaultMethod: string | null;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TwoFactorModal: React.FC<Props> = ({ open, setOpen, onSuccess, email, password, modes, defaultMethod }) => {
    const [method, setMethod] = useState("");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const multipleModesActive = modes.filter((mode) => mode.active).length > 1;

    const [sendCode, { error, loading }] = useMutation<sendTwoFactorCode, sendTwoFactorCodeVariables>(SEND_CODE_TO_MAIL);

    const sendCodeToMail = async () => {
        if (error) {
            console.log("error");
        }
        try {
            await sendCode({
                variables: { email, password },
                // onCompleted: () => {},
            });
        } catch (e) {
            console.log("error");
        }
    };

    useEffect(() => {
        if (defaultMethod !== null) {
            setMethod(defaultMethod);
        }
    }, [defaultMethod]);

    const renderContent = () => {
        switch (true) {
            case method === "totp" && modes.find((item) => item.mode === "totp")?.active:
                return (
                    <>
                        <SixDigits setOpen={setOpen} onSuccess={onSuccess} email={email} password={password} />
                    </>
                );
            case method === "backupCode":
                return (
                    <>
                        <RecoveryCode setOpen={setOpen} onSuccess={onSuccess} />
                    </>
                );
            case method === "mail" && modes.find((item) => item.mode === "mail")?.active:
                return (
                    <>
                        <MailCode
                            setOpen={setOpen}
                            onSuccess={onSuccess}
                            email={email}
                            password={password}
                            sendCodeToMail={sendCodeToMail}
                            loadingsend={loading}
                        />
                    </>
                );
            case method === "alternative":
                return (
                    <>
                        <Typography variant="body1" fontWeight={500} sx={{ color: "#acacac", marginBottom: "16px", textAlign: "center" }}>
                            Kies een andere verificatiemethode
                        </Typography>
                        <List sx={{ padding: "0px", margin: "0px 16px 32px" }}>
                            {modes.find((item) => item.mode === "mail")?.active && (
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            sendCodeToMail();
                                            setMethod("mail");
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography textAlign="center" variant="h6">
                                                    Verificatie via email
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}
                            {modes.find((item) => item.mode === "totp")?.active && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => setMethod("totp")}>
                                        <ListItemText
                                            primary={
                                                <Typography textAlign="center" variant="h6">
                                                    Verificatie via authenticator
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}
                            {modes.find((item) => item.mode === "totp")?.active && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => setMethod("backupCode")}>
                                        <ListItemText
                                            primary={
                                                <Typography textAlign="center" variant="h6">
                                                    Gebruik een herstelcode
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </List>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted aria-describedby="twofactormodal">
            <DialogContent
                sx={{
                    width: isMobile ? "100%" : "450px",
                    height: "525px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "16px 0px 32px",
                        }}
                    >
                        <Avatar sx={{ width: 96, height: 96, backgroundColor: "#f2f2f2" }}>
                            <Lock sx={{ color: "#465c84", width: 56, height: 56 }} />
                        </Avatar>
                    </div>
                    <Typography textAlign="center" variant="h2" sx={{ margin: "16px 8px" }}>
                        Verificatie in 2 stappen
                    </Typography>
                    {renderContent()}
                </div>
                {multipleModesActive && (
                    <div>
                        <Divider variant="middle" sx={{ margin: "20px 0px" }} />
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                disabled={!multipleModesActive}
                                variant="text"
                                onClick={() => {
                                    if (method === "alternative") {
                                        setMethod(defaultMethod as string);
                                    } else {
                                        setMethod("alternative");
                                    }
                                }}
                            >
                                {method === "alternative" ? "Terug" : "Een andere manier"}
                            </Button>
                        </div>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default TwoFactorModal;
