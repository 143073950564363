import React from "react";
import classes from "./LoginHeader.module.scss";
import { ReactComponent as Logo } from "../../assets/tenderguide.svg";

/**
 * Header for the Login/Register/Forgot-password form.
 */
const LoginHeader: React.FC = () => {
    return (
        <div className={classes.LoginHeader}>
            <Logo className={classes.logo} />
        </div>
    );
};

export default LoginHeader;
