type Tender = {
    published_looptijd_start: null | any;
    published_looptijd_einde: null | any;
    published_looptijd_beschrijving: null | any;
    published_looptijd_interval: null | any;
    published_looptijd_periode: null | any;
    looptijd_start: any[] | null;
    next_looptijd_einde: any[] | null;
    looptijd_einde_incl_opties: any[] | null;
    status_contract: any[] | null;
    lots: {
        next_looptijd_einde: null | any;
        looptijd_start: null | any;
        looptijd_einde: null | any;
        participants_count: null | any;
        award_date: null | any;
        lowest_bid: null | any;
        highest_bid: null | any;
        looptijd_periode: null | any;
        looptijd_interval: null | any;
        looptijd_overeenkomst: null | any;
        contract: null | any;
        description: null | any;
        qualitativeScope: null | any;
    }[];
    contract: null | any;
};

export const isTenderFieldsNull = (tender: Tender): boolean => {
    // Check specific fields in tender
    const {
        published_looptijd_start,
        published_looptijd_einde,
        published_looptijd_beschrijving,
        published_looptijd_interval,
        published_looptijd_periode,
        looptijd_start,
        next_looptijd_einde,
        looptijd_einde_incl_opties,
        status_contract,
        lots,
        contract,
    } = tender;

    // Check main fields
    if (
        published_looptijd_start !== null ||
        published_looptijd_einde !== null ||
        published_looptijd_beschrijving !== null ||
        published_looptijd_interval !== null ||
        published_looptijd_periode !== null ||
        (looptijd_start && looptijd_start.length > 0) || // Check if looptijd_start array has elements
        (next_looptijd_einde && next_looptijd_einde.length > 0) || // Check if next_looptijd_einde array has elements
        (looptijd_einde_incl_opties && looptijd_einde_incl_opties.length > 0) || // Check if looptijd_einde_incl_opties array has elements
        (status_contract && status_contract.length > 0) || // Check if status_contract array has elements
        contract !== null
    ) {
        return false;
    }

    // Check lots array fields
    for (const lot of lots) {
        const {
            next_looptijd_einde,
            looptijd_start,
            looptijd_einde,
            participants_count,
            award_date,
            lowest_bid,
            highest_bid,
            looptijd_periode,
            looptijd_interval,
            looptijd_overeenkomst,
            contract,
            description,
            qualitativeScope,
        } = lot;

        if (
            next_looptijd_einde !== null ||
            looptijd_start !== null ||
            looptijd_einde !== null ||
            participants_count !== null ||
            award_date !== null ||
            lowest_bid !== null ||
            highest_bid !== null ||
            looptijd_periode !== null ||
            looptijd_interval !== null ||
            looptijd_overeenkomst !== null ||
            contract !== null ||
            description !== null ||
            qualitativeScope !== null
        ) {
            return false;
        }
    }

    return true; // All checked fields are null
};
