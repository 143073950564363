import React, { useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ConversationsList, { ContactListItem, ConversationListItem, Team } from "../tenderNote/ConversationsList";
// GQL
import { QueryResult, useQuery } from "@apollo/client";
// import EchoClient from "../../utils/EchoClient";
import {
    GetConversationGroup,
    GetConversationGroupVariables,
    GetConversationGroup_conversationGroup_conversations,
} from "../../__generated__/GetConversationGroup";
import { isPrivateConversation, userIsTeamMember, userIsNotCu } from "../../utils/messenger";
import ActiveConversation from "./ActiveConversation";
import { GetColleagues } from "../../__generated__/GetColleagues";
import { QUERY_SUBSCRIPTION_USERS } from "../../graphql/queryDefUserAndTeams";
import { GetSubscriptionUsers } from "../../__generated__/GetSubscriptionUsers";
import { useCurrentUserOrganization } from "../../components/contextProviders/CurrentUserOrganization";
import { activeConversation } from "./TenderNoteDialog";
import TenderNoteDialogLoader from "./ChatMessenger/TenderNoteDialogLoader";
import { QUERY_COLLEAGUES } from "../../graphql/queryDefChat";
import moment from "moment";

interface Props {
    conversation: activeConversation | null;
    setConversation: React.Dispatch<React.SetStateAction<activeConversation | null>>;
    convGrp: QueryResult<GetConversationGroup, GetConversationGroupVariables>;
    modelType: string;
    staticLoading: boolean;
    setStaticLoading: (boolean: boolean) => void;
}

export interface UserSetting {
    team?: Team;
    user?: ContactListItem;
    action: "user" | "team" | "private" | "none";
}

const DialogContent: React.FC<Props> = ({ conversation, setConversation, convGrp, modelType, staticLoading, setStaticLoading }) => {
    const { org_id } = useCurrentUserOrganization();
    const theme = useTheme();
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));

    // Keep track of the active conversation
    // Keep track of user ID of the active conversation
    const [chosenConversation, setChosenConversation] = useState<UserSetting | undefined>();

    /**
     * Query colleagues of user to create contactlist
     */
    const colleagueTeams = useQuery<GetColleagues, GetConversationGroupVariables>(QUERY_COLLEAGUES);

    /**
     * Query colleagues of user to create contactlist
     */
    const colleagues = useQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
    });

    /**
     * Loading screen when data is not fetched yet
     */
    if (convGrp.loading) {
        return <TenderNoteDialogLoader loading={true} textualHelper="Data ophalen..." />;
    }

    /**
     * Error on query to fetch all conversations
     */
    if (convGrp.error) return <TenderNoteDialogLoader loading={false} textualHelper="Er ging iets mis..." />;

    /**
     * list of active conversations with some customized props to show in GUI
     */
    const conversationList: ConversationListItem[] =
        convGrp.data?.conversationGroup.conversations?.map((conversation: GetConversationGroup_conversationGroup_conversations) => ({
            /**
             * Conversation id
             */
            id: conversation.id,
            /**
             * Id of user
             */
            userIds: conversation.users.map((u) => u.id),
            /**
             * user object
             * */
            users: conversation.users,
            /**
             * Name of user
             */
            name: conversation.users
                .filter((i) => i.id !== localStorage.getItem("user_id"))
                .map((u) => `${u.employee.givenname} ${u.employee.familyname}`)
                .join(""),
            /**
             * Team id
             */
            team_id: conversation.team?.id,
            /**
             * Team name
             */
            team_name: conversation.team?.name,
            /**
             * Last message in array
             */
            lastMessage: conversation.chatMessages.length ? conversation.chatMessages[conversation.chatMessages.length - 1].message : "-",
            /**
             * Last message in timestamp
             */
            lastMessageTime: conversation.chatMessages.length ? conversation.chatMessages[conversation.chatMessages.length - 1].send_at : "-",
            /**
             * Check if private notes or chat
             */
            private: isPrivateConversation(conversation),
            /**
             * Unread messages count
             */
            unreadMessagesCount: conversation.unreadChatMessages.length,
        })) || [];

    const sortedConversationList = conversationList.sort((a, b) => {
        const timeA = a.lastMessageTime === "-" ? moment(0) : moment(a.lastMessageTime);
        const timeB = b.lastMessageTime === "-" ? moment(0) : moment(b.lastMessageTime);
        return timeB.diff(timeA); // Sort descending
    });

    /**
     * Contactlist of user
     */
    const contacts =
        colleagues.data?.organization?.users
            .map((user) => ({
                id: user.id || "",
                name: user.employee.name || "",
                familyName: user.employee.familyname || "",
            }))
            .filter((colleague) => userIsNotCu(colleague)) || [];

    /**
     * Create array of teams where current user is a teamMember
     */
    const teams =
        colleagueTeams.data?.currentUser.employee.organization.teams
            .filter((team) => userIsTeamMember(team))
            .map((team) => ({
                teamId: team.id,
                teamName: team.name,
                members:
                    team.users.map((user) => ({
                        id: user.id || "",
                        name: user.employee.givenname || "",
                        familyName: user.employee.familyname || "",
                    })) || [],
            })) || [];

    /**
     * key that has to be unique in array below
     */
    const key = "teamId";

    /**
     * Create array of unique teams
     */
    const teamArrayUniqueByKey = [...new Map(teams.map((item: Team) => [item[key], item])).values()];

    const existingOrNew = (conv: activeConversation | null, chosen: UserSetting | undefined) => {
        if (conv === null && chosen === undefined) {
            return false;
        } else {
            if (conv) {
                return true;
            } else if (chosen) {
                return true;
            } else {
                return false;
            }
        }
    };

    if (mobileOnly) {
        return (
            <Grid container sx={{ height: `calc(80vh - 64px)` }}>
                {/*
                 * list with all possible people to chat with
                 * Order:
                 * - Active conversations
                 * - Teams of user
                 * - All colleagues of current user
                 */}

                {!existingOrNew(conversation, chosenConversation) && (
                    <Grid xs={12} md={3} style={{ height: "100%", overflow: "auto", borderRight: "1px solid lightgrey" }}>
                        <ConversationsList
                            setStaticLoading={setStaticLoading}
                            allConversations={sortedConversationList}
                            selectedConversation={conversation}
                            selectConversation={setConversation}
                            contacts={contacts}
                            onSelectNewContact={setChosenConversation}
                            teams={teamArrayUniqueByKey}
                            modelType={modelType}
                            newSelected={chosenConversation}
                        />
                    </Grid>
                )}

                {/*
                 * Grid with clicked/active chat
                 */}

                {existingOrNew(conversation, chosenConversation) && (
                    <Grid xs={12} md={9} style={{ height: "100%" }}>
                        <ActiveConversation
                            setStaticLoading={setStaticLoading}
                            staticLoading={staticLoading}
                            modelType={modelType}
                            conversationId={conversation?.id as string}
                            newConversation={chosenConversation !== undefined ? true : false}
                            users={sortedConversationList.find((conv) => conv.id === conversation?.id)?.users}
                            team={chosenConversation?.team}
                            user={chosenConversation?.user}
                            conversation_group_id={convGrp.data?.conversationGroup.id}
                            onSelectNewContact={setChosenConversation}
                            setConversation={setConversation}
                            fullConversation={sortedConversationList.filter((conv) => conv.id === conversation?.id)}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    return (
        <Grid container sx={{ height: `calc(80vh - 64px)` }}>
            {/*
             * list with all possible people to chat with
             * Order:
             * - Active conversations
             * - Teams of user
             * - All colleagues of current user
             */}

            <Grid xs={12} md={3} style={{ height: "100%", overflow: "auto", borderRight: "1px solid lightgrey" }}>
                <ConversationsList
                    setStaticLoading={setStaticLoading}
                    allConversations={sortedConversationList}
                    selectedConversation={conversation}
                    selectConversation={setConversation}
                    contacts={contacts}
                    onSelectNewContact={setChosenConversation}
                    teams={teamArrayUniqueByKey}
                    modelType={modelType}
                    newSelected={chosenConversation}
                />
            </Grid>

            {/*
             * Grid with clicked/active chat
             */}
            <Grid xs={12} md={9} style={{ height: "100%" }}>
                <ActiveConversation
                    setStaticLoading={setStaticLoading}
                    staticLoading={staticLoading}
                    modelType={modelType}
                    conversationId={conversation?.id as string}
                    newConversation={chosenConversation !== undefined ? true : false}
                    users={sortedConversationList.find((conv) => conv.id === conversation?.id)?.users}
                    team={chosenConversation?.team}
                    user={chosenConversation?.user}
                    conversation_group_id={convGrp.data?.conversationGroup.id}
                    onSelectNewContact={setChosenConversation}
                    setConversation={setConversation}
                    fullConversation={sortedConversationList.filter((conv) => conv.id === conversation?.id)}
                />
            </Grid>
        </Grid>
    );
};

export default DialogContent;
