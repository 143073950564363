import React from "react";
import classnames from "classnames";
import classes from "./WidgetBox.module.scss";
import { Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    header: React.ReactNode;
    highlight?: "blue" | "green" | "yellow" | "pink" | "grey";
    padding?: number;
    className?: string;
    actions?: React.ReactNode;
    mb?: number;
    loading?: boolean;
    elevation?: number;
}

const WidgetBox: React.FC<Props> = ({ header, highlight, padding, children, className, actions, mb, loading, elevation }) => {
    return (
        <div className={className} style={{ marginBottom: mb === undefined ? 28 : mb }}>
            <div className={classes.boxHeader}>
                <div className="title" style={{ width: "100%" }}>
                    {header} {loading && <CircularProgress style={{ width: 13, height: 13 }} />}
                </div>
                {actions && <div className="actions">{actions}</div>}
            </div>

            <Paper square elevation={elevation}>
                {highlight && <div className={classnames(classes.highlight, highlight && classes[highlight])} />}
                <div
                    className="content"
                    style={{ padding: padding === undefined ? 20 : padding, border: elevation === 0 ? "1px solid #70707014" : undefined }}
                >
                    {children}
                </div>
            </Paper>
        </div>
    );
};

export default WidgetBox;
