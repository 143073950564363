import React from "react";
import { Box, Grid } from "@mui/material";
import TopLinksModule from "../../../../components/organizationComponents/TopLinksModule";
import OrganizationDetails from "../../../../components/organizationComponents/OrganizationDetails";
import OrganizationSocials from "../../../../components/organizationComponents/OrganizationSocials";
import OrganizationContactInfo from "../../../../components/organizationComponents/OrganizationContactInfo";
import { GetOrganizationDetail, GetOrganizationDetailVariables } from "../../../../__generated__/GetOrganizationDetail";

import FullPageSpinner from "../../../../components/loading/FullPageSpinner";
import { useQuery } from "@apollo/client";
// import ReadMoreComponent from "../../../../components/ReadMoreComponent";
// import OrganizationAddress from "../../../../components/mapModule/OrganizationAddress";
import MPnames from "./MPnames";
import { useTranslation } from "react-i18next";
import MPMostAwardedFrom from "./MPMostAwardedFrom";
import MPTopFiveMostActiveIn from "./MPTopFiveMostActiveIn";
import RenderPage from "../../../../components/loading/RenderPage";
import { QUERY_INFO_ORGANIZATION } from "../../../../graphql/queryDefOrg";

// Version 2 component
// import MostActiveWidget from "./MostActiveWidget";

interface Props {
    id: string;
}

const GeneralInfoOrganization: React.FC<Props> = ({ id }) => {
    const { loading, error, data } = useQuery<GetOrganizationDetail, GetOrganizationDetailVariables>(QUERY_INFO_ORGANIZATION, { variables: { id } });
    const { t } = useTranslation();

    if (loading) {
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    // Errormessage. TODO: fancy error page
    if (error || !data?.tenderer) {
        return (
            <RenderPage>
                <p>Fout tijdens laden van marktpartij</p>
            </RenderPage>
        );
    }

    const insta = data.tenderer.urls.filter((item) => item.type === "INSTAGRAM").length;
    const fb = data.tenderer.urls.filter((item) => item.type === "FACEBOOK").length;
    const linkedin = data.tenderer.urls.filter((item) => item.type === "LINKEDIN").length;
    const youtube = data.tenderer.urls.filter((item) => item.type === "YOUTUBE").length;
    const twitter = data.tenderer.urls.filter((item) => item.type === "TWITTER").length;
    const pinterest = data.tenderer.urls.filter((item) => item.type === "PINTEREST").length;
    const socialLength = insta + fb + linkedin + youtube + twitter + pinterest;
    const copy = data.tenderer?.settlings?.data ? [...data.tenderer?.settlings?.data] : undefined;
    return (
        <React.Fragment>
            <Grid container xs={12} style={{ padding: 24 }}>
                {/* LEFT SECTION */}
                <Grid item container xs={12} md={2} direction="column">
                    <Grid style={{ marginBottom: 12, maxWidth: "100%" }}>
                        <OrganizationDetails details={copy?.sort() || undefined} logo={data.tenderer.logo || undefined} />
                    </Grid>
                    <Grid style={{ width: "100%" }}>
                        <OrganizationContactInfo
                            phonenumbers={data.tenderer.phonenumbers}
                            urls={data.tenderer.urls}
                            emails={data.tenderer.emails}
                            postal_adresses={data.tenderer.postal_addresses}
                        />

                        {socialLength > 0 && <OrganizationSocials urls={data.tenderer.urls} />}
                    </Grid>
                </Grid>

                {/* MIDDLE SECTION */}
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        paddingLeft: (theme) => (theme.breakpoints.down("sm") ? 0 : "24px"),
                    }}
                >
                    {/* <Grid item xs={12} style={{ marginBottom: 16 }} sx={{
                                padding:  (theme) => (theme.breakpoints.down("sm") ? '0px': "0 16px",
                                margin:  (theme) => (theme.breakpoints.down("sm") ? "20px auto 0px": "0 auto",
                                maxHeight: "300px",
                                overflow: "auto",
                                height:  (theme) => (theme.breakpoints.down("sm") ? "246px" :undefined
                    }}>
                        <div>
                            <TopLinksModule Title="Omschrijving">
                                <Typography>
                                    <ReadMoreComponent
                                        text={
                                            "Landerd en Uden willen samen één Maashorstgemeente zijn. Een groene gemeente met een stevige ambitie qua dienstverlening, voorzieningen en werkgelegenheid en met sterke kernen rondom natuurgebied De Maashorst. Een gemeente die er toe doet in de regio, die iets te vertellen heeft en zo goed voor haar inwoners kan zorgen. Een gemeente met zes krachtige kernen en een sterke identiteit. Gelegen in en om natuurgebied De Maashorst kan ze zich met recht hart en longen van Noordoost Brabant noemen. Bewoners uit de kernen worden betrokken bij het herindelingsontwerp. Zo ontstaat een gemeente die recht doet aan haar inwoners. Een gemeente waar mensen zich samen inzetten voor goed wonen, werken en recreëren. Waar inwoners, ondernemers, verenigingen en maatschappelijke organisaties de ruimte en kans krijgen om hun omgeving nog fijner te maken. De gemeenteraden van Landerd en van Uden hebben het plan van aanpak voor de vorming van een nieuwe Maashorstgemeente vastgesteld. Dit betekent dat zij vanaf nu samen gaan toewerken naar de definitieve herindeling. Vanaf 1 januari 2022 vormen de gemeente Uden en Landerd samen de nieuwe gemeente Maashorst."
                                        }
                                        length={500}
                                        variant={"body2"}
                                    />
                                </Typography>
                                <Typography>
                                    <Link className={classes.link} to="#">
                                        {t("Source")}: deba.nl/over-ons
                                    </Link>
                                </Typography>
                            </TopLinksModule>
                        </div>
                    </Grid> */}

                    <Grid item xs={12} md={6} direction="column">
                        {/*
                         * Top five most active in
                         * List of max 5 sectors
                         */}
                        <Box
                            sx={{
                                padding: "0px",
                                listStyle: "none",
                                margin: "0 auto",
                            }}
                        >
                            <MPTopFiveMostActiveIn mp_id={id} />
                        </Box>
                        {/*
                         * Top five most awarded from
                         * List of max 5 contracting authorities
                         */}
                        <Box
                            sx={{
                                padding: "0px",
                                listStyle: "none",
                                margin: { xs: "0 auto", md: "16px auto" },
                            }}
                        >
                            <MPMostAwardedFrom mp_id={id} />
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        direction="column"
                        sx={{
                            marginBottom: { xs: "20px", md: "0px" },
                            marginTop: { xs: "20px", md: "0px" },
                            paddingLeft: { xs: "0px", md: "16px" },
                        }}
                    >
                        {/* <div style={{ listStyle: "none", paddingBottom: 20 }}>
                            <TopLinksModule Title="hoogste concernrelatie">
                                <div style={{ listStyle: "none" }}>
                                    <li>Deba Voertuigtechniek B.V.</li>
                                </div>
                            </TopLinksModule>
                        </div> */}

                        {/*
                         * Tenderer names modal
                         * List of active and inactive names of the tenderer
                         */}
                        <MPnames mp_id={id} />

                        {/*
                         * Tenderer information
                         * for now registration number only
                         */}
                        <div style={{ listStyle: "none" }}>
                            {data.tenderer.kvknumber !== null && (
                                <TopLinksModule Title="kerngegevens">
                                    <div style={{ listStyle: "none" }}>
                                        {/* Kvk */}

                                        {data.tenderer.kvknumber !== null && (
                                            <li>
                                                {t("Column_Header.Registration_number")} {data.tenderer.kvknumber}
                                            </li>
                                        )}

                                        {/* <li>Besloten vennootschap (B.V.)</li>

                                    <li>Opgericht 28 april 1987</li>

                                    <li>Beeïndigd 22 december 2020</li> */}
                                    </div>
                                </TopLinksModule>
                            )}
                        </div>
                    </Grid>
                </Grid>

                {/* RIGHT SECTION */}
                {/* <Grid item container xs={12} md={4} className={classes.rightSection}> */}
                {/* <Grid item xs={12}> */}
                {/* Organization mapview */}
                {/* <OrganizationAddress /> */}
                {/* </Grid> */}
                {/* </Grid> */}
            </Grid>
        </React.Fragment>
    );
};

export default GeneralInfoOrganization;
