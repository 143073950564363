import React from "react";

// MUI import
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import { ReactComponent as IconSeen } from "../assets/icons/check-outlined.svg";

// GQL
import { useMutation, useQuery } from "@apollo/client";
import { GetOppDetailPage, GetOppDetailPageVariables } from "../__generated__/GetOppDetailPage";
import { setPageReadState, setPageReadStateVariables } from "../__generated__/setPageReadState";
import { QUERY_DETAILPAGE_OF_OPPORTUNITY } from "../graphql/queryDefinitions";
import { SET_PAGE_READ_STATE } from "../graphql/mutationDefinitions";

interface Props {
    opp_id: number;
}

const LikeSelectedTenderButton: React.FC<Props> = ({ opp_id }) => {
    const { data } = useQuery<GetOppDetailPage, GetOppDetailPageVariables>(QUERY_DETAILPAGE_OF_OPPORTUNITY, {
        variables: { file_id: opp_id, first: 25, page: 1 },
        fetchPolicy: "network-only",
    });

    /**
     * mutation to rate page of document
     */
    const [rate, { loading: loadingPageState, error: errorMutation }] = useMutation<setPageReadState, setPageReadStateVariables>(SET_PAGE_READ_STATE);
    const arr = data?.opportunityFilePersonalPages?.data.filter((page) => page.status !== true).map((i) => i.id);

    /**
     * @param id id of page that is being rated
     * @param status is boolean if read (status) is true or false
     */
    const setStateOfAllPages = () => {
        /**
         * if arr is empty,all pages are marked as read (or no pages found).
         * if arr is > 0 then all pages will be set to read -> true.
         */
        if (arr !== undefined && arr.length > 0) {
            arr.forEach(async (id: string) => {
                // Function to like organization
                if (loadingPageState) {
                }
                if (errorMutation) {
                    // toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await rate({
                        variables: {
                            id: id,
                            status: true,
                        },
                        refetchQueries: [QUERY_DETAILPAGE_OF_OPPORTUNITY, "GetOppDetailPage"],
                        awaitRefetchQueries: true,
                    });
                } catch (e) {
                    // toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    // setSelection([]);
                }
            });
        }
    };

    return (
        <React.Fragment>
            {arr !== undefined && arr.length > 0 && (
                <Tooltip enterNextDelay={100} title="Markeer alle pagina's als gezien" placement="top">
                    <IconButton disabled={data === undefined || loadingPageState} onClick={setStateOfAllPages}>
                        <IconSeen
                            style={{
                                width: 22,
                                height: 22,
                                fill: "#707070",
                            }}
                        />
                    </IconButton>
                </Tooltip>
            )}
        </React.Fragment>
    );
};

export default LikeSelectedTenderButton;
