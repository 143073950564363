import React, { useState } from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { useQuery } from "@apollo/client";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton, Typography } from "@mui/material";
import { Description } from "@mui/icons-material";

import { TENDER_UPDATE_DATA_NOTIFICATION } from "../../graphql/queryDefNotifications";
import {
    GetTenderUpdateNotificationData,
    GetTenderUpdateNotificationDataVariables,
    GetTenderUpdateNotificationData_tender,
} from "../../__generated__/GetTenderUpdateNotificationData";
import FaviconBox from "../FaviconBox";
import TooltipOnNewLines from "../TooltipOnNewLines";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(e: any): void;
    keyIndex: number;
}

/**
 * Display a single notification row for the notification Dialog
 */
const TenderUpdateNotification: React.FC<Props> = ({ notification, onClick, keyIndex }) => {
    const yellow = "#F57117";
    const blue = "#173357";

    const [fetchedData, setFetchedData] = useState<GetTenderUpdateNotificationData_tender>();
    // notification[0] -> tender id
    // notification[1] -> update message

    /**
     * Fetch tender data
     */
    const { loading, error } = useQuery<GetTenderUpdateNotificationData, GetTenderUpdateNotificationDataVariables>(TENDER_UPDATE_DATA_NOTIFICATION, {
        skip: notification === null || notification.data === null,
        variables: {
            tender_id: notification !== null && notification.data !== null ? (notification.data[0] as string) : "",
        },
        onCompleted: (data) => {
            if (data && data.tender) {
                setFetchedData(data.tender);
            }
        },
    });

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (loading) {
        return (
            <MenuItem key={keyIndex} style={{ whiteSpace: "normal", maxHeight: 250, opacity: notification.read_at !== null ? 0.5 : undefined }}>
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    /**
     * Return null whenever an error occured or no data is available
     */
    if (error || !fetchedData) {
        return null;
    }

    return (
        <MenuItem
            key={keyIndex}
            onClick={() => onClick(fetchedData.id as string)}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: blue }}>
                    <Description />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <TooltipOnNewLines
                        placement={"bottom-start"}
                        text={`${notification.data[1]}: ${fetchedData.namegiventocontract}` || ""}
                        variant={"body1"}
                        lines={2}
                        id={`T${notification.id as string}`}
                        fontweight={notification.read_at === null ? 500 : "normal"}
                        fontSize={14}
                    />
                }
                secondary={
                    // Show message
                    <Typography
                        style={{
                            fontSize: 12,
                            // When the nofitication is unread, the date-part of the notification is bold and accent colored
                            color: notification?.read_at ? blue : undefined,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <FaviconBox
                            favicon={fetchedData.clients.find((ca) => ca.pivot?.category === "A")?.favicon || null}
                            name={fetchedData.clients.find((ca) => ca.pivot?.category === "A")?.name || fetchedData.published_opdrachtgever}
                            color={yellow}
                        />{" "}
                        <TooltipOnNewLines
                            placement={"bottom-start"}
                            text={fetchedData.clients.find((ca) => ca.pivot?.category === "A")?.name || ""}
                            variant={"body1"}
                            lines={1}
                            id={`T${notification.id as string}${fetchedData.clients.find((ca) => ca.pivot?.category === "A")?.id}`}
                            fontweight={notification.read_at === null ? 500 : "normal"}
                            fontSize={12}
                        />
                    </Typography>
                }
            />
        </MenuItem>
    );
};

export default TenderUpdateNotification;
