import React, { useEffect, useState } from "react";
import { Collapse, useMediaQuery, useTheme } from "@mui/material";
import SearchLineMenu from "./SearchLineMenu";
import SearchLineSettings from "./SearchLineSettings";
import SearchLineStats from "./SearchLineStats";
import { presetColors } from "./presetColors";
import { Disable } from "react-disable";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import SearchLineDistribution from "./SearchLineDistribution";
import CopySearchLine from "./CopySearchline";
import ShareSearchLine from "./ShareSearchLine";
import MobileViewSearch from "./MobileViewSearch";
import StopShareSearchLine from "./StopShareSearchLine";

interface Props {
    searchline: any;
    openEdit: () => void;
    type: "opportunity" | "tender";
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
    chosenColors: string[];
    admin: boolean;
    showLock: boolean;
}
export interface SearchLine {
    id: string;
    title: string;
    region: string;
    searchterms: string[];
    defaultTerm: string[];
    update?: string;
    updateChannel?: string;
    results: string;
    active: string;
    loading: boolean;
}
export interface Color {
    id: string;
    colors: string[];
}

const SearchLineItem: React.FC<Props> = ({ searchline, openEdit, type, refetch, chosenColors, admin, showLock }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const [openPicker, setOpenPicker] = useState(false);
    const [openStats, setOpenStats] = useState(false);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [searchterm, setSearchterm] = useState<string[]>([""]);
    const [color, setColor] = useState(searchline?.color || "#000000");
    const [removeRuleLoader, setRemoveRuleLoader] = useState<boolean>(false);
    const [openShare, setOpenShare] = useState<boolean>(false);
    const [openStopShare, setOpenStopShare] = useState<boolean>(false);

    const [openCopy, setOpenCopy] = useState<boolean>(false);
    const dist = searchline.pivot
        ? {
              in_app: searchline.pivot.in_app,
              worklist: searchline.pivot.worklist,
              email_interval: searchline.pivot.email_interval,
          }
        : {
              in_app: true,
              worklist: true,
              email_interval: "daily",
          };

    /**
     * Open sharemenu mobile
     */
    const handleClickShareMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenShare(true);
    };

    /**
     * Close colorPicker
     */
    const handleClosePicker = () => {
        setOpenPicker(false);
        refetch();
    };

    /**
     * Close colorPicker
     */
    const handleOpenEdit = () => {
        // Open searcrule modal with data prefilled
        openEdit();
        // Close menu
        handleCloseSub();
    };
    const handleOpenStopShare = () => {
        // Open menu with colleagues and teams
        setOpenStopShare(true);
        // close menu with options to share, remove, edit
        setOpenMenu(false);
    };

    const handleCloseStopShare = () => {
        // Open share menu
        setOpenStopShare(false);
        // Close menu
        handleCloseSub();
    };

    const handleOpenShare = () => {
        // Open menu with colleagues and teams
        setOpenShare(true);
        // close menu with options to share, remove, edit
        setOpenMenu(false);
    };
    const handleCloseShare = () => {
        // Open share menu
        setOpenShare(false);
        // Close menu
        handleCloseSub();
    };

    const handleOpenCopy = () => {
        // Open menu with colleagues and teams
        setOpenCopy(true);
        // close menu with options to share, remove, edit
        setOpenMenu(false);
    };
    const handleCloseCopy = () => {
        // Open copy menu
        setOpenCopy(false);
        // Close menu
        handleCloseSub();
    };

    /**
     * open colorPicker
     */
    const handleOpenPicker = () => {
        setOpenPicker(true);
        handleCloseSub();
    };

    /**
     * open statistics
     */
    const handleOpenStats = () => {
        setOpenStats(true);
        setOpen(false);
        handleCloseSub();
    };

    /**
     * close statistics
     */
    const handleCloseStats = () => {
        setOpenStats(false);
        setOpen(false);
        handleCloseSub();
    };

    /**
     * @param event selected searchterm
     * add selected searchterm to selection
     */
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSearchterm(event.target.value as string[]);
    };

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    const handleOpenDist = () => {
        setOpen(!open);
        setOpenStats(false);
    };

    const openDistribution = () => {
        setOpenMenu(false);
        setAnchorEl(null);
        handleOpenDist();
    };
    /**
     * Temportary loading animation
     */
    // const [progress, setProgress] = useState(0);
    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    //     }, 800);

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);
    // useEffect(() => {
    //     setDistribution(JSON.parse(searchline.distribution));
    // }, [searchline.distribution]);

    useEffect(() => {
        setColor(searchline.color);
    }, [searchline.color]);

    return (
        <Disable disabled={removeRuleLoader}>
            {/*
             * Settings for searchrule
             * 1 sentence with al settings for individual searchrule.
             * TODO: inline edit
             */}

            {isMobile ? (
                <MobileViewSearch searchline={searchline} type={type} onOpenShare={handleClickShareMobile} dist={dist} />
            ) : (
                <SearchLineSettings
                    admin={admin}
                    chosenColors={chosenColors}
                    type={type}
                    color={color}
                    searchline={searchline}
                    open={open}
                    searchterm={searchterm}
                    handleChange={handleChange}
                    progress={0}
                    handleOpenStats={handleOpenDist}
                    handleClickSub={handleClickSub}
                    refetch={refetch}
                    handleOpenPicker={handleOpenPicker}
                    setColor={setColor}
                    handleClosePicker={handleClosePicker}
                    openPicker={openPicker}
                    showLock={showLock}
                />
            )}

            {/*
             * Box contains dsitribution settings for individual searchrule
             * TODO: inline edit
             */}
            <Collapse in={open || openStats} timeout="auto">
                {openStats && !open ? (
                    <SearchLineStats />
                ) : (
                    <SearchLineDistribution dist={dist} moment={searchline.moment} type={type} id={searchline.id} refetch={refetch} />
                )}
            </Collapse>

            {/*
             * Submenu searchrule
             *  Choose color //TODO: connect to gql mutation/query
             * ✅ Share searchrule
             * ✅ Copy searchrule
             * ✅ Delete searchrule
             * ✅ Edit searchrule
             * */}
            <SearchLineMenu
                isOwner={searchline.owner?.id === localStorage.getItem("user_id")}
                chosenColors={chosenColors}
                openShare={handleOpenShare}
                shareOpened={openShare}
                openCopy={handleOpenCopy}
                setRemoveRuleLoader={setRemoveRuleLoader}
                type={"tender"}
                openEdit={handleOpenEdit}
                searchRuleId={searchline.id}
                searchline={searchline}
                handleCloseSub={handleCloseSub}
                anchorEl={anchorEl}
                handleOpenPicker={handleOpenPicker}
                color={color}
                setColor={setColor}
                presetColors={presetColors}
                handleClosePicker={handleClosePicker}
                openPicker={openPicker}
                handleOpenStats={handleOpenStats}
                openStats={openStats}
                handleCloseStats={handleCloseStats}
                openMenu={openMenu}
                openDistribution={openDistribution}
                openShareStop={handleOpenStopShare}
                shareStopOpened={openStopShare}
            />
            <CopySearchLine
                type="tender"
                searchID={searchline.id}
                search={searchline}
                shareOpened={openCopy}
                anchorEl={anchorEl}
                handleCloseSub={handleCloseCopy}
                teamsComponent={[]}
                colleaguesComponents={[]}
            />

            <ShareSearchLine
                alreadyShared={searchline.participants.map((user: any) => user.id)}
                type="tender"
                dist={dist}
                search={searchline}
                searchID={searchline.id}
                shareOpened={openShare}
                anchorEl={anchorEl}
                handleCloseSub={handleCloseShare}
                teamsComponent={[]}
                colleaguesComponents={[]}
            />

            <StopShareSearchLine
                alreadyShared={searchline.participants.map((user: any) => user.id)}
                type="tender"
                dist={dist}
                search={searchline}
                searchID={searchline.id}
                shareOpened={openStopShare}
                anchorEl={anchorEl}
                handleCloseSub={handleCloseStopShare}
                teamsComponent={[]}
                colleaguesComponents={[]}
            />
        </Disable>
    );
};

export default SearchLineItem;
