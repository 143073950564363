import React from "react";
import { GET_NEXT_DATE_END_COLUMN } from "../../graphql/queryDefForColumns";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import LotDateField from "../LotDateField";
import { GetDateNextEndColumn, GetDateNextEndColumnVariables } from "../../__generated__/GetDateNextEndColumn";

interface Props {
    tender_id: string;
}

const DateNextEndColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetDateNextEndColumn, GetDateNextEndColumnVariables>(GET_NEXT_DATE_END_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;
    return <LotDateField data={tender.next_looptijd_einde} />;
};

export default DateNextEndColumn;
