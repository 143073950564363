import { Settings } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Project, Widget } from "../../../components/layout/MainLayout";

interface Props {
    onChangeWidget: (name: string, checked: boolean, event?: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAllWidgets: (widgets: Widget) => void;
    project: Project;
}

const WidgetsToChoose: React.FC<Props> = ({ onChangeWidget, onChangeAllWidgets, project }) => {
    const [open, setOpen] = useState(false);
    const w = project.widgets;
    const allTrue = Boolean(w.contract && w.documents && w.labels && w.planning && w.scope && w.summary);

    const test = Object.values(project.widgets).filter((i) => i !== false).length;

    useEffect(() => {
        if (!allTrue) {
            setOpen(true);
        }
        if (allTrue) {
            setOpen(false);
        }
    }, [allTrue]);

    return (
        <React.Fragment>
            <List sx={{ width: "100%", bgcolor: "background.paper" }} component="nav" aria-labelledby="nested-list-subheader">
                <ListItemButton
                    onClick={() =>
                        onChangeAllWidgets(
                            allTrue
                                ? {
                                      summary: false,
                                      planning: false,
                                      scope: false,
                                      documents: false,
                                      contract: false,
                                      labels: false,
                                  }
                                : {
                                      summary: true,
                                      planning: true,
                                      scope: true,
                                      documents: true,
                                      contract: true,
                                      labels: true,
                                  }
                        )
                    }
                    dense
                >
                    <ListItemIcon sx={{ minWidth: 0, marginRight: "12px" }}>
                        <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Alle widgets" secondary="Samenvatting, planning, documenten, contract, omvang en labels" />
                    <Checkbox checked={test === 6} indeterminate={test > 0 && test <= 5} />
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                        {/* Summary */}
                        <ListItemButton onClick={() => onChangeWidget("summary", !project.widgets.summary)} role={undefined} dense>
                            <ListItemText primary={"Samenvatting"} />
                            <Checkbox name="summary" edge="start" checked={project.widgets.summary} tabIndex={-1} disableRipple />
                        </ListItemButton>
                        {/* Planning */}
                        <ListItemButton onClick={() => onChangeWidget("planning", !project.widgets.planning)} dense>
                            <ListItemText id="switch-list-label-wifi" primary={"Planning"} />
                            <Checkbox name="planning" edge="start" checked={project.widgets.planning} tabIndex={-1} disableRipple />
                        </ListItemButton>
                        {/* Documents */}
                        <ListItemButton onClick={() => onChangeWidget("documents", !project.widgets.documents)} dense>
                            <ListItemText id="switch-list-label-wifi" primary={"Documenten"} />
                            <Checkbox name="documents" edge="start" checked={project.widgets.documents} tabIndex={-1} disableRipple />
                        </ListItemButton>
                        {/* Contract */}
                        <ListItemButton onClick={() => onChangeWidget("contract", !project.widgets.contract)} dense>
                            <ListItemText id="switch-list-label-wifi" primary={"Contract"} />
                            <Checkbox name="contract" edge="start" checked={project.widgets.contract} tabIndex={-1} disableRipple />
                        </ListItemButton>
                        {/* Scope */}
                        <ListItemButton onClick={() => onChangeWidget("scope", !project.widgets.scope)} dense>
                            <ListItemText id="switch-list-label-wifi" primary={"Omvang"} />
                            <Checkbox name="scope" edge="start" checked={project.widgets.scope} tabIndex={-1} disableRipple />
                        </ListItemButton>
                        {/* Labels */}
                        <ListItemButton onClick={() => onChangeWidget("labels", !project.widgets.labels)} dense>
                            <ListItemText id="switch-list-label-wifi" primary={"Labels"} />
                            <Checkbox name="labels" edge="start" checked={project.widgets.labels} tabIndex={-1} disableRipple />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </React.Fragment>
    );
};

export default WidgetsToChoose;
