import React from "react";
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import history from "./editions/history.json";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
    a?: number;
}

const History: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { version: routeVersion } = useParams<{ version: string }>(); // Get the version from the URL

    if (history.editions.filter((v) => v.edition !== routeVersion).length === 0) {
        return null;
    }
    return (
        <Box py={4} px={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div id="history" />
            <Typography variant="h1" fontWeight={500} gutterBottom align="center" sx={{ margin: 5, fontSize: "3rem !important" }}>
                Vorige edities
            </Typography>
            <Grid
                width={{ xs: "90vw", md: "40vw" }}
                sx={{ justifyContent: "center" }}
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                {history.editions
                    .filter((v) => v.edition !== routeVersion)
                    .map((edition, index) => (
                        <Grid xs={12} sm={6} md={6} key={index}>
                            <Card sx={{ maxWidth: 375 }} onClick={() => navigate(`/tendenz/${edition.edition}`)}>
                                <CardActionArea>
                                    <CardMedia sx={{ height: 200 }} image={edition.image} title={edition.location} />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" fontWeight={500}>
                                            {edition.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ whiteSpace: "pre-line" }}
                                            dangerouslySetInnerHTML={{
                                                __html: edition.description,
                                            }}
                                        />
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: "flex-end" }}>
                                        <Button size="small" onClick={() => navigate(`/tendenz/${edition.edition}`)}>
                                            Bekijk
                                        </Button>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default History;
