import React from "react";

// MUI import
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

// GQL
import { FetchResult, InternalRefetchQueriesInclude, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GetLikedCas } from "../__generated__/GetLikedCas";
import { likeContractingAuthority, likeContractingAuthorityVariables } from "../__generated__/likeContractingAuthority";
import MatomoEvent from "../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { DISLIKECAS, LIKECONTRACTING_AUTHORITY } from "../graphql/mutationDefinitions";
import { QUERY_USER_LIKED_CAS } from "../graphql/queryDefCurrentUser";
import { dislikeCAS, dislikeCASVariables } from "../__generated__/dislikeCAS";

interface Props {
    org_id: string;
    buttonSize: "small" | "medium";
    color: string;
    refetchQueries?: InternalRefetchQueriesInclude | ((result: FetchResult) => InternalRefetchQueriesInclude) | undefined;
}

const LikeCABtn: React.FC<Props> = ({ org_id, buttonSize, color, refetchQueries }) => {
    const { t } = useTranslation();
    const { error: errorQuery, loading: loadingQuery, data: dataQuery } = useQuery<GetLikedCas>(QUERY_USER_LIKED_CAS);
    const { trackEvent } = useMatomo();

    const [like, { loading: loadingMutation, error: errorMutation }] = useMutation<likeContractingAuthority, likeContractingAuthorityVariables>(
        LIKECONTRACTING_AUTHORITY
    );
    const [dislike, { loading: loadingDislike, error: errorDislike }] = useMutation<dislikeCAS, dislikeCASVariables>(DISLIKECAS);

    // Track event
    const trackStarCa = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // Function to like organization
    const likeOrg = async () => {
        if (errorMutation) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await like({
                variables: {
                    ca_id: org_id,
                },
                refetchQueries: refetchQueries,
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    // Function to dislike organization
    const dislikeOrg = async () => {
        if (errorDislike) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await dislike({
                variables: {
                    org_id: org_id,
                },
                refetchQueries: refetchQueries,
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    if (errorQuery || dataQuery?.currentUser === undefined || dataQuery?.currentUser === null) {
        return null;
    }

    const likes = dataQuery?.currentUser?.contracting_authorities.find((ca) => ca.id === org_id);

    return (
        <React.Fragment>
            {likes === undefined ? (
                <Tooltip enterNextDelay={100} title={t("With-star") as string} placement="top">
                    <IconButton
                        disabled={loadingMutation || loadingQuery}
                        onClick={(e) => {
                            e.stopPropagation();
                            likeOrg();
                            trackStarCa({ category: "Ca", action: "Click-like-star-ca", href: window.location.href });
                        }}
                        size={buttonSize}
                    >
                        <StarOutlineIcon fontSize="small" style={{ color: color }} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip enterNextDelay={100} title={t("Without-star") as string} placement="top">
                    <IconButton
                        disabled={loadingDislike || loadingQuery}
                        onClick={(e) => {
                            e.stopPropagation();
                            dislikeOrg();
                            trackStarCa({ category: "Ca", action: "Click-remove-star-ca", href: window.location.href });
                        }}
                        size={buttonSize}
                    >
                        <StarIcon fontSize="small" style={{ color: color }} />
                    </IconButton>
                </Tooltip>
            )}
        </React.Fragment>
    );
};

export default LikeCABtn;
