import { createContext, useContext } from "react";

export type ColumnContextType = {
    selectedRows: Array<any>;
    setSelectedRows: (selectedRows: any) => void;
};

export const ColumnContext = createContext<ColumnContextType>({
    selectedRows: [],
    setSelectedRows: () => console.warn("no theme provider"),
});
export const useColumnSetter = () => useContext(ColumnContext);
