import React, { useState } from "react";
import AddColumnButton from "./AddColumnButton";
import ColumnComponent from "./ColumnComponent";

import uniqueId from "../../../utils/uniqueId";
// import update from "immutability-helper";

// Drag and Drop
// import ColumnWrapper from "./ColumnWrapper";
import ColumnTemplateModal from "./ColumnTemplateModal";
import { ALL_PROJECTS, PROJECT_STATES, SINGLE_PROJECT } from "../../../graphql/queryDefProjects";
import { useMutation, useQuery } from "@apollo/client";
import { projectStates, projectStates_projectStates } from "../../../__generated__/projectStates";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { updateStateOfProject, updateStateOfProjectVariables } from "../../../__generated__/updateStateOfProject";
import { CREATE_NEW_PROJECT_STATE, UPDATE_PROJECT_STATE } from "../../../graphql/mutationDefProjects";
import { Box, Typography } from "@mui/material";
import ColumnName from "./ColumnName";
import { createState, createStateVariables } from "../../../__generated__/createState";
import { useCurrentUserOrganization } from "../../../components/contextProviders/CurrentUserOrganization";
import { GetSubscriptionUsers, GetSubscriptionUsers_organization_users } from "../../../__generated__/GetSubscriptionUsers";
import { QUERY_CURRENT_USER_IS_SUB_ADMIN, QUERY_SUBSCRIPTION_USERS } from "../../../graphql/queryDefUserAndTeams";
import { UserIsAD } from "../../../__generated__/UserIsAD";
import { Disable } from "react-disable";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    a?: number;
}

// Interface single data item inside a column
export interface Column {
    id: string;
    columnTitle: string;
    columnOrder: number;

    /**
     * Only the ids of the tenders in the column
     */
    projectIds: string[];
}

const ColumnView: React.FC<Props> = (props) => {
    const [columns, setColumn] = useState<projectStates_projectStates[]>([]);
    const [newColumn, setNewColumn] = useState<boolean>(false);
    const [leaders, setLeaders] = useState<GetSubscriptionUsers_organization_users[]>([]);
    const { org_id } = useCurrentUserOrganization();

    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Query if cu is admin
     */
    const { data: cuData } = useQuery<UserIsAD>(QUERY_CURRENT_USER_IS_SUB_ADMIN);

    /**
     * Query colleagues admins
     */
    useQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
        onCompleted: (data) => {
            if (data && data.organization && data.organization.users) {
                const subbies = data.organization.users.filter((user) => user.subscription_admin === true);
                setLeaders(subbies);
            }
        },
    });

    const [newCol, setNewCol] = useState<projectStates_projectStates>({
        id: "x",
        name: "Voer een kolomtitel in",
        order_number: columns.length + 1,
        amountOfProjects: 0,
        __typename: "ProjectState",
    });
    const { data, loading, refetch } = useQuery<projectStates>(PROJECT_STATES, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data === null || data.projectStates === null || data.projectStates.length === 0) {
                createNewColumn({
                    variables: { name: "New", order: 1 },
                });
                refetch();
            }
        },
    });

    /**
     * Update project title
     */
    const [createNewColumn, { loading: loadNewColumn }] = useMutation<createState, createStateVariables>(CREATE_NEW_PROJECT_STATE, {
        refetchQueries: [PROJECT_STATES, "projectStates"],
        awaitRefetchQueries: true,
    });

    /**
     * Update project title
     */
    const [updateProjectState] = useMutation<updateStateOfProject, updateStateOfProjectVariables>(UPDATE_PROJECT_STATE, {
        refetchQueries: [SINGLE_PROJECT, "single_project", PROJECT_STATES, "projectStates"],
        awaitRefetchQueries: true,
    });

    /**
     * Function to drag a whole column with items
     */
    // const moveColumn = useCallback(
    //     (dragIndex: number, hoverIndex: number) => {
    //         const dragCard = columns[dragIndex];
    //         setColumn(
    //             update(columns, {
    //                 $splice: [
    //                     [dragIndex, 1],
    //                     [hoverIndex, 0, dragCard],
    //                 ],
    //             })
    //         );
    //     },
    //     [columns]
    // );

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "scroll",
                    height: `calc(100vh - 200px)`,
                    justifyContent: "center",
                }}
            >
                <FullPageSpinner />
            </div>
        );
    }

    /**
     * Add new team to teams array
     */
    const handleNewColumn = () => {
        setNewColumn(true);
        const columnId = uniqueId().toString();
        setNewCol({
            id: columnId,
            name: "",
            order_number: columns.length + 1,
            amountOfProjects: 0,
            __typename: "ProjectState",
        });
    };

    /**
     * Add a item to column list
     * @param id id of the item that has to be added to the column
     * TODO: bugfix: last item get removed when dropping a new item
     */
    const handleAddItemToColumn = (projectId: string, columnID: string) => {
        trackevents({ category: "Project", action: "Drag-project-to-another-column" });

        updateProjectState({
            variables: { id: projectId, state: columnID },
            refetchQueries: [ALL_PROJECTS, "GetAllProjects", PROJECT_STATES, "projectStates"],
            awaitRefetchQueries: true,
        });
    };

    const cols = [...(data?.projectStates as any)] as any[];

    const saveNameAndClose = (name: string) => {
        trackevents({ category: "Project", action: "Create-new-column", name: name });

        if (name !== "") {
            createNewColumn({
                variables: { name: name, order: (cols[cols.length - 1].order_number as number) + 1 },
                refetchQueries: [PROJECT_STATES, "projectStates"],
                onCompleted: () => {
                    setNewColumn(false);
                },
            });
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", overflowX: "scroll", paddingBottom: "16px" }}>
            {/* Map columns and push data to column component to find matching detailinfo */}
            {cols
                ?.sort((a: any, b: any) => a.order_number - b.order_number)
                .map((column, index) => {
                    return (
                        // <ColumnWrapper moveColumn={moveColumn} key={column.id} index={index}>
                        <div key={column.id} style={{ display: "flex" }}>
                            <ColumnComponent
                                columnIndex={index}
                                setColumn={setColumn}
                                column={column}
                                columns={columns}
                                handleAddItemToColumn={(projectId) => handleAddItemToColumn(projectId, column.id)}
                                isAdmin={Boolean(cuData?.currentUser.subscription_admin)}
                            />
                        </div>
                        // </ColumnWrapper>
                    );
                })}
            {newColumn === true && (
                <Box
                    sx={{
                        border: "1px solid #f4f4f4",
                        minWidth: "350px",
                        maxWidth: "350px",
                        background: "#ececec",
                        marginLeft: "8px",
                        marginRight: "8px",
                        padding: "12px",
                        borderRadius: "6px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                        }}
                    >
                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                            <Disable disabled={loadNewColumn}>
                                <ColumnName
                                    columnName={newCol.name || ""}
                                    setColumnName={(newValue) => {
                                        saveNameAndClose(newValue);
                                    }}
                                    editMode={newColumn}
                                    setEditMode={setNewColumn}
                                    amount={0}
                                />
                            </Disable>
                        </div>
                    </div>
                </Box>
            )}

            {/*
             * Check states
             * If 1 state 'New' => show illustrations
             * If more states => show add state button to add an individual state
             */}
            {data?.projectStates?.length === 1 && (data.projectStates[0].name === "New" || data.projectStates[0].name === "Nieuw") ? (
                <div
                    style={{
                        flex: 1,
                        // background: "wheat",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "6px",
                        border: "1px solid #f4f4f4",
                        flexDirection: "column",
                    }}
                >
                    <div style={{ width: "60%" }}>
                        <h2 style={{ margin: 0, fontSize: "1.4rem", textAlign: "center" }}>Kanban-bord voor het managen van je projecten</h2>
                        <Typography textAlign={"center"} sx={{ margin: "16px 0px" }}>
                            Maak je eigen template of kies een standaard template.
                            <br />
                            Je kunt projecten managen door ze te slepen van en naar naar kolommen
                            <br />
                        </Typography>
                        <Disable disabled disabledOpacity={0.6}>
                            <img
                                style={{ margin: "32px 0px" }}
                                src="https://storage.googleapis.com/tenderguide-mail-assets/illustrations/outlinedcolumnexplain.svg"
                                alt="projects"
                            />
                        </Disable>
                    </div>
                    <div>
                        <ColumnTemplateModal admins={leaders} cuIsAdmin={Boolean(cuData?.currentUser.subscription_admin)} />
                    </div>
                </div>
            ) : (
                <div style={{ border: "1px solid #f4f4f4" }}>
                    {/* addbutton to add more columns in view */}
                    {Boolean(cuData?.currentUser.subscription_admin) && <AddColumnButton onClick={handleNewColumn} />}
                </div>
            )}
        </div>
    );
};

export default ColumnView;
