import React, { useEffect } from "react";

//  Custom components
import ContentDrawer from "../../../../components/contentDrawer/ContentDrawer";
// import { ZoomContext } from "../../../../components/contextProviders/ZoomContext";
import newPDFLayers from "../../../../components/pdfViewer/api/newPDFLayers";
import "../../../../components/pdfViewer/Viewer.scss";

interface Props {
    /**
     * boolean to open/close drawer
     */
    open: boolean;
    /**
     * handler to close drawer
     */
    onClose(): void;
    /**
     * title to show in header of drawer
     */
    title?: string;
    /**
     * url to load pdf
     */
    url?: string;
    /**
     * color of toolbar
     */
    color: string;
    /**
     * doc_id to download pdf
     */
    doc_id?: string;
    /**
     *  module id tender_id/ca_id/mp_id/opp_id to create correct url to download pdf
     */
    module_item_id?: string;
}

const DocumentDrawer: React.FC<Props> = ({ open, onClose, title, url, color, doc_id, module_item_id }) => {
    // const [zoom, setZoom] = useState(1.5);

    /**
     * Set opportuniy id to pdf loader/viewer
     */
    useEffect(() => {
        if (url !== undefined) {
            newPDFLayers({ a: url });
        }
    }, [url]);

    return (
        // <ZoomContext.Provider value={{ zoom, setZoom }}>
        <ContentDrawer
            url={url}
            open={open}
            onClose={onClose}
            title={title}
            bgColor={"#f5f5f5"}
            toolbarColor={color}
            doc_id={doc_id}
            module_item_id={module_item_id}
        >
            <div id="pdfViewerDiv" style={{ margin: 8, height: "94vh", overflow: "auto" }}>
                <div id="viewer" className="pdfViewer" style={{ zIndex: 990 }} />
            </div>
        </ContentDrawer>
        // </ZoomContext.Provider>
    );
};

export default DocumentDrawer;
