import React, { useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./MobileHeader.scss";
// import NavBar from "./NavBar/NavBar";
import { Avatar, Theme, useMediaQuery, useTheme } from "@mui/material";
import NotificationsMenu from "../notifications/NotificationsMenu";
import UserSupport from "../chat/UserSupport";
import ProfileBox from "./NavBar/ProfileBox";
import { useFaq } from "../contextProviders/FaqOpenerContext";
import { useProfile } from "../contextProviders/ProfileOpenerContext";
import SupportMenu from "./NavBar/SupportMenu";
import { withStyles } from "tss-react/mui";
import NavBarMobile from "./NavBar/NavBarMobile";

interface State {
    activePage?: string;
    openSupport: boolean;
    setOpenSupport: (bln: boolean) => void;
}

const StyledAvatar = withStyles(Avatar, (theme: Theme) => ({
    root: {
        width: "24px",
        height: "24px",
        fontSize: "0.75rem",
        backgroundColor: "#454545",
    },
}));

const MobileHeader: React.FunctionComponent<State> = ({ activePage, openSupport, setOpenSupport }) => {
    const theme = useTheme();
    const [openDrawer, setOpenDrawer] = useState(false);
    const anchorMobile = useRef<any>(null);
    const { openProfile, setOpenProfile, initials } = useProfile();
    const { setOpenFaq, setView } = useFaq();
    const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));

    // const anchorpoint = useRef<any>(null);

    const backToHome = async () => {
        setOpenFaq(false);
        await sleep(100);
        setView("start");
    };
    return (
        <React.Fragment>
            <div className="MobileHeader">
                <SwipeableDrawer
                    open={openDrawer}
                    onClose={() => {
                        setOpenDrawer(false);
                    }}
                    onOpen={() => setOpenDrawer(true)}
                >
                    {/* <div
                        tabIndex={0}
                        role="button"
                        onClick={() => setOpenDrawer(false)}
                        onKeyDown={() => setOpenDrawer(false)}
                        className="color"
                        style={{ height: "100%", overflow: "auto" }}
                    > */}
                    <NavBarMobile activePage={activePage} opened={openDrawer} closeDrawer={() => setOpenDrawer(false)} />
                    {/* </div> */}
                </SwipeableDrawer>

                <div className="toolbar">
                    <div style={{ display: "flex" }}>
                        <IconButton onClick={() => setOpenDrawer(true)}>
                            <MenuIcon style={{ color: "white" }} />
                        </IconButton>
                    </div>
                    <div className="middle">TenderGuide</div>
                    {mobileOnly && (
                        <div style={{ display: "flex" }}>
                            {/* notification pop up component */}
                            <NotificationsMenu />

                            {/*
                             * Menu with help options
                             * - Interactive guide (TODO)
                             * - Knowledgebase
                             * - Send support message
                             * - Screen share
                             */}
                            <SupportMenu openSupport={openSupport} setOpenSupport={setOpenSupport} />
                            {/* TourMenu */}

                            {/* user support pop up component */}
                            <UserSupport
                                anchorEl={anchorMobile.current}
                                handleClose={() => {
                                    backToHome();
                                }}
                                // TODO change input state to GQL or something to activate online support
                                supportState={false}
                            />
                            {/* Profile button */}
                            <IconButton ref={anchorMobile} onClick={() => setOpenProfile(true)}>
                                <StyledAvatar>{initials === null ? null : initials}</StyledAvatar>
                            </IconButton>
                            <ProfileBox anchorEl={anchorMobile.current} opened={openProfile} handleClose={() => setOpenProfile(false)} />
                        </div>
                    )}
                </div>
            </div>
            {mobileOnly && <span ref={anchorMobile} />}
        </React.Fragment>
    );
};

export default MobileHeader;
