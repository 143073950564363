/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContractOptionPeriod {
  DAY = "DAY",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum ContractingAuthoritiesCategory {
  A = "A",
  B = "B",
}

export enum ContractingAuthoritiesFilterSource {
  ALL_CONTRACTING_AUTHORITIES = "ALL_CONTRACTING_AUTHORITIES",
  MY_CONTRACTING_AUTHORITIES = "MY_CONTRACTING_AUTHORITIES",
}

export enum DistributionIntervalInput {
  DAILY = "DAILY",
  HOURLY = "HOURLY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum EmailType {
  FINANCE = "FINANCE",
  GENERAL = "GENERAL",
  INVOICE = "INVOICE",
  SALES = "SALES",
  TENDERS = "TENDERS",
}

export enum EmailVerificationStatus {
  VERIFIED = "VERIFIED",
}

export enum ForgotPasswordStatus {
  EMAIL_SENT = "EMAIL_SENT",
}

export enum GridInput {
  OPPORTUNITY_REVIEWS = "OPPORTUNITY_REVIEWS",
  OPPORTUNITY_WORKLIST = "OPPORTUNITY_WORKLIST",
  STARRED_CONTRACTING_AUTHORITIES = "STARRED_CONTRACTING_AUTHORITIES",
  STARRED_MARKET_PARTIES = "STARRED_MARKET_PARTIES",
  TENDER_REVIEWS = "TENDER_REVIEWS",
  TENDER_WORKLIST = "TENDER_WORKLIST",
}

export enum GridViewInput {
  COLUMN = "COLUMN",
  LIST = "LIST",
  MAP = "MAP",
  SPLIT = "SPLIT",
  UPDATE = "UPDATE",
}

export enum KnowledgebaseLanguage {
  DE = "DE",
  EN = "EN",
  ES = "ES",
  FR = "FR",
  NL = "NL",
}

export enum LotAwardStatus {
  PROCUREMENT_DISCONTINUED = "PROCUREMENT_DISCONTINUED",
  PROCUREMENT_SUCCESSFUL = "PROCUREMENT_SUCCESSFUL",
  PROCUREMENT_UNSUCCESSFUL = "PROCUREMENT_UNSUCCESSFUL",
}

export enum MeetingTimeslotType {
  SALES = "SALES",
  SUPPORT = "SUPPORT",
}

export enum NotificationEventInput {
  AWARD_INFORMATION = "AWARD_INFORMATION",
  CHANGE = "CHANGE",
  NEW_DOCUMENT = "NEW_DOCUMENT",
  NEW_TENDER = "NEW_TENDER",
}

export enum OpportunitySearchSource {
  ALL_OPPORTUNITY_FILES = "ALL_OPPORTUNITY_FILES",
  MY_OPPORTUNITY_FILES = "MY_OPPORTUNITY_FILES",
  MY_WORKLIST = "MY_WORKLIST",
}

export enum OrgImgType {
  FAVICON = "FAVICON",
  LOGO = "LOGO",
}

export enum OrganizationNameType {
  abbreviation = "abbreviation",
  alias = "alias",
  alias_source = "alias_source",
  alternative = "alternative",
  logographic = "logographic",
  registered_name = "registered_name",
  trade_name = "trade_name",
}

export enum OrganizationUrlType {
  ABOUT_US = "ABOUT_US",
  BLOG = "BLOG",
  CERTIFICATIONS = "CERTIFICATIONS",
  CONTACT = "CONTACT",
  FACEBOOK = "FACEBOOK",
  GOOGLE_PLAY = "GOOGLE_PLAY",
  HOME = "HOME",
  INSTAGRAM = "INSTAGRAM",
  ITUNES = "ITUNES",
  LINKEDIN = "LINKEDIN",
  NEWS = "NEWS",
  PINTEREST = "PINTEREST",
  PRODUCTS = "PRODUCTS",
  PROJECTS = "PROJECTS",
  PURCHASINGPOLICY = "PURCHASINGPOLICY",
  TENDER = "TENDER",
  TWITTER = "TWITTER",
  VIMEO = "VIMEO",
  YOUTUBE = "YOUTUBE",
}

export enum PhonenumberType {
  FAX = "FAX",
  PHONE = "PHONE",
}

export enum Priority {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

/**
 * Allowed column names for Query.opportunityFilesWithResults.orderBy.
 */
export enum QueryOpportunityFilesWithResultsOrderByColumn {
  CREATED_AT = "CREATED_AT",
  YEAR_FROM = "YEAR_FROM",
  YEAR_TILL = "YEAR_TILL",
}

export enum ReminderEventInput {
  DEADLINE = "DEADLINE",
  FINAL_ENDDATE_CONTRACT = "FINAL_ENDDATE_CONTRACT",
  INITIAL_ENDDATE_CONTRACT = "INITIAL_ENDDATE_CONTRACT",
  NEXT_ENDDATE_CONTRACT = "NEXT_ENDDATE_CONTRACT",
  OPTIONS_ENDDATE_CONTRACT = "OPTIONS_ENDDATE_CONTRACT",
  STARTDATE_CONTRACT = "STARTDATE_CONTRACT",
}

export enum RemindersPreferenceMomentInput {
  AFTER = "AFTER",
  BEFORE = "BEFORE",
}

export enum RemindersPreferencePeriodInput {
  DAYS = "DAYS",
  MONTHS = "MONTHS",
  WEEKS = "WEEKS",
  YEARS = "YEARS",
}

export enum ResetPasswordStatus {
  PASSWORD_RESET = "PASSWORD_RESET",
}

export enum SearchDistributionMoment {
  AT_AWARD = "AT_AWARD",
  AT_CONTRACT_EXPIRATION = "AT_CONTRACT_EXPIRATION",
  AT_PUBLICATION = "AT_PUBLICATION",
}

/**
 * Directions for ordering a list of records.
 */
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TenderFilterSource {
  ALL_TENDERS = "ALL_TENDERS",
  MY_TENDERS = "MY_TENDERS",
  WORKLIST = "WORKLIST",
}

export enum TenderFilterTarget {
  ALL_DOCS = "ALL_DOCS",
  RELEVANT_DOCS = "RELEVANT_DOCS",
  SUMMARY = "SUMMARY",
}

export enum TenderStatus {
  AWARDED = "AWARDED",
  EXPIRED = "EXPIRED",
  OPEN = "OPEN",
  PARTICIPANTS_KNOWN = "PARTICIPANTS_KNOWN",
  PRELIMINARY = "PRELIMINARY",
  PRELIMINARY_AWARDED = "PRELIMINARY_AWARDED",
  PRELIMINARY_EXPIRED = "PRELIMINARY_EXPIRED",
  PROCUREMENT_DISCONTINUED = "PROCUREMENT_DISCONTINUED",
  PROCUREMENT_UNSUCCESSFUL = "PROCUREMENT_UNSUCCESSFUL",
}

export enum TenderUserStatus {
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
  DISLIKED = "DISLIKED",
  LIKED = "LIKED",
  UNREVIEWED = "UNREVIEWED",
}

export enum TenderersFilterSource {
  ALL_TENDERERS = "ALL_TENDERERS",
  MY_TENDERERS = "MY_TENDERERS",
}

export enum TwoFactorMethod {
  MAIL = "MAIL",
  TOTP = "TOTP",
}

export enum UpdatePasswordStatus {
  PASSWORD_UPDATED = "PASSWORD_UPDATED",
}

export enum vat {
  excluded = "excluded",
  included = "included",
  unknown = "unknown",
}

export interface AddressInput {
  postal_code: string;
  housenumber: string;
  housenumber_suffix?: string | null;
  country: string;
  city?: string | null;
  street?: string | null;
}

export interface BidInput {
  id?: string | null;
  isWinner?: boolean | null;
  isPreliminaryAwarded?: boolean | null;
  organisationId: number;
  lotId?: number | null;
  settlingId?: number | null;
  value?: ValueInput | null;
  minValue?: ValueInput | null;
  maxValue?: ValueInput | null;
  combinationId?: number | null;
  awardedAt?: any | null;
}

export interface ContractInput {
  source: SourceInput;
  description: string;
  initialDuration?: PeriodInput | null;
  undeterminedPeriod?: boolean | null;
  options?: (ContractOptionInput | null)[] | null;
}

export interface ContractOptionInput {
  amount?: number | null;
  length?: number | null;
  indefinite?: boolean | null;
  period?: ContractOptionPeriod | null;
}

export interface ContractingAuthoritiesInput {
  organisationId: number;
  category: ContractingAuthoritiesCategory;
  lot_id?: number | null;
}

export interface ContractingAuthoritiesModulePreferences {
  notifications?: (NotificationPreferenceInput | null)[] | null;
}

export interface ContractingAuthoritiesSearchInput {
  query?: string | null;
  source: ContractingAuthoritiesFilterSource;
  filters?: OrganizationsFilterInput | null;
}

export interface CropConfig {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface DateRange {
  from?: any | null;
  to?: any | null;
}

export interface DefaultViewPreferenceInput {
  grid?: GridInput | null;
  view?: GridViewInput | null;
}

export interface EmailInput {
  email: string;
  type: EmailType;
}

export interface ForgotPasswordInput {
  email: string;
  reset_password_url?: ResetPasswordUrlInput | null;
}

export interface IntRange {
  from?: number | null;
  to?: number | null;
}

export interface LotContractInput {
  lot: number;
  contract: ContractInput;
}

export interface LotInput {
  id?: string | null;
  number: string;
  title: string;
  description?: string | null;
  award_status?: LotAwardStatus | null;
}

export interface MarketPartiesModulePreferences {
  notifications?: (NotificationPreferenceInput | null)[] | null;
}

export interface NotificationPreferenceInput {
  event: NotificationEventInput;
  notification: boolean;
  email: boolean;
  emailInterval?: DistributionIntervalInput | null;
}

export interface OpportunityFilterInput {
  contracting_authorities?: number[] | null;
  contracting_authority_city?: string[] | null;
  contracting_authority_region?: string[] | null;
  contracting_authority_state?: string[] | null;
  contracting_authority_type?: number[] | null;
  document_last_reviewed?: DateRange | null;
  publication_date?: DateRange | null;
  file_type?: number[] | null;
  document_unreviewed_page_count?: number | null;
  year_from?: number[] | null;
  year_to?: IntRange | null;
  personal_opportunity_count?: number | null;
  personal_value?: IntRange | null;
  personal_year?: number[] | null;
  result_count?: number | null;
  searches?: (number | null)[] | null;
}

export interface OpportunitySearchInput {
  query?: string | null;
  filters?: OpportunityFilterInput | null;
}

/**
 * Allows ordering a list of records.
 */
export interface OrderByClause {
  column: string;
  order: SortOrder;
}

export interface OrganizationInput {
  name: string;
  kvknumber: string;
}

export interface OrganizationUrlInput {
  url: string;
  type: OrganizationUrlType;
}

export interface OrganizationsFilterInput {
  contractingAuthorityTypes?: number[] | null;
  country?: number[] | null;
  lastUpdate?: DateRange | null;
  name?: string | null;
  search?: number[] | null;
  sectors?: number[] | null;
  subSectors?: number[] | null;
  status?: number[] | null;
  opportunityFileCount?: number | null;
  tenderCount?: number | null;
}

export interface PeriodInput {
  value?: string | null;
  type?: string | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface PhonenumberInput {
  phonenumber: string;
  type: PhonenumberType;
}

export interface RemindersPreferenceInput {
  event: ReminderEventInput;
  notification: boolean;
  agenda: boolean;
  email: boolean;
  interval?: number | null;
  period?: RemindersPreferencePeriodInput | null;
  moment?: RemindersPreferenceMomentInput | null;
}

export interface ResetPasswordInput {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

/**
 * The url used to reset the password.
 * Use the `__EMAIL__` and `__TOKEN__` placeholders to inject the reset password email and token.
 * 
 * e.g; `https: // my-front-end.com?reset-password?email=__EMAIL__&token=__TOKEN__`
 */
export interface ResetPasswordUrlInput {
  url: string;
}

export interface ScopeInput {
  description?: string | null;
  source?: SourceInput | null;
  totalValue?: ValueInput | null;
  minValue?: ValueInput | null;
  maxValue?: ValueInput | null;
  lines?: (ScopeLineInput | null)[] | null;
}

export interface ScopeLineInput {
  lot_id?: number | null;
  unitValue?: number | null;
  minUnitValue?: number | null;
  maxUnitValue?: number | null;
  unit?: string | null;
  description?: string | null;
  contracting_authority_id?: number | null;
  value?: ValueInput | null;
  min_value?: ValueInput | null;
  max_value?: ValueInput | null;
  valueIsEstimated?: boolean | null;
  valueIsHistoric?: boolean | null;
  valueIsOptional?: boolean | null;
  period?: PeriodInput | null;
  source?: SourceInput | null;
  source_description?: string | null;
  children?: (ScopeLineInput | null)[] | null;
}

export interface SearchDistributionInput {
  in_app?: boolean | null;
  worklist: boolean;
  email_interval?: DistributionIntervalInput | null;
}

export interface SearchInput {
  query?: string | null;
  target: TenderFilterTarget;
  filters?: TenderFilterInput | null;
}

export interface SettlingInput {
  name: string;
  settlingnumber?: string | null;
  type: string;
  subnumber?: string | null;
  visit_street?: string | null;
  visit_housenumber?: string | null;
  visit_housenumbersuffix?: string | null;
  visit_postalcode?: string | null;
  visit_city?: string | null;
  visit_country?: string | null;
  postal_address?: string | null;
  postal_postalcode?: string | null;
  postal_city?: string | null;
  phonenumber?: string | null;
  faxnumber?: string | null;
  email?: string | null;
  actief?: number | null;
  status?: string | null;
}

export interface SourceInput {
  type: string;
  id?: number | null;
}

export interface TagGroup {
  tags: string[];
  lot_id?: number | null;
}

export interface TenderFilterInput {
  agenda?: DateRange | null;
  award_date?: DateRange | null;
  searches?: number[] | null;
  status?: number[] | null;
  country?: number[] | null;
  cpvs?: string[] | null;
  regions?: string[] | null;
  sectors?: number[] | null;
  subSectors?: number[] | null;
  type?: number[] | null;
  contractingAuthorities?: number[] | null;
  contractingAuthorityTypes?: number[] | null;
  contractors?: number[] | null;
  first_publication?: DateRange | null;
  deadline_inschrijving?: DateRange | null;
  startdate_contract?: DateRange | null;
  next_enddate_contract?: DateRange | null;
  enddate_contract?: DateRange | null;
  enddate_contract_incl_options?: DateRange | null;
  platform?: number[] | null;
  type_procedure?: number[] | null;
  reviews?: number[] | null;
  scopeMin?: number | null;
  scopeMax?: number | null;
  enddate_contract_quarters?: string[] | null;
  rated_date?: DateRange | null;
  updated_at?: DateRange | null;
  last_update_types?: number[] | null;
}

export interface TenderModulePreferences {
  notifications?: (NotificationPreferenceInput | null)[] | null;
  reminders?: (RemindersPreferenceInput | null)[] | null;
}

export interface TenderSearchInput {
  query?: string | null;
  source: TenderFilterSource;
  target: TenderFilterTarget;
  filters?: TenderFilterInput | null;
}

export interface TenderersSearchInput {
  query?: string | null;
  source: TenderersFilterSource;
  filters?: OrganizationsFilterInput | null;
}

export interface TextInput {
  source: SourceInput;
  lot?: number | null;
  value: string;
}

export interface UpdateDashboardColumnInput {
  id: string;
  name?: string | null;
  widgetId?: number | null;
}

export interface UpdateDashboardLayoutInput {
  rows: UpdateDashboardRowInput[];
}

export interface UpdateDashboardRowInput {
  id: string;
  order?: number | null;
  columns?: UpdateDashboardColumnInput[] | null;
}

export interface ValueInput {
  value?: string | null;
  currency?: string | null;
  vat?: vat | null;
  vat_percentage?: string | null;
}

export interface VerifyEmailInput {
  id: string;
  hash: string;
  expires?: number | null;
  signature?: string | null;
}

export interface WidgetsInProjectInput {
  summary: boolean;
  planning: boolean;
  scope: boolean;
  documents: boolean;
  contract: boolean;
  labels: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
