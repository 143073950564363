import React, { useState } from "react";

import { Typography, FormControlLabel, Checkbox } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useTranslation } from "react-i18next";
import { TenderModulePreferences } from "../../../__generated__/globalTypes";
import update from "immutability-helper";

interface Props {
    settings: TenderModulePreferences;
    saveSettings: (settings: TenderModulePreferences) => void;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const TenderCalendar: React.FC<Props> = ({ settings, saveSettings, setChanged }) => {
    const { t } = useTranslation();

    const blue = "#173357";
    const [hover, setHover] = useState<any | false>(false);

    return (
        <table style={{ padding: "0 16px", borderCollapse: "collapse" }}>
            <tr>
                <td>
                    <Typography variant="h5" style={{ display: "flex", alignItems: "center" }}>
                        <ThumbUpIcon style={{ color: blue, marginRight: 8, height: 20, width: 20 }} />
                        {t("dashboardPage.Agenda")}
                    </Typography>
                    <Typography>{t("Select_agenda_items")}</Typography>
                </td>
                <td style={{ width: 150 }}>
                    <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <EventAvailableIcon sx={{ color: "#707070" }} style={{ marginBottom: "4px" }} />
                        <Typography variant="h4">{t("faq.calendar")}</Typography>
                    </div>
                </td>
            </tr>
            <tr style={{ height: 16 }} />

            {/* New document added */}
            {settings.reminders?.map((reminder, i) => {
                return (
                    <tr
                        style={{ backgroundColor: hover === reminder?.event ? "#f0f0f0" : undefined }}
                        onMouseEnter={() => {
                            setHover(reminder?.event);
                        }}
                        onMouseLeave={() => {
                            setHover(false);
                        }}
                        key={reminder?.event}
                    >
                        <td style={{ width: "30vw", paddingLeft: "16px" }}>
                            <Typography style={{ fontSize: "1rem" }}>{t(reminder?.event as string)}</Typography>
                        </td>
                        <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <FormControlLabel
                                    sx={{ marginLeft: 0, marginRight: 0 }}
                                    control={
                                        <Checkbox
                                            disabled={true}
                                            checked={reminder?.agenda}
                                            onChange={(e) => {
                                                saveSettings(
                                                    update(settings, {
                                                        reminders: {
                                                            [i]: {
                                                                agenda: { $set: e.target.checked },
                                                            },
                                                        },
                                                    })
                                                );
                                                setChanged(true);
                                            }}
                                            name={reminder?.event}
                                        />
                                    }
                                    label=""
                                />
                            </div>
                        </td>
                    </tr>
                );
            })}
            <tr
                style={{ backgroundColor: hover === "startdate" ? "#f0f0f0" : undefined }}
                onMouseEnter={() => {
                    setHover("startdate");
                }}
                onMouseLeave={() => {
                    setHover(false);
                }}
            >
                <td style={{ width: "30vw", paddingLeft: "16px" }}>
                    <Typography style={{ fontSize: "1rem" }}>{t("STARTDATE_CONTRACT")}</Typography>
                </td>
                <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <FormControlLabel
                            sx={{ marginLeft: 0, marginRight: 0 }}
                            control={<Checkbox disabled={true} checked={true} name={"STARTDATE_CONTRACT"} />}
                            label=""
                        />
                    </div>
                </td>
            </tr>
        </table>
    );
};

export default TenderCalendar;
