import React from "react";
import { Card, CardContent, Divider, Typography, SvgIcon, Skeleton } from "@mui/material";

//icons used in this component
import { ReactComponent as pdfIcon } from "../assets/IconSet/pdf.svg";
import LanguageIcon from "@mui/icons-material/Language";

interface Props {
    title: string;
}

const DocumentCard: React.FC<Props> = ({ title }) => {
    return (
        <React.Fragment>
            <Card
                sx={{
                    minWidth: "300px",
                    maxWidth: "300px",
                    height: "250px",
                    margin: "8px",
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
                variant="elevation"
            >
                <CardContent sx={{ display: "flex", flexDirection: "column", paddingBottom: "16px !important", height: "100%" }}>
                    {/* skeleton lines to show a document outline */}
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "8px" }}>
                        <div>
                            <Skeleton animation={false} height={10} width="100%" style={{ marginBottom: "6px" }} />
                            <Skeleton animation={false} height={10} width="75%" style={{ marginBottom: "6px" }} />
                            <Skeleton animation={false} height={10} width="90%" style={{ marginBottom: "6px" }} />
                            <Skeleton animation={false} height={10} width="85%" style={{ marginBottom: "6px" }} />
                            <Skeleton animation={false} height={10} width="77%" style={{ marginBottom: "6px" }} />
                            <Skeleton animation={false} height={10} width="97%" style={{ marginBottom: "6px" }} />
                            <Skeleton animation={false} height={10} width="83%" style={{ marginBottom: "6px" }} />
                            <Skeleton animation={false} height={10} width="87%" style={{ marginBottom: "6px" }} />
                            <Skeleton animation={false} height={10} width="92%" style={{ marginBottom: "6px" }} />
                            <Skeleton animation={false} height={10} width="97%" style={{ marginBottom: "6px" }} />
                        </div>
                    </div>
                    <Divider />

                    {/*  document name with icon*/}
                    <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "8px", height: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", maxWidth: "100%", alignItems: "center" }}>
                            {/* if documentname ends with pdf, show pdf icon, if not show browsericon */}
                            {title.slice(title.length - 3) === "pdf" ? (
                                <SvgIcon component={pdfIcon} fontSize="small" sx={{ color: "#9e9e9e" }} />
                            ) : (
                                <LanguageIcon fontSize="small" sx={{ color: "#9e9e9e" }} />
                            )}
                            {/* Link */}
                            <Typography
                                noWrap
                                sx={{
                                    color: "#465c84",
                                    marginLeft: "16px",
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                            >
                                {title}
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default DocumentCard;
