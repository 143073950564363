import React from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Badge, Card, CardActionArea, CardContent, CardMedia, CardProps, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import { useAuthSettings } from "./AuthSettingsContext";

interface Props {
    disabled: boolean;
    icon: React.ReactNode;
    text: string;
    onClick?: () => void;
    avatarColor?: string;
    success: boolean;
    align?: string;
    option: string;
    wrongPW: boolean;
}

interface StyledCardProps extends CardProps {
    checked?: boolean; // Define the checked prop here
}

// Styled Paper component
const StyledCard = styled(Card)<StyledCardProps>(({ theme, checked }) => ({
    width: "345px",
    height: "300px",
    position: "relative",
    overflow: "visible",
    // Adding the "DEFAULT" label at the top center of the border
    ...(checked && {
        // Adding the "DEFAULT" label only when `isDefault` is true
        "&::before": {
            content: '"STANDAARD"', // Text to display
            position: "absolute",
            top: "-12px", // Position it slightly higher to fully show the text
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "white", // Background color to cut out the border
            padding: "0 8px",
            fontSize: "0.9rem",
            fontWeight: 500,
            color: "grey",
        },
    }),
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 32,
    height: 32,
    color: theme.colorPalette.green.light,
    backgroundColor: "#b1ddb3",
}));

const OptionCard: React.FC<Props> = ({
    disabled,
    children,
    icon,
    text,
    avatarColor = "#f2f2f2",
    onClick,
    success,
    align = "center",
    option,
    wrongPW,
}) => {
    const { data_two_factor } = useAuthSettings();

    return (
        <StyledCard
            checked={option === data_two_factor?.currentUser.two_factor_default_method}
            variant={"outlined"}
            onClick={() => {
                if (!disabled && onClick) {
                    onClick();
                }
            }}
        >
            <CardActionArea disabled={disabled} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <CardMedia sx={{ height: "140px", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "32px" }}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        badgeContent={
                            success && (
                                <SmallAvatar>
                                    <Check />
                                </SmallAvatar>
                            )
                        }
                    >
                        <Avatar sx={{ width: 96, height: 96, backgroundColor: avatarColor }}>{icon}</Avatar>
                    </Badge>
                </CardMedia>
                <Typography
                    textAlign="center"
                    variant="h5"
                    fontWeight={500}
                    sx={{
                        color: wrongPW ? `#c0392b` : "grey.500",
                        transition: "color 0.5s ease",
                    }}
                >
                    {text}
                </Typography>

                <CardContent sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: align, width: "100%", padding: "8px" }}>
                    {children}
                </CardContent>
            </CardActionArea>
        </StyledCard>
    );
};

export default OptionCard;
