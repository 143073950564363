import React, { useEffect } from "react";
import { Box, Card, Grid, Grow, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import "chartjs-plugin-datalabels";

import {
    BusinessCenter,
    Commute,
    EmojiNature,
    EventSeat,
    Fastfood,
    HomeWork,
    LocalGasStation,
    LocalHospital,
    LocalPrintshop,
    LocalShipping,
    RestoreFromTrash,
    SquareFoot,
    Tv,
    TwoWheeler,
} from "@mui/icons-material";
import { Doughnut } from "react-chartjs-2";
import { ReactComponent as Icon63 } from "../../assets/iconSectors/63_2.svg";
import { ReactComponent as Icon95 } from "../../assets/iconSectors/95.svg";
import { ReactComponent as Icon99 } from "../../assets/iconSectors/99.svg";
import { ReactComponent as Icon163 } from "../../assets/iconSectors/163.svg";
import { gql, useLazyQuery } from "@apollo/client";
import { TendenZ_Sectors, TendenZ_SectorsVariables } from "../../__generated__/TendenZ_Sectors";
import ChartDataLabels from "chartjs-plugin-datalabels";

interface Props {
    fetch: boolean;
    registerRef: any;
    refreshTrigger: any;
    eventID: string;
    type: "Webinar" | "Event";
}

const CircleGraph: React.FC<Props> = ({ fetch, registerRef, refreshTrigger, eventID, type }) => {
    const theme = useTheme();
    const maxAmount = 70;
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const size = noMobile ? window.innerWidth * 0.4 : window.innerWidth * 0.8;
    // Function to generate evenly spread positions for icons
    const getEvenPositions = (size: number, count: number) => {
        const positions = [];
        const radius = size / 2 - 20; // Adjust the radius as needed

        for (let i = 0; i < count; i++) {
            const angle = (i / count) * 2 * Math.PI - Math.PI / 2;
            const x = Math.cos(angle) * radius + size / 2;
            const y = Math.sin(angle) * radius + size / 2;
            positions.push({ top: y, left: x });
        }

        return positions;
    };

    /**
     * QUERY USERS OF ORGANIZATION
     */
    const [fetching, { data: dataGraph, loading }] = useLazyQuery<TendenZ_Sectors, TendenZ_SectorsVariables>(TENDENZ_SECTORS, {
        variables: {
            event_id: parseInt(eventID),
        },
        fetchPolicy: "network-only",
    });

    // Array with dynamic sizes based on amount
    const iconsData = [
        { id: "37", amount: 2, sector: "Overige zakelijke dienstverlening", icon: <BusinessCenter /> },
        { id: "47", amount: 4, sector: "Diensten in bouw, milieu en ruimte", icon: <SquareFoot /> },
        { id: "54", amount: 3, sector: "Groenvoorziening en boomverzorging", icon: <EmojiNature /> },
        { id: "62", amount: 6, sector: "Facilitair en aanverwant", icon: <Icon63 /> },
        { id: "67", amount: 5, sector: "Vervoer van personen", icon: <Commute /> },
        { id: "73", amount: 2, sector: "Horeca, gastronomie en evenementen", icon: <Fastfood /> },
        { id: "80", amount: 4, sector: "Grafimedia en aanverwant", icon: <LocalPrintshop /> },
        { id: "86", amount: 3, sector: "Afval en aanverwant", icon: <RestoreFromTrash /> },
        { id: "90", amount: 6, sector: "Kleding en uitrusting", icon: <Icon95 /> },
        { id: "96", amount: 8, sector: "Medisch en laboratorium", icon: <LocalHospital /> },
        { id: "103", amount: 2, sector: "Energie, water en brandstoffen", icon: <LocalGasStation /> },
        { id: "110", amount: 4, sector: "ICT en telecom", icon: <Tv /> },
        { id: "117", amount: 3, sector: "Arbeid en welzijn", icon: <Icon99 /> },
        { id: "124", amount: 6, sector: "Projectinrichting en kantoorsupplies", icon: <EventSeat /> },
        { id: "129", amount: 5, sector: "Bouw, installatie en gebouwenbeheer", icon: <HomeWork /> },
        { id: "146", amount: 2, sector: "Voer-, vaar- en vliegtuigen", icon: <TwoWheeler /> },
        { id: "154", amount: 4, sector: "Vervoer van goederen", icon: <LocalShipping /> },
        { id: "162", amount: 3, sector: "Grond-, Weg- en Waterbouw", icon: <Icon163 /> },
    ];

    const positions = getEvenPositions(size, iconsData.length);
    const renderPage = (children: React.ReactNode) => {
        return <div ref={registerRef}>{children}</div>;
    };

    useEffect(() => {
        if (type === "Event") {
            fetching();
        }
    }, [refreshTrigger, fetching, type]);

    /**
     * Amount of registrations
     */
    const count_reg = dataGraph?.TendenZSectorsWithCounts?.amountOfRegistrations || 0;
    /**
     * Free space
     */
    const freeSpace = maxAmount - count_reg;

    // Real data for the donut chart
    const data = {
        labels: loading ? ["", ""] : ["Aanmeldingen", "Vrije plekken"],

        datasets: [
            {
                label: "",
                data: [count_reg <= 15 ? 15 : count_reg, freeSpace],
                backgroundColor: ["#70a4b7bf", "#f4f4f4bf"],
                borderColor: ["#70a4b7", "#f4f4f4"],
                borderWidth: 1,
            },
        ],
    };

    return renderPage(
        <Box py={4} px={4} sx={{ display: "flex", flexDirection: "column", position: "relative", overflow: "hidden" }}>
            <Grid container justifyContent="center" alignItems="center" style={{}}>
                <div
                    style={{
                        position: "relative",
                        width: noMobile ? "40vw" : "80vw",
                        height: noMobile ? "40vw" : "80vw",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: noMobile ? "25vw" : "60vw",
                            height: noMobile ? "25vw" : "60vw",
                            position: "absolute",
                            borderRadius: "50%",
                            overflow: "hidden",
                        }}
                    >
                        <Doughnut
                            // import labels locally
                            plugins={[ChartDataLabels as any]}
                            data={data}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    title: {
                                        display: false,
                                        text: "not visible",
                                    },
                                    datalabels: {
                                        display: true,
                                        color: "black",
                                        // align: "center",
                                        padding: {
                                            right: 2,
                                        },
                                        labels: {
                                            value: {
                                                font: {
                                                    size: 20,
                                                },
                                                color: "black",
                                            },
                                        },
                                        // show labels in percentage instead of absolute values
                                        formatter: function (value, context) {
                                            if (context.chart.data.labels) {
                                                if (context.dataIndex === 1) {
                                                    return context.chart.data.labels[context.dataIndex];
                                                } else return "";
                                            } else return "";
                                        },
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: function (tooltipItem) {
                                                return ` ${tooltipItem.label}`;
                                            },
                                        },
                                    },
                                },
                            }}
                        />
                    </Box>
                    {!loading && (
                        <>
                            {positions.map((pos, index) => {
                                const icon = iconsData[index];
                                const iconToUse = dataGraph?.TendenZSectorsWithCounts?.sectors?.find((sector) => sector.sector.id === icon.id);

                                if (!iconToUse) {
                                    return null;
                                }

                                const fetchedAmount = iconToUse?.count ?? 0;
                                const amountToUse = fetchedAmount > 25 ? 25 : fetchedAmount;
                                const amountToUseMobile = fetchedAmount > 12 ? 12 : fetchedAmount;

                                const iconSize = noMobile ? amountToUse * 3 + 30 : amountToUseMobile * 3 + 20; // Example size calculation based on amount

                                return (
                                    <div
                                        key={index}
                                        style={{ position: "absolute", top: pos.top, left: pos.left, transform: "translate(-50%, -50%)" }}
                                    >
                                        <Grow key={index} in={fetch && !loading} timeout={500 * index}>
                                            <Tooltip
                                                title={
                                                    <Typography variant="body1" fontWeight={500}>
                                                        {icon.sector}
                                                    </Typography>
                                                }
                                                placement={index === 0 ? "top" : index > 0 && index <= 8 ? "right" : index === 9 ? "bottom" : "left"}
                                            >
                                                <Card
                                                    key={index}
                                                    sx={{
                                                        cursor: "pointer",
                                                        width: iconSize,
                                                        height: iconSize,
                                                        borderRadius: "20%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        transform: "translate(-50%, -50%)",
                                                        boxShadow: theme.shadows[3], // Optional: Adds a subtle shadow
                                                        "& svg": {
                                                            height: "75%",
                                                            width: "75%",
                                                            // color: "#70a4b7",
                                                            fill: "#70a4b7",
                                                        },
                                                    }}
                                                >
                                                    {icon.icon}
                                                </Card>
                                            </Tooltip>
                                        </Grow>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
            </Grid>
        </Box>
    );
};

export default CircleGraph;

// GET SECTORS IN REGISTRATIONS
export const TENDENZ_SECTORS = gql`
    query TendenZ_Sectors($event_id: Int!) {
        TendenZSectorsWithCounts(event_id: $event_id) {
            amountOfRegistrations
            sectors {
                count
                sector {
                    id
                }
            }
        }
    }
`;
