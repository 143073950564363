import React from "react";
import { useQuery } from "@apollo/client";
import { Tooltip, Typography } from "@mui/material";
import { FindInPage, FindInPageOutlined } from "@mui/icons-material";
import { GetSingleOppPersonalResultsData, GetSingleOppPersonalResultsDataVariables } from "../../__generated__/GetSingleOppPersonalResultsData";
import { QUERY_SINGLE_OPPORTUNITY_PERSONAL_RESULTS_DATA } from "../../graphql/opportunityQueries/queryDefinitions";

interface Props {
    opp_file_id: string;
}

const OppSearchruleMatches: React.FC<Props> = ({ opp_file_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetSingleOppPersonalResultsData, GetSingleOppPersonalResultsDataVariables>(
        QUERY_SINGLE_OPPORTUNITY_PERSONAL_RESULTS_DATA,
        {
            variables: { id: opp_file_id },
            fetchPolicy: "network-only",
        }
    );

    if (!data || !data.opportunityFile) {
        return <Typography>{""}</Typography>;
    }

    const opp = data.opportunityFile;

    const uniqueSearchIds = [...new Map(opp?.personalResults?.map((m) => [m?.search?.id, m])).values()];

    return (
        <div>
            {uniqueSearchIds.map((s: any) => {
                // If user has over 4 searchrules that match this opportunity, show a outlined tooltip with text "5 of meer zoekregels die matchen"
                if (uniqueSearchIds.length > 4) {
                    <Tooltip enterNextDelay={100} key={s.id} title={"5 of meer zoekregels die matchen"} placement="right">
                        <FindInPageOutlined style={{ color: "#225E4D" }} />
                    </Tooltip>;
                }
                return (
                    <Tooltip enterNextDelay={100} key={s.id} title={s.search.name as string} placement="right">
                        <FindInPage style={{ color: "#225E4D" }} />
                    </Tooltip>
                );
            })}
        </div>
    );
};

export default OppSearchruleMatches;
