import React, { Dispatch, SetStateAction, useEffect } from "react";
import { ListItemText, ListItemAvatar, Avatar, List, Divider, ListItemButton, Typography, Collapse, ListItemIcon } from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";
import { activeConversation } from "../dialogs/TenderNoteDialog";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Groups, Contacts } from "@mui/icons-material";
import { chatIsAlreadyInitiated, colorOfDialog, teamChatIsAlreadyInitiated } from "../../utils/messenger";
import { GetConversationGroup_conversationGroup_conversations_users } from "../../__generated__/GetConversationGroup";
import { UserSetting } from "../dialogs/DialogContent";
import UnreadMessagesCounter from "./UnreadMessagesCounter";
import LastMessageToShow, { QUERY_CONVERSATION_UNREAD_MESSAGES_COUNT } from "./LastMessageToShow";
import { useMutation } from "@apollo/client";
import { setChatMessageAsRead, setChatMessageAsReadVariables } from "../../__generated__/setChatMessageAsRead";
import { toast } from "react-toastify";
import { QUERY_ORG_STATE_FOR_USER } from "../../routes/organizations/detailPage/OrgTopBarModule";
import { QUERY_OPP_STATE_FOR_USER } from "../../routes/opportunities/detailpage/OppDetailTopBar";
import { QUERY_CA_STATE_FOR_USER } from "../../routes/contractingAuthoritiesModule/detailpage/CATopBarModule";
import { Link } from "react-router-dom";
import { QUERY_TENDER_STATE_FOR_USER } from "../../graphql/queryDefinitions";
import { MARKMESSAGEASREAD } from "../../graphql/queryDefChat";

export interface Team {
    teamId: string;
    teamName: string;
    members: Member[];
}

export interface Member {
    id: string;
    name: string;
    familyName: string;
}

export interface ConversationListItem {
    id: string;
    name: string;
    lastMessage: string;
    image?: string;
    team_id?: string;
    team_name?: string;
    private: boolean;
    userIds: string[];
    users: GetConversationGroup_conversationGroup_conversations_users[];
    unreadMessagesCount: number;
    lastMessageTime: any;
}

export interface ContactListItem {
    id: string;
    name: string;
    familyName: string;
}

interface Props {
    modelType: string;
    /**
     * string with id of selected chat
     */
    selectedConversation: activeConversation | null;
    /**
     * handler to select a conversation
     * @param conversation id of clicked conversation
     */
    selectConversation: Dispatch<SetStateAction<activeConversation | null>>;
    /**
     * list of all conversations
     */
    allConversations: ConversationListItem[];
    /**
     * All users in team(s)
     */
    contacts: ContactListItem[];
    /**
     *
     * @param userId id of clicked team member
     */
    onSelectNewContact: React.Dispatch<React.SetStateAction<UserSetting | undefined>>;
    teams: Team[];
    newSelected: UserSetting | undefined;
    setStaticLoading: (bln: boolean) => void;
}

const ConversationsList: React.FC<Props> = ({
    newSelected,
    setStaticLoading,
    selectedConversation,
    selectConversation,
    allConversations,
    contacts,
    onSelectNewContact,
    teams,
    modelType,
}) => {
    const [openContacts, setOpenContacts] = React.useState(false);
    const [openTeams, setOpenTeams] = React.useState(false);
    const [markMessageAsRead] = useMutation<setChatMessageAsRead, setChatMessageAsReadVariables>(MARKMESSAGEASREAD);

    /**
     * Handler to set message or conversation to read
     * @param messageID id of single message
     * @param conversationID id of whole conversation
     */
    const markAsRead = async (conversationID: string) => {
        try {
            await markMessageAsRead({
                variables: { conversation_id: conversationID },
                refetchQueries: [
                    QUERY_CONVERSATION_UNREAD_MESSAGES_COUNT,
                    "GetConversation_Unread_Messages_Counter",
                    QUERY_TENDER_STATE_FOR_USER,
                    "GetTenderStateForUser",
                    QUERY_ORG_STATE_FOR_USER,
                    QUERY_OPP_STATE_FOR_USER,
                    QUERY_CA_STATE_FOR_USER,
                ],
                // onCompleted: () => {
                //     toast.info(`in de try conv_id: ${varToUse.conversation_id} msg_id: ${varToUse.message_id}`, { autoClose: false });
                // },
            });
        } catch (e) {
            toast.error(`Er ging iets mis ${conversationID}`, { autoClose: false });
        }
    };

    /**
     * Handler to open/close list of colleagues
     */
    const handleClickContacts = () => {
        setOpenContacts(!openContacts);
    };

    /**
     * Handler to open/close list of teams
     */
    const handleClickTeams = () => {
        setOpenTeams(!openTeams);
    };

    /**
     * Set collapsable list to open when user has no active chats with colleagues
     */
    useEffect(() => {
        if (allConversations.length < 2) {
            setOpenTeams(true);
        }
    }, [allConversations.length]);

    return (
        <List disablePadding>
            {/*
             * Private user notes
             */}
            {allConversations
                .filter((conv) => conv.private)
                .map((conv) => {
                    return (
                        <React.Fragment key={conv.id}>
                            <ListItemButton
                                alignItems="flex-start"
                                selected={conv.id === selectedConversation?.id}
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: `${colorOfDialog(modelType)}14`,
                                        "&:hover": {
                                            backgroundColor: `${colorOfDialog(modelType)}1f`,
                                        },
                                    },
                                    "&:hover": {
                                        backgroundColor: `${colorOfDialog(modelType)}0a`,
                                    },
                                }}
                                onClick={() => {
                                    if (conv.id !== selectedConversation?.id) {
                                        markAsRead(conv.id);
                                        setStaticLoading(true);
                                        onSelectNewContact(undefined);
                                        selectConversation({ id: conv.id, private: conv.private });
                                    }
                                }}
                            >
                                {conv.team_id !== undefined ? (
                                    <ListItemAvatar>
                                        <Avatar>{conv.team_name?.match(/\b(\w)/g)?.slice(0, 2)}</Avatar>
                                    </ListItemAvatar>
                                ) : (
                                    <ListItemAvatar>
                                        <>
                                            {conv.private && (
                                                <Avatar>
                                                    <NotesIcon />
                                                </Avatar>
                                            )}
                                        </>
                                    </ListItemAvatar>
                                )}
                                <ListItemText primary={"Privé notities"} secondary={conv.lastMessage} secondaryTypographyProps={{ noWrap: true }} />
                            </ListItemButton>
                            <Divider component="li" />
                        </React.Fragment>
                    );
                })}

            {/*
             * All conversations
             */}
            {allConversations
                .filter((conv) => conv.private === false)
                .map((conv) => {
                    const conversationName = conv.team_id !== undefined ? conv.team_name : conv.name;
                    return (
                        <React.Fragment key={conv.id}>
                            <ListItemButton
                                alignItems="flex-start"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    "&.Mui-selected": {
                                        backgroundColor: `${colorOfDialog(modelType)}14`,
                                        "&:hover": {
                                            backgroundColor: `${colorOfDialog(modelType)}1f`,
                                        },
                                    },
                                    "&:hover": {
                                        backgroundColor: `${colorOfDialog(modelType)}0a`,
                                    },
                                }}
                                selected={conv.id === selectedConversation?.id}
                                onClick={() => {
                                    if (conv.id !== selectedConversation?.id) {
                                        markAsRead(conv.id);
                                        setStaticLoading(true);
                                        onSelectNewContact(undefined);
                                        selectConversation({ id: conv.id, private: conv.private });
                                    }
                                }}
                            >
                                {conv.team_id !== undefined ? (
                                    <ListItemAvatar>
                                        <Avatar>{conv.team_name?.match(/\b(\w)/g)?.slice(0, 2)}</Avatar>
                                    </ListItemAvatar>
                                ) : (
                                    <ListItemAvatar>
                                        <>
                                            {!conv.image && !conv.private && !conv.team_id && (
                                                <Avatar>{conv.name.match(/\b(\w)/g)?.slice(0, 2)}</Avatar>
                                            )}
                                            {conv.private && (
                                                <Avatar>
                                                    <NotesIcon />
                                                </Avatar>
                                            )}
                                        </>
                                    </ListItemAvatar>
                                )}
                                {/*
                                 * Shows team/user name and last message
                                 */}
                                <LastMessageToShow conv_id={conv.id} conv_name={conversationName as string} />

                                {/*
                                 * Counter to show amoun of unread messages for current user
                                 */}
                                <UnreadMessagesCounter modelType={modelType} conv_id={conv.id} />
                            </ListItemButton>
                            <Divider component="li" />
                        </React.Fragment>
                    );
                })}

            {/*****************************************
             **** TEAM SECTION
             *****************************************/}
            <ListItemButton onClick={teams.length > 0 ? handleClickTeams : undefined}>
                <ListItemIcon>
                    <Groups />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="caption">Teams</Typography>} />
                {teams.length > 0 ? (
                    <div>{openTeams ? <ExpandLess /> : <ExpandMore />}</div>
                ) : (
                    <Link to="/profile/users-teams" style={{ color: `${colorOfDialog(modelType)}b0` }}>
                        Team aanmaken
                    </Link>
                )}
            </ListItemButton>
            <Divider />
            <Collapse in={openTeams} timeout="auto" unmountOnExit>
                {/*
                 * Teamarray
                 */}
                {teams.map((team) => {
                    return (
                        <React.Fragment key={team.teamId}>
                            <ListItemButton
                                selected={newSelected?.action === "team" && team.teamId === newSelected.team?.teamId}
                                // sx={{ pl: 3, display: "flex", alignItems: "center" }}
                                sx={{
                                    pl: 3,
                                    display: "flex",
                                    alignItems: "center",
                                    "&.Mui-selected": {
                                        backgroundColor: `${colorOfDialog(modelType)}14`,
                                        "&:hover": {
                                            backgroundColor: `${colorOfDialog(modelType)}1f`,
                                        },
                                    },
                                    "&:hover": {
                                        backgroundColor: `${colorOfDialog(modelType)}0a`,
                                    },
                                }}
                                alignItems="flex-start"
                                onClick={() => {
                                    const idOfConversation = teamChatIsAlreadyInitiated(team.teamId, allConversations);

                                    if (idOfConversation !== undefined) {
                                        onSelectNewContact(undefined);
                                        markAsRead(idOfConversation);
                                        setStaticLoading(true);
                                        if (idOfConversation !== selectedConversation?.id) {
                                            selectConversation({ id: idOfConversation, private: false });
                                        }
                                    } else {
                                        setStaticLoading(true);
                                        onSelectNewContact({ team: team, action: "team" });
                                        selectConversation(null);
                                    }
                                }}
                            >
                                <ListItemAvatar sx={{ mt: 0, minWidth: 0, marginRight: "16px" }}>
                                    <Avatar sx={{ width: "32px", height: "32px" }}>
                                        <Typography>{team.teamName.match(/\b(\w)/g)?.slice(0, 2)}</Typography>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ margin: 0 }}
                                    secondaryTypographyProps={{ noWrap: true }}
                                    primary={`${team.teamName}`}
                                    secondary={<Typography variant="caption">{team.members.map((member) => member.name).join(", ")}</Typography>}
                                />
                            </ListItemButton>
                            <Divider component="li" />
                        </React.Fragment>
                    );
                })}
            </Collapse>

            {/*****************************************
             **** COLLEAGUE SECTION
             *****************************************/}
            <ListItemButton onClick={contacts.filter((i) => i.id !== localStorage.getItem("user_id")).length > 0 ? handleClickContacts : undefined}>
                <ListItemIcon>
                    <Contacts />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="caption">Gebruikers</Typography>} />
                {contacts.filter((i) => i.id !== localStorage.getItem("user_id")).length > 0 ? (
                    <div> {openContacts ? <ExpandLess /> : <ExpandMore />}</div>
                ) : (
                    <div>
                        <Link to="/profile/users-teams" style={{ color: `${colorOfDialog(modelType)}b0` }}>
                            Gebruiker aanmaken
                        </Link>
                    </div>
                )}
            </ListItemButton>
            <Divider />
            <Collapse in={openContacts} timeout="auto" unmountOnExit>
                {/*
                 * Contactlist of colleageas were no chat is started
                 */}
                {contacts
                    .filter((i) => i.id !== localStorage.getItem("user_id"))
                    .map((contact) => {
                        const username = `${contact.name}`;
                        return (
                            <React.Fragment key={contact.id}>
                                <ListItemButton
                                    selected={newSelected?.action === "user" && contact.id === newSelected?.user?.id}
                                    sx={{
                                        pl: 3,
                                        display: "flex",
                                        alignItems: "center",
                                        "&.Mui-selected": {
                                            backgroundColor: `${colorOfDialog(modelType)}14`,
                                            "&:hover": {
                                                backgroundColor: `${colorOfDialog(modelType)}1f`,
                                            },
                                        },
                                        "&:hover": {
                                            backgroundColor: `${colorOfDialog(modelType)}0a`,
                                        },
                                    }}
                                    alignItems="flex-start"
                                    onClick={() => {
                                        const idOfConversation = chatIsAlreadyInitiated(contact.id, allConversations);
                                        // if (idOfConversation !== selectedConversation?.id) {
                                        if (idOfConversation !== undefined) {
                                            markAsRead(idOfConversation);
                                            onSelectNewContact(undefined);
                                            setStaticLoading(true);
                                            if (idOfConversation !== selectedConversation?.id) {
                                                selectConversation({ id: idOfConversation, private: false });
                                            }
                                        } else {
                                            setStaticLoading(true);
                                            selectConversation(null);
                                            onSelectNewContact({ user: contact, action: "user" });
                                        }
                                        // }
                                    }}
                                >
                                    <ListItemAvatar sx={{ mt: 0, minWidth: 0, marginRight: "16px" }}>
                                        <Avatar sx={{ width: 32, height: 32 }}>
                                            <Typography>
                                                {contact.name?.slice(0, 1)}
                                                {contact.familyName?.slice(0, 1)}
                                            </Typography>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={username} />
                                </ListItemButton>
                                <Divider component="li" />
                            </React.Fragment>
                        );
                    })}
            </Collapse>
        </List>
    );
};

export default ConversationsList;
