import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

//MUI icons
import ListIcon from "@mui/icons-material/List";

interface Props {
    a?: number;
}

export interface ViewItem {
    id: string;
    view: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const ReviewViews: ViewItem[] = [
    {
        id: "1",
        view: "Lijstweergave",
        icon: ListIcon,
    },
    // {
    //     id: "2",
    //     view: "Kolomweergave",
    //     icon: ViewColumnIcon,
    // },
    // {
    //     id: "3",
    //     view: "Updateweergave",
    //     icon: AutorenewIcon,
    // },
    // {
    //     id: "5",
    //     view: "Kaartweergave",
    //     icon: MapIcon,
    // },
];

const OrganizationViewPreferences: React.FC<Props> = (props) => {
    // const { t } = useTranslation();
    const [reviewView, setReviewView] = useState<string>("1");

    return (
        <table style={{ width: "100%" }}>
            {/*
             * reviewed tenders view options
             */}
            <tr>
                <td style={{ paddingLeft: "16px", width: "30vw" }}>
                    <Typography style={{ fontSize: "1rem" }}>Marktpartijen met ster</Typography>
                </td>
                <td>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* Review view options */}
                        {ReviewViews.map(({ id, view, icon: Icon }) => {
                            // for each option create a card with name and icon of view
                            return (
                                <Card
                                    sx={{ marginRight: "16px", width: "140px" }}
                                    variant={"outlined"}
                                    key={id}
                                    onClick={() => setReviewView(id)}
                                    style={{
                                        border: reviewView === id ? "1px solid #B8B8B8" : undefined,
                                        backgroundColor: reviewView === id ? "#F5F5F5" : undefined,
                                    }}
                                >
                                    <CardActionArea style={{ height: "100%" }}>
                                        <CardContent
                                            sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {reviewView === id ? (
                                                    <div
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                            borderRadius: "48px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: "#e3e3e3",
                                                        }}
                                                    >
                                                        <Icon sx={{ color: "#707070" }} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                            borderRadius: "48px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Icon sx={{ color: "#707070" }} />
                                                    </div>
                                                )}
                                            </div>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                sx={{
                                                    color: reviewView === id ? "#000000" : undefined,
                                                }}
                                            >
                                                {view}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            );
                        })}
                    </div>
                </td>
            </tr>
        </table>
    );
};

export default OrganizationViewPreferences;
