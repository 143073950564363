import React from "react";

// Import Material UI
import { Badge, Grid } from "@mui/material";

// Import components
import StatusChip from "../../../components/StatusChip";
import LikeCABtn from "../../../components/LikeCABtn";
import ChatButtonTopBar from "../../../components/ChatButtonTopBar";
import { QUERY_USER_LIKED_CAS } from "../../../graphql/queryDefCurrentUser";
import { gql, useQuery } from "@apollo/client";
import { GetCAStateForUser } from "../../../__generated__/GetCAStateForUser";

interface Props {
    id: string;
    status: string | null;
    name: string | null;
}

// TODO: connect modal for chat, iconbutton 'Message'

const CATopBarModule: React.FC<Props> = ({ status, id, name }) => {
    const yellow = "#F57117";

    const { data } = useQuery<GetCAStateForUser>(QUERY_CA_STATE_FOR_USER, {
        variables: { id: id },
        fetchPolicy: "network-only",
    });

    return (
        <Grid container xs={12} md={12} justifyContent="space-between" alignItems="center" style={{ paddingTop: 10 }}>
            {/* Current status of the tender */}
            <Grid item sm={6} xs={6}>
                {/* <StatusChip type="actief" />
                <StatusChip type="opgeheven" />
                <StatusChip type="in oprichting" /> */}
                <StatusChip type={status} colored={true} />
            </Grid>

            {/* TenderActions, differnent based on list context */}
            <Grid item sm={6} xs={6}>
                <div className="flex right">
                    {/* Mark AD with star */}
                    <LikeCABtn org_id={id} buttonSize="medium" color={yellow} refetchQueries={[QUERY_USER_LIKED_CAS]} />

                    {/* Add/edit note of AD */}
                    <Badge
                        sx={{
                            ".MuiBadge-standard": {
                                maxWidth: "16px",
                                minWidth: "16px",
                                minHeight: "16px",
                                maxHeight: "16px",
                                fontSize: "0.6rem",
                                marginRight: "7px",
                                marginTop: "7px",
                            },
                        }}
                        badgeContent={data?.contracting_authority?.unreadChatsCount}
                        color="primary"
                        max={999}
                    >
                        <ChatButtonTopBar
                            id={id}
                            name={name || ""}
                            module={"App\\Models\\ContractingAuthorityV4"}
                            notesAvailable={Boolean(data?.contracting_authority?.hasConversations)}
                            location="Ca"
                        />
                    </Badge>
                    {/* TODO: v4.x inline update edit */}
                    {/* edit notification settings of the AD */}
                    {/* <Tooltip title={t("tenderDetail.edit-updates") as string} placement="top">
                        <IconButton onClick={() => setUpdatesModal((v) => !v)}>
                            <Autorenew fontSize="small" />
                        </IconButton>
                    </Tooltip> */}

                    {/* Dialog that show up when user clicks on edit updates */}
                    {/* <TenderUpdatesDialog open={updatesModal} handleClose={() => setUpdatesModal(false)} /> */}
                </div>
            </Grid>
        </Grid>
    );
};

export default CATopBarModule;

//? get all activities of current user
export const QUERY_CA_STATE_FOR_USER = gql`
    query GetCAStateForUser($id: ID!) {
        contracting_authority(id: $id) {
            hasConversations
            unreadChatsCount
        }
    }
`;
