import { gql } from "@apollo/client";

// ? Get all sidebar articles of current module
export const QUERY_SIDEBAR_ARTICLES_OF_CURRENT_MODULE = gql`
    query GetSideBarArticlesOfModule($module: String!) {
        getSidebarArticle(module: $module) {
            id
            active
            category
            content
            language
            module
            slug
            title
            top_item
        }
    }
`;

// ? get all articles sorted on module column
export const QUERY_GETALLARTICLES = gql`
    query getAllArticles {
        articles(orderBy: { column: "module", order: DESC }) {
            id
            title
            active
            category
            language
            module
            slug
            top_item
        }
    }
`;

// ? get single article (clicked item by user)
export const QUERY_GETSINGLEARTICLE = gql`
    query SingleArticle($id: ID!) {
        article(id: $id) {
            id
            title
            category
            content
            module
            slug
            top_item
            author {
                id
                name
                email
                givenname
                familyname
                linkedin
                picture
                function
                phonenumber
                organization {
                    id
                    name
                    logo
                }
            }
        }
    }
`;
