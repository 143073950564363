import React from "react";
import { Badge, Box, Card, Skeleton, Typography } from "@mui/material";
import { Groups, Notes, TaskAlt } from "@mui/icons-material";

interface Props {
    maxWidth: number;
    minWidth: number;
    staticMode: boolean;
}

const LoadingProjectCard: React.FC<Props> = ({ maxWidth, minWidth, staticMode }) => {
    return (
        <React.Fragment>
            <Card elevation={0} role="Box" style={{ display: "flex", flexDirection: "column", padding: "8px 8px 2px 8px", marginBottom: "8px" }}>
                <div>
                    <Typography variant="h6" sx={{ cursor: "pointer", display: "inline-flex" }}>
                        <Skeleton
                            animation={staticMode ? false : "wave"}
                            sx={{ width: staticMode ? maxWidth : `${Math.floor(Math.random() * (maxWidth - minWidth) + minWidth)}px` }}
                        />
                    </Typography>
                </div>
                <div style={{ height: "6px" }} />
                <Box
                    sx={{
                        color: "#9e9e9e",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginBottom: "4px",
                        marginTop: "12px",
                    }}
                >
                    <Badge
                        sx={{
                            cursor: "pointer",
                            marginRight: "12px",
                            "& .MuiBadge-badge": {
                                right: -2,
                                top: 2,
                                border: `2px solid #fff`,
                                padding: "0 4px",
                                fontSize: "9px",
                                minWidth: "18px",
                                height: "18px",
                            },
                        }}
                        badgeContent={0}
                        color="primary"
                    >
                        <Groups fontSize="small" />
                    </Badge>

                    <Badge
                        sx={{
                            cursor: "pointer",
                            marginRight: "12px",
                            "& .MuiBadge-badge": {
                                right: -2,
                                top: 2,
                                border: `2px solid #fff`,
                                padding: "0 4px",
                                fontSize: "9px",
                                minWidth: "18px",
                                height: "18px",
                            },
                        }}
                        badgeContent={0}
                        color="primary"
                    >
                        <TaskAlt fontSize="small" />
                    </Badge>
                    <Badge
                        sx={{
                            cursor: "pointer",
                            marginRight: "6px",
                            "& .MuiBadge-badge": {
                                right: -2,
                                top: 2,
                                border: `2px solid #fff`,
                                padding: "0 4px",
                                fontSize: "9px",
                                minWidth: "18px",
                                height: "18px",
                            },
                        }}
                        badgeContent={0}
                        color="primary"
                    >
                        <Notes fontSize="small" />
                    </Badge>
                </Box>
            </Card>
        </React.Fragment>
    );
};

export default LoadingProjectCard;
