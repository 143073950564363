import { ArrowDropDown, Close } from "@mui/icons-material";
import { Checkbox, IconButton, List, ListItemButton, ListItemText, Menu, MenuProps, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { ProjectToOpenContext, Widget } from "../../../../components/layout/MainLayout";
import { useMutation } from "@apollo/client";
import { UPDATE_WIDGETS_CU_IN_PROJECT } from "../../../../graphql/mutationDefProjects";
import {
    updateWidgetsToShowForCurrentUser,
    updateWidgetsToShowForCurrentUserVariables,
} from "../../../../__generated__/updateWidgetsToShowForCurrentUser";

interface Props {
    a?: number;
}

// Styled menu with anchors to show menu underneath button
const StyledMenu = (props: MenuProps) => (
    <Menu
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
);

const WidgetChanger: React.FC<Props> = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { project, changeProject } = useContext(ProjectToOpenContext);

    /**
     * @param event handler to open menu to change columns
     */
    const handleOpenMenuPopper = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    /**
     * handler to close menu
     */
    const handleCloseMenuPopper = () => {
        setAnchorEl(null);
    };

    // Change priority of task
    const [saveWidgetsToShow] = useMutation<updateWidgetsToShowForCurrentUser, updateWidgetsToShowForCurrentUserVariables>(
        UPDATE_WIDGETS_CU_IN_PROJECT
    );

    /**
     *
     */
    const handleChangeSingleWidget = (name: string, checked: boolean, event?: React.ChangeEvent<HTMLInputElement>) => {
        changeProject({
            ...project,
            widgets: {
                ...project.widgets,
                [name]: checked,
            },
        });

        const test = {
            ...project,
            widgets: {
                ...project.widgets,
                [name]: checked,
            },
        };

        onChangeWidgets(test.widgets);
    };

    /**
     * Update priority of task
     * @param taskId id of task
     * @param priority priority of task
     */
    const onChangeWidgets = async (widgets: Widget) => {
        try {
            await saveWidgetsToShow({
                variables: {
                    id: project.projectID,
                    widgets: widgets,
                },
            });
        } catch (e) {}
    };

    return (
        <React.Fragment>
            <Typography
                sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    paddingLeft: "4px",
                    marginLeft: "-4px",
                    fontSize: "1.1rem",

                    "&:hover": {
                        backgroundColor: "#ececec",
                        borderRadius: "4px",
                    },
                }}
                variant="h6"
                onClick={handleOpenMenuPopper}
            >
                Widgets <ArrowDropDown />
            </Typography>

            <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenuPopper}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "8px 16px 0px",
                    }}
                >
                    <Typography>Widgets wijzigen</Typography>

                    <IconButton size="small" onClick={handleCloseMenuPopper}>
                        <Close fontSize="small" />
                    </IconButton>
                </div>
                {/*
                 * List to add maxHeight with overflow to shorten the list
                 */}

                <List sx={{ maxHeight: 385, width: 275, overflow: "auto", bgcolor: "background.paper" }}>
                    {/* Summary */}
                    <ListItemButton onClick={() => handleChangeSingleWidget("summary", !project.widgets.summary)} role={undefined} dense>
                        <Checkbox name="summary" size="small" edge="start" checked={project.widgets.summary} tabIndex={-1} disableRipple />
                        <ListItemText primary={"Samenvatting"} />
                    </ListItemButton>
                    {/* Planning */}
                    <ListItemButton onClick={() => handleChangeSingleWidget("planning", !project.widgets.planning)} dense>
                        <Checkbox name="planning" size="small" edge="start" checked={project.widgets.planning} tabIndex={-1} disableRipple />
                        <ListItemText id="switch-list-label-wifi" primary={"Planning"} />
                    </ListItemButton>
                    {/* Documents */}
                    <ListItemButton onClick={() => handleChangeSingleWidget("documents", !project.widgets.documents)} dense>
                        <Checkbox name="documents" size="small" edge="start" checked={project.widgets.documents} tabIndex={-1} disableRipple />
                        <ListItemText id="switch-list-label-wifi" primary={"Documenten"} />
                    </ListItemButton>
                    {/* Contract */}
                    <ListItemButton onClick={() => handleChangeSingleWidget("contract", !project.widgets.contract)} dense>
                        <Checkbox name="contract" size="small" edge="start" checked={project.widgets.contract} tabIndex={-1} disableRipple />
                        <ListItemText id="switch-list-label-wifi" primary={"Contract"} />
                    </ListItemButton>
                    {/* Scope */}
                    <ListItemButton onClick={() => handleChangeSingleWidget("scope", !project.widgets.scope)} dense>
                        <Checkbox name="scope" size="small" edge="start" checked={project.widgets.scope} tabIndex={-1} disableRipple />
                        <ListItemText id="switch-list-label-wifi" primary={"Omvang"} />
                    </ListItemButton>
                    {/* Labels */}
                    <ListItemButton onClick={() => handleChangeSingleWidget("labels", !project.widgets.labels)} dense>
                        <Checkbox name="labels" size="small" edge="start" checked={project.widgets.labels} tabIndex={-1} disableRipple />
                        <ListItemText id="switch-list-label-wifi" primary={"Labels"} />
                    </ListItemButton>
                </List>
            </StyledMenu>
        </React.Fragment>
    );
};

export default WidgetChanger;
