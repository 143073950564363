import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";

// Components
import WidgetBox from "../../../components/WidgetBox";

// Material UI components
import { Box, List } from "@mui/material";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";

//Import momentjs
import moment from "moment";

//Import icon(s)
import FaviconBox from "../../../components/FaviconBox";
import { useLazyQuery } from "@apollo/client";
import { SortOrder, TenderFilterSource, TenderFilterTarget, TenderSearchInput } from "../../../__generated__/globalTypes";
import UpdateWidgetLoader from "../updateWidgets/UpdateWidgetLoader";
import UpdateWidgetError from "../updateWidgets/UpdateWidgetError";
import { GetFiveLatestTenderActivities, GetFiveLatestTenderActivitiesVariables } from "../../../__generated__/GetFiveLatestTenderActivities";
import Tooltip from "@mui/material/Tooltip";
import { Description, ThumbUp } from "@mui/icons-material";
import { QUERY_FIVE_LATEST_TENDER_ACTIVITIES } from "../../../graphql/queryDefinitions";

interface Props {
    a?: number;
}

const defaultSearch: TenderSearchInput = { target: TenderFilterTarget.SUMMARY, source: TenderFilterSource.MY_TENDERS, filters: { reviews: [1] } };
const variables: GetFiveLatestTenderActivitiesVariables = {
    page: 1,
    count: 5,
    orderField: "updated_at",
    order: SortOrder.DESC,
    search: defaultSearch,
};

const TenderUpdateWidget: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const blue = "#173357";
    const yellow = "#F57117";
    // const [tenderData, setTenderData] = useState<GetFiveLatestTenderActivities_filterTenders_results | null>();

    const { trackEvent } = useMatomo();

    // Track event
    const goToFullList = () => {
        trackEvent({ category: "Dashboard", action: "go-to-tender-updateview" });
    };

    const goToDetailPage = () => {
        trackEvent({ category: "Dashboard", action: "go-to-tender-detailpage-from-updatewidget" });
    };

    // Define the GQL query
    const [run, { data, loading, error }] = useLazyQuery<GetFiveLatestTenderActivities, GetFiveLatestTenderActivitiesVariables>(
        QUERY_FIVE_LATEST_TENDER_ACTIVITIES,
        {
            variables: variables,
            fetchPolicy: "network-only",
        }
    );

    // Set page title
    useEffect(() => {
        run({
            variables: variables,
        });
    }, [run]);

    const tenderData = data?.filterTenders?.results;

    // if loading -> show UpdateWidgetLoader with given attributes
    // TODO: change !loader to loader, temporary set to !loader to see loader UI
    if (loading) return <UpdateWidgetLoader color={blue} type={"tender"} />;

    // if error or empty -> show UpdateWidgetError component with given attributes
    if (error || !tenderData || tenderData.paginatorInfo.total === 0)
        return <UpdateWidgetError type={"tender"} errorText={t("tenders")} color={blue} />;

    return (
        <div id="update-tender-widget">
            <WidgetBox
                header={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <ThumbUp style={{ color: `${blue}`, marginRight: 4, height: 20 }} />
                            {t("dashboardPage.UpdatesInterestingTenders")}
                        </div>
                        <Typography>
                            <Link
                                id="link-to-full-list"
                                to="/tenders/reviews"
                                onClick={() => {
                                    // setview to eventview
                                    goToFullList();
                                }}
                            >
                                {" "}
                                {t("dashboardPage.ShowAll")}
                            </Link>
                        </Typography>
                    </div>
                }
                highlight="blue"
                padding={0}
            >
                <Box sx={{ width: "100%", height: { xs: "335px", md: "355px" }, position: "relative" }}>
                    {/* Underlaying icon with text  */}
                    <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}>
                        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            {<Description style={{ color: "#f2f2f270", height: "9rem", width: "9rem" }} />}
                            <Typography variant="h5" style={{ color: "#f2f2f2" }}>
                                {t("tenders")}
                            </Typography>
                        </div>
                    </div>
                    <List sx={{ padding: 0, zIndex: 99 }}>
                        {tenderData.data.map((row) => {
                            // Get ca with category 'A'
                            return (
                                <React.Fragment key={row.id}>
                                    <ListItem
                                        id="tender-list-item"
                                        sx={{
                                            paddingRight: "64px",
                                            paddingLeft: "8px",
                                            backgroundColor: "#ffffff",
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                            }}
                                        >
                                            <FaviconBox
                                                color={yellow}
                                                favicon={row.clientA[0]?.favicon_resized || row.clientA[0]?.favicon || null}
                                                name={row.clientA[0]?.name || row.published_opdrachtgever}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            sx={{
                                                "& .MuiListItemText-root": {
                                                    marginRight: "60px",
                                                },
                                            }}
                                            primary={
                                                <Typography noWrap>
                                                    <Tooltip
                                                        placement="bottom"
                                                        componentsProps={{ tooltip: { sx: { marginTop: "0px" } } }}
                                                        enterNextDelay={100}
                                                        title={row.type_update + ": " + row.namegiventocontract}
                                                    >
                                                        <Link id="tender-list-item-link" to={`/tenders/${row.id}`} onClick={goToDetailPage}>
                                                            {row.type_update}: {row.namegiventocontract}
                                                        </Link>
                                                    </Tooltip>
                                                </Typography>
                                            }
                                            secondary={<Typography noWrap>{row.clientA[0]?.name || row.published_opdrachtgever}</Typography>}
                                        />
                                        <ListItemSecondaryAction>
                                            {row.updated_at && (
                                                <React.Fragment>
                                                    <Typography align="right">
                                                        {moment(row.updated_at).isSame(moment(), "day")
                                                            ? moment(row.updated_at).format("LT")
                                                            : moment(row.updated_at).format("D MMM")}
                                                    </Typography>
                                                    <Typography align="right">
                                                        {moment(row.updated_at).isSame(moment(), "year") ? "" : moment(row.updated_at).format("YYYY")}
                                                    </Typography>
                                                </React.Fragment>
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Box>
            </WidgetBox>
        </div>
    );
};

export default TenderUpdateWidget;
