import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GetContractOfTenderOrLots, GetContractOfTenderOrLotsVariables } from "../../../__generated__/GetContractOfTenderOrLots";
import { GET_CONTRACT_OF_TENDER_OR_LOTS } from "../../../graphql/queryDefinitions";
interface Props {
    tender_id: string;
    mp?: string;
}
const SplitDetailMetaOptionalYears: React.FC<Props> = ({ tender_id, mp }) => {
    const { t } = useTranslation();
    /**
     * @param content Variable content to show
     * @returns Component
     */
    const renderPage = (content: React.ReactNode) => <div>{content}</div>;
    /**
     * Get dates
     */
    const { data, loading, error } = useQuery<GetContractOfTenderOrLots, GetContractOfTenderOrLotsVariables>(GET_CONTRACT_OF_TENDER_OR_LOTS, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });
    /**
     * loader
     */
    if (loading) {
        return renderPage(
            <div>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("tenderPage.Options")}
                </Typography>
            </div>
        );
    }
    /**
     * check if error occured or no data is available
     */
    if (data === undefined || data.tender === null || error) {
        return renderPage(
            <>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("tenderPage.Options")}
                </Typography>
                <Typography>-</Typography>
            </>
        );
    }
    /**
     * Shorter constant to use in component
     */
    const tndr = data.tender;
    /**
     * @param arr array to check for unique values
     * @param props string array that contains fields that have to be unique
     * @returns new array with unique values
     */
    const unique = (arr: any, props = [] as string[]) => [
        ...new Map(arr.map((entry: any) => [props.map((k) => entry[k]).join("|"), entry])).values(),
    ];
    const lotsToCheck = tndr.lots.map((lot) => lot.id);
    const getLotsOfCurrentMP =
        tndr.bids !== null && tndr.bids !== undefined
            ? tndr.bids.filter((bid) => lotsToCheck.includes(bid?.lot?.id as string)).filter((i) => i.marketParty.id === mp)
            : [];
    /**
     * Check if mp is given. if so => use mp bids only
     * Array with unique values based on "amount" & "length" & "indefinite"
     */
    const newArr =
        mp !== undefined
            ? (unique(
                  getLotsOfCurrentMP.map((i) => (i.lot?.contract ? i.lot.contract : "nope")),
                  ["amount", "length", "indefinite"]
              ) as any[])
            : (unique(
                  tndr.lots.map((i) => (i.contract ? i.contract : "nope")),
                  ["amount", "length", "indefinite"]
              ) as any[]);
    if (newArr.length === 0) {
        /**
         * Return default version where data of tender object will be used instead of lots object
         */
        return renderPage(
            <div>
                <Typography variant="h4" style={{}}>
                    {t("tenderPage.Options")}
                </Typography>
                <div style={{ display: "flex" }}>
                    <Typography style={{ marginRight: 4 }}>
                        {tndr.contract === null && "-"}
                        {tndr.contract !== undefined &&
                            tndr.contract?.options !== undefined &&
                            tndr.contract?.options.length > 0 &&
                            tndr.contract?.options.map((option, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        {option.indefinite === true ? (
                                            <>
                                                {t("tenderPage.indefiniteNumberOfOptionsFrom")} {option.length}{" "}
                                                {option.length === 1
                                                    ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                    : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                            </>
                                        ) : (
                                            <>
                                                {option.amount} x {option.length}{" "}
                                                {option.length === 1
                                                    ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                    : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                            </>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                    </Typography>
                </div>
            </div>
        );
    }
    /**
     * If tender has lots and start & end date => show lot version
     */
    if (tndr.lots.length > 0 && newArr.length === 1 && newArr[0] !== "nope" && newArr[0].options.length === 1) {
        const fltrdContract = newArr[0].options[0];
        return renderPage(
            <div>
                <Typography variant="h4" style={{}}>
                    {t("tenderPage.Options")}
                </Typography>
                <div style={{ display: "flex" }}>
                    <Typography style={{ marginRight: 4 }}>
                        <React.Fragment>
                            {fltrdContract.indefinite === true ? (
                                <>
                                    {t("tenderPage.indefiniteNumberOfOptionsFrom")} {fltrdContract.length}{" "}
                                    {fltrdContract.length === 1
                                        ? t(`singleContractPeriod.${fltrdContract.period?.toLocaleLowerCase() || ""}`)
                                        : t(`multiContractPeriod.${fltrdContract.period?.toLocaleLowerCase() || ""}`)}
                                </>
                            ) : (
                                <>
                                    {fltrdContract.amount} x {fltrdContract.length}{" "}
                                    {fltrdContract.length === 1
                                        ? t(`singleContractPeriod.${fltrdContract.period?.toLocaleLowerCase() || ""}`)
                                        : t(`multiContractPeriod.${fltrdContract.period?.toLocaleLowerCase() || ""}`)}
                                </>
                            )}
                        </React.Fragment>
                    </Typography>
                </div>
            </div>
        );
    }
    /**
     * Check if newArr has 0 options
     * then '-' will be the output
     */
    if ((tndr.lots.length > 0 && newArr[0] !== "nope" && newArr[0].options.length === 0) || tndr.lots.length === 0) {
        return renderPage(
            <>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("tenderPage.Options")}
                </Typography>
                <Typography>-</Typography>
            </>
        );
    }
    /**
     * If there are multiple unique options => show message
     */
    if (tndr.lots.length > 0 && newArr[0] !== "nope" && newArr[0].options.length > 1) {
        return renderPage(
            <>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("tenderPage.Options")}
                </Typography>
                <Typography>Meerdere opties van toepassing</Typography>
            </>
        );
    }
    /**
     * Return default version where data of tender object will be used instead of lots object
     */
    return renderPage(
        <div>
            <Typography variant="h4" style={{}}>
                {t("tenderPage.Options")}
            </Typography>
            <div style={{ display: "flex" }}>
                <Typography style={{ marginRight: 4 }}>
                    {tndr.contract === null && "-"}
                    {tndr.contract !== undefined &&
                        tndr.contract?.options !== undefined &&
                        tndr.contract?.options.length > 0 &&
                        tndr.contract?.options.map((option, i) => {
                            return (
                                <React.Fragment key={i}>
                                    {option.indefinite === true ? (
                                        <>
                                            {t("tenderPage.indefiniteNumberOfOptionsFrom")} {option.length}{" "}
                                            {option.length === 1
                                                ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                        </>
                                    ) : (
                                        <>
                                            {option.amount} x {option.length}{" "}
                                            {option.length === 1
                                                ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                        </>
                                    )}
                                </React.Fragment>
                            );
                        })}
                </Typography>
            </div>
        </div>
    );
};
export default SplitDetailMetaOptionalYears;
