import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Grid, Box, IconButton } from "@mui/material";
import { DragIndicator, Clear } from "@mui/icons-material";
import { GetDashboardLayout_GetDashboardLayout_rows } from "../../../__generated__/GetDashboardLayout";
import { useRowsContext } from "./RowsContext";

const ItemType = "DRAGCOLUMN";

interface DraggableColumnProps {
    id: string;
    rowIndex: number;
    index: number;
    moveColumn: (rowIndex: number, dragIndex: number, hoverIndex: number) => void;
    children: React.ReactNode;
    row: GetDashboardLayout_GetDashboardLayout_rows;
    onHoverChange: (bln: boolean) => void;
    isHovered: boolean;
    widgetId: number;
    editMode: boolean;
}

const DraggableColumn: React.FC<DraggableColumnProps> = ({
    id,
    rowIndex,
    index,
    moveColumn,
    children,
    row,
    onHoverChange,
    isHovered,
    widgetId,
    editMode,
}) => {
    const { removeColumn } = useRowsContext();
    const ref = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (item: { rowIndex: number; index: number }) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (item.rowIndex === rowIndex && dragIndex === hoverIndex) {
                return;
            }

            moveColumn(item.rowIndex, dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: ItemType,
        item: { id, rowIndex, index },
        canDrag: editMode,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.2 : 1;

    preview(drop(ref));

    return (
        <Grid
            ref={ref}
            item
            xs={12}
            sm={12 / row.columns.length}
            style={{
                opacity: opacity,
            }}
        >
            <Box
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    position: "relative",
                    margin: "5px",
                    backgroundColor: "#f4f4f4",
                }}
                onMouseEnter={() => onHoverChange(true)}
                onMouseLeave={() => onHoverChange(false)}
                bgcolor={isDragging ? "grey.300" : "white"}
            >
                {children}
                {isHovered && widgetId !== 1 && editMode && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(0, 0, 0, 0.2)",
                            color: "#fff",
                            padding: "5px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "6px",
                        }}
                    >
                        <Box
                            sx={{
                                width: "60px",
                                height: "60px",
                                backgroundColor: "#ffffff",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                marginRight: "16px",
                            }}
                        >
                            <IconButton ref={drag} size="large" disabled={row.columns.length === 1}>
                                <DragIndicator />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                width: "60px",
                                height: "60px",
                                backgroundColor: "#ffffff",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                            }}
                        >
                            <IconButton size="large" onClick={() => removeColumn(row.id, id.toString())}>
                                <Clear sx={{ color: isHovered ? "#c0392b" : "" }} />
                            </IconButton>
                        </Box>
                    </div>
                )}
            </Box>
        </Grid>
    );
};

export default DraggableColumn;
