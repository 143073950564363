// WordModalGuide

import React, { useLayoutEffect } from "react";
import { Typography, Button } from "@mui/material";
import {
    Business,
    Description,
    DesktopWindowsOutlined,
    Group,
    Today,
    TrendingUp,
    Send,
    LibraryBooks,
    AccountTree,
    Assistant,
} from "@mui/icons-material";
import GuideContent from "../elements/GuideContent";
import { useTour } from "../../contextProviders/TourContext";
import { useNavigate } from "react-router-dom";

interface Props {
    setTendenz?: () => void;
}

/**
 * @param step CurrentStep in tender searchwizard
 * @returns Returns current step as title and a button to open guide/tour
 */
const NewUserGuide: React.FC<Props> = ({ setTendenz }) => {
    const grey = "#707070";
    const navigate = useNavigate();
    // tour variables to set
    // locktour => true means item is interactive and no next/prev button will be shown
    const { setTourVariables, setOpenTour } = useTour();
    const createSearch = () => {
        navigate("/profile/search-rules");
        setTendenz && setTendenz();
    };

    /**
     * SPLIT IN 2 COMPONENTS
     * BUTTON
     * TOUR TO START
     */
    const steps = () => {
        return [
            /**
             * Step 1
             */
            {
                selector: '[data-tut="reactour__navbarmodules"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Navigeer naar module"
                            content={
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span>De verschillende modules binnen het platform</span>

                                    <div style={{ marginTop: 8 }}>
                                        {modules.map((module) => {
                                            return (
                                                <Typography
                                                    sx={{ display: "flex", marginBottom: "12px", flexDirection: "column" }}
                                                    key={module.module}
                                                >
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <module.icon sx={{ color: "#707070", marginRight: "8px" }} /> {module.module}
                                                    </div>
                                                    <span style={{ marginLeft: "4px" }}>{module.text}</span>
                                                </Typography>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        />
                    );
                },
                stepInteraction: false,
            },

            /**
             * Step 2
             */
            {
                selector: '[data-tut="reactour__navbarpersonalpages"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Persoonlijk menu"
                            content={
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ marginTop: 8 }}>
                                        {personalmenu.map((item) => {
                                            return (
                                                <Typography key={item.module} sx={{ display: "flex", marginBottom: "12px", flexDirection: "column" }}>
                                                    <span style={{ fontWeight: 500 }}>{item.module}</span>
                                                    <span style={{ marginLeft: "4px" }}>{item.text}</span>
                                                </Typography>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        />
                    );
                },
                stepInteraction: false,
            },

            /**
             * Step 3
             */
            {
                selector: '[data-tut="reactour__navbarhelpopen"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Help menu"
                            content={
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ marginTop: 8 }}>
                                        {support.map((sup) => {
                                            return (
                                                <Typography sx={{ display: "flex", marginBottom: "12px", flexDirection: "column" }} key={sup.module}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <sup.icon sx={{ color: "#707070", marginRight: "8px" }} />
                                                        <span style={{ fontWeight: 500 }}>{sup.module}</span>
                                                    </div>
                                                    <span style={{ marginLeft: "4px" }}>{sup.text}</span>
                                                </Typography>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        />
                    );
                },
                stepInteraction: false,
            },

            /**
             * Step 4
             */
            {
                selector: '[data-tut="reactour__navbar_notifications"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Meldingen"
                            content={
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span>Hier vind je meldingen gericht aan jou</span>
                                </div>
                            }
                        />
                    );
                },
                stepInteraction: false,
            },
            /**
             * Step 5
             */
            {
                content: () => {
                    return (
                        <GuideContent
                            title="Nog geen zoekregel?"
                            content={
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <br />
                                    {/* <span>Nog geen zoekregel?</span> <br /> */}
                                    <Button variant="contained" onClick={createSearch}>
                                        Zoekregel maken
                                    </Button>
                                </div>
                            }
                        />
                    );
                },
                stepInteraction: false,
            },
        ];
    };

    /**
     * Check if user has searchrules and domainModalTourOpen = false
     * true => open tour on default for current step
     */
    useLayoutEffect(() => {
        // if (user is new) {
        // timeout for smoother transition
        setTimeout(() => {
            setTourVariables(steps, grey, `newUserGuide`);
            setOpenTour(true);
        }, 450);

        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};

export default NewUserGuide;

// Array with menu items (top)
const modules = [
    {
        icon: DesktopWindowsOutlined,
        module: "Dashboard",
        text: "Diverse widgets met informatie voor jou op maat",
    },
    {
        icon: AccountTree,
        module: "Projecten",
        text: "Overzicht van projecten waar je betrokken bij bent",
    },
    {
        icon: Today,
        module: "Agenda",
        text: "Alle belangrijke data in jouw agenda",
    },

    {
        icon: Group,
        module: "Marktpartijen",
        text: "Volg de marktpartij van jouw keuze",
    },
    {
        icon: Description,
        module: "Aanbestedingen",
        text: "Hier vind je alle nieuwe en historische aanbestedingen",
    },
    {
        icon: TrendingUp,
        module: "Kansen",
        text: "Hier vind je begrotingen van Nederlandse overheidsorganisaties",
    },
    {
        icon: Business,
        module: "Aanbestedende diensten",
        text: "Volg de aanbestedende dienst van jouw keuze",
    },
];

// Array with menu items (bottom)
const personalmenu = [
    {
        module: "Persoonlijk",
        text: "Wijzig jouw gegevens",
    },
    {
        module: "Gebruikers & teams",
        text: "Alle gebruikers en jouw teams",
    },
    {
        module: "Zoekregels",
        text: "Hier stel je zoekregels in om aanbestedingen en kansen te ontvangen",
    },
    {
        module: "Voorkeuren",
        text: "Wijzig jouw voorkeuren voor de verschillende modules",
    },
    {
        module: "Logboek",
        text: "Hier vind je al jouw activiteiten terug",
    },
];

// Array with support items
const support = [
    { icon: Assistant, module: "Interactieve assistent", text: "Activeer deze uitleg op ieder gewenst moment" },
    { icon: LibraryBooks, module: "Kennisbank", text: "Hier vind je meer uitleg over functionaliteiten" },
    { icon: Send, module: "Bericht sturen", text: "Stuur jouw bericht aan support" },
    { icon: DesktopWindowsOutlined, module: "Schermdeelsessie", text: "Vraag ondersteuning op afstand aan" },
];
