import React from "react";
import { Link, ListItemButton, ListItemText, Typography } from "@mui/material";

interface Props {
    titleParts: { text: string; highlight: boolean }[];
    secondary: string;
    category: string | null;
    onItemClick(): void;
}

const KnowledgeBaseSearchItem: React.FC<Props> = ({ titleParts, secondary, category, onItemClick }) => {
    // const greyColor = "#9e9e9e";
    const greyColor2 = "#707070";

    // TODO: show icon when searchresult is a category instead of a article

    return (
        <ListItemButton
            style={{ width: "100%", display: "flex", alignItems: "center", margin: 0 }}
            onClick={(e) => {
                e.stopPropagation();
                onItemClick();
            }}
        >
            {/* icon matches filetype */}
            {/* <ListItemAvatar style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 0, marginLeft: "-16px" }}>
                    <DescriptionIcon style={{ color: `${greyColor}`, margin: 0 }} />
                </ListItemAvatar> */}

            {/* document title */}
            <ListItemText
                style={{ paddingRight: "16px" }}
                primary={
                    <Link
                        underline="none"
                        sx={{
                            "&:hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onItemClick();
                        }}
                    >
                        <Typography noWrap>
                            {titleParts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                        </Typography>
                    </Link>
                }
                // document fragment with matching word
                secondary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography noWrap style={{ display: "inline-block", width: "92%", color: `${greyColor2}`, fontSize: "0.8rem" }}>
                            {secondary}
                        </Typography>
                    </div>
                }
            />

            {/* document date
            <ListItemSecondaryAction
                style={{ marginRight: "-16px" }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {moment(date).isSame(moment(), "year") ? (
                    //current year date notation
                    <Typography style={{ textTransform: "uppercase", fontSize: "0.8rem" }}>{moment(date).format("DD MMM")}</Typography>
                ) : (
                    //previous or next year date notation
                    <React.Fragment>
                        <Typography style={{ textTransform: "uppercase", fontSize: "0.8rem" }}>{moment(date).format("DD MMM")}</Typography>

                        <Typography style={{ textTransform: "uppercase", textAlign: "right", fontSize: "0.8rem" }}>
                            {moment(date).format("YYYY")}
                        </Typography>
                    </React.Fragment>
                )}
            </ListItemSecondaryAction> */}
        </ListItemButton>
    );
};

export default KnowledgeBaseSearchItem;
