import React from "react";
import { GetTenderDetailSplitview_tender } from "../../../__generated__/GetTenderDetailSplitview";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Euro, Schedule } from "@mui/icons-material";
import SectorIconLoader from "../../../components/boxes/SectorIconLoader";
import SplitMetaStartEndDateWrapper from "./SplitMetaStartEndDateWrapper";
import SplitMetaOptionsWrapper from "./SplitMetaOptionsWrapper";
import SplitMetaValuePerBid from "./SplitMetaValuePerBid";
import { getRelativeTimeInDays } from "../../../utils/relativeTimeInDays";
import { currencyFormat } from "../../../utils/currencyFormat";

interface Props {
    selectedItem: GetTenderDetailSplitview_tender;
    mp?: string;
}

const SplitDetailViewMeta: React.FC<Props> = ({ selectedItem, mp }) => {
    const { t } = useTranslation();
    /**
     * get unique sector(s)
     */
    const uniqueParentIds = _.uniqBy(selectedItem?.sectors, function (e) {
        return e?.parent?.id;
    });

    return (
        //  *{/*
        //  * Bottom box
        //  * Contains all meta data
        //  */}
        <Grid item sx={{ marginTop: "24px", height: "160px" }} container>
            <Grid item container xs={12} style={{ height: "80px", marginBottom: "8px" }}>
                <Grid item xs={4} sx={{ height: "100%", display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                    {/* 
                    //* Publication date 
                    */}
                    <div>
                        <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                            <Schedule fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> Deadline
                        </Typography>
                        {/* if deadline is available -> show date and relative time */}
                        {selectedItem.deadline_inschrijving && (
                            <React.Fragment>
                                <Typography>{moment(selectedItem.deadline_inschrijving).format("L LT")}</Typography>
                                <Typography>
                                    {getRelativeTimeInDays(selectedItem.deadline_inschrijving, t).show && (
                                        <>({getRelativeTimeInDays(selectedItem.deadline_inschrijving, t).date})</>
                                    )}
                                </Typography>
                            </React.Fragment>
                        )}
                    </div>
                </Grid>
                <Grid item xs={4} sx={{ height: "100%", display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                    {/* 
                    //* start & end date 
                    */}
                    <SplitMetaStartEndDateWrapper tender_id={selectedItem.id} mp={mp} />
                </Grid>
                <Grid item xs={4} sx={{ height: "100%", display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                    {/* 
                    //* Options 
                    */}
                    <SplitMetaOptionsWrapper tender_id={selectedItem.id} mp={mp} />
                </Grid>
            </Grid>

            {/*
             //*  Second grid row
             */}
            <Grid item container xs={12} style={{ height: "80px", padding: "8px 0px" }}>
                {/*
                //* Publication date 
                */}
                <Grid item xs={4} sx={{ height: "100%", display: "flex", alignItems: "flex-start" }}>
                    {selectedItem.status !== "AWARDED" ? (
                        <div>
                            <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                {t("tenderPage.FirstPublication")}
                            </Typography>
                            <Typography>{moment(selectedItem.first_dispatchdate ? selectedItem.first_dispatchdate : "").format("L")}</Typography>
                        </div>
                    ) : (
                        <div style={{ height: "100%", overflow: "auto" }}>
                            <Typography variant="h4" style={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
                                <Euro fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> {t("tenderPage.FinalValue")}
                            </Typography>
                            <div>
                                <SplitMetaValuePerBid tender_id={selectedItem.id} />
                            </div>
                        </div>
                    )}
                </Grid>
                {/* 
                //* Scope 
                */}
                <Grid item xs={4} sx={{ height: "100%", display: "flex", alignItems: "flex-start" }}>
                    {selectedItem.scope !== null ? (
                        <div style={{ marginBottom: "16px" }}>
                            {selectedItem?.scope?.total !== null || (selectedItem?.scope?.total !== null && selectedItem?.scope?.total !== 0) ? (
                                <>
                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        <Euro fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> {t("tenderPage.TotalExpectedValue")}
                                    </Typography>
                                    <Typography>
                                        {currencyFormat(selectedItem?.scope?.total.currency)}
                                        {selectedItem?.scope?.total?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                    </Typography>
                                </>
                            ) : selectedItem.scope.max !== null && selectedItem.scope.min !== null ? (
                                <>
                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        <Euro fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> {t("tenderPage.ExpectedValue")}
                                    </Typography>
                                    <Typography>
                                        {currencyFormat(selectedItem?.scope?.min.currency)}
                                        {selectedItem?.scope?.min?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")} -{" "}
                                        {currencyFormat(selectedItem?.scope?.max.currency)}
                                        {selectedItem?.scope?.max?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                    </Typography>
                                </>
                            ) : selectedItem.scope.max !== null ? (
                                <>
                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        <Euro fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> {t("tenderPage.MaximumExpectedValue")}
                                    </Typography>
                                    <Typography>
                                        {currencyFormat(selectedItem?.scope?.max.currency)}
                                        {selectedItem?.scope?.max?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                    </Typography>
                                </>
                            ) : selectedItem.scope.min !== null ? (
                                <>
                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        <Euro fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> {t("tenderPage.MinimumExpectedValue")}
                                    </Typography>
                                    <Typography>
                                        {currencyFormat(selectedItem?.scope?.min.currency)}
                                        {selectedItem?.scope?.min?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                    </Typography>
                                </>
                            ) : (
                                <div style={{ marginBottom: "16px" }}>
                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        <Euro fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> {t("tenderPage.GeraamdeWaarde")}
                                    </Typography>
                                    <Typography>-</Typography>{" "}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div style={{ marginBottom: "16px" }}>
                            <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                <Euro fontSize="small" sx={{ marginRight: "4px", color: "#9e9e9e" }} /> {t("tenderPage.GeraamdeWaarde")}
                            </Typography>
                            <Typography>-</Typography>{" "}
                        </div>
                    )}
                </Grid>
                {/* 
                //* Subsectors 
                */}
                <Grid
                    item
                    xs={4}
                    sx={{ height: "100%", display: "flex", alignItems: "flex-start" }}
                    style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}
                >
                    <div>
                        {/* 
                        //* subsectors or sectors based on country (528 = nl) of tender 
                        */}
                        {selectedItem.country !== null && selectedItem.country.numeric === "528" ? (
                            <div style={{ marginBottom: "16px", height: "100%", overflow: "auto" }}>
                                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    {selectedItem.sectors.length > 1 ? t("subsectors") : t("subsector")}
                                </Typography>
                                {/* 
                                //* map subsectors 
                                */}
                                {selectedItem.sectors.map((s) => (
                                    <div key={s.id} style={{ display: "flex", alignItems: "center" }}>
                                        <SectorIconLoader sector_id={s.id} />
                                        <Typography style={{ marginLeft: 8 }}>{s.name}</Typography>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={{ marginBottom: "16px", height: "100%", overflow: "auto" }}>
                                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    {uniqueParentIds.length > 1 ? t("HeadSectors") : t("HeadSector")}
                                </Typography>

                                {/* 
                                //* map sectors 
                                */}
                                {uniqueParentIds.map((s) => {
                                    return (
                                        <div key={s.id} style={{ display: "flex", alignItems: "center" }}>
                                            <Typography>{s.parent?.name}</Typography>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SplitDetailViewMeta;
