import React, { useState } from "react";
import { Grid, Typography, Box, Stepper, Step, StepLabel, StepContent, Button, StepIconProps, styled, StepConnector } from "@mui/material";
import { useTour } from "../../../components/contextProviders/TourContext";
import { UserInTeam } from "../UserAndTeams/UsersAndTeams";
import CopySearchlineToUser from "./CopySearchlineToUser";
import ShareSearchlineWithUser from "./ShareSearchlineWithUser";
import { Disable } from "react-disable";
import TenderDistSummary from "./TenderDistSummary";
import { Create, FactCheck, LocalOffer, Share, Today } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { getColors } from "../../../__generated__/getColors";
import { useTenderSearchContext } from "../../../components/guides/tenderWizard/WizardContext";
import Name from "./stepsDist/Name";
import Color from "./stepsDist/Color";
import Distribution from "./stepsDist/Distribution";

interface Props {
    users: UserInTeam[];
    editRule: boolean;
    isAdmin: boolean;
}

// activity trigger chipoptions
export const chipTrigger = [{ value: "AT_PUBLICATION" }, { value: "AT_AWARD" }, { value: "AT_CONTRACT_EXPIRATION" }];

const TenderDist: React.FC<Props> = ({ users, editRule, isAdmin }) => {
    const {
        chosenWords,
        domain,
        filters,
        name,
        moment,
        dist,
        copyIDS,
        shareIDS,
        color,
        idsToDelete,
        setIdsToDelete,
        setName,
        setMoment,
        setDist,
        copySearchTo,
        shareSearchWith,
        setColor,
    } = useTenderSearchContext(); // Use the context hook to access context value and function
    const { currentTour, setCurrentStep, setLockTour } = useTour();
    const [activeStep, setActiveStep] = useState(name !== "" && moment !== null && moment !== undefined ? steps.length - 1 : 0);
    const [removeMailSetting, setRemoveMailSetting] = useState<boolean>(false);
    const [optionIsChosen, setChosenOption] = useState<boolean>(true);
    const [sharedByDefault] = useState(shareIDS);

    const { data } = useQuery<getColors>(COLORS, {
        fetchPolicy: "network-only",
    });

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    /**
     * @param value user id
     * @returns return checked or unchecked state of user
     */
    const handleToggleShare = (e: any, value: number) => {
        e.stopPropagation();
        const currentIndex = shareIDS.indexOf(value);
        const newChecked = [...shareIDS];
        let newIdsToDelete = [...idsToDelete]; // Copy the existing idsToDelete array

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        // If the clicked ID is in the sharedByDefault state, add it to idsToDelete
        if (sharedByDefault.includes(value)) {
            if (!newIdsToDelete.includes(value)) {
                newIdsToDelete.push(value);
            } else {
                // Remove the ID from idsToDelete
                newIdsToDelete = newIdsToDelete.filter((id) => id !== value);
            }
        }

        // setCheckedUsersToShare(newChecked);
        shareSearchWith(newChecked);

        // Update the idsToDelete state
        setIdsToDelete(newIdsToDelete);
    };

    /**
     * @param value user id
     * @returns return checked or unchecked state of user
     */
    const handleToggleCopy = (e: any, value: number) => {
        e.stopPropagation();
        const currentIndex = copyIDS.indexOf(value);
        const newChecked = [...copyIDS];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        // setCheckedUsersToCopy(newChecked);
        copySearchTo(newChecked);
    };

    const ColorlibStepIconRoot = styled("div")<{
        ownerState: { completed?: boolean; active?: boolean; error?: boolean };
    }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        ...(ownerState.active && {
            background: "#465c84",
            backgroundImage: "linear-gradient( 136deg, #465c84 0%,#173357 90%, #000b2e 100%)",
            boxShadow: "0 4px 10px 0 rgba(0,0,0,.1)",
            color: "#fff",
        }),
        ...(ownerState.completed && {
            background: "#ffffff",
            backgroundImage: "linear-gradient( 136deg, #465c84 0%,#173357 40%, #000b2e 100%)",
            boxShadow: "0 4px 10px 0 rgba(0,0,0,.1)",
        }),
        ...(ownerState.error && {
            background: "#c0392b",
        }),
    }));

    const ColorlibStepIcon = (props: StepIconProps) => {
        const { active, completed, className } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <Create />,
            2: <LocalOffer />,
            3: <Today />,
            4: <Share />,
            5: <FactCheck />,
        };

        return (
            <ColorlibStepIconRoot
                ownerState={{ completed, active }}
                className={className}
                sx={{
                    cursor: "pointer",
                }}
            >
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    };
    const active = {
        marginLeft: "17px",
        borderLeft: "3px solid #ccc",
    };

    const done = {
        marginLeft: "17px",
        borderLeft: "3px solid #173357",
    };

    const ConnectorMaker = () => {
        let index = 0;
        return (props: any) => {
            index++;
            return <StepConnector {...props} style={index - 1 > activeStep ? active : done} />;
        };
    };

    const Connector = ConnectorMaker();

    return (
        <Grid
            sx={{
                height: "70vh",
                display: "flex",
                flexDirection: "column",
                width: {
                    xs: "90vw",
                    xxl: "70vw",
                },
            }}
        >
            <Grid
                item
                xs={12}
                style={{ display: "flex", flexDirection: "column", alignItems: "center", flexBasis: "100%" }}
                data-tut="distribution_container"
            >
                <Box sx={{ width: "90%", marginBottom: "64px" }}>
                    <Stepper
                        activeStep={activeStep}
                        connector={<Connector />}
                        orientation="vertical"
                        sx={{
                            "& .MuiStepContent-root": {
                                marginLeft: "17px",
                                borderLeft: "3px solid",
                                borderImage: `linear-gradient(
                                    to bottom, 
                                    #17335733,
                                    #173357
                                  ) 0 0 0 1`,
                            },

                            "& .MuiStepConnector-line": {
                                borderLeft: "0px solid white",
                            },
                        }}
                    >
                        {steps.map((step, index) => (
                            <Step
                                key={step.label}
                                data-tut={
                                    index === 0
                                        ? "reactour__distTitle"
                                        : index === 1
                                        ? "reactour__distColor"
                                        : index === 3
                                        ? "reactour__distShareCopy"
                                        : ""
                                }
                            >
                                <StepLabel
                                    onClick={() => {
                                        setActiveStep(index);
                                    }}
                                    StepIconComponent={ColorlibStepIcon}
                                    optional={index === activeStep && index === 3 ? <Typography variant="caption">Optioneel</Typography> : null}
                                >
                                    <span style={{ fontSize: "1rem" }}>{index === activeStep && step.label}</span>
                                </StepLabel>

                                <StepContent>
                                    <Typography>{step.description}</Typography>
                                    {index === 0 ? (
                                        <Name name={name} setName={setName} />
                                    ) : index === 1 ? (
                                        <Color color={color} setColor={setColor} colors={data?.currentUser.searches} />
                                    ) : index === 2 ? (
                                        <Distribution
                                            moment={moment}
                                            setMoment={setMoment}
                                            dist={dist}
                                            setDist={setDist}
                                            optionIsChosen={optionIsChosen}
                                            setChosenOption={setChosenOption}
                                            removeMailSetting={removeMailSetting}
                                            setRemoveMailSetting={setRemoveMailSetting}
                                            index={2}
                                        />
                                    ) : index === 3 ? (
                                        <div style={{ margin: "48px 0px" }}>
                                            <Disable disabled={!isAdmin}>
                                                <ShareSearchlineWithUser
                                                    idsToDelete={idsToDelete}
                                                    users={users}
                                                    checked={shareIDS}
                                                    setChecked={shareSearchWith}
                                                    onToggle={handleToggleShare}
                                                    type="tender"
                                                />

                                                <div style={{ height: "16px" }} />

                                                <CopySearchlineToUser
                                                    users={users}
                                                    checked={copyIDS}
                                                    setChecked={copySearchTo}
                                                    onToggle={handleToggleCopy}
                                                    type="tender"
                                                />
                                            </Disable>
                                        </div>
                                    ) : (
                                        <span data-tut="reactour__distSummary">
                                            <TenderDistSummary
                                                color={color}
                                                name={name}
                                                moment={moment}
                                                dist={dist}
                                                chosenWords={chosenWords}
                                                domain={domain}
                                                filters={filters}
                                            />
                                        </span>
                                    )}
                                    {steps.length - 1 !== index && (
                                        <Box sx={{ mb: 2 }} data-tut="distribution_container_test">
                                            <div>
                                                {index !== 0 && (
                                                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                                        Vorige stap
                                                    </Button>
                                                )}

                                                <Button
                                                    variant="contained"
                                                    disabled={(index === 0 && name.length < 3) || (index === 2 && moment === null)}
                                                    onClick={() => {
                                                        handleNext();

                                                        if (currentTour === "TenderWizardDistribution" && index === 0)
                                                            setTimeout(function () {
                                                                setCurrentStep(1);
                                                            }, 300);

                                                        if (currentTour === "TenderWizardDistribution" && index === 1)
                                                            setTimeout(function () {
                                                                // setLockTour(false);
                                                                setCurrentStep(2);
                                                            }, 300);
                                                        if (currentTour === "TenderWizardDistribution" && index === 2)
                                                            setTimeout(function () {
                                                                setLockTour(true);
                                                                setCurrentStep(8);
                                                            }, 300);
                                                        if (currentTour === "TenderWizardDistribution" && index === 3)
                                                            setTimeout(function () {
                                                                setLockTour(false);
                                                                setCurrentStep(9);
                                                            }, 300);
                                                    }}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Volgende stap
                                                </Button>
                                            </div>
                                        </Box>
                                    )}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </Grid>
        </Grid>
    );
};

const steps = [
    {
        label: "Geef je zoekregel een naam",
        description: ``,
    },
    {
        label: "Kies een kleur",
        description: "",
    },
    {
        label: "Kies moment",
        description: ``,
    },
    {
        label: "Delen",
        description: ``,
    },
    {
        label: "Samenvatting",
        description: ``,
    },
];

export default TenderDist;

export const COLORS = gql`
    query getColors {
        currentUser {
            searches {
                id
                color
            }
        }
    }
`;
