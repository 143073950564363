import React from "react";
import SectorIconLoader from "../../../../components/boxes/SectorIconLoader";
import TopLinksModule from "../../../../components/organizationComponents/TopLinksModule";
import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetTopFiveSectorsForCA, GetTopFiveSectorsForCAVariables } from "../../../../__generated__/GetTopFiveSectorsForCA";
import { QUERY_TOP_FIVE_SECTORS_FOR_CA } from "../../../../graphql/queryDefCA";

interface Props {
    ca_id: string;
}

const TopFiveTendersIn: React.FC<Props> = ({ ca_id }) => {
    const { loading, error, data } = useQuery<GetTopFiveSectorsForCA, GetTopFiveSectorsForCAVariables>(QUERY_TOP_FIVE_SECTORS_FOR_CA, {
        variables: { id: ca_id },
    });

    if (loading || error || data?.contracting_authority?.most_tenders_in?.length === 0) return <></>;

    return (
        <TopLinksModule Title="meeste aanbestedingen in">
            {data?.contracting_authority?.most_tenders_in?.map((c, i) => {
                return (
                    <li key={i} style={{ display: "flex", alignItems: "center" }}>
                        {/* Iconloader, load icon based on sector_id */}
                        <SectorIconLoader sector_id={c?.sector.id} />
                        <Typography noWrap style={{ marginLeft: 10, marginRight: 2, maxWidth: "90%" }}>
                            {c?.sector.name}
                        </Typography>
                        <Typography style={{ display: "inline", width: "10%" }}>({c?.aantal})</Typography>
                    </li>
                );
            })}
        </TopLinksModule>
    );
};

export default TopFiveTendersIn;
