import React, { useState } from "react";
import { Grid, Divider, TextField, IconButton } from "@mui/material";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import TenderDist from "./TenderDist";
import { SearchDistributionInput, TenderFilterInput, TenderFilterTarget } from "../../../__generated__/globalTypes";
import OppDist from "./opportunityWizard/OppDist";
import { useQuery } from "@apollo/client";
import { GetSubscriptionUsers } from "../../../__generated__/GetSubscriptionUsers";
import { QUERY_SUBSCRIPTION_USERS } from "../../../graphql/queryDefUserAndTeams";
import { UserInTeam } from "../UserAndTeams/UsersAndTeams";
import { useCurrentUserOrganization } from "../../../components/contextProviders/CurrentUserOrganization";

interface Props {
    /**
     * return name to show name in UI
     */
    name: string;
    /**
     * setName to set name prop in mutation
     */
    setName: (name: string) => void;
    /**
     * get all props to set distribution settings in mainmodal
     * setDist sets dist -> dist is prop in mutation to save searchrule
     */
    setDist?: React.Dispatch<React.SetStateAction<SearchDistributionInput>>;

    /**
     * distribution settings
     */
    dist?: SearchDistributionInput;

    type: "tender" | "opportunity";
    shareSearchWith?: React.Dispatch<React.SetStateAction<number[]>>;
    copySearchTo?: React.Dispatch<React.SetStateAction<number[]>>;
    shareIDS?: number[];
    copyIDS?: number[];
    color: string;

    editRule: boolean;

    tenderDomain?: TenderFilterTarget;
    tenderFilters?: TenderFilterInput;
    isAdmin: boolean;
}

const DistModal: React.FC<Props> = ({
    setDist,
    setName,
    name,
    dist,
    type,
    copySearchTo,
    shareSearchWith,
    shareIDS,
    copyIDS,
    color,
    editRule,
    tenderDomain,
    tenderFilters,
    isAdmin,
}) => {
    const { org_id } = useCurrentUserOrganization();
    const [userList, setUserList] = useState<UserInTeam[]>([]);

    // const { t } = useTranslation();

    useQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.organization && data.organization.users) {
                setUserList(data.organization.users.filter((user) => user.id !== localStorage.getItem("user_id")));
            }
        },
    });

    return (
        <React.Fragment>
            {type === "opportunity" ? (
                <Grid
                    sx={{
                        height: "70vh",
                        display: "flex",
                        flexDirection: "column",
                        width: {
                            xs: "90vw",
                            xxl: "70vw",
                        },
                    }}
                >
                    {/*
                     *  Box contains:
                     *  - Labelbutton to change/select color
                     *  - Set title for searchrule
                     *  - Divider
                     */}

                    <Grid item xs={12} style={{ marginBottom: 32, display: "flex", flexDirection: "column", alignItems: "center", flexBasis: "40%" }}>
                        <div style={{ display: "flex", alignItems: "center", width: "90%", marginLeft: -110 }}>
                            {/* Button to open colorModal */}

                            <IconButton disabled={true} style={{ marginRight: 8 }}>
                                <FindInPageIcon style={{ color: color }} />
                            </IconButton>

                            {/*
                             * textfield to write title
                             */}
                            <TextField
                                data-tut="reactour__distTitle"
                                style={{ maxWidth: "43ch" }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                variant="standard"
                                InputProps={{
                                    sx: {
                                        fontSize: "1.2rem",
                                        width: "20vw",
                                    },
                                    disableUnderline: true,
                                }}
                                placeholder="Geef je zoekregel een naam"
                            />
                        </div>
                        <div style={{ width: "90%" }}>
                            <Divider style={{ width: "25%" }} />
                        </div>
                    </Grid>

                    <OppDist />
                </Grid>
            ) : (
                <div>
                    {/*
                     *  Box contains:
                     *  - Sentences for each activity trigger
                     *  - Divider
                     *  - Chips for each step that contain trigger options
                     */}
                    {tenderDomain &&
                        tenderFilters &&
                        dist &&
                        setDist &&
                        copyIDS &&
                        shareIDS &&
                        copySearchTo &&
                        shareSearchWith &&
                        type === "tender" && <TenderDist data-tut="reactour__distOptions" users={userList} editRule={editRule} isAdmin={isAdmin} />}
                </div>
            )}
        </React.Fragment>
    );
};

export default DistModal;
