import React from "react";
import { Paper, Grid, Card, CardContent, Typography, Tooltip, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import SvgIcon from "@mui/material/SvgIcon";

import { ReactComponent as pdfMarked } from "../../../assets/IconSet/pdfMarked.svg";
import { ReactComponent as pdfFile } from "../../../assets/IconSet/pdf.svg";
import { ReactComponent as txtFile } from "../../../assets/IconSet/txt.svg";
import { ReactComponent as docFile } from "../../../assets/IconSet/doc.svg";
import { TenderFilterTarget } from "../../../__generated__/globalTypes";
interface Props {
    domain: TenderFilterTarget;
    setDomain: (domain: TenderFilterTarget) => void;
}

const DomainModal: React.FC<Props> = ({ domain, setDomain }) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid container data-tut="reactour__domainGrid">
                {/* Grid item with 3 buttons to select a domain */}
                <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}
                    data-tut="reactour__domainOptions"
                >
                    {/* Button for summary  select */}

                    <Card
                        elevation={domain === TenderFilterTarget.SUMMARY ? 0 : 1}
                        onClick={() => {
                            setDomain(TenderFilterTarget.SUMMARY);
                            const strMessage1 = document.getElementById("textToReplace");

                            if (strMessage1) {
                                strMessage1.innerHTML = `Standaard wordt gezocht in de  <span style='color: #173351; font-weight:500;'>Samenvatting</span> van een aanbesteding. Hierbij wordt met jouw zoekwoorden gezocht in de: <ul id="list">
                                <li>titel van de aanbesteding;</li>
                                <li>naam aanbestedende dienst;</li>
                                <li>(verrijkte) omschrijving;</li>
                                <li>(verrijkte) omvang.</li>
                              </ul> Je kunt ook kiezen voor <span style='color: #173351; font-weight:500;'>Samenvatting + gemarkeerde documenten</span> of <span style='color: #173351; font-weight:500;'>Samenvatting + alle documenten</span>.`;
                            }
                        }}
                        sx={{
                            minHeight: "190px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginBottom: "16px",
                            cursor: "pointer",
                        }}
                        style={{ backgroundColor: domain === TenderFilterTarget.SUMMARY ? "#17335799" : undefined }}
                    >
                        <CardContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography
                                gutterBottom
                                style={{ color: domain === TenderFilterTarget.SUMMARY ? "#fff" : "#000", fontSize: "1.1rem", width: "70%" }}
                            >
                                {t("wizard.Summary")}
                            </Typography>
                            <Typography style={{ color: domain === TenderFilterTarget.SUMMARY ? "#fff" : "#000", width: "70%" }}>
                                {t("wizard.summary-helper")}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card
                        elevation={domain === TenderFilterTarget.RELEVANT_DOCS ? 0 : 1}
                        onClick={() => {
                            const strMessage1 = document.getElementById("textToReplace");

                            setDomain(TenderFilterTarget.RELEVANT_DOCS);
                            if (strMessage1) {
                                strMessage1.innerHTML = `Je kiest nu voor <span style='color: #173351; font-weight:500;'>Samenvatting + gemarkeerde documenten</span>.<br/> Hierbij wordt met jouw zoekwoorden gezocht in de samenvatting + gemarkeerde documenten.<br/><br/> Onze redactie markeert na publicatie belangrijke documenten, zoals een beschrijvend document of programma van eisen.<br/><br/> Deze mogelijkheid is alleen beschikbaar voor procedures van Nederlandse aanbestedende diensten.
                                `;
                            }
                        }}
                        sx={{
                            minHeight: "190px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginBottom: "16px",
                            cursor: "pointer",
                        }}
                        style={{ backgroundColor: domain === TenderFilterTarget.RELEVANT_DOCS ? "#17335799" : undefined }}
                    >
                        <CardContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ width: "70%" }}>
                                <Typography
                                    gutterBottom
                                    style={{ color: domain === TenderFilterTarget.RELEVANT_DOCS ? "#fff" : "#000", fontSize: "1.1rem" }}
                                >
                                    {t("wizard.summary-relevant")}
                                </Typography>
                                <Typography style={{ color: domain === TenderFilterTarget.RELEVANT_DOCS ? "#fff" : "#000", marginBottom: 16 }}>
                                    {t("wizard.summary-relevant-helper")}
                                </Typography>
                                <Tooltip placement="right" title={"Alleen beschikbaar voor procedures van Nederlandse aanbestedende diensten"}>
                                    <div style={{ display: "inline", height: 23, marginLeft: -3 }}>
                                        <ReactCountryFlag
                                            style={{
                                                height: 11,
                                                marginLeft: 4,
                                            }}
                                            countryCode="NL"
                                            svg
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </CardContent>
                    </Card>

                    <Card
                        elevation={domain === TenderFilterTarget.ALL_DOCS ? 0 : 1}
                        onClick={() => {
                            setDomain(TenderFilterTarget.ALL_DOCS);
                            const strMessage1 = document.getElementById("textToReplace");

                            if (strMessage1) {
                                strMessage1.innerHTML = `Je kiest nu voor <span style='color: #173351; font-weight:500;'>Samenvatting + alle documenten</span>.<br/> Hierbij wordt met jouw zoekwoorden gezocht in de samenvatting + alle documenten die gepubliceerd werden bij een aanbesteding.<br/><br/> Nu worden ook bijlagen meegenomen in jouw resultaten.
                                `;
                            }
                        }}
                        sx={{
                            minHeight: "190px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginBottom: "16px",
                            cursor: "pointer",
                        }}
                        style={{ backgroundColor: domain === TenderFilterTarget.ALL_DOCS ? "#17335799" : undefined, marginBottom: 0 }}
                    >
                        <CardContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography
                                gutterBottom
                                style={{ color: domain === TenderFilterTarget.ALL_DOCS ? "#fff" : "#000", fontSize: "1.1rem", width: "70%" }}
                            >
                                {t("wizard.summary-all")}
                            </Typography>
                            <Typography style={{ color: domain === TenderFilterTarget.ALL_DOCS ? "#fff" : "#000", width: "70%" }}>
                                {t("wizard.summary-all-helper")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Animation layout to show user where he can search */}
                <Grid item xs={12} md={8}>
                    <Paper elevation={0} style={{ marginLeft: 16, padding: 16, height: "100%" }} data-tut="reactour__domainDetailpage">
                        {/* Summary */}
                        <div>
                            <Typography style={{ fontSize: "1.1rem", display: "inline", borderBottom: "2px solid #17335799", padding: "2px" }}>
                                {t("wizard.tender-title")}
                            </Typography>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                {/* summary is always selected. */}
                                {
                                    <div style={{ margin: "10px", overflow: "hidden", width: "70%" }}>
                                        <Typography>{t("wizard.Summary")}</Typography>
                                        <Skeleton
                                            animation={false}
                                            width={"92%"}
                                            variant="text"
                                            sx={{
                                                backgroundColor: "#465c84b8",
                                            }}
                                        />
                                        <Skeleton
                                            animation={false}
                                            width={"93%"}
                                            variant="text"
                                            sx={{
                                                backgroundColor: "#465c84b8",
                                            }}
                                        />
                                        <Skeleton
                                            animation={false}
                                            width={"86%"}
                                            variant="text"
                                            sx={{
                                                backgroundColor: "#465c84b8",
                                            }}
                                        />
                                        <Skeleton
                                            animation={false}
                                            width={"84%"}
                                            variant="text"
                                            sx={{
                                                backgroundColor: "#465c84b8",
                                            }}
                                        />
                                        <Skeleton
                                            animation={false}
                                            width={"89%"}
                                            variant="text"
                                            sx={{
                                                backgroundColor: "#465c84b8",
                                            }}
                                        />
                                        <Skeleton
                                            animation={false}
                                            width={"87%"}
                                            variant="text"
                                            sx={{
                                                backgroundColor: "#465c84b8",
                                            }}
                                        />
                                        <Skeleton
                                            animation={false}
                                            width={"95%"}
                                            variant="text"
                                            sx={{
                                                backgroundColor: "#465c84b8",
                                            }}
                                        />
                                    </div>
                                }
                                <div style={{ margin: "10px", overflow: "hidden", width: "30%" }}>
                                    <div style={{ height: 25 }} />
                                    <Skeleton animation={false} width={"69%"} variant="text" />
                                    <Skeleton animation={false} width={"60%"} variant="text" />
                                    <Skeleton animation={false} width={"55%"} variant="text" />
                                    <Skeleton animation={false} width={"63%"} variant="text" />
                                    <Skeleton animation={false} width={"68%"} variant="text" />
                                    <Skeleton animation={false} width={"60%"} variant="text" />
                                    <Skeleton animation={false} width={"70%"} variant="text" />
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ width: "49%" }}>
                                <div style={{ margin: "20px 10px 20px 10px" }}>
                                    {/* Contracting authority */}
                                    <Typography
                                        style={{
                                            borderBottom: "2px solid #17335799",
                                            display: "inline",
                                        }}
                                    >
                                        {t("wizard.Contracting-authority")}
                                    </Typography>
                                    <div className="Alignment" style={{ overflow: "hidden" }}>
                                        <Skeleton animation={false} variant="text" />
                                        <Skeleton animation={false} width={200} variant="text" />
                                        <Skeleton animation={false} width={125} variant="text" />
                                        <Skeleton animation={false} width={150} variant="text" />
                                        <Skeleton animation={false} width={200} variant="text" />
                                        <Skeleton animation={false} width={125} variant="text" />
                                    </div>
                                </div>
                                <div style={{ margin: "20px 10px 20px 10px" }}>
                                    {/* Contracts */}
                                    <div style={{ overflow: "hidden" }}>
                                        <Typography>{t("wizard.Contract")}</Typography>
                                        <Skeleton animation={false} width={300} variant="text" />
                                        <Skeleton animation={false} width={300} variant="text" />
                                        <Skeleton animation={false} width={75} variant="text" />
                                        <Skeleton animation={false} width={350} variant="text" />
                                        <Skeleton animation={false} width={100} variant="text" />
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "49%" }}>
                                {/* all docs  */}
                                <div style={{ margin: "20px 10px 20px 10px" }}>
                                    <div style={{ margin: "5px" }}>
                                        <Typography
                                            style={{
                                                borderBottom:
                                                    domain === TenderFilterTarget.RELEVANT_DOCS || domain === TenderFilterTarget.ALL_DOCS
                                                        ? "2px solid #17335799"
                                                        : undefined,
                                                display: "inline",
                                            }}
                                        >
                                            {t("wizard.Documents")}
                                        </Typography>

                                        {/* relevant docs */}
                                        <div>
                                            {domain === TenderFilterTarget.RELEVANT_DOCS || domain === TenderFilterTarget.ALL_DOCS ? (
                                                <React.Fragment>
                                                    <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                        <SvgIcon component={pdfMarked} style={{ color: "#465c84" }} />
                                                        <Skeleton
                                                            width={500}
                                                            animation={false}
                                                            variant="text"
                                                            sx={{
                                                                backgroundColor: "#465c84b8",
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                        <SvgIcon component={pdfMarked} style={{ color: "#465c84" }} />
                                                        <Skeleton
                                                            width={450}
                                                            animation={false}
                                                            variant="text"
                                                            sx={{
                                                                backgroundColor: "#465c84b8",
                                                            }}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                        <SvgIcon component={pdfMarked} style={{ color: "#465c84" }} />
                                                        <Skeleton width={475} animation={false} variant="text" />
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                        <SvgIcon component={pdfMarked} style={{ color: "#465c84" }} />
                                                        <Skeleton width={500} animation={false} variant="text" />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>

                                        {domain === TenderFilterTarget.ALL_DOCS ? (
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                    <SvgIcon component={txtFile} style={{ color: "#465c84" }} />
                                                    <Skeleton
                                                        width={475}
                                                        animation={false}
                                                        variant="text"
                                                        sx={{
                                                            backgroundColor: "#465c84b8",
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                    <SvgIcon component={pdfFile} style={{ color: "#465c84" }} />
                                                    <Skeleton
                                                        width={500}
                                                        animation={false}
                                                        variant="text"
                                                        sx={{
                                                            backgroundColor: "#465c84b8",
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                    <SvgIcon component={docFile} style={{ color: "#465c84" }} />
                                                    <Skeleton
                                                        width={475}
                                                        animation={false}
                                                        variant="text"
                                                        sx={{
                                                            backgroundColor: "#465c84b8",
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                    <SvgIcon component={pdfFile} style={{ color: "#465c84" }} />
                                                    <Skeleton
                                                        width={500}
                                                        animation={false}
                                                        variant="text"
                                                        sx={{
                                                            backgroundColor: "#465c84b8",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                    <SvgIcon component={txtFile} style={{ color: "#465c84" }} />
                                                    <Skeleton width={475} animation={false} variant="text" />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                    <SvgIcon component={pdfFile} style={{ color: "#465c84" }} />
                                                    <Skeleton width={500} animation={false} variant="text" />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                    <SvgIcon component={docFile} style={{ color: "#465c84" }} />
                                                    <Skeleton width={475} animation={false} variant="text" />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
                                                    <SvgIcon component={pdfFile} style={{ color: "#465c84" }} />
                                                    <Skeleton width={500} animation={false} variant="text" />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={{ margin: "20px 10px 20px 10px" }}>
                                    {/* Scope */}
                                    <Typography>{t("wizard.Scope")}</Typography>
                                    <Skeleton animation={false} variant="text" />
                                    <Skeleton animation={false} width={100} variant="text" />
                                    <Skeleton animation={false} width={75} variant="text" />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default DomainModal;
