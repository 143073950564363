import { createContext, useContext } from "react";

export type NotificationContextType = {
    openNotifications: boolean;
    setOpenNotifications: (openNotifications: boolean) => void;
};

export const NotificationContext = createContext<NotificationContextType>({
    openNotifications: false,
    setOpenNotifications: (openNotifications) => console.warn(""),
});
export const useNotification = () => useContext(NotificationContext);
