import React, { useState } from "react";

// MUI import
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";

// GQL
import { useMutation } from "@apollo/client";
import { Archive } from "@mui/icons-material";
// import { TENDER_ARCHIVE } from "./dialogs/ArchiveDialog";
import { TenderArchive, TenderArchiveVariables } from "../__generated__/TenderArchive";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { QUERY_FULL_WORKLIST_MINIMUM, QUERY_TENDERLIKED, QUERY_TENDERS_INTERESTING_MINIMUM } from "../graphql/queryDefinitions";
import { TENDER_ARCHIVE } from "../graphql/mutationDefinitions";

interface Props {
    selection: any;
    buttonSize: "small" | "medium";
    iconSize: string;
    setSelection: any;
}

const ArchiveSelectedTenderButton: React.FC<Props> = ({ setSelection, selection: selectedArr, buttonSize, iconSize }) => {
    const { t } = useTranslation();
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [save, { loading: loadingMutation, error: errorMutation }] = useMutation<TenderArchive, TenderArchiveVariables>(TENDER_ARCHIVE);
    const [openModal, setOpenModal] = useState<boolean>(false);
    /**
     * Archive all selected tenders
     */
    const ArchiveSelectedTenders = () => {
        const selection = selectedArr;
        setIsloading(true);
        /**
         * if arr is empty, then all CA's are already starred.
         * if user clicks on button, then CA's star will be removed.
         * if arr is > 0 then CA's will be starred.
         */
        if (selection.length > 0) {
            selection.forEach(async (id: string) => {
                // Function to like organization
                if (loadingMutation) {
                }
                if (errorMutation) {
                    // toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await save({
                        variables: {
                            tenderID: id,
                        },
                        refetchQueries: [
                            QUERY_FULL_WORKLIST_MINIMUM,
                            "FilterWorklistMinimumOverview",
                            QUERY_TENDERLIKED,
                            "GetTenderLikes",
                            QUERY_TENDERS_INTERESTING_MINIMUM,
                            "FilterInterestingMinimumTendersOverview",
                        ],
                        awaitRefetchQueries: true,
                    });
                    setSelection([]);
                } catch (e) {
                    // toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    setSelection([]);
                    setIsloading(false);
                }
            });
            // onClose?.();
        }
        // else {
        //     onClose?.();
        // }
    };

    const handleClose = () => {
        setOpenModal(false);
        setSelection([]);
    };

    return (
        <React.Fragment>
            <Tooltip enterNextDelay={100} title={t("Archive") as string} placement="top">
                <IconButton
                    disabled={loadingMutation || isLoading}
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenModal(true);
                    }}
                    size={buttonSize}
                >
                    <Archive style={{ fontSize: iconSize }} />
                </IconButton>
            </Tooltip>

            <Dialog open={openModal} onClose={handleClose} maxWidth="md">
                <DialogTitle id="alert-dialog-title">Archiveren</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography>Je wilt de geselecteerde {selectedArr.length === 1 ? "aanbesteding" : "aanbestedingen"} archiveren.</Typography>
                        <Typography style={{ marginTop: 8 }}>Je ontvangt geen meldingen meer van deze aanbesteding.</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loadingMutation} color="primary">
                        Annuleren
                    </Button>
                    <Button
                        onClick={() => {
                            ArchiveSelectedTenders();
                        }}
                        disabled={loadingMutation || isLoading}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        Bevestigen {loadingMutation && <CircularProgress size={14} style={{ marginLeft: 4 }} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ArchiveSelectedTenderButton;
