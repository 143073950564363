import { gql } from "@apollo/client";

//?  Query for highlighting in title, summary and description
export const GET_HIGHLIGHTED_SUMMARY = gql`
    query highlightedSummary($id: ID!) {
        highlightedTender(id: $id) {
            namegiventocontract
            omschrijving
            omvang
        }
    }
`;

//?  Query to fetch matching searchruels
export const QUERY_GETSEARCHRULEMATCHES = gql`
    query GetSearchRuleMatches($id: ID!) {
        getUserSearches(tender_id: $id) {
            id
            name
            query
            filters_with_labels {
                key
                labels
            }
            color
        }
    }
`;

// ? get searchrules of current user (logged in)
export const QUERY_GETLABELMATCHPARAMS_WITH_LABELS = gql`
    query GetSearchParamMatchesWithLabels($tender_id: Int!) {
        GetSearchTenderMatch(tender_id: $tender_id) {
            words
            search {
                id
                name
                color
                filters_with_labels {
                    key
                    labels
                }
            }
        }
    }
`;

// ? get searchrules of current user (logged in)
export const QUERY_GETLABELMATCH = gql`
    query GetSearchMatchesWithLabels($tender_id: Int!) {
        GetSearchTenderMatch(tender_id: $tender_id) {
            search {
                id
                name
                color
            }
        }
    }
`;

// ? get snippets with highlighting in documents
export const GET_SNIPPETS_OF_DOCS = gql`
    query getSnippetsForLabelWidget($id: ID!) {
        tender(id: $id) {
            id
            userLabels {
                document {
                    id
                    filename
                    temporary_link
                    extension
                }
                snippets {
                    snippets
                    search {
                        id
                        name
                        color
                    }
                }
            }
        }
    }
`;

//? get documents that match with inserted query
export const QUERY_SEARCH_TENDER_DOCUMENTS = gql`
    query SearchTenderDocuments($tender_id: ID!, $query: String!) {
        searchDocuments(tender_id: $tender_id, query: $query) {
            document {
                id
                temporary_link
                extension
            }
            tender_id
            filename
            dispatchdate
            highlighting {
                content
                filename
            }
        }
    }
`;

export const GET_SEARCH_RULE_META = gql`
    query getSearchlineMeta($days: Int, $months: Int) {
        getSearchesStatistics(days: $days, months: $months) {
            search {
                id
                name
                color
            }
            liked
            disliked
            not_reviewed
        }
    }
`;

export const QUERY_SYSTEM_RULES_OF_SECTOR = gql`
    query GetSystemRulesOfSector($sector_id: ID!, $first: Int!, $page: Int) {
        opportunitySearchGroups(sector_id: $sector_id, first: $first, page: $page) {
            data {
                searches {
                    id
                    name
                }
            }
        }
    }
`;
