import { useEffect, useRef, RefObject } from "react";

type CallbackFunction = () => void;

function useRegisterScrollTrigger(targetRef: RefObject<HTMLElement>, callback: CallbackFunction) {
    const observer = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        observer.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    callback();
                }
            },
            { threshold: 0 }
        );

        const element = targetRef.current;
        if (element) {
            observer.current.observe(element);
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [targetRef, callback]);
}

export default useRegisterScrollTrigger;
