import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { SearchDistributionInput } from "../../../__generated__/globalTypes";
import { useTranslation } from "react-i18next";

interface Props {
    dist: SearchDistributionInput;
    moment: string;
    type: "opportunity" | "tender";
}

const MobileViewDistribution: React.FC<Props> = ({ dist, type, moment }) => {
    const { t } = useTranslation();
    const [disti, setDist] = useState<SearchDistributionInput>({} as any);

    const bluelight = "#465c84";
    const green = "#225E4D";

    useEffect(() => {
        setDist(dist);
    }, [dist]);

    return (
        <div>
            {/* activity trigger */}
            <Typography
                sx={{
                    display: "inline",
                    marginRight: "4px",
                    color: type === "opportunity" ? green : type === "tender" ? bluelight : "#000000",
                    fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                }}
            >
                {t(`${moment}`) as string}
            </Typography>

            <Typography style={{ display: "inline", marginRight: 4 }}>nieuwe resultaten </Typography>

            {disti.worklist === true && (
                <Typography
                    sx={{
                        display: "inline",
                        marginRight: "4px",
                        color: type === "opportunity" ? green : type === "tender" ? bluelight : "#000000",
                        fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                    }}
                >
                    tonen in werklijst
                </Typography>
            )}

            {/*
             * Notification trigger
             */}
            {disti.worklist === true && !disti.email_interval && (
                <Typography
                    sx={{
                        display: "inline-block",
                        marginRight: "4px",
                        color: type === "opportunity" ? green : type === "tender" ? bluelight : "#000000",
                        fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                    }}
                >
                    en per melding sturen
                </Typography>
            )}
            {disti.worklist === true && disti.email_interval && (
                <Typography
                    sx={{
                        display: "inline-block",
                        marginRight: "4px",
                        color: type === "opportunity" ? green : type === "tender" ? bluelight : "#000000",
                        fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                    }}
                >
                    , per melding sturen
                </Typography>
            )}
            {disti.worklist === false && !disti.email_interval && (
                <Typography
                    sx={{
                        display: "inline-block",
                        marginRight: "4px",
                        color: type === "opportunity" ? green : type === "tender" ? bluelight : "#000000",
                        fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                    }}
                >
                    per melding sturen
                </Typography>
            )}
            {/*
             *  Email trigger
             */}
            {disti.email_interval && (
                <React.Fragment>
                    {disti.worklist === true && <Typography style={{ display: "inline-block", marginRight: "4px" }}>en</Typography>}

                    {disti.email_interval && (
                        <Typography
                            sx={{
                                display: "inline-block",
                                marginRight: "4px",
                                color: type === "opportunity" ? green : type === "tender" ? bluelight : "#000000",
                                fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                            }}
                        >
                            {t(`${disti.email_interval.toUpperCase()}`) as string}
                        </Typography>
                    )}

                    {disti.email_interval !== null && (
                        <Typography
                            sx={{
                                display: "inline-block",
                                marginRight: "4px",
                                color: type === "opportunity" ? green : type === "tender" ? bluelight : "#000000",
                                fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                            }}
                        >
                            per e-mail
                        </Typography>
                    )}

                    <Typography style={{ display: "inline-block", marginRight: "4px" }}>sturen</Typography>
                </React.Fragment>
            )}
        </div>
    );
};

export default MobileViewDistribution;
