import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupLogicalOperators, LogicalOperator } from "./QueryBuilderContext";

interface Props {
    updateGroupOperator: (groupId: string, newOperator: "AND" | "OR" | "NOT") => void;
    groupId: string;
    operator: LogicalOperator | undefined;
}

const ChangeOperatorBetweenGroups: React.FC<Props> = ({ updateGroupOperator, groupId, operator }) => {
    // menu AND | NOT
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button sx={{ marginRight: "8px" }} onClick={handleClickMenu} variant="contained" startIcon={operator?.icon}>
                {t(operator?.text as string)}
            </Button>
            <Menu open={open} onClose={handleCloseMenu} anchorEl={anchorEl}>
                {GroupLogicalOperators.map((operator, index) => {
                    return (
                        <MenuItem
                            key={index}
                            value={operator.text}
                            onClick={() => {
                                updateGroupOperator(groupId, operator.text as "AND" | "OR" | "NOT");
                                handleCloseMenu(); // Close the menu
                            }}
                        >
                            <ListItemIcon>{operator.icon}</ListItemIcon>
                            <ListItemText>{t(operator.text)}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default ChangeOperatorBetweenGroups;
