import React from "react";
import { useDragLayer } from "react-dnd";
import { GetDashboardLayout_GetDashboardLayout_rows } from "../../../__generated__/GetDashboardLayout";
import { useRowsContext } from "./RowsContext";
import { Card, Grid } from "@mui/material";

interface Props {
    rows: GetDashboardLayout_GetDashboardLayout_rows[];
}

const CustomDragLayer: React.FC<Props> = ({ rows }) => {
    const { itemType, isDragging, item, currentOffset } = useDragLayer((monitor) => ({
        itemType: monitor.getItemType(),
        isDragging: monitor.isDragging(),
        item: monitor.getItem(),
        currentOffset: monitor.getClientOffset(),
    }));

    const { widgets } = useRowsContext();

    if (!isDragging || !currentOffset) {
        return null;
    }

    const layerStyles: React.CSSProperties = {
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: currentOffset.x,
        top: currentOffset.y,
        transform: "translate(0, -50%)",
        width: "80%",
        opacity: 0.7,
        border: "2px dashed #e1e1e1",
        height: "250px",
    };

    const renderItem = () => {
        switch (itemType) {
            case "ROW":
                const test = rows.find((row) => row.id === item.id.toString())?.columns.map((item) => item.widgetId) || [];
                return (
                    <div style={layerStyles}>
                        <Grid
                            container
                            spacing={2}
                            style={{ height: "100%", padding: "8px", borderRadius: "4px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
                        >
                            {test.map((column, columnIndex) => {
                                const widget = widgets.find((w) => w.id === column);
                                if (widget === undefined) {
                                    return <></>;
                                } else
                                    return (
                                        <Grid item key={columnIndex} xs={12} sm={12 / test.length}>
                                            <Card sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                {widget.label}
                                            </Card>
                                        </Grid>
                                    );
                            })}
                        </Grid>
                    </div>
                );

            default:
                return (
                    <div
                        style={{
                            position: "fixed",
                            pointerEvents: "none",
                            zIndex: 100,
                            left: currentOffset.x,
                            top: currentOffset.y,
                            transform: "translate(0, -50%)",
                            width: "80%",
                            opacity: 0.7,
                            height: "10px",
                            overflow: "hidden",
                        }}
                    />
                );
        }
    };

    return <div>{renderItem()}</div>;
};

export default CustomDragLayer;
