import React, { useEffect, useState } from "react";
import { useReRunHighlighting } from "./contextProviders/HighlightContext";
import dompurify from "dompurify";
import { Link, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

interface Props {
    text: any;
    variant: "inherit" | Variant | undefined;
    lines: number;
    id: string;
}

const ReadMoreBasedOnNewLines: React.FC<Props> = ({ text, variant, lines, id }) => {
    // State to show less or more text
    const [showLess, setShowLess] = useState(true);
    // State tracks if text is clamped or not
    const [clamped, setClamped] = useState(true);
    // Variable style based on lines given to component
    const maxLines = lines * 3;
    // classes with variable items
    // readmore text color
    const readMore = "#465c84";
    // rerun highlight
    const { rerunHighlight, setRerunHighlight } = useReRunHighlighting();
    // function to check if div contains more text => used to set clamped state
    const isTextClamped = (elm: any) => elm.scrollHeight > elm.clientHeight;

    /**
     * dompurify imported for sanitizing html
     */
    const frag = dompurify.sanitize(text, { FORCE_BODY: true });

    /**
     * Check if text is clamped each render again
     * id => id of div to check
     * text => content inside div
     */
    useEffect(() => {
        const getText = document.querySelector(`#${id}`);
        setClamped(isTextClamped(getText));
    }, [id, text]);

    return (
        <div>
            <Typography
                id={id}
                sx={{
                    maxHeight: showLess ? `${maxLines}em` : "100%",
                    lineHeight: "1.5em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: showLess ? lines : "initial",
                    WebkitBoxOrient: "vertical",
                    width: "fit-content",
                    whiteSpace: "pre-wrap",
                    paddingBottom: "4px",
                }}
                variant={variant}
                dangerouslySetInnerHTML={{ __html: frag }}
            />
            {/* Read more button is clamped is true */}
            {clamped && (
                <Link
                    underline="none"
                    style={{ color: `${readMore}`, cursor: "pointer" }}
                    onClick={() => {
                        setRerunHighlight(!rerunHighlight);
                        setShowLess(!showLess);
                    }}
                >
                    {showLess ? "lees meer" : "toon minder"}
                </Link>
            )}
        </div>
    );
};

export default ReadMoreBasedOnNewLines;
