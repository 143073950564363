import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";

// MUI import
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

// GQL
import { FetchResult, InternalRefetchQueriesInclude, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { dislikeOrg, dislikeOrgVariables } from "../__generated__/dislikeOrg";
import { GetLikedOrgs } from "../__generated__/GetLikedOrgs";
import { likeOrganization, likeOrganizationVariables } from "../__generated__/likeOrganization";
import MatomoEvent from "../models/MatomoEvent";
import { QUERY_USER_LIKED_ORGS } from "../graphql/queryDefCurrentUser";
import { DISLIKEORGANIZATION, LIKEORGANIZATION } from "../graphql/mutationDefinitions";

interface Props {
    org_id: string;
    buttonSize: "small" | "medium";
    color: string;
    refetchQueries?: InternalRefetchQueriesInclude | ((result: FetchResult) => InternalRefetchQueriesInclude) | undefined;
}

const LikeCABtn: React.FC<Props> = ({ org_id, buttonSize, color, refetchQueries }) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const { error: errorQuery, loading: loadingQuery, data: dataQuery } = useQuery<GetLikedOrgs>(QUERY_USER_LIKED_ORGS);
    const [like, { loading: loadingMutation, error: errorMutation }] = useMutation<likeOrganization, likeOrganizationVariables>(LIKEORGANIZATION);
    const [dislike, { loading: loadingDislike, error: errorDislike }] = useMutation<dislikeOrg, dislikeOrgVariables>(DISLIKEORGANIZATION);

    // Track event
    const trackStarOrg = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // Function to like organization
    const likeOrg = async () => {
        if (errorMutation) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await like({
                variables: {
                    org_id: org_id,
                },
                refetchQueries: refetchQueries,
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    // Function to dislike organization
    const dislikeOrg = async () => {
        if (errorDislike) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await dislike({
                variables: {
                    org_id: org_id,
                },
                refetchQueries: refetchQueries,
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    if (errorQuery || dataQuery?.currentUser === undefined || dataQuery?.currentUser === null) {
        return null;
    }
    const likes = dataQuery.currentUser.market_parties.find((mp) => mp.id === org_id);

    return (
        <React.Fragment>
            {likes === undefined ? (
                <Tooltip enterNextDelay={100} title={t("With-star") as string} placement="top">
                    <IconButton
                        disabled={loadingMutation || loadingQuery}
                        onClick={(e) => {
                            e.stopPropagation();
                            likeOrg();
                            trackStarOrg({ category: "Organizations", action: "Click-add-star-org", href: window.location.href });
                        }}
                        size={buttonSize}
                    >
                        <StarOutlineIcon fontSize="small" style={{ color: color }} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip enterNextDelay={100} title={t("Without-star") as string} placement="top">
                    <IconButton
                        disabled={loadingDislike || loadingQuery}
                        onClick={(e) => {
                            e.stopPropagation();
                            dislikeOrg();
                            trackStarOrg({ category: "Organizations", action: "Click-remove-star-org", href: window.location.href });
                        }}
                        size={buttonSize}
                    >
                        <StarIcon fontSize="small" style={{ color: color }} />
                    </IconButton>
                </Tooltip>
            )}
        </React.Fragment>
    );
};

export default LikeCABtn;
