import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Tooltip, ClickAwayListener } from "@mui/material";
import {
    GetUserSearchRules_currentUser_searches,
    GetUserSearchRules_currentUser_searches_filters_with_labels,
} from "../../../__generated__/GetUserSearchRules";

interface Props {
    filterValues: (GetUserSearchRules_currentUser_searches_filters_with_labels | null)[] | null;
    searchline: GetUserSearchRules_currentUser_searches;
}

const MobielViewSearchSummary: React.FC<Props> = ({ searchline, filterValues }) => {
    const { t } = useTranslation();
    const [openTooltip, setOpenTooltip] = useState<string | false>(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = (str: string) => {
        if (openTooltip === str) {
            handleTooltipClose();
        } else {
            setOpenTooltip(str);
        }
    };

    return (
        <div onClick={handleTooltipClose}>
            <Typography variant="body2" sx={{ display: "inline", color: "#707070", marginRight: "4px" }}>
                Ik zoek aanbestedingen
            </Typography>
            {/* If searchline contains filter country/countries => show typography component with data */}
            {filterValues !== null &&
                filterValues
                    .filter((fltr) => fltr?.key === "country")
                    .map((c, i) => {
                        return (
                            <Typography variant="body2" sx={{ display: "inline", color: "#707070", marginRight: "4px" }} key={i}>
                                {filterValues?.filter((f) => f?.key === "country").length > 0 && `uit`}{" "}
                                <strong>{c?.labels?.map((l) => l).join(" en ")}</strong>
                            </Typography>
                        );
                    })}

            {/* If searchline contains query => show typography component with query data */}
            {searchline.query && (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <>
                        met{" "}
                        <Tooltip
                            onClose={handleTooltipClose}
                            open={openTooltip === "words"}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            enterNextDelay={100}
                            placement="top"
                            PopperProps={{
                                style: { marginBottom: -10 },
                                disablePortal: true,
                            }}
                            title={searchline.query.split(" OR ").map((i) => {
                                if (i.includes("~" || "*")) return <div key={i}>{i}</div>;
                                return <div key={i}>{i.replace(/["']/g, "")}</div>;
                            })}
                        >
                            <Typography
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleTooltipOpen("words");
                                }}
                                variant="body2"
                                sx={{ display: "inline", color: "#707070", marginRight: "4px", cursor: "pointer" }}
                            >
                                <strong> zoekwoorden </strong>
                            </Typography>
                        </Tooltip>
                    </>
                </ClickAwayListener>
            )}

            {searchline.query && searchline.target && (
                <>
                    in{" "}
                    <Typography variant="body2" sx={{ display: "inline", color: "#707070", marginRight: "4px" }}>
                        <strong> {t(searchline.target)} </strong>
                    </Typography>
                </>
            )}

            {filterValues !== undefined && filterValues !== null && filterValues.filter((f) => f?.key !== "country").length > 0 && (
                <>
                    <Typography variant="body2" sx={{ display: "inline", color: "#707070", marginRight: "4px" }}>
                        {searchline.query !== null && searchline.query.length > 0 ? "en de" : "met de"}{" "}
                        {filterValues?.length > 1 ? "filters" : "filter"}
                        {filterValues
                            .filter((fltr) => fltr?.key !== "country")
                            .map((f, i: number) => {
                                // remove country from list of filters to const to use as params for " en " and ", "
                                const filterWithoutCountry = filterValues.filter((fltr) => fltr?.key !== "country");
                                return (
                                    <>
                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                            <Tooltip
                                                key={i}
                                                enterNextDelay={100}
                                                onClose={handleTooltipClose}
                                                open={openTooltip === t(`filter.${f?.key}`).toLowerCase()}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                placement="top"
                                                PopperProps={{
                                                    style: { marginBottom: -10 },
                                                }}
                                                title={
                                                    <>
                                                        {f?.labels?.map((l) => {
                                                            return <div key={l}>{l}</div>;
                                                        })}
                                                    </>
                                                }
                                            >
                                                <Typography
                                                    variant="body2"
                                                    style={{ display: "inline-flex", cursor: "pointer" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleTooltipOpen(t(`filter.${f?.key}`).toLowerCase());
                                                    }}
                                                >
                                                    <strong style={{ marginLeft: 4, marginRight: filterWithoutCountry?.length - 2 === i ? 4 : 0 }}>
                                                        {t(`filter.${f?.key}`).toLowerCase()}
                                                    </strong>
                                                </Typography>
                                            </Tooltip>
                                        </ClickAwayListener>
                                        {filterWithoutCountry.length - 2 === i ? "en" : i !== filterWithoutCountry.length - 1 ? "," : ""}
                                    </>
                                );
                            })}
                    </Typography>
                </>
            )}

            {/* select searchterms */}
            {/* <FormControl>
            <Select
                disableUnderline
                multiple
                value={searchterm}
                onChange={handleChange}
                input={<Input />}
                classes={{ root: classes.selectField, icon: classes.selectFieldIcon }}
            >
                <MenuItem value="1">name</MenuItem>
            </Select>
        </FormControl> */}
        </div>
    );
};

export default MobielViewSearchSummary;
