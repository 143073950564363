import React, { useState } from "react";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Condition, LogicalOperator, logicalOperators, useQueryBuilder } from "./QueryBuilderContext";
import ProximityBox from "./ProximityBox";
import RemoveCondition from "./RemoveCondition";

interface Props {
    condition: Condition;
    groupId: string;
    updateConditionKeyword: (groupId: string, conditionId: string, newKeyword: string) => void;
    handleNearTermChange: (groupId: string, conditionId: string, newNearTerm: string) => void;
    handleProximityChange: (groupId: string, conditionId: string, newProximity: number | undefined) => void;
    highlightedConditionId: string | null;
    activeGroup: string;
    handleNearCondition: (groupId: string, conditionId: string) => void;
    updateConditionOperator: (groupId: string, conditionId: string, newOperator?: LogicalOperator, secondOperator?: LogicalOperator) => void;
    removeNearTerm: () => void;
    isORSelected: boolean;
    isANDorNOTSelected: boolean;
    themeColor: string;
}

const NearBlock: React.FC<Props> = ({
    condition,
    groupId,
    activeGroup,
    highlightedConditionId,
    handleProximityChange,
    handleNearCondition,
    updateConditionOperator,
    isANDorNOTSelected,
    isORSelected,
    removeNearTerm,
    themeColor,
}) => {
    const { t } = useTranslation();
    const [anchorElupdate, setAnchorElupdate] = useState<null | HTMLElement>(null);
    const openupdate = Boolean(anchorElupdate);
    const { getOperatorIcon } = useQueryBuilder();

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ margin: "0px 8px", color: "#000000" }}>
                {t("within")}
            </Typography>
            <Button
                size="small"
                onClick={(e) => setAnchorElupdate(e.currentTarget)}
                variant="contained"
                startIcon={getOperatorIcon(condition.operator?.text as string)}
            >
                {t(condition.operator?.text as string)}
            </Button>
            <Typography variant="h6" sx={{ margin: "0px 8px", color: "#000000" }}>
                {t("of")}
            </Typography>
            <ProximityBox
                handleProximityChange={handleProximityChange}
                groupId={groupId}
                conditionId={condition.id}
                proximity={condition.proximity as number}
            />
            <Typography variant="h6" sx={{ margin: "0px 8px", color: "#000000" }}>
                {t("words_of")}
            </Typography>

            {!condition.nearTerm ? (
                <Typography
                    variant="button"
                    fontStyle="italic"
                    sx={{ color: "#b8b8b8", marginTop: "2px", fontSize: "0.7rem", borderBottom: "1px dashed #b8b8b8" }}
                >
                    {t("Add_Searchterm")}
                </Typography>
            ) : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 4px 4px 8px",
                        backgroundColor: condition.id === highlightedConditionId ? `${themeColor}50` : "#ffffff",
                        transition: "background-color 0.5s ease",
                        borderRadius: "4px",
                        width: "fit-content",
                        height: "30px",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: condition.nearTerm?.includes("*") || condition.nearTerm?.includes("?") ? "#c0392b" : themeColor,
                        }}
                    >
                        {condition.nearTerm}
                    </Typography>
                    <RemoveCondition onRemove={removeNearTerm} active={groupId === activeGroup} />
                </div>
            )}

            <Menu open={openupdate} onClose={() => setAnchorElupdate(null)} anchorEl={anchorElupdate}>
                {logicalOperators.map((operator, index) => {
                    // Determine whether the current operator should be disabled
                    const isDisabled = (operator.text === "AND" || operator.text === "NOT") && isORSelected;
                    const isOrDisabled = operator.text === "OR" && isANDorNOTSelected;
                    return (
                        <MenuItem
                            key={index}
                            value={operator.text}
                            disabled={isDisabled || isOrDisabled}
                            onClick={() => {
                                if (operator.text === "NEAR") {
                                    // If the operator is NEAR, handle it differently
                                    handleNearCondition(groupId, condition.id); // Call your NEAR handler
                                } else {
                                    // For other operators, just update the condition operator
                                    updateConditionOperator(groupId, condition.id, operator as LogicalOperator);
                                }
                                setAnchorElupdate(null); // Close the menu
                            }}
                        >
                            <ListItemIcon>{operator.icon}</ListItemIcon>
                            <ListItemText>{t(operator.text)}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default NearBlock;
