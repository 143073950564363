import React, { useEffect, useState } from "react";
import { CircularProgress, ListItem, Typography } from "@mui/material";
import { DistributionIntervalInput, SearchDistributionInput } from "../../../__generated__/globalTypes";
import { useTranslation } from "react-i18next";
import GeneralSelector from "../../../components/input/GeneralSelector";
import DistributionSoloOption from "../../../components/input/DistributionSoloOption";
import { useMutation, useQuery } from "@apollo/client";
import { OrganizationOfCurrentUser } from "../../../__generated__/OrganizationOfCurrentUser";
import { QUERY_ORGANIZATION_CURRENT_USER } from "../../../graphql/queryDefUserAndTeams";
import { SET_SEARCH_DISTRIBUTION } from "../../../graphql/mutationDefinitions";
import { setSearchDistribution, setSearchDistributionVariables } from "../../../__generated__/setSearchDistribution";

interface Props {
    id: string;
    dist: SearchDistributionInput;
    moment: string;
    type: "opportunity" | "tender";
    refetch: any;
}

const SearchLineDistribution: React.FC<Props> = ({ dist, type, moment, id, refetch }) => {
    const { t } = useTranslation();
    const [disti, setDist] = useState<SearchDistributionInput>({} as any);
    const [UID, setUID] = useState<string>(localStorage.getItem("user_id") || "");
    const [staticLoading, setStaticLoading] = useState<boolean>(false);
    const blue = "#173357";
    const bluelight = "#465c84";

    const green = "#225E4D";
    const bg = "#ffffff";
    // State to track whether the object is dirty or not

    const [updateDistribution, { loading }] = useMutation<setSearchDistribution, setSearchDistributionVariables>(SET_SEARCH_DISTRIBUTION);

    useQuery<OrganizationOfCurrentUser>(QUERY_ORGANIZATION_CURRENT_USER, {
        skip: localStorage.getItem("user_id") === null,
        onCompleted: (data) => {
            if (data && data.currentUser) {
                setUID(data.currentUser.id);
            }
        },
    });

    useEffect(() => {
        setDist(dist);
    }, [dist]);

    /**
     * Update complete state of single task
     * @param taskId id of task
     * @param boolean bolean to set task to
     */
    const changeDistribution = async (variables: setSearchDistributionVariables) => {
        setStaticLoading(true);
        try {
            await updateDistribution({
                variables: variables,
                onCompleted: () => {
                    // refetch();
                    setTimeout(() => {
                        setStaticLoading(false);
                    }, 500);
                },
            });
        } catch (e) {}
    };

    return (
        <ListItem
            style={{
                borderLeft: type === "opportunity" ? `5px solid ${green}` : type === "tender" ? `5px solid ${blue}` : undefined,
                backgroundColor: bg,
                marginBottom: "5px",
            }}
        >
            <div style={{ marginLeft: "36px" }}>
                <div>
                    {/* activity trigger */}
                    <Typography
                        sx={{
                            display: "inline-block",
                            marginRight: "4px",
                            marginLeft: "4px",
                            color: type === "opportunity" ? green : type === "tender" ? bluelight : "#000000",
                            fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                        }}
                    >
                        {t(`${moment}`) as string}
                    </Typography>
                    {/* <GeneralSelector
                        type={type}
                        paddingTop={"0px"}
                        typoVariant="body1"
                        // set to null to show selected value in a typography component instead of a selectbox (add array of props to get select version)
                        values={[
                            { value: "AT_PUBLICATION", text: t("AT_PUBLICATION") },
                            { value: "AT_AWARD", text: t("AT_AWARD") },
                            { value: "AT_CONTRACT_EXPIRATION", text: t("AT_CONTRACT_EXPIRATION") },
                        ]}
                        // set distribution moment to chosen moment
                        onSelect={(value) => {
                            setDist({ ...disti, moment: value as SearchDistributionMoment });
                        }}
                        selectedValue={t(`${disti.moment}`) as string}
                    /> */}
                    <Typography style={{ display: "inline-block", marginRight: 4 }}>nieuwe resultaten </Typography>
                    {/* worklist trigger, if false -> dont show text in sentence */}
                    {disti.worklist === true && (
                        // <Typography
                        //     sx={{
                        //         display: "inline-block",
                        //         color: type === "opportunity" ? green : type === "tender" ? blue :"#000000",
                        //         fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                        //     }}
                        // >
                        //     tonen in werklijst
                        // </Typography>

                        <DistributionSoloOption
                            type={type}
                            mR={"0px"}
                            typoVariant="body1"
                            selectedValue={disti.worklist === true ? "tonen in werklijst" : ""}
                            onRemove={() => {
                                setDist({ ...disti, worklist: false });
                                changeDistribution({
                                    search_id: id,
                                    user_id: UID,
                                    distribution: {
                                        in_app: disti.in_app,
                                        worklist: false,
                                        email_interval: disti.email_interval,
                                    },
                                });
                            }}
                        />
                    )}
                    {/*
                     * Notification trigger
                     */}
                    {disti.worklist === true && !disti.email_interval && (
                        <Typography
                            sx={{
                                display: "inline-block",
                                marginRight: "4px",
                                marginLeft: "4px",
                                color: type === "opportunity" ? green : type === "tender" ? blue : "#000000",
                                fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                            }}
                        >
                            en per melding sturen
                        </Typography>
                    )}
                    {disti.worklist === true && disti.email_interval && (
                        <Typography
                            sx={{
                                display: "inline-block",
                                marginRight: "4px",
                                color: type === "opportunity" ? green : type === "tender" ? blue : "#000000",
                                fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                            }}
                        >
                            , per melding sturen
                        </Typography>
                    )}
                    {disti.worklist === false && !disti.email_interval && (
                        <Typography
                            sx={{
                                display: "inline-block",
                                marginRight: "4px",
                                color: type === "opportunity" ? green : type === "tender" ? blue : "#000000",
                                fontWeight: type === "opportunity" ? 500 : type === "tender" ? 500 : 400,
                            }}
                        >
                            per melding sturen
                        </Typography>
                    )}
                    {/*
                     *  Email trigger
                     */}
                    {disti.email_interval && (
                        <React.Fragment>
                            {disti.worklist === true && <Typography style={{ display: "inline-block", marginRight: 0 }}>en</Typography>}

                            {disti.email_interval && (
                                <GeneralSelector
                                    openStep={false}
                                    type="tender"
                                    values={[
                                        { value: "HOURLY", text: t("HOURLY") },
                                        { value: "DAILY", text: t("DAILY") },
                                        { value: "WEEKLY", text: t("WEEKLY") },
                                        { value: "MONTHLY", text: t("MONTHLY") },
                                    ]}
                                    onSelect={(value) => {
                                        setDist({
                                            ...disti,
                                            email_interval: value as DistributionIntervalInput,
                                        });

                                        changeDistribution({
                                            search_id: id,
                                            user_id: UID,
                                            distribution: {
                                                in_app: disti.in_app,
                                                worklist: disti.worklist,
                                                email_interval: value as DistributionIntervalInput,
                                            },
                                        });
                                    }}
                                    selectedValue={t(`${disti.email_interval.toUpperCase()}`) as string}
                                    typoVariant="body1"
                                    paddingTop="0px"
                                />
                            )}

                            <DistributionSoloOption
                                type="tender"
                                typoVariant="body1"
                                selectedValue={disti.email_interval !== null ? "per e-mail" : ""}
                                onRemove={() => {
                                    setDist({ ...disti, email_interval: null });
                                    changeDistribution({
                                        search_id: id,
                                        user_id: UID,
                                        distribution: {
                                            in_app: disti.in_app,
                                            worklist: disti.worklist,
                                            email_interval: null,
                                        },
                                    });
                                }}
                            />

                            <Typography style={{ display: "inline-block" }}>sturen</Typography>
                        </React.Fragment>
                    )}
                    {loading || (staticLoading && <CircularProgress size={12} sx={{ marginLeft: "8px" }} />)}
                </div>
                {/* options not set yet */}
                {!(disti.worklist && disti.email_interval) && (
                    <div style={{ display: "flex", paddingLeft: "4px" }}>
                        <Typography sx={{ mr: "4px" }}>Bezorgopties:</Typography>

                        {!disti.worklist && (
                            <Typography
                                sx={{
                                    display: "inline-block",
                                    fontWeight: 600,
                                    color: "#cccccc",
                                    cursor: "pointer",
                                    marginRight: "4px",
                                    "&:hover": {
                                        color: "#465c84",
                                    },
                                }}
                                onClick={() => {
                                    setDist({ ...disti, worklist: true });
                                    changeDistribution({
                                        search_id: id,
                                        user_id: UID,
                                        distribution: {
                                            in_app: disti.in_app,
                                            worklist: true,
                                            email_interval: disti.email_interval,
                                        },
                                    });
                                }}
                            >
                                werklijst
                            </Typography>
                        )}

                        {!disti.email_interval && (
                            <Typography
                                sx={{
                                    display: "inline-block",
                                    marginRight: 0,
                                    fontWeight: 600,
                                    color: "#cccccc",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#465c84",
                                    },
                                }}
                                onClick={() => {
                                    setDist({ ...disti, email_interval: DistributionIntervalInput.DAILY });
                                    changeDistribution({
                                        search_id: id,
                                        user_id: UID,
                                        distribution: {
                                            in_app: disti.in_app,
                                            worklist: disti.worklist,
                                            email_interval: DistributionIntervalInput.DAILY,
                                        },
                                    });
                                }}
                            >
                                e-mail
                            </Typography>
                        )}
                    </div>
                )}
            </div>
        </ListItem>
    );
};

export default SearchLineDistribution;
