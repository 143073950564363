import React from "react";
import { useDrag } from "react-dnd";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { AccountTree, Check, Equalizer, LocalOffer, Star, TaskAlt, ThumbUp, ThumbsUpDown, Today } from "@mui/icons-material";

const ItemType = "COLUMN";
interface Props {
    label: string;
    widget: {
        id: number;
        label: string;
        component: (amountOfWidgets: number) => JSX.Element;
    };
    disabled: boolean;
}

const DragCard: React.FC<Props> = ({ label, widget, disabled }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        canDrag: !disabled,
        item: () => {
            return { widget };
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <Card ref={drag} style={{ opacity: isDragging ? 0.5 : 1, width: "100%" }}>
            <CardContent sx={{ padding: "16px !important" }}>
                <Stack direction={"row"} alignItems={"center"}>
                    <div style={{ marginRight: "12px" }}>
                        <div style={{ width: "50px", display: "flex", flexDirection: "column" }}>
                            <Stack
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "30px",
                                    alignItems: "center",
                                    padding: "2px 0px",
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "darkgrey",
                                        borderRadius: "2px",
                                        height: "2px",
                                        width: "24px",
                                    }}
                                />

                                <Box
                                    sx={{
                                        backgroundColor: "darkgrey",
                                        borderRadius: "2px",
                                        height: "2px",
                                        width: "16px",
                                    }}
                                />
                            </Stack>
                            <Box
                                sx={{
                                    border: "1px solid grey",
                                    borderTop: "2px solid grey",
                                    // borderRadius: "inherit",
                                    display: "flex",
                                    flex: "1 1 auto",
                                    flexDirection: "column",
                                }}
                            >
                                <Stack
                                    direction={"row"}
                                    sx={{
                                        display: "flex",
                                        flex: "1 1 auto",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flex: "1 1 auto",
                                            padding: "2px",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                border: " 1px dashed grey",
                                                borderRadius: "2px",
                                                flex: "1 1 auto",
                                                padding: "4px",
                                            }}
                                        >
                                            {/*
                                             *   Icons in card thumbnail
                                             */}
                                            {widget.id === 1 ? (
                                                <ThumbsUpDown fontSize="small" sx={{ color: "#1733579c" }} />
                                            ) : widget.id === 2 ? (
                                                <ThumbUp fontSize="small" sx={{ color: "#1733579c" }} />
                                            ) : widget.id === 3 ? (
                                                <Star fontSize="small" sx={{ color: "#E036609c" }} />
                                            ) : widget.id === 4 ? (
                                                <Star fontSize="small" sx={{ color: "#F571179c" }} />
                                            ) : widget.id === 5 ? (
                                                <LocalOffer fontSize="small" sx={{ color: "#1733579c" }} />
                                            ) : widget.id === 6 ? (
                                                <Equalizer fontSize="small" sx={{ color: "#1733579c" }} />
                                            ) : widget.id === 7 ? (
                                                <div style={{ height: "16px", width: "16px", backgroundColor: "#9e9e9e9c", borderRadius: "4px" }} />
                                            ) : widget.id === 8 ? (
                                                <TaskAlt fontSize="small" sx={{ color: "#9e9e9e9c" }} />
                                            ) : widget.id === 9 ? (
                                                <AccountTree fontSize="small" sx={{ color: "#9e9e9e9c" }} />
                                            ) : widget.id === 10 ? (
                                                <Today fontSize="small" sx={{ color: "#9e9e9e9c" }} />
                                            ) : (
                                                <Stack
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px",
                                                        margin: "2px",
                                                        alignItems: "center",
                                                        padding: "4px 8px",
                                                        borderRadius: "2px",
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Stack>
                            </Box>
                        </div>
                    </div>

                    <Box flex={1}>
                        <Typography sx={{ fontSize: 14, fontWeight: 500 }} color="text.secondary">
                            {label}
                        </Typography>
                        {/* <Typography variant="body2">well meaning and kindly.</Typography> */}
                    </Box>

                    {disabled && <Check sx={{ color: "#508c79" }} />}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default DragCard;
