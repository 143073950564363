import React from "react";
import Spinner from "./Spinner";
import { Typography } from "@mui/material";

interface Props {
    loadingText?: string;
}

const FullPageSpinner: React.FC<Props> = ({ loadingText }) => {
    return (
        <div className="flex centered v-centered" style={{ height: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Spinner />
                <Typography style={{ marginTop: "16px" }}> {loadingText}</Typography>
            </div>
        </div>
    );
};

export default FullPageSpinner;
