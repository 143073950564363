import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import RenderPage from "../../../components/loading/RenderPage";
import setTitle, { setTitleLoading } from "../../../utils/setTitle";
import OpportunityDetailPage from "./OpportunityDetailPage";
import { useTranslation } from "react-i18next";

import Div404 from "../../Div404";
import { GetOppDetailPageGeneralInfo, GetOppDetailPageGeneralInfoVariables } from "../../../__generated__/GetOppDetailPageGeneralInfo";
import { DynamicTab } from "../../../components/ModuleTabs";
import { QUERY_DETAILPAGE_OF_OPPORTUNITY_GENERAL } from "../../../graphql/queryDefinitions";

interface Props {
    registerTab: (tab: DynamicTab) => void;
}

const OpportunityDetailContainer: React.FC<Props> = ({ registerTab }) => {
    const { id } = useParams();
    const { t } = useTranslation();

    const { loading, error, data } = useQuery<GetOppDetailPageGeneralInfo, GetOppDetailPageGeneralInfoVariables>(
        QUERY_DETAILPAGE_OF_OPPORTUNITY_GENERAL,
        {
            variables: { id: id as string },
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.opportunityFile) {
                    // set name for detailpagetab
                    registerTab({
                        value: `/opportunities/${id}`,
                        label: `${data.opportunityFile?.contractingAuthority.name} - ${data.opportunityFile?.name}` || "",
                    });
                }
            },
        }
    );

    if (loading) {
        setTitleLoading(t);
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    if (error || !data?.opportunityFile) {
        setTitle(t("error-occurred"));
        return (
            <RenderPage>
                <Div404 />
            </RenderPage>
        );
    }
    // Set title of browsertab
    setTitle(`${data.opportunityFile.contractingAuthority.name} - ${data.opportunityFile.name}` || "");
    return (
        <div className="responsive-container topbottom" style={{ height: "100%" }}>
            <OpportunityDetailPage opportunity={data.opportunityFile} />
        </div>
    );
};

export default OpportunityDetailContainer;
