import React, { useState } from "react";
import { Badge, useMediaQuery, useTheme } from "@mui/material";

// Custom components
import SearchRuleData from "../SearchRuleData";
import StatusChip from "../StatusChip";
import LikeTenderButton from "../LikeTenderButton";
import DislikeTenderButton from "../DislikeTenderButton";

// Material ui components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

// Material UI Icons
import { LocalOfferOutlined } from "@mui/icons-material";

// import More from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import ArchiveTender from "../ArchiveTender";
import { Disable } from "react-disable";
import InViewPaginationTenderWorklist from "../InViewPaginationTenderWorklist";
import { GetTenderStateForUser } from "../../__generated__/GetTenderStateForUser";
import { TenderUserStatus } from "../../__generated__/globalTypes";
import UnArchiveTenderButton from "../UnArchiveTenderButton";
import ChatButtonTopBar from "../ChatButtonTopBar";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { QUERY_TENDER_STATE_FOR_USER } from "../../graphql/queryDefinitions";
// import ShareTenderButton from "../ShareTenderButton";
import ShareTenderButton from "../sharetendercomponent/ShareTenderButton";
import AvatarReviewed from "../AvatarReviewed";
import OpenOrCreateProject from "./OpenOrCreateProject";

interface Props {
    /**
     * Id of clicked tender
     */
    tender_id: string;

    /**
     * Status of clicked tender
     */
    status: string;

    /**
     * tender title for chat/archive modal
     */
    tender_title: string | null;
    /**
     * user searches
     */
    // searches: GetDefaultSummary_tender_searchresults[];
    searches: any[];

    /**
     * onOpenSearchRuleWizard to open searchrulewizard
     */
    onOpenSearchRuleWizard: () => void;
}

const TenderTopBar: React.FC<Props> = ({ tender_id, status, tender_title, searches, onOpenSearchRuleWizard }) => {
    const [state, setState] = useState<TenderUserStatus | undefined>();
    const [conversations, setConversations] = useState<boolean>(false);
    const { trackEvent } = useMatomo();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // Track event
    const trackSearchRuleBtn = (event: MatomoEvent) => {
        trackEvent(event);
    };
    const { data } = useQuery<GetTenderStateForUser>(QUERY_TENDER_STATE_FOR_USER, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
        onCompleted(data) {
            if (data && data.tender !== null) {
                setState(data.tender.userStatus);
                setConversations(data.tender.hasConversations);
            }
        },
    });

    const { t } = useTranslation();
    // const [updatesModal, setUpdatesModal] = useState(false);

    const renderSwitch = (tenderState: TenderUserStatus | undefined) => {
        switch (tenderState) {
            case TenderUserStatus.LIKED:
                return (
                    <>
                        <ArchiveTender tender_id={tender_id} buttonSize="medium" title={tender_title} />
                        <Tooltip enterNextDelay={100} placement="top" title={"Reeds als interessant beoordeeld"}>
                            <div>
                                <Disable disabled={true}>
                                    <LikeTenderButton tender_id={""} buttonSize="medium" refetchQueries={[QUERY_TENDER_STATE_FOR_USER]} />
                                </Disable>
                            </div>
                        </Tooltip>
                        <DislikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.DISLIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER]}
                        />{" "}
                    </>
                );

            case TenderUserStatus.DISLIKED:
                return (
                    <>
                        <LikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.LIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER]}
                        />

                        <Tooltip enterNextDelay={100} placement="top" title={"Reeds als niet interessant beoordeeld"}>
                            <div>
                                <Disable disabled={true}>
                                    <DislikeTenderButton tender_id={""} buttonSize="medium" refetchQueries={[QUERY_TENDER_STATE_FOR_USER]} />
                                </Disable>
                            </div>
                        </Tooltip>
                    </>
                );

            case TenderUserStatus.UNREVIEWED:
                return (
                    <>
                        <LikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.LIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER]}
                        />
                        <DislikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.DISLIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER]}
                        />{" "}
                    </>
                );

            case TenderUserStatus.DELETED:
                return (
                    <>
                        <LikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.LIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER]}
                        />
                        <DislikeTenderButton
                            tender_id={tender_id}
                            buttonSize="medium"
                            onClick={() => setState(TenderUserStatus.DISLIKED)}
                            refetchQueries={[QUERY_TENDER_STATE_FOR_USER]}
                        />{" "}
                    </>
                );

            case TenderUserStatus.ARCHIVED:
                return (
                    <>
                        <UnArchiveTenderButton tender_id={tender_id} buttonSize="medium" onClick={() => setState(TenderUserStatus.LIKED)} />
                    </>
                );

            // Fallback
            default:
                return <></>;
        }
    };

    return (
        <React.Fragment>
            {/*
             *      Current status of the tender
             *      TODO: translation
             */}
            <Grid item sm={4} xs={4} style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 16 }}>
                    <StatusChip type={`${status}`} colored={true} />
                </div>

                {/*
                 *   Searchrule labels show if its a match
                 */}
                <SearchRuleData placement={"right"} tender_id={tender_id} marginRight="8px" searches={searches} />
            </Grid>

            {/* 
                    Pagination based on the current list context
                    TODO: Connect to GQL
            */}
            <Grid
                item
                sm={4}
                xs={4}
                sx={{
                    display: onlyMobile ? "none" : "block",
                }}
            >
                <div className="flex centered v-center">
                    <InViewPaginationTenderWorklist listName={t("Worklist")} bgColor="#173357" tender_id={tender_id} />
                </div>
            </Grid>

            {/*
             * TenderActions, different based on list context
             */}
            <Grid item sm={4} xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                {/*
                 * Show avatar of users who liked/disliked tender
                 */}
                <div style={{ marginRight: onlyMobile ? 0 : 48 }}>
                    <AvatarReviewed tender_id={tender_id} placement={"bottom"} />
                </div>

                {/*
                 * Component to show the correct button for projects
                 */}
                <div className="flex right">
                    {/*
                     * popper to create project
                     * hide button on mobile devices
                     */}
                    {!onlyMobile && (
                        <OpenOrCreateProject
                            tender_id={tender_id}
                            tender_title={tender_title}
                            buttonSize="medium"
                            openFromTable={false}
                            refetchQueries={[]}
                        />
                    )}

                    {/*
                     * Create searchrule
                     * Button to create a new searchrule
                     */}
                    {!onlyMobile && (
                        <Tooltip title="Maak zoekregel" placement="top">
                            <IconButton
                                size="medium"
                                onClick={() => {
                                    onOpenSearchRuleWizard();
                                    trackSearchRuleBtn({ category: "Tender", action: "Click-create-searchrule-from-tenderdetail" });
                                }}
                            >
                                <LocalOfferOutlined fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {/*
                     * switchcase on state
                     */}
                    {renderSwitch(state)}

                    {/* Share tender */}
                    <ShareTenderButton tender_id={tender_id} buttonSize="medium" modelType={"App\\Models\\Tender"} title={tender_title} />

                    {/*
                     * Add/edit note of current tender
                     * chat with colleagues/teams
                     */}
                    <Badge
                        sx={{
                            ".MuiBadge-standard": {
                                maxWidth: "16px",
                                minWidth: "16px",
                                minHeight: "16px",
                                maxHeight: "16px",
                                fontSize: "0.6rem",
                                marginRight: "7px",
                                marginTop: "7px",
                            },
                        }}
                        badgeContent={data?.tender?.unreadChatsCount}
                        color="primary"
                        max={999}
                    >
                        <ChatButtonTopBar
                            id={tender_id}
                            name={tender_title || ""}
                            module={"App\\Models\\Tender"}
                            notesAvailable={conversations}
                            location="Tender"
                        />
                    </Badge>
                </div>
            </Grid>
        </React.Fragment>
    );
};

export default TenderTopBar;
