import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import Archive from "@mui/icons-material/Archive";
import { useTranslation } from "react-i18next";
import { useArchiveDialog } from "./contextProviders/ArchiveContext";

interface Props {
    tender_id: string;
    buttonSize: "small" | "medium";
    title: string | null;
}

const ArchiveTender: React.FC<Props> = ({ tender_id, buttonSize, title }) => {
    const { t } = useTranslation();
    const { setArchiveId, setOpenModalArchive, setArchiveTitle } = useArchiveDialog();

    return (
        <React.Fragment>
            <Tooltip title={t("Archive") as string} placement="top">
                <IconButton
                    onClick={(e) => {
                        // Prevent the tab iself being clicked,
                        // we want to execute the click on the close button
                        e.stopPropagation();
                        setOpenModalArchive(true);
                        setArchiveTitle(title || "");
                        setArchiveId(tender_id);
                    }}
                    size={buttonSize}
                >
                    <Archive fontSize="small" />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

export default ArchiveTender;
