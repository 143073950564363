import { Add, Business, Domain } from "@mui/icons-material";
import React, { useContext, useState } from "react";
import AccordionTile from "../AccordionTile";
import { Badge, Box, IconButton, Tooltip, Typography } from "@mui/material";

import SingleCaData from "./SingleCaData";
import { useMutation } from "@apollo/client";
import { UPDATE_CAS_IN_PROJECT } from "../../../../graphql/mutationDefProjects";
import { updateCA, updateCAVariables } from "../../../../__generated__/updateCA";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import { SINGLE_PROJECT } from "../../../../graphql/queryDefProjects";
import AddCaToProject from "./AddCaToProject";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";

interface Props {
    ca_id: string[];
}

const CaWidgetBox: React.FC<Props> = ({ ca_id }) => {
    const { trackEvent } = useMatomo();
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // has to be defined before error otherwise error will be shown instead of add component
    const { project, changeProject } = useContext(ProjectToOpenContext);
    const [load, setLoad] = useState<boolean>(false);
    const [updateCas] = useMutation<updateCA, updateCAVariables>(UPDATE_CAS_IN_PROJECT);
    const [showAddSection, setShowAddSection] = useState<boolean>(false);

    const removeFromArrayAndUpdate = (id: string) => {
        trackClick({ category: "Project", action: "Remove-ca-from-project" });
        const newArr = project.ca_ids.filter((item) => item !== id);
        setLoad(true);
        saveNewCasArray(newArr);
    };

    /**
     * Add new ca to project
     */
    const saveNewCasArray = (newArr: any[]) => {
        trackClick({ category: "Project", action: "Update-cas-in-project" });

        updateCas({
            variables: {
                project_id: project.projectID,
                ca_ids: newArr,
            },
            refetchQueries: [SINGLE_PROJECT],
            onCompleted: () => {
                changeProject({ ca_ids: newArr });
                setLoad(false);
            },
        });
    };

    const renderPage = (open: boolean, summary: React.ReactNode, children: React.ReactNode) => {
        return (
            <AccordionTile open={open} topColor="#F57117" icon={<Business fontSize="medium" />} titleText={summary}>
                {children}
                <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginTop: "8px" }}>
                    <div style={{ width: "100%" }}>
                        {/* Show textmessage if no ca's are added to project and searchbar is hidden */}
                        {ca_id.length === 0 && showAddSection === false && (
                            <Typography style={{ marginBottom: "3px" }}>Geen aanbestedende dienst toegevoegd</Typography>
                        )}
                        {/* Show searchbar */}
                        {showAddSection === true && <AddCaToProject />}
                    </div>
                    <div>
                        <Badge
                            sx={{
                                cursor: "pointer",
                                marginRight: "-2px",
                                "& .MuiBadge-badge": {
                                    right: 8,
                                    top: 8,
                                    border: `1px solid #fff`,
                                    backgroundColor: "#F57117",
                                    padding: "0 2px",
                                    fontSize: "9px",
                                    minWidth: "12px",
                                    height: "16px",
                                },
                            }}
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            badgeContent={
                                <>
                                    <Add sx={{ height: "10px", width: "10px", padding: 0, color: "#ffffff" }} />
                                </>
                            }
                        >
                            <Tooltip placement="left" title="Aanbestedende dienst toevoegen" disableInteractive>
                                <IconButton
                                    onClick={() => {
                                        setShowAddSection(!showAddSection);
                                    }}
                                    size="medium"
                                >
                                    <Domain fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Badge>
                    </div>
                </div>
            </AccordionTile>
        );
    };

    if (ca_id.length === 0) {
        return renderPage(false, <>Aanbestedende dienst</>, <Box component="div" />);
    }

    return renderPage(
        true,
        <>Aanbestedende dienst</>,
        <div>
            {ca_id.map((ca) => {
                return <SingleCaData key={ca} ca_id={ca} remove_id={removeFromArrayAndUpdate} disable={load} />;
            })}
        </div>
    );
};

export default CaWidgetBox;
