import { useMutation } from "@apollo/client";
import React from "react";
import { SWEEP_WORKLIST } from "../../graphql/mutationDefinitions";
import { CleanupTenderWorklist } from "../../__generated__/CleanupTenderWorklist";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { FindReplace } from "@mui/icons-material";
import { toast } from "react-toastify";

interface Props {
    buttonSize: "small" | "medium";
    iconSize: string;
}

const SweepWorklist: React.FC<Props> = ({ buttonSize, iconSize }) => {
    const [sweepWorklist] = useMutation<CleanupTenderWorklist>(SWEEP_WORKLIST);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // submit new password
    const submitUpdatePlanning = async () => {
        try {
            sweepWorklist({
                onCompleted: () => {
                    toast.success(
                        <Typography sx={{ display: "flex", flexDirection: "column" }}>
                            <span>Werklijst wordt opgeschoond.</span>
                            <span>Dit proces kan enige tijd duren.</span>
                        </Typography>
                    );
                    handleClose();
                },
            });
        } catch (e) {}
    };

    return (
        <React.Fragment>
            <Tooltip title="Werklijst opruimen" placement="top">
                <IconButton size={buttonSize} onClick={handleClickOpen}>
                    <FindReplace sx={{ fontSize: iconSize }} />
                </IconButton>
            </Tooltip>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Werklijst opschonen</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Aanbestedingen die niet meer actueel zijn, worden uit de werklijst verwijderd.
                        <br /> <br /> Deze zijn terug te vinden in het tabblad <strong>beoordelingen</strong> <br /> met de filter
                        <strong> Beoordelingen: Niet beoordeeld </strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuleren</Button>
                    <Button onClick={submitUpdatePlanning} variant="contained">
                        Opschonen
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default SweepWorklist;
