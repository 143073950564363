import React, { useState } from "react";
import {
    GetDefaultSummary_tender_lots,
    GetDefaultSummary_tender_omschrijving_source,
    GetDefaultSummary_tender_omvang_opdracht_source,
} from "../../__generated__/GetDefaultSummary";
import { Tabs, Tab, Typography, AppBar, Box, Tooltip, Theme, createStyles } from "@mui/material";
import { useReRunHighlighting } from "../contextProviders/HighlightContext";
import { useTranslation } from "react-i18next";
import { OpenProps as Oprops } from "../tenderDetail/Summary";
import SummaryExpandSingle from "./SummaryExpandSingle";
import { withStyles } from "tss-react/mui";

interface Props {
    lots: GetDefaultSummary_tender_lots[];
    description: string;
    qualitativeScope: string;
    omvang_opdracht_source: GetDefaultSummary_tender_omvang_opdracht_source | null;
    omschrijving_source: GetDefaultSummary_tender_omschrijving_source | null;
    tender_id: string;
    setOpenDoc: React.Dispatch<React.SetStateAction<Oprops>>;
}

interface StyledTabsProps {
    children: any;
    value: string;
    onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
}

const StyledTabs = withStyles(
    (props: StyledTabsProps) => (
        <Tabs {...props} variant="scrollable" scrollButtons="auto" TabIndicatorProps={{ children: <span /> }} textColor="primary" />
    ),
    {
        root: {
            minHeight: 29,
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: 75,
                width: "100%",
                backgroundColor: "#173357",
            },
        },
    }
);

interface StyledTabProps {
    label: React.ReactNode;
    value: string;
}

const StyledTab = withStyles(
    (props: StyledTabProps) => <Tab disableRipple {...props} />,
    (theme: Theme) =>
        createStyles({
            root: {
                minHeight: "35px",
                minWidth: "120px",
                maxWidth: "120px",
                padding: 0,
                textTransform: "none",
                fontSize: theme.typography.pxToRem(15),
                marginRight: theme.spacing(1),
                "&:focus": {
                    opacity: 1,
                },
            },
        })
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const SummaryExpandLots: React.FC<Props> = ({
    lots,
    description,
    qualitativeScope,
    omvang_opdracht_source,
    omschrijving_source,
    tender_id,
    setOpenDoc,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>("algemeen");
    const copyLots = [...lots];
    const { rerunHighlight, setRerunHighlight } = useReRunHighlighting();
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
        setRerunHighlight(!rerunHighlight);
    };

    return (
        <React.Fragment>
            <AppBar elevation={0} position="static" color="transparent">
                <StyledTabs value={value} onChange={handleChange}>
                    <StyledTab
                        value={"algemeen"}
                        label={
                            <>
                                <span
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "100px",
                                        whiteSpace: "nowrap",
                                        display: "inline-block",
                                    }}
                                >{`Algemeen`}</span>
                            </>
                        }
                    />
                    {copyLots
                        .sort((a, b) => parseInt(a.number) - parseInt(b.number))
                        .map((lot) => {
                            return (
                                <StyledTab
                                    value={lot.id}
                                    key={lot.id}
                                    label={
                                        <>
                                            <Tooltip title={`${t("tenderPage.Lot")} ${lot.number} - ${lot.title}`}>
                                                <span
                                                    style={{
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        maxWidth: "100px",
                                                        whiteSpace: "nowrap",
                                                        display: "inline-block",
                                                    }}
                                                >{`${t("tenderPage.Lot")} ${lot.number}`}</span>
                                            </Tooltip>
                                        </>
                                    }
                                />
                            );
                        })}
                </StyledTabs>
            </AppBar>
            <TabPanel value={value} index={value}>
                {value === "algemeen" && (
                    <div style={{ marginBottom: 8, paddingTop: 20 }}>
                        <SummaryExpandSingle
                            tender_id={tender_id}
                            description={description}
                            qualitativeScope={qualitativeScope}
                            omvang_opdracht_source={omvang_opdracht_source}
                            omschrijving_source={omschrijving_source}
                            setOpenDoc={setOpenDoc}
                        />
                    </div>
                )}
                {lots
                    // Filter data on selected value (active tab)
                    .filter((lot) => lot.id === value)
                    .map((lot) => {
                        return (
                            <div key={lot.id} style={{ marginBottom: 8, paddingTop: 20 }}>
                                <SummaryExpandSingle
                                    tender_id={tender_id}
                                    description={lot.description?.value || lot.published_description || "-"}
                                    qualitativeScope={lot.qualitativeScope?.value || ""}
                                    omvang_opdracht_source={lot.qualitativeScope?.source as any}
                                    omschrijving_source={lot.description?.source as any}
                                    setOpenDoc={setOpenDoc}
                                />
                            </div>
                        );
                    })}
            </TabPanel>
        </React.Fragment>
    );
};

export default SummaryExpandLots;
