import { ViewAgenda } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import ColumnTemplateBox from "./ColumnTemplateBox";
import { PROJECT_STATES } from "../../../graphql/queryDefProjects";
import { useMutation } from "@apollo/client";
import { CREATE_NEW_PROJECT_STATE } from "../../../graphql/mutationDefProjects";
import { createState, createStateVariables } from "../../../__generated__/createState";
import PredefinedColumns from "./PredefinedColumns";
import CustomColumns from "./CustomColumns";
import { GetSubscriptionUsers_organization_users } from "../../../__generated__/GetSubscriptionUsers";
import MatomoEvent from "../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    admins: GetSubscriptionUsers_organization_users[];
    cuIsAdmin: boolean;
}

export interface Column {
    name: string;
    order: number;
}

const ColumnTemplateModal: React.FC<Props> = ({ admins, cuIsAdmin }) => {
    // state to show modal
    const [open, setOpen] = useState<boolean>(false);
    const [openRequest, setOpenRequest] = useState<boolean>(false);
    const [chosenEntity, setChosenEntity] = useState<string>("1");
    const [chosenColumns, setChosenColumns] = useState<Column[]>([]);
    const [staticLoad, setStaticLoad] = useState<boolean>(false);

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Create new project state
     */
    const [createNewColumn] = useMutation<createState, createStateVariables>(CREATE_NEW_PROJECT_STATE, {
        refetchQueries: [PROJECT_STATES, "projectStates"],
        awaitRefetchQueries: true,
    });

    /**
     * open request dialog
     */
    const handleOpenRequestAdmins = () => {
        setOpenRequest(true);
    };
    /**
     * close request dialog
     */
    const handleCloseRequestAdmins = () => {
        setOpenRequest(false);
    };

    /**
     * Open modal
     */
    const handleOpenModal = () => {
        setOpen(true);
        setChosenColumns([]);
    };

    /**
     * Close modal
     */
    const handleCloseModal = () => {
        setOpen(false);
        setChosenColumns([]);
    };

    /**
     * @param value name of column
     * @returns return checked or unchecked state of column
     */
    const handleToggle = (e: any, value: Column) => {
        e.stopPropagation();

        const currentIndex = chosenColumns.map((i) => i.name).indexOf(value.name);
        const newChecked = [...chosenColumns];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChosenColumns(newChecked);
    };

    /**
     * Like all selected CA's
     */
    const createChosenColumns = () => {
        const selection = chosenColumns;
        setStaticLoad(true);

        /**
         * if arr is empty, then no columns are chosen
         * if arr is > 0 then selection will be added as column(s)
         */
        if (selection.length > 0) {
            selection.forEach(async (col: Column) => {
                try {
                    await createNewColumn({
                        variables: {
                            name: col.name,
                            order: col.order,
                        },
                        refetchQueries: [PROJECT_STATES, "projectStates"],
                        awaitRefetchQueries: true,
                    });
                } catch (e) {
                    // toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    setStaticLoad(false);
                    handleCloseModal();
                }
            });
            // onClose?.();
        }
        // else {
        //     onClose?.();
        // }
    };

    return (
        <React.Fragment>
            {/* Button */}
            <Box
                sx={{
                    minWidth: "200px",
                    height: "32px",
                    // backgroundColor: "#e1e1e1BF",
                    backgroundColor: (theme) => `${"#707070"}BF`,
                    color: "#ffffff",
                    marginLeft: "8px",
                    marginRight: "8px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "8px",
                    borderRadius: "6px",
                    "&:hover": {
                        cursor: "pointer",
                        // background: "#e1e1e1",
                        backgroundColor: (theme) => `${"#707070"}`,
                        color: "#ffffff",
                    },
                }}
                onClick={() => {
                    cuIsAdmin ? handleOpenModal() : handleOpenRequestAdmins();
                }}
            >
                <ViewAgenda sx={{ rotate: "90deg", mr: "8px" }} /> <Typography variant="button">Voeg kolommen toe</Typography>
            </Box>
            <Dialog
                open={openRequest}
                onClose={handleCloseRequestAdmins}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Kolommen toevoegen</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography sx={{ marginBottom: "8px" }}>
                            Vraag het beheerdersaccount van jouw organisatie om kolommen toe te voegen.
                        </Typography>

                        {admins.map((admin) => {
                            return (
                                <ListItem key={admin.id} dense disablePadding>
                                    <ListItemText
                                        primary={admin.employee.name}
                                        secondary={<Typography variant="caption">{admin.email}</Typography>}
                                    />
                                </ListItem>
                            );
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRequestAdmins} autoFocus>
                        Sluiten
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                PaperProps={{
                    sx: { minHeight: "75vh", minWidth: "80vw", maxWidth: "90vw" },
                }}
                fullWidth
                maxWidth={"xl"}
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="max-width-dialog-title"
            >
                <Grid container columns={20} sx={{ minHeight: "75vh" }}>
                    {/* left panel wt */}
                    <Grid item xs={20} md={4} sx={{ padding: "16px 0px 16px 16px", height: "75vh" }}>
                        <div>
                            <Typography variant="h3" style={{ fontWeight: 500, marginBottom: "12px" }}>
                                Beschikbare sjablonen
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 400, lineHeight: "18px", marginRight: "8px" }}>
                                Selecteer een sjabloon dat het best bij jouw organisatie past. <br />
                            </Typography>
                        </div>

                        {/*
                         * Set with columns to choose
                         */}
                        <div style={{ marginTop: "24px", paddingRight: "16px", height: "90%", overflowY: "auto" }}>
                            {hardcodedData.map((row) => {
                                return (
                                    <ColumnTemplateBox
                                        chosenEntity={chosenEntity}
                                        key={row.id}
                                        item={row}
                                        setChosenEntity={(id) => {
                                            setChosenEntity(id);
                                            setChosenColumns([]);
                                        }}
                                    />
                                );
                            })}
                            <Divider sx={{ mt: "16px", mb: "16px" }} />

                            <ColumnTemplateBox
                                chosenEntity={chosenEntity}
                                item={{ id: "custom", name: "Kies je eigen indeling", explanation: "Maak zelf kolommen aan" }}
                                setChosenEntity={(id) => {
                                    setChosenEntity(id);
                                    setChosenColumns([]);
                                }}
                            />
                        </div>
                    </Grid>

                    {/* Divider */}
                    <Divider orientation="vertical" flexItem sx={{ mr: "-1px", mt: "8px", mb: "8px" }} />

                    {/* Right panel */}
                    <Grid
                        item
                        xs={20}
                        md={16}
                        sx={{ padding: "16px", flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}
                    >
                        {chosenEntity === "custom" ? (
                            <CustomColumns chosenColumns={chosenColumns} setChosenColumns={setChosenColumns} />
                        ) : (
                            <PredefinedColumns
                                hardcodedData={hardcodedData}
                                onToggle={handleToggle}
                                chosenColumns={chosenColumns}
                                chosenEntity={chosenEntity}
                            />
                        )}
                    </Grid>
                </Grid>
                {/* Buttons */}
                <DialogActions>
                    <Button disabled={staticLoad} onClick={handleCloseModal} color="primary">
                        annuleren
                    </Button>
                    <Button
                        disabled={staticLoad}
                        onClick={() => {
                            trackevents({ category: "Project", action: "Create-template" });

                            createChosenColumns();
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Opslaan {staticLoad && <CircularProgress size={14} style={{ marginLeft: 4 }} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

const hardcodedData = [
    {
        id: "1",
        name: "Inschrijvers",
        explanation: "Jouw organisatie schrijft in op aanbestedingen",
        columnsToShow: [
            { name: "Beïnvloeden", text: "Projecten (in de toekomst) die je nog kunt beïnvloeden", order: 2 },
            { name: "Analyseren", text: "Projecten die je nog beter wilt begrijpen", order: 3 },
            { name: "Inschrijven", text: "Projecten waarop je wilt inschrijven", order: 4 },
            { name: "Beoordeling", text: "Projecten waar de uitslag nog niet van bekend is", order: 5 },
            { name: "Evalueren", text: "Projecten waarop je een interne evaluatie wilt uitvoeren", order: 6 },
        ],
    },
    {
        id: "2",
        name: "Toeleverancier",
        explanation: "Jouw organisatie levert goederen of diensten aan inschrijvende partijen",
        columnsToShow: [
            { name: "Beïnvloeden", text: "Projecten (in de toekomst) die je nog kunt beïnvloeden", order: 2 },
            { name: "Benaderen", text: "Projecten waar je proactief marktpartijen bij zoekt", order: 3 },
            { name: "Aanbieden", text: "Projecten waar je een aanvraag voor hebt ontvangen", order: 4 },
            { name: "Beoordeling", text: "Projecten waar de uitslag nog niet van bekend is", order: 5 },
            { name: "Realisatie", text: "Projecten die in uitvoering zijn", order: 6 },
        ],
    },
    {
        id: "3",
        name: "Projectfasering",
        explanation: "Jouw organisatie hanteert een projectfasering",
        columnsToShow: [
            { name: "Initiatie", text: "Projecten waarvoor de basis wordt gelegd", order: 2 },
            { name: "Definitie", text: "Projecten waarvoor eisen en wensen in kaart worden gebracht", order: 3 },
            { name: "Ontwerp", text: "Projecten waarvoor ontwerpen (oplossingsrichtingen) worden bedacht", order: 4 },
            { name: "Realisatie", text: "Projecten die in uitvoering zijn", order: 5 },
            { name: "Nazorg", text: "Projecten waarbij nazorg van toepassing is", order: 6 },
        ],
    },
];

export default ColumnTemplateModal;
