import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Card, CardActionArea, IconButton, Tooltip, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { ContentCopy, Download } from "@mui/icons-material";
import { useAuthSettings } from "./AuthSettingsContext";

interface Props {
    error: boolean;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

// Styled Paper component
const StyledPaper = styled("div")(({ theme }) => ({
    padding: `16px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const ShowRecoveryCodes: React.FC<Props> = ({ error, setActiveStep }) => {
    const { codes } = useAuthSettings();

    const [copied, setCopied] = useState<boolean>(false);
    const [downloaded, setDownload] = useState<boolean>(false);
    // Explain mutation

    // Function to download recovery codes as a .txt file
    const handleDownload = (codes: string[]) => {
        const allCodes = codes.join("\n"); // Join codes with a newline
        const blob = new Blob([allCodes], { type: "text/plain" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a"); // Create an anchor element
        a.href = url;
        a.download = "recovery_codes.txt"; // Set the file name
        document.body.appendChild(a); // Append anchor to body
        a.click(); // Trigger click
        document.body.removeChild(a); // Remove anchor from body
        URL.revokeObjectURL(url); // Clean up the URL object

        setDownload(true);
    };

    // Function to handle copying all recovery codes to clipboard
    const handleCopyAll = (codes: string[]) => {
        const allCodes = codes.join("\n"); // Join codes with a newline
        navigator.clipboard
            .writeText(allCodes)
            .then(() => {
                setCopied(true);
                toast.info("Herstelcodes gekopieerd naar klembord");
            })
            .catch((error) => {
                console.error("Failed to copy: ", error);
            });
    };

    return (
        <StyledPaper>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", width: "100%" }}>
                <Typography sx={{ margin: "16px 0px" }}>Hieronder de herstelsleutels.</Typography>
                <Typography>Bewaar deze herstelsleutels op een beveiligde, maar toegankelijke plaats.</Typography>
                <Card
                    elevation={0}
                    sx={{
                        backgroundColor: "#eee",
                        width: "100%",
                        zIndex: 99,
                        marginTop: "16px",
                    }}
                >
                    <CardActionArea
                        onClick={() => {
                            handleCopyAll(codes);
                        }}
                    >
                        <div style={{ textAlign: "center", margin: "16px", display: "flex" }}>
                            <div style={{ textWrap: "balance", flex: "1" }}>{codes.join(", ")}</div>
                            <Tooltip title="Kopiëren" placement="top">
                                <IconButton>
                                    <ContentCopy sx={{ color: copied ? "#508c79" : "#465c84" }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Downloaden" placement="top">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        handleDownload(codes);
                                    }}
                                >
                                    <Download sx={{ color: downloaded ? "#508c79" : "#465c84" }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </CardActionArea>
                </Card>
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        sx={{ marginTop: "8px", zIndex: 9999 }}
                        size="small"
                        onClick={() => {
                            // cancel();
                            setActiveStep(0);
                        }}
                    >
                        Sluiten
                    </Button>
                </div>
            </div>
        </StyledPaper>
    );
};

export default ShowRecoveryCodes;
