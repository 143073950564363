import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { usePoll } from "../PollContext";
import { Check, KeyboardArrowRight } from "@mui/icons-material";
import HeroDot1 from "../../Hero_GUI/HeroDot1";
import OverlappingDotLeft from "../../Hero_GUI/OverlappingDotLeft";
import OverlappingDotRight from "../../Hero_GUI/OverlappingDotRight";
import WobbleLine from "../../Hero_GUI/WobbleLine";

interface Props {
    hideLogo?: boolean;
    admin?: boolean;
}

const InlinePollLayout: React.FC<Props> = ({ children, hideLogo = false, admin = false }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const { pollArray, navToNextQuestion, getNextQuestionId, currentQuestion, finishCurrentPoll } = usePoll();

    const nextQuestionId = getNextQuestionId(pollArray, currentQuestion?.activeTendenzQuestion?.id as number);

    return (
        <div
            style={{
                height: "70dvh",
                width: `calc(80vw - 48px)`,
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflow: "hidden",
                padding: "0px 32px",
            }}
        >
            {/* ANSWERS */}
            <div style={{ flex: 1, display: "flex", zIndex: 99 }}>{children}</div>

            {admin ? (
                <div>
                    <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
                        {/* <Button sx={{ zIndex: 99 }} variant="text" startIcon={<KeyboardArrowLeft />}>
                            Vorige
                        </Button> */}
                        {nextQuestionId === -1 ? (
                            <IconButton
                                sx={{ zIndex: 99 }}
                                onClick={() => finishCurrentPoll(1)}
                                disabled={(currentQuestion?.activeTendenzQuestion?.id as number) === 0}
                            >
                                <Check />
                            </IconButton>
                        ) : (
                            <IconButton
                                sx={{ zIndex: 99 }}
                                onClick={() => navToNextQuestion(nextQuestionId)}
                                disabled={(currentQuestion?.activeTendenzQuestion?.id as number) === 0}
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        )}
                    </Box>
                </div>
            ) : (
                <div>
                    <Box sx={{ display: "flex", justifyContent: "center", p: 2, alignItems: "center" }} />
                </div>
            )}

            {/* Dot left */}
            {<HeroDot1 color={"#70a4b71A"} top="100px" />}
            {/* Dot  overlap left*/}
            {<OverlappingDotLeft color="#82b6a01A" top={noMobile ? undefined : "300px"} left={noMobile ? undefined : "150px"} />}
            {/* Dot */}
            {<OverlappingDotRight color="#70a4b71A" top={noMobile ? undefined : "550px"} left={noMobile ? undefined : "0px"} />}
            {/* Wobble line */}
            {noMobile && <WobbleLine />}
        </div>
    );
};

export default InlinePollLayout;
