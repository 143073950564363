import React, { useState } from "react";
import { Paper, Popover } from "@mui/material";
import { Divider, IconButton, ListItemText, Tooltip, Typography } from "@mui/material";
import { Close, Delete, Edit, Share } from "@mui/icons-material";
import TooltipOnNewLines from "../../components/TooltipOnNewLines";

import { SavedTenderEvents_getCalendarEvents_UserEvent } from "../../__generated__/SavedTenderEvents";
import moment from "moment";

interface Props {
    content: React.ReactNode;
    data: SavedTenderEvents_getCalendarEvents_UserEvent;
    setId(): void;
    setActiveTooltip: (bln: any) => void;
}

const UserEventTooltip: React.FC<Props> = ({ content, data, setActiveTooltip, setId }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTooltipClose = () => {
        setAnchorEl(null);
        setActiveTooltip(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <React.Fragment>
            <div onClick={handleTooltipOpen}>{content}</div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleTooltipClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <EventPaper data={data} handleTooltipClose={handleTooltipClose} setId={setId} />
            </Popover>
        </React.Fragment>
    );
};

/**
 * @returns Tooltip component with eventbased data/actions
 */
const EventPaper = ({ data: tada, handleTooltipClose, setId }: any) => {
    // const { t } = useTranslation();
    const data: SavedTenderEvents_getCalendarEvents_UserEvent = tada;
    return (
        <Paper style={{ width: "550px", padding: "4px" }}>
            <div
                style={{
                    height: "44px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                    alignItems: "flex-start",
                }}
            >
                <div>
                    <TooltipOnNewLines
                        placement={"bottom-start"}
                        text={data.title || ""}
                        variant={"body1"}
                        lines={2}
                        id={`Tuserx`}
                        fontweight={"normal"}
                        fontSize={16}
                    />
                </div>
                {/*
                 * Event buttons
                 */}
                <div>
                    {/* Edit button */}
                    <Tooltip title="Bewerken">
                        <IconButton>
                            <Edit fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {/* Delete button */}
                    <Tooltip title="Verwijderen">
                        <IconButton>
                            <Delete fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {/* Share button */}
                    <Tooltip title="Delen">
                        <IconButton>
                            <Share fontSize="small" />
                        </IconButton>
                    </Tooltip>

                    {/* Close button */}
                    <Tooltip title="Sluiten">
                        <IconButton onClick={handleTooltipClose}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {/* Div to split header and content field */}
            <Divider />
            <div style={{ padding: "6px" }}>
                <ListItemText
                    primary={<></>}
                    secondary={
                        <div>
                            <Typography>
                                {/* Check if start & end have same time */}
                                {moment(data.start).isSame(moment(data.end), "hours") ? (
                                    <div>
                                        <div>
                                            {/* Header */}
                                            <Typography variant="caption">Datum</Typography>
                                        </div>
                                        <span>
                                            {moment(data.start).format("LL")}, {moment(data.start).format("LT")} uur
                                        </span>
                                    </div>
                                ) : // Check if date is same (start & end)
                                moment(data.start).isSame(moment(data.end), "day") ? (
                                    <div>
                                        <div>
                                            {/* Header */}
                                            <Typography variant="caption">Datum</Typography>
                                        </div>
                                        <span>
                                            {moment(data.start).format("LL")} {moment(data.start).format("LT")} - {moment(data.end).format("LT")} uur
                                        </span>
                                    </div>
                                ) : (
                                    // otherwise show startdate + time and enddate + time as seperate stamps
                                    <div>
                                        <div>
                                            {/* Header */}
                                            <Typography variant="caption">Periode</Typography>
                                        </div>
                                        <span>
                                            {moment(data.start).format("LLL")} {data.start && data.end ? "- " : ""}
                                            {moment(data.end).format("LLL")}
                                        </span>
                                    </div>
                                )}
                            </Typography>
                        </div>
                    }
                />
            </div>
            {/* div contains description of event (optional) */}
            {data.description && (
                <div style={{ padding: "6px" }}>
                    <Typography variant="caption">Omschrijving</Typography>

                    <Typography>{data.description}</Typography>
                </div>
            )}
        </Paper>
    );
};

export default UserEventTooltip;
