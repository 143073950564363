import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import WidgetBox from "../WidgetBox";
import { AppBar, Tab, Tabs, Tooltip, Typography, Box, MenuItem, ListItem, Link, Theme, createStyles } from "@mui/material";
import SingleLotContract from "./SingleLotContract";
import WidgetBoxMenu from "../WidgetBoxMenu";
import ContractPublished from "./ContractPublished";
import DocumentDrawer from "../../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import moment from "moment";
import {
    GetContractInfoForCurrentTender_tender_contract,
    GetContractInfoForCurrentTender_tender_lots,
} from "../../__generated__/GetContractInfoForCurrentTender";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { withStyles } from "tss-react/mui";
import { toast } from "react-toastify";
import HorizontalContractTimeline from "./widgets/contract_timeline/HorizontalContractTimeline";
import ContractWidget from "./ContractWidget";
import { OptieEinde, findLotValue } from "../../utils/contracttimeline";
import { isAllDataInputsNullExceptStartDate } from "../../utils/checkHorizontalTimeLineDates";
interface Props {
    lots: GetContractInfoForCurrentTender_tender_lots[];
    tender_id: string;
    elevation?: number;
    contract_tender_level: GetContractInfoForCurrentTender_tender_contract | null;
    opties: OptieEinde[];
    datum_gunning: string | null;
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

interface StyledTabsProps {
    children: any;
    value: string;
    onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
}

const StyledTabs = withStyles(
    (props: StyledTabsProps) => (
        <Tabs {...props} variant="scrollable" scrollButtons="auto" TabIndicatorProps={{ children: <span /> }} textColor="primary" />
    ),
    {
        root: {
            minHeight: "29px",
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "75px",
                width: "100%",
                backgroundColor: "#173357",
            },
        },
    }
);

interface StyledTabProps {
    label: React.ReactNode;
    value: string;
}

const StyledTab = withStyles(
    (props: StyledTabProps) => <Tab disableRipple {...props} />,
    (theme: Theme) =>
        createStyles({
            root: {
                minHeight: "35px",
                minWidth: "120px",
                maxWidth: "120px",
                padding: 0,
                textTransform: "none",
                fontSize: theme.typography.pxToRem(15),
                marginRight: theme.spacing(1),
                "&:focus": {
                    opacity: 1,
                },
            },
        })
);

interface OpenProps {
    open: boolean;
    link: string;
    label: string;
    doc_id: string;
}

const ContractLotsWidget: React.FC<Props> = ({ lots, tender_id, elevation, contract_tender_level, opties, datum_gunning }) => {
    const { t } = useTranslation();
    const [text, setText] = useState<string>("");
    const [value, setValue] = useState<string>(lots[0].id);
    const copyLots = [...lots];
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));
    const [showSource, setShowSource] = useState(false);
    const [hideWidget, setHideContractWidget] = useState<boolean>(false);
    const [open, setOpen] = useState<OpenProps>({ open: false, link: "", label: "", doc_id: "" });
    const blue = "#173357";

    /**
     * Handler to close drawer and set empty state
     */
    const closeDrawer = () => {
        setOpen({ open: false, link: "", label: "", doc_id: "" });
    };
    /**
     * handler to open clicked tab
     * @param event event is change event
     * @param newValue id of lot
     */
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
        closeDrawer();
        setShowSource(false);
    };

    /**
     * set anchor to clicked target
     */
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Copy function
     */
    const handleCopy = () => {
        navigator.clipboard.writeText(text);
        toast.success(t("Gegevens gekopieërd"), { autoClose: 1500 });
        setAnchorEl(null);
    };
    /**
     * Show source
     */
    const handleSource = async () => {
        setAnchorEl(null);
        await sleep(100);
        setShowSource((s) => !s);
    };

    const { trackEvent } = useMatomo();

    // Track event
    const trackEvents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Show blue line with title only
     */
    if (hideWidget === true) {
        return (
            // <Disable disabled={true}>            </Disable>
            // {/* <WidgetBox header={t("tenderPage.Contract")} highlight="blue" padding={0} /> */}

            <ContractWidget
                datum_gunning={datum_gunning}
                contract={contract_tender_level}
                start={null}
                end={null}
                description={null}
                interval={null}
                period={null}
                renew_desc={null}
                next_looptijd_einde={null}
                looptijd_start={null}
                looptijd_einde_incl_opties={null}
                status_contract={null}
                tender_id={tender_id}
                looptijd_einde={null}
                opties={[]}
            />
        );
    }
    const checkNumberOfLot = lots.length === 1 && lots.some((item) => item.number === "0");

    // Filter data on selected value (active tab)
    const showSourceAsMenuItem = lots.filter((lot) => lot.id === value).filter((item) => item.contract?.source);

    return (
        <WidgetBox
            elevation={elevation}
            header={t("tenderPage.Contract")}
            highlight="blue"
            padding={0}
            actions={
                // TODO: create function to copy, add function to star all organizations
                <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                    <MenuItem
                        onClick={() => {
                            handleCopy();
                            trackEvents({ category: "Tender", action: "Click-copy-contract-data" });
                        }}
                    >
                        {t("CopyData")}
                    </MenuItem>
                    {showSourceAsMenuItem.length > 0 && (
                        <MenuItem
                            onClick={() => {
                                handleSource();
                                trackEvents({ category: "Tender", action: "Click-source-contract" });
                            }}
                        >
                            {showSource ? t("HideSource") : t("ShowSource")}
                        </MenuItem>
                    )}
                </WidgetBoxMenu>
            }
        >
            {!checkNumberOfLot && (
                <AppBar elevation={0} position="static" color="transparent">
                    <StyledTabs value={value} onChange={handleChange}>
                        {copyLots
                            .sort((a, b) => parseInt(a.number) - parseInt(b.number))
                            .map((lot) => {
                                return (
                                    <StyledTab
                                        value={lot.id}
                                        key={lot.id}
                                        label={
                                            <>
                                                <Tooltip title={`${t("tenderPage.Lot")} ${lot.number} - ${lot.title}`}>
                                                    <span
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            maxWidth: "100px",
                                                            whiteSpace: "nowrap",
                                                            display: "inline-block",
                                                        }}
                                                    >{`${t("tenderPage.Lot")} ${lot.number}`}</span>
                                                </Tooltip>
                                            </>
                                        }
                                    />
                                );
                            })}
                    </StyledTabs>
                </AppBar>
            )}
            <TabPanel value={value} index={value}>
                {lots
                    // Filter data on selected value (active tab)
                    .filter((lot) => lot.id === value)
                    .map((lot) => {
                        const source = lot.contract?.source;

                        // Check if date props are not an empty string
                        const hasLooptijdData =
                            Boolean(lot.looptijd_einde) ||
                            Boolean(lot.looptijd_start) ||
                            Boolean(lot.looptijd_periode) ||
                            Boolean(lot.looptijd_interval) ||
                            Boolean(lot.description);
                        const startDate = lot?.contract?.period?.startDate || (lot.looptijd_start && lot.looptijd_start[0]?.value) || null;
                        return (
                            <React.Fragment key={lot.id}>
                                {/* Return enriched contract component */}
                                {lot.contract !== null ? (
                                    <React.Fragment key={lot.id}>
                                        {!isAllDataInputsNullExceptStartDate({
                                            contractEnd: lot?.contract?.period?.endDate,
                                            looptijd_einde: lot.looptijd_einde,
                                            next_looptijd_einde: lot.next_looptijd_einde,
                                        }) && (
                                            <HorizontalContractTimeline
                                                datum_gunning_as_start={Boolean(startDate === null)}
                                                start={lot.contract.period?.startDate || lot.looptijd_start || null}
                                                end={lot.contract.period?.endDate || lot.looptijd_einde || null}
                                                noEndDateAvailable={Boolean(lot.looptijd_einde === null)}
                                                undetermined={Boolean(lot.contract?.undeterminedPeriod)}
                                                options={findLotValue(opties, parseInt(lot.number)) ?? []}
                                            />
                                        )}
                                        <SingleLotContract lot={lot} setCopyText={setText} />
                                    </React.Fragment>
                                ) : // Return contract published component
                                hasLooptijdData ? (
                                    <>
                                        {!isAllDataInputsNullExceptStartDate({
                                            contractEnd: null,
                                            looptijd_einde: lot.looptijd_einde,
                                            next_looptijd_einde: lot.next_looptijd_einde,
                                        }) && (
                                            <HorizontalContractTimeline
                                                datum_gunning_as_start={Boolean(lot.looptijd_start === null)}
                                                options={findLotValue(opties, parseInt(lot.number)) ?? []}
                                                start={lot.looptijd_start || null}
                                                end={lot.looptijd_einde || null}
                                                noEndDateAvailable={Boolean(lot.looptijd_einde === null)}
                                                undetermined={false}
                                            />
                                        )}
                                        <ContractPublished
                                            key={lot.id}
                                            start={lot.looptijd_start}
                                            end={lot.looptijd_einde}
                                            period={lot.looptijd_periode}
                                            interval={lot.looptijd_interval}
                                            description={lot.description ? (lot.description.value as unknown as string) : ("" as string)}
                                            renew_desc={null}
                                            setCopyText={setText}
                                            lot_title={lot.title}
                                        />
                                    </>
                                ) : (
                                    // Else hide widget
                                    setHideContractWidget(true)
                                )}
                                {showSource && source && (
                                    <ListItem style={{ marginTop: 0 }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Typography style={{ fontStyle: "italic", display: "inline", marginRight: 4 }} variant="caption">
                                                {t("Source")}:
                                            </Typography>

                                            {source?.__typename === "Document" ? (
                                                <React.Fragment>
                                                    {source.extension === "pdf" ? (
                                                        <Link
                                                            underline="none"
                                                            component="button"
                                                            style={{ fontStyle: "italic" }}
                                                            variant="caption"
                                                            onClick={() =>
                                                                setOpen({
                                                                    open: true,
                                                                    link: source.temporary_link,
                                                                    label: source.filename,
                                                                    doc_id: source.id,
                                                                })
                                                            }
                                                        >
                                                            {source.filename}
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            underline="none"
                                                            component="button"
                                                            style={{ fontStyle: "italic" }}
                                                            variant="caption"
                                                            onClick={(e) => {
                                                                // Stop click on listitem to prevent document-viewer to initiate
                                                                e.stopPropagation();
                                                                const element = document.createElement("a");
                                                                element.setAttribute("download", `${source.filename}`);
                                                                element.setAttribute(
                                                                    "href",
                                                                    `${process.env.REACT_APP_API_ROOT}/tenders/${tender_id}/downloadFile/${source.id}`
                                                                );
                                                                element.style.display = "none";
                                                                document.body.appendChild(element);
                                                                element.click();
                                                                document.body.removeChild(element);
                                                            }}
                                                        >
                                                            {source.filename}
                                                        </Link>
                                                    )}
                                                </React.Fragment>
                                            ) : source?.__typename === "Publication" ? (
                                                <Typography style={{ fontStyle: "italic" }} variant="caption">
                                                    {moment(source?.dispatchdate).format("LL")} - {source?.host}, {source?.publication_type.name}
                                                </Typography>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </ListItem>
                                )}
                            </React.Fragment>
                        );
                    })}
            </TabPanel>
            {/* show/hide source of description */}

            {/* Drawer that contains clicked pdf file */}
            {typeof open.link === "string" && open.open === true && typeof open.label === "string" && (
                <DocumentDrawer
                    open={open.open}
                    onClose={closeDrawer}
                    title={open.label}
                    color={blue}
                    url={open.link}
                    doc_id={open.doc_id}
                    module_item_id={tender_id}
                />
            )}
        </WidgetBox>
    );
};

export default ContractLotsWidget;
