import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Import Material UI
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";

interface Props {
    updateFilter: (days: number, months: number) => void;
}
const MenuWidget: React.FC<Props> = ({ updateFilter }) => {
    const { t } = useTranslation();
    const [text, setText] = useState("30days");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItem = (item: string) => {
        setText(item);
        handleClose();
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <div>
            <Tooltip title="Periode kiezen" placement="right">
                <Button
                    id="menu-button"
                    variant="contained"
                    size="small"
                    color="grey"
                    sx={(theme) => ({
                        height: 20,
                        fontSize: "0.7rem",
                    })}
                    onClick={handleClick}
                >
                    {t(`dashboardPage.${text}`)}
                </Button>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <MenuList dense autoFocusItem={open} style={{ padding: 0 }}>
                    <MenuItem
                        selected={text === "Today"}
                        id="1"
                        onClick={() => {
                            handleItem("Today");
                            updateFilter(1, 0);
                        }}
                    >
                        {t("dashboardPage.Today")}
                    </MenuItem>
                    <MenuItem
                        selected={text === "7days"}
                        id="2"
                        onClick={() => {
                            handleItem("7days");
                            updateFilter(7, 0);
                        }}
                    >
                        {t("dashboardPage.7days")}
                    </MenuItem>
                    <MenuItem
                        selected={text === "30days"}
                        id="3"
                        onClick={() => {
                            handleItem("30days");
                            updateFilter(30, 0);
                        }}
                    >
                        {t("dashboardPage.30days")}
                    </MenuItem>
                    <MenuItem
                        selected={text === "3months"}
                        id="4"
                        onClick={() => {
                            handleItem("3months");
                            updateFilter(0, 3);
                        }}
                    >
                        {t("dashboardPage.3months")}
                    </MenuItem>
                    <MenuItem
                        selected={text === "6months"}
                        id="5"
                        onClick={() => {
                            handleItem("6months");
                            updateFilter(0, 6);
                        }}
                    >
                        {t("dashboardPage.6months")}
                    </MenuItem>
                    <MenuItem
                        selected={text === "12months"}
                        id="6"
                        onClick={() => {
                            handleItem("12months");
                            updateFilter(0, 12);
                        }}
                    >
                        {t("dashboardPage.12months")}
                    </MenuItem>
                </MenuList>
            </Popover>
        </div>
    );
};

export default MenuWidget;
