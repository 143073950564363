import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import CAstarredTable, { caStarredColumns } from "./CAstarredTable";
import setTitle from "../../../utils/setTitle";
import { useTranslation } from "react-i18next";
import { MUIDataTableState } from "mui-datatables";
import {
    OrganizationsFilterInput,
    ContractingAuthoritiesSearchInput,
    SortOrder,
    ContractingAuthoritiesFilterSource,
} from "../../../__generated__/globalTypes";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { ColumnContext } from "../../../components/contextProviders/ColumnContext";
import {
    FilterMyCAS,
    FilterMyCASVariables,
    FilterMyCAS_filterContractingAuthorities_results,
    FilterMyCAS_filterContractingAuthorities_filters,
} from "../../../__generated__/FilterMyCAS";
import OrganizationSearch from "../../organizations/OrganizationSearch";
import { ColumnProvider } from "../../../components/contextProviders/ColumnProvider";
import ModuleInformation from "../../../components/ModuleInfoBox/ModuleInformation";
import CaContent from "../../../components/ModuleInfoBox/CaContent";
import { QUERY_MY_CONTRACTING_AUTHORITIES } from "../../../graphql/queryDefCA";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CardView from "../../organizations/starredOrganizations/CardView";

const filters: OrganizationsFilterInput = {};
const defaultSearch: ContractingAuthoritiesSearchInput = { source: ContractingAuthoritiesFilterSource.MY_CONTRACTING_AUTHORITIES, filters };
const defaultVariables: FilterMyCASVariables = {
    page: 1,
    count: 10,
    orderField: "last_update_date_timestamp",
    order: SortOrder.DESC,
    search: defaultSearch,
};

// TODO: possibility to search with searchbox. now commented because its not available yet in GQL for organizationmodel

const CAstarredOverview: React.FC = () => {
    const [page, setPage] = useState<number | null | undefined>(defaultVariables.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.count);
    const [orderField, setOrderField] = useState<string>(defaultVariables.orderField);
    const [orderDir, setOrderDir] = useState<SortOrder>(defaultVariables.order);
    const [searchInput, setSearchInput] = useState<OrganizationsFilterInput>({});
    const { t } = useTranslation();
    const [query, setQuery] = useState<string>("");
    const [source] = useState<ContractingAuthoritiesFilterSource>(defaultSearch.source);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterData, setFilterData] = useState<FilterMyCAS_filterContractingAuthorities_filters[] | null>([]);
    const [caData, setCaData] = useState<FilterMyCAS_filterContractingAuthorities_results | null>();
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        if (sessionStorage.getItem("scap")) {
            // set current page to sessionstorage value or 1 if sessionstorage isn't set
            setPage(parseInt(JSON.parse(sessionStorage.getItem("scap") || "1")));
        }
    }, []);

    // Variables for the GQL query
    const variables: FilterMyCASVariables = {
        page: page,
        count: rowsPerPage,
        orderField,
        order: orderDir,
        search: {
            source: source,
            query: query,
            filters: searchInput,
        },
    };

    // Define the GQL query
    const [run, { data, loading, error }] = useLazyQuery<FilterMyCAS, FilterMyCASVariables>(QUERY_MY_CONTRACTING_AUTHORITIES, {
        variables: variables,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (data && data.filterContractingAuthorities) {
            setFilterData(data.filterContractingAuthorities.filters);
            setCaData(data.filterContractingAuthorities.results);
        }
    }, [data]);

    // Set page title
    useEffect(() => {
        setTitle(t("AllCAWithStarCAS"));
        sessionStorage.setItem("scap", JSON.stringify(page));

        run({
            variables: {
                page: page,
                count: rowsPerPage,
                orderField,
                order: orderDir,
                search: {
                    source: source,
                    query: query,
                    filters: searchInput,
                },
            },
        });
    }, [run, orderDir, orderField, page, rowsPerPage, searchInput, t, query, source]);

    const handlePaginationChange = (page: number, rowsPerPage: number) => {
        setPage(page);
        setRowsPerPage(rowsPerPage);
    };

    const handleTableChange = (_: string, tableState: MUIDataTableState) => {
        if (tableState.sortOrder.direction) setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
        if (tableState.sortOrder.name) setOrderField(tableState.sortOrder.name);
    };

    const renderPage = (content: React.ReactNode) => (
        <ColumnProvider storeKey="cuadsc" defaultState={caStarredColumns}>
            <ColumnContext.Provider value={{ selectedRows, setSelectedRows }}>
                <Box component="div" sx={{ padding: { xs: "20px 8px", md: "20px" }, overflow: "scroll" }}>
                    <OrganizationSearch
                        query={query}
                        filterData={filterData}
                        setQuery={setQuery}
                        resetPage={() => setPage(1)}
                        loading={loading}
                        searchInput={searchInput}
                        onChange={setSearchInput}
                        startSearch={() => {
                            setPage(1);
                            run({ variables });
                        }}
                        resetFilters={() => {
                            setSearchInput({});
                            setPage(1);
                        }}
                    />

                    <div style={{ marginTop: 12 }} />

                    {content}
                </Box>
            </ColumnContext.Provider>
            <ModuleInformation title={"Aanbestedende diensten"}>
                <CaContent />
            </ModuleInformation>
        </ColumnProvider>
    );

    if (!caData && loading) return renderPage(<FullPageSpinner />);

    if (error || !caData)
        return renderPage(
            <CAstarredTable
                loading={loading}
                rows={[]}
                paginatorInfo={
                    caData?.paginatorInfo
                        ? caData?.paginatorInfo
                        : {
                              count: 0,
                              currentPage: 0,
                              hasMorePages: false,
                              firstItem: null,
                              lastItem: null,
                              lastPage: 0,
                              perPage: 10,
                              total: 0,
                              __typename: "PaginatorInfo",
                          }
                }
                onChangePagination={handlePaginationChange}
                onTableChange={handleTableChange}
            />
        );

    if (!noMobile) {
        return renderPage(
            <CardView
                type={"ca"}
                data={caData?.data.map((row) => ({
                    id: row.id,
                    name: row.name ?? "",
                    favicon: row.favicon ?? "",
                    country: row.country?.alpha2 ?? "",
                    org_number: row.kvknumber ?? "",
                    last_update: row.last_update_date,
                    place:
                        row.settlings?.data.find((i) => (i.type === "hoofdvestiging" ? i : i.type === "rechtspersoon" ? i : null))?.visit_address
                            ?.city ?? "",
                }))}
                loading={loading}
                paginatorInfo={
                    caData?.paginatorInfo
                        ? caData?.paginatorInfo
                        : {
                              count: 0,
                              currentPage: 0,
                              hasMorePages: false,
                              firstItem: 0,
                              lastItem: 0,
                              lastPage: 1,
                              perPage: 10,
                              total: 0,
                              __typename: "PaginatorInfo",
                          }
                }
                onChangePagination={handlePaginationChange}
            />
        );
    }

    return renderPage(
        <CAstarredTable
            loading={loading}
            rows={caData?.data}
            paginatorInfo={
                caData?.paginatorInfo
                    ? caData?.paginatorInfo
                    : {
                          count: 0,
                          currentPage: 0,
                          hasMorePages: false,
                          firstItem: null,
                          lastItem: null,
                          lastPage: 0,
                          perPage: 10,
                          total: 0,
                          __typename: "PaginatorInfo",
                      }
            }
            onChangePagination={handlePaginationChange}
            onTableChange={handleTableChange}
        />
    );
};

export default CAstarredOverview;
