import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, IconButton, Paper, Tooltip, useMediaQuery } from "@mui/material";
import { Add, Clear, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface Props {
    query: string;
    openModal: () => void;
    themeColor?: string;
    resetQuery: () => void;
    createSearchRule?: () => void;
    searchruleTooltip?: boolean;
}

export const styleOperators = (text: string, themeColor: string, t: any, searchruleTooltip: boolean) => {
    // Regex to match OR, AND, NOT, proximity terms, and quoted terms
    const regex = /\b(OR|AND|NOT)\b|("([^"]+)"~(\d+))|"(.*?)"/g;

    // Initialize an array to hold the styled elements
    const parts: JSX.Element[] = [];
    let lastIndex = 0;

    // Match all occurrences and process each one
    let match: RegExpExecArray | null;
    while ((match = regex.exec(text)) !== null) {
        // Capture the non-matching text (if any) before this match
        if (lastIndex < match.index) {
            parts.push(
                <span style={{ display: "inline", whiteSpace: "nowrap" }} key={`regular-text-${parts.length}`}>
                    {text.slice(lastIndex, match.index)}
                </span>
            );
        }

        // Check if the match is an operator (OR, AND, NOT)
        const operator = match[1];
        if (operator) {
            parts.push(
                <span
                    key={`operator-${parts.length}`}
                    style={{
                        color: "white",
                        fontWeight: "500",
                        borderRadius: "4px",
                        padding: searchruleTooltip ? "0px" : "4px 8px",
                        // margin: "0px 4px",
                        backgroundColor: searchruleTooltip ? "none" : themeColor,
                        fontSize: "12px",
                        display: "inline",
                    }}
                >
                    {t(operator)}
                </span>
            );
        }

        // Check if the match is a proximity search (e.g., "iphones ipads"~5)
        const proximityMatch = match[2];
        const proximityNum = match[4];
        if (proximityMatch && proximityNum) {
            const words = match[3].split(" ");
            const proximity = Number(proximityNum); // Ensure it's a number
            parts.push(
                <span key={`proximity-${parts.length}`} style={{ display: "inline", whiteSpace: "nowrap" }}>
                    <span style={{ color: themeColor, fontWeight: "500", display: "inline" }}>{words[0]}</span> binnen een{" "}
                    <span
                        style={{
                            color: "white",
                            fontWeight: "500",
                            borderRadius: "4px",
                            padding: searchruleTooltip ? "0px" : "4px 8px",
                            backgroundColor: searchruleTooltip ? "none" : themeColor,
                            display: "inline",
                            fontSize: "12px",
                        }}
                    >
                        {t("NEAR")}
                    </span>{" "}
                    {t("of")}{" "}
                    <span
                        style={{
                            color: "white",
                            fontWeight: "500",
                            borderRadius: "4px",
                            padding: searchruleTooltip ? "0px" : "4px 8px",
                            backgroundColor: searchruleTooltip ? "none" : themeColor,
                            display: "inline",
                            fontSize: "12px",
                        }}
                    >
                        {proximity}
                    </span>{" "}
                    {t("words_of")} <span style={{ color: themeColor, fontWeight: "500", display: "inline" }}>{words[1]} </span>
                </span>
            );
        }

        // Check if the match is a quoted term (e.g., "iphones")
        const quotedTerm = match[5];
        if (quotedTerm) {
            parts.push(
                <span key={`quoted-term-${parts.length}`} style={{ color: themeColor, fontWeight: "500" }}>
                    {quotedTerm}
                </span>
            );
        }

        // Update the last index processed
        lastIndex = regex.lastIndex;
    }

    // If there's any text remaining after the last match, add it
    if (lastIndex < text.length) {
        parts.push(<span key={`regular-text-${parts.length}`}>{text.slice(lastIndex)}</span>);
    }

    return <>{parts}</>;
};

const AdvancedQueryField: React.FC<Props> = ({
    query,
    openModal,
    themeColor = "#173357",
    resetQuery,
    createSearchRule,
    searchruleTooltip = false,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [hover, setHover] = useState(false);
    const grey = "#707070";

    return (
        <Tooltip open={hover} title="Zoekopdracht bewerken" followCursor placement="top">
            <Paper
                onClick={openModal}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                square
                sx={{
                    padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: onlyMobile ? "100%" : "438px",
                    cursor: hover ? "pointer" : "auto",
                    backgroundColor: hover ? "#eeeeee" : "#ffffff",
                }}
            >
                {/* Searchicon */}
                <Search style={{ margin: "10px 0px 10px 6px", color: grey }} />
                <Box
                    sx={{
                        padding: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: onlyMobile ? "100%" : "438px",
                        overflowX: "auto", // Allow horizontal scroll
                        scrollbarWidth: "none", // Firefox (to hide the scrollbar)
                        msOverflowStyle: "none", // Internet Explorer and Edge (to hide the scrollbar)
                        whiteSpace: "nowrap",
                    }}
                >
                    <span>{styleOperators(query, themeColor, t, searchruleTooltip)}</span>
                </Box>
                {createSearchRule && (
                    <Tooltip placement="right" title="Zoekregel maken" disableInteractive>
                        <IconButton
                            color="primary"
                            size="small"
                            aria-label="directions"
                            onMouseOver={(e) => {
                                e.stopPropagation();
                                setHover(false);
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                createSearchRule();
                            }}
                        >
                            <Add color="primary" />
                        </IconButton>
                    </Tooltip>
                )}
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                {/*
                 * RESET BUTTON
                 */}
                <Tooltip placement="right" title="Zoekopdracht verwijderen" disableInteractive>
                    <IconButton
                        color="primary"
                        size="small"
                        aria-label="directions"
                        onMouseOver={(e) => {
                            e.stopPropagation();
                            setHover(false);
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            resetQuery();
                        }}
                    >
                        <Clear color="error" />
                    </IconButton>
                </Tooltip>
            </Paper>
        </Tooltip>
    );
};

export default AdvancedQueryField;
