import React, { useState, useEffect, useContext } from "react";
import { InputAdornment, List, TextField, CircularProgress, Popper, Autocomplete, ListItemButton, ListItemText } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import FaviconBox from "../../../../components/FaviconBox";
import { SortOrder, TenderFilterSource, TenderFilterTarget } from "../../../../__generated__/globalTypes";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import { SINGLE_PROJECT } from "../../../../graphql/queryDefProjects";
import {
    SearchTenderForProject,
    SearchTenderForProjectVariables,
    SearchTenderForProject_filterTenders_results_data,
} from "../../../../__generated__/SearchTenderForProject";
import { updateTenderInProject, updateTenderInProjectVariables } from "../../../../__generated__/updateTenderInProject";
import { UPDATE_TENDERS_IN_PROJECT } from "../../../../graphql/mutationDefProjects";
import MatomoEvent from "../../../../models/MatomoEvent";
import TooltipOnNewLines from "../../../../components/TooltipOnNewLines";
import StatusDot from "../../../../components/StatusDot";

interface Props {
    a?: number;
}

// Custom popper
// - disabled flip
const CustomPopper = (props: any) => {
    return (
        <Popper
            {...props}
            placement="bottom"
            disablePortal={false}
            modifiers={{
                flip: {
                    enabled: false,
                },
            }}
        />
    );
};

const AddCaToProject: React.FC<Props> = (props) => {
    const [options, setOptions] = useState<SearchTenderForProject_filterTenders_results_data[]>([] as any[]);
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState<string>("");
    const [load, setLoad] = useState<boolean>(false);
    const { project, changeProject } = useContext(ProjectToOpenContext);
    const [updateTenders] = useMutation<updateTenderInProject, updateTenderInProjectVariables>(UPDATE_TENDERS_IN_PROJECT);

    const { trackSiteSearch, trackEvent } = useMatomo();

    // track navigation items
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const addItemToArrayAndUpdate = (id: string) => {
        const newArr = project.tender_ids.concat(id);
        setLoad(true);
        saveNewTenderArray(newArr);
    };

    /**
     * Add new task to array
     */
    const saveNewTenderArray = (newArr: any[]) => {
        updateTenders({
            variables: {
                project_id: project.projectID,
                tender_ids: newArr,
            },
            refetchQueries: [SINGLE_PROJECT],
            onCompleted: () => {
                changeProject({ tender_ids: newArr });
                setLoad(false);
            },
        });
    };

    // Track documentsearch
    const trackDocumentSearch = (q: string) => {
        trackSiteSearch({ keyword: q, category: "Tender", href: window.location.href });
    };

    // Define the GQL query
    const { loading, data } = useQuery<SearchTenderForProject, SearchTenderForProjectVariables>(QUERY_SEARCH_TENDER_FOR_PROJECT, {
        skip: query.length <= 3,
        variables: {
            page: 1,
            count: 100,
            orderField: "updated_at",
            order: SortOrder.DESC,
            search: {
                source: TenderFilterSource.ALL_TENDERS,
                target: TenderFilterTarget.SUMMARY,
                query: query,
            },
        },
        fetchPolicy: "network-only",
    });

    const debouncedSearch = debounce(async (newQuery) => {
        setQuery(newQuery);
    }, 300);

    const handleQueryChange = (newQuery: string) => {
        debouncedSearch(newQuery);
        if (newQuery.length > 2) {
            trackDocumentSearch(newQuery);
        }
    };

    useEffect(() => {
        let active = true;

        if (loading === true) {
            return undefined;
        }

        (async () => {
            if (active && data?.filterTenders?.results?.data !== undefined) {
                setOptions(data?.filterTenders?.results?.data);
            }
        })();

        return () => {
            active = false;
        };
    }, [data?.filterTenders?.results?.data, loading, query]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        // Searchbar with dropdownlist
        <React.Fragment>
            <Autocomplete
                disableCloseOnSelect={false}
                size="small"
                open={open}
                onOpen={() => {
                    if (query.length > 3 && options.length > 0) {
                        setOpen(true);
                    }
                }}
                onClose={() => {
                    setOpen(false);
                }}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option.namegiventocontract as string}
                options={options}
                loading={loading}
                loadingText=""
                noOptionsText={"Geen resultaten gevonden"}
                style={{ flexGrow: 1, margin: "0px" }}
                PopperComponent={CustomPopper}
                renderOption={(props, option, state, ownerState) => {
                    const clientA = option.clients.find((ca) => ca.pivot?.category === "A");
                    return (
                        <React.Fragment>
                            {/* liststyle when system has matching documents */}
                            <List dense style={{ flexGrow: 1, padding: 0, margin: 0 }}>
                                <ListItemButton
                                    sx={{
                                        maxWidth: "423px",
                                        display: "flex",
                                        alignItems: "center",
                                        margin: 0,
                                    }}
                                    disabled={load || project.tender_ids.includes(option.id) || project.tender_ids.length >= 1}
                                    onClick={() => {
                                        if (!project.tender_ids.includes(option.id)) {
                                            trackClick({ category: "Project", action: "Add-tender-in-empty-project" });
                                            addItemToArrayAndUpdate(option.id);
                                        }
                                    }}
                                >
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div>
                                            <FaviconBox
                                                favicon={clientA?.favicon_resized ?? clientA?.favicon ?? null}
                                                name={clientA?.name || option.published_opdrachtgever}
                                                color="#F57117"
                                            />
                                        </div>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                sx: {
                                                    width: "97%",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                },
                                            }}
                                            primary={
                                                <TooltipOnNewLines
                                                    text={option.namegiventocontract}
                                                    variant={"body1"}
                                                    lines={1}
                                                    id={`TW${option.id}`}
                                                    fontweight={"normal"}
                                                    fontSize={"14"}
                                                    placement={"top"}
                                                />
                                            }
                                            secondary={clientA ? clientA.name : option.published_opdrachtgever}
                                        />
                                        <div>
                                            <StatusDot type={option.status} placement={"left"} />
                                        </div>
                                    </div>
                                </ListItemButton>
                            </List>
                        </React.Fragment>
                    );
                }}
                // searchIcon in textfield
                renderInput={(params) => {
                    params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    );
                    return (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Aanbesteding zoeken..."
                            onChange={(e) => {
                                handleQueryChange(e.target.value);
                                e.target.value.length <= 3 ? setOpen(false) : setOpen(true);
                            }}
                            sx={{ marginRight: "16px" }}
                            value={query}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    );
                }}
            />
        </React.Fragment>
    );
};

export default AddCaToProject;

//? get all contracting authorities with pagination
export const QUERY_SEARCH_TENDER_FOR_PROJECT = gql`
    query SearchTenderForProject($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                data {
                    id
                    namegiventocontract
                    status
                    searchresults {
                        search {
                            id
                            name
                            color
                        }
                    }
                    deadline_inschrijving
                    country {
                        name
                        alpha2
                        numeric
                    }
                    clients {
                        id
                        name
                        favicon
                        favicon_resized
                        pivot {
                            category
                        }
                    }
                    published_opdrachtgever
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;
