import React from "react";
import { Box, Tooltip } from "@mui/material";
import { Priority } from "../../../../__generated__/globalTypes";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface Props {
    priority: Priority | null;
    updatePrio?: (prio: Priority) => void;
    dot: boolean;
    disabled: boolean;
}

const TaskPriorityChip: React.FC<Props> = ({ priority, updatePrio, dot, disabled }) => {
    const { t } = useTranslation();

    return dot ? (
        <Tooltip disableInteractive title={t(`${priority}`)} placement="top">
            <div
                onClick={() => {
                    if (priority === Priority.MEDIUM) {
                        updatePrio && updatePrio(Priority.HIGH);
                    }
                    if (priority === Priority.HIGH) {
                        updatePrio && updatePrio(Priority.LOW);
                    }
                    if (priority === Priority.LOW) {
                        updatePrio && updatePrio(Priority.MEDIUM);
                    }
                }}
                style={{ cursor: "pointer", display: "flex" }}
            >
                {priority === Priority.HIGH ? (
                    <KeyboardArrowUp sx={{ color: "#C60505" }} />
                ) : priority === Priority.LOW ? (
                    <KeyboardArrowDown sx={{ color: "#2E76FF" }} />
                ) : (
                    <div
                        style={{
                            color: "#f7b642",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "28px",
                            marginTop: "-3px",
                        }}
                    >
                        =
                    </div>
                )}
            </div>
        </Tooltip>
    ) : (
        <Tooltip disableInteractive title={"Prioriteit"} placement="left">
            <Box
                component="div"
                sx={{
                    mx: "8px",
                    width: "65px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: priority === Priority.LOW ? "#2E76FF" : priority === Priority.HIGH ? "#C60505" : "#f7b642",
                    cursor: disabled ? "default" : "pointer",
                    borderRadius: "3px",
                }}
                onClick={() => {
                    if (priority === Priority.MEDIUM) {
                        updatePrio && updatePrio(Priority.HIGH);
                    }
                    if (priority === Priority.HIGH) {
                        updatePrio && updatePrio(Priority.LOW);
                    }
                    if (priority === Priority.LOW) {
                        updatePrio && updatePrio(Priority.MEDIUM);
                    }
                }}
            >
                <span
                    style={{
                        borderRadius: 3,
                        color: "#fff",
                        boxSizing: "border-box",
                        display: "inline-block",
                        fontSize: 11,
                        fontWeight: 600,
                        lineHeight: 1,
                        maxWidth: "100%",
                        padding: 4,
                        textTransform: "uppercase",
                        verticalAlign: "baseline",
                    }}
                >
                    {t(`${priority}`)}
                </span>
            </Box>
        </Tooltip>
    );
};

export default TaskPriorityChip;
