import React from "react";
import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

//TODO: translation v4.x

interface Props {
    openFirstTime: (item: string) => void;
}

const FirstSearchLine: React.FC<Props> = ({ openFirstTime }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const blue = "#173357";
    const green = "#225E4D";
    const white = "#ffffff";
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} md style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Typography variant="h5">{t("firstSearchRule.NoRule")}</Typography>
                    <Typography style={{ margin: "5px" }}>{t("firstSearchRule.RuleHelper")}</Typography>
                    <Typography>{t("firstSearchRule.Tip")}</Typography>
                </Grid>
                {noMobile ? (
                    <Grid item xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "center", marginTop: 32 }}>
                        <Button
                            onClick={() => openFirstTime("tender")}
                            variant="contained"
                            style={{ marginRight: 16, backgroundColor: blue, color: white }}
                        >
                            Aanbestedingen
                        </Button>{" "}
                        <Button onClick={() => openFirstTime("opportunity")} variant="contained" style={{ backgroundColor: green, color: white }}>
                            Kansen
                        </Button>
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "center", marginTop: 32 }}>
                        <Typography>Zoekregels kunnen niet op een mobiele telefoon worden aangemaakt.</Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default FirstSearchLine;
