import React, { useEffect } from "react";
import setTheme from "../utils/setTheme";
import setTitle from "../utils/setTitle";
import MainLayout from "../components/layout/MainLayout";
import { useTranslation } from "react-i18next";

//Imported Material UI components
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { greyTheme } from "../assets/styles";
import { Tabs, Tab, Button, useMediaQuery } from "@mui/material";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import DashboardBasePlate from "./Dashboard/editabledashboard/DashboardBasePlate";
import { useRowsContext } from "./Dashboard/editabledashboard/RowsContext";
import MatomoEvent from "../models/MatomoEvent";

// Chart.register(ChartDataLabels);

const Dashboard = () => {
    const { trackPageView, trackEvent } = useMatomo();
    const { t } = useTranslation();
    const { setOpen } = useRowsContext();
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        setTheme("darkGray");
        setTitle("Dashboard");
    });

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: "Dashboard", // optional
            href: "https://tool.tender.guide/dashboard", // optional
        });
    }, [trackPageView]);

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const tracking = () => {
        track({ category: "Dashboard", action: "Click-open-edit-dashboard", href: window.location.href });
        setOpen(true);
    };

    return (
        <ThemeProvider theme={greyTheme}>
            <MainLayout
                page="dashboard"
                header={
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{ width: `calc(100vw - 68px)`, height: "100%" }}
                        sx={{
                            "& .MuiTabs-root": {
                                height: "100%",
                            },
                            "& .MuiTabs-flexContainer": {
                                height: "100%",
                            },
                        }}
                    >
                        <Tab sx={{ paddingLeft: "48px" }} value="/tenders" label={t("dashboardPage.Dashboard")} />
                    </Tabs>
                }
            >
                <div className="responsive-container topbottom" style={{ height: "100%" }}>
                    {noMobile && (
                        <Button
                            variant="contained"
                            size="small"
                            aria-label="edit"
                            sx={{
                                zIndex: 600,
                                position: "absolute",
                                top: 16,
                                right: 32,
                            }}
                            onClick={tracking}
                        >
                            aanpassen
                        </Button>
                    )}

                    <DashboardBasePlate />
                </div>
            </MainLayout>
        </ThemeProvider>
    );
};

export default Dashboard;
