import { gql } from "@apollo/client";

//? get name and favicon for single ca
export const GET_CA_DATA_FOR_FILTERITEM = gql`
    query GetCADataForFilterItem($ca_id: ID!) {
        contracting_authority(id: $ca_id) {
            id
            name
            favicon
            favicon_resized
        }
    }
`;

// ? Get favicon code
export const QUERY_ALL_FAVICONS_OF_CAS = gql`
    query contracting_authority_favicon_to_show($id: ID!) {
        contracting_authority(id: $id) {
            id
            favicon
            favicon_resized
        }
    }
`;

//? get id and name for detailpage
//? show name in tab
export const QUERY_CADETAILS = gql`
    query GetCADetails($id: ID!) {
        contracting_authority(id: $id) {
            id
            name
            favicon
            favicon_resized
            status
            settlings(first: 10, page: 1) {
                data {
                    id
                    type
                }
            }
            tenders {
                id
            }
        }
    }
`;

//? Get contacts of CA
export const QUERY_CONTACTS = gql`
    query GetCADetailsContacts($id: ID!) {
        contracting_authority(id: $id) {
            id
            employees {
                cellphone
                email
                familyname
                function
                givenname
                id
                linkedin
                middlename
                name
                phonenumber
                organization {
                    id
                    name
                }
            }
        }
    }
`;

//? get all contracting authorities with pagination
export const QUERY_ALL_CONTRACTING_AUTHORITIES = gql`
    #set to active when generate types, work around for now
    query FilterCAS($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: ContractingAuthoritiesSearchInput) {
        filterContractingAuthorities(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    name
                    logo
                    favicon
                    favicon_resized
                    types
                    last_update_date
                    country {
                        alpha2
                        name
                        numeric
                    }
                    kvknumber

                    #settlings
                    settlings(first: 10, page: 1) {
                        data {
                            id
                            visit_address {
                                id
                                city
                            }
                            type
                        }
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get general info of clicked contracting authority
export const QUERY_ALL_NAMES_OF_CA = gql`
    query GetCaNamesAndInActiveNames($id: ID!) {
        contracting_authority(id: $id) {
            id
            names {
                id
                type
                value
            }

            inactive_names {
                id
                type
                value
            }
        }
    }
`;

//? get general info of clicked contracting authority
export const QUERY_CA_GENERAL_INFO = gql`
    query GetCAGeneralInfo($id: ID!) {
        contracting_authority(id: $id) {
            id
            name
            kvknumber
            types
            country {
                alpha2
                name
                numeric
            }
            logo
            favicon
            favicon_resized
            emails {
                id
                email
                type
            }
            urls {
                id
                url
                type
            }

            postal_addresses {
                id
                street
                housenumber
                housenumber_suffix
                country
                city
                postal_code
            }
            settlings(first: 10, page: 1) {
                data {
                    id
                    name
                    faxnumber
                    type
                    actief
                    phonenumber
                    visit_address {
                        id
                        city
                        street
                        country
                        housenumber
                        postal_code
                        housenumber_suffix
                    }
                }
            }
            phonenumbers {
                id
                phonenumber
                type
            }
        }
    }
`;

//? get top five tenderers for CA
export const QUERY_TOP_FIVE_TENDERERS_FOR_CA = gql`
    query GetTopFiveTenderersForCA($id: ID!) {
        contracting_authority(id: $id) {
            id
            most_awarded_to {
                client {
                    id
                    name
                    favicon
                    favicon_resized
                }
                aantal
            }
        }
    }
`;

//? get top five sectors for CA
export const QUERY_TOP_FIVE_SECTORS_FOR_CA = gql`
    query GetTopFiveSectorsForCA($id: ID!) {
        contracting_authority(id: $id) {
            id
            most_tenders_in {
                sector {
                    id
                    name
                }
                aantal
            }
        }
    }
`;

//? get purchasing policy data of clicked contracting authority
export const QUERY_PURCHASINGPOLICIES = gql`
    query GetCaPurchasingPolicies($id: ID!) {
        contracting_authority(id: $id) {
            id
            urls {
                id
                url
                type
            }
            thresholds {
                cat
                currency
                id
                subcat
                sum_from
                sum_to
            }
        }
    }
`;

//? get purchasing policy data of clicked contracting authority
export const QUERY_TRESHOLDS = gql`
    query GetCaTresholds($id: ID!) {
        contracting_authority(id: $id) {
            id
            thresholds {
                cat
                currency
                id
                subcat
                sum_from
                sum_to
            }
        }
    }
`;

//? get all starred contracting authorities
export const QUERY_MY_CONTRACTING_AUTHORITIES = gql`
    query FilterMyCAS($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: ContractingAuthoritiesSearchInput) {
        filterContractingAuthorities(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            filters {
                title
                topFilter
                active
                searchRuleFilter
                values {
                    identifier
                    label
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    name
                    logo
                    favicon
                    favicon_resized
                    types
                    last_update_date
                    country {
                        alpha2
                        name
                        numeric
                    }
                    kvknumber

                    #settlings
                    settlings(first: 10, page: 1) {
                        data {
                            id
                            visit_address {
                                id
                                city
                            }
                            type
                        }
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

//? get five latest activities to show in dashboard
export const QUERY_FIVE_LATEST_CA_ACTIVITIES = gql`
    query GetFiveLatestCaActivities($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: ContractingAuthoritiesSearchInput) {
        filterContractingAuthorities(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                data {
                    # CA data
                    id
                    name
                    logo
                    favicon
                    favicon_resized
                    country {
                        alpha2
                        name
                        numeric
                    }
                    kvknumber
                    last_update_text
                    last_update_date
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

// ? Get all liked tenders of a user
export const QUERY_TOP_FIVE_CAS_SUGGESTIONS = gql`
    query top5Cas {
        getTopContractingAuthorities {
            id
            name
            favicon
            favicon_resized
        }
    }
`;
