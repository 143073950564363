import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useCurrentUserOrganization } from "../../../components/contextProviders/CurrentUserOrganization";
import { QUERY_SUBSCRIPTION_USERS } from "../../../graphql/queryDefUserAndTeams";
import { GetColleagues } from "../../../__generated__/GetColleagues";
import { GetSubscriptionUsers } from "../../../__generated__/GetSubscriptionUsers";
import {
    userIsNotCu,
    //  userIsNotCu,
    // userIsTeamMember,
} from "../../../utils/messenger";
// import { Team } from "../../../components/tenderNote/ConversationsList";
import Avatars from "../projectModal/Avatars";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import { QUERY_COLLEAGUES } from "../../../graphql/queryDefChat";

interface Props {
    a?: number;
}

export interface ShareSelectionItem {
    id: string;
    team: boolean;
    conversation: boolean;
}

const UserAndTeamsSection: React.FC<Props> = (props) => {
    const { org_id } = useCurrentUserOrganization();
    const { project, changeProject } = useContext(ProjectToOpenContext);
    const [searchTerm] = useState<string>("");

    /**
     * Query colleagues of user to create contactlist
     */
    const colleagueTeams = useQuery<GetColleagues>(QUERY_COLLEAGUES);

    /**
     * Query colleagues of user to create contactlist
     */
    const colleagues = useQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
    });

    /**
     * Contactlist of user
     */
    const contacts =
        colleagues.data?.organization?.users
            .map((user) => ({
                id: user.id || "",
                name: user.employee.name || "",
                familyName: user.employee.familyname || "",
            }))
            .filter((colleague) => userIsNotCu(colleague)) || [];

    /**
     * Create array of teams where current user is a teamMember
     */
    // const teams =
    //     colleagueTeams.data?.currentUser.employee.organization.teams
    //         .filter((team) => userIsTeamMember(team))
    //         .map((team) => ({
    //             teamId: team.id,
    //             teamName: team.name,
    //             members:
    //                 team.users.map((user) => ({
    //                     id: user.id || "",
    //                     name: user.employee.givenname || "",
    //                     familyName: user.employee.familyname || "",
    //                 })) || [],
    //         })) || [];

    /**
     * key that has to be unique in array below
     */
    // const key = "teamId";

    /**
     * Create array of unique teams
     */
    // const teamArrayUniqueByKey = [...new Map(teams.map((item: Team) => [item[key], item])).values()];

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and givenname
     */
    // const results = teamArrayUniqueByKey.filter((element) => {
    //     // 👇️ using OR (||) operator
    //     return (
    //         element.teamName?.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
    //         element.members.some((e) => e.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
    //     );
    // });

    // Create array with unqiue team objects
    // const teamResults = !searchTerm ? teamArrayUniqueByKey : results;

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and givenname
     */
    const contactresults = !searchTerm ? contacts : contacts.filter((item) => item.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    //  id name initials
    const userMerge = contactresults.map((contact) => ({
        id: contact.id,
        name: contact.name,
        initials: `${contact.name.slice(0, 1)}${contact.familyName.slice(0, 1)}`,
    }));

    //  Id name initials
    // const teamMerge = teamResults.map((team) => ({
    //     id: team.teamId,
    //     name: team.teamName,
    //     initials:
    //         team.teamName
    //             .match(/\b(\w)/g)
    //             ?.slice(0, 2)
    //             .join("") || "",
    //     users: team.members.map((member) => ({
    //         id: member.id,
    //         name: member.name,
    //         familyName: member.familyName,
    //     })),
    // }));

    // const checkLength = Boolean(contactresults.filter((i) => i.id !== localStorage.getItem("user_id")).length + teamResults.length === 0);

    return (
        <React.Fragment>
            <div style={{ display: "flex", marginLeft: "-8px", paddingTop: "8px", paddingLeft: "16px", alignItems: "center" }}>
                <Box component="div" sx={{ mx: "8px" }}>
                    <Avatars
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        size="medium"
                        chosenUsers={project.users}
                        users={userMerge}
                        teams={[]}
                        editMode
                        loading={colleagues.loading || colleagueTeams.loading}
                        setUsers={(users) => changeProject({ users: users })}
                        amounToShow={25}
                    />
                </Box>
            </div>
        </React.Fragment>
    );
};

export default UserAndTeamsSection;
