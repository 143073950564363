import React, { useState, useRef } from "react";
import { Line, getElementAtEvent } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import moment from "moment";
import { EndingContracts, EndingContracts_currentUser_endingContracts } from "../../../__generated__/EndingContracts";
import { useNavigate } from "react-router-dom";
import "chart.js/auto";
import { GetUserSearchRules } from "../../../__generated__/GetUserSearchRules";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { GET_ENDING_CONTRACTS, QUERY_SEARCHRULES } from "../../../graphql/queryDefCurrentUser";

interface Props {
    setQuarterArray: (quarterArray: React.SetStateAction<(EndingContracts_currentUser_endingContracts | null)[]>) => void;
}
const LineGraph: React.FC<Props> = ({ setQuarterArray }) => {
    const { trackEvent } = useMatomo();
    const chartRef = useRef<any>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenderLine = "#3d5a80";
    const bgTenderLine = "#3d5a80" + 40;
    const searchRules = "#688AB6";
    const bgsearchRules = "#688AB6" + 40;
    const [contract, setContract] = useState<(EndingContracts_currentUser_endingContracts | null)[]>([]);
    const { data } = useQuery<GetUserSearchRules>(QUERY_SEARCHRULES);

    // Track event
    const clickElement = () => {
        trackEvent({ category: "Dashboard", action: "clicked-on-linechart" });
    };

    // Fetch first 5 items of worklist to show on dashboardData
    // TODO: on like refetch to 5 items

    const { loading } = useQuery<EndingContracts>(GET_ENDING_CONTRACTS, {
        onCompleted: (data) => {
            if (data && data.currentUser && data.currentUser.endingContracts !== null) {
                setContract(data.currentUser.endingContracts);
                setQuarterArray(data.currentUser.endingContracts);
            }
        },
    });

    if (loading) {
        return <FullPageSpinner />;
    }

    const arrayForSort = [...contract];

    // Sort contracts
    const sortedContracts = arrayForSort.sort((a, b) => {
        // change string 'Q1 2022' to "2022 1", now sorting works first on year and then on number
        const stringA = `${a?.quarter?.split(" ").pop()} ${a?.quarter?.slice(1, 3)}`;
        const stringB = `${b?.quarter?.split(" ").pop()} ${b?.quarter?.slice(1, 3)}`;
        return moment(stringA) > moment(stringB) ? 1 : -1;
    });

    // Line chart data labels and datasets
    const lineChartData = {
        labels: sortedContracts.map((i) => i?.quarter),
        datasets: [
            {
                label: t("dashboardPage.MySearchrules"),
                data: sortedContracts.map((i) => i?.searchResults),
                fill: {
                    target: "origin",
                    above: `${bgTenderLine}`,
                },
                backgroundColor: `${tenderLine}`,
                borderColor: `${tenderLine}`,
            },
            {
                label: t("dashboardPage.Interesting"),
                data: sortedContracts.map((i) => i?.liked),
                backgroundColor: `${searchRules}`,
                borderColor: `${searchRules}`,
                fill: {
                    target: "origin",
                    above: `${bgsearchRules}`,
                },
            },
        ],
    };

    const elementAtEvent = (e: any) => {
        // remove key on element click to avoid filter mistakes
        sessionStorage.removeItem("rtf");

        const element = getElementAtEvent(chartRef.current, e);

        if (element.length > 0) {
            const clickedElementIndex = element[0].index;
            const clickedDatasetLabel = lineChartData.labels[clickedElementIndex];
            // clicked quarter
            const quarter = clickedDatasetLabel;
            // push id of searchrule(s) to new array
            const newArr = data?.currentUser.searches.map((search) => parseInt(search.id));
            /**
             * 0 -> searchrule line
             * Onclick store filters in sessionstorage and navigate to reviews
             */
            if (element[0].datasetIndex === 0) {
                sessionStorage.setItem("rtf", JSON.stringify({ searches: newArr, enddate_contract_quarters: [quarter], reviews: [-1, 0, 1, 2] }));
                navigate(`/tenders/reviews`);
            }

            /**
             * 1 -> searchrule line
             * Onclick store filters in sessionstorage and navigate to reviews
             */
            if (element[0].datasetIndex === 1) {
                sessionStorage.setItem("rtf", JSON.stringify({ enddate_contract_quarters: [quarter], reviews: [1] }));
                navigate(`/tenders/reviews`);
            }
            clickElement();
            // const clickedDatasetPoint = lineChartData.datasets[clickedDatasetIndex];

            // Clicked quarter in chart
            // const year = clickedDatasetPoint.label;
            // set clicked quarter as param in url
        }
    };

    return (
        <Line
            id="lineChart"
            ref={chartRef}
            onClick={elementAtEvent}
            data={lineChartData}
            options={{
                onHover(event, elements, chart) {
                    const test = event.native?.target as any;
                    test.style.cursor = elements[0] ? "pointer" : "default";
                },
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0.3,
                    },
                },
                transitions: {
                    show: {
                        animations: {
                            colors: {
                                type: "color",
                                duration: 2000,
                                from: "transparent",
                            },
                        },
                    },
                    hide: {
                        animations: {
                            colors: {
                                type: "color",
                                duration: 2000,
                                to: "transparent",
                            },
                        },
                    },
                },
                scales: {
                    xAxis: {
                        beginAtZero: true,
                        display: true,
                        ticks: {
                            precision: 0,
                            align: "center",
                        },
                        grid: {
                            lineWidth: 0,
                        },
                    },
                    yAxis: {
                        beginAtZero: true,
                        display: true,
                        ticks: {
                            precision: 0,
                            callback: function (value, index, values) {
                                return value;
                            },
                        },
                    },
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        position: "bottom",
                        labels: {
                            usePointStyle: true,
                        },
                    },
                    title: {
                        display: false,
                        text: "not visible",
                    },
                },
            }}
        />
    );
};

export default LineGraph;
