import { TaskAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";

interface Props {
    errorText: string;
}

const MyTasksWidgetError: React.FC<Props> = ({ errorText }) => {
    return (
        <div style={{ height: 355, width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            {<TaskAlt style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />}
            <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                {errorText}
            </Typography>
        </div>
    );
};

export default MyTasksWidgetError;
