import { Box, CardActions, CardContent, List, Paper, Skeleton, Stack } from "@mui/material";
import React from "react";

interface Props {
    a?: number;
}

const MyProjectsWidgetLoader: React.FC<Props> = (props) => {
    return (
        <List
            sx={{
                overflow: "auto",
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                width: "100%",
                height: { xs: "335px", md: "355px" },
                position: "relative",
                padding: "8px",
            }}
        >
            {[...Array(3)].map((elem, index) => {
                return (
                    <React.Fragment key={index}>
                        <Paper elevation={0} variant="outlined" sx={{ backgroundColor: "#ffffff", zIndex: 99, marginBottom: "8px" }}>
                            <CardContent>
                                <Stack direction="row" justifyContent={"space-between"}>
                                    <Skeleton variant="text" width={200} />
                                </Stack>

                                {/* <Typography variant="subtitle2" color={"text.secondary"} sx={{ marginbottom: "-4px", marginTop: "4px" }}>
                                    Taken
                                </Typography> */}

                                {/* <TaskProgressBar done={tasksDone} totalAmount={totalTasks} /> */}
                                <Skeleton variant="text" width={60} height={12} sx={{ marginTop: "4px" }} />
                                <Skeleton />
                            </CardContent>
                            <CardActions disableSpacing>
                                <Stack
                                    width={"100%"}
                                    display={"flex"}
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    sx={{ margin: "0px 8px" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Skeleton variant="rounded" sx={{ bgcolor: "#2E76FF4d" }} width={40} height={20} />
                                    </div>
                                    {/*
                                     * Box with small icons and badges
                                     * - badge with amount of users
                                     * - badge with amount of tasks
                                     */}
                                    <Box
                                        sx={{
                                            color: "#9e9e9e",
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                            marginBottom: "4px",
                                            marginTop: "12px",
                                        }}
                                    >
                                        <Skeleton variant="circular" sx={{ marginRight: "4px" }} width={16} height={16} />
                                        <Skeleton variant="circular" sx={{ marginRight: "4px" }} width={16} height={16} />
                                        <Skeleton variant="circular" width={16} height={16} />
                                    </Box>
                                    {/* <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                                    <ExpandMoreIcon />
                                </ExpandMore> */}
                                </Stack>
                            </CardActions>
                        </Paper>
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default MyProjectsWidgetLoader;
