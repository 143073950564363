import React from "react";
import ProfileMenu from "./ProfileMenu";
import { useProfile } from "../../contextProviders/ProfileOpenerContext";

interface Props {
    anchorEl?: Element | null;
    handleClose(): void;
    opened: boolean;
}

const ProfileBox: React.FC<Props> = ({ anchorEl, handleClose, opened }) => {
    const { givenname, middlename, familyname, org_name, org_logo, org_favicon } = useProfile();

    return (
        // {/* Profile menu */}
        <ProfileMenu
            anchorEl={anchorEl ? anchorEl : null}
            opened={opened && Boolean(anchorEl)}
            givenName={givenname}
            middleName={middlename}
            familyName={familyname}
            favicon={org_favicon}
            logo={org_logo}
            org_name={org_name}
            handleClose={handleClose}
            handleLogout={() => {
                // Remove token => session will be gone
                localStorage.removeItem("access_token");
                localStorage.removeItem("user_id");
                localStorage.removeItem("initials");
                localStorage.removeItem("version");
                sessionStorage.clear();
                // navigate to login page
                window.location.replace("/login");
            }}
        />
    );
};

export default ProfileBox;
