import React from "react";

// Material UI components
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { TenderFilterInput } from "../../__generated__/globalTypes";
import update from "immutability-helper";
import EuroIcon from "@mui/icons-material/Euro";
export type IntRange = { from: number; to: number };

interface Props {
    setOpenFilter(open: string | boolean): void;
    IntRangeState: IntRange;
    onChange(searchInput: TenderFilterInput): void;
    searchInput: TenderFilterInput;
    // Pass through to template component
    label: string;
    setIntRange(IntRangeState: IntRange): void;
}
export type DropdownOption = { key: string; label: string };

const IntRangeFilterItem: React.FC<Props> = ({ onChange, setOpenFilter, IntRangeState, searchInput, label, setIntRange, children }) => {
    const { t } = useTranslation();

    /**
     * Error
     */
    const error = IntRangeState.from !== null && IntRangeState.to !== null && IntRangeState.from > IntRangeState.to;

    /**
     * on click save button -> set selected items, reset filter to empty string and close selected filter
     */
    const handleClose = () => {
        handleSave();
        setOpenFilter(false);
    };

    const handleChange = (prop: keyof IntRange) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setIntRange({ ...IntRangeState, [prop]: parseInt(event.target.value) });
    };

    /**
     * Handler when the popover gets closed
     * onError => unset filter to avoid errors in GUI
     * onSuccess => set filter to selected Values
     */
    const handleSave = () => {
        if (error) {
            return onChange(update(searchInput, { $unset: [label.toLocaleLowerCase() as keyof TenderFilterInput] }));
        }

        onChange(
            update(searchInput, {
                [label.toLocaleLowerCase()]: {
                    $set: {
                        from: IntRangeState.from,
                        to: IntRangeState.to,
                    },
                },
            })
        );
    };

    return (
        <React.Fragment>
            <div style={{ overflow: "auto", flex: 1 }}>
                <div style={{ padding: 16, display: "flex", alignItems: "center" }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={12} style={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                                label={"Minimale waarde"}
                                type="number"
                                helperText={isNaN(IntRangeState.from) && <Typography variant="caption">Leeg veld niet toegestaan</Typography>}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ color: (theme) => `${"#000000"}8a` }}>
                                            <EuroIcon />
                                        </InputAdornment>
                                    ),
                                    inputProps: { max: IntRangeState.to },
                                }}
                                value={IntRangeState.from}
                                onChange={handleChange("from")}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} style={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                                label={"Maximale waarde"}
                                type="number"
                                helperText={isNaN(IntRangeState.to) && <Typography variant="caption">Leeg veld niet toegestaan</Typography>}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ color: (theme) => `${"#000000"}8a` }}>
                                            <EuroIcon />
                                        </InputAdornment>
                                    ),
                                    inputProps: { min: IntRangeState.from },
                                }}
                                value={IntRangeState.to}
                                onChange={handleChange("to")}
                            />
                        </Grid>
                        <Grid item sm={12} style={{ display: "flex", justifyContent: "center" }}>
                            <Typography variant="caption" sx={{ color: (theme) => `${"#000000"}8a` }}>
                                {error && "Minimale waarde dient lager te zijn dan de maximale waarde"}
                            </Typography>
                        </Grid>
                        {/* Component contains child elements (variable content) */}
                        <Grid item sm={12} style={{ paddingTop: 48 }}>
                            {children}
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", margin: "24px" }}>
                {/*
                 * ResetButton
                 * setDateRange => set to null instead of a date
                 * unset the filter to avoid errors in GUI
                 *  */}
                <Button
                    sx={{ color: "#000000" }}
                    onClick={() => {
                        setIntRange({ from: 0, to: 0 });
                        onChange(update(searchInput, { $unset: [label as keyof TenderFilterInput] }));
                    }}
                    variant="text"
                >
                    {t("filter.reset")}
                </Button>

                {/*
                 * Button to save selected dates
                 * Disabled if field === null
                 */}
                <Button
                    style={{ color: "white" }}
                    disabled={
                        IntRangeState.from === null ||
                        IntRangeState.to === null ||
                        error ||
                        isNaN(IntRangeState.from) ||
                        isNaN(IntRangeState.to) ||
                        (IntRangeState.from === 0 && IntRangeState.to === 0)
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                >
                    {t("filter.save")}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default IntRangeFilterItem;
