import React, { useState } from "react";
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    ListItemSecondaryAction,
    Divider,
    Button,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CountryFlagBox from "../boxes/CountryFlagBox";
import FaviconBox from "../FaviconBox";
import StatusChip from "../StatusChip";

interface Props {
    participants: any;
}

const BasicPublished: React.FC<Props> = ({ participants }) => {
    const { t } = useTranslation();
    const ItemsPerClick = 5;
    // state for number of items to show
    const [noOfItems, setnoOfItems] = useState(5);
    const theme = useTheme();
    const pink = "#E03660";
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    /**
     * Handler to show more items onclick,
     * get value of noOfItems and add value of ItemsPerClick.
     */
    const handleShowMoreItems = () => {
        setnoOfItems(noOfItems + ItemsPerClick);
    };

    /**
     * Handler to show less items onclick,
     * get value of noOfItems and add value of ItemsPerClick.
     */
    const handleShowLess = () => {
        setnoOfItems(5);
    };
    return (
        <React.Fragment>
            {/* List with bids and information */}
            <List
                sx={{
                    width: "100%",
                }}
            >
                {/*
                 * Listitem with general information of lot
                 * Map participants
                 */}

                {Array.isArray(participants) &&
                    participants?.map((item: any, i: number) => {
                        // Change "EUR" to "€"
                        const currencySign = item.definitieve_waarde?.toString().replace("EUR", "€");
                        // Change dot to comma
                        const dotToComma = currencySign?.replace(/\./g, ",");
                        // Add dots, example: 187995,43 -> 187.995,43
                        const value = dotToComma?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");

                        // State to show items based on state of items to show
                        const itemsToShow = item?.contractors?.slice(0, noOfItems);

                        return (
                            <ListItem key={i} style={{ padding: "8px 16px" }}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Table size="small" sx={{ width: "100%" }}>
                                                <TableBody>
                                                    {/* Datum_gunning */}
                                                    {item.datum_gunning && (
                                                        <TableRow>
                                                            <TableCell
                                                                sx={{
                                                                    width: onlyMobile ? "50%" : "75px",
                                                                    color: "#000000",
                                                                    borderBottom: "none",
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Typography>Datum gunning</Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    width: onlyMobile ? "50%" : "75%",
                                                                    color: "#000000",
                                                                    borderBottom: "none",
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Typography style={{ whiteSpace: "pre-wrap" }}>
                                                                    {moment(item.datum_gunning).format("LL")}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}

                                                    {/* Opdracht_nummer */}
                                                    {item.opdracht_nummer && (
                                                        <TableRow>
                                                            <TableCell
                                                                sx={{
                                                                    width: onlyMobile ? "50%" : "75px",
                                                                    color: "#000000",
                                                                    borderBottom: "none",
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Typography>Opdracht nummer</Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    width: onlyMobile ? "50%" : "75%",
                                                                    color: "#000000",
                                                                    borderBottom: "none",
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Typography style={{ whiteSpace: "pre-wrap" }}>{item.opdracht_nummer}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}

                                                    {/* Definitieve_waarde */}
                                                    {item.definitieve_waarde && (
                                                        <TableRow>
                                                            <TableCell
                                                                sx={{
                                                                    width: onlyMobile ? "50%" : "75px",
                                                                    color: "#000000",
                                                                    borderBottom: "none",
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Typography>Definitieve waarde</Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    width: onlyMobile ? "50%" : "75%",
                                                                    color: "#000000",
                                                                    borderBottom: "none",
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                                    {value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}

                                                    {/* Aantal_inschrijvingen */}
                                                    {item.aantal_inschrijvingen && (
                                                        <TableRow>
                                                            <TableCell
                                                                sx={{
                                                                    width: onlyMobile ? "50%" : "75px",
                                                                    color: "#000000",
                                                                    borderBottom: "none",
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Typography>Aantal inschrijvers</Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    width: onlyMobile ? "50%" : "75%",
                                                                    color: "#000000",
                                                                    borderBottom: "none",
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <Typography style={{ whiteSpace: "pre-wrap" }}>
                                                                    {item.aantal_inschrijvingen}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    <div style={{ height: 16 }} />
                                                </TableBody>
                                            </Table>

                                            {/* Map winners  */}
                                            {itemsToShow?.map((c: any, i: number) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <ListItem style={{ padding: "8px 0px" }}>
                                                            {/* Favicon with 2 pink letters */}
                                                            <div style={{ marginRight: 8 }}>
                                                                <FaviconBox marginTop={0} favicon={null} name={c.name} color={pink} />
                                                            </div>
                                                            {/* Listitemtext with amount and percentage */}
                                                            <ListItemText
                                                                style={{ marginRight: 100 }}
                                                                primary={<Typography noWrap>{c.name}</Typography>}
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Typography
                                                                            style={{ whiteSpace: "pre-wrap", display: "flex", alignItems: "center" }}
                                                                        >
                                                                            {c.address && <>{c.address}, </>}
                                                                            {c.zipcode && <>{c.zipcode} </>}
                                                                            {c.city && <>{c.city} </>}
                                                                            {c.country && (
                                                                                // Show flag
                                                                                <CountryFlagBox code={c.country} />
                                                                            )}
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                            />

                                                            {/* Label that shows who won */}
                                                            <ListItemSecondaryAction
                                                                style={{ paddingBottom: 8, display: "flex", alignItems: "center" }}
                                                            >
                                                                <Typography>{c.definitieve_waarde}</Typography>
                                                                <StatusChip colored={false} type="winner" />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>

                                                        {i === item.contractors.length - 1 ? <></> : <Divider />}
                                                    </React.Fragment>
                                                );
                                            })}

                                            {/* Button to load more contractors */}
                                            {item?.contractors?.length > 5 && (
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    {itemsToShow?.length !== item?.contractors?.length ? (
                                                        <Button
                                                            size="small"
                                                            variant="text"
                                                            color="primary"
                                                            style={{ marginBottom: 8 }}
                                                            onClick={handleShowMoreItems}
                                                        >
                                                            {t("tenderPage.LoadMore")}
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            size="small"
                                                            variant="text"
                                                            color="primary"
                                                            style={{ marginBottom: 8 }}
                                                            onClick={handleShowLess}
                                                        >
                                                            {t("tenderPage.ShowLess")}
                                                        </Button>
                                                    )}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        );
                    })}
            </List>
        </React.Fragment>
    );
};
export default BasicPublished;
