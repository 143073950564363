import React, { useState } from "react";
import { Button, Popover, MenuList, MenuItem, ListItemIcon, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useTranslation } from "react-i18next";

interface Props {
    openTenderWizard: () => void;
    openOpportunityWizard: () => void;
}

const WizardMenu: React.FC<Props> = ({ openTenderWizard, openOpportunityWizard }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const green = "#225E4D";
    const blue = "#173357";
    /**
     *
     * @param event Open popover underneath button
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    /**
     * close popover
     */
    const handleClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    return (
        <React.Fragment>
            <Button
                color="grey"
                onClick={(e) => {
                    handleClick(e);
                }}
                variant="contained"
                endIcon={<ArrowDropDownIcon />}
            >
                Maak zoekregel
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                    handleClose(e);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <MenuList autoFocusItem={open} style={{ padding: 0 }}>
                    <MenuItem
                        onClick={(e) => {
                            // open tenderwizard
                            openTenderWizard();
                            // close menu
                            handleClose(e);
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                            <LocalOfferIcon fontSize="small" style={{ color: blue }} />
                        </ListItemIcon>
                        <Typography sx={{ padding: "4px 8px" }}>{t("tenders")}</Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            // TODO: add function to open opportunity wizard
                            openOpportunityWizard();
                            // close menu
                            handleClose(e);
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                            <FindInPageIcon fontSize="small" style={{ color: green }} />
                        </ListItemIcon>
                        <Typography sx={{ padding: "4px 8px" }}>{t("opportunities")}</Typography>
                    </MenuItem>
                </MenuList>
            </Popover>
        </React.Fragment>
    );
};

export default WizardMenu;
