import React from "react";
import { AccordionDetails, AccordionSummary, List, Accordion as MuiAccordion, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Disable } from "react-disable";
import { Link } from "react-router-dom";
import { GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_results_data_personalOpportunities } from "../../__generated__/GetAllDocsForOpportunitiesOnTender";

import PersonalOpportunityField from "./PersonalOpportunityField";
import SnippetField from "./SnippetField";
import FaviconBox from "../FaviconBox";
import { withStyles } from "tss-react/mui";

interface Props {
    defaultExpanded?: boolean;
    expanded: string | false;
    clicked: string;
    handleChange: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    name: string;
    snippets?: any;
    caName: string;
    favicon: string | null;
    opp_id: string;
    personalOpps?: (GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_results_data_personalOpportunities | null)[] | null;
}

const Accordion = withStyles(MuiAccordion, {
    root: {
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

const OpportunityAccordionPerDoc: React.FC<Props> = ({
    expanded,
    clicked,
    handleChange,
    defaultExpanded,
    name,
    snippets,
    caName,
    favicon,
    opp_id,
    personalOpps,
}) => {
    return (
        <Disable disabled={false}>
            <Accordion
                expanded={expanded === clicked}
                onChange={snippets && snippets.length === 0 ? undefined : handleChange}
                defaultExpanded={defaultExpanded}
                TransitionProps={{
                    timeout: 100,
                }}
            >
                <AccordionSummary
                    expandIcon={snippets && snippets.length === 0 ? <></> : personalOpps && personalOpps.length === 0 ? <></> : <ExpandMore />}
                >
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        {/* name of doc and contracting authority  */}
                        <Link
                            to={`/opportunities/${opp_id}`}
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                        >
                            <Typography style={{ display: "flex", alignItems: "center", color: "#000" }}>
                                <FaviconBox favicon={favicon} name={caName} color={"#F57117"} />
                                {name} - {caName}
                            </Typography>
                        </Link>
                    </div>
                </AccordionSummary>
                {/* snippets in doc */}
                <AccordionDetails style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <List id="doclisting" dense style={{ width: "100%", padding: 0, marginBottom: 10 }}>
                        {snippets && <SnippetField snippets={snippets} />}
                        {personalOpps && <PersonalOpportunityField personalOpps={personalOpps} opp_id={opp_id} />}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Disable>
    );
};

export default OpportunityAccordionPerDoc;
