import React from "react";
import { Card, Typography } from "@mui/material";

interface Props {
    eventName: string;
    eventTime: string;
    image: string;
    reverseImg: boolean;
}

const StaticEventCard: React.FC<Props> = ({ eventName, eventTime, image, reverseImg }) => {
    return (
        <Card
            elevation={1}
            style={{
                flex: 1,
                borderRadius: "16px",
                position: "relative",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#82b6a0",
                height: "100%",
                width: "100%",
            }}
        >
            <img
                style={{
                    filter: `grayscale(100%) contrast(0.5) blur(0)`,
                    flex: `1 0 100%`,
                    height: `100%`,
                    maxWidth: `100%`,
                    mixBlendMode: "multiply",
                    objectFit: "cover",
                    position: "relative",
                    width: "100%",
                    objectPosition: "100% 0%",
                    transform: reverseImg ? "scaleX(-1)" : "none",
                }}
                src={image}
                alt=""
            />
            <div
                style={{
                    width: "100%",
                    position: "absolute",
                    padding: "12px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#fff", // Text color
                    fontSize: "24px", // Adjust font size as needed
                    textAlign: "center",
                    zIndex: 2,
                }}
            >
                <Typography variant="h1" style={{ width: "100%", color: "#fff" }} gutterBottom fontWeight={500} textAlign={"center"}>
                    {eventName}
                </Typography>
                <Typography variant="h5" style={{ width: "100%", color: "#fff", lineHeight: 1 }} fontWeight={500} textAlign={"center"}>
                    {eventTime}
                </Typography>
            </div>
        </Card>
    );
};

export default StaticEventCard;
