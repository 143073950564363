import React from "react";
import { Avatar, Chip, Grid, IconButton, Paper, Skeleton, Typography } from "@mui/material";
import { LocalOffer, Message, Share } from "@mui/icons-material";
import LikeTenderButton from "../../../components/LikeTenderButton";
import { Disable } from "react-disable";
import DislikeTenderButton from "../../../components/DislikeTenderButton";
// import ShareTenderButton from "../../../components/ShareTenderButton";

interface Props {
    elevation?: number;
}

const SplitDetailViewLoader: React.FC<Props> = ({ elevation = 0 }) => {
    return (
        <Paper elevation={elevation} square style={{ height: "72.6vh", padding: "20px" }}>
            <Grid container style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                {/* Topbox
                 * Contains status, title and CA of document
                 */}
                <Grid
                    item
                    sx={{
                        display: "flex",
                        marginBottom: "16px",
                    }}
                >
                    {/*
                     * Left panel
                     * contains: tender_name, ca_name, city
                     */}
                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div>
                                {/*
                                 * Favicon CA of opportunity document
                                 */}
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Chip style={{ color: "#eeeeee", backgroundColor: "#eeeeee", marginRight: 8 }} size="small" label="gegund" />
                                    <LocalOffer style={{ color: "#eeeeee" }} />
                                </div>
                                <div style={{ margin: "8px" }} />
                                <Typography variant="body1" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 5 }}>
                                    <Typography style={{ marginRight: 10 }}>
                                        <div
                                            style={{
                                                marginTop: 4,
                                                height: 18,
                                                minWidth: 18,
                                                maxWidth: 18,
                                                marginRight: 4,
                                                borderRadius: 6,
                                                backgroundColor: "#eeeeee",
                                            }}
                                        />
                                    </Typography>
                                    <Typography style={{ marginTop: 3 }}>
                                        <Skeleton height={18} animation="wave" width={130} />
                                    </Typography>
                                </Typography>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography style={{ marginRight: 10 }}>
                                        <div
                                            style={{
                                                marginTop: 4,
                                                height: 14,
                                                minWidth: 18,
                                                maxWidth: 18,
                                                marginRight: 4,
                                                borderRadius: 2,
                                                backgroundColor: "#eeeeee",
                                            }}
                                        />
                                    </Typography>
                                    <Typography style={{ marginTop: 3 }}>
                                        <Skeleton height={18} animation="wave" width={50} />
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        {/* Right panel */}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Disable disabled>
                                <Avatar
                                    sx={{
                                        border: "1px solid #ffffff",
                                        width: (theme) => theme.spacing(3.5),
                                        height: (theme) => theme.spacing(3.5),
                                        fontSize: "0.8rem",
                                        marginRight: "4px",
                                    }}
                                />
                            </Disable>
                            {/* like btn*/}
                            <Disable disabled>
                                <LikeTenderButton tender_id={""} buttonSize="medium" />
                            </Disable>
                            {/* dislike btn*/}
                            <Disable disabled>
                                <DislikeTenderButton tender_id={""} buttonSize="medium" />
                            </Disable>
                            {/* share btn*/}
                            <Disable disabled>
                                <IconButton size={"medium"}>
                                    <Share fontSize="small" />
                                </IconButton>
                            </Disable>
                            {/* chat btn*/}
                            <Disable disabled>
                                <IconButton size={"medium"}>
                                    <Message fontSize="small" />
                                </IconButton>
                            </Disable>
                        </div>
                    </div>
                </Grid>

                {/*
                 * Center box
                 * Contains all fragments of the opportunity document
                 */}
                <Grid
                    item
                    sx={{
                        flex: 1 /* 1 and it will fill whole space left if no flex value are set to other children*/,
                        overflow: "auto",
                        marginTop: "16px",
                    }}
                >
                    <Skeleton variant="text" animation="wave" width={120} style={{ marginBottom: "8px" }} />
                    <Skeleton variant="text" animation="wave" width={"90%"} />
                    <Skeleton variant="text" animation="wave" width={"88%"} />
                    <Skeleton variant="text" animation="wave" width={"92%"} />
                    <Skeleton variant="text" animation="wave" width={"83%"} />

                    <Skeleton variant="text" animation="wave" width={120} style={{ marginBottom: "8px", marginTop: "16px" }} />
                    <Skeleton variant="text" animation="wave" width={"90%"} />
                    <Skeleton variant="text" animation="wave" width={"88%"} />
                    <Skeleton variant="text" animation="wave" width={"92%"} />
                    <Skeleton variant="text" animation="wave" width={"83%"} />
                </Grid>

                {/*
                 * Bottom box
                 * Contains all meta data
                 */}
                <Grid item sx={{ height: "160px" }} style={{ marginTop: "24px" }} container>
                    <Grid item container xs={12} style={{ height: "80px" }}>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                            {/* Publication date */}
                            <div>
                                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    <Skeleton height={18} animation="wave" width={120} />
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                            {/* start & end date */}
                            <div>
                                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    <Skeleton height={18} animation="wave" width={130} />
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                            {/* Options */}
                            <div>
                                <Typography variant="h4" style={{}}>
                                    <Skeleton height={18} animation="wave" width={100} />
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    {/*
                     *  Second grid row
                     */}
                    <Grid item container xs={12} style={{ height: 80 }}>
                        {/* Publication date */}
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                            <div>
                                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    <Skeleton height={18} animation="wave" width={140} />
                                </Typography>
                            </div>
                        </Grid>
                        {/* Scope */}
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-start", padding: "8px 0px" }}>
                            <div style={{ marginBottom: "16px" }}>
                                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    <Skeleton height={18} animation="wave" width={150} />
                                </Typography>
                            </div>
                        </Grid>
                        {/* Subsectors */}
                        <Grid
                            item
                            xs={4}
                            sx={{ display: "flex", alignItems: "flex-start", padding: "8px 0px" }}
                            style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}
                        >
                            <div>
                                {/* subsectors or sectors based on country (528 = nl) of tender */}

                                <div style={{ marginBottom: "16px", height: "100%", overflow: "auto" }}>
                                    <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        <Skeleton height={18} animation="wave" width={130} />
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default SplitDetailViewLoader;
