import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";

import {
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Accordion as MuiAccordion,
    AccordionDetails,
    Divider,
    AccordionSummary,
    Table,
    Typography,
    TableBody,
    TableRow,
    TableCell,
    Button,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import CountryFlagBox from "../boxes/CountryFlagBox";
import FaviconBox from "../FaviconBox";
import StatusChip from "../StatusChip";

interface Props {
    participants: any;
}

const Accordion = withStyles(MuiAccordion, {
    root: {
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

const LotsPublished: React.FC<Props> = ({ participants }) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const { t } = useTranslation();
    const theme = useTheme();
    const pink = "#E03660";
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // Expand accordion based on lot ID
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const ItemsPerClick = 5;
    // State for number of items to show
    const [noOfItems, setnoOfItems] = useState(5);

    /**
     * Handler to show more items onclick,
     * get value of noOfItems and add value of ItemsPerClick.
     */
    const handleShowMoreItems = () => {
        setnoOfItems(noOfItems + ItemsPerClick);
    };

    /**
     * Handler to show less items onclick,
     * get value of noOfItems and add value of ItemsPerClick.
     */
    const handleShowLess = () => {
        setnoOfItems(5);
    };

    return (
        <React.Fragment>
            {/* map over lots, for each lot an accordion item */}
            {participants &&
                participants?.map((p: any, i: number) => {
                    // change "EUR" to "€"
                    const currencySign = p.definitieve_waarde?.toString().replace("EUR", "€");
                    // change dot to comma
                    const dotToComma = currencySign?.replace(/\./g, ",");
                    // add dots, example: 187995,43 -> 187.995,43
                    const value = dotToComma?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");

                    // state to show items if contractors are available
                    const itemsToShow = p.contractors ? p.contractors.slice(0, noOfItems) : null;

                    return (
                        <React.Fragment key={i}>
                            <Accordion expanded={expanded === i.toString()} onChange={handleChange(i.toString())}>
                                {/* oneliner with some information that user sees on first sight */}
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        "&:hover": {
                                            background: "#f4f4f4",
                                        },
                                    }}
                                >
                                    <Typography sx={{ flexBasis: "80%", flexShrink: 0 }}>
                                        {t("tenderPage.Lot")} {p.perceel_nummer} - {p.title}
                                    </Typography>
                                </AccordionSummary>

                                {/* Detailcomponent with more information about a lot */}
                                <AccordionDetails
                                    sx={{
                                        "& .MuiAccordionDetails-root": {
                                            paddingTop: 0,
                                            justifyContent: "center",
                                        },
                                    }}
                                >
                                    <List
                                        sx={{
                                            "& .MuiList-root": {
                                                width: "100%",
                                                padding: 0,
                                            },
                                        }}
                                    >
                                        <ListItem style={{ paddingLeft: 0 }}>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                        <Table size="small" sx={{ width: "100%" }}>
                                                            <TableBody>
                                                                {/* datum_gunning */}
                                                                {p.datum_gunning && (
                                                                    <TableRow>
                                                                        <TableCell
                                                                            sx={{
                                                                                width: onlyMobile ? "50%" : "25%",
                                                                                color: "#000000",
                                                                                borderBottom: "none",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <Typography>Datum gunning</Typography>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                width: onlyMobile ? "50%" : "75%",
                                                                                color: "#000000",
                                                                                borderBottom: "none",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <Typography style={{ whiteSpace: "pre-wrap" }}>
                                                                                {moment(p.datum_gunning).format("LL")}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}

                                                                {/* opdracht_nummer */}
                                                                {p.opdracht_nummer && (
                                                                    <TableRow>
                                                                        <TableCell
                                                                            sx={{
                                                                                width: onlyMobile ? "50%" : "25%",
                                                                                color: "#000000",
                                                                                borderBottom: "none",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <Typography>Opdracht nummer</Typography>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                width: onlyMobile ? "50%" : "75%",
                                                                                color: "#000000",
                                                                                borderBottom: "none",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <Typography style={{ whiteSpace: "pre-wrap" }}>
                                                                                {p.opdracht_nummer}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}

                                                                {/* definitieve_waarde */}
                                                                {p.definitieve_waarde && (
                                                                    <TableRow>
                                                                        <TableCell
                                                                            sx={{
                                                                                width: onlyMobile ? "50%" : "25%",
                                                                                color: "#000000",
                                                                                borderBottom: "none",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <Typography>Definitieve waarde</Typography>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                width: onlyMobile ? "50%" : "75%",
                                                                                color: "#000000",
                                                                                borderBottom: "none",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                                                {value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}

                                                                {/* aantal_inschrijvingen */}
                                                                {p.aantal_inschrijvingen && (
                                                                    <TableRow>
                                                                        <TableCell
                                                                            sx={{
                                                                                width: onlyMobile ? "50%" : "25%",
                                                                                color: "#000000",
                                                                                borderBottom: "none",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <Typography>Aantal inschrijvers</Typography>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                width: onlyMobile ? "50%" : "75%",
                                                                                color: "#000000",
                                                                                borderBottom: "none",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <Typography style={{ whiteSpace: "pre-wrap" }}>
                                                                                {p.aantal_inschrijvingen}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                                <div style={{ height: 16 }} />
                                                            </TableBody>
                                                        </Table>

                                                        {/* Map winners  */}
                                                        {itemsToShow?.map((c: any, i: number) => {
                                                            return (
                                                                <React.Fragment key={i}>
                                                                    <ListItem style={{ padding: "8px 16px" }}>
                                                                        {/* favicon with 2 pink letters */}
                                                                        <div style={{ marginRight: 8 }}>
                                                                            <FaviconBox marginTop={0} favicon={null} name={c.name} color={pink} />
                                                                        </div>

                                                                        {/* Listitemtext with amount and percentage */}
                                                                        <ListItemText
                                                                            style={{ marginRight: 100 }}
                                                                            primary={<Typography noWrap>{c.name}</Typography>}
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    <Typography
                                                                                        style={{
                                                                                            whiteSpace: "pre-wrap",
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        {c.address && <>{c.address}, </>}
                                                                                        {c.zipcode && <>{c.zipcode} </>}
                                                                                        {c.city && <>{c.city} </>}
                                                                                        {c.country && (
                                                                                            // show flag
                                                                                            <CountryFlagBox code={c.country} />
                                                                                        )}
                                                                                    </Typography>
                                                                                </React.Fragment>
                                                                            }
                                                                        />

                                                                        {/* Label that shows who won */}
                                                                        <ListItemSecondaryAction
                                                                            style={{ paddingBottom: 8, display: "flex", alignItems: "center" }}
                                                                        >
                                                                            <Typography>{c.definitieve_waarde}</Typography>
                                                                            <StatusChip colored={false} type="winner" />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>

                                                                    {i === p.contractors.length - 1 ? <></> : <Divider />}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        {/* Button to load more contractors */}
                                        {p.contractors && p.contractors.length > 5 && (
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {itemsToShow.length !== p.contractors.length ? (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        color="primary"
                                                        style={{ marginBottom: 8 }}
                                                        onClick={handleShowMoreItems}
                                                    >
                                                        {t("tenderPage.LoadMore")}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        color="primary"
                                                        style={{ marginBottom: 8 }}
                                                        onClick={handleShowLess}
                                                    >
                                                        {t("tenderPage.ShowLess")}
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                            <Divider />
                        </React.Fragment>
                    );
                })}
        </React.Fragment>
    );
};

export default LotsPublished;
