import "./utils/wdyr";

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import warnSelfXSS from "./utils/warnSelfXSS";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import "./index.scss";
import "./utils/i18n";

// Chartlabels need to import in highest level
// Unregister plugin => now possible to add locally instead of globally
// This plugin shows unwanted labels in choropleth

Chart.unregister(ChartDataLabels);

// eslint-disable-next-line @typescript-eslint/no-empty-function
if (process.env.NODE_ENV !== "development") console.log = () => {};
if (process.env.REACT_APP_TG_ENVIRONMENT !== "local") {
    warnSelfXSS(); // Places a warning in the user's browser to not use the JS console
}

ReactDOM.render(<App />, document.getElementById("root"));
