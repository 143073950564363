import React from "react";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";

interface Props {
    start: string | null;
    end: string | null;
    description: string | null;
    renew_desc: string | null;
    interval: string | null;
    period: string | null;
    setCopyText(text: string): void;
    lot_title?: string;
}

// Component for published contract
const ContractPublished: React.FC<Props> = ({ start, end, description, renew_desc, interval, period, setCopyText, lot_title }) => {
    const { t } = useTranslation();

    /**
     * dompurify imported for sanitizing html
     */
    const desc_frag = description ? dompurify.sanitize(description, { FORCE_BODY: true }) : null;
    const renew_desc_frag = renew_desc ? dompurify.sanitize(renew_desc, { FORCE_BODY: true }) : null;

    /**
     * Copy initial start/end date with period
     */
    const startDate = "".concat(
        t("tenderPage.InitialDuration") +
            ": " +
            `${start !== null ? moment(start).format("L") + " " : ""}` +
            `${end !== null ? t("until") : ""} ${end !== null ? moment(end).format("L") + " " : ""}` +
            `${period !== null ? period : ""}` +
            " " +
            `${
                interval !== null
                    ? `${
                          interval === "1"
                              ? t(`singleContractPeriod.${interval.toLowerCase() || ""}`)
                              : t(`multiContractPeriod.${interval?.toLowerCase() || ""}`)
                      }`
                    : ""
            }` +
            " "
    );

    /**
     * Copy description from renew_desc prop
     */
    const renewalDescription = renew_desc_frag !== null && "".concat(t("tenderPage.Description") + ": " + renew_desc_frag);

    /**
     * Copy description from description prop
     */
    const oldDescription = desc_frag !== null && "".concat(t("tenderPage.Description") + ": " + desc_frag);

    /**
     * check if one of the description props is filled, with priority for renew. if empty give empty string back
     */
    const copyDescription = renewalDescription ? renewalDescription : oldDescription ? oldDescription : "";

    const lotTitle = lot_title ? lot_title : "";

    /**
     * Get all items and combine in one string to make it possible to copy on clipboard
     */
    setCopyText(lotTitle + "\n" + startDate + "\n" + copyDescription);

    return (
        <React.Fragment>
            <Grid container style={{ padding: "8px 16px" }}>
                <Grid item xs={12} md={7} style={{ marginBottom: 4 }}>
                    <table>
                        <tbody>
                            {/* 
                            - Check if undeterminedPeriod is true
                            - Check if period os not 'null'
                            - if atleast one is true, show duration   
                            */}
                            {start !== null && end !== null && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                        {t("tenderPage.InitialDuration")}:
                                    </td>
                                    <td>
                                        <>
                                            <Typography>
                                                <>
                                                    {start !== null && moment(start).format("L")}{" "}
                                                    {end !== null && `${t("until")} ${moment(end).format("L")}`} {period && period}{" "}
                                                    {interval !== null && (
                                                        <>
                                                            {interval === "1"
                                                                ? t(`singleContractPeriod.${interval.toLowerCase() || ""}`)
                                                                : t(`multiContractPeriod.${interval?.toLowerCase() || ""}`)}
                                                        </>
                                                    )}
                                                </>
                                            </Typography>
                                        </>
                                    </td>
                                </tr>
                            )}
                            {/*
                             *   Show startdate if only
                             */}
                            {start && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                        {t("tenderPage.StartDuration")}:
                                    </td>
                                    <td>
                                        <>
                                            <Typography>
                                                <>{moment(start).format("L")}</>
                                            </Typography>
                                        </>
                                    </td>
                                </tr>
                            )}
                            {/*
                             *   Show enddate if only
                             */}
                            {end && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                        {t("tenderPage.EndDuration")}:
                                    </td>
                                    <td>
                                        <>
                                            <Typography>
                                                <>{moment(end).format("L")}</>
                                            </Typography>
                                        </>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Grid>
                {/*
                 * Description field
                 */}
                <Grid item xs={12} md={5}>
                    {renew_desc_frag ? (
                        <table>
                            <tr>
                                <Typography>{t("tenderPage.Description")}:</Typography>
                            </tr>
                            <tr>
                                <Typography
                                    sx={{ marginTop: "4px", maxHeight: "120px", overflow: "auto" }}
                                    dangerouslySetInnerHTML={{
                                        __html: renew_desc_frag,
                                    }}
                                />
                            </tr>
                        </table>
                    ) : desc_frag ? (
                        <table>
                            <tr>
                                <Typography>{t("tenderPage.Description")}:</Typography>
                            </tr>
                            <tr>
                                <Typography
                                    sx={{ marginTop: "4px", maxHeight: "120px", overflow: "auto" }}
                                    dangerouslySetInnerHTML={{
                                        __html: desc_frag,
                                    }}
                                />
                            </tr>
                        </table>
                    ) : null}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ContractPublished;
