import React, { useState } from "react";
import LogOptionSelector from "../Logbook/LogOptionSelector";
import { List, ListItemButton } from "@mui/material";
import update from "immutability-helper";

interface Props {
    owners: any[];
    chosenOwners: number[];
    setChosenOwners: (owners: number[]) => void;
}

const SearchOwnerFilter: React.FC<Props> = ({ owners, chosenOwners, setChosenOwners }) => {
    const [selected, setSelected] = useState(chosenOwners);

    // Function to handle owner selection
    const handleOwnerSelection = (ownerId: number) => {
        // If the clicked owner is already selected, deselect it
        const index = selected.indexOf(ownerId);
        if (index !== -1) {
            setSelected(update(selected, { $splice: [[index, 1]] }));
        } else {
            // Add the owner to the list of selected owners
            setSelected(update(selected, { $push: [ownerId] }));
        }
    };
    return (
        <LogOptionSelector
            disabled={false}
            label={`Gemaakt door ${chosenOwners.length > 0 ? `(${chosenOwners.length})` : ""}`}
            active={chosenOwners.length > 0}
            allowReset={chosenOwners.length > 0 ? true : false}
            onClose={() => {
                setChosenOwners(chosenOwners);
                setSelected(chosenOwners);
            }}
            onReset={() => {
                setChosenOwners([]);
                setSelected([]);
            }}
            onSave={() => {
                setChosenOwners(selected);
            }}
        >
            <div style={{ width: 240 }}>
                <List sx={{ padding: 0 }}>
                    {owners.map((owner) => {
                        return (
                            <ListItemButton
                                key={owner.id}
                                sx={{ fontWeight: selected.includes(parseInt(owner.id)) ? 600 : "normal" }}
                                selected={selected.includes(parseInt(owner.id))}
                                onClick={() => handleOwnerSelection(parseInt(owner.id))}
                            >
                                {owner.employee.name} ({owner.count})
                            </ListItemButton>
                        );
                    })}
                </List>
            </div>
        </LogOptionSelector>
    );
};

export default SearchOwnerFilter;
