import React, { useState } from "react";

// MUI import
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";

// GQL
import { useMutation } from "@apollo/client";
import { likeTenderBatch, likeTenderBatchVariables } from "../__generated__/likeTenderBatch";
import { Unarchive } from "@mui/icons-material";
import { QUERY_FULL_WORKLIST_MINIMUM, QUERY_TENDERLIKED, QUERY_TENDERS_INTERESTING_MINIMUM } from "../graphql/queryDefinitions";
import { LIKETENDERBATCH } from "../graphql/mutationDefinitions";

interface Props {
    selection: any;
    buttonSize: "small" | "medium";
    iconSize: string;
    setSelection: any;
}

const UnarchiveSelectedTenderButton: React.FC<Props> = ({ setSelection, selection: selectedArr, buttonSize, iconSize }) => {
    const { t } = useTranslation();
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [save, { loading: loadingMutation, error: errorMutation }] = useMutation<likeTenderBatch, likeTenderBatchVariables>(LIKETENDERBATCH);

    /**
     * Unarchive selected tenders
     */
    const likeSelectedTenders = () => {
        const selection = selectedArr;
        setIsloading(true);
        /**
         * if arr is empty, then all CA's are already starred.
         * if user clicks on button, then CA's star will be removed.
         * if arr is > 0 then CA's will be starred.
         */
        if (selection.length > 0) {
            selection.forEach(async (id: string) => {
                // Function to like organization
                if (loadingMutation) {
                }
                if (errorMutation) {
                    // toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await save({
                        variables: {
                            tenderID: id,
                            reason: "",
                            remark: "",
                        },
                        refetchQueries: [
                            QUERY_FULL_WORKLIST_MINIMUM,
                            "FilterWorklistMinimumOverview",
                            QUERY_TENDERLIKED,
                            "GetTenderLikes",
                            QUERY_TENDERS_INTERESTING_MINIMUM,
                            "FilterInterestingMinimumTendersOverview",
                        ],
                        awaitRefetchQueries: true,
                    });
                    setSelection([]);
                } catch (e) {
                    // toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    setSelection([]);
                    setIsloading(false);
                }
            });
            // onClose?.();
        }
        // else {
        //     onClose?.();
        // }
    };

    return (
        <React.Fragment>
            <Tooltip enterNextDelay={100} title={t("Unarchive") as string} placement="top">
                <IconButton
                    disabled={loadingMutation || isLoading}
                    onClick={(e) => {
                        e.stopPropagation();
                        likeSelectedTenders();
                    }}
                    size={buttonSize}
                >
                    <Unarchive style={{ fontSize: iconSize }} />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

export default UnarchiveSelectedTenderButton;
