import React from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { GetPublishedContactPersoonColumn, GetPublishedContactPersoonColumnVariables } from "../../__generated__/GetPublishedContactPersoonColumn";
import { GET_PUBLISHED_CONTACT_PERSOON_COLUMN } from "../../graphql/queryDefForColumns";

interface Props {
    tender_id: string;
}

const PublishedContactPersonColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetPublishedContactPersoonColumn, GetPublishedContactPersoonColumnVariables>(GET_PUBLISHED_CONTACT_PERSOON_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    return <Typography style={{ whiteSpace: "pre-wrap" }}>{tender.published_contactpersoon}</Typography>;
};

export default PublishedContactPersonColumn;
