import React from "react";
import { Grid, Typography, Tooltip, IconButton } from "@mui/material";
import { Edit, Euro, Event, Close } from "@mui/icons-material";
import { GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities } from "../../__generated__/GetopportunityPagePersonalOpportunities";
import { useMutation } from "@apollo/client";
import { deletePersonalOpportunity, deletePersonalOpportunityVariables } from "../../__generated__/deletePersonalOpportunity";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { DELETE_PERSONAL_OPPORTUNITY } from "../../graphql/mutationDefinitions";
import { QUERY_OPPS_OF_CA_DOC_TYPE, QUERY_USER_OPPS_OF_PAGE } from "../../graphql/queryDefinitions";
// import ReadMoreComponent from "../ReadMoreComponent";

interface Props {
    opportunity: GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities;
    setEditMode: (editMode: GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities) => void;
    setEditField: (editField: boolean) => void;
}

const OpportunityViewMode: React.FC<Props> = ({ opportunity, setEditMode, setEditField }) => {
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * mutation to delete opportunity
     */
    const [deletePersonalOpp, { error: errorMutation }] = useMutation<deletePersonalOpportunity, deletePersonalOpportunityVariables>(
        DELETE_PERSONAL_OPPORTUNITY
    );

    /**
     * handle submit of new opportunity
     */
    const deleteOpp = async () => {
        if (errorMutation) {
        }
        try {
            await deletePersonalOpp({
                variables: {
                    id: opportunity.id,
                },
                refetchQueries: [
                    QUERY_USER_OPPS_OF_PAGE,
                    "GetopportunityPagePersonalOpportunities",
                    QUERY_OPPS_OF_CA_DOC_TYPE,
                    "GetCAOpportunitiesOfType",
                ],
            });
        } catch (e) {}
    };

    function currencyFormatDE(num: number) {
        return num
            .toFixed(2) // always two decimal digits
            .replace(".", ",") // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
    }

    /**
     * @returns comment with newwlines replaced with <br /> OR emoty string if comment is null
     */
    const replaceWithBr = () => {
        if (opportunity.comment !== null) {
            return opportunity.comment.replace(/\n/g, "<br />");
        }
        return "";
    };

    return (
        <Grid container>
            {/*
             * Grid with opportunity text/comment
             */}
            <Grid
                xs={12}
                md={12}
                sx={{ padding: "10.5px 10px 10.5px 10px", width: "100%", display: "flex", alignItems: "center" }}
                style={{ marginBottom: "4px" }}
            >
                <div style={{ padding: "0 10px ", width: "100%", display: "flex", alignItems: "center" }}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: replaceWithBr(),
                        }}
                    />
                </div>
            </Grid>

            {/*
             * Grid with year and value
             * TODO: mapping to get multiple years and values
             */}
            <Grid
                xs={12}
                md={12}
                sx={{ padding: "10.5px 10px 10.5px 10px", width: "100%", display: "flex", alignItems: "center" }}
                style={{ justifyContent: "space-between", marginBottom: "4px" }}
            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    {opportunity?.parsedValues?.map((v, i) => {
                        return (
                            <div key={i} style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                {/*
                                 * Div with calendar icon and saved year
                                 */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Event sx={{ marginRight: 0, color: "#707070", marginLeft: "8px" }} />
                                    <div
                                        style={{
                                            padding: "10.5px 10px 10.5px 10px",
                                            height: "37.63px",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{v?.year}</Typography>
                                    </div>
                                </div>
                                {/*
                                 * Div with currency icon (euro) and value
                                 */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Euro sx={{ marginRight: 0, color: "#707070", marginLeft: "8px" }} />
                                    <div
                                        style={{
                                            padding: "10.5px 10px 10.5px 10px",
                                            height: "37.63px",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{v?.value !== undefined && v.value !== null && currencyFormatDE(v?.value)}</Typography>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {/*
                     * Div with buttons to edit or delete opportunity
                     */}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Kans bewerken" placement="top">
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                                setEditMode(opportunity);
                                setEditField(true);
                                trackevents({ category: "Opportunity", action: "Click-edit-opportunity", value: parseInt(opportunity.id) });
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Kans verwijderen" placement="top">
                        <IconButton
                            color="primary"
                            size="small"
                            // TODO: onclick fires delete mutation
                            onClick={() => {
                                deleteOpp();
                                trackevents({ category: "Opportunity", action: "Click-remove-opportunity", value: parseInt(opportunity.id) });
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                </div>
            </Grid>
        </Grid>
    );
};

export default OpportunityViewMode;
