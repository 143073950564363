import React from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { QUERY_SINGLE_CREATED_AT } from "../../graphql/opportunityQueries/queryDefinitions";
import { GetSingleOppCreatedAt, GetSingleOppCreatedAtVariables } from "../../__generated__/GetSingleOppCreatedAt";
import moment from "moment";

interface Props {
    opp_file_id: string;
}

const OppCreatedAtColumn: React.FC<Props> = ({ opp_file_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetSingleOppCreatedAt, GetSingleOppCreatedAtVariables>(QUERY_SINGLE_CREATED_AT, {
        variables: { id: opp_file_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.opportunityFile) {
        return <Typography>{""}</Typography>;
    }

    const opp = data.opportunityFile;

    return <Typography>{moment(opp.publication_date).format("l")}</Typography>;
};

export default OppCreatedAtColumn;
