import { IconButton } from "@mui/material";
import React from "react";

interface Props {
    handleAddAtSymbol: () => void;
}

const MentionButton: React.FC<Props> = ({ handleAddAtSymbol, children }) => {
    return (
        <IconButton onClick={handleAddAtSymbol} sx={{ padding: "2px 2px", width: "16px", height: "16px" }} size="small">
            <span style={{ fontSize: "11px" }}>{children}</span>
        </IconButton>
    );
};

export default MentionButton;
