/* eslint-disable react/jsx-no-undef */
import React from "react";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_data } from "../../../../__generated__/GetAllDocsForOppSearchWizard";
import FaviconBox from "../../../../components/FaviconBox";
import StatusDot from "../../../../components/StatusDot";
import { ApolloError } from "@apollo/client";
import FullPageSpinner from "../../../../components/loading/FullPageSpinner";
// import { useMediaQuery } from "@mui/material";
// import moment from "moment";

interface Props {
    selectedItem: GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_data | undefined;
    setSelectedItem: (item: GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_data) => void;
    data: GetAllDocsForOppSearchWizard_filterOpportunityFiles_results_data[] | null | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}

const OppSplitList: React.FC<Props> = ({ data, setSelectedItem, selectedItem, loading, error }) => {
    const hoverColor = "#efefef";
    const yellow = "#F57117";

    if (loading) {
        return (
            <Paper elevation={0} square style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FullPageSpinner />
            </Paper>
        );
    }

    if (error || !data) {
        return (
            <Paper elevation={0} square style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                        Error
                    </Typography>
                </div>
            </Paper>
        );
    }

    return (
        <Paper square elevation={0} style={{ height: "100%" }}>
            <List style={{ padding: 0, height: "100%", overflow: "auto" }} id="opplist">
                {data.map((item) => {
                    return (
                        <ListItem
                            button
                            dense
                            key={item.id}
                            onClick={() => {
                                setSelectedItem(item);
                            }}
                            style={{
                                backgroundColor: selectedItem !== undefined && selectedItem.id === item.id ? `${hoverColor}` : undefined,
                                display: "flex",
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                }}
                            >
                                <FaviconBox
                                    favicon={item.contractingAuthority.favicon_resized ?? item.contractingAuthority.favicon}
                                    name={item.contractingAuthority.name}
                                    color={yellow}
                                />
                            </ListItemIcon>
                            <ListItemText primary={<Typography noWrap>{item.name}</Typography>} secondary={item.contractingAuthority.name} />

                            <ListItemSecondaryAction style={{ height: 14, width: 14 }}>
                                <StatusDot type={item.id} placement="left" />
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
};

export default OppSplitList;
