import { useEffect } from "react";
import ClientEcho from "./Echo";

interface WhisperData {
    [key: string]: any;
}

if (!window.Echo) {
    window.Echo = ClientEcho;
}

/**
 * CHANNEL FOR POLLS
 * @param channel Channel name
 * @param event event name
 * @param callBack data to return
 */
export const usePublicSocket = (channel: string, event: string, callBack: (payload: any) => void) => {
    useEffect(() => {
        // Listen to a public channel
        window.Echo.channel(channel).listen(event, (payload: any) => {
            callBack(payload);
        });

        // Clean up function to leave the channel when the component unmounts
        return () => {
            window.Echo.leaveChannel(channel);
        };
    }, [callBack, channel, event]);
};

/**
 * CHANNEL FOR NOTIFICATIONS
 * @param channel Channel name
 * @param event event name
 * @param callBack data to return
 */
export const useSocket = (channel: string, event: string, callBack: (payload: any) => void) => {
    useEffect(() => {
        window.Echo.private(channel).listen(event, (payload: any) => {
            callBack(payload);
        });

        return () => {
            window.Echo.leaveChannel(channel);
        };
    }, [callBack, channel, event]);
};

/**
 * CONNECTION FOR CHATS + whispering
 * @param channel Channel name
 * @param event event name
 * @param callBack data to return
 * @param whisperEvent eventname for whispering
 * @returns websocket
 */
export const useSocketForChat = (
    channel: string,
    event: string,
    callBack: (payload: any, eventName: string) => void,
    whisperEvent: string | null = null
) => {
    useEffect(() => {
        const echoChannel = window.Echo.private(channel);

        // Listen for all events on the channel
        echoChannel.listen(event, (payload: any, eventName: any) => {
            callBack(payload, eventName);
        });

        // Optionally listen for whispers if whisperEvent is provided
        if (whisperEvent) {
            echoChannel.listenForWhisper(whisperEvent, (payload: any) => {
                callBack(payload, whisperEvent);
            });
        }

        return () => {
            if (whisperEvent) {
                echoChannel.stopListeningForWhisper(whisperEvent);
            }

            window.Echo.leaveChannel(channel);
        };
    }, [callBack, channel, event, whisperEvent]);

    // Function to send a whisper
    const sendWhisper = (eventName: string, data: WhisperData) => {
        window.Echo.private(channel).whisper(eventName, data);
    };

    return { sendWhisper };
};
