import React from "react";

import { Typography, Grid, FormControlLabel, Checkbox, FormControl, Select, MenuItem, useTheme, useMediaQuery } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Disable } from "react-disable";
import AdViewpreferences from "./AdViewPreferences";
import BusinessIcon from "@mui/icons-material/Business";
import { useTranslation } from "react-i18next";
import StarIcon from "@mui/icons-material/Star";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import update from "immutability-helper";
import { ContractingAuthoritiesModulePreferences, DistributionIntervalInput } from "../../../__generated__/globalTypes";
import AdViewPreferencesMobile from "./AdViewPreferencesMobile";
import AdSettingsMobile from "./AdSettingsMobile";
interface Props {
    settings: ContractingAuthoritiesModulePreferences;
    saveSettings: (settings: ContractingAuthoritiesModulePreferences) => void;
    setChanged: (changed: boolean) => void;
}

const AdSettings: React.FC<Props> = ({ settings, saveSettings, setChanged }) => {
    const { t } = useTranslation();
    const yellow = "#F57117";
    const theme = useTheme();
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Disable disabled={false}>
            <AccordionDetails sx={{ overflowX: "auto", padding: mobileOnly ? "0px" : "8px 16px" }}>
                <Grid container>
                    {/* HEAD: general update preferences */}
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "left", margin: 16 }}>
                        {mobileOnly ? (
                            <AdSettingsMobile settings={settings} saveSettings={saveSettings} setChanged={setChanged} />
                        ) : (
                            <table style={{ padding: "0 16px" }}>
                                <tr>
                                    <td>
                                        <Typography variant="h5" style={{ display: "flex", alignItems: "center" }}>
                                            <StarIcon style={{ color: yellow, marginRight: 8, height: 20, width: 20 }} />
                                            {t("Notifications")}
                                        </Typography>
                                        <Typography>{t("NotificationsDescription")}</Typography>
                                    </td>
                                    <td style={{ width: 150 }}>
                                        <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <NotificationsIcon sx={{ color: "#707070" }} style={{ marginBottom: "4px" }} />
                                            <Typography variant="h4">{t("Mentions")}</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <MailIcon sx={{ color: "#707070" }} style={{ marginBottom: "4px" }} />
                                            <Typography variant="h4">{t("Emails")}</Typography>
                                        </div>
                                    </td>
                                </tr>
                                <tr style={{ height: 16 }} />

                                {/*
                                 * Change contactdata
                                 */}
                                {settings.notifications?.map((setting, i) => {
                                    return (
                                        <tr key={setting?.event}>
                                            <td style={{ width: "30vw", paddingLeft: "16px" }}>
                                                <Typography style={{ fontSize: "1rem" }}>{t(setting?.event as string)}</Typography>
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <FormControlLabel
                                                        sx={{
                                                            removeIconMargin: {
                                                                marginLeft: 0,
                                                                marginRight: 0,
                                                            },
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                disabled
                                                                checked={setting?.notification}
                                                                name={setting?.event}
                                                                onChange={(e) => {
                                                                    saveSettings(
                                                                        update(settings, {
                                                                            notifications: {
                                                                                [i]: {
                                                                                    notification: { $set: e.target.checked },
                                                                                },
                                                                            },
                                                                        })
                                                                    );
                                                                    setChanged(true);
                                                                }}
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <FormControlLabel
                                                        sx={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                checked={setting?.email}
                                                                name={setting?.event}
                                                                onChange={(e) => {
                                                                    saveSettings(
                                                                        update(settings, {
                                                                            notifications: {
                                                                                [i]: {
                                                                                    email: { $set: e.target.checked },
                                                                                },
                                                                            },
                                                                        })
                                                                    );
                                                                    setChanged(true);
                                                                }}
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                {setting?.email && (
                                                    <FormControl variant="standard" margin="dense">
                                                        <Select
                                                            disableUnderline
                                                            labelId="demo-simple-select-outlined-label"
                                                            defaultValue={setting?.emailInterval || DistributionIntervalInput.DAILY}
                                                        >
                                                            <MenuItem
                                                                value={"HOURLY"}
                                                                onClick={(e) => {
                                                                    saveSettings(
                                                                        update(settings, {
                                                                            notifications: {
                                                                                [i]: {
                                                                                    emailInterval: { $set: DistributionIntervalInput.HOURLY },
                                                                                },
                                                                            },
                                                                        })
                                                                    );
                                                                    setChanged(true);
                                                                }}
                                                            >
                                                                Direct
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={"DAILY"}
                                                                onClick={(e) => {
                                                                    saveSettings(
                                                                        update(settings, {
                                                                            notifications: {
                                                                                [i]: {
                                                                                    emailInterval: { $set: DistributionIntervalInput.DAILY },
                                                                                },
                                                                            },
                                                                        })
                                                                    );
                                                                    setChanged(true);
                                                                }}
                                                            >
                                                                Dagelijks
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={"WEEKLY"}
                                                                onClick={(e) => {
                                                                    saveSettings(
                                                                        update(settings, {
                                                                            notifications: {
                                                                                [i]: {
                                                                                    emailInterval: { $set: DistributionIntervalInput.WEEKLY },
                                                                                },
                                                                            },
                                                                        })
                                                                    );
                                                                    setChanged(true);
                                                                }}
                                                            >
                                                                Wekelijks
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={"MONTHLY"}
                                                                onClick={(e) => {
                                                                    saveSettings(
                                                                        update(settings, {
                                                                            notifications: {
                                                                                [i]: {
                                                                                    emailInterval: { $set: DistributionIntervalInput.MONTHLY },
                                                                                },
                                                                            },
                                                                        })
                                                                    );
                                                                    setChanged(true);
                                                                }}
                                                            >
                                                                Maandelijks
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        )}
                    </Grid>
                    {/*
                     * GRID WITH CARDS FOR DEFAULT VIEW SETTINGS
                     */}
                    {mobileOnly ? (
                        <Grid item xs={12} sx={{ margin: "16px" }}>
                            <AdViewPreferencesMobile />
                        </Grid>
                    ) : (
                        <Grid item xs={12} style={{ margin: "0px 32px" }}>
                            <Typography variant="h5" style={{ display: "flex", alignItems: "center" }}>
                                <BusinessIcon style={{ color: yellow, marginRight: 8, width: 20, height: 20 }} />
                                {t("DefaultView")}
                            </Typography>
                            <Typography style={{ marginBottom: 24 }}>{t("DefaultViewDescription")}</Typography>

                            <AdViewpreferences />
                        </Grid>
                    )}
                </Grid>
            </AccordionDetails>
        </Disable>
    );
};

export default AdSettings;
