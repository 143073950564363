import React from "react";
import { Grid, MenuItem, Pagination, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    onChangePagination: (page: number, rowsPerPage: number) => void;
    currentPage: number;
    perPage: number;
    total: number;
    lastItemIndex: number | null;
    firstItemIndex: number | null;
}

const TableNavigation: React.FC<Props> = ({ onChangePagination, currentPage, perPage, total, lastItemIndex, firstItemIndex }) => {
    const { t } = useTranslation();
    const amountPages = Math.ceil(total / perPage);
    return (
        <Grid container spacing={2} style={{ padding: 20 }} alignItems="center" justifyContent="space-between">
            {/* Left section
             *  number of total results found
             */}
            <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                {total === 0 ? 0 : firstItemIndex?.toLocaleString()}-{lastItemIndex?.toLocaleString()} {t("of")} {total.toLocaleString()}
            </Grid>

            {/* Middle section,
             *  Show amount of pages with back/next arrow
             */}
            <Grid item sm={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Pagination page={currentPage} count={amountPages} onChange={(_, page) => onChangePagination(page, perPage)} />
            </Grid>

            {/* Right section
             *  Selectable field with option to show more/less rows per page
             */}
            <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                <Select
                    variant="standard"
                    value={perPage}
                    onChange={(e) => onChangePagination(currentPage, e.target.value as number)}
                    style={{ marginRight: 4 }}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>

                {t("resultsPerPage")}
            </Grid>
        </Grid>
    );
};

export default TableNavigation;
