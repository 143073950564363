import React from "react";
import SectorIconLoader from "../../../../components/boxes/SectorIconLoader";
import { Typography } from "@mui/material";
import TopLinksModule from "../../../../components/organizationComponents/TopLinksModule";
import { useQuery } from "@apollo/client";
import { GetTopFiveMostActiveIn, GetTopFiveMostActiveInVariables } from "../../../../__generated__/GetTopFiveMostActiveIn";
import { QUERY_TOP_FIVE_MOST_ACTIVE_IN_MP } from "../../../../graphql/queryDefOrg";

interface Props {
    mp_id: string;
}

const MPTopFiveMostActiveIn: React.FC<Props> = ({ mp_id }) => {
    const { loading, error, data } = useQuery<GetTopFiveMostActiveIn, GetTopFiveMostActiveInVariables>(QUERY_TOP_FIVE_MOST_ACTIVE_IN_MP, {
        variables: { id: mp_id },
    });

    if (loading || error || data?.tenderer?.most_active_in?.length === 0) return <></>;

    return (
        <TopLinksModule Title="meest actief in">
            {data?.tenderer?.most_active_in?.map((m, i) => {
                return (
                    <li key={i} style={{ display: "flex", alignItems: "center" }}>
                        {/* Iconloader, load icon based on sector_id */}
                        <SectorIconLoader sector_id={m?.sector.id} />
                        <Typography noWrap style={{ marginLeft: 10, marginRight: 2, maxWidth: "90%" }}>
                            {m?.sector.name}
                        </Typography>
                        <Typography style={{ display: "inline", width: "10%" }}>({m?.aantal})</Typography>
                    </li>
                );
            })}
        </TopLinksModule>
    );
};

export default MPTopFiveMostActiveIn;
