import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { Color } from "../routes/profile/SearchRule/SearchLineItem";
import { Check } from "@mui/icons-material";

interface Props {
    color: string;
    onChange(presetColor: string): void;
    cancelAndClose(): void;
    presetColors: Color[];
    open: boolean;
    onClosePicker(): void;
    chosenColorArray: string[];
}

const Colorpicker: React.FC<Props> = ({ color, onChange, presetColors, open, onClosePicker, cancelAndClose, chosenColorArray }) => {
    return (
        <Dialog open={open} onClose={cancelAndClose}>
            <DialogTitle>Kies een kleur</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {presetColors.map((presetColor) => (
                            <div key={presetColor.id}>
                                {presetColor.colors.map((colorItem) => (
                                    <Button
                                        key={colorItem}
                                        sx={{
                                            minWidth: "32px",
                                            height: "32px",
                                            margin: "4px",
                                            background: colorItem,
                                            borderRadius: color !== colorItem ? "none" : "25px",
                                            "&:hover": {
                                                opacity: color !== colorItem ? 0.8 : 1,
                                            },
                                        }}
                                        onClick={() => onChange(colorItem)}
                                    >
                                        {chosenColorArray.find((color) => color === colorItem) ? (
                                            <Check sx={{ fontSize: "14px", color: "white" }} />
                                        ) : (
                                            <></>
                                        )}
                                    </Button>
                                ))}
                            </div>
                        ))}
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClosePicker();
                    }}
                >
                    Opslaan
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Colorpicker;
