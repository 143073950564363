import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { GetOrganizationAllNames_tenderer_names, GetOrganizationAllNames_tenderer_inactive_names } from "../../__generated__/GetOrganizationAllNames";

interface Props {
    names: GetOrganizationAllNames_tenderer_names[];
    inactive_names: GetOrganizationAllNames_tenderer_inactive_names[];
    handleClose(): void;
    open: boolean;
}

// TODO: inactivenames extra dataprop for recognition in array

const TradeNameModal: React.FC<Props> = ({ handleClose, open, names, inactive_names }) => {
    const { t } = useTranslation();
    const mergeResult = [...names, ...inactive_names];
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
            <DialogTitle id="max-width-dialog-title">Alle handelsnamen</DialogTitle>
            <DialogContent>
                <div>
                    <table>
                        <tbody>
                            {mergeResult
                                .filter((n) => n.type === "trade_name")
                                .sort((a, b) => a.value.localeCompare(b.value))
                                .map((n) => {
                                    return (
                                        <tr key={n.id}>
                                            {inactive_names.find((i) => i.id === n.id) ? (
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    <Tooltip placement={"right"} title={"Vervallen handelsnaam"}>
                                                        <Typography sx={{ textDecoration: "line-through", fontStyle: "italic", display: "inline" }}>
                                                            {n.value}
                                                        </Typography>
                                                    </Tooltip>
                                                </td>
                                            ) : (
                                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                                    <Typography style={{ display: "inline" }}>{n.value}</Typography>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("advancedSearch.Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TradeNameModal;
