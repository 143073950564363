import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

interface Props {
    startAnimation: boolean;
}

const Lock = styled("div")<{ isLocked: boolean }>(({ isLocked }) => ({
    // lock
    width: "24px",
    height: "21px",
    border: `3px solid ${isLocked ? "#5fadbf" : "#eee"}`, // Dynamic color
    background: isLocked ? "#5fadbf" : "none",
    borderRadius: "5px",
    position: "relative",
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",

    // shackle
    "&::before": {
        content: '""',
        display: "block",
        width: "10px",
        height: "11px",
        bottom: isLocked ? "100%" : "118% !important",
        position: "absolute",
        left: "50%",
        marginLeft: isLocked ? "-8px" : "-19.5px",
        border: `3px solid ${isLocked ? "#5fadbf" : "#eee"}`,
        borderTopRightRadius: "50%",
        borderTopLeftRadius: "50%",
        borderBottom: "0",
        transition: "all 0.2s ease-in-out, bottom 0.5s ease-in-out",
        transform: isLocked ? "rotate(0)" : "rotateY(180deg)", // Unlock shackle animation
    },

    // hole
    "&::after": {
        content: '""',
        display: "block",
        background: isLocked ? "#fff" : "#eee",
        width: "6px",
        height: "6px",
        position: "absolute",
        top: "50%",
        left: "50%",
        borderRadius: "50%",
        margin: "-3px 0 0 -3px",
        transition: "all 0.2s ease-in-out",
    },
}));

const LockAnimation: React.FC<Props> = ({ startAnimation }) => {
    const [isLocked, setIsLocked] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLocked(startAnimation);
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startAnimation]);

    return (
        <div style={{ height: "40px", width: "40px", display: "flex", alignItems: "center", justifyContent: "center", transform: `scale(4)` }}>
            <Lock isLocked={isLocked} />
        </div>
    );
};

export default LockAnimation;
