import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";

// Components used
import TopbarCalendar from "./TopbarCalendar";
import MonthView from "./MonthView";
import WeekView from "./WeekView";
import DayView from "./DayView";
import CalendarMobile from "./CalendarMobile";

import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import setTitle from "../../utils/setTitle";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
    FilterCalendarDataItems_filterTenders_results_data_looptijd_start,
    FilterCalendarDataItems_filterTenders_results_data_next_looptijd_einde,
} from "../../__generated__/FilterCalendarDataItems";
import { GetIcalUser } from "../../__generated__/GetIcalUser";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { toast } from "react-toastify";
import { QUERY_ICAL_LINK, QUERY_USER_AND_TENDER_EVENTS } from "../../graphql/queries/calendarQueries";
import { SavedTenderEvents, SavedTenderEventsVariables } from "../../__generated__/SavedTenderEvents";

interface Props {
    a?: number;
}

export interface Item {
    id: string;
    event: string;
    startDate: (FilterCalendarDataItems_filterTenders_results_data_looptijd_start | null)[] | null;
    endDate: (FilterCalendarDataItems_filterTenders_results_data_next_looptijd_einde | null)[] | null;
    deadline: string;
    type: string;
}

export interface EventItem {
    id: string;
    event: string;
    startDate: (FilterCalendarDataItems_filterTenders_results_data_looptijd_start | null)[] | null;
    endDate: (FilterCalendarDataItems_filterTenders_results_data_next_looptijd_einde | null)[] | null;
    deadline: string;
    type: string;
}

const CalendarModule: React.FC<Props> = (props) => {
    const theme = useTheme();
    const { trackEvent } = useMatomo();
    const { t } = useTranslation();
    const today = moment().format();

    const [currentMonth, setCurrentMonth] = useState<string>(today);
    const [selectedDate] = useState<string>("");
    const [view, setView] = useState<number>(0);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const monthStart = moment(currentMonth).startOf("month").format();
    const monthEnd = moment(currentMonth).endOf("month").format();
    const startDate = moment(monthStart).startOf("week").format("YYYY-MM-DD");
    const endDate = moment(monthEnd).endOf("week").format("YYYY-MM-DD");
    const [searchInput, setSearchInput] = useState({ from: startDate, till: endDate });
    const [icalLink, setIcalLink] = useState<string | null>(null);

    /**
     * Track event Ical click
     */
    const trackIcalClick = () => {
        trackEvent({ category: "Calendar", action: "clicked-Ical" });
    };

    /**
     * Variables for the GQL query
     */
    const CustomVariables: SavedTenderEventsVariables = {
        from: startDate,
        till: endDate,
    };

    /**
     * set title
     */
    useEffect(() => {
        setTitle(t("agenda"));
    }, [t]);

    /**
     * Define the GQL query to fetch ical link
     */
    useQuery<GetIcalUser>(QUERY_ICAL_LINK, {
        fetchPolicy: "network-only", // Used for first execution
        onCompleted: (data) => {
            if (data.currentUser.ical_link) {
                setIcalLink(data.currentUser.ical_link);
            }
        },
    });

    /**
     * Define the GQL query
     */
    const [runQuery, { loading: loadingQuery, data: Querydata }] = useLazyQuery<SavedTenderEvents, SavedTenderEventsVariables>(
        QUERY_USER_AND_TENDER_EVENTS,
        {
            variables: CustomVariables,
            fetchPolicy: "network-only", // Used for first execution
        }
    );

    /**
     * @param period go to next month/week/day based on view
     */
    const nextHandler = (period: "month" | "week" | "day") => {
        if (period === "month") {
            const nextMonth = moment(currentMonth).add(1, "months").format();
            setCurrentMonth(nextMonth);

            // start of month
            const monthStart = moment(nextMonth).startOf("month").format();
            // end of month
            const monthEnd = moment(nextMonth).endOf("month").format();

            // First day view => possibly a day off previous month
            const startDate = moment(monthStart).startOf("week").format("YYYY-MM-DD");
            // Last day of view => possibly a day off next month
            const endDate = moment(monthEnd).endOf("week").format("YYYY-MM-DD");

            setSearchInput({
                from: startDate,
                till: endDate,
            });
        }
        if (period === "week") {
            const nextWeek = moment(currentMonth).add(1, "weeks").format();
            setCurrentMonth(moment(currentMonth).add(1, "weeks").format());

            // start of month
            const weekStart = moment(nextWeek).startOf("week").format();
            // end of month
            const weekEnd = moment(nextWeek).endOf("week").format();

            // First day view => possibly a day off previous month
            const startDate = moment(weekStart).startOf("week").format("YYYY-MM-DD");
            // Last day of view => possibly a day off next month
            const endDate = moment(weekEnd).endOf("week").format("YYYY-MM-DD");

            setSearchInput({
                from: startDate,
                till: endDate,
            });
        }

        if (period === "day") {
            setCurrentMonth(moment(currentMonth).add(1, "days").format());

            const nextDay = moment(currentMonth).add(1, "days").format();
            setCurrentMonth(moment(currentMonth).add(1, "days").format());

            // start of month
            const dayStart = moment(nextDay).startOf("day").format();
            // end of month
            const dayEnd = moment(nextDay).endOf("day").format();

            // First day view => possibly a day off previous month
            const startDate = moment(dayStart).startOf("day").format("YYYY-MM-DD");
            // Last day of view => possibly a day off next month
            const endDate = moment(dayEnd).endOf("day").add(1, "days").format("YYYY-MM-DD");

            setSearchInput({
                from: startDate,
                till: endDate,
            });
        }
    };

    /**
     * @param period go to previous month/week/day based on view
     */
    const prevHandler = (period: "month" | "week" | "day") => {
        if (period === "month") {
            const prevMonth = moment(currentMonth).subtract(1, "months").format();
            setCurrentMonth(prevMonth);

            // start of month
            const monthStart = moment(prevMonth).startOf("month").format();
            // end of month
            const monthEnd = moment(prevMonth).endOf("month").format();

            // First day view => possibly a day off previous month
            const startDate = moment(monthStart).startOf("week").format("YYYY-MM-DD");
            // Last day of view => possibly a day off next month
            const endDate = moment(monthEnd).endOf("week").format("YYYY-MM-DD");

            // On month change, update the searchInput
            setSearchInput({
                from: startDate,
                till: endDate,
            });
        }
        if (period === "week") {
            const prevWeek = moment(currentMonth).subtract(1, "weeks").format();
            setCurrentMonth(moment(currentMonth).subtract(1, "weeks").format());

            // start of month
            const weekStart = moment(prevWeek).startOf("week").format();
            // end of month
            const weekEnd = moment(prevWeek).endOf("week").format();

            // First day view => possibly a day off previous month
            const startDate = moment(weekStart).startOf("week").format("YYYY-MM-DD");
            // Last day of view => possibly a day off next month
            const endDate = moment(weekEnd).endOf("week").format("YYYY-MM-DD");

            setSearchInput({
                from: startDate,
                till: endDate,
            });
        }
        if (period === "day") {
            setCurrentMonth(moment(currentMonth).subtract(1, "days").format());

            const prevDay = moment(currentMonth).subtract(1, "days").format();

            // start of month
            const dayStart = moment(prevDay).startOf("day").format();
            // end of month
            const dayEnd = moment(prevDay).endOf("day").format();

            // First day view => possibly a day off previous month
            const startDate = moment(dayStart).startOf("day").format("YYYY-MM-DD");
            // Last day of view => possibly a day off next month
            const endDate = moment(dayEnd).endOf("day").add(1, "days").format("YYYY-MM-DD");

            setSearchInput({
                from: startDate,
                till: endDate,
            });
        }
    };

    /**
     * Go to today, same in all views
     */
    const setToday = () => {
        setCurrentMonth(moment().format());

        // start of month
        const monthStart = moment().startOf("month").format();
        // end of month
        const monthEnd = moment().endOf("month").format();

        // First day view => possibly a day off previous month
        const startDate = moment(monthStart).startOf("week").format("YYYY-MM-DD");
        // Last day of view => possibly a day off next month
        const endDate = moment(monthEnd).endOf("week").format("YYYY-MM-DD");

        setSearchInput({
            from: startDate,
            till: endDate,
        });
    };

    /**
     * Change to clicked view
     * @param view
     */
    const onSetView = (view: number) => {
        setView(view);
    };

    /**
     * Handler to copy calendar
     */
    const copyCal = () => {
        if (icalLink !== null) {
            navigator.clipboard.writeText(icalLink);
            toast.success("Agenda link gekopieerd", { autoClose: 1500 });
        }
        trackIcalClick();
    };

    /**
     * Set page title
     */
    useEffect(() => {
        runQuery({ variables: { from: searchInput.from, till: searchInput.till } });
    }, [t, runQuery, searchInput]);

    // customized TODO: connect to knowledgebase id
    // const action = (key: any) => (
    //     <React.Fragment>
    //         <Button
    //             style={{ color: "#fff" }}
    //             onClick={() => {
    //                 alert(`Deze knop opent FAQ met item over ical invoegen in agenda applicatie`);
    //             }}
    //         >
    //             Info
    //         </Button>
    //     </React.Fragment>
    // );

    /**
     * Mobile version of calendar
     */
    if (isMobile) {
        return (
            <React.Fragment>
                <CalendarMobile
                    loading={loadingQuery}
                    currentMonth={currentMonth}
                    setToday={setToday}
                    view={view}
                    setView={onSetView}
                    copyCal={copyCal}
                    selectedDate={selectedDate}
                    data={Querydata?.getCalendarEvents || []}
                    nextHandler={nextHandler}
                    prevHandler={prevHandler}
                />
            </React.Fragment>
        );
    }

    /**
     * @param view month | week | day
     * @returns Shows view user has chosen to view
     */
    const renderSwitch = (view: number) => {
        switch (view) {
            case 0:
                return <MonthView currentMonth={currentMonth} selectedDate={selectedDate} data={Querydata?.getCalendarEvents || []} />;
            case 1:
                return <WeekView currentMonth={currentMonth} selectedDate={selectedDate} data={Querydata?.getCalendarEvents || []} />;
            default:
                return <DayView currentMonth={currentMonth} selectedDate={selectedDate} data={Querydata?.getCalendarEvents || []} />;
        }
    };
    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <TopbarCalendar
                    loading={loadingQuery}
                    currentMonth={currentMonth}
                    nextHandler={nextHandler}
                    prevHandler={prevHandler}
                    setToday={setToday}
                    view={view}
                    setView={onSetView}
                    copyCal={copyCal}
                />
                {/* Render chosen view */}
                {renderSwitch(view)}
            </div>
        </React.Fragment>
    );
};

export default CalendarModule;
