import React from "react";
import { MUIDataTableColumn } from "mui-datatables";
import { Tooltip, Typography } from "@mui/material";
import { Translation } from "react-i18next";
import AvatarReviewed from "../../../components/AvatarReviewed";
import SearchRuleData from "../../../components/SearchRuleData";
import SmallTenderMenu from "../../../components/SmallTenderMenu";
import TenderTitleWidth from "../../../components/customColumns/TenderTitleWidth";
import { LocalOfferOutlined } from "@mui/icons-material";
import LastMessageColumn from "../../../components/columns/LastMessageColumn";
import InitialDurationColumn from "../../../components/columns/InitialDurationColumn";
import ContractDesciptionColumn from "../../../components/columns/ContractDesciptionColumn";
import TypeColumn from "../../../components/columns/TypeColumn";
import ProcedureTypeColumn from "../../../components/columns/ProcedureTypeColumn";
import SectorColumn from "../../../components/columns/SectorColumn";
import SubSectorColumn from "../../../components/columns/SubSectorColumn";
import OptionsColumn from "../../../components/columns/OptionsColumn";
import DateStartColumn from "../../../components/columns/DateStartColumn";
import DateEndColumn from "../../../components/columns/DateEndColumn";
import DateNextEndColumn from "../../../components/columns/DateNextEndColumn";
import AwardDateColumn from "../../../components/columns/AwardDateColumn";
import DateInclOptionsColumn from "../../../components/columns/DateInclOptionsColumn";
import UpdatedAtColumn from "../../../components/columns/UpdatedAtColumn";
import FirstDispatchColumn from "../../../components/columns/FirstDispatchColumn";
import UpdateTypeColumn from "../../../components/columns/UpdateTypeColumn";
import PublishedVestigingsplaatsColumn from "../../../components/columns/PublishedVestigingPlaatsColumn";
import StatusDot from "../../../components/StatusDot";
import PublishedHostColumn from "../../../components/columns/PublishedHostColumn";
import { QUERY_TENDERS_MINIMUM } from "../../../graphql/queryDefinitions";
/**
 * Columns for tender worklist
 */
export const TenderColumns: MUIDataTableColumn[] = [
    // index 0
    {
        name: "id",
        options: { display: "excluded" },
    },
    // contracting authority, HAS TO BE SECOND COLUMN
    // index 1
    {
        label: "AD",
        name: "contractingAuthorities",
        options: { display: "excluded" },
    },
    // favicon, HAS TO BE THIRD COLUMN
    // index 2
    {
        name: "favicon",
        options: { display: "excluded" },
    },
    // tender title
    // index 3
    // title can fit 2 lines, CA can fit 1 line.
    {
        label: "Title",
        name: "tender",
        options: {
            sort: false,
            draggable: false,
            display: true,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Title")}</>}</Translation>;
            },
            customBodyRender(value: any, tableMeta) {
                return (
                    <>
                        <TenderTitleWidth location="Tender" tender_name={value} tender_id={tableMeta.rowData[0]} />
                    </>
                );
            },
        },
    },

    // index 4
    // Contracting Authority id
    {
        label: "CA",
        name: "ca_id",
        options: {
            display: "excluded",
        },
    },

    // index 5
    // Country
    {
        label: "country",
        name: "country",
        options: {
            display: "excluded",
        },
    },

    // Status of tender
    // index 6
    {
        label: "Status",
        name: "status",
        options: {
            display: true,
            sort: true,
            customHeadLabelRender: () => {
                return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Translation>{(t) => <>{t("Column_Header.Status")}</>}</Translation>
                    </div>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return (
                    <div style={{ display: "flex" }}>
                        <StatusDot type={tender_id} placement="left" />
                    </div>
                );
            },
        },
    },

    // Deadline date
    // index 7
    {
        label: "Deadline",
        name: "deadline_inschrijving",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Deadline")}</>}</Translation>;
            },
            customBodyRender(title: any, tableMeta) {
                return <Typography style={{ whiteSpace: "nowrap" }}>{title}</Typography>;
            },
        },
    },

    // index 8
    // Type (services, works, supplies)
    {
        label: "Type",
        name: "type",
        options: {
            sort: true,
            display: true,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Type")}</>}</Translation>;
            },
            customBodyRender(value: any, tableMeta) {
                return <TypeColumn tender_id={value} />;
            },
        },
    },

    // Type procedure
    // index 9
    {
        label: "TypeProcedure",
        name: "type_procedure",
        options: {
            display: false,
            sort: true,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500, width: 110 }}>
                                {t("Column_Header.TypeProcedure")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                // see TenderTitleWidth.tsx for break on (x) lines
                return <ProcedureTypeColumn tender_id={tender_id} />;
            },
        },
    },

    // start date
    // index 10
    {
        label: "DurationStart",
        name: "looptijd_start",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500 }}>
                                {t("Column_Header.DurationStart")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <DateStartColumn tender_id={tender_id} />;
            },
        },
    },

    // End date
    // index 11
    {
        label: "DurationEnd",
        name: "looptijd_einde",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500 }}>
                                {t("Column_Header.DurationEnd")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <DateEndColumn tender_id={tender_id} />;
            },
        },
    },

    // First possible enddate
    // index 12
    {
        label: "FirstEndDate",
        name: "next_looptijd_einde",
        options: {
            display: true,
            sort: false,

            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => <Typography style={{ fontWeight: 500, width: 110 }}>{t("Column_Header.FirstEndDate")}</Typography>}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <DateNextEndColumn tender_id={tender_id} />;
            },
        },
    },

    // Land & plaats
    // index 13
    {
        label: "City",
        name: "published_vestigingsplaats",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.City")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedVestigingsplaatsColumn tender_id={tender_id} />;
            },
        },
    },

    // Platform
    // index 14
    {
        label: "Source",
        name: "published_host",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500, width: 110 }}>
                                {t("Column_Header.Source")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedHostColumn tender_id={tender_id} />;
            },
        },
    },

    // award date
    // index 15
    {
        label: "Award_date",
        name: "datum_gunning",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Award_date")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <AwardDateColumn tender_id={tender_id} />;
            },
        },
    },

    // looptijd_einde_incl_opties
    // index 16
    {
        label: "Date_incl_options",
        name: "looptijd_einde_incl_opties",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Date_incl_options")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <DateInclOptionsColumn tender_id={tender_id} />;
            },
        },
    },

    // first_dispatchdate
    // index 17
    {
        label: "First_dispatchdate",
        name: "first_dispatchdate",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.First_dispatchdate")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <FirstDispatchColumn tender_id={tender_id} />;
            },
        },
    },

    // updated_at
    // index 18
    {
        label: "Last_update",
        name: "updated_at",
        options: {
            display: false,
            sort: true,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Last_update")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <UpdatedAtColumn tender_id={tender_id} />;
            },
        },
    },

    // type_update
    // index 19
    {
        label: "Type_update",
        name: "type_update",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Type_update")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <UpdateTypeColumn tender_id={tender_id} />;
            },
        },
    },

    // sector_name
    // index 20
    {
        label: "Sector_name",
        name: "sector_name",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Sector_name")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <SectorColumn tender_id={tender_id} />;
            },
        },
    },

    // Subsector_name
    // index 21
    {
        label: "Subsector_name",
        name: "subsector_name",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Subsector_name")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <SubSectorColumn tender_id={tender_id} />;
            },
        },
    },

    // Contract options
    // index 23
    {
        label: "OptionsMonth",
        name: "optionsMonth",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.OptionsMonth")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <OptionsColumn tender_id={tender_id} />;
            },
        },
    },

    // Contract description
    // index 24
    {
        label: "ContractDescription",
        name: "contractDescription",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.ContractDescription")}</>}</Translation>;
            },
            customBodyRender(field: any, tableMeta) {
                return <ContractDesciptionColumn tender_id={field} />;
            },
        },
    },

    // initialDuration
    // index 25
    {
        label: "InitialDuration",
        name: "initialDuration",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.InitialDuration")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <InitialDurationColumn tender_id={tender_id} />;
            },
        },
    },

    // User avatars who liked tender
    // index 26
    // release 4.1
    {
        label: "Reviews",
        name: "reviews",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Reviews")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return (
                    <>
                        <AvatarReviewed tender_id={tender_id} />
                    </>
                );
            },
        },
    },

    // searchRulelabels will be shown here.
    // index 27
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        // TODO: show max 5 searchrules in column
        label: "Actions",
        name: "searches",
        options: {
            sort: false,
            display: true,
            draggable: false,
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(data: any, tableMeta) {
                return (
                    <div style={{ display: "flex", flexDirection: "row", width: "70%" }}>
                        {data.length > 4 ? (
                            <Tooltip placement="left" title="Meerdere resultaten gevonden">
                                <LocalOfferOutlined style={{ color: "#9e9e9e" }} />
                            </Tooltip>
                        ) : (
                            <SearchRuleData placement="top" tender_id={tableMeta.rowData[0]} searches={data} />
                        )}
                    </div>
                );
            },
        },
    },

    // hasConversations
    // index 27
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        name: "hasConversations",
        options: {
            display: "excluded",
        },
    },

    // show last message
    // index 28
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        label: "LastPrivateNote",
        name: "lastPrivateNote",
        options: {
            sort: false,
            display: false,
            draggable: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.LastPrivateNote")}</>}</Translation>;
            },
            customBodyRender(id: any, tableMeta) {
                return <LastMessageColumn tender_id={id} />;
            },
        },
    },

    // actions to like, dislike, share tender
    // index 29
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        label: "Actions",
        name: "",
        options: {
            sort: false,
            display: true,
            draggable: false,
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(title: any, tableMeta) {
                return (
                    <SmallTenderMenu
                        id={tableMeta.rowData[0]}
                        title={tableMeta.rowData[3]}
                        userStatus={tableMeta.rowData[29]}
                        hasConversations={tableMeta.rowData[27]}
                        refetchQueries={[QUERY_TENDERS_MINIMUM]}
                    />
                );
            },
        },
    },
];
