import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// GQL
import { useLazyQuery } from "@apollo/client";

// Components
import WidgetBox from "../../../components/WidgetBox";

// Material UI components
import { Box, List } from "@mui/material";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";

//Import momentjs
import moment from "moment";

//Import icon(s)
import StarIcon from "@mui/icons-material/Star";
import FaviconBox from "../../../components/FaviconBox";
import {
    GetFiveLatestCaActivities,
    GetFiveLatestCaActivitiesVariables,
    GetFiveLatestCaActivities_filterContractingAuthorities_results,
} from "../../../__generated__/GetFiveLatestCaActivities";
import { ContractingAuthoritiesSearchInput, ContractingAuthoritiesFilterSource, SortOrder } from "../../../__generated__/globalTypes";
import UpdateWidgetError from "../updateWidgets/UpdateWidgetError";
import UpdateWidgetLoader from "../updateWidgets/UpdateWidgetLoader";
import { Tooltip } from "@mui/material";
import { Business } from "@mui/icons-material";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import { QUERY_FIVE_LATEST_CA_ACTIVITIES } from "../../../graphql/queryDefCA";
interface Props {
    a?: number;
}

const defaultSearch: ContractingAuthoritiesSearchInput = { source: ContractingAuthoritiesFilterSource.MY_CONTRACTING_AUTHORITIES };
const variables: GetFiveLatestCaActivitiesVariables = {
    page: 1,
    count: 5,
    orderField: "last_update_date_timestamp",
    order: SortOrder.DESC,
    search: defaultSearch,
};
const CaUpdateWidget: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const yellow = "#F57117";
    const [caData, setCaData] = useState<GetFiveLatestCaActivities_filterContractingAuthorities_results | null>();

    // Track event
    const goToFullList = () => {
        trackEvent({ category: "Dashboard", action: "go-to-ca-updateview" });
    };

    const goToDetailPage = () => {
        trackEvent({ category: "Dashboard", action: "go-to-ca-detailpage-from-updatewidget" });
    };

    // Define the GQL query
    const [run, { loading, error }] = useLazyQuery<GetFiveLatestCaActivities, GetFiveLatestCaActivitiesVariables>(QUERY_FIVE_LATEST_CA_ACTIVITIES, {
        variables: variables,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.filterContractingAuthorities) {
                setCaData(data.filterContractingAuthorities.results);
            }
        },
    });

    // Set page title
    useEffect(() => {
        run({
            variables: variables,
        });
    }, [run]);

    // if loading -> show UpdateWidgetLoader with given attributes
    // TODO: change !loader to loader, temporary set to !loader to see loader UI
    if (loading) return <UpdateWidgetLoader color={yellow} type={"ca"} />;

    // if error or empty -> show UpdateWidgetError component with given attributes
    if (error || caData?.paginatorInfo.total === 0) return <UpdateWidgetError type={"ca"} errorText={t("contractingAuthorities")} color={yellow} />;

    return (
        <div id="update-ca-update-widget">
            <WidgetBox
                header={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <StarIcon style={{ color: `${yellow}`, marginRight: 4, height: 20 }} />
                            {t("dashboardPage.UpdatesCA")}
                        </div>
                        <Typography>
                            <Link
                                id="link-to-full-list"
                                data-testid="show-all-ca-updates"
                                to="/contracting-authorities/starred"
                                onClick={goToFullList}
                            >
                                {" "}
                                {t("dashboardPage.ShowAll")}
                            </Link>
                        </Typography>
                    </div>
                }
                highlight="yellow"
                padding={0}
            >
                <Box sx={{ width: "100%", height: { xs: "335px", md: "355px" }, position: "relative" }}>
                    {/* Underlaying icon with text  */}
                    <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}>
                        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            {<Business style={{ color: "#f2f2f270", height: "9rem", width: "9rem" }} />}
                            <Typography variant="h5" style={{ color: "#f2f2f2" }}>
                                {t("contractingAuthorities")}
                            </Typography>
                        </div>
                    </div>
                    <List sx={{ padding: 0, overflow: "auto", zIndex: 99 }}>
                        {caData?.data.map((row) => {
                            return (
                                <React.Fragment key={row.id}>
                                    <ListItem
                                        id="ca-list-item"
                                        sx={{
                                            paddingRight: "64px",
                                            paddingLeft: "8px",
                                            backgroundColor: "#ffffff",
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                            }}
                                        >
                                            <FaviconBox name={row.name} favicon={row.favicon_resized ?? row.favicon} color={yellow} />
                                        </ListItemIcon>
                                        <ListItemText
                                            sx={{
                                                "& .MuiListItemText-root": {
                                                    marginRight: "60px",
                                                },
                                            }}
                                            primary={
                                                <Typography noWrap>
                                                    <Link id="ca-list-item-link" to={`/contracting-authorities/${row.id}`} onClick={goToDetailPage}>
                                                        {row.name}
                                                    </Link>
                                                </Typography>
                                            }
                                            secondary={
                                                <Tooltip
                                                    placement="bottom"
                                                    componentsProps={{ tooltip: { sx: { marginTop: "0px" } } }}
                                                    enterNextDelay={100}
                                                    title={row.last_update_text as string}
                                                >
                                                    <Typography noWrap>{row.last_update_text}</Typography>
                                                </Tooltip>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            {row.last_update_date && (
                                                <React.Fragment>
                                                    <Typography align="right">{moment(row.last_update_date).format("D MMM")}</Typography>
                                                    <Typography align="right">
                                                        {moment(row.last_update_date).isSame(moment(), "year")
                                                            ? ""
                                                            : moment(row.last_update_date).format("YYYY")}
                                                    </Typography>
                                                </React.Fragment>
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Box>
            </WidgetBox>
        </div>
    );
};

export default CaUpdateWidget;
