import React from "react";
import { CircularProgress, List, Paper, Typography } from "@mui/material";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { ApolloError } from "@apollo/client";

import InfiniteScroll from "react-infinite-scroll-component";
import {
    FilterWorklistSplitView_filterTenders_results_data,
    FilterWorklistSplitView_filterTenders_results_paginatorInfo,
} from "../../../__generated__/FilterWorklistSplitView";

import SplitDetailListItem from "./SplitDetailListItem";
import { ContractingAuthoritySplitView } from "./SplitView";
import { useLocation } from "react-router-dom";

interface Props {
    /**
     * Selected tender id
     */
    selectedId: string;
    /**
     *
     * @param item selected tender id
     * @returns new id of chosen tender (clicked list item)
     */
    setSelectedId: (item: string) => void;
    /**
     *
     * @param page pagenumber
     * @returns new page
     */
    setPage: (page: number) => void;
    /**
     * Current page
     */
    page: number;
    /**
     * paginatorinfo
     */
    paginatorInfo: FilterWorklistSplitView_filterTenders_results_paginatorInfo;
    /**
     * fetched data to fill list
     */
    data: FilterWorklistSplitView_filterTenders_results_data[] | null | undefined;
    /**
     * Loading state
     */
    loading: boolean;
    /**
     * Apollo error when a problem occurs
     */
    error: ApolloError | undefined;
    /**
     * elevate paper component (optional)
     * if not set => elevation = 0
     */
    elevation?: number;
    /**
     * custom contracting authority data (optional)
     * now used in ca module only
     * If not set => clients data off tender object will be used
     */
    ca?: ContractingAuthoritySplitView;
    mp?: string;
}

const SplitDetailList: React.FC<Props> = ({
    selectedId,
    setSelectedId,
    paginatorInfo,
    data,
    loading,
    error,
    setPage,
    page,
    elevation = 0,
    ca,
    mp,
}) => {
    const { pathname } = useLocation();

    if (loading && !data) {
        return (
            <Paper
                elevation={elevation}
                square
                style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <FullPageSpinner />
            </Paper>
        );
    }

    if (error || !data) {
        return (
            <Paper
                elevation={elevation}
                square
                style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                        Geen resultaten gevonden
                    </Typography>
                </div>
            </Paper>
        );
    }

    return (
        <Paper id="splitscroll" square elevation={elevation}>
            <InfiniteScroll
                height={"72.6vh"}
                /**
                 * Scroll if 98% is scrolled
                 */
                scrollThreshold={0.98}
                /**
                 * id of menulist to target the menu as window instead of whole window
                 */
                scrollableTarget="splitscroll"
                /**
                 * This is important field to render the next data.
                 */
                dataLength={data.length} //This is important field to render the next data
                /**
                 * function to get next data
                 */
                next={() => setPage(page + 5)}
                /**
                 * boolean to check if more data is available
                 */
                hasMore={paginatorInfo.hasMorePages}
                /**
                 * loader component
                 */
                loader={
                    loading && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress classes={{ root: "spinner" }} size={18} />
                        </div>
                    )
                }
                /**
                 * message when bottom is reached
                 */
                endMessage={<p style={{ textAlign: "center" }} />}
            >
                <List style={{ padding: 0 }} id="splitlist">
                    {data.map((item) => {
                        return (
                            <SplitDetailListItem
                                key={item.id}
                                setSelectedId={setSelectedId}
                                selectedId={selectedId}
                                item={item}
                                ca={pathname.includes("tenders") ? undefined : ca}
                                mp={mp}
                            />
                        );
                    })}
                </List>
            </InfiniteScroll>
        </Paper>
    );
};

export default SplitDetailList;
