import React from "react";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";

interface Props {
    item: any;
    setChosenEntity: (id: any) => void;
    chosenEntity: string;
}

const ColumnTemplateBox: React.FC<Props> = ({ item, setChosenEntity, chosenEntity }) => {
    return (
        <React.Fragment>
            <Card elevation={0} variant="outlined" sx={{ margin: "8px 0px", backgroundColor: chosenEntity === item.id ? "#e7e7e7" : "transparent" }}>
                <CardActionArea onClick={() => setChosenEntity(item.id)}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {item.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {item.explanation}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
};

export default ColumnTemplateBox;
