import { EditNote, Home, InterpreterMode, Poll } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React from "react";

interface Props {
    a?: number;
}

const MobileTendenzNavigation: React.FC<Props> = (props) => {
    const [value, setValue] = React.useState("");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <BottomNavigation
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 9999,
                /*the alpha value controls the transparency*/
                background: `rgba( 255, 255, 255, 0.3 )`,
                /* This controls the blurring effect*/
                backdropFilter: `blur( 7.5px )`,
                // -webkit-backdropilter: blur( 7.5px );
                /*Adding the shadow*/
                boxShadow: `0 8px 32px 0 rgba( 0, 0, 0, 0.18 )`,
            }}
            showLabels
            value={value}
            onChange={handleChange}
        >
            <BottomNavigationAction href="#tendenz" style={{ textDecoration: "none" }} label="TendenZ" value="favorites" icon={<Home />} />
            <BottomNavigationAction href="#program" style={{ textDecoration: "none" }} label="Programma" value="program" icon={<InterpreterMode />} />
            {/* <BottomNavigationAction href="#location" style={{ textDecoration: "none" }} label="Locatie" value="location" icon={<LocationOn />} /> */}
            <BottomNavigationAction href="#register" style={{ textDecoration: "none" }} label="Aanmelden" value="register" icon={<EditNote />} />
            <BottomNavigationAction href="#poll" style={{ textDecoration: "none" }} label="Peiling" value="poll" icon={<Poll />} />
        </BottomNavigation>
    );
};

export default MobileTendenzNavigation;
