import React, { useState } from "react";
import {
    Box,
    CircularProgress,
    CircularProgressProps,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    IconButton,
    Button,
    // InputAdornment,
    // Input,
} from "@mui/material";

import {
    //  Edit, Check,
    MoreVert,
    LocalOffer,
    FindInPage,
} from "@mui/icons-material";
// Icons used on this page
import { GetUserSearchRules_currentUser_opportunitySearches } from "../../../../__generated__/GetUserSearchRules";
import { useTranslation } from "react-i18next";
// import SearchLineSummarySentence from "./SearchLineSummarySentence";
// import OppSearchLineItem from "./OppSearchLineItem";
import OppSearchLineSummarySentence from "./OppSearchLineSummarySentence";
import { gql, useMutation } from "@apollo/client";
import { acceptCopiedOppSearch, acceptCopiedOppSearchVariables } from "../../../../__generated__/acceptCopiedOppSearch";
import { DeleteOpportunitySearch, DeleteOpportunitySearchVariables } from "../../../../__generated__/DeleteOpportunitySearch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DELETEOPPORTUNITYSEARCHRULE } from "../../../../graphql/mutationDefinitions";
import { QUERY_SEARCHRULES } from "../../../../graphql/queryDefCurrentUser";
// import { UPDATESEARCHRULEOPPORTUNITY } from "../../../../graphql/opportunityQueries/queryDefinitions";
// import { useMutation } from "@apollo/client";
// import { UpdateOpportunitySearch, UpdateOpportunitySearchVariables } from "../../../../__generated__/UpdateOpportunitySearch";
// import OppSearchLineSummarySentence from "./OppSearchLineSummarySentence";
interface Props {
    color: string;
    searchline: GetUserSearchRules_currentUser_opportunitySearches;
    open: boolean;
    searchterm: string[];
    handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    progress: number;
    handleOpenStats: () => void;
    handleClickSub: (event: React.MouseEvent<HTMLButtonElement>) => void;
    type: string;
}

/**
 * Loader with percentage in middle of circle
 */
const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const SearchLineSettings: React.FC<Props> = ({
    color,
    searchline,
    open,
    searchterm,
    handleChange,
    progress,
    handleOpenStats,
    handleClickSub,
    type,
}) => {
    const { t } = useTranslation();
    const bgColor = "#ffffff";
    const green = "#225E4D";
    const blue = "#173357";
    const navigate = useNavigate();

    // const [showEdit, setShowEdit] = useState<boolean>(false);

    // Local state of individual searchrule
    const [
        searchState,
        //  setSearchState
    ] = useState<GetUserSearchRules_currentUser_opportunitySearches>(searchline);

    const [accept_opp_search, { loading: loadingOppMutation, error: errorOppMutation }] = useMutation<
        acceptCopiedOppSearch,
        acceptCopiedOppSearchVariables
    >(ACCEPT_INCOMING_OPP_SEARCH);

    /**
     * Navigate to worklist pagw with searchrule filter active
     * @param id id of clicked searchrule
     */
    const setWof = (id: number) => {
        sessionStorage.removeItem("wof");
        sessionStorage.setItem(
            "wof",
            JSON.stringify({
                searches: [id],
            })
        );
        navigate(`/opportunities/worklist`);
    };

    /**
     * Mutation to remove opportunity searchrule
     */
    const [removeOppRule] = useMutation<DeleteOpportunitySearch, DeleteOpportunitySearchVariables>(DELETEOPPORTUNITYSEARCHRULE);

    /**
     * accept incoming opportunity searchrule
     */
    const acceptOpp_search = async (id: string) => {
        // Function to like organization
        if (loadingOppMutation) {
        }
        if (errorOppMutation) {
            // toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await accept_opp_search({
                variables: {
                    opp_search_id: parseInt(id),
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
            });
        } catch (e) {
            // toast.error("Fout tijdens opslaan (catch)");
        } finally {
        }
    };

    /**
     * Handler to remove a opportunity searchrule (shared)
     */
    const cancel_opp_search = async (id: string) => {
        try {
            await removeOppRule({
                variables: {
                    id: id,
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    /**
     *
     * @param prop key of item that has to be updated
     * @returns return new updated state
     */
    // const handleChangeSearch = (prop: keyof GetUserSearchRules_currentUser_opportunitySearches) => (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchState({ ...searchState, [prop]: event.target.value });
    // };

    /**
     * Mutation to update opportunity search
     */
    // const [update] = useMutation<UpdateOpportunitySearch, UpdateOpportunitySearchVariables>(UPDATESEARCHRULEOPPORTUNITY);

    /**
     * Function to update an existing searchrule
     */
    // const updateSearchRule = async () => {
    //     try {
    //         await update({
    //             variables: {
    //                 id: searchState.id,
    //                 name: searchState.name,
    //                 search: {
    //                     query: searchState.query,
    //                     filters: JSON.parse(JSON.parse(searchState.filters)),
    //                 },
    //             },
    //         });
    //     } catch (e) {}
    // };

    return (
        // if type = tender => blue color, if type = opportunity => green color
        <ListItem
            style={{
                marginBottom: open ? "0px" : "5px",
                borderLeft: type === "tender" ? `5px solid ${blue}` : type === "opportunity" ? `5px solid ${green}` : undefined,
                backgroundColor: bgColor,
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: "40px",
                    opacity: searchline.state === "PENDING_ACCEPTANCE" ? 0.5 : 1,
                }}
                style={{ color: color }}
            >
                {type === "tender" && <LocalOffer style={{ marginRight: "8px", marginLeft: "-8px" }} />}
                {type === "opportunity" && (
                    <IconButton disabled style={{ marginRight: "8px", marginLeft: "-8px" }}>
                        <FindInPage sx={{ color: color }} />
                    </IconButton>
                )}
            </ListItemIcon>

            <ListItemText
                sx={{
                    color: "#173357",
                    opacity: searchline.state === "PENDING_ACCEPTANCE" ? 0.5 : 1,
                }}
                /**
                 * primary = title of searchline
                 */
                primary={
                    // Inline name editor
                    // showEdit ? (
                    // <Input
                    //     name={"name"}
                    //     value={searchState.name}
                    //     // onchange handler to change function and push with mutation to server
                    //     onChange={handleChangeSearch("name")}
                    //     endAdornment={
                    //         <InputAdornment position="end">
                    //             <IconButton
                    //                 aria-label="save name"
                    //                 size="small"
                    //                 onClick={() => {
                    //                     updateSearchRule();
                    //                     setShowEdit(false);
                    //                 }}
                    //             >
                    //                 <Check />
                    //             </IconButton>
                    //         </InputAdornment>
                    //     }
                    // />
                    // ) : (
                    // Show name of searchline
                    <Typography style={{ display: "flex", alignItems: "center", marginTop: 3, marginBottom: 3 }}>
                        {searchState.name}
                        {/* <IconButton style={{ width: 12, height: 12 }} onClick={() => setShowEdit(true)}>
                            <Edit style={{ width: 16, height: 16 }} />
                        </IconButton> */}
                    </Typography>
                    // )
                }
                /**
                 * secondary = summary of searchline
                 * TODO, user can edit inline. add regions, add or remove searchterms.
                 * create react fragment to add more features
                 */
                secondary={<OppSearchLineSummarySentence searchline={searchline} />}
            />

            {/* On smaller devices -> all actions in submenu. */}
            {/* TODO: change searchline.id to state or percentage*/}
            {searchline.state === "PENDING_ACCEPTANCE" ? (
                <div>
                    {/* Opportunity buttonset */}
                    {type === "opportunity" && (
                        <div>
                            <Button size="small" variant="text" onClick={() => cancel_opp_search(searchline.id)}>
                                weigeren
                            </Button>
                            <Button size="small" variant="contained" style={{ marginLeft: "8px" }} onClick={() => acceptOpp_search(searchline.id)}>
                                accepteren
                            </Button>
                        </div>
                    )}
                </div>
            ) : searchline.id === "" ? (
                <React.Fragment>
                    <Typography variant="button" style={{ marginRight: 12 }}>
                        {t("fetchSearchrule")}
                    </Typography>
                    <CircularProgressWithLabel value={progress} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {searchline.state === "FINISHED" ? (
                        <Button onClick={() => setWof(parseInt(searchline.id))} size="small">
                            Toon resultaten
                        </Button>
                    ) : searchline.state === "QUEUED" || searchline.state === "RUNNING" ? (
                        <Button disabled={true} size="small">
                            In uitvoering
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Button onClick={handleOpenStats} size="small">
                        {searchline.id === ""
                            ? t("fetchSearchrule")
                            : open
                            ? t("Hide_delivery")
                            : type === "opportunity"
                            ? "Toon bezorging"
                            : t("Show_delivery")}
                    </Button>
                    <IconButton onClick={handleClickSub}>
                        <MoreVert fontSize="small" />
                    </IconButton>
                </React.Fragment>
            )}
        </ListItem>
    );
};

export default SearchLineSettings;

export const ACCEPT_INCOMING_OPP_SEARCH = gql`
    mutation acceptCopiedOppSearch($opp_search_id: Int!) {
        acceptCopiedOpportunitySearch(search_id: $opp_search_id)
    }
`;
