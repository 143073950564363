import React, { useState } from "react";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { Share } from "@mui/icons-material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useCurrentUserOrganization } from ".././contextProviders/CurrentUserOrganization";
import { GetColleagues } from "../../__generated__/GetColleagues";
import { QUERY_SUBSCRIPTION_USERS } from "../../graphql/queryDefUserAndTeams";
import { GetSubscriptionUsers } from "../../__generated__/GetSubscriptionUsers";
import { ConversationListItem, Team } from ".././tenderNote/ConversationsList";
import { chatIsAlreadyInitiated, teamChatIsAlreadyInitiated, userIsNotCu, userIsTeamMember } from "../../utils/messenger";
import {
    GetConversationGroupForSharing,
    GetConversationGroupForSharingVariables,
    GetConversationGroupForSharing_conversationGroup_conversations,
} from "../../__generated__/GetConversationGroupForSharing";
import ShareTenderButtonComponent from "./ShareTenderButtonComponent";
import TeamListItem from "./TeamListItem";
import UserListItem from "./UserListItem";
import { SendChatMessage, SendChatMessageVariables } from "../../__generated__/SendChatMessage";
import { toast } from "react-toastify";
import { GetTenderStateForUser } from "../../__generated__/GetTenderStateForUser";
import { useTenderChat } from "../contextProviders/TenderChatContext";
import { QUERY_TENDER_STATE_FOR_USER } from "../../graphql/queryDefinitions";
import { QUERY_COLLEAGUES, SENDTENDERMESSAGE } from "../../graphql/queryDefChat";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    tender_id: string;
    buttonSize: "small" | "medium";
    modelType: string;
    title: string | null;
}

export interface ShareSelectionItem {
    id: string;
    team: boolean;
    conversation: boolean;
}

const ShareTenderButton: React.FC<Props> = ({ tender_id, buttonSize, modelType, title }) => {
    const { org_id } = useCurrentUserOrganization();
    const [openShare, setOpenShare] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [arrayWithUsersOrTeamsToShare, setArrayWithUsersOrTeamsToShare] = useState<ShareSelectionItem[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [convID, setConvID] = useState<string>("");
    const { setOpenModal, setChatTarget } = useTenderChat();
    const [openChatAfterShare, setOpenChatAfterShare] = useState<boolean>(true);

    const { trackEvent } = useMatomo();

    // Track event
    const trackSharing = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const { refetch } = useQuery<GetTenderStateForUser>(QUERY_TENDER_STATE_FOR_USER, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    /**
     * Mutation to share tender
     * Actually you send a message to multiple users
     */
    const [send] = useMutation<SendChatMessage, SendChatMessageVariables>(SENDTENDERMESSAGE);

    const handleShareTender = () => {
        trackSharing({ category: "Tender", action: "Click-share-tender-to-user-or-team", href: window.location.href });

        if (arrayWithUsersOrTeamsToShare.length > 0) {
            arrayWithUsersOrTeamsToShare.forEach(async (item: ShareSelectionItem) => {
                const varToUse = item.conversation
                    ? {
                          conversation: item.id,
                          message: "Ik wil deze aanbesteding graag met je delen",
                          conversation_group_id: convID,
                      }
                    : item.team === false && item.conversation === false
                    ? {
                          message: "Ik wil deze aanbesteding graag met je delen",
                          to_user: item.id,
                          conversation_group_id: convID,
                      }
                    : {
                          message: "Ik wil deze aanbesteding graag met je delen",
                          to_team: item.id,
                          conversation_group_id: convID,
                      };

                try {
                    await send({
                        variables: varToUse,
                    });
                } catch (e) {
                    toast.error("Er ging iets mis", { autoClose: 1500 });
                }
            });
            setOpenShare(false);

            setTimeout(() => {
                if (openChatAfterShare) {
                    setChatTarget(tender_id, modelType, title as string, "");
                    setOpenModal(true);
                    /**
                     * remove anchor
                     */
                    setAnchorEl(null);
                    handleReset();
                    /**
                     * Refetch counter of unreadmessages
                     */
                    refetch();
                } else {
                    // show toaster
                    toast.success("Verzonden", { autoClose: 1500 });
                    /**
                     * remove anchor
                     */
                    setAnchorEl(null);
                    handleReset();
                    /**
                     * Refetch counter of unreadmessages
                     */
                    refetch();
                }
            }, 500);
        }
    };

    /**
     * @param value clicked user/team
     * @returns return arrayWithUsersOrTeamsToShare or unchecked state of listitem
     */
    const handleToggle = (e: any, value: ShareSelectionItem) => {
        e.stopPropagation();

        const checkedids = arrayWithUsersOrTeamsToShare.map((i) => i.id);
        const currentIndex = checkedids.indexOf(value.id);
        const newChecked = [...arrayWithUsersOrTeamsToShare];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setArrayWithUsersOrTeamsToShare(newChecked);
    };

    /**
     * Set array back to empty array
     */
    const handleReset = () => {
        setArrayWithUsersOrTeamsToShare([]);
    };

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenShare(true);
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenShare(false);
        handleReset();
    };

    /**
     * Query all conversation of clicked chat
     * Chat has modeltype (module) and modelid (id of clicked chat)
     */
    const convGrp = useQuery<GetConversationGroupForSharing, GetConversationGroupForSharingVariables>(QUERY_CONVERSATION_GROUP, {
        variables: { id: tender_id, modelType },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setConvID(data.conversationGroup.id);
        },
    });

    /**
     * Query colleagues of user to create contactlist
     */
    const colleagueTeams = useQuery<GetColleagues>(QUERY_COLLEAGUES);

    /**
     * Query colleagues of user to create contactlist
     */
    const colleagues = useQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
    });

    /**
     * List of active conversations with some customized props to show in GUI
     */
    const conversationList: ConversationListItem[] =
        convGrp.data?.conversationGroup.conversations?.map((conversation: GetConversationGroupForSharing_conversationGroup_conversations) => ({
            /**
             * Conversation id
             */
            id: conversation.hashid,
            /**
             * Id of user
             */
            userIds: conversation.users.map((u) => u.id),
            /**
             * user object
             * */
            users: conversation.users,
            /**
             * Name of user
             */
            name: conversation.users
                .filter((i) => i.id !== localStorage.getItem("user_id"))
                .map((u) => `${u.employee.givenname} ${u.employee.familyname}`)
                .join(""),
            /**
             * Team id
             */
            team_id: conversation.team?.id,
            /**
             * Team name
             */
            team_name: conversation.team?.name,
            /**
             * Last message in array
             */
            lastMessage: "-",
            /**
             * Last message in timestamp
             */
            lastMessageTime: "-",
            /**
             * Check if private notes or chat
             */
            private: false,
            /**
             * Unread messages count
             */
            unreadMessagesCount: 0,
        })) || [];

    /**
     * Contactlist of user
     */
    const contacts =
        colleagues.data?.organization?.users
            .map((user) => ({
                id: user.id || "",
                name: user.employee.name || "",
                familyName: user.employee.familyname || "",
            }))
            .filter((colleague) => userIsNotCu(colleague)) || [];

    /**
     * Create array of teams where current user is a teamMember
     */
    const teams =
        colleagueTeams.data?.currentUser.employee.organization.teams
            .filter((team) => userIsTeamMember(team))
            .map((team) => ({
                teamId: team.id,
                teamName: team.name,
                members:
                    team.users.map((user) => ({
                        id: user.id || "",
                        name: user.employee.givenname || "",
                        familyName: user.employee.familyname || "",
                    })) || [],
            })) || [];

    /**
     * key that has to be unique in array below
     */
    const key = "teamId";

    /**
     * Create array of unique teams
     */
    const teamArrayUniqueByKey = [...new Map(teams.map((item: Team) => [item[key], item])).values()];

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and givenname
     */
    const results = teamArrayUniqueByKey.filter((element) => {
        // 👇️ using OR (||) operator
        return (
            element.teamName?.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            element.members.some((e) => e.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
        );
    });

    // Create array with unqiue team objects
    const teamResults = !searchTerm ? teamArrayUniqueByKey : results;

    const teamsComponent = teamResults.map((team) => {
        const idOfConversation = teamChatIsAlreadyInitiated(team.teamId, conversationList);

        return (
            <React.Fragment key={team.teamId}>
                <TeamListItem
                    idOfConversation={idOfConversation}
                    onToggle={handleToggle}
                    team={team}
                    arrayWithUsersOrTeamsToShare={arrayWithUsersOrTeamsToShare}
                />
                <Divider />
            </React.Fragment>
        );
    });

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and givenname
     */
    const contactresults = !searchTerm ? contacts : contacts.filter((item) => item.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    const colleaguesComponents = contactresults
        .filter((i) => i.id !== localStorage.getItem("user_id"))
        .map((contact) => {
            const username = `${contact.name}`;
            const idOfConversation = chatIsAlreadyInitiated(contact.id, conversationList);
            return (
                <React.Fragment key={contact.id}>
                    <UserListItem
                        arrayWithUsersOrTeamsToShare={arrayWithUsersOrTeamsToShare}
                        contact={contact}
                        onToggle={handleToggle}
                        idOfConversation={idOfConversation}
                        username={username}
                    />
                    <Divider />
                </React.Fragment>
            );
        });

    /**
     * handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */
    const handleSearchUser = (event: any) => {
        event.stopPropagation();
        const {
            target: { value },
        } = event;

        setSearchTerm(value);
    };

    const checkLength = Boolean(contactresults.filter((i) => i.id !== localStorage.getItem("user_id")).length + teamResults.length === 0);

    return (
        <React.Fragment>
            <Tooltip title="Deel aanbesteding" enterNextDelay={100} placement="top">
                <IconButton onClick={handleClickSub} size={buttonSize}>
                    <Share fontSize="small" />
                </IconButton>
            </Tooltip>

            <ShareTenderButtonComponent
                showPlaceholder={checkLength}
                disableShare={Boolean(arrayWithUsersOrTeamsToShare.length === 0)}
                openChatAfterShare={openChatAfterShare}
                setOpenChatAfterShare={setOpenChatAfterShare}
                shareOpened={openShare}
                anchorEl={anchorEl}
                handleCloseSub={handleCloseSub}
                teamsComponent={teamsComponent}
                colleaguesComponents={colleaguesComponents}
                handleSearchUser={handleSearchUser}
                searchTerm={searchTerm}
                onShareTender={handleShareTender}
            />
        </React.Fragment>
    );
};

export default ShareTenderButton;

//? Get conversationgroup of current user
export const QUERY_CONVERSATION_GROUP = gql`
    query GetConversationGroupForSharing($id: ID!, $modelType: String!) {
        conversationGroup(id: $id, modelType: $modelType) {
            id
            conversations {
                hashid
                team {
                    id
                    name
                }
                users {
                    id
                    employee {
                        id
                        givenname
                        familyname
                    }
                }
            }
        }
    }
`;
