import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import WorkListTable from "../worklistWidget/WorkListTableDashboard";
import TenderUpdateWidget from "../dashboardWidgets/TenderUpdateWidget";
import OrganizationUpdateWidget from "../organizations/OrganizationUpdateWidget";
import CaUpdateWidget from "../contractingAuthorities/CaUpdateWidget";
import ReviewGraph from "../ReviewedGraph/ReviewGraph";
import ContractGraphOverview from "../contractGraph/ContractGraphOverview";
import TileContractContainer from "../tileContract/TileContractContainer";
import { useLazyQuery, useMutation } from "@apollo/client";
import { QUERY_DASHBOARD_LAYOUT } from "../../../graphql/queryDefCurrentUser";
import { GetDashboardLayout, GetDashboardLayout_GetDashboardLayout_rows } from "../../../__generated__/GetDashboardLayout";
import { UPDATE_DASHBOARD } from "../../../graphql/mutationDefinitions";
import { updateDashboardLayout, updateDashboardLayoutVariables } from "../../../__generated__/updateDashboardLayout";
import { toast } from "react-toastify";
import MyTasksWidget from "../myTasks/MyTasksWidget";
import MyProjectsWidget from "../myProjects/MyProjectsWidget";
import MyCalendarWidget from "../myCalendarItems/MyCalendarWidget";

interface RowsContextType {
    loading: boolean;
    cancelChanges: () => void;
    removeColumn: (rowId: string, columnId: string) => void;
    update: () => Promise<void>;
    updateLoad: boolean;
    rows: GetDashboardLayout_GetDashboardLayout_rows[];
    setRows: React.Dispatch<React.SetStateAction<GetDashboardLayout_GetDashboardLayout_rows[]>>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setColsToRemove: (strarray: string[]) => void;
    colsToRemove: string[];
    widgets: {
        id: number;
        label: string;
        component: (amountOfWidgets: number) => JSX.Element;
    }[];
}

type AnyObject = { [key: string]: any };

// Create the context with default values
const RowsContext = createContext<RowsContextType | undefined>(undefined);

// Create the provider component
export const RowsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [rows, setRows] = useState<GetDashboardLayout_GetDashboardLayout_rows[]>([]);
    const [backUp, setBackup] = useState<GetDashboardLayout_GetDashboardLayout_rows[]>([]);
    const [rowsToRemove, setRowsToRemove] = useState<string[]>([]);
    const [colsToRemove, setColsToRemove] = useState<string[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    // Mutation to edit dashboard
    const [updateDashboard, { loading: updateLoad }] = useMutation<updateDashboardLayout, updateDashboardLayoutVariables>(UPDATE_DASHBOARD);
    // query to fetch dashboard
    const [run, { loading }] = useLazyQuery<GetDashboardLayout>(QUERY_DASHBOARD_LAYOUT, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.GetDashboardLayout) {
                setRows(sortRowsAndColumns(data.GetDashboardLayout.rows));
                setBackup(data.GetDashboardLayout.rows);
            }
        },
    });

    useEffect(() => {
        run();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function to sort rows and columns before setting the state
    const sortRowsAndColumns = (data: GetDashboardLayout_GetDashboardLayout_rows[]) => {
        // Create a deep copy of the data to avoid mutating the original
        const sortedRows = JSON.parse(JSON.stringify(data));

        sortedRows.forEach((row: any) => {
            // Sort the columns of each row by their name
            row.columns.sort((a: any, b: any) => a.name.localeCompare(b.name));
        });

        // Sort the rows by their order
        sortedRows.sort((a: any, b: any) => a.order - b.order);

        return sortedRows;
    };

    /**
     * Remove __typename field from layout input
     * @param obj all rows and columns
     * @returns returns input for mutation without __typename field
     */
    const removeTypename = (obj: any): any => {
        if (Array.isArray(obj)) {
            return obj.map(removeTypename);
        } else if (obj && typeof obj === "object") {
            return Object.keys(obj).reduce((newObj: AnyObject, key: string) => {
                if (key !== "__typename") {
                    newObj[key] = removeTypename(obj[key]);
                }
                return newObj;
            }, {});
        }
        return obj;
    };

    /**
     * @param id id can be "new4"
     * @returns 'new' as id
     */
    const transformId = (id: string) => {
        if (id.includes("new")) {
            return id.replace(/[0-9]/g, "");
        }
        return id;
    };

    const transformRows = (rows: GetDashboardLayout_GetDashboardLayout_rows[]): GetDashboardLayout_GetDashboardLayout_rows[] => {
        return rows.map((row) => ({
            ...row, // Spread operator to copy other properties
            id: transformId(row.id),
            columns: row.columns.map((column) => ({
                ...column, // Spread operator to copy other properties
                id: transformId(column.id),
            })),
        }));
    };

    /**
     * Restore to version before editing.
     */
    const cancelChanges = () => {
        setRows([]);
        setRows(backUp);
    };

    /**
     * Remove column, if row is empty remove row as well
     * @param rowId id of row
     * @param columnId id of column
     */
    const removeColumn = (rowId: string, columnId: string): void => {
        const updatedRows = rows.map((row) => {
            // Find the row with the given rowId
            if (row.id === rowId) {
                // Filter out the column with the given columnId
                const updatedColumns = row.columns.filter((column) => column.id !== columnId);
                // Check if there are no columns left in the row
                if (updatedColumns.length === 0) {
                    // Return null to indicate that this row should be removed
                    setRowsToRemove([...rowsToRemove, rowId]);

                    return null;
                }

                // Add the specific column ID to colsToRemove
                setColsToRemove((prevColsToRemove) => [...prevColsToRemove, columnId]);
                // Return a new row object with updated columns
                return { ...row, columns: updatedColumns };
            }
            return row;
        });

        // Remove null entries (rows to be removed) from the updated rows
        const filteredRows = updatedRows.filter((row): row is GetDashboardLayout_GetDashboardLayout_rows => row !== null);

        // Update the state or variable containing the rows array
        setRows(filteredRows);
    };

    /**
     * Update dashboard layout
     * Empty rows will be stored in rowsToRemove
     * rows will be removed from DB, widgets can be used in other rows
     */
    const update = async () => {
        const data = removeTypename(rows);
        // Change local 'newX' to 'new' => to make the mutation work properly
        const transformedRows = transformRows(data);

        try {
            await updateDashboard({
                variables: {
                    input: {
                        rows: transformedRows,
                    },
                    rowsToRemove: rowsToRemove,
                    columnsToRemove: colsToRemove,
                },
                onCompleted: (data) => {
                    toast.success("Wijzigingen doorgevoerd", { autoClose: 1500 });
                    setOpen(false);
                    run();
                },
            });
        } catch (e) {}
    };

    /**
     * Hardcoded widget array.
     */
    const widgets = [
        {
            id: 1,
            label: "Werklijst aanbestedingen",

            component: (amountOfWidgets: number) => <WorkListTable />,
        },
        {
            id: 2,
            label: "Updates aanbestedingen",
            component: (amountOfWidgets: number) => <TenderUpdateWidget />,
        },
        {
            id: 3,
            label: "Updates marktpartijen",

            component: (amountOfWidgets: number) => <OrganizationUpdateWidget />,
        },
        {
            id: 4,
            label: "Updates aanbestedende diensten",

            component: (amountOfWidgets: number) => <CaUpdateWidget />,
        },
        {
            id: 5,
            label: "Zoekregels aanbestedingen",
            component: (amountOfWidgets: number) => <ReviewGraph />,
        },
        {
            id: 6,
            label: "Aflopende contracten",
            component: (amountOfWidgets: number) => <ContractGraphOverview />,
        },
        {
            id: 7,
            label: "Aflopende contracten (half jaar)",
            component: (amountOfWidgets: number) => <TileContractContainer />,
        },
        {
            id: 8,
            label: "Mijn taken",
            component: (amountOfWidgets: number) => <MyTasksWidget />,
        },
        {
            id: 9,
            label: "Mijn projecten",
            component: (amountOfWidgets: number) => <MyProjectsWidget columnsInRow={amountOfWidgets} />,
        },
        {
            id: 10,
            label: "Mijn agenda items",
            component: (amountOfWidgets: number) => <MyCalendarWidget />,
        },
    ];

    /**
     * return dataprovider
     */
    return (
        <RowsContext.Provider
            value={{ rows, setRows, open, setOpen, widgets, update, updateLoad, removeColumn, cancelChanges, loading, colsToRemove, setColsToRemove }}
        >
            {children}
        </RowsContext.Provider>
    );
};

// Custom hook to use the RowsContext
export const useRowsContext = () => {
    const context = useContext(RowsContext);
    if (!context) {
        throw new Error("useRowsContext must be used within a RowsProvider");
    }
    return context;
};
