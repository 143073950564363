import React from "react";
import { Typography, IconButton, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

interface Props {
    // Value displayed in typography element
    selectedValue: string;
    typoVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button";
    // Option to remove item
    onRemove?: (value: string) => void;
    type: "opportunity" | "tender";
    mR?: string;
}

/**
 * Show a typography element with tooltip.
 * Tooltip: shown on hover -> shows icon to remove value
 * Typography: shows selected value
 */
const DistributionSoloOption: React.FC<Props> = ({ selectedValue, onRemove, typoVariant, type, mR = "4px" }) => {
    return (
        <React.Fragment>
            {onRemove ? (
                <Tooltip
                    id="canceltooltiptoshow"
                    placement="top-end"
                    componentsProps={{ tooltip: { sx: { backgroundColor: "transparent", marginBottom: "-18px !important", marginRight: "-24px" } } }}
                    title={
                        <>
                            <IconButton size="small" onClick={() => onRemove(selectedValue)}>
                                <CancelIcon style={{ fontSize: "1rem" }} />
                            </IconButton>
                        </>
                    }
                >
                    <Typography
                        variant={typoVariant ? typoVariant : "h2"}
                        sx={{
                            color: (theme) => (type === "tender" ? "#465c84" : type === "opportunity" ? "#508c79" : "#9e9e9e"),
                            fontWeight: 600,
                            display: "inline-flex",
                            cursor: "pointer",
                            marginRight: mR,
                        }}
                    >
                        {selectedValue}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography
                    variant={typoVariant ? typoVariant : "h2"}
                    sx={{
                        color: (theme) => (type === "tender" ? "#465c84" : type === "opportunity" ? "#508c79" : "#9e9e9e"),
                        fontWeight: 600,
                        display: "inline-flex",
                        cursor: "default",
                        marginRight: 0,
                    }}
                    style={{ cursor: "default", marginRight: 0 }}
                >
                    {selectedValue}
                </Typography>
            )}
        </React.Fragment>
    );
};

export default DistributionSoloOption;
