import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import ReactCountryFlag from "react-country-flag";
import { useQuery } from "@apollo/client";
import { getIsoCountryCode, getIsoCountryCodeVariables, getIsoCountryCode_getCountry } from "../../__generated__/getIsoCountryCode";
import { QUERY_GETISOCOUNTRYCODE } from "../../graphql/queryDefinitions";

interface Props {
    code: string | null;
    marginLeft?: number;
    alpha2?: string | null | undefined;
}

const CountryFlagBox: React.FC<Props> = ({ code, marginLeft, alpha2 }) => {
    const { t } = useTranslation();
    const [country, setCountry] = useState<getIsoCountryCode_getCountry | null>(null);
    const getFlagId = alpha2?.length === 1 ? `00${alpha2}` : alpha2?.length === 2 ? `0${alpha2}` : alpha2;
    useQuery<getIsoCountryCode, getIsoCountryCodeVariables>(QUERY_GETISOCOUNTRYCODE, {
        skip: code !== null && typeof alpha2 !== "string" ? true : false,
        variables: { id: getFlagId ? getFlagId : "" },
        onCompleted: (data) => {
            setCountry(data?.getCountry);
        },
    });

    return (
        <React.Fragment>
            {code ? (
                <Tooltip placement="right" title={t(`language.${code}`) as string}>
                    <div style={{ display: "inline", height: 23 }}>
                        <ReactCountryFlag
                            style={{
                                height: 11,
                                marginLeft: marginLeft !== undefined ? marginLeft : 4,
                            }}
                            countryCode={code}
                            svg
                        />
                    </div>
                </Tooltip>
            ) : country ? (
                <div style={{ display: "inline", height: 23 }}>
                    <ReactCountryFlag
                        style={{
                            height: 11,
                            marginLeft: marginLeft !== undefined ? marginLeft : 4,
                        }}
                        countryCode={country.alpha2}
                        svg
                    />
                </div>
            ) : null}
        </React.Fragment>
    );
};

export default CountryFlagBox;
