import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// GQL
import { useLazyQuery } from "@apollo/client";

// Components
import WidgetBox from "../../../components/WidgetBox";

// Material UI components/style
import { Box, List } from "@mui/material";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";

//Import momentjs
import moment from "moment";

//Import icon(s)

import FaviconBox from "../../../components/FaviconBox";
import { TenderersSearchInput, TenderersFilterSource, SortOrder } from "../../../__generated__/globalTypes";
import {
    GetFiveLatestMPActivities,
    GetFiveLatestMPActivitiesVariables,
    GetFiveLatestMPActivities_filterTenderers_results,
} from "../../../__generated__/GetFiveLatestMPActivities";
import UpdateWidgetError from "../updateWidgets/UpdateWidgetError";
import UpdateWidgetLoader from "../updateWidgets/UpdateWidgetLoader";
import Tooltip from "@mui/material/Tooltip";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import { People, Star } from "@mui/icons-material";
import { QUERY_FIVE_LATEST_MARKET_PARTY_ACTIVITIES } from "../../../graphql/queryDefOrg";

interface Props {
    a?: number;
}

const defaultSearch: TenderersSearchInput = { source: TenderersFilterSource.MY_TENDERERS };
const variables: GetFiveLatestMPActivitiesVariables = {
    page: 1,
    count: 5,
    orderField: "last_update_date_timestamp",
    order: SortOrder.DESC,
    search: defaultSearch,
};
const OrganizationUpdateView: React.FC<Props> = (props) => {
    const { t } = useTranslation();

    const pink = "#E03660";

    const [mpData, setMpData] = useState<GetFiveLatestMPActivities_filterTenderers_results | null>();

    const { trackEvent } = useMatomo();

    // Track event
    const goToFullList = () => {
        trackEvent({ category: "Dashboard", action: "go-to-organization-updateview" });
    };

    const goToDetailPage = () => {
        trackEvent({ category: "Dashboard", action: "go-to-organization-detailpage-from-updatewidget" });
    };

    // Define the GQL query
    const [run, { loading, error }] = useLazyQuery<GetFiveLatestMPActivities, GetFiveLatestMPActivitiesVariables>(
        QUERY_FIVE_LATEST_MARKET_PARTY_ACTIVITIES,
        {
            variables: variables,
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterTenderers) {
                    setMpData(data.filterTenderers.results);
                }
            },
        }
    );

    /**
     * Run the query with variables
     */
    useEffect(() => {
        run({
            variables: variables,
        });
    }, [run]);

    /**
     * If laoding -> show UpdateWidgetLoader with given data
     */
    if (loading) return <UpdateWidgetLoader color={pink} type={"mp"} />;

    /**
     * If error or empty state -> show UpdateWidgetError component with given data
     */
    // TODO: change !error to error, temporary set to !error to see error UI
    if (error || !mpData || mpData?.paginatorInfo.total === 0) return <UpdateWidgetError type={"mp"} errorText={t("competitors")} color={pink} />;

    return (
        <div id="update-organizations-widget">
            <WidgetBox
                header={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Star style={{ color: `${pink}`, marginRight: 4, height: 20 }} />
                            {t("dashboardPage.UpdatesOrganizations")}
                        </div>
                        <Typography>
                            <Link id="link-to-full-list" to="/organizations/starred" onClick={goToFullList}>
                                {" "}
                                {t("dashboardPage.ShowAll")}
                            </Link>
                        </Typography>
                    </div>
                }
                highlight="pink"
                padding={0}
            >
                <Box sx={{ width: "100%", height: { xs: "335px", md: "355px" }, position: "relative" }}>
                    {/* Underlaying icon with text  */}
                    <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}>
                        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            {<People style={{ color: "#f2f2f270", height: "9rem", width: "9rem" }} />}
                            <Typography variant="h5" style={{ color: "#f2f2f2" }}>
                                {t("competitors")}
                            </Typography>
                        </div>
                    </div>
                    <List sx={{ padding: 0, zIndex: 99 }}>
                        {mpData?.data.map((row) => {
                            return (
                                <React.Fragment key={row.id}>
                                    <ListItem
                                        id="organization-list-item"
                                        sx={{
                                            paddingRight: "64px",
                                            paddingLeft: "8px",
                                            backgroundColor: "#ffffff",
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                            }}
                                        >
                                            <FaviconBox name={row.name} favicon={row.favicon_resized ?? row.favicon} color={pink} />
                                        </ListItemIcon>
                                        <ListItemText
                                            sx={{
                                                "& .MuiListItemText-root": {
                                                    marginRight: "60px",
                                                },
                                            }}
                                            primary={
                                                <Typography noWrap>
                                                    <Link
                                                        id="organization-list-item-link"
                                                        style={{ display: "inline-bock" }}
                                                        to={`/organizations/${row.id}`}
                                                        onClick={goToDetailPage}
                                                    >
                                                        {row.name}
                                                    </Link>
                                                </Typography>
                                            }
                                            secondary={
                                                <Tooltip
                                                    placement="bottom"
                                                    componentsProps={{ tooltip: { sx: { marginTop: "0px" } } }}
                                                    enterNextDelay={100}
                                                    title={row.last_update_text as string}
                                                >
                                                    <Typography noWrap>{row.last_update_text}</Typography>
                                                </Tooltip>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            {row.last_update_date && (
                                                <React.Fragment>
                                                    <Typography align="right">{moment(row.last_update_date).format("D MMM")}</Typography>
                                                    <Typography align="right">
                                                        {moment(row.last_update_date).isSame(moment(), "year")
                                                            ? ""
                                                            : moment(row.last_update_date).format("YYYY")}
                                                    </Typography>
                                                </React.Fragment>
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Box>
            </WidgetBox>
        </div>
    );
};

export default OrganizationUpdateView;
