import React from "react";
import DragCard from "./DragCard";
import { Grid } from "@mui/material";
import { useRowsContext } from "./RowsContext";

interface Props {
    widgets: {
        id: number;
        label: string;
        component: (amountOfWidgets: number) => JSX.Element;
    }[];
}

const DrawerContent: React.FC<Props> = ({ widgets }) => {
    const { rows } = useRowsContext();

    const chosenWidgetIds = new Set(rows.flatMap((row) => row.columns.map((column) => column.widgetId)));

    return (
        <Grid container>
            {widgets.map((widget) => {
                return (
                    <Grid key={widget.id} item xs={12} sx={{ padding: "8px" }}>
                        <DragCard disabled={chosenWidgetIds.has(widget.id)} label={widget.label} widget={widget} />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default DrawerContent;
