import React from "react";

import {
    Avatar,
    Paper,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
    // Tooltip,
    //  IconButton,
    //   CircularProgress
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineItem as MuiTimelineItem,
} from "@mui/lab";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { withStyles } from "tss-react/mui";
import {
    GetUserLogsWithFilters_activities_data,
    GetUserLogsWithFilters_activities_data_subject_Search,
} from "../../../__generated__/GetUserLogsWithFilters";
// import LogModal from "./LogModal";

interface Props {
    item: any;
    log: GetUserLogsWithFilters_activities_data;
}

/**
 * This is used for the advanced search
 * timeline component adds this styling automatically,
 * Now its overruled so its aligned on the left side.
 */
const TimelineItem = withStyles(MuiTimelineItem, {
    missingOppositeContent: {
        "&:before": {
            display: "none",
        },
    },
    content: {
        flex: 20,
    },
    oppositeContent: {
        flex: 3,
    },
});

const StyledLink = styled(Link)`
    color: #000000;
    text-decoration: underline;
    &:hover {
        background-color: #0000001a;
    }
`;

const UserTimelineItem: React.FC<Props> = ({ log, item }) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const desc_without_whitespace = log?.description?.replace(/\s/g, "_");
    const remove_special_characters = desc_without_whitespace?.replace(/[^a-zA-Z0-9_]/g, "");

    const name = (log.subject as GetUserLogsWithFilters_activities_data_subject_Search).namesearch;

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            {isMobile ? (
                <Paper variant="outlined" sx={{ padding: "8px", marginTop: "8px", display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                        <Search />
                    </Avatar>
                    <div>
                        {/* TEXT FOR EVERY ITEM DEFINED */}
                        <Typography>
                            <span>
                                {log?.causer?.employee.givenname} {t("has")} {t("searchrule")}
                            </span>{" "}
                            {/*
                             * Link to page
                             * map item name -> searchrulename
                             */}
                            <StyledLink
                                to={`/profile/search-rules`}
                                onClick={() => {
                                    trackevents({ category: "Personal", action: "Click-go-to-detailpage-from-searchrule-log" });
                                }}
                            >
                                {name}
                            </StyledLink>{" "}
                            <span>{t(`log.${remove_special_characters as string}`)}</span>
                        </Typography>
                        {/* TIME */}
                        <Typography variant="caption" color="textSecondary">
                            {moment(log.created_at).format("LL")}, {moment(log.created_at).format("LT")}
                        </Typography>
                    </div>
                </Paper>
            ) : (
                <TimelineItem>
                    <TimelineOppositeContent sx={{ flex: 3 }}>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LL")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LT")}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                            <Search />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ flex: 20 }}>
                        <Paper elevation={1} sx={{ padding: "6px 16px", marginTop: "3px" }}>
                            <Typography>
                                <span>
                                    {log?.causer?.employee.givenname} {t("has")} {t("searchrule")}
                                </span>{" "}
                                {/*
                                 * Link to page
                                 * map item name -> searchrulename
                                 */}
                                <StyledLink
                                    to={`/profile/search-rules`}
                                    onClick={() => {
                                        trackevents({ category: "Personal", action: "Click-go-to-detailpage-from-searchrule-log" });
                                    }}
                                >
                                    {name}
                                </StyledLink>{" "}
                                <span>{t(`log.${remove_special_characters as string}`)}</span>
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            )}
        </>
    );
};

export default UserTimelineItem;
