import { gql } from "@apollo/client";

export const ENABLE_2FA = gql`
    mutation enableTwoFactor($password: String!) {
        enableTwoFactor(password: $password) {
            message
        }
    }
`;

export const VERIFY_2FA = gql`
    mutation confirmTwoFactor($code: Int!) {
        confirmTwoFactor(code: $code) {
            message
            recovery_codes
        }
    }
`;

export const DISABLE_2FA = gql`
    mutation disableTwoFactor($password: String!) {
        disableTwoFactor(password: $password)
    }
`;

export const VERIFY_RECOVERY_CODE = gql`
    mutation verifyRecoveryCode($recovery_code: String!) {
        verifyRecoveryCode(recovery_code: $recovery_code) {
            message
            success
            token
        }
    }
`;

export const ENABLE_MAIL_VERIFY = gql`
    mutation enableMailVerification($password: String!) {
        enableMailVerification(password: $password) {
            message
            success
        }
    }
`;

export const DISABLE_MAIL_VERIFY = gql`
    mutation disableMailVerification($password: String!) {
        disableMailVerification(password: $password) {
            message
            success
        }
    }
`;

export const VERIFY_LOGIN_BY_MAIL = gql`
    mutation verifyWithMail($email: String!, $password: String!, $code: String!) {
        verifyWithMail(input: { email: $email, password: $password }, mail_code: $code) {
            token
            two_factor_mail
            two_factor_totp
            message
            success
        }
    }
`;

export const GET_RECOVERY_CODES = gql`
    mutation GetRecoveryCodes($password: String!) {
        getRecoveryCode(password: $password) {
            message
            recovery_codes
        }
    }
`;

export const SAVE_DEFAULT_METHOD = gql`
    mutation setdefaultmethod($method: TwoFactorMethod) {
        setDefaultTwoFactorMethod(method: $method)
    }
`;

export const SEND_CODE_TO_MAIL = gql`
    mutation sendTwoFactorCode($email: String!, $password: String!) {
        sendTwoFactorCode(input: { email: $email, password: $password }) {
            message
            success
        }
    }
`;

export const GET_2FA_STATE = gql`
    query GetCu2FA {
        currentUser {
            id
            two_factor_totp_enabled
            two_factor_email_enabled
            two_factor_default_method
        }
    }
`;

export const GET_SESSIONS = gql`
    query personalTokens {
        currentUser {
            id
            tokens {
                id
                token
                last_used_at
            }
        }
    }
`;
