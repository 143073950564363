import React from "react";
import { TextField, Grid, TextFieldProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Disable } from "react-disable";
import { withStyles } from "tss-react/mui";

interface Props {
    loading: boolean;
    register: any;
    errors: any;
}

//Textfield styling
export const TextFieldProfile = withStyles((props: TextFieldProps) => <TextField {...props} variant={"outlined"} fullWidth size={"small"} />, {
    root: {
        marginBottom: "12px",
        display: "block",
        width: "50%",
    },
});

const UserInfoForm: React.FC<Props> = ({ register, errors, loading }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Typography
                variant="h3"
                sx={{
                    fontWeight: 500,
                    marginBottom: "12px",
                }}
            >
                Contactgegevens voor de demo
            </Typography>
            <Disable disabled={loading}>
                <Grid container spacing={4} justifyContent="space-between">
                    {/* Grid item for all the formfields on the left side. */}
                    <Grid item xs={12} sm={12} md>
                        {/* Textfield givenname, this is a required field */}
                        <TextFieldProfile
                            inputRef={register({ required: true })}
                            name={"givenname"}
                            label={`${t("personal.firstname")}*`}
                            error={Boolean(errors.givenname)}
                        />
                        {/* Textfield for familyname, this is a required field */}
                        <TextFieldProfile
                            inputRef={register({ required: true })}
                            name={"familyname"}
                            label={`${t("personal.lastname")}*`}
                            error={Boolean(errors.familyname)}
                        />

                        {/* Field for email, required field  */}
                        <TextFieldProfile
                            inputRef={register({ required: true })}
                            name={"email"}
                            label={`${t("personal.email")}*`}
                            error={Boolean(errors.email)}
                        />

                        {/* Textfield for cellphone */}
                        <TextFieldProfile inputRef={register} name={"cellphone"} label={`${t("personal.cellphone")}`} />
                    </Grid>
                </Grid>
            </Disable>
        </div>
    );
};

export default UserInfoForm;
