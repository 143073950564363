import React from "react";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import {
    GetContractInfoForCurrentTender_tender_contract,
    GetContractInfoForCurrentTender_tender_next_looptijd_einde,
    GetContractInfoForCurrentTender_tender_looptijd_einde_incl_opties,
    GetContractInfoForCurrentTender_tender_status_contract,
} from "../../__generated__/GetContractInfoForCurrentTender";
import LotDateField from "../LotDateField";
import LotStringField from "../LotStringField";

interface Props {
    contract: GetContractInfoForCurrentTender_tender_contract | null;
    next_looptijd_einde: (GetContractInfoForCurrentTender_tender_next_looptijd_einde | null)[] | null;
    setCopyText(text: string): void;
    status_contract: (GetContractInfoForCurrentTender_tender_status_contract | null)[] | null;
    looptijd_einde_incl_opties: (GetContractInfoForCurrentTender_tender_looptijd_einde_incl_opties | null)[] | null;
}

const ContractEnriched: React.FC<Props> = ({ contract, next_looptijd_einde, setCopyText, looptijd_einde_incl_opties, status_contract }) => {
    const { t } = useTranslation();

    /**
     * dompurify imported for sanitizing html
     */
    const description = contract?.description ? dompurify.sanitize(contract.description, { FORCE_BODY: true }) : null;

    /**
     * text to use in copy function when indefinite period
     */
    const undetermined = "".concat(
        t("tenderPage.InitialDuration") +
            ": " +
            t("tenderPage.From") +
            " " +
            moment(contract?.period?.startDate).format("L") +
            " " +
            t("tenderPage.indefinitePeriod")
    );

    /**
     * contract period text with start and enddate
     */
    const contractText =
        contract?.period?.value !== null && contract?.period?.startDate !== null && contract?.period?.endDate !== null
            ? "".concat(
                  t("tenderPage.InitialDuration") +
                      ": " +
                      ` ${contract?.period?.startDate ? moment(contract?.period?.startDate).format("L") + " " + t("until") + " " : ""}` +
                      `${contract?.period?.endDate ? moment(contract?.period?.endDate).format("L") : ""}` +
                      " (" +
                      contract?.period?.value?.toString() +
                      " " +
                      `${
                          contract?.period?.value === 1
                              ? t(`singleContractPeriod.${contract?.period?.type?.toLocaleLowerCase() || ""}`)
                              : t(`multiContractPeriod.${contract?.period?.type?.toLocaleLowerCase() || ""}`)
                      }` +
                      ")"
              )
            : "";

    /**
     * options text of contract
     */
    const optionText =
        contract?.options !== undefined && contract?.options.length > 0
            ? "".concat(
                  t("tenderPage.Options") +
                      ": " +
                      `${
                          contract?.options[0].indefinite
                              ? t("tenderPage.indefiniteNumberOfOptionsFrom") +
                                " " +
                                contract?.options[0].length +
                                " " +
                                `${
                                    contract?.options[0].length === 1
                                        ? t(`singleContractPeriod.${contract?.options[0].period?.toLocaleLowerCase() || ""}`)
                                        : t(`multiContractPeriod.${contract?.options[0].period?.toLocaleLowerCase() || ""}`)
                                }`
                              : contract?.options[0].amount +
                                " x " +
                                contract?.options[0].length +
                                " " +
                                `${
                                    contract?.options[0].length === 1
                                        ? t(`singleContractPeriod.${contract?.options[0].period?.toLocaleLowerCase() || ""}`)
                                        : t(`multiContractPeriod.${contract?.options[0].period?.toLocaleLowerCase() || ""}`)
                                }`
                      }` +
                      " "
              )
            : "";

    /**
     * copy status of contract
     * TODO: if not available dont copy
     */
    const copyStatus = status_contract
        ? "".concat(t("Contract_state") + ": " + status_contract.map((nxt) => `${nxt?.value} (${nxt?.lots.map((lot) => lot)})`))
        : "";

    /**
     * Copy first end date of contract.
     */
    const firstEndDateCopy =
        next_looptijd_einde !== null
            ? "".concat(
                  t("tenderPage.FirstEnddate") +
                      ": " +
                      next_looptijd_einde.map((nxt) => `${moment(nxt?.value).format("L")} (${nxt?.lots.map((lot) => lot)})`)
              )
            : "";

    /**
     * copy first end date with options
     */
    const firstEndWithOptionsCopy =
        looptijd_einde_incl_opties !== null
            ? "".concat(
                  t("tenderPage.First_enddate_with_options") +
                      ": " +
                      looptijd_einde_incl_opties.map((nxt) => `${moment(nxt?.value).format("L")} (${nxt?.lots.map((lot) => lot)})`)
              )
            : "";

    /**
     * copy description of contract
     */
    const descriptionCopy = description !== null && "".concat(t("tenderPage.Description") + ": " + description);

    /**
     * Get all items and combine in one string to make it possible to copy on clipboard
     */
    setCopyText(
        contract?.undeterminedPeriod
            ? undetermined + "\n" + optionText + "\n" + copyStatus + "\n" + firstEndDateCopy + "\n" + firstEndWithOptionsCopy + "\n" + descriptionCopy
            : contractText + "\n" + optionText + "\n" + copyStatus + "\n" + firstEndDateCopy + "\n" + firstEndWithOptionsCopy + "\n" + descriptionCopy
    );

    return (
        <React.Fragment>
            <Grid container style={{ padding: "8px 16px" }}>
                <Grid item xs={12} md={7} style={{ marginBottom: 4 }}>
                    <table>
                        <tbody>
                            {/* status contract */}
                            {status_contract && status_contract?.filter((val) => val?.value !== null).length !== 0 && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("Contract_state")}:</td>
                                    <td>
                                        <Typography>
                                            <LotStringField data={status_contract} />
                                        </Typography>
                                    </td>
                                </tr>
                            )}
                            {/* 
                            - Check if undeterminedPeriod is true
                            - Check if period os not 'null'
                            - if atleast one is true, show duration   
                            */}
                            {contract?.undeterminedPeriod === true ||
                                (contract?.period !== null && (
                                    <tr>
                                        <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                            {t("tenderPage.InitialDuration")}:
                                        </td>
                                        <td>
                                            <>
                                                <Typography>
                                                    {contract?.undeterminedPeriod ? (
                                                        <>
                                                            {t("tenderPage.From")} {moment(contract?.period?.startDate).format("L")}{" "}
                                                            {t("tenderPage.indefinitePeriod")}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {/*
                                                             * Check if period has a startdate -> show startDate
                                                             */}
                                                            {contract?.period?.startDate && moment(contract?.period?.startDate).format("L")}{" "}
                                                            {/*
                                                             * Check if period has a enddate -> show enddate
                                                             */}
                                                            {contract?.period?.endDate
                                                                ? `${t("until")} ${moment(contract?.period?.endDate).format("L")}`
                                                                : ""}
                                                            {/*
                                                             * check if period has a value and check if start and end date are available -> show value
                                                             */}
                                                            {contract?.period?.value !== null &&
                                                                contract?.period?.startDate &&
                                                                contract?.period?.endDate && (
                                                                    <>
                                                                        {" "}
                                                                        ({contract?.period?.value}{" "}
                                                                        {contract?.period?.value === 1
                                                                            ? t(
                                                                                  `singleContractPeriod.${
                                                                                      contract?.period?.type?.toLocaleLowerCase() || ""
                                                                                  }`
                                                                              )
                                                                            : t(
                                                                                  `multiContractPeriod.${
                                                                                      contract?.period?.type?.toLocaleLowerCase() || ""
                                                                                  }`
                                                                              )}
                                                                        )
                                                                    </>
                                                                )}
                                                            {/* If startdate is null and enddate is null show value  */}
                                                            {contract?.period?.startDate === null && contract?.period?.endDate === null && (
                                                                <>
                                                                    {" "}
                                                                    {contract?.period?.value}{" "}
                                                                    {contract?.period?.value === 1
                                                                        ? t(
                                                                              `singleContractPeriod.${
                                                                                  contract?.period?.type?.toLocaleLowerCase() || ""
                                                                              }`
                                                                          )
                                                                        : t(
                                                                              `multiContractPeriod.${
                                                                                  contract?.period?.type?.toLocaleLowerCase() || ""
                                                                              }`
                                                                          )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </Typography>
                                            </>
                                        </td>
                                    </tr>
                                ))}
                            {/* Enriched contract period */}
                            {contract?.options !== undefined && contract?.options.length > 0 && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("tenderPage.Options")}:</td>
                                    <td>
                                        {contract.options.map((option) => (
                                            <Typography key={option.id}>
                                                {option.indefinite ? (
                                                    <>
                                                        {t("tenderPage.indefiniteNumberOfOptionsFrom")} {option.length}{" "}
                                                        {option.length === 1
                                                            ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                            : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                                    </>
                                                ) : (
                                                    <>
                                                        {option.amount} x {option.length}{" "}
                                                        {option.length === 1
                                                            ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                            : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                                    </>
                                                )}
                                            </Typography>
                                        ))}
                                    </td>
                                </tr>
                            )}

                            {/* next looptijdeinde */}

                            {next_looptijd_einde !== null && next_looptijd_einde?.filter((val) => val?.value !== null).length !== 0 && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                        {t("tenderPage.FirstEnddate")}:
                                    </td>
                                    <td>
                                        <Typography>
                                            <LotDateField data={next_looptijd_einde} />
                                        </Typography>
                                    </td>
                                </tr>
                            )}
                            {/* First enddate with options status */}

                            {looptijd_einde_incl_opties !== null && looptijd_einde_incl_opties?.filter((val) => val?.value !== null).length !== 0 && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                        {t("tenderPage.First_enddate_with_options")}:
                                    </td>
                                    <td>
                                        <Typography>
                                            <LotDateField data={looptijd_einde_incl_opties} />
                                        </Typography>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Grid>
                <Grid item xs={12} md={5}>
                    {/* enriched data */}
                    {description && (
                        <table>
                            <tr>
                                <Typography>{t("tenderPage.Description")}:</Typography>
                            </tr>
                            <tr>
                                <Typography
                                    sx={{ marginTop: "4px", maxHeight: "120px", overflow: "auto" }}
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            </tr>
                        </table>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ContractEnriched;
