import React from "react";
import { GET_PUBLISHED_ADRES_COLUMN } from "../../graphql/queryDefForColumns";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";

import { GetPublishedAddressColumn, GetPublishedAddressColumnVariables } from "../../__generated__/GetPublishedAddressColumn";

interface Props {
    tender_id: string;
}

const PublishedAddressColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetPublishedAddressColumn, GetPublishedAddressColumnVariables>(GET_PUBLISHED_ADRES_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    return <Typography style={{ whiteSpace: "nowrap" }}>{tender.published_adres}</Typography>;
};

export default PublishedAddressColumn;
