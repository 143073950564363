import React, { useRef, useEffect, useState } from "react";
import { EndingQuarterContracts_getUserContractsInQuarter } from "../../../__generated__/EndingQuarterContracts";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import "chart.js/auto";

interface Props {
    quarterArray: (EndingQuarterContracts_getUserContractsInQuarter | null)[];
    loading: boolean;
    chosenQuarter: string;
}

const GroupedBar: React.FC<Props> = ({ quarterArray, loading, chosenQuarter }) => {
    const chartRef = useRef<any>();
    const [chartData, setChartData] = useState<(EndingQuarterContracts_getUserContractsInQuarter | null)[]>();
    const chart = chartRef.current;
    const navigate = useNavigate();

    const { trackEvent } = useMatomo();

    // Track event
    const trackClickBar = () => {
        trackEvent({ category: "Dashboard", action: "clicked-on-barchart" });
    };

    useEffect(() => {
        // get quarterArray and filter out all 0 values
        const results = quarterArray.filter((element) => {
            // 👇️ using OR (||) operator
            return element?.liked !== 0 || element?.total !== 0;
        });
        setChartData(results);
    }, [chart, quarterArray]);

    if (loading) {
        return <FullPageSpinner />;
    }

    const barChartData = {
        labels: chartData?.map((q, i) => {
            // get last item
            if (i === chartData?.length - 1 && q?.search === null) {
                return "Geen zoekregel";
            }
            if (q?.liked === 0 && q.total === 0) {
                return null;
            }
            return q?.search?.name;
        }),

        datasets: [
            {
                label: "Aflopende contracten",
                data: chartData?.map((i) => {
                    return i?.total;
                }),
                backgroundColor: chartData?.map((i, index) => {
                    // set color for last item in array (no searchrule prop)
                    if (i?.search === null) {
                        return "#3d5a80";
                    }
                    return i?.search?.color;
                }),
                stack: "Stack 0",
                categoryPercentage: 0.7,
                barPercentage: 1.0,
            },
            {
                label: "Interessant",
                data: chartData?.map((i) => {
                    return i?.liked;
                }),
                backgroundColor: chartData?.map((i, index) => {
                    // set color for last item in array (no searchrule prop)
                    if (i?.search === null) {
                        return "#3d5a80";
                    }
                    return `${i?.search?.color}40` as string;
                }),
                stack: "Stack 1",
                categoryPercentage: 0.7,
                barPercentage: 1.0,
            },
        ],
    };

    // Click handler to set chosen quarter as url param
    const handleClickEvent = (e: any) => {
        const element = getElementAtEvent(chartRef.current, e);

        if (element.length > 0 && barChartData !== undefined && barChartData.labels !== undefined) {
            const clickedIndex = element[0].index;
            const interesting = element[0].datasetIndex;

            /**
             * Get id of clicked searchrule bar
             */
            const clickedSearchRuleId = chartData !== undefined && chartData[clickedIndex]?.search?.id;

            /**
             * Remove rtf key to make sure data will be set to the new parameters
             */
            sessionStorage.removeItem("rtf");
            /**
             * First check if a searchrule is undefined => means user clicked on bar with "no searchrule"
             * - set reviewfilter to 'interessant' and enddate_contract_quarters filter to chosen quarter
             */
            if (clickedSearchRuleId === undefined) {
                sessionStorage.setItem("rtf", JSON.stringify({ reviews: [1], enddate_contract_quarters: [chosenQuarter] }));
            } else if (interesting === 1) {
                /**
                 * If searchId is not undefined and dataset index === 1 => means user clicked on searchrule 'interessant' bar
                 * => set reviewfilter to 'interessant', searchesfilter to id of searchrule and enddate_contract_quarters filter to chosen quarter
                 */
                sessionStorage.setItem(
                    "rtf",
                    JSON.stringify({ reviews: [1], searches: [parseInt(clickedSearchRuleId as string)], enddate_contract_quarters: [chosenQuarter] })
                );
            } else {
                /**
                 * If both checks are not true => means user clicked on all results of a specific searchrule
                 * => set searchesfilter to id of searchrule and enddate_contract_quarters filter to chosen quarter
                 */
                sessionStorage.setItem(
                    "rtf",
                    JSON.stringify({ searches: [parseInt(clickedSearchRuleId as string)], enddate_contract_quarters: [chosenQuarter] })
                );
            }
            trackClickBar();
            /**
             * Navigate to reviewpage
             * => in this view the filterset based from above will be active
             */
            navigate(`/tenders/reviews`);
        }
    };

    return (
        <>
            <Bar
                onClick={handleClickEvent}
                ref={chartRef}
                data={barChartData}
                options={{
                    onHover(event, elements, chart) {
                        const test = event.native?.target as any;
                        test.style.cursor = elements[0] ? "pointer" : "default";
                    },
                    // hover: {
                    //     mode: "dataset",
                    //     intersect: false,
                    // },
                    layout: {
                        padding: {
                            right: 15,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        xAxis: {
                            beginAtZero: true,
                            display: true,
                            ticks: {
                                // display: false,

                                precision: 0,
                                callback: function (value, index, values) {
                                    if (chartData !== undefined && chartData[index]?.search !== null) {
                                        return "local_offer";
                                    }
                                    return null;
                                },
                                // Function to fill color of tick with color of bar
                                color: (c) => {
                                    if (chartData !== undefined && chartData[c.index]?.search !== null) {
                                        return chartData[c.index]?.search?.color as any;
                                    }
                                    return "#3d5a80";
                                },

                                font: {
                                    family: "Material Icons",
                                    size: 20,
                                },
                            },

                            grid: {
                                lineWidth: 0,
                            },
                        },
                        yAxis: {
                            beginAtZero: true,
                            display: true,
                            ticks: {
                                precision: 0,
                                callback: function (value, index, values) {
                                    return value;
                                },
                            },
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                        tooltip: {
                            enabled: true,
                            filter: function (e, index, array, item) {
                                // if formattedValue = 0 -> dont show tooltip
                                if (e.formattedValue === "0") {
                                    return false;
                                } else {
                                    return true;
                                }
                            },
                        },
                        legend: {
                            display: false,
                            position: "top",
                        },
                        title: {
                            display: false,
                            text: "not visible",
                        },
                    },
                }}
            />
        </>
    );
};

export default GroupedBar;
