import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useContext } from "react";
import { PROJECT_STATES, SINGLE_PROJECT } from "../../../graphql/queryDefProjects";
import { projectStates } from "../../../__generated__/projectStates";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_NEW_PROJECT_STATE, UPDATE_PROJECT_STATE } from "../../../graphql/mutationDefProjects";
import { updateStateOfProject, updateStateOfProjectVariables } from "../../../__generated__/updateStateOfProject";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import { createState, createStateVariables } from "../../../__generated__/createState";
import { useTranslation } from "react-i18next";

interface Props {
    width: string;
    create?: boolean;
}

const ProjectFaseSelector: React.FC<Props> = ({ width = "182px", create = false }) => {
    const { t } = useTranslation();
    const { project, changeProject } = useContext(ProjectToOpenContext);

    // fetch project states
    const { data, refetch } = useQuery<projectStates>(PROJECT_STATES, {
        onCompleted: (data) => {
            if (data === null || data.projectStates === null || data.projectStates.length === 0) {
                createNewColumn({
                    variables: { name: "New", order: 1 },
                });
                refetch();
            }

            if (data && data.projectStates && project.state === "") {
                changeProject({ state: data.projectStates[0].id });
            }
        },
    });

    const handleChange = (event: SelectChangeEvent) => {
        changeProject({ state: event.target.value });
        if (create === false) {
            updateProjectState({ variables: { id: project.projectID, state: event.target.value } });
        }
    };

    const [createNewColumn] = useMutation<createState, createStateVariables>(CREATE_NEW_PROJECT_STATE, {
        refetchQueries: [PROJECT_STATES, "projectStates"],
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            changeProject({ state: data.createProjectState?.id });
        },
    });

    /**
     * Update project title
     */
    const [updateProjectState] = useMutation<updateStateOfProject, updateStateOfProjectVariables>(UPDATE_PROJECT_STATE, {
        refetchQueries: [SINGLE_PROJECT, "single_project", PROJECT_STATES, "projectStates"],
        awaitRefetchQueries: true,
    });

    if (create) {
        return (
            <Select
                sx={{ width: width, ml: "8px" }}
                size="small"
                labelId="project-fase-selector"
                id="project-fase-selector"
                value={project.state.toString()}
                disableUnderline
                onChange={handleChange}
            >
                {data?.projectStates?.map((state) => {
                    return (
                        <MenuItem key={state.id} value={state.id}>
                            {state.name === "New" ? t("New") : state.name}
                        </MenuItem>
                    );
                })}
            </Select>
        );
    }

    return (
        <Select
            sx={{ width: width, ml: "8px" }}
            size="small"
            labelId="project-fase-selector"
            id="project-fase-selector"
            value={project.state.toString()}
            disableUnderline
            onChange={handleChange}
        >
            {data?.projectStates?.map((state) => {
                return (
                    <MenuItem key={state.id} value={state.id}>
                        {state.name === "New" ? t("New") : state.name}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export default ProjectFaseSelector;
