import React from "react";
import Typography from "@mui/material/Typography";

interface Props {
    url?: string;
}

const Div404: React.FC<Props> = (props) => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", height: "100%" }}>
            <Typography variant="h1">Niet gevonden!</Typography>
            <Typography style={{ margin: "16px 0" }}>Er is een fout opgetreden</Typography>
        </div>
    );
};

export default Div404;
