import React from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

//MUI icons
import { DefaultViewPreferenceInput, GridInput, GridViewInput } from "../../../__generated__/globalTypes";
import { VerticalSplit, List, Autorenew } from "@mui/icons-material";
import update from "immutability-helper";

interface Props {
    defaultView: DefaultViewPreferenceInput[];
    setDefaultView: React.Dispatch<React.SetStateAction<DefaultViewPreferenceInput[]>>;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ViewItem {
    id: number;
    view: GridViewInput;
    grid: GridInput;
    viewLabel: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const WorklistViews: ViewItem[] = [
    {
        id: 0,
        viewLabel: "Lijstweergave",
        icon: List,
        grid: GridInput.TENDER_WORKLIST,
        view: GridViewInput.LIST,
    },
    // {
    //     id: 1,
    //     view: "Kolomweergave",
    //     icon: ViewColumnIcon,
    // },
    {
        id: 3,
        viewLabel: "Splitweergave",
        icon: VerticalSplit,
        view: GridViewInput.SPLIT,
        grid: GridInput.TENDER_WORKLIST,
    },
    // {
    //     id: 4,
    //     view: "Updateweergave",
    //     icon: AutorenewIcon,
    // },
    // {
    //     id: 5,
    //     view: "Kaartweergave",
    //     icon: MapIcon,
    // },
];

const ReviewViews: ViewItem[] = [
    {
        id: 0,
        viewLabel: "Lijstweergave",
        view: GridViewInput.LIST,
        grid: GridInput.TENDER_REVIEWS,

        icon: List,
    },
    // {
    //     id: 1,
    //     view: "Kolomweergave",
    //     icon: ViewColumnIcon,
    // },
    {
        id: 2,
        viewLabel: "Updateweergave",
        icon: Autorenew,
        view: GridViewInput.UPDATE,
        grid: GridInput.TENDER_REVIEWS,
    },
    // {
    //     id: 5,
    //     view: "Kaartweergave",
    //     icon: MapIcon,
    // },
];

const TenderViewpreferences: React.FC<Props> = ({ defaultView, setDefaultView, setChanged }) => {
    const { t } = useTranslation();
    return (
        <table style={{ width: "100%" }}>
            {/*
             * reviewed tenders view options
             */}
            <tr>
                <td style={{ paddingLeft: "16px", width: "30vw" }}>
                    <Typography>{t("Reviews")}</Typography>
                </td>
                <td>
                    <div style={{ display: "flex", flexDirection: "row", paddingLeft: 20 }}>
                        {/* Review view options */}
                        {ReviewViews.map(({ id, view, icon: Icon, viewLabel, grid }, i) => {
                            // for each option create a card with name and icon of view
                            return (
                                <Card
                                    sx={{ marginRight: "16px", width: "140px" }}
                                    variant={"outlined"}
                                    key={id}
                                    onClick={() => {
                                        setChanged(true);
                                        setDefaultView(
                                            update(defaultView, {
                                                // 1: TENDER_REVIEWS (based on localstate array - settings.tsx)
                                                1: {
                                                    view: { $set: view },
                                                },
                                            })
                                        );
                                    }}
                                    style={{
                                        border: defaultView[1].view === view ? "1px solid #B8B8B8" : undefined,
                                        backgroundColor: defaultView[1].view === view ? "#F5F5F5" : undefined,
                                    }}
                                >
                                    <CardActionArea style={{ height: "100%" }}>
                                        <CardContent
                                            sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {defaultView[1].view === view ? (
                                                    <div
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                            borderRadius: "48px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: "#e3e3e3",
                                                        }}
                                                    >
                                                        <Icon sx={{ color: "#707070" }} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                            borderRadius: "48px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Icon sx={{ color: "#707070" }} />
                                                    </div>
                                                )}
                                            </div>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                sx={{
                                                    color: defaultView[1].view === view ? "#000000" : undefined,
                                                }}
                                            >
                                                {viewLabel}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            );
                        })}
                    </div>
                </td>
            </tr>

            {/*
             * Worklist tenders view options
             */}
            <tr>
                <td style={{ paddingLeft: "16px", width: "30vw" }}>
                    <Typography>{t("Worklist")}</Typography>
                </td>
                <td>
                    <div style={{ display: "flex", flexDirection: "row", paddingLeft: 20 }}>
                        {/* Worklist view options */}
                        {WorklistViews.map(({ id, view, icon: Icon, viewLabel }) => {
                            // for each option create a card with name and icon of view
                            return (
                                <Card
                                    sx={{ marginRight: "16px", width: "140px" }}
                                    variant={"outlined"}
                                    key={id}
                                    onClick={() => {
                                        setChanged(true);
                                        setDefaultView(
                                            update(defaultView, {
                                                // 0: TENDER_WORKLIST (based on localstate array - settings.tsx)
                                                0: {
                                                    view: { $set: view },
                                                },
                                            })
                                        );
                                    }}
                                    style={{
                                        border: defaultView[0].view === view ? "1px solid #B8B8B8" : undefined,
                                        backgroundColor: defaultView[0].view === view ? "#F5F5F5" : undefined,
                                    }}
                                >
                                    <CardActionArea style={{ height: "100%" }}>
                                        <CardContent
                                            sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {defaultView[0].view === view ? (
                                                    <div
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                            borderRadius: "48px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: "#e3e3e3",
                                                        }}
                                                    >
                                                        <Icon sx={{ color: "#707070" }} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                            borderRadius: "48px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Icon sx={{ color: "#707070" }} />
                                                    </div>
                                                )}
                                            </div>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                sx={{
                                                    color: defaultView[0].view === view ? "#000000" : undefined,
                                                }}
                                            >
                                                {viewLabel}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            );
                        })}
                    </div>
                </td>
            </tr>
        </table>
    );
};

export default TenderViewpreferences;
