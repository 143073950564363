import React from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { QUERY_SINGLE_OPPORTUNITY_PERSONAL_RESULTS } from "../../graphql/opportunityQueries/queryDefinitions";
import { GetSingleOppPersonalResults, GetSingleOppPersonalResultsVariables } from "../../__generated__/GetSingleOppPersonalResults";

interface Props {
    opp_file_id: string;
}

const OppSearchruleCount: React.FC<Props> = ({ opp_file_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetSingleOppPersonalResults, GetSingleOppPersonalResultsVariables>(QUERY_SINGLE_OPPORTUNITY_PERSONAL_RESULTS, {
        variables: { id: opp_file_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.opportunityFile) {
        return <Typography>{""}</Typography>;
    }

    const oppCount = data.opportunityFile.personalResults?.length || 0;

    return <Typography>{oppCount}</Typography>;
};

export default OppSearchruleCount;
