import React from "react";
import { Typography } from "@mui/material";

interface Props {
    step: {
        id: number;
        title: string;
    };
    InteractiveHelper: React.ReactNode;
}

const OpportunityTitleToShow: React.FC<Props> = ({ step, InteractiveHelper }) => {
    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
            {/* Title of active step */}
            <Typography variant="h6" sx={{ marginLeft: (theme) => theme.spacing(2), flex: 1 }}>
                {`Kansen - ${step.title}`}
            </Typography>

            {/* Space to add a JSX element
             *  => in this case used to show the interactive helper to start a guide based on current view
             */}
            <React.Fragment>{InteractiveHelper}</React.Fragment>
        </div>
    );
};

export default OpportunityTitleToShow;
