import React from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton, Typography } from "@mui/material";
import { Today } from "@mui/icons-material";
import TooltipOnNewLines from "../TooltipOnNewLines";
import { gql, useQuery } from "@apollo/client";

import { useNavigate } from "react-router-dom";
import { GetChangedTenderPlanning, GetChangedTenderPlanningVariables } from "../../__generated__/GetChangedTenderPlanning";
import FaviconBox from "../FaviconBox";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(e: string): void;
}

const ChangedEventNotification: React.FC<Props> = ({ notification, onClick }) => {
    const grey = "#707070";
    const yellow = "#F57117";

    const navigate = useNavigate();

    const { data, loading } = useQuery<GetChangedTenderPlanning, GetChangedTenderPlanningVariables>(GET_PLANNING_TENDER_CHANGE, {
        skip: notification === null || notification.data === null,
        variables: {
            id: notification.data ? (notification.data[0] as string) : "",
        },
        fetchPolicy: "network-only",
    });

    /**
     * Spinner on loading state
     */
    if (loading) {
        return (
            <MenuItem
                style={{
                    whiteSpace: "normal",
                    maxHeight: 250,
                    opacity: notification.read_at !== null ? 0.5 : undefined,
                }}
            >
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    return (
        <MenuItem
            onClick={() => {
                if (notification.data) {
                    onClick(notification.data[0] as string);
                    if (data && data.tender) {
                        navigate(`/tenders/${data.tender.id}`);
                    }
                }
            }}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: grey }}>
                    <Today fontSize="small" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <TooltipOnNewLines
                        showTooltip={false}
                        placement={"bottom-start"}
                        text={`Gewijzigde planning: ${data?.tender?.namegiventocontract}`}
                        variant={"body1"}
                        lines={2}
                        id={`T${notification.id as string}first`}
                        fontweight={notification.read_at === null ? 500 : "normal"}
                        fontSize={14}
                    />
                }
                secondary={
                    // <TooltipOnNewLines
                    //     showTooltip={false}
                    //     placement={"top-start"}
                    //     text={data?.tender?.namegiventocontract}
                    //     variant={"body1"}
                    //     lines={1}
                    //     id={`T${notification.id as string}second`}
                    //     fontweight={"normal"}
                    //     fontSize={12}
                    // />
                    <Typography
                        style={{
                            fontSize: 12,
                            // When the nofitication is unread, the date-part of the notification is bold and accent colored
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <FaviconBox
                            favicon={data?.tender?.clientA[0].favicon || null}
                            name={data?.tender?.clientA[0].name || data?.tender?.published_opdrachtgever}
                            color={yellow}
                        />{" "}
                        <TooltipOnNewLines
                            placement={"bottom-start"}
                            text={data?.tender?.clientA[0].name || data?.tender?.published_opdrachtgever || ""}
                            variant={"body1"}
                            lines={1}
                            id={`T${notification.id as string}second`}
                            fontweight={notification.read_at === null ? 500 : "normal"}
                            fontSize={12}
                        />
                    </Typography>
                }
            />
        </MenuItem>
    );
};
export default ChangedEventNotification;

//? get name and favicon for single ca
export const GET_PLANNING_TENDER_CHANGE = gql`
    query GetChangedTenderPlanning($id: ID!) {
        tender(id: $id) {
            id
            namegiventocontract
            published_opdrachtgever
            clientA {
                id
                name
                favicon
            }
        }
    }
`;
