import React from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip, Typography, IconButton } from "@mui/material";
import { useFaq } from "./contextProviders/FaqOpenerContext";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../models/MatomoEvent";

interface Props {
    faq_id?: string;
    tooltipText?: React.ReactNode;
}

const InfoButtonTooltip: React.FC<Props> = ({ faq_id, tooltipText }) => {
    const { t } = useTranslation();
    const { setOpenFaq, setView } = useFaq();
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        /**
         * Tooltip to explain the target options
         */
        <React.Fragment>
            {tooltipText ? (
                <Tooltip
                    placement="right"
                    enterNextDelay={100}
                    title={
                        <>
                            <Typography variant="caption" style={{ display: "inline" }}>
                                {tooltipText}
                            </Typography>
                            {/*
                             * Read more textbutton only visible if faq_id is set to an article_id
                             */}
                            {faq_id !== undefined && (
                                <Typography
                                    sx={{
                                        marginLeft: "4px",
                                        display: "inline",
                                        cursor: "pointer",
                                        fontStyle: "italic",
                                        color: "#ffffff",
                                        "&:hover": {
                                            textDecoration: "underline",
                                        },
                                    }}
                                    variant="caption"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (faq_id !== undefined) {
                                            setOpenFaq(true);
                                            setView(faq_id);
                                        }
                                        return null;
                                    }}
                                >
                                    {t("legal_basis_tooltip.ReadMore")}
                                </Typography>
                            )}
                        </>
                    }
                >
                    {/*
                     * (i) button to show tooltip/ open faq item
                     * onClick only available if faq_id is set to an article_id
                     */}
                    <IconButton
                        sx={{ width: "0.7em", height: "0.7em", margin: "0 8px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (faq_id !== undefined) {
                                setOpenFaq(true);
                                setView(faq_id);
                            }
                            trackevents({ category: "Help", action: "Clicked-info-tooltip", href: window.location.href });
                            return null;
                        }}
                    >
                        <InfoIcon sx={{ width: "0.7em", height: "0.7em" }} />
                    </IconButton>
                </Tooltip>
            ) : (
                <IconButton
                    sx={{ width: "0.7em", height: "0.7em", margin: "0 8px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (faq_id !== undefined) {
                            setOpenFaq(true);
                            setView(faq_id);
                        }
                        return null;
                    }}
                >
                    <InfoIcon sx={{ width: "0.7em", height: "0.7em" }} />
                </IconButton>
            )}
        </React.Fragment>
    );
};

export default InfoButtonTooltip;
