/**
 * @param email input in emailfield
 * @param domain list of domains that are valid
 * @returns boolean if typed mail is allowed to use
 */
const emailDomainCheck = (email: string, domain: string[]) => {
    const parts = email.split("@");
    if (parts.length === 2) {
        if (domain.find((single) => single === parts[1])) {
            return true;
        }
    }
    return false;
};
export default emailDomainCheck;
