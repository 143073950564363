import React, { useEffect } from "react";
import { Tooltip, IconButton, Badge } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import { useTranslation } from "react-i18next";
import { useTenderChat } from "../../components/contextProviders/TenderChatContext";
import LikeCABtn from "../../components/LikeCABtn";
import { FetchResult, InternalRefetchQueriesInclude, gql, useQuery } from "@apollo/client";
import { GetCaCount, GetCaCountVariables } from "../../__generated__/GetCaCount";

interface Props {
    ca_name: string;
    ca_id: string;
    refetchQueries?: InternalRefetchQueriesInclude | ((result: FetchResult) => InternalRefetchQueriesInclude) | undefined;
}

const SmallCaMenu: React.FC<Props> = ({ ca_id, ca_name, refetchQueries }) => {
    const { t } = useTranslation();
    const { setOpenModal, setChatTarget, messageSent, openModal } = useTenderChat();

    /**
     * Get message data
     */
    const { data, refetch } = useQuery<GetCaCount, GetCaCountVariables>(GET_CA_COUNT, {
        variables: { id: ca_id },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        messageSent === true && openModal === false && refetch();
    }, [refetch, ca_id, messageSent, openModal]);

    return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <LikeCABtn org_id={ca_id} buttonSize="small" color={"#F57117"} refetchQueries={refetchQueries} />

            {/*
             * Button to open chat modal.
             * Available in all tabs
             * */}
            <Tooltip enterNextDelay={100} title={t("dashboardPage.Chat") as string} placement="top">
                <Badge
                    sx={{
                        ".MuiBadge-standard": {
                            maxWidth: "16px",
                            minWidth: "16px",
                            minHeight: "16px",
                            maxHeight: "16px",
                            fontSize: "0.6rem",
                            marginRight: "7px",
                            marginTop: "7px",
                        },
                    }}
                    badgeContent={data?.contracting_authority?.unreadChatsCount}
                    color="primary"
                    max={999}
                >
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            // Prevent the tab iself being clicked,
                            // we want to execute the click on the close button
                            e.stopPropagation();
                            setChatTarget(ca_id, "App\\Models\\ContractingAuthorityV4", ca_name || "", "");
                            setOpenModal(true);
                        }}
                    >
                        <MessageIcon fontSize="small" sx={{ color: data?.contracting_authority?.hasConversations === true ? "grey" : "lightgrey" }} />
                    </IconButton>
                </Badge>
            </Tooltip>
        </div>
    );
};

export default SmallCaMenu;

//? get last message and boolean
export const GET_CA_COUNT = gql`
    query GetCaCount($id: ID!) {
        contracting_authority(id: $id) {
            id
            name
            lastPrivateNote
            unreadChatsCount
            hasConversations
        }
    }
`;
