import React, { useEffect, useState } from "react";
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Paper, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { DesktopWindowsOutlined, TrendingUp, Business, Today, Description, Group, ExpandLess, ExpandMore, AccountTree } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface Props {
    a?: number;
    activePage: string | undefined;
    checked: boolean;
}

export interface MenuItem {
    label: string;
    alias: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    link: string;
    category: "Dashboard" | "Calendar" | "Tender" | "Opportunity" | "Ca" | "Organizations" | "Help" | "Personal" | "Project";
    action: string;
    sub: MenuItem[];
}

const CollapsableNavBar: React.FC<Props> = ({ activePage, checked }) => {
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const handleClick = (alias: string) => {
        setOpen((prevOpen) => ({
            [alias]: !prevOpen[alias],
        }));
    };

    const menuItems: MenuItem[] = [
        {
            label: t("dashboard"),
            alias: "dashboard",
            icon: DesktopWindowsOutlined,
            link: "/dashboard",
            category: "Dashboard",
            action: "Go-to-dashboard",
            sub: [],
        },
        {
            label: t("projectmanagement"),
            alias: "projects",
            icon: AccountTree,
            link: "/projects",
            category: "Project",
            action: "Go-to-projects",
            sub: [],
        },
        { label: t("agenda"), alias: "calendar", icon: Today, link: "/calendar", category: "Calendar", action: "Go-to-calendar", sub: [] },
        {
            label: t("competitors"),
            alias: "organizations",
            icon: Group,
            link: "/organizations/starred",
            category: "Organizations",
            action: "Go-to-organizations-starred",
            sub: [
                {
                    label: t("All"),
                    alias: "organizations",
                    icon: Group,
                    link: "/organizations",
                    category: "Organizations",
                    action: "Go-to-organizations",
                    sub: [],
                },
                {
                    label: t("StarMarked"),
                    alias: "organizations",
                    icon: Group,
                    link: "/organizations/starred",
                    category: "Organizations",
                    action: "Go-to-organizations-starred",
                    sub: [],
                },
            ],
        },
        {
            label: t("tenders"),
            alias: "tenders",
            icon: Description,
            link: "/tenders/worklist",
            category: "Tender",
            action: "Go-to-tenders-worklist",
            sub: [
                {
                    label: t("All"),
                    alias: "tenders",
                    icon: Description,
                    link: "/tenders",
                    category: "Tender",
                    action: "Go-to-tenders",
                    sub: [],
                },
                {
                    label: t("Reviews"),
                    alias: "tenders",
                    icon: Description,
                    link: "/tenders/reviews",
                    category: "Tender",
                    action: "Go-to-tenders-reviews",
                    sub: [],
                },
                {
                    label: t("Worklist"),
                    alias: "tenders",
                    icon: Description,
                    link: "/tenders/worklist",
                    category: "Tender",
                    action: "Go-to-tenders-worklist",
                    sub: [],
                },
            ],
        },
        {
            label: t("opportunities"),
            alias: "opportunities",
            icon: TrendingUp,
            link: "/opportunities/worklist",
            category: "Opportunity",
            action: "Go-to-opportunities-worklist",
            sub: [
                {
                    label: t("All"),
                    alias: "opportunities",
                    icon: TrendingUp,
                    link: "/opportunities",
                    category: "Opportunity",
                    action: "Go-to-opportunities",
                    sub: [],
                },
                {
                    label: t("Reviews"),
                    alias: "opportunities",
                    icon: TrendingUp,
                    link: "/opportunities/reviews",
                    category: "Opportunity",
                    action: "Go-to-opportunities-reviews",
                    sub: [],
                },
                {
                    label: t("Worklist"),
                    alias: "opportunities",
                    icon: TrendingUp,
                    link: "/opportunities/worklist",
                    category: "Opportunity",
                    action: "Go-to-opportunities-worklist",
                    sub: [],
                },
            ],
        },
        {
            label: t("contractingAuthorities"),
            alias: "contracting-authorities",
            icon: Business,
            link: "/contracting-authorities/starred",
            category: "Ca",
            action: "Go-to-contracting-authorities-starred",
            sub: [
                {
                    label: t("All"),
                    alias: "contracting-authorities",
                    icon: Business,
                    link: "/contracting-authorities",
                    category: "Ca",
                    action: "Go-to-contracting-authorities",
                    sub: [],
                },
                {
                    label: t("StarMarked"),
                    alias: "contracting-authorities",
                    icon: Business,
                    link: "/contracting-authorities/starred",
                    category: "Ca",
                    action: "Go-to-contracting-authorities-starred",
                    sub: [],
                },
            ],
        },

        // {
        //     label: t("Chat"),
        //     alias: "chat",
        //     icon: ForumIcon,
        // },
    ];

    useEffect(() => {
        if (!checked) {
            setOpen({});
        }
    }, [checked]);

    const renderMenuItems = (items: MenuItem[], inset: boolean) => {
        return items.map((item) => (
            <React.Fragment key={item.alias}>
                <ListItem
                    sx={{
                        padding: 0,
                        width: "100%",
                    }}
                    onClick={() => {
                        if (item.sub.length) {
                            handleClick(item.alias);
                        } else {
                            navigate(item.link);
                        }
                    }}
                >
                    <div
                        style={{
                            height: inset ? "32px" : "48px",
                            width: "100%",
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: inset ? "center" : "flex-start",
                        }}
                    >
                        {!inset && (
                            <ListItemIcon
                                sx={{
                                    backgroundColor: item.alias === activePage && !inset ? `#ffffff2b` : undefined,
                                    minWidth: 48,
                                    borderRadius: "16px",
                                    height: "48px",
                                    color: "#fff",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: "8px",
                                    "&:hover": {
                                        backgroundColor: !inset ? `#ffffff1a` : undefined,
                                    },
                                }}
                                onClick={() => {
                                    navigate(item.link);
                                }}
                            >
                                {React.createElement(item.icon)}
                            </ListItemIcon>
                        )}
                        {/* {checked && ( */}
                        <ListItemText
                            primary={
                                <Typography
                                    sx={{
                                        "&:hover": {
                                            fontWeight: 600,
                                        },
                                    }}
                                    noWrap={!inset}
                                    variant="h6"
                                    fontWeight={item.link === location.pathname && inset ? 900 : item.alias === activePage && inset ? 400 : 500}
                                    color={"#fff"}
                                >
                                    {item.label}
                                </Typography>
                            }
                        />
                        {/* )} */}
                    </div>
                    {item.sub.length ? (
                        open[item.alias] && checked ? (
                            <ExpandLess sx={{ color: "#fff", marginRight: "8px" }} />
                        ) : (
                            <ExpandMore sx={{ color: "#fff", marginRight: "8px" }} />
                        )
                    ) : null}
                </ListItem>
                {item.sub.length > 0 && (
                    <Collapse in={open[item.alias]} timeout={500} unmountOnExit>
                        <Paper sx={{ ml: 7, backgroundColor: "#ffffff2b", borderRadius: "16px 0px 0px 16px" }} elevation={0}>
                            <List component="div" disablePadding sx={{ padding: "8px 0px 8px 8px", width: "100%" }}>
                                {renderMenuItems(item.sub, true)}
                            </List>
                        </Paper>
                    </Collapse>
                )}
            </React.Fragment>
        ));
    };

    return (
        <List sx={{ width: "100%", padding: "0px", height: "100%" }} component="nav" aria-labelledby="nested-list-subheader">
            {renderMenuItems(menuItems, false)}
        </List>
    );
};

export default CollapsableNavBar;
