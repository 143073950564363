import React from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
// TODO: add color and icons to state

interface Props {
    size?: number;
    type: string | null;
    placement:
        | "bottom"
        | "left"
        | "right"
        | "top"
        | "bottom-end"
        | "bottom-start"
        | "left-end"
        | "left-start"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | undefined;
}

const StatusDot: React.FC<Props> = ({ type, placement, size = 14 }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {(() => {
                /**
                 * Switch contains all states a tender could have, for each state a different component.
                 */
                switch (type) {
                    case "PRELIMINARY":
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.Preliminary")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "green" }} />
                            </Tooltip>
                        );

                    case "0":
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.Preliminary")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "green" }} />
                            </Tooltip>
                        );

                    case "OPEN":
                        // green
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.Open")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "green" }} />
                            </Tooltip>
                        );

                    case "1":
                        // green
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.Open")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "green" }} />
                            </Tooltip>
                        );

                    case "EXPIRED":
                        // yellow
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.Expired")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "#d2d211" }} />
                            </Tooltip>
                        );

                    case "2":
                        // yellow, "Uitslag niet bekend"
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.Expired")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "#d2d211" }} />
                            </Tooltip>
                        );

                    case "PARTICIPANTS_KNOWN":
                        // Orange
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.ParticipantsKnown")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "orange" }} />
                            </Tooltip>
                        );

                    case "4":
                        // Orange
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.ParticipantsKnown")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "orange" }} />
                            </Tooltip>
                        );

                    case "PRELIMINARY_AWARDED":
                        // outlined red
                        return (
                            <Tooltip title={t<string>("Status.PreliminaryAwarded")} placement={placement} arrow>
                                <RadioButtonUncheckedIcon sx={{ fontSize: "16px", color: "red" }} />
                            </Tooltip>
                        );

                    case "8":
                        // outlined red
                        return (
                            <Tooltip title={t<string>("Status.PreliminaryAwarded")} placement={placement} arrow>
                                <RadioButtonUncheckedIcon sx={{ fontSize: "16px", color: "red" }} style={{ marginTop: 5 }} />
                            </Tooltip>
                        );

                    case "AWARDED":
                        // red
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.Awarded")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "red" }} />
                            </Tooltip>
                        );

                    case "16":
                        // red
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.Awarded")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "red" }} />
                            </Tooltip>
                        );

                    case "PROCUREMENT_DISCONTINUED":
                        // red crossed
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.ProcurementDiscontinued")} placement={placement} arrow>
                                <NotInterestedIcon sx={{ fontSize: "16px", color: "red" }} />
                            </Tooltip>
                        );

                    case "32":
                        // red crossed
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.ProcurementDiscontinued")} placement={placement} arrow>
                                <NotInterestedIcon sx={{ fontSize: "16px", color: "red" }} style={{ marginTop: 5 }} />
                            </Tooltip>
                        );

                    case "PROCUREMENT_UNSUCCESSFUL":
                        // red
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.ProcurementUnsuccesful")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "red" }} />
                            </Tooltip>
                        );

                    case "48":
                        // red
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.ProcurementUnsuccesful")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "red" }} />
                            </Tooltip>
                        );

                    case "PRELIMINARY_EXPIRED":
                        // red
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.PreliminaryExpired")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "red" }} />
                            </Tooltip>
                        );

                    case "64":
                        // red
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.PreliminaryExpired")} placement={placement} arrow>
                                <div style={{ height: size, width: size, borderRadius: 8, backgroundColor: "red" }} />
                            </Tooltip>
                        );

                    default:
                        return null;
                }
            })()}
        </React.Fragment>
    );
};

export default StatusDot;
