import { createContext, useContext } from "react";

export type TenderWorklistType = {
    page: number;
    setPage: (page: number) => void;
};

export const WorklistContext = createContext<TenderWorklistType>({
    page: 0,
    setPage: (page) => console.warn("no theme provider"),
});
export const useWorklistPage = () => useContext(WorklistContext);
