import React from "react";
import { useTranslation } from "react-i18next";
import StatusDot from "../../../components/StatusDot";

//Import momentjs
import moment from "moment";
import InfoTooltip from "../../../components/InfoTooltip";
import { Typography } from "@mui/material";
import { getRelativeTimeInDays } from "../../../utils/relativeTimeInDays";

interface Props {
    deadline: string | null;
    state: string;
    start?: string[];
    end?: string;
    firstPossibleEnd?: string;
}

const DeadlineTooltip: React.FC<Props> = ({ deadline, state, start, end, firstPossibleEnd }) => {
    const { t } = useTranslation();

    const visibleDL = deadline !== null ? moment(deadline).format("L LT") : "";
    // const relativetime = deadline !== null ? moment(deadline).format() : ""; // 2022-12-16T15:36:54+01:00
    const removeEmptyStart = start !== undefined && start.length > 0 ? start?.filter((item: any) => item !== "") : [];

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", alignItems: "center", marginRight: 8, cursor: "pointer" }}>
                <StatusDot type={state} placement="left" />
            </div>
            <InfoTooltip
                content={
                    <React.Fragment>
                        <div style={{ marginBottom: 4 }}>
                            <Typography>{t("dashboardPage.Deadline")}</Typography>
                            <Typography style={{ marginLeft: 4 }}>{visibleDL}</Typography>
                        </div>
                        {firstPossibleEnd !== "" && (
                            <div style={{ marginBottom: 4 }}>
                                <Typography>{t("dashboardPage.Announcement")}</Typography>
                                <Typography style={{ marginLeft: 4 }}>{firstPossibleEnd}</Typography>
                            </div>
                        )}
                        {removeEmptyStart && removeEmptyStart.length > 0 && (
                            <div style={{ marginBottom: 4 }}>
                                <Typography>{t("dashboardPage.StartDuration")}</Typography>
                                <Typography style={{ marginLeft: 4 }}>
                                    {removeEmptyStart.map((i) => {
                                        return (
                                            <span key={i}>
                                                {i} <br />
                                            </span>
                                        );
                                    })}
                                </Typography>
                            </div>
                        )}
                    </React.Fragment>
                }
                placement="top-start"
            >
                <div style={{ cursor: "pointer" }}>
                    <Typography noWrap>{visibleDL}</Typography>
                    <Typography noWrap>
                        {deadline && getRelativeTimeInDays(deadline, t).show && <>{getRelativeTimeInDays(deadline, t).date}</>}
                    </Typography>
                </div>
            </InfoTooltip>
        </div>
    );
};

export default DeadlineTooltip;
