import { useEffect, useState } from "react";

const useScrollPosition = (ref: React.RefObject<HTMLElement>, threshold: number) => {
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                setScrollPosition(ref.current.scrollTop);
            }
        };

        const element = ref.current;
        if (element) {
            element.addEventListener("scroll", handleScroll);
            return () => {
                element.removeEventListener("scroll", handleScroll);
            };
        } else return;

        // Handle the case when ref.current is null
        // In this case, we don't need to do anything as `scrollPosition > threshold` will handle it properly.
    }, [ref]);

    return scrollPosition > threshold;
};

export default useScrollPosition;
