import React from "react";
import { Route, Routes } from "react-router-dom";
import PollDetailPage from "./PollDetailPage";
import { useUserId } from "../../../components/contextProviders/UserIdContext";

interface Props {
    a?: number;
}

const PollContainer: React.FC<Props> = (props) => {
    const { userId } = useUserId();

    return (
        <React.Fragment>
            <Routes>
                <Route path="" element={<PollDetailPage />} />
                {userId === "10291" && <Route path="/admin" element={<PollDetailPage admin />} />}
            </Routes>
        </React.Fragment>
    );
};

export default PollContainer;
