import React from "react";
import { Backdrop, Box, Button, CircularProgress, Grid } from "@mui/material";
import SettlingCard from "./SettlingCard";

interface Props {
    data: settlingDataProps[];
    paginatorInfo: any;
    onChangePagination(page: number, rowsPerPage: number): void;
    loading: boolean;
    type: "mp" | "ca";
}

export interface settlingDataProps {
    id: string;
    name: string;
    type: string;
    country: string;
    statustext: string | null;
    status: number | null;
    place: string;
    adres: string;
    wonBids: number;
}

const CardViewSettlings: React.FC<Props> = ({ data, paginatorInfo, onChangePagination, loading, type }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            {loading && (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Grid container>
                {data.map((row) => {
                    return (
                        <Grid key={row.id} xs={12} md={3}>
                            <SettlingCard data={row} type={type} />
                        </Grid>
                    );
                })}
            </Grid>
            <div>
                <Button
                    sx={{ justifySelf: "center" }}
                    disabled={!paginatorInfo.hasMorePages}
                    onClick={() => onChangePagination(paginatorInfo.currentPage, paginatorInfo.perPage + 10)}
                >
                    Laad meer
                </Button>
            </div>
        </Box>
    );
};

export default CardViewSettlings;
