import React, { useEffect } from "react";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ProfileLayout from "../ProfileLayout";
import { useTranslation } from "react-i18next";
import setTitle from "../../../utils/setTitle";
import Logbook from "./Logbook";

interface Props {
    a?: number;
}

export interface LogItem {
    id: string;
    activity?: string;
    user: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    date: string;
    category: string;
    sentence: string;
    tender?: string;
    from?: string;
    action?: string;
    opportunity?: string;
    userAgent?: string;
    emailSubject?: string;
    emailURL?: string;
}

const LogbookLayout: React.FC<Props> = (props) => {
    const { t } = useTranslation();

    //set tab title
    useEffect(() => {
        setTitle(t("Logbook"));
    });

    return (
        <ProfileLayout title={<h3>{t("Logbook")}</h3>}>
            <Logbook />
        </ProfileLayout>
    );
};

export default LogbookLayout;
