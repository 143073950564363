import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Accordion as MuiAccordion,
    AccordionDetails,
    Divider,
    AccordionSummary,
    Button,
    Typography,
    Tooltip,
    Theme,
    createStyles,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import CountryFlagBox from "../boxes/CountryFlagBox";
import StatusChip from "../StatusChip";
import { GetStarredRelatedOrgs_currentUser_market_parties } from "../../__generated__/GetStarredRelatedOrgs";
import FaviconBox from "../FaviconBox";
import {
    GetRelatedTendersForCurrentTender_tender_related_tenders,
    GetRelatedTendersForCurrentTender_tender_related_tenders_bids,
    GetRelatedTendersForCurrentTender_tender_related_tenders_lots,
} from "../../__generated__/GetRelatedTendersForCurrentTender";
import { withStyles } from "tss-react/mui";
import currencyFormatDE from "../../utils/currencyFormatter";
import { Add } from "@mui/icons-material";
import { OptieEinde, findLotValue } from "../../utils/contracttimeline";
import HorizontalContractTimeline from "../tenderDetail/widgets/contract_timeline/HorizontalContractTimeline";
import { isAllDataInputsNullExceptStartDate } from "../../utils/checkHorizontalTimeLineDates";
import { currencyFormat } from "../../utils/currencyFormat";

interface Props {
    bids: GetRelatedTendersForCurrentTender_tender_related_tenders_bids[];
    lots: GetRelatedTendersForCurrentTender_tender_related_tenders_lots[];
    starredOrgs: GetStarredRelatedOrgs_currentUser_market_parties[];
    opties: OptieEinde[];
    tender: GetRelatedTendersForCurrentTender_tender_related_tenders;
}

const Accordion = withStyles(MuiAccordion, (theme: Theme) =>
    createStyles({
        root: {
            border: "none",
            boxShadow: "none",
            "&:before": {
                display: "none",
            },
            "&$expanded": {
                margin: "auto",
            },
        },
        expanded: {
            margin: "0 !important",
        },
    })
);

// TODO
// if bids < 3 -> expanded is true for all items.
// if contractors > 5, show 5 with load more button.
// state for number of items to show

const ExtendedDetails: React.FC<Props> = ({ bids, lots, starredOrgs, opties, tender: tndr }) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const { t } = useTranslation();
    const starColor = "#9e9e9e";
    const pink = "#E03660";

    // expand accordion based on lot ID
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const ItemsPerClick = bids.length;
    const [noOfItems, setnoOfItems] = useState(5);

    const [noOfBids, setnoOfBids] = useState(5);

    // state to show items based on state of items to show
    const bts = bids.slice(0, noOfBids);

    const morebts = () => {
        setnoOfBids(ItemsPerClick);
    };

    // state to show items based on state of items to show
    // const itemsToShow = bids.slice(0, noOfItems);

    /**
     * Handler to show more items onclick,
     * get value of noOfItems and add value of ItemsPerClick.
     */
    const handleShowMoreItems = () => {
        setnoOfItems(ItemsPerClick);
    };

    /**
     * Handler to show 5 items,
     */
    const handleShowLess = () => {
        setnoOfItems(5);
    };

    /**
     * key = property of array that has to be unique
     */
    const key = "combination";

    /**
     * map array with last items that have duplicate values,
     * opposite of JS find() function
     */
    const arrayUniqueByKey = [...new Map(bids.map((item) => [item[key], item])).values()];

    /**
     * get first item that is a duplicate
     */
    const uniqByKeepFirst = (a: any, key: any) => {
        const seen = new Set();
        return a.filter((item: any) => {
            const k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    };

    return (
        <React.Fragment>
            {/* map over lots, for each lot an accordion item */}
            {lots.map((lot, index) => {
                // check if bid has lot id, if not -> dont expand and dont show arrow icon
                const item = bids.find((u: any) => u.lot?.id === lot.id);
                const start =
                    tndr.looptijd_start && tndr.looptijd_start.length > 1
                        ? tndr.looptijd_start[index]?.value
                        : tndr.looptijd_start
                        ? tndr.looptijd_start[0]?.value
                        : null;
                const end =
                    tndr.looptijd_einde && tndr.looptijd_einde.length > 1
                        ? tndr.looptijd_einde[index]?.value
                        : tndr.looptijd_einde
                        ? tndr.looptijd_einde[0]?.value
                        : null;

                if (lots.length === 1 && lot.number === "0") {
                    return (
                        <div key={lot.id}>
                            <div style={{ width: "100%" }}>
                                {/* *************************************************************************************************************
                                 **************************************************** TIMELINE **************************************************
                                 ************************************************************************************************************* */}
                                {!isAllDataInputsNullExceptStartDate({
                                    contractEnd: null,
                                    looptijd_einde: lot.looptijd_einde,
                                    next_looptijd_einde: lot.next_looptijd_einde,
                                }) && (
                                    <HorizontalContractTimeline
                                        options={findLotValue(opties, parseInt(lot.number)) ?? []}
                                        start={lot.looptijd_start || start || tndr.datum_gunning || null}
                                        end={lot.looptijd_einde || end || null}
                                        datum_gunning_as_start={Boolean(lot.looptijd_start || start) === null}
                                        noEndDateAvailable={Boolean(lot.looptijd_einde === null)}
                                        undetermined={false}
                                    />
                                )}
                            </div>

                            {/* list with mapped contractors */}
                            <List
                                sx={{
                                    "& .MuiList-root": {
                                        width: "100%",
                                        paddingTop: "16px",
                                    },
                                }}
                            >
                                {/* map all contractors with logo, winner and more info */}
                                {bts.map((bid, i) => {
                                    const filteredNames = bid.marketParty.names.filter((n) => n.type === "trade_name");
                                    return (
                                        <React.Fragment key={bid.id}>
                                            {/* <ListItem style={{ padding: "8px 16px" }}> */}
                                            <ListItem
                                                sx={{
                                                    padding: bid.combination === null ? "8px 16px" : "0px 16px",
                                                }} // get first item of combination and add 8px padding top
                                                style={{
                                                    paddingTop:
                                                        bid.combination !== null &&
                                                        uniqByKeepFirst(bids, (it: any) => it.combination).find(
                                                            (t: any) => t.id === bid.id && t.combination !== null
                                                        ) &&
                                                        8,

                                                    // last item of combination -> paddingBottom 8
                                                    paddingBottom: arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && 8,
                                                }}
                                            >
                                                {/* tenderer logo */}
                                                <div style={{ marginRight: 8 }}>
                                                    <FaviconBox
                                                        favicon={bid.marketParty.favicon_resized ?? bid.marketParty.favicon}
                                                        name={bid.marketParty.name}
                                                        color={pink}
                                                        marginTop={0}
                                                    />
                                                </div>

                                                {/* listitemtext with amount and percentage */}
                                                <ListItemText
                                                    primary={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Typography
                                                                noWrap
                                                                sx={{
                                                                    display: "inline",
                                                                    maxWidth: (theme) =>
                                                                        theme.breakpoints.up("xl")
                                                                            ? "88%"
                                                                            : theme.breakpoints.up("xxl")
                                                                            ? "94%"
                                                                            : undefined,
                                                                }}
                                                            >
                                                                {filteredNames.length > 0 ? (
                                                                    <Tooltip
                                                                        title={filteredNames
                                                                            .sort((a, b) => a.value.localeCompare(b.value))
                                                                            .map((item, i) => {
                                                                                return (
                                                                                    <React.Fragment key={i}>
                                                                                        {i !== filteredNames.length - 1 ? (
                                                                                            <Typography variant="caption">{item.value} | </Typography>
                                                                                        ) : (
                                                                                            <Typography variant="caption">{item.value}</Typography>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                );
                                                                            })}
                                                                    >
                                                                        <Link to={`/organizations/${bid.marketParty.id}`}>
                                                                            {bid.marketParty.name}{" "}
                                                                        </Link>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Link to={`/organizations/${bid.marketParty.id}`}>{bid.marketParty.name} </Link>
                                                                )}
                                                            </Typography>
                                                            <CountryFlagBox
                                                                code={bid.marketParty.country?.alpha2 ? bid.marketParty.country.alpha2 : null}
                                                            />
                                                            {/* TODO: conditon, render only if starred */}
                                                            {starredOrgs.find((s) => s.id === bid.marketParty.id) && (
                                                                <StarIcon style={{ color: `${starColor}`, marginLeft: 4, fontSize: "1rem" }} />
                                                            )}
                                                            {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null)
                                                                ? null
                                                                : bid.combination !== null && (
                                                                      <Add style={{ height: "0.7em", width: "0.7em", marginLeft: 16 }} />
                                                                  )}
                                                        </div>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) &&
                                                            bid.bid ? (
                                                                <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                                    {currencyFormat(bid.totalValue?.currency)} {currencyFormatDE(bid.bid)}
                                                                </Typography>
                                                            ) : (
                                                                bid.bid &&
                                                                bid.combination === null && (
                                                                    <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                                        {currencyFormat(bid.totalValue?.currency)} {currencyFormatDE(bid.bid)}
                                                                    </Typography>
                                                                )
                                                            )}
                                                            {/* Percentage */}
                                                            {bid.percentage && (
                                                                <Typography style={{ display: "inline-block" }}>+{bid.percentage}%</Typography>
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                />

                                                {/* Chip that shows who won the bid */}
                                                <ListItemSecondaryAction style={{ height: "100%", paddingTop: 12 }}>
                                                    {bid.winner && bid.combination === null ? (
                                                        <div style={{ marginLeft: 4 }}>
                                                            <StatusChip colored={false} type="winner" />
                                                        </div>
                                                    ) : (
                                                        bid.winner &&
                                                        uniqByKeepFirst(bids, (it: any) => it.combination).find(
                                                            (t: any) => t.id === bid.id && t.combination !== null
                                                        ) && (
                                                            <div style={{ marginLeft: 4 }}>
                                                                <StatusChip colored={false} type="winner" />
                                                            </div>
                                                        )
                                                    )}
                                                </ListItemSecondaryAction>
                                            </ListItem>

                                            {i !== bts.length - 1 && bid.combination === null && <Divider />}
                                            {/* Check if item is last one AND item is not a combination, last one -> show divider */}

                                            {/* Set divider to last index of a combination */}
                                            {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && <Divider />}
                                        </React.Fragment>
                                    );
                                })}
                            </List>
                            {/*
                             * Button to load more contractors
                             */}
                            {bids.length > 5 && (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {bts.length !== bids.length && (
                                        <Button size="small" variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={morebts}>
                                            {t("tenderPage.showAll")}
                                        </Button>
                                    )}

                                    {bts.length > 5 && (
                                        <Button size="small" variant="text" color="primary" style={{ marginBottom: 8 }} onClick={morebts}>
                                            {t("tenderPage.ShowLess")}
                                        </Button>
                                    )}
                                </div>
                            )}
                            <div />
                        </div>
                    );
                }

                return (
                    <React.Fragment key={lot.id}>
                        {/* TODO: is this the way we want it ? 
                                - Accordion expands if lot has bids 
                        */}
                        <Accordion expanded={item !== undefined && expanded === item?.lot?.id} onChange={handleChange(lot.id)}>
                            {/* oneliner with some information that user sees on first sight */}
                            <AccordionSummary
                                expandIcon={item !== undefined ? <ExpandMoreIcon /> : <StatusChip colored={false} type="noWinner" />}
                                sx={{
                                    "&:hover": {
                                        background: "#f4f4f4",
                                    },
                                }}
                            >
                                <Typography sx={{ flexBasis: "80%", flexShrink: 0 }}>
                                    {t("tenderPage.Lot")} {lot.number} - {lot.title}
                                </Typography>
                            </AccordionSummary>

                            {/* *************************************************************************************************************
                             **************************************************** TIMELINE **************************************************
                             ************************************************************************************************************* */}
                            {!isAllDataInputsNullExceptStartDate({
                                contractEnd: null,
                                looptijd_einde: lot.looptijd_einde,
                                next_looptijd_einde: lot.next_looptijd_einde,
                            }) && (
                                <HorizontalContractTimeline
                                    options={findLotValue(opties, parseInt(lot.number)) ?? []}
                                    start={lot.looptijd_start || start || tndr.datum_gunning || null}
                                    end={lot.looptijd_einde || end || null}
                                    datum_gunning_as_start={Boolean(lot.looptijd_start || start) === null}
                                    noEndDateAvailable={Boolean(lot.looptijd_einde === null)}
                                    undetermined={false}
                                />
                            )}

                            {/* Detailcomponent with more information about a lot */}
                            {bids.find((bid) => bid.lot?.id === lot?.id) && (
                                <AccordionDetails sx={{ "& .MuiAccordionDetails-root": { paddingTop: 0, justifyContent: "center" } }}>
                                    <List
                                        sx={{
                                            "& .MuiList-root": {
                                                width: "100%",
                                                padding: 0,
                                            },
                                        }}
                                    >
                                        <ListItem style={{ paddingLeft: 0 }}>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                        <table>
                                                            <tbody>
                                                                {/* bids in lot */}
                                                                {lot.participants_count && (
                                                                    <tr>
                                                                        <td
                                                                            style={{
                                                                                paddingRight: "20px",
                                                                                whiteSpace: "nowrap",
                                                                                verticalAlign: "top",
                                                                            }}
                                                                        >
                                                                            <Typography>{t("tenderPage.NumberOfTenderers")}</Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography> {lot.participants_count}</Typography>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider style={{ marginTop: 8 }} />

                                        {/* map over bids in a single lot item */}
                                        {bids
                                            .filter((bid) => bid.lot?.id === lot.id)
                                            .sort((a: any, b: any) => a.combination - b.combination)
                                            .slice(0, noOfItems)
                                            .map((bid, i) => {
                                                const filteredNames = bid.marketParty.names.filter((n) => n.type === "trade_name");

                                                return (
                                                    <React.Fragment key={bid.id}>
                                                        {/* <ListItem style={{ paddingLeft: 0 }}> */}
                                                        <ListItem sx={{ paddingLeft: "8px 16px" }}>
                                                            {/* tenderer logo */}
                                                            <div style={{ marginRight: 8 }}>
                                                                <FaviconBox
                                                                    favicon={bid.marketParty.favicon_resized ?? bid.marketParty.favicon}
                                                                    name={bid.marketParty.name}
                                                                    color={pink}
                                                                    marginTop={0}
                                                                />
                                                            </div>

                                                            {/* Link to tenderer */}
                                                            <ListItemText
                                                                primary={
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            alignItems: "center",
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            noWrap
                                                                            sx={{
                                                                                display: "inline",
                                                                                maxWidth: (theme) =>
                                                                                    theme.breakpoints.up("xl")
                                                                                        ? "88%"
                                                                                        : theme.breakpoints.up("xxl")
                                                                                        ? "94%"
                                                                                        : "80%",
                                                                            }}
                                                                        >
                                                                            {filteredNames.length > 0 ? (
                                                                                <Tooltip
                                                                                    title={filteredNames
                                                                                        .sort((a, b) => a.value.localeCompare(b.value))
                                                                                        .map((item, i) => {
                                                                                            return (
                                                                                                <React.Fragment key={i}>
                                                                                                    {i !== filteredNames.length - 1 ? (
                                                                                                        <Typography variant="caption">
                                                                                                            {item.value} |{" "}
                                                                                                        </Typography>
                                                                                                    ) : (
                                                                                                        <Typography variant="caption">
                                                                                                            {item.value}
                                                                                                        </Typography>
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}
                                                                                >
                                                                                    <Link to={`/organizations/${bid.marketParty.id}`}>
                                                                                        {bid.marketParty.name}{" "}
                                                                                    </Link>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <Link to={`/organizations/${bid.marketParty.id}`}>
                                                                                    {bid.marketParty.name}{" "}
                                                                                </Link>
                                                                            )}
                                                                        </Typography>
                                                                        <CountryFlagBox
                                                                            code={
                                                                                bid.marketParty.country?.alpha2
                                                                                    ? bid.marketParty.country.alpha2
                                                                                    : null
                                                                            }
                                                                        />
                                                                        {/* TODO: conditon, render only if starred */}
                                                                        {starredOrgs.find((s) => s.id === bid.marketParty.id) && (
                                                                            <StarIcon
                                                                                style={{ color: `${starColor}`, marginLeft: 4, fontSize: "1rem" }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                }
                                                                secondary={
                                                                    <React.Fragment>
                                                                        {bid.totalValue && (
                                                                            <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                                                {/* get value and change EUR to currency icon */}
                                                                                {currencyFormat(bid.totalValue?.currency)}
                                                                                {/* Check amount and add dots if needed */}
                                                                                {bid.totalValue?.value
                                                                                    ?.toString()
                                                                                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                                                            </Typography>
                                                                        )}

                                                                        {/* show green or red percentage when there are over 1 winners, percentage based on diff between lowest offer and offer of bid */}
                                                                        {bid.percentage && (
                                                                            <Typography
                                                                                style={{ display: "inline-block" }}
                                                                                sx={{ color: (theme) => theme.palette.error.main }}
                                                                            >
                                                                                {bid.percentage}
                                                                            </Typography>
                                                                        )}
                                                                    </React.Fragment>
                                                                }
                                                            />

                                                            {/* Chip that shows who won the bid */}
                                                            <ListItemSecondaryAction style={{ display: "flex", flexDirection: "row" }}>
                                                                {bid.winner && bid.combination === null ? (
                                                                    <div style={{ marginLeft: 4, marginTop: 8 }}>
                                                                        <StatusChip colored={false} type="winner" hover={true} />
                                                                    </div>
                                                                ) : (
                                                                    bid.winner &&
                                                                    uniqByKeepFirst(bids, (it: any) => it.combination).find(
                                                                        (t: any) => t.id === bid.id && t.combination !== null
                                                                    ) && (
                                                                        <div style={{ marginLeft: 4, marginTop: 8, marginRight: 20 }}>
                                                                            <StatusChip colored={false} type="winner" hover={true} />
                                                                        </div>
                                                                    )
                                                                )}
                                                            </ListItemSecondaryAction>
                                                        </ListItem>

                                                        {/* get array with last duplicates and check if id matches
                                                        If id is a match, dont show a '+', if no match show '+' */}
                                                        {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null)
                                                            ? null
                                                            : bid.combination !== null && (
                                                                  <ListItem style={{ padding: "0px 16px", height: 0 }}>
                                                                      <ListItemText inset style={{ margin: "0 0 0 32px", fontSize: "1rem" }}>
                                                                          +
                                                                      </ListItemText>
                                                                  </ListItem>
                                                              )}

                                                        {/* Check if item is last one AND item is not a combination, last one -> show divider */}
                                                        {bids.filter((bid) => bid.lot?.id === lot.id).length - 1 !== i &&
                                                            bid.combination === null && <Divider />}

                                                        {/* Set divider to last index of a combination */}
                                                        {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && <Divider />}
                                                    </React.Fragment>
                                                );
                                            })}
                                    </List>
                                </AccordionDetails>
                            )}

                            {bids.filter((bid) => bid.lot?.id === lot.id).length > 5 && (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {noOfItems === 5 ? (
                                        <Button size="small" variant="text" color="primary" style={{ marginBottom: 8 }} onClick={handleShowMoreItems}>
                                            {t("tenderPage.showAll")}
                                        </Button>
                                    ) : (
                                        <Button size="small" variant="text" color="primary" style={{ marginBottom: 8 }} onClick={handleShowLess}>
                                            {t("tenderPage.ShowLess")}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </Accordion>
                        <Divider />
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default ExtendedDetails;
