import React, { useState } from "react";
import { Popover, MenuList, MenuItem, Typography, IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { DELETE_PROJECT_STATE } from "../../../graphql/mutationDefProjects";
import { deleteStateOfProject, deleteStateOfProjectVariables } from "../../../__generated__/deleteStateOfProject";
import { projectStates_projectStates } from "../../../__generated__/projectStates";
import ResponseOnRemove from "./ResponseOnRemove";
import { PROJECT_STATES } from "../../../graphql/queryDefProjects";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    column: projectStates_projectStates;
}

const ColumnMenu: React.FC<Props> = ({ column }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [response, setResponse] = useState<string>("none");
    const [opened, setOpen] = useState<boolean>(false);
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     *
     * @param event Open popover underneath button
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    /**
     * close popover
     */
    const handleClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;

    const [deleteCol] = useMutation<deleteStateOfProject, deleteStateOfProjectVariables>(DELETE_PROJECT_STATE);

    /**
     * Update complete state of single task
     * @param taskId id of task
     * @param boolean bolean to set task to
     */
    const onDelete = async () => {
        try {
            await deleteCol({
                variables: {
                    id: column.id,
                },
                onCompleted: (data) => {
                    trackevents({ category: "Project", action: "Delete-column", name: data.deleteStateOfProject as string });
                    setResponse(data.deleteStateOfProject as string);
                    if ((data.deleteStateOfProject as string) !== "Verwijderd") {
                        setOpen(true);
                    }
                },
                refetchQueries: [PROJECT_STATES, "projectStates"],
            });
        } catch (e) {}
    };

    return (
        <React.Fragment>
            <IconButton size="small" onClick={handleClick}>
                <MoreVert fontSize="small" />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                    handleClose(e);
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList autoFocusItem={open} style={{ padding: 0 }}>
                    <MenuItem
                        onClick={(e) => {
                            // handleClose(e);
                            onDelete();
                        }}
                    >
                        <Typography style={{ padding: "4px 8px" }}>Verwijderen</Typography>
                    </MenuItem>
                </MenuList>
            </Popover>
            <ResponseOnRemove response={response} opened={opened} setOpen={setOpen} />
        </React.Fragment>
    );
};

export default ColumnMenu;
