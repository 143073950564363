import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from "@apollo/client";
import { ApolloClient } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_ROOT}/graphql`,
    credentials: "same-origin",
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("access_token");
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            Accept: "application/json",
        },
    };
});

/**
 * Add a 'listener' on errors during a graphql query/mutation
 * When the error is related to authentication ==> remove the token
 * since it may be expired or corrupt.
 */
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            if (err.message === "Unauthenticated.") {
                console.log("GRAPHQL ERROR", `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`);
                localStorage.removeItem("access_token");
                // window.location.reload();
                return;
            }
            console.log("GRAPHQL ERROR", `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`);
        }
    }
    if (networkError) console.log("GRAPHQL NETWORK ERROR", `[Network error]: ${networkError}`);

    return forward(operation);
});

const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache({
        /**
         * GraphQL has issues with nested union types.
         * We need to help by specifying what a Source can be.
         * If not, all data-fields will be stripped from all nested objects and no error is returned
         *
         * https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
         */
        possibleTypes: {
            Source: ["Document", "Publication"],
        },
    }),
});

export default client;
