import React, { useEffect, useState } from "react";

import setTitle from "../../../utils/setTitle";
import { useTranslation } from "react-i18next";
import { ColumnContext } from "../../../components/contextProviders/ColumnContext";
import { useLazyQuery } from "@apollo/client";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";

import OpportunitySearch from "../OpportunitySearch";
import { OpportunityFilterInput, OpportunitySearchInput, OpportunitySearchSource, SortOrder } from "../../../__generated__/globalTypes";
import OpportunityWorklistTable from "./OpportunityWorklistTable";
import { ColumnProvider } from "../../../components/contextProviders/ColumnProvider";
import { workOppColumns } from "./Oppcolumns";
import { QUERY_WORKLIST_DOCUMENTS } from "../../../graphql/opportunityQueries/queryDefinitions";
import {
    GetWorklistDocsForOpportunities,
    GetWorklistDocsForOpportunitiesVariables,
    GetWorklistDocsForOpportunities_filterOpportunityFiles_filters,
    GetWorklistDocsForOpportunities_filterOpportunityFiles_results,
} from "../../../__generated__/GetWorklistDocsForOpportunities";
import ModuleInformation from "../../../components/ModuleInfoBox/ModuleInformation";
import OpportunityContent from "../../../components/ModuleInfoBox/OpportunityContent";
import { Box } from "@mui/material";

interface Props {
    a?: number;
}

const filters: OpportunityFilterInput = {};
const defaultSearch: OpportunitySearchInput = { filters };
const defaultVariables: GetWorklistDocsForOpportunitiesVariables = {
    page: 1,
    first: 10,
    orderField: "publication_date",
    order: SortOrder.DESC,
    source: OpportunitySearchSource.MY_WORKLIST,
    search: defaultSearch,
};

const WorklistPage: React.FC<Props> = (props) => {
    const [searchInput, setSearchInput] = useState<OpportunityFilterInput>({});
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(defaultVariables.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.first);
    const [
        orderField,
        //  setOrderField
    ] = useState<string>(defaultVariables.orderField);
    const [
        orderDir,
        // setOrderDir
    ] = useState<SortOrder>(defaultVariables.order);
    // state to keep track of searchinput
    const [query, setQuery] = useState<string>("");
    // query filterdata
    const [filterData, setFilterData] = useState<GetWorklistDocsForOpportunities_filterOpportunityFiles_filters[] | null>([]);
    // query response data
    const [oppData, setOppData] = useState<GetWorklistDocsForOpportunities_filterOpportunityFiles_results | null>();

    const variables: GetWorklistDocsForOpportunitiesVariables = {
        source: defaultVariables.source,
        first: rowsPerPage,
        page: page,
        orderField: orderField,
        order: orderDir,
        search: {
            filters: searchInput,
        },
    };

    /**
     * Initialize state from url parameters
     */
    useEffect(() => {
        // Initial setup using query parameters
        // get active filterset from session storage
        if (sessionStorage.getItem("wof")) {
            setSearchInput(JSON.parse(sessionStorage.getItem("wof") || "{}"));
        }
        if (sessionStorage.getItem("wop")) {
            // set current page to sessionstorage value or 1 if sessionstorage isn't set
            setPage(parseInt(JSON.parse(sessionStorage.getItem("wop") || "1")));
        }
        // get query from session storage (not in use atm)
        // if (sessionStorage.getItem("woq")) {
        //     setQuery(JSON.parse(sessionStorage.getItem("woq") || ""));
        // }
    }, [t]);

    /**
     * Define the GQL query
     */
    const [run, { error, loading }] = useLazyQuery<GetWorklistDocsForOpportunities, GetWorklistDocsForOpportunitiesVariables>(
        QUERY_WORKLIST_DOCUMENTS,
        {
            variables: variables,
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterOpportunityFiles) {
                    setFilterData(data.filterOpportunityFiles.filters);
                    setOppData(data.filterOpportunityFiles.results);
                }
            },
        }
    );

    // Set page title
    useEffect(() => {
        setTitle(t("Worklist"));
        sessionStorage.setItem("wof", JSON.stringify(searchInput));
        sessionStorage.setItem("wop", JSON.stringify(page));

        // not in use atm.
        // sessionStorage.setItem("woq", JSON.stringify(query));
        run({
            variables: {
                source: defaultVariables.source,
                first: rowsPerPage,
                page: page,
                orderField: orderField,
                order: orderDir,
                search: {
                    filters: searchInput,
                },
            },
        });
    }, [run, t, page, rowsPerPage, orderField, orderDir, searchInput]);

    // handle pagination change: -page change, -rows per page change
    const handlePaginationChange = (page: number, rows: number) => {
        setPage(page);
        setRowsPerPage(rows);
        if (rows !== rowsPerPage) {
            setPage(1);
        }
    };

    //! handle table change: -sort column, -sort direction
    // const handleTableChange = (_: string, tableState: MUIDataTableState) => {
    //     if (tableState.sortOrder.direction) {
    //         setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
    //     }
    //     if (tableState.sortOrder.name) {
    //         setOrderField(tableState.sortOrder.name);
    //     }
    // };

    const renderPage = (content: React.ReactNode) => (
        <ColumnProvider storeKey="cuocw" defaultState={workOppColumns}>
            <ColumnContext.Provider value={{ selectedRows, setSelectedRows }}>
                <Box component="div" id="tender-root" sx={{ padding: { xs: "20px 8px", md: "20px" }, overflow: "scroll" }}>
                    <OpportunitySearch
                        resetFilters={() => {
                            setSearchInput({});
                            setPage(1);
                        }}
                        columns={workOppColumns}
                        orderField={orderField}
                        order={orderDir}
                        loading={loading}
                        resetPage={() => setPage(1)}
                        searchInput={searchInput}
                        onChange={setSearchInput}
                        visible={false}
                        filterData={filterData}
                        query={query}
                        setQuery={setQuery}
                        startSearch={() => {
                            setPage(1);
                            run({ variables });
                        }}
                    />

                    {content}
                </Box>
            </ColumnContext.Provider>
            <ModuleInformation title={"Kansen"}>
                <OpportunityContent />
            </ModuleInformation>
        </ColumnProvider>
    );

    if (!oppData && loading) return renderPage(<FullPageSpinner />);
    if (error || !oppData)
        return renderPage(
            <OpportunityWorklistTable
                loading={loading}
                error={error}
                rows={[]}
                paginatorInfo={{
                    count: 0,
                    currentPage: 0,
                    hasMorePages: false,
                    firstItem: 0,
                    lastItem: 0,
                    lastPage: 1,
                    perPage: 10,
                    total: 0,
                    __typename: "PaginatorInfo",
                }}
                onChangePagination={handlePaginationChange}
                onTableChange={undefined}
            />
        );

    return renderPage(
        <div id="opportunity-worklist">
            <OpportunityWorklistTable
                loading={loading}
                rows={oppData.data}
                paginatorInfo={
                    oppData.paginatorInfo
                        ? oppData.paginatorInfo
                        : {
                              count: 0,
                              currentPage: 0,
                              hasMorePages: false,
                              firstItem: 0,
                              lastItem: 0,
                              lastPage: 1,
                              perPage: 10,
                              total: 0,
                              __typename: "PaginatorInfo",
                          }
                }
                onChangePagination={handlePaginationChange}
                onTableChange={undefined}
            />
        </div>
    );
};

export default WorklistPage;
