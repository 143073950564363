import { useQuery } from "@apollo/client";
import React from "react";
import { GET_BID_VALUE_COLUMN } from "../../../graphql/queryDefForColumns";
import { GetBidValueColumn, GetBidValueColumnVariables } from "../../../__generated__/GetBidValueColumn";
import { Tooltip, Typography } from "@mui/material";
import currencyFormatDE from "../../../utils/currencyFormatter";

interface Props {
    tender_id: string;
}

const SplitMetaValuePerBid: React.FC<Props> = ({ tender_id }) => {
    const { data } = useQuery<GetBidValueColumn, GetBidValueColumnVariables>(GET_BID_VALUE_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    if (tender.bids === null || tender.bids.length === 0) {
        const jsonData = JSON.parse(tender.published_participants);
        return (
            <Typography>
                {jsonData.length > 0 &&
                    jsonData?.map((row: any) =>
                        row.contractors?.map((p: any, i: number) => {
                            // change "EUR" to "€"
                            const currencySign = p?.definitieve_waarde?.toString().replace("EUR", "€");
                            // change dot to comma
                            const dotToComma = currencySign?.replace(/\./g, ",");
                            // add dots, example: 187995,43 -> 187.995,43
                            const value = dotToComma?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
                            return (
                                <div key={i}>
                                    <Typography style={{ display: "inline-block", marginRight: 16 }}>{value}</Typography>
                                </div>
                            );
                        })
                    )}
            </Typography>
        );
    }

    // Filter bid winner(s)
    const key = "combination";
    const onlyValueOfWinners = tender.bids.filter((bid) => bid.winner === true);
    const checkForCombinations = [...new Set(onlyValueOfWinners.map((i) => i.combination))].length;

    const arrayUniqueCombinations =
        checkForCombinations > 1 ? [...new Map(onlyValueOfWinners.map((item) => [item[key], item])).values()] : onlyValueOfWinners;

    return (
        <div style={{ display: "inline-flex", flexDirection: "column" }}>
            {arrayUniqueCombinations.map((bid) => {
                return (
                    <Tooltip key={bid.id} title={bid.lot ? `Perceel ${bid.lot?.number}` : ""} placement="right" disableInteractive>
                        <Typography style={{ whiteSpace: "pre-wrap", display: "inline-flex" }}>
                            {bid.bid ? `€ ${currencyFormatDE(bid.bid)}` : ""}
                        </Typography>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export default SplitMetaValuePerBid;
