import { createContext, useContext } from "react";

export type ColumnContextType = {
    selectedRows: Array<any>;
    setSelectedRows: (selectedRows: any) => void;
};

export const ColumnContextTndrInt = createContext<ColumnContextType>({
    selectedRows: [],
    setSelectedRows: () => console.warn("no rows selected"),
});
export const useColumnSetterInt = () => useContext(ColumnContextTndrInt);
