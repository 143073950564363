import React, { useContext, useState } from "react";
import { AvatarGroup, Avatar, Badge, IconButton, ListItemButton, ListItemText, Popover, Tooltip } from "@mui/material";
import { AccountTree, ArrowOutward } from "@mui/icons-material";
import { ProjectToOpenContext } from "../layout/MainLayout";
import TooltipOnNewLines from "../TooltipOnNewLines";
import { gql, useQuery } from "@apollo/client";
import { GetTenderProjectsForUser, GetTenderProjectsForUserVariables } from "../../__generated__/GetTenderProjectsForUser";
import { Disable } from "react-disable";
import CreateProjectFromEntity from "../../routes/projects/createproject/CreateProjectFromEntity";

interface Props {
    tender_id: string;
    tender_title: string | null;
    buttonSize: "small" | "medium";
    openFromTable: boolean;
    refetchQueries: any;
}

const OpenOrCreateProject: React.FC<Props> = ({ tender_id, tender_title, buttonSize, openFromTable, refetchQueries }) => {
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);
    const [openPopper, setOpenPopper] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { data, loading } = useQuery<GetTenderProjectsForUser, GetTenderProjectsForUserVariables>(QUERY_TENDER_PROJECTS_FOR_USER, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenPopper(true);
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenPopper(false);
    };
    const projectsOfTender = data?.tender?.projects;
    return (
        <React.Fragment>
            <Disable disabled={loading}>
                {projectsOfTender !== null && projectsOfTender !== undefined && projectsOfTender.length === 1 ? (
                    <Tooltip disableInteractive title="Open project" enterNextDelay={100} placement="top">
                        <Badge
                            sx={{
                                marginRight: "0px",
                                cursor: "pointer",
                                "& .MuiBadge-badge": {
                                    right: 6,
                                    top: 6,
                                    border: `1px solid #fff`,
                                    backgroundColor: "#9e9e9e",
                                    padding: "0 2px",
                                    fontSize: "9px",
                                    minWidth: "18px",
                                    height: "16px",
                                },
                            }}
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            badgeContent={
                                <>
                                    <ArrowOutward sx={{ height: "10px", width: "10px", padding: 0, color: "#ffffff" }} />
                                </>
                            }
                        >
                            <IconButton
                                onClick={() => {
                                    if (projectsOfTender[0] !== null) {
                                        changeProject({
                                            projectID: projectsOfTender[0].id as string,
                                            projectTitle: projectsOfTender[0].title,
                                            state: projectsOfTender[0].state?.id as string,
                                        });
                                    }
                                    setOpen(true);
                                }}
                                size={buttonSize}
                            >
                                <AccountTree fontSize="small" />
                            </IconButton>
                        </Badge>
                    </Tooltip>
                ) : projectsOfTender !== null && projectsOfTender !== undefined && projectsOfTender.length > 1 ? (
                    <Tooltip disableInteractive title="Kies project" enterNextDelay={100} placement="top">
                        <Badge
                            sx={{
                                cursor: "pointer",
                                marginRight: "0px",
                                "& .MuiBadge-badge": {
                                    right: 6,
                                    top: 6,
                                    border: `1px solid #fff`,
                                    backgroundColor: "#9e9e9e",
                                    color: "#ffffff",
                                    padding: "0 2px",
                                    fontSize: "9px",
                                    minWidth: "16px",
                                    height: "16px",
                                },
                            }}
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            badgeContent={projectsOfTender?.length || 0}
                        >
                            <IconButton
                                onClick={(e) => {
                                    handleClickSub(e);
                                    setOpenPopper(true);
                                }}
                                size={buttonSize}
                            >
                                <AccountTree fontSize="small" />
                            </IconButton>
                        </Badge>
                    </Tooltip>
                ) : (
                    <CreateProjectFromEntity
                        tender_title={tender_title ?? ""}
                        tender_id={tender_id}
                        openFromTable={openFromTable}
                        btnSize={buttonSize}
                        refetchQueries={refetchQueries}
                    />
                )}
            </Disable>
            <Popover
                anchorEl={anchorEl}
                open={openPopper}
                onClose={handleCloseSub}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                /**
                 * Width and height for chat/knowledge base
                 */
                slotProps={{
                    paper: {
                        sx: {
                            width: "300px",
                        },
                    },
                }}
                // PaperProps={{
                //     style: { width: "300px" },
                // }}
            >
                {projectsOfTender?.map((prjct) => {
                    if (prjct === null) {
                        return <></>;
                    }
                    return (
                        <ListItemButton
                            // dense
                            key={prjct.id}
                            sx={{ pl: 1, display: "flex", alignItems: "center" }}
                            alignItems="flex-start"
                            onClick={(e) => {
                                changeProject({
                                    projectID: prjct.id as string,
                                    projectTitle: prjct.title,
                                    state: prjct.state?.id as string,
                                });
                                setOpen(true);
                                handleCloseSub(e);
                            }}
                        >
                            <ListItemText
                                primary={
                                    <TooltipOnNewLines
                                        text={prjct.title}
                                        variant={"body1"}
                                        lines={1}
                                        id={`project${prjct.id}`}
                                        fontweight={"normal"}
                                        fontSize={14}
                                        placement={"top"}
                                    />
                                }
                            />
                            <Tooltip
                                disableInteractive
                                title={
                                    <>
                                        {prjct.activeUsers?.map((u) => {
                                            return (
                                                <span key={u.id}>
                                                    {u.employee.name} <br />
                                                </span>
                                            );
                                        })}
                                    </>
                                }
                            >
                                <AvatarGroup
                                    max={4}
                                    sx={{
                                        cursor: "pointer",
                                        "& .MuiAvatarGroup-avatar": {
                                            width: "24px",
                                            height: "24px",
                                            fontSize: "0.75rem",
                                            border: "2px solid #fff",
                                        },
                                    }}
                                >
                                    {prjct.activeUsers?.map((user) => {
                                        return (
                                            <Avatar key={user.id}>
                                                {user.employee.givenname?.slice(0, 1)}
                                                {user.employee.familyname?.slice(0, 1)}
                                            </Avatar>
                                        );
                                    })}
                                </AvatarGroup>
                            </Tooltip>
                        </ListItemButton>
                    );
                })}
            </Popover>
        </React.Fragment>
    );
};

export default OpenOrCreateProject;

//? get all activities of current user
export const QUERY_TENDER_PROJECTS_FOR_USER = gql`
    query GetTenderProjectsForUser($id: ID!) {
        tender(id: $id) {
            projects {
                id
                title
                state {
                    id
                }
                activeUsers {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
            }
        }
    }
`;
