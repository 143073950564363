import React from "react";
import { Grid, Tooltip } from "@mui/material";
import { useQuery } from "@apollo/client";
import SearchRuleLabel from "./SearchRuleLabel";
import { GetSearchParamMatchesWithLabels, GetSearchParamMatchesWithLabelsVariables } from "../__generated__/GetSearchParamMatchesWithLabels";
import { QUERY_GETLABELMATCHPARAMS_WITH_LABELS } from "../graphql/queryDefSearchAndHighlighting";
import { LocalOfferOutlined } from "@mui/icons-material";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";

interface Props {
    iconSize?: "small" | "medium" | "inherit" | "large";
    tender_id: string;
    /**
     * marginRight is optional, can set to a number for margin. undefined = marginRight:0
     */
    marginRight?: string;
    /**
     * placement for tooltip
     */
    placement:
        | "bottom"
        | "left"
        | "right"
        | "top"
        | "bottom-end"
        | "bottom-start"
        | "left-end"
        | "left-start"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | undefined;
    /**
     * Searchrule results
     */
    searches: any;
    card?: boolean;
    showMultiple?: boolean;
}

const SearchRuleData: React.FC<Props> = ({ tender_id, placement, marginRight, searches, card = false, iconSize, showMultiple = true }) => {
    const { data, loading } = useQuery<GetSearchParamMatchesWithLabels, GetSearchParamMatchesWithLabelsVariables>(
        QUERY_GETLABELMATCHPARAMS_WITH_LABELS,
        {
            variables: { tender_id: parseInt(tender_id) },
            fetchPolicy: "network-only",
        }
    );

    if (loading || data === undefined) {
        // return <LocalOfferIcon style={{ color: "#eeeeee" }} />;
        return <></>;
    }

    return (
        <Grid container>
            {!showMultiple &&
            data.GetSearchTenderMatch !== undefined &&
            data.GetSearchTenderMatch !== null &&
            data.GetSearchTenderMatch.length > 1 ? (
                <Tooltip placement="left" title="Meerdere resultaten gevonden">
                    <LocalOfferOutlined style={{ color: "#9e9e9e" }} />
                </Tooltip>
            ) : (
                <SearchRuleLabel
                    iconSize={iconSize}
                    data={
                        data !== undefined &&
                        data !== null &&
                        data.GetSearchTenderMatch !== undefined &&
                        data.GetSearchTenderMatch !== null &&
                        data.GetSearchTenderMatch.length > 0
                            ? data.GetSearchTenderMatch
                            : searches
                    }
                    placement={placement}
                    marginRight={marginRight}
                    card={card}
                />
            )}
        </Grid>
    );
};

export default SearchRuleData;
