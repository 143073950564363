import { createContext, useContext } from "react";

export type TourContextType = {
    steps: any[];
    openTour: boolean;
    setOpenTour: (closeTour: boolean) => void;
    currentTour: string;
    tourColor: string;
    currentStep: number;
    lockTour: boolean;
    setLockTour: (lockTour: boolean) => void;
    setCurrentStep: (currentStep: number) => void;
    setTourVariables: (steps: any, tourColor: string, currentTour: string) => void;
};

export const TourContext = createContext<TourContextType>({
    steps: [],
    openTour: false,
    currentStep: 0,
    tourColor: "#173155",
    lockTour: false,
    currentTour: "",
    setLockTour: (lockTour) => console.warn("no theme provider"),
    setCurrentStep: (currentstep) => console.warn("no theme provider"),
    setOpenTour: (closeTour) => console.warn("no theme provider"),
    setTourVariables: (steps, tourColor, currentTour) => console.warn("no variables given "),
});
export const useTour = () => useContext(TourContext);
