import React from "react";
import ColumnView from "./columnView/ColumnView";
import { View } from "./topbar/ProjectViewToggleButtons";

interface Props {
    view: View;
}

const ProjectContainer: React.FC<Props> = ({ view }) => {
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <div>
                {(() => {
                    /**
                     * Switch contains all states a tender could have, for each state a different component.
                     */
                    switch (true) {
                        case view === 0:
                            return <div>list</div>;

                        case view === 1:
                            return <ColumnView />;

                        case view === 2:
                            return <div>event</div>;

                        case view === 3:
                            return <div>person</div>;

                        default:
                            return <ColumnView />;
                    }
                })()}
            </div>
        </div>
    );
};

export default ProjectContainer;
