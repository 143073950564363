import React, { useEffect, useState } from "react";

import { MenuItem, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { FindInPage, LocalOffer } from "@mui/icons-material";
import StatusDot from "../StatusDot";
import CountryFlagBox from "../boxes/CountryFlagBox";
import FaviconBox from "../FaviconBox";
import { useTranslation } from "react-i18next";
import { MemoIcon } from "../boxes/SectorIconLoader";

interface Props {
    data: any[];
    onOptionClick: (key: any) => void;
    label: string;
    width: number;
}

const FilterDropdownChosenOptions: React.FC<Props> = ({ data, onOptionClick, label, width }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const yellow = "#F57117";
    const [dataset, setDataset] = useState<any[]>([]);

    useEffect(() => {
        /**
         * Property that has to be unique
         */
        const key = "id";
        /**
         * dedpulicate array based on key
         */
        const arrayUniqueByKey = [...new Map(data.map((item) => [item[key], item])).values()];
        /**
         * Set state to unqiue array
         */
        setDataset(arrayUniqueByKey);
    }, [data]);

    return (
        <div style={{ width: width }}>
            {dataset?.map((option, index) => {
                return (
                    <MenuItem key={index} dense onClick={() => onOptionClick(option)}>
                        {/* if label is translationkey status show StatusDot component */}
                        {label === t("filter.searches") && (pathname === "/tenders/reviews" || pathname === "/tenders/worklist") && (
                            <div style={{ marginLeft: -4, marginRight: 6, marginTop: 6 }}>
                                <LocalOffer style={{ color: option.key }} />
                            </div>
                        )}
                        {label === t("filter.searches") && (pathname === "/opportunities/reviews" || pathname === "/opportunities/worklist") && (
                            <div style={{ marginLeft: -4, marginRight: 6, marginTop: 6 }}>
                                <FindInPage style={{ color: option.key }} />
                            </div>
                        )}
                        {/* If translationkey is status, show labels in color   */}
                        {label === t("filter.status") && (
                            <div style={{ marginRight: 6, marginLeft: -4 }}>
                                <StatusDot type={option.id.toString()} placement={"right"} />
                            </div>
                        )}
                        {/* If translationkey is country, show countryflag */}
                        {label === t("filter.country") && (
                            <div style={{ marginLeft: -4, marginRight: 6, marginTop: -2 }}>
                                <CountryFlagBox code={null} alpha2={option.id.toString()} />
                            </div>
                        )}
                        {/* If translationkey is ca, show favicon */}
                        {label === t("filter.contractingAuthorities") && (
                            <div>
                                <FaviconBox favicon="" name={option.key} color={yellow} id={option.id} />
                            </div>
                        )}
                        {/* If translationkey is subsectors, show icon */}
                        {label === t("filter.subSectors") && (
                            <div style={{ marginTop: 4, marginRight: 4 }}>
                                <MemoIcon sector_id={option.id.toString()} />
                            </div>
                        )}
                        <Tooltip title={option.label} placement="top-start" componentsProps={{ tooltip: { sx: { marginBottom: "3px" } } }}>
                            <Typography variant="inherit" noWrap>
                                <strong>{option.label}</strong>
                            </Typography>
                        </Tooltip>
                    </MenuItem>
                );
            })}
        </div>
    );
};

export default FilterDropdownChosenOptions;
