import React from "react";
import { GET_SCOPE_COLUMN } from "../../graphql/queryDefForColumns";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { GetScopeColumn, GetScopeColumnVariables } from "../../__generated__/GetScopeColumn";
import Scopevaluedatacell from "../Scopevaluedatacell";

interface Props {
    tender_id: string;
}

const ScopeColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetScopeColumn, GetScopeColumnVariables>(GET_SCOPE_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    return <Scopevaluedatacell selectedItem={tender.scope} />;
};

export default ScopeColumn;
