import React, { useState } from "react";
import MUIDataTable, { ExpandButton, MUIDataTableOptions } from "mui-datatables";
import { Paper, createTheme, Theme, ThemeProvider, TableRow, TableCell, Typography, useTheme, useMediaQuery } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Disable } from "react-disable";
import {
    GetAllDocsForOpportunities_filterOpportunityFiles_results_data,
    GetAllDocsForOpportunities_filterOpportunityFiles_results_paginatorInfo,
    GetAllDocsForOpportunities_filterOpportunityFiles_snippets,
} from "../../../__generated__/GetAllDocsForOpportunities";
import { useColumnSetter } from "../../../components/contextProviders/ColumnContext";
import LinearLoader from "../../../components/loading/LinearLoader";
import { allOppColumns } from "./AllOppColumns";
import { handleOppHighlighting } from "../../../components/HighlightOpportunity";
import { useSelectedColumns } from "../../../components/contextProviders/ColumnProvider";
import MobileTableNavigation from "../../../components/MobileTableNavigation";
import TableNavigation from "../../../components/TableNavigation";

interface Props {
    /**
     * Query to check if user searched for something
     */
    query: string;
    /**
     * snippet Data
     */
    snippets: (GetAllDocsForOpportunities_filterOpportunityFiles_snippets | null)[] | null;
    /**
     * Data to be displayed in table
     */
    rows: GetAllDocsForOpportunities_filterOpportunityFiles_results_data[];
    /**
     * Pagination info
     */
    paginatorInfo: GetAllDocsForOpportunities_filterOpportunityFiles_results_paginatorInfo;
    /**
     * onChange page or rows per page
     */
    onChangePagination(page: number, rowsPerPage: number): void;
    /**
     * chang ein show/hide columns
     */
    onTableChange: MUIDataTableOptions["onTableChange"];
    /**
     * Loading state
     */
    loading: boolean;
    /**
     * Apollo error
     */
    error?: ApolloError | undefined;
}

// Custom override styling for table
const getMuiTheme = (theme: Theme) =>
    createTheme({
        palette: {
            primary: {
                main: "#225E4D",
            },
        },

        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro, system-ui, sans-serif",
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },

        components: {
            // MUIDataTable: {
            //     tableRoot: {
            //         width: "100% !important",
            //     },
            // },

            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "none !important",
                    },
                },
            },

            // Custom hovercolor: opacity 7% -> add 12 to hexcode
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: `${"#225E4D"}12 !important`,
                        },
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        textAlign: "start",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: 8,
                    },
                },
            },
            // MUIDataTableHeadCell: {
            //     styleOverrides: {
            //         data: {
            //             textAlign: "start",
            //         },
            //     },
            // },
            // MUIDataTableSelectCell: {
            //     styleOverrides: {
            //         expandDisabled: {
            //             // Soft hide the button.
            //             visibility: "hidden",
            //         },
            //     },
            // },
        },
    });

const AllOppTable: React.FC<Props> = ({ rows, paginatorInfo, onChangePagination, loading, snippets, query }) => {
    const { t } = useTranslation();
    const { setSelectedRows, selectedRows } = useColumnSetter();
    const { items } = useSelectedColumns(allOppColumns);
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const snippetCount = snippets?.map((i) => i?.snippets?.map((i) => i).length).reduce((a: any, b: any) => a + b, 0);
    /**
     * state to keep number of expanded row
     */
    const [rowsExpanded, setRowsExpanded] = useState<number[]>([0]);

    /**
     * Data reformat definition, each container is a columnsection in table.
     */
    const convertedData: GetAllDocsForOpportunities_filterOpportunityFiles_results_data[] = rows.map((i, index) => {
        const container: any = {};
        const id = "id";
        const filetype = "filetype";
        const provincie = "provincie";
        const date = "date";
        const favicon = "favicon";
        const ad_id = "ad_id";
        const AD = "AD";
        const ad_country = "ad_country";
        const ad_city = "ad_city";
        const doc_name = "doc_name";
        const ca_type = "ca_type";
        const searchrules = "searchrules";
        const sharing = "sharing";
        const smallmenu = "smallmenu";

        container[id] = i.id;
        container[ad_id] = i.contractingAuthority.id;
        container[filetype] = i.type?.name;
        container[provincie] = "provincie";
        container[date] = i.id;
        container[favicon] = i.contractingAuthority.favicon_resized ?? i.contractingAuthority.favicon;
        container[AD] = i.contractingAuthority.name;
        container[ad_country] = i.contractingAuthority.country;
        container[ad_city] = i.contractingAuthority.city;
        container[doc_name] = i.name;
        container[ca_type] = i?.contractingAuthority?.types?.map((i) => i).join(", ");
        container[searchrules] = i.id;
        container[sharing] = i.id;
        container[smallmenu] = i.id;

        return container;
    });

    /**
     * Fuction to get the selected rows and add id's of item inside row to context provider. now its possible to use the data inside to export,archive etc.
     */
    const onRowSelectionChange = (currentRowsSelected: Array<any>, allRowsSelected: Array<any>, rowsSelected: Array<any> | undefined) => {
        if (rowsSelected !== undefined) {
            const ids = rowsSelected.map((index) => convertedData[index].id);
            setSelectedRows(ids);
        }
    };

    const options: MUIDataTableOptions = {
        elevation: 0,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        filterType: "checkbox",
        draggableColumns: {
            enabled: false,
            transitionTime: 200,
        },
        serverSide: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: "none",
        onRowSelectionChange: onRowSelectionChange,
        rowsSelected: selectedRows.map((id: string) => convertedData.findIndex((item) => item.id === id)),
        // onColumnSortChange: onOrderChange,
        fixedSelectColumn: false,
        responsive: "standard",
        selectableRows: "none",

        expandableRows: snippetCount === 0 || snippetCount === undefined || query.length < 3 ? false : true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,

        /**
         *  if statement possible to set expandableRow to true or false
         */
        isRowExpandable: (dataIndex, expandedRows) => {
            if (snippets?.find((snippet) => snippet?.id === convertedData[dataIndex].id)) return true;
            // Prevent row from being expandable if dataIndex doesnt have a fragment as result

            return false;
        },

        /**
         * Textual helpers with translation
         * Header tooltip text
         */
        textLabels: {
            body: {
                noMatch: t("TableHelpers.NoMatch"),
                toolTip: t("TableHelpers.Sort"),
                columnHeaderTooltip: (column) => `${t(`Column_Header.${column.label}`)}`,
            },
        },

        /**
         * rowsExpanded -> state with indexnumber of expanded row.
         * defaultstate = index 0 (first row)
         */
        rowsExpanded: rowsExpanded,

        /**
         * Custom expanded row with snippets and highlighting
         */
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const snippet = snippets?.find((snippet) => snippet?.id === convertedData[rowMeta.dataIndex].id);
            return (
                <TableRow>
                    <TableCell colSpan={colSpan} style={{ padding: "10px 80px" }}>
                        {snippet?.snippets?.map((snippet, i) => {
                            return (
                                <Typography
                                    key={i}
                                    style={{ padding: "0px 8px 8px 0" }}
                                    dangerouslySetInnerHTML={{
                                        __html: `...${snippet}...`,
                                    }}
                                />
                            );
                        })}
                    </TableCell>
                </TableRow>
            );
        },
        /**
         * Run highlighting function for each change
         */
        onRowExpansionChange: (_, allRowsExpanded) => {
            // setRowsexpanded to clicked row (expanded)
            setRowsExpanded(allRowsExpanded.slice(-1).map((item) => item.index));
            // run highlighting function to show highlighting in expanded row
            handleOppHighlighting();
        },

        /**
         * Custom pagination footer
         */
        customFooter() {
            if (onlyMobile) {
                return (
                    <MobileTableNavigation
                        perPage={paginatorInfo.perPage}
                        total={paginatorInfo.total}
                        firstItemIndex={paginatorInfo.firstItem as number}
                        lastItemIndex={paginatorInfo.lastItem as number}
                        currentPage={paginatorInfo.currentPage}
                        onChangePagination={onChangePagination}
                    />
                );
            }
            return (
                <TableNavigation
                    perPage={paginatorInfo.perPage}
                    total={paginatorInfo.total}
                    firstItemIndex={paginatorInfo.firstItem as number}
                    lastItemIndex={paginatorInfo.lastItem as number}
                    currentPage={paginatorInfo.currentPage}
                    onChangePagination={onChangePagination}
                />
            );
        },
        // onTableChange,
    };

    const components = {
        ExpandButton: function (props: any) {
            // if (props.dataIndex > 0)
            //  return <div style={{ width: "24px" }} />;
            return <ExpandButton {...props} />;
        },
    };

    return (
        <React.Fragment>
            <LinearLoader loading={loading} color="green" />
            <Disable disabledOpacity={0.6} disabled={loading}>
                <Paper square>
                    {/* Added paper element around table to use the square variant instead of the default, 
                        - in MuidatatableOptons: elevation -> 0 
                    */}
                    <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                            title="Interessante aanbestedingen"
                            data={convertedData}
                            columns={items}
                            options={options}
                            components={components}
                        />
                    </ThemeProvider>
                </Paper>
            </Disable>
        </React.Fragment>
    );
};

export default AllOppTable;
