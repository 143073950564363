import React from "react";
import { Typography, Grid } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import { GetContractInfoForCurrentTender_tender_lots } from "../../__generated__/GetContractInfoForCurrentTender";

interface Props {
    lot: GetContractInfoForCurrentTender_tender_lots;
    setCopyText: (text: string) => void;
}

const SingleLotContract: React.FC<Props> = ({ lot, setCopyText }) => {
    const { t } = useTranslation();

    /**
     * dompurify imported for sanitizing html
     */
    const description = lot.contract?.description ? dompurify.sanitize(lot.contract.description, { FORCE_BODY: true }) : null;

    /**
     * text to use in copy function when indefinite period
     */
    const undetermined = "".concat(
        t("tenderPage.InitialDuration") +
            ": " +
            t("tenderPage.From") +
            " " +
            moment(lot.contract?.period?.startDate).format("L") +
            " " +
            t("tenderPage.indefinitePeriod")
    );

    /**
     * contract period text with start and enddate
     */
    const contractText =
        lot.contract?.period?.value !== null && lot.contract?.period?.startDate !== null && lot.contract?.period?.endDate !== null
            ? "".concat(
                  t("tenderPage.InitialDuration") +
                      ": " +
                      ` ${lot.contract?.period?.startDate ? moment(lot.contract?.period?.startDate).format("L") + " " + t("until") + " " : ""}` +
                      `${lot.contract?.period?.endDate ? moment(lot.contract?.period?.endDate).format("L") : ""}` +
                      " (" +
                      lot.contract?.period?.value?.toString() +
                      " " +
                      `${
                          lot.contract?.period?.value === 1
                              ? t(`singleContractPeriod.${lot.contract?.period?.type?.toLocaleLowerCase() || ""}`)
                              : t(`multiContractPeriod.${lot.contract?.period?.type?.toLocaleLowerCase() || ""}`)
                      }` +
                      ")"
              )
            : "";

    /**
     * options text of contract
     */
    const optionText =
        lot.contract?.options !== undefined && lot.contract?.options.length > 0
            ? "".concat(
                  t("tenderPage.Options") +
                      ": " +
                      `${
                          lot.contract?.options[0].indefinite
                              ? t("tenderPage.indefiniteNumberOfOptionsFrom") +
                                " " +
                                lot.contract?.options[0].length +
                                " " +
                                `${
                                    lot.contract?.options[0].length === 1
                                        ? t(`singleContractPeriod.${lot.contract?.options[0].period?.toLocaleLowerCase() || ""}`)
                                        : t(`multiContractPeriod.${lot.contract?.options[0].period?.toLocaleLowerCase() || ""}`)
                                }`
                              : lot.contract?.options[0].amount +
                                " x " +
                                lot.contract?.options[0].length +
                                " " +
                                `${
                                    lot.contract?.options[0].length === 1
                                        ? t(`singleContractPeriod.${lot.contract?.options[0].period?.toLocaleLowerCase() || ""}`)
                                        : t(`multiContractPeriod.${lot.contract?.options[0].period?.toLocaleLowerCase() || ""}`)
                                }`
                      }` +
                      " "
              )
            : "";

    /**
     * copy status of contract
     * TODO: if not available dont copy
     */
    // const copyStatus = "".concat(t("Contract_state") + ": " + status_contract);

    /**
     * Copy first end date of contract.
     */
    const firstEndDateCopy =
        lot.next_looptijd_einde !== null ? "".concat(t("tenderPage.FirstEnddate") + ": " + moment(lot.next_looptijd_einde).format("L") + " ") : "";

    /**
     * copy first end date with options
     */
    // const firstEndWithOptionsCopy =
    //     looptijd_einde_incl_opties !== null
    //         ? "".concat(t("tenderPage.First_enddate_with_options") + ": " + moment(looptijd_einde_incl_opties).format("L") + " ")
    //         : "";

    /**
     * copy description of contract
     */
    const descriptionCopy = description !== null && "".concat(t("tenderPage.Description") + ": " + description);

    /**
     * Get all items and combine in one string to make it possible to copy on clipboard
     */
    setCopyText(
        lot.title + "\n" + lot.contract?.undeterminedPeriod
            ? undetermined + "\n" + optionText + "\n" + firstEndDateCopy + "\n" + (descriptionCopy ? descriptionCopy : "")
            : contractText + "\n" + optionText + "\n" + firstEndDateCopy + "\n" + (descriptionCopy ? descriptionCopy : "")
    );

    const isUndetermined = Boolean(lot.contract?.undeterminedPeriod);
    const periodNotNull = Boolean(lot.contract?.period !== null);

    return (
        <Grid container style={{ padding: "8px 16px" }}>
            <Grid item xs={12} md={7} style={{ marginBottom: 4 }}>
                <table>
                    <tbody>
                        {/* status contract */}
                        {/* {lot.status_contract && (
                        <   tr>
                            <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("Contract_state")}:</td>
                            <td>
                                <Typography>{status_contract}</Typography>
                            </td>
                        </>
                    )} */}
                        {/* 
                    - Check if undeterminedPeriod is true
                    - Check if period os not 'null'
                    - if atleast one is true, show duration   
                    */}
                        {isUndetermined || periodNotNull ? (
                            <tr>
                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                    {t("tenderPage.InitialDuration")}:
                                </td>
                                <td>
                                    <>
                                        <Typography>
                                            {lot.contract?.undeterminedPeriod ? (
                                                <>
                                                    {moment(lot.contract?.period?.startDate).format("L")} {t("tenderPage.indefinitePeriod")}
                                                </>
                                            ) : (
                                                <>
                                                    {/*
                                                     * Check if period has a startdate -> show startDate
                                                     */}
                                                    {lot.contract?.period?.startDate && moment(lot.contract?.period?.startDate).format("L")}{" "}
                                                    {/*
                                                     * Check if period has a enddate -> show enddate
                                                     */}
                                                    {lot.contract?.period?.endDate
                                                        ? `${t("until")} ${moment(lot.contract?.period?.endDate).format("L")}`
                                                        : ""}
                                                    {/*
                                                     * check if period has a value and check if start and end date are available -> show value
                                                     */}
                                                    {lot.contract?.period?.value !== null &&
                                                        lot.contract?.period?.startDate &&
                                                        lot.contract?.period?.endDate && (
                                                            <>
                                                                {" "}
                                                                ({lot.contract?.period?.value}{" "}
                                                                {lot.contract?.period?.value === 1
                                                                    ? t(
                                                                          `singleContractPeriod.${
                                                                              lot.contract?.period?.type?.toLocaleLowerCase() || ""
                                                                          }`
                                                                      )
                                                                    : t(
                                                                          `multiContractPeriod.${
                                                                              lot.contract?.period?.type?.toLocaleLowerCase() || ""
                                                                          }`
                                                                      )}
                                                                )
                                                            </>
                                                        )}
                                                    {/* If startdate is null and enddate is null show value  */}
                                                    {lot.contract?.period?.startDate === null && lot.contract?.period?.endDate === null && (
                                                        <>
                                                            {" "}
                                                            {lot.contract?.period?.value}{" "}
                                                            {lot.contract?.period?.value === 1
                                                                ? t(`singleContractPeriod.${lot.contract?.period?.type?.toLocaleLowerCase() || ""}`)
                                                                : t(`multiContractPeriod.${lot.contract?.period?.type?.toLocaleLowerCase() || ""}`)}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Typography>
                                    </>
                                </td>
                            </tr>
                        ) : (
                            <></>
                        )}

                        {/* Enriched contract period */}
                        {lot.contract?.options !== undefined && lot.contract?.options.length > 0 && (
                            <tr>
                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("tenderPage.Options")}:</td>
                                <td>
                                    {lot.contract.options.map((option) => (
                                        <Typography key={option.id}>
                                            {option.indefinite ? (
                                                <>
                                                    {t("tenderPage.indefiniteNumberOfOptionsFrom")} {option.length}{" "}
                                                    {option.length === 1
                                                        ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                        : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                                </>
                                            ) : (
                                                <>
                                                    {option.amount} x {option.length}{" "}
                                                    {option.length === 1
                                                        ? t(`singleContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)
                                                        : t(`multiContractPeriod.${option.period?.toLocaleLowerCase() || ""}`)}
                                                </>
                                            )}
                                        </Typography>
                                    ))}
                                </td>
                            </tr>
                        )}

                        {/* next looptijdeinde */}
                        {lot.next_looptijd_einde !== null && (
                            <tr>
                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("tenderPage.FirstEnddate")}:</td>
                                <td>
                                    <Typography>{moment(lot.next_looptijd_einde).format("L")}</Typography>
                                </td>
                            </tr>
                        )}
                        {/* First enddate with options status */}

                        {/* {lot.looptijd_einde_incl_opties !== null && (
                        <tr>
                            <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("tenderPage.First_enddate_with_options")}:</td>
                            <td>
                                <Typography>{moment(lot.looptijd_einde_incl_opties).format("L")}</Typography>
                            </td>
                        </tr>
                    )} */}
                    </tbody>
                </table>
            </Grid>
            <Grid item xs={12} md={5}>
                {/* enriched data */}
                {lot.contract?.description && (
                    <table>
                        <tr>
                            <Typography>{t("tenderPage.Description")}:</Typography>
                        </tr>
                        <tr>
                            <Typography
                                sx={{ marginTop: "4px", height: "120px", overflow: "auto" }}
                                dangerouslySetInnerHTML={{
                                    __html: lot.contract?.description,
                                }}
                            />
                        </tr>
                    </table>
                )}
            </Grid>
        </Grid>
    );
};

export default SingleLotContract;
