import React from "react";
import { Dialog, Divider, IconButton, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MarkedDocGroup from "./MarkedDocGroup";
import { DocProps } from "./Documents";
import { GetDocumentsList_tender } from "../../../__generated__/GetDocumentsList";
import MarkedDocument from "./MarkedDocument";
import { Disable } from "react-disable";
import moment from "moment";
import DocumentGroup from "./DocumentGroup";
import ListOfDocs from "./ListOfDocs";
import { GetTenderDocsWithPagination_tenderDocuments } from "../../../__generated__/GetTenderDocsWithPagination";
import { Close } from "@mui/icons-material";
import Search from "./Search";

interface Props {
    handleClose(): void;
    open: boolean;
    tender_id: string;
    documentDateArray: DocProps[];
    relevantDocs: GetDocumentsList_tender | null;
    DocsLoading: boolean;
    expanded: string | false;
    handleChange: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    count: number;
    setFolder: React.Dispatch<React.SetStateAction<string>>;
    folder: string;
    handleDirectoryClick: (item: string) => void;
    docsData: GetTenderDocsWithPagination_tenderDocuments;
    showMarkedDocs: () => void;
    showMarked: boolean;
}

const DocumentExpandView: React.FC<Props> = ({
    handleClose,
    open,
    tender_id,
    documentDateArray,
    relevantDocs,
    DocsLoading,
    expanded,
    handleChange,
    setCount,
    count,
    setFolder,
    folder,
    handleDirectoryClick,
    docsData,
    showMarkedDocs,
    showMarked,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            scroll={"paper"}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "85%",
                    height: "80vh",
                    display: "flex",
                    flexDirection: "column",
                },
            }}
            fullWidth
            maxWidth={"lg"}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <Toolbar
                sx={{
                    backgroundColor: "#173357",
                    color: "#ffffff",
                }}
                disableGutters
                style={{ display: "flex", justifyContent: "space-between", paddingLeft: 16, paddingRight: 16 }}
            >
                <div style={{ flexGrow: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {/* Tender title as chat title */}
                    <Typography variant="body2">{t("tenderPage.Documents")}</Typography>
                </div>
                {/* conversation name like private notes, team.. or name of colleague  and closebutton to close modal*/}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {/* <Typography variant="body2">{conversationsTest.find((c) => c.id === conversation)?.name}</Typography> */}
                    <IconButton onClick={handleClose} size="small">
                        <Close htmlColor={"#ffffff"} />
                    </IconButton>
                </div>
            </Toolbar>
            <Search
                marked={showMarked}
                relevantAmount={relevantDocs !== null ? relevantDocs.relevantDocuments.length : 0}
                handleMarkedDocs={showMarkedDocs}
                tenderId={tender_id}
            />
            <div style={{ flexGrow: 1, overflow: "auto" }}>
                {showMarked && (
                    <React.Fragment>
                        <MarkedDocGroup
                            tender_id={tender_id}
                            label={t("tenderPage.MarkedDocs")}
                            amountDocuments={relevantDocs?.relevantDocuments.length}
                            defaultExpanded
                        >
                            {relevantDocs?.relevantDocuments.map(
                                ({
                                    id,
                                    created_at,
                                    filename,
                                    extension,
                                    temporary_link,
                                    // lot
                                }) => {
                                    return (
                                        <MarkedDocument
                                            label={filename}
                                            icon={extension}
                                            key={id}
                                            date={created_at}
                                            link={temporary_link}
                                            file_id={id}
                                            tender_id={tender_id}
                                            // lotNumber={lot?.number}
                                            lotNumber={undefined}
                                            lots={relevantDocs.lots}
                                        />
                                    );
                                }
                            )}
                        </MarkedDocGroup>
                        <Divider />
                    </React.Fragment>
                )}
                <Disable disabled={DocsLoading} disabledOpacity={0.6}>
                    <div style={{ height: "100%", overflow: "auto" }}>
                        {documentDateArray
                            .sort((a, b) => (moment(b.date).isBefore(moment(a.date)) ? -1 : 1))
                            .map((d, i) => {
                                return (
                                    <div key={i}>
                                        <DocumentGroup
                                            loading={DocsLoading}
                                            tender_id={tender_id}
                                            expanded={expanded}
                                            handleChange={handleChange(d.date)}
                                            label={moment(d.date || "").format("LL")}
                                            clicked={d.date}
                                            amountDocuments={parseInt(d.amount)}
                                            defaultExpanded={i === 0}
                                        >
                                            <ListOfDocs
                                                height={"60vh"}
                                                setCount={setCount}
                                                count={count}
                                                loading={DocsLoading}
                                                tender_id={tender_id}
                                                setFolder={setFolder}
                                                folder={folder}
                                                data={docsData}
                                                onDirectoryClick={handleDirectoryClick}
                                                date={d.date}
                                            />
                                        </DocumentGroup>
                                        {i !== documentDateArray.length - 1 && <Divider />}
                                    </div>
                                );
                            })}
                    </div>
                </Disable>
            </div>
        </Dialog>
    );
};

export default DocumentExpandView;
