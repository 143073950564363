import React, { useState } from "react";

import {
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    ClickAwayListener,
    Collapse,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { LocalOffer, Share } from "@mui/icons-material";
import { GetUserSearchRules_currentUser_searches } from "../../../__generated__/GetUserSearchRules";
import MobielViewSearchSummary from "./MobielViewSearchSummary";
import MobileViewDistribution from "./MobileViewDistribution";
import { SearchDistributionInput } from "../../../__generated__/globalTypes";
import { Disable } from "react-disable";

interface Props {
    searchline: GetUserSearchRules_currentUser_searches;
    type: "opportunity" | "tender";
    dist: SearchDistributionInput;
    onOpenShare: (e: any) => void;
}

const MobileViewSearch: React.FC<Props> = ({ searchline, type, dist, onOpenShare }) => {
    const green = "#225E4D";
    const blue = "#173357";
    const [expanded, setExpanded] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    return (
        <Disable disabled={searchline.state === "PENDING_ACCEPTANCE"}>
            <Card
                elevation={0}
                square
                sx={{ m: 1, borderTop: type === "tender" ? `5px solid ${blue}` : type === "opportunity" ? `5px solid ${green}` : undefined }}
            >
                <CardHeader
                    avatar={
                        searchline.participants && (
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <div style={{ marginRight: "4px" }}>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={handleTooltipClose}
                                        open={openTooltip}
                                        placement="right-start"
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={searchline.participants
                                            .slice() // Create a copy of the array to avoid mutating the original
                                            .sort((a, b) => {
                                                if (
                                                    a?.employee.id === searchline.owner?.employee.id &&
                                                    b?.employee.id !== searchline.owner?.employee.id
                                                ) {
                                                    return -1; // 'a' comes before 'b' because 'a' is admin and 'b' is not
                                                } else if (
                                                    a?.employee.id !== searchline.owner?.employee.id &&
                                                    b?.employee.id === searchline.owner?.employee.id
                                                ) {
                                                    return 1; // 'b' comes before 'a' because 'b' is admin and 'a' is not
                                                } else {
                                                    return 0; // Leave the order unchanged
                                                }
                                            })
                                            .map((l, index) => {
                                                if (index === 0) {
                                                    return (
                                                        <div key={l?.id}>
                                                            <span>Gemaakt door:</span>
                                                            <br />
                                                            <span>{l?.employee.name}</span>

                                                            {searchline.participants && searchline.participants.length > 1 && (
                                                                <>
                                                                    <br />
                                                                    <div style={{ marginTop: "4px" }}>
                                                                        <span>Gedeeld met:</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                                return <div key={l?.id}>{l?.employee.name}</div>;
                                            })}
                                    >
                                        <Badge
                                            badgeContent={searchline.participants.length}
                                            sx={{
                                                "& .MuiBadge-badge": {
                                                    right: 5,
                                                    top: 16,
                                                    padding: "0 4px",
                                                    border: `2px solid #fff`,
                                                    fontSize: 10,
                                                    color: "#fff",
                                                    backgroundColor: "#173357",
                                                },
                                            }}
                                        >
                                            <IconButton size="small" onClick={handleTooltipOpen}>
                                                <LocalOffer sx={{ color: searchline.color }} />
                                            </IconButton>
                                        </Badge>
                                    </Tooltip>
                                </div>
                            </ClickAwayListener>
                        )
                    }
                    action={
                        <IconButton aria-label="share" onClick={(e) => onOpenShare(e)}>
                            <Share fontSize="small" />
                        </IconButton>
                    }
                    title={searchline.name}
                />
                <CardContent sx={{ padding: "0px 16px 8px" }}>
                    <Typography variant="body2" color="text.secondary">
                        <MobielViewSearchSummary searchline={searchline} filterValues={searchline.filters_with_labels || null} />
                    </Typography>
                </CardContent>
                <CardActions disableSpacing sx={{ padding: "8px 16px", display: "flex", justifyContent: "flex-end" }}>
                    {searchline.state === "PENDING_ACCEPTANCE" ? (
                        <Typography variant="button" fontSize={12}>
                            Nog niet geaccepteerd
                        </Typography>
                    ) : (
                        <Button size="small" onClick={handleExpandClick}>
                            {expanded ? "Verberg bezorging" : "Toon bezorging"}
                        </Button>
                    )}
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ padding: "0px 16px 8px" }}>
                        <Typography>Bezorging:</Typography>
                        <MobileViewDistribution dist={dist} moment={searchline.moment as any} type={type} />
                    </CardContent>
                </Collapse>
            </Card>
        </Disable>
    );
};

export default MobileViewSearch;
