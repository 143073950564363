import React from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { GET_PROCEDURE_TYPE_COLUMN } from "../../graphql/queryDefForColumns";
import { GetProcedureTypeColumn, GetProcedureTypeColumnVariables } from "../../__generated__/GetProcedureTypeColumn";

interface Props {
    tender_id: string;
}

const ProcedureTypeColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetProcedureTypeColumn, GetProcedureTypeColumnVariables>(GET_PROCEDURE_TYPE_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    return (
        <Typography
            style={{
                maxHeight: "3em",
                lineHeight: "1.5em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                width: "fit-content",
            }}
        >
            {tender.type_procedure}
        </Typography>
    );
};

export default ProcedureTypeColumn;
