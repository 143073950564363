import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, Typography, MenuList, Skeleton } from "@mui/material";
import KnowledgeBaseItem from "./KnowledgeBaseItem";
import KnowledgeBaseDetailPage from "./KnowledgeBaseDetailPage";
import KnowledgeBaseItemLoader from "./KnowledgeBaseItemLoader";
import { useLocation } from "react-router-dom";

// GQL
import { useQuery } from "@apollo/client";
import { getAllArticles } from "../../__generated__/getAllArticles";
import KnowledgeBaseSearchBar from "./KnowledgeBaseSearchBar";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { useFaq } from "../contextProviders/FaqOpenerContext";
import { QUERY_GETALLARTICLES } from "../../graphql/queryDefFaq";
import CatSelector from "./CatSelector";

interface Props {
    a?: number;
}

const KnowledgeBase: React.FC<Props> = (props) => {
    const { view, setView } = useFaq();
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const { data, error, loading } = useQuery<getAllArticles>(QUERY_GETALLARTICLES);
    const location = useLocation();
    const link = location.pathname.split("/");
    const urlSplit =
        link[1].replace(/[^a-zA-Z0-9 ]/g, "") === "profile" ? link[2].replace(/[^a-zA-Z0-9 ]/g, "") : link[1].replace(/[^a-zA-Z0-9 ]/g, "");

    const language = localStorage.getItem("i18nextLng");

    const url =
        urlSplit === "calendar"
            ? "calender"
            : urlSplit === "profile"
            ? link[2].replace(/[^a-zA-Z0-9 ]/g, "")
            : urlSplit === "usersteams"
            ? "usersteams"
            : urlSplit === "searchrules"
            ? "searches"
            : urlSplit === "settings"
            ? "preferences"
            : urlSplit === "log"
            ? "logbook"
            : urlSplit;

    // track navigation items
    const trackClickHelpItem = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // Loader
    if (loading) {
        return (
            <div>
                <Paper
                    elevation={3}
                    sx={{
                        padding: "16px",
                        margin: "16px",
                    }}
                >
                    <Skeleton animation="wave" height={12} width="190px" />
                    <Skeleton animation="wave" height={60} width="100%" style={{ marginTop: 8, marginBottom: 4 }} />
                </Paper>
                <div>
                    <Skeleton animation="wave" height={12} width="190px" style={{ margin: "0px 16px 8px 16px" }} />

                    {/* Map top_articles based on url */}
                    <MenuList>
                        <KnowledgeBaseItemLoader />
                    </MenuList>
                </div>
            </div>
        );
    }
    /**
     * Error occured or no data availables
     */
    if (error || !data || !data.articles) return <p>error loading articles</p>;

    /**
     * show 5 top items
     */
    const filteredData = data.articles
        .filter((article) => article.active)
        .filter((a) => a.top_item === true && a.language.toLowerCase() === language && url === a.module?.replace(/[^a-zA-Z0-9 ]/g, ""))
        .slice(0, 5);

    return (
        <React.Fragment>
            {(() => {
                /**
                 * Switch contains all sector icons, based on given sector_id, show icon
                 */
                switch (true) {
                    ///////////////////////////////////////////////////////////////////////////////////////////////////
                    /////////////////////////////////              FAQ START             //////////////////////////////
                    ///////////////////////////////////////////////////////////////////////////////////////////////////

                    case view === "start":
                        return (
                            <div>
                                <Paper
                                    draggable={false}
                                    elevation={3}
                                    sx={{
                                        padding: "16px",
                                        margin: "16px",
                                    }}
                                >
                                    <Typography variant="h6">{t("faq.Search-help-sentence")}</Typography>
                                    <KnowledgeBaseSearchBar articles={data.articles.filter((article) => article.active)} />
                                </Paper>

                                <CatSelector handleView={setView} />

                                <div>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 600,
                                            margin: "8px 16px 8px 18px",
                                        }}
                                    >
                                        {t("faq.Relevant-articles")}: {t(`faq.${url}`)}
                                    </Typography>

                                    {/* Map top_articles based on url */}
                                    <MenuList>
                                        {filteredData.map((a) => (
                                            <KnowledgeBaseItem
                                                onItemClick={() => {
                                                    setView(a.id);
                                                    trackClickHelpItem({
                                                        category: "Help",
                                                        action: "Click-knowledgebase-item",
                                                        name: a.title,
                                                        value: parseInt(a.id),
                                                    });
                                                }}
                                                key={a.id}
                                                title={a.title}
                                                text={""}
                                            />
                                        ))}
                                    </MenuList>
                                </div>
                            </div>
                        );

                    ///////////////////////////////////////////////////////////////////////////////////////////////////
                    /////////////////////////////////              SINGlE ARTICLE             /////////////////////////
                    ///////////////////////////////////////////////////////////////////////////////////////////////////
                    case Boolean(data.articles.filter((article) => article.active).find((item) => item.id === view)):
                        return <KnowledgeBaseDetailPage article_id={view} />;

                    ///////////////////////////////////////////////////////////////////////////////////////////////////
                    /////////////////////////////////               MODULES             ///////////////////////////////
                    ///////////////////////////////////////////////////////////////////////////////////////////////////
                    case Boolean(data.articles.filter((article) => article.active).find((item) => item.module === view)):
                        return (
                            <div>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        padding: "16px",
                                        margin: "16px",
                                    }}
                                >
                                    <Typography variant="h6">{t("faq.Search-help-sentence")}</Typography>
                                    <KnowledgeBaseSearchBar articles={data.articles.filter((article) => article.active)} />
                                </Paper>

                                <CatSelector handleView={setView} />

                                <div>
                                    <Typography variant="body1" sx={{ fontWeight: 600, margin: "8px 16px 8px 18px" }}>
                                        {t("faq.All_articles")}: {t(`faq.${view}`)}
                                    </Typography>

                                    {/* Map articles based on language and module */}
                                    <MenuList>
                                        {data.articles
                                            .filter((article) => article.active)
                                            // show support_content only
                                            .filter((a) => a.category === "support_content")
                                            .filter((a) => a.language.toLowerCase() === language && view === a.module)
                                            .map((a) => (
                                                <KnowledgeBaseItem
                                                    onItemClick={() => {
                                                        setView(a.id);
                                                        trackClickHelpItem({
                                                            category: "Help",
                                                            action: "Click-knowledgebase-item",
                                                            name: a.title,
                                                            value: parseInt(a.id),
                                                        });
                                                    }}
                                                    key={a.id}
                                                    title={a.title}
                                                    text={""}
                                                />
                                            ))}
                                    </MenuList>
                                </div>
                            </div>
                        );

                    ///////////////////////////////////////////////////////////////////////////////////////////////////
                    /////////////////////////////////               CATEGORY             ///////////////////////////////
                    ///////////////////////////////////////////////////////////////////////////////////////////////////
                    case Boolean(data.articles.filter((article) => article.active).find((item) => item.category === view)):
                        return (
                            <div>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        padding: "16px",
                                        margin: "16px",
                                    }}
                                >
                                    <Typography variant="h6">{t("faq.Search-help-sentence")}</Typography>
                                    <KnowledgeBaseSearchBar articles={data.articles.filter((article) => article.active)} />
                                </Paper>

                                <CatSelector handleView={setView} />

                                <div>
                                    <Typography variant="body1" sx={{ fontWeight: 600, margin: "8px 16px 8px 18px" }}>
                                        {t("faq.All_articles")}: {t(`faq.${view}`)}
                                    </Typography>

                                    {/* Map articles based on language and module */}
                                    <MenuList>
                                        {data.articles
                                            .filter((article) => article.active)
                                            // show support_content only
                                            .filter((a) => a.language.toLowerCase() === language && view === a.category)
                                            .sort((a, b) => parseInt(b.id) - parseInt(a.id))
                                            .map((a) => (
                                                <KnowledgeBaseItem
                                                    onItemClick={() => {
                                                        setView(a.id);
                                                        trackClickHelpItem({
                                                            category: "Help",
                                                            action: "Click-knowledgebase-item",
                                                            name: a.title,
                                                            value: parseInt(a.id),
                                                        });
                                                    }}
                                                    key={a.id}
                                                    title={a.title}
                                                    text={""}
                                                />
                                            ))}
                                    </MenuList>
                                </div>
                            </div>
                        );

                    default:
                        return (
                            <div>
                                <Paper
                                    draggable={false}
                                    elevation={3}
                                    sx={{
                                        padding: "16px",
                                        margin: "16px",
                                    }}
                                >
                                    <Typography variant="h6">{t("faq.Search-help-sentence")}</Typography>
                                    <KnowledgeBaseSearchBar articles={data.articles.filter((article) => article.active)} />
                                </Paper>

                                <CatSelector handleView={setView} />

                                <div>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 600,
                                            margin: "8px 16px 8px 18px",
                                        }}
                                    >
                                        {t("faq.All_articles")}: {t(`faq.${view}`)}
                                    </Typography>

                                    {/* Map articles based on language and module */}
                                    <MenuList>
                                        {data.articles
                                            .filter((article) => article.active)
                                            // show support_content only
                                            .filter((a) => a.language.toLowerCase() === language && view === a.category)
                                            .map((a) => (
                                                <KnowledgeBaseItem
                                                    onItemClick={() => {
                                                        setView(a.id);
                                                        trackClickHelpItem({
                                                            category: "Help",
                                                            action: "Click-knowledgebase-item",
                                                            name: a.title,
                                                            value: parseInt(a.id),
                                                        });
                                                    }}
                                                    key={a.id}
                                                    title={a.title}
                                                    text={""}
                                                />
                                            ))}
                                    </MenuList>
                                </div>
                            </div>
                        );
                }
            })()}
        </React.Fragment>
    );
};

export default KnowledgeBase;
