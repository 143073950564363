import React, { useState, useEffect } from "react";

// Material UI components
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { List } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaviconBox from "../FaviconBox";
import CountryFlagBox from "../boxes/CountryFlagBox";
import StatusDot from "../StatusDot";
import { sortCountry } from "../TenderSearchComponent/TenderSearch";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SectorIconLoader from "../boxes/SectorIconLoader";
interface Props {
    allowReset: boolean;
    values: string[];
    onChange(values: string[]): void;
    options: DropdownOption[];
    allowSearch?: boolean;
    setOpenFilter(open: string | boolean): void;
    title: string;
}
export type DropdownOption = { key: string; label: string; id: string };

const FilterItemStringVersion: React.FC<Props> = ({ allowReset, values, onChange, options, allowSearch, setOpenFilter, title }) => {
    const [filter, setFilter] = useState<string>("");
    // Local state version of currently selected items
    const [newOptions, setNewOptions] = useState<string[]>(values);
    // Local state version of all options
    const [allOptions, setAllOptions] = useState<DropdownOption[]>(options);
    const { t } = useTranslation();
    const yellow = "#F57117";

    /**
     * When new values/options as prop are passed, update the internal state
     */
    useEffect(() => {
        setNewOptions(values);
        setAllOptions(options);
    }, [options, values]);

    /**
     * on click save button -> set selected items, reset filter to empty string and close selected filter
     */
    const handleClose = () => {
        onChange(newOptions);
        setFilter("");
        setOpenFilter(false);
    };

    /**
     * When a option is clicked, toggle the active state in the local state
     */
    const handleOptionClick = React.useCallback((key: string) => {
        setNewOptions((prev) => {
            if (prev.includes(key)) {
                return prev.filter((o) => o !== key);
            } else {
                return [...prev, key];
            }
        });
    }, []);

    /**
     * Only when search is allowed, we filter the options before rendering in the list
     */
    const filteredOptions =
        allowSearch !== true
            ? allOptions
            : allOptions.filter((option) => {
                  if (!filter) {
                      return true;
                  } else {
                      return option.label.toLowerCase().includes(filter.toLowerCase()) || option.id.toString().includes(filter);
                  }
              });

    return (
        <React.Fragment>
            {/* Clicked filter opens and shows a searchbox if true and items inside selected filter.
                User can search inside this filter, selected items wil be visible in closed view when user clicks on save.
            */}
            {allowSearch && (
                <div style={{ padding: 16 }}>
                    <TextField
                        placeholder="Zoeken..."
                        variant="outlined"
                        size="small"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        autoFocus
                        fullWidth
                        inputProps={{ sx: { fontSize: "14px", color: "#000000" } }}
                    />
                </div>
            )}
            <div style={{ overflow: "auto", flex: 1 }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <List>
                            {/* map over filtered items.
                                Options will be filtered when user starts typing in searchfield
                            */}
                            {filteredOptions
                                //! Sort array of options on country => NL will be the first element in the array
                                ?.sort((a, b) => sortCountry.indexOf(a.key) - sortCountry.indexOf(b.key))
                                .map((item) => {
                                    return (
                                        <React.Fragment key={item.key}>
                                            {/*
                                             * // TODO: style to classes to create responsive design
                                             */}
                                            <ListItem
                                                // ! role = undefined -> onClick function now works  alse on checkboxclick
                                                role={undefined}
                                                style={{ paddingLeft: 50 }}
                                                button
                                                onClick={() => handleOptionClick(item.id as string)}
                                            >
                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                    <Checkbox
                                                        style={{ marginRight: 16 }}
                                                        sx={{
                                                            "& .Mui=checked": {
                                                                color: "#000000",
                                                            },
                                                        }}
                                                        checked={newOptions.findIndex((el) => el === item.id) !== -1}
                                                        edge="start"
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                {/* If favicon is not undefined -> show faviconbox or name if no favicon is available */}
                                                {title === "contractingAuthorities" && (
                                                    <div>
                                                        <FaviconBox favicon="" name={item.key} color={yellow} marginTop={0} />
                                                    </div>
                                                )}
                                                {title === "status" && (
                                                    <div style={{ marginRight: 6, marginLeft: -4 }}>
                                                        <StatusDot type={item.id.toString()} placement={"right"} />
                                                    </div>
                                                )}
                                                {/* if countryFlag is not undefined -> show countryFlag */}
                                                {title === "country" && (
                                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: -2 }}>
                                                        <CountryFlagBox code={item.key} />
                                                    </div>
                                                )}
                                                {/* if countryFlag is not undefined -> show countryFlag */}
                                                {title === "ca_country" && (
                                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: -2 }}>
                                                        <CountryFlagBox code={item.key} />
                                                    </div>
                                                )}
                                                {/* show label for searchrules */}
                                                {title === "searches" && (
                                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: 6 }}>
                                                        <LocalOfferIcon style={{ color: item.key }} />
                                                    </div>
                                                )}
                                                {/* show label for searchrules */}
                                                {title === "subSectors" && (
                                                    <div style={{ marginLeft: -4, marginRight: 6, marginTop: 6 }}>
                                                        <SectorIconLoader sector_id={item.id.toString()} />
                                                    </div>
                                                )}
                                                <ListItemText primary={<Typography sx={{ color: "#000000" }}>{item.label}</Typography>} />
                                            </ListItem>
                                        </React.Fragment>
                                    );
                                })}
                        </List>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", margin: "24px" }}>
                {/* TODO: reset all filters  */}
                {allowReset && newOptions.length > 0 && (
                    <Button sx={{ color: "#000000" }} onClick={() => setNewOptions([])} variant="text">
                        {t("filter.reset")}
                    </Button>
                )}
                <Button style={{ color: "white" }} variant="contained" color="primary" onClick={handleClose}>
                    {t("filter.save")}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default FilterItemStringVersion;
