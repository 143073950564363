import React, { useState } from "react";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { useMatomo } from "@datapunt/matomo-tracker-react";

// import update from "immutability-helper";

// Import components
// import FilterDropdown from "../../components/input/FilterDropdown";
import FilterDrawer from "../../components/FilterDrawer/FilterDrawer";
// import PeriodFilter, { DateRange } from "../../components/input/PeriodFilter";
import {
    // GridInput,
    OrganizationsFilterInput,
} from "../../__generated__/globalTypes";

// Material UI components
import TuneIcon from "@mui/icons-material/Tune";
import { useLocation } from "react-router-dom";
import { Disable } from "react-disable";
import TopFilterBar, { DateRange } from "../../components/FilterDrawer/TopFilterBar";
import { useTranslation } from "react-i18next";
import OrgSearchBar from "./OrgSeachBar";
import { FilterOrganizationOverview_filterTenderers_filters } from "../../__generated__/FilterOrganizationOverview";
import { useColumnSetter } from "../../components/contextProviders/ColumnContext";
// import RowExportButton from "../../components/tableComponents/RowExportButton";
import LikeOrDislikeSelectedOrgs from "../../components/LikeOrDislikeSelectedOrgs";

import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import ColumnChanger from "../../components/tableComponents/ColumnChanger";
import { allOrgColumns } from "./allOrganizations/OrganizationTable";
import { starredOrgColumns } from "./starredOrganizations/StarredOrganizationTable";
import { caStarredColumns } from "../contractingAuthoritiesModule/starredPage/CAstarredTable";
import { allCaColumns } from "../contractingAuthoritiesModule/allCApage/CAtable";
import MatomoEvent from "../../models/MatomoEvent";
import LikeOrdislikeSelectedCAS from "../../components/LikeOrDislikeSelectedCAS";

interface Props {
    /**
     * User inpot in searchbox
     */
    query: string;
    /**
     * set query as searchparam
     */
    setQuery: (query: string) => void;
    /**
     * reset page to chosen page
     */
    resetPage: () => void;
    /**
     * filter input
     */
    searchInput: OrganizationsFilterInput;
    /**
     * @param searchInput onchange filterinput
     */
    onChange(searchInput: OrganizationsFilterInput): void;
    /**
     * start search with active filter(s)
     */
    startSearch(): void;
    /**
     * @param view set view to selected viewtype
     */
    onViewClick?(view: number): void;
    /**
     * Chosen view, listview is default
     */
    activeView?: number;
    /**
     * Data to fill filtervalues from backend
     */
    filterData?: FilterOrganizationOverview_filterTenderers_filters[] | null;
    /**
     * loading state
     */
    loading: boolean;
    /**
     * Resetbutton to deactivate all filters
     */
    resetFilters: () => void;
}

const OrganizationSearch: React.FC<Props> = ({
    filterData,
    searchInput,
    onChange,
    startSearch,
    query,
    setQuery,
    resetPage,
    loading,
    resetFilters,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    // Check if user is on worklist page
    const orgstarred = pathname === "/organizations/starred";
    const org = pathname === "/organizations";
    const castarred = pathname === "/contracting-authorities/starred";
    const ca = pathname === "/contracting-authorities";
    const organizationModule = org || orgstarred;
    const caModule = ca || castarred;
    // Check if device is mobile or not
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    // open filterdrawer
    const [open, setOpen] = useState<boolean>(false);
    const { selectedRows } = useColumnSetter();
    const [lastUpdate, setLastUpdate] = useState<DateRange>({ from: null, to: null });

    const { trackSiteSearch, trackEvent } = useMatomo();

    const cat = pathname.includes("organizations") ? "Organization" : "Ca";

    // Track event
    const trackSearch = (q: string) => {
        trackSiteSearch({ keyword: `${q}`, category: `${cat}` });
    };

    //track event all filter button
    const trackAllFilterBtn = () => {
        if (organizationModule) {
            trackEvent({ category: "Organizations", action: "Click-show-all—filters" } as MatomoEvent);
        }
        if (caModule) {
            trackEvent({ category: "Ca", action: "Click-show-all—filters" } as MatomoEvent);
        }
    };

    const grey = "#707070";
    // const starredmp = pathname === "/organizations/starred";
    // const starredCA = pathname === "/contracting-authorities/starred";

    // const [enddateContract, setEnddateContract] = useState<DateRange>({ from: null, to: null });
    const closeDrawer = () => {
        setOpen(false);
    };

    return (
        <Disable disabled={loading} disabledOpacity={1}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                    <OrgSearchBar
                        query={query}
                        onChangeQuery={(q) => {
                            setQuery(q);
                            resetPage();
                            if (q.length > 2) {
                                trackSearch(q);
                            }
                        }}
                        onSearch={() => {
                            startSearch();
                        }}
                    />
                </div>
            </div>

            <div style={{ height: 20 }} />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    {noMobile && (
                        // Bar with topfilters and active filters
                        <TopFilterBar
                            pageview="organizations"
                            filterData={filterData ? filterData : []}
                            resetPage={resetPage}
                            onChange={onChange}
                            startSearch={startSearch}
                            searchInput={searchInput}
                            setLastUpdate={setLastUpdate}
                            lastUpdate={lastUpdate}
                        />
                    )}
                    {/* Button to open all filters drawer */}
                    <Button
                        startIcon={<TuneIcon />}
                        size="small"
                        sx={{ color: "#000000" }}
                        onClick={() => {
                            setOpen(true);
                            trackAllFilterBtn();
                        }}
                        style={{ marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0 }}
                    >
                        {t("allFilters")}
                    </Button>
                    {!_.isEmpty(searchInput) && (
                        <Button
                            startIcon={<CloseIcon />}
                            size="small"
                            onClick={() => {
                                resetFilters();
                                setLastUpdate({ from: null, to: null });
                            }}
                            style={{ color: "grey", marginBottom: noMobile ? 8 : 0, marginRight: noMobile ? 8 : 0, marginLeft: noMobile ? 8 : 0 }}
                        >
                            {t("filter.reset")}
                        </Button>
                    )}
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {selectedRows.length > 0 && organizationModule && (
                        <React.Fragment>
                            {/* Mark with star */}
                            <LikeOrDislikeSelectedOrgs color={grey} selection={selectedRows} buttonSize={"small"} />
                        </React.Fragment>
                    )}
                    {selectedRows.length > 0 && caModule && (
                        <React.Fragment>
                            {/* Mark with star */}
                            <LikeOrdislikeSelectedCAS color={grey} selection={selectedRows} buttonSize={"small"} />
                        </React.Fragment>
                    )}
                    {/* Export selected rows */}
                    {/* {starredmp && <RowExportButton columnExport={GridInput.STARRED_MARKET_PARTIES} />}
                    {starredCA && <RowExportButton columnExport={GridInput.STARRED_CONTRACTING_AUTHORITIES} />} */}

                    {/* View/hide columns in table */}
                    {org && noMobile && <ColumnChanger columns={allOrgColumns} location={"/organizations"} />}
                    {orgstarred && noMobile && <ColumnChanger columns={starredOrgColumns} location={"/organizations/starred"} />}
                    {ca && noMobile && <ColumnChanger columns={allCaColumns} location={"/contracting-authorities"} />}
                    {castarred && noMobile && <ColumnChanger columns={caStarredColumns} location={"/contracting-authorities/starred"} />}
                </div>
            </div>

            <FilterDrawer
                loading={loading}
                open={open}
                onClose={closeDrawer}
                searchInput={searchInput}
                onChange={onChange}
                startSearch={startSearch}
                // check url to show correct color
                location={organizationModule ? "tenderers" : "cas"}
                filterData={filterData ? filterData : []}
                lastUpdate={lastUpdate}
                setLastUpdate={setLastUpdate}
            />
        </Disable>
    );
};

export default OrganizationSearch;
