import { ApolloError, useMutation, useQuery } from "@apollo/client";
import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from "react";
import {
    FINISH_POLL,
    GET_SINGLE_POLL_TENDENZ,
    SUBMIT_TENDENZ_NEXT_QUESTION,
    SUBMIT_TENDENZ_POLL_ANSWER,
    TENDENZ_ACTIVE_QUESTION,
} from "../../../graphql/tendenzDefinitions";
import { TendenZ_poll, TendenZ_poll_TendenzPoll_questions } from "../../../__generated__/TendenZ_poll";
import { submitAnswerTendenzQuestion, submitAnswerTendenzQuestionVariables } from "../../../__generated__/submitAnswerTendenzQuestion";
import { toast } from "react-toastify";
import { tendenZNextQuestion, tendenZNextQuestionVariables } from "../../../__generated__/tendenZNextQuestion";
import { usePublicSocket } from "../../../utils/websocketChannels";
import { tendenzActiveQuestion } from "../../../__generated__/tendenzActiveQuestion";
import { tendenZFinishPoll, tendenZFinishPollVariables } from "../../../__generated__/tendenZFinishPoll";

export type Answer = {
    id: number;
    answer: string;
};

export type Question = {
    id: number;
    question: string;
    answers: Answer[];
};

type PollContextType = {
    sendingAnswer: (questionId: string, answerId: string, quizId: string) => void;
    navToNextQuestion: (nextID: number) => void;
    finishCurrentPoll: (pollID: number) => void;
    waiting: boolean;
    timer: number;
    chosenAnswerID: number | null;
    pollArray: TendenZ_poll_TendenzPoll_questions[];
    setPollarray: React.Dispatch<React.SetStateAction<TendenZ_poll_TendenzPoll_questions[]>>;
    loadingQuery: boolean;
    loadingNext: boolean;
    errorQuery: ApolloError | undefined;
    loadingMutation: boolean;
    errorNext: ApolloError | undefined;
    usersAnswered: number;
    getNextQuestionId: (pollArray: TendenZ_poll_TendenzPoll_questions[], currentQuestionId: number) => number;
    pollStarted: boolean;
    startPoll: () => void;
    currentPollId: number;
    currentQuestion: tendenzActiveQuestion | undefined;
    hasAnswered: (questionID: string) => any;
    getLocalStorageAnswers: () => any;
    setWaiting: React.Dispatch<React.SetStateAction<boolean>>;
    setChosenAnswerID: React.Dispatch<React.SetStateAction<number | null>>;
    endOfPoll: boolean;
};

const PollContext = createContext<PollContextType | undefined>(undefined);

export const PollProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [timer, setTimer] = useState(20);
    const [waiting, setWaiting] = useState(false);
    const [pollArray, setPollarray] = useState<TendenZ_poll_TendenzPoll_questions[]>([]);
    const [currentQuestionId, setCurrentQuestionId] = useState<number>(0); // Initialize with the first question's id
    const [chosenAnswerID, setChosenAnswerID] = useState<number | null>(null);
    const [usersAnswered, setUsersAnswered] = useState(0);
    const [pollStarted, setPollStarted] = useState<boolean>(false);
    const [endOfPoll, setEndOfPoll] = useState<boolean>(false);
    const [currentPollId, setCurrentPollId] = useState<number>(0);

    // current UID
    const userID = localStorage.getItem("user_id");

    // Helper function to get the answers from local storage
    const getLocalStorageAnswers = () => {
        const answers = localStorage.getItem("userAnswersPoll");
        return answers ? JSON.parse(answers) : [];
    };

    // Helper function to save answers to local storage
    const saveAnswerToLocalStorage = (questionID: string, answerID: string) => {
        const currentAnswers = getLocalStorageAnswers();
        const updatedAnswers = [...currentAnswers, { questionID, answerID }];
        localStorage.setItem("userAnswersPoll", JSON.stringify(updatedAnswers));
    };

    // Helper function to check if the question has been answered
    const hasAnswered = useCallback((questionID: string) => {
        const answers = localStorage.getItem("userAnswersPoll");
        const parsedAnswers = answers ? JSON.parse(answers) : [];
        return parsedAnswers.some((answer: { questionID: string }) => answer.questionID === questionID);
    }, []);

    /**
     * Query to fetch active question (used for users that reconnect/refresh the page)
     */
    const {
        data: currentQuestion,
        refetch,
        loading: loadingQuery,
        error: errorQuery,
    } = useQuery<tendenzActiveQuestion>(TENDENZ_ACTIVE_QUESTION, {
        // pollInterval: 5000,
        onCompleted: (data) => {
            if (data && data.activeTendenzQuestion) {
                setPollStarted(true);
                const QID = data.activeTendenzQuestion.id?.toString();

                if (hasAnswered(QID as string)) {
                    const savedAnswer = getLocalStorageAnswers().find((answer: { questionID: string }) => answer.questionID === (QID as string));
                    if (savedAnswer) {
                        setChosenAnswerID(parseInt(savedAnswer.answerID));
                        setWaiting(true);
                    }
                }
            }
        },
    });

    useEffect(() => {
        refetch();
    }, [refetch, currentQuestionId]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                // Refetch the query when the page becomes visible
                refetch().then((data) => {
                    if (data && data.data.activeTendenzQuestion) {
                        setPollStarted(true);
                        const QID = data.data.activeTendenzQuestion.id?.toString();

                        if (hasAnswered(QID as string)) {
                            const savedAnswer = getLocalStorageAnswers().find(
                                (answer: { questionID: string }) => answer.questionID === (QID as string)
                            );
                            if (savedAnswer) {
                                setChosenAnswerID(parseInt(savedAnswer.answerID));
                                setWaiting(true);
                            }
                        }
                    }
                });
            }
        };

        // Add event listener for visibility change
        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [hasAnswered, refetch]);

    /**
     * Query to fetch active poll
     */
    useQuery<TendenZ_poll>(GET_SINGLE_POLL_TENDENZ, {
        onCompleted: (data) => {
            if (data && data.TendenzPoll && data.TendenzPoll.questions) {
                setPollarray(data.TendenzPoll.questions as TendenZ_poll_TendenzPoll_questions[]);
                setCurrentPollId(data.TendenzPoll.id as number);
            }
        },
    });

    /**
     * Mutation to navigate to next question
     */
    const [nextQuestion, { loading: loadingNext, error: errorNext }] = useMutation<tendenZNextQuestion, tendenZNextQuestionVariables>(
        SUBMIT_TENDENZ_NEXT_QUESTION
    );

    /**
     * handler to navigate to next question
     * @param nextID id of next question
     * websocket event will be broadcasted to users
     */
    const navToNextQuestion = async (nextID: number) => {
        if (errorMutation) {
            toast.error("error");
        }
        try {
            nextQuestion({
                variables: {
                    question_id: nextID.toString(),
                    poll_id: "1",
                },
            });
        } catch (e) {
            toast.error("error");
        }
    };

    /**
     * Mutation to submit chosen answer
     */
    const [sendAnswer, { loading: loadingMutation, error: errorMutation }] = useMutation<
        submitAnswerTendenzQuestion,
        submitAnswerTendenzQuestionVariables
    >(SUBMIT_TENDENZ_POLL_ANSWER);

    /**
     * Send chosen answer to backend
     * @param question_id
     * @param answer_id
     * @param quizId
     */
    const sendingAnswer = async (question_id: string, answer_id: string, quizId: string) => {
        setChosenAnswerID(parseInt(answer_id));
        setWaiting(true);
        if (errorMutation) {
            toast.error("error");
        }
        try {
            sendAnswer({
                variables: {
                    question_id: question_id,
                    answer_id: answer_id,
                    poll_id: quizId,
                },
                onCompleted: () => {
                    saveAnswerToLocalStorage(question_id, answer_id);
                },
            });
        } catch (e) {
            toast.error("error");
        }
    };

    /**
     * Mutation to navigate to next question
     */
    const [finishPoll] = useMutation<tendenZFinishPoll, tendenZFinishPollVariables>(FINISH_POLL);

    /**
     * Handler to navigate to next question
     * @param nextID id of next question
     * Websocket event will be broadcasted to users
     */
    const finishCurrentPoll = async (pollID: number) => {
        if (errorMutation) {
            toast.error("error");
        }
        try {
            finishPoll({
                variables: {
                    poll_id: pollID.toString(),
                },
                onCompleted: () => {
                    setPollStarted(false);
                    setEndOfPoll(true);
                },
            });
        } catch (e) {
            toast.error("error");
        }
    };

    /**
     * Navigate to first question of active poll
     */
    const startPoll = () => {
        /** 1 is the id of the first question to show to users */
        navToNextQuestion(1);
    };

    /**
     * Whenever new notifications are published on the socket channel,
     * fetch notifications
     */
    usePublicSocket(`poll.1`, ".question.answered", (e) => {
        // Update usersAnswered with the event data
        if (e && e.usersAnswered !== undefined) {
            setUsersAnswered(e.usersAnswered);
        }
    });

    /**
     * Whenever new notifications are published on the socket channel,
     * fetch notifications
     */
    usePublicSocket(`poll.1`, ".next.question", (event) => {
        if (event && event.questionId !== undefined) {
            setPollStarted(true);
            setCurrentQuestionId(parseInt(event.questionId)); // Update the current question ID with the one from the event
        }
    });

    /**
     * Whenever new notifications are published on the socket channel,
     * fetch notifications
     */
    usePublicSocket(`poll.1`, ".poll.finished", (event) => {
        if (event && event.pollId !== undefined) {
            setPollStarted(false);
            setEndOfPoll(true);
        }
    });

    /**
     * Helper to find next question id
     * @param pollArray array of questions in active poll
     * @param currentQuestionId id of current question
     * @returns id of next question
     */
    const getNextQuestionId = (pollArray: TendenZ_poll_TendenzPoll_questions[], currentQuestionId: number): number => {
        // Find the index of the current question
        const currentIndex = pollArray.findIndex((question) => question.id === currentQuestionId);

        // If current question is not found or pollArray is empty, return null
        if (currentIndex === -1 || pollArray.length === 0) {
            return 0;
        }

        // Calculate the index of the next question
        const nextIndex = currentIndex + 1;

        // If there is a next question, return its id
        if (nextIndex < pollArray.length) {
            return pollArray[nextIndex].id as number;
        }

        // If the current question is the last one, return null or handle accordingly
        // return pollArray[0].id as number; //loop back to the first question
        return -1;
    };

    /**
     * Set waiting to false when currentQuestionId changes
     */
    useEffect(() => {
        // Reset timer to 30 seconds and waiting state
        setTimer(20);
        setWaiting(false);

        // Start the countdown timer
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(countdown); // Clear the interval when timer reaches 0
                    if (userID === "10291") {
                        setWaiting(true); // Set waiting to true when timer hits 0
                    }
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        // Cleanup function to clear the interval
        return () => clearInterval(countdown); // Clear the interval when the component unmounts or when the question changes
    }, [currentQuestionId, userID]); // Dependencies array to trigger effect when currentQuestionId changes

    return (
        <PollContext.Provider
            value={{
                waiting,
                setWaiting,
                sendingAnswer,
                timer,
                chosenAnswerID,
                pollArray,
                setPollarray,
                loadingQuery,
                errorQuery,
                errorNext,
                loadingNext,
                navToNextQuestion,
                usersAnswered,
                getNextQuestionId,
                loadingMutation,
                startPoll,
                pollStarted,
                currentPollId,
                currentQuestion,
                hasAnswered,
                getLocalStorageAnswers,
                setChosenAnswerID,
                finishCurrentPoll,
                endOfPoll,
            }}
        >
            {children}
        </PollContext.Provider>
    );
};

export const usePoll = () => {
    const context = useContext(PollContext);
    if (context === undefined) {
        throw new Error("usePoll must be used within a PollProvider");
    }
    return context;
};
