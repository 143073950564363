import React, { useState } from "react";
import WidgetBox from "../WidgetBox";
import ScopeWithoutLots from "./ScopeWithoutLots";
// import ScopeWithLots from "./ScopeWithLots";
import { useTranslation } from "react-i18next";
//GQL

// custom components
import MenuItem from "@mui/material/MenuItem";
import WidgetBoxMenu from "../WidgetBoxMenu";
import {
    GetScopeValuesOfCurrentTender_tender_scope,
    GetScopeValuesOfCurrentTender_tender_lots,
} from "../../__generated__/GetScopeValuesOfCurrentTender";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    scope: GetScopeValuesOfCurrentTender_tender_scope;
    lots: GetScopeValuesOfCurrentTender_tender_lots[];
    tender_id: string;
    elevation?: number;
}
const ScopeWidget: React.FC<Props> = ({ scope, lots, tender_id, elevation }) => {
    const { t } = useTranslation();
    const [showSource, setShowSource] = useState(false);

    const { trackEvent } = useMatomo();

    // Track event source click
    const trackSource = (event: MatomoEvent) => {
        trackEvent(event);
    };

    //submenu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // close submenu, set time out, change showsource to opposite
    // with sleep 100 user doesnt notice the change in button text before closing submenu.
    const handleSource = async () => {
        setAnchorEl(null);
        await sleep(100);
        setShowSource((s) => !s);
    };
    return (
        <React.Fragment>
            {/* start widget */}
            <WidgetBox
                elevation={elevation}
                header={t("tenderPage.Scope")}
                highlight="blue"
                padding={0}
                actions={
                    // TODO: create function to copy, add function to star all organizations
                    <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                        <MenuItem
                            onClick={() => {
                                handleSource();
                                trackSource({ category: "Tender", action: "Click-source-scope" });
                            }}
                        >
                            {showSource ? t("HideSource") : t("ShowSource")}
                        </MenuItem>
                    </WidgetBoxMenu>
                }
            >
                <React.Fragment>
                    <ScopeWithoutLots scope={scope} showSource={showSource} tender_id={tender_id} />
                </React.Fragment>
            </WidgetBox>
        </React.Fragment>
    );
};

export default ScopeWidget;
