import React from "react";
import { Typography, IconButton, Tooltip, Divider } from "@mui/material";
import WidgetBox from "../WidgetBox";
import NewOpportunity from "./NewOpportunity";
import { Remove } from "@mui/icons-material";
import {
    GetopportunityPagePersonalOpportunities,
    GetopportunityPagePersonalOpportunitiesVariables,
} from "../../__generated__/GetopportunityPagePersonalOpportunities";
import { useQuery } from "@apollo/client";
import CreateOpportunityOnFrag from "./CreateOpportunityOnFrag";
import { useOppOpener } from "../contextProviders/CreateNewOpportunityContext";
import { QUERY_USER_OPPS_OF_PAGE } from "../../graphql/queryDefinitions";

interface Props {
    active: number;
    page_id: string;
    onClose: () => void;
}
const NewOppOnpageSelect: React.FC<Props> = ({ active, page_id, onClose }) => {
    // state to show form to create a opportunity
    const { newOpp } = useOppOpener();
    const { data } = useQuery<GetopportunityPagePersonalOpportunities, GetopportunityPagePersonalOpportunitiesVariables>(QUERY_USER_OPPS_OF_PAGE, {
        variables: { page_id: parseInt(page_id) },
        fetchPolicy: "network-only",
    });

    return (
        <React.Fragment>
            <WidgetBox className="ContractingAuthorityBox" header={""} highlight="green" padding={0}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 10 }}>
                    <Typography variant="h6">Pagina {active}</Typography>
                    <Tooltip placement="top" title="Sluit venster">
                        <IconButton size="small" onClick={() => onClose()}>
                            <Remove />
                        </IconButton>
                    </Tooltip>
                </div>

                <React.Fragment>
                    {data?.opportunityPagePersonalOpportunities
                        .filter((i) => i.comment !== "")
                        .filter((j) => j.comment !== null)
                        .map((o) => {
                            return (
                                <React.Fragment key={o.id}>
                                    <CreateOpportunityOnFrag opportunity={o} page_id={page_id} />
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                </React.Fragment>

                {!data?.opportunityPagePersonalOpportunities.find((page: any) => page.page === active) && (
                    <React.Fragment>{newOpp && <NewOpportunity showHideBtn={false} page_id={page_id} page_number={active} />}</React.Fragment>
                )}
            </WidgetBox>
        </React.Fragment>
    );
};

export default NewOppOnpageSelect;
