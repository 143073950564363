import Echo from "laravel-echo";
import Pusher from "pusher-js";
declare global {
    interface Window {
        Echo: Echo;
        Pusher: any;
    }
}
// Ensure Pusher is available globally
(window as any).Pusher = Pusher;

const authToken = localStorage.getItem("access_token");

const ClientEcho = new Echo({
    broadcaster: "reverb",
    key: process.env.REACT_APP_REVERB_APP_KEY,
    wsHost: process.env.REACT_APP_REVERB_HOST,
    wsPort: process.env.REACT_APP_REVERB_PORT,
    wssPort: process.env.REACT_APP_REVERB_PORT,
    forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
    authEndpoint: "https://api.tender.guide/api/v1/broadcasting/auth",
    auth: {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    },
});

export default ClientEcho;
