import React from "react";
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, List, Typography } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateDownloadBtn from "./DateDownloadBtn";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";

const Accordion = withStyles(MuiAccordion, {
    root: {
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

interface DocumentGroupProps {
    label: any;
    amountDocuments: number | undefined;
    defaultExpanded?: boolean;
    expanded: string | false;
    clicked: string;
    loading: boolean;
    handleChange: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    tender_id: string;
}

const DocumentGroup: React.FC<DocumentGroupProps> = ({
    label,
    amountDocuments,
    children,
    defaultExpanded = false,
    expanded,
    handleChange,
    clicked,
    tender_id,
}) => {
    const { t } = useTranslation();

    return (
        <Accordion
            expanded={expanded === clicked}
            onChange={handleChange}
            defaultExpanded={defaultExpanded}
            TransitionProps={{
                timeout: 100,
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    {/* Download button with data as label */}
                    <DateDownloadBtn
                        text={t("Download_date")}
                        label={label}
                        onDownload={(e: any) => {
                            // Stop click on listitem to prevent document-viewer to initiate
                            e.stopPropagation();
                            const element = document.createElement("a");
                            element.setAttribute("download", `${clicked}`);
                            element.setAttribute("href", `${process.env.REACT_APP_API_ROOT}/tenders/${tender_id}/downloadDateZip/${clicked}`);
                            element.style.display = "none";
                            document.body.appendChild(element);
                            element.click();
                            document.body.removeChild(element);
                        }}
                    />
                    {/* amount of files  */}
                    <Typography color="textSecondary">
                        {amountDocuments} {amountDocuments === 1 ? "document" : "documenten"}
                    </Typography>
                </div>
            </AccordionSummary>
            {/* Files/folders inside date */}
            <AccordionDetails style={{ paddingTop: 0, paddingBottom: 0 }}>
                <List id="doclisting" dense style={{ width: "100%", padding: 0, marginBottom: 10 }}>
                    {children}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

export default DocumentGroup;
