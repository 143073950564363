import React from "react";
import PollContainer from "./PollContainer";
import { PollProvider } from "./PollContext";

interface Props {
    a?: number;
}

const PollView: React.FC<Props> = (props) => {
    return (
        <PollProvider>
            <PollContainer />
        </PollProvider>
    );
};

export default PollView;
