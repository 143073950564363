import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import React from "react";
import { QUERY_SINGLE_PERSONAL_COUNT } from "../../graphql/opportunityQueries/queryDefinitions";
import { GetSingleOppPersonalCount, GetSingleOppPersonalCountVariables } from "../../__generated__/GetSingleOppPersonalCount";

interface Props {
    opp_file_id: string;
}

const OppPersonalCount: React.FC<Props> = ({ opp_file_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetSingleOppPersonalCount, GetSingleOppPersonalCountVariables>(QUERY_SINGLE_PERSONAL_COUNT, {
        variables: { id: opp_file_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.opportunityFile) {
        return <Typography>{""}</Typography>;
    }

    const opp = data.opportunityFile;

    return <Typography>{opp.personalOpportunitiesCount}</Typography>;
};

export default OppPersonalCount;
