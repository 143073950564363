import React, { useState } from "react";
import FaviconBox from "../../../../components/FaviconBox";
import { Box, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TooltipOnNewLines from "../../../../components/TooltipOnNewLines";
import ChatButtonTopBar from "../../../../components/ChatButtonTopBar";
import { useQuery } from "@apollo/client";
import { GetCaForProject, GetCaForProjectVariables } from "../../../../__generated__/GetCaForProject";
import { QUERY_CA_FOR_PROJECT } from "../../../../graphql/queryDefProject";
import { Close } from "@mui/icons-material";

interface Props {
    ca_id: string;
    remove_id: (id: string) => void;
    disable: boolean;
}

const SingleCaData: React.FC<Props> = ({ ca_id, remove_id, disable }) => {
    const [hoverState, setHoverState] = useState<string | boolean>(false);

    /**
     * Default tender summary query
     */
    const { data, loading, error } = useQuery<GetCaForProject, GetCaForProjectVariables>(QUERY_CA_FOR_PROJECT, {
        skip: ca_id === "" || ca_id === undefined,
        variables: { id: ca_id },
        fetchPolicy: "cache-and-network",
    });

    // Loading
    if (loading) {
        return (
            <Box component="div">
                <Skeleton sx={{ width: `${Math.floor(Math.random() * (80 - 35) + 35)}%` }} />
            </Box>
        );
    }

    // Error
    if (error || !data || !data.contracting_authority) {
        return (
            <Box component="div">
                <TooltipOnNewLines
                    text={"Er ging iets mis..."}
                    variant={"body1"}
                    lines={1}
                    id={"tendertitletoshow"}
                    fontweight={"normal"}
                    fontSize={"14px"}
                    placement={"top"}
                />
            </Box>
        );
    }

    return (
        <React.Fragment>
            <div onMouseEnter={() => setHoverState(ca_id)} onMouseLeave={() => setHoverState(false)}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div>
                            {/* Favicon */}
                            <FaviconBox
                                favicon={data.contracting_authority.favicon_resized ?? data.contracting_authority.favicon ?? null}
                                name={data.contracting_authority.name}
                                color="#F57117"
                            />
                        </div>
                        <div>
                            {/* Link to tenderdetailpage, TODO: get tenderid to link to */}
                            <Typography noWrap>
                                <Link id="contracting_authority-name" to={`/contracting-authorities/${data.contracting_authority.id}`}>
                                    <TooltipOnNewLines
                                        text={data.contracting_authority.name}
                                        variant={"body1"}
                                        lines={2}
                                        id={`tendertitletoshow${data.contracting_authority.id}`}
                                        fontweight={"normal"}
                                        fontSize={"14px"}
                                        placement={"top"}
                                    />
                                </Link>
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        {/* Show delete button on hover */}
                        {hoverState === ca_id && (
                            <Tooltip disableInteractive placement="top" title="Verwijder uit project">
                                <IconButton
                                    size="small"
                                    edge="end"
                                    sx={{ "&:hover": { color: (theme) => theme.palette.error.main } }}
                                    aria-label="delete"
                                    disabled={disable}
                                    onClick={() => {
                                        remove_id(ca_id);
                                    }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        <ChatButtonTopBar
                            buttonSize="small"
                            id={ca_id}
                            name={data.contracting_authority.name || ""}
                            module={"App\\Models\\ContractingAuthorityV4"}
                            notesAvailable={Boolean(data?.contracting_authority?.hasConversations)}
                            location="Ca"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SingleCaData;
