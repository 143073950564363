import React, { useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Disable } from "react-disable";
import { useMutation } from "@apollo/client";
import { login2FA, login2FAVariables } from "../../__generated__/login2FA";
import { LOGIN_TG } from "../Login";
import { useTranslation } from "react-i18next";

interface Props {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: () => void;
    email: string;
    password: string;
}

const SixDigits: React.FC<Props> = ({ setOpen, onSuccess, email, password }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [code, setCode] = useState<string[]>(new Array(6).fill("")); // Array for 6
    const [login, { loading }] = useMutation<login2FA, login2FAVariables>(LOGIN_TG);
    const [error, setError] = useState<string | undefined>(undefined);

    /**
     * handle 6 digit code input
     * @param index index of number to fill in
     * @param value number to fill in
     */
    const handleChange = (index: number, value: string) => {
        // Allow only digits (0-9)
        if (/^\d*$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value; // Update the current index

            // Move to the next input if the current one is filled
            if (value && index < code.length - 1) {
                document.getElementById(`input-${index + 1}`)?.focus(); // Move focus to next input
            }

            // Set the new code
            setCode(newCode);

            // Check if the last digit is filled in
            if (index === code.length - 1 && value && newCode.length === 6) {
                handle2faSubmit(newCode.join("")); // Call verify function with the complete code
            }
        } else if (value === "") {
            // Allow backspace to clear the input
            const newCode = [...code];
            newCode[index] = "";
            setCode(newCode);
        }
    };

    const handle2faSubmit = async (verificationcode: string) => {
        try {
            const response = await login({
                variables: { email, password, code: verificationcode },
            });
            const data = response.data?.loginTG;

            if (data?.success && data?.token) {
                // Handle successful 2FA login (e.g., store the token, redirect)
                localStorage.setItem("access_token", data.token);
                setOpen(false);
                onSuccess();
            } else {
                setError(t(data?.message as string));
            }
        } catch (e) {
            console.error("Error signing in", e);
            setError(t("auth.error-login-check-credentials"));
            localStorage.removeItem("acces_token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("initials");
        }
    };

    return (
        <>
            <Typography textAlign="center" variant="body1" fontWeight={500} sx={{ color: "#acacac" }}>
                Vul de 6 cijferige authenticatie code in
            </Typography>
            <Disable disabled={loading}>
                <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginTop: "32px" }}>
                    {code.map((digit, index) => (
                        <input
                            autoFocus={index === 0}
                            disabled={loading}
                            key={index}
                            type="text"
                            id={`input-${index}`}
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            style={{
                                width: isMobile ? "30px" : "50px",
                                height: isMobile ? "40px" : "60px",
                                textAlign: "center",
                                fontSize: "24px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                marginRight: index === 2 ? "24px" : "0px",
                            }}
                            maxLength={1} // Limit input to 1 character
                        />
                    ))}
                </div>

                <div
                    style={{
                        marginBottom: "32px",
                        marginLeft: "16px",
                        visibility: error ? "visible" : "hidden",
                    }}
                >
                    <Typography variant="caption" color="error.main">
                        {error || "ERROR_PLACEHOLDER"}
                    </Typography>
                </div>
            </Disable>
        </>
    );
};

export default SixDigits;
