import { createContext, useContext } from "react";

export type OpportunityWorklistType = {
    page: number;
    setPage: (page: number) => void;
};

export const OpportunityWorklistContext = createContext<OpportunityWorklistType>({
    page: 1,
    setPage: (page) => console.warn("no theme provider"),
});
export const useOpportunityWorklistPage = () => useContext(OpportunityWorklistContext);
