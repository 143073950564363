import React from "react";
import { Box, Card, CardMedia, Typography } from "@mui/material";

interface Props {
    logo: string | null;
    favicon: string | null;
    name: string;
    elevation?: number;
    width?: string;
    height?: string;
    colorDefault?: boolean;
    textColor: string;
}

const LogoBox: React.FC<Props> = ({ logo, favicon, name, elevation = 1, width = "80px", height = "80px", colorDefault = false, textColor }) => {
    return (
        <Card elevation={elevation} sx={{ width: width, height: height }}>
            {logo ? (
                <CardMedia
                    component="img"
                    sx={{
                        filter: colorDefault ? "grayscale(0)" : "grayscale(1)",
                        transform: "scale(0.8)",
                        objectFit: "scale-down",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        transition: `0.3s`,
                        cursor: "pointer",
                        "&:hover": {
                            transform: "scale(1.5)",
                            filter: `grayscale(0)`,
                        },
                    }}
                    image={logo}
                />
            ) : favicon ? (
                <CardMedia
                    component="img"
                    sx={{
                        filter: colorDefault ? "grayscale(0)" : "grayscale(1)",
                        transform: "scale(0.8)",
                        objectFit: "scale-down",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        transition: `0.3s`,
                        cursor: "pointer",
                        "&:hover": {
                            transform: "scale(1.5)",
                            filter: `grayscale(0)`,
                        },
                    }}
                    image={`https://storage.googleapis.com/organizations_favicons/${favicon}`}
                />
            ) : (
                <Box
                    sx={{
                        filter: colorDefault ? "grayscale(0)" : "grayscale(1)",
                        transform: "scale(0.8)",
                        objectFit: "scale-down",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        transition: `0.3s`,
                        cursor: "pointer",
                        "&:hover": {
                            transform: "scale(1.5)",
                            filter: `grayscale(0)`,
                            color: textColor,
                        },
                    }}
                >
                    <Typography sx={{ fontWeight: 600 }}>{name}</Typography>
                </Box>
            )}
        </Card>
    );
};

export default LogoBox;
