import React, { useEffect } from "react";
import { GetLastTenderMessage, GetLastTenderMessageVariables } from "../../__generated__/GetLastTenderMessage";
import { GET_LAST_TENDER_MESSAGE } from "../../graphql/queryDefinitions";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useTenderChat } from "../contextProviders/TenderChatContext";

interface Props {
    tender_id: string;
}

const LastMessageColumn: React.FC<Props> = ({ tender_id }) => {
    const { messageSent, openModal } = useTenderChat();

    /**
     * Get message data
     */
    const { data, refetch } = useQuery<GetLastTenderMessage, GetLastTenderMessageVariables>(GET_LAST_TENDER_MESSAGE, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        messageSent === true && openModal === false && refetch();
    }, [refetch, tender_id, messageSent, openModal]);

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }
    return <Typography variant="body1">{data.tender.lastPrivateNote}</Typography>;
};

export default LastMessageColumn;
