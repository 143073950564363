import { gql } from "@apollo/client";

export const CREATE_NEW_PROJECT_STATE = gql`
    mutation createState($name: String!, $order: Int!) {
        createProjectState(name: $name, order: $order) {
            id
            name
            order_number
        }
    }
`;

export const CREATE_NEW_PROJECT = gql`
    mutation createNewProject(
        $title: String!
        $tender_ids: [ID]
        $user_ids: [ID!]
        $contractingAuthority_ids: [ID]
        $state: ID!
        $widgets: WidgetsInProjectInput!
    ) {
        createProject(
            title: $title
            tender_ids: $tender_ids
            user_ids: $user_ids
            contractingAuthority_ids: $contractingAuthority_ids
            state: $state
            widgets: $widgets
        ) {
            id
        }
    }
`;

export const CREATE_NEW_TASK = gql`
    mutation createTask($project_id: ID!, $description: String!, $deadline: Date, $completed: Boolean!, $priority: Priority!, $user_ids: [ID]) {
        createTask(
            project_id: $project_id
            description: $description
            deadline: $deadline
            completed: $completed
            priority: $priority
            user_ids: $user_ids
        )
    }
`;

export const UPDATE_PROJECT_NAME = gql`
    mutation updateProjectName($id: ID!, $title: String!) {
        updateProjectName(id: $id, title: $title)
    }
`;

export const UPDATE_PROJECT_STATE = gql`
    mutation updateStateOfProject($id: ID!, $state: ID!) {
        updateStateOfProject(id: $id, state: $state)
    }
`;

export const UPDATE_TASK_COMPLETION = gql`
    mutation updateTaskCompletion($id: ID!, $completed: Boolean!) {
        updateTaskCompletion(id: $id, completed: $completed)
    }
`;

export const UPDATE_TASK_PRIORITY = gql`
    mutation updateTaskPriority($id: ID!, $priority: Priority!) {
        updateTaskPriority(id: $id, priority: $priority)
    }
`;

export const UPDATE_PROJECT_STATE_NAME = gql`
    mutation updateProjectStateName($id: ID!, $name: String!) {
        updateProjectStateName(id: $id, name: $name)
    }
`;

export const UPDATE_TASK_DESCRIPTION = gql`
    mutation updateTaskDescription($id: ID!, $description: String!) {
        updateTaskDescription(id: $id, description: $description)
    }
`;

export const UPDATE_TASK_DEADLINE = gql`
    mutation updateTaskDeadline($id: ID!, $deadline: Date!) {
        updateTaskDeadline(id: $id, deadline: $deadline)
    }
`;

export const UPDATE_TASK_USER = gql`
    mutation updateTaskUsers($id: ID!, $user_ids: [ID]!) {
        updateTaskUsers(id: $id, user_ids: $user_ids)
    }
`;

export const UPDATE_USERS_IN_PROJECT = gql`
    mutation updateUsersInProject($id: ID!, $add_ids: [ID!], $remove_ids: [ID!], $widgets: WidgetsInProjectInput!) {
        updateUsersInProject(id: $id, add_ids: $add_ids, remove_ids: $remove_ids, widgets: $widgets)
    }
`;

export const CREATE_COMMENT_IN_PROJECT = gql`
    mutation createComment($project_id: ID!, $comment: String!) {
        createComment(project_id: $project_id, comment: $comment)
    }
`;

export const UPDATE_COMMENT_IN_PROJECT = gql`
    mutation updateComment($comment_id: ID!, $comment: String!) {
        updateComment(comment_id: $comment_id, comment: $comment)
    }
`;

export const REPLY_TO_COMMENT_IN_PROJECT = gql`
    mutation replyToComment($project_id: ID!, $comment_id: ID!, $comment: String!) {
        replyToComment(project_id: $project_id, comment_id: $comment_id, comment: $comment)
    }
`;

export const REPLY_TO_REPLY_IN_PROJECT = gql`
    mutation replyToReply($project_id: ID!, $reply_id: ID!, $comment: String!) {
        replyToReply(project_id: $project_id, reply_id: $reply_id, comment: $comment)
    }
`;

export const UPDATE_CAS_IN_PROJECT = gql`
    mutation updateCA($project_id: ID!, $ca_ids: [ID]!) {
        updateContractingAuthoritiesInProject(id: $project_id, ca_ids: $ca_ids)
    }
`;

export const UPDATE_TENDERS_IN_PROJECT = gql`
    mutation updateTenderInProject($project_id: ID!, $tender_ids: [ID]!) {
        updateTendersInProject(id: $project_id, tender_ids: $tender_ids)
    }
`;

export const UPDATE_WIDGETS_CU_IN_PROJECT = gql`
    mutation updateWidgetsToShowForCurrentUser($id: ID!, $widgets: WidgetsInProjectInput!) {
        updateWidgetsToShowInProject(id: $id, widgets: $widgets)
    }
`;

export const UPDATE_CANCELLATION_REVIEW_CU_IN_PROJECT = gql`
    mutation updateReviewCancellationForCurrentUser($id: ID!) {
        updateReviewCancellation(id: $id)
    }
`;

export const UPDATE_REPLY_IN_PROJECT = gql`
    mutation updateReply($reply_id: ID!, $comment: String!) {
        updateReply(reply_id: $reply_id, comment: $comment)
    }
`;

export const DELETE_REPLY_IN_PROJECT = gql`
    mutation deleteReply($reply_id: ID!) {
        deleteReply(reply_id: $reply_id)
    }
`;

export const DELETE_COMMENT_IN_PROJECT = gql`
    mutation deleteComment($comment_id: ID!) {
        deleteComment(comment_id: $comment_id)
    }
`;

export const DELETE_PROJECT_STATE = gql`
    mutation deleteStateOfProject($id: ID!) {
        deleteStateOfProject(id: $id)
    }
`;

export const DELETE_PROJECT_TASK = gql`
    mutation deleteTask($id: ID!) {
        deleteTask(id: $id)
    }
`;
export const REMOVE_USER_FROM_SEARCH = gql`
    mutation removeUserFromSearch($user_ids: [Int]!, $search_id: Int!) {
        removeUserFromSearch(user_ids: $user_ids, search_id: $search_id)
    }
`;
