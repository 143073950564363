import { gql, useQuery } from "@apollo/client";
import { ListItemText, Skeleton } from "@mui/material";
import React from "react";
import {
    GetConversation_Unread_Messages_Counter,
    GetConversation_Unread_Messages_CounterVariables,
} from "../../__generated__/GetConversation_Unread_Messages_Counter";

interface Props {
    conv_id: string;
    conv_name: string;
}

const LastMessageToShow: React.FC<Props> = ({ conv_id, conv_name }) => {
    /**
     * Query messages of active conversation
     */
    const { data, loading } = useQuery<GetConversation_Unread_Messages_Counter, GetConversation_Unread_Messages_CounterVariables>(
        QUERY_CONVERSATION_UNREAD_MESSAGES_COUNT,
        {
            variables: { id: conv_id },
            fetchPolicy: "network-only",
        }
    );
    /**
     * Load sate
     */
    if (loading)
        return (
            <ListItemText
                primary={<Skeleton variant="text" width={150} />}
                secondary={<Skeleton variant="text" width={100} />}
                secondaryTypographyProps={{ noWrap: true }}
            />
        );

    /**
     * Show empty div when count = 0
     */
    if (!data || data.conversation.chat_messages.length === 0) {
        return <></>;
    }

    const conv = data.conversation;

    return (
        <ListItemText
            sx={{
                ".MuiListItemText-primary": {
                    fontSize: "0.9rem",
                },
                ".MuiListItemText-secondary": {
                    fontWeight: conv.unreadChatMessages.length > 0 ? 500 : 400,
                },
            }}
            primary={conv_name}
            secondary={conv.chat_messages[conv.chat_messages.length - 1].message || ""}
            secondaryTypographyProps={{ noWrap: true }}
        />
    );
};

export default LastMessageToShow;

// ? get clicked conversation
export const QUERY_CONVERSATION_UNREAD_MESSAGES_COUNT = gql`
    query GetConversation_Unread_Messages_Counter($id: ID!) {
        conversation(id: $id) {
            unreadChatMessages {
                id
            }
            chat_messages {
                id
                message

                from {
                    id
                    name
                    email
                    employee {
                        id
                        givenname
                        middlename
                        familyname
                    }
                }
                send_at
            }
        }
    }
`;
