import React, { createContext, useContext, useState } from "react";
import {
    DistributionIntervalInput,
    SearchDistributionInput,
    SearchDistributionMoment,
    TenderFilterInput,
    TenderFilterTarget,
} from "../../../__generated__/globalTypes";
import { GetUserEmail, GetUserEmail_currentUser_employee_organization_subscriptions_sectors } from "../../../__generated__/GetUserEmail";
import { QUERY_EMAIL } from "../../../graphql/queryDefCurrentUser";
import { useQuery } from "@apollo/client";

// Define the type for your context value
interface TenderSearchType {
    // Add your additional state variables here
    chosenWords: string[];
    query: string;
    domain: TenderFilterTarget;
    filters: TenderFilterInput;
    name: string;
    moment: SearchDistributionMoment | null;
    dist: SearchDistributionInput;
    sectorList: (GetUserEmail_currentUser_employee_organization_subscriptions_sectors | null)[];
    sector: number[];
    checkedSub: number[];
    country: number[];
    staticLoad: boolean;
    copyIDS: number[];
    shareIDS: number[];
    color: string;
    idsToDelete: number[];
    setIdsToDelete: React.Dispatch<React.SetStateAction<number[]>>;
    setChosenWords: React.Dispatch<React.SetStateAction<string[]>>;
    setQuery: React.Dispatch<React.SetStateAction<string>>;
    setDomain: React.Dispatch<React.SetStateAction<TenderFilterTarget>>;
    setFilters: React.Dispatch<React.SetStateAction<TenderFilterInput>>;
    setName: React.Dispatch<React.SetStateAction<string>>;
    setMoment: React.Dispatch<React.SetStateAction<SearchDistributionMoment | null>>;
    setDist: React.Dispatch<React.SetStateAction<SearchDistributionInput>>;
    setCountry: React.Dispatch<React.SetStateAction<number[]>>;
    setSector: React.Dispatch<React.SetStateAction<number[]>>;
    setCheckedSub: React.Dispatch<React.SetStateAction<number[]>>;
    copySearchTo: React.Dispatch<React.SetStateAction<number[]>>;
    shareSearchWith: React.Dispatch<React.SetStateAction<number[]>>;
    setColor: React.Dispatch<React.SetStateAction<string>>;
    setStaticLoad: React.Dispatch<React.SetStateAction<boolean>>;
    reset: () => void;
}

// Create the context with an initial value
const TenderSearchObject = createContext<TenderSearchType>({
    chosenWords: [],
    query: "",
    domain: TenderFilterTarget.SUMMARY,
    filters: { country: [528] },
    name: "",
    moment: null,
    dist: {
        worklist: true,
        in_app: true,
        email_interval: DistributionIntervalInput.DAILY,
    },
    sector: [],
    checkedSub: [],
    country: [528],
    staticLoad: false,
    copyIDS: [],
    shareIDS: [],
    sectorList: [],
    color: "#808080",
    idsToDelete: [],
    setIdsToDelete: () => console.warn(""),
    setChosenWords: () => console.warn(""),
    setQuery: () => console.warn(""),
    setDomain: () => console.warn(""),
    setFilters: () => console.warn(""),
    setName: () => console.warn(""),
    setMoment: () => console.warn(""),
    setDist: () => console.warn(""),
    setCountry: () => console.warn(""),
    setSector: () => console.warn(""),
    setCheckedSub: () => console.warn(""),
    copySearchTo: () => console.warn(""),
    shareSearchWith: () => console.warn(""),
    setColor: () => console.warn(""),
    setStaticLoad: () => console.warn(""),
    reset: () => console.warn(""),
});

// Create a provider component for your context
export const TenderSearchProvider: React.FC = ({ children }) => {
    // Initialize your additional state variables here
    const [chosenWords, setChosenWords] = useState<string[]>([]);
    const [query, setQuery] = useState<string>("");
    const [domain, setDomain] = useState<TenderFilterTarget>(TenderFilterTarget.SUMMARY);
    const [filters, setFilters] = useState<TenderFilterInput>({ country: [528] });
    const [name, setName] = useState<string>("");
    const [moment, setMoment] = useState<SearchDistributionMoment | null>(null);
    const [dist, setDist] = useState<SearchDistributionInput>({
        worklist: true,
        in_app: true,
        email_interval: DistributionIntervalInput.DAILY,
    });
    const [sector, setSector] = useState<number[]>([]);
    const [checkedSub, setCheckedSub] = useState<number[]>([]);
    const [country, setCountry] = useState<number[]>([528]);
    const [staticLoad, setStaticLoad] = useState<boolean>(false);
    const [copyIDS, copySearchTo] = useState<number[]>([]);
    const [shareIDS, shareSearchWith] = useState<number[]>([]);
    const [color, setColor] = useState("#808080");
    const [sectorList, setSectorList] = useState<(GetUserEmail_currentUser_employee_organization_subscriptions_sectors | null)[]>([]);
    const [idsToDelete, setIdsToDelete] = useState<number[]>([]);

    useQuery<GetUserEmail>(QUERY_EMAIL, {
        onCompleted(data) {
            if (
                data &&
                data.currentUser &&
                data.currentUser.employee &&
                data.currentUser.employee.organization &&
                data.currentUser.employee.organization.subscriptions &&
                data.currentUser.employee.organization.subscriptions[0].sectors
            ) {
                setSectorList(data.currentUser.employee.organization.subscriptions[0].sectors);
            }
        },
    });

    const reset = () => {
        setChosenWords([]);
        setQuery("");
        setDomain(TenderFilterTarget.SUMMARY);
        setFilters({ country: [528] });
        setName("");
        setMoment(null);
        setDist({
            worklist: true,
            in_app: true,
            email_interval: DistributionIntervalInput.DAILY,
        });
        setSector([]);
        setCheckedSub([]);
        setCountry([528]);
        setStaticLoad(false);
        copySearchTo([]);
        shareSearchWith([]);
        setColor("#808080");
        setIdsToDelete([]);
    };

    return (
        <TenderSearchObject.Provider
            value={{
                chosenWords,
                query,
                domain,
                filters,
                name,
                moment,
                dist,
                sector,
                checkedSub,
                country,
                staticLoad,
                copyIDS,
                shareIDS,
                color,
                sectorList,
                idsToDelete,
                setIdsToDelete,
                setSector,
                setChosenWords,
                setQuery,
                setDomain,
                setFilters,
                setName,
                setMoment,
                setDist,
                setCheckedSub,
                setStaticLoad,
                copySearchTo,
                shareSearchWith,
                setColor,
                setCountry,
                reset,
            }}
        >
            {children}
        </TenderSearchObject.Provider>
    );
};

// Create a custom hook to easily consume the context
export const useTenderSearchContext = () => {
    return useContext(TenderSearchObject);
};
