import React from "react";
import { Tooltip, Typography } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useTranslation } from "react-i18next";

interface Props {
    iconSize?: "small" | "medium" | "inherit" | "large";
    data: any;
    /**
     * marginRight is optional, can set to a number for margin. undefined = marginRight:0
     */
    marginRight?: string;
    /**
     * placement for tooltip
     */
    placement:
        | "bottom"
        | "left"
        | "right"
        | "top"
        | "bottom-end"
        | "bottom-start"
        | "left-end"
        | "left-start"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | undefined;
    card?: boolean;
}

const SearchRuleLabel: React.FC<Props> = ({ data: searches, placement, marginRight, card = false, iconSize = "medium" }) => {
    const { t } = useTranslation();
    const data = [...searches];

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {data?.map((label: any, i: number) => {
                // set all words to lowercase
                const lowercase =
                    label?.words !== null &&
                    label?.words !== undefined &&
                    label?.words?.length > 0 &&
                    label?.words?.map((label: string) => label.toLowerCase());
                // create array with unique words
                const uniqueArrOfWords = lowercase ? [...new Set(lowercase)] : [];
                return (
                    <React.Fragment key={i}>
                        {/* Tooltip that shows up on hover, displays title of searchrule */}
                        <Tooltip
                            componentsProps={{ tooltip: { sx: { maxWidth: "70vw" } } }}
                            placement={placement}
                            title={
                                <React.Fragment>
                                    <Typography style={{ marginBottom: 4 }}>
                                        <strong>{label?.search?.name}</strong>
                                    </Typography>
                                    {uniqueArrOfWords !== null && uniqueArrOfWords !== undefined && uniqueArrOfWords.length > 0 && (
                                        <div>
                                            {/*
                                             * Query result (fragment)
                                             */}
                                            <Typography style={{ display: "inline-flex" }}>
                                                <strong>{uniqueArrOfWords.length === 1 ? "Gevonden zoekwoord" : "Gevonden zoekwoorden"}:</strong>
                                            </Typography>{" "}
                                            {/* [... new Set(a)]
                                             */}
                                            {uniqueArrOfWords !== null &&
                                                uniqueArrOfWords !== undefined &&
                                                uniqueArrOfWords.length > 0 &&
                                                uniqueArrOfWords.map((q: any, i: number) => {
                                                    // Check if index is same number as array length
                                                    const comma = uniqueArrOfWords && uniqueArrOfWords.length - 1 !== i;
                                                    return (
                                                        <React.Fragment key={i}>
                                                            {/*  add margin right if item is lastitem in array */}
                                                            <Typography style={{ display: "inline-flex", marginRight: comma ? 4 : 0 }}>
                                                                {q}
                                                                {comma ? "," : ""}
                                                            </Typography>
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </div>
                                    )}
                                    {/*
                                     * Filters with matches
                                     */}
                                    {label?.search?.filters_with_labels !== null && label?.search?.filters_with_labels !== undefined && (
                                        <div key={i}>
                                            <Typography style={{ display: "inline-flex", marginRight: 4 }}>
                                                <strong>
                                                    {label?.search?.filters_with_labels !== null &&
                                                    label?.search?.filters_with_labels !== undefined &&
                                                    label?.search?.filters_with_labels.length === 1
                                                        ? "Met filter"
                                                        : "Met filters"}
                                                    :
                                                </strong>
                                            </Typography>
                                            <Typography style={{ display: "inline-flex" }}>
                                                {label?.search?.filters_with_labels?.map((fltr: any, i: number) => {
                                                    const lastitem =
                                                        label?.search?.filters_with_labels !== null &&
                                                        label?.search?.filters_with_labels !== undefined &&
                                                        label?.search?.filters_with_labels.length - 1 !== i;
                                                    return (
                                                        <>
                                                            <Typography
                                                                key={fltr?.key}
                                                                style={{
                                                                    display: "inline-flex",
                                                                    marginRight: lastitem ? 4 : 0,
                                                                }}
                                                            >
                                                                {t(`filter.${fltr?.key}`)}
                                                                {lastitem ? " |" : ""}
                                                            </Typography>
                                                        </>
                                                    );
                                                })}
                                            </Typography>
                                        </div>
                                    )}
                                </React.Fragment>
                            }
                        >
                            {/*
                             * Icon of tender searchrule filled with searchrule color
                             */}

                            {card === false ? (
                                <LocalOfferIcon
                                    key={label?.search?.id}
                                    fontSize={iconSize}
                                    className={`search_${label?.search?.id}`}
                                    style={{
                                        marginRight: `${marginRight !== undefined ? marginRight : 0}`,
                                        cursor: "pointer",
                                        color: `${label?.search?.color}`,
                                    }}
                                />
                            ) : (
                                <span
                                    key={label?.search?.id}
                                    style={{
                                        backgroundColor: `${label?.search?.color}`,
                                        //  backgroundColor: `${item}`
                                        height: "6px !important",
                                        width: "22px !important ",
                                    }}
                                />
                            )}
                        </Tooltip>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default SearchRuleLabel;
