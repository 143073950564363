import React, { useState, useEffect } from "react";
import dompurify from "dompurify";
import LabelPagination from "./LabelPagination";
import { Typography } from "@mui/material";

interface Props {
    arrayOfSnips: (string | null | undefined)[] | undefined;
    handleHighlighting: () => void;
}

const SingleLabelSnippet: React.FC<Props> = ({ arrayOfSnips, handleHighlighting }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const PageSize = 1;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    const handleChangePage = (newPage: number) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        handleHighlighting();
    });
    return (
        <div>
            {arrayOfSnips?.slice(firstPageIndex, lastPageIndex).map((snip) => {
                if (snip !== null && snip !== undefined) {
                    const text = snip.replace(/(?:\r\n|\r|\n|\\n)/g, " ");
                    const frag = dompurify.sanitize(text, { FORCE_BODY: true });
                    return (
                        <div key={snip} style={{ padding: 16, margin: "4px 0px", height: 200, overflow: "auto", width: "100%" }}>
                            <Typography
                                style={{ wordWrap: "break-word" }}
                                dangerouslySetInnerHTML={{
                                    __html: frag,
                                }}
                            />
                        </div>
                    );
                }
                return null;
            })}

            {/*
             * Pagination to go to prev or next fragment
             */}
            {arrayOfSnips !== null && arrayOfSnips !== undefined && arrayOfSnips.length > 1 && (
                <LabelPagination userSnippets={arrayOfSnips.length} currentPage={currentPage} handleChangePage={handleChangePage} />
            )}
        </div>
    );
};

export default SingleLabelSnippet;
