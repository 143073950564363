import React from "react";

import { Grid } from "@mui/material";
import LabelWidget from "../../../components/LabelWidget/LabelWidget";

interface Props {
    tender_id: string;
}

const SplitViewLabels: React.FC<Props> = ({ tender_id }) => {
    return (
        <Grid container style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {/*
             * Center box
             * Contains all fragments of the opportunity document
             */}
            <Grid
                item
                sx={{
                    flex: 1 /* 1 and it will fill whole space left if no flex value are set to other children*/,
                    overflow: "auto",
                    marginTop: "-4px",
                    width: "100%",
                }}
            >
                <LabelWidget tender_id={tender_id} title={false} highlight={false} />
            </Grid>
        </Grid>
    );
};

export default SplitViewLabels;
