/**
 * Determine wether or not the user is
 * already authenticated in the react app
 * Implementation: check if a token exists in local storage
 */
const checkAuth = (): boolean => {
    return Boolean(localStorage.getItem("access_token"));
};

export default checkAuth;
