import React from "react";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TrendingUp } from "@mui/icons-material";

import HighlightInTenderDetail from "../../../components/tenderDetail/HighlightInTenderDetail";
import { GetTenderDetailSplitview_tender } from "../../../__generated__/GetTenderDetailSplitview";
import SplitDetailViewMeta from "./SplitDetailViewMeta";

interface Props {
    selectedItem: GetTenderDetailSplitview_tender | null | undefined;
    error: ApolloError | undefined;
    tender_description: string;
    tender_scope: string;
    mp?: string;
}

const SplitDetailView: React.FC<Props> = ({ selectedItem, error, tender_description, tender_scope, mp }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const tabletAndSmaller = useMediaQuery(theme.breakpoints.up("lg"));
    /**
     * Error component to show user something is happening
     */
    if (error || selectedItem === null || selectedItem === undefined) {
        return (
            <Paper elevation={0} square style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <TrendingUp style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                    <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                        {t("TableHelpers.NoMatch")}
                    </Typography>
                </div>
            </Paper>
        );
    }

    return (
        <HighlightInTenderDetail tender_id={selectedItem?.id as string} backup={selectedItem.searchresults}>
            <Paper elevation={0} square style={{ height: `calc(71.5vh - 166px)`, padding: "0px 0px 0px 20px" }}>
                {selectedItem !== undefined && (
                    <Grid container style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        {/*
                         * Center box
                         * Contains all fragments of the opportunity document
                         */}
                        <Grid
                            item
                            sx={{
                                flex: 1 /* 1 and it will fill whole space left if no flex value are set to other children*/,
                                overflow: "auto",
                                marginTop: "16px",
                                paddingRight: "20px",
                            }}
                        >
                            {/* Dont show 'omschrijving' if there's nothing to show */}
                            {tender_description !== "" && (
                                <React.Fragment>
                                    <Typography variant="h4" style={{ marginBottom: 8 }}>
                                        {t("tenderPage.Description")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        style={{ display: "inline", whiteSpace: "pre-wrap" }}
                                        dangerouslySetInnerHTML={{
                                            __html: tender_description,
                                        }}
                                    />
                                </React.Fragment>
                            )}
                            {/*
                             * Dont show 'Omvang' if there's nothing to show
                             */}
                            {tender_scope !== "" && (
                                <React.Fragment>
                                    <Typography variant="h4" style={{ margin: "32px 0px 8px" }}>
                                        {t("tenderPage.Scope")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        style={{ display: "inline", whiteSpace: "pre-wrap" }}
                                        dangerouslySetInnerHTML={{
                                            __html: tender_scope,
                                        }}
                                    />
                                </React.Fragment>
                            )}
                            {!tabletAndSmaller && <SplitDetailViewMeta selectedItem={selectedItem} mp={mp} />}
                        </Grid>

                        {/*
                         * Grid with meta data
                         */}
                        {tabletAndSmaller && <SplitDetailViewMeta selectedItem={selectedItem} mp={mp} />}
                    </Grid>
                )}
            </Paper>
        </HighlightInTenderDetail>
    );
};

export default SplitDetailView;
