import React from "react";
import { ListItemText, Typography, ListItemSecondaryAction, Chip } from "@mui/material";
import FaviconBox from "../FaviconBox";
import { Link } from "react-router-dom";
import CountryFlagBox from "./CountryFlagBox";
import StarIcon from "@mui/icons-material/Star";
import { useTranslation } from "react-i18next";
import { GetStarredCas_currentUser_contracting_authorities } from "../../__generated__/GetStarredCas";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    primary: Record<string, React.ReactNode>;
    starredCAS: GetStarredCas_currentUser_contracting_authorities[];
    logo: string | false;
}

const CABoxPrimary: React.FC<Props> = ({ primary, starredCAS, logo }) => {
    const starColor = "#9e9e9e";
    const { t } = useTranslation();
    const yellow = "#F57117";
    const { trackEvent } = useMatomo();

    // Track event
    const trackClickCa = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {/* Show logo if its available */}
            {primary?.logo !== null && primary?.logo !== undefined && primary?.logo !== "" && (
                <div
                    style={{
                        width: 100,
                        height: 75,
                        backgroundImage: `url(${logo})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        marginRight: 24,
                    }}
                />
            )}

            {/* listitemtext with amount and percentage */}
            <ListItemText
                primary={
                    <React.Fragment>
                        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                            {/* Link to ca detailpage */}
                            {typeof primary?.logo !== "string" && (
                                <div style={{ marginRight: 8 }}>
                                    <FaviconBox
                                        marginTop={0}
                                        favicon={(primary?.favicon_resized as string) ?? (primary?.favicon as string)}
                                        name={primary?.name as string}
                                        color={yellow}
                                    />
                                </div>
                            )}{" "}
                            <Link
                                style={{ maxWidth: "95%" }}
                                to={`/contracting-authorities/${primary?.id}`}
                                onClick={() => {
                                    trackClickCa({ category: "Tender", action: "Go-from-ca-widget-to-ca-detail" });
                                }}
                            >
                                <Typography>{primary?.name}</Typography>
                            </Link>
                            {/* TODO: conditon, render only if starred */}
                            {starredCAS.find((s) => s.id === primary.id) && (
                                <StarIcon style={{ color: `${starColor}`, marginLeft: 4, fontSize: "1rem" }} />
                            )}
                        </div>
                    </React.Fragment>
                }
                secondary={
                    <React.Fragment>
                        <Typography style={{ display: "flex", alignItems: "center", marginLeft: typeof primary?.logo !== "string" ? 35 : 0 }}>
                            {/* show postalcode and city */}
                            {primary.adres_visit && <>{primary.adres_visit}</>}
                        </Typography>
                        {primary.postcode_visit && primary.plaats_visit && (
                            <Typography style={{ display: "flex", alignItems: "center", marginLeft: typeof primary?.logo !== "string" ? 35 : 0 }}>
                                {/* show postalcode and city */}
                                {primary.postcode_visit} {primary.plaats_visit}
                                {/* Show countryflag*/}
                                <>
                                    <CountryFlagBox code={primary.country as string} />
                                </>
                            </Typography>
                        )}
                        {primary.lot && (
                            <Typography>
                                {t("Lot")} {`${primary.lot}`}
                            </Typography>
                        )}
                    </React.Fragment>
                }
            />

            {/* Label only visible when CA isn't active anymore */}
            <ListItemSecondaryAction style={{ paddingBottom: 8 }}>
                {primary.active === null || "" || "1" ? (
                    <></>
                ) : (
                    // change primary.active to something that shows state instead of a number
                    <Chip
                        size="small"
                        label={primary.active}
                        sx={{ textTransform: "uppercase", letterSpacing: 0.15, fontSize: "0.7rem", fontWeight: 500 }}
                    />
                )}
            </ListItemSecondaryAction>
        </div>
    );
};

export default CABoxPrimary;
