import React, { useEffect, useState } from "react";
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary as MuiAccordionSummary, Box } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { ExpandMore } from "@mui/icons-material";

interface Props {
    topColor: "#173351" | "#F57117" | "#225E4D" | "#E03660";
    icon: React.ReactNode;
    titleText: React.ReactNode;
    open: boolean;
}

const Accordion = withStyles(MuiAccordion, {
    root: {
        borderLeft: "1px solid #70707014",
        borderRight: "1px solid #70707014",
        borderBottom: "1px solid #70707014",
        width: "100%",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

const AccordionSummary = withStyles(MuiAccordionSummary, {
    root: {
        minHeight: "32px !important",
        padding: "8px 8px",
    },
    content: {
        margin: 0,
        "&$expanded": {
            margin: "0px !important",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

const AccordionTile: React.FC<Props> = ({ topColor, icon, titleText, children, open }) => {
    const [expandedItem, setExpandedItem] = useState<string | false>(false);

    useEffect(() => {
        setExpandedItem(open ? "panel" : false);
    }, [open]);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpandedItem(isExpanded ? panel : false);
    };
    return (
        <Box component="div" sx={{ marginBottom: "12px" }}>
            <Box component="div" sx={{ height: "4px", backgroundColor: `${topColor}`, width: "100%" }} />
            <Accordion
                square
                expanded={expandedItem === "panel"}
                onChange={handleChange("panel")}
                TransitionProps={{
                    timeout: 100,
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    {/* Icon to show */}
                    <Box sx={{ display: "flex", alignItems: "center", color: "#9e9e9e", marginRight: "8px" }}>{icon}</Box>
                    {titleText}
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px 5px 8px 8px" }}>{children}</AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default AccordionTile;
