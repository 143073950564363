import { AccountTree, RadioButtonUnchecked, TaskAlt } from "@mui/icons-material";
import {
    Box,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { GetMyTasks_tasks } from "../../../__generated__/GetMyTasks";
import moment from "moment";
import TaskPriorityChip from "../../projects/projectModal/task/TaskPriorityChip";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_COMPLETION } from "../../../graphql/mutationDefProjects";
import { updateTaskCompletion, updateTaskCompletionVariables } from "../../../__generated__/updateTaskCompletion";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import { useNavigate } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    data: GetMyTasks_tasks[];
    refetch: () => void;
}

const MyTasksWidgetData: React.FC<Props> = ({ data, refetch }) => {
    const { trackEvent } = useMatomo();

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [clickedBox] = useState<string>("");
    const [hoverBox, setHoverBox] = useState<string>("");
    const [saveTaskCompletion] = useMutation<updateTaskCompletion, updateTaskCompletionVariables>(UPDATE_TASK_COMPLETION);
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);
    const navigate = useNavigate();

    /**
     * Update complete state of single task
     * @param taskId id of task
     * @param boolean bolean to set task to
     */
    const onChangeBoolean = async (taskId: string, boolean: boolean) => {
        track({ category: "Dashboard", action: "Click-mark-task-as-complete-via-dashboard", href: window.location.href });

        try {
            await saveTaskCompletion({
                variables: {
                    id: taskId,
                    completed: boolean,
                },
                onCompleted: () => {
                    refetch();
                },
            });
        } catch (e) {}
    };

    return (
        <Box sx={{ height: { xs: "335px", md: "355px" }, position: "relative" }}>
            {/* Underlaying icon with text  */}
            <div style={{ height: "100%", width: "100%", position: "absolute", top: 0, left: 0 }}>
                <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    {<TaskAlt style={{ color: "#f2f2f270", height: "9rem", width: "9rem" }} />}
                    <Typography variant="h5" style={{ color: "#f2f2f2" }}>
                        Mijn openstaande taken
                    </Typography>
                </div>
            </div>
            <List sx={{ padding: 0, overflow: "auto", zIndex: 99, height: "100%" }}>
                {data
                    .filter((row) => !row.task_completed)
                    .sort((a, b) => {
                        // Define the priority order
                        const priorityOrder = {
                            HIGH: 0,
                            MEDIUM: 1,
                            LOW: 2,
                        };

                        // Get the priority value of 'a' and 'b'
                        const priorityA = priorityOrder[a.task_priority || "LOW"];
                        const priorityB = priorityOrder[b.task_priority || "LOW"];

                        // Compare the priorities
                        if (priorityA !== priorityB) {
                            return priorityA - priorityB;
                        }

                        // If priorities are equal, sort by deadline
                        if (a.task_deadline === null && b.task_deadline === null) {
                            return 0; // If both tasks have no deadline, keep their relative order
                        }
                        if (a.task_deadline === null) {
                            return 1; // If 'a' has no deadline, place it after 'b'
                        }
                        if (b.task_deadline === null) {
                            return -1; // If 'b' has no deadline, place it after 'a'
                        }

                        const dateA = moment(a.task_deadline);
                        const dateB = moment(b.task_deadline);
                        return dateA.diff(dateB);
                    })
                    .map((row) => {
                        return (
                            <React.Fragment key={row.id}>
                                <ListItem
                                    id="task-list-item"
                                    sx={{
                                        paddingLeft: "8px",
                                        paddingRight: "140px",
                                        backgroundColor: "#ffffff",
                                    }}
                                >
                                    <ListItemText
                                        sx={{
                                            marginLeft: "4px",
                                            marginTop: "3px",
                                            marginBottom: "3px",
                                            "& .MuiListItemText-root": {
                                                marginRight: "60px",
                                            },
                                        }}
                                        primary={
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {row.task_priority && (
                                                    <TaskPriorityChip dot={true} disabled={row.task_completed} priority={row.task_priority} />
                                                )}
                                                <Typography variant="h6" noWrap>
                                                    {row.task_description}
                                                </Typography>
                                            </div>
                                        }
                                        secondary={
                                            // {/* <Tooltip
                                            //     placement="bottom"
                                            //     sx={{ display: "inline", widgt: "100%" }}
                                            //     componentsProps={{ tooltip: { sx: { marginTop: "0px" } } }}
                                            //     enterNextDelay={100}
                                            //     title={row.project?.title}
                                            // > */}
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div
                                                    style={{
                                                        minWidth: "24px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <AccountTree sx={{ width: "12px", color: "#b3b3b3" }} />
                                                </div>
                                                <Typography
                                                    noWrap
                                                    onClick={() => {
                                                        if (isMobile) {
                                                            navigate(`/projects/${row.project?.id as string}`);
                                                        } else {
                                                            changeProject({
                                                                projectID: row.project?.id,
                                                                projectTitle: row.project?.title,
                                                                state: row.project?.state?.id as string,
                                                            });
                                                            setOpen(true);
                                                        }
                                                    }}
                                                    sx={{
                                                        // marginTop: "1px",
                                                        cursor: "pointer",
                                                        display: "inline-block",
                                                        maxWidth: "100%", // Adjust the maximum width based on your parent container width
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        color: "#1f4769",
                                                        "&:hover": {
                                                            textDecoration: "underline",
                                                        },
                                                    }}
                                                >
                                                    {row.project?.title}
                                                </Typography>
                                            </div>
                                        }
                                    />
                                    <ListItemSecondaryAction sx={{ display: "flex", alignItems: "center" }}>
                                        {row.task_deadline && (
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                padding={"4px"}
                                                minWidth={"50px"}
                                                maxWidth={"50px"}
                                            >
                                                <Typography align="center">
                                                    {moment(row.task_deadline).format("MMM").toUpperCase().replace(".", "")}
                                                </Typography>
                                                <Typography align="center" variant="h2" fontWeight={600} lineHeight={1}>
                                                    {moment(row.task_deadline).format("D")}
                                                </Typography>
                                                <Typography variant="caption" align="center">
                                                    {moment(row.task_deadline).format("YYYY")}
                                                </Typography>
                                            </Stack>
                                        )}

                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                            }}
                                        >
                                            <Tooltip
                                                disableInteractive
                                                title="Taak voltooien"
                                                placement="left"
                                                slotProps={{
                                                    popper: {
                                                        modifiers: [
                                                            {
                                                                name: "offset",
                                                                options: {
                                                                    offset: [0, -16],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                }}
                                            >
                                                <Checkbox
                                                    onMouseOver={() => setHoverBox(row.id)}
                                                    onMouseOut={() => setHoverBox("")}
                                                    icon={
                                                        clickedBox === row.id ? (
                                                            <TaskAlt fontSize="small" sx={{ color: "#2E76FF" }} />
                                                        ) : hoverBox === row.id ? (
                                                            <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                                                        ) : (
                                                            <RadioButtonUnchecked fontSize="small" />
                                                        )
                                                    }
                                                    checkedIcon={<TaskAlt sx={{ color: "#2E76FF" }} />}
                                                    edge="start"
                                                    size="small"
                                                    checked={row.task_completed}
                                                    disableRipple
                                                    sx={{ marginLeft: "4px" }}
                                                    // setState to checked value
                                                    onChange={(e) => {
                                                        if (row.users && row.users.filter((u) => u.id === localStorage.getItem("user_id"))) {
                                                            onChangeBoolean(row.id, e.target.checked);
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
            </List>
        </Box>
    );
};

export default MyTasksWidgetData;
