import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
//Import Material UI
import { greenTheme } from "../../assets/styles";
import { useMatomo } from "@datapunt/matomo-tracker-react";

//Import components
import setTheme from "../../utils/setTheme";
import OpportunityOverview from "./allOpportunities/OpportunityOverview";
import OpportunityWorklist from "./worklistpage/OpportunityWorklist";
import MainLayout from "../../components/layout/MainLayout";
import InterestingOpportunities from "./interestingpage/InterestingOpportunities";
import OpportunityDetailContainer from "./detailpage/OpportunityDetailContainer";
import NotFound404 from "../NotFound404";
import { OpportunityWorklistContext } from "../../components/contextProviders/OpportunityWorklistContext";
import ModuleTabs, { DynamicTab } from "../../components/ModuleTabs";
import { ThemeProvider } from "@mui/material";

interface Props {
    a?: number;
}

const OpportunityModule: React.FC<Props> = (props) => {
    useEffect(() => {
        setTheme("green");
    });
    const history = useLocation();
    const navigate = useNavigate();
    const [tabs, setTabs] = useState<DynamicTab[]>([]);
    const [page, setPage] = useState(1);
    const { trackPageView } = useMatomo();

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: "Opportunity", // optional
            href: `https://tool.tender.guide${history.pathname}`, // optional
        });
    }, [trackPageView, history.pathname]);

    /**
     * Get tabs in session storage (opportunity) and store in state
     */
    useEffect(() => {
        if (sessionStorage.getItem("oot")) {
            setTabs(JSON.parse(sessionStorage.getItem("oot") || "[]"));
        }
    }, []);

    /**
     * Register a new tab to the OpportunityModule
     */
    //* --> add useCallback: import React, {useState, useCallback}
    const registerTab = useCallback((tab: DynamicTab) => {
        setTabs((prevTabs) => {
            // If the tab that is going to registered is not in the list yet
            // Add it
            if (!prevTabs.find((prevTab) => prevTab.value === tab.value)) {
                return [...prevTabs, tab];
            }
            return prevTabs;
        });
    }, []);

    /**
     * Handle closing the tab, and navigate to worklist tab or stay at active tab
     */
    const closeTab = (url: string) => {
        setTabs((prevTabs) => {
            const newTabs = prevTabs.filter((tab) => tab.value !== url);
            // If active tender is the one thats been closed -> navigate to worklist
            // otherwise navigate to current pathname
            if (history.pathname === url) {
                navigate("/opportunities/worklist");
            } else {
                navigate(history.pathname);
            }
            return newTabs;
        });
    };

    /**
     * Handle closing all tabs, and navigate to all opportunities
     * Dont navigate to worklist if user is on "all" or "reviews" tab
     */
    const closeAllTab = () => {
        setTabs([]);
        if (history.pathname === "/opportunities/reviews") {
            navigate("/opportunities/reviews");
        }
        if (history.pathname === "/opportunities") {
            navigate("/opportunities");
        } else {
            navigate("/opportunities/worklist");
        }
    };

    /**
     * Array of default tabs
     */
    const defaultTabs = [
        { id: 1, route: "/opportunities", label: "All" },
        { id: 2, route: "/opportunities/reviews", label: "Reviews" },
        { id: 3, route: "/opportunities/worklist", label: "Worklist" },
    ];

    /**
     * update session storage when tabs change
     */
    useEffect(() => {
        sessionStorage.setItem("oot", JSON.stringify(tabs));
    }, [tabs]);

    return (
        <ThemeProvider theme={greenTheme}>
            <OpportunityWorklistContext.Provider value={{ page, setPage }}>
                <MainLayout
                    page="opportunities"
                    header={
                        <ModuleTabs
                            page="Opportunity"
                            value={history.pathname}
                            onChange={(value) => navigate(value)}
                            onClose={closeTab}
                            tabs={tabs}
                            onCloseAll={closeAllTab}
                            defaultTabs={defaultTabs}
                        />
                    }
                >
                    <Routes>
                        {/* Opportunity for users routes */}
                        <Route path="" element={<OpportunityOverview />} />
                        <Route path="reviews" element={<InterestingOpportunities />} />
                        <Route path="worklist" element={<OpportunityWorklist />} />
                        <Route path=":id" element={<OpportunityDetailContainer registerTab={registerTab} />} />
                        <Route path="*" element={<NotFound404 url="/opportunities" />} />
                    </Routes>
                </MainLayout>
            </OpportunityWorklistContext.Provider>
        </ThemeProvider>
    );
};

export default OpportunityModule;
