import React from "react";
import Directory from "./Directory";
import Document from "./Document";
import { TenderDoc } from "./ListOfDocs";

interface Props {
    docList: TenderDoc[];
    onDirectoryClick: (folder: string) => void;
    onFileClick?: (folder: number) => void;
    tender_id: string;
    relevantDocumentIds?: number[];
    onFileClickData?: (folder: { id: any; label: any; link: any }) => void;
}

// sorting to show folders first instead of files
const sorting = ["DocumentFolder", "DocumentFile"];

const TreeItem: React.FC<Props> = ({ docList, onDirectoryClick, tender_id, onFileClick, relevantDocumentIds, onFileClickData }) => {
    return (
        <React.Fragment>
            {/*
             * Sort array by __typename (DocumentFolder or DocumentFile)
             * map over items, if __typename is DocumentFolder, render TreeItem component again
             * if __typename is DocumentFile, render Document component
             */}
            {docList
                ?.sort((a, b) => sorting.indexOf(a.__typename) - sorting.indexOf(b.__typename))
                .map((folder, i: number) => {
                    if (folder.__typename === "DocumentFolder") {
                        return (
                            <Directory key={i} label={folder.label} onDirectoryClick={onDirectoryClick}>
                                <TreeItem docList={[]} onDirectoryClick={onDirectoryClick} tender_id={tender_id} />
                            </Directory>
                        );
                    }

                    return (
                        <Document
                            onFileClickData={onFileClickData}
                            relevantDocumentIds={relevantDocumentIds}
                            onFileClick={onFileClick}
                            key={folder.id}
                            download_link={"folder.download_link"}
                            label={folder.label}
                            icon={folder.class}
                            marked={false}
                            link={folder.link}
                            file_id={folder.id as unknown as string}
                            tender_id={tender_id}
                            lotNumber={folder.lot}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default TreeItem;
