import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

interface Props {
    icon?: React.ReactNode;
    children: React.ReactNode;
    title?: string;
}

const GeneralInformationModule: React.FC<Props> = ({ icon, children, title }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <React.Fragment>
            <Typography sx={{ color: "#707070", margin: "4px 0" }} variant="h4">
                {title}
            </Typography>
            <div style={{ display: "flex" }}>
                <Box sx={{ display: "flex", alignItems: "center", color: "#9e9e9e" }}>{icon}</Box>
                <Box
                    sx={{
                        maxWidth: "100%",
                        margin: noMobile ? undefined : "0 10px",
                    }}
                >
                    {children}
                </Box>
            </div>
        </React.Fragment>
    );
};

export default GeneralInformationModule;
