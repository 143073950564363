import React, { useState } from "react";
import {
    Avatar,
    AvatarGroup,
    Divider,
    FormControl,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tooltip,
    Typography,
} from "@mui/material";
import { LocalOffer } from "@mui/icons-material";
import SearchLineSummarySentence from "./SearchLineSummarySentence";
import { GetSubscriptionUsers_organization_users } from "../../../__generated__/GetSubscriptionUsers";
import { DELETESEARCHRULE } from "../../../graphql/mutationDefinitions";
import { DeleteSearch, DeleteSearchVariables } from "../../../__generated__/DeleteSearch";
import { useMutation } from "@apollo/client";
import { GET_ORGANIZATION_SEARCHES } from "../../../graphql/queryDefCurrentUser";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

interface Props {
    type: "tender" | "opportunity";
    color: string;
    searchline: any;
    handleChangeOwner: (event: SelectChangeEvent, searchId: number) => void;
    /**
     * list of colleagues that are valid and not the owner of the searchline
     */
    colleagues: GetSubscriptionUsers_organization_users[];
    refetch: any;
    nameOfUserToDelete: string;
}

const SearchLineItemToChangeOwner: React.FC<Props> = ({ type, color, searchline, handleChangeOwner, colleagues, refetch }) => {
    const bgColor = "#ffffff";
    const green = "#225E4D";
    const blue = "#173357";
    const [newOwner, setNewOwner] = useState<string>("none");

    /**
     * Mutation to remove tendersearchrule
     */
    const [remove, { loading }] = useMutation<DeleteSearch, DeleteSearchVariables>(DELETESEARCHRULE);

    /**
     * Handler to remove a tender searchrule
     */
    const onClickRemove = async (searchRuleId: string) => {
        try {
            await remove({
                variables: {
                    id: searchRuleId,
                },
                refetchQueries: [GET_ORGANIZATION_SEARCHES, "GetOrganizationSearchRules"],
                onCompleted: () => {
                    toast.success("Zoekregel verwijderd");
                    refetch();
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        <>
            <ListItem
                style={{
                    marginTop: "5px",
                    borderLeft: type === "tender" ? `5px solid ${blue}` : type === "opportunity" ? `5px solid ${green}` : undefined,
                    backgroundColor: bgColor,
                }}
            >
                <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                    <ListItemIcon
                        sx={{
                            minWidth: "40px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                        }}
                        style={{ color: color }}
                    >
                        <LocalOffer sx={{ color: color }} />
                    </ListItemIcon>
                    <ListItemText
                        sx={{
                            color: "#173357",
                            opacity: 1,
                        }}
                        /**
                         * primary = title of searchline
                         */
                        primary={
                            <div>
                                <Typography style={{ display: "flex", alignItems: "center", height: "21px" }}>{searchline.name}</Typography>
                            </div>
                        }
                        /**
                         * secondary = summary of searchline
                         * TODO, user can edit inline. add regions, add or remove searchterms.
                         * create react fragment to add more features
                         */
                        secondary={
                            <React.Fragment>
                                {type === "tender" && (
                                    <SearchLineSummarySentence searchline={searchline} filterValues={searchline.filters_with_labels || null} />
                                )}
                            </React.Fragment>
                        }
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {searchline.state === "PENDING_ACCEPTANCE" && (
                        <Typography variant="button" sx={{ marginRight: "16px", fontSize: "12px", color: "#00000042" }}>
                            Nog niet geaccepteerd
                        </Typography>
                    )}
                    {/* Avatars => sharing results with those users */}
                    {searchline.participants && (
                        <>
                            {searchline.participants.length === 1 && searchline.owner?.id === localStorage.getItem("user_id") ? (
                                // USER IS OWNER ADN THE ONLY PARTICIPANT
                                <Tooltip disableInteractive placement={"right"} title={<div>{searchline.owner?.employee.name}</div>}>
                                    <AvatarGroup
                                        max={4}
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: "8px",
                                            "& .MuiAvatar-root": {
                                                border: "1px solid #ffffff",
                                                width: (theme) => theme.spacing(3.5),
                                                height: (theme) => theme.spacing(3.5),
                                                fontSize: "0.8rem",
                                            },
                                        }}
                                    >
                                        <Avatar sx={{ backgroundColor: "#173357" }}>
                                            {searchline.owner.employee.name?.slice(0, 1)}
                                            {searchline.owner.employee.familyname?.slice(0, 1)}
                                        </Avatar>
                                    </AvatarGroup>
                                </Tooltip>
                            ) : (
                                <>
                                    {searchline.participants.length > 0 ? (
                                        // MAP OVER PARTICIPANTS
                                        // BLUE AVATAR => OWNER
                                        // GREY => PARTICIPANTS
                                        <Tooltip
                                            disableInteractive
                                            placement={"right"}
                                            title={
                                                <>
                                                    <span>Gemaakt door:</span>
                                                    <br />
                                                    <span>{searchline.owner?.employee.name}</span>
                                                    <br />
                                                    {searchline.participants.filter((p: any) => p?.employee.id !== searchline.owner?.employee.id)
                                                        .length > 0 && (
                                                        <div style={{ marginTop: "4px" }}>
                                                            <span>Gedeeld met:</span>
                                                        </div>
                                                    )}
                                                    {searchline.participants
                                                        .filter((p: any) => p?.employee.id !== searchline.owner?.employee.id)
                                                        .map((l: any) => {
                                                            return <div key={l?.id}>{l?.employee.name}</div>;
                                                        })}
                                                </>
                                            }
                                        >
                                            <AvatarGroup
                                                max={4}
                                                sx={{
                                                    cursor: "pointer",
                                                    marginRight: "8px",
                                                    "& .MuiAvatar-root": {
                                                        border: "1px solid #ffffff",
                                                        width: (theme) => theme.spacing(3.5),
                                                        height: (theme) => theme.spacing(3.5),
                                                        fontSize: "0.8rem",
                                                    },
                                                }}
                                            >
                                                <Avatar sx={{ backgroundColor: "#173357" }}>
                                                    {searchline.owner?.employee?.name?.slice(0, 1)}
                                                    {searchline.owner?.employee?.familyname?.slice(0, 1)}
                                                </Avatar>
                                                {/* Map avatar of users that liked tender */}
                                                {searchline.participants
                                                    .filter((p: any) => p?.employee.id !== searchline.owner?.employee.id)
                                                    .map((l: any) => {
                                                        return (
                                                            <Avatar key={l?.id}>
                                                                {l?.employee?.name?.slice(0, 1)}
                                                                {l?.employee?.familyname?.slice(0, 1)}
                                                            </Avatar>
                                                        );
                                                    })}
                                            </AvatarGroup>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip disableInteractive placement={"right"} title={<div>{searchline.owner?.employee.name}</div>}>
                                            <AvatarGroup
                                                max={4}
                                                sx={{
                                                    cursor: "pointer",
                                                    marginRight: "8px",
                                                    "& .MuiAvatar-root": {
                                                        border: "1px solid #ffffff",
                                                        width: (theme) => theme.spacing(3.5),
                                                        height: (theme) => theme.spacing(3.5),
                                                        fontSize: "0.8rem",
                                                    },
                                                }}
                                            >
                                                <Avatar sx={{ backgroundColor: "#173357" }}>
                                                    {searchline.owner?.employee?.name?.slice(0, 1)}
                                                    {searchline.owner?.employee?.familyname?.slice(0, 1)}
                                                </Avatar>
                                            </AvatarGroup>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {/* On smaller devices -> all actions in submenu. */}
                    {/* TODO: change searchline.id to state or percentage*/}

                    <div style={{ width: "306px" }}>
                        {/* Tender buttonset */}

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "16px" }}>
                            <div>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 175 }}>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={newOwner}
                                        onChange={(e) => {
                                            handleChangeOwner(e, parseInt(searchline.id));
                                            setNewOwner(e.target.value);
                                        }}
                                        placeholder="Kies eigenaar"
                                    >
                                        <MenuItem aria-label="Geen" value="none">
                                            <em>Kies eigenaar</em>
                                        </MenuItem>
                                        {colleagues.map((col) => {
                                            return (
                                                <MenuItem key={col.id} value={col.id}>
                                                    {col.employee.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <LoadingButton
                                loading={loading}
                                disabled={loading}
                                size="small"
                                variant="text"
                                style={{ marginLeft: "8px", color: "#c0392b" }}
                                onClick={() => onClickRemove(searchline.id)}
                            >
                                verwijderen
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </ListItem>
            <Divider />
        </>
    );
};

export default SearchLineItemToChangeOwner;
