import React from "react";
import { GetDashboardLayout_GetDashboardLayout_rows } from "../../../__generated__/GetDashboardLayout";
import DraggableRow from "./DraggableRow";
import DraggableColumn from "./DraggableColumn";
import { Disable } from "react-disable";
import { Grid, Typography } from "@mui/material";
import { useDrop } from "react-dnd";
import { useRowsContext } from "./RowsContext";

interface Props {
    id: number;
    editMode: boolean;
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    children: React.ReactNode;
    row: GetDashboardLayout_GetDashboardLayout_rows;
    onAddColumnToRow: (rowId: string, columnCount: number) => void;
    onHoverChange: (bln: boolean) => void;
    isHovered: boolean;
    totalRows: number;
    moveColumn: (rowIndex: number, dragIndex: number, hoverIndex: number) => void;
    handleColumnHoverChange: (columnIndex: number, hovered: boolean) => void;
    hoveredColumnIndex: number | null;
    addRowAndColumn: (widgetId: number, rowIndex: number) => void;
}

const RowElement: React.FC<Props> = ({
    id,
    index,
    moveRow,
    row,
    onAddColumnToRow,
    onHoverChange,
    isHovered,
    editMode,
    totalRows,
    moveColumn,
    handleColumnHoverChange,
    hoveredColumnIndex,
    addRowAndColumn,
}) => {
    const { widgets } = useRowsContext();

    // React DnD hook to handle drop events for adding new rows
    const [{ isOver: over }, dropTarget] = useDrop({
        accept: "COLUMN",
        drop: (item: any) => {
            // Add a new row and column when a widget is dropped
            addRowAndColumn(item.widget.id, index + 1);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return (
        <React.Fragment>
            <DraggableRow
                totalRows={totalRows}
                editMode={editMode}
                key={row.id}
                id={id}
                index={index}
                moveRow={moveRow}
                onAddColumnToRow={onAddColumnToRow}
                row={row}
                onHoverChange={onHoverChange}
                isHovered={isHovered}
            >
                {row.columns
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((column, columnIndex) => {
                        const widget = widgets.find((w) => w.id === column.widgetId);
                        if (widget === undefined) {
                            return <></>;
                        } else
                            return (
                                <DraggableColumn
                                    key={column.id}
                                    editMode={editMode}
                                    id={column.id}
                                    widgetId={column.widgetId}
                                    rowIndex={index}
                                    index={columnIndex}
                                    moveColumn={moveColumn}
                                    row={row}
                                    onHoverChange={(hovered) => handleColumnHoverChange(column.widgetId, hovered)}
                                    isHovered={Boolean((hoveredColumnIndex as number) === column.widgetId)}
                                >
                                    <Disable disabled={editMode}>{widget.component(row.columns.length)}</Disable>
                                </DraggableColumn>
                            );
                    })}
            </DraggableRow>
            {editMode && (
                <Grid
                    container
                    ref={dropTarget}
                    style={{
                        border: over ? "2px dashed grey" : "2px dashed #e1e1e1",
                        backgroundColor: over ? "#fff" : undefined,
                        borderRadius: "4px",
                        height: "50px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "8px",
                    }}
                >
                    <Typography variant="caption">Plaats hier een widget om een nieuwe rij aan te maken</Typography>
                </Grid>
            )}
        </React.Fragment>
    );
};

export default RowElement;
