import React, { useState } from "react";
import LoginHeader from "../components/login/LoginHeader";
import { TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "./Login.scss";
import { useMutation } from "@apollo/client";
import { saveNewSetPassword, saveNewSetPasswordVariables } from "../__generated__/saveNewSetPassword";
import queryString from "query-string";
import { SAVE_NEW_PASSWORD } from "../graphql/mutationDefinitions";

interface Props {
    a?: number;
}

const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

const ResetPassword: React.FC<Props> = (props) => {
    const query = queryString.parse(window.location.search);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [secondPassword, setSecondPassword] = useState<string>("");
    const [success, setSuccess] = useState<string | null | undefined>("");
    const token = query.token as string;
    const [focused, setFocused] = useState(false);
    const [pwFocus, setPwFocus] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    /**
     * mutation to request a new password
     */
    const [passwordReset] = useMutation<saveNewSetPassword, saveNewSetPasswordVariables>(SAVE_NEW_PASSWORD);

    // submit new password
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (loading) return;
        if (!email || !password || !secondPassword) {
            setError(t("auth.error-login-no-input"));
            return;
        }

        try {
            setLoading(true);

            passwordReset({
                variables: {
                    input: {
                        email: email,
                        password: password,
                        password_confirmation: secondPassword,
                        token: token,
                    },
                },
            });
            setSuccess("Wachtwoord is succesvol gewijzigd");
            await sleep(2500);
            navigate("/login");
        } catch (e) {
            console.error("Error signing in", e);
            setError(t("auth.error-login-check-credentials"));
        }
    };

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    return (
        <div className="Login auth-form-wrapper">
            <div className="auth-form-container">
                <LoginHeader />
                {success ? (
                    <Typography style={{ marginBottom: 6, fontSize: 18, textAlign: "center" }}>{success}</Typography>
                ) : (
                    <form onSubmit={onSubmit}>
                        <div style={{ marginBottom: 20 }}>
                            <TextField
                                onFocus={onFocus}
                                onBlur={onBlur}
                                type="email"
                                placeholder={t("auth.email")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoFocus
                                fullWidth
                                variant="outlined"
                                disabled={loading}
                                autoComplete="username"
                                helperText={!isValidEmail(email) && !focused ? "Vul een geldig e-mailadres in" : ""}
                            />
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <TextField
                                onFocus={() => setPwFocus(true)}
                                onBlur={() => setPwFocus(false)}
                                helperText={
                                    password.length > 0 && password.length < 6 && pwFocus === false
                                        ? "Wachtwoord moet minimaal 6 tekens bevatten"
                                        : ""
                                }
                                error={password.length > 0 && password.length < 6 && pwFocus === false}
                                disabled={isValidEmail(email) ? false : true || loading}
                                style={{ marginBottom: 20 }}
                                type="password"
                                placeholder={t("auth.password")}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                                variant="outlined"
                                autoComplete="current-password"
                            />

                            <TextField
                                type="password"
                                error={secondPassword.length >= 6 && password !== secondPassword}
                                helperText={secondPassword.length >= 6 && password !== secondPassword ? t("auth.error-passwords-not-equal") : ""}
                                disabled={password.length < 6 || !isValidEmail(email) || loading}
                                placeholder={t("auth.password-repeat")}
                                value={secondPassword}
                                onChange={(e) => setSecondPassword(e.target.value)}
                                fullWidth
                                variant="outlined"
                                autoComplete="current-password"
                            />
                        </div>

                        {/*
                         * If anything went wrong during the login procedure, error will be displayed here
                         */}
                        <div style={{ visibility: error ? "visible" : "hidden" }} className="auth-form-error">
                            {error || "ERROR_PLACEHOLDER"}
                        </div>

                        <div style={{ visibility: success ? "visible" : "hidden" }} className="auth-form-error">
                            {success}
                        </div>

                        {/*
                         * bottom div of login form
                         * with link to request a demo and a logn button to submit login
                         */}
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                                disabled={
                                    loading || !isValidEmail(email) || password.length < 6 || secondPassword.length < 6 || password !== secondPassword
                                }
                            >
                                {loading ? t("auth.loading") : t("auth.save")}
                            </Button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ResetPassword;
