import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import { Disable } from "react-disable";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { verifyRecoveryCode, verifyRecoveryCodeVariables } from "../../__generated__/verifyRecoveryCode";
import { LoadingButton } from "@mui/lab";
import { VERIFY_RECOVERY_CODE } from "../../graphql/twoFAdefinitions";

interface Props {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: () => void;
}

const RecoveryCode: React.FC<Props> = ({ setOpen, onSuccess }) => {
    const { t } = useTranslation();
    const [inputCode, setInputCode] = useState<string>("");

    const [verify, { loading }] = useMutation<verifyRecoveryCode, verifyRecoveryCodeVariables>(VERIFY_RECOVERY_CODE);
    const [error, setError] = useState<string | undefined>(undefined);

    const handleInputChange = (e: any) => {
        setInputCode(e.target.value);
    };

    const handleVerify = async () => {
        try {
            const response = await verify({
                variables: { recovery_code: inputCode },
            });
            const data = response.data?.verifyRecoveryCode;

            if (data?.success && data?.token) {
                // Handle successful 2FA login (e.g., store the token, redirect)
                localStorage.setItem("access_token", data.token);
                setOpen(false);
                onSuccess();
            } else {
                setError(t(data?.message as string));
            }
        } catch (e) {
            setError("Herstelcode incorrect");
            console.error("Error signing in", e);
            localStorage.removeItem("acces_token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("initials");
        }
    };

    return (
        <>
            <Typography textAlign="center" variant="body1" fontWeight={500} sx={{ color: "#acacac" }}>
                Gebruik een herstelcode
            </Typography>
            <Disable disabled={loading}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        marginTop: "32px",
                        flexDirection: "column",
                    }}
                >
                    <TextField
                        fullWidth
                        label="Voer je herstelcode in"
                        value={inputCode}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{ marginBottom: "16px" }}
                        error={!!error} // Show error state if there's an error
                        helperText={
                            <div style={{ visibility: error ? "visible" : "hidden" }}>
                                <Typography variant="caption" color="error.main">
                                    {error || "ERROR_PLACEHOLDER"}
                                </Typography>
                            </div>
                        }
                    />
                    <div>
                        <LoadingButton loading={loading} disabled={inputCode.length < 3} variant="contained" fullWidth={false} onClick={handleVerify}>
                            verifiëren
                        </LoadingButton>
                    </div>
                </div>
            </Disable>
        </>
    );
};

export default RecoveryCode;
