// WordModalGuide

import React, { useLayoutEffect } from "react";
import { Button, Typography } from "@mui/material";
import { Assistant } from "@mui/icons-material";
import GuideContent from "../elements/GuideContent";
import { useTour } from "../../contextProviders/TourContext";
import { useFirstSearchRuleSetter } from "../../contextProviders/FirstSearchRuleContext";

interface Props {
    hasSearchrules: boolean;
}

/**
 * @param step CurrentStep in tender searchwizard
 * @returns Returns current step as title and a button to open guide/tour
 */
const DomainModalGuide: React.FC<Props> = ({ hasSearchrules }) => {
    //accent color
    const blue = "#173357";
    // first searchrule global state
    const { domainModalTourOpen, setDomainModalTourOpen } = useFirstSearchRuleSetter();
    // tour variables to set
    // locktour => true means item is interactive and no next/prev button will be shown
    const { setTourVariables, setOpenTour } = useTour();

    /**
     * SPLIT IN 2 COMPONENTS
     * BUTTON
     * TOUR TO START
     */
    const steps = () => {
        return [
            /**
             * step 1: Explain right grid (tenderdetailpage layout)
             */
            {
                selector: '[data-tut="reactour__domainOptions"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Kies zoekdomein"
                            content={
                                <div>
                                    <span id="textToReplace">
                                        Standaard wordt gezocht in de <span style={{ color: "#173351", fontWeight: 500 }}>Samenvatting</span> van een
                                        aanbesteding. Hierbij wordt met jouw zoekwoorden gezocht in de:
                                        <ul id="list">
                                            <li>titel van de aanbesteding;</li>
                                            <li>naam aanbestedende dienst;</li>
                                            <li>(verrijkte) omschrijving;</li>
                                            <li>(verrijkte) omvang.</li>
                                        </ul>{" "}
                                        Je kunt ook kiezen voor{" "}
                                        <span style={{ color: "#173351", fontWeight: 500 }}>Samenvatting + gemarkeerde documenten</span> of{" "}
                                        <span style={{ color: "#173351", fontWeight: 500 }}>Samenvatting + alle documenten</span>.
                                    </span>
                                </div>
                            }
                        />
                    );
                },
                stepInteraction: true,
            },
            /**
             * Step 2: next
             */
            {
                selector: '[data-tut="reactour__nextBtn"]',
                stepInteraction: true,
                content: () => {
                    return (
                        <GuideContent
                            title="Volgende stap"
                            content={
                                <span>
                                    Klik op{" "}
                                    <span
                                        style={{
                                            backgroundColor: "#173351",
                                            color: "#ffffff",
                                            padding: "2px 8px",
                                            display: "inline-flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <Typography style={{ fontSize: "0.7rem" }} variant="button">
                                            volgende
                                        </Typography>
                                    </span>{" "}
                                    om verder te gaan
                                </span>
                            }
                        />
                    );
                },
            },
        ];
    };

    /**
     * Check if user has searchrules and domainModalTourOpen = false
     * true => open tour on default for current step
     */
    useLayoutEffect(() => {
        if (hasSearchrules && !domainModalTourOpen) {
            // timeout for smoother transition
            setTimeout(() => {
                setTourVariables(steps, blue, "TenderWizardDomain");
                setOpenTour(true);
                setDomainModalTourOpen(true);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domainModalTourOpen, hasSearchrules]);

    return (
        <Button
            data-tut="reactour__tourbtn"
            variant="text"
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                setTourVariables(steps, blue, "TenderWizardDomain");
                setOpenTour(true);
            }}
            startIcon={<Assistant fontSize="small" />}
        >
            Interactieve assistent
        </Button>
    );
};

export default DomainModalGuide;
