import React, { useState, useEffect } from "react";
import { InputAdornment, List, TextField, CircularProgress, Popper, Autocomplete } from "@mui/material";
import { useQuery } from "@apollo/client";

//import components
// import HistoryItem from "./HistoryItem";
import DocResultItem from "./DocResultItem";

// icon for this component
import SearchIcon from "@mui/icons-material/Search";

import {
    SearchTenderDocuments,
    SearchTenderDocumentsVariables,
    SearchTenderDocuments_searchDocuments,
} from "../../../../__generated__/SearchTenderDocuments";
import { debounce } from "lodash";
import DocumentDrawer from "../../../../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { QUERY_SEARCH_TENDER_DOCUMENTS } from "../../../../graphql/queryDefSearchAndHighlighting";

interface Props {
    tenderId: string;
    onFileClick?: (folder: number) => void;
    relevantDocumentIds?: number[];
    onFileClickData?: (folder: { id: any; label: any; link: any }) => void;
}

// Custom popper
// - disabled flip
const CustomPopper = (props: any) => {
    return (
        <Popper
            {...props}
            placement="bottom"
            disablePortal={false}
            modifiers={{
                flip: {
                    enabled: false,
                },
            }}
        />
    );
};
interface OpenProps {
    open: boolean;
    link: string;
    label: string;
    doc_id: string;
    tender_id: string;
}

const Searchbar: React.FC<Props> = ({ tenderId, onFileClick, relevantDocumentIds, onFileClickData }) => {
    const [options, setOptions] = useState<SearchTenderDocuments_searchDocuments[]>([] as any[]);
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState<string>("");
    const { trackSiteSearch } = useMatomo();
    const blue = "#173357";
    const [docDrawer, setDocDrawer] = useState<OpenProps>({ open: false, link: "", label: "", doc_id: "", tender_id: "" });
    // Track documentsearch
    const trackDocumentSearch = (q: string) => {
        trackSiteSearch({ keyword: q, category: "Tender", href: window.location.href });
    };

    const closeDrawer = () => {
        setDocDrawer({ open: false, link: "", label: "", doc_id: "", tender_id: "" });
    };

    const { loading, data } = useQuery<SearchTenderDocuments, SearchTenderDocumentsVariables>(QUERY_SEARCH_TENDER_DOCUMENTS, {
        variables: { tender_id: tenderId, query },
        skip: query.length <= 3,
        fetchPolicy: "network-only",
    });

    const debouncedSearch = debounce(async (newQuery) => {
        setQuery(newQuery);
    }, 300);

    const handleQueryChange = (newQuery: string) => {
        debouncedSearch(newQuery);
        if (newQuery.length > 2) {
            trackDocumentSearch(newQuery);
        }
    };

    useEffect(() => {
        let active = true;

        if (loading === true) {
            return undefined;
        }

        (async () => {
            if (active && data?.searchDocuments !== undefined) {
                setOptions(data.searchDocuments);
            }
        })();

        return () => {
            active = false;
        };
    }, [data?.searchDocuments, loading, query]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        // Searchbar with dropdownlist
        <React.Fragment>
            <Autocomplete
                multiple={onFileClick && relevantDocumentIds && onFileClickData ? true : false}
                disableCloseOnSelect={onFileClick && relevantDocumentIds && onFileClickData ? true : false}
                size="small"
                open={open}
                onOpen={() => {
                    if (query.length > 3 && options.length > 0) {
                        setOpen(true);
                    }
                }}
                onClose={() => {
                    setOpen(false);
                }}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option.filename}
                options={options}
                loading={loading}
                loadingText=""
                noOptionsText={"Geen resultaten gevonden"}
                style={{ flexGrow: 1, margin: "8px 0 8px 8px" }}
                PopperComponent={CustomPopper}
                renderOption={(props, option, state, ownerState) => {
                    return (
                        <React.Fragment>
                            {/* liststyle when system has matching documents */}
                            <List style={{ flexGrow: 1, width: "100%", padding: 0, margin: 0 }}>
                                <DocResultItem
                                    onFileClick={onFileClick}
                                    onFileClickData={onFileClickData}
                                    relevantDocumentIds={relevantDocumentIds}
                                    setDocDrawer={setDocDrawer}
                                    tender_id={tenderId}
                                    file_name={option.filename}
                                    document_id={option.document?.id}
                                    temp_link={option.document?.temporary_link}
                                    titleParts={option.highlighting?.filename || option.filename}
                                    fragParts={option.highlighting?.content || ""}
                                    date={option.dispatchdate}
                                    icon={option.document?.extension ? option.document?.extension : null}
                                />
                            </List>
                        </React.Fragment>
                    );
                }}
                // searchIcon in textfield
                renderInput={(params) => {
                    params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    );
                    return (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Zoeken naar of in documenten..."
                            onChange={(e) => {
                                handleQueryChange(e.target.value);
                                e.target.value.length <= 3 ? setOpen(false) : setOpen(true);
                            }}
                            value={query}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    );
                }}
            />
            {/* Drawer that contains clicked pdf file */}
            {typeof docDrawer.link === "string" && docDrawer.open === true && typeof docDrawer.label === "string" && (
                <DocumentDrawer
                    open={docDrawer.open}
                    onClose={closeDrawer}
                    title={docDrawer.label}
                    color={blue}
                    url={docDrawer.link}
                    doc_id={docDrawer.doc_id}
                    module_item_id={docDrawer.tender_id}
                />
            )}
        </React.Fragment>
    );
};

export default Searchbar;
