import React, { useState } from "react";
import { CircularProgress, Dialog, DialogContent, DialogTitle, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { Check, KeyboardArrowRight } from "@mui/icons-material";
import VerifyTwoFactor from "./2FA/VerifyTwoFactor";
// import DisableTwoFactor from "./2FA/DisableTwoFactor";
import ChooseOption from "./2FA/ChooseOption";
import { useAuthSettings } from "./2FA/AuthSettingsContext";
import ShowRecoveryCodes from "./2FA/ShowRecoveryCodes";

interface Props {
    a?: number;
}

const TwoFactorBox: React.FC<Props> = (props) => {
    const { loading, setActiveStep, data_two_factor, activeStep, qrCodeUrl, error, setClickedOption } = useAuthSettings();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setClickedOption("");
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setActiveStep(0);
            setClickedOption("");
        }, 700);
    };

    return (
        <>
            {/* LISTITEM */}
            <ListItem disablePadding onClick={handleClickOpen}>
                <ListItemButton sx={{ paddingLeft: "32px" }}>
                    <ListItemText
                        primary={
                            <Typography fontSize={"1rem"} fontWeight={500}>
                                Verificatie in twee stappen
                            </Typography>
                        }
                        secondary={<Typography variant="caption">Extra beveiligingslaag aan je account toevoegen</Typography>}
                    />
                    {loading.loadingState ? (
                        <CircularProgress size={20} sx={{ color: "#508c79", marginRight: "14px" }} />
                    ) : data_two_factor?.currentUser.two_factor_email_enabled ? (
                        <>
                            Ingeschakeld
                            <Check sx={{ color: "#508c79", marginLeft: "4px", marginRight: "14px" }} />
                        </>
                    ) : data_two_factor?.currentUser.two_factor_totp_enabled ? (
                        <>
                            Ingeschakeld
                            <Check sx={{ color: "#508c79", marginLeft: "4px", marginRight: "14px" }} />
                        </>
                    ) : (
                        <>Inschakelen</>
                    )}
                    <KeyboardArrowRight />
                </ListItemButton>
            </ListItem>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={data_two_factor?.currentUser.two_factor_totp_enabled || data_two_factor?.currentUser.two_factor_email_enabled ? "md" : "lg"}
                fullWidth
            >
                <DialogTitle sx={{ paddingBottom: "28px" }}>Verificatie in twee stappen</DialogTitle>

                <DialogContent>
                    {/* CHOSEN FOR AUTHENTICATOR */}
                    {activeStep === 1 ? (
                        <>
                            <VerifyTwoFactor
                                QRcodeURL={qrCodeUrl}
                                waitForQrCode={loading.enable2FA}
                                error={Boolean(error.enable2FA)}
                                cancel={handleClose}
                                setActiveStep={setActiveStep}
                            />
                        </>
                    ) : activeStep === 99 ? (
                        <ShowRecoveryCodes error={Boolean(error.enable2FA)} setActiveStep={setActiveStep} />
                    ) : (
                        // CHOOSE AUTHENTICATOR OR MAIL
                        <ChooseOption cancel={handleClose} />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TwoFactorBox;
