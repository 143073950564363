import React, { useState } from "react";
import { Box, Button, Card, Collapse, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

interface Props {
    bgColor: string;
    image: string;
    eventTitle: string;
    smaller: boolean;
    eventSubTitle: string;
    eventSmallText: string;
    eventTime: string;
    eventText: string;
    programTextSneakPreview: string;
}

const PresentationCard: React.FC<Props> = ({
    bgColor,
    image,
    eventTitle,
    smaller,
    eventSubTitle,
    eventSmallText,
    eventTime,
    eventText,
    programTextSneakPreview,
}) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Card
            elevation={1}
            style={{
                flex: 1,
                borderRadius: "16px",
                position: "relative",
                overflow: "hidden",
                display: "flex",
                alignItems: "flex-end",
                backgroundColor: bgColor,
                height: smaller && !noMobile ? "100px" : "50vh",
            }}
        >
            <div
                style={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "#173357", // Replace with actual value
                    mixBlendMode: "lighten", // Replace with actual value
                    zIndex: 1,
                }}
            />
            {image && (
                <img
                    style={{
                        filter: `grayscale(100%) contrast(0.5) blur(${expanded ? "6px" : 0})`,
                        flex: `1 0 100%`,
                        height: `100%`,
                        maxWidth: `100%`,
                        mixBlendMode: "multiply",
                        objectFit: "cover",
                        position: "relative",
                        width: "100%",
                        objectPosition: "50% 0%",
                        transform: eventTitle === "Coffee break" ? "scaleX(-1)" : "none",
                    }}
                    src={image}
                    alt=""
                />
            )}

            {smaller && noMobile ? (
                // Show this div for static events like coffee break etc. on bigger devices
                <div
                    style={{
                        position: "absolute",
                        padding: "12px",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "#fff", // Text color
                        fontSize: "24px", // Adjust font size as needed
                        textAlign: "center",
                        zIndex: 2,
                    }}
                >
                    <Typography variant="h1" fontWeight={500} gutterBottom textAlign={"center"}>
                        {eventTitle === "Netwerkborrel" ? "Netwerk borrel" : eventTitle}
                    </Typography>
                    <Typography variant="h5" style={{ width: "100%", color: "#fff", lineHeight: 1 }} fontWeight={500} textAlign={"center"}>
                        {eventTitle === "Netwerkborrel" ? "Circa" : ""} <br />
                        {eventTime}
                    </Typography>
                </div>
            ) : smaller ? (
                <div
                    style={{
                        position: "absolute",
                        padding: "24px",
                        color: "#fff", // Text color
                        fontSize: "24px", // Adjust font size as needed
                        textAlign: "center",
                        zIndex: 2,
                    }}
                >
                    <Typography variant="h1" fontWeight={500} textAlign={"left"}>
                        {eventTitle}
                    </Typography>
                    {/* static event => Show time */}

                    <Typography variant="h5" style={{ width: "100%", color: "#fff", lineHeight: 1 }} fontWeight={500} textAlign={"left"}>
                        {eventTitle === "Netwerkborrel" ? "Circa" : ""} {eventTime}
                    </Typography>
                </div>
            ) : (
                // show if its a mobile or not an static event
                <div
                    style={{
                        position: "absolute",
                        padding: "24px",
                        color: "#fff", // Text color
                        fontSize: "24px", // Adjust font size as needed
                        textAlign: "center",
                        zIndex: 2,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="h1" fontWeight={500} textAlign={"left"}>
                        {eventTitle}
                    </Typography>

                    <>
                        <div style={{ marginBottom: "16px" }}>
                            <Typography textAlign={"left"} variant="h5">
                                {eventSubTitle}
                            </Typography>
                            <Typography variant="h6" textAlign={"left"}>
                                {eventSmallText}
                            </Typography>
                        </div>
                        <div style={{ position: "relative" }}>
                            <TransitionGroup>
                                {!expanded && (
                                    <Collapse timeout={250}>
                                        <Typography textAlign={"left"}>{programTextSneakPreview}</Typography>
                                    </Collapse>
                                )}
                            </TransitionGroup>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <Box sx={{ height: "100%", overflow: "auto" }}>
                                    <Typography textAlign={"left"}>{eventText}</Typography>
                                </Box>
                            </Collapse>
                        </div>
                    </>
                    <Button sx={{ color: "#fff", alignSelf: "center", justifyContent: "center" }} onClick={handleToggle}>
                        {expanded ? "Sluiten" : "Lees meer"}
                    </Button>
                </div>
            )}
        </Card>
    );
};

export default PresentationCard;
