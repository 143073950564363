import React from "react";
import { Box, Button, Checkbox, Divider, FormControlLabel, InputAdornment, Popover, TextField, Typography } from "@mui/material";
import { PersonAdd, Search } from "@mui/icons-material";
import { Disable } from "react-disable";
import { Link } from "react-router-dom";

interface Props {
    anchorEl: any;
    handleCloseSub: (e: any) => void;
    shareOpened: boolean;
    teamsComponent: JSX.Element[];
    colleaguesComponents: JSX.Element[];
    handleSearchUser: (event: any) => void;
    searchTerm: string;
    onShareTender: () => void;
    openChatAfterShare: boolean;
    setOpenChatAfterShare: (bln: boolean) => void;
    disableShare: boolean;
    showPlaceholder: boolean;
}

const ShareTenderButtonComponent: React.FC<Props> = ({
    anchorEl,
    handleCloseSub,
    shareOpened,
    teamsComponent,
    colleaguesComponents,
    handleSearchUser,
    searchTerm,
    onShareTender,
    openChatAfterShare,
    setOpenChatAfterShare,
    disableShare,
    showPlaceholder,
}) => {
    return (
        <Popover
            anchorEl={anchorEl}
            open={shareOpened}
            onClose={handleCloseSub}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            /**
             * Width and height for chat/knowledge base
             */
            slotProps={{
                paper: {
                    sx: {
                        width: "300px",
                    },
                },
            }}
            // PaperProps={{
            //     style: { width: "300px" },
            // }}
        >
            {showPlaceholder ? (
                <div>
                    {/*
                     * Searchbox
                     */}
                    <Box style={{ width: "100%", display: "flex" }}>
                        <TextField
                            disabled
                            onClick={(e) => e.stopPropagation()}
                            sx={{ margin: "10px", flexGrow: 1 }}
                            placeholder="Zoek een gebruiker..."
                            size="small"
                            variant="outlined"
                            value={searchTerm}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ height: "310px", overflow: "auto" }}>
                        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <PersonAdd style={{ color: "#f2f2f2", height: "8rem", width: "8rem" }} />
                            <Typography variant="body1" style={{ color: "#d4d4d4", fontSize: "1.15rem" }}>
                                Geen gebruikers gevonden
                            </Typography>
                            <Link to="/profile/users-teams" style={{ color: "#173357b0" }}>
                                Gebruikers toevoegen
                            </Link>
                        </div>
                    </Box>
                    <Disable disabled>
                        <Divider />
                        <Box style={{ display: "flex", justifyContent: "flex-end", marginRight: 8 }}>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={openChatAfterShare} />}
                                label="Open chat na delen"
                                labelPlacement="start"
                            />
                        </Box>
                    </Disable>

                    {/* Buttons to cancel or share */}
                    <Box style={{ margin: 8, marginTop: 0, display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            variant="contained"
                            // color="primary"
                            sx={{ backgroundColor: "#173357", color: "#ffffff" }}
                            onClick={(e) => {
                                handleCloseSub(e);
                            }}
                        >
                            Sluiten
                        </Button>
                    </Box>
                </div>
            ) : (
                <div>
                    {/*
                     * Searchbox
                     */}
                    <Box style={{ width: "100%", display: "flex" }}>
                        <TextField
                            onClick={(e) => e.stopPropagation()}
                            sx={{ margin: "10px", flexGrow: 1 }}
                            placeholder="Zoek een gebruiker..."
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                                e.stopPropagation();
                                handleSearchUser(e);
                            }}
                            value={searchTerm}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ height: "310px", overflow: "auto" }}>
                        {teamsComponent}
                        {colleaguesComponents}
                    </Box>
                    <div>
                        <Divider />
                        <Box style={{ display: "flex", justifyContent: "flex-end", marginRight: 8 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox size="small" checked={openChatAfterShare} onChange={() => setOpenChatAfterShare(!openChatAfterShare)} />
                                }
                                label="Open chat na delen"
                                labelPlacement="start"
                            />
                        </Box>
                    </div>

                    {/* Buttons to cancel or share */}
                    <Box style={{ margin: 8, marginTop: 0, display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            variant="text"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCloseSub(e);
                            }}
                        >
                            Annuleren
                        </Button>
                        <Button
                            disabled={disableShare}
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                onShareTender();
                            }}
                        >
                            Delen
                        </Button>
                    </Box>
                </div>
            )}
        </Popover>
    );
};

export default ShareTenderButtonComponent;
