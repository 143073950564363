import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import MainLayout from "../../components/layout/MainLayout";
import setTheme from "../../utils/setTheme";
import { materialTheme } from "../../assets/styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    a?: number;
}

setTheme("blue");

const RequestModule: React.FC<Props> = ({ children }) => {
    const { t } = useTranslation();

    return (
        <ThemeProvider theme={materialTheme}>
            <MainLayout
                page="demo"
                header={
                    <div id="DemoPage" style={{ display: "flex", height: "100%", alignItems: "center" }}>
                        <Typography variant="button" sx={{ paddingLeft: "20px", margin: { xs: "16px 0px", md: undefined } }}>
                            {t("SupportTimeSlot")}
                        </Typography>
                    </div>
                }
            >
                {children}
            </MainLayout>
        </ThemeProvider>
    );
};

export default RequestModule;
