import React from "react";

import { Typography } from "@mui/material";
import { Schedule, Check } from "@mui/icons-material";
import Example from "../../assets/helperImg.png";

interface Props {
    // checked: boolean;
    // setChecked: (checked: boolean) => void;
    a?: number;
}

const LeftExplanationPanel: React.FC<Props> = (props) => {
    const lightGrey = "#9e9e9e";

    // const handleChange = () => {
    //     if (checked) {
    //         setChecked(false);
    //     } else {
    //         setChecked(true);
    //     }
    // };

    return (
        <React.Fragment>
            <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: "20px" }}>
                Vraag een schermdeelsessie aan
            </Typography>
            <div
                style={{
                    maxWidth: "400px",
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                }}
            >
                <img src={Example} alt="fireSpot" style={{ width: "100%" }} />
            </div>
            <Typography style={{ display: "flex", alignItems: "center" }}>
                <Schedule style={{ marginRight: 4, color: lightGrey }} /> 20-30 min
            </Typography>
            {/* Block with google meet text and icons */}
            <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
                <img
                    src="https://ssl.gstatic.com/calendar/images/conferenceproviders/logo_meet_2020q4_192px.svg"
                    alt="Google Meet"
                    style={{ width: 20, height: 20, margin: "1px 4px 0px 3px" }}
                />
                {/* <VideocamIcon style={{ marginRight: 4, color: lightGrey }} /> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Google Meet</Typography>
                    <Typography variant="caption" style={{ marginTop: -5, marginLeft: 1 }}>
                        Informatie over Google Meet ontvang je in de bevestigingsmail
                    </Typography>
                </div>
            </div>
            {/* Block with expectations,
                            TODO: translate */}
            <div style={{ marginTop: 12 }}>
                <Typography variant="h6">Wat kun je verwachten?</Typography>
                <Typography style={{ display: "flex", alignItems: "center", marginLeft: 4, marginBottom: 3 }}>
                    <Check style={{ color: "green", width: "0.8em", height: "0.8em", marginRight: 4 }} />
                    Via scherm delen ondersteuning in jouw eigen omgeving
                </Typography>
                <Typography style={{ display: "flex", alignItems: "center", marginLeft: 4, marginBottom: 3 }}>
                    <Check style={{ color: "green", width: "0.8em", height: "0.8em", marginRight: 4 }} />
                    Stel je vragen aan een supportmedewerker
                </Typography>
                {/* <Typography style={{ display: "flex", alignItems: "center", marginLeft: 4, marginBottom: 3 }}>
                    <Check style={{ color: "green", width: "0.8em", height: "0.8em", marginRight: 4 }} /> Gratis voorproefje van jouw
                    gepersonaliseerde omgeving
                </Typography> */}
            </div>
        </React.Fragment>
    );
};

export default LeftExplanationPanel;
