import React from "react";
import FullPageSpinner from "../../loading/FullPageSpinner";

interface Props {
    loading: boolean;
}

const UserListLoader: React.FC<Props> = ({ loading }) => {
    return <React.Fragment>{loading && <FullPageSpinner />}</React.Fragment>;
};

export default UserListLoader;
