import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Tooltip, tooltipClasses, styled, TooltipProps } from "@mui/material";
import {
    GetUserSearchRules_currentUser_searches,
    GetUserSearchRules_currentUser_searches_filters_with_labels,
} from "../../../__generated__/GetUserSearchRules";
import { styleOperators } from "../../../components/input/AdvancedQueryField";

interface Props {
    filterValues: (GetUserSearchRules_currentUser_searches_filters_with_labels | null)[] | null;
    searchline: GetUserSearchRules_currentUser_searches;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 800,
    },
});

const SearchLineSummarySentence: React.FC<Props> = ({ searchline, filterValues }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px" }}>
                Ik zoek aanbestedingen
            </Typography>
            {/* If searchline contains filter country/countries => show typography component with data */}
            {filterValues !== null &&
                filterValues
                    .filter((fltr) => fltr?.key === "country")
                    .map((c, i) => {
                        return (
                            <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px" }} key={i}>
                                {filterValues?.filter((f) => f?.key === "country").length > 0 && `uit`}{" "}
                                <strong>{c?.labels?.map((l) => l).join(" en ")}</strong>
                            </Typography>
                        );
                    })}
            {/* <Typography variant="body2" sx={{        display: "inline-block",
        color:"#707070",
        marginRight: "4px",
        cursor: "pointer",}}>
            <FormControl>
                <Select classes={{ root: classes.selectField, icon: classes.selectFieldIcon }} disableUnderline value={"Gebied"}>
                    <ListSubheader className={classes.groupHeading}>Land</ListSubheader>
                    <MenuItem value={1}>Nederland</MenuItem>
                    <MenuItem value={2}>België</MenuItem>
                    <ListSubheader className={classes.groupHeading}>Provincie</ListSubheader>
                    <MenuItem value={3}>Limburg</MenuItem>
                    <MenuItem value={4}>Gelderland</MenuItem>
                    <ListSubheader className={classes.groupHeading}>Gemeenten</ListSubheader>
                    <MenuItem value={5}>Venlo</MenuItem>
                    <MenuItem value={6}>Venray</MenuItem>
                </Select>
            </FormControl>
        </Typography> */}

            {/* If searchline contains query => show typography component with query data */}
            {searchline.query && (
                <>
                    met{" "}
                    <CustomWidthTooltip
                        enterNextDelay={100}
                        placement="top"
                        PopperProps={{
                            style: { marginBottom: -10 },
                        }}
                        title={<span>{styleOperators(searchline.query, "#f4f4f4", t, true)}</span>}
                    >
                        <Typography
                            variant="body2"
                            sx={{ display: "inline-block", color: "#707070", marginRight: "4px", cursor: "pointer", fontWeight: "bold" }}
                        >
                            zoekwoorden
                        </Typography>
                    </CustomWidthTooltip>
                </>
            )}

            {searchline.query && searchline.target && (
                <>
                    in{" "}
                    <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px" }}>
                        <strong> {t(searchline.target)} </strong>
                    </Typography>
                </>
            )}

            {filterValues !== undefined && filterValues !== null && filterValues.filter((f) => f?.key !== "country").length > 0 && (
                <>
                    <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px" }}>
                        {searchline.query !== null && searchline.query.length > 0 ? "en de" : "met de"}{" "}
                        {filterValues?.length > 1 ? "filters" : "filter"}
                        {filterValues
                            .filter((fltr) => fltr?.key !== "country")
                            .map((f, i: number) => {
                                // remove country from list of filters to const to use as params for " en " and ", "
                                const filterWithoutCountry = filterValues.filter((fltr) => fltr?.key !== "country");
                                return (
                                    <>
                                        <Tooltip
                                            key={i}
                                            enterNextDelay={100}
                                            placement="top"
                                            PopperProps={{
                                                style: { marginBottom: -10 },
                                            }}
                                            title={
                                                <>
                                                    {f?.labels?.map((l) => {
                                                        return <div key={l}>{l}</div>;
                                                    })}
                                                </>
                                            }
                                        >
                                            <Typography style={{ display: "inline-flex", cursor: "pointer" }}>
                                                <strong style={{ marginLeft: 4, marginRight: filterWithoutCountry?.length - 2 === i ? 4 : 0 }}>
                                                    {t(`filter.${f?.key}`).toLowerCase()}
                                                </strong>
                                            </Typography>
                                        </Tooltip>
                                        {filterWithoutCountry.length - 2 === i ? "en" : i !== filterWithoutCountry.length - 1 ? "," : ""}
                                    </>
                                );
                            })}
                    </Typography>
                </>
            )}

            {/* select searchterms */}
            {/* <FormControl>
            <Select
                disableUnderline
                multiple
                value={searchterm}
                onChange={handleChange}
                input={<Input />}
                classes={{ root: classes.selectField, icon: classes.selectFieldIcon }}
            >
                <MenuItem value="1">name</MenuItem>
            </Select>
        </FormControl> */}
        </React.Fragment>
    );
};

export default SearchLineSummarySentence;
