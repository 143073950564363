import React, { useEffect, useRef, useState } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import { GetAllProjectsDashboard_projects_contractingAuthorities } from "../../../__generated__/GetAllProjectsDashboard";
import FaviconBox from "../../../components/FaviconBox";
import { LightTooltip } from "../../projects/columnView/projectcard/ConnectedEntitities";

interface Props {
    data: (GetAllProjectsDashboard_projects_contractingAuthorities | null)[] | null;
}

const MyProjectFaviconBox: React.FC<Props> = ({ data }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    // const [containerWidth, setContainerWidth] = useState(0);
    const [itemsToShow, setItemsToShow] = useState<(GetAllProjectsDashboard_projects_contractingAuthorities | null)[]>([]);
    const [itemsToHide, setItemsToHide] = useState<(GetAllProjectsDashboard_projects_contractingAuthorities | null)[]>([]);

    useEffect(() => {
        if (containerRef.current && data) {
            // setContainerWidth(containerRef.current.offsetWidth);
            const showable = Math.floor(containerRef.current.offsetWidth / 26);
            setItemsToShow(data?.slice(0, showable));
            setItemsToHide(data?.slice(showable));
        }
    }, [containerRef.current?.offsetWidth, data]);

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Grid ref={containerRef} container style={{ margin: "12px 0px 8px", width: "90%" }}>
                {itemsToShow.map((ca) => {
                    return (
                        <Tooltip key={ca?.id} title={ca?.name} placement="right" disableInteractive>
                            <Grid item sx={{ marginTop: "8px" }}>
                                <FaviconBox favicon={ca?.favicon ?? null} name={ca?.name} marginTop={0} />
                            </Grid>
                        </Tooltip>
                    );
                })}
            </Grid>
            {itemsToHide.length > 0 && (
                <LightTooltip
                    arrow
                    placement="left"
                    title={
                        <div>
                            {itemsToHide.map((i) => {
                                return (
                                    <div
                                        key={i?.id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: itemsToHide.length === 1 ? "0px" : "6px",
                                        }}
                                    >
                                        <FaviconBox favicon={null} id={i?.id} name={i?.name} />
                                        <span style={{ fontSize: "14px", lineHeight: 1.1 }}>
                                            {i?.name} <br />
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    }
                >
                    <Typography variant="caption" sx={{ marginTop: "8px", cursor: "pointer" }}>
                        en {itemsToHide.length} meer
                    </Typography>
                </LightTooltip>
            )}
        </div>
    );
};

export default MyProjectFaviconBox;
