import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Paper, Typography, Avatar, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import {
    GetUserLogsWithFilters_activities_data,
    GetUserLogsWithFilters_activities_data_subject_Search,
} from "../../../__generated__/GetUserLogsWithFilters";
import { Person, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import moment from "moment";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineItem as MuiTimelineItem,
} from "@mui/lab";

interface Props {
    item: any;
    log: GetUserLogsWithFilters_activities_data;
}

/**
 * This is used for the advanced search
 * timeline component adds this styling automatically,
 * Now its overruled so its aligned on the left side.
 */
const TimelineItem = withStyles(MuiTimelineItem, {
    missingOppositeContent: {
        "&:before": {
            display: "none",
        },
    },
    content: {
        flex: 20,
    },
    oppositeContent: {
        flex: 3,
    },
});

const StyledLink = styled(Link)`
    color: #000;
    text-decoration: underline;
    &:hover {
        background-color: #cccccc;
    }
`;

const StyledSpan = styled("span")`
    color: #9f9f9f;
    text-decoration: underline;
    cursor: pointer;
    font-style: italic;
    &:hover {
        background-color: #f4f4f4;
    }
`;

const ProfileTimelineItem: React.FC<Props> = ({ log, item }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const showLogMessage = (action: string) => {
        // sent searchrule
        if (action === "sent") {
            return (
                // return searchrule received log
                <span>
                    een kopie van zoekregel{" "}
                    {log.subject !== null ? (
                        <StyledLink
                            to={`/profile/search-rules`}
                            onClick={() => {
                                trackevents({ category: "Personal", action: "Click-go-to-detailpage-from-search-log" });
                            }}
                        >
                            {`${(log.subject as GetUserLogsWithFilters_activities_data_subject_Search).namesearch}`}
                        </StyledLink>
                    ) : (
                        <StyledSpan>zoekregel niet gevonden</StyledSpan>
                    )}{" "}
                    verstuurd
                </span>
            );
        }
        // received searchrule
        if (action === "received") {
            return (
                // return searchrule received log
                <span>
                    zoekregel{" "}
                    {log.subject !== null ? (
                        <StyledLink
                            to={`/profile/search-rules`}
                            onClick={() => {
                                trackevents({ category: "Personal", action: "Click-go-to-detailpage-from-search-log" });
                            }}
                        >
                            {`${(log.subject as GetUserLogsWithFilters_activities_data_subject_Search).namesearch}`}
                        </StyledLink>
                    ) : (
                        <StyledSpan>zoekregel niet gevonden</StyledSpan>
                    )}{" "}
                    ontvangen
                </span>
            );
        }
        // accepted searchrule
        else if (action === "accepted") {
            return (
                // return searchrule accepted log
                <span>
                    zoekregel{" "}
                    {log.subject !== null ? (
                        <StyledLink
                            to={`/profile/search-rules`}
                            onClick={() => {
                                trackevents({ category: "Personal", action: "Click-go-to-detailpage-from-search-log" });
                            }}
                        >
                            {`${(log.subject as GetUserLogsWithFilters_activities_data_subject_Search).namesearch}`}
                        </StyledLink>
                    ) : (
                        <StyledSpan>zoekregel niet gevonden</StyledSpan>
                    )}{" "}
                    geaccepteerd
                </span>
            );
        }
        // made user sub admin
        else if (action === "made-admin") {
            return <span>is nu beheerdersaccount</span>;
        }
        return;
    };

    return (
        <>
            {isMobile ? (
                <Paper variant="outlined" sx={{ padding: "8px", marginTop: "8px", display: "flex", alignItems: "center" }}>
                    {/* searchicon */}
                    {log?.subject?.__typename === "Search" && (
                        <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                            <Search />
                        </Avatar>
                    )}
                    {/* user icon */}
                    {log?.subject?.__typename === "User" && (
                        <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                            <Person />
                        </TimelineDot>
                    )}

                    {/* searchicon */}
                    {log?.subject === null && log.action === "accepted" && (
                        <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                            <Search />
                        </Avatar>
                    )}

                    {/* searchicon */}
                    {log?.subject === null && log.action === "deleted" && (
                        <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                            <Search />
                        </Avatar>
                    )}

                    <div>
                        <Typography style={{ display: "inline-block" }}>
                            <span>
                                {log?.causer?.employee.givenname} {log.action !== "made-admin" && t("has")}
                            </span>{" "}
                            {log.action !== null && <span>{showLogMessage(log.action as string)}</span>}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {moment(log.created_at).format("LL")}, {moment(log.created_at).format("LT")}
                        </Typography>
                    </div>
                </Paper>
            ) : (
                <TimelineItem>
                    <TimelineOppositeContent sx={{ flex: 3 }}>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LL")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LT")}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {/* search icon */}
                        {log?.subject?.__typename === "Search" && (
                            <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                                <Search />
                            </TimelineDot>
                        )}
                        {/* user icon */}
                        {log?.subject?.__typename === "User" && (
                            <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                                <Person />
                            </TimelineDot>
                        )}
                        {/* searchicon */}
                        {log?.subject === null && log.action === "accepted" && (
                            <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                                <Search />
                            </TimelineDot>
                        )}

                        {/* searchicon */}
                        {log?.subject === null && log.action === "deleted" && (
                            <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                                <Search />
                            </TimelineDot>
                        )}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ flex: 20 }}>
                        <Paper elevation={1} sx={{ padding: "6px 16px", marginTop: "3px" }}>
                            <Typography style={{ display: "inline-block" }}>
                                <span>
                                    {log?.causer?.employee.givenname} {log.action !== "made-admin" && t("has")}
                                </span>{" "}
                                {log.action !== null && <span>{showLogMessage(log.action as string)}</span>}
                                {/* <span>{t(remove_special_characters as string)} </span> */}
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            )}
        </>
    );
};

export default ProfileTimelineItem;
