import React from "react";
import { DateCalendar } from "@mui/x-date-pickers";
import moment from "moment";
import { IconButton, Popover, Tooltip, Typography } from "@mui/material";
import { Event } from "@mui/icons-material";

interface Props {
    date: moment.Moment | null;
    updateDate: (date: moment.Moment | null) => void;
}

const TaskDatePicker: React.FC<Props> = ({ date, updateDate }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    /**
     *
     * @param event Open popover underneath button
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    /**
     * close popover
     */
    const handleClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;

    return (
        // {/* Datepicker */}
        <Tooltip title="Deadline" placement="right" disableInteractive>
            <div style={{ display: "flex", alignItems: "center" }}>
                {date !== null && (
                    <Typography textAlign={"end"} sx={{ width: "90px", marginLeft: "8px" }}>
                        {moment(date).format("l")}
                    </Typography>
                )}
                <IconButton
                    size="small"
                    onClick={(e) => {
                        handleClick(e);
                    }}
                >
                    <Event />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={(e) => {
                        handleClose(e);
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <DateCalendar
                        value={date}
                        onChange={(d) => {
                            updateDate(d);
                            setAnchorEl(null);
                        }}
                        minDate={moment()}
                    />
                </Popover>
            </div>
        </Tooltip>
    );
};

export default TaskDatePicker;
