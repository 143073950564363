import { createContext, useContext } from "react";
import {
    GetLoggedInUsersData_currentUser_contracting_authorities,
    GetLoggedInUsersData_currentUser_market_parties,
} from "../../__generated__/GetLoggedInUsersData";

export type ProfileContextType = {
    openProfile: boolean;
    setOpenProfile: (openProfile: boolean) => void;
    initials: string;
    givenname: string | null;
    middlename: string | null;
    familyname: string | null;
    org_id: string | null;
    org_name: string | null;
    org_logo: string | null;
    org_favicon: string | null;
    employee_id: string | null;
    email: string | null;
    datafetched: boolean;
    myMPs: GetLoggedInUsersData_currentUser_market_parties[];
    myCAS: GetLoggedInUsersData_currentUser_contracting_authorities[];
    setMyMPs: React.Dispatch<React.SetStateAction<GetLoggedInUsersData_currentUser_market_parties[]>>;
    setMyCas: React.Dispatch<React.SetStateAction<GetLoggedInUsersData_currentUser_contracting_authorities[]>>;
    setProfileData: (
        initials: string,
        givenname: string,
        middlename: string,
        familyname: string,
        org_id: string,
        org_name: string,
        org_logo: string,
        org_favicon: string,
        employee_id: string,
        email: string,
        datafetched: boolean
    ) => void;
};

export const ProfileContext = createContext<ProfileContextType>({
    openProfile: false,
    setOpenProfile: (openProfile: boolean) => console.warn(""),
    initials: "",
    givenname: null,
    middlename: null,
    familyname: null,
    org_id: null,
    org_name: null,
    org_logo: null,
    org_favicon: null,
    employee_id: null,
    email: null,
    datafetched: false,
    myCAS: [],
    myMPs: [],
    setMyMPs: () => console.warn(""),
    setMyCas: () => console.warn(""),
    setProfileData: (initials, givenname, middlename, familyname, org_id, org_name, org_logo, org_favicon, employee_id, email, datafetched) =>
        console.warn("no profile provider"),
});
export const useProfile = () => useContext(ProfileContext);
