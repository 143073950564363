import React from "react";
import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import UnregisterUser from "../UnregisterUser";

interface Props {
    givenName: string;
    familyname: string;
    middlename: string;
    id: number;
    showDelete: boolean;
    iAmRegistered: string | undefined;
    dontAllowDelete: boolean;
    edition: string;
    eventID: string;
}

const AddedUsers: React.FC<Props> = ({ givenName, familyname, id, showDelete, iAmRegistered, middlename, dontAllowDelete, edition, eventID }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Card
            elevation={4}
            sx={{
                minWidth: noMobile ? 175 : "100px",
                minHeight: noMobile ? 175 : "100px",
                maxWidth: noMobile ? 175 : "100px",
                maxHeight: noMobile ? 175 : "100px",
                display: "flex",
                padding: noMobile ? "16px" : "8px",
                borderRadius: "16px",
                flexDirection: "column",
            }}
        >
            {((showDelete && dontAllowDelete) || (iAmRegistered === id?.toString() && dontAllowDelete)) && (
                <Box sx={{ alignSelf: "end" }}>
                    <UnregisterUser id={id} edition={edition} eventID={eventID} />
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                <Typography variant="h3">{givenName}</Typography>
                <Typography variant="h6" sx={{ lineHeight: 1, marginTop: "2px", fontWeight: 400 }}>
                    {middlename}
                </Typography>
                <Typography variant="h5">{familyname}</Typography>
            </Box>
        </Card>
    );
};

export default AddedUsers;
