import React, { createContext, useState, ReactNode } from "react";

interface MyContextType {
    value: boolean;
    setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

export const MyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [value, setValue] = useState<boolean>(false);

    return <MyContext.Provider value={{ value, setValue }}>{children}</MyContext.Provider>;
};

export const useMyContext = (): MyContextType => {
    const context = React.useContext(MyContext);
    if (context === undefined) {
        throw new Error("useMyContext must be used within a MyProvider");
    }
    return context;
};
