import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

// Import components
import GeneralInfoCAS from "./GeneralInfoCAS";
// import Collaborations from "./Collaborations";
// import Attendees from "./attendees/Attendees";
import PurchasingPolicyTab from "./purchasingPolicy/PurchasingPolicyTab";
// import Documents from "./Documents";
import Contactmodule from "./Contactmodule";
import CADetailTabs from "../CADetailTabs";

// Import Material UI
import AppBar from "@mui/material/AppBar";

interface Props {
    id: string;
    settlingCount: number;
    policyLength: boolean;
    contacts: number | undefined;
}

const CATabs: React.FC<Props> = ({ id, settlingCount, policyLength, contacts }) => {
    const history = useLocation();
    const navigate = useNavigate();

    return (
        <div>
            {/* Appbar with all tabs for contracting authority */}
            <AppBar position="static" style={{ background: "white", color: "black", boxShadow: "none" }}>
                <CADetailTabs
                    value={history.pathname}
                    onChange={(value) => navigate(value)}
                    settlingCount={settlingCount}
                    policyLength={policyLength}
                    contacts={contacts}
                />
            </AppBar>

            {/* CA Routes
             *   settlingCount returns a number
             */}
            <Routes>
                {/* Route to general information tab */}
                <Route path={""} element={<GeneralInfoCAS id={id} />} />
                {/* Route to contact tab, overview with vcards */}
                <Route path={`contacts`} element={<Contactmodule id={id} />} />

                {/* When settlingcount is over 1, settlingtab is available with a table of all settlings */}
                {/* {settlingCount > 1 && <Route path={`settlings`} element={<div>pagina - vestigingen</div>} />} */}

                {/* Route to purchasing policy links */}
                {<Route path={`policy`} element={<PurchasingPolicyTab id={id} />} />}

                {/* Route to see all documents of contracting authority */}
                {/* <Route path={`documents`} element={<Documents />} /> */}

                {/* Route to see all collaborations of contracting authority */}
                {/* <Route path={`collaborations`} element={<Collaborations />} /> */}

                {/* Route with all partakers */}
                {/* <Route path={`partakers`} element={<Attendees />} /> */}
            </Routes>
        </div>
    );
};
export default CATabs;
