import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { ListItem } from "@mui/material";
// import MenuWidget from "../../Dashboard/ReviewedGraph/MenuWidget";
// import PiechartReview from "../../Dashboard/ReviewedGraph/PiechartReview";

interface Props {
    a?: number;
}

// const initialGraph = {
//     id: "0",
//     title: "alles",
//     totalTenders: 150,
//     liked: 83,
//     disliked: 10,
//     open: 57,
//     color: "#173357",
// };

const SearchLineStats: React.FC<Props> = (props) => {
    // state for graph, initial state = all searchrules
    // const [graph] = useState(initialGraph);
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <ListItem
            sx={{
                borderLeft: "5px solid var(--themeBlue)",
                backgroundColor: "#ffffff",
                marginBottom: "0px !important",
            }}
        >
            <div
                style={{
                    height: onlyMobile ? "300px" : "350px",
                    width: "100%",
                    padding: "20px 0 16px 20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {/* small filter menu */}
                <div style={{ width: 333 }}>{/* <MenuWidget /> */}</div>
                {/* DonutChart */}
                {/* <PiechartReview data={graph} /> */}
            </div>
        </ListItem>
    );
};

export default SearchLineStats;
