import React, { useCallback, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useTranslation } from "react-i18next";
import setTheme from "../../utils/setTheme";
import setTitle from "../../utils/setTitle";
import { greyTheme } from "../../assets/styles";
import MainLayout from "../../components/layout/MainLayout";
import ProjectContainer from "./ProjectContainer";
import { View } from "./topbar/ProjectViewToggleButtons";
import ProjectTabs, { DynamicTab } from "./ProjectTabs";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ProjectDetailPage from "./detailpage/ProjectDetailPage";

interface Props {
    a?: number;
}

const Projects: React.FC<Props> = (props) => {
    const { trackPageView } = useMatomo();
    const { t } = useTranslation();
    const history = useLocation();
    const [tabs, setTabs] = useState<DynamicTab[]>([]);
    const [view, setView] = useState<View>(1);
    const navigate = useNavigate();

    useEffect(() => {
        setTheme("darkGray");
        setTitle(t("projectmanagement"));
    });

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: "Projectmanagement", // optional
            href: "https://tool.tender.guide/projects", // optional
        });
    }, [trackPageView]);

    /**
     * Get tabs in session storage (project) and store in state
     */
    useEffect(() => {
        if (sessionStorage.getItem("opt")) {
            setTabs(JSON.parse(sessionStorage.getItem("opt") || "[]"));
        }
    }, []);

    /**
     * Register a new tab to the ProjectModule
     */
    const registerTab = useCallback((tab: DynamicTab) => {
        setTabs((prevTabs) => {
            // If the tab that is going to registered is not in the list yet add it
            if (!prevTabs.find((prevTab) => prevTab.value === tab.value)) {
                return [...prevTabs, tab];
            }
            return prevTabs;
        });
    }, []);

    /**
     * Handle closing the tab, and navigate to the last tab in the sequence
     */
    const closeTab = (url: string) => {
        setTabs((prevTabs) => {
            const newTabs = prevTabs.filter((tab) => tab.value !== url);
            // If active project is the one thats been closed -> navigate to projectpage
            // otherwise navigate to current pathname
            if (history.pathname === url) {
                navigate("/projects");
            } else {
                navigate(history.pathname);
            }
            return newTabs;
        });
    };

    /**
     * Handle closing all tabs, and navigate to all tenders
     * Dont navigate to worklist if user is on "all" or "reviews" tab
     */
    const closeAllTab = () => {
        setTabs([]);
        navigate("/projects");
    };

    /**
     * Array of default tabs
     */
    const defaultTabs = [{ id: 1, route: "/projects", label: "projectmanagement" }];

    /**
     * Update session storage when tabs change
     */
    useEffect(() => {
        sessionStorage.setItem("opt", JSON.stringify(tabs));
    }, [tabs]);

    return (
        <ThemeProvider theme={greyTheme}>
            <MainLayout
                page="projects"
                header={
                    <ProjectTabs
                        value={history.pathname}
                        onChange={(value) => navigate(value)}
                        onClose={closeTab}
                        tabs={tabs}
                        onCloseAll={closeAllTab}
                        defaultTabs={defaultTabs}
                        setView={setView}
                        view={view}
                    />
                }
            >
                <Routes>
                    {/* Tender for users routes */}
                    <Route
                        path=""
                        element={
                            <div className="responsive-container topbottom" style={{ height: "100%" }}>
                                <ProjectContainer view={view} />
                            </div>
                        }
                    />

                    {/* <Route path="new-project" element={<NewProject registerTab={registerTab} />} /> */}
                    <Route path=":id" element={<ProjectDetailPage registerTab={registerTab} />} />
                </Routes>
            </MainLayout>
        </ThemeProvider>
    );
};

export default Projects;
