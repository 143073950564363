import React from "react";
import { Divider, List, ListItemButton, ListItemSecondaryAction, ListItemText, Skeleton } from "@mui/material";

interface Props {
    a?: number;
}

const MyCalendarLoader: React.FC<Props> = (props) => {
    return (
        <List
            style={{
                paddingTop: 5,
                padding: 0,
                overflow: "auto",
                height: "355px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            {[...Array(5)].map((elem, index) => {
                return (
                    <React.Fragment key={index}>
                        <ListItemButton
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                padding: "8px",
                                backgroundColor: "#ffffff",
                                "&:hover": {
                                    backgroundColor: "#465c8433",
                                },
                            }}
                        >
                            <Skeleton variant="rounded" width={50} height={50} />

                            <ListItemText
                                sx={{
                                    marginLeft: "16px",
                                    marginTop: "3px",
                                    marginBottom: "3px",
                                    marginRight: "60px",
                                    "& .MuiListItemText-root": {
                                        marginRight: "60px",
                                    },
                                }}
                                primary={
                                    <div>
                                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"70%"} />
                                    </div>
                                }
                                secondary={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Skeleton variant="circular" width={12} height={12} sx={{ marginRight: "4px", marginTop: "1px" }} />

                                        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "90%" }} />
                                    </div>
                                }
                            />
                            <ListItemSecondaryAction sx={{ display: "flex", alignItems: "center" }}>
                                <Skeleton variant="circular" width={40} height={40} />
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default MyCalendarLoader;
