import React from "react";
import { Tooltip, Typography } from "@mui/material";
import moment from "moment";

interface Props {
    data: (dataProp | null)[] | null;
    whiteSpace?: "normal" | "pre" | "nowrap" | "pre-wrap" | "pre-line" | "break-spaces";
    noDataSign?: string;
    displayData?: "LL" | "L";
    fontWeight?: string | number;
    fontColor?: string;
}

interface dataProp {
    __typename: "LotDate";
    lots: (number | null)[];
    value: any | null;
}

const LotDateField: React.FC<Props> = ({
    data,
    noDataSign = "",
    whiteSpace = "nowrap",
    displayData = "L",
    fontWeight = 400,
    fontColor = "#000000",
}) => {
    if (!data || data.length === 0 || data?.filter((val) => val?.value !== null).length === 0) {
        return <>{noDataSign}</>;
    }

    return (
        <React.Fragment>
            {data.map((val, i: number) => {
                /**
                 * If multiple lotnumbers are available ->  show tooltip
                 */
                if (val?.lots !== undefined && val?.lots.length > 1) {
                    return (
                        <div key={i}>
                            <Tooltip
                                disableInteractive
                                data-testid="tooltip"
                                placement={"right"}
                                title={
                                    <>
                                        Perceel:{" "}
                                        {[...val?.lots]
                                            .sort((a, b) => (a as number) - (b as number))
                                            .map((lot) => lot)
                                            .join(", ")}
                                    </>
                                }
                            >
                                <Typography
                                    style={{
                                        whiteSpace: whiteSpace,
                                        display: "inline-flex",
                                        borderBottom: `1px dashed #9e9e9e`,
                                        textDecoration: "none",
                                        cursor: "pointer",
                                    }}
                                >
                                    {val?.value ? moment(val.value).format("L") : ""}
                                </Typography>
                            </Tooltip>
                        </div>
                    );
                }

                /**
                 * If multiple dates are available with one or more lots -> show tooltip
                 */
                if (val?.lots !== undefined && val?.lots.length > 0 && data.length > 1) {
                    return (
                        <div key={i}>
                            <Tooltip
                                disableInteractive
                                data-testid="tooltip"
                                placement={"right"}
                                title={
                                    <>
                                        Perceel:{" "}
                                        {[...val?.lots]
                                            .sort((a, b) => (a as number) - (b as number))
                                            .map((lot) => lot)
                                            .join(", ")}
                                    </>
                                }
                            >
                                <Typography
                                    style={{
                                        whiteSpace: whiteSpace,
                                        display: "inline-flex",
                                        borderBottom: `1px dashed #9e9e9e`,
                                        textDecoration: "none",
                                        cursor: "pointer",
                                    }}
                                >
                                    {val?.value ? moment(val.value).format("L") : ""}
                                </Typography>
                            </Tooltip>
                        </div>
                    );
                }
                // return if no lots are available
                return (
                    <div key={i}>
                        <Typography style={{ whiteSpace: whiteSpace, fontWeight: fontWeight, color: fontColor }}>
                            {val?.value ? moment(val.value).format(displayData) : ""}
                        </Typography>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default LotDateField;
