import React, { useState } from "react";
import { Tabs, Tab, Typography, AppBar, Box, Tooltip, Theme, createStyles } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import anchorNewTab from "../../utils/anchorNewTab";
import ReadMoreComponent from "../ReadMoreComponent";
import Summary from "./Summary";
import DocumentDrawer from "../../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import {
    GetDefaultSummary_tender_omvang_opdracht_source,
    GetDefaultSummary_tender_omschrijving_source,
    GetDefaultSummary_tender_lots,
} from "../../__generated__/GetDefaultSummary";
import { useReRunHighlighting } from "../contextProviders/HighlightContext";
import { withStyles } from "tss-react/mui";

interface Props {
    lots: GetDefaultSummary_tender_lots[];
    showSource: boolean;
    description: string;
    qualitativeScope: string;
    omvang_opdracht_source: GetDefaultSummary_tender_omvang_opdracht_source | null;
    omschrijving_source: GetDefaultSummary_tender_omschrijving_source | null;
    tender_id: string;
}
// Doc drawer props
interface OpenProps {
    open: boolean;
    link: string;
    label: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

interface StyledTabsProps {
    children: any;
    value: string;
    onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
}

const StyledTabs = withStyles(
    (props: StyledTabsProps) => (
        <Tabs {...props} variant="scrollable" scrollButtons="auto" TabIndicatorProps={{ children: <span /> }} textColor="primary" />
    ),
    {
        root: {
            minHeight: "29px",
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "75px",
                width: "100%",
                backgroundColor: "#173357",
            },
        },
    }
);

interface StyledTabProps {
    label: React.ReactNode;
    value: string;
}

const StyledTab = withStyles(
    (props: StyledTabProps) => <Tab disableRipple {...props} />,
    (theme: Theme) =>
        createStyles({
            root: {
                minHeight: "35px",
                minWidth: "120px",
                maxWidth: "120px",
                padding: 0,
                textTransform: "none",
                fontSize: theme.typography.pxToRem(15),
                marginRight: theme.spacing(1),
                "&:focus": {
                    opacity: 1,
                },
            },
        })
);

const LotSummary: React.FC<Props> = ({ lots, showSource, description, qualitativeScope, omvang_opdracht_source, omschrijving_source, tender_id }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>("algemeen");
    const copyLots = [...lots];
    const { rerunHighlight, setRerunHighlight } = useReRunHighlighting();
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
        setRerunHighlight(!rerunHighlight);
    };

    /**
     * DocumentDrawer component settings
     */
    const [open, setOpen] = useState<OpenProps>({ open: false, link: "", label: "" });
    const blue = "#173357";
    const closeDrawer = () => {
        setOpen({ open: false, link: "", label: "" });
    };

    return (
        <>
            <AppBar elevation={0} position="static" color="transparent">
                <StyledTabs value={value} onChange={handleChange}>
                    <StyledTab
                        value={"algemeen"}
                        label={
                            <>
                                <span
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "100px",
                                        whiteSpace: "nowrap",
                                        display: "inline-block",
                                    }}
                                >{`Algemeen`}</span>
                            </>
                        }
                    />
                    {copyLots
                        .sort((a, b) => parseInt(a.number) - parseInt(b.number))
                        .map((lot) => {
                            return (
                                <StyledTab
                                    value={lot.id}
                                    key={lot.id}
                                    label={
                                        <>
                                            <Tooltip title={`${t("tenderPage.Lot")} ${lot.number} - ${lot.title}`}>
                                                <span
                                                    style={{
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        maxWidth: "100px",
                                                        whiteSpace: "nowrap",
                                                        display: "inline-block",
                                                    }}
                                                >{`${t("tenderPage.Lot")} ${lot.number}`}</span>
                                            </Tooltip>
                                        </>
                                    }
                                />
                            );
                        })}
                </StyledTabs>
            </AppBar>
            <TabPanel value={value} index={value}>
                {value === "algemeen" && (
                    <div style={{ marginBottom: 8, padding: 20 }}>
                        <Summary
                            tender_id={tender_id}
                            padding={0}
                            description={description}
                            qualitativeScope={qualitativeScope}
                            omvang_opdracht_source={omvang_opdracht_source}
                            omschrijving_source={omschrijving_source}
                            showSource={showSource}
                        />
                    </div>
                )}
                {lots
                    // Filter data on selected value (active tab)
                    .filter((lot) => lot.id === value)
                    .map((lot) => {
                        return (
                            <React.Fragment key={lot.id}>
                                <div style={{ marginBottom: 8, padding: 20 }}>
                                    <div style={{ marginBottom: 8 }}>
                                        <Typography variant="h4">{t("tenderPage.Description")}</Typography>
                                        {showSource && (
                                            <React.Fragment>
                                                <Typography style={{ fontStyle: "italic" }} variant="caption">
                                                    {t("Source")}:{" "}
                                                </Typography>
                                                {/*
                                                 * File as source -> show filename and element is clickable
                                                 */}
                                                {lot.description?.source.__typename === "Document" && lot.description.source.extension === "pdf" ? (
                                                    <Typography
                                                        style={{ fontStyle: "italic" }}
                                                        variant="caption"
                                                        onClick={() => {
                                                            setOpen({
                                                                link:
                                                                    lot.description?.source.__typename === "Document"
                                                                        ? lot.description.source.temporary_link
                                                                        : "",
                                                                open: lot.description?.source.__typename === "Document" ? true : false,
                                                                label:
                                                                    lot.description?.source.__typename === "Document"
                                                                        ? lot.description.source.filename
                                                                        : "",
                                                            });
                                                        }}
                                                    >
                                                        {lot.description?.source.filename}
                                                    </Typography>
                                                ) : lot.description?.source.__typename === "Document" &&
                                                  lot.description.source.extension !== "pdf" ? (
                                                    <Typography
                                                        style={{ fontStyle: "italic" }}
                                                        variant="caption"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const element = document.createElement("a");
                                                            element.setAttribute(
                                                                "download",
                                                                `${
                                                                    lot.description?.source.__typename === "Document"
                                                                        ? lot.description.source.temporary_link
                                                                        : ""
                                                                }`
                                                            );
                                                            element.setAttribute(
                                                                "href",
                                                                `${process.env.REACT_APP_API_ROOT}/tenders/${tender_id}/downloadFile/${
                                                                    lot.description?.source.__typename === "Document" ? lot.description.source.id : ""
                                                                }`
                                                            );
                                                            element.style.display = "none";
                                                            document.body.appendChild(element);
                                                            element.click();
                                                            document.body.removeChild(element);
                                                        }}
                                                    >
                                                        {lot.description?.source.filename}
                                                    </Typography>
                                                ) : lot.description?.source.__typename === "Publication" ? (
                                                    // Publication as source -> show date, host and publication type
                                                    <Typography style={{ fontStyle: "italic" }} variant="caption">
                                                        {moment(lot.description?.source?.dispatchdate).format("LL")} - {lot.description?.source?.host}
                                                        , {lot.description?.source?.publication_type.name}
                                                    </Typography>
                                                ) : (
                                                    // Show "-" if no source is available
                                                    <Typography style={{ fontStyle: "italic" }} variant="caption">
                                                        -
                                                    </Typography>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <ReadMoreComponent
                                        text={lot.description ? lot.description.value : lot.published_description ? lot.published_description : "-"}
                                        length={1000}
                                        variant={"body2"}
                                    />

                                    {lot.qualitativeScope && (
                                        <React.Fragment>
                                            <div style={{ marginBottom: 8, marginTop: 16 }}>
                                                <Typography variant="h4">{t("tenderPage.Scope")}</Typography>
                                                {showSource && (
                                                    <React.Fragment>
                                                        <Typography style={{ fontStyle: "italic" }} variant="caption">
                                                            {t("Source")}:{" "}
                                                        </Typography>
                                                        {/*
                                                         * File as source -> show filename and element is clickable
                                                         */}
                                                        {lot.qualitativeScope?.source.__typename === "Document" ? (
                                                            <a {...anchorNewTab} href={lot.qualitativeScope.source.temporary_link}>
                                                                <Typography style={{ fontStyle: "italic" }} variant="caption">
                                                                    {lot.qualitativeScope.source.filename}
                                                                </Typography>
                                                            </a>
                                                        ) : // Publication as source -> show date, host and publication type
                                                        lot.qualitativeScope.source.__typename === "Publication" ? (
                                                            <Typography style={{ fontStyle: "italic" }} variant="caption">
                                                                {moment(lot.qualitativeScope?.source?.dispatchdate).format("LL")} -{" "}
                                                                {lot.qualitativeScope?.source?.host},{" "}
                                                                {lot.qualitativeScope?.source?.publication_type.name}
                                                            </Typography>
                                                        ) : (
                                                            // Show "-" if no source is available
                                                            <Typography style={{ fontStyle: "italic" }} variant="caption">
                                                                -
                                                            </Typography>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                            <ReadMoreComponent text={lot.qualitativeScope.value} length={1000} variant={"body2"} />
                                        </React.Fragment>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    })}
                {/* Drawer that contains clicked pdf file */}
                {typeof open.link === "string" && open.open === true && typeof open.label === "string" && (
                    <DocumentDrawer open={open.open} onClose={closeDrawer} title={open.label} color={blue} url={open.link} />
                )}
            </TabPanel>
        </>
    );
};

export default LotSummary;
