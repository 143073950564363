import React from "react";
import { GET_AWARD_DATE_COLUMN } from "../../graphql/queryDefForColumns";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { GetAwardDateColumn, GetAwardDateColumnVariables } from "../../__generated__/GetAwardDateColumn";
import moment from "moment";

interface Props {
    tender_id: string;
}

const AwardDateColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetAwardDateColumn, GetAwardDateColumnVariables>(GET_AWARD_DATE_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;
    const time = tender.datum_gunning ? moment(tender.datum_gunning).format("L") : "";

    return <Typography style={{ whiteSpace: "nowrap" }}>{time}</Typography>;
};

export default AwardDateColumn;
