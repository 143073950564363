import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_CA_DATA_FOR_FILTERITEM } from "../../../graphql/queryDefCA";
import { MenuItem, Tooltip, Typography } from "@mui/material";
import FaviconBox from "../../FaviconBox";
import { DropdownOption } from "../FilterDropdownStringVersion";
import { GetCADataForFilterItem, GetCADataForFilterItemVariables } from "../../../__generated__/GetCADataForFilterItem";

interface Props {
    id: number;
    bold: boolean;
    onAdd: (item: DropdownOption) => void;
    width: number;
}

interface FetchedCA {
    __typename?: "ContractingAuthority" | undefined;
    id: string;
    name?: string | null | undefined;
    favicon?: string | null | undefined;
}

const FilterDropDownAlreadyInSearchRule: React.FC<Props> = ({ bold, id, onAdd, width }) => {
    const [currentState, setCurrentState] = useState<FetchedCA | null>(null);
    const yellow = "#F57117";

    useQuery<GetCADataForFilterItem, GetCADataForFilterItemVariables>(GET_CA_DATA_FOR_FILTERITEM, {
        variables: { ca_id: id.toString() },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.contracting_authority) {
                setCurrentState(data.contracting_authority);
            }
        },
    });

    if (currentState === null) {
        return null;
    }

    return (
        <MenuItem
            style={{ width: width }}
            key={id}
            dense
            onClick={() => {
                onAdd({ key: currentState.name, label: currentState.name, id: parseInt(currentState.id) } as DropdownOption);
            }}
        >
            <div>
                <FaviconBox favicon={currentState.favicon || null} name={currentState.name} color={yellow} id={currentState.id} />
            </div>

            <Tooltip title={currentState.name || ""} placement="top-start" componentsProps={{ tooltip: { sx: { marginBottom: "3px" } } }}>
                <Typography variant="inherit" noWrap style={{ color: "black" }}>
                    {bold ? <strong>{currentState.name || ""}</strong> : <span>{currentState.name || ""}</span>}
                </Typography>
            </Tooltip>
        </MenuItem>
    );
};

export default FilterDropDownAlreadyInSearchRule;
