import React from "react";
import { Grid, Typography } from "@mui/material";
import { usePoll } from "../PollContext";
import FullPageSpinner from "../../../../components/loading/FullPageSpinner";
import InlinePollLayout from "./InlinePollLayout";
import InlineLobby from "./InlineLobby";
import WaitingScreen from "../WaitingScreen";
import AnswerCard from "../AnswerCard";
import moment from "moment";
import { Poll } from "@mui/icons-material";

interface Props {
    date: string;
}

const InlinePollContainer: React.FC<Props> = ({ date }) => {
    const { waiting, pollArray, sendingAnswer, loadingQuery, errorQuery, chosenAnswerID, pollStarted, currentPollId, currentQuestion, endOfPoll } =
        usePoll();

    // LOADING
    if (loadingQuery) {
        return (
            <InlinePollLayout hideLogo>
                <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <FullPageSpinner />
                </div>
            </InlinePollLayout>
        );
    }

    /**
     * Error || no poll active
     */
    if (errorQuery || !pollArray) {
        return (
            <InlinePollLayout hideLogo>
                <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography
                        sx={{
                            fontSize: "2rem !important", // Adjust font size as needed
                            fontWeight: 700, // Adjust font weight as needed
                            textAlign: "center", // Center align the text
                        }}
                    >
                        Er ging iets mis
                    </Typography>
                </div>
            </InlinePollLayout>
        );
    }

    /**
     * SHOW LOBBY TO USERS EXCEPT ADMIN & ITS EVENT DAY
     */
    if (!pollStarted && moment(date).isSame(moment(), "day")) {
        return <InlineLobby endOfPoll={endOfPoll} />;
    }

    /**
     * POLL NOT STARTED AND NOT EVENT DAY
     */
    if (!pollStarted && !moment(date).isSame(moment(), "day")) {
        return (
            <InlinePollLayout>
                <Grid container spacing={2}>
                    {/* PLACEHOLDER */}
                    <Grid item xs={12} sx={{ margin: "8px", display: "flex", flexDirection: "column", flex: 1 }}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Poll sx={{ height: "12rem", width: "12rem", color: "#82b6a07A" }} />
                            <Typography align="center" variant="h1">
                                De stellingen worden voorbereid
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </InlinePollLayout>
        );
    }
    if (endOfPoll) {
        return <InlineLobby endOfPoll={endOfPoll} />;
    }

    return (
        <InlinePollLayout>
            <Grid container spacing={2}>
                {/* QUESTION */}
                <Grid item xs={12} sx={{ margin: "8px", display: "flex", flexDirection: "column", flex: 1 }}>
                    <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography align="center" variant="h1">
                            {currentQuestion?.activeTendenzQuestion?.text}
                        </Typography>
                    </div>
                </Grid>

                <Grid item container spacing={1} sx={{ margin: "16px 8px 16px 0px", alignSelf: "end" }}>
                    {/*
                     * Waiting screen
                     */}
                    {waiting ? (
                        // Waiting screen if waiting is true
                        // => True by default and true when a user refreshes page
                        <WaitingScreen currentQuestionId={currentQuestion?.activeTendenzQuestion?.id as number} userAnswerId={chosenAnswerID} />
                    ) : (
                        <>
                            {/* Show question when waiting is not true */}
                            {currentQuestion?.activeTendenzQuestion?.answers?.map((ans, i) => {
                                if (ans === null) return <></>;
                                return (
                                    // {/* ANSWERS */}
                                    <Grid key={ans.id} item xs={12} md={6}>
                                        <AnswerCard
                                            answer={ans.text as string}
                                            index={i}
                                            onClickAnswer={() => {
                                                sendingAnswer(
                                                    // question id
                                                    currentQuestion?.activeTendenzQuestion?.id?.toString() as string,
                                                    // answer id
                                                    ans.id?.toString() as string,
                                                    // quiz id
                                                    currentPollId.toString()
                                                );
                                            }}
                                        />
                                    </Grid>
                                );
                            })}
                        </>
                    )}
                </Grid>
            </Grid>
        </InlinePollLayout>
    );
};

export default InlinePollContainer;
