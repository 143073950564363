import React from "react";
import { useQuery } from "@apollo/client";
import { GET_INITIAL_DURATION_DATA } from "../../graphql/queryDefForColumns";
import LotStringField from "../LotStringField";
import { Typography } from "@mui/material";
import { GetInitialDuration, GetInitialDurationVariables } from "../../__generated__/GetInitialDuration";

interface Props {
    tender_id: string;
}

const InitialDurationColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetInitialDuration, GetInitialDurationVariables>(GET_INITIAL_DURATION_DATA, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    return <LotStringField data={tender.contract_duration} whiteSpace="nowrap" />;
};

export default InitialDurationColumn;
