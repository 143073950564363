import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward, DragHandle } from "@mui/icons-material";
import { GetDashboardLayout_GetDashboardLayout_rows } from "../../../__generated__/GetDashboardLayout";

const ItemType = "ROW";
const ColumnType = "COLUMN"; // Define the column type

interface DraggableRowProps {
    id: number;
    editMode: boolean;
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    children: React.ReactNode;
    row: GetDashboardLayout_GetDashboardLayout_rows;
    onAddColumnToRow: (rowId: string, columnCount: number) => void;
    onHoverChange: (bln: boolean) => void;
    isHovered: boolean;
    totalRows: number;
}

const DraggableRow: React.FC<DraggableRowProps> = ({
    id,
    index,
    moveRow,
    children,
    row,
    onAddColumnToRow,
    onHoverChange,
    isHovered,
    editMode,
    totalRows,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const [over, drop] = useDrop({
        accept: ItemType,
        hover: (item: { index: number }) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { id, index },
        canDrag: editMode,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    // Drop target for COLUMN
    const [{ isOver }, dropColumn] = useDrop({
        accept: ColumnType, // Accept drops of type COLUMN
        drop: (item: any) => {
            if (row.columns.length <= 2 && row.columns.filter((c) => c.widgetId === 1).length === 0) {
                onAddColumnToRow(row.id, item.widget.id); // Assuming columnId is 0-based index
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const opacity = isDragging ? 0.2 : 1;
    // preview(drop(dropColumn(ref)));
    drop(dropColumn(ref));

    return (
        <Grid
            ref={ref}
            item
            container
            xs={12}
            spacing={2}
            style={{
                width: "99%",
                position: "relative",
                opacity: opacity,
                border: !editMode ? undefined : over ? "2px dashed grey" : "2px dashed #e1e1e1",
                borderRadius: "4px",
                padding: editMode ? "16px" : undefined,
                margin: editMode ? "8px" : undefined,
                backgroundColor: isOver && row.columns.length <= 2 && row.columns.filter((c) => c.widgetId === 1).length === 0 ? "#fff" : undefined,
            }}
            onMouseEnter={() => onHoverChange(true)}
            onMouseLeave={() => onHoverChange(false)}
        >
            {children}

            {isOver && row.columns.filter((c) => c.widgetId === 1).length !== 0 && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        color: "#fff",
                        backgroundColor: "#c0392b",
                        opacity: 0.7,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 10,
                    }}
                >
                    <Typography variant="h3" fontWeight={500}>
                        Extra widget in deze rij niet toegestaan
                    </Typography>
                </div>
            )}

            {isOver && row.columns.length >= 3 && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        color: "#fff",
                        backgroundColor: "#c0392b",
                        opacity: 0.7,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 10,
                    }}
                >
                    <Typography variant="h3" fontWeight={500}>
                        Maximaal 3 widgets per rij
                    </Typography>
                </div>
            )}

            {editMode && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "36px",
                        color: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 10,
                    }}
                >
                    <div style={{ minWidth: "24px", minHeight: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {isHovered && index !== 0 && <ArrowUpward sx={{ color: "#808080a8" }} />}
                    </div>

                    <div ref={drag}>
                        <IconButton size="medium">
                            <DragHandle />
                        </IconButton>
                    </div>
                    <div style={{ minWidth: "24px", minHeight: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {isHovered && index !== totalRows - 1 && <ArrowDownward sx={{ color: "#808080a8" }} />}
                    </div>
                </div>
            )}
        </Grid>
    );
};

export default DraggableRow;
