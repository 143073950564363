import React, { useEffect, useState } from "react"; // ,{ useState }
import Grid from "@mui/material/Grid";

import { ApolloError, useLazyQuery } from "@apollo/client";
import SplitDetailView from "./SplitDetailView";
import SplitDetailList from "./SplitDetailList";
import { Paper, Typography } from "@mui/material";
import LinearLoader from "../../../components/loading/LinearLoader";
import {
    FilterWorklistSplitView_filterTenders_results_data,
    FilterWorklistSplitView_filterTenders_results_paginatorInfo,
} from "../../../__generated__/FilterWorklistSplitView";
import { Description } from "@mui/icons-material";
import {
    GetTenderDetailSplitview,
    GetTenderDetailSplitviewVariables,
    GetTenderDetailSplitview_tender,
} from "../../../__generated__/GetTenderDetailSplitview";
import SplitDetailViewLoader from "./SplitDetailViewLoader";
import SplitTabPanel from "./SplitTabPanel";
import { highlightedSummary, highlightedSummaryVariables } from "../../../__generated__/highlightedSummary";
import { QUERY_SINLGE_TENDER_SPLITVIEW } from "../../../graphql/queryDefinitions";
import { GET_HIGHLIGHTED_SUMMARY } from "../../../graphql/queryDefSearchAndHighlighting";
import { DefaultSummary } from "../TenderDetailContainer";
import SplitViewLabels from "./SplitViewLabels";
import SplitViewAward from "./SplitviewAward";
import TooltipOnSelect from "../../../components/TooltipOnSelect";
import SplitViewTopBar from "./SplitViewTopBar";

interface Props {
    /**
     * Data to be displayed in table
     */
    rows: FilterWorklistSplitView_filterTenders_results_data[] | null | undefined;
    /**
     * Pagination info
     */
    paginatorInfo: FilterWorklistSplitView_filterTenders_results_paginatorInfo;
    /**
     * Loading state
     */
    loading: boolean;
    /**
     * Apollo error
     */
    error?: ApolloError | undefined;
    /**
     * Current page
     */
    page: number;
    /**
     * @param page current page
     * @returns new page
     */
    setPage: (page: number) => void;
    /**
     * elevate paper component (optional)
     * if not set => elevation = 0
     */
    elevation?: number;
    /**
     * Custom contracting authority data (optional)
     * Now used in ca module only
     * If not set => clients data off tender object will be used
     */
    ca?: ContractingAuthoritySplitView;
    mp?: string;
    setSelectedId: (selectedId: string) => void;
    selectedId: string;
}

export interface ContractingAuthoritySplitView {
    __typename: "ContractingAuthority";
    id: string;
    name: string | null;
    favicon: string | null;
    favicon_resized: string | null;
}

export interface HighlightObject {
    title: string;
    summary: string;
    scope: string;
}

const SplitView: React.FC<Props> = ({ rows, paginatorInfo, loading, error, page, setPage, elevation = 0, ca, mp, setSelectedId, selectedId }) => {
    // const [selectedId, setSelectedId] = useState<string>("");
    const [selectedItem, setSelectedItem] = useState<GetTenderDetailSplitview_tender>();
    const [activeTab, setActiveTab] = useState<string>("0");
    const defaultHighlight = {
        id: "",
        omschrijving: "",
        omvang: "",
        titel: "",
    };
    const [highlighting, setHighlighting] = useState<DefaultSummary | undefined>(defaultHighlight);

    const defaultHighlightData = { title: "", summary: "", scope: "" };
    const [highlightedData, setHighlightedData] = useState<HighlightObject | undefined>(defaultHighlightData);

    /**
     * Highlighted summary query
     */
    const [highlighter] = useLazyQuery<highlightedSummary, highlightedSummaryVariables>(GET_HIGHLIGHTED_SUMMARY, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.highlightedTender) {
                setHighlighting({
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    id: selectedItem!.id,
                    titel: data.highlightedTender.namegiventocontract || null,
                    omschrijving: data.highlightedTender.omschrijving || null,
                    omvang: data.highlightedTender.omvang || null,
                });
            }
        },
    });

    // use tender id to query highlighted data
    useEffect(() => {
        if (selectedItem !== null && selectedItem !== undefined) {
            highlighter({
                variables: {
                    id: selectedItem.id,
                },
            });
        }
    }, [highlighter, selectedItem]);

    useEffect(() => {
        /**
         * tender title
         * Will be updated if highlighted query has data
         */
        const tender_title =
            highlighting?.titel !== null && highlighting?.titel !== undefined
                ? highlighting.titel
                : selectedItem && selectedItem.namegiventocontract
                ? selectedItem.namegiventocontract
                : "";

        /**
         * Description
         * Will be updated if highlighted query has data
         */
        const tender_description =
            highlighting !== undefined && highlighting.omschrijving !== null
                ? highlighting.omschrijving
                : selectedItem !== undefined && selectedItem.omschrijving !== null
                ? selectedItem.omschrijving
                : "";

        /**
         * scope
         * Will be updated if highlighted query has data
         */
        const tender_scope =
            highlighting !== undefined && highlighting.omvang !== null
                ? highlighting.omvang
                : selectedItem !== undefined && selectedItem.omvang_opdracht !== null
                ? selectedItem.omvang_opdracht
                : "";
        setHighlightedData({
            title: tender_title,
            summary: tender_description,
            scope: tender_scope,
        });
    }, [selectedItem, highlighting]);

    /**
     * CA of tender
     */

    const currentCA = selectedItem?.clientA ? selectedItem?.clientA[0] : null;

    /**
     * Highlighted summary query
     */
    const [getdetails, { loading: loadDetails }] = useLazyQuery<GetTenderDetailSplitview, GetTenderDetailSplitviewVariables>(
        QUERY_SINLGE_TENDER_SPLITVIEW,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.tender) {
                    setSelectedItem(data.tender);
                }
            },
        }
    );

    /**
     * Don't set id if no rows are available
     */
    useEffect(() => {
        if (rows !== null && rows !== undefined && rows.length > 0 && (selectedItem === undefined || selectedItem.id !== selectedId)) {
            getdetails({ variables: { id: selectedId } });
        }
    }, [rows, getdetails, selectedId, selectedItem]);

    return (
        <Grid container style={{ height: "100%" }}>
            {/* Grid with list of items */}
            <Grid xs={12} md={5} item style={{ height: "72.6vh" }}>
                {/*
                 * List with tenderitems
                 * data = all tenders in worklist
                 * onitemclick = get id of clicked tender
                 * selectedindex = push id of selected tender to set clicked bg color
                 */}
                <LinearLoader loading={loading} color="blue" />
                <SplitDetailList
                    mp={mp}
                    ca={ca}
                    elevation={elevation}
                    page={page}
                    setPage={setPage}
                    selectedId={selectedId}
                    setSelectedId={(id: string) => {
                        setSelectedId(id);
                        getdetails({ variables: { id: id } });
                        setHighlighting(undefined);
                        setHighlightedData(undefined);
                        setActiveTab("0");
                    }}
                    paginatorInfo={paginatorInfo}
                    data={rows}
                    loading={loading}
                    error={error}
                />
            </Grid>

            {/* Grid with detailview of selected item */}
            <Grid xs={12} md={7} item sx={{ paddingLeft: "16px", marginTop: "4px" }}>
                {/* Loading screen */}
                {loadDetails || highlightedData === undefined ? (
                    <SplitDetailViewLoader elevation={elevation} />
                ) : // array with tenders is empty
                rows === null || rows === undefined || rows.length === 0 ? (
                    <Paper
                        elevation={elevation}
                        square
                        style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Description style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                            <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                                Geen resultaten gevonden
                            </Typography>
                        </div>
                    </Paper>
                ) : // no item selected
                !selectedItem ? (
                    <Paper
                        elevation={elevation}
                        square
                        style={{ height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Description style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />
                            <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                                Kies een aanbesteding voor meer details
                            </Typography>
                        </div>
                    </Paper>
                ) : (
                    <Paper elevation={elevation} square style={{ height: "72.6vh" }}>
                        {/* Topbox
                         * Contains status, title and CA of document
                         */}
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                padding: { sm: "16px", lg: "20px" },
                            }}
                        >
                            {/*
                             * Left panel
                             * contains: tender_name, ca_name, city
                             */}
                            <SplitViewTopBar selectedItem={selectedItem} ca={ca} currentCA={currentCA} highlightedData={highlightedData} />
                        </Grid>
                        <SplitTabPanel
                            tender_id={selectedItem.id}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            awardInfo={
                                selectedItem.status === "PARTICIPANTS_KNOWN" ||
                                selectedItem.status === "AWARDED" ||
                                selectedItem.status === "PRELIMINARY_AWARDED" ||
                                (selectedItem.bids !== null && selectedItem.bids?.length > 0)
                            }
                            labels={selectedItem.userLabels.length > 0}
                        >
                            <TooltipOnSelect>
                                {/* Summary view */}
                                {activeTab === "0" ? (
                                    <SplitDetailView
                                        mp={mp}
                                        selectedItem={selectedItem}
                                        error={error}
                                        tender_description={highlightedData.summary}
                                        tender_scope={highlightedData.scope}
                                    />
                                ) : // Label widget
                                activeTab === "1" ? (
                                    <SplitViewLabels tender_id={selectedItem.id} />
                                ) : // Awardinfo widget
                                activeTab === "2" ? (
                                    <SplitViewAward tender_id={selectedItem.id} />
                                ) : (
                                    // Summary view if something goes wrong
                                    <SplitDetailView
                                        mp={mp}
                                        selectedItem={selectedItem}
                                        error={error}
                                        tender_description={highlightedData.summary}
                                        tender_scope={highlightedData.scope}
                                    />
                                )}
                            </TooltipOnSelect>
                        </SplitTabPanel>
                    </Paper>
                )}
            </Grid>

            <Grid xs={12}>
                <Typography variant="body1" style={{ fontWeight: 500, marginTop: 8 }}>
                    {rows !== undefined ? rows?.length : 0} van {paginatorInfo.total} resultaten
                </Typography>
            </Grid>
        </Grid>
    );
};

export default SplitView;
