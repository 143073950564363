import React, { useState } from "react";
import SearchUsers from "./SearchUsers";
import { Box, Chip, Popover, Typography } from "@mui/material";
import { CopyAll, PersonAdd, Share, TaskAlt } from "@mui/icons-material";
import { UserInTeam } from "../UserAndTeams/UsersAndTeams";

interface Props {
    users: UserInTeam[];
    checked: number[];
    setChecked: React.Dispatch<React.SetStateAction<number[]>>;
    onToggle: (e: any, value: number) => void;
    type: "tender" | "opportunity";
}

const CopySearchlineToUser: React.FC<Props> = ({ users, checked, setChecked, onToggle, type }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [openShare, setOpenShare] = useState<boolean>(false);

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };
    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenMenu(false);
    };

    /**
     * Open submenu to share or delete searchline
     */
    const handleOpensubNoUsers = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenShare(true);
    };

    /**
     * Open submenu to share or delete searchline
     */
    const handleClosesubNoUsers = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenShare(false);
    };

    if (!users || users.length === 0) {
        return (
            <React.Fragment>
                <Popover
                    anchorEl={anchorEl}
                    open={openShare}
                    onClose={handleClosesubNoUsers}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    /**
                     * Width and height for chat/knowledge base
                     */
                    slotProps={{
                        paper: {
                            sx: {
                                width: "300px",
                            },
                        },
                    }}
                >
                    <Box sx={{ height: "310px", overflow: "auto" }}>
                        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <PersonAdd style={{ color: "#f2f2f2", height: "8rem", width: "8rem" }} />
                            <Typography variant="body1" style={{ color: "#d4d4d4", fontSize: "1.15rem" }}>
                                Geen gebruikers gevonden
                            </Typography>
                        </div>
                    </Box>
                </Popover>
                <Typography
                    sx={{
                        fontWeight: 500,
                        display: "inline-flex",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "4px 6px",
                        fontSize: "1rem",
                        marginLeft: "-4px",
                        borderRadius: "4px",
                        "&:hover": {
                            backgroundColor: "#ececec",
                        },
                    }}
                    onClick={handleOpensubNoUsers}
                    variant="h5"
                >
                    <Share fontSize="small" style={{ marginRight: "4px", color: type === "tender" ? "#465c84" : "#508c79" }} /> Deel zoekregel
                </Typography>
                <Typography sx={{ margin: "4px 0px 12px 0px" }}>
                    Deel de resultaten van jouw zoekregel met je collega&apos;s. Alleen jij kunt wijzingen doorvoeren voor deze zoekregel.
                </Typography>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <SearchUsers
                anchorEl={anchorEl}
                handleCloseSub={handleCloseSub}
                shareOpened={openMenu}
                users={users}
                checked={checked}
                setChecked={setChecked}
            />

            <Typography
                sx={{
                    fontWeight: 500,
                    display: "inline-flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "4px 6px",
                    fontSize: "1rem",
                    marginLeft: "-4px",
                    borderRadius: "4px",
                    "&:hover": {
                        backgroundColor: "#ececec",
                    },
                }}
                onClick={handleClickSub}
                variant="h5"
            >
                <CopyAll fontSize="small" style={{ marginRight: "4px", color: type === "tender" ? "#465c84" : "#508c79" }} /> Stuur kopie
            </Typography>
            <Typography sx={{ margin: "4px 0px 12px 0px" }}>
                Kopieer de zoekregel naar je collega&apos;s. Ze kunnen zelf wijzingen doorvoeren voor deze zoekregel.
            </Typography>

            <div style={{ height: "25px" }}>
                {checked.map((id) => {
                    const user = users.find((user) => parseInt(user.id) === id) as UserInTeam;
                    return (
                        <Chip
                            variant="outlined"
                            size="small"
                            sx={{ marginRight: "8px", borderRadius: "4px" }}
                            key={user.id}
                            onClick={(e) => onToggle(e, id)}
                            icon={<TaskAlt style={{ color: type === "tender" ? "#465c84" : "#508c79" }} />}
                            label={user.employee.name}
                        />
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default CopySearchlineToUser;
