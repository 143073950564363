import React, { createContext, useContext, useState, ReactNode } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { toast } from "react-toastify";

import { GetCu2FA } from "../../../../../__generated__/GetCu2FA";
import { setdefaultmethod, setdefaultmethodVariables } from "../../../../../__generated__/setdefaultmethod";
import { TwoFactorMethod } from "../../../../../__generated__/globalTypes";
import { enableTwoFactor, enableTwoFactorVariables } from "../../../../../__generated__/enableTwoFactor";
import { disableTwoFactor, disableTwoFactorVariables } from "../../../../../__generated__/disableTwoFactor";
import { enableMailVerification, enableMailVerificationVariables } from "../../../../../__generated__/enableMailVerification";
import { disableMailVerification, disableMailVerificationVariables } from "../../../../../__generated__/disableMailVerification";
import { GetRecoveryCodes, GetRecoveryCodesVariables } from "../../../../../__generated__/GetRecoveryCodes";
import {
    DISABLE_2FA,
    DISABLE_MAIL_VERIFY,
    ENABLE_2FA,
    ENABLE_MAIL_VERIFY,
    GET_2FA_STATE,
    GET_RECOVERY_CODES,
    SAVE_DEFAULT_METHOD,
} from "../../../../../graphql/twoFAdefinitions";

interface AuthSettingsContextProps {
    enable2FA: (pw: string) => void;
    setDefaultMethod: (method: TwoFactorMethod) => void;
    disable2FA: (pw: string) => void;
    enableMail: (pw: string) => void;
    disableMail: (pw: string) => void;
    getCodes: (pw: string, bln?: boolean) => void;
    setPasswordIncorrect: React.Dispatch<React.SetStateAction<boolean>>;
    setQR: React.Dispatch<React.SetStateAction<string>>;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    setClickedOption: React.Dispatch<React.SetStateAction<string>>;
    setpw: React.Dispatch<React.SetStateAction<string>>;
    pw: string;
    passwordMailIncorrect: boolean;
    defaultMethod: string | null;
    clickedOption: string;
    passwordIncorrect: boolean;
    refetch: any;
    qrCodeUrl: string;
    activeStep: number;
    loading: {
        enable2FA: boolean;
        disable2FA: boolean;
        enableMail: boolean;
        disableMail: boolean;
        loadingState: boolean;
        getCodes: boolean;
    };
    error: {
        enable2FA?: Error;
        disable2FA?: Error;
        enableMail?: Error;
        disableMail?: Error;
        getCodes?: Error;
    };
    data_two_factor: GetCu2FA | undefined;
    setActivateBoth: React.Dispatch<React.SetStateAction<boolean>>;
    activateBoth: boolean;
    codes: string[];
    setCodes: React.Dispatch<React.SetStateAction<string[]>>;
}

const AuthSettingsContext = createContext<AuthSettingsContextProps | undefined>(undefined);

export const AuthSettingsProvider = ({ children }: { children: ReactNode }) => {
    ////////////////
    // MUTATIONS //
    //////////////
    const [enable, { loading: loadingEnable, error: errorEnable }] = useMutation<enableTwoFactor, enableTwoFactorVariables>(ENABLE_2FA);
    const [disable, { loading: loadingDisable, error: errorDisable }] = useMutation<disableTwoFactor, disableTwoFactorVariables>(DISABLE_2FA);
    const [enableVerifyByMail, { loading: loadingEnableMail, error: errorEnableMail }] = useMutation<
        enableMailVerification,
        enableMailVerificationVariables
    >(ENABLE_MAIL_VERIFY);
    const [disableVerifyByMail, { loading: loadingDisableMail, error: errorDisableMail }] = useMutation<
        disableMailVerification,
        disableMailVerificationVariables
    >(DISABLE_MAIL_VERIFY);
    const [getCodesBackup, { loading: loadingCodes, error: errorCodes }] = useMutation<GetRecoveryCodes, GetRecoveryCodesVariables>(
        GET_RECOVERY_CODES
    );

    ////////////////////
    // END MUTATIONS //
    //////////////////
    const [defaultMethod, setDefault] = useState<string | null>(null);
    const [passwordIncorrect, setPasswordIncorrect] = useState(false);
    const [passwordMailIncorrect, setPasswordMailIncorrect] = useState(false);
    const [qrCodeUrl, setQR] = useState<string>("");
    const { data: data_two_factor, loading: loadingstate, refetch } = useQuery<GetCu2FA>(GET_2FA_STATE);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [clickedOption, setClickedOption] = useState<string>("");
    const [activateBoth, setActivateBoth] = useState<boolean>(false);
    const [saveDefault] = useMutation<setdefaultmethod, setdefaultmethodVariables>(SAVE_DEFAULT_METHOD);
    const [pw, setpw] = useState<string>("");
    const [codes, setCodes] = useState<string[]>([]);

    const setDefaultMethod = async (method: TwoFactorMethod | null) => {
        if (errorEnable) toast.error("Er ging iets mis");
        try {
            await saveDefault({
                variables: { method: method },
                onCompleted: (data) => {
                    setDefault(data.setDefaultTwoFactorMethod);
                    refetch();
                },
            });
        } catch (e) {
            toast.error("Er ging iets mis");
        }
    };

    const getCodes = async (pw: string, bln?: boolean) => {
        if (errorCodes) toast.error("Er ging iets mis");
        try {
            await getCodesBackup({
                variables: { password: pw },
                onCompleted: (data) => {
                    if (data.getRecoveryCode.message === "password incorrect") {
                        setPasswordIncorrect(true);
                    } else if (data.getRecoveryCode.message === "Succes") {
                        const recoveryCodes: string[] = JSON.parse(data.getRecoveryCode.recovery_codes);
                        // handleCopyAll(recoveryCodes);
                        // handleDownload(recoveryCodes);
                        // setActiveStep(1);
                        setPasswordIncorrect(false);
                        if (bln === true) {
                            setActiveStep(99);
                            setCodes(recoveryCodes);
                        }
                    }
                },
            });
        } catch (e) {
            toast.error("Er ging iets mis");
        }
    };

    const enable2FA = async (pw: string) => {
        if (errorEnable) toast.error("Er ging iets mis");
        try {
            await enable({
                variables: { password: pw },
                onCompleted: (data) => {
                    if (data.enableTwoFactor.message === "password incorrect") {
                        setPasswordIncorrect(true);
                    } else if (data.enableTwoFactor.message !== "Two-factor authentication is already enabled.") {
                        setQR(data.enableTwoFactor.message);
                        setActiveStep(1);
                        setClickedOption("");
                        refetch();
                        setPasswordIncorrect(false);
                    }
                },
            });
        } catch (e) {
            toast.error("Er ging iets mis");
        }
    };

    const disable2FA = async (pw: string) => {
        if (errorDisable) toast.error("Er ging iets mis");
        try {
            await disable({
                variables: { password: pw },
                onCompleted: (data) => {
                    if (data.disableTwoFactor === "password incorrect") {
                        setPasswordIncorrect(true);
                    } else {
                        setActiveStep(0);
                        refetch();
                        setPasswordIncorrect(false);
                    }
                },
            });
        } catch (e) {
            toast.error("Er ging iets mis");
        }
    };

    const enableMail = async (pw: string) => {
        if (errorEnableMail) toast.error("Er ging iets mis");
        try {
            await enableVerifyByMail({
                variables: { password: pw },
                onCompleted: (data) => {
                    if (data.enableMailVerification.message === "Password incorrect") {
                        setPasswordMailIncorrect(true);
                    } else {
                        setpw("");
                        setClickedOption("");
                        refetch();
                        setPasswordMailIncorrect(false);
                    }
                },
            });
        } catch (e) {
            toast.error("Er ging iets mis");
        }
    };

    const disableMail = async (pw: string) => {
        if (errorDisableMail) toast.error("Er ging iets mis");
        try {
            await disableVerifyByMail({
                variables: { password: pw },
                onCompleted: (data) => {
                    if (data.disableMailVerification.message === "Password incorrect") {
                        setPasswordMailIncorrect(true);
                    } else {
                        setClickedOption("");
                        refetch();
                        setPasswordMailIncorrect(false);
                    }
                },
            });
        } catch (e) {
            toast.error("Er ging iets mis");
        }
    };

    return (
        <AuthSettingsContext.Provider
            value={{
                activateBoth,
                setActivateBoth,
                enable2FA,
                disable2FA,
                enableMail,
                disableMail,
                setQR,
                setPasswordIncorrect,
                setActiveStep,
                setClickedOption,
                getCodes,
                setpw,
                setCodes,
                codes,
                pw,
                activeStep,
                qrCodeUrl,
                passwordIncorrect,
                data_two_factor,
                clickedOption,
                defaultMethod,
                passwordMailIncorrect,
                setDefaultMethod,
                loading: {
                    enable2FA: loadingEnable,
                    disable2FA: loadingDisable,
                    enableMail: loadingEnableMail,
                    disableMail: loadingDisableMail,
                    loadingState: loadingstate,
                    getCodes: loadingCodes,
                },
                error: {
                    enable2FA: errorEnable,
                    disable2FA: errorDisable,
                    enableMail: errorEnableMail,
                    disableMail: errorDisableMail,
                    getCodes: errorCodes,
                },
                refetch,
            }}
        >
            {children}
        </AuthSettingsContext.Provider>
    );
};

export const useAuthSettings = () => {
    const context = useContext(AuthSettingsContext);
    if (context === undefined) {
        throw new Error("useAuthSettings must be used within an AuthSettingsProvider");
    }
    return context;
};
