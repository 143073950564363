import React from "react";
import { Assistant } from "@mui/icons-material";
import { Typography } from "@mui/material";

interface Props {
    title: string;
    content?: React.ReactNode;
}

const GuideContent: React.FC<Props> = ({ title, content = "" }) => {
    return (
        <div style={{ marginRight: "16px" }}>
            <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
                <Assistant fontSize="small" sx={{ marginLeft: "-4px", marginRight: "8px" }} /> {title}
            </Typography>
            <div>{content}</div>
        </div>
    );
};

export default GuideContent;
