import React from "react";
import { Typography } from "@mui/material";
import { GET_SINGLE_POLL_TENDENZ } from "../../../graphql/tendenzDefinitions";
import { TendenZ_poll } from "../../../__generated__/TendenZ_poll";
import { useQuery } from "@apollo/client";
import { usePublicSocket } from "../../../utils/websocketChannels";

interface Props {
    currentQuestionId: number;
}

const PollQuestionTotalAnswers: React.FC<Props> = ({ currentQuestionId }) => {
    const { data, refetch } = useQuery<TendenZ_poll>(GET_SINGLE_POLL_TENDENZ);

    /**
     * Whenever new notifications are published on the socket channel,
     * fetch notifications
     */
    usePublicSocket(`poll.1`, ".question.answered", (e) => {
        // Update usersAnswered with the event data
        if (e && e.usersAnswered !== undefined) {
            refetch();
        }
    });
    const current = data?.TendenzPoll?.questions?.find((q) => q?.id === currentQuestionId);

    return (
        <div>
            <Typography align="center" variant="h2" sx={{ fontWeight: 500, color: "#000" }}>
                {current?.users_answered}
            </Typography>
            <Typography align="center" variant="h2" sx={{ fontWeight: 500, color: "#000" }}>
                Antwoorden
            </Typography>
        </div>
    );
};

export default PollQuestionTotalAnswers;
