import React, { useState } from "react";
import AccordionTile from "../AccordionTile";
import { Add, Description, LocalOfferOutlined } from "@mui/icons-material";
import TooltipOnNewLines from "../../../../components/TooltipOnNewLines";
import { Badge, Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { QUERY_TENDER_FOR_PROJECT } from "../../../../graphql/queryDefProject";
import { useMutation, useQuery } from "@apollo/client";
import { GetTenderOfProject, GetTenderOfProjectVariables } from "../../../../__generated__/GetTenderOfProject";
import ChatButtonTopBar from "../../../../components/ChatButtonTopBar";
import StatusDot from "../../../../components/StatusDot";
import SearchRuleData from "../../../../components/SearchRuleData";
import { useTranslation } from "react-i18next";
import moment from "moment";
import anchorNewTab from "../../../../utils/anchorNewTab";
import LegalFrameworkTooltip from "../../../../components/LegalFrameworkTooltip";
import SectorIconLoader from "../../../../components/boxes/SectorIconLoader";
import { Link } from "react-router-dom";
import { TenderUserStatus } from "../../../../__generated__/globalTypes";
import LikeTenderButton from "../../../../components/LikeTenderButton";
import { UPDATE_CANCELLATION_REVIEW_CU_IN_PROJECT } from "../../../../graphql/mutationDefProjects";
import {
    updateReviewCancellationForCurrentUser,
    updateReviewCancellationForCurrentUserVariables,
} from "../../../../__generated__/updateReviewCancellationForCurrentUser";
import { toast } from "react-toastify";
import { SINGLE_PROJECT } from "../../../../graphql/queryDefProjects";
import AddTenderToProject from "./AddTenderToProject";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { getRelativeTimeInDays } from "../../../../utils/relativeTimeInDays";

interface Props {
    tender_id: string | undefined;
    newAddedTenderFoundOrNull: boolean;
    project_id: string;
}

const TenderWidgetBox: React.FC<Props> = ({ project_id, tender_id, newAddedTenderFoundOrNull }) => {
    const { t } = useTranslation();
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showAddSection, setShowAddSection] = useState<boolean>(false);

    const [saveCancelReview, { loading: loadcancel, error: errorcancel }] = useMutation<
        updateReviewCancellationForCurrentUser,
        updateReviewCancellationForCurrentUserVariables
    >(UPDATE_CANCELLATION_REVIEW_CU_IN_PROJECT);

    const { trackEvent } = useMatomo();
    // track navigation items
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Default tender summary query
     */
    const { data, loading, error } = useQuery<GetTenderOfProject, GetTenderOfProjectVariables>(QUERY_TENDER_FOR_PROJECT, {
        skip: tender_id === "" || tender_id === undefined,
        variables: { id: tender_id as string },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data && data.tender && data.tender) {
                // newAddedTenderFoundOrNull = cancelreview date is before date of adding new tender OR no review state is set yet
                data.tender.userStatus === TenderUserStatus.UNREVIEWED && newAddedTenderFoundOrNull && setShowMessage(true);
            }
        },
    });

    /**
     * Function to set a date of hide screen for this project
     */
    const hideModalForThisProject = async () => {
        trackClick({ category: "Project", action: "Hide-review-modal-project" });
        try {
            await saveCancelReview({
                variables: {
                    id: project_id,
                },
                refetchQueries: [SINGLE_PROJECT],
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
            if (errorcancel) {
                toast.error("Fout tijdens opslaan (error)");
            }
        }
        setShowMessage(false);
    };

    // Loading
    if (loading) {
        return renderPage(
            false,
            <>Aanbesteding ophalen...</>,
            <Box component="div">
                <TooltipOnNewLines
                    text={"Data ophalen..."}
                    variant={"body1"}
                    lines={1}
                    id={"tendertitletoshow"}
                    fontweight={"normal"}
                    fontSize={"14px"}
                    placement={"top"}
                />
            </Box>
        );
    }

    // No tender selected
    // has to be defined before error otherwise error will be shown instead of add component
    if (tender_id === "" || tender_id === undefined) {
        return renderPage(
            false,
            <>Aanbesteding</>,
            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginTop: "8px" }}>
                <div style={{ width: "100%" }}>
                    {showAddSection === false && <Typography style={{ marginBottom: "3px" }}>Geen aanbesteding toegevoegd</Typography>}

                    {/* Show searchbar */}
                    {showAddSection === true && <AddTenderToProject />}
                </div>
                <div>
                    <Badge
                        sx={{
                            cursor: "pointer",
                            marginRight: "-2px",
                            "& .MuiBadge-badge": {
                                right: 8,
                                top: 8,
                                border: `1px solid #fff`,
                                backgroundColor: "#173357",
                                padding: "0 2px",
                                fontSize: "9px",
                                minWidth: "12px",
                                height: "16px",
                            },
                        }}
                        overlap="circular"
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        badgeContent={
                            <>
                                <Add sx={{ height: "10px", width: "10px", padding: 0, color: "#ffffff" }} />
                            </>
                        }
                    >
                        <Tooltip placement="left" title="Aanbesteding toevoegen" disableInteractive>
                            <IconButton
                                onClick={() => {
                                    setShowAddSection(!showAddSection);
                                }}
                                size="medium"
                            >
                                <Description fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Badge>
                </div>
            </div>
        );
    }

    // Error
    if (error || !data || !data.tender) {
        return renderPage(
            false,
            <>Aanbesteding</>,
            <Box component="div">
                <TooltipOnNewLines
                    text={"Er ging iets mis..."}
                    variant={"body1"}
                    lines={1}
                    id={"tendertitletoshow"}
                    fontweight={"normal"}
                    fontSize={"14px"}
                    placement={"top"}
                />
            </Box>
        );
    }

    return renderPage(
        true,
        <>Aanbesteding</>,
        <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ marginRight: "8px" }}>
                        <StatusDot type={data.tender.status} placement={"top"} />
                    </div>
                    <Typography noWrap>
                        <Link
                            id="tender-name"
                            onClick={() => trackClick({ category: "Project", action: "Navigate-to-tender-from-project" })}
                            to={`/tenders/${tender_id}`}
                        >
                            <TooltipOnNewLines
                                text={data.tender.namegiventocontract}
                                variant={"body1"}
                                lines={2}
                                id={`tendertitletoshow${tender_id}`}
                                fontweight={"normal"}
                                fontSize={"14px"}
                                placement={"top"}
                            />{" "}
                        </Link>
                    </Typography>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {data.tender.searchresults.length > 2 ? (
                        <Tooltip placement="left" title="Meerdere resultaten gevonden">
                            <LocalOfferOutlined fontSize="small" style={{ color: "#9e9e9e" }} />
                        </Tooltip>
                    ) : (
                        <SearchRuleData
                            placement={"top"}
                            tender_id={tender_id}
                            marginRight="4px"
                            searches={data.tender.searchresults}
                            iconSize="small"
                        />
                    )}

                    <ChatButtonTopBar
                        buttonSize="small"
                        id={tender_id}
                        name={data.tender.namegiventocontract || ""}
                        module={"App\\Models\\Tender"}
                        notesAvailable={data.tender.hasConversations}
                        location="Tender"
                    />
                </div>
            </div>

            <div>
                <div>
                    <table>
                        <tbody>
                            {/* deadline */}
                            {data.tender.deadline_inschrijving !== null && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("tenderPage.Deadline")}:</td>
                                    <td>
                                        <>
                                            {moment(data.tender.deadline_inschrijving).format("LLL")}, (
                                            {getRelativeTimeInDays(data.tender.deadline_inschrijving, t).show && (
                                                <>({getRelativeTimeInDays(data.tender.deadline_inschrijving, t).date})</>
                                            )}
                                            )
                                        </>
                                    </td>
                                </tr>
                            )}

                            {/* First dispatch */}
                            {data.tender.first_dispatchdate && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                        {t("tenderPage.FirstPublication")}:
                                    </td>
                                    <td>
                                        {moment(data.tender.first_dispatchdate).format("LL")},{" "}
                                        {data.tender.published_host && (
                                            <>
                                                {Boolean(data.tender.original_link) ? (
                                                    <a {...anchorNewTab} href={data.tender.original_link || ""}>
                                                        {data.tender.published_host}
                                                    </a>
                                                ) : (
                                                    <>{data.tender.published_host}</>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            )}

                            {/* Last update */}
                            {data.tender.updated_at && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                        {t("tenderPage.LastUpdate")}:
                                    </td>
                                    <td>{moment(data.tender.updated_at).format("LL")}</td>
                                </tr>
                            )}

                            {/* Procedure */}
                            {data.tender.type_procedure !== null && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("procedure")}:</td>
                                    <td>
                                        {data.tender.type_procedure}
                                        {data.tender.translated_type !== "" && `, ${data.tender.translated_type}`}
                                        {data.tender.published_juridisch_kader ? (
                                            <>
                                                <LegalFrameworkTooltip framework={data.tender.published_juridisch_kader} />
                                            </>
                                        ) : data.tender.published_legal_basis !== null ? (
                                            <>
                                                <Tooltip
                                                    title={
                                                        <Typography variant="caption">
                                                            {t(`legal_basis_tooltip.${data.tender.published_legal_basis}`).toString()}
                                                        </Typography>
                                                    }
                                                >
                                                    <Typography style={{ marginLeft: 4 }}>
                                                        (
                                                        {data.tender.published_legal_basis !== null
                                                            ? t(`legal_basis.${data.tender.published_legal_basis}`)
                                                            : null}
                                                        )
                                                    </Typography>
                                                </Tooltip>
                                            </>
                                        ) : null}
                                        <br />
                                    </td>
                                </tr>
                            )}

                            {/* Award criteria in Typography component with pre-wrap */}
                            {/* {awardCrit && (
                            <tr>
                                <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>{t("awardCriteria")}:</td>
                                <td>
                                    <Typography style={{ whiteSpace: "pre-wrap" }}>{awardCrit?.replace(/<br\s*[\\/]?>/gi, "\n")}</Typography>
                                </td>
                            </tr>
                        )} */}

                            {/* Map sectors, could be multiple items, dutch tenders */}
                            {data.tender.sectors && data.tender.country?.name === "Netherlands" && (
                                <tr>
                                    <td style={{ paddingRight: "20px", whiteSpace: "nowrap", verticalAlign: "top" }}>
                                        {data.tender.sectors.length > 1 ? t("subsectors") : t("subsector")}:
                                    </td>
                                    <td>
                                        <>
                                            {data.tender.sectors.map((s) => (
                                                <div key={s.id} style={{ display: "flex", alignItems: "center", marginLeft: -3 }}>
                                                    <SectorIconLoader sector_id={s.id} />
                                                    <Typography style={{ marginLeft: 8 }}>{s.name}</Typography>
                                                </div>
                                            ))}
                                        </>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <React.Fragment>
                <Dialog
                    open={showMessage}
                    onClose={() => setShowMessage(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Beoordeel gekoppelde aanbesteding</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            De aanbesteding die is gekoppeld aan dit project is nog niet door jou beoordeeld.
                            <br />
                            <br />
                            <div>Wil je de updates ontvangen van deze aanbesteding?</div>
                            <div style={{ marginTop: "16px", display: "flex", justifyContent: "center" }}>
                                {/* Like button */}
                                <LikeTenderButton
                                    tender_id={tender_id}
                                    buttonSize="medium"
                                    onClick={() => {
                                        setShowMessage(false);
                                        trackClick({ category: "Project", action: "like-tender-in-project" });
                                    }}
                                />
                                {/* spacer */}
                                <div style={{ width: "16px" }} />
                                {/* Dont show this modal until new tender is added to project */}
                                <Button disabled={loadcancel} size="small" onClick={hideModalForThisProject}>
                                    Nee, bedankt
                                </Button>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        </div>
    );
};

export default TenderWidgetBox;

const renderPage = (open: boolean, summary: React.ReactNode, children: React.ReactNode) => {
    return (
        <AccordionTile open={open} topColor="#173351" icon={<Description fontSize="medium" />} titleText={summary}>
            {children}
        </AccordionTile>
    );
};
