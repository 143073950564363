import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import classes from "./NavBar.module.scss";
import ProfileBox from "./ProfileBox";
import { ReactComponent as LogoIcon } from "../../../assets/tenderguide.svg";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import {
    SvgIconTypeMap,
    useTheme,
    Theme,
    Avatar,
    useMediaQuery,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
    Typography,
    Paper,
} from "@mui/material";
import UserSupport from "../../chat/UserSupport";
import { useFaq } from "../../contextProviders/FaqOpenerContext";
import { useProfile } from "../../contextProviders/ProfileOpenerContext";
import NotificationsMenu from "../../notifications/NotificationsMenu";
import SupportMenu from "./SupportMenu";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { withStyles } from "tss-react/mui";
import { DesktopWindowsOutlined, TrendingUp, Business, Today, Description, Group, ExpandLess, ExpandMore, AccountTree } from "@mui/icons-material";

interface Props extends WithTranslation {
    activePage?: string;
    opened: boolean;
    closeDrawer: () => void;
}
export interface MenuItem {
    label: string;
    alias: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    link: string;
    category: "Dashboard" | "Calendar" | "Tender" | "Opportunity" | "Ca" | "Organizations" | "Help" | "Personal" | "Project";
    action: string;
    sub: MenuItem[];
}

const StyledAvatar = withStyles(Avatar, (theme: Theme) => ({
    root: {
        width: "30px",
        height: "30px",
        fontSize: "0.9rem",
        backgroundColor: "#454545",
    },
}));

const NavBarMobile: React.FunctionComponent<Props> = ({ t, activePage, closeDrawer }) => {
    const theme = useTheme();
    const location = useLocation();

    const { setOpenFaq, setView } = useFaq();
    const { trackEvent } = useMatomo();
    const {
        openProfile,
        setOpenProfile,
        initials,
        //  datafetched
    } = useProfile();
    const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));
    const anchorpoint = useRef<any>(null);
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const navigate = useNavigate();
    // const [openChat, setOpenChat] = useState(false);
    const [openSupport, setOpenSupport] = useState<boolean>(false);
    // const [open, setOpen] = useState<string | false>(false);
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});

    const handleClick = (alias: string) => {
        setOpen((prevOpen) => ({
            ...prevOpen,
            [alias]: !prevOpen[alias],
        }));
    };

    const renderMenuItems = (items: MenuItem[], inset: boolean) => {
        return items.map((item, i) => (
            <React.Fragment key={item.alias}>
                <ListItemButton
                    sx={{ padding: 1 }}
                    onClick={() => {
                        if (item.sub.length) {
                            handleClick(item.alias);
                        } else {
                            navigate(item.link);
                            setTimeout(() => {
                                closeDrawer();
                            }, 300);
                        }
                    }}
                >
                    {!inset && (
                        <div
                            style={{
                                borderRadius: "16px",
                                height: "48px",
                                width: "48px",
                                backgroundColor: item.alias === activePage ? `#ffffff2b` : undefined,
                                marginRight: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={() => {
                                navigate(item.link);
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 0, color: "#fff" }}>{React.createElement(item.icon)}</ListItemIcon>
                        </div>
                    )}
                    <ListItemText
                        primary={
                            <Typography
                                variant="h3"
                                fontWeight={item.link === location.pathname && inset ? 900 : item.alias === activePage && inset ? 400 : 500}
                                color={"#fff"}
                            >
                                {item.label}
                            </Typography>
                        }
                    />
                    {item.sub.length ? open[item.alias] ? <ExpandLess sx={{ color: "#fff" }} /> : <ExpandMore sx={{ color: "#fff" }} /> : null}
                </ListItemButton>
                {item.sub.length > 0 && (
                    <Collapse in={open[item.alias]} timeout="auto" unmountOnExit>
                        <Paper sx={{ ml: 8, backgroundColor: "#ffffff2b", borderRadius: "16px 0px 0px 16px" }} elevation={0}>
                            <List component="div" disablePadding sx={{ padding: "8px 0px 8px 8px" }}>
                                {renderMenuItems(item.sub, true)}
                            </List>
                        </Paper>
                    </Collapse>
                )}
            </React.Fragment>
        ));
    };

    // const [newUserLoggedInmodal, setNewUserLoggedInmodal] = useState<boolean>(false);
    // const [startNewUserTour, setStartNewUserTour] = useState<boolean>(false);

    // useEffect(() => {
    //     if (datafetched) {
    //         setNewUserLoggedInmodal(true);
    //     }
    // }, [datafetched]);

    // The alias is for css styling.
    // Using the map function on this array we add the `url` property
    const menuItems: MenuItem[] = [
        {
            label: t("dashboard"),
            alias: "dashboard",
            icon: DesktopWindowsOutlined,
            link: "/dashboard",
            category: "Dashboard",
            action: "Go-to-dashboard",
            sub: [],
        },
        {
            label: t("projectmanagement"),
            alias: "projects",
            icon: AccountTree,
            link: "/projects",
            category: "Project",
            action: "Go-to-projects",
            sub: [],
        },
        { label: t("agenda"), alias: "calendar", icon: Today, link: "/calendar", category: "Calendar", action: "Go-to-calendar", sub: [] },
        {
            label: t("competitors"),
            alias: "organizations",
            icon: Group,
            link: "/organizations/starred",
            category: "Organizations",
            action: "Go-to-organizations-starred",
            sub: [
                {
                    label: t("All"),
                    alias: "organizations",
                    icon: Group,
                    link: "/organizations",
                    category: "Organizations",
                    action: "Go-to-organizations",
                    sub: [],
                },
                {
                    label: t("StarMarked"),
                    alias: "organizations",
                    icon: Group,
                    link: "/organizations/starred",
                    category: "Organizations",
                    action: "Go-to-organizations-starred",
                    sub: [],
                },
            ],
        },
        {
            label: t("tenders"),
            alias: "tenders",
            icon: Description,
            link: "/tenders/worklist",
            category: "Tender",
            action: "Go-to-tenders-worklist",
            sub: [
                {
                    label: t("All"),
                    alias: "tenders",
                    icon: Description,
                    link: "/tenders",
                    category: "Tender",
                    action: "Go-to-tenders",
                    sub: [],
                },
                {
                    label: t("Reviews"),
                    alias: "tenders",
                    icon: Description,
                    link: "/tenders/reviews",
                    category: "Tender",
                    action: "Go-to-tenders-reviews",
                    sub: [],
                },
                {
                    label: t("Worklist"),
                    alias: "tenders",
                    icon: Description,
                    link: "/tenders/worklist",
                    category: "Tender",
                    action: "Go-to-tenders-worklist",
                    sub: [],
                },
            ],
        },
        {
            label: t("opportunities"),
            alias: "opportunities",
            icon: TrendingUp,
            link: "/opportunities/worklist",
            category: "Opportunity",
            action: "Go-to-opportunities-worklist",
            sub: [
                {
                    label: t("All"),
                    alias: "opportunities",
                    icon: TrendingUp,
                    link: "/opportunities",
                    category: "Opportunity",
                    action: "Go-to-opportunities",
                    sub: [],
                },
                {
                    label: t("Reviews"),
                    alias: "opportunities",
                    icon: TrendingUp,
                    link: "/opportunities/reviews",
                    category: "Opportunity",
                    action: "Go-to-opportunities-reviews",
                    sub: [],
                },
                {
                    label: t("Worklist"),
                    alias: "opportunities",
                    icon: TrendingUp,
                    link: "/opportunities/worklist",
                    category: "Opportunity",
                    action: "Go-to-opportunities-worklist",
                    sub: [],
                },
            ],
        },
        {
            label: t("contractingAuthorities"),
            alias: "contracting-authorities",
            icon: Business,
            link: "/contracting-authorities/starred",
            category: "Ca",
            action: "Go-to-contracting-authorities-starred",
            sub: [
                {
                    label: t("All"),
                    alias: "contracting-authorities",
                    icon: Business,
                    link: "/contracting-authorities",
                    category: "Ca",
                    action: "Go-to-contracting-authorities",
                    sub: [],
                },
                {
                    label: t("StarMarked"),
                    alias: "contracting-authorities",
                    icon: Business,
                    link: "/contracting-authorities/starred",
                    category: "Ca",
                    action: "Go-to-contracting-authorities-starred",
                    sub: [],
                },
            ],
        },

        // {
        //     label: t("Chat"),
        //     alias: "chat",
        //     icon: ForumIcon,
        // },
    ];

    // start & end time for online support, at the moment hardcoded with a switch in operator chat
    // const currentTime = moment(); // e.g. 11:00 pm
    // const startTime = moment("09:00:00", "HH:mm:ss");
    // const endTime = moment("16:30:00", "HH:mm:ss");

    // const amIBetween = currentTime.isBetween(startTime, endTime);
    // const supportState = localStorage.getItem("supportState");

    const backToHome = async () => {
        setOpenFaq(false);
        await sleep(100);
        setView("start");
    };

    // track navigation items
    const trackNavigation = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <React.Fragment>
            <div className={classes.NavBar} style={{ backgroundColor: activePage, width: "90vw" }}>
                {/* TenderGuide logo */}
                <div
                    style={{
                        // padding: "24px 0px 16px 16px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Link
                        to="/"
                        style={{ width: "75%", height: "auto", overflow: "hidden" }}
                        onClick={() => trackNavigation({ category: "Dashboard", action: "Go-to-dashboard" })}
                    >
                        <LogoIcon title="TenderGuide" className={classes.tenderguideWhite} />
                    </Link>
                </div>

                <List
                    sx={{ width: "100%", padding: "0px", overflow: "auto", marginTop: "32px" }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {renderMenuItems(menuItems, false)}
                </List>

                {/* Navigation icons to switch between modules */}
                {/* <div data-tut="reactour__navbarmodules">
                        {menuItems.map(({ label, link, alias, icon: Icon, category, action }) => (
                            <div key={link} style={{ marginBottom: "3px" }}>
                                <Tooltip title={label} placement="right">
                                    <Link to={link} onClick={() => trackNavigation({ category: category, action: action })}>
                                        <IconButton
                                            sx={{ padding: "12px" }}
                                            classes={{ root: alias === activePage ? classes.iconSelected : undefined }}
                                        >
                                            <Icon htmlColor="#e6e6e6" titleAccess={label} />
                                        </IconButton>
                                        {label}
                                    </Link>
                                </Tooltip>
                            </div>
                        ))}
                    </div> */}
            </div>

            {/* Bottom part of the NavBar */}
            {noMobile && (
                <div className={classes.columnCentered} style={{ paddingBottom: 10 }} data-tut="reactour__navbarpersonal">
                    {/* Warning if the environment is not production */}
                    {process.env.REACT_APP_TG_ENVIRONMENT !== "production" && (
                        <React.Fragment />
                        // <Tooltip
                        //     title={
                        //         <span>
                        //             Pas op! Je werkt op de staging omgeving.
                        //             <br />
                        //             Wijzigingen worden elke dag gereset.
                        //         </span>
                        //     }
                        //     placement="right"
                        // >
                        //     <IconButton>
                        //         <BuildIcon htmlColor="#e6e6e6" />
                        //     </IconButton>
                        // </Tooltip>
                    )}

                    {/*
                     * Notification pop up component
                     */}
                    <NotificationsMenu />
                    <div style={{ height: "6px" }} />

                    {/*
                     * Menu with help options
                     * - Interactive guide (TODO)
                     * - Knowledgebase
                     * - Send support message
                     * - Screen share
                     */}
                    <SupportMenu openSupport={openSupport} setOpenSupport={setOpenSupport} />

                    {/* TourMenu */}

                    {/* user support pop up component */}
                    <UserSupport
                        anchorEl={anchorpoint.current}
                        handleClose={() => {
                            backToHome();
                        }}
                        // TODO change input state to GQL or something to activate online support
                        supportState={false}
                    />
                    {/* Profile button */}
                    <div style={{ height: "6px" }} />
                    <IconButton
                        data-tut="reactour__navbarpersonalpages"
                        ref={anchorpoint}
                        onClick={() => {
                            setOpenProfile(true);
                            trackNavigation({ category: "Personal", action: "Open-profile-modal" });
                        }}
                    >
                        <StyledAvatar>{initials}</StyledAvatar>
                    </IconButton>
                    <ProfileBox anchorEl={anchorpoint.current} opened={openProfile} handleClose={() => setOpenProfile(false)} />
                </div>
            )}

            {/* <NewUserLoggedInDialog
                open={newUserLoggedInmodal}
                handleClose={() => setNewUserLoggedInmodal(false)}
                setStartNewUserTour={setStartNewUserTour}
            /> */}
            {/* {datafetched && startNewUserTour && <NewUserGuide setOpenSupport={setOpenSupport} setOpenProfile={setOpenProfile} />} */}
        </React.Fragment>
    );
};

export default withTranslation("translation")(NavBarMobile);
