import React from "react";
import { styled } from "@mui/material/styles";
// import SessionBox from "./SessionBox";
import { Divider, List } from "@mui/material";
import PasswordChange from "./PasswordChange";
import TwoFactorBox from "./TwoFactorBox";
import { AuthSettingsProvider } from "./2FA/AuthSettingsContext";

interface Props {
    a?: number;
}

// Styled Paper component
const StyledPaper = styled("div")(({ theme }) => ({}));

const SecurityContainer: React.FC<Props> = (props) => {
    return (
        <StyledPaper>
            <List sx={{ padding: 0 }}>
                <PasswordChange />

                <Divider sx={{ marginLeft: "32px" }} component="li" />
                <AuthSettingsProvider>
                    <TwoFactorBox />
                </AuthSettingsProvider>

                <Divider sx={{ marginLeft: "32px" }} component="li" />
                {/* Sessions */}
                {/* <SessionBox /> */}
            </List>
        </StyledPaper>
    );
};

export default SecurityContainer;
