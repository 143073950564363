import { Checkbox, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import React from "react";
import EditableTaskField from "../EditableTaskField";
import { RadioButtonUnchecked, TaskAlt } from "@mui/icons-material";
import { Disable } from "react-disable";
import TaskPriorityChip from "./TaskPriorityChip";
import UserForTask from "./UserForTask";
import TaskDatePicker from "./TaskDatePicker";
import moment from "moment";
import { single_project_tasks_project_tasks } from "../../../../__generated__/single_project_tasks";

interface Props {
    task: single_project_tasks_project_tasks;
}

const RemovedTask: React.FC<Props> = ({ task }) => {
    return (
        <React.Fragment>
            <ListItem disablePadding dense>
                {/*
                 * Icon to show
                 * - Disable state if task is not for current user
                 */}
                <ListItemIcon>
                    <Checkbox
                        disabled
                        icon={<RadioButtonUnchecked fontSize="small" />}
                        checkedIcon={<TaskAlt sx={{ color: "#2E76FF" }} />}
                        edge="start"
                        size="small"
                        checked={task.task_completed}
                        disableRipple
                        sx={{ marginLeft: "4px" }}
                    />
                </ListItemIcon>

                {/* Tasks text */}
                <ListItemText
                    sx={{ textDecoration: task.task_completed ? "line-through" : "none" }}
                    primary={
                        <EditableTaskField
                            disabled
                            tasktext={task.task_description}
                            setTasktext={(txt) => {
                                console.log();
                            }}
                            taskID={task.id}
                            bossOfProject={false}
                        />
                    }
                />

                {/* DatePicker */}
                <Disable disabledOpacity={0.6} disabled>
                    <TaskDatePicker
                        date={moment(task.task_deadline)}
                        updateDate={() => {
                            console.log();
                        }}
                    />
                </Disable>

                {/* Users linked to task */}
                <Disable disabledOpacity={0.6} disabled>
                    <UserForTask chosenUserId={task.users as any} task_id={task.id} />
                </Disable>

                {/* Prioritychip  */}
                <Disable disabledOpacity={0.6} disabled>
                    <TaskPriorityChip dot={false} disabled priority={task.task_priority} />
                </Disable>

                {/* Delete task 
                // TODO add mutation to delete a task
                */}
                <ListItemSecondaryAction>
                    <div style={{ width: "30px" }} />{" "}
                    {/* <Tooltip placement="top" title="Verwijder taak" disableInteractive>
                        <IconButton sx={{ "&:hover": { color: (theme) => theme.palette.error.main } }} size="small" edge="end" aria-label="delete">
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip> */}
                </ListItemSecondaryAction>
            </ListItem>
        </React.Fragment>
    );
};

export default RemovedTask;
