import React from "react";

// Material UI components
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { DateRange } from "../TopFilterBar/PeriodFilter";
import { TenderFilterInput } from "../../__generated__/globalTypes";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import update from "immutability-helper";

interface Props {
    setOpenFilter(open: string | boolean): void;
    dateRangeState: DateRange;
    onChange(searchInput: TenderFilterInput): void;
    searchInput: TenderFilterInput;
    // Pass through to template component
    label: string;
    setDateRange(dateRange: DateRange): void;
}
export type DropdownOption = { key: string; label: string };

const PeriodFilterItem: React.FC<Props> = ({ onChange, setOpenFilter, dateRangeState, searchInput, label, setDateRange, children }) => {
    const { t } = useTranslation();

    /**
     * DatePicker
     */
    const error = dateRangeState.from !== null && dateRangeState.to !== null && dateRangeState.from.valueOf() > dateRangeState.to.valueOf();

    /**
     * on click save button -> set selected items, reset filter to empty string and close selected filter
     */
    const handleClose = () => {
        handleSave();
        setOpenFilter(false);
    };

    /**
     * Handler when the popover gets closed
     * onError => unset filter to avoid errors in GUI
     * onSuccess => set filter to selected DateRange
     * Format to YYYY-MM-DD (format that is used in backend)
     */
    const labelToUse = label === "lastUpdate" ? "lastUpdate" : label.toLocaleLowerCase();
    const handleSave = () => {
        if (error || dateRangeState.from === null || dateRangeState.to === null) {
            return onChange(update(searchInput, { $unset: [labelToUse as keyof TenderFilterInput] }));
        }
        onChange(
            update(searchInput, {
                [labelToUse]: {
                    $set: {
                        from: moment(dateRangeState.from).format("YYYY-MM-DD"),
                        to: moment(dateRangeState.to).format("YYYY-MM-DD"),
                    },
                },
            })
        );
    };

    return (
        <React.Fragment>
            <div style={{ overflow: "auto", flex: 1 }}>
                <div style={{ padding: 16, display: "flex", alignItems: "center" }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={12} style={{ display: "flex", justifyContent: "center" }}>
                            <DatePicker
                                slotProps={{ textField: { variant: "standard" } }}
                                // format="DD/MM/YYYY"
                                label={t("filter.From")}
                                // variant="inline"
                                // autoOk={true}
                                value={dateRangeState.from}
                                onChange={(date) => setDateRange({ from: date, to: dateRangeState.to })}
                                // {...sharedProps}
                                // Clone endDate and subtract 1 day to prevent selecting the same day
                                maxDate={dateRangeState.to ? dateRangeState.to.clone() : undefined}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} style={{ display: "flex", justifyContent: "center" }}>
                            <DatePicker
                                slotProps={{ textField: { variant: "standard" } }}
                                label={t("filter.To")}
                                // autoOk={true}
                                value={dateRangeState.to}
                                onChange={(date) => {
                                    setDateRange({ to: date, from: dateRangeState.from });
                                }}
                                // {...sharedProps}
                                // Clone startDate and add 1 day to prevent selecting the same day
                                minDate={dateRangeState.from ? dateRangeState.from.clone() : undefined}
                            />
                        </Grid>
                        {/* Component contains child elements (variable content) */}
                        <Grid item sm={12} style={{ paddingTop: 48 }}>
                            {children}
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", margin: "24px" }}>
                {/*
                 * ResetButton
                 * setDateRange => set to null instead of a date
                 * unset the filter to avoid errors in GUI
                 *  */}
                <Button
                    sx={{ color: "#000000" }}
                    onClick={() => {
                        setDateRange({ from: null, to: null });
                        onChange(update(searchInput, { $unset: [label as keyof TenderFilterInput] }));
                    }}
                    variant="text"
                >
                    {t("filter.reset")}
                </Button>

                {/*
                 * Button to save selected dates
                 * Disabled if field === null
                 */}
                <Button
                    style={{ color: "white" }}
                    disabled={dateRangeState.from === null || dateRangeState.to === null}
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                >
                    {t("filter.save")}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default PeriodFilterItem;
