// WordModalGuide

import React, { useLayoutEffect } from "react";
import { Button } from "@mui/material";
import { Assistant } from "@mui/icons-material";
import GuideContent from "../elements/GuideContent";
import { useTour } from "../../contextProviders/TourContext";
import { useFirstSearchRuleSetter } from "../../contextProviders/FirstSearchRuleContext";

interface Props {
    hasSearchrules: boolean;
}

/**
 * @param step CurrentStep in tender searchwizard
 * @returns Returns current step as title and a button to open guide/tour
 */
const OppDistModalGuide: React.FC<Props> = ({ hasSearchrules }) => {
    //accent color
    const color = "#225E4D";
    // first searchrule global state
    const { distModalTourOpen, setDistModalTourOpen } = useFirstSearchRuleSetter();
    // tour variables to set
    // locktour => true means item is interactive and no next/prev button will be shown
    const { setTourVariables, setOpenTour, setLockTour, setCurrentStep } = useTour();

    /**
     * SPLIT IN 2 COMPONENTS
     * BUTTON
     * TOUR TO START
     */
    const steps = () => {
        return [
            /**
             * Step 1: Results
             */
            {
                selector: '[data-tut="reactour__distTitle"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Naam zoekregel invoeren"
                            content={<span>Is een document een resultaat van deze zoekregel, wordt dit met de gekozen naam aangeduid.</span>}
                        />
                    );
                },
                stepInteraction: true,
            },

            /**
             * Step 2: options
             */
            {
                selector: '[data-tut="reactour__distOptions"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Kies moment"
                            content={<span>Kies het moment wanneer een document een resultaat moet zijn van je zoekregel.</span>}
                        />
                    );
                },
                action: (n: any) => {
                    n.focus();
                    setLockTour(true);
                    const btn0 = document.getElementById("chiptochoose0");

                    /**
                     * find clicked button and go to next step
                     */
                    if (btn0) {
                        btn0.onclick = () => {
                            setTimeout(function () {
                                setLockTour(false);
                                setCurrentStep(2);
                            }, 100);
                        };
                    }
                },
                stepInteraction: true,
            },

            /**
             * Step 3: save
             */
            {
                selector: '[data-tut="reactour__distSave"]',
                content: () => {
                    return <GuideContent title="Zoekregel opslaan" content={<span>De zoekregel kan worden opgeslagen!</span>} />;
                },
                stepInteraction: true,
            },
        ];
    };

    /**
     * Check if user has searchrules and domainModalTourOpen = false
     * true => open tour on default for current step
     */
    useLayoutEffect(() => {
        if (hasSearchrules && !distModalTourOpen) {
            // timeout for smoother transition
            setTimeout(() => {
                setTourVariables(steps, color, "OppWizardDistribution");
                setOpenTour(true);
                setDistModalTourOpen(true);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distModalTourOpen, hasSearchrules]);

    return (
        <Button
            data-tut="reactour__tourbtn"
            variant="text"
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                setTourVariables(steps, color, "OppWizardDistribution");
                setOpenTour(true);
            }}
            startIcon={<Assistant fontSize="small" />}
        >
            Interactieve assistent
        </Button>
    );
};

export default OppDistModalGuide;
