import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// import { useFaq } from "./contextProviders/FaqOpenerContext";
// import { useView } from "./contextProviders/ViewKnowledgeContext";
// import { useTranslation } from "react-i18next";
interface Props {
    framework: string | null;
}

// const useStyles = makeStyles((theme: Theme) => ({
//     hyperlink: {
//         display: "inline",
//         cursor: "pointer",
//         color: "#1f4769",
//         "&:hover": {
//             textDecoration: "underline",
//         },
//     },
//     hyperlinktooltip: {
//         display: "inline",
//         cursor: "pointer",
//         fontStyle: "italic",
//         color: "#fff",
//         "&:hover": {
//             textDecoration: "underline",
//         },
//     },
// }));

const LegalFrameworkTooltip: React.FC<Props> = ({ framework }) => {
    // const { setOpenFaq } = useFaq();
    // const { setView } = useView();

    // const { t } = useTranslation();

    return (
        <React.Fragment>
            {(() => {
                switch (true) {
                    // If framework === aanbestedingswet 2012, show this case
                    case framework === "Aanbestedingswet 2012":
                        return (
                            <Tooltip
                                title={
                                    <>
                                        <Typography variant="caption" style={{ display: "inline" }}>
                                            Nederlandse wet voor omzetting Europese richtlijnen naar Nederlands recht.
                                        </Typography>{" "}
                                        {/* <Typography
                                            // className={classes.hyperlinktooltip}
                                            variant="caption"
                                            // onClick={() => {
                                            //     setOpenFaq(true);
                                            //     setView("13");
                                            // }}
                                        >
                                            {t("legal_basis_tooltip.ReadMore")}
                                        </Typography> */}
                                    </>
                                }
                            >
                                <Typography
                                    // onClick={() => {
                                    //     setOpenFaq(true);
                                    //     setView("13");
                                    // }}
                                    // className={classes.hyperlink}
                                    style={{ marginLeft: 4, display: "inline", cursor: "pointer" }}
                                >
                                    ({framework})
                                </Typography>
                            </Tooltip>
                        );

                    // if framework includes ARW 2016, show this case
                    case framework?.includes("ARW 2016"):
                        return (
                            <Tooltip
                                title={
                                    <>
                                        <Typography variant="caption" style={{ display: "inline" }}>
                                            Aanbestedingsreglement voor werken waarvan de waarde onder de Europese drempelwaarden ligt.
                                        </Typography>{" "}
                                        {/* <Typography
                                            // className={classes.hyperlinktooltip}
                                            variant="caption"
                                            // onClick={() => {
                                            //     setOpenFaq(true);
                                            //     setView("13");
                                            // }}
                                        >
                                            {t("legal_basis_tooltip.ReadMore")}
                                        </Typography> */}
                                    </>
                                }
                            >
                                <Typography
                                    // className={classes.hyperlink}
                                    style={{ display: "inline", cursor: "pointer" }}
                                >
                                    (ARW 2016)
                                </Typography>
                            </Tooltip>
                        );

                    // Fallback -> show framework without tooltip if none of above cases is true
                    default:
                        return <Typography>({framework})</Typography>;
                }
            })()}
        </React.Fragment>
    );
};

export default LegalFrameworkTooltip;
