import React, { useCallback, useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

//Import Material UI
import { yellowTheme } from "../../assets/styles";

//Import components
import setTheme from "../../utils/setTheme";
import MainLayout from "../../components/layout/MainLayout";
import ContractAuthorityDetailPage from "./ContractingAuthorityDetailPage";
import CAoverview from "./allCApage/CAoverview";
import CAstarredOverview from "./starredPage/CAstarredOverview";
import NotFound404 from "../NotFound404";
import ModuleTabs, { DynamicTab } from "../../components/ModuleTabs";
import { ThemeProvider } from "@mui/material";

const ContractingAuthorityModule: React.FC = () => {
    setTheme("yellow");
    const navigate = useNavigate();
    const history = useLocation();
    const [tabs, setTabs] = useState<DynamicTab[]>([]);

    const { trackPageView } = useMatomo();

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: "Ca", // optional
            href: `https://tool.tender.guide${history.pathname}`, // optional
        });
    }, [trackPageView, history.pathname]);

    /**
     * Get tabs in session storage (opportunity) and store in state
     */
    useEffect(() => {
        if (sessionStorage.getItem("oadt")) {
            setTabs(JSON.parse(sessionStorage.getItem("oadt") || "[]"));
        }
    }, []);

    //  Register a new tab to the CA Module
    const registerTab = useCallback((tab: DynamicTab) => {
        setTabs((prevTabs) => {
            // If the tab that is going to registered is not in the list yet
            // Add it
            if (!prevTabs.find((prevTab) => prevTab.value === tab.value)) {
                return [...prevTabs, tab];
            }
            return prevTabs;
        });
    }, []);

    /**
     * Handle closing the tab,
     * if active tab is closed go to /contracting-authorities
     */
    const closeTab = (url: string) => {
        setTabs((prevTabs) => {
            const newTabs = prevTabs.filter((tab) => tab.value !== url);
            // If active tender is the one thats been closed -> navigate to worklist
            // otherwise navigate to current pathname
            if (history.pathname.includes(url)) {
                navigate("/contracting-authorities");
            } else {
                navigate(history.pathname);
            }
            return newTabs;
        });
    };

    /**
     * Handle closing all tabs, and navigate to all ca's
     * Dont navigate if user is on the other default tab
     */
    const closeAllTab = () => {
        setTabs([]);
        if (history.pathname === "/contracting-authorities/starred") {
            navigate("/contracting-authorities/starred");
        } else {
            navigate("/contracting-authorities");
        }
    };

    /**
     * Array of default tabs
     */
    const defaultTabs = [
        { id: 1, route: "/contracting-authorities", label: "All" },
        { id: 2, route: "/contracting-authorities/starred", label: "StarMarked" },
    ];

    /**
     * update session storage when tabs change
     */
    useEffect(() => {
        sessionStorage.setItem("oadt", JSON.stringify(tabs));
    }, [tabs]);

    return (
        <ThemeProvider theme={yellowTheme}>
            <MainLayout
                page="contracting-authorities"
                header={
                    <ModuleTabs
                        page="Ca"
                        value={history.pathname}
                        onChange={(value) => navigate(value)}
                        onClose={closeTab}
                        tabs={tabs}
                        onCloseAll={closeAllTab}
                        defaultTabs={defaultTabs}
                    />
                }
            >
                <Routes>
                    <Route path="" element={<CAoverview />} />
                    <Route path="starred" element={<CAstarredOverview />} />
                    <Route path=":id/*" element={<ContractAuthorityDetailPage registerTab={registerTab} />} />
                    <Route path="*" element={<NotFound404 url="/contracting-authorities" />} />
                </Routes>
            </MainLayout>
        </ThemeProvider>
    );
};

export default ContractingAuthorityModule;
