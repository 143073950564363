import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, Divider, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import Tasks from "./task/Tasks";
import ProjectTopNavBar from "./ProjectTopNavBar";
// import ProjectDocuments from "./ProjectDocuments";
import ProjectEntities from "./ProjectEntities";
import TenderWidgetBox from "./tender/TenderWidgetBox";
import CaWidgetBox from "./ca/CaWidgetBox";
import { useQuery } from "@apollo/client";
import { SINGLE_PROJECT } from "../../../graphql/queryDefProjects";
import { single_project, single_projectVariables } from "../../../__generated__/single_project";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import Notes from "./notes/Notes";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

// import update from "immutability-helper";

interface Props {
    a?: number;
}

const ProjectModal: React.FC<Props> = (props) => {
    // state to show fullscreen or dialog screen
    const { open, setOpen, project, changeProject } = useContext(ProjectToOpenContext);
    const [staticload, SetStaticLoad] = useState<boolean>(true);
    const [views, setViews] = useState<string[]>(["tasks", "notes"]);
    const [newAddedTenderFoundOrNull, setNewAddedTenderFoundOrNull] = useState<boolean>(false);
    const { trackEvent } = useMatomo();

    // Track navigation items
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const navigate = useNavigate();

    useQuery<single_project, single_projectVariables>(SINGLE_PROJECT, {
        skip: project.projectID === "newProject",
        variables: { id: project.projectID },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setTimeout(() => {
                SetStaticLoad(false);
            }, 700);
            if (data && data.project) {
                changeProject({
                    users:
                        data.project.users?.map((user) => ({
                            id: user.id,
                            name: user.employee.name || "",
                            initials: `${user.employee.name?.slice(0, 1)}${user.employee.familyname?.slice(0, 1)}`,
                        })) || [],
                    tender_ids: data.project.tenders?.map((i) => i?.id || "") || [],
                    ca_ids: data.project.contractingAuthorities?.map((i) => i?.id || "") || [],
                    widgets: JSON.parse(data.project.widgets),
                });
                if (data.project.tenders && data.project.tenders.length > 0) {
                    setViews(["tasks", "notes", "entities"]);
                }

                if (data.project.reviewCancellation !== null) {
                    // date of cancel is before date of new added tender
                    setNewAddedTenderFoundOrNull(moment(data.project.reviewCancellation).isBefore(data.project.dateOfAddingTender));
                }
                if (data.project.reviewCancellation === null) {
                    setNewAddedTenderFoundOrNull(true);
                }
            }
        },
    });

    const handleViews = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
        setViews(newFormats);
        trackClick({
            category: "Project",
            action: "Toggle-view-in-project",
            name: newFormats.join(","),
        });
    };

    const closeModal = () => {
        setOpen(false);
        changeProject({
            projectID: `newProject`,
            projectTitle: "",
            state: "",
            widgets: { summary: true, planning: true, scope: true, documents: true, contract: true, labels: true },
            tasks: [],
            users: [],
            ca_ids: [],
            tender_ids: [],
        });
    };

    const closeAndNavigate = (id: string) => {
        trackClick({
            category: "Project",
            action: "expand-project",
        });

        const test = id;
        closeModal();
        navigate(`/projects/${test}`);
    };

    return (
        <Dialog
            PaperProps={{
                sx: { minHeight: "95vh", minWidth: "70vw" },
            }}
            fullWidth
            scroll="paper"
            maxWidth={"lg"}
            open={open}
            onClose={closeModal}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogContent sx={{ padding: 0 }}>
                {/* <Grid container columns={20} sx={{ minHeight: size ? "100vh" : "75vh" }}> */}
                <Grid container columns={20}>
                    <Grid item container columns={20}>
                        {/* Left panel */}
                        <Grid item xs={16} md={13} sx={{ padding: "16px" }}>
                            {/* Topbar with title and buttons */}
                            <ProjectTopNavBar handleViews={handleViews} views={views} loading={staticload} />

                            {/* Task block */}
                            <Collapse in={views.includes("tasks")}>
                                <Tasks />
                            </Collapse>

                            {/* Note block */}
                            <Collapse in={views.includes("notes")}>
                                <Notes />
                            </Collapse>

                            {/* Coming soon docs */}
                            {/* {views.includes("docs") && <ProjectDocuments />} */}
                        </Grid>
                        {/* divider */}
                        <Divider orientation="vertical" flexItem sx={{ mr: "-1px", mt: "8px", mb: "8px" }} />

                        {/* Right panel wt */}
                        <Grid item xs={16} md={7} sx={{ padding: "16px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box component={"div"}>
                                {/* Tenders */}
                                <TenderWidgetBox
                                    tender_id={project.tender_ids[0]}
                                    newAddedTenderFoundOrNull={newAddedTenderFoundOrNull}
                                    project_id={project.projectID}
                                />

                                {/* Contracting authorities */}
                                <CaWidgetBox ca_id={project.ca_ids} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />

                {/*
                 * Entities of project
                 */}
                {views.includes("entities") && <ProjectEntities />}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeAndNavigate(project.projectID);
                    }}
                >
                    Vergroten
                </Button>
                <Button onClick={closeModal} color="primary">
                    Sluiten
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProjectModal;
