import React from "react";

import { Button, Menu, MenuItem } from "@mui/material";
// MUI v4 icons
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ReorderIcon from "@mui/icons-material/Reorder";
import SyncIcon from "@mui/icons-material/Sync";
import { ArrowDropDown } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { useLocation } from "react-router-dom";

export enum View {
    List = 0,
    Column = 1,
    Event = 2,
    Split = 3,
}

interface Props {
    activeView: number | undefined;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onViewClick: (view: number) => void;
    handleClose: () => void;
    anchorEl: null | HTMLElement;
    worklist: boolean;
    allPage: boolean;
    reviewPage: boolean;
    /**
     * Array of optional views
     * array can be different for each page
     */
    views?: View[];
}

const ViewMenu: React.FC<Props> = ({ activeView, handleClick, onViewClick, handleClose, anchorEl, views }) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const { pathname } = useLocation();

    // Track event
    const trackViewMode = () => {
        if (pathname === "/tenders/worklist") {
            if (activeView === 0) {
                trackEvent({ category: "Tender", action: `Click-tender-worklist-listview` } as MatomoEvent);
            }
            if (activeView === 1) {
                trackEvent({ category: "Tender", action: `Click-tender-worklist-columnview` } as MatomoEvent);
            }
            if (activeView === 2) {
                trackEvent({ category: "Tender", action: `Click-tender-worklist-updateview` } as MatomoEvent);
            }
            if (activeView === 3) {
                trackEvent({ category: "Tender", action: `Click-tender-worklist-splitview` } as MatomoEvent);
            }
        }
        if (pathname === "/tenders/reviews") {
            if (activeView === 0) {
                trackEvent({ category: "Tender", action: `Click-tender-reviews-listview` } as MatomoEvent);
            }
            if (activeView === 1) {
                trackEvent({ category: "Tender", action: `Click-tender-reviews-columnview` } as MatomoEvent);
            }
            if (activeView === 2) {
                trackEvent({ category: "Tender", action: `Click-tender-reviews-updateview` } as MatomoEvent);
            }
            if (activeView === 3) {
                trackEvent({ category: "Tender", action: `Click-tender-reviews-splitview` } as MatomoEvent);
            }
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} endIcon={<ArrowDropDown />}>
                {activeView === 0
                    ? t("Views.ListView")
                    : activeView === 1
                    ? t("Views.ColumnView")
                    : activeView === 2
                    ? t("Views.UpdateView")
                    : activeView === 3
                    ? t("Views.SplitView")
                    : null}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {/* Map over optional views and show icon with text */}
                {views?.map((view) => {
                    return (
                        <MenuItem
                            key={view}
                            onClick={() => {
                                onViewClick(view);
                                trackViewMode();
                                handleClose();
                            }}
                        >
                            {view === 0 ? (
                                <React.Fragment>
                                    <ReorderIcon sx={{ marginRight: "8px", color: "#707070", fontSize: "19px" }} /> {t("Views.ListView")}
                                </React.Fragment>
                            ) : view === 1 ? (
                                <React.Fragment>
                                    <ViewColumnIcon sx={{ marginRight: "8px", color: "#707070", fontSize: "19px" }} /> {t("Views.ColumnView")}
                                </React.Fragment>
                            ) : view === 2 ? (
                                <React.Fragment>
                                    <SyncIcon sx={{ marginRight: "8px", color: "#707070", fontSize: "19px" }} /> {t("Views.UpdateView")}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <VerticalSplitIcon sx={{ marginRight: "8px", color: "#707070", fontSize: "19px" }} /> {t("Views.SplitView")}
                                </React.Fragment>
                            )}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default ViewMenu;
