import React, { useEffect, useState } from "react";
import WidgetBox from "../../../components/WidgetBox";
import { useTranslation } from "react-i18next";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
    GET_AMOUNT_OF_SEARCHRULES,
    QUERY_GETSTARREDCAS,
    QUERY_TILE_WIDGET_DATA,
    QUERY_USER_LIKED_TENDERS,
} from "../../../graphql/queryDefCurrentUser";
import { useLazyQuery, useQuery } from "@apollo/client";
import { getSearchRuleCount } from "../../../__generated__/getSearchRuleCount";
import {
    GetTileWidgetData,
    GetTileWidgetDataVariables,
    GetTileWidgetData_filterTenders_results_data,
} from "../../../__generated__/GetTileWidgetData";
import { SortOrder, TenderFilterSource, TenderFilterTarget } from "../../../__generated__/globalTypes";
import _ from "lodash";
import LogoBox from "./tileComponents/LogoBox";
import ExpandedCard from "./tileComponents/ExpandedCard";
import moment from "moment";
import { GetStarredCas } from "../../../__generated__/GetStarredCas";
import { GetLikedTendersOfUser } from "../../../__generated__/GetLikedTendersOfUser";
import { Star, ThumbUp } from "@mui/icons-material";
// import FilterComp from "./FilterComp";

interface Props {
    a?: number;
}
interface GroupedData {
    [key: string]: {
        clientId: string;
        name: string | null;
        logo: string | null;
        favicon: string | null;
        tenders: GetTileWidgetData_filterTenders_results_data[];
    };
}

export interface ClickedItem {
    clientId: string;
    name: string | null;
    logo: string | null;
    favicon: string | null;
    tenders: GetTileWidgetData_filterTenders_results_data[];
}

const TileContractContainer: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [expandedCard, setExpandedCard] = useState<boolean>(false);
    const [searches, setSearches] = useState<number[]>([]);
    const [clickedData, setClickedData] = useState<ClickedItem | null>(null);
    const { data: TNDR } = useQuery<GetLikedTendersOfUser>(QUERY_USER_LIKED_TENDERS, { fetchPolicy: "network-only" });
    const tndrs = TNDR?.currentUser.likedTenders.map((t) => t.id);
    const { data: CAS } = useQuery<GetStarredCas>(QUERY_GETSTARREDCAS, { fetchPolicy: "network-only" });
    const starredCAS = CAS?.currentUser.contracting_authorities;

    // Variables for the GQL query
    const variables: GetTileWidgetDataVariables = {
        page: 1,
        count: 1000,
        orderField: "updated_at",
        order: SortOrder.DESC,
        search: {
            source: TenderFilterSource.ALL_TENDERS,
            target: TenderFilterTarget.SUMMARY,
            filters: {
                searches: searches,
                next_enddate_contract: { from: moment().format("YYYY-MM-DD"), to: moment().add(6, "months").format("YYYY-MM-DD") },
                status: [0, 1, 2, 4, 8, 16, 64],
                country: [528],
            },
        },
    };

    const { loading: load } = useQuery<getSearchRuleCount>(GET_AMOUNT_OF_SEARCHRULES, {
        onCompleted: (data) => {
            if (data && data.currentUser && data.currentUser.searches) {
                setSearches(data.currentUser.searches.map((i) => parseInt(i.id)) ?? []);
            }
        },
    });

    const [run, { data, loading }] = useLazyQuery<GetTileWidgetData, GetTileWidgetDataVariables>(QUERY_TILE_WIDGET_DATA, {
        variables: variables,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        run({
            variables: {
                page: 1,
                count: 200,
                orderField: "updated_at",
                order: SortOrder.DESC,
                search: {
                    source: TenderFilterSource.ALL_TENDERS,
                    target: TenderFilterTarget.SUMMARY,
                    filters: {
                        searches: searches,
                        next_enddate_contract: { from: moment().format("YYYY-MM-DD"), to: moment().add(6, "months").format("YYYY-MM-DD") },
                        status: [0, 1, 2, 4, 8, 16, 64],
                        country: [528],
                    },
                },
            },
        });
    }, [run, searches]);

    const handleCardClick = () => {
        setExpandedCard(true);
    };

    const handleClose = () => {
        setExpandedCard(false);
        setClickedData(null);
    };

    const groupByClientId = (dataArray: GetTileWidgetData_filterTenders_results_data[]): GroupedData => {
        // Group by the clientId and include name, logo, and favicon as properties
        const groupedData = _.groupBy(dataArray, (item) => {
            const clientA = item.clientA[0];
            return clientA ? clientA.id : "NoClientID";
        });

        // Convert to an array and include name, logo, and favicon as properties
        const groupedArray = Object.entries(groupedData).map(([clientId, tenders]) => {
            const clientA = tenders[0].clientA[0];
            return {
                clientId: clientId,
                name: clientA ? (clientA.name as string) : tenders[0].published_opdrachtgever, // Handle null or undefined name
                logo: clientA ? clientA.logo : null,
                favicon: clientA ? (clientA.favicon as string) : "No Favicon",
                tenders: tenders,
            };
        });

        // Convert the array back to an object with clientId as keys
        const result: GroupedData = {};
        groupedArray.forEach((group) => {
            result[group.clientId] = group;
        });

        return result;
    };

    if (loading || load) {
        return (
            <WidgetBox
                header={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>Aflopende contracten (half jaar)</div>
                        <Typography>
                            <Link id="link-to-full-list" to="/tenders/reviews">
                                {t("dashboardPage.ShowAll")}
                            </Link>
                        </Typography>
                    </div>
                }
                highlight="blue"
                padding={0}
            >
                <Box sx={{ width: "100%", height: { xs: "335px", md: "355px" }, position: "relative", overflow: "auto", padding: "8px" }}>
                    <Grid container spacing={1} sx={{ position: "relative" }} justifyContent="flex-start" alignItems="flex-start">
                        {Array.from({ length: 42 }, (_, index) => index + 1).map((n) => {
                            return (
                                <Grid item key={n}>
                                    <Skeleton
                                        sx={{
                                            borderRadius: "8px",
                                            //  animationDelay: `${n * 111}ms`, animationDuration: "1.5s"
                                        }}
                                        variant="rectangular"
                                        width={80}
                                        height={80}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </WidgetBox>
        );
    }
    if (!data || !data.filterTenders || !data.filterTenders.results) {
        return null;
    }

    const findIdInObjects = (ids: string[] | undefined, objects: GetTileWidgetData_filterTenders_results_data[]) => {
        if (!ids) return false;
        return ids.some((id) => objects.some((object) => object.id === id));
    };

    const groupedData = groupByClientId(data.filterTenders.results.data);

    return (
        <WidgetBox
            header={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {/* {t("dashboardPage.UpdatesInterestingTenders")} */}
                        Aflopende contracten (half jaar)
                    </div>
                    {/* <Typography>
                            <Link id="link-to-full-list" to="/tenders/reviews">
                                {t("dashboardPage.ShowAll")}
                            </Link>
                        </Typography> */}
                </div>
            }
            highlight="blue"
            padding={0}
        >
            <Box sx={{ width: "100%", height: { xs: "335px", md: "355px" }, position: "relative", overflow: "auto", padding: "8px" }}>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        position: "relative",
                    }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {Object.values(groupedData).map(({ clientId, name, logo, favicon, tenders }) => {
                        return (
                            <Grid
                                item
                                key={clientId}
                                onClick={() => {
                                    handleCardClick();
                                    setClickedData({ clientId, name, logo, favicon, tenders });
                                }}
                            >
                                <LogoBox logo={logo} favicon={favicon === "No Favicon" ? null : favicon} name={name ?? ""} textColor="#F57117" />

                                <div
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "row",
                                        bottom: 18,
                                        right: 4,
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            borderRadius: "8px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {findIdInObjects(tndrs, tenders) && (
                                            <div
                                                style={{
                                                    height: "16px",
                                                    width: "16px",
                                                    backgroundColor: "#fff",
                                                    marginLeft: "4px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    borderRadius: 8,
                                                }}
                                            >
                                                <ThumbUp sx={{ height: "12px", width: "12px", color: "#173357" }} />
                                            </div>
                                        )}

                                        {starredCAS?.find((c) => c.id === clientId) && (
                                            <div
                                                style={{
                                                    height: "16px",
                                                    width: "16px",
                                                    backgroundColor: "#fff",
                                                    marginLeft: "4px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    borderRadius: 8,
                                                }}
                                            >
                                                <Star sx={{ height: "12px", width: "12px", color: "#F57117" }} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}

                    {/*
                     * Detailcontainer
                     */}
                    {expandedCard && clickedData && <ExpandedCard open={expandedCard} onClose={handleClose} data={clickedData} />}
                </Grid>
            </Box>
        </WidgetBox>
    );
};

export default TileContractContainer;
