import { createContext, useContext } from "react";

export interface Option {
    id: number;
    label: any;
    key: any;
}
export type OppCustomOptions = {
    customOppOptionsWorklist: Option[];
    customOppOptionsReviews: Option[];
    customOppOptionsAll: Option[];
    setCustomOppOptionsWorklist: (customOptions: any) => void;
    setCustomOppOptionsReviews: (customOptions: any) => void;
    setCustomOppOptionsAll: (customOptions: any) => void;
};

export const OppCustomOptionsContext = createContext<OppCustomOptions>({
    customOppOptionsWorklist: [],
    customOppOptionsReviews: [],
    customOppOptionsAll: [],
    setCustomOppOptionsWorklist: (customOptions: any) => console.warn("no context provider"),
    setCustomOppOptionsReviews: (customOptions: any) => console.warn("no context provider"),
    setCustomOppOptionsAll: (customOptions: any) => console.warn("no context provider"),
});
export const useCustomOppOptions = () => useContext(OppCustomOptionsContext);
