import React, { useState } from "react";
import { CircularProgress, IconButton, InputAdornment, OutlinedInput, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OptionCard from "./OptionCard";
import { Email, Send, Lock, ArrowBack, PowerSettingsNew } from "@mui/icons-material";
import { useAuthSettings } from "./AuthSettingsContext";
import DefaultOption from "./DefaultOption";

interface Props {
    a?: number;
}

const MailOption: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { enableMail, disableMail, loading, passwordMailIncorrect, data_two_factor, clickedOption, setClickedOption } = useAuthSettings();
    const [passwordMail, setPasswordMail] = useState<string>("");
    const [disable, setDisable] = useState<boolean>(false);
    const [password2FA, setPassword2FA] = useState<string>("");
    const loadmutate = loading.enableMail || loading.disableMail;

    return (
        <>
            {clickedOption === "mail" && !Boolean(data_two_factor?.currentUser.two_factor_email_enabled) ? (
                <OptionCard
                    align="end"
                    option="MAIL"
                    success={Boolean(data_two_factor?.currentUser.two_factor_email_enabled)}
                    onClick={() => {
                        setClickedOption("");
                        setPasswordMail("");
                    }}
                    disabled={false}
                    icon={
                        loading.loadingState ? (
                            <CircularProgress sx={{ color: "#ffa24b" }} />
                        ) : (
                            <Lock sx={{ color: "#ffa24b", width: "56px", height: "56px" }} />
                        )
                    }
                    text={passwordMailIncorrect ? "Wachtwoord incorrect" : "Vul je wachtwoord in"}
                    wrongPW={passwordMailIncorrect}
                >
                    <OutlinedInput
                        error={passwordMailIncorrect}
                        onClick={(e) => e.stopPropagation()}
                        type="password"
                        placeholder={t("auth.password")}
                        value={passwordMail}
                        onChange={(e) => setPasswordMail(e.target.value)}
                        fullWidth
                        size="small"
                        autoFocus
                        autoComplete="current-password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" size="small" onClick={() => enableMail(passwordMail)} edge="end">
                                    {<Send fontSize="small" />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </OptionCard>
            ) : (
                <OptionCard
                    option="MAIL"
                    onClick={() => setClickedOption("mail")}
                    avatarColor="#ffa24b"
                    success={Boolean(data_two_factor?.currentUser.two_factor_email_enabled)}
                    disabled={false}
                    icon={loadmutate ? <CircularProgress /> : <Email sx={{ width: "56px", height: "56px" }} />}
                    align="end"
                    text={
                        passwordMailIncorrect
                            ? "Wachtwoord incorrect"
                            : Boolean(data_two_factor?.currentUser.two_factor_email_enabled) === true
                            ? "E-mail verificatie ingeschakeld"
                            : "E-mail verificatie"
                    }
                    wrongPW={passwordMailIncorrect}
                >
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        {!Boolean(data_two_factor?.currentUser.two_factor_email_enabled) ? (
                            <Typography textAlign={"center"} variant="body2" sx={{ marginBottom: "8px", color: "text.secondary" }}>
                                Ontvang een e-mail met een verificatiecode
                            </Typography>
                        ) : (
                            <div>
                                {!disable ? (
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <DefaultOption option={"MAIL"} disable={!Boolean(data_two_factor?.currentUser.two_factor_email_enabled)} />
                                        <Tooltip title="Uitschakelen" placement="top">
                                            <IconButton color="error" onClick={() => setDisable(true)}>
                                                <PowerSettingsNew />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setDisable(false);
                                            }}
                                        >
                                            <ArrowBack />
                                        </IconButton>
                                        <OutlinedInput
                                            error={passwordMailIncorrect}
                                            onClick={(e) => e.stopPropagation()}
                                            type="password"
                                            placeholder={t("auth.password")}
                                            value={password2FA}
                                            onChange={(e) => setPassword2FA(e.target.value)}
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            autoComplete="current-password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        size="small"
                                                        onClick={() => {
                                                            disableMail(password2FA);
                                                            setPassword2FA("");
                                                        }}
                                                        edge="end"
                                                    >
                                                        {<Send fontSize="small" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </OptionCard>
            )}
        </>
    );
};

export default MailOption;
