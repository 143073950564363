import React from "react";
import { Accordion, AccordionDetails, AccordionSlots, AccordionSummary, Fade, SvgIconTypeMap, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface Props {
    panelID: string;
    expanded: boolean;
    handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    title: string;
    subTitle: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const PersonalAccordion: React.FC<Props> = ({ panelID, expanded, handleChange, title, subTitle, children, icon: Icon }) => {
    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange(panelID)}
            slots={{ transition: Fade as AccordionSlots["transition"] }}
            slotProps={{ transition: { timeout: 400 } }}
            sx={[
                expanded
                    ? {
                          "& .MuiAccordion-region": {
                              height: "auto",
                          },
                          "& .MuiAccordionDetails-root": {
                              display: "block",
                          },
                      }
                    : {
                          "& .MuiAccordion-region": {
                              height: 0,
                          },
                          "& .MuiAccordionDetails-root": {
                              display: "none",
                          },
                      },
            ]}
        >
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                <div style={{ display: "flex", alignItems: "center" }}>
                    {/* Icon to show in title */}
                    <Icon style={{ color: "#9e9e9e", marginRight: "8px" }} />
                    {/* Title of accordion */}
                    <Typography fontSize={"1rem"} fontWeight={500}>
                        {title}
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default PersonalAccordion;
