import React from "react";
import { GET_UPDATED_AT_COLUMN } from "../../graphql/queryDefForColumns";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { GetUpdatedAtColumn, GetUpdatedAtColumnVariables } from "../../__generated__/GetUpdatedAtColumn";
import moment from "moment";

interface Props {
    tender_id: string;
}

const UpdatedAtColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetUpdatedAtColumn, GetUpdatedAtColumnVariables>(GET_UPDATED_AT_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;
    const updatetime = tender.updated_at ? moment(tender.updated_at).format("L") : "";
    return <Typography style={{ whiteSpace: "nowrap" }}>{updatetime}</Typography>;
};

export default UpdatedAtColumn;
