import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import UserList from "./UserList";
import SingleTeam from "./SingleTeam";
import { TeamInSub, UserInTeam } from "./UsersAndTeams";
import TeamAnimation from "../example/TeamAnimation";
import {
    GetScubscriptionAndTeams_organization_subscriptions_adminUsers,
    GetScubscriptionAndTeams_organization_subscriptions_allowedDomains,
} from "../../../__generated__/GetScubscriptionAndTeams";

interface Props {
    teamSubList: TeamInSub[];
    userList: UserInTeam[];
    toggle: boolean;
    error: boolean;
    loading: boolean;
    seats: number | null | undefined;
    totalSeats: number | null | undefined;
    subOwner: (GetScubscriptionAndTeams_organization_subscriptions_adminUsers | null)[] | undefined;
    allowedDomains: (GetScubscriptionAndTeams_organization_subscriptions_allowedDomains | null)[] | undefined;
    setReset: () => void;
    setUserList: (t: UserInTeam[]) => void;
    mobileView: "users" | "teams";
}

const UserAndTeamsContent: React.FC<Props> = ({
    subOwner,
    teamSubList,
    userList,
    toggle,
    error,
    loading,
    seats,
    allowedDomains,
    setReset,
    setUserList,
    totalSeats,
    mobileView,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    if (error || loading || toggle) {
        return (
            <Grid container justifyContent="space-between" xs={12} md={12}>
                {/*
                 * Div for the title of the paper
                 */}
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={(theme) => ({
                        padding: "20px",
                        paddingLeft: "0px !important",
                        // TABLET style
                        [theme.breakpoints.up("md")]: {
                            padding: "10px",
                        },

                        // MOBILE style
                        [theme.breakpoints.down("sm")]: {
                            padding: 0,
                            marginBottom: "16px",
                        },
                    })}
                >
                    {/*
                     * <h3>
                     * {userTotal} ({users.length})
                     * </h3>
                     */}

                    {/*
                     * Left paper with list of users, searchbar and button for add new user.
                     */}
                    <UserList
                        totalSeats={toggle ? totalSeats : null}
                        users={userList}
                        loading={loading}
                        seats={toggle ? seats : null}
                        subOwner={subOwner}
                        allowedDomains={allowedDomains}
                        setReset={setReset}
                        setUserList={setUserList}
                    />
                </Grid>

                {/*
                 * Right column: Teamsection User can manage teams in this section.
                 */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    sx={(theme) => ({
                        padding: "20px",
                        // TABLET style
                        [theme.breakpoints.up("md")]: {
                            padding: "10px",
                        },

                        // MOBILE style
                        [theme.breakpoints.down("sm")]: {
                            padding: 0,
                        },
                    })}
                >
                    <>
                        {/*
                         * If there is no team yet: animation to show to user how to create teams.
                         */}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "-6px",
                                overflow: "auto",
                                height: "75vh",
                            }}
                        >
                            <TeamAnimation toggle={toggle} error={error} loading={loading} />
                        </div>
                    </>
                </Grid>
            </Grid>
        );
    }

    if (isMobile) {
        return (
            <Grid container justifyContent="space-between" xs={12} md={12}>
                {/*
                 * Div for the title of the paper
                 */}
                {mobileView === "users" && (
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={(theme) => ({
                            padding: "20px",
                            paddingLeft: "0px !important",
                            // TABLET style
                            [theme.breakpoints.up("md")]: {
                                padding: "10px",
                            },

                            // MOBILE style
                            [theme.breakpoints.down("sm")]: {
                                padding: 0,
                                marginBottom: "16px",
                            },
                        })}
                    >
                        {/*
                         * <h3>
                         *    {userTotal} ({users.length})
                         * </h3>
                         */}

                        {/*
                         * Left paper with list of users, searchbar and button for add new user.
                         */}
                        <UserList
                            totalSeats={totalSeats}
                            users={userList}
                            loading={false}
                            seats={seats}
                            subOwner={subOwner}
                            allowedDomains={allowedDomains}
                            setReset={setReset}
                            setUserList={setUserList}
                        />
                    </Grid>
                )}

                {/*
                 * Right column: Teamsection User can manage teams in this section.
                 */}
                {mobileView === "teams" && (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        sx={(theme) => ({
                            padding: "20px",
                            // TABLET style
                            [theme.breakpoints.up("md")]: {
                                padding: "10px",
                            },

                            // MOBILE style
                            [theme.breakpoints.down("sm")]: {
                                padding: 0,
                            },
                        })}
                    >
                        <div>
                            {/*
                             * When there is a team, the animation wont show up again but user sees a list of teams.
                             */}
                            <div style={{ marginTop: "-6px", overflow: "auto", height: "75vh" }}>
                                <Grid container xs={12} sm={12} md={12}>
                                    {teamSubList.map((team) => {
                                        return (
                                            <Grid item key={team.id} xs={12} md={6} lg={4} sx={{ padding: { xs: "8px 0px", md: "8px" } }}>
                                                <SingleTeam team={team} />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                )}
            </Grid>
        );
    }

    return (
        <Grid container justifyContent="space-between" xs={12} md={12}>
            {/*
             * Div for the title of the paper
             */}
            <Grid
                item
                xs={12}
                md={4}
                sx={(theme) => ({
                    padding: "20px",
                    paddingLeft: "0px !important",
                    // TABLET style
                    [theme.breakpoints.up("md")]: {
                        padding: "10px",
                    },

                    // MOBILE style
                    [theme.breakpoints.down("sm")]: {
                        padding: 0,
                        marginBottom: "16px",
                    },
                })}
            >
                {/*
                 * <h3>
                 *    {userTotal} ({users.length})
                 * </h3>
                 */}

                {/*
                 * Left paper with list of users, searchbar and button for add new user.
                 */}
                <UserList
                    totalSeats={totalSeats}
                    users={userList}
                    loading={false}
                    seats={seats}
                    subOwner={subOwner}
                    allowedDomains={allowedDomains}
                    setReset={setReset}
                    setUserList={setUserList}
                />
            </Grid>

            {/*
             * Right column: Teamsection User can manage teams in this section.
             */}

            <Grid
                item
                xs={12}
                sm={12}
                md={8}
                sx={(theme) => ({
                    padding: "20px",
                    // TABLET style
                    [theme.breakpoints.up("md")]: {
                        padding: "10px",
                    },

                    // MOBILE style
                    [theme.breakpoints.down("sm")]: {
                        padding: 0,
                    },
                })}
            >
                <div>
                    {/*
                     * When there is a team, the animation wont show up again but user sees a list of teams.
                     */}
                    <div style={{ marginTop: "-6px", overflow: "auto", height: "75vh" }}>
                        <Grid container xs={12} sm={12} md={12}>
                            {teamSubList.map((team) => {
                                return (
                                    <Grid item key={team.id} xs={12} md={6} lg={4} sx={{ padding: { xs: "8px 0px", md: "8px" } }}>
                                        <SingleTeam team={team} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default UserAndTeamsContent;
