import React, { useContext, useState } from "react";
import {
    Avatar,
    AvatarGroup,
    Backdrop,
    Box,
    CircularProgress,
    Divider,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import ProjectTitle from "./ProjectTitle";
import ProjectFaseSelector from "./ProjectFaseSelector";
import { HubOutlined, TaskAlt, Notes } from "@mui/icons-material";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import { Disable } from "react-disable";
import { useQuery } from "@apollo/client";
import { useCurrentUserOrganization } from "../../../components/contextProviders/CurrentUserOrganization";
import { GetSubscriptionUsers } from "../../../__generated__/GetSubscriptionUsers";
import { QUERY_SUBSCRIPTION_USERS } from "../../../graphql/queryDefUserAndTeams";
// import { userIsNotCu } from "../../../utils/messenger";
import AddUsersToProject from "./AddUsersToProject";

interface Props {
    views: string[];
    handleViews: (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => void;
    loading: boolean;
    id?: string;
}

const ProjectTopNavBar: React.FC<Props> = ({ views, handleViews, loading, id }) => {
    const [openPopper, setOpenPopper] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { org_id } = useCurrentUserOrganization();
    const { project, changeProject } = useContext(ProjectToOpenContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    /**
     * Query colleagues of user to create contactlist
     */
    const colleagues = useQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
    });

    /**
     * Contactlist of user
     */
    const contacts =
        colleagues.data?.organization?.users.map((user) => ({
            id: user.id || "",
            name: user.employee.name || "",
            familyName: user.employee.familyname || "",
        })) || [];
    // .filter((colleague) => userIsNotCu(colleague)) || [];

    // toggle editmode to edit title of project
    const [editMode, setEditMode] = useState<boolean>(false);

    const allUsers = contacts.map((contact) => ({
        id: contact.id,
        name: contact.name,
        initials: `${contact.name.slice(0, 1)}${contact.familyName.slice(0, 1)}`,
    }));

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenPopper(true);
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenPopper(false);
    };

    return (
        <React.Fragment>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex", flexDirection: "column" }} open={loading}>
                <CircularProgress color="inherit" />
                <Typography sx={{ marginTop: "8px" }}>projectgegevens ophalen...</Typography>
            </Backdrop>
            <Box component="div" sx={{ display: "flex", flexDirection: "row" }}>
                {/* Project title */}
                <ProjectTitle
                    projectTitle={project.projectTitle}
                    setProjectTitle={(title: string) => changeProject({ projectTitle: title })}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    projectId={project.projectID}
                    bossOfProject={true}
                />
                {/* Fase selector */}
                <ProjectFaseSelector width={isMobile ? "100px" : "182px"} />
            </Box>
            {/* Divider */}
            <Divider sx={{ marginTop: "4px" }} />
            <Disable disabled={loading}>
                <Box
                    component="div"
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "4px",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        {/* Toggle buttons to show/hide components */}
                        <ToggleButtonGroup size="small" value={views} onChange={handleViews} aria-label="text formatting">
                            <ToggleButton value="tasks" aria-label="tasks">
                                <Tooltip title={views.includes("tasks") ? "Verberg taken" : "Toon taken"} placement="bottom">
                                    <TaskAlt fontSize="small" />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value={"notes"} aria-label="notes">
                                <Tooltip title={views.includes("notes") ? "Verberg notities" : "Toon notities"} placement="bottom">
                                    <Notes fontSize="small" />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value="entities" aria-label="entities">
                                <Tooltip title={views.includes("entities") ? "Verberg widgets" : "Toon widgets"} placement="bottom">
                                    <HubOutlined fontSize="small" />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                    {/* Linked users for this project */}
                    <div>
                        <AvatarGroup
                            onClick={(e) => handleClickSub(e as any)}
                            max={4}
                            sx={{
                                cursor: "pointer",
                                "& .MuiAvatar-root": {
                                    border: "2px solid #ffffff",
                                    width: "28px",
                                    height: "28px",
                                    fontSize: "0.8rem",
                                },
                            }}
                        >
                            {project.users.map((user) => {
                                return <Avatar key={user.id}>{user.initials}</Avatar>;
                            })}
                        </AvatarGroup>
                        <AddUsersToProject
                            anchorEl={anchorEl}
                            handleCloseSub={handleCloseSub}
                            open={openPopper}
                            users={allUsers}
                            AlreadyChosenUsers={project.users}
                        />
                    </div>
                </Box>
            </Disable>
        </React.Fragment>
    );
};

export default ProjectTopNavBar;
