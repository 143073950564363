import React, { useState, useEffect } from "react";
import WidgetBox from "../../../components/WidgetBox";
import {
    Typography,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails,
    Grid,
    IconButton,
    Accordion as MuiAccordion,
    Divider,
    Tooltip,
} from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { GetCAOpportunitiesOfType, GetCAOpportunitiesOfTypeVariables } from "../../../__generated__/GetCAOpportunitiesOfType";
import { Euro, Event, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import {
    ExpandMore,
    // ExpandLess
} from "@mui/icons-material";
import moment from "moment";
import DocIconSwitch from "../../../components/DocIconSwitch";
import AccordionDetailOpp from "./AccordionDetailOpp";
import { deletePersonalOpportunity, deletePersonalOpportunityVariables } from "../../../__generated__/deletePersonalOpportunity";
// import WidgetBoxMenu from "../../../components/WidgetBoxMenu";
import queryString from "query-string";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { withStyles } from "tss-react/mui";
import { QUERY_OPPS_OF_CA_DOC_TYPE, QUERY_USER_OPPS_OF_PAGE } from "../../../graphql/queryDefinitions";
import { DELETE_PERSONAL_OPPORTUNITY } from "../../../graphql/mutationDefinitions";

interface Props {
    ca_id: string;
    file_type_id: string | undefined;
}
// accordion styling
const Accordion = withStyles(MuiAccordion, {
    root: {
        cursor: "default !important",
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
        "&:hover:not(.Mui-disabled)": {
            cursor: "default",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

// accordion summary change cursor to default
const AccordionSummary = withStyles(MuiAccordionSummary, {
    root: {
        cursor: "default !important",
        "&:hover:not(.Mui-disabled)": {
            cursor: "default",
        },
    },
});

const CreatedOppsSameCa: React.FC<Props> = ({ ca_id, file_type_id }) => {
    const [expanded, setExpanded] = useState<number | false>(false);
    // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [firstAmount, setFirstAmount] = useState<number>(10);
    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const navigate = useNavigate();

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // expand accordion based on lot ID
    const handleChange = (panel: number) => {
        if (panel === expanded) {
            setExpanded(false);
        } else {
            setExpanded(panel);
        }
    };

    /**
     * GQL query to get all opportunities of a certain type for a certain CA
     * @param ca_id CA ID
     * @param file_type_id Get all opportunities of this type of document instead of all documents
     */
    const [run, { loading, error, data }] = useLazyQuery<GetCAOpportunitiesOfType, GetCAOpportunitiesOfTypeVariables>(QUERY_OPPS_OF_CA_DOC_TYPE, {
        variables: {
            ca_id: parseInt(ca_id),
            // file_type_id: parseInt(file_type_id as string),
            first: firstAmount,
            page: 1,
        },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        run({
            variables: {
                ca_id: parseInt(ca_id),
                first: firstAmount,
                page: 1,
            },
        });
    }, [run, ca_id, firstAmount]);

    /**
     * mutation to delete opportunity
     */
    const [deletePersonalOpp, { error: errorMutation }] = useMutation<deletePersonalOpportunity, deletePersonalOpportunityVariables>(
        DELETE_PERSONAL_OPPORTUNITY
    );

    /**
     * handle submit of new opportunity
     */
    const deleteOpp = async (id: any) => {
        if (errorMutation) {
        }
        try {
            await deletePersonalOpp({
                variables: {
                    id: id,
                },
                refetchQueries: [
                    QUERY_USER_OPPS_OF_PAGE,
                    "GetopportunityPagePersonalOpportunities",
                    QUERY_OPPS_OF_CA_DOC_TYPE,
                    "GetCAOpportunitiesOfType",
                ],
            });
        } catch (e) {}
    };

    // add dots and comma's in numbers
    function currencyFormatDE(num: number) {
        return num
            .toFixed(2) // always two decimal digits
            .replace(".", ",") // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
    }

    // Renderpage is the div that render child components inside
    const renderPage = (content: React.ReactNode) => (
        <WidgetBox
            header="Toegevoegde kansen"
            highlight="green"
            padding={0}
            // actions={
            // TODO: show history, export opps, share button
            // <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
            //     <MenuItem>Toon historie</MenuItem>
            //     <MenuItem>Exporteer kansen</MenuItem>
            //     <MenuItem>Stuur door</MenuItem>
            // </WidgetBoxMenu>
            // }
        >
            {content}
        </WidgetBox>
    );

    /**
     * if loading, show spinner inside widget
     */
    if (loading) {
        return renderPage(<FullPageSpinner />);
    }

    /**
     * If no data is available, show this widgetbox with a message
     */
    if (error || !data || !data.contractingAuthorityOpportunities || data?.contractingAuthorityOpportunities?.paginatorInfo.total === 0) {
        return renderPage(
            <Typography style={{ height: 61, display: "flex", alignItems: "center", padding: "0 16px" }}>Er zijn geen kansen toegevoegd</Typography>
        );
    }

    /**
     * function to check if overflow is true or false
     * @param el element id
     * @returns boolean if div is overflowing or not
     */
    function check(el: HTMLElement | null) {
        if (el === null) {
            return false;
        }
        const curOverf = el.style.overflow;

        if (!curOverf || curOverf === "visible") el.style.overflow = "hidden";

        const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

        el.style.overflow = curOverf;

        return isOverflowing;
    }
    const loadAll = () => {
        setFirstAmount(
            data?.contractingAuthorityOpportunities?.paginatorInfo.total ? data?.contractingAuthorityOpportunities?.paginatorInfo.total : 10
        );
    };

    /**
     * @returns comment with newlines replaced with <br /> OR empty string if comment is null
     */
    const replaceWithBr = (text: string | null) => {
        if (text !== null) {
            return text.replace(/\n/g, "<br />");
        }
        return "";
    };

    /**
     * navigate to page with page as query
     */
    const handlePageClick = (id: string, page: number) => {
        trackevents({ category: "Opportunity", action: "Click-year-to-go-to-saved-opportunity", value: page, name: `to_opp_${id}` });
        navigate(
            `/opportunities/${id}?${queryString.stringify({
                page: page,
            })}`
        );
    };

    /**
     * If data is available render List component with all opportunities
     */
    return renderPage(
        <>
            {data?.contractingAuthorityOpportunities?.data
                .filter((s) => s.comment !== "")
                .filter((s) => s.comment !== null)
                .sort((a, b) => moment(b.created_at).diff(a.created_at))
                .map((o, i) => {
                    const moreValues = o.parsedValues !== null && o.parsedValues.length > 1;
                    const isOverflowing = check(document.getElementById(`opportunity-${i}`));
                    return (
                        <React.Fragment key={i}>
                            <Accordion expanded={(isOverflowing && expanded === i) || (moreValues && expanded === i)}>
                                <AccordionSummary
                                    expandIcon={
                                        moreValues ? (
                                            <IconButton size="small" onClick={() => handleChange(i)}>
                                                <ExpandMore />
                                            </IconButton>
                                        ) : isOverflowing ? (
                                            <IconButton size="small" onClick={() => handleChange(i)}>
                                                <ExpandMore />
                                            </IconButton>
                                        ) : (
                                            <div style={{ height: 30, width: 30, padding: 12 }} />
                                        )
                                    }
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Grid container style={{ cursor: "default !important" }}>
                                        <Grid zeroMinWidth item xs={12} md={8} style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <DocIconSwitch icon={"pdf"} />
                                                <div
                                                    style={{
                                                        marginLeft: "-8px",
                                                        padding: "10.5px 10px 10.5px 10px",
                                                        height: "37.63px",
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Tooltip
                                                        placement="top"
                                                        title={
                                                            <React.Fragment>
                                                                <span>{o.page?.file?.name}</span>
                                                                <br />
                                                                <span>Pagina {o.page?.page}</span>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Typography
                                                            onClick={() => handlePageClick(o.page?.file?.id as string, o.page?.page as number)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            {o?.page?.file?.year_from}
                                                        </Typography>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <Typography
                                                sx={{
                                                    maxHeight: "1.5em",
                                                    lineHeight: "1.5em",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: 1,
                                                    WebkitBoxOrient: "vertical",
                                                    width: "fit-content",
                                                }}
                                                id={`opportunity-${i}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: replaceWithBr(o.comment),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            {o?.parsedValues?.map((v, i) => {
                                                if (i === 0)
                                                    return (
                                                        <div
                                                            key={i}
                                                            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                                        >
                                                            {/*
                                                             * Div with calendar icon and saved year
                                                             */}
                                                            <div style={{ marginLeft: "8px", display: "flex", alignItems: "center" }}>
                                                                <Event
                                                                    sx={{
                                                                        marginRight: 0,
                                                                        color: "#707070",
                                                                        marginLeft: "8px",
                                                                    }}
                                                                />
                                                                <div
                                                                    style={{
                                                                        padding: "10.5px 10px 10.5px 10px",
                                                                        height: "37.63px",
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Typography>{v?.year}</Typography>
                                                                </div>
                                                            </div>
                                                            {/*
                                                             * Div with currency icon (euro) and value
                                                             */}
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Euro
                                                                    sx={{
                                                                        marginRight: 0,
                                                                        color: "#707070",
                                                                        marginLeft: "8px",
                                                                    }}
                                                                />
                                                                <div
                                                                    style={{
                                                                        padding: "10.5px 10px 10.5px 10px",
                                                                        height: "37.63px",
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Typography>
                                                                        {v !== null && v.value !== null && currencyFormatDE(v.value)}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                return null;
                                            })}
                                        </Grid>
                                    </Grid>

                                    {/*
                                     *   Grid with archive button
                                     */}
                                    <Grid item xs={12} md={1} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        <Tooltip title="Kans verwijderen">
                                            <IconButton
                                                onClick={(e) => {
                                                    // Prevent the tab iself being clicked,
                                                    // we want to execute the click on the close button
                                                    e.stopPropagation();
                                                    deleteOpp(o.id);
                                                }}
                                                size={"small"}
                                            >
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </AccordionSummary>
                                {/*
                                 *   collapse div
                                 */}

                                {isOverflowing ? (
                                    <AccordionDetails>
                                        <AccordionDetailOpp parsedValues={o.parsedValues} comment={replaceWithBr(o.comment)} />
                                    </AccordionDetails>
                                ) : moreValues ? (
                                    <AccordionDetails>
                                        <AccordionDetailOpp parsedValues={o.parsedValues} comment={replaceWithBr(o.comment)} />
                                    </AccordionDetails>
                                ) : null}
                            </Accordion>
                            <Divider />
                        </React.Fragment>
                    );
                })}
            {data.contractingAuthorityOpportunities.paginatorInfo.hasMorePages && (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography
                        sx={{
                            color: "#225E4D",
                            padding: "4px 8px",
                            cursor: "pointer",
                            margin: 4,
                            borderRadius: 4,
                            // hover
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                        }}
                        align="center"
                        onClick={() => loadAll()}
                    >
                        Laad alles
                    </Typography>
                </div>
            )}
        </>
    );
};

export default CreatedOppsSameCa;
