import React from "react";
import { useTheme } from "@mui/material";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import FullPageSpinner from "../loading/FullPageSpinner";
import { top5Cas } from "../../__generated__/top5Cas";
import FaviconBox from "../FaviconBox";
import { GetUserEmail } from "../../__generated__/GetUserEmail";
import { QUERY_EMAIL } from "../../graphql/queryDefCurrentUser";
import { QUERY_TOP_FIVE_CAS_SUGGESTIONS } from "../../graphql/queryDefCA";

interface Props {
    a?: number;
}

const CaContent: React.FC<Props> = (props) => {
    const { loading, data } = useQuery<top5Cas>(QUERY_TOP_FIVE_CAS_SUGGESTIONS);
    const theme = useTheme();
    const yellow = "#F57117";
    const { loading: sectorLoading, data: sectorData } = useQuery<GetUserEmail>(QUERY_EMAIL, {
        fetchPolicy: "cache-and-network",
    });
    // get amount of sectors in organization
    const sector = sectorData?.currentUser.employee.organization.subscriptions[0].sectors?.map((sector) => sector) || [];

    // show sentence based on amount of sectors
    const sectorLength = sector.length === 1 ? "sector" : "sectors";

    if (loading || sectorLoading) {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <FullPageSpinner />
            </div>
        );
    }

    return (
        <React.Fragment>
            <Typography variant="body1" sx={{ margin: (theme) => (theme.breakpoints.down("sm") ? "16px" : "48px") }}>
                {/* If loading is false => show sentence */}
                <Typography>Top vijf aanbestedende diensten in jouw {sectorLength}</Typography>

                <ul style={{ color: "#000000", listStyleType: "none", padding: 0 }}>
                    {data?.getTopContractingAuthorities?.map((item) => {
                        return (
                            <li key={item?.id} style={{ display: "flex", flexDirection: "row", margin: "2px 0px" }}>
                                <FaviconBox favicon={item?.favicon_resized ?? item?.favicon ?? null} name={item?.name} color={yellow} />

                                <Link to={`${item?.id}`} style={{ cursor: "pointer", color: theme.palette.primary.main }}>
                                    {item?.name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </Typography>
        </React.Fragment>
    );
};

export default CaContent;
