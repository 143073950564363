import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface CountdownItemProps {
    value: number;
    label: string;
    padding?: string;
}

const CountDownItem: React.FC<CountdownItemProps> = ({ value, label, padding = "32px" }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Grid item xs={3} textAlign="center">
            <div
                style={{
                    // display: "flex",
                    position: "relative",
                    /* Setting the size of the upper layer to 80% of the screen size*/
                    padding: padding,
                    /*the alpha value controls the transparency*/
                    background: `rgba( 255, 255, 255, 0.3 )`,
                    /* This controls the blurring effect*/
                    backdropFilter: `blur( 7.5px )`,
                    // -webkit-backdropilter: blur( 7.5px );

                    /*Adding the shadow*/
                    boxShadow: `0 8px 32px 0 rgba( 0, 0, 0, 0.18 )`,

                    /* Adding our borders*/
                    borderRadius: `10px`,
                    border: `1px solid rgba( 255, 255, 255, 0.18 )`,
                    zIndex: 4,
                }}
            >
                <Typography
                    sx={{
                        fontSize: noMobile ? "3rem !important" : "2rem !important", // Adjust font size as needed
                        fontWeight: 700, // Adjust font weight as needed
                        textAlign: "center", // Center align the text
                    }}
                >
                    {value}
                </Typography>
                <Typography
                    variant="h5"
                    fontWeight={500}
                    sx={{
                        textAlign: "center", // Center align the text
                    }}
                >
                    {label}
                </Typography>
            </div>
        </Grid>
    );
};

export default CountDownItem;
