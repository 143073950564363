import React from "react";
import { GetDefaultSummary_tender_omschrijving_source, GetDefaultSummary_tender_omvang_opdracht_source } from "../../__generated__/GetDefaultSummary";
import moment from "moment";
import { Grid, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OpenProps } from "../tenderDetail/Summary";
import dompurify from "dompurify";
import { useReRunHighlighting } from "../contextProviders/HighlightContext";

interface Props {
    tender_id: string;
    description: string;
    qualitativeScope: string;
    omvang_opdracht_source: GetDefaultSummary_tender_omvang_opdracht_source | null;
    omschrijving_source: GetDefaultSummary_tender_omschrijving_source | null;
    setOpenDoc: React.Dispatch<React.SetStateAction<OpenProps>>;
}

const SummaryExpandSingle: React.FC<Props> = ({
    tender_id,
    description,
    qualitativeScope,
    omvang_opdracht_source,
    omschrijving_source,
    setOpenDoc,
}) => {
    const { t } = useTranslation();
    const descriptionSanitized = dompurify.sanitize(description, { FORCE_BODY: true });
    const qualiSanitized = dompurify.sanitize(qualitativeScope, { FORCE_BODY: true });
    const { setRerunHighlight } = useReRunHighlighting();
    setRerunHighlight(true);

    return (
        <Grid
            container
            xs={12}
            spacing={2}
            //  className={classes.root}
        >
            {/*
             *****
             * SUMMARY
             *****
             */}
            <Grid item xs={12}>
                {description !== "" && <Typography variant="h4">{t("tenderPage.Description")}</Typography>}
                {description !== "" && (
                    <>
                        <Typography
                            id={tender_id}
                            variant="body1"
                            dangerouslySetInnerHTML={{ __html: descriptionSanitized }}
                            style={{ margin: "12px 0px 2px", whiteSpace: "pre-wrap" }}
                        />
                        <React.Fragment>
                            <Typography style={{ fontStyle: "italic" }} variant="caption">
                                {t("Source")}:{" "}
                            </Typography>
                            {omschrijving_source ? (
                                <>
                                    {omschrijving_source?.__typename === "Document" ? (
                                        <>
                                            {omschrijving_source.extension === "pdf" ? (
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => {
                                                        setOpenDoc({
                                                            link: omschrijving_source.temporary_link,
                                                            open: true,
                                                            label: omschrijving_source.filename,
                                                            doc_id: omschrijving_source.id,
                                                        });
                                                    }}
                                                >
                                                    {omschrijving_source.filename}
                                                </Link>
                                            ) : (
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    style={{ fontStyle: "italic" }}
                                                    variant="caption"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const element = document.createElement("a");
                                                        element.setAttribute(
                                                            "href",
                                                            `${omschrijving_source.temporary_link}&response-content-disposition=attachment; filename=${omschrijving_source.filename}`
                                                        );

                                                        element.style.display = "none";
                                                        document.body.appendChild(element);
                                                        element.click();
                                                        document.body.removeChild(element);
                                                    }}
                                                >
                                                    {omschrijving_source.filename}
                                                </Link>
                                            )}
                                        </>
                                    ) : omschrijving_source?.__typename === "Publication" ? (
                                        <Typography style={{ fontStyle: "italic" }} variant="caption">
                                            {moment(omschrijving_source?.dispatchdate).format("LL")} - {omschrijving_source?.host},{" "}
                                            {omschrijving_source?.publication_type.name}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                <>
                                    {/* // Show "-" if no source is available */}
                                    <Typography style={{ fontStyle: "italic" }} variant="caption">
                                        -
                                    </Typography>
                                </>
                            )}
                        </React.Fragment>
                    </>
                )}
            </Grid>

            {/*
             *****
             * QUALITATIVE SCOPE
             *****
             */}

            {
                <Grid item xs={12}>
                    {qualitativeScope !== "" && <Typography variant="h4">{t("tenderPage.Scope")}</Typography>}
                    {qualitativeScope !== "" && (
                        <>
                            <Typography
                                id={tender_id}
                                variant="body1"
                                dangerouslySetInnerHTML={{ __html: qualiSanitized }}
                                style={{ margin: "12px 0px 2px", whiteSpace: "pre-wrap" }}
                            />
                            <Typography style={{ fontStyle: "italic" }} variant="caption">
                                {t("Source")}:{" "}
                            </Typography>
                            <React.Fragment>
                                {omvang_opdracht_source ? (
                                    <>
                                        {omvang_opdracht_source?.__typename === "Document" ? (
                                            <>
                                                {omvang_opdracht_source.extension === "pdf" ? (
                                                    <Link
                                                        underline="none"
                                                        component="button"
                                                        style={{ fontStyle: "italic" }}
                                                        variant="caption"
                                                        onClick={() => {
                                                            setOpenDoc({
                                                                link: omvang_opdracht_source.temporary_link,
                                                                open: true,
                                                                label: omvang_opdracht_source.filename,
                                                                doc_id: omvang_opdracht_source.id,
                                                            });
                                                        }}
                                                    >
                                                        {omvang_opdracht_source.filename}
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        underline="none"
                                                        component="button"
                                                        style={{ fontStyle: "italic" }}
                                                        variant="caption"
                                                        onClick={(e) => {
                                                            // Stop click on listitem to prevent document-viewer to initiate
                                                            e.stopPropagation();
                                                            const element = document.createElement("a");

                                                            element.setAttribute(
                                                                "href",
                                                                `${omvang_opdracht_source.temporary_link}&response-content-disposition=attachment; filename=${omvang_opdracht_source.filename}`
                                                            );
                                                            element.style.display = "none";
                                                            document.body.appendChild(element);
                                                            element.click();
                                                            document.body.removeChild(element);
                                                        }}
                                                    >
                                                        {omvang_opdracht_source.filename}
                                                    </Link>
                                                )}
                                            </>
                                        ) : omvang_opdracht_source.__typename === "Publication" ? (
                                            <Typography style={{ fontStyle: "italic" }} variant="caption">
                                                {moment(omvang_opdracht_source?.dispatchdate).format("LL")} - {omvang_opdracht_source?.host},{" "}
                                                {omvang_opdracht_source?.publication_type.name}
                                            </Typography>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    // Show "-" if no source is available
                                    <Typography style={{ fontStyle: "italic" }} variant="caption">
                                        -
                                    </Typography>
                                )}
                            </React.Fragment>
                        </>
                    )}
                </Grid>
            }
        </Grid>
    );
};

export default SummaryExpandSingle;
