import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    page: "all" | "worklist" | "review";
    oppName: string;
    oppLink: string;
    caName: string;
    caLink: string;
}

const AllOppToDetail: React.FC<Props> = ({ oppName, oppLink, caLink, caName, page }) => {
    const { trackEvent } = useMatomo();
    // Track event
    const clickedOpp = (event: MatomoEvent) => {
        trackEvent(event);
    };
    return (
        <div>
            {/* link to tenderdetailpage, TODO: get tenderid to link to */}
            <Typography>
                <Link
                    id="opp-title"
                    // to={`/opportunities/${tableMeta.rowData[0]}/?query=${tableMeta.rowData[0]}`}
                    to={oppLink}
                    onClick={(e) => {
                        // Prevent the tab iself being clicked,
                        clickedOpp({ category: "Opportunity", action: `Click-from-opportunity-${page}-to-opportunity-detail` });
                        e.stopPropagation();
                    }}
                >
                    {oppName}
                </Link>
            </Typography>

            {/* link to Contracting authority, TODO: get Contracting Authorithy id to link to */}
            <Typography>
                <Link
                    id="oppAD"
                    to={caLink}
                    style={{ color: "#707070" }}
                    onClick={() => clickedOpp({ category: "Opportunity", action: `Click-from-opportunity-${page}-to-ca-detail` })}
                >
                    {caName}
                </Link>
            </Typography>
        </div>
    );
};

export default AllOppToDetail;
