import React from "react";
import TenderTimelineItem from "./TenderTimelineItem";
import UserTimelineItem from "./UserTimelineItem";
import OrganizationTimelineItem from "./OrganizationTimelineItem";
import SearchTimelineItem from "./SearchTimeLineItem";
import UserTaskLog from "./usertask/UserTaskLog";
import UserProjectLog from "./usertask/UserProjectLog";
import CommentLog from "./usertask/CommentLog";
import CommentReplyLog from "./usertask/CommentReply";
import LogDescriptionDeleted from "./LogDescriptionDeleted";
import { GetUserLogsWithFilters_activities_data } from "../../../__generated__/GetUserLogsWithFilters";
import CATimelineItem from "./CATimelineItem";
import ProfileTimelineItem from "./ProfileTimelineItem";

interface Props {
    item: any;
    log: GetUserLogsWithFilters_activities_data;
    onClickOpen(): void;
    onClickEmail(open: boolean, link: string): void;
}
/**
 * switch to show the correct timeline item
 */
const LogTimeLine: React.FC<Props> = ({ item, log, onClickOpen, onClickEmail }) => {
    return (
        <React.Fragment>
            {(() => {
                switch (true) {
                    case Boolean(log.channel === null && log.module === null && log.subject === null):
                        return <></>;

                    case Boolean(log.channel === null && log.module === null && log.action === null && log.subject === null):
                        return null;

                    case Boolean(log.channel === null && log.module === null && log.subject?.__typename === "Employee"):
                        return <></>;

                    case Boolean(log.channel === null && log.module === null && log.subject?.__typename === "User"):
                        return <></>;

                    case log.module === "profile":
                        return <ProfileTimelineItem log={log} item={item} />;

                    case log.module === "tenders":
                        return <TenderTimelineItem log={log} item={item} />;

                    case log.subject?.__typename === "User":
                        return <UserTimelineItem log={log} item={item} />;

                    case log.subject?.__typename === "Employee":
                        return <UserTimelineItem log={log} item={item} />;

                    case log.module === "contracting-authorities":
                        return <CATimelineItem log={log} item={item} />;

                    case log.module === "organizations":
                        return <OrganizationTimelineItem log={log} item={item} />;

                    case log.subject?.__typename === "Search":
                        return <SearchTimelineItem log={log} item={item} />;

                    case log.className === "App\\Models\\Comment":
                        return <CommentLog log={log} item={item} />;

                    case log.className === "App\\Models\\CommentReply":
                        return <CommentReplyLog log={log} item={item} />;

                    case log.className === `App\\Models\\UserTask`:
                        return <UserTaskLog log={log} item={item} />;

                    case log.subject?.__typename === "ProjectState":
                        return <></>;

                    case log.module === "projects":
                        return <UserProjectLog log={log} item={item} />;

                    case log.description === "deleted":
                        return <LogDescriptionDeleted item={item} log={log} />;

                    default:
                        return <></>;
                }
            })()}
        </React.Fragment>
    );
};

export default LogTimeLine;
