import React from "react";

import { Box, Paper, Stack, Typography } from "@mui/material";

interface Props {
    amount: number;
}

const ColumnSelector: React.FC<Props> = ({ amount }) => {
    return (
        <div>
            <Paper sx={{ width: "100px", height: "75px", display: "flex" }}>
                <Box
                    sx={{
                        border: "1px solid grey",
                        borderRadius: "inherit",
                        display: "flex",
                        flex: "1 1 auto",
                        flexDirection: "column",
                    }}
                >
                    <Stack
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            alignItems: "center",
                            borderBottom: "1px dashed grey",
                            padding: "4px 8px",
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "darkgrey",
                                borderRadius: "2px",
                                height: "4px",
                                width: "16px",
                            }}
                        />
                    </Stack>
                    <Stack
                        direction={"row"}
                        sx={{
                            display: "flex",
                            flex: "1 1 auto",
                        }}
                    >
                        <Box sx={{ marginTop: "2px", padding: "2px", borderRight: "1px dashed grey" }}>
                            <Stack direction={"column"} spacing={0.2}>
                                {Array.from({ length: 3 }, (_, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            borderRadius: "2px",
                                            height: "4px",
                                            width: "4px",
                                            background: "purple",
                                        }}
                                    />
                                ))}
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flex: "1 1 auto",
                                padding: "2px",
                            }}
                        >
                            <Box
                                sx={{
                                    // backgroundColor: "darkgrey",
                                    // border: " 1px dashed grey",
                                    borderRadius: "2px",
                                    flex: "1 1 auto",
                                    padding: "2px",
                                }}
                            >
                                <Stack
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "8px",
                                        margin: "2px",
                                        alignItems: "center",
                                        border: "1px dashed grey",
                                        padding: "4px 8px",
                                        borderRadius: "2px",
                                    }}
                                >
                                    {Array.from({ length: amount }, (_, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                backgroundColor: "darkgrey",
                                                borderRadius: "2px",
                                                height: "4px",
                                                width: "100%",
                                            }}
                                        />
                                    ))}
                                </Stack>
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </Paper>
            <Typography textAlign={"center"}>
                {amount} {amount === 1 ? "kolom" : "kolommen"}
            </Typography>
        </div>
    );
};

export default ColumnSelector;
