import React from "react";
import { Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import StaticEventCard from "./program/StaticEventCard";
import PresentationCard from "./program/PresentationCard";
import { ProgramData } from "./TendenzModule";

interface Props {
    programData: ProgramData[];
}

const ProgramSection: React.FC<Props> = ({ programData }) => {
    // const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    // const handleExpandClick = (index: number) => {
    //     setExpandedIndex(expandedIndex === index ? null : index);
    // };

    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Box py={4} px={4} sx={{ display: "flex", flexDirection: "column" }}>
            <div id="program" />
            <Typography variant="h1" fontWeight={500} gutterBottom align="center" sx={{ margin: 5, fontSize: "3rem !important" }}>
                Programma
            </Typography>

            {noMobile && <div style={{ height: "100px" }} />}

            <Grid container sx={{ alignItems: "center", flexDirection: noMobile ? "row" : "column", height: "60%" }} spacing={2}>
                {programData.map((item, index) => {
                    // hide Inloop
                    if (item.title === "Inloop" && noMobile) {
                        return null;
                    }
                    // show aanvang & inloop in one section
                    if (item.title === "Aanvang" && noMobile) {
                        const aanvang = programData.find((item) => item.title === "Aanvang");
                        const inloop = programData.find((item) => item.title === "Inloop");

                        return (
                            <Grid item flex={1} key={item.title} sx={{ display: "flex", flexDirection: "column", height: "52vh" }} xs={12} md={1}>
                                <StaticEventCard
                                    eventName={inloop?.title as string}
                                    eventTime={inloop?.time as string}
                                    image={"https://cdn.pixabay.com/photo/2022/03/31/14/10/aroace-7103105_1280.png"}
                                    reverseImg={true}
                                />
                                <div style={{ height: "16px" }} />
                                <StaticEventCard
                                    eventName={aanvang?.title as string}
                                    eventTime={aanvang?.time as string}
                                    image={"https://cdn.pixabay.com/photo/2022/03/31/14/10/aroace-7103105_1280.png"}
                                    reverseImg={false}
                                />
                            </Grid>
                        );
                    }

                    // Return all other objects
                    return (
                        <Grid item flex={1} key={index} sx={{ display: "flex", height: "60%", width: "100%" }} xs={12} md={item.size}>
                            <PresentationCard
                                eventText={item.text ?? ""}
                                bgColor={item.bg}
                                image={item.img}
                                eventTitle={item.title}
                                eventSubTitle={item.subTitle ?? ""}
                                eventTime={item.time}
                                eventSmallText={item.function ?? ""}
                                smaller={item.smaller ?? false}
                                programTextSneakPreview={item.programSneak ?? ""}
                            />
                        </Grid>
                    );
                })}
            </Grid>

            {noMobile && <div style={{ height: "100px" }} />}
        </Box>
    );
};

export default ProgramSection;
