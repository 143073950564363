import { createContext, useContext } from "react";

export type FaqContextType = {
    openFaq: boolean;
    setOpenFaq: (openFaq: boolean) => void;
    openChat: boolean;
    setOpenChat: (openChat: boolean) => void;
    view: string;
    setView: (view: string) => void;
    xAxis: number;
    setX: (axis: number) => void;
    yAxis: number;
    setY: (axis: number) => void;
};

export const FaqContext = createContext<FaqContextType>({
    openFaq: false,
    setOpenFaq: (openFaq) => console.warn("no openFaq"),
    openChat: false,
    setOpenChat: (openFaq) => console.warn("no openChat"),
    view: "start",
    setView: (view) => console.warn("no openChat"),
    xAxis: 0,
    setX: (axis) => console.warn("no openChat"),
    yAxis: 0,
    setY: (axis) => console.warn("no openChat"),
});
export const useFaq = () => useContext(FaqContext);
