import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Checkbox,
    Divider,
    InputAdornment,
    ListItemButton,
    ListItemText,
    Popover,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { PersonAddAltRounded, Search } from "@mui/icons-material";
import truncate from "truncate";
import { useMutation } from "@apollo/client";
import { Priority, ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import { User } from "../Avatars";
import { createTask, createTaskVariables } from "../../../../__generated__/createTask";
import { CREATE_NEW_TASK } from "../../../../graphql/mutationDefProjects";
import moment from "moment";
import { SINGLE_PROJECT, SINGLE_PROJECT_TASKS } from "../../../../graphql/queryDefProjects";
import TaskDatePicker from "./TaskDatePicker";
import TaskPriorityChip from "./TaskPriorityChip";
import MatomoEvent from "../../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    users: User[];
    showAddNewTask: (bln: boolean) => void;
}

const AddUsersToProject: React.FC<Props> = ({ users, showAddNewTask }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const { project } = useContext(ProjectToOpenContext);
    const [newTask, setTaskValue] = useState<string>("");
    const [openPopper, setOpenPopper] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [arrayWithUsersOrTeamsToShare, setArrayWithUsersOrTeamsToShare] = useState<User[]>([]);
    const [prio, setPrio] = useState<Priority>(Priority.MEDIUM);
    const [deadline, setDeadline] = useState<moment.Moment | null>(null);

    const { trackEvent } = useMatomo();
    // track navigation items
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };

    useEffect(() => {
        if (users.length === 1) {
            setArrayWithUsersOrTeamsToShare(users);
        }
    }, [users]);

    // Create a new task
    const [createNewTask] = useMutation<createTask, createTaskVariables>(CREATE_NEW_TASK);
    /**
     * Add new task to array
     */
    const saveTask = () => {
        trackClick({ category: "Project", action: "Create-task" });

        arrayWithUsersOrTeamsToShare.forEach(async (user: User) => {
            createNewTask({
                variables: {
                    project_id: project.projectID,
                    description: newTask,
                    deadline: deadline === null ? null : moment(deadline).format("YYYY-MM-DD"),
                    completed: false,
                    priority: prio,
                    user_ids: [user.id],
                },
                refetchQueries: [SINGLE_PROJECT_TASKS, SINGLE_PROJECT],
                onCompleted: () => {
                    // refetch();
                },
            });
        });

        setTaskValue("");
        setPrio(Priority.MEDIUM);
        setDeadline(null);
        users.length > 1 && setArrayWithUsersOrTeamsToShare([]);
    };

    const cancelTask = () => {
        setTaskValue("");
        showAddNewTask(false);
    };

    /**
     * @param value clicked user/team
     * @returns return arrayWithUsersOrTeamsToShare or unchecked state of listitem
     */
    const handleToggle = (e: any, value: User) => {
        e.stopPropagation();

        const checkedids = arrayWithUsersOrTeamsToShare.map((i) => i.id);
        const currentIndex = checkedids.indexOf(value.id);
        const newChecked = [...arrayWithUsersOrTeamsToShare];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setArrayWithUsersOrTeamsToShare(newChecked);
    };

    /**
     * handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */
    const handleSearchUser = (event: any) => {
        event.stopPropagation();
        const {
            target: { value },
        } = event;

        setSearchTerm(value);
    };

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenPopper(true);
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenPopper(false);
    };

    const results = !searchTerm ? users : users.filter((item) => item.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    return (
        <div style={{ display: "grid", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "16px" }}>
                {/* Todo text */}
                <TextField
                    fullWidth
                    size="small"
                    id="new-task"
                    placeholder="Wat moet er gedaan worden?"
                    variant="outlined"
                    value={newTask}
                    onChange={(event) => setTaskValue(event.target.value)}
                />

                {/* deadline */}
                <TaskDatePicker
                    date={deadline}
                    updateDate={(date) => {
                        setDeadline(date as moment.Moment);
                    }}
                />

                {/* priority */}
                <TaskPriorityChip
                    dot={false}
                    disabled={false}
                    priority={prio as any}
                    updatePrio={(prio) => {
                        setPrio(prio as Priority);
                    }}
                />

                {/* users */}
                <Tooltip title="Gebruikers" placement="left" disableInteractive>
                    {users.length === 1 ? (
                        <AvatarGroup
                            onClick={(e) => handleClickSub(e as any)}
                            max={4}
                            sx={{
                                cursor: "pointer",
                                "& .MuiAvatar-root": {
                                    border: "1px solid #ffffff",
                                    width: (theme) => theme.spacing(3.5),
                                    height: (theme) => theme.spacing(3.5),
                                    fontSize: "0.8rem",
                                },
                            }}
                        >
                            {arrayWithUsersOrTeamsToShare.map((user) => {
                                return <Avatar key={user.id}>{user.initials}</Avatar>;
                            })}
                        </AvatarGroup>
                    ) : (
                        <AvatarGroup
                            onClick={(e) => handleClickSub(e as any)}
                            max={4}
                            sx={{
                                cursor: "pointer",
                                "& .MuiAvatar-root": {
                                    border: "1px solid #ffffff",
                                    width: (theme) => theme.spacing(3.5),
                                    height: (theme) => theme.spacing(3.5),
                                    fontSize: "0.8rem",
                                },
                            }}
                        >
                            {arrayWithUsersOrTeamsToShare.length === 0 ? (
                                <Avatar
                                    sx={{
                                        width: "28px",
                                        height: "28px",
                                        fontSize: "0.65rem",
                                    }}
                                >
                                    <PersonAddAltRounded sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(3) }} />
                                </Avatar>
                            ) : (
                                arrayWithUsersOrTeamsToShare.map((user) => {
                                    return <Avatar key={user.id}>{user.initials}</Avatar>;
                                })
                            )}
                        </AvatarGroup>
                    )}
                </Tooltip>
            </div>
            <div style={{ justifySelf: "flex-end" }}>
                <Button size="small" onClick={cancelTask}>
                    Annuleren
                </Button>
                <Button size="small" variant="contained" onClick={saveTask} disabled={newTask === "" || arrayWithUsersOrTeamsToShare.length === 0}>
                    Aanmaken
                </Button>
            </div>

            {/*
             *   Popper with users of current project
             */}
            <Popover
                anchorEl={anchorEl}
                open={openPopper}
                onClose={handleCloseSub}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                /**
                 * Width and height for chat/knowledge base
                 */
                slotProps={{
                    paper: {
                        sx: {
                            width: "300px",
                        },
                    },
                }}
                // PaperProps={{
                //     style: { width: "300px" },
                // }}
            >
                <div>
                    {/*
                     * Searchbox
                     */}
                    <Box style={{ width: "100%", display: "flex" }}>
                        <TextField
                            onClick={(e) => e.stopPropagation()}
                            sx={{ margin: "10px", flexGrow: 1 }}
                            placeholder="Zoek een gebruiker..."
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                                e.stopPropagation();
                                handleSearchUser(e);
                            }}
                            value={searchTerm}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ maxHeight: "310px", overflow: "auto" }}>
                        {results.map((user) => {
                            /**
                             * UserListItem
                             */
                            return (
                                <React.Fragment key={user.id}>
                                    <ListItemButton
                                        onClick={(e) => handleToggle(e, user)}
                                        dense
                                        selected={Boolean(arrayWithUsersOrTeamsToShare.find((usr) => usr.id === user.id))}
                                        disableRipple
                                    >
                                        {/* Listitem with userdetails. */}
                                        <ListItemText
                                            /**
                                             * Combination of user givenname and familyname. line through when user is inactive
                                             */
                                            primary={
                                                <>
                                                    <Typography>{truncate(user.name ? user.name : "", 40)}</Typography>
                                                </>
                                            }
                                        />
                                        {/* Checkbox */}
                                        <Checkbox
                                            size="small"
                                            edge="start"
                                            checked={Boolean(arrayWithUsersOrTeamsToShare.find((usr) => usr.id === user.id))}
                                            tabIndex={-1}
                                            disableRipple
                                            color="primary"
                                        />
                                    </ListItemButton>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </Box>
                </div>
            </Popover>
        </div>
    );
};

export default AddUsersToProject;
