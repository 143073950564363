import React, { useState, useEffect } from "react";

// Import Material UI
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { MUIDataTableState } from "mui-datatables";
import CaTenderTable from "./CaTenderTable";
import TenderSearchField from "../../../../components/input/TenderSearchField";
import { SortOrder, TenderFilterTarget, TenderFilterInput, TenderSearchInput, TenderFilterSource } from "../../../../__generated__/globalTypes";
import { useLazyQuery } from "@apollo/client";
import SplitView from "../../../tenders/splitview/SplitView";
import {
    FilterWorklistSplitView,
    FilterWorklistSplitView_filterTenders_results_data,
    FilterWorklistSplitViewVariables,
} from "../../../../__generated__/FilterWorklistSplitView";
import { FilterWorklistOverview_filterTenders_results_paginatorInfo } from "../../../../__generated__/FilterWorklistOverview";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import CATenderFilterBar from "./CATenderFilterBar";
import { Disable } from "react-disable";
import {
    FilterTendersOverviewOnCaPageMinimum,
    FilterTendersOverviewOnCaPageMinimumVariables,
    FilterTendersOverviewOnCaPageMinimum_filterTenders_filters,
    FilterTendersOverviewOnCaPageMinimum_filterTenders_results,
} from "../../../../__generated__/FilterTendersOverviewOnCaPageMinimum";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { QUERY_TENDERS_OF_CA_MINIMUM, QUERY_WORKLIST_SPLIT } from "../../../../graphql/queryDefinitions";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface Props {
    ca_id: string;
    ca_name: string | null;
    ca_favicon: string | null;
    ca_favicon_resized: string | null;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`${index}`}>
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

const filters: TenderFilterInput = {};
const defaultSearch: TenderSearchInput = {
    source: TenderFilterSource.ALL_TENDERS,
    target: TenderFilterTarget.SUMMARY,
    filters,
};
const defaultVariables: FilterTendersOverviewOnCaPageMinimumVariables = {
    page: 1,
    count: 10,
    orderField: "updated_at",
    order: SortOrder.DESC,
    search: defaultSearch,
};

const CATenderTabs: React.FC<Props> = ({ ca_id, ca_name, ca_favicon, ca_favicon_resized }) => {
    const { trackEvent } = useMatomo();
    const [value, setValue] = useState(0);
    const { t } = useTranslation();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Track event ca tenders
    const trackCaTenderTabs = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * setvalue to clicked value, value is number of tab that is clicked
     */
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const [page, setPage] = useState(defaultVariables.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.count);
    const [orderField, setOrderField] = useState<string>(defaultVariables.orderField);
    const [orderDir, setOrderDir] = useState<SortOrder>(defaultVariables.order);
    const [searchInput, setSearchInput] = useState<TenderFilterInput>({ contractingAuthorities: [parseInt(ca_id)] });
    const [
        source,
        // setSource
    ] = useState<TenderFilterSource>(defaultSearch.source);
    const [query, setQuery] = useState<string>("");
    const [target, setTarget] = useState<TenderFilterTarget>(defaultSearch.target);

    // Tenderdata
    const [filterData, setFilterData] = useState<FilterTendersOverviewOnCaPageMinimum_filterTenders_filters[] | null>([]);

    // tenderfilters
    const [tendersData, setTendersData] = useState<FilterTendersOverviewOnCaPageMinimum_filterTenders_results | null>();
    // array of tenders based on active filter(s) -> splitview
    const [tendersDataSplit, setTendersDataSplit] = useState<FilterWorklistSplitView_filterTenders_results_data[] | null>();
    // paginator info
    const [filteredPagination, setFilteredPagination] = useState<FilterWorklistOverview_filterTenders_results_paginatorInfo>();
    const [selectedId, setSelectedId] = useState<string>("");

    // Variables for the GQL query
    const variables: FilterTendersOverviewOnCaPageMinimumVariables = {
        page: page,
        count: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            source: source,
            target: target,
            query: query,
            filters: searchInput,
        },
    };

    useEffect(() => {
        // listview settings
        if (value === 0) {
            setPage(1);
            setRowsPerPage(10);
        }
        // splitview settings
        if (value === 1) {
            setPage(1);
            setRowsPerPage(20);
        }
    }, [value]);

    /**
     * Define the GQL query
     */
    const [run, { loading, data: dataList }] = useLazyQuery<FilterTendersOverviewOnCaPageMinimum, FilterTendersOverviewOnCaPageMinimumVariables>(
        QUERY_TENDERS_OF_CA_MINIMUM,
        {
            variables: variables,
            fetchPolicy: "network-only",
        }
    );

    // Define the GQL query splitlist
    const [runSplit, { error: errorSplit, loading: loadingSplit, data: dataSplit }] = useLazyQuery<
        FilterWorklistSplitView,
        FilterWorklistSplitViewVariables
    >(QUERY_WORKLIST_SPLIT, {
        variables: variables,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (value === 0) {
            if (dataList && dataList.filterTenders) {
                setFilterData(dataList.filterTenders.filters);
                setTendersData(dataList.filterTenders.results);
            }
        }
        if (value === 1) {
            if (dataSplit && dataSplit.filterTenders !== null && dataSplit.filterTenders.results !== null) {
                setFilterData(dataSplit.filterTenders.filters);
                setTendersDataSplit(dataSplit.filterTenders.results.data);
                setFilteredPagination(dataSplit.filterTenders.results.paginatorInfo);
                if (selectedId === "" && dataSplit.filterTenders.results.data.length > 0) {
                    setSelectedId(dataSplit.filterTenders.results.data[0].id);
                }
            }
        }
    }, [dataList, dataSplit, selectedId, value]);

    /**
     * Run lazyquery based on view
     */
    useEffect(() => {
        // listview
        if (value === 0) {
            run({
                variables: {
                    page: page,
                    count: rowsPerPage,
                    orderField: orderField,
                    order: orderDir,
                    search: {
                        source: source,
                        target: target,
                        query: query,
                        filters: searchInput,
                    },
                },
            });
        }
        // splitview
        if (value === 1) {
            runSplit({
                variables: {
                    page: page,
                    count: rowsPerPage,
                    orderField: orderField,
                    order: orderDir,
                    search: {
                        source: source,
                        target: target,
                        query: query,
                        filters: searchInput,
                    },
                },
            });
        }
    }, [run, runSplit, orderDir, orderField, page, rowsPerPage, searchInput, query, target, source, t, value]);

    /**
     * Handle pagination change: -page change, -rows per page change
     */
    const handlePaginationChange = (page: number, rowsPerPage: number) => {
        setPage(page);
        setRowsPerPage(rowsPerPage);
    };

    /**
     * Handle table change:
     *  -sort column,
     *  -sort direction
     */
    const handleTableChange = (_: string, tableState: MUIDataTableState) => {
        if (tableState.sortOrder.direction) {
            setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
        }
        if (tableState.sortOrder.name) {
            setOrderField(tableState.sortOrder.name);
        }
    };

    const hasKeys = Object.keys(searchInput);
    const showResetBtn = hasKeys.length === 1 && hasKeys.includes("contractingAuthorities");

    console.log("🚀 query", query);

    return (
        <div>
            <AppBar position="static" style={{ background: "white", color: "black", boxShadow: "none" }}>
                <Tabs
                    sx={{
                        "& .MuiTab-root.Mui-selected": {
                            color: "#173357",
                        },
                    }}
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ sx: { background: "#173357" } }}
                >
                    {/* {data.filterTenders.results.data.length > 0 && <Tab label="Overzicht" />} */}
                    <Tab
                        sx={{ width: "160px" }}
                        label={t("List")}
                        onClick={() => trackCaTenderTabs({ category: "Ca", action: "Click-ca-tender-listview" })}
                    />
                    {!onlyMobile && (
                        <Tab
                            sx={{ width: "160px" }}
                            label={t("Split")}
                            onClick={() => trackCaTenderTabs({ category: "Ca", action: "Click-ca-tender-splitview" })}
                        />
                    )}
                    {/* <Tab label="Kaart" /> */}
                </Tabs>
            </AppBar>

            {/*
             * Overview
             */}
            {/* <TabPanel value={value} index={0}>
                {data.filterTenders.results.data.length > 0 && (
                    <CATenderOverview tenders={data.filterTenders.results.data} favicon={ca_favicon} name={ca_name} />
                )}
            </TabPanel> */}

            {/*
             * ListView
             */}
            <TabPanel value={value} index={0}>
                <Disable disabled={loading}>
                    <div style={{ margin: 8, marginTop: 0 }}>
                        <TenderSearchField
                            query={query}
                            target={target}
                            onChangeQuery={(q) => {
                                setQuery(q);
                                setPage(1);
                            }}
                            onChangeTarget={(target) => {
                                setTarget(target);
                                setPage(1);
                            }}
                            onSearch={() => {
                                run({
                                    variables,
                                });
                            }}
                            visible={false}
                        />
                    </div>

                    <div style={{ margin: 8, marginTop: 16, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {/* Filterbar */}
                        <CATenderFilterBar
                            filterData={filterData ? filterData : []}
                            resetPage={() => setPage(1)}
                            onChange={setSearchInput}
                            startSearch={() => {
                                setPage(1);
                                run({ variables });
                            }}
                            searchInput={searchInput}
                        />
                        {!showResetBtn && (
                            <Button
                                startIcon={<Close />}
                                size="small"
                                onClick={() => {
                                    setSearchInput({ contractingAuthorities: [parseInt(ca_id)] });
                                    setPage(1);

                                    // setCaSearchQuery("");
                                    // setResetCustomFilter(true);
                                }}
                                style={{ color: "grey", marginBottom: 8 }}
                            >
                                {t("filter.reset")}
                            </Button>
                        )}
                    </div>

                    <CaTenderTable
                        ca_id={ca_id}
                        ca_name={ca_name}
                        ca_favicon={ca_favicon_resized ?? ca_favicon}
                        loading={loading}
                        rows={tendersData?.data || []}
                        paginatorInfo={
                            tendersData?.paginatorInfo
                                ? tendersData?.paginatorInfo
                                : {
                                      count: 0,
                                      currentPage: 0,
                                      hasMorePages: false,
                                      firstItem: null,
                                      lastItem: null,
                                      lastPage: 0,
                                      perPage: 10,
                                      total: 0,
                                      __typename: "PaginatorInfo",
                                  }
                        }
                        onChangePagination={handlePaginationChange}
                        onTableChange={handleTableChange}
                    />
                </Disable>
            </TabPanel>

            {/* Splitview */}
            <TabPanel value={value} index={1}>
                <Disable disabled={loadingSplit}>
                    <div style={{ margin: 8, marginTop: 0 }}>
                        <TenderSearchField
                            query={query}
                            target={target}
                            onChangeQuery={(q) => {
                                setQuery(q);
                                setPage(1);
                                setSelectedId("");
                            }}
                            onChangeTarget={(target) => {
                                setTarget(target);
                                setPage(1);
                                setSelectedId("");
                            }}
                            onSearch={() => {
                                runSplit({ variables });
                                setSelectedId("");
                            }}
                            visible={false}
                        />
                    </div>

                    <div style={{ margin: 8, marginTop: 16, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {/* Filterbar */}
                        <CATenderFilterBar
                            filterData={filterData ? filterData : []}
                            resetPage={() => setPage(1)}
                            onChange={setSearchInput}
                            startSearch={() => {
                                setPage(1);
                                setSelectedId("");
                                runSplit({ variables });
                            }}
                            searchInput={searchInput}
                        />
                        {/* Show resetbutton is showResetBtn is false */}
                        {!showResetBtn && (
                            <Button
                                startIcon={<Close />}
                                size="small"
                                onClick={() => {
                                    setSearchInput({ contractingAuthorities: [parseInt(ca_id)] });
                                    setPage(1);

                                    // setCaSearchQuery("");
                                    // setResetCustomFilter(true);
                                }}
                                style={{ color: "grey", marginBottom: 8 }}
                            >
                                {t("filter.reset")}
                            </Button>
                        )}
                    </div>
                </Disable>
                <div style={{ padding: 8, marginTop: 0 }}>
                    <SplitView
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        ca={{
                            __typename: "ContractingAuthority",
                            id: ca_id,
                            name: ca_name,
                            favicon: ca_favicon,
                            favicon_resized: ca_favicon_resized,
                        }}
                        elevation={1}
                        page={rowsPerPage as number}
                        setPage={setRowsPerPage}
                        rows={tendersDataSplit}
                        loading={loadingSplit}
                        error={errorSplit}
                        paginatorInfo={
                            filteredPagination
                                ? filteredPagination
                                : {
                                      count: 0,
                                      currentPage: 0,
                                      hasMorePages: false,
                                      firstItem: null,
                                      lastItem: null,
                                      lastPage: 1,
                                      perPage: 10,
                                      total: 0,
                                      __typename: "PaginatorInfo",
                                  }
                        }
                    />
                </div>
            </TabPanel>

            {/*
             * Mapview
             */}
            {/* <TabPanel value={value} index={2}>
                <Mapview />
            </TabPanel> */}
        </div>
    );
};
export default CATenderTabs;
