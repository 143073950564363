import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";

import OpportunityOverviewTable from "./OpportunityOverviewTable";
import { useTranslation } from "react-i18next";
import setTitle from "../../../utils/setTitle";
import { useLazyQuery } from "@apollo/client";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { OpportunityFilterInput, OpportunitySearchInput, OpportunitySearchSource, SortOrder } from "../../../__generated__/globalTypes";
import {
    GetAllDocsForOpportunities,
    GetAllDocsForOpportunitiesVariables,
    GetAllDocsForOpportunities_filterOpportunityFiles_results,
    GetAllDocsForOpportunities_filterOpportunityFiles_filters,
    GetAllDocsForOpportunities_filterOpportunityFiles_snippets,
} from "../../../__generated__/GetAllDocsForOpportunities";
import { ColumnContext } from "../../../components/contextProviders/ColumnContext";
import OpportunitySearch from "../OpportunitySearch";
import queryString from "query-string";
import { handleOppHighlighting } from "../../../components/HighlightOpportunity";
import { ColumnProvider } from "../../../components/contextProviders/ColumnProvider";
import { allOppColumns } from "./AllOppColumns";
import { useMediaQuery } from "@mui/material";
import { QUERY_OPPORTUNITY_DOCUMENTS } from "../../../graphql/opportunityQueries/queryDefinitions";

interface Props {
    a?: number;
}

const filters: OpportunityFilterInput = {};
const defaultSearch: OpportunitySearchInput = { filters };
const defaultVariables: GetAllDocsForOpportunitiesVariables = {
    page: 1,
    first: 10,
    orderField: "publication_date",
    order: SortOrder.DESC,
    search: defaultSearch,
    source: OpportunitySearchSource.ALL_OPPORTUNITY_FILES,
};

const OpportunityOverview: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [
        orderField,
        // setOrderField
    ] = useState<string>(defaultVariables.orderField);
    const [
        orderDir,
        // setOrderDir
    ] = useState<SortOrder>(defaultVariables.order);
    const [query, setQuery] = useState<string>("");
    const [searchInput, setSearchInput] = useState<OpportunityFilterInput>({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(defaultVariables.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.first);
    const { t } = useTranslation();
    const [oppData, setOppData] = useState<GetAllDocsForOpportunities_filterOpportunityFiles_results | null>();
    const [oppSnippets, setOppSnippets] = useState<(GetAllDocsForOpportunities_filterOpportunityFiles_snippets | null)[] | null>();
    const [filterData, setFilterData] = useState<GetAllDocsForOpportunities_filterOpportunityFiles_filters[] | null>([]);
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    /**
     * Variables for the GQL query
     */
    const variables: GetAllDocsForOpportunitiesVariables = {
        source: defaultVariables.source,
        page: page,
        first: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            query: query,
            filters: searchInput,
        },
    };

    /**
     * Initialize state from url parameters
     */
    useEffect(() => {
        // Initial setup using query parameters
        // get active filterset from session storage
        if (sessionStorage.getItem("aof")) {
            setSearchInput(JSON.parse(sessionStorage.getItem("aof") || "{}"));
        }
        // get query from session storage
        if (sessionStorage.getItem("aoq")) {
            setQuery(JSON.parse(sessionStorage.getItem("aoq") || ""));
        }
        if (sessionStorage.getItem("aop")) {
            // set current page to sessionstorage value or 1 if sessionstorage isn't set
            setPage(parseInt(JSON.parse(sessionStorage.getItem("aop") || "1")));
        }

        // check url if some parameters are available
        // in this case only CA is a possible parameter in url
        if (window.location.search) {
            const query = queryString.parse(window.location.search);
            // check if url has query params for year, month and likedTender.
            // If so, set the state and filters accordingly.

            if (typeof query.contracting_authorities === "string") {
                setSearchInput({
                    contracting_authorities: [parseInt(query.contracting_authorities)],
                });
            }
        }
    }, [t]);

    /**
     * GQL query to get all opportunities, on complete -> set filters to state and data to another state
     */
    const [run, { loading, error }] = useLazyQuery<GetAllDocsForOpportunities, GetAllDocsForOpportunitiesVariables>(QUERY_OPPORTUNITY_DOCUMENTS, {
        variables: variables,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.filterOpportunityFiles) {
                setFilterData(data.filterOpportunityFiles.filters);
                setOppData(data.filterOpportunityFiles.results);
                setOppSnippets(data.filterOpportunityFiles.snippets);
                handleOppHighlighting();
            }
        },
    });

    /**
     * On each state change run GQL query again with updated variables
     */
    useEffect(() => {
        setTitle(t("AllOpportunities"));
        sessionStorage.setItem("aof", JSON.stringify(searchInput));
        sessionStorage.setItem("aop", JSON.stringify(page));
        sessionStorage.setItem("aoq", JSON.stringify(query));
        run({
            variables: {
                source: defaultVariables.source,
                page: page,
                first: rowsPerPage,
                orderField: orderField,
                order: orderDir,
                search: {
                    query: query,
                    filters: searchInput,
                },
            },
        });
    }, [run, t, page, rowsPerPage, orderField, orderDir, query, searchInput]);

    // handle pagination change: -page change, -rows per page change
    const handlePaginationChange = (page: number, rows: number) => {
        setPage(page);
        setRowsPerPage(rows);
        if (rows !== rowsPerPage) {
            setPage(1);
        }
    };

    const renderPage = (content: React.ReactNode) => (
        <ColumnProvider storeKey="cuoc" defaultState={allOppColumns}>
            <ColumnContext.Provider value={{ selectedRows, setSelectedRows }}>
                <Box component="div" id="opportunity-root" sx={{ padding: { xs: "20px 8px", md: "20px" }, overflow: "scroll" }}>
                    <OpportunitySearch
                        resetFilters={() => {
                            setSearchInput({});
                            setPage(1);
                        }}
                        columns={allOppColumns}
                        orderField={orderField}
                        order={orderDir}
                        loading={loading}
                        resetPage={() => setPage(1)}
                        searchInput={searchInput}
                        onChange={setSearchInput}
                        filterData={filterData}
                        visible={noMobile ? true : false}
                        query={query}
                        setQuery={setQuery}
                        startSearch={() => {
                            setPage(1);
                            run({ variables });
                        }}
                    />
                    {content}
                </Box>
            </ColumnContext.Provider>
        </ColumnProvider>
    );

    if (!oppData && loading) {
        return renderPage(<FullPageSpinner />);
    }

    if (error || !oppData) {
        return renderPage(
            <OpportunityOverviewTable
                query={""}
                snippets={null}
                loading={loading}
                error={error}
                rows={[]}
                paginatorInfo={{
                    count: 0,
                    currentPage: 0,
                    hasMorePages: false,
                    firstItem: 0,
                    lastItem: 0,
                    lastPage: 1,
                    perPage: 10,
                    total: 0,
                    __typename: "PaginatorInfo",
                }}
                onChangePagination={handlePaginationChange}
                onTableChange={undefined}
            />
        );
    }

    return renderPage(
        <div>
            <OpportunityOverviewTable
                query={query}
                snippets={oppSnippets ? oppSnippets : null}
                loading={loading}
                rows={oppData.data}
                paginatorInfo={
                    oppData.paginatorInfo
                        ? oppData.paginatorInfo
                        : {
                              count: 0,
                              currentPage: 0,
                              hasMorePages: false,
                              firstItem: 0,
                              lastItem: 0,
                              lastPage: 1,
                              perPage: 10,
                              total: 0,
                              __typename: "PaginatorInfo",
                          }
                }
                onChangePagination={handlePaginationChange}
                onTableChange={undefined}
            />
        </div>
    );
};

export default OpportunityOverview;
