import React from "react";
import { GET_FIRST_DISPATCH_COLUMN } from "../../graphql/queryDefForColumns";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import moment from "moment";
import { GetFirstDispatchColumn, GetFirstDispatchColumnVariables } from "../../__generated__/GetFirstDispatchColumn";

interface Props {
    tender_id: string;
}

const FirstDispatchColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetFirstDispatchColumn, GetFirstDispatchColumnVariables>(GET_FIRST_DISPATCH_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;
    const time = tender.first_dispatchdate ? moment(tender.first_dispatchdate).format("L") : "";

    return <Typography style={{ whiteSpace: "nowrap" }}>{time}</Typography>;
};

export default FirstDispatchColumn;
