import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
// import { Link } from "react-router-dom";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Grid, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import {
    GetDefaultSummary_tender_lots,
    GetDefaultSummary_tender_omschrijving_source,
    GetDefaultSummary_tender_omvang_opdracht_source,
    GetDefaultSummary_tender_sectors,
} from "../../__generated__/GetDefaultSummary";
import DocumentDrawer from "../../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import { OpenProps } from "../tenderDetail/Summary";
import SummaryExpandSingle from "./SummaryExpandSingle";
import SummaryExpandLots from "./SummaryExpandLots";
import moment from "moment";
import anchorNewTab from "../../utils/anchorNewTab";
import LegalFrameworkTooltip from "../LegalFrameworkTooltip";
import SectorIconLoader from "./SectorIconLoader";
import { getRelativeTimeInDays } from "../../utils/relativeTimeInDays";

interface Props {
    handleClose(): void;
    open: boolean;
    tender_id: string;
    lots: GetDefaultSummary_tender_lots[];
    description: string;
    qualitativeScope: string;
    omvang_opdracht_source: GetDefaultSummary_tender_omvang_opdracht_source | null;
    omschrijving_source: GetDefaultSummary_tender_omschrijving_source | null;
    uniqueParentIds: GetDefaultSummary_tender_sectors[];
    subSectors: GetDefaultSummary_tender_sectors[];
    host: string | null;
    hostUrl: string | null;
    deadline: string;
    lastUpdate: string;
    firstDispatch: string;
    awardCrit: string | null;
    procedureType: string | null;
    legalFramework: string | null;
    legalBase: string | null;
    type: string | null;
    tender_country: string | null;
}

const SummaryExpandModal: React.FC<Props> = ({
    handleClose,
    open,
    tender_id,
    lots,
    description,
    qualitativeScope,
    omvang_opdracht_source,
    omschrijving_source,
    deadline,
    host,
    hostUrl,
    legalBase,
    legalFramework,
    tender_country,
    type,
    procedureType,
    awardCrit,
    firstDispatch,
    lastUpdate,
    subSectors,
    uniqueParentIds,
}) => {
    const { t } = useTranslation();
    const white = "#ffffff";
    const blue = "#173357";
    const [openDoc, setOpenDoc] = useState<OpenProps>({ open: false, link: "", label: "", doc_id: "" });
    // Close doc drawer
    const closeDrawer = () => {
        setOpenDoc({ open: false, link: "", label: "", doc_id: "" });
    };

    return (
        <Dialog
            open={open}
            scroll={"paper"}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "85%",
                    height: "80vh",
                },
            }}
            fullWidth
            maxWidth={"lg"}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <Toolbar
                sx={{
                    backgroundColor: blue,
                    color: white,
                }}
                disableGutters
                style={{ display: "flex", justifyContent: "space-between", paddingLeft: 16, paddingRight: 16 }}
            >
                <div style={{ flexGrow: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {/* Tender title as chat title */}
                    <Typography variant="body2">Samenvatting</Typography>
                </div>
                {/* conversation name like private notes, team.. or name of colleague  and closebutton to close modal*/}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {/* <Typography variant="body2">{conversationsTest.find((c) => c.id === conversation)?.name}</Typography> */}
                    <IconButton onClick={handleClose} size="small">
                        <Close htmlColor={white} />
                    </IconButton>
                </div>
            </Toolbar>
            <DialogContent style={{ paddingTop: 0 }}>
                <div style={{ margin: "8px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "95%" }}>
                    <div style={{ marginTop: "16px" }}>
                        {lots.length === 0 ? (
                            <SummaryExpandSingle
                                tender_id={tender_id}
                                description={description}
                                qualitativeScope={qualitativeScope}
                                omvang_opdracht_source={omvang_opdracht_source}
                                omschrijving_source={omschrijving_source}
                                setOpenDoc={setOpenDoc}
                            />
                        ) : (
                            <SummaryExpandLots
                                lots={lots}
                                description={description}
                                qualitativeScope={qualitativeScope}
                                omvang_opdracht_source={omvang_opdracht_source}
                                omschrijving_source={omschrijving_source}
                                setOpenDoc={setOpenDoc}
                                tender_id={tender_id}
                            />
                        )}
                    </div>
                    <div>
                        <Grid container item xs={12} spacing={2} style={{ marginTop: 32 }}>
                            {/* 
                            1: Deadline
                             */}
                            {deadline !== null && (
                                <Grid item xs={3}>
                                    <Typography sx={{ marginBottom: "4px" }} variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        {t("tenderPage.Deadline")}
                                    </Typography>
                                    <React.Fragment>
                                        <Typography>
                                            {moment(deadline).format("LLL")},
                                            {getRelativeTimeInDays(deadline, t).show && <> ({getRelativeTimeInDays(deadline, t).date})</>}
                                        </Typography>
                                    </React.Fragment>
                                </Grid>
                            )}
                            {/* 
                            2: First dispatch 
                            */}
                            <Grid item xs={3}>
                                <Typography sx={{ marginBottom: "4px" }} variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    {t("tenderPage.FirstPublication")}
                                </Typography>
                                <Typography>
                                    {moment(firstDispatch).format("LL")},{" "}
                                    {host && (
                                        <>
                                            {Boolean(hostUrl) ? (
                                                <a {...anchorNewTab} href={hostUrl || ""}>
                                                    {host}
                                                </a>
                                            ) : (
                                                <>{host}</>
                                            )}
                                        </>
                                    )}
                                </Typography>
                            </Grid>
                            {/* 
                            3: Last update 
                            */}
                            <Grid item xs={3}>
                                <Typography sx={{ marginBottom: "4px" }} variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    {t("tenderPage.LastUpdate")}
                                </Typography>
                                <Typography>{moment(lastUpdate).format("LL")}</Typography>
                            </Grid>
                            {/* 
                            4: Procedure 
                            */}
                            <Grid item xs={3}>
                                <Typography sx={{ marginBottom: "4px" }} variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                    {t("procedure")}
                                </Typography>
                                <Typography>
                                    {procedureType}
                                    {type !== "" && `, ${type}`}
                                    {legalFramework ? (
                                        <>
                                            <LegalFrameworkTooltip framework={legalFramework} />
                                        </>
                                    ) : legalBase !== null ? (
                                        <>
                                            <Tooltip
                                                title={<Typography variant="caption">{t(`legal_basis_tooltip.${legalBase}`).toString()}</Typography>}
                                            >
                                                <Typography style={{ marginLeft: 4 }}>
                                                    ({legalBase !== null ? t(`legal_basis.${legalBase}`) : null})
                                                </Typography>
                                            </Tooltip>
                                        </>
                                    ) : null}
                                    <br />
                                </Typography>
                            </Grid>
                            {/*
                            5: Award criteria
                            */}
                            {awardCrit && (
                                <Grid item xs={3}>
                                    <Typography sx={{ marginBottom: "4px" }} variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        {t("awardCriteria")}
                                    </Typography>
                                    <Typography style={{ whiteSpace: "pre-wrap" }}>{awardCrit?.replace(/<br\s*[\\/]?>/gi, "\n")}</Typography>
                                </Grid>
                            )}
                            {/* 
                            6: Subsectors
                            */}
                            {subSectors && tender_country === "Netherlands" && (
                                <Grid item xs={3}>
                                    <React.Fragment>
                                        <Typography sx={{ marginBottom: "4px" }} variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                            {subSectors.length > 1 ? t("subsectors") : t("subsector")}:
                                        </Typography>
                                        <Typography>
                                            {subSectors.map((s) => (
                                                <div key={s.id} style={{ display: "flex", alignItems: "center", marginLeft: -3 }}>
                                                    <SectorIconLoader sector_id={s.id} />
                                                    <Typography style={{ marginLeft: 8 }}>{s.name}</Typography>
                                                </div>
                                            ))}
                                        </Typography>
                                    </React.Fragment>
                                </Grid>
                            )}
                            {/* 
                            7: Sectors
                            */}
                            {uniqueParentIds && tender_country !== "Netherlands" && (
                                <Grid item xs={3}>
                                    <Typography sx={{ marginBottom: "4px" }} variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        {uniqueParentIds.length > 1 ? t("HeadSectors") : t("HeadSector")}:
                                    </Typography>
                                    <Typography>
                                        <>
                                            {uniqueParentIds.map((s) => (
                                                <div key={s.id} style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography>{s.parent?.name}</Typography>
                                                </div>
                                            ))}
                                        </>
                                    </Typography>
                                </Grid>
                            )}
                            {/*
                            8: Lots
                            */}
                            {lots.length > 0 && (
                                <Grid item xs={3}>
                                    <Typography sx={{ marginBottom: "4px" }} variant="h4" style={{ display: "flex", alignItems: "center" }}>
                                        {t("Lots")}
                                    </Typography>
                                    <React.Fragment>
                                        <Tooltip
                                            placement="right"
                                            title={lots.map((i) => {
                                                return (
                                                    <div key={i.id} style={{ marginBottom: 4 }}>
                                                        <Typography style={{ lineHeight: 1.3 }} variant="caption">
                                                            {`${t("tenderPage.Lot")} ${i.number} - ${i.title}`}
                                                        </Typography>
                                                    </div>
                                                );
                                            })}
                                        >
                                            <Typography style={{ display: "inline", cursor: "pointer" }}>{lots.length}</Typography>
                                        </Tooltip>
                                    </React.Fragment>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("advancedSearch.Close")}
                </Button>
            </DialogActions>
            {/* Drawer that contains clicked pdf file */}
            {typeof openDoc.link === "string" && openDoc.open === true && typeof openDoc.label === "string" && (
                <DocumentDrawer
                    open={openDoc.open}
                    onClose={closeDrawer}
                    title={openDoc.label}
                    color={blue}
                    url={openDoc.link}
                    doc_id={openDoc.doc_id}
                    module_item_id={tender_id}
                />
            )}
        </Dialog>
    );
};

export default SummaryExpandModal;
