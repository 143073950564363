export default () => {
    const stylingHeader = `
        font-size: 60px;
        color: red;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    `;

    const stylingText = `
        font-size: 20px;
    `;
    console.log("%cSTOP!", stylingHeader);
    console.log(
        "%cDit venster is alleen bedoeld voor ontwikkelaars van deze website. / This console is intended for developers of this website.",
        stylingText
    );
};
