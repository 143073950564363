import React from "react";
import { MUIDataTableColumn } from "mui-datatables";
import { Translation } from "react-i18next";
import FaviconBox from "../../../components/FaviconBox";
import AllOppToDetail from "./AllOppToDetail";
import OppCreatedAtColumn from "../../../components/columns/OppCreatedAtColumn";
import OppSeachruleMatches from "../../../components/columns/OppSeachruleMatches";
import SmallOppMenu from "../SmallOppMenu";
import LastMessageColumnType from "../../../components/columns/LastMessageColumnType";

// DEFAULT COLUMN DEFINITION, first 4 columns have to stay in the same order
export const allOppColumns: MUIDataTableColumn[] = [
    // id of the opportunity [0]
    {
        label: "id",
        name: "id",
        options: { display: "excluded" },
    },
    // contracting authority, HAS TO BE SECOND COLUMN [1]
    {
        label: "AD",
        name: "AD",
        options: { display: "excluded" },
    },
    // favicon, HAS TO BE THIRD COLUMN [2]
    {
        label: "",
        name: "favicon",
        options: { display: "excluded" },
    },
    // contracting authority id, [3]
    {
        label: "ad_id",
        name: "ad_id",
        options: { display: "excluded" },
    },
    // contracting authority id, [4]
    {
        label: "ad_country",
        name: "ad_country",
        options: { display: "excluded" },
    },
    // opportunity title [5]
    {
        label: "Title",
        name: "doc_name",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Title")}</>}</Translation>;
            },
            customBodyRender(value: any, tableMeta) {
                return (
                    <>
                        {/* return tendertitle and second Contracting Authority. */}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <FaviconBox favicon={tableMeta.rowData[2]} name={tableMeta.rowData[1]} color={"#F57117"} />
                            <AllOppToDetail
                                page="all"
                                oppName={value}
                                oppLink={`/opportunities/${tableMeta.rowData[0]}`}
                                caLink={`/contracting-authorities/${tableMeta.rowData[3]}`}
                                caName={tableMeta.rowData[1]}
                            />
                        </div>
                    </>
                );
            },
        },
    },

    // {
    //     label: "City",
    //     name: "ad_city",
    //     options: {
    //         display: true,
    //         sort: true,
    //         customHeadLabelRender: () => {
    //             return <Translation>{(t) => <>{t("Column_Header.City")}</>}</Translation>;
    //         },
    //         customBodyRender(value: any, tableMeta) {
    //             return (
    //                 <div style={{ display: "flex", alignItems: "center" }}>
    //                     <div style={{ marginRight: 8, display: "flex", marginLeft: -4 }}>
    //                         <CountryFlagBox code={tableMeta.rowData[4]} />
    //                     </div>
    //                     <Typography noWrap>{value}</Typography>
    //                 </div>
    //             );
    //         },
    //     },
    // },

    // {
    //     label: "Province",
    //     name: "provincie",
    //     options: {
    //         display: true,
    //         customHeadLabelRender: () => {
    //             return <Translation>{(t) => <>{t("Column_Header.Province")}</>}</Translation>;
    //         },
    //     },
    // },

    {
        label: "DocType",
        name: "filetype",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.DocType")}</>}</Translation>;
            },
        },
    },
    {
        label: "CA_type",
        name: "ca_type",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.CA_type")}</>}</Translation>;
            },
        },
    },
    {
        label: "Published",
        name: "date",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Published")}</>}</Translation>;
            },
            customBodyRender(value: any, tableMeta) {
                return <OppCreatedAtColumn opp_file_id={value} />;
            },
        },
    },

    // {
    //     label: "Reviews",
    //     name: "",
    //     options: {
    //         display: true,
    //         customHeadLabelRender: () => {
    //             return <Translation>{(t) => <>{t("Column_Header.Reviews")}</>}</Translation>;
    //         },
    //         customBodyRender(value: any, tableMeta) {
    //             return (
    //                 <>
    //                     <p>avatar</p>
    //                     {/* <AvatarReviewed likes={null} /> */}
    //                 </>
    //             );
    //         },
    //     },
    // },

    // show last private note
    // index 7
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        label: "LastPrivateNote",
        name: "lastPrivateNote",
        options: {
            sort: false,
            display: true,
            draggable: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.LastPrivateNote")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <LastMessageColumnType id={tableMeta.rowData[0]} module={"opportunity"} />;
            },
        },
    },
    {
        label: "Actions",
        name: "searchrules",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(value: any, tableMeta) {
                return (
                    <div style={{ display: "flex", flexDirection: "row", width: "70%", justifyContent: "flex-end" }}>
                        <OppSeachruleMatches opp_file_id={value} />
                    </div>
                );
            },
        },
    },
    // actions to like, dislike
    // index 7
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        label: "Actions",
        name: "smallmenu",
        options: {
            sort: false,
            display: true,
            draggable: false,
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(id: any, tableMeta) {
                return <SmallOppMenu opp_id={id} />;
            },
        },
    },
];
