import React from "react";
import { Dialog, DialogContent, DialogContentText, Typography } from "@mui/material";

interface Props {
    response: string;
    opened: boolean;
    setOpen: (bln: boolean) => void;
}

const ResponseOnRemove: React.FC<Props> = ({ response, opened, setOpen }) => {
    const formatted = response.replace(/(\n|^)Project:/g, "\n\nProject:");

    return (
        <Dialog open={opened} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: formatted }} />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default ResponseOnRemove;
