import React from "react";
import { Tabs, Tab, Typography, AppBar, Box, Divider, Theme, createStyles } from "@mui/material";
import { useReRunHighlighting } from "../../../components/contextProviders/HighlightContext";
import { withStyles } from "tss-react/mui";

interface Props {
    tender_id: string;
    children: React.ReactNode;
    activeTab: string;
    setActiveTab(str: string): void;
    awardInfo: boolean;
    labels: boolean;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{
                height: `calc(72.6vh - 166px)`,
                overflow: "auto",
            }}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

interface StyledTabsProps {
    children: any;
    value: string;
    onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
}

const StyledTabs = withStyles(
    (props: StyledTabsProps) => (
        <Tabs
            {...props}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ children: <span /> }}
            textColor="primary"
            sx={{
                "& .MuiTab-root.Mui-selected": {
                    color: "#173357",
                },
            }}
        />
    ),
    {
        root: {
            minHeight: "29px",
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "140px",
                width: "100%",
                backgroundColor: "#173357",
            },
        },
    }
);

interface StyledTabProps {
    label: React.ReactNode;
    value: string;
}

const StyledTab = withStyles(
    (props: StyledTabProps) => <Tab disableRipple {...props} />,
    (theme: Theme) =>
        createStyles({
            root: {
                minHeight: "44px",
                minWidth: "140px",
                maxWidth: "140px",
                padding: 0,
                textTransform: "none",
                fontSize: theme.typography.pxToRem(15),
                marginRight: theme.spacing(1),
                "&:focus": {
                    opacity: 1,
                },
            },
        })
);

const SplitTabPanel: React.FC<Props> = ({ children, activeTab, setActiveTab, tender_id, awardInfo, labels }) => {
    const { rerunHighlight, setRerunHighlight } = useReRunHighlighting();
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setActiveTab(newValue);
        setRerunHighlight(!rerunHighlight);
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <AppBar elevation={0} position="static" color="transparent" style={{ width: undefined, flex: 1 }}>
                    <StyledTabs value={activeTab} onChange={handleChange}>
                        <StyledTab
                            value={"0"}
                            label={
                                <>
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "140px",
                                            whiteSpace: "nowrap",
                                            display: "inline-block",
                                        }}
                                    >{`Samenvatting`}</span>
                                </>
                            }
                        />
                        {labels && (
                            <StyledTab
                                value={"1"}
                                label={
                                    <>
                                        <span
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "140px",
                                                whiteSpace: "nowrap",
                                                display: "inline-block",
                                            }}
                                        >{`Labels`}</span>
                                    </>
                                }
                            />
                        )}
                        {awardInfo && (
                            <StyledTab
                                value={"2"}
                                label={
                                    <>
                                        <span
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "140px",
                                                whiteSpace: "nowrap",
                                                display: "inline-block",
                                            }}
                                        >{`Gunningsinformatie`}</span>
                                    </>
                                }
                            />
                        )}
                    </StyledTabs>
                </AppBar>
            </div>
            <Divider />
            <TabPanel value={activeTab} index={activeTab}>
                {children}
            </TabPanel>
        </>
    );
};

export default SplitTabPanel;
