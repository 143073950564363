import React from "react";
import { Avatar, Paper, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { Description } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineItem as MuiTimelineItem,
} from "@mui/lab";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { withStyles } from "tss-react/mui";
import { GetUserLogsWithFilters_activities_data } from "../../../__generated__/GetUserLogsWithFilters";

interface Props {
    item: any;
    log: GetUserLogsWithFilters_activities_data;
}

/**
 * This is used for the advanced search
 * timeline component adds this styling automatically,
 * Now its overruled so its aligned on the left side.
 */
const TimelineItem = withStyles(MuiTimelineItem, {
    missingOppositeContent: {
        "&:before": {
            display: "none",
        },
    },
    content: {
        flex: 20,
    },
    oppositeContent: {
        flex: 3,
    },
});

const StyledLink = styled(Link)`
    color: #173357;
    text-decoration: underline;
    &:hover {
        background-color: #1733571a;
    }
`;

const UserTimelineItem: React.FC<Props> = ({ log, item }) => {
    const { t } = useTranslation();
    const potentialLog = log.description?.includes("Verstuurd")
        ? log.description.replace("Verstuurd", "ontvangen").replace("naar", "op")
        : log.description?.replace(/\s/g, "_").replace(/[^a-zA-Z0-9_]/g, "");
    // const desc_without_whitespace = log?.description?.replace(/\s/g, "_");
    // const remove_special_characters = desc_without_whitespace?.replace(/[^a-zA-Z0-9_]/g, "");

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            {isMobile ? (
                <Paper variant="outlined" sx={{ padding: "8px", marginTop: "8px", display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ bgcolor: "#173357", marginRight: "12px" }}>
                        <Description />
                    </Avatar>
                    <div>
                        <Typography style={{ display: "inline-block" }}>
                            <span>
                                {log?.causer?.employee.givenname} {t("has")}
                            </span>{" "}
                            {log?.subject?.__typename === "Tender" && (
                                <StyledLink
                                    to={`/tenders/${log?.subject?.id}`}
                                    onClick={() => {
                                        trackevents({ category: "Personal", action: "Click-go-to-detailpage-from-tender-log" });
                                    }}
                                >
                                    {item && item.map((i: any) => i.name)}
                                    {log.subject.namegiventocontract}
                                </StyledLink>
                            )}{" "}
                            <span>{t(potentialLog as string)}</span>
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {moment(log.created_at).format("LL")}, {moment(log.created_at).format("LT")}
                        </Typography>
                    </div>
                </Paper>
            ) : (
                <TimelineItem>
                    <TimelineOppositeContent sx={{ flex: 3 }}>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LL")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LT")}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        {log?.subject?.__typename === "Tender" && (
                            <TimelineDot sx={{ backgroundColor: "#173357", margin: "8px 0px" }}>
                                <Description />
                            </TimelineDot>
                        )}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ flex: 20 }}>
                        <Paper elevation={1} sx={{ padding: "6px 16px", marginTop: "3px" }}>
                            <Typography style={{ display: "inline-block" }}>
                                <span>
                                    {log?.causer?.employee.givenname} {t("has")}
                                </span>{" "}
                                {log?.subject?.__typename === "Tender" && (
                                    <StyledLink
                                        to={`/tenders/${log?.subject?.id}`}
                                        onClick={() => {
                                            trackevents({ category: "Personal", action: "Click-go-to-detailpage-from-tender-log" });
                                        }}
                                    >
                                        {item && item.map((i: any) => i.name)}
                                        {log.subject.namegiventocontract}
                                    </StyledLink>
                                )}{" "}
                                {/* <span>{t(remove_special_characters as string)} </span> */}
                                <span>{t(potentialLog as string)}</span>
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            )}
        </>
    );
};

export default UserTimelineItem;
