import React, { useState } from "react";

import { Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useForm } from "react-hook-form";
import { Disable } from "react-disable";
import { useTranslation } from "react-i18next";
import OpportunityViewpreferences from "./OpportunityViewPreferences";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { toast } from "react-toastify";
import OpportunityViewPreferencesMobile from "./OpportunityViewPreferencesMobile";
interface Props {
    a?: number;
}

const OpportunitySettings: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);

    const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));
    const green = "#225E4D";
    const onSubmit = async (data: Record<string, any>) => {
        setLoading(true);
        await sleep(1000);
        setLoading(false);
        toast.success("Voorkeuren voor kansen opgeslagen", { autoClose: 1500 });
    };

    const theme = useTheme();
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Disable disabled={loading}>
            <AccordionDetails sx={{ overflowX: "auto" }}>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                    <Grid container>
                        {mobileOnly ? (
                            <Grid item xs={12}>
                                <OpportunityViewPreferencesMobile />
                            </Grid>
                        ) : (
                            <Grid item xs={12} style={{ margin: "16px 16px 0px " }}>
                                <Typography variant="h5" style={{ display: "flex", alignItems: "center" }}>
                                    <TrendingUpIcon style={{ color: green, marginRight: 8, height: 20, width: 20 }} />
                                    {t("DefaultView")}
                                </Typography>
                                <Typography style={{ marginBottom: 24 }}>{t("DefaultViewDescription")}</Typography>
                                <OpportunityViewpreferences />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </AccordionDetails>
        </Disable>
    );
};

export default OpportunitySettings;
