import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

interface Props {
    active: number;
    textualHelper: string | null;
    placement:
        | "bottom"
        | "left"
        | "right"
        | "top"
        | "bottom-end"
        | "bottom-start"
        | "left-end"
        | "left-start"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | undefined;
}

const StatusDot_Org: React.FC<Props> = ({ active, placement, textualHelper }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {(() => {
                /**
                 * Switch contains all states a tender could have, for each state a different component.
                 */
                switch (active) {
                    ///////////////////////////
                    // ORGANIZATIONS STATES //
                    /////////////////////////

                    case 1:
                        // green
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.Active")} placement={placement} arrow>
                                <div style={{ height: 14, width: 14, borderRadius: 8, backgroundColor: "green" }} />
                            </Tooltip>
                        );

                    case 0:
                        // red
                        return (
                            <Tooltip enterNextDelay={100} title={textualHelper as string} placement={placement} arrow>
                                <div style={{ height: 14, width: 14, borderRadius: 8, backgroundColor: "red" }} />
                            </Tooltip>
                        );

                    case 2:
                        // blue
                        return (
                            <Tooltip enterNextDelay={100} title={t<string>("Status.In-formation")} placement={placement} arrow>
                                <div style={{ height: 14, width: 14, borderRadius: 8, backgroundColor: "blue" }} />
                            </Tooltip>
                        );

                    default:
                        return null;
                }
            })()}
        </React.Fragment>
    );
};

export default StatusDot_Org;
