import { LocalOffer } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import React from "react";
import { SearchDistributionInput, SearchDistributionMoment, TenderFilterInput, TenderFilterTarget } from "../../../__generated__/globalTypes";
import { useTranslation } from "react-i18next";

interface Props {
    color: string;
    name: string;
    moment: SearchDistributionMoment | null;
    dist: SearchDistributionInput;
    chosenWords: string[];
    domain: TenderFilterTarget;
    filters: TenderFilterInput;
}

const TenderDistSummary: React.FC<Props> = ({ color, name, moment, dist, chosenWords, domain, filters }) => {
    const { t } = useTranslation();
    const blue = "#173357";

    const hasKeys = Object.keys(filters).filter((i) => i !== "country");

    return (
        <Paper square elevation={0} sx={{ p: 3, margin: "38px 0px" }}>
            <div style={{ display: "flex", marginBottom: "8px" }}>
                <LocalOffer sx={{ color: color, marginRight: "8px" }} /> <Typography fontSize={"1rem"}>{name}</Typography>
            </div>
            <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px", marginLeft: "4px" }}>
                Ik zoek aanbestedingen
            </Typography>

            {filters.country && (
                <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px" }}>
                    uit{" "}
                    <strong style={{ color: blue }}>
                        {filters.country.map((c) => (c === 528 ? "Nederland" : c.toString() === "56" ? "België" : c))}
                    </strong>
                    {/* {filterValues?.filter((f) => f?.key === "country").length > 0 && `uit`} <strong>{c?.labels?.map((l) => l).join(" en ")}</strong> */}
                </Typography>
            )}

            {chosenWords.length > 0 && (
                <>
                    met{" "}
                    {/* <Tooltip
                        enterNextDelay={100}
                        placement="top"
                        PopperProps={{
                            style: { marginBottom: -10 },
                        }}
                        title={chosenWords.map((i) => {
                            if (i.includes("~" || "*")) return <div key={i}>{i}</div>;
                            return <div key={i}>{i.replace(/["']/g, "")}</div>;
                        })}
                    > */}
                    <Typography variant="body2" sx={{ display: "inline-block", color: blue, marginRight: "4px" }}>
                        <strong> zoekwoorden </strong>
                    </Typography>
                    {/* </Tooltip> */}
                    <>
                        in{" "}
                        <Typography variant="body2" sx={{ display: "inline-block", color: blue, marginRight: "4px" }}>
                            <strong> {t(domain)} </strong>
                        </Typography>
                    </>
                </>
            )}
            {hasKeys.length > 0 && (
                <Typography variant="body2" sx={{ display: "inline-block", color: "#707070", marginRight: "4px" }}>
                    en de {hasKeys.length === 1 ? "filter" : "filters"}{" "}
                    {hasKeys.map((item, i) => {
                        if (i === hasKeys.length - 2) {
                            return (
                                <span style={{ color: blue, fontWeight: 500 }} key={i}>
                                    {t(`filter.${item}`)} en{" "}
                                </span>
                            );
                        }
                        if (i === hasKeys.length - 1) {
                            return (
                                <span style={{ color: blue, fontWeight: 500 }} key={i}>
                                    {t(`filter.${item}`)}
                                </span>
                            );
                        }
                        return (
                            <span style={{ color: blue, fontWeight: 500 }} key={i}>
                                {t(`filter.${item}`)},{" "}
                            </span>
                        );
                    })}
                </Typography>
            )}

            <div>
                {/* activity trigger */}
                <Typography
                    sx={{
                        display: "inline-block",
                        marginRight: "4px",
                        marginLeft: "4px",
                        color: blue,
                        fontWeight: 500,
                    }}
                >
                    {t(`${moment}`) as string}
                </Typography>

                <Typography style={{ display: "inline-block", marginRight: 4 }}>nieuwe resultaten </Typography>
                {/* worklist trigger, if false -> dont show text in sentence */}
                {dist.worklist === true && (
                    <Typography
                        sx={{
                            display: "inline-block",
                            color: blue,
                            fontWeight: 500,
                        }}
                    >
                        tonen in werklijst
                    </Typography>
                )}
                {/*
                 * Notification trigger
                 */}
                {dist.worklist === true && !dist.email_interval && (
                    <Typography
                        sx={{
                            display: "inline-block",
                            marginRight: "4px",
                            marginLeft: "4px",
                            color: blue,
                            fontWeight: 500,
                        }}
                    >
                        en per melding sturen
                    </Typography>
                )}
                {dist.worklist === true && dist.email_interval && (
                    <Typography
                        sx={{
                            display: "inline-block",
                            marginRight: "4px",
                            color: blue,
                            fontWeight: 500,
                        }}
                    >
                        , per melding sturen
                    </Typography>
                )}
                {dist.worklist === false && !dist.email_interval && (
                    <Typography
                        sx={{
                            display: "inline-block",
                            marginRight: "4px",
                            color: blue,
                            fontWeight: 500,
                        }}
                    >
                        per melding sturen
                    </Typography>
                )}
                {/*
                 *  Email trigger
                 */}
                {dist.email_interval && (
                    <React.Fragment>
                        {dist.worklist === true && <Typography style={{ display: "inline-block", marginRight: 4 }}>en</Typography>}

                        <Typography
                            sx={{
                                display: "inline-block",
                                marginRight: "4px",
                                color: blue,
                                fontWeight: 500,
                            }}
                        >
                            {t(`${dist.email_interval.toUpperCase()}`) as string}
                        </Typography>

                        <Typography
                            sx={{
                                display: "inline-block",
                                marginRight: "4px",
                                color: blue,
                                fontWeight: 500,
                            }}
                        >
                            {dist.email_interval !== null ? "per e-mail" : ""}
                        </Typography>

                        <Typography style={{ display: "inline-block" }}>sturen</Typography>
                    </React.Fragment>
                )}
            </div>
        </Paper>
    );
};

export default TenderDistSummary;
