import React, { useCallback, useState } from "react";
import { Grid } from "@mui/material";
import DraggableColumn from "./DraggableColumn";
import update from "immutability-helper";
import { Disable } from "react-disable";
import { useRowsContext } from "./RowsContext";
import {
    GetDashboardLayout_GetDashboardLayout_rows,
    GetDashboardLayout_GetDashboardLayout_rows_columns,
} from "../../../__generated__/GetDashboardLayout";
import RowElement from "./RowElement";
import CustomDragLayer from "./CustomDragLayer";

interface Props {
    editMode: boolean;
}

const SortableGrid: React.FC<Props> = ({ editMode }) => {
    // Access the rows, setRows function, and widgets from the context
    const { rows, setRows, widgets } = useRowsContext();

    // State for tracking the hovered row and column indices
    const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
    const [hoveredColumnIndex, setHoveredColumnIndex] = useState<number | null>(null);

    // Handle changes in row hover state
    const handleRowHoverChange = (rowIndex: number, hovered: boolean) => {
        setHoveredRowIndex(hovered ? rowIndex : null);
    };

    // Handle changes in column hover state
    const handleColumnHoverChange = (columnIndex: number, hovered: boolean) => {
        setHoveredColumnIndex(hovered ? columnIndex : null);
    };

    const addRowAndColumn = (widgetId: number, rowIndex: number) => {
        const newRow: GetDashboardLayout_GetDashboardLayout_rows = {
            id: `new${rows.length}`,
            columns: [{ id: "new", widgetId: widgetId, __typename: "DashboardColumn", name: "A" }],
            order: rowIndex,
            __typename: "DashboardRow",
        };

        setRows((prevRows) => {
            const updatedRows = [...prevRows.slice(0, rowIndex), newRow, ...prevRows.slice(rowIndex)];

            // Update the order property based on the new positions
            return updatedRows.map((row, index) => ({
                ...row,
                order: index + 1,
            }));
        });
    };

    // Handle changes to columns within a row, using useCallback for memoization
    const handleColumnChange = useCallback(
        (rowId: string, widgetId: number) => {
            setRows((prevRows) => {
                // Find the index of the row to be updated
                const rowIndex = prevRows.findIndex((row) => row.id === rowId);
                if (rowIndex === -1) return prevRows;

                const updatedColumns = [...prevRows[rowIndex].columns];
                // Find the index of the column to be updated
                const columnIndex = updatedColumns.findIndex((column) => column.widgetId === widgetId);

                if (columnIndex !== -1) {
                    // Update existing column
                    updatedColumns[columnIndex] = {
                        ...updatedColumns[columnIndex],
                        widgetId: widgetId, // Ensure widgetId is updated correctly if needed
                    };
                } else {
                    // Add new column with the widgetId
                    updatedColumns.push({
                        id: `new${updatedColumns.length}`,
                        widgetId: widgetId,
                        __typename: "DashboardColumn",
                        name: String.fromCharCode(65 + updatedColumns.length), // Assign a name based on the order (A, B, C, etc.)
                    });
                }

                // Return the updated rows with the modified columns for the specified row
                return update(prevRows, {
                    [rowIndex]: {
                        columns: {
                            $set: updatedColumns,
                        },
                    },
                });
            });
        },
        [setRows]
    );

    // Move a row to a new position, updating the order property accordingly
    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            setRows((prevRows: GetDashboardLayout_GetDashboardLayout_rows[]) => {
                const updatedRows = update(prevRows, {
                    $splice: [
                        [dragIndex, 1], // Remove the row from its original position
                        [hoverIndex, 0, prevRows[dragIndex] as GetDashboardLayout_GetDashboardLayout_rows], // Insert the row at the new position
                    ],
                });

                // Update the order property based on the new position
                return updatedRows.map((row, index) => ({
                    ...row,
                    order: index + 1,
                }));
            });
        },
        [setRows]
    );

    // Move a column within a row, updating the name property to maintain alphabetical order
    const moveColumn = useCallback(
        (rowIndex: number, dragIndex: number, hoverIndex: number) => {
            setRows((prevRows) => {
                // Create a deep copy of the previous state
                const updatedRows = prevRows.map((row) => ({ ...row, columns: [...row.columns] }));

                // Extract the columns array of the row being modified
                const column = [...updatedRows[rowIndex].columns];

                // Remove the dragged column from its original position
                const [movedColumn] = column.splice(dragIndex, 1);

                // Insert the dragged column into the new position
                column.splice(hoverIndex, 0, movedColumn);

                const columns: GetDashboardLayout_GetDashboardLayout_rows_columns[] = column.map((row, index) => ({
                    ...row,
                    name: String.fromCharCode(65 + index),
                }));

                //TODO: add line to set name to ABC
                // Sort columns alphabetically by name
                // columns.sort((a, b) => a.name.localeCompare(b.name));

                // Update the columns array of the row in the updated state
                updatedRows[rowIndex] = { ...updatedRows[rowIndex], columns };

                // Return the updated state
                return updatedRows;
            });
        },
        [setRows]
    );

    return (
        <div>
            <Grid container spacing={1}>
                {rows
                    .slice()
                    .sort((a, b) => a.order - b.order)
                    .map((row, rowIndex) => (
                        <RowElement
                            totalRows={rows.length}
                            editMode={editMode}
                            key={row.id}
                            id={parseInt(row.id)}
                            index={rowIndex}
                            moveRow={moveRow}
                            onAddColumnToRow={handleColumnChange}
                            row={row}
                            onHoverChange={(hovered) => handleRowHoverChange(parseInt(row.id), hovered)}
                            isHovered={hoveredRowIndex === parseInt(row.id) && hoveredColumnIndex === null}
                            moveColumn={moveColumn}
                            handleColumnHoverChange={handleColumnHoverChange}
                            hoveredColumnIndex={hoveredColumnIndex}
                            addRowAndColumn={addRowAndColumn}
                        >
                            {row.columns
                                .slice()
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((column, columnIndex) => {
                                    const widget = widgets.find((w) => w.id === column.widgetId);
                                    if (widget === undefined) {
                                        return <></>;
                                    } else
                                        return (
                                            <DraggableColumn
                                                key={column.id}
                                                editMode={editMode}
                                                id={column.id}
                                                widgetId={column.widgetId}
                                                rowIndex={rowIndex}
                                                index={columnIndex}
                                                moveColumn={moveColumn}
                                                row={row}
                                                onHoverChange={(hovered) => handleColumnHoverChange(column.widgetId, hovered)}
                                                isHovered={Boolean((hoveredColumnIndex as number) === column.widgetId)}
                                            >
                                                <Disable disabled={editMode}>{widget.component(row.columns.length)}</Disable>
                                            </DraggableColumn>
                                        );
                                })}
                        </RowElement>
                    ))}
                {editMode && <CustomDragLayer rows={rows} />}
            </Grid>
        </div>
    );
};

export default SortableGrid;
