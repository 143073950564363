import React, { useState, useEffect } from "react";
import { Paper, Button, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// components used in this page
import { useTranslation } from "react-i18next";
import setTheme from "../../../../utils/setTheme";
import { greenTheme } from "../../../../assets/styles";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { OpportunitySearchInput, OpportunityFilterInput, SortOrder } from "../../../../__generated__/globalTypes";
import { GetAllDocsForOppSearchWizard, GetAllDocsForOppSearchWizardVariables } from "../../../../__generated__/GetAllDocsForOppSearchWizard";
import ResultsModalOpp from "./ResultModalOpp";
import DistModal from "../DistModal";
import { AddOpportunitySearch, AddOpportunitySearchVariables } from "../../../../__generated__/AddOpportunitySearch";
import { Disable } from "react-disable";
import { GetUserSearchRules_currentUser_opportunitySearches } from "../../../../__generated__/GetUserSearchRules";
import { UpdateOpportunitySearch, UpdateOpportunitySearchVariables } from "../../../../__generated__/UpdateOpportunitySearch";
import {
    QUERY_ALL_OPPORTUNITY_DOCUMENTS_FOR_WIZARD,
    SAVESEARCHRULEOPPORTUNITY,
    UPDATESEARCHRULEOPPORTUNITY,
} from "../../../../graphql/opportunityQueries/queryDefinitions";
import { useTour } from "../../../../components/contextProviders/TourContext";
import { QUERY_ALL_SEARCHTERMS, QUERY_SEARCHRULES, QUERY_USER_IS_SUB_ADMIN } from "../../../../graphql/queryDefCurrentUser";
import { GetWordArray } from "../../../../__generated__/GetWordArray";
import { CheckIfUserIsAdmin } from "../../../../__generated__/CheckIfUserIsAdmin";
import WordModalQueryBuilder from "../WordModalQueryBuilder";
import { generateUniqueId, useQueryBuilder } from "../QueryBuilder/QueryBuilderContext";

interface Props {
    onClose(): void;
    editRule: GetUserSearchRules_currentUser_opportunitySearches;
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const getSteps = () => {
    return ["Zoekwoorden opstellen", "Resultaten verfijnen", "Distributie"];
};

// Default settings for states
const filters: OpportunityFilterInput = {};
const defaultSearch: OpportunitySearchInput = { filters };
const defaultVariables: GetAllDocsForOppSearchWizardVariables = {
    page: 1,
    first: 50,
    orderField: "year_from",
    order: SortOrder.DESC,
    search: defaultSearch,
};

const OpportunityBasePlate: React.FC<Props> = ({ onClose, editRule, activeStep, setActiveStep }) => {
    const { refetch } = useQuery<GetWordArray>(QUERY_ALL_SEARCHTERMS);
    const { t } = useTranslation();
    // state for searchrule name
    const [name, setName] = useState<string>("");
    // State for orderField
    const [
        orderField,
        // setOrderField
    ] = useState<string>(defaultVariables.orderField);
    // State for orderDir
    const [
        orderDir,
        // setOrderDir
    ] = useState<SortOrder>(defaultVariables.order);
    // State for query
    const [query, setQuery] = useState<string>("");
    // State for active filters
    const [searchInput, setSearchInput] = useState<OpportunityFilterInput>({});
    // State for active page
    const [page] = useState(defaultVariables.page);
    // State for rows per page
    const [rowsPerPage] = useState(defaultVariables.first);
    // Array of chosen searchterms
    // const [chosenWords, setChosenWords] = useState<string[]>([]);

    // TourOpener
    const { setOpenTour, setTourVariables, setCurrentStep } = useTour();
    // checked system opportunity searchrules
    // const [checkedSub, setCheckedSub] = useState<number[]>([]);
    const {
        areThereAnyWordsSelected,
        advancedQueryString,
        handleGenerateQuery,
        resetAdvancedQuery,
        areThereNEARConditionsNotValid,
        // containsOnlyOrOperator,
        detectSingleOperatorUsage,
        createKeywordwithConditions,
        setGroups,
    } = useQueryBuilder();

    // State that keeps all data from the query
    const [allData, setAllData] = useState<GetAllDocsForOppSearchWizard | null>(null);

    // const [loading, setLoading] = useState<boolean>(false);
    const [staticLoad, setStaticLoad] = useState<boolean>(false);

    /**
     * Define the GQL query
     */
    const { data: adminCheck } = useQuery<CheckIfUserIsAdmin>(QUERY_USER_IS_SUB_ADMIN, {
        fetchPolicy: "network-only",
    });

    /**
     * Check if editRule is defined
     * This should mean a user want to edit a rule
     * Fill in name and query of selected searchrule
     */
    useEffect(() => {
        if (editRule) {
            setName(editRule.name);
            /**
             * If query is not empty, set chosenWords to query of editRule
             */
            if (!!editRule.query) {
                const checkOperators = detectSingleOperatorUsage(editRule.query);
                if (checkOperators !== false) {
                    const conditions = createKeywordwithConditions(editRule.query, checkOperators);
                    setGroups([
                        {
                            id: generateUniqueId(), // Unique identifier for each group
                            conditions: conditions, // Array of conditions in the group
                        },
                    ]);
                }
                // setChosenWords(editRule.query?.replace(/["']/g, "").split(" OR ") || []);
            }

            const filterValues = JSON.parse(editRule.filters).length === 0 ? {} : JSON.parse(editRule.filters);

            setSearchInput(filterValues);

            /**
             * Create searchrule => tender detailpage => widget opportunities
             */
            if (editRule && editRule.id === "newOppFromWidgetOnTenderDetail") {
                setQuery(editRule.query ? editRule.query : "");

                const filterValues = JSON.parse(editRule.filters);
                setSearchInput(filterValues);

                // setStep to 2 -> resultmodal (cant go back)
                setActiveStep(2);
            }

            /**
             * Create opportunity => all opportunity page
             */
            if (editRule && editRule.id === "newOppFromList") {
                // setQuery if user has input otherwise empty string
                setQuery(editRule.query ? editRule.query : "");

                //Empty name -> user can give a name
                setName("");

                // setStep to 1 -> resultmodal (cant go back)
                setActiveStep(1);
            } else {
                setName(editRule.name);

                const filterVal = JSON.parse(editRule.filters).length === 0 ? {} : JSON.parse(editRule.filters);
                setSearchInput(filterVal);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editRule, setActiveStep]);

    /**
     * GQL query to get all opportunities, on complete -> set filters to state and data to another state
     */
    const [run, { loading, error }] = useLazyQuery<GetAllDocsForOppSearchWizard, GetAllDocsForOppSearchWizardVariables>(
        QUERY_ALL_OPPORTUNITY_DOCUMENTS_FOR_WIZARD,
        {
            variables: {
                page: page,
                first: rowsPerPage,
                orderField: orderField,
                order: orderDir,
                search: {
                    query: query,
                    filters: searchInput,
                },
            },
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.filterOpportunityFiles) {
                    setAllData(data);
                    // setLoading(false);
                }
            },
        }
    );

    /**
     * Mutation to save opportunity search
     */
    const [saveOpp, { loading: loadingMutation, error: errorMutation }] = useMutation<AddOpportunitySearch, AddOpportunitySearchVariables>(
        SAVESEARCHRULEOPPORTUNITY
    );
    /**
     * Mutation to update opportunity search
     */
    const [update, { loading: loadingUpdateMutation }] = useMutation<UpdateOpportunitySearch, UpdateOpportunitySearchVariables>(
        UPDATESEARCHRULEOPPORTUNITY
    );

    // Function to save opportunity searchrule
    const saveOppSearch = async () => {
        if (errorMutation) {
        }
        try {
            await saveOpp({
                variables: {
                    name: name,
                    search: {
                        query: query,
                        filters: searchInput,
                    },
                },
                onCompleted: () => {
                    setActiveStep(0);
                    onClose();
                    resetAdvancedQuery();
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
            });
        } catch (e) {}
    };

    /**
     * Function to update an existing searchrule
     */
    const updateSearchRule = async () => {
        if (errorMutation) {
        }
        try {
            await update({
                variables: {
                    id: editRule.id,
                    name: name,
                    search: {
                        query: query,
                        filters: searchInput,
                    },
                },
                onCompleted: () => {
                    setActiveStep(0);
                    onClose();
                    resetAdvancedQuery();
                },

                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
            });
        } catch (e) {}
    };

    /**
     * Handler to update searchrule
     * Check if name of searchrule contains 3 or more characters
     */
    const updateSearchRuleAndClose = async () => {
        if (name.length >= 3) {
            updateSearchRule();
            resetTour();
        }
    };

    const variables = {
        page: page,
        first: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            query: query,
            filters: searchInput,
        },
    };

    /**
     * Run query whenever a state changes
     */
    useEffect(() => {
        // setLoading(false);
        run({
            variables: {
                page: page,
                first: rowsPerPage,
                orderField: orderField,
                order: orderDir,
                search: {
                    query: query,
                    filters: searchInput,
                },
            },
        });
    }, [run, page, rowsPerPage, orderField, orderDir, query, searchInput]);

    const getStepContent = (step: number) => {
        switch (step) {
            default:
                return "Zoekregel opgeslagen!";
            case 0:
                /**
                 * First step: WORDMODAL
                 * Step to add words to basket and add words to query
                 */
                return <WordModalQueryBuilder query={query} setQuery={setQuery} type={"opportunity"} />;
            case 1:
                // show results based on chosen terms (step 1) and chosen domain (step 2)
                return (
                    <React.Fragment>
                        <ResultsModalOpp
                            snippetData={allData?.filterOpportunityFiles?.snippets}
                            oppData={allData?.filterOpportunityFiles?.results?.data}
                            filterData={allData?.filterOpportunityFiles?.filters ? [...allData?.filterOpportunityFiles?.filters] : null}
                            paginatorInfo={allData?.filterOpportunityFiles?.results?.paginatorInfo}
                            loading={loading}
                            error={error}
                            onChange={setSearchInput}
                            filters={searchInput}
                            startSearch={() => {
                                // setLoading(false);
                                run({ variables });
                            }}
                        />
                    </React.Fragment>
                );
            case 2:
                // add option to choose distribution settings
                return <DistModal isAdmin={Boolean(adminCheck)} name={name} setName={setName} type={"opportunity"} color={""} editRule={false} />;
        }
    };

    // Get step titles
    const steps = getSteps();

    // Get total amount of steps
    const totalSteps = () => {
        return steps.length;
    };

    // Get last step
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    /**
     * Reset tour variable
     */
    const resetTour = () => {
        setOpenTour(false);
        setTourVariables([], "#173155", "");
        setCurrentStep(0);
    };

    // Handler to go to next step
    // Check if next step is last step. If so, save mutation and close modal
    const handleNext = () => {
        // If active step is 0, set query to single string with OR operator
        if (activeStep === 0) {
            handleGenerateQuery();

            refetch();
        }

        if (activeStep === 1) {
            // Domain modal
        }

        setStaticLoad(true);
        // reset tour variables to avoid errors
        resetTour();
        // after 400ms go to next step
        setTimeout(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setStaticLoad(false);
        }, 400);
    };

    // Handler to go to previous step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    setTheme("green");

    // SET QUERY TO ADVANCED QUERY STRING
    useEffect(() => {
        if (query !== advancedQueryString) {
            setQuery(advancedQueryString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advancedQueryString]);

    return (
        <ThemeProvider theme={greenTheme}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                {/* Paper element contains activestep */}
                <Paper
                    sx={{
                        height: `calc(93vh - 64px)`,
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f4f4f4",
                    }}
                    elevation={0}
                >
                    <div>{getStepContent(activeStep)}</div>
                </Paper>
                {/* Navigation to switch to different steps.
            - if active step is 3, show button with "save" instead of "next"
            - if active step is 0, dont show "back" button
            */}
                <div style={{ display: "flex", flexDirection: "row-reverse", width: "90%" }}>
                    {!isLastStep() ? (
                        <Button
                            disabled={!areThereAnyWordsSelected() || query !== advancedQueryString || areThereNEARConditionsNotValid()}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            data-tut="reactour__nextBtn"
                        >
                            {`${t("mainModal.Next")}`} {staticLoad && <CircularProgress size={14} sx={{ marginLeft: "4px", color: "#ffffff" }} />}
                        </Button>
                    ) : editRule && editRule.id !== "newOppFromList" && editRule.id !== "newOppFromWidgetOnTenderDetail" ? (
                        /**
                         * If editRule is true, show button with update mutation instead of save mutation.
                         * If name of searchrule is smaller than 3 characters, or no dist.moment is not set or disable save button
                         */
                        <Disable disabled={loadingUpdateMutation || name.length < 3} disabledOpacity={1}>
                            <Button
                                disabled={loadingUpdateMutation || name.length < 3}
                                variant="contained"
                                color="primary"
                                onClick={updateSearchRuleAndClose}
                            >
                                {/* Show loading spinner when saving searchrule */}
                                {loadingUpdateMutation ? (
                                    <>
                                        {`${t("mainModal.Save")}`} <CircularProgress size={14} style={{ marginLeft: 4 }} />
                                    </>
                                ) : (
                                    `${t("mainModal.Save")}`
                                )}
                            </Button>
                        </Disable>
                    ) : (
                        // <Disable  disabledOpacity={1}>

                        <Disable disabled={loadingMutation || name.length < 3} disabledOpacity={1} data-tut="reactour__distSave">
                            <Button
                                disabled={loadingMutation || name.length < 3}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    saveOppSearch();
                                    resetTour();
                                }}
                            >
                                {/* Show loading spinner when saving searchrule */}
                                {loadingMutation ? (
                                    <>
                                        {`${t("mainModal.Save")}`} <CircularProgress size={14} style={{ marginLeft: 4 }} />
                                    </>
                                ) : (
                                    `${t("mainModal.Save")}`
                                )}
                            </Button>
                        </Disable>
                        // </Disable>
                    )}
                    {editRule && editRule.id === "newOppFromList" ? (
                        <Button
                            style={{ display: activeStep === 0 ? "none" : activeStep === 1 ? "none" : "block" }}
                            onClick={handleBack}
                            data-tut="reactour__distSave"
                        >
                            {t("mainModal.Previous")}
                        </Button>
                    ) : editRule && editRule.id === "newOppFromWidgetOnTenderDetail" ? (
                        <Button
                            style={{ display: activeStep === 0 ? "none" : activeStep === 1 ? "none" : activeStep === 2 ? "none" : "block" }}
                            onClick={handleBack}
                        >
                            {t("mainModal.Previous")}
                        </Button>
                    ) : (
                        <Button style={{ display: activeStep === 0 ? "none" : "block" }} onClick={handleBack}>
                            {t("mainModal.Previous")}
                        </Button>
                    )}
                </div>
            </div>
        </ThemeProvider>
    );
};

export default OpportunityBasePlate;
