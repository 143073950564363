import React from "react";
import { useTranslation } from "react-i18next";
import { QUERY_SCOPEVALUES_OF_CURRENT_TENDER } from "../../../graphql/queryDefinitions";
import { useQuery } from "@apollo/client";
import { GetScopeValuesOfCurrentTender, GetScopeValuesOfCurrentTenderVariables } from "../../../__generated__/GetScopeValuesOfCurrentTender";
import { Disable } from "react-disable";
import WidgetBox from "../../WidgetBox";
import ScopeWidget from "../../ScopeModule/ScopeWidget";

interface Props {
    tender_id: string;
}

const TenderScopeWidget: React.FC<Props> = ({ tender_id }) => {
    const { t } = useTranslation();

    const { loading, error, data } = useQuery<GetScopeValuesOfCurrentTender, GetScopeValuesOfCurrentTenderVariables>(
        QUERY_SCOPEVALUES_OF_CURRENT_TENDER,
        {
            variables: { id: tender_id },
        }
    );

    if (loading)
        return (
            <Disable disabled={true}>
                <WidgetBox loading={true} header={t("tenderPage.Scope")} highlight="blue" padding={0} />
            </Disable>
        );

    if (error || !data || !data.tender)
        return (
            <Disable disabled={true}>
                <WidgetBox header={t("tenderPage.Scope")} highlight="blue" padding={0} />
            </Disable>
        );

    const tender = data.tender;

    return tender.scope !== null ? (
        <ScopeWidget scope={tender.scope} lots={tender.lots} tender_id={tender.id} />
    ) : (
        <Disable disabled={true}>
            <WidgetBox header={t("tenderPage.Scope")} highlight="blue" padding={0} />
        </Disable>
    );
};

export default TenderScopeWidget;
