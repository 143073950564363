import * as React from "react";
import { MUIDataTableColumn } from "mui-datatables";
import secureLocalStorage from "react-secure-storage";

type SkuId = string;
type Quantity = boolean;
type Basket = Record<SkuId, Quantity>;
type Product = MUIDataTableColumn;
type AddItemParams = { id: SkuId; quantity?: Quantity };

type BasketContextType = {
    basket: Basket;
    addItem: (params: AddItemParams) => void;
};

type Actions = { type: "ADD_ITEM" } & AddItemParams;

const BasketContext = React.createContext<BasketContextType>({
    basket: {},
    addItem: () => null,
});

const basketReducer = (state: Basket, action: Actions): Basket => {
    switch (action.type) {
        case "ADD_ITEM":
            return {
                ...state,
                [action.id]: !state[action.id],
            };
        default:
            return state;
    }
};

export function ColumnProvider({
    children,
    storeKey = "@local-basket",
    defaultState,
}: {
    children: React.ReactNode;
    storeKey?: string;
    defaultState: MUIDataTableColumn[];
}) {
    const localData = React.useRef(secureLocalStorage.getItem(storeKey));

    /**
     * Set initial state from local storage or default state
     * defaultstate given from parent component
     */
    const [basket, dispatch] = React.useReducer(
        basketReducer,
        localData.current ? JSON.parse(localData.current as string) : defaultState.map((item) => item.options?.display)
    );

    /**
     * callback to change boolean of column to true/false
     */
    const addItem = React.useCallback<BasketContextType["addItem"]>(
        (params) => {
            dispatch({ type: "ADD_ITEM", ...params });
        },
        [dispatch]
    );

    /**
     * Set local storage with data in basket
     * storeKey given from parent component, if not set key to "@local-basket"
     */
    React.useEffect(() => {
        secureLocalStorage.setItem(storeKey, JSON.stringify(basket));
    }, [basket, storeKey]);

    return <BasketContext.Provider value={{ basket, addItem }}>{children}</BasketContext.Provider>;
}

export function useColumns() {
    return React.useContext(BasketContext);
}

export function useSelectedColumns(products: Product[]) {
    const { basket } = useColumns();
    const items = React.useMemo(() => {
        return Object.keys(basket).map((productId) => {
            const toInt = parseInt(productId);
            const product = products[toInt];
            const quantity = basket[toInt];
            return {
                ...product,
                options: {
                    ...product.options,
                    display: quantity,
                },
            };
        });
    }, [products, basket]);

    return { items };
}
