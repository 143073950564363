import React from "react";
import { Typography } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props {
    /**
     * Date string
     */
    date: string;
    /**
     * Index number
     */
    index: number;
}

/**
 * @returns return date or string for each date group
 */
const DateDivider: React.FC<Props> = ({ date, index }) => {
    const { t } = useTranslation();

    /**
     * function to get todat and yesterday as string instead of date
     */
    const REFERENCE = moment(); // fixed just for testing, use moment();
    const TODAY = REFERENCE.clone().startOf("day").format("L");
    const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day").format("L");
    /**
     * @param momentDate date to check
     * @returns boolean if given date is today
     */
    const isToday = (momentDate: any) => {
        const dateToCheck = moment(momentDate);
        return dateToCheck.isSame(TODAY, "d");
    };

    /**
     * @param momentDate date to check
     * @returns boolean if given date is yesterday
     */
    const isYesterday = (momentDate: any) => {
        const dateToCheck = moment(momentDate);
        return dateToCheck.isSame(YESTERDAY, "d");
    };

    /**
     * return date or string
     */
    return (
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ height: "1px", backgroundColor: "#e1e1e1", width: "125px" }} />
            <div style={{ padding: "4px 8px ", borderRadius: "4px" }} key={index}>
                {/* If date is today -> show text 'today' */}
                {isToday(moment(date)) ? (
                    <Typography variant="caption" sx={{ color: "#cccccc" }}>
                        {t("Today")}
                    </Typography>
                ) : // If date is yesterdat -> show text 'yesterday'
                isYesterday(moment(date)) ? (
                    <Typography variant="caption" sx={{ color: "#cccccc" }}>
                        {t("Yesterday")}
                    </Typography>
                ) : (
                    // Else show date
                    <Typography variant="caption" sx={{ color: "#cccccc" }}>
                        {date}
                    </Typography>
                )}
            </div>
            <div style={{ height: "1px", backgroundColor: "#e1e1e1", width: "125px" }} />
        </div>
    );
};

export default DateDivider;
