import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, List, ListItem } from "@mui/material";
import { DateRange } from "./TopFilterBar";
import moment from "moment";

interface Props {
    setDateRange(dateRange: DateRange): void;
}

const FutureDates: React.FC<Props> = ({ setDateRange }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Typography style={{ paddingLeft: 16 }}>Opties:</Typography>
            <List>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({ from: moment(), to: moment() });
                    }}
                >
                    {t("moments.Today")}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({ from: moment().add(1, "days"), to: moment().add(1, "days") });
                    }}
                >
                    {t("moments.Tomorrow")}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({ from: moment(), to: moment().add(7, "days") });
                    }}
                >
                    {t("moments.Next7days")}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({ from: moment(), to: moment().add(31, "days") });
                    }}
                >
                    {t("moments.Next30days")}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({
                            from: moment().quarter(moment().quarter()).startOf("quarter"),
                            to: moment().quarter(moment().quarter()).endOf("quarter").add(1, "days"),
                        });
                    }}
                >
                    {t("moments.Current_quarter")}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({
                            from: moment()
                                .quarter(moment().quarter() + 1)
                                .startOf("quarter"),
                            to: moment()
                                .quarter(moment().quarter() + 1)
                                .endOf("quarter")
                                .add(1, "days"),
                        });
                    }}
                >
                    {t("moments.Next_quarter")}
                </ListItem>
            </List>
        </React.Fragment>
    );
};

export default FutureDates;
