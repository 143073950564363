import React, { useEffect, useState } from "react";
import { Dialog, Typography, IconButton, Toolbar, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Chat } from "@mui/icons-material";
import { Link } from "react-router-dom";

// GQL
import { useMutation, useQuery } from "@apollo/client";
import DialogContent from "./DialogContent";
import { GetConversationGroup, GetConversationGroupVariables } from "../../__generated__/GetConversationGroup";
import { colorOfDialog, getConversationWithLatestMessage, isNotPrivateConversation, isPrivateConversation } from "../../utils/messenger";
import { GetCaOfTenderChatToOpen, GetCaOfTenderChatToOpenVariables } from "../../__generated__/GetCaOfTenderChatToOpen";
import { GetCaOfOpportunityToOpen, GetCaOfOpportunityToOpenVariables } from "../../__generated__/GetCaOfOpportunityToOpen";
import SearchRuleData from "../SearchRuleData";
import TooltipOnNewLines from "../TooltipOnNewLines";
import SearchRuleDataOpportunity from "../SearchRuleDataOpportunity";
import { setChatMessageAsRead, setChatMessageAsReadVariables } from "../../__generated__/setChatMessageAsRead";
import { toast } from "react-toastify";
import { QUERY_ORG_STATE_FOR_USER } from "../../routes/organizations/detailPage/OrgTopBarModule";
import { QUERY_OPP_STATE_FOR_USER } from "../../routes/opportunities/detailpage/OppDetailTopBar";
import { QUERY_CA_STATE_FOR_USER } from "../../routes/contractingAuthoritiesModule/detailpage/CATopBarModule";
import { QUERY_TENDER_STATE_FOR_USER } from "../../graphql/queryDefinitions";
import {
    MARKMESSAGEASREAD,
    QUERY_CA_OF_OPPORTUNITY_TO_OPEN,
    QUERY_CA_OF_TENDERCHAT_TO_OPEN,
    QUERY_CONVERSATION_GROUP,
} from "../../graphql/queryDefChat";
import { GET_AMOUNT_OF_UREAD_NOTIFICATIONS_CURRENT_USER, GET_NOTIFICATIONS_CURRENT_USER } from "../../graphql/queryDefCurrentUser";
import { MARK_NOTIFICATION_AS_READ } from "../notifications/NotificationsMenu";
import { markNotificationsAsRead, markNotificationsAsReadVariables } from "../../__generated__/markNotificationsAsRead";
import { useSocket } from "../../utils/websocketChannels";
import { useTenderChat } from "../contextProviders/TenderChatContext";
import { useUserId } from "../contextProviders/UserIdContext";

export interface TenderNoteMessage {
    content: string;
    position: "left" | "right";
    conversation: string;
    sendAt: Date;
}

interface Props {
    open: boolean;
    handleClose(): void;
    modelType: string;
    modelId: string;
    chat_title: string | null;
    conversationIdToOpen: string;
}

export interface activeConversation {
    id: string | null;
    private: boolean;
}

export interface UserInTeam {
    __typename?: "User" | undefined;
    id: string;
    employee: Employee;
}

export interface Employee {
    __typename?: "Employee" | undefined;
    id: string;
    name?: string | null | undefined;
    middlename?: string | null | undefined;
    familyname?: string | null | undefined;
    email?: string | null | undefined;
}

const TenderNoteDialog: React.FC<Props> = ({ open, handleClose, modelId, modelType, chat_title, conversationIdToOpen }) => {
    const black = "#000000";
    const [conversation, setConversation] = useState<activeConversation | null>(null);
    const [staticLoading, setStaticLoading] = useState<boolean>(false);
    const [markMessageAsRead] = useMutation<setChatMessageAsRead, setChatMessageAsReadVariables>(MARKMESSAGEASREAD);
    const [read_single, { error: errorSingle, loading: loadingSingle }] = useMutation<markNotificationsAsRead, markNotificationsAsReadVariables>(
        MARK_NOTIFICATION_AS_READ
    );

    // Function to set notification to read
    const markSingleNotification = async (id: string) => {
        if (loadingSingle) return;
        if (errorSingle) {
        }
        try {
            await read_single({
                variables: {
                    id: id,
                },
                // Refetch get_dashboard_worklist to update the worklist
                refetchQueries: [GET_NOTIFICATIONS_CURRENT_USER, GET_AMOUNT_OF_UREAD_NOTIFICATIONS_CURRENT_USER],
                awaitRefetchQueries: true,
            });
        } catch (e) {}
    };

    /**
     * Handler to set message or conversation to read
     * @param messageID id of single message
     * @param conversationID id of whole conversation
     */
    const markAsRead = async (messageID: string | null, conversationID: string | null) => {
        const varToUse = messageID !== null && messageID !== undefined ? { message_id: messageID } : { conversation_id: conversationID };
        try {
            await markMessageAsRead({
                variables: varToUse,
                refetchQueries: [
                    QUERY_CONVERSATION_GROUP,
                    "GetConversationGroup",
                    QUERY_TENDER_STATE_FOR_USER,
                    "GetTenderStateForUser",
                    QUERY_ORG_STATE_FOR_USER,
                    QUERY_OPP_STATE_FOR_USER,
                    QUERY_CA_STATE_FOR_USER,
                ],
            });
        } catch (e) {
            toast.error(`Er ging iets mis ${(varToUse.conversation_id, varToUse.message_id)}`, { autoClose: false });
        }
    };

    useEffect(() => {
        if (conversationIdToOpen !== "") {
            setConversation({ id: conversationIdToOpen, private: false });
            markAsRead(null, conversationIdToOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationIdToOpen]);

    /**
     * Fetch contracting authority for tender
     * show in topbar
     */
    const getCaOfTender = useQuery<GetCaOfTenderChatToOpen, GetCaOfTenderChatToOpenVariables>(QUERY_CA_OF_TENDERCHAT_TO_OPEN, {
        variables: { tender_id: modelId },
        fetchPolicy: "network-only",
        skip: !modelType.includes("Tender"),
    });

    const { conversationIdToOpen: cID } = useTenderChat();
    const { userId } = useUserId();

    /**
     * Whenever new notifications are published on the socket channel,
     * fetch notifications
     */
    useSocket(`user.notifications.${userId}`, ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated", (e) => {
        console.log("conversationIdToOpen", conversationIdToOpen, e.conversation_hash);

        // Check if conversationIdToOpen is not null and equals the notification's hash
        if (cID && cID === e.conversation_hash) {
            // Mark the notification as read
            markSingleNotification(e.conversation_hash);
        }
    });

    /**
     * Fetch contracting authority for opportunity file
     * show in topbar
     */
    const getCaOfOpportunity = useQuery<GetCaOfOpportunityToOpen, GetCaOfOpportunityToOpenVariables>(QUERY_CA_OF_OPPORTUNITY_TO_OPEN, {
        variables: { id: modelId },
        fetchPolicy: "network-only",
        skip: !modelType.includes("Opportunity"),
    });

    /**
     * Query all conversation of clicked chat
     * Chat has modeltype (module) and modelid (id of clicked chat)
     */
    const convGrp = useQuery<GetConversationGroup, GetConversationGroupVariables>(QUERY_CONVERSATION_GROUP, {
        variables: { id: modelId, modelType },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (conversation === null && data.conversationGroup.conversations.length > 0 && conversationIdToOpen === "") {
                const chatIdWithLatestMessage = getConversationWithLatestMessage(data.conversationGroup.conversations);

                const idToOpen =
                    chatIdWithLatestMessage ||
                    data.conversationGroup.conversations.find((i) => isNotPrivateConversation(i))?.id ||
                    data.conversationGroup.conversations.find((i) => isPrivateConversation(i))?.id ||
                    data.conversationGroup.conversations[0].id;

                setConversation({ id: idToOpen, private: false });
                markAsRead(null, idToOpen);
            }
        },
    });

    /**
     * Function to close modal
     * Check if handler is set
     * Set conversation back to 'null'
     */
    const onClose = () => {
        setConversation(null);
        setStaticLoading(false);
        if (handleClose) {
            handleClose();
            setConversation(null);
            setStaticLoading(false);
        }
    };
    /**
     * @param modelType Model that is used to fetch the right conversation
     * @returns Title and secondary prop when requested
     */
    const titleToShow = (modelType: string) => {
        switch (true) {
            // Tender
            case modelType.includes("Tender"):
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Link to={`/tenders/${modelId}`} style={{ color: colorOfDialog(modelType) }}>
                            <TooltipOnNewLines
                                maxWidth="80vw"
                                placement={"top"}
                                text={chat_title || ""}
                                variant={"body2"}
                                lines={1}
                                id={`TE${modelId as string}`}
                                fontweight={"normal"}
                                fontSize={14}
                            />
                        </Link>
                        {/* CA of tender */}
                        {getCaOfTender.loading ? (
                            <Skeleton variant="text" width={100} sx={{ fontSize: "0.75rem", lineHeight: 1.66 }} />
                        ) : (
                            <Typography variant="caption">
                                {getCaOfTender.data?.tender?.clients.find((ca) => ca.pivot?.category === "A")?.name ||
                                    getCaOfTender.data?.tender?.published_opdrachtgever ||
                                    ""}
                            </Typography>
                        )}
                    </div>
                );

            // Opportunity
            case modelType.includes("Opportunity"):
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Link to={`/opportunities/${modelId}`} style={{ color: colorOfDialog(modelType) }}>
                            <TooltipOnNewLines
                                maxWidth="80vw"
                                placement={"top"}
                                text={chat_title || ""}
                                variant={"body2"}
                                lines={1}
                                id={`TE${modelId as string}`}
                                fontweight={"normal"}
                                fontSize={14}
                            />
                        </Link>
                        {/* ca of opportunity file */}
                        {getCaOfOpportunity.loading ? (
                            <Skeleton variant="text" width={100} sx={{ fontSize: "0.75rem", lineHeight: 1.66 }} />
                        ) : (
                            <Typography variant="caption">{getCaOfOpportunity.data?.opportunityFile?.contractingAuthority.name || ""}</Typography>
                        )}
                    </div>
                );

            // MarketParty
            case modelType.includes("MarketParty"):
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Link to={`/organizations/${modelId}`} style={{ color: colorOfDialog(modelType) }}>
                            <TooltipOnNewLines
                                maxWidth="80vw"
                                placement={"top"}
                                text={chat_title || ""}
                                variant={"body2"}
                                lines={1}
                                id={`TE${modelId as string}`}
                                fontweight={"normal"}
                                fontSize={14}
                            />
                        </Link>
                    </div>
                );

            // ContractingAuthority
            case modelType.includes("ContractingAuthority"):
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Link to={`/contracting-authorities/${modelId}`} style={{ color: colorOfDialog(modelType) }}>
                            <TooltipOnNewLines
                                maxWidth="80vw"
                                placement={"top"}
                                text={chat_title || ""}
                                variant={"body2"}
                                lines={1}
                                id={`TE${modelId as string}`}
                                fontweight={"normal"}
                                fontSize={14}
                            />
                        </Link>
                    </div>
                );

            // When error occurs/ another modeltype is requested => show nothing
            default:
                return <></>;
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: "85%",
                    height: "80vh",
                },
            }}
        >
            {/*
             * Topbar of chatbox
             * Includes:
             * - Tendertitle/ name of org/ name of ca/ name of opportunity
             * - Closebutton
             */}
            <Toolbar
                sx={{
                    color: black,
                    borderBottom: `4px solid ${colorOfDialog(modelType)}`,
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                }}
                disableGutters
            >
                <div style={{ flexGrow: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Chat style={{ marginRight: 8, color: colorOfDialog(modelType) }} />
                    {/*
                     * Switchcase to show title component based on param modelType
                     */}
                    {titleToShow(modelType)}
                </div>
                {/*
                 * Conversation name like private notes, team.. or name of colleague + Closebutton to close modal
                 */}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {modelType.includes("Tender") && (
                        <SearchRuleData placement={"left"} tender_id={modelId} marginRight="8px" searches={[]} iconSize="small" />
                    )}

                    {modelType.includes("Opportunity") && (
                        <SearchRuleDataOpportunity opp_id={modelId} iconSize="small" placement={"top"} marginRight="8px" />
                    )}
                    <IconButton style={{ marginLeft: "4px" }} onClick={onClose} size="small">
                        <CloseIcon htmlColor={colorOfDialog(modelType)} />
                    </IconButton>
                </div>
            </Toolbar>

            {/*
             * Component to render userlist and conversation
             * Includes switch to show loadanimation / error message(s) or data
             */}
            <DialogContent
                convGrp={convGrp}
                conversation={conversation}
                setConversation={setConversation}
                modelType={modelType}
                staticLoading={staticLoading}
                setStaticLoading={setStaticLoading}
            />
        </Dialog>
    );
};

export default TenderNoteDialog;
