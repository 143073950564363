import React from "react";
import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Typography,
} from "@mui/material";
import { DistributionIntervalInput, MarketPartiesModulePreferences } from "../../../__generated__/globalTypes";
import { Mail, Notifications, Star } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";

interface Props {
    settings: MarketPartiesModulePreferences;
    saveSettings: (settings: MarketPartiesModulePreferences) => void;
    setChanged: (changed: boolean) => void;
}

const OrganizationSettingsMobile: React.FC<Props> = ({ settings, saveSettings, setChanged }) => {
    const { t } = useTranslation();
    const pink = "#E03660";

    return (
        <List sx={{ width: "100%", padding: 0 }}>
            <Typography variant="h3" sx={{ display: "flex", alignItems: "center" }}>
                <Star fontSize="small" sx={{ marginRight: "8px", color: pink }} /> {t("Notifications")}
            </Typography>

            <Typography variant="caption">Bepaal jouw gewenste notificaties</Typography>
            <Paper variant="outlined" sx={{ padding: "8px" }}>
                <Typography sx={{ fontWeight: "bold" }}>Gunningsinformatie bekend</Typography>

                {settings?.notifications?.map((setting, i) => {
                    return (
                        <>
                            {/*********************
                             ***    NOTIFICATIONS
                             **********************/}
                            <ListItem key={setting?.event} sx={{ padding: 0 }}>
                                <ListItemIcon style={{ minWidth: 0, marginRight: "8px" }}>
                                    <Notifications fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={"Melding"} />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <FormControlLabel
                                        sx={{
                                            marginLeft: 0,
                                            marginRight: 0,
                                        }}
                                        control={
                                            <Checkbox
                                                size="small"
                                                disabled
                                                checked={setting?.notification}
                                                onChange={(e) => {
                                                    saveSettings(
                                                        update(settings, {
                                                            notifications: {
                                                                [i]: {
                                                                    notification: { $set: e.target.checked },
                                                                },
                                                            },
                                                        })
                                                    );
                                                    setChanged(true);
                                                }}
                                                name={setting?.event}
                                            />
                                        }
                                        label=""
                                    />
                                </div>
                            </ListItem>

                            <Divider />

                            {/*********************
                             ***    MAIL
                             **********************/}
                            <ListItem sx={{ padding: 0 }}>
                                <ListItemIcon style={{ minWidth: 0, marginRight: "8px" }}>
                                    <Mail fontSize="small" />
                                </ListItemIcon>
                                <ListItemText id="switch-list-label-wifi" primary={"Mail"} />
                                <FormControlLabel
                                    sx={{ marginLeft: 0, marginRight: 0 }}
                                    control={
                                        <>
                                            {setting?.email === true && (
                                                <FormControl variant="standard" margin="dense">
                                                    <Select
                                                        disableUnderline
                                                        labelId="demo-simple-select-outlined-label"
                                                        defaultValue={setting?.emailInterval || DistributionIntervalInput.DAILY}
                                                    >
                                                        <MenuItem
                                                            value={"HOURLY"}
                                                            onClick={(e) => {
                                                                saveSettings(
                                                                    update(settings, {
                                                                        notifications: {
                                                                            [i]: {
                                                                                emailInterval: { $set: DistributionIntervalInput.HOURLY },
                                                                            },
                                                                        },
                                                                    })
                                                                );
                                                                setChanged(true);
                                                            }}
                                                        >
                                                            Direct
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"DAILY"}
                                                            onClick={(e) => {
                                                                saveSettings(
                                                                    update(settings, {
                                                                        notifications: {
                                                                            [i]: {
                                                                                emailInterval: { $set: DistributionIntervalInput.DAILY },
                                                                            },
                                                                        },
                                                                    })
                                                                );
                                                                setChanged(true);
                                                            }}
                                                        >
                                                            Dagelijks
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"WEEKLY"}
                                                            onClick={(e) => {
                                                                saveSettings(
                                                                    update(settings, {
                                                                        notifications: {
                                                                            [i]: {
                                                                                emailInterval: { $set: DistributionIntervalInput.WEEKLY },
                                                                            },
                                                                        },
                                                                    })
                                                                );
                                                                setChanged(true);
                                                            }}
                                                        >
                                                            Wekelijks
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"MONTHLY"}
                                                            onClick={(e) => {
                                                                saveSettings(
                                                                    update(settings, {
                                                                        notifications: {
                                                                            [i]: {
                                                                                emailInterval: { $set: DistributionIntervalInput.MONTHLY },
                                                                            },
                                                                        },
                                                                    })
                                                                );
                                                                setChanged(true);
                                                            }}
                                                        >
                                                            Maandelijks
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )}
                                            <Checkbox
                                                size="small"
                                                checked={setting?.email}
                                                onChange={(e) => {
                                                    saveSettings(
                                                        update(settings, {
                                                            notifications: {
                                                                [i]: {
                                                                    email: { $set: e.target.checked },
                                                                },
                                                            },
                                                        })
                                                    );
                                                    setChanged(true);
                                                }}
                                                name={setting?.event}
                                            />
                                        </>
                                    }
                                    label=""
                                />
                            </ListItem>
                        </>
                    );
                })}
            </Paper>
        </List>
    );
};

export default OrganizationSettingsMobile;
