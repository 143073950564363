import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { profileLinks } from "../../../routes/Profile";
import { useNavigate } from "react-router-dom";
import { Divider, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaviconBox from "../../FaviconBox";
import MatomoEvent from "../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    anchorEl: Element | null;
    opened: boolean;
    handleClose(): void;
    handleLogout(): void;
    givenName: string | null;
    middleName: string | null;
    familyName: string | null;
    logo: string | null;
    favicon: string | null;
    org_name: string | null;
}

// const StyledBadge = withStyles((theme: Theme) =>
//     createStyles({
//         badge: {
//             right: -3,
//             top: 3,
//             height: 6,
//             minWidth: 6,
//         },
//     })
// )(Badge);

const ProfileMenu: React.FC<Props> = ({
    anchorEl,
    opened,
    handleClose,
    handleLogout,
    givenName,
    familyName,
    middleName,
    logo,
    favicon,
    org_name,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const { trackEvent } = useMatomo();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // length of person's full name
    const nameLength = (givenName?.length || 0) + (middleName?.length || 0) + (familyName?.length || 0);
    const org_name_length = org_name?.length || 0;
    /**
     * Track clicked menu items
     * @param event clicked menu item
     */
    const trackNavigation = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <Menu
            sx={{
                "& .MuiMenu-root": { width: "230px" },
                "& .MuiMenu-list": { width: "230px" },
            }}
            anchorEl={anchorEl}
            keepMounted
            open={opened && Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: onlyMobile ? "bottom" : "bottom",
                horizontal: onlyMobile ? "left" : 70,
            }}
            transformOrigin={{
                vertical: onlyMobile ? -20 : "bottom",
                horizontal: onlyMobile ? -170 : "left",
            }}
        >
            <div style={{ width: "100%" }}>
                <div style={{ padding: 8, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <FaviconBox favicon={favicon !== null ? `${favicon}` : ""} name={org_name} borderRadius={0} />

                    <div style={{ width: 180 }}>
                        {org_name_length > 20 ? (
                            <Tooltip placement="bottom-start" title={org_name as string} enterDelay={100}>
                                <Typography noWrap style={{ width: "100%", fontWeight: 500 }}>
                                    {org_name}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography noWrap style={{ width: "100%" }}>
                                {org_name}
                            </Typography>
                        )}
                        {nameLength > 20 ? (
                            <Tooltip
                                placement="bottom-start"
                                title={`${givenName} ${middleName ? middleName + " " : ""}${familyName}`}
                                enterDelay={100}
                            >
                                <Typography noWrap style={{ width: "100%", fontWeight: 500 }}>
                                    {givenName} {middleName} {familyName}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography noWrap style={{ width: "100%", fontWeight: 500 }}>
                                {givenName} {middleName} {familyName}
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
            <Divider />

            {profileLinks.map((route) => (
                <MenuItem
                    key={route.route}
                    onClick={() => {
                        navigate(route.route);
                        trackNavigation({ category: route.category as MatomoEvent["category"], action: route.action });
                        handleClose();
                    }}
                    style={{ justifyContent: "space-between", paddingRight: "8px" }}
                >
                    {/* TODO: v4.x dots */}
                    {/* {route.route === "/profile/personal" ? (
                        <StyledBadge color="error" variant="dot">
                            <>{t(`${route.label}`)}</>
                        </StyledBadge>
                    ) : route.route === "/profile/search-rules" ? (
                        <StyledBadge color="error" variant="dot">
                            <>{t(`${route.label}`)}</>
                        </StyledBadge>
                    ) : ( */}
                    <>{t(`${route.label}`)}</>
                    {/* )} */}
                </MenuItem>
            ))}

            <Divider />
            <MenuItem
                onClick={() => {
                    trackNavigation({ category: "Personal", action: "Click-logout" });
                    handleLogout();
                    handleClose();
                }}
            >
                Log uit
            </MenuItem>
        </Menu>
    );
};

export default ProfileMenu;
