import React from "react";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    update_text: string;
    tender_name: string;
    tender_id: string;
}

export const TenderTitleWidthAndUpdate: React.FC<Props> = ({ tender_id, tender_name, update_text }) => {
    const { trackEvent } = useMatomo();

    // Track click to tender detailpage
    const clickToTenderDetailPage = () => {
        trackEvent({ category: "Dashboard", action: "worklist-to-tender-detailpage" });
    };

    return (
        <>
            {/*return tendertitle and second update.*/}
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* link to tenderdetailpage*/}

                    <Tooltip title={update_text} placement="right">
                        <Link
                            style={{
                                maxHeight: "3em",
                                lineHeight: "1.5em",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                width: "fit-content",
                            }}
                            id="tender-title"
                            to={`/tenders/${tender_id}`}
                            onClick={(e) => {
                                // Prevent the row iself being clicked,
                                e.stopPropagation();
                                clickToTenderDetailPage();
                            }}
                        >
                            {update_text}
                        </Link>
                    </Tooltip>

                    <Tooltip title={tender_name} placement="right">
                        <Typography style={{ display: "inline-flex" }}>
                            <Link
                                style={{
                                    maxHeight: "1.5em",
                                    lineHeight: "1.5em",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    width: "fit-content",
                                    color: "#707070",
                                }}
                                id="tender-title"
                                to={`/tenders/${tender_id}`}
                                onClick={(e) => {
                                    // Prevent the row iself being clicked,
                                    e.stopPropagation();
                                    clickToTenderDetailPage();
                                }}
                            >
                                {tender_name}
                            </Link>
                        </Typography>
                    </Tooltip>

                    {/* if ca has an id, show link to page.*/}
                </div>
            </div>
        </>
    );
};

export default TenderTitleWidthAndUpdate;
