import { useQuery } from "@apollo/client";
import React from "react";
import {
    GetConversation_Unread_Messages_Counter,
    GetConversation_Unread_Messages_CounterVariables,
} from "../../__generated__/GetConversation_Unread_Messages_Counter";
import { QUERY_CONVERSATION_UNREAD_MESSAGES_COUNT } from "./LastMessageToShow";

interface Props {
    conv_id: string;
    modelType: string;
}

const UnreadMessagesCounter: React.FC<Props> = ({ conv_id, modelType }) => {
    const grey = "#ffffff";
    const tenderBlue = "#173357";
    const yellow = "#F57117";
    const green = "#225E4D";
    const pink = "#E03660";

    /**
     * Query messages of active conversation
     */
    const { data, loading } = useQuery<GetConversation_Unread_Messages_Counter, GetConversation_Unread_Messages_CounterVariables>(
        QUERY_CONVERSATION_UNREAD_MESSAGES_COUNT,
        {
            variables: { id: conv_id },
            fetchPolicy: "network-only",
        }
    );
    /**
     * Load sate
     */
    if (loading) return <></>;

    /**
     * Show empty div when count = 0
     */
    if (data?.conversation.unreadChatMessages.length === 0) {
        return <></>;
    }

    return (
        <div
            style={{
                height: "20px",
                minWidth: "20px",
                maxWidth: "20px",
                borderRadius: "50%",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.75rem",
                backgroundColor: modelType.includes("Tender")
                    ? tenderBlue
                    : modelType.includes("Opportunity")
                    ? green
                    : modelType.includes("MarketParty")
                    ? pink
                    : modelType.includes("ContractingAuthority")
                    ? yellow
                    : grey,
            }}
        >
            {data?.conversation.unreadChatMessages.length}
        </div>
    );
};

export default UnreadMessagesCounter;
