import React from "react";
import Typography from "@mui/material/Typography";
import MainLayout from "../components/layout/MainLayout";
import checkAuth from "../utils/checkAuth";
import setTheme from "../utils/setTheme";
import Div404 from "./Div404";
import { Link } from "react-router-dom";

interface Props {
    url: string;
    admin?: boolean | null;
}

const NotFound404: React.FC<Props> = ({ url, admin }) => {
    setTheme("darkGray");

    return (
        <React.Fragment>
            {checkAuth() && admin === undefined ? (
                <MainLayout page="404">
                    <Div404 />
                </MainLayout>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100vw",
                        height: "100vh",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30%",
                            width: 500,
                        }}
                    >
                        <Typography variant="h1">Niet gevonden!</Typography>
                        <Typography style={{ margin: "16px 0" }}>404 - pagina niet gevonden.</Typography>
                        <Link to={"/login"}>Naar loginpagina</Link>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default NotFound404;
