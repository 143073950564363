import React from "react";
import { gql, useQuery } from "@apollo/client";
import { GetOppSearchesInfo, GetOppSearchesInfoVariables } from "../__generated__/GetOppSearchesInfo";
import { Tooltip } from "@mui/material";
import { FindInPage } from "@mui/icons-material";

interface Props {
    opp_id: string;
    iconSize?: "small" | "medium" | "inherit" | "large";
    marginRight?: string;
    placement:
        | "bottom"
        | "left"
        | "right"
        | "top"
        | "bottom-end"
        | "bottom-start"
        | "left-end"
        | "left-start"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | undefined;
}

const SearchRuleDataOpportunity: React.FC<Props> = ({ opp_id, placement, marginRight, iconSize = "medium" }) => {
    const { loading, error, data } = useQuery<GetOppSearchesInfo, GetOppSearchesInfoVariables>(QUERY_OPPORTUNITY_SEARCHES, {
        variables: { id: opp_id },
        fetchPolicy: "network-only",
    });

    if (loading || error) {
        return <></>;
    }

    const searchRuleData = data?.opportunityFile?.personalResults;

    /**
     * create new array without duplicates
     * based on searchrule id
     */
    const uniqueSearchIds = [...new Map(searchRuleData?.map((m) => [m?.search?.id, m])).values()];

    return (
        <React.Fragment>
            {uniqueSearchIds?.map((d, i) => {
                return (
                    <Tooltip key={i} placement={placement} title={d?.search?.name as string}>
                        <FindInPage
                            fontSize={iconSize}
                            style={{ color: "#225E4D", cursor: "pointer", marginRight: i === uniqueSearchIds.length - 1 ? marginRight : 0 }}
                        />
                    </Tooltip>
                );
            })}
        </React.Fragment>
    );
};

export default SearchRuleDataOpportunity;

export const QUERY_OPPORTUNITY_SEARCHES = gql`
    query GetOppSearchesInfo($id: ID!) {
        opportunityFile(id: $id) {
            id
            # searchRule results
            personalResults {
                id
                search {
                    id
                    name
                    query
                    filters
                }
            }
        }
    }
`;
