import { Button, DialogActions, DialogContent, Dialog } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
    a?: number;
    onClose(): void;
    open: boolean;
    emailURL: string;
}

const EmailModal: React.FC<Props> = ({ emailURL, onClose, open }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContent>
                <iframe src={emailURL} frameBorder="0" style={{ height: "1000px", width: "100%" }} title="test" />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("advancedSearch.Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailModal;
