import React from "react";
import { Avatar, Link, Paper, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { Person } from "@mui/icons-material";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineItem as MuiTimelineItem,
} from "@mui/lab";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { GetUserLogsWithFilters_activities_data } from "../../../__generated__/GetUserLogsWithFilters";

interface Props {
    item: any;
    log: GetUserLogsWithFilters_activities_data;
}

/**
 * This is used for the advanced search
 * timeline component adds this styling automatically,
 * Now its overruled so its aligned on the left side.
 */
const TimelineItem = withStyles(MuiTimelineItem, {
    missingOppositeContent: {
        "&:before": {
            display: "none",
        },
    },
    content: {
        flex: 20,
    },
    oppositeContent: {
        flex: 3,
    },
});

const StyledLink = styled(Link)`
    cursor: pointer;
    color: #000000;
    text-decoration: underline;
    &:hover {
        background-color: #0000001a;
    }
`;

const LogDescriptionDeleted: React.FC<Props> = ({ log, item }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            {isMobile ? (
                <Paper variant="outlined" sx={{ padding: "8px", marginTop: "8px", display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                        <Person />
                    </Avatar>
                    <div>
                        {/* TEXT FOR EVERY ITEM DEFINED */}
                        <Typography style={{ display: "inline-block" }}>
                            <span>
                                {log?.causer?.employee.givenname} {t("has")}
                            </span>{" "}
                            {/*
                             * Link to page
                             * map item name -> searchrulename
                             */}
                            <StyledLink> </StyledLink> {log.description !== null && <span>{t(`log.${log.description as string}`)} </span>}
                        </Typography>
                        {/* TIME */}
                        <Typography variant="caption" color="textSecondary">
                            {moment(log.created_at).format("LL")}, {moment(log.created_at).format("LT")}
                        </Typography>
                    </div>
                </Paper>
            ) : (
                <TimelineItem>
                    <TimelineOppositeContent sx={{ flex: 3 }}>
                        {/* Date format */}
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LL")}
                        </Typography>
                        {/* Time format */}
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LT")}
                        </Typography>
                    </TimelineOppositeContent>
                    {/* Icon to show in dot */}
                    <TimelineSeparator>
                        <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                            <Person />
                        </TimelineDot>

                        <TimelineConnector />
                    </TimelineSeparator>

                    {/* Contentbox to show a logmessage */}
                    <TimelineContent sx={{ flex: 20 }}>
                        <Paper elevation={1} sx={{ padding: "6px 16px", marginTop: "3px" }}>
                            {/* Language update log */}

                            <Typography style={{ display: "inline-block" }}>
                                <Typography style={{ display: "inline-block" }}>
                                    <span>
                                        {log?.causer?.employee.givenname} {t("has")}
                                    </span>{" "}
                                    {/*
                                     * Link to page
                                     * map item name -> searchrulename
                                     */}
                                    <StyledLink> </StyledLink> {log.description !== null && <span>{t(`log.${log.description as string}`)} </span>}
                                </Typography>
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            )}
        </>
    );
};

export default LogDescriptionDeleted;
