import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, Grow, useMediaQuery, useTheme } from "@mui/material";
import { useMyContext } from "../../components/layout/ScrollableContentContextProvider";

interface Props {
    showLogo: boolean;
    setShowLogo: (bln: boolean) => void;
}

const ResponsiveAppBar: React.FC<Props> = ({ showLogo, setShowLogo }) => {
    const theme = useTheme();
    const minimalLaptop = useMediaQuery(theme.breakpoints.up("lg"));
    const { value } = useMyContext();

    // check if scroll is active to add elevation to bottom of header
    // const trigger = useScrollTrigger({ disableHysteresis: true, target: window ? window : undefined, threshold: 300 });/
    // const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 300 });

    const renderHeader = (content: React.ReactNode) => {
        // setShowLogo(trigger);
        return (
            <AppBar elevation={0} sx={{ width: "100px", left: "64px", backgroundColor: "transparent" }}>
                {content}
            </AppBar>
        );
    };

    const showTopbar = () => {
        /**
         * Switch contains all states a tender could have, for each state a different component.
         */
        switch (minimalLaptop) {
            case true:
                return renderHeader(
                    <Toolbar disableGutters>
                        <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "flex" } }}>
                            {value && (
                                <Grow in={value}>
                                    <Box sx={{ width: "200px", zIndex: 99, position: "relative", margin: "32px" }}>
                                        <img
                                            src="https://storage.googleapis.com/tenderguide-public/tendenzLogo.png"
                                            alt="TendenZ"
                                            width="auto"
                                            height="64px"
                                        />
                                    </Box>
                                </Grow>
                            )}
                        </Box>
                    </Toolbar>
                );

            default:
                return null;
        }
    };

    return <React.Fragment>{showTopbar()}</React.Fragment>;
};
export default ResponsiveAppBar;
