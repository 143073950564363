import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import StarIcon from "@mui/icons-material/Star";
import IconButton from "@mui/material/IconButton";
import { GetLikedContractingAuthorities } from "../__generated__/GetLikedContractingAuthorities";
import { dislikeCAS, dislikeCASVariables } from "../__generated__/dislikeCAS";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { likeContractingAuthority, likeContractingAuthorityVariables } from "../__generated__/likeContractingAuthority";
import { Tooltip } from "@mui/material";
import { DISLIKECAS, LIKECONTRACTING_AUTHORITY } from "../graphql/mutationDefinitions";
import { QUERY_USER_LIKED_CAS } from "../graphql/queryDefCurrentUser";

interface Props {
    buttonSize?: "small" | "medium";
    color?: string;
    selection: any;
    menuItem?: boolean;
}

const LikeOrdislikeSelectedCAS: React.FC<Props> = ({ selection: selectedArr, color, buttonSize, menuItem }) => {
    /**
     * Get liked CA's
     */
    const { error: errorQuery, loading: loadQuery, data: dataQuery } = useQuery<GetLikedContractingAuthorities>(QUERY_USER_LIKED_CAS);
    const [queryData, setQueryData] = useState(dataQuery);
    const { t } = useTranslation();

    useEffect(() => {
        if (loadQuery === false && dataQuery) {
            setQueryData(dataQuery);
        }
    }, [loadQuery, dataQuery]);

    /**
     * mutation to like a CA
     */
    const [like, { error: errorLike, loading: loadingLike }] = useMutation<likeContractingAuthority, likeContractingAuthorityVariables>(
        LIKECONTRACTING_AUTHORITY,
        {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onError: () => {},
        }
    );

    /**
     * mutation to dislike a CA
     */
    const [dislike, { loading: loadingDislike, error: errorDislike }] = useMutation<dislikeCAS, dislikeCASVariables>(DISLIKECAS, {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onError: () => {},
    });

    if (errorQuery || queryData?.currentUser === undefined || queryData?.currentUser === null) {
        return null;
    }
    const likedCAS = queryData.currentUser.contracting_authorities;

    const arrayOfSelection = selectedArr.filter((i: string) => !likedCAS.some((item) => i === item.id));

    /**
     * Like all selected CA's
     */
    const likeOrdislikeSelectedCAS = () => {
        const selection = selectedArr;
        const arr = selection.filter((i: string) => !likedCAS.some((item) => i === item.id));

        /**
         * if arr is empty, then all CA's are already starred.
         * if user clicks on button, then CA's star will be removed.
         * if arr is > 0 then CA's will be starred.
         */

        if (arr.length > 0) {
            arr.forEach(async (item: string) => {
                // Function to like organization
                if (loadingLike) {
                }
                if (errorLike) {
                    toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await like({
                        variables: {
                            ca_id: item,
                        },
                        refetchQueries: [QUERY_USER_LIKED_CAS, "GetLikedContractingAuthorities"],
                    });
                } catch (e) {}
            });
        } else {
            /**
             * if arr is empty, use selected rows to remove star
             */
            selection.forEach(async (item: string) => {
                if (loadingDislike) {
                }
                if (errorDislike) {
                }
                try {
                    await dislike({
                        variables: {
                            org_id: item,
                        },
                        refetchQueries: [QUERY_USER_LIKED_CAS, "GetLikedContractingAuthorities"],
                    });
                } catch (e) {
                    toast.error("Fout tijdens opslaan");
                }
            });
        }
    };

    return (
        <React.Fragment>
            {menuItem ? (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        likeOrdislikeSelectedCAS();
                    }}
                >
                    {t("AddStar")}
                </MenuItem>
            ) : (
                <Tooltip
                    enterNextDelay={100}
                    title={arrayOfSelection.length > 0 ? (t("With-star") as string) : (t("Without-star") as string)}
                    placement="top"
                >
                    <IconButton
                        disabled={loadingLike || loadingDislike}
                        onClick={(e) => {
                            e.stopPropagation();
                            likeOrdislikeSelectedCAS();
                        }}
                        size={buttonSize}
                    >
                        <StarIcon fontSize="small" style={{ color: color }} />
                    </IconButton>
                </Tooltip>
            )}
        </React.Fragment>
    );
};

export default LikeOrdislikeSelectedCAS;
