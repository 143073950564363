import React, { useEffect, useState } from "react";
import { List, Grid, Typography, ListItem, Divider } from "@mui/material";
import FirstSearchLine from "../example/FirstSearchLine";
import SearchLineItem from "./SearchLineItem";
import { useTranslation } from "react-i18next";
import setTitle from "../../../utils/setTitle";

// GQL
import { useQuery } from "@apollo/client";
import { GetUserSearchRules } from "../../../__generated__/GetUserSearchRules";
import RenderPage from "../../../components/loading/RenderPage";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import OppSearchLineItem from "./OpportunityRules/OppSearchLineItem";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { QUERY_SEARCHRULES, QUERY_USER_IS_SUB_ADMIN } from "../../../graphql/queryDefCurrentUser";
import OrganizationSearches from "./OrganizationSearches";
import { CheckIfUserIsAdmin } from "../../../__generated__/CheckIfUserIsAdmin";
import SearchOwnerFilter from "./SearchOwnerFilter";
import { FindInPage, LocalOffer } from "@mui/icons-material";

interface Props {
    openModal: (type: string, item: any) => void;
    chosenRules: string;
    openFirstTime: (item: string) => void;
}

const SearchLine: React.FC<Props> = ({ openModal, chosenRules, openFirstTime }) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const [uniqueOwners, setUniqueOwners] = useState<any[]>([]);
    const [chosenOwners, setChosenOwners] = useState<number[]>([]);

    /**
     * Define the GQL query
     */
    const { data: adminCheck } = useQuery<CheckIfUserIsAdmin>(QUERY_USER_IS_SUB_ADMIN, {
        fetchPolicy: "network-only",
    });

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Set tab title
     */

    useEffect(() => {
        setTitle(t("SearchRules"));
    });

    /**
     * Define the GQL query
     */
    const { data, loading, error, refetch } = useQuery<GetUserSearchRules>(QUERY_SEARCHRULES, {
        skip: chosenRules === "all",
        onCompleted: (data) => {
            if (data && data.currentUser.searches) {
                const searchlines = data.currentUser.searches as any;
                const uniqueOwnerIds = [...new Set(searchlines.map((searchline: any) => searchline.owner?.id))];
                const uniqueOwners = uniqueOwnerIds.map((ownerId) => {
                    const ownerSearches = searchlines.filter((searchline: any) => searchline.owner?.id === ownerId);
                    return {
                        ...searchlines.find((searchline: any) => searchline.owner.id === ownerId).owner,
                        count: ownerSearches.length,
                    };
                });
                setUniqueOwners(uniqueOwners);
            }
        },
        fetchPolicy: "network-only",
    });

    if (chosenRules === "All") {
        return <OrganizationSearches openModal={openModal} admin={Boolean(adminCheck?.currentUser.subscription_admin)} />;
    }

    if (loading)
        /**
         * if loading -> show loading
         */
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );

    /**
     * if error or empty -> show error
     */
    if (error || !data)
        return (
            <List style={{ padding: 0 }}>
                <ListItem
                    sx={{
                        borderLeft: (theme) => `5px solid ${"#173357"}`,
                        backgroundColor: "#ffffff",
                        height: "75px",
                    }}
                >
                    <Typography variant="h6">{t("Error")}</Typography>
                </ListItem>
            </List>
        );

    const searchRuleData = data.currentUser;

    const tenderColorArray = searchRuleData.searches.map((i) => i.color);

    // Filter the array based on the chosenOwner IDs
    const filteredSearchlines =
        chosenOwners.length > 0
            ? searchRuleData.searches.filter((searchline) => chosenOwners.includes(parseInt(searchline?.owner?.id as string)))
            : searchRuleData.searches;

    return (
        <Grid>
            {/* 
                If searchline = 0, user sees an animation that shows what a searchline does.
                There is also a button on this page to create the first searchline.
                Animation is gone when user creates his first searchline.
         
                When there is 1 or more searchline, the user sees a list with all the lines
                In this list its possible to edit, share, delete and see the stats.
            */}
            {searchRuleData.searches.length === 0 && searchRuleData.opportunitySearches?.length === 0 ? (
                <FirstSearchLine openFirstTime={openFirstTime} />
            ) : (
                <>
                    {/*
                     * If chosenRules is 'all' => show all searchrules
                     * Tender and opportunity searchrules
                     */}
                    {chosenRules === "my" ? (
                        <div>
                            {/*
                             * Tender searchrules
                             */}
                            <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "8px" }}>
                                <SearchOwnerFilter owners={uniqueOwners} chosenOwners={chosenOwners} setChosenOwners={setChosenOwners} />
                            </Grid>

                            {/*
                             * LIST WITH SEARCHES THAT ARE NOT ACCEPTED YET
                             */}
                            <List style={{ padding: 0 }}>
                                {searchRuleData.ownedSearches
                                    .filter((s) => s.state === "PENDING_ACCEPTANCE")
                                    .map((searchline) => {
                                        return (
                                            <SearchLineItem
                                                showLock={true}
                                                admin={Boolean(adminCheck?.currentUser.subscription_admin)}
                                                chosenColors={tenderColorArray}
                                                refetch={refetch}
                                                type={"tender"}
                                                key={searchline.id}
                                                searchline={searchline}
                                                openEdit={() => {
                                                    openModal("tender", searchline);
                                                    trackevents({
                                                        category: "Personal",
                                                        action: "Click-edit-tender-searchrule",
                                                        name: searchline.name,
                                                        value: parseInt(searchline.id),
                                                    });
                                                }}
                                            />
                                        );
                                    })}
                            </List>

                            {/*
                             * LIST WITH SEARCHES (accepted searches included)
                             */}
                            <List style={{ padding: 0 }}>
                                {filteredSearchlines
                                    .filter((s) => s.state !== "PENDING_ACCEPTANCE")
                                    .map((searchline) => {
                                        return (
                                            <SearchLineItem
                                                showLock={true}
                                                admin={Boolean(adminCheck?.currentUser.subscription_admin)}
                                                chosenColors={tenderColorArray}
                                                refetch={refetch}
                                                type={"tender"}
                                                key={searchline.id}
                                                searchline={searchline}
                                                openEdit={() => {
                                                    openModal("tender", searchline);
                                                    trackevents({
                                                        category: "Personal",
                                                        action: "Click-edit-tender-searchrule",
                                                        name: searchline.name,
                                                        value: parseInt(searchline.id),
                                                    });
                                                }}
                                            />
                                        );
                                    })}
                            </List>

                            {searchRuleData?.opportunitySearches &&
                                searchRuleData?.opportunitySearches.length > 0 &&
                                filteredSearchlines.length > 0 && <Divider sx={{ margin: "16px 0px", color: "#cccccc" }}>Kansen</Divider>}

                            {/*
                             * Opportunity searchrules
                             */}
                            <List style={{ padding: 0 }}>
                                {searchRuleData?.opportunitySearches?.map((searchline) => {
                                    return (
                                        <OppSearchLineItem
                                            type={"opportunity"}
                                            key={searchline.id}
                                            searchline={searchline}
                                            openEdit={() => {
                                                openModal("opportunity", searchline);
                                                trackevents({
                                                    category: "Personal",
                                                    action: "Click-edit-opportunity-searchrule",
                                                    name: searchline.name,
                                                    value: parseInt(searchline.id),
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </List>
                        </div>
                    ) : (
                        // If chosenRules is "tenders" => show tender searchRules only
                        <div>
                            {/*
                             * Tender searchrules
                             */}

                            {chosenRules === "tenders" && (
                                <div>
                                    <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "8px" }}>
                                        <SearchOwnerFilter owners={uniqueOwners} chosenOwners={chosenOwners} setChosenOwners={setChosenOwners} />
                                    </Grid>
                                    {searchRuleData.searches.length ? (
                                        <List style={{ padding: 0 }}>
                                            {searchRuleData.searches.map((searchline) => {
                                                return (
                                                    <SearchLineItem
                                                        showLock={true}
                                                        admin={Boolean(adminCheck?.currentUser.subscription_admin)}
                                                        chosenColors={tenderColorArray}
                                                        refetch={refetch}
                                                        type={"tender"}
                                                        key={searchline.id}
                                                        searchline={searchline}
                                                        openEdit={() => {
                                                            openModal("tender", searchline);
                                                            trackevents({
                                                                category: "Personal",
                                                                action: "Click-edit-tender-searchrule",
                                                                name: searchline.name,
                                                                value: parseInt(searchline.id),
                                                            });
                                                        }}
                                                    />
                                                );
                                            })}
                                        </List>
                                    ) : (
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "70svh",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <LocalOffer sx={{ height: "9em", width: "9em", color: "#1733572a" }} />
                                                <Typography variant="h5" sx={{ mt: "16px", color: "#d4d4d4" }}>
                                                    Geen zoekregels - aanbestedingen
                                                </Typography>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/*
                             * If chosenRules is "opportunities" => show opportunity searchrules only
                             * TODO: dynamic content
                             */}
                            {chosenRules === "opportunities" && (
                                <div>
                                    <Grid
                                        container
                                        sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "8px", height: "40px" }}
                                    />
                                    {searchRuleData?.opportunitySearches?.length ? (
                                        <List style={{ padding: 0 }}>
                                            {searchRuleData?.opportunitySearches?.map((searchline) => {
                                                return (
                                                    <OppSearchLineItem
                                                        type={"opportunity"}
                                                        key={searchline.id}
                                                        searchline={searchline}
                                                        openEdit={() => {
                                                            openModal("opportunity", searchline);
                                                            trackevents({
                                                                category: "Personal",
                                                                action: "Click-edit-opportunity-searchrule",
                                                                name: searchline.name,
                                                                value: parseInt(searchline.id),
                                                            });
                                                        }}
                                                    />
                                                );
                                            })}
                                        </List>
                                    ) : (
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "70svh",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <FindInPage sx={{ height: "9em", width: "9em", color: "#225E4D2a" }} />
                                                <Typography variant="h5" sx={{ mt: "16px", color: "#d4d4d4" }}>
                                                    Geen zoekregels - kansen
                                                </Typography>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {chosenRules === "SharedSearchRules" && (
                                <div>
                                    {/*
                                     * Tender searchrules
                                     */}
                                    {/* <List style={{ padding: 0 }}>
                                        {searchRuleData.searches.map((searchline) => {
                                            return (
                                                <SearchLineItem
                                                    shared={true}
                                                    type={"tender"}
                                                    key={searchline.id}
                                                    searchline={searchline}
                                                    openEdit={() => {
                                                        openModal("tender", searchline);
                                                        trackevents({
                                                            category: "Personal",
                                                            action: "Click-edit-tender-searchrule",
                                                            name: searchline.name,
                                                            value: parseInt(searchline.id),
                                                        });
                                                    }}
                                                />
                                            );
                                        })}
                                    </List> */}

                                    {/*
                                     * Opportunity searchrules
                                     */}
                                    {/* <List style={{ padding: 0 }}>
                                        {searchRuleData?.opportunitySearches?.map((searchline) => {
                                            return (
                                                <OppSearchLineItem
                                                    shared={true}
                                                    type={"opportunity"}
                                                    key={searchline.id}
                                                    searchline={searchline}
                                                    openEdit={() => {
                                                        openModal("opportunity", searchline);
                                                        trackevents({
                                                            category: "Personal",
                                                            action: "Click-edit-opportunity-searchrule",
                                                            name: searchline.name,
                                                            value: parseInt(searchline.id),
                                                        });
                                                    }}
                                                />
                                            );
                                        })}
                                    </List> */}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </Grid>
    );
};

export default SearchLine;
