/* eslint-disable react/jsx-no-undef */
import React from "react";
import { ListItemButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import StatusDot from "../../../components/StatusDot";
// import { useMediaQuery } from "@mui/material";
// import moment from "moment";
import FaviconBox from "../../../components/FaviconBox";

interface Props {
    selectedIndex: string | undefined;
    onItemClick(id: string): void;
    data: any;
}

const SplitList: React.FC<Props> = ({ selectedIndex, onItemClick, data }) => {
    const hoverColor = "#efefef";
    const yellow = "#F57117";
    // const matchxxl = useMediaQuery(theme.breakpoints.up("xxl"));

    return (
        <Paper square style={{ height: "100%", overflow: "auto" }}>
            <List style={{ padding: 0 }} id="notificationlist">
                {data !== null &&
                    data !== undefined &&
                    data.map((item: any) => {
                        const primaryCA = item.clients.find((ca: any) => ca.pivot?.category === "A");
                        return (
                            <ListItemButton
                                dense
                                key={item.id}
                                onClick={() => onItemClick(item.id)}
                                style={{
                                    backgroundColor: selectedIndex === item.id ? `${hoverColor}` : undefined,
                                    display: "flex",
                                    paddingRight: "48px",
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: "0px",
                                    }}
                                >
                                    {primaryCA !== undefined ? (
                                        <FaviconBox favicon={primaryCA.favicon_resized ?? primaryCA.favicon} name={primaryCA.name} color={yellow} />
                                    ) : (
                                        <FaviconBox favicon={null} name={item.published_opdrachtgever} color={yellow} />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography noWrap>{item.namegiventocontract}</Typography>}
                                    secondary={primaryCA ? primaryCA.name : item.published_opdrachtgever}
                                />

                                {/* {matchxxl && (
                                    <Typography style={{ paddingRight: 24 }}>
                                        Deadline: {moment(item.deadline_inschrijving).format("LLL")}{" "}
                                    </Typography>
                                )} */}

                                <ListItemSecondaryAction style={{ height: "14px", width: "14px" }}>
                                    <StatusDot type={item.status} placement="left" />
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        );
                    })}
            </List>
        </Paper>
    );
};

export default SplitList;
