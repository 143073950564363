import React, { useState } from "react";
import {
    Badge,
    Divider,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    TextField,
    Button,
    Typography,
    CircularProgress,
    Link,
    Theme,
    useTheme,
    createStyles,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { browserName, browserVersion } from "react-device-detect";

//icons in this component
import { ReactComponent as LogoIcon } from "../../assets/tenderguide-icon.svg";
import SendIcon from "@mui/icons-material/Send";
import { useMutation, useQuery } from "@apollo/client";
import { GetLoggedInUsersData } from "../../__generated__/GetLoggedInUsersData";
// import { QUERY_GETUSERDATA } from "../layout/NavBar/ProfileBox";
import { SendQuestionToSupport, SendQuestionToSupportVariables } from "../../__generated__/SendQuestionToSupport";
import { useNavigate } from "react-router";
import { withStyles } from "tss-react/mui";
import { SEND_QUESTION_TO_SUPPORT } from "../../graphql/mutationDefinitions";
import { QUERY_GETUSERDATA } from "../../graphql/queryDefCurrentUser";

interface Props {
    a?: number;
}

//Styling for badge, visual feedback if support is online or not
const StyledBadge = withStyles(Badge, (theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: theme.palette.error.main,
            boxShadow: `0 0 0 2px ${"#ffffff"}`,
        },
    })
);

const OfflineSupport: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { data } = useQuery<GetLoggedInUsersData>(QUERY_GETUSERDATA);
    const [text, setText] = useState<string>("");
    const [sendSuccesful, setSendSuccesful] = useState<boolean>(false);
    // short way to user the fetched data
    const user = data !== undefined && data !== null ? data.currentUser : null;
    const navigate = useNavigate();
    const [SendMail, { loading }] = useMutation<SendQuestionToSupport, SendQuestionToSupportVariables>(SEND_QUESTION_TO_SUPPORT);
    const theme = useTheme();
    const white = "#ffffff";
    /**
     * Collect data to send with question
     */
    const sendMail = async () => {
        try {
            await SendMail({
                variables: {
                    question: text,
                    extra_data: [
                        `Browser: ${browserName}(V${browserVersion}) - (${window.screen.width} x ${window.screen.height})`,
                        `Url: ${window.location.href}`,
                    ],
                },
                onCompleted: () => {
                    setSendSuccesful(true);
                },
            });
            setText("");
            setTimeout(function () {
                setSendSuccesful(false);
            }, 2000);
        } catch (e) {
            console.log("");
        }
    };

    return (
        <React.Fragment>
            {/* list item showing the user an avatar and status support */}
            <ListItem>
                <ListItemAvatar>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        // variant="dot"
                    >
                        <Avatar style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#bdbdbd4d" }}>
                            <LogoIcon style={{ display: "block", width: "24px", color: white }} />
                        </Avatar>
                    </StyledBadge>
                </ListItemAvatar>
                <ListItemText
                    primary="Support TenderGuide"
                    //  secondary={t("FaqChat.Offline")}
                />
            </ListItem>
            <Divider style={{ marginBottom: 8 }} />

            {/*
             * Element that contains the chatelements. now filled with dummy data
             */}
            <div style={{ overflow: "auto" }}>
                <Typography variant="body1" style={{ marginTop: 8 }}>
                    {t("FaqChat.LeaveMessage")}
                </Typography>
                <Typography style={{ margin: "0px 0px 8px" }}>
                    Of vraag een{" "}
                    <Link
                        underline="none"
                        component="button"
                        onClick={() => navigate("/request-support")}
                        style={{ cursor: "pointer", marginTop: -2 }}
                    >
                        schermdeelsessie
                    </Link>{" "}
                    aan.
                </Typography>
                <TextField
                    value={text}
                    variant="outlined"
                    placeholder={`${t("FaqChat.TypeText")} ${user?.employee.givenname + " "}${
                        user?.employee.middlename ? user?.employee.middlename + " " : ""
                    }${user?.employee.familyname} (${user?.email})`}
                    fullWidth
                    margin="dense"
                    sx={{ margin: "0px", marginBottom: "8px" }}
                    multiline
                    minRows={17}
                    onChange={(e) => setText(e.target.value)}
                />
            </div>

            {/*
             * Textfield with a button to send message to support.
             */}
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: 6 }}>
                {sendSuccesful && (
                    <Typography variant="body1" sx={{ marginRight: "16px", color: "#9e9e9e" }}>
                        Bericht is verzonden naar support
                    </Typography>
                )}
                <Button
                    disabled={text.length === 0 || loading}
                    size="small"
                    variant="contained"
                    // fullWidth
                    sx={{
                        padding: "8px",
                        height: "38px",
                        color: "#ffffff",
                        backgroundColor: theme.palette.primary.light,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                        },
                    }}
                    onClick={sendMail}
                >
                    {t("FaqChat.SendMessage")} <SendIcon style={{ marginLeft: 8 }} />{" "}
                    {loading && <CircularProgress size={14} style={{ marginLeft: 4 }} />}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default OfflineSupport;
