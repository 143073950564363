import React from "react";
import { List, ListItem, ListItemText, Paper, styled, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses, Typography } from "@mui/material";
import { People } from "@mui/icons-material";
import { List as Listy } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface Props {
    a?: number;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
        marginLeft: -1,
        borderLeft: "1px solid transparent",
    },
}));

const OrganizationVIewPreferencesMobile: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const pink = "#E03660";

    return (
        <List sx={{ width: "100%", padding: 0 }}>
            <Typography variant="h3" sx={{ display: "flex", alignItems: "center" }}>
                <People style={{ color: pink, marginRight: 8, height: 20, width: 20 }} />
                {t("DefaultView")}
            </Typography>

            <Typography variant="caption">Bepaal jouw gewenste weergave</Typography>
            <Paper variant="outlined" sx={{ padding: "8px", width: "100%" }}>
                {/* ****************************************
                 *** REVIEWS
                 ******************************************** */}
                <ListItem sx={{ padding: 0, width: "100%" }}>
                    <ListItemText id="switch-list-label-wifi" primary={"Marktpartijen met ster"} />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div>
                            <Paper
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    border: (theme) => `1px solid ${theme.palette.divider}`,
                                    flexWrap: "wrap",
                                }}
                            >
                                <StyledToggleButtonGroup size="small" value={"list"} exclusive>
                                    <ToggleButton size="small" value={"list"} aria-label="left aligned">
                                        <Listy />
                                    </ToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </div>
                    </div>
                </ListItem>
            </Paper>
        </List>
    );
};

export default OrganizationVIewPreferencesMobile;
