import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { settlingDataProps } from "./CardViewSettlings";
import CountryFlagBox from "../../../../components/boxes/CountryFlagBox";
import StatusDotOrg from "../../../../components/StatusDotOrg";

interface Props {
    type: "ca" | "mp";
    data: settlingDataProps;
}

const SettlingCard: React.FC<Props> = ({ type, data }) => {
    return (
        <Card elevation={0} square variant="outlined" sx={{ m: 1 }}>
            <CardHeader
                sx={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    paddingBottom: "2px",
                    display: "flex",
                    alignItems: "center",
                    ".MuiCardHeader-avatar": {
                        marginRight: 0,
                    },
                }}
                action={
                    <div style={{ padding: "16px" }}>
                        <StatusDotOrg active={data.status as number} placement={"left"} textualHelper={data.statustext} />
                    </div>
                }
                title={<Typography variant="h3">{data.type}</Typography>}
                subheader={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CountryFlagBox marginLeft={2} code={data.country} /> <Typography style={{ marginLeft: "8px" }}>{data.place}</Typography>
                    </div>
                }
            />
            <CardContent sx={{ padding: "0px 8px" }}>
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography>{data.adres}</Typography>
                    </div>
                    {data.wonBids > 0 && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography>{data.wonBids} gegunde opdrachten</Typography>
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default SettlingCard;
