import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import React from "react";
import { QUERY_SINGLE_LAST_REVIEWED } from "../../graphql/opportunityQueries/queryDefinitions";
import { GetSingleOppLastReview, GetSingleOppLastReviewVariables } from "../../__generated__/GetSingleOppLastReview";
import moment from "moment";

interface Props {
    opp_file_id: string;
}

const OppLastReview: React.FC<Props> = ({ opp_file_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetSingleOppLastReview, GetSingleOppLastReviewVariables>(QUERY_SINGLE_LAST_REVIEWED, {
        variables: { id: opp_file_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.opportunityFile) {
        return <Typography>{""}</Typography>;
    }

    const opp = data.opportunityFile;

    const review = opp.lastReviewed ? moment(opp.lastReviewed).format("l") : "";

    return <Typography>{review}</Typography>;
};

export default OppLastReview;
