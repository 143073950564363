import React from "react";
import WidgetBox from "../../../components/WidgetBox";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";

// import icons
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import StarIcon from "@mui/icons-material/Star";
import { Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Skeleton } from "@mui/material";

interface Props {
    color: string;
    type: "ca" | "mp" | "tender";
}

const UpdateWidgetLoader: React.FC<Props> = ({ color, type }) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();

    // Track event
    const goToList = (cat: string, action: string) => {
        trackEvent({ category: cat, action: action });
    };
    return (
        <WidgetBox
            header={
                <React.Fragment>
                    {/* Type is contracting authority */}
                    {type === "ca" ? (
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <StarIcon style={{ color: `${color}`, marginRight: 4, height: 20 }} />
                                {t("dashboardPage.UpdatesCA")}
                            </div>
                            <Typography>
                                <Link
                                    id="link-to-full-list"
                                    to="/contracting-authorities/starred"
                                    onClick={() => goToList("Ca", "go-to-ca-updateview")}
                                >
                                    {" "}
                                    {t("dashboardPage.ShowAll")}
                                </Link>
                            </Typography>
                        </div>
                    ) : // Type is tenderer
                    type === "mp" ? (
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <StarIcon style={{ color: `${color}`, marginRight: 4, height: 20 }} />
                                {t("dashboardPage.UpdatesOrganizations")}
                            </div>
                            <Typography>
                                <Link
                                    id="link-to-full-list"
                                    to="/organizations/starred"
                                    onClick={() => goToList("Organization", "go-to-organization-updateview")}
                                >
                                    {" "}
                                    {t("dashboardPage.ShowAll")}
                                </Link>
                            </Typography>
                        </div>
                    ) : (
                        // Type is tender
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <ThumbUpIcon style={{ color: `${color}`, marginRight: 4, height: 20 }} />
                                {t("dashboardPage.UpdatesInterestingTenders")}
                            </div>
                            <Typography>
                                <Link
                                    id="link-to-full-list"
                                    to="/tenders/reviews"
                                    onClick={() => {
                                        localStorage.setItem("tenderUpdateView", "event");
                                        goToList("Tender", "go-to-tender-updateview");
                                    }}
                                >
                                    {" "}
                                    {t("dashboardPage.ShowAll")}
                                </Link>
                            </Typography>
                        </div>
                    )}
                </React.Fragment>
            }
            // highlightcolor based on type
            highlight={type === "ca" ? "yellow" : type === "mp" ? "pink" : "blue"}
            padding={0}
        >
            <List
                style={{
                    paddingTop: 5,
                    padding: 0,
                    overflow: "auto",
                    height: "355px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                {[...Array(5)].map((elem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <ListItem
                                id="ca-list-item"
                                sx={{
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                    }}
                                >
                                    <Skeleton variant="circular" width={24} height={24} style={{ marginRight: 8 }} />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        marginRight: "60px",
                                    }}
                                    primary={<Skeleton animation="wave" />}
                                    secondary={<Skeleton animation="wave" />}
                                />
                                <ListItemSecondaryAction>
                                    <Skeleton animation="wave" width={30} />
                                </ListItemSecondaryAction>
                            </ListItem>
                            {index !== 4 && <Divider />}
                        </React.Fragment>
                    );
                })}
            </List>
        </WidgetBox>
    );
};

export default UpdateWidgetLoader;
