import React from "react";
import { Box } from "@mui/material";
import OnlineChat from "./OnlineChat";
import OfflineSupport from "./OfflineSupport";

interface Props {
    online: boolean;
}

const ChatBox: React.FC<Props> = ({ online }) => {
    return <Box p={2}>{online ? <OnlineChat /> : <OfflineSupport />}</Box>;
};

export default ChatBox;
