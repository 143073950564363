import React, { useContext } from "react";
import { Avatar, Link as Linkie, Paper, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { AccountTree } from "@mui/icons-material";

import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineItem as MuiTimelineItem,
} from "@mui/lab";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";

// import LogMapperModal from "../LogMapperModal";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import {
    GetUserLogsWithFilters_activities_data,
    GetUserLogsWithFilters_activities_data_subject_CommentReply,
} from "../../../../__generated__/GetUserLogsWithFilters";
import { Link } from "react-router-dom";
// import LogModal from "../LogModal";

interface Props {
    item: any;
    log: GetUserLogsWithFilters_activities_data;
}

/**
 * This is used for the advanced search
 * timeline component adds this styling automatically,
 * Now its overruled so its aligned on the left side.
 */
const TimelineItem = withStyles(MuiTimelineItem, {
    missingOppositeContent: {
        "&:before": {
            display: "none",
        },
    },
    content: {
        flex: 20,
    },
    oppositeContent: {
        flex: 3,
    },
});

const StyledLink = styled(Link)`
    cursor: pointer;
    color: #000000;
    text-decoration: underline;
    &:hover {
        background-color: #0000001a;
    }
`;

const StyledLinkTyp = styled(Linkie)`
    cursor: pointer;
    color: #000000;
    text-decoration: underline;
    &:hover {
        background-color: #0000001a;
    }
`;

const CommentReplyLog: React.FC<Props> = ({ log, item }) => {
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);

    // const [open, setOpenModal] = useState(false);
    // const handleOpen = () => setOpenModal(true);
    // const handleClose = () => setOpenModal(false);

    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (log.action === "deleted") {
        return (
            <>
                {isMobile ? (
                    <Paper variant="outlined" sx={{ padding: "8px", marginTop: "8px", display: "flex", alignItems: "center" }}>
                        <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                            <AccountTree />
                        </Avatar>
                        <div>
                            {/* TEXT FOR EVERY ITEM DEFINED */}
                            <Typography style={{ display: "inline-block" }}>
                                <span>
                                    {log?.causer?.employee.givenname} {t("has")} notitie
                                </span>{" "}
                                {log.description !== null && <span> {t(`log.${log.description as string}`)}</span>}
                            </Typography>
                            {/* TIME */}
                            <Typography variant="caption" color="textSecondary">
                                {moment(log.created_at).format("LL")}, {moment(log.created_at).format("LT")}
                            </Typography>
                        </div>
                    </Paper>
                ) : (
                    <TimelineItem>
                        <TimelineOppositeContent sx={{ flex: 3 }}>
                            <Typography variant="body2" color="textSecondary">
                                {moment(log.created_at).format("LL")}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {moment(log.created_at).format("LT")}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                                <AccountTree />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ flex: 20 }}>
                            <Paper elevation={1} sx={{ padding: "6px 16px", marginTop: "3px" }}>
                                <div>
                                    <Typography style={{ display: "inline-block" }}>
                                        <span>
                                            {log?.causer?.employee.givenname} {t("has")} notitie
                                        </span>{" "}
                                        {log.description !== null && <span> {t(`log.${log.description as string}`)}</span>}
                                    </Typography>
                                </div>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                )}
            </>
        );
    }

    // const parsed_value = JSON.parse(log.properties);
    const comment = log.subject as GetUserLogsWithFilters_activities_data_subject_CommentReply;

    if (comment === null) {
        return <></>;
    }

    return (
        <>
            {isMobile ? (
                <Paper variant="outlined" sx={{ padding: "8px", marginTop: "3px", display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ bgcolor: "#cccccc", marginRight: "12px" }}>
                        <AccountTree />
                    </Avatar>
                    <div>
                        {/* TEXT FOR EVERY ITEM DEFINED */}
                        {comment.comment === "replyIsDeleted" ? (
                            <>
                                {log?.causer?.employee.givenname} {t("has")} reactie
                                {log.description !== null && <span style={{ marginLeft: "4px" }}> {t(`log.${log.description as string}`)}</span>}
                            </>
                        ) : (
                            <div>
                                <Typography style={{ display: "inline-block" }}>
                                    <span>
                                        {log?.causer?.employee.givenname} {t("has")}{" "}
                                        {/* {log.properties !== undefined && log.description !== undefined && checkChanges(log.properties, log.description as string)}{" "} */}
                                        reactie
                                    </span>{" "}
                                    {/*
                                     * Link to page
                                     * map item name -> searchrulename
                                     */}
                                    <StyledLink to={`/projects/${comment.project?.id}`}>{comment.comment}</StyledLink>
                                    {log.description !== null && <span> {t(`log.${log.description as string}`)}</span>}
                                </Typography>
                            </div>
                        )}
                        {/* TIME */}
                        <Typography variant="caption" color="textSecondary">
                            {moment(log.created_at).format("LL")}, {moment(log.created_at).format("LT")}
                        </Typography>
                    </div>
                </Paper>
            ) : (
                <TimelineItem>
                    <TimelineOppositeContent sx={{ flex: 3 }}>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LL")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {moment(log.created_at).format("LT")}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot sx={{ backgroundColor: "#cccccc", margin: "8px 0px" }}>
                            <AccountTree />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ flex: 20 }}>
                        <Paper elevation={1} sx={{ padding: "6px 16px", marginTop: "3px", display: "flex" }}>
                            {comment.comment === "replyIsDeleted" ? (
                                <>
                                    {log?.causer?.employee.givenname} {t("has")} reactie
                                    {log.description !== null && <span style={{ marginLeft: "4px" }}> {t(`log.${log.description as string}`)}</span>}
                                </>
                            ) : (
                                <div>
                                    <Typography style={{ display: "inline-block" }}>
                                        <span>
                                            {log?.causer?.employee.givenname} {t("has")}{" "}
                                            {/* {log.properties !== undefined && log.description !== undefined && checkChanges(log.properties, log.description as string)}{" "} */}
                                            reactie
                                        </span>{" "}
                                        {/*
                                         * Link to page
                                         * map item name -> searchrulename
                                         */}
                                        <StyledLinkTyp
                                            onClick={() => {
                                                changeProject({
                                                    projectID: comment.project?.id,
                                                    projectTitle: comment.project?.title,
                                                    state: comment.project?.state?.id,
                                                });
                                                setOpen(true);
                                            }}
                                        >
                                            {comment.comment}
                                        </StyledLinkTyp>
                                        {log.description !== null && <span> {t(`log.${log.description as string}`)}</span>}
                                    </Typography>
                                </div>
                            )}

                            <Typography
                                onClick={() => {
                                    changeProject({
                                        projectID: comment.project?.id,
                                        projectTitle: comment.project?.title,
                                        state: comment.project?.state?.id,
                                    });
                                    setOpen(true);
                                }}
                                sx={{
                                    marginLeft: "8px",
                                    display: "inline-block",
                                    fontStyle: "italic",
                                    cursor: "pointer",
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                }}
                            >
                                Bekijk details
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            )}
        </>
    );
};

export default CommentReplyLog;
