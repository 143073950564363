import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface Props {
    marginLeft: number;
}

const TodayIndicator: React.FC<Props> = ({ marginLeft }) => {
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div
            style={{
                alignSelf: "center",
                marginLeft: onlyMobile && marginLeft > 96 ? "97.5%" : !onlyMobile && marginLeft > 98.6 ? "99%" : `${marginLeft}%`,
                position: "absolute",
                marginTop: "30px",
            }}
        >
            <div style={{ height: "46px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Typography variant="caption" style={{ marginTop: "-18px" }}>
                    Nu
                </Typography>
                <div
                    style={{
                        margin: "-1px 0px",
                        minHeight: "16px",
                        maxHeight: "16px",
                        minWidth: "4px",
                        maxWidth: "4px",
                        backgroundColor: "#ffa24b",
                        borderRadius: "2px",
                    }}
                />
            </div>
        </div>
    );
};

export default TodayIndicator;
