import React, { useState } from "react";
import WidgetBox from "../WidgetBox";
import { Typography, List, ListItem, MenuItem, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import WidgetBoxMenu from "../WidgetBoxMenu";

import moment from "moment";
import ContractEnriched from "./ContractEnriched";
import ContractPublished from "./ContractPublished";
import DocumentDrawer from "../../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import {
    GetContractInfoForCurrentTender_tender_contract,
    GetContractInfoForCurrentTender_tender_looptijd_einde,
    GetContractInfoForCurrentTender_tender_looptijd_einde_incl_opties,
    GetContractInfoForCurrentTender_tender_looptijd_start,
    GetContractInfoForCurrentTender_tender_next_looptijd_einde,
    GetContractInfoForCurrentTender_tender_status_contract,
} from "../../__generated__/GetContractInfoForCurrentTender";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { toast } from "react-toastify";
import HorizontalContractTimeline from "./widgets/contract_timeline/HorizontalContractTimeline";
import { OptieEinde, convertToStringArray } from "../../utils/contracttimeline";
import { isAllDataInputsNullExceptStartDate } from "../../utils/checkHorizontalTimeLineDates";

interface OpenProps {
    open: boolean;
    link: string;
    label: string;
    doc_id: string;
}
interface Props {
    contract: GetContractInfoForCurrentTender_tender_contract | null;
    start: string | null;
    end: string | null;
    description: string | null;
    renew_desc: string | null;
    interval: string | null;
    period: string | null;
    looptijd_start: (GetContractInfoForCurrentTender_tender_looptijd_start | null)[] | null;
    next_looptijd_einde: (GetContractInfoForCurrentTender_tender_next_looptijd_einde | null)[] | null;
    looptijd_einde_incl_opties: (GetContractInfoForCurrentTender_tender_looptijd_einde_incl_opties | null)[] | null;
    looptijd_einde: (GetContractInfoForCurrentTender_tender_looptijd_einde | null)[] | null;
    status_contract: (GetContractInfoForCurrentTender_tender_status_contract | null)[] | null;
    tender_id: string;
    elevation?: number;
    opties: OptieEinde[];
    datum_gunning: string | null;
}

const ContractWidget: React.FC<Props> = ({
    contract,
    start,
    end,
    description,
    interval,
    period,
    renew_desc,
    next_looptijd_einde,
    looptijd_einde_incl_opties,
    status_contract,
    tender_id,
    elevation,
    looptijd_start,
    opties,
    looptijd_einde,
    datum_gunning,
}) => {
    const [showSource, setShowSource] = useState(false);
    const source = contract?.source;
    const [text, setText] = useState<string>("");
    const { t } = useTranslation();

    //submenu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));

    const [open, setOpen] = useState<OpenProps>({ open: false, link: "", label: "", doc_id: "" });
    const blue = "#173357";
    const closeDrawer = () => {
        setOpen({ open: false, link: "", label: "", doc_id: "" });
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // close submenu, set time out, change showsource to opposite
    // with sleep 100 user doesnt notice that word changes before closing submenu.
    const handleSource = async () => {
        setAnchorEl(null);
        await sleep(100);
        setShowSource((s) => !s);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(text);
        toast.success(t("Gegevens gekopieërd"), { autoClose: 1500 });
        setAnchorEl(null);
    };

    const { trackEvent } = useMatomo();

    // Track event
    const trackEvents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const renderPage = (content: React.ReactNode) => (
        <WidgetBox
            elevation={elevation}
            header={t("tenderPage.Contract")}
            highlight="blue"
            padding={0}
            actions={
                // TODO: create function to copy, add function to star all organizations
                <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                    <MenuItem
                        onClick={() => {
                            handleCopy();
                            trackEvents({ category: "Tender", action: "Click-copy-contract-data" });
                        }}
                    >
                        {t("CopyData")}
                    </MenuItem>
                    {source && (
                        <MenuItem
                            onClick={() => {
                                handleSource();
                                trackEvents({ category: "Tender", action: "Click-source-contract" });
                            }}
                        >
                            {showSource ? t("HideSource") : t("ShowSource")}
                        </MenuItem>
                    )}
                </WidgetBoxMenu>
            }
        >
            <List>
                {content}

                {/* Show/hide source of description */}
                {showSource && (
                    <ListItem style={{ marginTop: 0 }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Typography style={{ fontStyle: "italic", display: "inline", marginRight: 4 }} variant="caption">
                                {t("Source")}:
                            </Typography>

                            {source?.__typename === "Document" ? (
                                <React.Fragment>
                                    {source.extension === "pdf" ? (
                                        <Link
                                            underline="none"
                                            component="button"
                                            style={{ fontStyle: "italic" }}
                                            variant="caption"
                                            onClick={() =>
                                                setOpen({
                                                    open: true,
                                                    link: source.temporary_link,
                                                    label: source.filename,
                                                    doc_id: source.id,
                                                })
                                            }
                                        >
                                            {source.filename}
                                        </Link>
                                    ) : (
                                        <Link
                                            underline="none"
                                            component="button"
                                            style={{ fontStyle: "italic" }}
                                            variant="caption"
                                            onClick={(e) => {
                                                // Stop click on listitem to prevent document-viewer to initiate
                                                e.stopPropagation();
                                                const element = document.createElement("a");
                                                element.setAttribute("download", `${source.filename}`);
                                                element.setAttribute(
                                                    "href",
                                                    `${process.env.REACT_APP_API_ROOT}/tenders/${tender_id}/downloadFile/${source.id}`
                                                );
                                                element.style.display = "none";
                                                document.body.appendChild(element);
                                                element.click();
                                                document.body.removeChild(element);
                                            }}
                                        >
                                            {source.filename}
                                        </Link>
                                    )}
                                </React.Fragment>
                            ) : source?.__typename === "Publication" ? (
                                <Typography style={{ fontStyle: "italic" }} variant="caption">
                                    {moment(source?.dispatchdate).format("LL")} - {source?.host}, {source?.publication_type.name}
                                </Typography>
                            ) : (
                                ""
                            )}
                        </div>
                    </ListItem>
                )}
            </List>
            {/* Drawer that contains clicked pdf file */}
            {typeof open.link === "string" && open.open === true && typeof open.label === "string" && (
                <DocumentDrawer
                    open={open.open}
                    onClose={closeDrawer}
                    title={open.label}
                    color={blue}
                    url={open.link}
                    doc_id={open.doc_id}
                    module_item_id={tender_id}
                />
            )}

            {/* // TODO: Version 4.2 */}
            {/* {showHistory && (
            <>
                <Divider />
                <Typography style={{ color: "grey", marginLeft: 16, marginTop: 8 }}>{t("tenderPage.Changes")}:</Typography>
                <History />
            </>
        )} */}
        </WidgetBox>
    );

    if (contract !== null) {
        const startDate = contract.period?.startDate || (looptijd_start && looptijd_start[0]?.value) || start || null;
        const alldates = isAllDataInputsNullExceptStartDate({
            contractEnd: contract.period?.endDate,
            looptijd_einde: looptijd_einde,
            next_looptijd_einde: next_looptijd_einde,
        });

        const einde: string | null = looptijd_einde && looptijd_einde[0]?.value ? looptijd_einde[0]?.value.toString() : null;

        // If enddate is same as datum_gunning -> hide timeline
        const gunningISeind = Boolean(datum_gunning && einde && moment(datum_gunning).format("DD-MM-YYYY") === moment(einde).format("DD-MM-YYYY"));

        return renderPage(
            <React.Fragment>
                {alldates === false && gunningISeind === false && (
                    <HorizontalContractTimeline
                        options={convertToStringArray(opties)}
                        start={contract.period?.startDate || (looptijd_start && looptijd_start[0]?.value) || start || datum_gunning || null}
                        end={
                            contract.period?.endDate ||
                            (looptijd_einde && looptijd_einde[0]?.value) ||
                            (next_looptijd_einde && next_looptijd_einde[0]?.value) ||
                            null
                        }
                        undetermined={Boolean(contract?.undeterminedPeriod)}
                        datum_gunning_as_start={Boolean(startDate === null)}
                        noEndDateAvailable={Boolean(
                            (contract.period?.endDate ||
                                (looptijd_einde && looptijd_einde[0]?.value) ||
                                (next_looptijd_einde && next_looptijd_einde[0]?.value)) === null
                        )}
                    />
                )}

                <div>
                    <ContractEnriched
                        contract={contract}
                        next_looptijd_einde={next_looptijd_einde}
                        setCopyText={setText}
                        status_contract={status_contract}
                        looptijd_einde_incl_opties={looptijd_einde_incl_opties}
                    />
                </div>
            </React.Fragment>
        );
    }
    const startDate = start || null;

    // If enddate is same as datum_gunning -> hide timeline
    const gunningISeind = Boolean(datum_gunning && end && moment(datum_gunning).format("DD-MM-YYYY") === moment(end).format("DD-MM-YYYY"));

    return renderPage(
        <React.Fragment>
            {isAllDataInputsNullExceptStartDate({
                contractEnd: null,
                looptijd_einde: looptijd_einde,
                next_looptijd_einde: next_looptijd_einde,
            }) &&
                gunningISeind === false && (
                    <HorizontalContractTimeline
                        start={start || datum_gunning}
                        datum_gunning_as_start={Boolean(startDate === null)}
                        end={end}
                        undetermined={false}
                        noEndDateAvailable={Boolean(end === null)}
                        options={convertToStringArray(opties)}
                    />
                )}
            <ContractPublished
                start={start}
                end={end}
                period={period}
                interval={interval}
                description={description}
                renew_desc={renew_desc}
                setCopyText={setText}
            />
        </React.Fragment>
    );
};

export default ContractWidget;

// const History: React.FC = () => {
//     return (
//         <List dense>
//             <ListItem>
//                 <ListItemText primary="Start van de looptijd veranderd van 1-3-2020 naar 1-1-2020" secondary="Gewijzigd op 30-12-2019" />
//             </ListItem>
//             <ListItem>
//                 <ListItemText primary="Optie tot verlengen toegevoegd: 1 x 12 maanden" secondary="Gewijzigd op 31-12-2019" />
//             </ListItem>
//         </List>
//     );
// };
