import React, { useState, useRef, useCallback, useEffect } from "react";
import { TenderFilterTarget } from "../../__generated__/globalTypes";
import IconButton from "@mui/material/IconButton";
import InfoButtonTooltip from "../InfoButtonTooltip";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
// import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { debounce } from "lodash";
import { Add, Bolt, Clear } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { Divider, Theme, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { useQueryBuilder } from "../../routes/profile/Dialog/QueryBuilder/QueryBuilderContext";
import AdvancedQueryModal from "../../routes/profile/Dialog/AdvancedQueryModal";
import AdvancedQueryField from "./AdvancedQueryField";

const StyledToggleButtonGroup = withStyles(ToggleButtonGroup, (theme: Theme) => ({
    root: {
        border: "none",
        width: "436px",
        display: "flex",
        justifyContent: "space-between",
        "@media (max-width: 800px)": {
            width: "100%",
        },
    },
    grouped: {
        border: "none",
        fontSize: "12px",
        padding: "4px 6px",
        "@media (max-width: 800px)": {
            fontSize: "10px",
        },
    },
}));

const StyledToggleButton = withStyles(ToggleButton, (theme: Theme) => ({
    /* Styles applied to the root element. */
    root: {
        border: "none !important",
        background: "none !important",
        "&:hover": {
            textDecoration: "underline",
            textDecorationThickness: 2,
            textUnderlineOffset: "3px",
            // Reset on mouse devices
            backgroundColor: "none !important",
            "@media (hover: none)": {
                backgroundColor: "none !important",
            },
            "&$disabled": {
                backgroundColor: "none !important",
            },
        },
    },

    /* Pseudo-class applied to the root element if `selected={true}`. */
    selected: {
        color: "#173357",
        background: "none !important",
        textDecoration: "underline ",
        textDecorationThickness: 2,
        textUnderlineOffset: "3px",
        "&:hover": {
            background: "none !important",
            textDecorationThickness: 2,
        },
    },
}));

interface Props {
    query: string;
    target: TenderFilterTarget;
    onChangeQuery(query: string): void;
    onChangeTarget(target: TenderFilterTarget): void;
    onSearch(): void;
    visible: boolean;
    openModal?(): void;
    allpage?: boolean;
}
// TODO: Target changes to summary on query update (if another target is chosen)
const TenderSearchField: React.FC<Props> = ({ allpage = false, query, target, onChangeQuery, onChangeTarget, onSearch, visible, openModal }) => {
    const { advancedQueryString, setActiveGroup, groups, resetAdvancedQuery } = useQueryBuilder();
    const [openAdvancedBuilder, setOpenAdvancedBuilder] = useState<boolean>(false);
    const inputRef = useRef();
    const location = useLocation();
    // const { t } = useTranslation();
    const grey = "#707070";
    const blue = "#173357";
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    /**
     * get defaultQuery based on location(url)
     */
    const defaultQuery =
        location.pathname === "/tenders"
            ? sessionStorage.getItem("atq")
            : location.pathname === "/tenders/reviews"
            ? sessionStorage.getItem("rtq")
            : location.pathname === "/tenders/worklist"
            ? sessionStorage.getItem("wtq")
            : "";

    // check if defAultQuery is not null and parse json
    const defaultQueryJson = defaultQuery ? JSON.parse(defaultQuery) : "";

    /**
     * Searchfield state with default value from sessionStorage (defaultQuery)
     * remove "" in query
     */
    const [userValue, setUserValue] = useState(defaultQueryJson || "");

    const [searchdomain, setSearchdomain] = useState(target);
    // debounce function to prevent request if characters is < 3 except 0
    const debounced = useRef(debounce((value) => (value.length === 0 ? onChangeQuery(value) : value.length >= 3 && onChangeQuery(value)), 1100));
    const updateUserValue = useCallback(({ target: { value } }) => {
        setUserValue(value);
        debounced.current(value);
    }, []);

    useEffect(() => {
        onChangeQuery(advancedQueryString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advancedQueryString]);

    return (
        <div
            style={{
                width: onlyMobile ? "100%" : "480px",
                maxWidth: "100% !important",
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {/*
                 * Searchbox to type query
                 * onSearch -> start search with typed query
                 */}
                {advancedQueryString === "" ? (
                    <Paper
                        square
                        component="form"
                        sx={{ padding: "2px 4px", display: "flex", alignItems: "center", width: onlyMobile ? "100%" : "438px" }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSearch();
                        }}
                    >
                        {/* Searchicon */}
                        <SearchIcon style={{ margin: "10px 0px 10px 6px", color: grey }} />
                        {/* Inputfield query */}
                        <InputBase
                            ref={inputRef}
                            sx={{ marginLeft: (theme) => theme.spacing(1), flex: 1 }}
                            placeholder="Zoeken"
                            autoFocus={onlyMobile}
                            value={userValue}
                            onChange={updateUserValue}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        {/*
                         * RESET BUTTON
                         */}
                        <Tooltip placement="right" title="Zoekopdracht verwijderen" disableInteractive>
                            <IconButton
                                disabled={userValue === ""}
                                color="primary"
                                size="small"
                                aria-label="directions"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setUserValue("");
                                    onChangeQuery("");
                                    onSearch();
                                }}
                            >
                                <Clear sx={{ color: userValue === "" ? "#b3b3b3" : "error.main" }} />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                ) : (
                    <AdvancedQueryField
                        query={advancedQueryString}
                        openModal={() => setOpenAdvancedBuilder(true)}
                        resetQuery={() => {
                            resetAdvancedQuery();
                            setUserValue("");
                        }}
                        createSearchRule={() => {
                            openModal && openModal();
                        }}
                        themeColor="#173357"
                    />
                )}

                <Tooltip title="Geavanceerd zoeken" placement="right" disableInteractive>
                    <IconButton
                        onClick={() => {
                            setOpenAdvancedBuilder(true);
                            setActiveGroup(groups[0].id);
                        }}
                        size="small"
                        style={{ margin: "0 6px" }}
                    >
                        <Bolt />
                    </IconButton>
                </Tooltip>
            </div>
            {/*
             * Div with 4 px whitespace
             */}
            <div style={{ height: 4 }} />

            {/*
             * Select the target to search in
             */}
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <StyledToggleButtonGroup
                    value={target}
                    exclusive
                    onChange={(_e, value) => value !== null && onChangeTarget(value as TenderFilterTarget)}
                >
                    <StyledToggleButton
                        onClick={() => {
                            setSearchdomain(TenderFilterTarget.SUMMARY);
                        }}
                        selected={
                            searchdomain === TenderFilterTarget.SUMMARY || TenderFilterTarget.RELEVANT_DOCS || TenderFilterTarget.ALL_DOCS
                                ? true
                                : false
                        }
                        value={TenderFilterTarget.SUMMARY}
                    >
                        Samenvatting
                    </StyledToggleButton>
                    {/*
                     * Show add icon if relevant is chosen
                     */}
                    {searchdomain !== TenderFilterTarget.SUMMARY ? (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 0, border: 0, margin: 0 }}>
                            <Add style={{ width: "0.5em", height: "0.5em", color: blue }} />
                        </div>
                    ) : (
                        <div style={{ width: "0.5em", height: "0.5em" }} />
                    )}
                    <StyledToggleButton
                        onClick={() => {
                            setSearchdomain(TenderFilterTarget.RELEVANT_DOCS);
                        }}
                        selected={searchdomain === TenderFilterTarget.RELEVANT_DOCS || searchdomain === TenderFilterTarget.ALL_DOCS ? true : false}
                        disabled={typeof query === "string" && query.trim().length === 0}
                        value={TenderFilterTarget.RELEVANT_DOCS}
                    >
                        Gemarkeerde documenten
                    </StyledToggleButton>
                    {searchdomain === TenderFilterTarget.ALL_DOCS ? (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 0, border: 0, margin: 0 }}>
                            <Add style={{ width: "0.5em", height: "0.5em", color: blue }} />
                        </div>
                    ) : (
                        <div style={{ width: "0.5em", height: "0.5em" }} />
                    )}
                    <StyledToggleButton
                        onClick={() => {
                            setSearchdomain(TenderFilterTarget.ALL_DOCS);
                        }}
                        selected={searchdomain === TenderFilterTarget.ALL_DOCS ? true : false}
                        disabled={typeof query === "string" && query.trim().length === 0}
                        value={TenderFilterTarget.ALL_DOCS}
                    >
                        Alle documenten
                    </StyledToggleButton>
                </StyledToggleButtonGroup>
                {/*
                 * id 23 is id of article to show
                 */}
                <InfoButtonTooltip faq_id="23" />
            </div>
            {openAdvancedBuilder && (
                <AdvancedQueryModal open={openAdvancedBuilder} onClose={() => setOpenAdvancedBuilder(false)} themeColor="#173357" />
            )}
        </div>
    );
};

export default TenderSearchField;
