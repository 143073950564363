import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CountDownItem from "./CountDownItem";
import moment from "moment";

interface CountdownProps {
    targetDate: string;
    teaser?: string;
    showCountdown: boolean;
    time: string;
}

const CountDown: React.FC<CountdownProps> = ({ targetDate, teaser, showCountdown, time }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    const calculateTimeLeft = () => {
        const difference = +moment(targetDate) - +moment();
        let timeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };

        if (difference > 0) {
            // Calculate days, hours, minutes, and seconds from the difference
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            let hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            // Check if now is before the comparison date
            if (moment().isBefore("2024-10-21")) {
                // Subtract one hour if it's greater than 0
                if (hours > 0) {
                    hours -= 1; // Only subtract 1 hour if hours are greater than 0
                }
            }

            timeLeft = {
                days: days,
                // CHECK IF ITS CORRECT
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    /**
     * RETURN IF DEVICE IS MOBILE
     */
    if (!noMobile) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    bottom: 175,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0",
                    flexDirection: "row",
                    width: "100%",
                }}
            >
                <div style={{ width: "95%" }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                            {teaser && (
                                <Typography
                                    sx={{
                                        fontSize: "1rem !important", // Adjust font size as needed
                                        fontWeight: 500, // Adjust font weight as needed
                                        textAlign: "center", // Center align the text
                                    }}
                                >
                                    {teaser}
                                </Typography>
                            )}
                            <Typography
                                sx={{
                                    fontSize: "2rem !important", // Adjust font size as needed
                                    fontWeight: 700, // Adjust font weight as needed
                                    textAlign: "center", // Center align the text
                                }}
                            >
                                {moment(targetDate).format("LL")}
                            </Typography>
                            <Typography variant="h2" fontWeight={500} sx={{ zIndex: 999, position: "relative" }}>
                                {time}
                            </Typography>
                        </Grid>
                        {showCountdown && (
                            <>
                                <CountDownItem value={timeLeft.days} label="Dagen" padding="32px 6px" />
                                <CountDownItem value={timeLeft.hours} label="Uren" padding="32px 6px" />
                                <CountDownItem value={timeLeft.minutes} label="Minuten" padding="32px 6px" />
                                <CountDownItem value={timeLeft.seconds} label="Seconden" padding="32px 6px" />
                            </>
                        )}
                    </Grid>
                </div>
            </Box>
        );
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "256px", flexDirection: "column" }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                    {teaser && (
                        <Typography
                            sx={{
                                fontSize: "3rem", // Adjust font size as needed
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: "center", // Center align the text
                            }}
                        >
                            {teaser}
                        </Typography>
                    )}
                    <Typography
                        sx={{
                            fontSize: "3rem", // Adjust font size as needed
                            fontWeight: 700, // Adjust font weight as needed
                            textAlign: "center", // Center align the text
                        }}
                    >
                        {moment(targetDate).format("LL")}
                    </Typography>

                    <Typography variant="h2" fontWeight={500} sx={{ zIndex: 999, position: "relative" }}>
                        {time}
                    </Typography>
                </Grid>
            </Grid>

            {showCountdown && (
                <Grid container spacing={2} alignItems="center" sx={{ display: "flex", width: "50%" }}>
                    <CountDownItem value={timeLeft.days} label="Dagen" />
                    <CountDownItem value={timeLeft.hours} label="Uren" />
                    <CountDownItem value={timeLeft.minutes} label="Minuten" />
                    <CountDownItem value={timeLeft.seconds} label="Seconden" />
                </Grid>
            )}
        </Box>
    );
};

export default CountDown;
