import React from "react";
import FullSearchRuleRun from "../FullSearchRuleRun";
import TenderUpdateNotification from "../TenderUpdateNotification";
import NewAwardForMarketParty from "../NewAwardForMarketParty";
import NewTenderForContractingAuthorityNotification from "../NewTenderForContractingAuthorityNotification";
import TenderNotificationMenuItem from "../TenderNotificationMenuItem";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../../__generated__/GetNotificationsCurrentUser";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FullOpportunitySearchRuleRun from "../FullOpportunitySearchRuleRun";
import TenderDeadlineReminderNotification from "../TenderDeadlineReminderNotification";
import TenderNextEndDateReminderNotification from "../TenderNextEndDateReminderNotification";
import RequestTenderSearchline from "../RequestTenderSearchline";
import NewChatmessageNotification from "../NewChatMessageNotification";
import UserAddedToTeam from "../UserAddedToTeam";
import AddedToProjectNotification from "../AddedToProjectNotification";
import MentionedInProject from "../MentionedInProject";
import AssignedToTaskNotification from "../AssignedToTaskNotification";
import RepliedToComment from "../RepliedToComment";
import ChangedEventNotification from "../ChangedEventNotification";
import ChangedPlanning from "../ChangedPlanning";
import TendenzRegistrationNotification from "../TendenzRegistrationNotification";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    index: number;
    markSingleNotification?: (e: string) => void;
    setOpenNotifications: (openNotifications: boolean) => void;
    markBatchOfNotifications?: () => void;
}
/**
 * @returns Returns notification based on given type, each notification has its own notification component defined
 */
const NotificationTypeSelector: React.FC<Props> = ({
    index,
    notification,
    markSingleNotification,
    setOpenNotifications,
    markBatchOfNotifications,
}) => {
    const navigate = useNavigate();

    /**
     * @param notification notification item
     * @returns Menu item based on notification type
     */
    const getNotificationType = (notification: any, i: number): JSX.Element => {
        switch (true) {
            case notification.type === "App\\Notifications\\RegisteredForTendenz":
                return (
                    <TendenzRegistrationNotification
                        key={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markBatchOfNotifications && markBatchOfNotifications();
                            markSingleNotification && markSingleNotification(notification.id);
                        }}
                    />
                );
            case notification.type === "App\\Notifications\\NotifyUserOfChangedEvent":
                return (
                    <ChangedEventNotification
                        key={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markBatchOfNotifications && markBatchOfNotifications();
                            markSingleNotification && markSingleNotification(notification.id);
                        }}
                    />
                );

            case notification.type === "App\\Notifications\\NotifyUserOfChangedPlanning":
                return (
                    <ChangedPlanning
                        key={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markBatchOfNotifications && markBatchOfNotifications();
                            markSingleNotification && markSingleNotification(notification.id);
                        }}
                    />
                );

            case notification.type === "App\\Notifications\\RepliedToComment":
                return (
                    <RepliedToComment
                        key={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markBatchOfNotifications && markBatchOfNotifications();
                            markSingleNotification && markSingleNotification(notification.id);
                        }}
                    />
                );

            case notification.type === "App\\Notifications\\AssignedToTask":
                return (
                    <AssignedToTaskNotification
                        key={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markBatchOfNotifications && markBatchOfNotifications();
                            markSingleNotification && markSingleNotification(notification.id);
                        }}
                    />
                );

            case notification.type === "App\\Notifications\\MentionedInComment":
                return (
                    <MentionedInProject
                        key={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markBatchOfNotifications && markBatchOfNotifications();
                            markSingleNotification && markSingleNotification(notification.id);
                        }}
                    />
                );

            case notification.type === "App\\Notifications\\AddedToProject":
                return (
                    <AddedToProjectNotification
                        key={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markBatchOfNotifications && markBatchOfNotifications();
                            markSingleNotification && markSingleNotification(notification.id);
                        }}
                    />
                );

            case notification.type === "App\\Notifications\\AddedToTeam":
                return (
                    <UserAddedToTeam
                        key={i}
                        notification={notification}
                        onClick={() => {
                            markBatchOfNotifications && markBatchOfNotifications();
                            markSingleNotification && markSingleNotification(notification.id);
                            if (notification !== null && notification.data !== null) {
                                navigate(`/profile/users-teams`);
                            }
                        }}
                    />
                );

            case notification.type === "App\\Notifications\\NewChatMessageReceived":
                return (
                    <NewChatmessageNotification
                        key={i}
                        notification={notification}
                        onClick={() => {
                            markBatchOfNotifications && markBatchOfNotifications();
                            setOpenNotifications(false);
                        }}
                    />
                );
            case notification.type === "App\\Notifications\\RequestToCopySearchNotification":
                return (
                    <RequestTenderSearchline
                        key={i}
                        type={notification.data[1]}
                        notification={notification}
                        onClick={() => {
                            markSingleNotification && markSingleNotification(notification.id);
                            if (notification !== null && notification.data !== null) {
                                navigate(`/profile/search-rules`);
                            }
                            setOpenNotifications(false);
                        }}
                    />
                );

            case notification.type === "App\\Notifications\\TenderEnddateReminderNotification":
                return (
                    <TenderNextEndDateReminderNotification
                        key={i}
                        keyIndex={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markSingleNotification && markSingleNotification(notification.id);
                            if (notification !== null && notification.data !== null) {
                                navigate(`/tenders/${id}`);
                            }
                            setOpenNotifications(false);
                        }}
                    />
                );
            case notification.type === "App\\Notifications\\TenderDeadlineReminderNotification":
                return (
                    <TenderDeadlineReminderNotification
                        key={i}
                        keyIndex={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markSingleNotification && markSingleNotification(notification.id);
                            if (notification !== null && notification.data !== null) {
                                navigate(`/tenders/${id}`);
                            }
                            setOpenNotifications(false);
                        }}
                    />
                );
            case notification.type === "App\\Notifications\\NewSearchResultNotification":
                return (
                    <TenderNotificationMenuItem
                        key={i}
                        keyIndex={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markSingleNotification && markSingleNotification(notification.id);
                            if (notification !== null && notification.data !== null) {
                                navigate(`/tenders/${id}`);
                            }
                            setOpenNotifications(false);
                        }}
                    />
                );
            /**
             * Notification when a new searchrule has first data
             */
            case notification.type === "App\\Notifications\\FullSearchFinishedNotification":
                return (
                    <FullSearchRuleRun
                        key={i}
                        keyIndex={i}
                        notification={notification}
                        onClick={() => {
                            markSingleNotification && markSingleNotification(notification.id);
                        }}
                    />
                );
            /**
             * Tender update notification
             */
            case notification.type === "App\\Notifications\\TenderUpdateNotification":
                return (
                    <TenderUpdateNotification
                        key={i}
                        keyIndex={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markSingleNotification && markSingleNotification(notification.id);
                            if (notification !== null && notification.data !== null) {
                                navigate(`/tenders/${id}`);
                            }
                        }}
                    />
                );

            /**
             * New award organization
             */
            case notification.type === "App\\Notifications\\NewAwardForMarketParty":
                return (
                    <NewAwardForMarketParty
                        key={i}
                        keyIndex={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markSingleNotification && markSingleNotification(notification.id);
                            if (notification !== null && notification.data !== null) {
                                navigate(`/organizations/${id}`);
                            }
                        }}
                    />
                );

            /**
             * New award organization (New typename)
             */
            case notification.type === "App\\Notifications\\NewAwardForMarketPartyNotification":
                return (
                    <NewAwardForMarketParty
                        key={i}
                        keyIndex={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markSingleNotification && markSingleNotification(notification.id);
                            if (notification !== null && notification.data !== null) {
                                navigate(`/organizations/${id}`);
                            }
                        }}
                    />
                );

            /**
             * New tender of a starred CA
             */
            case notification.type === "App\\Notifications\\NewTenderForContractingAuthorityNotification":
                return (
                    <NewTenderForContractingAuthorityNotification
                        key={i}
                        keyIndex={i}
                        notification={notification}
                        onClick={(id: string) => {
                            markSingleNotification && markSingleNotification(notification.id);
                            if (notification !== null && notification.data !== null) {
                                navigate(`/contracting-authorities/${id}`);
                            }
                        }}
                    />
                );

            /**
             * Opportunity searchrule created notfication
             */
            case notification.type === "App\\Notifications\\FullOpportunitySearchFinishedNotification":
                return (
                    <FullOpportunitySearchRuleRun
                        key={i}
                        keyIndex={i}
                        notification={notification}
                        onClick={() => {
                            markSingleNotification && markSingleNotification(notification.id);
                        }}
                    />
                );
            default:
                return <MenuItem key={i} />;
        }
    };

    return getNotificationType(notification, index);
};

export default NotificationTypeSelector;
