import React, { useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useDrop } from "react-dnd";
import ColumnName from "./ColumnName";
import DraggableProjectCard from "./projectcard/DraggableProjectCard";
import { projectStates_projectStates } from "../../../__generated__/projectStates";
import { useMutation, useQuery } from "@apollo/client";
import { ALL_PROJECTS, PROJECT_STATES } from "../../../graphql/queryDefProjects";
import { GetAllProjects } from "../../../__generated__/GetAllProjects";
import { UPDATE_PROJECT_STATE_NAME } from "../../../graphql/mutationDefProjects";
import { updateProjectStateName, updateProjectStateNameVariables } from "../../../__generated__/updateProjectStateName";
import LoadingProjectCard from "./projectcard/LoadingProjectCard";
import ColumnMenu from "./ColumnMenu";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    column: projectStates_projectStates;
    columns: projectStates_projectStates[];
    columnIndex: number;
    setColumn: React.Dispatch<React.SetStateAction<projectStates_projectStates[]>>;
    handleAddItemToColumn: (id: string, columnId: string) => void;
    isAdmin: boolean;
}

// TODO: change title textfield to editablefield (own component)
const ColumnComponent: React.FC<Props> = ({ columns, column: columnProps, setColumn, handleAddItemToColumn, isAdmin, columnIndex }) => {
    const { trackEvent } = useMatomo();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };
    const [editMode, setEditMode] = useState<boolean>(false);
    const { data, loading } = useQuery<GetAllProjects>(ALL_PROJECTS, {
        fetchPolicy: "network-only",
    });

    /**
     * Dropzone for drag and drop item to column.
     */
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: "ITEM",
        drop: (item: { id: string }) => handleAddItemToColumn(item.id, columnProps.id),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    /**
     * Update project title
     */
    const [updateProjectStateName] = useMutation<updateProjectStateName, updateProjectStateNameVariables>(UPDATE_PROJECT_STATE_NAME, {
        refetchQueries: [PROJECT_STATES, "projectStates"],
        awaitRefetchQueries: true,
    });

    const columnToShow = data?.projects?.filter((prjct) => prjct.state?.id === columnProps.id);

    const renderPage = (content: React.ReactNode) => {
        return (
            <Box
                ref={drop}
                sx={{
                    border: isActive ? "1px solid #465c84" : canDrop ? "1px solid #f4f4f4" : "1px solid #f4f4f4",
                    minWidth: columnProps.amountOfProjects === 0 ? "200px" : isMobile ? "75vw" : "350px",
                    maxWidth: columnProps.amountOfProjects === 0 ? "200px" : isMobile ? "75vw" : "350px",
                    background: columnProps.amountOfProjects === 0 ? "#ececec" : "#e1e1e1",
                    marginLeft: "8px",
                    marginRight: "8px",
                    padding: "12px",
                    borderRadius: "6px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                    }}
                >
                    <div
                        style={{
                            width: columnProps.amountOfProjects === 0 ? "80%" : "100%",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <ColumnName
                            columnName={columnProps.name || ""}
                            setColumnName={(newValue) => {
                                if (isAdmin) {
                                    updateProjectStateName({ variables: { id: columnProps.id, name: newValue } });
                                    trackevents({ category: "Project", action: "Update-column-name", name: newValue });
                                }
                            }}
                            editMode={isAdmin ? editMode : false}
                            setEditMode={setEditMode}
                            amount={columnProps.amountOfProjects}
                        />
                    </div>
                    {columnProps.name === "New" || columnProps.name === "Nieuw" ? <></> : isAdmin ? <ColumnMenu column={columnProps} /> : <></>}
                </div>
                <div>
                    <div style={{ height: `calc(100vh - 200px)`, overflowY: "auto" }}>{content}</div>
                </div>
            </Box>
        );
    };

    /**
     * When dropzone is active, the background color changes = classes.isActive
     * When item is over dropzone color will change so user gets feedback that its possible to drop = classes.canDrop
     * Background goes back to white color when user drops the user or cancels the drop action. = classes.root
     */
    const isActive = isOver && canDrop;

    if (loading) {
        return renderPage(
            Array.from(Array(columnProps.amountOfProjects)).map((_, i) => {
                return <LoadingProjectCard key={i} maxWidth={290} minWidth={111} staticMode={false} />;
            })
        );
    }

    return renderPage(
        <React.Fragment>
            {columnToShow?.map((project) => {
                return <DraggableProjectCard key={project.id} project={project} />;
            })}
        </React.Fragment>
    );
};

export default ColumnComponent;
