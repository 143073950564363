import { createContext, useContext } from "react";

export interface Option {
    id: number;
    label: any;
    key: any;
}
export type SearchCustomOptions = {
    customOptions: Option[];
    setCustomOptions: (customOptions: any) => void;
};

export const SearchCustomOptionsContext = createContext<SearchCustomOptions>({
    customOptions: [],
    setCustomOptions: (customOptions: any) => console.warn("no context provider"),
});
export const useCustomSearchOptions = () => useContext(SearchCustomOptionsContext);
