import React, { useContext, useState } from "react";
import { DynamicTab } from "../ProjectTabs";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FULL_SINGLE_PROJECT } from "../../../graphql/queryDefProjects";
import RenderPage from "../../../components/loading/RenderPage";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import Div404 from "../../Div404";
import setTitle, { setTitleLoading } from "../../../utils/setTitle";
import { useTranslation } from "react-i18next";
import { Box, Collapse, Divider, Grid } from "@mui/material";
import ProjectEntities from "../projectModal/ProjectEntities";
import ProjectTopNavBar from "../projectModal/ProjectTopNavBar";
import Tasks from "../projectModal/task/Tasks";

import TenderWidgetBox from "../projectModal/tender/TenderWidgetBox";
import CaWidgetBox from "../projectModal/ca/CaWidgetBox";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import Notes from "../projectModal/notes/Notes";
import { full_single_project, full_single_projectVariables } from "../../../__generated__/full_single_project";
import moment from "moment";

interface Props {
    registerTab: (tab: DynamicTab) => void;
}

const ProjectDetailPage: React.FC<Props> = ({ registerTab }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const projectID = id as string;
    const [normalTitle, setNormalTitle] = useState<string>("");
    const { project, changeProject } = useContext(ProjectToOpenContext);
    const [views, setViews] = useState<string[]>(["tasks", "notes", "entities"]);
    const [newAddedTenderFoundOrNull, setNewAddedTenderFoundOrNull] = useState<boolean>(false);

    const handleViews = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
        setViews(newFormats);
    };

    const { data, loading, error } = useQuery<full_single_project, full_single_projectVariables>(FULL_SINGLE_PROJECT, {
        variables: { id: projectID },
        onCompleted: (data) => {
            if (data && data.project) {
                registerTab({ value: `/projects/${projectID}`, label: data.project.title || "" });
                setNormalTitle(data.project.title || "");
                changeProject({
                    projectID: projectID,
                    projectTitle: data.project.title,
                    users:
                        data.project.users?.map((user) => ({
                            id: user.id,
                            name: user.employee.name || "",
                            initials: `${user.employee.givenname?.slice(0, 1)}${user.employee.familyname?.slice(0, 1)}`,
                        })) || [],
                    tender_ids: data.project.tenders?.map((i) => i?.id || "") || [],
                    ca_ids: data.project.contractingAuthorities?.map((i) => i?.id || "") || [],
                    notes: data.project.comments ?? [],
                    tasks: data.project.tasks ?? [],
                });

                if (data.project.reviewCancellation !== null) {
                    // date of cancel is before date of new added tender
                    setNewAddedTenderFoundOrNull(moment(data.project.reviewCancellation).isBefore(data.project.dateOfAddingTender));
                }
                if (data.project.reviewCancellation === null) {
                    setNewAddedTenderFoundOrNull(true);
                }
            }
        },
    });

    /**
     * If loading -> show fullpagespinner
     */
    if (loading) {
        setTitleLoading(t);
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    /**
     * If error or no data -> show div404
     */
    if (error || !data || !data.project) {
        setTitle(t("error-occurred"));
        return (
            <RenderPage>
                <Div404 url="/projects" />
            </RenderPage>
        );
    }

    /**
     * Set title in tab of browser
     */
    setTitle(normalTitle);

    return (
        <div style={{ margin: "6px", backgroundColor: "#ffffff", minHeight: "99%" }}>
            <div>
                {/* <Grid container columns={20} sx={{ minHeight: size ? "100vh" : "75vh" }}> */}
                <Grid container columns={20}>
                    <Grid item container columns={20}>
                        {/* Left panel */}
                        <Grid item xs={20} md={13} sx={{ padding: "16px" }}>
                            {/* Topbar with title and buttons */}
                            <ProjectTopNavBar handleViews={handleViews} views={views} loading={loading} />

                            {/* Task block */}
                            <Collapse in={views.includes("tasks")}>
                                <Tasks />
                            </Collapse>

                            {/* Note block */}
                            <Collapse in={views.includes("notes")}>
                                <Notes />
                            </Collapse>

                            {/* Coming soon docs */}
                            {/* {views.includes("docs") && <ProjectDocuments />} */}
                        </Grid>
                        {/* divider */}
                        <Divider orientation="vertical" flexItem sx={{ mr: "-1px", mt: "8px", mb: "8px" }} />

                        {/* Right panel wt */}
                        <Grid item xs={20} md={7} sx={{ padding: "16px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box component={"div"}>
                                {/* Tenders */}
                                <TenderWidgetBox
                                    tender_id={project.tender_ids[0]}
                                    newAddedTenderFoundOrNull={newAddedTenderFoundOrNull}
                                    project_id={projectID}
                                />

                                {/* Contracting authorities */}
                                <CaWidgetBox ca_id={project.ca_ids} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />

                {/*
                 * Entities of project
                 */}
                {views.includes("entities") && <ProjectEntities />}
            </div>
        </div>
    );
};

export default ProjectDetailPage;
