import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";

// Import Material UI
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

// Import components
import WidgetBox from "../../../components/WidgetBox";
import ContractOverallGraph from "./ContractOverallGraph";
import ContractGroupedGraph from "./ContractGroupedGraph";
import {
    EndingQuarterContracts,
    EndingQuarterContractsVariables,
    EndingQuarterContracts_getUserContractsInQuarter,
} from "../../../__generated__/EndingQuarterContracts";
import { EndingContracts_currentUser_endingContracts } from "../../../__generated__/EndingContracts";
import CountryFlagBox from "../../../components/boxes/CountryFlagBox";
import { Tooltip } from "@mui/material";
import { GET_ENDING_CONTRACTS_QUARTER } from "../../../graphql/queryDefinitions";

interface Props {
    a?: number;
}
const ContractGraphOverview: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    // chosen quarter for graph
    const [text, setText] = useState("Alles");
    // array with al available quarters
    const [quarterArray, setQuarterArray] = useState<(EndingContracts_currentUser_endingContracts | null)[]>([]);
    // string array with all quarters, set as option to choose in menu
    const [quarterData, setQuarterData] = useState<(EndingQuarterContracts_getUserContractsInQuarter | null)[]>([]);
    // state for open/close menu
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    /**
     * open popover underneath button, contains all available quarters
     */
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * close popover
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * @param item selected quarter from popover
     * setText -> show selected quarter in button as text
     * handleClose -> close popover
     */
    const handleItem = (item: string | null | undefined) => {
        if (item !== undefined && item !== null) {
            setText(item);
            handleClose();
        }
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    // create shadowcopy of array
    const arrayForSort = [...quarterArray];

    // Sort contracts
    const sortedContracts = arrayForSort.sort((a, b) => {
        // change string 'Q1 2022' to "2022 1", now sorting works first on year and then on number
        const stringA = `${a?.quarter?.split(" ").pop()} ${a?.quarter?.slice(1, 3)}`;
        const stringB = `${b?.quarter?.split(" ").pop()} ${b?.quarter?.slice(1, 3)}`;
        return moment(stringA) > moment(stringB) ? 1 : -1;
    });

    // Lazyquery to fetch quarter data from backend
    const [getQuarter, { loading: loadQuarter }] = useLazyQuery<EndingQuarterContracts, EndingQuarterContractsVariables>(
        GET_ENDING_CONTRACTS_QUARTER,
        {
            variables: {
                quarter: text,
            },
            fetchPolicy: "cache-and-network",
            onCompleted: (data) => {
                if (data && data.getUserContractsInQuarter) {
                    setQuarterData(data.getUserContractsInQuarter);
                }
            },
        }
    );

    return (
        <div id="contract-graph">
            <WidgetBox header={t("dashboardPage.EndingContracts")} highlight="blue">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {/* small dropdown menu */}
                    <div>
                        <Tooltip title="Alleen Nederlandse aanbestedingen" placement="left">
                            <Button
                                id="contract-graph-button"
                                variant="contained"
                                color="grey"
                                size="small"
                                sx={(theme) => ({
                                    height: 20,
                                    fontSize: "0.7rem",
                                })}
                                onClick={handleClick}
                            >
                                {text}
                                {text === "Alles" ? <CountryFlagBox code="NL" /> : null}
                            </Button>
                        </Tooltip>

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <MenuList dense autoFocusItem={open} style={{ padding: 0 }}>
                                <MenuItem id="1" onClick={() => handleItem("Alles")} selected={text === "Alles"}>
                                    {t("dashboardPage.All")} <CountryFlagBox code="NL" />
                                </MenuItem>
                                {sortedContracts.map((c, i) => {
                                    return (
                                        <MenuItem
                                            key={i}
                                            selected={text === c?.quarter}
                                            onClick={() => {
                                                handleItem(c?.quarter);
                                                getQuarter();
                                            }}
                                        >
                                            {c?.quarter}
                                        </MenuItem>
                                    );
                                })}
                            </MenuList>
                        </Popover>
                    </div>
                </div>
                {/* Show graph based on selected menuItem, default is linegraph */}
                <div style={{ height: 289 }}>
                    {text === "Alles" ? (
                        // Component contains line chart
                        <ContractOverallGraph setQuarterArray={setQuarterArray} />
                    ) : (
                        // Component contains grouped bar chart per quarter
                        // liked tenders or results of searchrule per quarter
                        <ContractGroupedGraph quarterArray={quarterData} loading={loadQuarter} chosenQuarter={text} />
                    )}
                </div>
            </WidgetBox>
        </div>
    );
};

export default ContractGraphOverview;
