export const currencyFormat = (currency: string | null | undefined) => {
    if (!currency) {
        return ""; // Return empty string if currency is null or undefined
    }

    switch (currency.toUpperCase()) {
        case "USD":
            return "USD";
        case "EUR":
            return "€";
        case "GBP":
            return "£";
        // Add more cases as needed
        case "HUF":
            return "Ft";
        default:
            return ""; // Default for unrecognized currencies
    }
};
