import React from "react";
import FaviconBox from "../FaviconBox";
import Typography from "@mui/material/Typography";
import { Skeleton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useQuery } from "@apollo/client";
import { GetClientAOfTender, GetClientAOfTenderVariables } from "../../__generated__/GetClientAOfTender";
import { GET_CLIENTA_OF_TENDER } from "../../graphql/queryDefinitions";

interface Props {
    tender_name: string;
    tender_id: string;
    location: "Dashboard" | "Calendar" | "Tender" | "Opportunity" | "Ca" | "Organizations" | "Help" | "Personal" | "Project";
}

export const TenderTitleWidth: React.FC<Props> = ({ tender_id, tender_name, location }) => {
    const { trackEvent } = useMatomo();
    const href = window.location.href;
    // Track click to tender detailpage
    const clickToTenderDetailPage = () => {
        trackEvent({ category: location, action: "tender-title-to-tender-detailpage", href: href });
    };

    // Track click to ca detailpage
    const clickToCaDetailPage = () => {
        trackEvent({ category: location, action: "from-tender-table-to-ca-detailpage", href: href });
    };

    // Fetch first 5 items of worklist to show on dashboardData
    const { loading, data } = useQuery<GetClientAOfTender, GetClientAOfTenderVariables>(GET_CLIENTA_OF_TENDER, {
        variables: {
            id: tender_id,
        },
        fetchPolicy: "network-only",
    });

    const CA = data?.tender?.clientA && data?.tender?.clientA.length > 0 ? data.tender.clientA[0] : null;
    const fallback = data?.tender?.published_opdrachtgever;

    return (
        <>
            {/*return tendertitle and second Contracting Authority.*/}
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div>
                    <FaviconBox favicon={CA?.favicon_resized ?? CA?.favicon ?? null} name={CA?.name ?? fallback ?? ""} color={"#F57117"} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* link to tenderdetailpage*/}
                    <Tooltip title={tender_name} placement="right">
                        <Typography style={{ display: "inline-flex" }}>
                            <Link
                                style={{
                                    maxHeight: "3em",
                                    lineHeight: "1.5em",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    width: "fit-content",
                                }}
                                id="tender-title"
                                to={`/tenders/${tender_id}`}
                                onClick={(e) => {
                                    // Prevent the row iself being clicked,
                                    e.stopPropagation();
                                    clickToTenderDetailPage();
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: tender_name,
                                }}
                            />
                        </Typography>
                    </Tooltip>

                    {/* if ca has an id, show link to page.*/}
                    {loading ? (
                        <Skeleton variant="text" sx={{ fontSize: "0.875rem" }} />
                    ) : CA !== null ? (
                        <Tooltip title={CA.name} placement="right">
                            <Link
                                style={{
                                    maxHeight: "1.5em",
                                    lineHeight: "1.5em",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    width: "fit-content",
                                    color: "#707070",
                                }}
                                id="tenderAD"
                                to={`/contracting-authorities/${CA.id}`}
                                onClick={clickToCaDetailPage}
                            >
                                {CA.name}
                            </Link>
                        </Tooltip>
                    ) : (
                        <Tooltip title={fallback ?? ""} placement="right">
                            <Typography
                                style={{
                                    maxHeight: "1.5em",
                                    lineHeight: "1.5em",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    width: "fit-content",
                                    color: "#707070",
                                }}
                            >
                                {fallback ?? ""}
                            </Typography>
                        </Tooltip>
                    )}
                </div>
            </div>
        </>
    );
};

export default TenderTitleWidth;
