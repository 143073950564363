import React, { useContext, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

// Drag and drop
import { useDrag } from "react-dnd";
import { Avatar, Badge, Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { Groups, Notes, TaskAlt } from "@mui/icons-material";
import TaskReadOnly from "./TaskReadOnly";
import { GetAllProjects_projects } from "../../../../__generated__/GetAllProjects";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import { useQuery } from "@apollo/client";
import { single_project_tasks, single_project_tasksVariables } from "../../../../__generated__/single_project_tasks";
import { SINGLE_PROJECT_TASKS } from "../../../../graphql/queryDefProjects";
import ConnectedEntitities from "./ConnectedEntitities";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface Props {
    project: GetAllProjects_projects;
}

const DraggableProjectCard: React.FC<Props> = ({ project }) => {
    const [showTasks, setShowTasks] = useState<boolean>(true);
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    /**
     * Drag User from list to team and get an alert when dropped in zone.
     * Item
     */
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "ITEM",
        item: { id: project.id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }));

    const { data } = useQuery<single_project_tasks, single_project_tasksVariables>(SINGLE_PROJECT_TASKS, {
        variables: {
            id: project.id,
        },
    });

    const opacity = isDragging ? 0.4 : 1;
    const cursorDrag = isDragging ? "grab" : "pointer";
    /**
     * Task to show for current user where completed = false
     */
    const currentUserAndNotCompletedYet =
        data?.project?.tasks
            ?.filter((task) => task.task_completed === false)
            .filter((task) => task.users?.find((t) => t.id === localStorage.getItem("user_id"))) || [];

    /**
     * Counter to show total amount of tasks bind to current user
     */
    const allCuTasks = data?.project?.tasks?.filter((task) => task.users?.find((t) => t.id === localStorage.getItem("user_id"))).length || 0;

    /**
     * Completed tasks for a single project
     */
    const allCompletedTasks = data?.project?.tasks?.filter((task) => task.task_completed === true).length || 0;

    return (
        <Card
            elevation={0}
            ref={drag}
            role="Box"
            onClick={() => {
                if (isMobile) {
                    navigate(`/projects/${project.id}`);
                } else {
                    changeProject({
                        projectID: project.id,
                        projectTitle: project.title,
                        state: project.state?.id as string,
                    });
                    setOpen(true);
                }
            }}
            style={{
                cursor: cursorDrag,
                opacity: opacity,
                display: "flex",
                flexDirection: "column",
                padding: "8px 8px 2px 8px",
                marginBottom: "8px",
            }}
        >
            {/*
             * Connected entities (colored bars on top of project card)
             */}
            <ConnectedEntitities project_id={project.id} />

            {/*
             * Title of project
             */}
            <div style={{ marginTop: "4px" }}>
                <Typography
                    variant="h6"
                    sx={{ display: "inline-flex", lineHeight: 1.2, fontSize: "0.875rem" }}
                    // onClick={() => {
                    //     changeProject({
                    //         projectID: project.id,
                    //         projectTitle: project.title,
                    //         state: project.state?.id as string,
                    //     });
                    //     setOpen(true);
                    // }}
                >
                    {project.title}
                </Typography>
            </div>
            <div style={{ height: "6px" }} />

            {/*
             * Showtask component
             */}
            {currentUserAndNotCompletedYet.length > 0 && (
                <TaskReadOnly showTasks={showTasks} setShowTasks={setShowTasks} tasks={currentUserAndNotCompletedYet || []} />
            )}

            {/*
             * Box with small icons and badges
             * - badge with amount of users
             * - badge with amount of tasks
             */}
            <Box
                sx={{
                    color: "#9e9e9e",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginBottom: "4px",
                    marginTop: "12px",
                }}
            >
                {/* Amount of users in project */}
                <Tooltip
                    disableInteractive
                    title={
                        <>
                            {project.users?.map((i) => {
                                return (
                                    <>
                                        <span>{i.employee.name}</span>
                                        <br />
                                    </>
                                );
                            })}
                        </>
                    }
                    placement="top"
                >
                    {project.users && project.users.length === 1 ? (
                        <Avatar
                            sx={{
                                height: "18px",
                                width: "18px",
                                fontSize: "9px",
                                marginRight: "12px",
                            }}
                        >
                            {project.users[0].employee.givenname?.slice(0, 1)}
                            {project.users[0].employee.familyname?.slice(0, 1)}
                        </Avatar>
                    ) : (
                        <Badge
                            sx={{
                                cursor: "pointer",
                                marginRight: "12px",
                                "& .MuiBadge-badge": {
                                    right: -2,
                                    top: 2,
                                    border: `2px solid #fff`,
                                    padding: "0 4px",
                                    fontSize: "9px",
                                    minWidth: "18px",
                                    height: "18px",
                                },
                            }}
                            badgeContent={project.amountUsers}
                            color="primary"
                        >
                            <Groups fontSize="small" />
                        </Badge>
                    )}
                </Tooltip>

                {/* Amount of tasks in project */}
                <Tooltip
                    disableInteractive
                    placement="top"
                    title={
                        <>
                            <>Taken totaal: {project.amountTasks}</>
                            <br />
                            <>Taken voltooid: {allCompletedTasks}</>
                            <br />
                            <>Mijn taken: {allCuTasks}</>
                        </>
                    }
                >
                    <Badge
                        sx={{
                            cursor: "pointer",
                            marginRight: "12px",
                            "& .MuiBadge-badge": {
                                right: -2,
                                top: 2,
                                border: `2px solid #fff`,
                                padding: "0 4px",
                                fontSize: "9px",
                                minWidth: "18px",
                                height: "18px",
                            },
                        }}
                        badgeContent={project.amountTasks}
                        color="primary"
                        onClick={() => setShowTasks(!showTasks)}
                    >
                        <TaskAlt fontSize="small" />
                    </Badge>
                </Tooltip>

                {/* Amount of comments in project */}
                {project.latestComment === null ? (
                    <Badge
                        sx={{
                            cursor: "pointer",
                            marginRight: "6px",
                            "& .MuiBadge-badge": {
                                right: -2,
                                top: 2,
                                border: `2px solid #fff`,
                                padding: "0 4px",
                                fontSize: "9px",
                                minWidth: "18px",
                                height: "18px",
                                width: "18px",
                            },
                        }}
                        badgeContent={project.amountNotes}
                        color="primary"
                    >
                        <Notes fontSize="small" />
                    </Badge>
                ) : (
                    <Tooltip
                        disableInteractive
                        placement="top"
                        title={
                            <>
                                <>Notities: {project.amountNotes}</>
                                <br />

                                <>Nieuwste: {moment(project.latestComment.created_at).format("LLL")}</>
                            </>
                        }
                    >
                        <Badge
                            sx={{
                                cursor: "pointer",
                                marginRight: "6px",
                                "& .MuiBadge-badge": {
                                    right: -2,
                                    top: 2,
                                    border: `2px solid #fff`,
                                    padding: "0 4px",
                                    fontSize: "9px",
                                    minWidth: "18px",
                                    height: "18px",
                                },
                            }}
                            badgeContent={project.amountNotes}
                            color="primary"
                        >
                            <Notes fontSize="small" />
                        </Badge>
                    </Tooltip>
                )}
            </Box>
        </Card>
    );
};

export default DraggableProjectCard;
