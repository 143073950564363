import React, { lazy, useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import MainLayout from "../../components/layout/MainLayout";
import { ThemeProvider } from "@mui/material";
import { pinkTheme } from "../../assets/styles";
import setTheme from "../../utils/setTheme";
import OrganizationDetailPage from "./OrganizationDetailPage";

import StarredOrganizations from "./starredOrganizations/StarredOrganizations";
import AllOrganizations from "./allOrganizations/AllOrganizations";
import NotFound404 from "../NotFound404";
import ModuleTabs, { DynamicTab } from "../../components/ModuleTabs";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useQuery } from "@apollo/client";
import { GetAdminState } from "../../__generated__/GetAdminState";
import { IS_USER_ADMIN } from "../../graphql/queryAdmin";

const OrganizationEnrichment = lazy(() => import("./OrganizationEnrichment"));
const OrganizationAdmin = lazy(() => import("./OrganizationAdmin"));
const OrganizationsModule = () => {
    useEffect(() => {
        setTheme("pink");
    });
    const history = useLocation();
    const navigate = useNavigate();
    const [tabs, setTabs] = useState<DynamicTab[]>([]);
    const { data: admin } = useQuery<GetAdminState>(IS_USER_ADMIN);

    const { trackPageView } = useMatomo();

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: "Organizations", // optional
            href: `https://tool.tender.guide${history.pathname}`, // optional
        });
    }, [trackPageView, history.pathname]);

    /**
     * Get tabs in session storage (market party) and store in state
     */
    useEffect(() => {
        if (sessionStorage.getItem("omt")) {
            setTabs(JSON.parse(sessionStorage.getItem("omt") || "[]"));
        }
    }, []);

    // /**
    //  * Register a new tab to the OpportunityModule
    //  */
    // --> add useCallback: import React, {useState, useCallback}
    const registerTab = useCallback((tab: DynamicTab) => {
        setTabs((prevTabs) => {
            // If the tab that is going to registered is not in the list yet
            // Add it
            if (!prevTabs.find((prevTab) => prevTab.value === tab.value)) {
                return [...prevTabs, tab];
            }
            return prevTabs;
        });
    }, []);

    /**
     * Handle closing the tab, and navigate to the last tab in the sequence
     */
    const closeTab = (url: string) => {
        setTabs((prevTabs) => {
            const newTabs = prevTabs.filter((tab) => tab.value !== url);
            // If active tender is the one thats been closed -> navigate to worklist
            // otherwise navigate to current pathname
            if (history.pathname.includes(url)) {
                navigate("/organizations");
            } else {
                navigate(history.pathname);
            }
            return newTabs;
        });
    };

    /**
     * Handle closing all tabs, and navigate to all organizations
     * Dont navigate if user is on the other default tab
     */
    const closeAllTab = () => {
        setTabs([]);
        if (history.pathname === "/organizations/starred") {
            navigate("/organizations/starred");
        } else {
            navigate("/organizations");
        }
    };

    /**
     * Array of default tabs
     */
    const defaultTabs = [
        { id: 1, route: "/organizations", label: "All" },
        { id: 2, route: "/organizations/starred", label: "StarMarked" },
    ];

    /**
     * update session storage when tabs change
     */
    useEffect(() => {
        sessionStorage.setItem("omt", JSON.stringify(tabs));
    }, [tabs]);

    return (
        <>
            <ThemeProvider theme={pinkTheme}>
                <MainLayout
                    page="organizations"
                    header={
                        <ModuleTabs
                            page="Organizations"
                            value={history.pathname}
                            onChange={(value) => navigate(value)}
                            onClose={closeTab}
                            tabs={tabs}
                            onCloseAll={closeAllTab}
                            defaultTabs={defaultTabs}
                        />
                    }
                >
                    <Routes>
                        {/* Organization Enrichment Routes */}
                        {admin?.currentUser.is_admin === true && <Route path="admin" element={<OrganizationAdmin />} />}
                        {admin?.currentUser.is_admin === true && <Route path="admin/new" element={<OrganizationEnrichment />} />}
                        {admin?.currentUser.is_admin === true && <Route path="admin/:id" element={<OrganizationEnrichment />} />}
                        <Route path="starred" element={<StarredOrganizations />} />
                        <Route path="" element={<AllOrganizations />} />
                        <Route path=":id/*" element={<OrganizationDetailPage registerTab={registerTab} />} />
                        <Route path="*" element={<NotFound404 url="/organizations" admin={admin?.currentUser.is_admin} />} />
                    </Routes>
                </MainLayout>
            </ThemeProvider>
        </>
    );
};

export default OrganizationsModule;
