import React from "react";
import SplitMetaOptionsNewFormat from "./SplitMetaOptionsNewFormat";
import SplitDetailMetaOptionalYears from "./SplitDetailMetaOptionalYears";

interface Props {
    tender_id: string;
    mp?: string;
}

const SplitMetaStartEndDateWrapper: React.FC<Props> = ({ tender_id, mp = undefined }) => {
    const renderSwitch = () => {
        switch (true) {
            case mp !== undefined:
                return <SplitDetailMetaOptionalYears tender_id={tender_id} mp={mp} />;

            // Fallback
            default:
                return <SplitMetaOptionsNewFormat tender_id={tender_id} />;
        }
    };

    return <div style={{ display: "flex", height: "100%" }}>{renderSwitch()}</div>;
};

export default SplitMetaStartEndDateWrapper;
