import React from "react";
import { ShareSelectionItem } from "./ShareTenderButton";
import { Avatar, Checkbox, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";

interface Props {
    arrayWithUsersOrTeamsToShare: ShareSelectionItem[];
    contact: {
        id: string;
        name: string;
        familyName: string;
    };
    onToggle: (e: any, value: ShareSelectionItem) => void;
    idOfConversation: string | undefined;
    username: string;
}

const UserListItem: React.FC<Props> = ({ arrayWithUsersOrTeamsToShare, contact, onToggle, idOfConversation, username }) => {
    return (
        <ListItemButton
            selected={Boolean(
                arrayWithUsersOrTeamsToShare.find((t) => t.id === contact.id) || arrayWithUsersOrTeamsToShare.find((t) => t.id === idOfConversation)
            )}
            sx={{ pl: 3, display: "flex", alignItems: "center" }}
            alignItems="flex-start"
            onClick={(e) => {
                if (idOfConversation !== undefined) {
                    onToggle(e, { id: idOfConversation, team: false, conversation: true });
                } else {
                    onToggle(e, { id: contact.id, team: false, conversation: false });
                }
            }}
        >
            <ListItemAvatar sx={{ mt: 0, minWidth: 0, marginRight: "16px" }}>
                <Avatar sx={{ width: 32, height: 32 }}>
                    <Typography>
                        {contact.name.slice(0, 1)}
                        {contact.familyName.slice(0, 1)}
                    </Typography>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={username} />
            <Checkbox
                size="small"
                edge="start"
                disableRipple
                checked={Boolean(
                    arrayWithUsersOrTeamsToShare.find((t) => t.id === contact.id) ||
                        arrayWithUsersOrTeamsToShare.find((t) => t.id === idOfConversation)
                )}
                inputProps={{ "aria-label": "primary checkbox" }}
            />
        </ListItemButton>
    );
};

export default UserListItem;
