import React, { useState } from "react";
import WidgetBox from "../WidgetBox";
import { useTranslation } from "react-i18next";
import BasicPublished from "./BasicPublished";
import LotsPublished from "./LotsPublished";
import WidgetBoxMenu from "../WidgetBoxMenu";
import MenuItem from "@mui/material/MenuItem";

interface Props {
    participants: any | null;
    status: string;
    highlight?: boolean;
    title?: boolean;
}
const AwardInfoPublished: React.FC<Props> = ({ participants, status, title, highlight }) => {
    const { t } = useTranslation();
    const obj = JSON.parse(participants);
    const checkForLots = obj.length > 0 && obj.map((i: any) => i.perceel_nummer);

    //submenu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            {/* // box with color of module and header */}
            {status !== "PROCUREMENT_UNSUCCESSFUL" && status !== "PROCUREMENT_DISCONTINUED" && (
                <WidgetBox
                    header={title === false ? "" : t("tenderPage.AwardInformation")}
                    highlight={highlight === false ? undefined : "pink"}
                    padding={0}
                    actions={
                        // TODO: create function to copy, add function to star all organizations
                        <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                            <MenuItem onClick={handleClose}>{t("AddStar")}</MenuItem>
                        </WidgetBoxMenu>
                    }
                >
                    {checkForLots.length > 1 ? <LotsPublished participants={obj} /> : <BasicPublished participants={obj} />}
                </WidgetBox>
            )}
        </React.Fragment>
    );
};

export default AwardInfoPublished;
