import { LocationOn } from "@mui/icons-material";
import { Box, Card, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import LeafletMap from "./map/LeafletMap";
import { EventData } from "./TendenzModule";

interface Props {
    locationData: EventData;
}

const LocationInfo: React.FC<Props> = ({ locationData }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Box py={4} px={4} sx={{ display: "flex", flexDirection: "column", position: "relative", overflow: "hidden" }}>
            <div id="location" />
            <Typography variant="h1" fontWeight={500} gutterBottom align="center" sx={{ margin: 5, fontSize: "3rem !important" }}>
                Locatie
            </Typography>

            {noMobile && (
                <Card
                    sx={{
                        position: "absolute",
                        width: "65px",
                        height: "65px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "12px",
                        right: "67px",
                        top: "216px",
                        background: "#fff",
                        zIndex: 25,
                    }}
                    elevation={3}
                >
                    <LocationOn sx={{ fontSize: "3rem", color: "#70a4b7" }} />
                </Card>
            )}

            <Grid container spacing={2} style={{ flexDirection: noMobile ? "row" : "column-reverse" }}>
                <Grid
                    item
                    xs={12}
                    md={5}
                    style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center", zIndex: 99 }}
                >
                    {noMobile && <div style={{ height: 150 }} />}
                    <Card sx={{ height: 350, width: noMobile ? "70%" : "100%", borderRadius: "16px" }}>
                        <img
                            src={locationData.locationImage1}
                            alt=""
                            style={{
                                objectFit: "cover",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                width: "100%",
                                objectPosition: "100% 100%",
                            }}
                        />
                    </Card>

                    <div style={{ height: "32px" }} />

                    <Card sx={{ height: 350, width: noMobile ? "70%" : "100%", borderRadius: "16px", left: noMobile ? 40 : 0, position: "relative" }}>
                        <img
                            src={locationData.locationImage2}
                            alt=""
                            style={{
                                objectFit: "cover",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                width: "100%",
                                objectPosition: "100% 15%",
                            }}
                        />
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={undefined}
                    md={1}
                    style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
                />

                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ marginBottom: "32px" }}>
                        <img
                            src={locationData.locationLogo}
                            alt="Weidehoeve"
                            width={noMobile ? 400 : "100%"}
                            height="auto"
                            style={{ margin: "16px 0px" }}
                        />
                        <Typography variant="h5" fontWeight={400} gutterBottom sx={{ marginTop: 0, width: noMobile ? "80%" : "100%" }}>
                            {locationData.locationDescription}
                        </Typography>

                        <Typography gutterBottom fontWeight={500} variant="h5" style={{ marginTop: "16px" }}>
                            Adresgegevens
                        </Typography>
                        <Typography variant="h5">{locationData.locationAddress}</Typography>
                        <Typography variant="h5">{locationData.locationPlace}</Typography>
                    </div>

                    <Card sx={{ borderRadius: "16px" }}>
                        <LeafletMap />
                    </Card>
                </Grid>
            </Grid>
            {noMobile && <div style={{ height: 100 }} />}

            {/* ICON PINPOINT */}
            <div
                style={{
                    position: "absolute",
                    top: "-50px",
                    right: "-300px",
                    height: "800px",
                    width: "800px",
                }}
            >
                <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-m9simb"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="LocationOnIcon"
                >
                    <path
                        fill="#70a4b714"
                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5"
                    />
                </svg>
            </div>

            {/* DOTS */}
            <div
                style={{
                    position: "absolute",
                    bottom: "60px",
                    left: "110px",
                    height: "500px",
                    width: "500px",
                }}
            >
                <svg id="10015.io" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <pattern
                            id="svg-pattern"
                            x="0"
                            y="0"
                            width="54"
                            height="54"
                            patternUnits="userSpaceOnUse"
                            patternTransform="translate(30, 30) rotate(0) skewX(0)"
                        >
                            <svg width="24" height="24" viewBox="0 0 100 100">
                                <g fill="#70a4b714" opacity="1">
                                    <circle cx="50" cy="50" r="50" />
                                </g>
                            </svg>
                        </pattern>
                    </defs>
                    <rect x="0" y="0" width="100%" height="100%" fill="rgba(255, 255, 255, 0)" />
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#svg-pattern)" />
                </svg>
            </div>
        </Box>
    );
};

export default LocationInfo;
