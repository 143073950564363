import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_CURRENT_USER_HAS_ID_STORED } from "../../graphql/queryDefUserAndTeams";
import { UserHasIdStored } from "../../__generated__/UserHasIdStored";

// Define the UIDType
export type UIDType = {
    userId: string | null;
    setUserId: (UID: string | null) => void;
};

// Create the context with a proper default value
const UserIdContext = createContext<UIDType>({
    userId: null,
    setUserId: () => console.warn("setUserId not provided"),
});

export const UIDProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [userId, setUserId] = useState<string | null>(localStorage.getItem("user_id"));
    const [localStorageChangeTrigger, setLocalStorageChangeTrigger] = useState<number>(0);

    const { data, refetch } = useQuery<UserHasIdStored>(QUERY_CURRENT_USER_HAS_ID_STORED, {
        // skip: userId === localStorage.getItem("user_id"),
    });

    // useEffect(() => {
    //     if (userId !== localStorage.getItem("user_id") && data && data.currentUser) {
    //         const fetchedUserId = data.currentUser.id;
    //         localStorage.setItem("user_id", fetchedUserId);
    //         setUserId(fetchedUserId);
    //     }
    // }, [data, userId]);

    useEffect(() => {
        // Listener for storage events
        const handleStorageChange = () => {
            // Trigger a refetch if localStorage changes
            setLocalStorageChangeTrigger((prev) => prev + 1);
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    useEffect(() => {
        if (data && data.currentUser) {
            const fetchedUserId = data.currentUser.id;

            if (fetchedUserId !== localStorage.getItem("user_id")) {
                localStorage.setItem("user_id", fetchedUserId);
                setUserId(fetchedUserId);
            }
        }
    }, [data, localStorageChangeTrigger]);

    useEffect(() => {
        // Refetch query when localStorageChangeTrigger changes
        refetch();
    }, [localStorageChangeTrigger, refetch]);

    return <UserIdContext.Provider value={{ userId, setUserId }}>{children}</UserIdContext.Provider>;
};

export default UIDProvider;
export const useUserId = () => useContext(UserIdContext);
