import { useQuery } from "@apollo/client";
import React from "react";
import WidgetBox from "../../../components/WidgetBox";
import { ALL_PROJECTS_WIDGET } from "../../../graphql/queryDefProjects";
import MyProjectsWidgetLoader from "./MyProjectsWidgetLoader";
import MyProjectsError from "./MyProjectsError";
import MyProjectsWidgetData from "./MyProjectsWidgetData";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { GetAllProjectsDashboard } from "../../../__generated__/GetAllProjectsDashboard";
import MatomoEvent from "../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    columnsInRow: number;
}

const MyProjectsWidget: React.FC<Props> = ({ columnsInRow }) => {
    const { data, loading, error } = useQuery<GetAllProjectsDashboard>(ALL_PROJECTS_WIDGET, {
        fetchPolicy: "network-only",
    });
    const { trackEvent } = useMatomo();

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const renderPage = (content: React.ReactNode) => (
        <WidgetBox
            header={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {/* {t("dashboardPage.UpdatesInterestingTenders")} */}
                        Mijn projecten
                    </div>
                    <Typography>
                        <Link
                            id="link-to-full-list-of-projects"
                            to="/projects"
                            onClick={() => {
                                track({ category: "Dashboard", action: "Click-go-to-projects-via-dashboard", href: window.location.href });
                            }}
                        >
                            Toon alle
                        </Link>
                    </Typography>
                </div>
            }
            highlight="grey"
            padding={0}
        >
            {content}
        </WidgetBox>
    );

    if (loading) {
        return renderPage(<MyProjectsWidgetLoader />);
    }

    // if error or empty -> show UpdateWidgetError component with given attributes
    if (error || !data || !data.projects) return renderPage(<MyProjectsError errorText={"Geen openstaande taken gevonden"} />);

    return renderPage(<MyProjectsWidgetData data={data.projects} columnsInRow={columnsInRow} />);
};

export default MyProjectsWidget;

//? //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////? PARENT COMPONENT /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//? //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
