import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { QUERY_CONTRACT_INFORMATION_FOR_CURRENT_TENDER } from "../../../graphql/queryDefinitions";
import { GetContractInfoForCurrentTender, GetContractInfoForCurrentTenderVariables } from "../../../__generated__/GetContractInfoForCurrentTender";
import { Disable } from "react-disable";
import WidgetBox from "../../WidgetBox";
import ContractLotsWidget from "../ContractLotsWidget";
import ContractWidget from "../ContractWidget";
import { mergeOptiesEinde } from "../../../utils/contracttimeline";
import { isTenderFieldsNull } from "../../../utils/contractCheckAllTenderDateProps";

interface Props {
    tender_id: string;
}

const TenderContractWidget: React.FC<Props> = ({ tender_id }) => {
    const { t } = useTranslation();

    const { loading, error, data } = useQuery<GetContractInfoForCurrentTender, GetContractInfoForCurrentTenderVariables>(
        QUERY_CONTRACT_INFORMATION_FOR_CURRENT_TENDER,
        {
            variables: { id: tender_id },
        }
    );

    if (loading) {
        return (
            <Disable disabled={true}>
                <WidgetBox loading={true} header={t("tenderPage.Contract")} highlight="blue" padding={0} />
            </Disable>
        );
    }
    if (error || !data || !data.tender) {
        return (
            <Disable disabled={true}>
                <WidgetBox header={t("tenderPage.Contract")} highlight="blue" padding={0} />
            </Disable>
        );
    }
    const tender = data.tender;

    // function has a bunch of fields to check before showing the contract widget
    if (isTenderFieldsNull(tender)) {
        return (
            <Disable disabled={true}>
                <WidgetBox header={t("tenderPage.Contract")} highlight="blue" padding={0} />
            </Disable>
        );
    }

    if (Boolean(tender.lots.length > 0)) {
        return (
            <ContractLotsWidget
                lots={tender.lots}
                tender_id={tender_id}
                contract_tender_level={tender.contract}
                opties={mergeOptiesEinde(tender.opties_einde)}
                datum_gunning={tender.datum_gunning}
            />
        );
    }

    return (
        <React.Fragment>
            {tender.contract || tender.published_looptijd_start || tender.published_looptijd_einde || tender.published_looptijd_beschrijving ? (
                <ContractWidget
                    contract={tender.contract}
                    start={tender.published_looptijd_start}
                    end={tender.published_looptijd_einde}
                    description={tender.published_looptijd_beschrijving}
                    interval={tender.published_looptijd_interval}
                    period={tender.published_looptijd_periode}
                    renew_desc={tender.displayPublications[0].renewal_descr}
                    next_looptijd_einde={tender.next_looptijd_einde}
                    looptijd_start={tender.looptijd_start}
                    looptijd_einde_incl_opties={tender.looptijd_einde_incl_opties}
                    looptijd_einde={tender.looptijd_einde}
                    status_contract={tender.status_contract}
                    tender_id={tender.id}
                    opties={mergeOptiesEinde(tender.opties_einde)}
                    datum_gunning={tender.datum_gunning}
                />
            ) : (
                <Disable disabled={true}>
                    <WidgetBox header={t("tenderPage.Contract")} highlight="blue" padding={0} />
                </Disable>
            )}
        </React.Fragment>
    );
};

export default TenderContractWidget;
