import React, { useEffect, useRef, useState } from "react";
import { Fade, IconButton, Paper, Popper, PopperProps, Tooltip } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { useMutation, useQuery } from "@apollo/client";
import { addWord, addWordVariables } from "../__generated__/addWord";
import { GetWordArray } from "../__generated__/GetWordArray";
import { MUTATION_ADD_SEARCHTERM } from "../graphql/mutationDefinitions";
import { QUERY_ALL_SEARCHTERMS } from "../graphql/queryDefCurrentUser";
interface Props {
    a?: number;
}
/**
 * Tooltip like Medium.com.
 * 2 functions: copy selection & add selection as searchterm
 */
const TooltipOnSelect: React.FC<Props> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [added, setAdded] = useState(false);
    const [copied, setCopied] = useState(false);
    const [selection, setSelection] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);
    const previousAnchorElPosition = useRef<DOMRect | undefined>(undefined);

    useEffect(() => {
        if (anchorEl) {
            if (typeof anchorEl === "object") {
                previousAnchorElPosition.current = anchorEl.getBoundingClientRect();
            } else {
                previousAnchorElPosition.current = anchorEl().getBoundingClientRect();
            }
        }
    }, [anchorEl]);

    /**
     * Mutation to add a word as searchterm
     */
    const [saveWord] = useMutation<addWord, addWordVariables>(MUTATION_ADD_SEARCHTERM);
    /**
     * Query to get all searchterms
     */
    const queryWordArray = useQuery<GetWordArray>(QUERY_ALL_SEARCHTERMS);

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
        setAdded(false);
        setCopied(false);
    };

    const handleMouseUp = () => {
        setAdded(false);
        setSelection("");
        const selection = window.getSelection();

        // Resets when the selection has a length of 0
        if (!selection || selection.anchorOffset === selection.focusOffset) {
            handleClose();
            return;
        }

        const getBoundingClientRect = () => {
            if (selection.rangeCount === 0 && previousAnchorElPosition.current) {
                setOpen(false);
                return previousAnchorElPosition.current;
            }
            return selection.getRangeAt(0).getBoundingClientRect();
        };

        /**
         * set selection to state
         */
        setSelection(selection.toString());
        /**
         * set open state to true to show the tooltip
         */
        setOpen(true);
        /**
         * set anchorEl to the selection
         */
        setAnchorEl({ getBoundingClientRect });
    };

    /**
     * Copy selection to clipboard
     */
    const copySelectionToClipboard = () => {
        if (selection !== "") {
            navigator.clipboard.writeText(selection);
            setCopied(true);
        }
        return;
    };

    /**
     * Check if selection contains only text (+specials) and numbers
     * Max 2 spaces, no special characters (- only)
     */
    const reg = new RegExp(/^[A-Za-zÀ-ȕ\d-]+(\s[A-Za-zÀ-ȕ\d-]*){0,2}$/).test(selection);

    /**
     * Check if selection is already a searchterm
     */
    const checkIfWordExists =
        queryWordArray.data &&
        reg &&
        queryWordArray.data.currentUser.keywords.some((item) => item.keyword.toLocaleLowerCase() === selection.toLocaleLowerCase())
            ? true
            : false;

    /**
     * Check if selection is a single word without special characters
     * If so, add it to the searchterms
     */
    const handleAdd = async () => {
        if (!reg) {
            setSelection("");
        }
        if (selection.length >= 3) {
            try {
                await saveWord({
                    variables: {
                        keyword: selection,
                    },
                    refetchQueries: [
                        QUERY_ALL_SEARCHTERMS, // DocumentNode object parsed with gql
                        "GetWordArray", // Query name
                    ],
                    awaitRefetchQueries: true,
                });
            } catch (e) {}
            setAdded(true);
        }
    };

    const id = open ? "virtual-element-popper" : undefined;

    // https://mui.com/material-ui/react-tooltip/#virtual-element
    return (
        <div onMouseLeave={handleClose}>
            <div aria-describedby={id} onMouseUp={handleMouseUp} onDoubleClick={handleMouseUp}>
                {children}
            </div>
            <Popper id={id} open={open} anchorEl={anchorEl} transition placement="top" style={{ zIndex: 9000 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={250}>
                        <Paper style={{ padding: 4, display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Tooltip placement="top" title={selection === "" || selection === " " ? "Kopiëren niet mogelijk" : "Kopieer selectie"}>
                                <div>
                                    <IconButton
                                        size="small"
                                        disabled={selection === "" || selection === " "}
                                        onClick={() => {
                                            copySelectionToClipboard();
                                        }}
                                    >
                                        <FileCopyOutlinedIcon fontSize="small" style={{ color: copied ? "green" : undefined }} />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            {added === false ? (
                                <Tooltip
                                    placement="top"
                                    title={
                                        selection.length < 3
                                            ? "Toevoegen als zoekwoord niet mogelijk"
                                            : checkIfWordExists
                                            ? "Reeds toegevoegd"
                                            : reg
                                            ? `${selection} toevoegen als zoekwoord`
                                            : "Toevoegen als zoekwoord niet mogelijk"
                                    }
                                >
                                    <div>
                                        <IconButton
                                            style={{ padding: 6 }}
                                            disabled={!reg || selection.length < 3 || checkIfWordExists}
                                            size="small"
                                            onClick={() => {
                                                handleAdd();
                                            }}
                                        >
                                            <AddBoxIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip placement="top" title="Toevoegen gelukt">
                                    <div>
                                        <IconButton size="small" style={{ padding: 6 }}>
                                            <CheckIcon fontSize="small" style={{ color: "green" }} />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            )}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </div>
    );
};

export default TooltipOnSelect;
