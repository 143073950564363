// Page-Global number
let counter = 1;

/**
 * Returns a page-global unique number
 */
export default (): number => {
    counter++;
    return counter;
};
