import React from "react";
import { styled } from "@mui/material/styles";

interface Props {
    a?: number;
}

const StyledPaper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const Dots = styled("div")(({ theme }) => ({
    width: "4px",
    height: "4px",
    backgroundColor: "#333",
    borderRadius: "50%",
    margin: "0 2px",
    animation: "moveUpDown 1.4s ease-in-out infinite", // Adjusted timing and easing function
}));

const keyframes = `
 @keyframes moveUpDown {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-4px); }
        }
`;

const TypingIndicator: React.FC<Props> = (props) => {
    return (
        <StyledPaper>
            <Dots style={{ animationDelay: "0s" }} />
            <Dots style={{ animationDelay: "0.3s" }} />
            <Dots style={{ animationDelay: "0.6s" }} />
            <style>{keyframes}</style>
        </StyledPaper>
    );
};

export default TypingIndicator;
