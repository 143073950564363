import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Card, CardActionArea, CircularProgress, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";

import { confirmTwoFactor, confirmTwoFactorVariables } from "../../../../../__generated__/confirmTwoFactor";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { ContentCopy, Download } from "@mui/icons-material";
import LockAnimation from "./LockAnimation";
import { useAuthSettings } from "./AuthSettingsContext";
import { VERIFY_2FA } from "../../../../../graphql/twoFAdefinitions";

interface Props {
    QRcodeURL: string;
    waitForQrCode: boolean;
    error: boolean;
    cancel: () => void;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

// Styled Paper component
const StyledPaper = styled("div")(({ theme }) => ({
    padding: `16px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const VerifyTwoFactor: React.FC<Props> = ({ QRcodeURL, waitForQrCode, error, setActiveStep }) => {
    const { enableMail, refetch, activateBoth, pw } = useAuthSettings();

    const [code, setCode] = useState<string[]>(new Array(6).fill("")); // Array for 6 digits
    const [state, setState] = useState<{ message: string; recovery_codes: string; received: boolean }>();
    const [copied, setCopied] = useState<boolean>(false);
    const [downloaded, setDownload] = useState<boolean>(false);
    // Explain mutation
    const [verify, { loading: loadVerify, error: errorVerify }] = useMutation<confirmTwoFactor, confirmTwoFactorVariables>(VERIFY_2FA);

    // Explain mutation handler
    const handleVerifyCode = async (code: string) => {
        const verificationCode = parseInt(code);
        if (errorVerify) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await verify({
                variables: {
                    code: verificationCode,
                },
                onCompleted: (data) => {
                    if (data && data.confirmTwoFactor) {
                        if (activateBoth) {
                            enableMail(pw);
                        }
                        setState({
                            message: data.confirmTwoFactor.message,
                            recovery_codes: data.confirmTwoFactor.recovery_codes,
                            received: true,
                        });
                        refetch();
                    }
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };
    /**
     * handle 6 digit code input
     * @param index index of number to fill in
     * @param value number to fill in
     */
    const handleChange = (index: number, value: string) => {
        // Allow only digits (0-9)
        if (/^\d*$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value; // Update the current index

            // Move to the next input if the current one is filled
            if (value && index < code.length - 1) {
                document.getElementById(`input-${index + 1}`)?.focus(); // Move focus to next input
            }

            // Set the new code
            setCode(newCode);

            // Check if all six digits are filled
            const isComplete = newCode.every((digit) => digit !== "") && newCode.length === 6;
            if (isComplete) {
                const joinedCode = newCode.join("");
                handleVerifyCode(joinedCode); // Trigger verification with the complete code
            }
        } else if (value === "") {
            // Allow backspace to clear the input
            const newCode = [...code];
            newCode[index] = "";
            setCode(newCode);
        }
    };

    // Function to download recovery codes as a .txt file
    const handleDownload = (codes: string[]) => {
        const allCodes = codes.join("\n"); // Join codes with a newline
        const blob = new Blob([allCodes], { type: "text/plain" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a"); // Create an anchor element
        a.href = url;
        a.download = "recovery_codes.txt"; // Set the file name
        document.body.appendChild(a); // Append anchor to body
        a.click(); // Trigger click
        document.body.removeChild(a); // Remove anchor from body
        URL.revokeObjectURL(url); // Clean up the URL object

        setDownload(true);
    };

    // Function to handle copying all recovery codes to clipboard
    const handleCopyAll = (codes: string[]) => {
        const allCodes = codes.join("\n"); // Join codes with a newline
        navigator.clipboard
            .writeText(allCodes)
            .then(() => {
                setCopied(true);
                toast.info("Herstelcodes gekopieerd naar klembord");
            })
            .catch((error) => {
                console.error("Failed to copy: ", error);
            });
    };

    // loading QR CODE
    if (waitForQrCode) {
        return (
            <StyledPaper>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div
                        style={{
                            height: "300px",
                            width: "300px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "8px",
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                height: "300px",
                                width: "300px",
                                backgroundColor: "#ffffffe8",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>

                        <QRCodeSVG value={""} size={256} />
                    </div>
                    <Skeleton variant="text" sx={{ fontSize: "1.7rem", width: "300px" }} />

                    <Skeleton variant="text" sx={{ fontSize: "0.875rem", margin: "16px 0px", width: "200px" }} />

                    <div style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "32px 0px" }}>
                        {code.map((digit, index) => (
                            <input
                                autoFocus={index === 0}
                                disabled
                                key={index}
                                type="text"
                                id={`input-${index}`}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                style={{
                                    width: "50px",
                                    height: "60px",
                                    textAlign: "center",
                                    fontSize: "24px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    marginRight: index === 2 ? "24px" : "0px",
                                }}
                                maxLength={1} // Limit input to 1 character
                            />
                        ))}
                    </div>
                </div>
            </StyledPaper>
        );
    }

    // error occured
    if (error) {
        return <div> :\</div>;
    }
    // already installed
    if (QRcodeURL === "Two-factor authentication is already enabled.") {
        return (
            <StyledPaper>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ height: "300px", width: "300px", display: "flex", alignItems: "center", justifyContent: "center", padding: "8px" }}>
                        <QRCodeSVG value={""} size={256} />
                    </div>
                    <Typography variant="h1">Verificatie in 2 stappen is al ingesteld</Typography>

                    <div style={{ fontSize: "0.875rem", margin: "16px 0px" }} />

                    <div style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "32px 0px" }}>
                        {code.map((digit, index) => (
                            <input
                                disabled
                                key={index}
                                type="text"
                                id={`input-${index}`}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                style={{
                                    width: "50px",
                                    height: "60px",
                                    textAlign: "center",
                                    fontSize: "24px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    marginRight: index === 2 ? "24px" : "0px",
                                }}
                                maxLength={1} // Limit input to 1 character
                            />
                        ))}
                    </div>
                </div>
            </StyledPaper>
        );
    }

    if (state?.received) {
        const recoveryCodes: string[] = JSON.parse(state.recovery_codes) || [];

        return (
            <StyledPaper>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column", width: "100%" }}>
                    <div style={{ height: "300px", width: "300px", display: "flex", alignItems: "center", justifyContent: "center", padding: "8px" }}>
                        <LockAnimation startAnimation={true} />
                    </div>

                    <Typography variant="h1">Gelukt!</Typography>
                    <Typography sx={{ margin: "16px 0px" }}>Hieronder de herstelsleutels.</Typography>
                    <Typography>Bewaar deze back-upcodes op een beveiligde, maar toegankelijke plaats.</Typography>
                    <Card
                        elevation={0}
                        sx={{
                            backgroundColor: "#eee",
                            width: "100%",
                            zIndex: 99,
                        }}
                    >
                        <CardActionArea
                            onClick={() => {
                                handleCopyAll(recoveryCodes);
                            }}
                        >
                            <div style={{ textAlign: "center", margin: "16px", display: "flex" }}>
                                <div style={{ textWrap: "balance", flex: "1" }}>{recoveryCodes.join(", ")}</div>
                                <Tooltip title="Kopiëren" placement="top">
                                    <IconButton>
                                        <ContentCopy sx={{ color: copied ? "#508c79" : "#465c84" }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Downloaden" placement="top">
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            handleDownload(recoveryCodes);
                                        }}
                                    >
                                        <Download sx={{ color: downloaded ? "#508c79" : "#465c84" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </CardActionArea>
                    </Card>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            sx={{ marginTop: "8px", zIndex: 9999 }}
                            size="small"
                            onClick={() => {
                                // cancel();
                                setActiveStep(0);
                            }}
                        >
                            Sluiten
                        </Button>
                    </div>
                </div>
            </StyledPaper>
        );
    }

    return (
        <StyledPaper>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <div style={{ height: "300px", width: "300px", display: "flex", alignItems: "center", justifyContent: "center", padding: "8px" }}>
                    <QRCodeSVG value={QRcodeURL} size={256} />
                </div>
                <Typography variant="h1">Scan de QR code</Typography>
                <Typography variant="body1" fontWeight={500} sx={{ margin: "16px 0px" }}>
                    Vul de code die in de authenticator app staat in.
                </Typography>
                <Typography>
                    Open de authenticator app op jouw smartphone. <br /> Klik vervolgens op het + plusteken en scan de QR-code.
                </Typography>
                <div style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "32px 0px" }}>
                    {code.map((digit, index) => (
                        <input
                            autoFocus={index === 0}
                            disabled={loadVerify}
                            key={index}
                            type="text"
                            id={`input-${index}`}
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            style={{
                                width: "50px",
                                height: "60px",
                                textAlign: "center",
                                fontSize: "24px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                marginRight: index === 2 ? "24px" : "0px",
                            }}
                            maxLength={1} // Limit input to 1 character
                        />
                    ))}
                </div>
            </div>
        </StyledPaper>
    );
};

export default VerifyTwoFactor;
