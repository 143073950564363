import React from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { ListItemAvatar, ListItemText, MenuItem, Skeleton, Typography } from "@mui/material";
// import DescriptionIcon from "@mui/icons-material/Description";
import TooltipOnNewLines from "../TooltipOnNewLines";

import { useTenderChat } from "../contextProviders/TenderChatContext";
import { gql, useQuery } from "@apollo/client";
import {
    GetChatNotification,
    GetChatNotificationVariables,
    GetChatNotification_conversation_conversation_group_conversationable_MarketParty,
    GetChatNotification_conversation_conversation_group_conversationable_OpportunityFile,
    GetChatNotification_conversation_conversation_group_conversationable_Organization,
    GetChatNotification_conversation_conversation_group_conversationable_Tender,
} from "../../__generated__/GetChatNotification";
// import { modelTypeOfNotification } from "../../utils/messenger";
import NewChatMessageNotificationContent from "./NewChatMessageNotificationContent";
import FaviconBox from "../FaviconBox";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(): void;
}

/**
 * Display a single notification row for the notification Dialog
 */
const NewChatmessageNotification: React.FC<Props> = ({ onClick, notification }) => {
    const { setOpenModal, setChatTarget } = useTenderChat();

    const idToFetchData = notification !== null && notification.data !== null ? (notification.data[0] as string) : "";

    const { loading, data: chat } = useQuery<GetChatNotification, GetChatNotificationVariables>(GET_DATA_FOR_CHAT_NOTIFICATION, {
        skip: notification === null || notification.data === null,
        variables: {
            id: idToFetchData,
        },
        fetchPolicy: "network-only",
    });

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (loading) {
        return (
            <MenuItem
                style={{
                    whiteSpace: "normal",
                    maxHeight: 250,
                    opacity: notification.read_at !== null ? 0.5 : undefined,
                }}
            >
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    if (chat === undefined) {
        return <></>;
    }

    return (
        <div>
            {(() => {
                switch (chat.conversation.conversation_group?.conversationable?.__typename) {
                    /*********************************
                     **** TENDER CHAT NOTIFACTIONS
                     *********************************/
                    case "Tender":
                        const chatTndr: GetChatNotification_conversation_conversation_group_conversationable_Tender =
                            chat.conversation.conversation_group.conversationable;
                        return (
                            <NewChatMessageNotificationContent
                                module={"Tender"}
                                read_at={notification.read_at}
                                onClick={() => {
                                    setChatTarget(
                                        `${chatTndr.id}`,
                                        "App\\Models\\Tender",
                                        `${chatTndr.namegiventocontract}` || "",
                                        `${idToFetchData}`
                                    );
                                    setOpenModal(true);
                                    onClick();
                                }}
                                messageToShow={
                                    <TooltipOnNewLines
                                        placement={"bottom-start"}
                                        text={
                                            chat.conversation.unreadChatMessages.length === 0
                                                ? `Nieuwe chatberichten: ${chatTndr.namegiventocontract}`
                                                : chat.conversation.unreadChatMessages.length === 1
                                                ? `Nieuw chatbericht: ${chatTndr.namegiventocontract}`
                                                : `${chat.conversation.unreadChatMessages.length} Nieuwe chatberichten: ${chatTndr.namegiventocontract}` ||
                                                  ""
                                        }
                                        variant={"body1"}
                                        lines={1}
                                        id={`T${notification.id as string}`}
                                        fontweight={notification.read_at === null ? 500 : "normal"}
                                        fontSize={14}
                                    />
                                }
                                secondaryMessage={
                                    <Typography
                                        style={{
                                            fontSize: 12,
                                            // When the nofitication is unread, the date-part of the notification is bold and accent colored
                                            color: undefined,
                                            // notification?.read_at ? blue : undefined,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FaviconBox
                                            favicon={
                                                chatTndr.clients.find((ca: any) => ca.pivot?.category === "A")?.favicon_resized ??
                                                chatTndr.clients.find((ca: any) => ca.pivot?.category === "A")?.favicon ??
                                                null
                                            }
                                            name={
                                                chatTndr.clients.find((ca: any) => ca.pivot?.category === "A")?.name ||
                                                chatTndr.published_opdrachtgever
                                            }
                                            color={"#F57117"}
                                        />{" "}
                                        <TooltipOnNewLines
                                            placement={"bottom-start"}
                                            text={
                                                chatTndr.clients.find((ca: any) => ca.pivot?.category === "A")?.name ||
                                                chatTndr.published_opdrachtgever ||
                                                ""
                                            }
                                            variant={"body1"}
                                            lines={1}
                                            id={`Tndr${idToFetchData}${chatTndr.clients.find((ca: any) => ca.pivot?.category === "A")?.id}`}
                                            fontweight={notification.read_at === null ? 500 : "normal"}
                                            fontSize={12}
                                        />
                                    </Typography>
                                }
                            />
                        );

                    /*********************************
                     **** OPPORTUNITY CHAT NOTIFACTIONS
                     *********************************/
                    case "OpportunityFile":
                        const chatOpp: GetChatNotification_conversation_conversation_group_conversationable_OpportunityFile =
                            chat.conversation.conversation_group.conversationable;
                        return (
                            <NewChatMessageNotificationContent
                                module={"Opportunity"}
                                read_at={notification.read_at}
                                onClick={() => {
                                    setChatTarget(
                                        `${chatOpp.id}`,
                                        "App\\Models\\Opportunity\\File",
                                        // change to title
                                        `${chatOpp.name}` || "",
                                        `${idToFetchData}`
                                    );
                                    setOpenModal(true);
                                    onClick();
                                }}
                                messageToShow={
                                    <TooltipOnNewLines
                                        placement={"bottom-start"}
                                        text={
                                            // change to title
                                            chat.conversation.unreadChatMessages.length === 0
                                                ? `Nieuwe chatberichten: ${chatOpp.name}`
                                                : chat.conversation.unreadChatMessages.length === 1
                                                ? `Nieuw chatbericht: ${chatOpp.name}`
                                                : `${chat.conversation.unreadChatMessages.length} Nieuwe chatberichten: ${chatOpp.name}` || ""
                                        }
                                        variant={"body1"}
                                        lines={1}
                                        id={`O${notification.id as string}`}
                                        fontweight={notification.read_at === null ? 500 : "normal"}
                                        fontSize={14}
                                    />
                                }
                                secondaryMessage={
                                    <Typography
                                        style={{
                                            fontSize: 12,
                                            // When the nofitication is unread, the date-part of the notification is bold and accent colored
                                            color: undefined,
                                            // notification?.read_at ? blue : undefined,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FaviconBox
                                            favicon={chatOpp.contractingAuthority.favicon_resized ?? chatOpp.contractingAuthority.favicon}
                                            name={chatOpp.contractingAuthority.name}
                                            color={"#F57117"}
                                        />{" "}
                                        <TooltipOnNewLines
                                            placement={"bottom-start"}
                                            text={chatOpp.contractingAuthority.name}
                                            variant={"body1"}
                                            lines={1}
                                            id={`C${notification.data ? notification.data[0] : ("" as string)}${chatOpp.contractingAuthority.id}`}
                                            fontweight={notification.read_at === null ? 500 : "normal"}
                                            fontSize={12}
                                        />
                                    </Typography>
                                }
                            />
                        );

                    /*********************************
                     **** CONTRACTING AUTHORITY CHAT NOTIFACTIONS
                     *********************************/
                    case "Organization":
                        const chatCa: GetChatNotification_conversation_conversation_group_conversationable_Organization =
                            chat.conversation.conversation_group.conversationable;
                        return (
                            <NewChatMessageNotificationContent
                                module={"ContractingAuthority"}
                                read_at={notification.read_at}
                                onClick={() => {
                                    setChatTarget(
                                        `${chatCa.id}`,
                                        "App\\Models\\ContractingAuthorityV4",
                                        // change to title
                                        `${chatCa.name}` || "",
                                        `${idToFetchData}`
                                    );
                                    setOpenModal(true);
                                    onClick();
                                }}
                                messageToShow={
                                    <TooltipOnNewLines
                                        placement={"bottom-start"}
                                        text={
                                            // change to title
                                            chat.conversation.unreadChatMessages.length === 0
                                                ? `Nieuwe chatberichten: ${chatCa.name}`
                                                : chat.conversation.unreadChatMessages.length === 1
                                                ? `Nieuw chatbericht: ${chatCa.name}`
                                                : `${chat.conversation.unreadChatMessages.length} Nieuwe chatberichten: ${chatCa.name}` || ""
                                        }
                                        variant={"body1"}
                                        lines={1}
                                        id={`CA${notification.id as string}`}
                                        fontweight={notification.read_at === null ? 500 : "normal"}
                                        fontSize={14}
                                    />
                                }
                                secondaryMessage={undefined}
                            />
                        );
                    /*********************************
                     **** TENDERER CHAT NOTIFACTIONS
                     *********************************/
                    case "MarketParty":
                        const chatMp: GetChatNotification_conversation_conversation_group_conversationable_MarketParty =
                            chat.conversation.conversation_group.conversationable;
                        return (
                            <NewChatMessageNotificationContent
                                module={"MarketParty"}
                                read_at={notification.read_at}
                                onClick={() => {
                                    setChatTarget(
                                        `${chatMp.id}`,
                                        "App\\Models\\MarketParty",
                                        // change to title
                                        `${chatMp.name}` || "",
                                        `${idToFetchData}`
                                    );
                                    setOpenModal(true);
                                    onClick();
                                }}
                                messageToShow={
                                    <TooltipOnNewLines
                                        placement={"bottom-start"}
                                        text={
                                            // change to title
                                            chat.conversation.unreadChatMessages.length === 0
                                                ? `Nieuwe chatberichten: ${chatMp.name}`
                                                : chat.conversation.unreadChatMessages.length === 1
                                                ? `Nieuw chatbericht: ${chatMp.name}`
                                                : `${chat.conversation.unreadChatMessages.length} Nieuwe chatberichten: ${chatMp.name}` || ""
                                        }
                                        variant={"body1"}
                                        lines={1}
                                        id={`MP${notification.id as string}`}
                                        fontweight={notification.read_at === null ? 500 : "normal"}
                                        fontSize={14}
                                    />
                                }
                                secondaryMessage={undefined}
                            />
                        );
                    default:
                        return null;
                }
            })()}
        </div>
    );
};

export default NewChatmessageNotification;

//? get name and favicon for single ca
export const GET_DATA_FOR_CHAT_NOTIFICATION = gql`
    query GetChatNotification($id: ID!) {
        conversation(id: $id) {
            hashid
            team {
                id
                name
            }
            users {
                id
                employee {
                    id
                    name
                }
            }
            conversation_group {
                id
                conversationable {
                    __typename
                    ... on Tender {
                        id
                        namegiventocontract
                        clients {
                            id
                            name
                            favicon
                            favicon_resized
                            pivot {
                                category
                            }
                        }
                        published_opdrachtgever
                    }
                    ... on OpportunityFile {
                        id
                        name
                        contractingAuthority {
                            id
                            name
                            favicon
                            favicon_resized
                        }
                    }
                    ... on Organization {
                        id
                        name
                    }
                    ... on MarketParty {
                        id
                        name
                    }
                }
            }
            unreadChatMessages {
                id
            }
            chat_messages {
                id
                message

                from {
                    id
                    name
                    email
                    employee {
                        id
                        givenname
                        middlename
                        familyname
                    }
                }
                send_at
            }
        }
    }
`;
