import React from "react";
import { Typography } from "@mui/material";

interface Props {
    snippets: any;
}

const SnippetField: React.FC<Props> = ({ snippets }) => {
    return (
        <React.Fragment>
            {snippets.map((snippet: any, i: number) => {
                return (
                    <Typography
                        key={i}
                        style={{ padding: "0px 8px 8px 0" }}
                        dangerouslySetInnerHTML={{
                            __html: `...${snippet}...`,
                        }}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default SnippetField;
