import React from "react";
import "./DevInfo.scss";

const DevInfo = () => {
    return (
        <div className="DevInfo">
            <div className="dev-info">
                <div className="header">
                    <h1>Developer info / Frontend</h1>
                </div>
                <div className="body">
                    <h3>Git</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Commit SHA:</td>
                                <td>
                                    <code>{process.env.REACT_APP_COMMIT_SHA || "no-commit-sha"}</code>
                                </td>
                            </tr>
                            <tr>
                                <td>Branch Name:</td>
                                <td>
                                    <code>{process.env.REACT_APP_BRANCH_NAME || "no-branch-name"}</code>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h3>Environment Variables</h3>
                    <div className="responsive-table">
                        <table>
                            <tbody>
                                {Object.entries(process.env).map(([key, value]) => (
                                    <tr key={key}>
                                        <td>
                                            <code>{key}</code>
                                        </td>
                                        <td>
                                            <code>{value}</code>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DevInfo;
