import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { Clear, Check, Help, DoneOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GET_WINNER_OF_TENDER_FOR_MP_COLUMN } from "../../graphql/queryDefForColumns";
import { GetWinnerForTenderColumn, GetWinnerForTenderColumnVariables } from "../../__generated__/GetWinnerForTenderColumn";

interface Props {
    tender_id: string;
    org_id: string;
}

const WinnerOfTenderForMPColumn: React.FC<Props> = ({ tender_id, org_id }) => {
    const { t } = useTranslation();

    /**
     * Get message data
     */
    const { data } = useQuery<GetWinnerForTenderColumn, GetWinnerForTenderColumnVariables>(GET_WINNER_OF_TENDER_FOR_MP_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    const lotsToCheck = tender.lots.map((lot) => lot.id);

    const getLotsOfCurrentMP =
        tender.bids !== null
            ? tender.bids.filter((bid) => lotsToCheck.includes(bid?.lot?.id as string)).filter((i) => i.marketParty.id === org_id)
            : [];

    const value =
        tender.status === "OPEN"
            ? "OPEN"
            : tender.status === "PRELIMINARY_AWARDED"
            ? "PreliminaryAwarded"
            : tender.status === "PARTICIPANTS_KNOWN"
            ? "PARTICIPANTS_KNOWN"
            : getLotsOfCurrentMP.filter((i) => i.winner === true).length > 0
            ? "lots"
            : tender.bids?.find((i) => i.marketParty.id === org_id)?.preliminaryAwarded
            ? "PreliminaryAwarded"
            : tender.bids?.find((i) => i.marketParty.id === org_id)?.winner;

    return (
        <React.Fragment>
            {(() => {
                /**
                 * Switch contains all states a tender could have, for each state a different component.
                 */
                switch (value) {
                    case false:
                        return (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Tooltip title={t<string>("Status.AnotherWinner")} placement="right">
                                    <Clear fontSize="small" style={{ color: "red", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                        );
                    case true:
                        return (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Tooltip title={t<string>("Status.Winner")} placement="right">
                                    <Check fontSize="small" style={{ color: "green", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                        );

                    case "lots":
                        return (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Tooltip
                                    title={
                                        <>
                                            <div> {t<string>("Status.Winner")}</div>
                                            <div>perceel {getLotsOfCurrentMP.map((lot: any) => lot.lot.number).join(", ")}</div>
                                        </>
                                    }
                                    placement="right"
                                >
                                    <Check fontSize="small" style={{ color: "green", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                        );

                    case "OPEN":
                        return (
                            <Tooltip
                                title={
                                    <>
                                        <div> {t<string>("Status.Unknown")}</div>
                                    </>
                                }
                                placement="right"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <Help fontSize="small" style={{ color: "orange", cursor: "pointer" }} />
                            </Tooltip>
                        );

                    case "PreliminaryAwarded":
                        return (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Tooltip title={t<string>("Status.PreliminaryAwarded")} placement="right">
                                    <DoneOutline fontSize="small" style={{ color: "green", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                        );

                    case "PARTICIPANTS_KNOWN":
                        return (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Tooltip
                                    title={
                                        <>
                                            <div>Ingeschreven bij aanbesteding</div>
                                            <div> {t<string>("Status.Unknown")}</div>
                                        </>
                                    }
                                    placement="right"
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <Help fontSize="small" style={{ color: "orange", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                        );

                    default:
                        return (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Tooltip
                                    title={t<string>("Status.Unknown")}
                                    placement="left"
                                    arrow
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <Help fontSize="small" style={{ color: "orange", cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                        );
                }
            })()}
        </React.Fragment>
    );
};

export default WinnerOfTenderForMPColumn;
