import { gql } from "@apollo/client";

/**
 * Query admin
 */
export const IS_USER_ADMIN = gql`
    query GetAdminState {
        currentUser {
            id
            is_admin
        }
    }
`;
