import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
    a?: number;
    onClose(): void;
    open: boolean;
    dialogTitle: string;
    dialogText: string;
}

const InformationDialog: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.dialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {t("advancedSearch.Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InformationDialog;
