import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import TradeNameModal from "../../../../components/organizationComponents/TradeNameModal";
import TopLinksModule from "../../../../components/organizationComponents/TopLinksModule";
import { GetOrganizationAllNames, GetOrganizationAllNamesVariables } from "../../../../__generated__/GetOrganizationAllNames";
import { QUERY_ORGANIZATION_NAMES_AND_INACTIVE_NAMES } from "../../../../graphql/queryDefOrg";

interface Props {
    mp_id: string;
}

const MPnames: React.FC<Props> = ({ mp_id }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const { loading, error, data } = useQuery<GetOrganizationAllNames, GetOrganizationAllNamesVariables>(
        QUERY_ORGANIZATION_NAMES_AND_INACTIVE_NAMES,
        { variables: { id: mp_id } }
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (loading || error || !data || !data.tenderer) return null;

    return (
        <React.Fragment>
            {data.tenderer.names.filter((n) => n.type === "trade_name").length > 0 && (
                <div style={{ listStyle: "none", paddingBottom: 20 }}>
                    <TopLinksModule Title="actieve handelsnamen">
                        {data.tenderer.names
                            .filter((n: { type: string }) => n.type === "trade_name")
                            .slice(0, 5)
                            .map((i) => {
                                return (
                                    <li key={i.id}>
                                        <Typography>{i.value}</Typography>
                                    </li>
                                );
                            })}
                        {data.tenderer.inactive_names.length > 0 ? (
                            <li>
                                <Typography
                                    sx={{
                                        hyperlink: {
                                            display: "inline",
                                            cursor: "pointer",
                                            color: "#1f4769",
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        },
                                    }}
                                    onClick={handleClickOpen}
                                >
                                    {t("tenderPage.showAll")}
                                </Typography>
                            </li>
                        ) : data.tenderer.names.length > 5 ? (
                            <li>
                                <Typography
                                    sx={{
                                        hyperlink: {
                                            display: "inline",
                                            cursor: "pointer",
                                            color: "#1f4769",
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        },
                                    }}
                                    onClick={handleClickOpen}
                                >
                                    {t("tenderPage.showAll")}
                                </Typography>
                            </li>
                        ) : null}
                    </TopLinksModule>
                </div>
            )}
            <TradeNameModal handleClose={handleClose} open={open} names={data.tenderer.names} inactive_names={data.tenderer.inactive_names} />
        </React.Fragment>
    );
};

export default MPnames;
