import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
// import GeneralSelector from "../../../../components/input/GeneralSelector";

// import DistributionSoloOption from "../../../../components/input/DistributionSoloOption";

interface Props {
    a?: number;
}

// activity trigger chipoptions
export const chipTrigger = [{ value: "AT_PUBLICATION_OPP" }];

/**
 * TODO: ADD MUTATION WHEN AVAILABLE IN GQL
 */
const OppDist: React.FC<Props> = () => {
    const { t } = useTranslation();

    const lightgreen = "#508c79";

    return (
        <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "column", alignItems: "center", flexBasis: "100%" }}
            data-tut="distribution_container"
        >
            <div style={{ width: "90%" }} data-tut="reactour__distOptions">
                <Typography variant="h2">
                    <span style={{ fontWeight: 700, color: lightgreen }}>{t("AT_PUBLICATION_OPP" as string)}</span> alle resultaten tonen in werklijst
                </Typography>

                <Divider style={{ marginTop: 8 }} />
            </div>
        </Grid>
    );
};

export default OppDist;
