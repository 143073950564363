import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Import components
import WidgetBox from "../WidgetBox";
import BasicEnriched from "./BasicEnriched";
import LotsEnriched from "./LotsEnriched";
import WidgetBoxMenu from "../WidgetBoxMenu";
import { Disable } from "react-disable";

// gql imports
import { useQuery } from "@apollo/client";
import { GetStarredOrganizations } from "../../__generated__/GetStarredOrganizations";
import _ from "lodash";
import LikeSelectedOrgs from "../LikeSelectedOrgs";
import {
    GetAwardInfoForCurrentTender_tender_lots,
    GetAwardInfoForCurrentTender_tender_bids,
    GetAwardInfoForCurrentTender_tender_looptijd_start,
    GetAwardInfoForCurrentTender_tender_next_looptijd_einde,
} from "../../__generated__/GetAwardInfoForCurrentTender";
import { QUERY_GETSTARREDORGS } from "../../graphql/queryDefCurrentUser";

interface Props {
    status: string;
    lots: GetAwardInfoForCurrentTender_tender_lots[];
    bids: GetAwardInfoForCurrentTender_tender_bids[] | null;
    awardDate: string | null;
    endDate: (GetAwardInfoForCurrentTender_tender_next_looptijd_einde | null)[] | null;
    startDate: (GetAwardInfoForCurrentTender_tender_looptijd_start | null)[] | null;
    lowest_bid: number | null;
    highest_bid: number | null;
    published_participants: any;
    highlight?: boolean;
    title?: boolean;
}
const AwardInfoEnriched: React.FC<Props> = ({
    status,
    lots,
    bids,
    awardDate,
    endDate,
    highest_bid,
    lowest_bid,
    published_participants,
    startDate,
    highlight,
    title,
}) => {
    const { t } = useTranslation();
    //submenu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    /**
     * Open submenu on click dots
     */
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Close function to close submenu after execute function
     */
    const handleClose = () => {
        setAnchorEl(null);
    };
    /**
     * query for starred tenderers
     */
    const { error, data } = useQuery<GetStarredOrganizations>(QUERY_GETSTARREDORGS);

    /**
     * check if error occured
     */
    if (error || data?.currentUser?.market_parties === undefined || data?.currentUser?.market_parties === null) {
        return null;
    }

    const starredORGS = data.currentUser.market_parties;

    // create array of organization id's in bids
    const bidIds = _.map(bids, "marketParty.id");

    if (bids === null) {
        return null;
    }

    const jsonData = JSON.parse(published_participants);
    const amountOfParticipants = jsonData.length > 1 ? jsonData[0]["aantal_inschrijvingen"] : jsonData["aantal_inschrijvingen"];
    // check if bid has lot.id -> if not return false
    // if lot.id is available return lots widget otherwise basic widget
    const checkLotIDinBids = bids.filter((x) => x.lot?.id !== undefined).filter((x) => x.lot?.id !== null).length > 0 ? true : false;

    return (
        <React.Fragment>
            {/* // box with color of module and header */}
            {(status === "PROCUREMENT_DISCONTINUED" || status === "OPEN" || status === "PROCUREMENT_UNSUCCESSFUL") && bids.length > 0 ? (
                <WidgetBox
                    header={title === false ? "" : t("tenderPage.AwardInformation")}
                    highlight={highlight === false ? undefined : "pink"}
                    padding={0}
                    actions={
                        // TODO: create function to copy, add function to star all organizations
                        highlight !== false && (
                            <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                                <LikeSelectedOrgs selection={bidIds} menuItem onClose={handleClose} />
                            </WidgetBoxMenu>
                        )
                    }
                >
                    <div style={{ maxHeight: "70vh", overflow: "auto" }}>
                        {lots.length === 0 || checkLotIDinBids === false ? (
                            // component with bids only
                            <BasicEnriched
                                bids={bids}
                                awardDate={awardDate}
                                endDate={endDate !== null ? endDate[0]?.value : null}
                                startDate={startDate !== null ? startDate[0]?.value : null}
                                lowest_bid={lowest_bid}
                                highest_bid={highest_bid}
                                starredOrgs={starredORGS}
                                published_participants_count={amountOfParticipants}
                            />
                        ) : (
                            // component with lots and bids in a lot
                            <LotsEnriched
                                bids={bids}
                                lots={lots}
                                awardDate={awardDate}
                                startDate={startDate}
                                endDate={endDate}
                                starredOrgs={starredORGS}
                            />
                        )}
                    </div>
                </WidgetBox>
            ) : status !== "PROCUREMENT_UNSUCCESSFUL" ? (
                <WidgetBox
                    header={title === false ? "" : t("tenderPage.AwardInformation")}
                    highlight={highlight === false ? undefined : "pink"}
                    padding={0}
                    actions={
                        // TODO: create function to copy, add function to star all organizations
                        highlight !== false && (
                            <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                                <LikeSelectedOrgs selection={bidIds} menuItem onClose={handleClose} />
                            </WidgetBoxMenu>
                        )
                    }
                >
                    <div style={{ maxHeight: "70vh", overflow: "auto" }}>
                        {lots.length === 0 || checkLotIDinBids === false ? (
                            // component with bids only
                            <BasicEnriched
                                bids={bids}
                                awardDate={awardDate}
                                endDate={endDate !== null ? endDate[0]?.value : null}
                                startDate={startDate !== null ? startDate[0]?.value : null}
                                lowest_bid={lowest_bid}
                                highest_bid={highest_bid}
                                starredOrgs={starredORGS}
                                published_participants_count={amountOfParticipants}
                            />
                        ) : (
                            // component with lots and bids in a lot
                            <LotsEnriched
                                bids={bids}
                                lots={lots}
                                awardDate={awardDate}
                                startDate={startDate}
                                endDate={endDate}
                                starredOrgs={starredORGS}
                            />
                        )}
                    </div>
                </WidgetBox>
            ) : (
                <Disable disabled={true}>
                    <WidgetBox
                        header={title === false ? "" : t("tenderPage.AwardInformation")}
                        highlight={highlight === false ? undefined : "pink"}
                        padding={0}
                    />
                </Disable>
            )}
        </React.Fragment>
    );
};

export default AwardInfoEnriched;
