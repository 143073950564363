import React from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Check } from "@mui/icons-material";
// import { toast } from "react-toastify";

interface Props {
    open: boolean;
    onCloseAlert(): void;
    allItems: any;
    load: boolean;
    complete: boolean;
    saveSelectedDates(): void;
    onCloseAfterComplete(): void;
}

// const useStyles = makeStyles((theme: Theme) => ({
// subText: {
//     color: "#707070",
//     marginRight: "16px",
//     marginTop: 0,
// },
// text: {
//     color: "#707070",
//     marginRight: "4px",
// },

// selectField: {
//     textDecoration: "underline",
//     // maxWidth: 130,
//     paddingTop: 4,
// },
// selectFieldIcon: {
//     display: "none",
// },

//     centerCal: {
//         display: "flex",
//         alignItems: "start",
//     },
//     [theme.breakpoints.down("sm")]: {
//         text: {
//             display: "inline-block",
//             marginRight: 5,
//             marginLeft: 5,
//             paddingBottom: 4,
//         },
//         centerCal: {
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//         },
//         subText: {
//             marginBottom: 8,
//         },
//     },
// }));

const ScheduleDialog: React.FC<Props> = ({ open, onCloseAlert, allItems, load, complete, saveSelectedDates, onCloseAfterComplete }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    // const personalInfo = useQuery<GetCurrentUserNameToShow>(QUERY_CURRENT_USER_NAME);
    const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    // const [reminder, setReminder] = useState("10");
    // const [agenda, setAgenda] = useState<string[]>(["1"]);

    // const handleChangeAgenda = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     setAgenda(event.target.value as string[]);
    // };

    // const handleChangeReminder = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     setReminder(event.target.value as string);
    // };

    return (
        <Dialog open={open} onClose={onCloseAlert} fullWidth maxWidth="md">
            <DialogTitle>{t("tenderPage.AddToAgenda")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={{ padding: 0, display: "flex", flexDirection: "column" }}>
                        {allItems.map(({ id, planned, name, timeSignificant }: any) => (
                            <Paper elevation={2} key={id} style={{ marginBottom: "12px" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: onlySmallScreen ? "column" : "row",
                                    }}
                                >
                                    <div>
                                        {/* Timestamp:
                                         * - if 00:00 or timeSignificant = false => show name only
                                         * - else show timestamp
                                         */}
                                        <Typography style={{ margin: "5px" }}>
                                            {moment(planned).format("HH:mm") === "00:00" ? (
                                                <span>{name}</span>
                                            ) : timeSignificant ? (
                                                <span>
                                                    {moment(planned).format("HH:mm")}
                                                    {" - "}
                                                    {name}
                                                </span>
                                            ) : (
                                                <span>{name}</span>
                                            )}
                                        </Typography>
                                        <div style={{ display: "flex", flexDirection: onlySmallScreen ? "column" : "row" }}>
                                            <Typography
                                                sx={{
                                                    color: "#707070",
                                                    marginRight: "16px",
                                                    marginLeft: "5px",
                                                    marginTop: 0,
                                                    marginBottom: onlySmallScreen ? 8 : undefined,
                                                }}
                                            >
                                                {/* Show date with timestamp if timeSignificant = true */}
                                                {timeSignificant ? moment(planned).format("LLL") : moment(planned).format("LL")}
                                            </Typography>
                                            {/* <div className={classes.centerCal}>
                                                    <Typography className={classes.text}>{t("tenderPage.Reminder")}: </Typography>
                                                    <Select
                                                        classes={{ root: classes.selectField, icon: classes.selectFieldIcon }}
                                                        disableUnderline
                                                        value={reminder}
                                                        onChange={handleChangeReminder}
                                                    >
                                                        <MenuItem value={10}>{t("tenderPage.10minutes")}</MenuItem>
                                                        <MenuItem value={15}>{t("tenderPage.15minutes")}</MenuItem>
                                                        <MenuItem value={30}>{t("tenderPage.30minutes")}</MenuItem>
                                                    </Select>
                                                </div> */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: onlySmallScreen ? "row" : undefined,
                                                    alignItems: onlySmallScreen ? "center" : "start",
                                                }}
                                            >
                                                {/**********
                                                 * Chosen calendar to save dates to
                                                <Typography
                                                        sx={{
                                                            color: "#707070",
                                                            display: onlySmallScreen ? "inline-block" : undefined,
                                                            marginRight: onlySmallScreen ? "5px" : "4px",
                                                            marginLeft: onlySmallScreen ? "5px" : 0,
                                                            paddingBottom: onlySmallScreen ? "4px" : 0,
                                                        }}
                                                    >
                                                        {t("tenderPage.Agenda")}: {personalInfo.data?.currentUser.employee.givenname}
                                                    </Typography>
                                                **********/}
                                                {/**********
                                                 * select component with calendar options to choose from
                                                 <FormControl>
                                                        <Select
                                                            classes={{ root: classes.selectField, icon: classes.selectFieldIcon }}
                                                            disableUnderline
                                                            value={agenda}
                                                            onChange={handleChangeAgenda}
                                                            multiple
                                                        >
                                                            <MenuItem value={1}>Eigen agenda</MenuItem>
                                                            <MenuItem value={2}>Team A agenda</MenuItem>
                                                            <MenuItem value={3}>Google agenda</MenuItem>
                                                        </Select>
                                                    </FormControl> 
                                                **********/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        ))}
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between", padding: "8px 24px" }}>
                <div>
                    {complete && (
                        <Typography sx={{ alignSelf: "center", display: "flex", alignItems: "center" }}>
                            <Check sx={{ color: "#225E4D", marginRight: "8px" }} />
                            Items zijn toegevoegd aan je agenda
                        </Typography>
                    )}
                </div>
                <div>
                    {!complete && <Button onClick={onCloseAlert}>{t("tenderPage.Cancel")}</Button>}
                    {!complete ? (
                        <Button variant="contained" disabled={load || allItems.length === 0} onClick={saveSelectedDates}>
                            {t("tenderPage.Add")} {load && <CircularProgress color="inherit" sx={{ marginLeft: "4px" }} size={14} />}
                        </Button>
                    ) : (
                        <Button variant="contained" onClick={onCloseAfterComplete}>
                            Sluiten
                        </Button>
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default ScheduleDialog;
