import { useMediaQuery, useTheme } from "@mui/system";
import React from "react";

interface Props {
    a?: number;
    modalView?: boolean;
}

const WhiteBubbleSection: React.FC<Props> = ({ modalView = false }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const extralarge = useMediaQuery(theme.breakpoints.up("xxl"));

    return (
        <div
            className="custom-shape-divider-top-1720602832"
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                overflow: "hidden",
                lineHeight: 0,
                transform: `scaleX(-1)`,
            }}
        >
            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
                style={{
                    position: "relative",
                    display: "block",
                    width: `calc(140% + 1.3px)`,
                    height: extralarge && modalView === false ? "450px" : noMobile ? "450px" : "130px",
                    transform: `rotateY(180deg)`,
                }}
            >
                <path d="M0,0V6c0,21.6,291,111.46,741,110.26,445.39,3.6,459-88.3,459-110.26V0Z" className="shape-fill" style={{ fill: "#fff" }} />
            </svg>
        </div>
    );
};

export default WhiteBubbleSection;
