import React from "react";
import Grid from "@mui/material/Grid";
import PurchasingPolicyLinks from "./PurchasingPolicyLinks";
import ValueTypesAndAmount from "./ValueTypesAndAmount";

interface Props {
    id: string;
}

// TODO: connect to GQL
// TODO: add translation
// TODO: link doorlopen niet nowrap
// TODO: centreer div met icon en titel

export interface DocumentItem {
    url: string;
}

const PurchasingPolicy: React.FC<Props> = ({ id }) => {
    return (
        <React.Fragment>
            <Grid container xs={12} style={{ padding: 24 }}>
                {/* Left column */}
                <PurchasingPolicyLinks id={id} />

                {/* 
                Grid with values
            */}
                <Grid item container xs={12} md={12}>
                    <ValueTypesAndAmount id={id} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default PurchasingPolicy;
