import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import setTitle from "../../../utils/setTitle";
import { useTranslation } from "react-i18next";
import { MUIDataTableState } from "mui-datatables";
import OrganizationSearch from "../OrganizationSearch";
import StarredOrganizationTable, { starredOrgColumns } from "./StarredOrganizationTable";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { ColumnContext } from "../../../components/contextProviders/ColumnContext";
import { SortOrder, TenderersFilterSource, OrganizationsFilterInput, TenderersSearchInput } from "../../../__generated__/globalTypes";
import {
    FilterMyTenderers,
    FilterMyTenderersVariables,
    FilterMyTenderers_filterTenderers_results,
    FilterMyTenderers_filterTenderers_filters,
} from "../../../__generated__/FilterMyTenderers";
import { ColumnProvider } from "../../../components/contextProviders/ColumnProvider";
import ModuleInformation from "../../../components/ModuleInfoBox/ModuleInformation";
import MpContent from "../../../components/ModuleInfoBox/MpContent";
import { QUERY_MY_TENDERERS } from "../../../graphql/queryDefOrg";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CardView from "./CardView";

const filters: OrganizationsFilterInput = {};
const defaultSearch: TenderersSearchInput = { source: TenderersFilterSource.MY_TENDERERS, filters };
const defaultVariables: FilterMyTenderersVariables = {
    page: 1,
    count: 10,
    orderField: "last_update_date_timestamp",
    order: SortOrder.DESC,
    search: defaultSearch,
};

// TODO: possibility to search with searchbox. now commented because its not available yet in GQL for organizationmodel, connect filters of market parties
const StarredOrganizations: React.FC = () => {
    const [page, setPage] = useState<number | null | undefined>(defaultVariables.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.count);
    const [orderField, setOrderField] = useState<string>(defaultVariables.orderField);
    const [orderDir, setOrderDir] = useState<SortOrder>(defaultVariables.order);
    const [searchInput, setSearchInput] = useState<OrganizationsFilterInput>({});
    const { t } = useTranslation();
    const [query, setQuery] = useState<string>("");
    const [source] = useState<TenderersFilterSource>(defaultSearch.source);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterData, setFilterData] = useState<FilterMyTenderers_filterTenderers_filters[] | null>([]);
    const [tenderersData, setTenderersData] = useState<FilterMyTenderers_filterTenderers_results | null>();
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("md"));

    // temporary
    useEffect(() => {
        if (sessionStorage.getItem("smpp")) {
            // set current page to sessionstorage value or 1 if sessionstorage isn't set
            setPage(parseInt(JSON.parse(sessionStorage.getItem("smpp") || "1")));
        }
    }, []);

    // Variables for the GQL query
    const variables: FilterMyTenderersVariables = {
        page: page,
        count: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            source: source,
            query: query,
            filters: searchInput,
        },
    };

    // Define the GQL query
    const [run, { data, loading, error }] = useLazyQuery<FilterMyTenderers, FilterMyTenderersVariables>(QUERY_MY_TENDERERS, {
        variables: variables,
        fetchPolicy: "network-only",
    });
    useEffect(() => {
        if (data && data.filterTenderers && data.filterTenderers.results && data.filterTenderers.results.data) {
            setFilterData(data.filterTenderers.filters);
            setTenderersData(data.filterTenderers.results);
        }
    }, [data]);

    // Set page title
    useEffect(() => {
        setTitle(t("CompetitorsWithStar"));
        sessionStorage.setItem("smpp", JSON.stringify(page));

        run({
            variables: {
                page: page,
                count: rowsPerPage,
                orderField: orderField,
                order: orderDir,
                search: {
                    source: source,
                    query: query,
                    filters: searchInput,
                },
            },
        });
    }, [run, orderDir, orderField, page, rowsPerPage, searchInput, t, query, source]);

    const handlePaginationChange = (page: number, rows: number) => {
        setPage(page);
        setRowsPerPage(rows);
        if (rowsPerPage !== rows) {
            setPage(1);
        }
    };

    const handleTableChange = (_: string, tableState: MUIDataTableState) => {
        if (tableState.sortOrder.direction) setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
        if (tableState.sortOrder.name) setOrderField(tableState.sortOrder.name);
    };

    const renderPage = (content: React.ReactNode) => (
        <ColumnProvider storeKey="cumsc" defaultState={starredOrgColumns}>
            <ColumnContext.Provider value={{ selectedRows, setSelectedRows }}>
                <Box component="div" sx={{ padding: { xs: "20px 8px", md: "20px" } }}>
                    <OrganizationSearch
                        query={query}
                        filterData={filterData}
                        setQuery={setQuery}
                        resetPage={() => setPage(1)}
                        loading={loading}
                        searchInput={searchInput}
                        onChange={setSearchInput}
                        startSearch={() => {
                            setPage(1);
                            run({ variables });
                        }}
                        resetFilters={() => {
                            setSearchInput({});
                            setPage(1);
                        }}
                    />
                    <div style={{ marginTop: 12 }} />

                    {content}
                </Box>
            </ColumnContext.Provider>
            <ModuleInformation title={"Marktpartijen"}>
                <MpContent />
            </ModuleInformation>
        </ColumnProvider>
    );

    // If state is empty and loading is true, show fullpagespinner
    // only initial load, if tendersData has data, linearloader is shown.
    if (!tenderersData && loading) return renderPage(<FullPageSpinner />);

    // if error occurs or state is empty -> show error
    if (error || !tenderersData)
        return renderPage(
            <StarredOrganizationTable
                loading={loading}
                rows={[]}
                paginatorInfo={{
                    count: 0,
                    currentPage: 0,
                    hasMorePages: false,
                    firstItem: 0,
                    lastItem: 0,
                    lastPage: 1,
                    perPage: 10,
                    total: 0,
                    __typename: "PaginatorInfo",
                }}
                onChangePagination={handlePaginationChange}
                onTableChange={handleTableChange}
            />
        );

    if (!noMobile) {
        return renderPage(
            <CardView
                type={"mp"}
                data={tenderersData?.data.map((row) => ({
                    id: row.id,
                    name: row.name ?? "",
                    favicon: row.favicon ?? "",
                    country: row.country?.alpha2 ?? "",
                    org_number: row.kvknumber ?? "",
                    last_update: row.last_update_date,
                    place:
                        row.settlings?.data.find((i) => (i.type === "hoofdvestiging" ? i : i.type === "rechtspersoon" ? i : null))?.visit_address
                            ?.city ?? "",
                }))}
                loading={loading}
                paginatorInfo={
                    tenderersData?.paginatorInfo
                        ? tenderersData?.paginatorInfo
                        : {
                              count: 0,
                              currentPage: 0,
                              hasMorePages: false,
                              firstItem: 0,
                              lastItem: 0,
                              lastPage: 1,
                              perPage: 10,
                              total: 0,
                              __typename: "PaginatorInfo",
                          }
                }
                onChangePagination={handlePaginationChange}
            />
        );
    }

    return renderPage(
        <React.Fragment>
            <StarredOrganizationTable
                loading={loading}
                rows={tenderersData?.data}
                paginatorInfo={
                    tenderersData?.paginatorInfo
                        ? tenderersData?.paginatorInfo
                        : {
                              count: 0,
                              currentPage: 0,
                              hasMorePages: false,
                              firstItem: 0,
                              lastItem: 0,
                              lastPage: 1,
                              perPage: 10,
                              total: 0,
                              __typename: "PaginatorInfo",
                          }
                }
                onChangePagination={handlePaginationChange}
                onTableChange={handleTableChange}
            />
        </React.Fragment>
    );
};

export default StarredOrganizations;
