import React from "react";
import { Checkbox, Divider, FormControlLabel, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { Mail, Notifications } from "@mui/icons-material";
import GeneralSelector from "../../../../components/input/GeneralSelector";
import {
    RemindersPreferenceInput,
    RemindersPreferenceMomentInput,
    RemindersPreferencePeriodInput,
    TenderModulePreferences,
} from "../../../../__generated__/globalTypes";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";

interface Props {
    reminder: RemindersPreferenceInput | null;
    settings: TenderModulePreferences;
    saveSettings: (settings: TenderModulePreferences) => void;
    setChanged: (value: React.SetStateAction<boolean>) => void;
    i: number;
}

const DeadlineReminder: React.FC<Props> = ({ reminder, settings, saveSettings, setChanged, i }) => {
    const { t } = useTranslation();

    return (
        <Paper variant="outlined" sx={{ padding: "8px" }}>
            <Typography sx={{ fontWeight: "bold" }}>{t("Reminder")} deadline</Typography>
            <ListItem sx={{ padding: 0 }}>
                <ListItemIcon style={{ minWidth: 0, marginRight: "8px" }}>
                    <Notifications fontSize="small" />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        color: (theme) => (reminder?.email || reminder?.notification ? "#000000" : `${"#000000"}61`),
                                        marginRight: "8px",
                                    }}
                                >
                                    Melding
                                </Typography>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {/* Week selector */}
                                <div style={{ marginTop: "-2px" }}>
                                    <GeneralSelector
                                        openStep={false}
                                        type="tender"
                                        onSelect={(e) => {
                                            saveSettings(
                                                update(settings, {
                                                    reminders: {
                                                        [i]: {
                                                            interval: { $set: parseInt(e) },
                                                            period: { $set: "WEEKS" as RemindersPreferencePeriodInput },
                                                        },
                                                    },
                                                })
                                            );
                                            setChanged(true);
                                        }}
                                        values={[
                                            { value: "1", text: "1 week" },
                                            { value: "2", text: "2 weken" },
                                            { value: "3", text: "3 weken" },
                                        ]}
                                        selectedValue={`${reminder?.interval?.toString()} ${reminder?.interval === 1 ? "week" : "weken"}`}
                                    />
                                </div>
                                {/* before/after selector */}
                                <div style={{ marginTop: "-2px" }}>
                                    <GeneralSelector
                                        openStep={false}
                                        type="tender"
                                        values={[
                                            { value: RemindersPreferenceMomentInput.BEFORE, text: `${t("before")}` },
                                            { value: RemindersPreferenceMomentInput.AFTER, text: `${t("after")}` },
                                        ]}
                                        onSelect={(e) => {
                                            saveSettings(
                                                update(settings, {
                                                    reminders: {
                                                        [i]: {
                                                            moment: { $set: e as RemindersPreferenceMomentInput },
                                                        },
                                                    },
                                                })
                                            );
                                            setChanged(true);
                                        }}
                                        selectedValue={`${t(reminder?.moment?.toLowerCase() as string)}` || t("before")}
                                    />
                                </div>
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        color: (theme) => (reminder?.email || reminder?.notification ? "#000000" : `${"#000000"}61`),
                                        marginLeft: "10px",
                                    }}
                                >
                                    {t("deadline")}
                                </Typography>
                            </div>
                        </div>
                    }
                />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <FormControlLabel
                        sx={{ marginLeft: 0, marginRight: 0 }}
                        control={
                            <Checkbox
                                size="small"
                                disabled
                                checked={reminder?.notification}
                                // TODO:change to reminder -> notification
                                onChange={(e) => {
                                    saveSettings(
                                        update(settings, {
                                            reminders: {
                                                [i]: {
                                                    notification: { $set: e.target.checked },
                                                },
                                            },
                                        })
                                    );
                                    setChanged(true);
                                }}
                                name={reminder?.event}
                            />
                        }
                        label=""
                    />
                </div>
            </ListItem>
            <Divider />
            <ListItem sx={{ padding: 0 }}>
                <ListItemIcon style={{ minWidth: 0, marginRight: "8px" }}>
                    <Mail fontSize="small" />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div>
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        color: (theme) => (reminder?.email || reminder?.notification ? "#000000" : `${"#000000"}61`),
                                        marginRight: "8px",
                                    }}
                                >
                                    Mail
                                </Typography>
                            </div>
                            {/* Week selector */}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ marginTop: "-2px" }}>
                                    <GeneralSelector
                                        openStep={false}
                                        type="tender"
                                        onSelect={(e) => {
                                            saveSettings(
                                                update(settings, {
                                                    reminders: {
                                                        [i]: {
                                                            interval: { $set: parseInt(e) },
                                                            period: { $set: "WEEKS" as RemindersPreferencePeriodInput },
                                                        },
                                                    },
                                                })
                                            );
                                            setChanged(true);
                                        }}
                                        values={[
                                            { value: "1", text: "1 week" },
                                            { value: "2", text: "2 weken" },
                                            { value: "3", text: "3 weken" },
                                        ]}
                                        selectedValue={`${reminder?.interval?.toString()} ${reminder?.interval === 1 ? "week" : "weken"}`}
                                    />
                                </div>
                                {/* before/after selector */}
                                <div style={{ marginTop: "-2px" }}>
                                    <GeneralSelector
                                        openStep={false}
                                        type="tender"
                                        values={[
                                            { value: RemindersPreferenceMomentInput.BEFORE, text: `${t("before")}` },
                                            { value: RemindersPreferenceMomentInput.AFTER, text: `${t("after")}` },
                                        ]}
                                        onSelect={(e) => {
                                            saveSettings(
                                                update(settings, {
                                                    reminders: {
                                                        [i]: {
                                                            moment: { $set: e as RemindersPreferenceMomentInput },
                                                        },
                                                    },
                                                })
                                            );
                                            setChanged(true);
                                        }}
                                        selectedValue={`${t(reminder?.moment?.toLowerCase() as string)}` || t("before")}
                                    />
                                </div>
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        color: (theme) => (reminder?.email || reminder?.notification ? "#000000" : `${"#000000"}61`),
                                        marginLeft: "10px",
                                    }}
                                >
                                    {t("deadline")}
                                </Typography>
                            </div>
                        </div>
                    }
                />
                <FormControlLabel
                    sx={{ marginLeft: 0, marginRight: 0 }}
                    control={
                        <Checkbox
                            size="small"
                            checked={reminder?.email}
                            onChange={(e) => {
                                saveSettings(
                                    update(settings, {
                                        reminders: {
                                            [i]: {
                                                email: { $set: e.target.checked },
                                            },
                                        },
                                    })
                                );
                                setChanged(true);
                            }}
                            name={reminder?.event}
                        />
                    }
                    label=""
                />
            </ListItem>
        </Paper>
    );
};

export default DeadlineReminder;
