import React from "react";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import Select from "@mui/material/Select";
import { MenuItem, Grid, Paper, createTheme, Theme, ThemeProvider, Pagination } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { TenderEventColumns } from "./EventColumns";
import { useLocation } from "react-router-dom";
import LinearLoader from "../../../components/loading/LinearLoader";
import { Disable } from "react-disable";
import {
    FilterInterestingMinimumTendersOverview_filterTenders_results_data,
    FilterInterestingMinimumTendersOverview_filterTenders_results_paginatorInfo,
} from "../../../__generated__/FilterInterestingMinimumTendersOverview";

interface Props {
    /**
     * Data to be displayed in table
     */
    rows: FilterInterestingMinimumTendersOverview_filterTenders_results_data[];
    /**
     * Pagination info
     */
    paginatorInfo: FilterInterestingMinimumTendersOverview_filterTenders_results_paginatorInfo;
    /**
     * onChange page or rows per page
     */
    onChangePagination(page: number, rowsPerPage: number): void;
    /**
     * change in show/hide columns
     */
    onTableChange: MUIDataTableOptions["onTableChange"];
    /**
     * Loading state
     */
    loading: boolean;
    /**
     * Apollo error
     */
    error?: ApolloError | undefined;
}

const getMuiTheme = (theme: Theme) =>
    createTheme({
        palette: {
            primary: {
                main: "#173357",
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro, system-ui, sans-serif",
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },
        components: {
            // MUIDataTable: {
            //     tableRoot: {
            //         width: "100% !important",
            //     },
            // },

            // Hide toolbar
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "none !important",
                    },
                },
            },

            // Custom hovercolor: opacity 7% -> add 12 to hexcode
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: `${"#173357"}12 !important`,
                        },
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        textAlign: "start",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: 8,
                    },
                },
            },
            // MUIDataTableHeadCell: {
            //     data: {
            //         textAlign: "start",
            //     },
            // },
        },
    });

const EventView: React.FC<Props> = ({ rows, paginatorInfo, onChangePagination, onTableChange, loading, error }) => {
    // const amountPages = Math.ceil(paginatorInfo.total / paginatorInfo.perPage);
    const amountPages = Math.ceil(paginatorInfo.total / paginatorInfo.perPage);
    const { t } = useTranslation();
    const loc = useLocation();

    /**
     * Data reformat definition, each container is a columnsection in table.
     */
    const convertedData = rows.map((item) => {
        const container: any = {};
        const id = "id";
        const tender = "tender";
        const contractingAuthorities = "contractingAuthorities";
        const favicon = "favicon";
        const ca_id = "ca_id";
        const deadline_inschrijving = "deadline_inschrijving";
        const type_procedure = "type_procedure";
        const type = "type";
        const status = "status";
        const next_looptijd_einde = "next_looptijd_einde";
        const looptijd_start = "looptijd_start";
        const looptijd_einde = "looptijd_einde";
        const published_vestigingsplaats = "published_vestigingsplaats";
        const country = "country";
        const published_adres = "published_adres";
        const published_postcode = "published_postcode";
        const datum_gunning = "datum_gunning";
        const published_host = "published_host";
        const status_contract = "status_contract";
        const published_contactpersoon = "published_contactpersoon";
        const published_email = "published_email";
        const published_telefoon = "published_telefoon";
        const first_dispatchdate = "first_dispatchdate";
        const looptijd_einde_incl_opties = "looptijd_einde_incl_opties";
        const sector_name = "sector_name";
        const subsector_name = "subsector_name";
        const updated_at = "updated_at";
        const type_update = "type_update";
        const market_party_name = "market_party_name";
        const bid = "bid";
        const optionsMonth = "optionsMonth";
        const contractDescription = "contractDescription";
        const initialDuration = "initialDuration";
        const userStatus = "userStatus";
        const searches = "searches";
        const update_text = "update_text";
        // Get client with CA category 'A'
        const clientA = item.clients.find((ca) => ca.pivot?.category === "A");
        // get unique sectornames

        // tender properties
        container[id] = item.id;
        container[tender] = item.namegiventocontract;
        container[type_procedure] = item.id;
        container[type] = item.id;
        container[status] = item.status;
        container[country] = item.country?.alpha2 ? item.country.alpha2 : null;
        container[status_contract] = item.id;
        container[sector_name] = item.id;
        container[subsector_name] = item.id;
        container[type_update] = item.id;
        container[optionsMonth] = item.id;
        container[contractDescription] = item.id;
        container[initialDuration] = item.id;
        // ca properties of tender
        container[contractingAuthorities] =
            clientA?.name !== undefined ? clientA?.name : item.published_opdrachtgever !== null ? item.published_opdrachtgever : "";
        container[ca_id] = clientA?.id;
        container[favicon] = clientA?.favicon_resized ?? clientA?.favicon;
        container[userStatus] = item.userStatus;

        // Datestamps of tender -> fallback is empty string (empty field)
        container[deadline_inschrijving] = item.deadline_inschrijving ? moment(item.deadline_inschrijving).format("L LT") : "";
        container[looptijd_start] = item.id;
        container[looptijd_einde] = item.id;
        container[next_looptijd_einde] = item.id;
        container[datum_gunning] = item.id;
        container[looptijd_einde_incl_opties] = item.id;
        container[updated_at] = item.id;
        container[first_dispatchdate] = item.id;

        // published fields of tender
        container[published_vestigingsplaats] = item.id;
        container[published_adres] = item.id;
        container[published_postcode] = item.id;
        container[published_host] = item.id;
        container[published_contactpersoon] = item.id;
        container[published_telefoon] = item.id;
        container[published_email] = item.id;
        container[searches] = item.searchresults.map((i: any) => i);
        // tender bids properties ((x,i,arr)=>(i<arr.length-1)?x+',':x)
        container[update_text] = item.type_update;
        container[market_party_name] = item.id;
        container[bid] = item.id;
        return container;
    });

    const options: MUIDataTableOptions = {
        elevation: 0,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        filterType: "checkbox",
        draggableColumns: {
            enabled: false,
            transitionTime: 200,
        },
        serverSide: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: "none",
        // onRowSelectionChange: onRowSelectionChange,
        // rowsSelected: selectedRows.map((id: string) => convertedData.findIndex((item) => item.id === id)),
        // onColumnSortChange: onOrderChange,
        fixedSelectColumn: false,
        responsive: "standard",
        selectableRows: loc.pathname === "/tenders" ? "none" : "multiple",

        /**
         * Textual helpers with translation
         * Header tooltip text
         */
        textLabels: {
            body: {
                noMatch: t("TableHelpers.NoMatch"),
                toolTip: t("TableHelpers.Sort"),
                columnHeaderTooltip: (column) => `${t(`Column_Header.${column.label}`)}`,
            },
        },
        customFooter() {
            return (
                <Grid container spacing={2} style={{ padding: 20 }} alignItems="center" justifyContent="space-between">
                    {/* Left section
                     *  number of total results found
                     */}
                    <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                        {paginatorInfo?.total === 0 ? 0 : paginatorInfo?.firstItem?.toLocaleString()}-{paginatorInfo?.lastItem?.toLocaleString()}{" "}
                        {t("of")} {paginatorInfo?.total.toLocaleString()}
                    </Grid>

                    {/* Middle section,
                     *  Show amount of pages with back/next arrow
                     */}
                    <Grid item sm={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Pagination
                            page={paginatorInfo.currentPage}
                            count={amountPages}
                            onChange={(_, page) => onChangePagination(page, paginatorInfo.perPage)}
                        />
                    </Grid>

                    {/* Right section
                     *  Selectable field with option to show more/less rows per page
                     */}
                    <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <Select
                            variant="standard"
                            value={paginatorInfo.perPage}
                            onChange={(e) => onChangePagination(paginatorInfo.currentPage, e.target.value as number)}
                            style={{ marginRight: 4 }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>

                        {t("resultsPerPage")}
                    </Grid>
                </Grid>
            );
        },
        onTableChange,
    };

    return (
        <React.Fragment>
            <LinearLoader loading={loading} color="blue" />
            <Disable disabledOpacity={0.6} disabled={loading}>
                <Paper square>
                    {/* Added paper element around table to use the square variant instead of the default, 
            - in MuidatatableOptons: elevation -> 0 */}
                    <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable title="Interessante aanbestedingen" data={convertedData} columns={TenderEventColumns} options={options} />
                    </ThemeProvider>
                </Paper>
            </Disable>
        </React.Fragment>
    );
};

export default EventView;
