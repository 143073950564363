import React, { useEffect } from "react";
import { Container, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useLocation } from "react-router-dom";
// import ComingSoon from "../../components/comingSoon/ComingSoon";

interface Props {
    title: React.ReactNode;
    componentToPassDown?: React.ReactNode;
    parentOverflow?: boolean;
}

const ProfileLayout: React.FC<Props> = ({ parentOverflow = true, children, title, componentToPassDown }) => {
    const { pathname } = useLocation();
    const { trackPageView } = useMatomo();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: "Profile", // optional
            href: `https://tool.tender.guide${pathname}`, // optional
        });
    }, [trackPageView, pathname]);

    return (
        <React.Fragment>
            <Container
                maxWidth="xl"
                sx={(theme) => ({
                    marginTop: "60px",
                    [theme.breakpoints.up("md")]: {
                        marginTop: "30px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        marginTop: "20px",
                    },
                })}
            >
                <Grid
                    sx={(theme) => ({
                        paddingTop: "10px",
                        paddingRight: "15px",
                        paddingLeft: "20px",
                        [theme.breakpoints.up("md")]: {
                            paddingTop: "10px",
                            paddingRight: "15px",
                            paddingLeft: "20px",
                        },
                        [theme.breakpoints.down("sm")]: {
                            paddingTop: "0px",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                        },
                    })}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {title}
                        <div style={{ display: "flex", alignItems: "center" }}>{componentToPassDown}</div>
                    </div>
                    <Divider />
                </Grid>
                <Grid
                    sx={(theme) => ({
                        paddingTop: "10px",
                        paddingRight: "15px",
                        paddingLeft: "20px",
                        [theme.breakpoints.up("md")]: {
                            paddingTop: "10px",
                            paddingRight: "15px",
                            paddingLeft: "20px",
                        },
                        [theme.breakpoints.down("sm")]: {
                            paddingTop: "0px",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                        },
                    })}
                    style={{
                        marginTop: 14,
                        marginBottom: 14,
                        height: isMobile ? "75svh" : "80vh",
                        overflow: parentOverflow ? "auto" : undefined,
                    }}
                >
                    {children}
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default ProfileLayout;
