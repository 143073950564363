import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    MenuItem,
    Checkbox,
    ListItemIcon,
    Typography,
    Grid,
} from "@mui/material";
import { GridInput, SortOrder, TenderFilterInput, TenderFilterSource, TenderFilterTarget } from "../../__generated__/globalTypes";
import { useMutation, useQuery } from "@apollo/client";
import { ExportChosenColumns, ExportChosenColumnsVariables } from "../../__generated__/ExportChosenColumns";
import { useLocation } from "react-router-dom";
import LinearLoader from "../loading/LinearLoader";
import { Disable } from "react-disable";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { currentUserEmail } from "../../__generated__/currentUserEmail";
import { GET_USER_EMAIL } from "../../graphql/queryDefCurrentUser";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { EXPORT_CHOSEN_COLUMNS } from "../../graphql/queryDefinitions";

interface Props {
    onClose: () => void;
    dialogTitle: string;
    dialogText: string;
    open: boolean;
    columnExport: GridInput;
    searchInput: TenderFilterInput;
    query: string;
    target: TenderFilterTarget;
    order: SortOrder;
    orderField: string;
    columns: any[];
}

const TenderExportModal: React.FC<Props> = ({
    onClose,
    open,
    dialogTitle,
    dialogText,
    columnExport,
    searchInput,
    order,
    orderField,
    query,
    target,
    columns,
}) => {
    const [columnsToExport, setColumnsToExport] = useState<any[]>([]);
    const [completed, setCompleted] = useState<boolean>(false);
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [checked, setChecked] = useState(false);

    const { data: email } = useQuery<currentUserEmail>(GET_USER_EMAIL);

    const { trackEvent } = useMatomo();

    // Track export request
    const trackTenderExport = () => {
        if (pathname === "/tenders/reviews") {
            trackEvent({ category: "Tender", action: "Request-export-tender-reviewed" } as MatomoEvent);
        }

        if (pathname === "/tenders/worklist") {
            trackEvent({ category: "Tender", action: "Request-export-tender-worklist" } as MatomoEvent);
        }
    };

    const tenderSource =
        pathname === "/tenders/reviews"
            ? TenderFilterSource.ALL_TENDERS
            : pathname === "/tenders/worklist"
            ? TenderFilterSource.WORKLIST
            : TenderFilterSource.ALL_TENDERS;

    useEffect(() => {
        setColumnsToExport([]);
        // Tender reviewtable possible columns to export
        if (columnExport === "TENDER_REVIEWS") {
            const removeExcludedColumns = columns
                .filter((i: any) => i.options.display !== "excluded")
                .filter((i: any) => i.label !== "Actions")
                .filter((i: any) => i.label !== "Status")
                .filter((i: any) => i.name !== "tender")
                .filter((i: any) => i.name !== "market_party_name")
                .filter((i: any) => i.name !== "status_contract")
                .filter((i: any) => i.label !== "Reviews")
                .filter((i: any) => i.label !== "Subsector_name")
                .filter((i: any) => i.label !== "initialDuration")
                .filter((i: any) => i.label !== "OptionsMonth")
                .filter((i: any) => i.label !== "bid")
                .filter((i: any) => i.label !== "ContractDescription")
                .filter((i: any) => i.label !== "InitialDuration")
                .filter((i: any) => i.label !== "Type")
                .filter((i: any) => i.name !== "type")
                .filter((i: any) => i.label !== "Bid_value")
                .filter((i: any) => i.label !== "Sector_name")
                .filter((i: any) => i.label !== "LastPrivateNote")
                .filter((i: any) => i.name !== "looptijd_start")
                .filter((i: any) => i.name !== "looptijd_einde")
                .filter((i: any) => i.name !== "next_looptijd_einde")
                .filter((i: any) => i.name !== "looptijd_einde_incl_opties")
                .filter((i: any) => i.label !== "scopeValue");

            setColumnsToExport(
                [
                    { label: "Title", name: "namegiventocontract", options: { display: true, sort: true } },
                    { label: "CA", name: `published_opdrachtgever`, options: { display: true, sort: true } },
                    { label: "Country", name: "translated_country", options: { display: true, sort: true } },
                    {
                        label: "Status",
                        name: "status_text",
                        options: { display: columns.find((t) => t.name === "status").options.display, sort: true },
                    },
                    {
                        label: "Subsector_name",
                        name: "sectors.name",
                        options: { display: columns.find((t) => t.name === "subsector_name").options.display, sort: true },
                    },
                    {
                        label: "Sector_name",
                        name: "parent_sectors.name",
                        options: { display: columns.find((t) => t.name === "sector_name").options.display, sort: true },
                    },
                    {
                        label: "InitialDuration",
                        name: "contract_duration.value",
                        options: { display: columns.find((t) => t.name === "initialDuration").options.display, sort: true },
                    },
                    {
                        label: "Market_party_name",
                        name: "bids.marketParty.name",
                        options: { display: columns.find((t) => t.name === "market_party_name").options.display, sort: true },
                    },
                    {
                        label: "OptionsMonth",
                        name: "options.value",
                        options: { display: columns.find((t) => t.name === "optionsMonth").options.display, sort: true },
                    },
                    {
                        label: "LastPrivateNote",
                        name: "lastPrivateNote",
                        options: { display: columns.find((t) => t.name === "lastPrivateNote").options.display, sort: true },
                    },
                    { label: "Bid_value", name: "bids.bid", options: { display: columns.find((t) => t.name === "bid").options.display, sort: true } },
                    {
                        label: "ContractDescription",
                        name: "contract_description.value",
                        options: { display: columns.find((t) => t.name === "contractDescription").options.display, sort: true },
                    },
                    {
                        label: "Type",
                        name: "translated_type",
                        options: { display: columns.find((t) => t.name === "type").options.display, sort: true },
                    },
                    {
                        label: "DurationStart",
                        name: "looptijd_start.value",
                        options: { display: columns.find((t) => t.name === "looptijd_start").options.display, sort: true },
                    },
                    {
                        label: "DurationEnd",
                        name: "looptijd_einde.value",
                        options: { display: columns.find((t) => t.name === "looptijd_einde").options.display, sort: true },
                    },
                    {
                        label: "FirstEndDate",
                        name: "next_looptijd_einde.value",
                        options: { display: columns.find((t) => t.name === "next_looptijd_einde").options.display, sort: true },
                    },
                    {
                        label: "Date_incl_options",
                        name: "looptijd_einde_incl_opties.value",
                        options: { display: columns.find((t) => t.name === "looptijd_einde_incl_opties").options.display, sort: true },
                    },
                    {
                        label: "Status_Contract",
                        name: "status_contract.value",
                        options: { display: columns.find((t) => t.name === "status_contract").options.display, sort: true },
                    },
                    { label: "Searches", name: "searchresults.search.name", options: { display: true, sort: true } },
                ].concat(removeExcludedColumns)
            );
        }
        // Tender reviewtable possible columns to export
        if (columnExport === "TENDER_WORKLIST") {
            const removeExcludedColumns = columns
                .filter((i: any) => i.options.display !== "excluded")
                .filter((i: any) => i.label !== "Actions")
                .filter((i: any) => i.label !== "Status")
                .filter((i: any) => i.name !== "tender")
                .filter((i: any) => i.name !== "market_party_name")
                .filter((i: any) => i.name !== "status_contract")
                .filter((i: any) => i.label !== "Reviews")
                .filter((i: any) => i.label !== "Subsector_name")
                .filter((i: any) => i.label !== "initialDuration")
                .filter((i: any) => i.label !== "OptionsMonth")
                .filter((i: any) => i.label !== "bid")
                .filter((i: any) => i.label !== "ContractDescription")
                .filter((i: any) => i.label !== "InitialDuration")
                .filter((i: any) => i.label !== "Type")
                .filter((i: any) => i.name !== "type")
                .filter((i: any) => i.label !== "Bid_value")
                .filter((i: any) => i.label !== "Sector_name")
                .filter((i: any) => i.label !== "LastPrivateNote")
                .filter((i: any) => i.name !== "looptijd_start")
                .filter((i: any) => i.name !== "looptijd_einde")
                .filter((i: any) => i.name !== "next_looptijd_einde")
                .filter((i: any) => i.name !== "looptijd_einde_incl_opties")
                .filter((i: any) => i.label !== "scopeValue");

            setColumnsToExport(
                [
                    { label: "Title", name: "namegiventocontract", options: { display: true, sort: true } },
                    { label: "CA", name: `published_opdrachtgever`, options: { display: true, sort: true } },
                    { label: "Country", name: "translated_country", options: { display: true, sort: true } },
                    {
                        label: "Status",
                        name: "status_text",
                        options: { display: columns.find((t) => t.name === "status").options.display, sort: true },
                    },
                    {
                        label: "Subsector_name",
                        name: "sectors.name",
                        options: { display: columns.find((t) => t.name === "subsector_name").options.display, sort: true },
                    },
                    {
                        label: "Sector_name",
                        name: "parent_sectors.name",
                        options: { display: columns.find((t) => t.name === "sector_name").options.display, sort: true },
                    },
                    {
                        label: "InitialDuration",
                        name: "contract_duration.value",
                        options: { display: columns.find((t) => t.name === "initialDuration").options.display, sort: true },
                    },
                    {
                        label: "Market_party_name",
                        name: "bids.marketParty.name",
                        options: { display: columns.find((t) => t.name === "market_party_name").options.display, sort: true },
                    },
                    {
                        label: "OptionsMonth",
                        name: "options.value",
                        options: { display: columns.find((t) => t.name === "optionsMonth").options.display, sort: true },
                    },
                    {
                        label: "LastPrivateNote",
                        name: "lastPrivateNote",
                        options: { display: columns.find((t) => t.name === "lastPrivateNote").options.display, sort: true },
                    },
                    { label: "Bid_value", name: "bids.bid", options: { display: columns.find((t) => t.name === "bid").options.display, sort: true } },
                    {
                        label: "ContractDescription",
                        name: "contract_description.value",
                        options: { display: columns.find((t) => t.name === "contractDescription").options.display, sort: true },
                    },
                    {
                        label: "Type",
                        name: "translated_type",
                        options: { display: columns.find((t) => t.name === "type").options.display, sort: true },
                    },
                    {
                        label: "DurationStart",
                        name: "looptijd_start.value",
                        options: { display: columns.find((t) => t.name === "looptijd_start").options.display, sort: true },
                    },
                    {
                        label: "DurationEnd",
                        name: "looptijd_einde.value",
                        options: { display: columns.find((t) => t.name === "looptijd_einde").options.display, sort: true },
                    },
                    {
                        label: "FirstEndDate",
                        name: "next_looptijd_einde.value",
                        options: { display: columns.find((t) => t.name === "next_looptijd_einde").options.display, sort: true },
                    },
                    {
                        label: "Date_incl_options",
                        name: "looptijd_einde_incl_opties.value",
                        options: { display: columns.find((t) => t.name === "looptijd_einde_incl_opties").options.display, sort: true },
                    },
                    {
                        label: "Status_Contract",
                        name: "status_contract.value",
                        options: { display: columns.find((t) => t.name === "status_contract").options.display, sort: true },
                    },
                    { label: "Searches", name: "searchresults.search.name", options: { display: true, sort: true } },
                ].concat(removeExcludedColumns)
            );
        }
    }, [setColumnsToExport, columnExport, columns]);

    /**
     * function to show/hide columns
     * used filteredcolumns to create a shadow copy because this is a filtered array of the original where display: 'excluded' is removed.
     */
    const changeColumn = (index: number, change: boolean) => {
        // 1. Make a shallow copy of the items
        const items = [...columnsToExport];
        // 2. Make a shallow copy of the item you want to mutate
        const item = { ...columnsToExport[index] };
        // 3. Replace the property you're intested in
        item.options.display = change;
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        items[index] = item;
        // 5. Set the state to our new copy
        setColumnsToExport(items);
    };

    const colsWithScope = [...columnsToExport].concat([
        {
            label: "scopeValueTotal",
            name: "scope.total.value",
            options: { display: true, sort: true },
        },
        {
            label: "scopeValueMin",
            name: "scope.min.value",
            options: { display: true, sort: true },
        },
        {
            label: "scopeValueMax",
            name: "scope.max.value",
            options: { display: true, sort: true },
        },
    ]);

    // Get names of columns
    const get_names_only = checked
        ? colsWithScope.filter((i: any) => i.options.display === true).map((c: any) => c.name)
        : columnsToExport.filter((i: any) => i.options.display === true).map((c: any) => c.name);

    // Get names of checked columns and map them to the NL names -> shows dutch headers in export file
    const checkedBoxesNameNL = sortingArray.filter((e) => (get_names_only.indexOf(e.name) > -1 ? true : false));

    /**
     * Define the mutation to save user preferences
     */
    const [runExport, { loading }] = useMutation<ExportChosenColumns, ExportChosenColumnsVariables>(EXPORT_CHOSEN_COLUMNS, {
        variables: {
            search: {
                query: query,
                source: tenderSource,
                target: target,
                filters: searchInput,
            },
            headers: checkedBoxesNameNL.map((n) => n.nl),
            columns: checkedBoxesNameNL.map((n) => n.name),
            order: order,
            orderField: orderField,
        },
        onCompleted: (data) => {
            setCompleted(true);
        },
    });
    const sortedArr = sortingArray.map((i) => i.name);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
                setColumnsToExport([]);
            }}
            fullWidth
            maxWidth="md"
        >
            <LinearLoader loading={loading} color="blue" />
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {completed === false && <DialogContentText>{dialogText}</DialogContentText>}
                {completed === true ? (
                    <Grid container style={{ height: 450, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <CloudDownloadIcon style={{ color: "#f2f2f2", height: "12rem", width: "12rem" }} />
                            <Typography variant="h5" style={{ color: "#9c9c9c9c", marginBottom: "16px" }}>
                                De export is succesvol aangevraagd.
                            </Typography>
                            <Typography variant="h5" style={{ color: "#9c9c9c9c" }}>
                                Deze wordt verstuurd naar {email?.currentUser.email ? email?.currentUser.email : "je e-mailadres"}
                            </Typography>
                            <Typography variant="h5" style={{ color: "#9c9c9c9c" }}>
                                zodra de zoekregel(s) zijn uitgevoerd.
                            </Typography>
                        </div>
                    </Grid>
                ) : (
                    <Grid container>
                        {columnsToExport
                            .sort((a, b) => sortedArr.indexOf(a.name) - sortedArr.indexOf(b.name))
                            .map((c: any, i: number) => {
                                return (
                                    <Grid key={i} item xs={12} md={4}>
                                        <Disable disabled={loading}>
                                            <MenuItem
                                                dense
                                                onClick={() => {
                                                    changeColumn(i, !c.options.display);
                                                }}
                                            >
                                                <ListItemIcon style={{ minWidth: 0 }}>
                                                    <Checkbox
                                                        size="small"
                                                        edge="start"
                                                        disableRipple
                                                        checked={c.options.display}
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                    />
                                                </ListItemIcon>
                                                <Typography>{t(`Column_Header.${c.label}`)}</Typography>
                                            </MenuItem>
                                        </Disable>
                                    </Grid>
                                );
                            })}
                        <Grid item xs={12} md={4}>
                            <MenuItem
                                dense
                                onClick={(e) => {
                                    setChecked(!checked);
                                }}
                            >
                                <ListItemIcon style={{ minWidth: 0 }}>
                                    <Checkbox
                                        disabled={loading}
                                        size="small"
                                        edge="start"
                                        disableRipple
                                        checked={checked}
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                    />
                                </ListItemIcon>
                                <Typography>{t(`Column_Header.scopeValue`)}</Typography>
                            </MenuItem>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    onClick={() => {
                        onClose();
                        setColumnsToExport([]);
                        setCompleted(false);
                    }}
                    color="primary"
                    variant={completed === false ? "text" : "contained"}
                >
                    {t("advancedSearch.Close")}
                </Button>
                {completed === false && (
                    <Button
                        disabled={loading}
                        onClick={() => {
                            runExport();
                            trackTenderExport();
                        }}
                        variant="contained"
                        color="primary"
                    >
                        exporteren
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default TenderExportModal;

export const sortingArray = [
    { name: "namegiventocontract", nl: "Titel" },
    { name: "published_opdrachtgever", nl: "Aanbestedende dienst" },
    { name: "translated_country", nl: "Land" },
    { name: "status_text", nl: "Status" },
    { name: "published_vestigingsplaats", nl: "Plaats" },
    { name: "published_adres", nl: "Adres" },
    { name: "published_postcode", nl: "Postcode" },
    { name: "published_contactpersoon", nl: "Contactpersoon" },
    { name: "published_email", nl: "E-mail" },
    { name: "published_telefoon", nl: "Telefoon" },
    { name: "parent_sectors.name", nl: "Sector" },
    { name: "sectors.name", nl: "Deelsector" },
    { name: "contract_duration.value", nl: "Initiële looptijd" },
    { name: "deadline_inschrijving", nl: "Deadline inschrijving" },
    { name: "first_dispatchdate", nl: "Datum aankondiging" },
    { name: "datum_gunning", nl: "Datum Gunning" },
    { name: "looptijd_start.value", nl: "Looptijd start" },
    { name: "looptijd_einde.value", nl: "Looptijd einde" },
    { name: "next_looptijd_einde.value", nl: "Eerst mogelijke einddatum" },
    { name: "looptijd_einde_incl_opties.value", nl: "Looptijd einde incl. opties" },
    { name: "options.value", nl: "Opties" },
    { name: "updated_at", nl: "Laatste update" },
    { name: "type_update", nl: "Type update" },
    { name: "bids.bid", nl: "Definitieve waarde" },
    { name: "bids.marketParty.name", nl: "Marktpartij" },
    { name: "contract_description.value", nl: "Contract Omschrijving" },
    { name: "type_procedure", nl: "Type procedure" },
    { name: "translated_type", nl: "Type aanbesteding" },
    { name: "published_host", nl: "Bron" },
    { name: "status_contract.value", nl: "Status contract" },
    { name: "searchresults.search.name", nl: "Zoekregel(s)" },
    { name: "lastPrivateNote", nl: "Laatste notitie" },
    { name: "scope.total.value", nl: "Totale waarde" },
    { name: "scope.min.value", nl: "Minimale waarde" },
    { name: "scope.max.value", nl: "Maximale waarde" },
];
