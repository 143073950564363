import { Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

interface Props {
    data: any[];
    totalLengthOfContract: number;
}

const TimeBetweenDates: React.FC<Props> = ({ data, totalLengthOfContract }) => {
    const lastIndexNumber = data.length - 1;

    /**
     * @param currentStep index of mapped item
     * @param futureStep index of next item to map
     * @returns
     */
    const getlengthBetweenDates = (currentStep: number, futureStep: number) => {
        const diffBetweenDates = moment(data[futureStep].date).diff(data[currentStep].date, "days");
        return (diffBetweenDates / totalLengthOfContract) * 100;
    };

    /**
     *
     * @param currentStep index of mapped item
     * @param futureStep  index of next item to map
     * @returns
     */
    // const getlengthBetweenDatesInYears = (currentStep: number, futureStep: number) => {
    //     const diffBetweenDates = Math.round(moment(data[futureStep].date).diff(data[currentStep].date, "month", true));

    //     if (diffBetweenDates < 1) {
    //         const diffBetweenDatesInMonths = Math.round(moment(data[futureStep].date).diff(data[currentStep].date, "month", true));

    //         if (diffBetweenDatesInMonths < 1) {
    //             return `${Math.round(moment(data[futureStep].date).diff(data[currentStep].date, "day", true))} dgn.`;
    //         }

    //         return `${diffBetweenDatesInMonths} mnd.`;
    //     }

    //     return `${diffBetweenDates} jr.`;
    // };

    const getlengthBetweenDatesInYears = (currentStep: number, futureStep: number) => {
        const futureDate = moment(data[futureStep].date);
        const currentDate = moment(data[currentStep].date);
        const diffBetweenDatesInMonths = Math.round(futureDate.diff(currentDate, "month", true));

        if (diffBetweenDatesInMonths < 12) {
            if (diffBetweenDatesInMonths < 1) {
                return `${Math.round(futureDate.diff(currentDate, "day", true))} dgn.`;
            }
            return `${diffBetweenDatesInMonths} mnd.`;
        }

        const years = diffBetweenDatesInMonths / 12;
        if (Number.isInteger(years)) {
            return `${years} jr.`;
        }

        return `${diffBetweenDatesInMonths} mnd.`;
    };

    // Function to transform the titles in the tooltip
    const tooltipdata = data.map((item) => {
        let newTitle = item.title;
        if (newTitle.includes("Einddatum optie")) {
            // Extract the option number
            const optionNumber = newTitle.match(/Einddatum optie (\d+)/);
            if (optionNumber) {
                newTitle = `Optie ${optionNumber[1]}`;
            }
        } else if (newTitle === "Initiële einddatum") {
            newTitle = "Initiële looptijd";
        }
        return {
            ...item,
            title: newTitle,
        };
    });

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "32px",
                marginRight: "8px",
            }}
        >
            <div style={{ width: "90%", display: "flex", flexDirection: "row" }}>
                {data.map((date, i) => {
                    const nextIndex = i + 1;

                    if (i === data.length - 1) {
                        return <div key={i} />;
                    }

                    return (
                        <div
                            key={date.id}
                            style={{
                                width: `${i !== lastIndexNumber ? getlengthBetweenDates(i, nextIndex) : "0"}%`,
                                marginTop: "10px",
                                // backgroundColor: `${date.bg}`,
                                height: "4px",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div style={{ display: "inline-block" }}>
                                <Tooltip
                                    disableInteractive
                                    title={
                                        <div>
                                            <span>{tooltipdata[i + 1].title}</span>
                                            <br />
                                            <span>
                                                {moment(tooltipdata[i].date).format("L")} tot {moment(tooltipdata[i + 1].date).format("L")}
                                            </span>
                                        </div>
                                    }
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: [0, -10],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    <Typography noWrap variant="caption" sx={{ cursor: "pointer" }}>
                                        {i !== lastIndexNumber ? getlengthBetweenDatesInYears(i, nextIndex) : ""}
                                    </Typography>
                                </Tooltip>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TimeBetweenDates;
