import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import KnowledgeBase from "./KnowledgeBase";
import { useFaq } from "../contextProviders/FaqOpenerContext";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    handleClose(): void;
    open: boolean;
}

const KnowledgeBaseModal: React.FC<Props> = ({ handleClose, open }) => {
    const { t } = useTranslation();
    const { setOpenFaq } = useFaq();
    const { view, setView } = useFaq();
    const { trackEvent } = useMatomo();

    return (
        <Dialog
            open={open}
            scroll={"paper"}
            sx={{
                "& .MuiDialog-paper": {
                    minHeight: "80vh",
                    maxHeight: "80vh",
                },
            }}
            fullWidth
            maxWidth={"lg"}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogContent>
                <KnowledgeBase />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => {
                        trackEvent({ category: "Help", action: "Click-shrink-knowledgebase" } as MatomoEvent);
                        handleClose();
                        setOpenFaq(true);
                        setView(view);
                    }}
                >
                    Verkleinen
                </Button>
                <Button onClick={handleClose} color="primary" variant="contained" sx={{ color: "#fff" }}>
                    {t("advancedSearch.Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default KnowledgeBaseModal;
