import React from "react";
// import classes from "./TopHeader.module.scss";
import { AppBar } from "@mui/material";

const TopHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <AppBar
            sx={{
                padding: 0,
                display: "flex",
                height: "64px",
                flexDirection: "column",
                width: `calc(100% - 64px)`,
                boxSizing: "border-box",
                flexShrink: 0,
                position: "absolute",
                zIndex: 500,
                top: "0px",
                left: "auto",
                right: "0px",
                boxShadow: "none",
                backdropFilter: "blur(6px)",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                color: "rgb(48, 55, 65)",
                borderStyle: "solid",
                borderColor: "rgb(232, 234, 238)",
                borderWidth: "0px 0px thin",
            }}
        >
            {children}
        </AppBar>
    );

    // <div className={classes.TopHeader}>{children}</div>);
};

export default TopHeader;
