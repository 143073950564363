import React, { memo } from "react";
import { ReactComponent as Icon63 } from "../../assets/iconSectors/63_2.svg";
import { ReactComponent as Icon64 } from "../../assets/iconSectors/64.svg";
import { ReactComponent as Icon76 } from "../../assets/iconSectors/76.svg";
import { ReactComponent as Icon79 } from "../../assets/iconSectors/79.svg";
import { ReactComponent as Icon92 } from "../../assets/iconSectors/92.svg";
import { ReactComponent as Icon95 } from "../../assets/iconSectors/95.svg";
import { ReactComponent as Icon98 } from "../../assets/iconSectors/98.svg";
import { ReactComponent as Icon99 } from "../../assets/iconSectors/99.svg";
import { ReactComponent as Icon101 } from "../../assets/iconSectors/101.svg";
import { ReactComponent as Icon106 } from "../../assets/iconSectors/106.svg";
import { ReactComponent as Icon107 } from "../../assets/iconSectors/107.svg";
import { ReactComponent as Icon108 } from "../../assets/iconSectors/108.svg";
import { ReactComponent as Icon109 } from "../../assets/iconSectors/109.svg";
import { ReactComponent as Icon131 } from "../../assets/iconSectors/131.svg";
import { ReactComponent as Icon149 } from "../../assets/iconSectors/149.svg";
import { ReactComponent as Icon152 } from "../../assets/iconSectors/152.svg";
import { ReactComponent as Icon163 } from "../../assets/iconSectors/163.svg";
import { ReactComponent as Icon165 } from "../../assets/iconSectors/165.svg";
import { ReactComponent as Icon166 } from "../../assets/iconSectors/166.svg";
import { ReactComponent as Icon168 } from "../../assets/iconSectors/168.svg";
import { ReactComponent as Icon173 } from "../../assets/iconSectors/173.svg";
import { ReactComponent as Icon185 } from "../../assets/iconSectors/185.svg";
import { ReactComponent as Icon194 } from "../../assets/iconSectors/194.svg";
import { ReactComponent as Icon195 } from "../../assets/iconSectors/195.svg";
import { ReactComponent as Icon199 } from "../../assets/iconSectors/199.svg";
import { ReactComponent as Icon211 } from "../../assets/iconSectors/211.svg";
import { ReactComponent as Icon212 } from "../../assets/iconSectors/212.svg";
import { ReactComponent as Icon215 } from "../../assets/iconSectors/215.svg";
import { ReactComponent as Icon224 } from "../../assets/iconSectors/224.svg";
import { ReactComponent as Icon225 } from "../../assets/iconSectors/225_2.svg";
import {
    EnergySavingsLeaf,
    DirectionsBus,
    SportsSoccer,
    FilterVintage,
    DepartureBoard,
    EmojiNature,
    Waves,
    Payment,
    Money,
    AccountBalance,
    AccountBalanceWallet,
    LocationSearching,
    Gavel,
    Translate,
    SquareFoot,
    AccountTree,
    PermDataSetting,
    PersonAdd,
    Commute,
    LocalHotel,
    Fastfood,
    LocalLaundryService,
    LocalCafe,
    MenuBook,
    House,
    CardGiftcard,
    RestoreFromTrash,
    Web,
    Loop,
    LocalLibrary,
    LocalMall,
    LocalHospital,
    LocalGasStation,
    OfflineBolt,
    DesktopMac,
    Dns,
    SettingsEthernet,
    PhoneIphone,
    AllInclusive,
    Apps,
    Security,
    School,
    SupervisedUserCircle,
    EventSeat,
    Attachment,
    Dashboard,
    Build,
    TwoWheeler,
    FormatPaint,
    AcUnit,
    LocalShipping,
    MailOutline,
    CardTravel,
    ImportExport,
    Gradient,
    Traffic,
    LibraryBooks,
    Deck,
    RecordVoiceOver,
    Highlight,
    AccessibilityNew,
    EmojiPeople,
    LocalPrintshop,
    Nature,
    LocalParking,
    SportsHandball,
    Apartment,
    WbIncandescent,
    AllOut,
    DirectionsBoat,
    LocalAirport,
    BatteryChargingFull,
    HomeWork,
    BlurCircular,
    AirportShuttle,
    DriveEta,
    Storage,
    SettingsInputComponent,
    Tram,
    RecentActors,
    KingBed,
    BusinessCenter,
    ChildCare,
    LocalTaxi,
    HeadsetMic,
    Pets,
    Tv,
    WebStories,
} from "@mui/icons-material";

interface Props {
    sector_id: string | undefined;
}

const IconLazyLoad: React.FC<Props> = ({ sector_id }) => {
    const renderSwitch = (param: string | undefined) => {
        switch (param) {
            case "37":
                return <>{sector_id}</>;

            case "38":
                return <Payment style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "39":
                return <Money style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "41":
                return <AccountBalance style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "43":
                return <AccountBalanceWallet style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "44":
                return <LocationSearching style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "45":
                return <Gavel style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "46":
                return <Translate style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "47":
                return <>{sector_id}</>;

            case "48":
                return <House style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "49":
                return <SquareFoot style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "50":
                return <AccountTree style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "51":
                return <PermDataSetting style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "52":
                return <Waves style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "54":
                return <>{sector_id}</>;

            case "55":
                return <EmojiNature style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "56":
                return <EnergySavingsLeaf style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "58":
                return <SportsSoccer style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "61":
                return <FilterVintage style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "62":
                return <>{sector_id}</>;

            case "63":
                return <Icon63 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "64":
                return <Icon64 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "67":
                return <>{sector_id}</>;

            case "68":
                return <DepartureBoard style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "69":
                return <DirectionsBus style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "70":
                return <PersonAdd style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "72":
                return <Commute style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "73":
                return <>{sector_id}</>;

            case "74":
                return <LocalHotel style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "75":
                return <Fastfood style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "76":
                return <Icon76 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "77":
                return <LocalCafe style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "78":
                return <CardGiftcard style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "79":
                return <Icon79 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "80":
                return <>{sector_id}</>;

            case "82":
                return <MenuBook style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "83":
                return <Web style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "85":
                return <LocalLibrary style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "86":
                return <>{sector_id}</>;

            case "87":
                return <RestoreFromTrash style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "88":
                return <Loop style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "90":
                return <>{sector_id}</>;

            case "92":
                return <Icon92 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "93":
                return <LocalMall style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "94":
                return <LocalLaundryService style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "95":
                return <Icon95 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "96":
                return <>{sector_id}</>;

            case "97":
                return <LocalHospital style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "98":
                return <Icon98 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "99":
                return <Icon99 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "101":
                return <Icon101 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "103":
                return <>{sector_id}</>;

            case "104":
                return <LocalGasStation style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "105":
                return <OfflineBolt style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "106":
                return <Icon106 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "107":
                return <Icon107 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "108":
                return <Icon108 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "109":
                return <Icon109 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "110":
                return <>{sector_id}</>;

            case "111":
                return <Apps style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "112":
                return <DesktopMac style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "113":
                return <Dns style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "114":
                return <SettingsEthernet style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "116":
                return <PhoneIphone style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "117":
                return <>{sector_id}</>;

            case "120":
                return <AllInclusive style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "121":
                return <Security style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "122":
                return <School style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "123":
                return <SupervisedUserCircle style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "124":
                return <>{sector_id}</>;

            case "127":
                return <EventSeat style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "128":
                return <Attachment style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "129":
                return <>{sector_id}</>;

            case "131":
                return <Icon131 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "133":
                return <Dashboard style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "146":
                return <>{sector_id}</>;

            case "149":
                return <Icon149 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "151":
                return <TwoWheeler style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "152":
                return <Icon152 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "154":
                return <>{sector_id}</>;

            case "155":
                return <AcUnit style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "157":
                return <LocalShipping style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "158":
                return <MailOutline style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "159":
                return <CardTravel style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "160":
                return <ImportExport style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "162":
                return <>{sector_id}</>;

            case "163":
                return <Icon163 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "164":
                return <Gradient style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "165":
                return <Icon165 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "166":
                return <Icon166 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "168":
                return <Icon168 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "169":
                return <Traffic style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "173":
                return <Icon173 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "181":
                return <WebStories style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "182":
                return <LibraryBooks style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "183":
                return <Deck style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "184":
                return <Highlight style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "185":
                return <Icon185 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "186":
                return <Nature style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "187":
                return <LocalPrintshop style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "189":
                return <AccessibilityNew style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "190":
                return <EmojiPeople style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "192":
                return <RecordVoiceOver style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "193":
                return <School style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "194":
                return <Icon194 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "195":
                return <Icon195 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "196":
                return <SportsHandball style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "197":
                return <Apartment style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "198":
                return <FormatPaint style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "199":
                return <Icon199 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "200":
                return <WbIncandescent style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "201":
                return <AllOut style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "202":
                return <HomeWork style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "203":
                return <DirectionsBoat style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "204":
                return <LocalAirport style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "205":
                return <BatteryChargingFull style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "206":
                return <Build style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "207":
                return <BlurCircular style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "208":
                return <AirportShuttle style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "209":
                return <DriveEta style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "210":
                return <Storage style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "211":
                return <Icon211 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "212":
                return <Icon212 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "213":
                return <SettingsInputComponent style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "214":
                return <Tram style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "215":
                return <Icon215 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "216":
                return <RecentActors style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "217":
                return <LocalParking style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "218":
                return <KingBed style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "219":
                return <BusinessCenter style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "220":
                return <ChildCare style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "221":
                return <Tv style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "222":
                return <LocalTaxi style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "223":
                return <Pets style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "224":
                return <Icon224 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "225":
                return <Icon225 style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            case "227":
                return <HeadsetMic style={{ width: "20px", height: "20px", fill: "#9e9e9e" }} />;

            default:
                return null;
        }
    };
    return <React.Fragment>{renderSwitch(sector_id)}</React.Fragment>;
};
export const MemoIcon = memo(IconLazyLoad);

export default IconLazyLoad;

// IMPORT THESE ITEMS WHEN MIGRATE TO MUI V5
// 64
// import CleanHandsIcon from '@mui/icons-material/CleanHands';
// 76
// import Kitchen from '@mui/icons-material/Kitchen';
// 95
// import DryCleaning from '@mui/icons-material/DryCleaning';
// 98
// import VaccinsIcon from '@mui/icons-material/Vaccins';
// 99
// import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
// 101
// import BiotechIcon from '@mui/icons-material/Biotech';
// 106
// import TransformIcon from '@mui/icons-material/Transform';
// 107
// import SolarPowerIcon from '@mui/icons-material/SolarPower';
// 108
// import OilBarrelIcon from '@mui/icons-material/OilBarrel';
// 109
// import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
// 131
// import SignpostIcon from '@mui/icons-material/Signpost';
// 149
// import CarRepairIcon from '@mui/icons-material/CarRepair';
// 152
// import RvHookupIcon from '@mui/icons-material/RvHookup';
// 165
// import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
// 166
// import HeatPumpIcon from '@mui/icons-material/HeatPump';
// 173
// import Roofing from '@mui/icons-material/Roofing';
// 185
// import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
// 211
// import AgricultureIcon from '@mui/icons-material/Agriculture';
// 224
// import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';

// 92 - custom
// 194 - custom
// 212 - custom
// 168 - custom
// 163 - custom
// 199 - custom
