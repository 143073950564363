import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { TenderArchive, TenderArchiveVariables } from "../../__generated__/TenderArchive";
import { toast } from "react-toastify";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useLocation } from "react-router-dom";
import { TENDER_ARCHIVE } from "../../graphql/mutationDefinitions";
import {
    QUERY_TENDERS_INTERESTING_MINIMUM,
    QUERY_TENDERS_OF_CA_MINIMUM,
    QUERY_TENDERS_OF_MP_WITH_FILTER_MINIMUM,
    QUERY_TENDER_STATE_FOR_USER,
    QUERY_WORKLIST_SPLIT,
} from "../../graphql/queryDefinitions";

interface Props {
    open: boolean;
    handleClose(): void;
    archive_id: string;
    archive_title: string | null;
}

const ArchiveDialog: React.FC<Props> = ({ open, handleClose, archive_id, archive_title }) => {
    const [save, { loading: loadingMutation, error: errorMutation }] = useMutation<TenderArchive, TenderArchiveVariables>(TENDER_ARCHIVE);
    const { pathname } = useLocation();
    const { trackEvent } = useMatomo();

    // Track event archive tender
    const trackArchive = () => {
        if (pathname === "/dashboard") trackEvent({ category: "Dashboard", action: "archive-tender" });
        if (pathname.includes("tender")) trackEvent({ category: "Tender", action: "archive-tender" });
        if (pathname.includes("contracting-authorities")) trackEvent({ category: "Ca", action: "archive-tender" });
        if (pathname.includes("organizations")) trackEvent({ category: "Organizations", action: "archive-tender" });
    };

    // Function to delete a like
    const archiveTender = async () => {
        try {
            await save({
                variables: {
                    tenderID: archive_id,
                },
                refetchQueries: [
                    QUERY_TENDERS_INTERESTING_MINIMUM,
                    "FilterInterestingMinimumTendersOverview",
                    QUERY_TENDER_STATE_FOR_USER,
                    "GetTenderStateForUser",
                    QUERY_TENDERS_OF_MP_WITH_FILTER_MINIMUM,
                    "FilterTendersOverviewOnMarketPartyPageMinimum",
                    QUERY_TENDERS_OF_CA_MINIMUM,
                    "FilterTendersOverviewOnCaPageMinimum",
                    QUERY_WORKLIST_SPLIT,
                    "FilterWorklistSplitView",
                ],
                awaitRefetchQueries: true,
            });
        } catch (e) {
            if (errorMutation) {
                toast.error("Fout tijdens opslaan (error)");
            }
        } finally {
            handleClose();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
            <DialogTitle id="alert-dialog-title">Archiveren</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography>Je wilt aanbesteding `{archive_title}` archiveren.</Typography>
                    <Typography style={{ marginTop: 8 }}>Je ontvangt geen meldingen meer van deze aanbesteding.</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loadingMutation} color="primary">
                    Annuleren
                </Button>
                <Button
                    onClick={() => {
                        archiveTender();
                        trackArchive();
                    }}
                    disabled={loadingMutation}
                    variant="contained"
                    color="primary"
                    autoFocus
                >
                    Bevestigen {loadingMutation && <CircularProgress size={14} style={{ marginLeft: 4 }} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ArchiveDialog;
