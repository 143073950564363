import React from "react";
import { Avatar, ListItemAvatar, ListItemText, MenuItem } from "@mui/material";
import { Chat } from "@mui/icons-material";
import { colorOfDialog } from "../../utils/messenger";

interface Props {
    module: "Tender" | "MarketParty" | "ContractingAuthority" | "Opportunity";
    onClick(): void;
    read_at: any;
    messageToShow: React.ReactNode;
    secondaryMessage: React.ReactNode;
}

const NewChatMessageNotificationContent: React.FC<Props> = ({ module, read_at, messageToShow, onClick, secondaryMessage }) => {
    return (
        <MenuItem
            onClick={() => {
                onClick();
            }}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: colorOfDialog(module) }}>
                    <Chat fontSize="small" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={messageToShow} secondary={secondaryMessage} />
        </MenuItem>
    );
};

export default NewChatMessageNotificationContent;
