import React from "react";
import { MUIDataTableColumn } from "mui-datatables";
import { Typography } from "@mui/material";
import { Translation } from "react-i18next";
import AvatarReviewed from "../../../components/AvatarReviewed";
import SearchRuleData from "../../../components/SearchRuleData";
import SmallTenderMenu from "../../../components/SmallTenderMenu";
import StatusDot from "../../../components/StatusDot";
import FaviconBox from "../../../components/FaviconBox";
import TenderTitleWidthAndUpdate from "../../../components/customColumns/TenderTitleWidthAndUpdate";
import TypeColumn from "../../../components/columns/TypeColumn";
import ProcedureTypeColumn from "../../../components/columns/ProcedureTypeColumn";
import DateStartColumn from "../../../components/columns/DateStartColumn";
import DateEndColumn from "../../../components/columns/DateEndColumn";
import DateNextEndColumn from "../../../components/columns/DateNextEndColumn";
import PublishedAddressColumn from "../../../components/columns/PublishedAddressColumn";
import PublishedPostalCodeColumn from "../../../components/columns/PublishedPostalCodeColumn";
import PublishedVestigingsplaatsColumn from "../../../components/columns/PublishedVestigingPlaatsColumn";
import PublishedHostColumn from "../../../components/columns/PublishedHostColumn";
import ContractStatusColumn from "../../../components/columns/ContractStatusColumn";
import AwardDateColumn from "../../../components/columns/AwardDateColumn";
import DateInclOptionsColumn from "../../../components/columns/DateInclOptionsColumn";
import FirstDispatchColumn from "../../../components/columns/FirstDispatchColumn";
import UpdatedAtColumn from "../../../components/columns/UpdatedAtColumn";
import UpdateTypeColumn from "../../../components/columns/UpdateTypeColumn";
import SectorColumn from "../../../components/columns/SectorColumn";
import SubSectorColumn from "../../../components/columns/SubSectorColumn";
import PublishedBidValueColumn from "../../../components/columns/PublishedBidValueColumn";
import MarketPartyColumn from "../../../components/columns/MarketPartyColumn";
import PublishedContactPersonColumn from "../../../components/columns/PublishedContactPersonColumn";
import PublishedPhoneColumn from "../../../components/columns/PublishedPhoneColumn";
import PublishedMailColumn from "../../../components/columns/PublishedMailColumn";
import OptionsColumn from "../../../components/columns/OptionsColumn";
import ContractDesciptionColumn from "../../../components/columns/ContractDesciptionColumn";
import InitialDurationColumn from "../../../components/columns/InitialDurationColumn";
/**
 * Columns for event view (tenders)
 */
export const TenderEventColumns: MUIDataTableColumn[] = [
    // index 0
    {
        label: "id",
        name: "id",
        options: {
            display: "excluded",
            customBodyRender() {
                return <div />;
            },
        },
    },

    // favicon, HAS TO BE THIRD COLUMN
    // index 1
    {
        name: "favicon",
        options: {
            display: "excluded",
            customBodyRender() {
                return <div />;
            },
        },
    },
    // tender title
    // index 2
    // TODO: create something to show tooltip when user activates more columns -> tooltip now based on x characters on first render
    // title can fit 2 lines, CA can fit 1 line.
    {
        label: "Title",
        name: "tender",
        options: {
            sort: false,
            draggable: false,
            display: true,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Update")}</>}</Translation>;
            },
            customBodyRender(value: any, tableMeta) {
                return <TenderTitleWidthAndUpdate update_text={tableMeta.rowData[5]} tender_name={value} tender_id={tableMeta.rowData[0]} />;
            },
        },
    },

    // contracting authority, HAS TO BE SECOND COLUMN
    // index 3
    {
        label: "AD",
        name: "contractingAuthorities",
        options: {
            display: true,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.CA")}</>}</Translation>;
            },
            customBodyRender(name: any, tableMeta) {
                return (
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <FaviconBox favicon={tableMeta.rowData[1]} name={name} color={"#F57117"} />
                        {name}
                    </div>
                );
            },
        },
    },

    // index 4
    // Contracting Authority id
    {
        label: "CA",
        name: "ca_id",
        options: {
            display: "excluded",
            customBodyRender() {
                return <div />;
            },
        },
    },
    // contracting authority, HAS TO BE SECOND COLUMN
    // index 5
    {
        label: "update_text",
        name: "update_text",
        options: {
            display: "excluded",
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.update_text")}</>}</Translation>;
            },
        },
    },
    // index 6
    // Country
    {
        label: "country",
        name: "country",
        options: {
            display: "excluded",
            customBodyRender() {
                return <div />;
            },
        },
    },
    // Status of tender
    // index 7
    {
        label: "Status",
        name: "status",
        options: {
            display: true,
            sort: true,
            customHeadLabelRender: () => {
                return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Translation>{(t) => <>{t("Column_Header.Status")}</>}</Translation>
                    </div>
                );
            },
            customBodyRender(title: any, tableMeta) {
                return (
                    <div style={{ display: "flex" }}>
                        <StatusDot type={title} placement="left" />
                    </div>
                );
            },
        },
    },
    // Deadline date
    // index 8
    {
        label: "Deadline",
        name: "deadline_inschrijving",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Deadline")}</>}</Translation>;
            },
            customBodyRender(title: any, tableMeta) {
                return <Typography style={{ whiteSpace: "nowrap" }}>{title}</Typography>;
            },
        },
    },

    // index 9
    // Type (services, works, supplies)
    {
        label: "Type",
        name: "type",
        options: {
            sort: true,
            display: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Type")}</>}</Translation>;
            },
            customBodyRender(value: any, tableMeta) {
                return <TypeColumn tender_id={value} />;
            },
        },
    },

    // Type procedure
    // index 10
    {
        label: "TypeProcedure",
        name: "type_procedure",
        options: {
            display: false,
            sort: true,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500, width: 110 }}>
                                {t("Column_Header.TypeProcedure")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                // see TenderTitleWidth.tsx for break on (x) lines
                return <ProcedureTypeColumn tender_id={tender_id} />;
            },
        },
    },
    // start date
    // index 11
    {
        label: "DurationStart",
        name: "looptijd_start",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500 }}>
                                {t("Column_Header.DurationStart")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <DateStartColumn tender_id={tender_id} />;
            },
        },
    },
    // End date
    // index 12
    {
        label: "DurationEnd",
        name: "looptijd_einde",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500 }}>
                                {t("Column_Header.DurationEnd")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <DateEndColumn tender_id={tender_id} />;
            },
        },
    },
    // First possible enddate
    // index 13
    {
        label: "FirstEndDate",
        name: "next_looptijd_einde",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500, width: 110 }}>
                                {t("Column_Header.FirstEndDate")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <DateNextEndColumn tender_id={tender_id} />;
            },
        },
    },
    // Address
    // index 14
    {
        label: "Address",
        name: "published_adres",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500, width: 110 }}>
                                {t("Column_Header.Address")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedAddressColumn tender_id={tender_id} />;
            },
        },
    },
    // Postal code
    // index 15
    {
        label: "Postal_Code",
        name: "published_postcode",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500, width: 110 }}>
                                {t("Column_Header.Postal_Code")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedPostalCodeColumn tender_id={tender_id} />;
            },
        },
    },
    // Land & plaats
    // index 16
    {
        label: "City",
        name: "published_vestigingsplaats",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.City")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedVestigingsplaatsColumn tender_id={tender_id} />;
            },
        },
    },
    // Platform
    // index 17
    {
        label: "Source",
        name: "published_host",
        options: {
            display: false,
            sort: true,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500, width: 110 }}>
                                {t("Column_Header.Source")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedHostColumn tender_id={tender_id} />;
            },
        },
    },
    // Status contract
    // index 18
    // TODO: add to tendertable
    {
        label: "Status_Contract",
        name: "status_contract",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500, width: 110 }}>
                                {t("Column_Header.Status_Contract")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <ContractStatusColumn tender_id={tender_id} />;
            },
        },
    },
    // award date
    // index 19
    {
        label: "Award_date",
        name: "datum_gunning",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Award_date")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <AwardDateColumn tender_id={tender_id} />;
            },
        },
    },
    // looptijd_einde_incl_opties
    // index 20
    {
        label: "Date_incl_options",
        name: "looptijd_einde_incl_opties",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Date_incl_options")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <DateInclOptionsColumn tender_id={tender_id} />;
            },
        },
    },
    // first_dispatchdate
    // index 21
    {
        label: "First_dispatchdate",
        name: "first_dispatchdate",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.First_dispatchdate")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <FirstDispatchColumn tender_id={tender_id} />;
            },
        },
    },
    // updated_at
    // index 22
    {
        label: "Last_update",
        name: "updated_at",
        options: {
            display: true,
            sort: true,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500 }}>
                                {t("Column_Header.Last_update")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <UpdatedAtColumn tender_id={tender_id} />;
            },
        },
    },
    // type_update
    // index 23
    {
        label: "Type_update",
        name: "type_update",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Type_update")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <UpdateTypeColumn tender_id={tender_id} />;
            },
        },
    },
    // sector_name
    // index 24
    {
        label: "Sector_name",
        name: "sector_name",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Sector_name")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <SectorColumn tender_id={tender_id} />;
            },
        },
    },
    // Subsector_name
    // index 25
    {
        label: "Subsector_name",
        name: "subsector_name",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Subsector_name")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <SubSectorColumn tender_id={tender_id} />;
            },
        },
    },
    // bid.bid
    // index 26
    {
        label: "Bid_value",
        name: "bid",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Bid_value")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedBidValueColumn tender_id={tender_id} />;
            },
        },
    },
    // Market party name
    // index 27
    {
        label: "Market_party_name",
        name: "market_party_name",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Market_party_name")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <MarketPartyColumn tender_id={tender_id} />;
            },
        },
    },
    // published contactpersoon
    // index 28
    {
        label: "Published_contactpersoon",
        name: "published_contactpersoon",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Published_contactpersoon")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedContactPersonColumn tender_id={tender_id} />;
            },
        },
    },
    // published telefoon
    // index 29
    {
        label: "Published_telefoon",
        name: "published_telefoon",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Published_telefoon")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedPhoneColumn tender_id={tender_id} />;
            },
        },
    },
    // published email
    // index 30
    {
        label: "Published_email",
        name: "published_email",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Published_email")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedMailColumn tender_id={tender_id} />;
            },
        },
    },
    // Contract options
    // index 31
    {
        label: "OptionsMonth",
        name: "optionsMonth",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.OptionsMonth")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <OptionsColumn tender_id={tender_id} />;
            },
        },
    },
    // Contract description
    // index 32
    {
        label: "ContractDescription",
        name: "contractDescription",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.ContractDescription")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <ContractDesciptionColumn tender_id={tender_id} />;
            },
        },
    },
    // initialDuration
    // index 33
    {
        label: "InitialDuration",
        name: "initialDuration",
        options: {
            display: false,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.InitialDuration")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <InitialDurationColumn tender_id={tender_id} />;
            },
        },
    },
    // User avatars who liked tender
    // index 34
    {
        label: "Reviews",
        name: "",
        options: {
            display: "excluded",
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Reviews")}</>}</Translation>;
            },
            customBodyRender(value: any, tableMeta) {
                return (
                    <>
                        <AvatarReviewed tender_id={tableMeta.rowData[0]} />
                    </>
                );
            },
        },
    },
    // index 35, searchRulelabels will be shown here.
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        // TODO: show max 5 searchrules in column
        label: "Actions",
        name: "searches",
        options: {
            sort: false,
            display: true,
            draggable: false,
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(data: any, tableMeta) {
                return (
                    <div style={{ display: "flex", flexDirection: "row", width: "70%" }}>
                        <SearchRuleData placement="top" tender_id={tableMeta.rowData[0]} searches={data} />
                    </div>
                );
            },
        },
    },
    // index 36
    // userStatus
    {
        name: "userStatus",
        options: {
            display: "excluded",
        },
    },
    // actions to like, dislike, share tender
    // index 37
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        label: "Actions",
        name: "",
        options: {
            sort: false,
            display: true,
            draggable: false,
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(title: any, tableMeta) {
                return <SmallTenderMenu id={tableMeta.rowData[0]} title={tableMeta.rowData[3]} userStatus={tableMeta.rowData[35]} />;
            },
        },
    },
];
