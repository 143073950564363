import React from "react";
import { Box, DialogContentText, SelectChangeEvent } from "@mui/material";
import SearchLineItemToChangeOwner from "../SearchRule/SearchLineItemToChangeOwner";
import { GetSubscriptionUsers_organization_users } from "../../../__generated__/GetSubscriptionUsers";
import { GetOrganizationSearchRules_getOrganizationSearches } from "../../../__generated__/GetOrganizationSearchRules";
import { useMutation } from "@apollo/client";
import { changeSearchOwner, changeSearchOwnerVariables } from "../../../__generated__/changeSearchOwner";
import { CHANGE_SEARCH_OWNER, GET_ORGANIZATION_SEARCHES, QUERY_SEARCHRULES } from "../../../graphql/queryDefCurrentUser";
import { toast } from "react-toastify";
import { SET_SEARCH_DISTRIBUTION } from "../../../graphql/mutationDefinitions";
import { setSearchDistribution, setSearchDistributionVariables } from "../../../__generated__/setSearchDistribution";
import { DistributionIntervalInput } from "../../../__generated__/globalTypes";

interface Props {
    colleagues: GetSubscriptionUsers_organization_users[];
    data: (GetOrganizationSearchRules_getOrganizationSearches | null)[];
    refetch: any;
    nameOfUserToDelete: string;
    idOfUserToDelete: string;
}

const BoxWithSearchrulesToMove: React.FC<Props> = ({ data, colleagues, refetch, nameOfUserToDelete, idOfUserToDelete }) => {
    // set distribution of the search for the new owner
    const [saveDistribution] = useMutation<setSearchDistribution, setSearchDistributionVariables>(SET_SEARCH_DISTRIBUTION);
    // change the owner of a searchrule
    const [changeOwnerOfSearch] = useMutation<changeSearchOwner, changeSearchOwnerVariables>(CHANGE_SEARCH_OWNER);

    const getSearchDistributionOrStatus = (searchID: string, userID: string) => {
        const search = data.find((search) => search?.id === searchID);

        if (search) {
            const isUserParticipant = search.participants?.some((user: any) => user.id === userID);
            return isUserParticipant ? "already" : shareTenderSearch(userID, searchID, search);
        }

        return null; // Or handle the case where the searchID is not found
    };

    /**
     * Change owner of search
     * @param event chosen menuitem
     * @param searchID id of searchrule
     */
    const handleChangeOwner = (event: SelectChangeEvent, searchID: number) => {
        if (event.target.value === "none") {
            return;
        } else {
            changeOwner(parseInt(event.target.value), searchID);
        }
    };

    /**
     *
     * @param newUID id of new owner
     * @param search_id id of searchrule to change
     * @param search the whole searchobject to copy the distribution
     */
    const shareTenderSearch = (newUID: string, search_id: string, search: GetOrganizationSearchRules_getOrganizationSearches) => {
        saveDistribution({
            variables: {
                user_id: newUID,
                search_id: search_id,
                distribution: {
                    in_app: search.pivot?.in_app,
                    worklist: Boolean(search.pivot?.worklist),
                    email_interval: search.pivot?.email_interval ? (search.pivot.email_interval.toUpperCase() as DistributionIntervalInput) : null,
                },
            },
            refetchQueries: [QUERY_SEARCHRULES, "GetUserSearchRules"],
        });
    };

    /**
     * Handler to change owner of a search
     * @param UserID id of user
     * @param SearchID id of search
     */
    const changeOwner = (UserID: number, SearchID: number) => {
        changeOwnerOfSearch({
            variables: {
                search_id: SearchID,
                new_owner: UserID,
            },
            // refetchQueries: [QUERY_SUBSCRIPTION_USERS, "GetSubscriptionUsers", QUERY_ALL_USERS_IN_ORGANIZATION, QUERY_SUBSCRIPTION_AND_TEAMS],
            awaitRefetchQueries: true,
            refetchQueries: [GET_ORGANIZATION_SEARCHES],
            onError: () => {
                toast.error("Er ging iets mis...", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            },
            onCompleted: () => {
                getSearchDistributionOrStatus(SearchID.toString(), UserID.toString());
                toast.success("Gebruiker is nu beheerder", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            },
        });
    };

    return (
        <React.Fragment>
            {/* Show amount of searches that have to change owner before deleting user */}
            <DialogContentText sx={{ margin: "8px 0px 16px" }}>
                <span style={{ fontWeight: 500 }}>{nameOfUserToDelete}</span> is eigenaar van <span style={{ fontWeight: 500 }}>{data.length}</span>{" "}
                {data.length === 1 ? "zoekregel" : "zoekregels"}.
            </DialogContentText>
            <Box
                noValidate
                component="form"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {data.map((line) => {
                    if (line === null) {
                        return <></>;
                    }
                    return (
                        <SearchLineItemToChangeOwner
                            nameOfUserToDelete={nameOfUserToDelete}
                            colleagues={colleagues.filter((col) => col.id !== idOfUserToDelete)}
                            key={line.id}
                            type={"tender"}
                            color={line.color}
                            searchline={line}
                            handleChangeOwner={handleChangeOwner}
                            refetch={refetch}
                        />
                    );
                })}
            </Box>
        </React.Fragment>
    );
};

export default BoxWithSearchrulesToMove;
