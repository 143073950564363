import React from "react";
import { SavedTenderEvents_getCalendarEvents_UserEvent } from "../../__generated__/SavedTenderEvents";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import UserEventTooltip from "./UserEventTooltip";

interface Props {
    id: string;
    event: SavedTenderEvents_getCalendarEvents_UserEvent;
    setId(): void;
    setActiveTooltip: (bln: any) => void;
    activeTooltip: string | false;
}

const UserCalendarEvent: React.FC<Props> = ({ event, setId, setActiveTooltip, activeTooltip, id }) => {
    // const tender = "#465c84";
    // const opportunity = "#508c79";
    const other = "#9e9e9e";

    return (
        <div style={{ zIndex: 95 }}>
            <UserEventTooltip
                setActiveTooltip={setActiveTooltip}
                setId={setId}
                content={
                    <Box
                        component="div"
                        style={{
                            backgroundColor: other, //item.type === "Tender" ? `${tender}` : item.type === "opportunity" ? `${opportunity}` : `${other}`,
                            color: "white",
                            marginLeft: "4px",
                            marginRight: "4px",
                            marginBottom: "4px",
                            borderRadius: "3px",
                            zIndex: 99,
                            boxShadow: activeTooltip === id ? `rgba(0, 0, 0, 0.2) 0px 2px 5px 0px, rgba(0, 0, 0, 0.19) 0px 3px 2px 0px` : undefined,
                        }}
                        onClick={() => {
                            setActiveTooltip(id);
                        }}
                    >
                        <Typography noWrap style={{ paddingLeft: 4, paddingRight: 4 }}>
                            <span style={{ fontWeight: 700 }}>{moment(event.start).format("LT")}</span> {event.title}
                        </Typography>
                    </Box>
                }
                data={event}
            />
        </div>
    );
};
export default UserCalendarEvent;
