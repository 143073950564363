// WordModalGuide

import React, { useLayoutEffect } from "react";
import { Button } from "@mui/material";
import { Assistant } from "@mui/icons-material";
import GuideContent from "../elements/GuideContent";
import { useTour } from "../../contextProviders/TourContext";
import { useFirstSearchRuleSetter } from "../../contextProviders/FirstSearchRuleContext";

interface Props {
    hasSearchrules: boolean;
}

/**
 * @param step CurrentStep in tender searchwizard
 * @returns Returns current step as title and a button to open guide/tour
 */
const OppResultModalGuide: React.FC<Props> = ({ hasSearchrules }) => {
    //accent color
    const color = "#225E4D";
    // first searchrule global state
    const { resultModalTourOpen, setResultModalTourOpen } = useFirstSearchRuleSetter();
    // tour variables to set
    // locktour => true means item is interactive and no next/prev button will be shown
    const { setTourVariables, setOpenTour, setLockTour } = useTour();

    /**
     * SPLIT IN 2 COMPONENTS
     * BUTTON
     * TOUR TO START
     */
    const steps = () => {
        return [
            /**
             * Step 1: Results
             */
            {
                selector: '[data-tut="reactour__resultsResults"]',
                content: () => {
                    setLockTour(false);
                    return (
                        <GuideContent
                            title="Gevonden resultaten"
                            content={<span>Deze kansen zijn gevonden op basis van de gekozen zoekwoorden</span>}
                        />
                    );
                },
                stepInteraction: false,
            },

            /**
             * Step 2: detailpage
             */
            {
                selector: '[data-tut="reactour__resultsDetails"]',
                content: () => {
                    setLockTour(false);
                    return <GuideContent title="Detailweergave" content={<span>Bekijk de gevonden resulaten uit een document.</span>} />;
                },
                stepInteraction: false,
            },

            /**
             * Step 3 : filters
             */
            {
                selector: '[data-tut="reactour__resultsFilter"]',
                content: () => {
                    setLockTour(false);
                    return <GuideContent title="Filteren" content={<span>Activeer één of meerdere filters</span>} />;
                },
                stepInteraction: false,
            },
        ];
    };

    /**
     * Check if user has searchrules and domainModalTourOpen = false
     * true => open tour on default for current step
     */
    useLayoutEffect(() => {
        if (hasSearchrules && !resultModalTourOpen) {
            // timeout for smoother transition
            setTimeout(() => {
                setTourVariables(steps, color, "OppWizardResult");
                setOpenTour(true);
                setResultModalTourOpen(true);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultModalTourOpen, hasSearchrules]);

    return (
        <Button
            data-tut="reactour__tourbtn"
            variant="text"
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                setTourVariables(steps, color, "OppWizardResult");
                setOpenTour(true);
            }}
            startIcon={<Assistant fontSize="small" />}
        >
            Interactieve assistent
        </Button>
    );
};

export default OppResultModalGuide;
