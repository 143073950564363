import React, { useState } from "react";
import { Link, Typography } from "@mui/material";

import dompurify from "dompurify";
import { useReRunHighlighting } from "./contextProviders/HighlightContext";
import { Variant } from "@mui/material/styles/createTypography";

interface Props {
    text: any;
    length: number;
    variant: "inherit" | Variant | undefined;
}

const ReadMoreComponent: React.FC<Props> = ({ text, length, variant }) => {
    const [showLess, setShowLess] = useState(true);
    const readMore = "#465c84";
    const { rerunHighlight, setRerunHighlight } = useReRunHighlighting();

    /**
     * dompurify imported for sanitizing html
     */
    const frag = dompurify.sanitize(text, { FORCE_BODY: true });

    if (frag.length <= length) {
        return (
            <Typography
                style={{ display: "inline", whiteSpace: "pre-wrap" }}
                variant={variant}
                dangerouslySetInnerHTML={{
                    __html: frag,
                }}
            />
        );
    }

    return (
        <div>
            <Typography
                style={{ display: "inline", whiteSpace: "pre-wrap" }}
                variant={variant}
                dangerouslySetInnerHTML={{
                    __html: showLess ? `${frag.slice(0, length)}... ` : frag,
                }}
            />
            <Link
                underline="none"
                style={{ color: `${readMore}`, cursor: "pointer" }}
                onClick={() => {
                    setRerunHighlight(!rerunHighlight);
                    setShowLess(!showLess);
                }}
            >
                {" "}
                {showLess ? "lees meer" : "toon minder"}
            </Link>
        </div>
    );
};

export default ReadMoreComponent;
