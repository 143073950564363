import React from "react";
import { GET_PUBLISHED_PLACE_COLUMN } from "../../graphql/queryDefForColumns";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import CountryFlagBox from "../boxes/CountryFlagBox";
import { GetPublishedPlaceColumn, GetPublishedPlaceColumnVariables } from "../../__generated__/GetPublishedPlaceColumn";

interface Props {
    tender_id: string;
}

const PublishedVestigingsplaatsColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetPublishedPlaceColumn, GetPublishedPlaceColumnVariables>(GET_PUBLISHED_PLACE_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    const countryCode = data.tender.country?.alpha2;

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 8, display: "flex", marginLeft: -4 }}>
                <CountryFlagBox code={countryCode || ""} />
            </div>
            <Typography noWrap>{tender.published_vestigingsplaats}</Typography>
        </div>
    );
};

export default PublishedVestigingsplaatsColumn;
