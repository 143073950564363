import React from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import moment from "moment";
import LotDateField from "../../../components/LotDateField";
import LotStringField from "../../../components/LotStringField";
import { useTranslation } from "react-i18next";
import {
    GetDatesOfTenderOrLotsNewStructureVariables,
    GetDatesOfTenderOrLotsNewStructure,
} from "../../../__generated__/GetDatesOfTenderOrLotsNewStructure";
import { GET_DATES_OF_TENDER_OR_LOTS_NEW_STRUCTURE } from "../../../graphql/queryDefinitions";

interface Props {
    tender_id: string;
}

const SplitMetaStartEndNewFormat: React.FC<Props> = ({ tender_id }) => {
    const { t } = useTranslation();
    const renderPage = (content: React.ReactNode) => <div style={{ height: "100%" }}>{content}</div>;

    /**
     * Get dates
     */
    const { data, loading, error } = useQuery<GetDatesOfTenderOrLotsNewStructure, GetDatesOfTenderOrLotsNewStructureVariables>(
        GET_DATES_OF_TENDER_OR_LOTS_NEW_STRUCTURE,
        {
            variables: { id: tender_id },
            fetchPolicy: "network-only",
        }
    );

    /**
     * loader
     */
    if (loading) {
        return renderPage(
            <div>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("PeriodOfTender")}
                </Typography>
            </div>
        );
    }

    /**
     * check if error occured or no data is available
     */
    if (data === undefined || data.tender === null || error) {
        return renderPage(
            <>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("PeriodOfTender")}
                </Typography>
                <Typography>-</Typography>
            </>
        );
    }

    /**
     * Shorter constant to use in component
     */
    const tndr = data.tender;

    const start = tndr.looptijd_start && tndr.looptijd_start.filter((date) => date?.value !== null).length > 0;
    const startLength = tndr.looptijd_start && tndr.looptijd_start.filter((date) => date?.value !== null).length;
    const endLength = tndr.looptijd_einde && tndr.looptijd_einde.filter((date) => date?.value !== null).length;

    const end = tndr.looptijd_einde && tndr.looptijd_einde.filter((date) => date?.value !== null).length > 0;
    const nextEnd = tndr.next_looptijd_einde && tndr.next_looptijd_einde.filter((date) => date?.value !== null).length > 0;
    const publishedStart = tndr.published_looptijd_start !== null;
    const publishedEnd = tndr.published_looptijd_einde !== null;

    return renderPage(
        <div style={{ height: "100%", width: "100%" }}>
            <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                {t("PeriodOfTender")}
            </Typography>
            {/*
             * Check if looptijd_start and looptijd_einde have at least 1 date value available
             * if so, show format .... tot ....
             */}
            {start && end ? (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "start", height: "100%", width: "100%" }}>
                    <div
                        style={{
                            height: "100%",
                            overflow: "auto",
                            width: startLength && startLength > 1 ? "25%" : "auto",
                        }}
                    >
                        <LotDateField data={tndr.looptijd_start} />
                    </div>
                    <span style={{ margin: "0px 4px" }}> tot </span>
                    <div
                        style={{
                            height: "100%",
                            overflow: "auto",
                            width: endLength && endLength > 1 ? "25%" : "auto",
                        }}
                    >
                        <LotDateField data={tndr.looptijd_einde} />{" "}
                    </div>
                </div>
            ) : start ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        overflow: "auto",
                        height: "100%",
                        width: startLength && startLength > 1 ? "25%" : "auto",
                    }}
                >
                    <LotDateField data={tndr.looptijd_start} />
                </div>
            ) : end ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ margin: "0px 4px" }}> tot </span>
                    <div
                        style={{
                            height: "100%",
                            overflow: "auto",
                            width: endLength && endLength > 1 ? "25%" : "auto",
                        }}
                    >
                        <LotDateField data={tndr.looptijd_einde} />
                    </div>
                </div>
            ) : (
                /*
                 * Check if looptijd_start and looptijd_einde have at least 1 date value available
                 * if so, show format .... tot ....
                 */
                <Typography>
                    {tndr.published_looptijd_start !== null ? moment(tndr.published_looptijd_start).format("L") : ""}
                    {(publishedStart && publishedEnd) || publishedEnd ? " tot " : ""}
                    {nextEnd ? (
                        tndr.next_looptijd_einde?.map(
                            (next) =>
                                `${next && next.value !== null ? moment(next.value).format("L") : ""} ${
                                    next?.lots !== undefined && next.lots.length > 0 ? `(${next.lots.map((lot: any) => lot)})` : ""
                                }`
                        )
                    ) : tndr.published_looptijd_einde ? (
                        moment(tndr.published_looptijd_einde).format("L")
                    ) : (
                        <LotStringField data={tndr.contract_duration} whiteSpace="nowrap" noDataSign="-" />
                    )}
                </Typography>
            )}
        </div>
    );
};

export default SplitMetaStartEndNewFormat;
