import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Link } from "@mui/material";
import moment from "moment";
import DocumentDrawer from "../../routes/contractingAuthoritiesModule/detailpage/mainWidget/DocumentDrawer";
import { GetDefaultSummary_tender_omvang_opdracht_source, GetDefaultSummary_tender_omschrijving_source } from "../../__generated__/GetDefaultSummary";
import ReadMoreBasedOnNewLines from "../ReadMoreBasedOnNewLines";

interface Props {
    description: string;
    qualitativeScope: string;
    omvang_opdracht_source: GetDefaultSummary_tender_omvang_opdracht_source | null;
    omschrijving_source: GetDefaultSummary_tender_omschrijving_source | null;
    showSource: boolean;
    padding: number;
    tender_id: string;
}

export interface OpenProps {
    open: boolean;
    link: string;
    label: string;
    doc_id: string;
}

const Summary: React.FC<Props> = ({ description, qualitativeScope, omvang_opdracht_source, omschrijving_source, showSource, padding, tender_id }) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState<OpenProps>({ open: false, link: "", label: "", doc_id: "" });
    const blue = "#173357";
    const closeDrawer = () => {
        setOpen({ open: false, link: "", label: "", doc_id: "" });
    };

    return (
        <div style={{ padding: padding }}>
            {/* Description */}
            <>
                <div style={{ marginBottom: 8 }}>
                    {description !== "" && <Typography variant="h4">{t("tenderPage.Description")}</Typography>}
                    {showSource && description !== "" && (
                        <React.Fragment>
                            <Typography style={{ fontStyle: "italic" }} variant="caption">
                                {t("Source")}:{" "}
                            </Typography>

                            {omschrijving_source?.__typename === "Document" ? (
                                <>
                                    {omschrijving_source.extension === "pdf" ? (
                                        <Link
                                            component="button"
                                            underline="none"
                                            style={{ fontStyle: "italic" }}
                                            variant="caption"
                                            onClick={() => {
                                                setOpen({
                                                    link: omschrijving_source.temporary_link,
                                                    open: true,
                                                    label: omschrijving_source.filename,
                                                    doc_id: omschrijving_source.id,
                                                });
                                            }}
                                        >
                                            {omschrijving_source.filename}
                                        </Link>
                                    ) : (
                                        <Link
                                            component="button"
                                            underline="none"
                                            style={{ fontStyle: "italic" }}
                                            variant="caption"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const element = document.createElement("a");
                                                element.setAttribute(
                                                    "href",
                                                    `${omschrijving_source.temporary_link}&response-content-disposition=attachment; filename=${omschrijving_source.filename}`
                                                );

                                                element.style.display = "none";
                                                document.body.appendChild(element);
                                                element.click();
                                                document.body.removeChild(element);
                                            }}
                                        >
                                            {omschrijving_source.filename}
                                        </Link>
                                    )}
                                </>
                            ) : omschrijving_source?.__typename === "Publication" ? (
                                <Typography style={{ fontStyle: "italic" }} variant="caption">
                                    {moment(omschrijving_source?.dispatchdate).format("LL")} - {omschrijving_source?.host},{" "}
                                    {omschrijving_source?.publication_type.name}
                                </Typography>
                            ) : (
                                ""
                            )}
                        </React.Fragment>
                    )}
                </div>
                <ReadMoreBasedOnNewLines text={description} variant={"body2"} lines={qualitativeScope !== "" ? 8 : 120} id="summarydiv" />
            </>

            {/* Qualitative scope */}
            <>
                <div style={{ marginBottom: 8, marginTop: 16 }}>
                    {qualitativeScope !== "" && <Typography variant="h4">{t("tenderPage.Scope")}</Typography>}
                    {showSource && qualitativeScope !== "" && (
                        <React.Fragment>
                            <Typography style={{ fontStyle: "italic" }} variant="caption">
                                {t("Source")}:{" "}
                            </Typography>

                            {omvang_opdracht_source?.__typename === "Document" ? (
                                <>
                                    {omvang_opdracht_source.extension === "pdf" ? (
                                        <Link
                                            component="button"
                                            underline="none"
                                            style={{ fontStyle: "italic" }}
                                            variant="caption"
                                            onClick={() => {
                                                setOpen({
                                                    link: omvang_opdracht_source.temporary_link,
                                                    open: true,
                                                    label: omvang_opdracht_source.filename,
                                                    doc_id: omvang_opdracht_source.id,
                                                });
                                            }}
                                        >
                                            {omvang_opdracht_source.filename}
                                        </Link>
                                    ) : (
                                        <Link
                                            component="button"
                                            underline="none"
                                            style={{ fontStyle: "italic" }}
                                            variant="caption"
                                            onClick={(e) => {
                                                // Stop click on listitem to prevent document-viewer to initiate
                                                e.stopPropagation();
                                                const element = document.createElement("a");

                                                element.setAttribute(
                                                    "href",
                                                    `${omvang_opdracht_source.temporary_link}&response-content-disposition=attachment; filename=${omvang_opdracht_source.filename}`
                                                );
                                                element.style.display = "none";
                                                document.body.appendChild(element);
                                                element.click();
                                                document.body.removeChild(element);
                                            }}
                                        >
                                            {omvang_opdracht_source.filename}
                                        </Link>
                                    )}
                                </>
                            ) : omvang_opdracht_source?.__typename === "Publication" ? (
                                <Typography style={{ fontStyle: "italic" }} variant="caption">
                                    {moment(omvang_opdracht_source?.dispatchdate).format("LL")} - {omvang_opdracht_source?.host},{" "}
                                    {omvang_opdracht_source?.publication_type.name}
                                </Typography>
                            ) : (
                                ""
                            )}
                        </React.Fragment>
                    )}
                </div>

                <ReadMoreBasedOnNewLines text={qualitativeScope} variant={"body2"} lines={description !== "" ? 8 : 120} id="qualiscope" />
            </>

            {/* Drawer that contains clicked pdf file */}
            {typeof open.link === "string" && open.open === true && typeof open.label === "string" && (
                <DocumentDrawer
                    open={open.open}
                    onClose={closeDrawer}
                    title={open.label}
                    color={blue}
                    url={open.link}
                    doc_id={open.doc_id}
                    module_item_id={tender_id}
                />
            )}
        </div>
    );
};

export default Summary;
