import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Link as Linkie } from "react-router-dom";

//Import Material UI
import { ListItemButton, useMediaQuery, useTheme } from "@mui/material";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

//Import components
import WidgetBox from "../../../components/WidgetBox";
import PiechartReview from "./PiechartReview";
import MenuWidget from "./MenuWidget";
import { getSearchlineMeta, getSearchlineMetaVariables, getSearchlineMeta_getSearchesStatistics } from "../../../__generated__/getSearchlineMeta";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getSearchRuleCount } from "../../../__generated__/getSearchRuleCount";
import { GET_SEARCH_RULE_META } from "../../../graphql/queryDefSearchAndHighlighting";
import { GET_AMOUNT_OF_SEARCHRULES } from "../../../graphql/queryDefCurrentUser";

interface Props {
    a?: number;
}

const ReviewGraph: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    // Check if device is mobile or not
    const [variable, setVariable] = useState({ day: 30, month: 0 });
    // state for all data available
    const [graphData, setGraphData] = useState<(getSearchlineMeta_getSearchesStatistics | null)[]>([]);
    // state for selected searchrule
    const [singleGraph, setSingleGraph] = useState<getSearchlineMeta_getSearchesStatistics | null>();

    const { data: searchesCount } = useQuery<getSearchRuleCount>(GET_AMOUNT_OF_SEARCHRULES);
    // Lazyquery to fetch quarter data from backend
    const [getQuarter, { loading }] = useLazyQuery<getSearchlineMeta, getSearchlineMetaVariables>(GET_SEARCH_RULE_META, {
        variables: {
            // default variable is 30 days
            days: variable.day,
            months: variable.month,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.getSearchesStatistics) {
                // setGraphData contains all searchrules
                setGraphData(data.getSearchesStatistics);
                // set to last item in array
                // last item in array is item with cumulative data
                setSingleGraph(data.getSearchesStatistics[data.getSearchesStatistics.length - 1]);
            }
        },
    });

    const copyData = [...graphData];

    /**
     * Get data for selected period
     */
    const sumOfData = copyData;
    // const sumOfData = copyData.filter((r) => r !== undefined && r !== null && r.disliked + r.liked + r.not_reviewed !== 0);

    useEffect(() => {
        getQuarter();
    }, [getQuarter]);

    return (
        <div id="review-widget">
            <WidgetBox
                highlight="blue"
                padding={0}
                header={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>{t("dashboardPage.Searchrules_tenders")}</div>
                        <Typography>
                            <Linkie id="link-to-full-list-rules" to="/profile/search-rules">
                                {t("dashboardPage.ShowAllRules")}
                            </Linkie>
                        </Typography>
                    </div>
                }
            >
                <div style={{ display: "flex", flexDirection: onlyMobile ? "column-reverse" : "row", width: "100%", height: "100%" }}>
                    {/*
                     * chart div with daterange selector
                     */}
                    <div
                        style={{
                            height: onlyMobile ? "300px" : "350px",
                            width: onlyMobile ? "100%" : "65%",
                            padding: onlyMobile ? "20px" : "20px 0 16px 20px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {/*
                         * small filter menu
                         */}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <MenuWidget
                                updateFilter={(day: number, month: number) => {
                                    setVariable({ day: day, month: month });
                                    getQuarter();
                                }}
                            />
                        </div>

                        {/*
                         * DonutChart
                         */}
                        {loading ? <FullPageSpinner /> : <PiechartReview data={singleGraph} />}
                    </div>
                    {/*
                     * Vertical divider to divide chart from listview
                     */}
                    <Divider orientation="vertical" flexItem />

                    {/*
                     * List with for each searchRule an list item
                     */}
                    <List sx={{ padding: 0, width: onlyMobile ? "100%" : "35%", height: onlyMobile ? "200px" : "350px", overflow: "auto" }}>
                        {/*
                         * get copy of array searchrules
                         * reverse array with cumulative data as first item
                         * filter array, hide searchrule with no data
                         * map array to list items with searchrule name and searchrule data
                         */}
                        {sumOfData.length > 0 &&
                            copyData.reverse().map((row, i) => {
                                const sum = row !== undefined && row !== null ? row.liked + row.disliked + row.not_reviewed : 0;
                                if (sum === 0) {
                                    return <></>;
                                }
                                return (
                                    <ListItemButton
                                        selected={singleGraph?.search?.id === row?.search?.id}
                                        onClick={() => setSingleGraph(row)}
                                        key={i}
                                    >
                                        <ListItemIcon>
                                            {row?.search ? <LocalOfferIcon style={{ color: `${row?.search?.color}` }} /> : <LocalOfferOutlinedIcon />}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography noWrap>{row?.search ? row?.search?.name : "Alles"}</Typography>}
                                            secondary={
                                                <Typography noWrap style={{ color: "#9e9e9e" }}>
                                                    {sum} {t("dashboardPage.tenders")}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                );
                            })}
                        {/* If sum of data is 0, show no results found instead of searchrule items */}
                        {(searchesCount?.currentUser.searches === undefined ||
                            searchesCount?.currentUser.searches.length === 0 ||
                            sumOfData.length === 0) &&
                            !loading && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // height: "calc(100% - 70px)",
                                        height: "100%",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div>
                                        <Typography sx={{ fontSize: "1.25rem", fontWeight: 500 }}>Geen zoekregels</Typography>
                                    </div>
                                    <div>
                                        <Link
                                            sx={{
                                                mt: "4px",
                                                fontSize: "0.875rem",
                                            }}
                                            underline="none"
                                            component="button"
                                            onClick={() => {
                                                navigate("/profile/search-rules");
                                            }}
                                        >
                                            maak een zoekregel aan
                                        </Link>
                                    </div>
                                </div>
                            )}
                    </List>
                </div>
            </WidgetBox>
        </div>
    );
};

export default ReviewGraph;
