import { Box, List, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import React from "react";
import { Today } from "@mui/icons-material";
import {
    SavedTenderEvents_getCalendarEvents,
    SavedTenderEvents_getCalendarEvents_TenderDate,
    SavedTenderEvents_getCalendarEvents_TenderEvent,
    SavedTenderEvents_getCalendarEvents_UserEvent,
} from "../../../__generated__/SavedTenderEvents";
import MyTenderDate from "./MyTenderDate";
import MyTenderEvent from "./MyTenderEvent";
import MyUserEvent from "./MyUserEvent";

interface Props {
    data: (SavedTenderEvents_getCalendarEvents | null)[];
    dateRangeState: {
        from: Moment | null;
        to: Moment | null;
    };
    setDateRange: React.Dispatch<
        React.SetStateAction<{
            from: Moment | null;
            to: Moment | null;
        }>
    >;
    openId: string;
    setOpenId: (str: string) => void;
    setActiveTooltip: (bln: any) => void;
    activeTooltip: string | false;
}

const MyCalendarData: React.FC<Props> = ({ setDateRange, dateRangeState, data, openId, setOpenId, setActiveTooltip, activeTooltip }) => {
    const renderSwitch = (param: "TenderDate" | "TenderEvent" | "UserEvent" | undefined, row: SavedTenderEvents_getCalendarEvents | null) => {
        switch (param) {
            case "TenderDate":
                return (
                    <MyTenderDate
                        data={row as SavedTenderEvents_getCalendarEvents_TenderDate}
                        setActiveTooltip={setActiveTooltip}
                        activeTooltip={activeTooltip}
                        openId={openId}
                        setOpenId={setOpenId}
                    />
                );

            case "TenderEvent":
                return (
                    <MyTenderEvent
                        data={row as SavedTenderEvents_getCalendarEvents_TenderEvent}
                        setActiveTooltip={setActiveTooltip}
                        activeTooltip={activeTooltip}
                        openId={openId}
                        setOpenId={setOpenId}
                    />
                );

            case "UserEvent":
                return (
                    <MyUserEvent
                        data={row as SavedTenderEvents_getCalendarEvents_UserEvent}
                        setActiveTooltip={setActiveTooltip}
                        activeTooltip={activeTooltip}
                        openId={openId}
                        setOpenId={setOpenId}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <Box sx={{ width: "100%", height: { xs: "335px", md: "355px" }, position: "relative", overflow: "auto", padding: "0px" }}>
            <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}>
                <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    {<Today style={{ color: "#f2f2f270", height: "9rem", width: "9rem" }} />}
                    <Typography variant="h5" style={{ color: "#f2f2f2" }}>
                        Mijn agenda items
                    </Typography>
                </div>
            </div>
            <List sx={{ padding: 0, overflow: "auto", zIndex: 99, backgroundColor: "#ffffff" }}>
                {data
                    .slice()
                    .sort((a, b) => {
                        // Handle null cases
                        if (!a || !b) {
                            if (!a && !b) return 0;
                            return a ? -1 : 1;
                        }

                        //! Possibililty to add multiple typenames and fields to sort on
                        // Define date variables
                        let dateA: moment.Moment | null = null;
                        let dateB: moment.Moment | null = null;

                        // Parse dates based on the type of object
                        if (a.__typename === "TenderEvent") {
                            dateA = moment(a.planned);
                        } else if (a.__typename === "TenderDate") {
                            dateA = moment(a.planned);
                        }

                        if (b.__typename === "TenderEvent") {
                            dateB = moment(b.planned);
                        } else if (b.__typename === "TenderDate") {
                            dateB = moment(b.planned);
                        }

                        // Compare the dates
                        if (dateA && dateB) {
                            return dateA.diff(dateB);
                        } else if (dateA) {
                            return -1;
                        } else if (dateB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                    .map((row, index) => {
                        return <React.Fragment key={index}>{renderSwitch(row?.__typename, row)}</React.Fragment>;
                    })}
            </List>
        </Box>
    );
};

export default MyCalendarData;
