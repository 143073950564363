import React from "react";

interface Props {
    selectedItem: any;
}

const Scopevaluedatacell: React.FC<Props> = ({ selectedItem }) => {
    if (!selectedItem) {
        return <></>;
    }

    const maxValue = selectedItem.max !== null && selectedItem.max !== undefined;
    const totalValue = selectedItem.total !== null && selectedItem.total !== undefined;
    const minValue = selectedItem.min !== null && selectedItem.min !== undefined;

    return (
        <React.Fragment>
            <div style={{ marginBottom: 0 }}>
                {totalValue && (
                    <div>
                        {selectedItem?.total?.currency === "EUR" ? "totaal: €" : selectedItem?.total.currency}{" "}
                        {selectedItem?.total?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                    </div>
                )}
                {minValue && (
                    <div>
                        {selectedItem?.min.currency === "EUR" ? "min: €" : selectedItem?.min.currency}{" "}
                        {selectedItem?.min?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                    </div>
                )}
                {maxValue && (
                    <div>
                        {selectedItem?.max.currency === "EUR" ? "max: €" : selectedItem?.max.currency}{" "}
                        {selectedItem?.max?.value?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Scopevaluedatacell;
