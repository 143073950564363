import React from "react";
import { IconButton, Typography } from "@mui/material";
import ShowTitleHistory from "../../dialogs/ShowTitleHistory";
import dompurify from "dompurify";
import { History } from "@mui/icons-material";

interface Props {
    title: string;
    rawPublications: any[];
    onBtnClickOpen: () => void;
    onBtnClickClose: () => void;
    open: boolean;
    normal_title: string;
}

const TenderTitle: React.FC<Props> = ({ title, rawPublications, onBtnClickClose, onBtnClickOpen, open, normal_title }) => {
    return (
        <React.Fragment>
            <div style={{ display: "inline-block" }}>
                {/* Title of tender */}
                {/* When there are titlechanges, show previous versions in modal */}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography
                        style={{ display: "inline", whiteSpace: "pre-wrap" }}
                        variant="h1"
                        dangerouslySetInnerHTML={{
                            __html: dompurify.sanitize(title, { FORCE_BODY: true }),
                        }}
                    />
                    {rawPublications
                        .filter((i) => i.namegiventocontract !== null)
                        .filter((i) => i.namegiventocontract.trim()?.replace(/\s{2,}/g, " ") !== normal_title.trim().replace(/\s{2,}/g, " ")).length >
                        0 && (
                        <IconButton size="small" style={{ marginLeft: 4 }} onClick={onBtnClickOpen}>
                            <History />
                        </IconButton>
                    )}
                </div>
            </div>
            {/*
             * Modal with title changes
             */}
            <ShowTitleHistory open={open} onClose={onBtnClickClose} data={rawPublications} />
        </React.Fragment>
    );
};

export default TenderTitle;
