import { Box, Typography } from "@mui/material";
import React from "react";
import LoadingProjectCard from "./projectcard/LoadingProjectCard";
import { Check } from "@mui/icons-material";
import { Column } from "./ColumnTemplateModal";

interface Props {
    onToggle: (e: any, value: Column) => void;
    chosenEntity: string;
    chosenColumns: Column[];
    hardcodedData: {
        id: string;
        name: string;
        explanation: string;
        columnsToShow: {
            name: string;
            text: string;
            order: number;
        }[];
    }[];
}

const PredefinedColumns: React.FC<Props> = ({ onToggle, hardcodedData, chosenEntity, chosenColumns }) => {
    return (
        <React.Fragment>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <div style={{ height: "80px" }}>
                    <Typography variant="h3" style={{ fontWeight: 500, marginBottom: "12px" }}>
                        Voorbeeldweergave
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 400, lineHeight: "18px" }}>
                        Selecteer de gewenste kolommen.
                        <br />
                        Toevoegen van extra kolommen en het wijzigen van de namen kan in het overzicht.
                    </Typography>
                </div>
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        overflowX: "auto",
                        paddingBottom: "24px",
                        marginTop: "32px",
                    }}
                >
                    <Box
                        component={"div"}
                        sx={{
                            "&:hover": {
                                cursor: "pointer",
                                background: "#e7e7e7",
                            },
                            minWidth: "250px",
                            maxWidth: "250px",
                            background: "#e7e7e7",
                            marginLeft: "8px",
                            marginRight: "8px",
                            padding: "12px",
                            height: "100%",
                            minHeight: "200px",
                            borderRadius: "6px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                }}
                            >
                                <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                    <Typography variant="h5">Nieuw</Typography>
                                </div>
                            </div>
                            <div style={{ marginBottom: "16px", height: "50px" }}>
                                <div>
                                    <Typography>Hier komen standaard de nieuwste projecten</Typography>
                                </div>
                            </div>
                            {/* Static loading cards  */}
                            <LoadingProjectCard maxWidth={200} minWidth={100} staticMode />
                            <LoadingProjectCard maxWidth={200} minWidth={100} staticMode />
                            <LoadingProjectCard maxWidth={200} minWidth={100} staticMode />
                        </div>
                        <div style={{ textAlign: "end" }}>
                            <Check style={{ color: "green" }} />
                        </div>
                    </Box>
                    {hardcodedData
                        .filter((i) => i.id === chosenEntity)
                        .map((q) =>
                            q.columnsToShow.map((t, index) => {
                                const col: Column = { name: t.name, order: t.order };
                                return (
                                    <div key={index} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                        <Box
                                            onClick={(e) => {
                                                onToggle(e, col);
                                            }}
                                            component={"div"}
                                            sx={{
                                                "&:hover": {
                                                    cursor: "pointer",
                                                    background: "#ededed",
                                                },
                                                minWidth: "250px",
                                                maxWidth: "250px",
                                                background: chosenColumns.map((i) => i.name).includes(col.name) ? "#e7e7e7" : "#f4f4f4",
                                                marginLeft: "8px",
                                                marginRight: "8px",
                                                padding: "12px",
                                                height: "100%",
                                                minHeight: "200px",
                                                borderRadius: "6px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                        <Typography variant="h5">{t.name}</Typography>
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: "16px", height: "50px" }}>
                                                    <div>
                                                        <Typography>{t.text}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ textAlign: "end" }}>
                                                {chosenColumns.map((i) => i.name).includes(col.name) ? <Check style={{ color: "green" }} /> : <></>}
                                            </div>
                                        </Box>
                                    </div>
                                );
                            })
                        )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PredefinedColumns;
