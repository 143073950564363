import React, { useState } from "react";
import {
    Avatar,
    ListItemText,
    ListItem,
    ListItemAvatar,
    Divider,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ListItemSecondaryAction,
    IconButton,
    DialogContentText,
    Tooltip,
} from "@mui/material";

import { useDrag } from "react-dnd";
import truncate from "truncate";

// Icons used on this page
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { UserInTeam } from "./UsersAndTeams";
import {
    HdrAuto,
    MoreVert,
    // ToggleOff, ToggleOn
} from "@mui/icons-material";
import { Disable } from "react-disable";
import BoxWithSearchrulesToMove from "./BoxWithSearchrulesToMove";
import { useCurrentUserOrganization } from "../../../components/contextProviders/CurrentUserOrganization";
import { GetSubscriptionUsers } from "../../../__generated__/GetSubscriptionUsers";
import { QUERY_SUBSCRIPTION_USERS } from "../../../graphql/queryDefUserAndTeams";
import { useQuery } from "@apollo/client";
import { GetOrganizationSearchRules } from "../../../__generated__/GetOrganizationSearchRules";
import { GET_ORGANIZATION_SEARCHES } from "../../../graphql/queryDefCurrentUser";

/**
 * Type definition of "item" that will be passed
 * from drop item to drop target
 */
export interface DropPayload {
    userId: string;
}

interface Props {
    onDelete(id: string): void;
    singleUser: UserInTeam;
    isAdmin: boolean;
    cuIsOwner: boolean;
    onUpgrade(id: number): void;
}

const UserListItem: React.FC<Props> = ({ onDelete, singleUser, isAdmin, cuIsOwner, onUpgrade }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const { org_id } = useCurrentUserOrganization();

    // Function to filter search rules based on user_id
    const filterSearchRulesByUserId = (data: GetOrganizationSearchRules) => {
        return data.getOrganizationSearches?.filter((search) => search?.owner?.id === singleUser.id) || [];
    };

    /**
     * Define the GQL query
     */
    const { data, refetch } = useQuery<GetOrganizationSearchRules>(GET_ORGANIZATION_SEARCHES, {
        // skip: !admin,
        fetchPolicy: "network-only",
    });

    /**
     * Query colleagues of user to create contactlist
     */
    const colleagues = useQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
    });

    const handleClickOpen = () => {
        setOpen(true);
        handleCloseSub();
    };

    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Drag User from list to team and get an alert when dropped in zone.
     * Item
     */
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "USER",
        item: singleUser,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }));

    const opacity = isDragging ? 0.4 : 1;

    /**
     * Open submenu to remove or edit user
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = () => {
        setAnchorEl(null);
    };
    const user = singleUser.employee;

    //TODO: if user is not a teamowner or accountowner => drag is not possible
    return (
        <Disable disabled={user.id.includes("uniqueID")}>
            <ListItem ref={drag} role="Box" style={{ opacity: opacity }}>
                <ListItemAvatar>
                    <Avatar>
                        {(user.name ? user.name : "").charAt(0)}
                        {(user.familyname ? user.familyname : "").charAt(0)}
                    </Avatar>
                </ListItemAvatar>

                {/* Listitem with userdetails. */}
                <ListItemText
                    /**
                     * Combination of user givenname and familyname. line through when user is inactive
                     */
                    primary={
                        <>
                            {/* USERNAME */}
                            <Typography>{truncate(user.name ? user.name : "", 40)}</Typography>
                        </>
                    }
                    /**
                     * Second line in useritem, linethrough when state is inactive.
                     */
                    secondary={
                        <>
                            {/* MAIL */}
                            <Typography style={{ fontSize: "0.875rem" }}>{singleUser.email}</Typography>

                            {/* SHOW IF USER IS SUBSCRIPTION_ADMIN */}
                            <Typography style={{ fontSize: "0.875rem" }}>
                                {/* {user.state} */}
                                {isAdmin ? "Beheerdersaccount" : null}
                            </Typography>

                            {/* SHOW IF USER IS NOT VERIFIED YET */}
                            <Typography style={{ fontSize: "0.875rem" }}>
                                {singleUser.verified !== true ? "Uitnodiging nog niet geaccepteerd" : ""}
                            </Typography>
                        </>
                    }
                />

                {/* Action to show user that its possible to do something with a specific user. */}
                {singleUser.id !== localStorage.getItem("user_id") && cuIsOwner && (
                    <>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickSub}>
                                <MoreVert />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </>
                )}

                {/* Submenu */}
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseSub}
                >
                    {/* MenuItem with edit function. */}
                    {/* <MenuItem onClick={handleCloseSub}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Maak beheerder"} />
                    </MenuItem> */}

                    {/* MenuItem to activate user, only visible when user state is inactive */}
                    {/* <MenuItem disabled onClick={handleCloseSub}>
                        <ListItemIcon>
                            <ToggleOn style={{ color: "green" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Gebruiker activeren"} />
                    </MenuItem> */}

                    {/* MenuItem to deactivate a user, only visible when user state is active */}
                    {/* <MenuItem onClick={handleCloseSub}>
                        <ListItemIcon>
                            <ToggleOff style={{ color: "indianred" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Gebruiker deactiveren"} />
                    </MenuItem> */}

                    {/* MenuItem to remove a user */}
                    <MenuItem onClick={handleClickOpen}>
                        <ListItemIcon>
                            <ClearIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Gebruiker verwijderen"} />
                    </MenuItem>

                    {/* MenuItem to remove a user */}
                    <MenuItem
                        disabled={singleUser.subscription_admin}
                        onClick={() => {
                            onUpgrade(parseInt(singleUser.id));
                            handleCloseSub();
                        }}
                    >
                        <ListItemIcon>
                            <HdrAuto />
                        </ListItemIcon>
                        <ListItemText primary={"Maak beheerder"} />
                    </MenuItem>
                </Menu>
            </ListItem>
            <Divider />

            {/*
             * Modal to show when user wants to delete user
             */}
            <Dialog
                open={open}
                fullWidth
                maxWidth={data && filterSearchRulesByUserId(data).length > 0 ? "lg" : "sm"}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Gebruiker verwijderen</DialogTitle>

                <DialogContent>
                    {data && filterSearchRulesByUserId(data).length > 0 ? (
                        <BoxWithSearchrulesToMove
                            data={filterSearchRulesByUserId(data)}
                            refetch={refetch}
                            nameOfUserToDelete={singleUser.employee.name ?? ""}
                            idOfUserToDelete={singleUser.id}
                            colleagues={colleagues.data?.organization?.users.filter((u) => u.verified === true) ?? []}
                        />
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            Weet je zeker dat je <span style={{ fontWeight: 500 }}>{singleUser.employee.name}</span> wilt verwijderen? <br />
                            {/* Gegevens van het account bewaren? kies voor account deactiveren om de data op een later moment alsnog te kunnen gebruiken wanneer het account weer wordt geactiveerd. */}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    {/*
                     * CANCEL BTN
                     */}
                    <Button onClick={handleClose}>annuleren</Button>

                    {/*
                     * DELETE BTN
                     */}
                    {data && filterSearchRulesByUserId(data).length > 0 ? (
                        <Tooltip
                            title={`${singleUser.employee.name} heeft nog zoekregels`}
                            placement="top-end"
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, -14],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <span>
                                <Button
                                    disabled
                                    color="secondary"
                                    onClick={() => {
                                        onDelete(singleUser.id);
                                        handleClose();
                                    }}
                                    autoFocus
                                    sx={{ color: "#c0392b" }}
                                >
                                    Verwijderen
                                </Button>
                            </span>
                        </Tooltip>
                    ) : (
                        <Button
                            color="secondary"
                            onClick={() => {
                                onDelete(singleUser.id);
                                handleClose();
                            }}
                            autoFocus
                            sx={{ color: "#c0392b" }}
                        >
                            Verwijderen
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Disable>
    );
};

export default UserListItem;
