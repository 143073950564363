import React from "react";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Check, KeyboardArrowRight } from "@mui/icons-material";
import HeroDot1 from "../Hero_GUI/HeroDot1";
import OverlappingDotLeft from "../Hero_GUI/OverlappingDotLeft";
import OverlappingDotRight from "../Hero_GUI/OverlappingDotRight";
import WobbleLine from "../Hero_GUI/WobbleLine";
import { usePoll } from "./PollContext";

interface Props {
    hideLogo?: boolean;
    admin?: boolean;
}

const PollLayout: React.FC<Props> = ({ children, hideLogo = false, admin = false }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const { pollArray, navToNextQuestion, getNextQuestionId, currentQuestion, finishCurrentPoll } = usePoll();

    const nextQuestionId = getNextQuestionId(pollArray, currentQuestion?.activeTendenzQuestion?.id as number);

    return (
        <div style={{ height: "100dvh", display: "flex", flexDirection: "column", position: "relative", overflow: "hidden" }}>
            {!hideLogo && (
                <div>
                    {noMobile ? (
                        <Box sx={{ zIndex: 99, position: "relative", margin: "32px" }}>
                            <img src="https://storage.googleapis.com/tenderguide-public/tendenzLogo.png" alt="TendenZ" width="auto" height="72px" />
                        </Box>
                    ) : (
                        <Box sx={{ margin: "32px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src="https://storage.googleapis.com/tenderguide-public/tendenzLogo.png" alt="TendenZ" width="auto" height="56px" />
                        </Box>
                    )}
                </div>
            )}

            {/* ANSWERS */}
            <div style={{ flex: 1, display: "flex", zIndex: 99 }}>{children}</div>

            {admin ? (
                <div>
                    <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
                        {/* <Button sx={{ zIndex: 99 }} variant="text" startIcon={<KeyboardArrowLeft />}>
                            Vorige
                        </Button> */}
                        {nextQuestionId === -1 ? (
                            <IconButton
                                sx={{ zIndex: 99 }}
                                onClick={() => finishCurrentPoll(1)}
                                disabled={(currentQuestion?.activeTendenzQuestion?.id as number) === 0}
                            >
                                <Check />
                            </IconButton>
                        ) : (
                            <IconButton
                                sx={{ zIndex: 99 }}
                                onClick={() => navToNextQuestion(nextQuestionId)}
                                disabled={(currentQuestion?.activeTendenzQuestion?.id as number) === 0}
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        )}
                    </Box>
                </div>
            ) : (
                <div>
                    <Box sx={{ display: "flex", justifyContent: "center", p: 2, alignItems: "center" }} />
                </div>
            )}

            {/* Dot left */}
            {<HeroDot1 color={"#70a4b71A"} top="100px" />}
            {/* Dot  overlap left*/}
            {<OverlappingDotLeft color="#82b6a01A" top={noMobile ? undefined : "300px"} left={noMobile ? undefined : "150px"} />}
            {/* Dot */}
            {<OverlappingDotRight color="#70a4b71A" top={noMobile ? undefined : "550px"} left={noMobile ? undefined : "0px"} />}
            {/* Wobble line */}
            {noMobile && <WobbleLine />}
        </div>
    );
};

export default PollLayout;
