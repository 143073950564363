import { Box, Button, Checkbox, Divider, InputAdornment, ListItemButton, ListItemText, Popover, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { User } from "./Avatars";
import { Search } from "@mui/icons-material";
import truncate from "truncate";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import { useMutation, useQuery } from "@apollo/client";
import { single_project_tasks, single_project_tasksVariables } from "../../../__generated__/single_project_tasks";
import { ALL_PROJECTS, SINGLE_PROJECT, SINGLE_PROJECT_TASKS } from "../../../graphql/queryDefProjects";
import { updateUsersInProject, updateUsersInProjectVariables } from "../../../__generated__/updateUsersInProject";
import { UPDATE_USERS_IN_PROJECT } from "../../../graphql/mutationDefProjects";

interface Props {
    anchorEl: any;
    handleCloseSub: (e: any) => void;
    open: boolean;
    users: User[];
    AlreadyChosenUsers: User[];
}

const AddUsersToProject: React.FC<Props> = ({ anchorEl, handleCloseSub, open, users, AlreadyChosenUsers }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const { project, changeProject } = useContext(ProjectToOpenContext);
    const [arrayWithUsersOrTeamsToShare, setArrayWithUsersOrTeamsToShare] = useState<User[]>([]);

    useEffect(() => {
        if (arrayWithUsersOrTeamsToShare.length === 0) {
            setArrayWithUsersOrTeamsToShare(AlreadyChosenUsers);
        }
    }, [arrayWithUsersOrTeamsToShare, AlreadyChosenUsers]);

    const { data } = useQuery<single_project_tasks, single_project_tasksVariables>(SINGLE_PROJECT_TASKS, {
        variables: {
            id: project.projectID,
        },
        fetchPolicy: "network-only",
    });

    const [saveUsersOfProject] = useMutation<updateUsersInProject, updateUsersInProjectVariables>(UPDATE_USERS_IN_PROJECT);

    /**
     * Add new users to project with current widget settings syncing
     */
    const saveProjectUsers = () => {
        const removedUsers = AlreadyChosenUsers.filter((user) => !arrayWithUsersOrTeamsToShare.some((u) => u.id === user.id)).map((usr) => usr.id);
        const addedUsers = arrayWithUsersOrTeamsToShare.filter((user) => !AlreadyChosenUsers.some((u) => u.id === user.id)).map((usr) => usr.id);

        saveUsersOfProject({
            variables: {
                id: project.projectID,
                add_ids: addedUsers,
                remove_ids: removedUsers,
                widgets: project.widgets,
            },
            refetchQueries: [SINGLE_PROJECT, ALL_PROJECTS],
            onCompleted: () => {
                changeProject({ users: arrayWithUsersOrTeamsToShare });
            },
        });
    };

    /**
     * @param value clicked user/team
     * @returns return arrayWithUsersOrTeamsToShare or unchecked state of listitem
     */
    const handleToggle = (e: any, user: User) => {
        e.stopPropagation();

        const checkedids = arrayWithUsersOrTeamsToShare.map((i) => i.id);
        const currentIndex = checkedids.indexOf(user.id);
        const newChecked = [...arrayWithUsersOrTeamsToShare];

        if (currentIndex === -1) {
            newChecked.push(user);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        // setUsersToRemove(toremove);
        setArrayWithUsersOrTeamsToShare(newChecked);
    };

    /**
     * handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */
    const handleSearchUser = (event: any) => {
        event.stopPropagation();
        const {
            target: { value },
        } = event;

        setSearchTerm(value);
    };

    const results = !searchTerm ? users : users.filter((item) => item.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseSub}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            /**
             * Width and height for chat/knowledge base
             */
            slotProps={{
                paper: {
                    sx: {
                        width: "300px",
                    },
                },
            }}
            // PaperProps={{
            //     style: { width: "300px" },
            // }}
        >
            <div>
                {/*
                 * Searchbox
                 */}
                <Box style={{ width: "100%", display: "flex" }}>
                    <TextField
                        onClick={(e) => e.stopPropagation()}
                        sx={{ margin: "10px", flexGrow: 1 }}
                        placeholder="Zoek een gebruiker..."
                        size="small"
                        variant="outlined"
                        onChange={(e) => {
                            e.stopPropagation();
                            handleSearchUser(e);
                        }}
                        value={searchTerm}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ maxHeight: "310px", overflow: "auto" }}>
                    {results.map((user) => {
                        const userHasOpenTasks =
                            data?.project?.tasks?.filter((task) => task.users?.find((u) => u.id === user.id)) &&
                            data?.project?.tasks?.filter((task) => task.task_completed === false && task.users?.find((u) => u.id === user.id))
                                .length > 0;

                        /**
                         * UserListItem
                         */
                        return (
                            <React.Fragment key={user.id}>
                                <ListItemButton
                                    onClick={(e) => handleToggle(e, user)}
                                    dense
                                    // selected={Boolean(arrayWithUsersOrTeamsToShare.find((usr) => usr.id === user.id))}
                                    disableRipple
                                    // disabled={Boolean(userHasOpenTasks)}
                                >
                                    {/* Listitem with userdetails. */}
                                    <ListItemText
                                        /**
                                         * Combination of user givenname and familyname. line through when user is inactive
                                         */
                                        primary={
                                            <>
                                                <Typography>{truncate(user.name ? user.name : "", 40)}</Typography>
                                            </>
                                        }
                                        secondary={
                                            Boolean(userHasOpenTasks) && (
                                                <Typography variant="caption" style={{ color: "#c0392b" }}>
                                                    Openstaande taken:{" "}
                                                    {data?.project?.tasks?.filter((task) => task.users?.find((u) => u.id === user.id)).length}
                                                </Typography>
                                            )
                                        }
                                    />
                                    {/* Checkbox */}
                                    <Checkbox
                                        size="small"
                                        edge="start"
                                        checked={Boolean(arrayWithUsersOrTeamsToShare.find((usr) => usr.id === user.id))}
                                        tabIndex={-1}
                                        disableRipple
                                        color="primary"
                                    />
                                </ListItemButton>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
                </Box>

                {/* Buttons to cancel or share */}
                <Box style={{ margin: 8, display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="text"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCloseSub(e);
                            setArrayWithUsersOrTeamsToShare(AlreadyChosenUsers);
                        }}
                    >
                        Annuleren
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCloseSub(e);
                            saveProjectUsers();
                        }}
                    >
                        Opslaan
                    </Button>
                </Box>
            </div>
        </Popover>
    );
};

export default AddUsersToProject;
