import { gql } from "@apollo/client";

/**
 * Query to get opportunitydocuments
 * response based on variables used in query
 */
export const QUERY_OPPORTUNITY_DOCUMENTS = gql`
    query GetAllDocsForOpportunities(
        $source: OpportunitySearchSource!
        $search: OpportunitySearchInput
        $orderField: String!
        $order: SortOrder!
        $first: Int!
        $page: Int
    ) {
        filterOpportunityFiles(search: $search, orderBy: [{ column: $orderField, order: $order }], first: $first, page: $page, source: $source) {
            snippets {
                id
                snippets
            }
            filters {
                title
                topFilter
                searchRuleFilter
                active
                values {
                    identifier
                    label
                    numberValue
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    name

                    # AD data
                    contractingAuthority {
                        id
                        name
                        favicon
                        favicon_resized
                        #     address
                        #        postalcode
                        city
                        country {
                            alpha2
                            name
                            numeric
                        }
                        types
                    }
                    # type of document
                    type {
                        id
                        name
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

/**
 * Query to get opportunitydocuments
 * response based on variables used in query
 */
export const QUERY_RATED_DOCUMENTS = gql`
    query GetRatedDocsForOpportunities(
        $source: OpportunitySearchSource!
        $search: OpportunitySearchInput
        $orderField: String!
        $order: SortOrder!
        $first: Int!
        $page: Int
    ) {
        filterOpportunityFiles(search: $search, orderBy: [{ column: $orderField, order: $order }], first: $first, page: $page, source: $source) {
            snippets {
                id
                snippets
            }
            filters {
                title
                topFilter
                searchRuleFilter
                active
                values {
                    identifier
                    label
                    numberValue
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    name
                    lastReviewed
                    personalOpportunitiesCount
                    personalResultsCount
                    year_from
                    year_till
                    created_at
                    updated_at
                    # Created opps
                    personalOpportunities {
                        id
                        page {
                            id
                            page
                        }
                        name
                        comment
                        #values
                        parsedValues: yearValues {
                            year
                            value
                        }
                        created_at
                    }

                    # Type of doc
                    type {
                        id
                        name
                    }
                    # CA info
                    contractingAuthority {
                        id
                        name
                        favicon
                        favicon_resized
                        city
                        country {
                            alpha2
                            name
                            numeric
                        }
                        types
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

/**
 * Query to get opportunitydocuments that matches searchrule(s)
 * response based on variables used in query
 */
export const QUERY_WORKLIST_DOCUMENTS = gql`
    query GetWorklistDocsForOpportunities(
        $source: OpportunitySearchSource!
        $search: OpportunitySearchInput
        $orderField: String!
        $order: SortOrder!
        $first: Int!
        $page: Int
    ) {
        filterOpportunityFiles(search: $search, orderBy: [{ column: $orderField, order: $order }], first: $first, page: $page, source: $source) {
            filters {
                title
                topFilter
                searchRuleFilter
                active
                values {
                    identifier
                    label
                    numberValue
                    amountOfResults
                }
            }
            results {
                data {
                    id
                    name
                    # unreviewed_page_count
                    type {
                        id
                        name
                    }

                    # searchrule data
                    #      personalResults {
                    #        id
                    #       search {
                    #          id
                    #         name
                    #    }
                    #}

                    contractingAuthority {
                        id
                        name
                        favicon
                        favicon_resized
                        address
                        postalcode
                        city
                        country {
                            alpha2
                            name
                            numeric
                        }
                        types
                        visit_addresses {
                            city
                        }
                    }
                    #  year_from
                    #   year_till
                    #  created_at
                    #  updated_at
                }
                paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

/**
 * Query amount of documents for specific CA
 */
export const QUERY_AMOUNT_OF_OPPORTUNITY_DOCS_SINGLE_CA = gql`
    query GetAmountOfDocsOfSingleCa(
        $search: OpportunitySearchInput
        $orderField: String!
        $order: SortOrder!
        $first: Int!
        $page: Int
        $source: OpportunitySearchSource!
    ) {
        filterOpportunityFiles(search: $search, orderBy: [{ column: $orderField, order: $order }], first: $first, page: $page, source: $source) {
            filters {
                title
            }
            results {
                data {
                    id
                }
                paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

/**
 * Query to fetch data in opportunity wizard
 * ! source is hardcoded in this query
 */
export const QUERY_ALL_OPPORTUNITY_DOCUMENTS_FOR_WIZARD = gql`
    query GetAllDocsForOppSearchWizard($search: OpportunitySearchInput, $orderField: String!, $order: SortOrder!, $first: Int!, $page: Int) {
        filterOpportunityFiles(
            search: $search
            orderBy: [{ column: $orderField, order: $order }]
            first: $first
            page: $page
            source: ALL_OPPORTUNITY_FILES
        ) {
            filters {
                title
                topFilter
                searchRuleFilter
                active
                values {
                    identifier
                    label
                    numberValue
                    amountOfResults
                }
            }
            snippets {
                id
                snippets
            }
            results {
                data {
                    id
                    name
                    created_at
                    # searchrule data
                    personalResults {
                        id
                        search {
                            id
                            name
                        }
                    }
                    # personal opportunites for page
                    personalOpportunities {
                        id
                    }

                    # AD data
                    contractingAuthority {
                        id
                        name
                        favicon
                        favicon_resized
                        country {
                            alpha2
                            name
                            numeric
                        }
                        types
                        city
                    }
                    # type of document
                    type {
                        id
                        name
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

/**
 * Query for opportunity export
 */
export const EXPORT_CHOSEN_OPPORTUNITY_COLUMNS = gql`
    mutation ExportChosenOpportunityColumns(
        $search: OpportunitySearchInput
        $orderField: String!
        $order: SortOrder!
        $columns: [String]!
        $headers: [String]!
        $source: OpportunitySearchSource!
    ) {
        exportFilterOpportunities(
            search: $search
            orderBy: [{ column: $orderField, order: $order }]
            columns: $columns
            headers: $headers
            source: $source
        )
    }
`;

/**
 * Mutation to save opportunity searchrule
 */
export const SAVESEARCHRULEOPPORTUNITY = gql`
    mutation AddOpportunitySearch($name: String!, $search: OpportunitySearchInput!) {
        addOpportunitySearch(name: $name, search: $search) {
            id
            name
            query
            filters
        }
    }
`;

/**
 * Mutation to update an existing searchrule
 */
export const UPDATESEARCHRULEOPPORTUNITY = gql`
    mutation UpdateOpportunitySearch($id: ID!, $name: String!, $search: OpportunitySearchInput!) {
        updateOpportunitySearch(id: $id, name: $name, search: $search)
    }
`;

/**
 * Fetch savedOpportunity years from current user
 */
export const QUERY_OPP_YEARS_OF_USER = gql`
    query GetUserSavedOppYears {
        currentUser {
            id
            # YEARS OF USER CREATED OPPORTUNITIES
            savedOpportunityYears
        }
    }
`;

/**
 * Fetch Contracting authorites to fill filterdata
 */
export const QUERY_CA_FILTER_DATA = gql`
    query GetContractingAuthorityFilterData(
        $page: Int
        $count: Int
        $orderField: String!
        $order: SortOrder!
        $search: ContractingAuthoritiesSearchInput
    ) {
        filterContractingAuthorities(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                data {
                    id
                    name
                    country {
                        name
                        alpha2
                        numeric
                    }
                }
                paginatorInfo {
                    total
                }
            }
        }
    }
`;

/**
 * Query to get opportunitydocuments
 * response based on variables used in query
 */
export const QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE = gql`
    query GetAllDocsForOpportunitiesOnTender(
        $source: OpportunitySearchSource!
        $search: OpportunitySearchInput
        $orderField: String!
        $order: SortOrder!
        $first: Int!
        $page: Int
    ) {
        filterOpportunityFiles(search: $search, orderBy: [{ column: $orderField, order: $order }], first: $first, page: $page, source: $source) {
            snippets {
                id
                snippets
            }
            results {
                data {
                    id
                    name

                    # searchrule data
                    personalResults {
                        id
                        search {
                            id
                            name
                        }
                    }
                    # personal opportunites for page
                    personalOpportunities {
                        id
                        page {
                            id
                            page
                        }
                        name
                        comment
                        #values
                        parsedValues: yearValues {
                            year
                            value
                        }
                        created_at
                    }

                    personalResultsCount
                    unreviewed_page_count

                    # AD data
                    contractingAuthority {
                        id
                        name
                        favicon
                        favicon_resized
                    }
                    # type of document
                    type {
                        id
                        name
                    }
                }
                paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

/**
 * Query to get opportunitydocuments
 * response based on variables used in query
 */
export const QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE_TO_CREATE_TABS = gql`
    query GetAllDocsForOpportunitiesOnTenderForTabs($source: OpportunitySearchSource!, $search: OpportunitySearchInput) {
        filterOpportunityFiles(search: $search, orderBy: [{ column: "year_from", order: DESC }], first: 1, page: 1, source: $source) {
            results {
                data {
                    id
                }
                paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

// GET LENGTH OF PERSONAL RESULTS
export const QUERY_SINGLE_OPPORTUNITY_PERSONAL_RESULTS = gql`
    query GetSingleOppPersonalResults($id: ID!) {
        opportunityFile(id: $id) {
            id

            # searchRule results
            personalResults {
                id
            }
        }
    }
`;

// GET LENGTH OF unreviewed pages
export const QUERY_SINGLE_OPPORTUNITY_UNREVIEWED_PAGES = gql`
    query GetSingleOppUnreviewedPages($id: ID!) {
        opportunityFile(id: $id) {
            id
            unreviewed_page_count
        }
    }
`;

// GET DATA OF PERSONAL RESULTS
export const QUERY_SINGLE_OPPORTUNITY_PERSONAL_RESULTS_DATA = gql`
    query GetSingleOppPersonalResultsData($id: ID!) {
        opportunityFile(id: $id) {
            id

            # searchRule results
            personalResults {
                id
                search {
                    id
                    name
                }
            }
        }
    }
`;

// GET DOCTYPE
export const QUERY_SINGLE_CREATED_AT = gql`
    query GetSingleOppCreatedAt($id: ID!) {
        opportunityFile(id: $id) {
            id
            publication_date
        }
    }
`;

// GET PERSONAL COUNT
export const QUERY_SINGLE_PERSONAL_COUNT = gql`
    query GetSingleOppPersonalCount($id: ID!) {
        opportunityFile(id: $id) {
            id
            personalOpportunitiesCount
        }
    }
`;
// GET PERSONAL COUNT
export const QUERY_SINGLE_YEAR_FROM = gql`
    query GetSingleOppYearFrom($id: ID!) {
        opportunityFile(id: $id) {
            id
            year_from
        }
    }
`;
// GET PERSONAL COUNT
export const QUERY_SINGLE_LAST_REVIEWED = gql`
    query GetSingleOppLastReview($id: ID!) {
        opportunityFile(id: $id) {
            id
            lastReviewed
        }
    }
`;
