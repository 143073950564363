import React from "react";
import { DateRange } from "../../../../components/TopFilterBar/PeriodFilter";
import FilterDropdownTemplate from "../../../../components/TopFilterBar/FilterDropdownTemplate";
import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { GetUserLogsWithFiltersVariables } from "../../../../__generated__/GetUserLogsWithFilters";

interface Props {
    label: string;
    setDateRange: React.Dispatch<React.SetStateAction<DateRange>>;
    dateRange: DateRange;
    save: () => void;
    reset: () => void;
    active: boolean;
    variables: GetUserLogsWithFiltersVariables;
}

const LogPeriodSelector: React.FC<Props> = ({ label, dateRange, setDateRange, save, reset, active, variables }) => {
    const { t } = useTranslation();

    /**
     * DatePicker
     */
    const error = dateRange.from !== null && dateRange.to !== null && dateRange.from.valueOf() > dateRange.to.valueOf();
    /**
     * Handler when the popover gets closed
     * onError => unset filter to avoid errors in GUI
     * onSuccess => set filter to selected DateRange
     * Format to YYYY-MM-DD (format that is used in backend)
     */
    const handleSave = () => {
        if (error || dateRange.from === null || dateRange.to === null) {
            return setDateRange({
                from: null,
                to: null,
            });
        }
        setDateRange({
            from: moment(dateRange.from),
            to: moment(dateRange.to),
        });
        save();
    };

    // Close modal and set state to daterange
    const handleClose = () => {
        if (variables.createdBetween === undefined) {
            setDateRange({
                from: null,
                to: null,
            });
        } else {
            setDateRange(dateRange);
        }
    };

    const labelForTemplate: React.ReactNode = active ? <strong>{label}</strong> : label;

    return (
        // <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <FilterDropdownTemplate
            // TODO: add dynamic disable value
            amountSelectedItems={active ? 1 : 0}
            disabled={false}
            label={labelForTemplate}
            borderColor={"grey"}
            onSave={handleSave}
            onClose={handleClose}
            allowReset={false}
            onReset={reset}
        >
            <div style={{ maxWidth: 400, padding: 12 }}>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <DatePicker
                            slotProps={{ textField: { variant: "standard" } }}
                            label={t("filter.From")}
                            value={dateRange.from}
                            onChange={(date) => setDateRange({ from: date ? date : null, to: dateRange.to })}
                            // Clone endDate and subtract 1 day to prevent selecting the same day
                            maxDate={dateRange.to ? dateRange.to.clone() : undefined}
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <DatePicker
                            slotProps={{ textField: { variant: "standard" } }}
                            label={t("filter.To")}
                            // autoOk={true}
                            value={dateRange.to}
                            onChange={(date) => {
                                setDateRange({ to: date ? date : null, from: dateRange.from });
                            }}
                            // {...sharedProps}
                            // Clone startDate and add 1 day to prevent selecting the same day
                            minDate={dateRange.from ? dateRange.from.clone() : undefined}
                        />
                    </Grid>
                </Grid>
            </div>
        </FilterDropdownTemplate>
    );
};

export default LogPeriodSelector;
