import React, { useContext, useState } from "react";
import { single_project_notes_project_comments } from "../../../../__generated__/single_project_notes";
import { useMutation } from "@apollo/client";
import { replyToComment, replyToCommentVariables } from "../../../../__generated__/replyToComment";
import { DELETE_COMMENT_IN_PROJECT, REPLY_TO_COMMENT_IN_PROJECT } from "../../../../graphql/mutationDefProjects";
import { ProjectToOpenContext } from "../../../../components/layout/MainLayout";
import { SINGLE_PROJECT, SINGLE_PROJECT_NOTES } from "../../../../graphql/queryDefProjects";
import SingleComment from "./SingleComment";
import { deleteComment, deleteCommentVariables } from "../../../../__generated__/deleteComment";
import MatomoEvent from "../../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    comment: single_project_notes_project_comments;
}

const NoteParagraph: React.FC<Props> = ({ comment }) => {
    const { project } = useContext(ProjectToOpenContext);
    const { trackEvent } = useMatomo();
    const trackClick = (event: MatomoEvent) => {
        trackEvent(event);
    };
    const [commentValue, setCommentValue] = useState("");
    const [showReply, setShowReply] = useState<boolean>(false);

    const users = project.users.map((user) => ({
        id: user.id,
        display: user.name,
    }));

    const [replyToComment] = useMutation<replyToComment, replyToCommentVariables>(REPLY_TO_COMMENT_IN_PROJECT);

    // reply on comment and set commentvalue to empty string
    const replyToCommentAndReset = () => {
        onSaveCommentReply();
        setCommentValue("");
        trackClick({ category: "Project", action: "Reply-on-comment" });
        setShowReply(false);
    };

    /**
     * Reply to comment
     * @param commentId id of comment
     * @param commentValue value of comment
     */
    const onSaveCommentReply = async () => {
        if (commentValue.length > 2) {
            try {
                replyToComment({
                    variables: {
                        project_id: project.projectID,
                        comment_id: comment.id,
                        comment: commentValue,
                    },
                    refetchQueries: [SINGLE_PROJECT, SINGLE_PROJECT_NOTES],
                });
            } catch (e) {}
        }
    };

    const [deleteReply, { loading: loadDelete }] = useMutation<deleteComment, deleteCommentVariables>(DELETE_COMMENT_IN_PROJECT);

    /**
     * Delete reply
     */
    const deleteCurrentComment = async () => {
        trackClick({ category: "Project", action: "Delete-comment" });

        try {
            deleteReply({
                variables: {
                    comment_id: comment.id,
                },

                refetchQueries: [SINGLE_PROJECT, SINGLE_PROJECT_NOTES],
                awaitRefetchQueries: true,
            });
        } catch (e) {}
    };

    const userExists = (userId: string) => {
        return project.users.some((user) => user.id === userId);
    };

    return (
        <div>
            <SingleComment
                owner={comment.user.id}
                setShowReply={setShowReply}
                showReply={showReply}
                initials={`${comment.user.employee.givenname?.slice(0, 1)}${comment.user.employee.familyname?.slice(0, 1)}`}
                onDelete={deleteCurrentComment}
                loadDelete={loadDelete}
                comment_id={comment.id}
                comment={comment.comment}
                created_at={comment.created_at}
                updated_at={comment.updated_at}
                deleted_at={comment.deleted_at}
                user={comment.user}
                commentValue={commentValue}
                setCommentValue={setCommentValue}
                replyToCommentAndReset={replyToCommentAndReset}
                mentionList={users}
                replies={comment.replies}
                userRemovedFromProject={!userExists(comment.user.id)}
            />
        </div>
    );
};
export default NoteParagraph;
