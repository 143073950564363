import { createContext, useContext } from "react";

export type ReRunType = {
    rerunHighlight: Boolean;
    setRerunHighlight: (rerunHighlight: boolean) => void;
};

export const ReRunContext = createContext<ReRunType>({
    rerunHighlight: false,
    setRerunHighlight: () => console.warn("no theme provider"),
});
export const useReRunHighlighting = () => useContext(ReRunContext);
