import React from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { GET_UPDATE_TYPE_COLUMN } from "../../graphql/queryDefForColumns";
import { GetTypeUpdateColumn, GetTypeUpdateColumnVariables } from "../../__generated__/GetTypeUpdateColumn";

interface Props {
    tender_id: string;
}

const UpdateTypeColumn: React.FC<Props> = ({ tender_id }) => {
    /**
     * Get message data
     */
    const { data } = useQuery<GetTypeUpdateColumn, GetTypeUpdateColumnVariables>(GET_UPDATE_TYPE_COLUMN, {
        variables: { id: tender_id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) {
        return <Typography>{""}</Typography>;
    }

    const tender = data.tender;

    return <Typography style={{ whiteSpace: "nowrap" }}>{tender.type_update}</Typography>;
};

export default UpdateTypeColumn;
