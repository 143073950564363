import React, { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CircularProgress, IconButton, Menu, MenuItem, Tooltip, useMediaQuery, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
interface Props {
    currentMonth: string;
    setToday(): void;
    copyCal(): void;
    view: number;
    setView(view: number): void;
    loading: boolean;
    // openNewCalItem: () => void;
    nextHandler: (period: "month" | "week" | "day") => void;
    prevHandler: (period: "month" | "week" | "day") => void;
}

const TopbarCalendar: React.FC<Props> = ({ loading, currentMonth, nextHandler, setToday, view, setView, copyCal, prevHandler }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { trackEvent } = useMatomo();

    // Track event
    const trackCalendarView = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        // desktop view
        <div
            style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                paddingLeft: onlyMobile ? "0px" : "20px",
                marginBottom: "20px",
                justifyContent: "space-between",
            }}
        >
            {!isMobile ? (
                //////////////////////////////////////////////////////////////////////////////////
                //////////////////////////////// Desktop topbar /////////////////////////////////
                ////////////////////////////////////////////////////////////////////////////////
                <React.Fragment>
                    <div>
                        <Button
                            size="small"
                            sx={{
                                backgroundColor: (theme) => (view === 0 ? "#cccccc" : undefined),
                                color: `${"#000000"}de`,
                            }}
                            onClick={() => {
                                setView(0);
                                trackCalendarView({ category: "Calendar", action: "Click-month-view" });
                            }}
                        >
                            {t("Calendar.Month")}
                        </Button>
                        <Button
                            size="small"
                            sx={{
                                backgroundColor: (theme) => (view === 1 ? "#cccccc" : undefined),
                                color: `${"#000000"}de`,
                            }}
                            onClick={() => {
                                setView(1);
                                trackCalendarView({ category: "Calendar", action: "Click-week-view" });
                            }}
                        >
                            {t("Calendar.Week")}
                        </Button>
                        <Button
                            size="small"
                            sx={{
                                backgroundColor: (theme) => (view === 2 ? "#cccccc" : undefined),
                                color: `${"#000000"}de`,
                            }}
                            onClick={() => {
                                setView(2);
                                trackCalendarView({ category: "Calendar", action: "Click-day-view" });
                            }}
                        >
                            {t("Calendar.Day")}
                        </Button>
                    </div>
                    {view === 0 ? (
                        <Typography sx={{ textTransform: "uppercase", fontWeight: 700, fontSize: "115%", display: "flex", alignItems: "center" }}>
                            {moment(currentMonth).format("MMMM YYYY")}{" "}
                            {loading ? (
                                <CircularProgress style={{ width: 15, height: 15, marginLeft: 4 }} />
                            ) : (
                                <div style={{ width: 15, height: 15, marginLeft: 4 }} />
                            )}
                        </Typography>
                    ) : view === 1 ? (
                        <Typography sx={{ textTransform: "uppercase", fontWeight: 700, fontSize: "115%", display: "flex", alignItems: "center" }}>
                            week {moment(currentMonth).week()} {moment(currentMonth).year()}
                            {loading ? (
                                <CircularProgress style={{ width: 15, height: 15, marginLeft: 4 }} />
                            ) : (
                                <div style={{ width: 15, height: 15, marginLeft: 4 }} />
                            )}
                        </Typography>
                    ) : (
                        <Typography sx={{ textTransform: "uppercase", fontWeight: 700, fontSize: "115%", display: "flex", alignItems: "center" }}>
                            {moment(currentMonth).format("dddd D MMMM")} {moment(currentMonth).year()}
                            {loading ? (
                                <CircularProgress style={{ width: 15, height: 15, marginLeft: 4 }} />
                            ) : (
                                <div style={{ width: 15, height: 15, marginLeft: 4 }} />
                            )}
                        </Typography>
                    )}
                    {view === 0 ? (
                        <div>
                            {/* <Tooltip title="Nieuw agendaitem" placement="left">
                                <IconButton size="small" onClick={openNewCalItem}>
                                    <Add />
                                </IconButton>
                            </Tooltip> */}
                            <Tooltip title="Kopieer agenda link" placement="left">
                                <IconButton size="small" onClick={copyCal}>
                                    <LinkIcon />
                                </IconButton>
                            </Tooltip>
                            <Button sx={{ color: `${"#000000"}de` }} size="small" onClick={() => prevHandler("month")}>
                                <ChevronLeftIcon />
                            </Button>
                            <Button sx={{ color: `${"#000000"}de` }} size="small" onClick={setToday}>
                                {t("Calendar.Today")}
                            </Button>
                            <Button sx={{ color: `${"#000000"}de` }} size="small" onClick={() => nextHandler("month")}>
                                <ChevronRightIcon />
                            </Button>
                        </div>
                    ) : view === 1 ? (
                        <div>
                            {/* <Tooltip title="Nieuw agendaitem" placement="left">
                                <IconButton size="small" onClick={openNewCalItem}>
                                    <Add />
                                </IconButton>
                            </Tooltip> */}
                            <Tooltip title="Kopieer agenda link" placement="left">
                                <IconButton size="small" onClick={copyCal}>
                                    <LinkIcon />
                                </IconButton>
                            </Tooltip>
                            <Button sx={{ color: `${"#000000"}de` }} size="small" onClick={() => prevHandler("week")}>
                                <ChevronLeftIcon />
                            </Button>
                            <Button sx={{ color: `${"#000000"}de` }} size="small" onClick={setToday}>
                                {t("Calendar.Today")}
                            </Button>
                            <Button sx={{ color: `${"#000000"}de` }} size="small" onClick={() => nextHandler("week")}>
                                <ChevronRightIcon />
                            </Button>
                        </div>
                    ) : (
                        <div>
                            {/* <Tooltip title="Nieuw agendaitem" placement="left">
                                <IconButton size="small" onClick={openNewCalItem}>
                                    <Add />
                                </IconButton>
                            </Tooltip> */}
                            <Tooltip title="Kopieer agenda link" placement="left">
                                <IconButton size="small" onClick={copyCal}>
                                    <LinkIcon />
                                </IconButton>
                            </Tooltip>
                            <Button sx={{ color: `${"#000000"}de` }} size="small" onClick={() => prevHandler("day")}>
                                <ChevronLeftIcon />
                            </Button>
                            <Button sx={{ color: `${"#000000"}de` }} size="small" onClick={setToday}>
                                {t("Calendar.Today")}
                            </Button>
                            <Button sx={{ color: `${"#000000"}de` }} size="small" onClick={() => nextHandler("day")}>
                                <ChevronRightIcon />
                            </Button>
                        </div>
                    )}
                </React.Fragment>
            ) : (
                /////////////////////////////////////////////////////////////////////////////////
                //////////////////////////////// Mobile topbar /////////////////////////////////
                ///////////////////////////////////////////////////////////////////////////////
                <React.Fragment>
                    <div>
                        <Button
                            size="small"
                            sx={{ backgroundColor: "#cccccc" }}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            {view === 0 ? (
                                <Typography style={{ fontSize: "80%" }}>{t("Calendar.Month")}</Typography>
                            ) : view === 1 ? (
                                <Typography style={{ fontSize: "80%" }}>{t("Calendar.Week")}</Typography>
                            ) : (
                                <Typography style={{ fontSize: "80%" }}>{t("Calendar.Day")}</Typography>
                            )}
                        </Button>
                        <Tooltip title="Kopieer agenda link" placement="left">
                            <IconButton size="small" onClick={copyCal}>
                                <LinkIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                            <MenuItem
                                onClick={() => {
                                    setView(0);
                                    handleClose();
                                }}
                            >
                                {t("Calendar.Month")}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setView(1);
                                    handleClose();
                                }}
                            >
                                {t("Calendar.Week")}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setView(2);
                                    handleClose();
                                }}
                            >
                                {t("Calendar.Day")}
                            </MenuItem>
                        </Menu>
                    </div>
                    {/* Header text */}
                    {view === 0 ? (
                        <Typography sx={{ textTransform: "uppercase", fontWeight: 700, fontSize: "115%" }}>
                            {moment(currentMonth).format("MMMM YYYY")}
                        </Typography>
                    ) : view === 1 ? (
                        <Typography sx={{ textTransform: "uppercase", fontWeight: 700, fontSize: "115%" }}>
                            {t("Calendar.Week")} {moment(currentMonth).week()}
                        </Typography>
                    ) : (
                        <Typography sx={{ textTransform: "uppercase", fontWeight: 700, fontSize: "115%" }}>
                            {moment(currentMonth).format("dd D MMMM")}
                        </Typography>
                    )}

                    {/* next/prev/today buttons */}
                    {view === 0 ? (
                        <div>
                            <IconButton size="small" onClick={() => prevHandler("month")}>
                                <ChevronLeftIcon />
                            </IconButton>
                            <Button size="small" onClick={setToday}>
                                <Typography style={{ fontSize: "80%" }}>{t("Calendar.Today")}</Typography>
                            </Button>
                            <IconButton size="small" onClick={() => nextHandler("month")}>
                                <ChevronRightIcon />
                            </IconButton>
                        </div>
                    ) : view === 1 ? (
                        <div>
                            <IconButton size="small" onClick={() => prevHandler("week")}>
                                <ChevronLeftIcon />
                            </IconButton>
                            <Button size="small" onClick={setToday}>
                                <Typography style={{ fontSize: "80%" }}>{t("Calendar.Today")}</Typography>
                            </Button>
                            <IconButton size="small" onClick={() => nextHandler("week")}>
                                <ChevronRightIcon />
                            </IconButton>
                        </div>
                    ) : (
                        <div>
                            <IconButton size="small" onClick={() => prevHandler("day")}>
                                <ChevronLeftIcon />
                            </IconButton>
                            <Button size="small" onClick={setToday}>
                                <Typography style={{ fontSize: "80%" }}>{t("Calendar.Today")}</Typography>
                            </Button>
                            <IconButton size="small" onClick={() => nextHandler("day")}>
                                <ChevronRightIcon />
                            </IconButton>
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default TopbarCalendar;
