import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import React from "react";
import { ContactListItem, ConversationListItem, Team } from "../tenderNote/ConversationsList";
import { Notes } from "@mui/icons-material";

interface Props {
    newConversation: boolean | undefined;
    modelType: string;
    fullConversation: ConversationListItem[];
    team: Team | undefined;
    user: ContactListItem | undefined;
}

const AvatarTopbar: React.FC<Props> = ({ newConversation, modelType, fullConversation, team, user }) => {
    const grey = "#ffffff";
    const tenderBlue = "#173357";
    const yellow = "#F57117";
    const green = "#225E4D";
    const pink = "#E03660";

    const teamChat = Boolean(fullConversation.length > 0 && fullConversation[0].team_id !== undefined);
    const unique = fullConversation.length > 0 ? [...new Set(fullConversation[0].users)] : [];
    const uniqueNoCU = unique.filter((row) => row.id !== localStorage.getItem("user_id"));

    const privateChat = Boolean(fullConversation.length > 0 && fullConversation[0].users.length === 1);

    /**
     * @param notification Notification item
     * @returns Menu item based on notification type
     */
    const topbarToShow = (): JSX.Element => {
        switch (true) {
            /**
             * private notes
             */
            case privateChat:
                return (
                    <AvatarGroup
                        max={6}
                        sx={{
                            "& .MuiAvatar-root": {
                                border: "1px solid #ffffff",
                                width: (theme) => theme.spacing(4),
                                height: (theme) => theme.spacing(4),
                                fontSize: "0.875rem",
                                marginLeft: teamChat ? undefined : "0px",
                                backgroundColor: modelType.includes("Tender")
                                    ? tenderBlue
                                    : modelType.includes("Opportunity")
                                    ? green
                                    : modelType.includes("MarketParty")
                                    ? pink
                                    : modelType.includes("ContractingAuthority")
                                    ? yellow
                                    : grey,
                            },
                        }}
                    >
                        <Avatar>
                            <Notes />
                        </Avatar>
                    </AvatarGroup>
                );

            /**
             * Existing teamchat
             */
            case teamChat:
                return (
                    <Tooltip
                        placement={"bottom-start"}
                        title={unique.map((l) => {
                            return (
                                <div key={l.id}>
                                    {l.employee.givenname} {l.employee.familyname}
                                </div>
                            );
                        })}
                    >
                        <AvatarGroup
                            max={6}
                            sx={{
                                "& .MuiAvatar-root": {
                                    border: "1px solid #ffffff",
                                    width: (theme) => theme.spacing(4),
                                    height: (theme) => theme.spacing(4),
                                    fontSize: "0.875rem",
                                    marginLeft: teamChat ? undefined : "0px",
                                    cursor: "pointer",
                                    backgroundColor: modelType.includes("Tender")
                                        ? tenderBlue
                                        : modelType.includes("Opportunity")
                                        ? green
                                        : modelType.includes("MarketParty")
                                        ? pink
                                        : modelType.includes("ContractingAuthority")
                                        ? yellow
                                        : grey,
                                },
                            }}
                        >
                            {unique.map((l) => {
                                return (
                                    <Avatar key={l.id}>
                                        {l.employee.givenname?.slice(0, 1)}
                                        {l.employee.familyname?.slice(0, 1)}
                                    </Avatar>
                                );
                            })}
                        </AvatarGroup>
                    </Tooltip>
                );

            /**
             * New chat with single user
             */
            case newConversation && Boolean(user !== undefined):
                return (
                    <AvatarGroup
                        max={6}
                        sx={{
                            "& .MuiAvatar-root": {
                                border: "1px solid #ffffff",
                                width: (theme) => theme.spacing(4),
                                height: (theme) => theme.spacing(4),
                                fontSize: "0.875rem",
                                marginLeft: "0px",
                                backgroundColor: modelType.includes("Tender")
                                    ? tenderBlue
                                    : modelType.includes("Opportunity")
                                    ? green
                                    : modelType.includes("MarketParty")
                                    ? pink
                                    : modelType.includes("ContractingAuthority")
                                    ? yellow
                                    : grey,
                            },
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Avatar>
                                {user?.name.slice(0, 1)}
                                {user?.familyName?.slice(0, 1)}
                            </Avatar>
                            <div style={{ marginLeft: "4px" }}>{user?.name}</div>
                        </div>
                    </AvatarGroup>
                );

            /**
             * New teamchat
             */
            case newConversation && team !== undefined:
                return (
                    <Tooltip
                        placement={"bottom-start"}
                        title={team?.members.map((l) => {
                            return (
                                <div key={l.id}>
                                    {l.name} {l.familyName}
                                </div>
                            );
                        })}
                    >
                        <AvatarGroup
                            max={6}
                            sx={{
                                "& .MuiAvatar-root": {
                                    border: "1px solid #ffffff",
                                    width: (theme) => theme.spacing(4),
                                    height: (theme) => theme.spacing(4),
                                    fontSize: "0.875rem",
                                    marginLeft: undefined,
                                    cursor: "pointer",
                                    backgroundColor: modelType.includes("Tender")
                                        ? tenderBlue
                                        : modelType.includes("Opportunity")
                                        ? green
                                        : modelType.includes("MarketParty")
                                        ? pink
                                        : modelType.includes("ContractingAuthority")
                                        ? yellow
                                        : grey,
                                },
                            }}
                        >
                            {team?.members.map((l) => {
                                return (
                                    <Avatar key={l.id}>
                                        {l.name?.slice(0, 1)}
                                        {l.familyName?.slice(0, 1)}
                                    </Avatar>
                                );
                            })}
                        </AvatarGroup>
                    </Tooltip>
                );

            /**
             * Existing user chat
             */
            default:
                return (
                    <AvatarGroup
                        max={6}
                        sx={{
                            "& .MuiAvatar-root": {
                                border: "1px solid #ffffff",
                                width: (theme) => theme.spacing(4),
                                height: (theme) => theme.spacing(4),
                                fontSize: "0.875rem",
                                marginLeft: teamChat ? undefined : "0px",
                                backgroundColor: modelType.includes("Tender")
                                    ? tenderBlue
                                    : modelType.includes("Opportunity")
                                    ? green
                                    : modelType.includes("MarketParty")
                                    ? pink
                                    : modelType.includes("ContractingAuthority")
                                    ? yellow
                                    : grey,
                            },
                        }}
                    >
                        {uniqueNoCU.map((l) => {
                            return (
                                <div key={l.id} style={{ display: "flex", alignItems: "center" }}>
                                    <Avatar>
                                        {l.employee.givenname?.slice(0, 1)}
                                        {l.employee.familyname?.slice(0, 1)}
                                    </Avatar>
                                    <div style={{ marginLeft: "4px" }}>
                                        {l.employee.givenname} {l.employee.familyname}
                                    </div>
                                </div>
                            );
                        })}
                    </AvatarGroup>
                );
        }
    };

    return <React.Fragment>{topbarToShow()}</React.Fragment>;
};

export default AvatarTopbar;
