import React from "react";
import { Grid, Button, Typography } from "@mui/material";
import { Check, Schedule, Today } from "@mui/icons-material";
import { Get_chosen_timeslot_details_meetingTimeslot } from "../../__generated__/Get_chosen_timeslot_details";
import moment from "moment";

interface Props {
    meetingDetails: Get_chosen_timeslot_details_meetingTimeslot | null;
    cancel_timeslot: (hash: string) => void;
}

const TimeSlotDetails: React.FC<Props> = ({ meetingDetails, cancel_timeslot }) => {
    const lightGrey = "#9e9e9e";
    const dayCapitalized =
        moment(meetingDetails?.start).format("dddd DD MMMM").charAt(0).toUpperCase() + moment(meetingDetails?.start).format("dddd DD MMMM").slice(1);
    return (
        <Grid container xs={12} sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
            {/*
             * Grid to show when no timeslots are availabe
             */}

            <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                sx={{
                    padding: "8px 8px 8px 32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    flex: "1",
                }}
            >
                <div>
                    <Typography style={{ display: "flex", alignItems: "center", marginBottom: 12, fontSize: "1.2rem" }}>
                        <Check style={{ color: "green", marginRight: 12, width: "1.4em", height: "1.4em" }} />
                        Gekozen tijdslot is gereserveerd
                    </Typography>
                    {/* day */}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                        <Typography style={{ display: "flex", alignItems: "center", marginBottom: 12, fontSize: "1.2rem" }}>
                            <Today style={{ marginRight: 12, color: lightGrey, width: "1.4em", height: "1.4em" }} /> {dayCapitalized}
                        </Typography>
                    </div>
                    {/* timestamps */}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                        <Typography style={{ display: "flex", alignItems: "center", fontSize: "1.2rem" }}>
                            <Schedule style={{ marginRight: 12, color: lightGrey, width: "1.4em", height: "1.4em" }} />{" "}
                            {`${moment(meetingDetails?.start).format("LT")} - ${moment(meetingDetails?.end).format("LT")}`} (
                            {meetingDetails?.duration} minuten)
                        </Typography>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sx={{ alignSelf: "flex-end", flex: 0 }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div>
                        <Button
                            disabled={meetingDetails?.hash === undefined}
                            variant="text"
                            color="primary"
                            onClick={() => cancel_timeslot(meetingDetails?.hash ? meetingDetails.hash : "")}
                        >
                            Annuleer afspraak
                        </Button>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default TimeSlotDetails;
