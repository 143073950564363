import React from "react";
//marked iconset
import { ReactComponent as Doc } from "../assets/IconSet/docMarked.svg";
import { ReactComponent as Pdf } from "../assets/IconSet/pdfMarked.svg";
import { ReactComponent as Audio } from "../assets/IconSet/audioMarked.svg";
import { ReactComponent as Code } from "../assets/IconSet/codeMarked.svg";
import { ReactComponent as Default } from "../assets/IconSet/defaultMarked.svg";
import { ReactComponent as Excel } from "../assets/IconSet/excelMarked.svg";
import { ReactComponent as Img } from "../assets/IconSet/imgMarked.svg";
import { ReactComponent as Pp } from "../assets/IconSet/ppMarked.svg";
import { ReactComponent as Txt } from "../assets/IconSet/txtMarked.svg";
import { ReactComponent as Zip } from "../assets/IconSet/zipMarked.svg";
import { ReactComponent as Video } from "../assets/IconSet/videoMarked.svg";

interface Props {
    icon: string | null | undefined;
}

const MarkedDocIconSwitch: React.FC<Props> = ({ icon }) => {
    return (
        <React.Fragment>
            {(() => {
                switch (icon) {
                    case "pdf":
                    case "fa-file-pdf-o":
                        return <Pdf style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    case "xls":
                    case "xlsx":
                    case "csv":
                    case "xlt":
                    case "xltx":
                    case "ods":
                    case "xlsb":
                    case "xlsm":
                    case "xltm":
                    case "xlam":
                    case "xla":
                    case "fa-file-excel-o":
                        return <Excel style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    case "doc":
                    case "docx":
                    case "dot":
                    case "dotx":
                    case "rtf":
                    case "odt":
                    case "docm":
                    case "dotm":
                    case "fa-file-word-o":
                        return <Doc style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    case "ppt":
                    case "pptx":
                    case "pot":
                    case "potx":
                    case "odp":
                    case "ppsx":
                    case "pps":
                    case "pptm":
                    case "potm":
                    case "ppsm":
                    case "fa-file-powerpoint-o":
                        return <Pp style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    case "tif":
                    case "tiff":
                    case "bmp":
                    case "gif":
                    case "svg":
                    case "ai":
                    case "eps":
                    case "psd":
                    case "wmf":
                    case "dgn":
                    case "dwg":
                    case "dwf":
                    case "dxf":
                    case "png":
                    case "jpg":
                    case "jpeg":
                    case "fa-file-image-o":
                        return <Img style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    case "mp3":
                    case "wma":
                    case "aac":
                    case "wav":
                    case "aiff":
                    case "flac":
                    case "fa-file-audio-o":
                        return <Audio style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    case "xml":
                    case "fa-file-code-o":
                        return <Code style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    case "txt":
                    case "fa-file-text-o":
                        return <Txt style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    case "zip":
                    case "rar":
                    case "iso":
                    case "tar":
                    case "7z":
                    case "gz":
                    case "bz2":
                    case "zipx":
                        return <Zip style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    case "3gp":
                    case "asf":
                    case "avi":
                    case "divx":
                    case "flv":
                    case "mov":
                    case "mp4":
                    case "mpg":
                    case "wmv":
                    case "fa-file-video-o":
                        return <Video style={{ height: 24, minWidth: 24, marginRight: 8 }} />;

                    // Fallback
                    default:
                        return <Default style={{ height: 24, minWidth: 24, marginRight: 8 }} />;
                }
            })()}
        </React.Fragment>
    );
};

export default MarkedDocIconSwitch;
