import React, { useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { MUIDataTableState } from "mui-datatables";
import {
    FilterOrganizationOverview,
    FilterOrganizationOverviewVariables,
    FilterOrganizationOverview_filterTenderers_results,
    FilterOrganizationOverview_filterTenderers_filters,
} from "../../../__generated__/FilterOrganizationOverview";
import setTitle from "../../../utils/setTitle";
import { SortOrder, TenderersFilterSource, OrganizationsFilterInput, TenderersSearchInput } from "../../../__generated__/globalTypes";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import OrganizationSearch from "../OrganizationSearch";
import OrganizationTable, { allOrgColumns } from "./OrganizationTable";
import { useTranslation } from "react-i18next";
import { ColumnContext } from "../../../components/contextProviders/ColumnContext";
import { getSectorOfCurrentUser } from "../../../__generated__/getSectorOfCurrentUser";
import { ColumnProvider } from "../../../components/contextProviders/ColumnProvider";
import { QUERY_ALL_ORGANIZATIONS } from "../../../graphql/queryDefOrg";
import { QUERY_CURRENT_USER_SECTORS } from "../../../graphql/queryDefCurrentUser";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CardView from "../starredOrganizations/CardView";

const filters: OrganizationsFilterInput = {};
const defaultSearch: TenderersSearchInput = { source: TenderersFilterSource.ALL_TENDERERS, filters };
const defaultVariables: FilterOrganizationOverviewVariables = {
    page: 1,
    count: 10,
    orderField: "last_update_date_timestamp",
    order: SortOrder.DESC,
    search: defaultSearch,
};

// TODO: possibility to search with searchbox. now commented because its not available yet in GQL for organizationmodel, connect filters of market parties

const AllOrganizations: React.FC = () => {
    const [page, setPage] = useState<number | null | undefined>(defaultVariables.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.count);
    const [orderField, setOrderField] = useState<string>(defaultVariables.orderField);
    const [orderDir, setOrderDir] = useState<SortOrder>(defaultVariables.order);
    const [searchInput, setSearchInput] = useState<OrganizationsFilterInput>({});
    const [source] = useState<TenderersFilterSource>(defaultSearch.source);
    const { t } = useTranslation();
    const [query, setQuery] = useState<string>("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [sector, setSectors] = useState<any>();
    const [filterData, setFilterData] = useState<FilterOrganizationOverview_filterTenderers_filters[] | null>([]);
    const [tenderersData, setTenderersData] = useState<FilterOrganizationOverview_filterTenderers_results | null>();
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("md"));

    useQuery<getSectorOfCurrentUser>(QUERY_CURRENT_USER_SECTORS, {
        onCompleted(data) {
            if (data && data.currentUser && data.currentUser.employee && data.currentUser.employee.organization.subscriptions) {
                const getsectors = data.currentUser.employee.organization.subscriptions.find((t: any) => t.ends_at === null);
                if (getsectors) {
                    setSectors(getsectors.sectors?.map((sector) => parseInt(sector?.id as string)));
                }
            }
        },
    });

    useEffect(() => {
        if (sector !== undefined) {
            setSearchInput({ sectors: sector });
        }
        if (sessionStorage.getItem("ampp")) {
            // set current page to sessionstorage value or 1 if sessionstorage isn't set
            setPage(parseInt(JSON.parse(sessionStorage.getItem("ampp") || "1")));
        }
    }, [sector]);

    // temporary

    // Variables for the GQL query
    const variables: FilterOrganizationOverviewVariables = {
        page: page,
        count: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            source: source,
            query: query,
            filters: searchInput,
        },
    };

    /**
     * Define query to get all organizations
     * on complete -> set organizations to tenderersData, set data of filters to filterData
     */
    const [run, { loading, error }] = useLazyQuery<FilterOrganizationOverview, FilterOrganizationOverviewVariables>(QUERY_ALL_ORGANIZATIONS, {
        variables: variables,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.filterTenderers) {
                setFilterData(data.filterTenderers.filters);
                setTenderersData(data.filterTenderers.results);
            }
        },
    });

    /**
     * Set page title to all competitors (tab user currently is on)
     */
    useEffect(() => {
        setTitle(t("AllCompetitors"));
        sessionStorage.setItem("ampp", JSON.stringify(page));

        run({
            variables: {
                page: page,
                count: rowsPerPage,
                orderField: orderField,
                order: orderDir,
                search: {
                    source: source,
                    query: query,
                    filters: searchInput,
                },
            },
        });
    }, [run, orderDir, orderField, page, rowsPerPage, searchInput, t, query, source]);

    /**
     * @param page setPage to next/prev page
     * @param rowsPerPage setRowsPerPage to amount of rows per page
     */
    const handlePaginationChange = (page: number, rows: number) => {
        setPage(page);
        setRowsPerPage(rows);
        if (rowsPerPage !== rows) {
            setPage(1);
        }
    };

    /**
     * Handle the sorting direction
     * Handle the sorting columnname
     */
    const handleTableChange = (_: string, tableState: MUIDataTableState) => {
        if (tableState.sortOrder.direction) setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
        if (tableState.sortOrder.name) setOrderField(tableState.sortOrder.name);
    };

    /**
     * @param content child component(s) to be rendered based on state of query
     */
    const renderPage = (content: React.ReactNode) => (
        <ColumnProvider storeKey="cumc" defaultState={allOrgColumns}>
            <ColumnContext.Provider value={{ selectedRows, setSelectedRows }}>
                <Box component="div" sx={{ padding: { xs: "20px 8px", md: "20px" }, overflow: "scroll" }}>
                    <OrganizationSearch
                        query={query}
                        filterData={filterData}
                        setQuery={setQuery}
                        resetPage={() => setPage(1)}
                        loading={loading}
                        searchInput={searchInput}
                        onChange={setSearchInput}
                        startSearch={() => {
                            setPage(1);
                            run({ variables });
                        }}
                        resetFilters={() => {
                            setSearchInput({});
                            setPage(1);
                        }}
                    />
                    <div style={{ marginTop: 12 }} />
                    {content}
                </Box>
            </ColumnContext.Provider>
        </ColumnProvider>
    );

    /**
     *  If state is empty and loading is true, show fullpagespinner
     *  only initial load, if tendersData has data, linearloader is shown.
     */
    if (!tenderersData && loading) return renderPage(<FullPageSpinner />);

    /**
     *  If error occurs or state is empty -> show error
     */
    if (error || !tenderersData)
        return renderPage(
            <OrganizationTable
                loading={loading}
                rows={[]}
                paginatorInfo={{
                    count: 0,
                    currentPage: 0,
                    hasMorePages: false,
                    firstItem: 0,
                    lastItem: 0,
                    lastPage: 1,
                    perPage: 10,
                    total: 0,
                    __typename: "PaginatorInfo",
                }}
                onChangePagination={handlePaginationChange}
                onTableChange={handleTableChange}
            />
        );

    if (!noMobile) {
        return renderPage(
            <CardView
                type={"mp"}
                data={tenderersData?.data.map((row) => ({
                    id: row.id,
                    name: row.name ?? "",
                    favicon: row.favicon ?? "",
                    country: row.country?.alpha2 ?? "",
                    org_number: row.kvknumber ?? "",
                    last_update: row.last_update_date,
                    place:
                        row.settlings?.data.find((i) => (i.type === "hoofdvestiging" ? i : i.type === "rechtspersoon" ? i : null))?.visit_address
                            ?.city ?? "",
                }))}
                loading={loading}
                paginatorInfo={
                    tenderersData?.paginatorInfo
                        ? tenderersData?.paginatorInfo
                        : {
                              count: 0,
                              currentPage: 0,
                              hasMorePages: false,
                              firstItem: 0,
                              lastItem: 0,
                              lastPage: 1,
                              perPage: 10,
                              total: 0,
                              __typename: "PaginatorInfo",
                          }
                }
                onChangePagination={handlePaginationChange}
            />
        );
    }

    return renderPage(
        <OrganizationTable
            loading={loading}
            rows={tenderersData?.data}
            paginatorInfo={
                tenderersData?.paginatorInfo
                    ? tenderersData?.paginatorInfo
                    : {
                          count: 0,
                          currentPage: 0,
                          hasMorePages: false,
                          firstItem: 0,
                          lastItem: 0,
                          lastPage: 1,
                          perPage: 10,
                          total: 0,
                          __typename: "PaginatorInfo",
                      }
            }
            onChangePagination={handlePaginationChange}
            onTableChange={handleTableChange}
        />
    );
};

export default AllOrganizations;
