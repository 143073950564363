import cssVars from "css-vars-ponyfill";

const colors = {
    blue: "--themeBlue",
    yellow: "--themeYellow",
    pink: "--themePink",
    green: "--themeGreen",
    darkGray: "--themeDarkGray",
};

const setTheme = (color: keyof typeof colors) => {
    if (!Object.keys(colors).includes(color)) throw new Error("Invalid color!");

    cssVars({
        variables: {
            themeCurrent: `var(${colors[color]})`,
        },
    });

    // Get the computed value of the theme color
    const computedColor = getComputedStyle(document.documentElement).getPropertyValue(colors[color]).trim();

    // Update the theme-color meta tag
    let themeColorMetaTag = document.querySelector("meta[name='theme-color']");
    if (!themeColorMetaTag) {
        themeColorMetaTag = document.createElement("meta");
        themeColorMetaTag.setAttribute("name", "theme-color");
        document.head.appendChild(themeColorMetaTag);
    }
    themeColorMetaTag.setAttribute("content", computedColor);
};

export default setTheme;
