import React, { useEffect } from "react";
import { useOppOpener } from "../contextProviders/CreateNewOpportunityContext";
import pdfThumbnailCreator from "../pdfViewer/api/pdfThumbnailCreator";

import CreateOpportunityOnFrag from "./CreateOpportunityOnFrag";
import { Divider } from "@mui/material";
import NewOpportunity from "./NewOpportunity";
import { Visibility } from "@mui/icons-material";
import SinglePageResult from "./SinglePageResult";
import { GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities } from "../../__generated__/GetopportunityPagePersonalOpportunities";

interface Props {
    page: number | null;
    fragment_id: string;
    active: number | boolean;
    pdfUrl: string | null;
    onPageClick(): void;
    data: GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities[] | undefined;
    showPageNumber: boolean;
    read: boolean | null;
}

const FoundResult: React.FC<Props> = ({ page, pdfUrl, fragment_id, onPageClick, active, data, showPageNumber, read }) => {
    const green = "#225E4D";
    const { newOpp, setNewOpp } = useOppOpener();
    const white = "#ffffff";
    /**
     * Set opportuniy id to pdf loader/viewer
     */
    useEffect(() => {
        if (pdfUrl !== null) pdfThumbnailCreator({ a: pdfUrl, pageNumber: page ? page : 1 });
    }, [pdfUrl, page]);

    return (
        <React.Fragment>
            {/* Div contains snippet(s) */}
            <div style={{ height: "232px" }}>
                <React.Fragment>
                    <div
                        style={{
                            padding: "16px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        {/*
                         * Fragment of page
                         * Contains snippet of result with highlighting
                         */}
                        <SinglePageResult
                            read={read}
                            active={active === page}
                            page_id={fragment_id}
                            page_number={page}
                            createNew={setNewOpp}
                            onPageClick={onPageClick}
                            showPageNumber={showPageNumber}
                        />

                        {/* div for thumbnail */}
                        <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                            <canvas
                                id={`the-canvas${page}`}
                                style={{
                                    height: 200,
                                    width: 140,
                                    border: `1px solid ${green}`,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            />
                            {active === page && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#508c79",
                                        // opacity: 0.7,
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "50%",
                                        color: white,
                                        position: "absolute",
                                        top: "40%",
                                        left: "50%",
                                        transform: `translate(-50%)`,
                                    }}
                                >
                                    <Visibility />
                                </div>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            </div>
            {/*
             *   Div contains created opportunities
             */}
            <div>
                <Divider />
                {data
                    ?.filter((i) => i.comment !== "")
                    .filter((j) => j.comment !== null)
                    .map((o) => {
                        return (
                            <React.Fragment key={o.id}>
                                <CreateOpportunityOnFrag opportunity={o} page_id={fragment_id} />
                                <Divider />
                            </React.Fragment>
                        );
                    })}
            </div>
            {newOpp && active === page && <NewOpportunity showHideBtn={true} page_id={fragment_id} page_number={page} setAddNew={setNewOpp} />}
        </React.Fragment>
    );
};

export default FoundResult;
