import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import DateIndicator from "./DateIndicator";

interface Props {
    onlyMobile: boolean;
    start: string | null;
    today: string;
    noEndDateAvailable: boolean;
}

const UndeterminedEndDate: React.FC<Props> = ({ onlyMobile, start, today, noEndDateAvailable }) => {
    return (
        <React.Fragment>
            {/*
             * Component to show amount of years between two dates
             */}
            <div style={{ marginBottom: "32px" }} />

            {/*
             * Horizontal timeline items
             */}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "48px",
                    marginLeft: "-8px",
                    position: "relative",
                }}
            >
                <div style={{ width: "90%", display: "flex", flexDirection: "row", position: "absolute" }}>
                    {/*
                     * Base timeline
                     * Blue => in past
                     * Grey => in future
                     */}
                    <div
                        style={{
                            height: "4px",
                            backgroundColor: "#dfdfdf",
                            width: "100%",
                            marginLeft: "4px",
                            marginRight: "-10px",
                        }}
                    />

                    {/*
                     * Show start Date (first item of array)
                     */}
                    <div
                        style={{
                            left: "6px",
                            position: "absolute",
                        }}
                    >
                        <Typography variant="caption">
                            {onlyMobile ? moment(start as string).format("DD-MM-YY") : moment(start as string).format("L")}
                        </Typography>
                    </div>

                    {/*
                     * Show end Date (last item of array)
                     */}
                    <div
                        style={{
                            position: "absolute",
                            right: "2px",
                        }}
                    >
                        <Typography variant="caption">{noEndDateAvailable ? "" : "onbepaalde duur"}</Typography>
                    </div>

                    <DateIndicator
                        bgColor={moment(start as string).isSameOrBefore(today) ? "#173357" : "#dfdfdf"}
                        title={"Initiële startdatum"}
                        date={moment(today)}
                        marginLeft={0}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default UndeterminedEndDate;
