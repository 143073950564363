import React, { useState } from "react";
import { Avatar, IconButton, ListItemIcon, MenuItem, Paper, Tooltip, Typography } from "@mui/material";
import FaviconBox from "../../../components/FaviconBox";
import { TeamInSub, User } from "./UsersAndTeams";
import { useDrop } from "react-dnd";
import { ADD_USER_TO_TEAM, DELETE_TEAM, QUERY_SUBSCRIPTION_AND_TEAMS, REMOVE_USER_FROM_TEAM } from "../../../graphql/queryDefUserAndTeams";
// import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { Clear, Close, Groups } from "@mui/icons-material";
import TeamName from "./TeamName";
import WidgetBoxMenu from "../../../components/WidgetBoxMenu";
import { removeUserFromTeam, removeUserFromTeamVariables } from "../../../__generated__/removeUserFromTeam";
import { deleteTeam, deleteTeamVariables } from "../../../__generated__/deleteTeam";
import { addUserToTeam, addUserToTeamVariables } from "../../../__generated__/addUserToTeam";
import { toast } from "react-toastify";
import { isTeamOwner } from "../../../utils/isTeamOwner";
import MatomoEvent from "../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    team: TeamInSub;
}

const SingleTeam: React.FC<Props> = ({ team }) => {
    const { trackEvent } = useMatomo();

    // Track event
    const trackSharing = (event: MatomoEvent) => {
        trackEvent(event);
    };
    // const { t } = useTranslation();
    const [teamName, setTeamName] = useState<string>(team.name);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [fullTeam, showFullTeam] = useState<boolean>(false);

    //submenu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    /**
     * Mutation to remove a single team
     */
    const [removeTeam] = useMutation<deleteTeam, deleteTeamVariables>(DELETE_TEAM);
    /**
     * Mutation to remove user from team
     */
    const [removeUserFromTeam] = useMutation<removeUserFromTeam, removeUserFromTeamVariables>(REMOVE_USER_FROM_TEAM);

    /**
     * Mutation to add user to team
     */
    const [addUserToTeam] = useMutation<addUserToTeam, addUserToTeamVariables>(ADD_USER_TO_TEAM);

    const handleShowFullTeam = () => {
        showFullTeam(!fullTeam);
        setAnchorEl(null);
    };

    /**
     * Handler to open actionmenu
     */
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Handler to remove team
     * Refetch data on complete
     * Show toaster on succes/error
     * Close actionmenu
     */
    const handleRemoveTeam = async () => {
        try {
            await removeTeam({
                variables: {
                    teamId: team.id,
                },
                refetchQueries: [QUERY_SUBSCRIPTION_AND_TEAMS, "GetScubscriptionAndTeams"],
                awaitRefetchQueries: true,
                onCompleted: () => {
                    trackSharing({ category: "Personal", action: "Click-remove-team", href: window.location.href });
                    toast.success("Het team is verwijderd", {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                },
            });
        } catch (e) {
            toast.error("Er ging iets mis tijdens het verwijderen van het team", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setAnchorEl(null);
        }
    };

    /**
     * Helper to check if a user is the owner of a team
     */
    const bossOfTeam = isTeamOwner(team.owner?.id as string);
    /**
     * Trigger when the user confirms saving the new state
     * For now only user deletion
     * TODO: save the state to backend
     */
    const removeUserFromCurrentTeam = async (user_id: string) => {
        trackSharing({ category: "Personal", action: "Click-remove-user-from-team", href: window.location.href });

        try {
            await removeUserFromTeam({
                variables: {
                    user_id: parseInt(user_id),
                    team_id: parseInt(team.id),
                },
                refetchQueries: [QUERY_SUBSCRIPTION_AND_TEAMS, "GetScubscriptionAndTeams"],
                awaitRefetchQueries: true,
            });
        } catch (e) {
            toast.error("Er ging iets mis tijdens het verwijderen van de gebruiker", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    /**
     * Add a user to the teams user list
     * @param userId id of the user that has to be added to the team
     */
    const handleAddUserToTeam = (userId: User) => {
        trackSharing({ category: "Personal", action: "Click-add-user-to-team", href: window.location.href });
        addUserToTeam({
            variables: {
                user_id: parseInt(userId.id),
                team_id: parseInt(team.id),
            },
            refetchQueries: [QUERY_SUBSCRIPTION_AND_TEAMS, "GetScubscriptionAndTeams"],
            awaitRefetchQueries: true,
            onError: () => {
                toast.error("Er ging iets mis met het toevoegen van de gebruiker", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            },
        });
    };

    /**
     * Dropzone for drag and drop user to team.
     * Gives an alert with name of dragged user.
     */
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: "USER",
        drop: (item: User) => {
            if (bossOfTeam) {
                handleAddUserToTeam(item);
            } else {
                toast.error("Niet toegestaan. Vraag een teambeheerder", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: bossOfTeam && monitor.canDrop(),
        }),
    }));

    /**
     * When dropzone is active, the background color changes = classes.isActive
     * When item is over dropzone color will change so user gets feedback that its possible to drop = classes.canDrop
     * Background goes back to white color when user drops the user or cancels the drop action. = classes.White
     */
    const isActive = isOver && canDrop;

    return (
        <div ref={drop}>
            <Paper
                sx={(theme) => ({
                    width: "100%",
                    minHeight: "225px",
                    marginBottom: "6px",
                    backgroundColor: isActive ? `${"#508c79"}0D` : "#ffffff",
                    border: isActive ? `1px solid ${"#225E4D"} ` : canDrop ? `1px solid ${"#508c79"}` : `1px solid ${"#ffffff"} `,
                    borderTop: isActive ? `3px solid ${"#225E4D"}` : canDrop ? `3px solid ${"#508c79"}` : `3px solid ${"#9e9e9e"}`,
                })}
            >
                <div style={{ padding: "16px", paddingBottom: "0px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between", marginBottom: 24, width: "100%" }}>
                        <span style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                            <Avatar sx={(theme) => ({ width: theme.spacing(4), height: theme.spacing(4), fontSize: "1rem" })}>
                                {/*
                                 * First character of name & first character of family name
                                 */}
                                {`${team.name.charAt(0)}`}
                            </Avatar>
                            <span style={{ marginLeft: 8 }}>
                                {/*
                                 *  Teamname with actionmenu
                                 */}
                                <TeamName
                                    bossOfTeam={bossOfTeam}
                                    teamName={teamName}
                                    teamId={team.id}
                                    setTeamName={setTeamName}
                                    editMode={editMode}
                                    setEditMode={setEditMode}
                                />
                            </span>
                        </span>
                        {/*
                         *  Teamname with actionmenu
                         *  Only visible when user is teamowner
                         */}
                        {bossOfTeam && (
                            <div style={{ display: "flex", alignItems: "center", width: "50px", justifyContent: "flex-end" }}>
                                <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                                    <MenuItem onClick={handleRemoveTeam}>
                                        <ListItemIcon>
                                            <Clear fontSize="small" />
                                        </ListItemIcon>
                                        Verwijder team
                                    </MenuItem>
                                    <MenuItem onClick={handleShowFullTeam}>
                                        <ListItemIcon>
                                            <Groups fontSize="small" />
                                        </ListItemIcon>
                                        {!fullTeam ? "Toon volledig team" : "Kaart verkleinen"}
                                    </MenuItem>
                                </WidgetBoxMenu>
                            </div>
                        )}
                    </div>
                </div>

                {/*
                 *  Show 'Voer een teamnaam in' when user has not changed and saved the default teamname yet
                 *  TeamOwner will be added after teamname is saved
                 */}
                {team.users.length === 0 ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "52px" }}>
                        <Typography variant="caption">Voer een teamnaam in</Typography>
                    </div>
                ) : (
                    <div
                        style={{
                            maxHeight: fullTeam ? "none" : "160px",
                            overflow: fullTeam ? "visible" : "scroll",
                            padding: "16px",
                            paddingTop: "0px",
                        }}
                    >
                        {/*
                         *  Map over users in team
                         */}
                        {team.users.map((user) => {
                            const usersExceptBoss = team.owner?.id !== user.id;

                            return (
                                <div key={user.id} style={{ marginBottom: 8, display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex", alignItems: "center", width: "100%", marginLeft: 7 }}>
                                        <span style={{ marginTop: 0, marginRight: 2 }}>
                                            <FaviconBox marginTop={0} favicon={null} name={user.employee.name} />
                                        </span>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span>{user.employee.name}</span>
                                            <span style={{ color: "#707070", fontSize: 13 }}>
                                                {/* Show user role based on teamOwnerId */}
                                                {team.owner?.id === user.id ? "Teambeheerder" : "Gebruiker"}
                                            </span>
                                        </div>
                                    </div>
                                    {/*
                                     *  Button to remove user from team
                                     *  Only visible for teamowner
                                     *  Teamowner cant remove himself
                                     */}
                                    {bossOfTeam && usersExceptBoss && (
                                        <Tooltip placement="left" title="Verwijder uit team">
                                            <IconButton size="small" onClick={() => removeUserFromCurrentTeam(user.id)}>
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </Paper>
        </div>
    );
};

export default SingleTeam;
