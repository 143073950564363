import React from "react";
import UserListLoader from "./UserListLoader";
import ConversationLoader from "./ConversationLoader";
import { Grid } from "@mui/material";

interface Props {
    loading: boolean;
    textualHelper: string;
}

const TenderNoteDialogLoader: React.FC<Props> = ({ loading, textualHelper }) => {
    return (
        <Grid container sx={{ height: `calc(80vh - 64px)` }}>
            {/*
             * list with all possible people to chat with
             */}
            <Grid xs={12} md={3} style={{ height: "100%", overflow: "auto", borderRight: "1px solid lightgrey" }}>
                <UserListLoader loading={loading} />
            </Grid>

            {/*
             * Grid with clicked/active chat
             */}
            <Grid xs={12} md={9} style={{ height: "100%" }}>
                <ConversationLoader loading={loading} textualHelper={textualHelper} />
            </Grid>
        </Grid>
    );
};

export default TenderNoteDialogLoader;
