import { gql } from "@apollo/client";

export const QUERY_TENDER_FOR_PROJECT = gql`
    query GetTenderOfProject($id: ID!) {
        tender(id: $id) {
            id
            userStatus
            namegiventocontract
            deadline_inschrijving
            status
            first_dispatchdate
            updated_at
            type_procedure
            userStatus
            hasConversations
            unreadChatsCount
            original_link
            published_host
            published_legal_basis
            published_juridisch_kader
            translated_type
            type_procedure
            country {
                name
            }
            sectors {
                id
                name
                parent {
                    id
                    name
                }
                children {
                    id
                    name
                }
            }
            searchresults {
                search {
                    id
                    name
                    color
                }
            }
        }
    }
`;

export const QUERY_MP_FOR_PROJECT = gql`
    query GetMpForProject($id: ID!) {
        tenderer(id: $id) {
            id
            name
            hasConversations
            unreadChatsCount
            favicon
            favicon_resized
        }
    }
`;

export const QUERY_CA_FOR_PROJECT = gql`
    query GetCaForProject($id: ID!) {
        contracting_authority(id: $id) {
            id
            name
            hasConversations
            unreadChatsCount
            favicon
            favicon_resized
        }
    }
`;

export const QUERY_CAS_OF_TENDER_FOR_PROJECT = gql`
    query GetCasOfTenderForProject($id: ID!) {
        tender(id: $id) {
            id
            clients {
                id
                name
                favicon
                favicon_resized
            }
        }
    }
`;

export const SINGLE_PROJECT_NOTIFICATION = gql`
    query single_project_notification($id: ID!) {
        project(id: $id) {
            id
            title
        }
    }
`;

export const SINGLE_COMMENT_NOTIFICATION = gql`
    query single_comment_notification($id: ID!) {
        comment(id: $id) {
            id
            comment
            project {
                id
                title
                activeUsers {
                    id
                }
            }
            user {
                id
                employee {
                    id
                    name
                }
            }
        }
    }
`;

export const SINGLE_REPLY_NOTIFICATION = gql`
    query single_reply_notification($id: ID!) {
        reply(id: $id) {
            id
            comment
            project {
                id
                title
                activeUsers {
                    id
                }
            }
            user {
                id
                employee {
                    id
                    name
                }
            }
        }
    }
`;

export const SINGLE_TASK = gql`
    query single_task($id: ID!) {
        task(id: $id) {
            id
            task_priority
            task_description
            project {
                id
                title
                activeUsers {
                    id
                }
            }
        }
    }
`;
