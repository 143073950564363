import React from "react";
import { Box, Button, Divider, Drawer, Stack, Typography } from "@mui/material";
import DrawerContent from "./DrawerContent";
import ColumnSelector from "./ColumnSelector";
import { DrawerHeader } from "./DashboardBasePlate";
import { useRowsContext } from "./RowsContext";
import MatomoEvent from "../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    drawerWidth: number;
    open: boolean;
    onDrawerClose: () => void;
    widgets: {
        id: number;
        label: string;
        component: (amountOfWidgets: number) => JSX.Element;
    }[];
}

const CustomDrawer: React.FC<Props> = ({ drawerWidth, open, onDrawerClose, widgets }) => {
    const { update, cancelChanges } = useRowsContext();
    const { trackEvent } = useMatomo();

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                },
            }}
            variant="persistent"
            anchor="right"
            open={open}
        >
            <DrawerHeader>
                <Typography padding={1} fontWeight={500}>
                    Dashboard personaliseren
                </Typography>
            </DrawerHeader>
            <Divider />

            <Box padding={2} sx={{ flex: 1, display: "flex", overflow: "auto" }}>
                <DrawerContent widgets={widgets} />
            </Box>
            <Stack paddingBottom={4} paddingTop={2} direction="row" justifyContent="center">
                <Button
                    size="small"
                    onClick={() => {
                        cancelChanges();
                        onDrawerClose();
                        track({ category: "Dashboard", action: "Click-cancel-edit-dashboard", href: window.location.href });
                    }}
                >
                    Annuleren
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                        update();
                        track({ category: "Personal", action: "Click-save-edit-dashboard", href: window.location.href });
                    }}
                >
                    Opslaan
                </Button>
            </Stack>
            <Stack paddingBottom={4}>
                <Typography padding={2} fontWeight={500}>
                    Mogelijkheden per rij
                </Typography>
                <Stack direction="row" justifyContent="space-around">
                    <ColumnSelector amount={1} />
                    <ColumnSelector amount={2} />
                    <ColumnSelector amount={3} />
                </Stack>
            </Stack>
        </Drawer>
    );
};

export default CustomDrawer;
