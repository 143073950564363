import React from "react";

import { GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_results_data_personalOpportunities } from "../../__generated__/GetAllDocsForOpportunitiesOnTender";
import _ from "lodash";
import { Typography } from "@mui/material";
import { Euro, Event } from "@mui/icons-material";
import currencyFormatDE from "../../utils/currencyFormatter";
import { Link } from "react-router-dom";
interface Props {
    personalOpps: (GetAllDocsForOpportunitiesOnTender_filterOpportunityFiles_results_data_personalOpportunities | null)[];
    opp_id: string;
}

const PersonalOpportunityField: React.FC<Props> = ({ personalOpps, opp_id }) => {
    const result = _.chain(personalOpps)
        .groupBy((row) => row?.page?.page)
        .map((data, row) => ({ row, data })) //using ES6 shorthand to generate the objects
        .value();
    return (
        <div>
            {result
                ?.sort((a, b) => (a.row > b.row ? 1 : -1))
                .map((item, i: number) => {
                    return (
                        <div style={{ padding: "0px 8px 8px 0" }} key={i}>
                            <Link to={`/opportunities/${opp_id}?page=${item.row}`}>
                                <Typography variant="h6" style={{ fontSize: "0.9rem", display: "inline-block", color: "#225E4D" }}>
                                    Pagina {item?.row}
                                </Typography>
                            </Link>
                            {item?.data?.map((item, i) => {
                                const valueAndYear = item?.parsedValues?.map((val, i) => {
                                    return (
                                        <div style={{ display: "flex", alignItems: "center" }} key={i}>
                                            {val?.year && (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Event style={{ marginRight: 4, height: "0.8em", width: "0.8em", color: "#707070" }} />
                                                    <Typography style={{ marginRight: 8 }}>{val?.year}</Typography>
                                                </div>
                                            )}
                                            {val?.value !== null && val?.value !== undefined && (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Euro style={{ marginRight: 4, height: "0.8em", width: "0.8em", color: "#707070" }} />
                                                    <Typography>{currencyFormatDE(val?.value)}</Typography>
                                                </div>
                                            )}
                                        </div>
                                    );
                                });
                                return (
                                    <div style={{ paddingBottom: 8 }} key={i}>
                                        {item?.comment !== "" && item?.comment !== null && item?.comment !== undefined && (
                                            <Typography
                                                dangerouslySetInnerHTML={{
                                                    __html: `${item.comment}`,
                                                }}
                                            />
                                        )}
                                        <div>{valueAndYear}</div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    );
};

export default PersonalOpportunityField;
