import React from "react";
import { useQuery } from "@apollo/client";
import { GetTenderEvents, GetTenderEventsVariables } from "../../../../__generated__/GetTenderEvents";
import { GET_TENDER_EVENTS } from "../../../../graphql/queryDefinitions";
import ScheduleTimeline from "../../../../components/ScheduleTimeline";
import { Grid } from "@mui/material";

interface Props {
    tender_id: string;
    host: string | null;
    hostUrl: string | null;
}

const ScheduleTimelineWrapper: React.FC<Props> = ({ tender_id, host, hostUrl }) => {
    /**
     * Render widgetbox to use for loading and error state
     */
    // const renderPage = (content: React.ReactNode, disable: boolean, loading: boolean) => {
    //     return (
    //         <Disable disabled={disable}>
    //             <WidgetBox loading={loading} header={t("tenderPage.Schedule")} highlight="blue" padding={0} elevation={0}>
    //                 {content}
    //             </WidgetBox>
    //         </Disable>
    //     );
    // };

    /**
     * Fetch tender.events, deadline_inschrijving
     */
    const { loading, error, data, refetch } = useQuery<GetTenderEvents, GetTenderEventsVariables>(GET_TENDER_EVENTS, {
        variables: { id: tender_id },
    });

    /**
     * Show loader
     * content = empty
     * disabled = true
     * loading = state of loading (should be true)
     */
    if (loading) {
        // return renderPage(<></>, true, loading);
        return null;
    }

    /**
     * Show line and title only
     * content = empty
     * disabled = true
     * loading = false
     */
    if (!data || data?.tender === null || data.tender.events.length === 0 || error) {
        // return renderPage(<></>, true, false);
        return null;
    }

    const tender = data.tender;

    return (
        <Grid item xs={12} md={6}>
            <div id={tender_id}>
                <ScheduleTimeline
                    host={host}
                    hostUrl={hostUrl}
                    tender_id={tender_id}
                    itemLoop={tender.events}
                    deadline={tender.deadline_inschrijving}
                    country={tender.country?.alpha2 ? tender.country.alpha2 : null}
                    elevation={0}
                    refetch={refetch}
                />
            </div>
        </Grid>
    );
};

export default ScheduleTimelineWrapper;
