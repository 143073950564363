import React from "react";
import ThresholdPaper from "./ThresholdPaper";
import { Typography, Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetCaTresholds, GetCaTresholdsVariables } from "../../../../../__generated__/GetCaTresholds";
import { QUERY_TRESHOLDS } from "../../../../../graphql/queryDefCA";

interface Props {
    id: string;
}

const ValueTypesAndAmount: React.FC<Props> = ({ id }) => {
    const { data } = useQuery<GetCaTresholds, GetCaTresholdsVariables>(QUERY_TRESHOLDS, {
        variables: { id },
    });

    const services = data?.contracting_authority?.thresholds.filter((t) => t.cat === "Diensten");
    const works = data?.contracting_authority?.thresholds.filter((t) => t.cat === "Werken");
    const deliveries = data?.contracting_authority?.thresholds.filter((t) => t.cat === "Leveringen");

    if (data?.contracting_authority?.thresholds.length === 0) return null;

    return (
        <React.Fragment>
            {/* title for value papers */}
            <Typography variant="h4" style={{ marginBottom: 16 }}>
                Drempelbedragen
            </Typography>
            <Grid item container xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                {/* 
          Services paper 
      */}
                <Grid item xs={12} md={4}>
                    {services !== undefined && <ThresholdPaper title={services[0].cat} data={services} />}
                </Grid>

                {/* 
          Supplies paper
      */}
                <Grid item xs={12} md={4}>
                    {deliveries !== undefined && <ThresholdPaper title={deliveries[0].cat} data={deliveries} />}
                </Grid>

                {/* 
          Works paper 
      */}
                <Grid item xs={12} md={4}>
                    {works !== undefined && <ThresholdPaper title={works[0].cat} data={works} />}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ValueTypesAndAmount;
