/**
 * Formats a number to a currency string
 */
const currencyFormatDE = (num: number) => {
    // Format the number to always have two decimal places
    const formattedNum = num.toFixed(2);

    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = formattedNum.split(".");

    // Replace the decimal point character with a comma
    let result = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + "," + decimalPart;

    // If the decimal part is '00', replace it with a hyphen
    if (decimalPart === "00") {
        result = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + ",-";
    }

    return result;
};

export default currencyFormatDE;
