import React, { useState } from "react";
import { Button, ListItemText, Menu, MenuItem } from "@mui/material";

interface Props {
    handleProximityChange: (g: string, c: string, o: number) => void;
    groupId: string;
    conditionId: string;
    proximity: number;
}

const ProximityBox: React.FC<Props> = ({ handleProximityChange, groupId, conditionId, proximity }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button sx={{ minWidth: "37px" }} variant="contained" onClick={handleOpen} size="small">
                {proximity}
            </Button>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: 200,
                            width: "7ch",
                        },
                    },
                }}
            >
                {[...Array(50)].map((_, index) => {
                    const optionNumber = index + 1;
                    return (
                        <MenuItem
                            key={index}
                            value={optionNumber}
                            onClick={() => {
                                handleProximityChange(groupId, conditionId, optionNumber);
                                handleClose(); // Close the menu
                            }}
                        >
                            <ListItemText sx={{ textAlign: "center" }}>{optionNumber}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default ProximityBox;
