import React from "react";
import { GetTileWidgetData_filterTenders_results_data } from "../../../../__generated__/GetTileWidgetData";
import { Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import HorizontalContractTimeline from "../../../../components/tenderDetail/widgets/contract_timeline/HorizontalContractTimeline";
import BidCard from "./BidCard";
import StatusChip from "../../../../components/StatusChip";
import { useQuery } from "@apollo/client";
import { GetLotsOfTender, GetLotsOfTenderVariables } from "../../../../__generated__/GetLotsOfTender";
import { QUERY_LOTS_OF_TNDR } from "../../../../graphql/queryDefinitions";
import { OptieEinde, findLotValue } from "../../../../utils/contracttimeline";
import _ from "lodash";
import { GetLikedTendersOfUser } from "../../../../__generated__/GetLikedTendersOfUser";
import { QUERY_USER_LIKED_TENDERS } from "../../../../graphql/queryDefCurrentUser";
import { ThumbUp } from "@mui/icons-material";

interface Props {
    tndr: GetTileWidgetData_filterTenders_results_data;
    opties: OptieEinde[];
}

const LotInfoBox: React.FC<Props> = ({ tndr, opties }) => {
    const { data: TNDR } = useQuery<GetLikedTendersOfUser>(QUERY_USER_LIKED_TENDERS, { fetchPolicy: "network-only" });
    const tndrs = TNDR?.currentUser.likedTenders.map((t) => t.id);

    const { data } = useQuery<GetLotsOfTender, GetLotsOfTenderVariables>(QUERY_LOTS_OF_TNDR, {
        variables: { id: tndr.id },
        fetchPolicy: "network-only",
    });

    if (!data || !data.tender) return <>null</>;

    // Step 1: Filter the bids where winner is true
    const winningBids = _.filter(tndr.bids, { winner: true });

    // Step 2: Group the bids by marketParty id
    const groupedBids = _.groupBy(winningBids, (bid) => bid.marketParty.id);

    // Step 3: Merge data for each group
    const mergedBids = _.map(groupedBids, (group, id) => {
        const mergedBid = {
            marketParty: group[0].marketParty,
            bids: group.map((bid) => ({
                bid: bid.bid,
                lot: bid.lot,
            })),
        };
        return mergedBid;
    });

    // Function to filter mergedBids based on a specific lot id
    const filterMergedBidsByLotId = (lotId: string) => {
        return mergedBids
            .map((marketPartyGroup) => ({
                ...marketPartyGroup,
                bids: marketPartyGroup.bids.filter((bid) => bid.lot?.number === lotId),
            }))
            .filter((marketPartyGroup) => marketPartyGroup.bids.length > 0);
    };

    return (
        <React.Fragment key={tndr.id}>
            {data.tender.lots.map((lot, index) => {
                const start =
                    tndr.looptijd_start && tndr.looptijd_start.length > 1
                        ? tndr.looptijd_start[index]?.value
                        : tndr.looptijd_start
                        ? tndr.looptijd_start[0]?.value
                        : null;
                const end =
                    tndr.looptijd_einde && tndr.looptijd_einde.length > 1
                        ? tndr.looptijd_einde[index]?.value
                        : tndr.looptijd_einde
                        ? tndr.looptijd_einde[0]?.value
                        : null;
                return (
                    <div key={lot.id}>
                        <div key={lot.id} style={{ margin: "24px 16px" }}>
                            <Typography gutterBottom variant="h3">
                                {tndrs?.find((i) => i === tndr.id) && (
                                    <ThumbUp fontSize="small" sx={{ marginRight: "4px", marginTop: "4px", color: "#173357" }} />
                                )}
                                <Link to={`/tenders/${tndr.id}`}>
                                    {tndr.namegiventocontract} - perceel {lot.number}
                                </Link>
                            </Typography>
                            {/* ADD timeline when data is available */}
                            {lot.contract !== null ? (
                                <div>
                                    <HorizontalContractTimeline
                                        start={start || data.tender?.datum_gunning || null}
                                        end={end}
                                        options={findLotValue(opties, parseInt(lot.number)) ?? []}
                                        datum_gunning_as_start={Boolean(start === null)}
                                        noEndDateAvailable={Boolean(lot.looptijd_einde === null)}
                                        undetermined={Boolean(lot.contract?.undeterminedPeriod)}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <HorizontalContractTimeline
                                        datum_gunning_as_start={Boolean(lot.looptijd_start === null)}
                                        options={findLotValue(opties, parseInt(lot.number)) ?? []}
                                        start={lot.looptijd_start || data.tender?.datum_gunning || null}
                                        end={lot.looptijd_einde || null}
                                        noEndDateAvailable={Boolean(lot.looptijd_einde === null)}
                                        undetermined={false}
                                    />
                                </div>
                            )}

                            {/* Map over all tenders of this CA, show winning bids or state of tender if no winning bids are available*/}
                            {tndr.bids && tndr.bids.filter((bid) => bid.winner === true).length > 0 ? (
                                // Card for each winning bid, with lotnumber bid value and name of marketparty
                                <Grid container direction={"row"} spacing={2} marginTop={2} marginLeft={0}>
                                    {filterMergedBidsByLotId(lot.id).map((bid, index) => {
                                        return (
                                            <Grid key={index} marginBottom={1} marginRight={1}>
                                                <BidCard bid={bid.marketParty} bids={bid.bids} />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            ) : (
                                // Div with state of current tender
                                <div style={{ margin: "8px 0px" }}>
                                    <StatusChip type={tndr.status} colored={true} />
                                </div>
                            )}
                        </div>
                        <Divider />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default LotInfoBox;
