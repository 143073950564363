import React from "react";

import { Grid, Typography } from "@mui/material";
import { Euro, Event } from "@mui/icons-material";
import { GetCAOpportunitiesOfType_contractingAuthorityOpportunities_data_parsedValues } from "../../../__generated__/GetCAOpportunitiesOfType";

interface Props {
    parsedValues: (GetCAOpportunitiesOfType_contractingAuthorityOpportunities_data_parsedValues | null)[] | null;
    comment: string;
}

const AccordionDetailOpp: React.FC<Props> = ({ parsedValues, comment }) => {
    // add dots and comma's in numbers
    function currencyFormatDE(num: number) {
        return num
            .toFixed(2) // always two decimal digits
            .replace(".", ",") // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
    }

    return (
        <Grid container>
            {/*
             * Grid with opportunity text/comment
             */}
            <Grid
                xs={12}
                md={12}
                sx={{ padding: "10.5px 10px 10.5px 10px", width: "100%", display: "flex", alignItems: "center" }}
                style={{ marginBottom: 4 }}
            >
                <div style={{ padding: "0 10px ", width: "100%", display: "flex", alignItems: "center" }}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: comment,
                        }}
                    />
                </div>
            </Grid>

            {/*
             * Grid with year and value
             * TODO: mapping to get multiple years and values
             */}
            <Grid
                xs={12}
                md={12}
                sx={{ padding: "10.5px 10px 10.5px 10px", width: "100%", display: "flex", alignItems: "center" }}
                style={{ justifyContent: "space-between", marginBottom: 4 }}
            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    {parsedValues?.map((v, i) => {
                        return (
                            <div key={i} style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                {/*
                                 * Div with calendar icon and saved year
                                 */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Event sx={{ marginRight: 0, color: "#707070", marginLeft: "8px" }} />
                                    <div
                                        style={{
                                            padding: "10.5px 10px 10.5px 10px",
                                            height: "37.63px",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{v?.year}</Typography>
                                    </div>
                                </div>
                                {/*
                                 * Div with currency icon (euro) and value
                                 */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Euro sx={{ marginRight: 0, color: "#707070", marginLeft: "8px" }} />
                                    <div
                                        style={{
                                            padding: "10.5px 10px 10.5px 10px",
                                            height: "37.63px",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{v !== null && v.value !== null && currencyFormatDE(v.value)}</Typography>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Grid>
        </Grid>
    );
};

export default AccordionDetailOpp;
