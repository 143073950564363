import React from "react";
import { GetOrganizationDetail_tenderer_settlings_data } from "../../__generated__/GetOrganizationDetail";
import TopLinksModule from "../organizationComponents/TopLinksModule";
import CountryFlagBox from "../boxes/CountryFlagBox";
interface Props {
    details: GetOrganizationDetail_tenderer_settlings_data[] | undefined;
    logo: string | undefined;
}

const LogoAddress: React.FC<Props> = ({ details, logo }) => {
    // sort detail on id
    const head = details?.filter((item) => item.type === "hoofdvestiging" && item.actief === 1);
    const person = details?.filter((item) => item.type === "rechtspersoon");
    const noActive = details?.filter((item) => item.actief === 1).length === 0;
    const inactiveHead = details?.filter((item) => item.type === "hoofdvestiging");
    // get last item in array
    const last = details !== undefined && details[details.length - 1];

    // Temporary linkchange
    const stagingLogo = logo?.replace("staging-", "");

    return (
        <React.Fragment>
            {/* {details.map((item, i) => { */}

            {head !== undefined && head.length > 0 ? (
                <React.Fragment>
                    {logo !== undefined && (
                        <div
                            style={{
                                marginBottom: 24,
                                marginRight: 16,
                                height: 100,
                                width: "90%",
                                backgroundImage: `url(${stagingLogo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "left",
                                backgroundPositionY: "top",
                            }}
                        />
                    )}

                    <TopLinksModule Title={"hoofdvestiging"}>
                        <ul
                            style={{
                                listStyle: "none",
                                margin: 0,
                                marginBottom: 8,
                                padding: "0px",
                            }}
                        >
                            <li>
                                {head[0].visit_address?.street} {head[0].visit_address?.housenumber}
                                {head[0].visit_address?.housenumber_suffix}
                            </li>
                            <li style={{ display: "flex", alignItems: "center" }}>
                                {head[0].visit_address?.postal_code} {head[0].visit_address?.city}
                                <CountryFlagBox code={head[0].visit_address?.country ? head[0].visit_address?.country : null} />
                            </li>
                        </ul>
                    </TopLinksModule>
                </React.Fragment>
            ) : person !== undefined && person.length > 0 ? (
                <React.Fragment>
                    {logo !== undefined && (
                        <div
                            style={{
                                marginBottom: 24,
                                height: 100,
                                width: "90%",
                                backgroundImage: `url(${stagingLogo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "left",
                                backgroundPositionY: "top",
                            }}
                        />
                    )}

                    <TopLinksModule Title={"rechtspersoon"}>
                        <ul
                            style={{
                                listStyle: "none",
                                margin: 0,
                                marginBottom: 8,
                                padding: "0px",
                            }}
                        >
                            <li>
                                {person[0].visit_address?.street} {person[0].visit_address?.housenumber}
                                {person[0].visit_address?.housenumber_suffix}
                            </li>
                            <li style={{ display: "flex", alignItems: "center" }}>
                                {person[0].visit_address?.postal_code} {person[0].visit_address?.city}
                                <CountryFlagBox code={person[0].visit_address?.country ? person[0].visit_address?.country : null} />
                            </li>
                        </ul>
                    </TopLinksModule>
                </React.Fragment>
            ) : inactiveHead !== undefined && inactiveHead.length > 0 && noActive ? (
                <React.Fragment>
                    {logo !== undefined && (
                        <div
                            style={{
                                marginBottom: 24,
                                height: 100,
                                width: "90%",
                                backgroundImage: `url(${stagingLogo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "left",
                                backgroundPositionY: "top",
                            }}
                        />
                    )}

                    <TopLinksModule Title={"hoofdvestiging"}>
                        <ul
                            style={{
                                listStyle: "none",
                                margin: 0,
                                marginBottom: 8,
                                padding: "0px",
                            }}
                        >
                            <li>
                                {inactiveHead[0].visit_address?.street} {inactiveHead[0].visit_address?.housenumber}
                                {inactiveHead[0].visit_address?.housenumber_suffix}
                            </li>
                            <li style={{ display: "flex", alignItems: "center" }}>
                                {inactiveHead[0].visit_address?.postal_code} {inactiveHead[0].visit_address?.city}
                                <CountryFlagBox code={inactiveHead[0].visit_address?.country ? inactiveHead[0].visit_address?.country : null} />
                            </li>
                        </ul>
                    </TopLinksModule>
                </React.Fragment>
            ) : last !== false && last !== undefined ? (
                <React.Fragment>
                    {logo !== undefined && (
                        <div
                            style={{
                                marginBottom: 24,
                                height: 100,
                                width: "90%",
                                backgroundImage: `url(${stagingLogo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "left",
                                backgroundPositionY: "top",
                            }}
                        />
                    )}

                    <TopLinksModule Title={"vestiging"}>
                        <ul
                            style={{
                                listStyle: "none",
                                margin: 0,
                                marginBottom: 8,
                                padding: "0px",
                            }}
                        >
                            <li>
                                {last.visit_address?.street} {last.visit_address?.housenumber}
                                {last.visit_address?.housenumber_suffix}
                            </li>
                            <li style={{ display: "flex", alignItems: "center" }}>
                                {last.visit_address?.postal_code} {last.visit_address?.city}
                                <CountryFlagBox code={last.visit_address?.country ? last.visit_address?.country : null} />
                            </li>
                        </ul>
                    </TopLinksModule>
                </React.Fragment>
            ) : (
                <>
                    {logo !== undefined && (
                        <div
                            style={{
                                marginBottom: 24,
                                height: 100,
                                width: "90%",
                                backgroundImage: `url(${stagingLogo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "left",
                                backgroundPositionY: "top",
                            }}
                        />
                    )}
                </>
            )}
        </React.Fragment>
    );
};

export default LogoAddress;
