import React, { useState, useCallback, lazy, useEffect } from "react";
import { useLocation, useNavigate, Route, Routes } from "react-router-dom";

import setTheme from "../../utils/setTheme";
import MainLayout from "../../components/layout/MainLayout";

// Import components
import TenderDetailContainer from "./TenderDetailContainer";
import ModuleTabs, { DynamicTab } from "../../components/ModuleTabs";
import TenderOverview from "./allTenders/TenderOverview";
import TenderInteresting from "./interesting/TenderInteresting";
import { ThemeProvider } from "@mui/material/styles";
import { materialTheme } from "../../assets/styles";
import WorklistPage from "./worklistModule/WorklistPage";
import NotFound404 from "../NotFound404";
import { WorklistContext } from "../../components/contextProviders/TenderWorklistContext";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useQuery } from "@apollo/client";
import { GetUserSettings } from "../../__generated__/GetUserSettings";
import { QUERY_USER_SETTINGS } from "../../graphql/queryDefCurrentUser";
import { IS_USER_ADMIN } from "../../graphql/queryAdmin";
import { GetAdminState } from "../../__generated__/GetAdminState";
import { getSettingValue } from "../profile/preferences/Settings";

// const NewProject = lazy(() => import("./newProject/NewProject"));

const TenderAdminDetail = lazy(() => import("./TenderAdminDetail"));

const TenderModule: React.FC = () => {
    const { trackPageView } = useMatomo();
    const history = useLocation();
    const navigate = useNavigate();
    const [tabs, setTabs] = useState<DynamicTab[]>([]);
    const [page, setPage] = useState(1);
    const { data: admin } = useQuery<GetAdminState>(IS_USER_ADMIN);

    /**
     * Get user view preferences and set to localstate
     */
    useQuery<GetUserSettings>(QUERY_USER_SETTINGS, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.currentUser && data.currentUser.settings) {
                const allsettings = data.currentUser.settings;
                // // Worklist setting (chosen view in preferences)
                // const worklistView = data?.currentUser.settings?.find((i) => i?.name === "default_view_tender_worklist")?.value;
                // // Review tenders setting (chosen view in preferences)
                // const reviewView = data?.currentUser.settings?.find((i) => i?.name === "default_view_tender_reviews")?.value;

                // Worklist setting (chosen view in preferences)
                const worklistView = getSettingValue(allsettings, "default_view_tender_worklist");
                // review tenders setting (chosen view in preferences)
                const reviewView = getSettingValue(allsettings, "default_view_tender_reviews");

                // Worklist view
                localStorage.setItem(
                    "twv",
                    JSON.stringify(
                        worklistView === "SPLIT" ? 3 : worklistView === "UPDATE" ? 2 : worklistView === "LIST" ? 0 : worklistView === "COLUMN" ? 1 : 3
                    )
                );
                // Review tab view
                localStorage.setItem(
                    "rtv",
                    JSON.stringify(
                        reviewView === "SPLIT" ? 3 : reviewView === "UPDATE" ? 2 : reviewView === "LIST" ? 0 : reviewView === "COLUMN" ? 1 : 0
                    )
                );
            }
        },
    });

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: "Tender", // optional
            href: `https://tool.tender.guide${history.pathname}`, // optional
        });
    }, [trackPageView, history.pathname]);

    /**
     * Get tabs in session storage (tenders) and store in state
     */
    useEffect(() => {
        if (sessionStorage.getItem("ott")) {
            setTabs(JSON.parse(sessionStorage.getItem("ott") || "[]"));
        }
    }, []);
    /**
     * Register a new tab to the TenderModule
     */
    const registerTab = useCallback((tab: DynamicTab) => {
        setTabs((prevTabs) => {
            // If the tab that is going to registered is not in the list yet add it
            if (!prevTabs.find((prevTab) => prevTab.value === tab.value)) {
                return [...prevTabs, tab];
            }
            return prevTabs;
        });
    }, []);

    /**
     * Handle closing the tab, and navigate to the last tab in the sequence
     */
    const closeTab = (url: string) => {
        setTabs((prevTabs) => {
            const newTabs = prevTabs.filter((tab) => tab.value !== url);
            // If active tender is the one thats been closed -> navigate to worklist
            // otherwise navigate to current pathname
            if (history.pathname === url) {
                navigate("/tenders/worklist");
            } else {
                navigate(history.pathname);
            }
            return newTabs;
        });
    };

    /**
     * Handle closing all tabs, and navigate to all tenders
     * Dont navigate to worklist if user is on "all" or "reviews" tab
     */
    const closeAllTab = () => {
        setTabs([]);
        if (history.pathname === "/tenders/reviews") {
            navigate("/tenders/reviews");
        }
        if (history.pathname === "/tenders") {
            navigate("/tenders");
        } else {
            navigate("/tenders/worklist");
        }
    };

    /**
     * Array of default tabs
     */
    const defaultTabs = [
        { id: 1, route: "/tenders", label: "All" },
        { id: 2, route: "/tenders/reviews", label: "Reviews" },
        { id: 3, route: "/tenders/worklist", label: "Worklist" },
    ];

    /**
     * Update session storage when tabs change
     */
    useEffect(() => {
        sessionStorage.setItem("ott", JSON.stringify(tabs));
    }, [tabs]);

    setTheme("blue");

    return (
        <ThemeProvider theme={materialTheme}>
            <WorklistContext.Provider value={{ page, setPage }}>
                <MainLayout
                    page="tenders"
                    header={
                        <ModuleTabs
                            page="Tender"
                            value={history.pathname}
                            onChange={(value) => navigate(value)}
                            onClose={closeTab}
                            tabs={tabs}
                            onCloseAll={closeAllTab}
                            defaultTabs={defaultTabs}
                        />
                    }
                >
                    <Routes>
                        {/* Enrichment routes */}
                        {admin?.currentUser.is_admin === true && <Route path="admin/:id" element={<TenderAdminDetail />} />}

                        {/* Tender for users routes */}
                        <Route path="" element={<TenderOverview />} />
                        <Route path="reviews" element={<TenderInteresting />} />
                        <Route path="worklist" element={<WorklistPage />} />
                        {/* <Route path="new-project" element={<NewProject registerTab={registerTab} />} /> */}
                        <Route path=":id" element={<TenderDetailContainer registerTab={registerTab} />} />
                        <Route path="*" element={<NotFound404 url={"/tenders"} admin={admin?.currentUser.is_admin} />} />
                    </Routes>
                </MainLayout>
            </WorklistContext.Provider>
        </ThemeProvider>
    );
};

export default TenderModule;
