import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { Link } from "react-router-dom";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Grid, Paper, TextField, Typography } from "@mui/material";
import { GetCaDataForWidgetOfCurrentTender_tender_clients } from "../../__generated__/GetCaDataForWidgetOfCurrentTender";
import FaviconBox from "../FaviconBox";
import OrganizationSocials from "../organizationComponents/OrganizationSocials";
import { GetStarredCas_currentUser_contracting_authorities } from "../../__generated__/GetStarredCas";
import { Star } from "@mui/icons-material";
import TooltipOnNewLines from "../TooltipOnNewLines";

interface Props {
    handleClose(): void;
    open: boolean;
    clients: GetCaDataForWidgetOfCurrentTender_tender_clients[];
    starredCAS: GetStarredCas_currentUser_contracting_authorities[];
}

const CaExpandModal: React.FC<Props> = ({ handleClose, open, clients, starredCAS }) => {
    const { t } = useTranslation();
    const inputRef = useRef();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const starColor = "#9e9e9e";

    /**
     * handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */
    const handleSearchUser = (event: any) => {
        const {
            target: { value },
        } = event;

        setSearchTerm(value);
    };

    /**
     * Get mapping with unique values
     */
    const clientmapping = [...new Set(clients)];

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and name of ca
     */
    const results = !searchTerm
        ? clientmapping
        : clientmapping.filter((item) => item.name && item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    return (
        <Dialog
            open={open}
            scroll={"paper"}
            sx={{
                "& .MuiDialog-paper": {
                    minHeight: "80vh",
                    maxHeight: "80vh",
                },
            }}
            fullWidth
            maxWidth={"lg"}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <Paper
                elevation={3}
                sx={{
                    margin: "24px",
                    padding: "16px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                    // onSearch();
                }}
            >
                <Typography variant="h6">{t("contractingAuthorities")}</Typography>
                <TextField
                    variant="outlined"
                    size="small"
                    inputRef={inputRef}
                    sx={{
                        flex: 1,
                        marginTop: (theme) => theme.spacing(1),
                    }}
                    placeholder="Zoeken"
                    autoFocus
                    value={searchTerm}
                    onChange={handleSearchUser}
                />
            </Paper>
            <DialogContent style={{ paddingTop: 0 }}>
                <div style={{ margin: 0 }}>
                    <div>
                        <Grid
                            container
                            xs={12}
                            spacing={2}
                            //  className={classes.root}
                        >
                            {results
                                .sort((a: any, b: any) => (a.name || "zzz").localeCompare(b.name || "zzz"))
                                .sort((a: any, b: any) => (a.pivot.category || "zzz").localeCompare(b.pivot.category || "zzz"))

                                .map((ca) => {
                                    const insta = ca.urls.filter((item) => item.type === "INSTAGRAM").length;
                                    const fb = ca.urls.filter((item) => item.type === "FACEBOOK").length;
                                    const linkedin = ca.urls.filter((item) => item.type === "LINKEDIN").length;
                                    const youtube = ca.urls.filter((item) => item.type === "YOUTUBE").length;
                                    const twitter = ca.urls.filter((item) => item.type === "TWITTER").length;
                                    const pinterest = ca.urls.filter((item) => item.type === "PINTEREST").length;
                                    const socialLength = insta + fb + linkedin + youtube + twitter + pinterest;
                                    return (
                                        <Grid item key={ca.id} xs={12} md={3}>
                                            <Paper
                                                style={{
                                                    width: "100%",
                                                    padding: 16,
                                                    marginBottom: 6,
                                                    borderTop: "3px solid #F57117",
                                                }}
                                            >
                                                <div style={{ height: 84 }}>
                                                    <Typography style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                                                        <span style={{ marginTop: 4 }}>
                                                            <FaviconBox marginTop={0} favicon={ca.favicon_resized ?? ca.favicon} name={ca.name} />
                                                        </span>
                                                        <Link to={`/contracting-authorities/${ca.id}`}>
                                                            <TooltipOnNewLines
                                                                placement={"top"}
                                                                text={ca.name || ""}
                                                                variant={"body1"}
                                                                lines={2}
                                                                id={`T${ca.id as string}`}
                                                                fontweight={"normal"}
                                                                fontSize={14}
                                                            />
                                                        </Link>
                                                        {starredCAS.find((s) => s.id === ca.id) && (
                                                            <Star style={{ color: `${starColor}`, marginLeft: 4, fontSize: "1rem" }} />
                                                        )}
                                                    </Typography>
                                                    <Typography style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <span style={{ marginLeft: 27, color: "#0000008a" }}>
                                                            {ca.address}
                                                            <br />
                                                            {ca.postalcode && `${ca.postalcode}, `} {ca.city}
                                                        </span>
                                                    </Typography>
                                                </div>
                                                <div
                                                    style={{
                                                        // marginTop: 12,
                                                        height: 26,
                                                        paddingLeft: 23,
                                                    }}
                                                >
                                                    {socialLength > 0 && <OrganizationSocials title={false} urls={ca.urls} />}
                                                </div>
                                            </Paper>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("advancedSearch.Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CaExpandModal;
