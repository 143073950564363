import React from "react";
import { Close, Star } from "@mui/icons-material";
import { CardContent, Dialog, Divider, Grid, IconButton, Typography } from "@mui/material";
import { ClickedItem } from "../TileContractContainer";
import { Link } from "react-router-dom";
import LogoBox from "./LogoBox";
import InfoBox from "./InfoBox";
import LotInfoBox from "./LotInfoBox";
import { mergeOptiesEinde } from "../../../../utils/contracttimeline";
import { useQuery } from "@apollo/client";
import { GetStarredCas } from "../../../../__generated__/GetStarredCas";
import { QUERY_GETSTARREDCAS } from "../../../../graphql/queryDefCurrentUser";

interface Props {
    onClose: () => void;
    data: ClickedItem;
    open: boolean;
}

const ExpandedCard: React.FC<Props> = ({ onClose, data, open }) => {
    const { data: CAS } = useQuery<GetStarredCas>(QUERY_GETSTARREDCAS, { fetchPolicy: "network-only" });
    const starredCAS = CAS?.currentUser.contracting_authorities;

    return (
        <Dialog fullWidth maxWidth={"lg"} open={open} onClose={onClose}>
            <div style={{ margin: 8 }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
                        <Grid item alignItems={"center"}>
                            {/* show logo/favicon or name of current CA */}
                            <LogoBox
                                colorDefault
                                logo={data.logo}
                                favicon={data.favicon === "No Favicon" ? null : data.favicon}
                                name={data.name ?? ""}
                                elevation={0}
                                width="80px"
                                height="80px"
                                textColor="#F57117"
                            />
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    {/* name and link to detail page of CA */}
                                    <Typography variant="h5" sx={{ display: "flex", alignItems: "center" }}>
                                        {data.clientId === "NoClientID" ? (
                                            <span> {data.name}</span>
                                        ) : (
                                            <Link to={`/contracting-authorities/${data.clientId}`}> {data.name}</Link>
                                        )}
                                        {starredCAS?.find((i) => i.id === data.clientId) && (
                                            <Star fontSize="small" sx={{ marginLeft: "4px", color: "#F57117" }} />
                                        )}
                                    </Typography>
                                    {/* Amount of tenders found in ending contracts for this CA */}
                                    <Typography variant="body2" gutterBottom>
                                        <Typography>Aantal: {data.tenders.length}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* Close button. resets open state and data to show to emty state */}
            <IconButton onClick={onClose} sx={{ position: "absolute", top: 5, right: 5 }}>
                <Close />
            </IconButton>
            <Divider />
            <CardContent sx={{ flex: 1, overflow: "auto" }}>
                {/* Map over tenders for current CA */}
                {data.tenders.map((tndr, index) => {
                    if (
                        (tndr.opties_einde && mergeOptiesEinde(tndr.opties_einde) !== null && mergeOptiesEinde(tndr.opties_einde).length > 1) ||
                        (tndr.looptijd_start && tndr.looptijd_start.length > 1) ||
                        (tndr.looptijd_einde && tndr.looptijd_einde?.length > 1)
                    ) {
                        return <LotInfoBox key={tndr.id} tndr={tndr} opties={mergeOptiesEinde(tndr.opties_einde)} />;
                    }
                    return (
                        <InfoBox key={tndr.id} total={data.tenders.length} index={index} tndr={tndr} opties={mergeOptiesEinde(tndr.opties_einde)} />
                    );
                })}
            </CardContent>
        </Dialog>
    );
};

export default ExpandedCard;
