import React, { useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MenuItem, MenuList, Popover, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    pathParts: string[];
    setFolder: (folder: string) => void;
    tender_id: string;
    folder: string;
}

const DocBreadCrumbs: React.FC<Props> = ({ pathParts, setFolder, tender_id, folder }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    /**
     *
     * @param event Open popover underneath button
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    /**
     * close popover
     */
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {pathParts.length > 0 && (
                <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 8 }}>
                    {/*
                     * Link to go back to root map
                     */}
                    <Link underline="none" color="inherit" component="button" onClick={() => setFolder("")}>
                        ...
                        {/* Change to whatever has to be the name of the root */}
                    </Link>
                    {/*
                     * Map over pathParts and create a breadcrumb for each folder
                     * onClick => go back inside clicked folder
                     */}
                    {pathParts.map((part, i) => {
                        // create a  string with all folders included the clicked one
                        // clicked on is the new last item in strimg
                        const item = pathParts
                            .slice(0, i + 1)
                            .join("/")
                            .toString();

                        return (
                            // If part is last in array => show link with dropdown arrow
                            // Onclick => open popover with option to download content in folder
                            <React.Fragment key={i}>
                                {i === pathParts.length - 1 ? (
                                    <React.Fragment>
                                        <Typography
                                            key={i}
                                            color="textPrimary"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                paddingLeft: "4px",
                                                marginLeft: "-4px",
                                                fontSize: "0.785rem",
                                                marginBottom: "-1px",
                                                "&:hover": {
                                                    backgroundColor: "#ececec",
                                                    borderRadius: "4px",
                                                },
                                            }}
                                            aria-describedby={id}
                                            onClick={handleClick}
                                        >
                                            {part} <ArrowDropDownIcon sx={{ color: "#9e9e9e" }} />
                                        </Typography>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                        >
                                            <MenuList dense autoFocusItem={open} style={{ padding: 0 }}>
                                                <MenuItem
                                                    onClick={(e) => {
                                                        // Stop click on listitem to prevent document-viewer to initiate

                                                        e.stopPropagation();
                                                        const element = document.createElement("a");
                                                        element.setAttribute("download", `${folder}`);
                                                        element.setAttribute(
                                                            "href",
                                                            `${process.env.REACT_APP_API_ROOT}/tenders/${tender_id}/downloadFolderZip${folder}`
                                                        );
                                                        element.style.display = "none";
                                                        document.body.appendChild(element);
                                                        element.click();
                                                        document.body.removeChild(element);
                                                        handleClose();
                                                    }}
                                                >
                                                    {t("Download_folder")}
                                                </MenuItem>
                                            </MenuList>
                                        </Popover>
                                    </React.Fragment>
                                ) : (
                                    <Link
                                        underline="none"
                                        sx={{ fontSize: "0.785rem", color: "#707070", cursor: "pointer" }}
                                        color="inherit"
                                        onClick={() => setFolder(item)}
                                    >
                                        {part}
                                    </Link>
                                )}
                            </React.Fragment>
                        );
                    })}
                </Breadcrumbs>
            )}
        </React.Fragment>
    );
};

export default DocBreadCrumbs;
