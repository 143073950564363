import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import setTheme from "../../utils/setTheme";
import { greyTheme } from "../../assets/styles";
import MainLayout from "../../components/layout/MainLayout";
import CalendarModule from "./CalendarModule";
import {
    //  useNavigate, useLocation,
    Routes,
    Route,
} from "react-router-dom";
// import CalendarTabs from "./CalendarTabs";
import NotFound404 from "../NotFound404";
import { Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";

const Calendar = () => {
    const { t } = useTranslation();

    /**
     * set theme color
     */
    useEffect(() => {
        setTheme("darkGray");
    });

    return (
        <ThemeProvider theme={greyTheme}>
            <MainLayout
                page="calendar"
                header={
                    <Tabs
                        id="CalendarPage"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{ width: `calc(100vw - 68px)`, height: "100%" }}
                        sx={{
                            "& .MuiTabs-root": {
                                height: "100%",
                            },
                            "& .MuiTabs-flexContainer": {
                                height: "100%",
                            },
                        }}
                    >
                        <Tab sx={{ paddingLeft: "48px" }} value="/calendar" label={t("agenda")} />
                    </Tabs>
                    // TODO: when more tabs are available -> <CalendarTabs value={history.pathname} onChange={(value) => navigate(value)} />
                    // <div id="CalendarPage" style={{ display: "flex", height: "100%", alignItems: "center" }}>
                    //     <Typography variant="button" className={classes.dashboard}>
                    //         {t("agenda")}
                    //     </Typography>
                    // </div>
                }
            >
                <Routes>
                    <Route path="" element={<CalendarModule />} />
                    {/* //TODO: Activate route(s) in version 4.x.x release */}
                    {/* <Route path="tasks" element={<TaskModule />} /> */}
                    {/* <Route path="support" element={<OperatorMessenger />} /> */}
                    <Route path="*" element={<NotFound404 url={"/calendar"} />} />
                </Routes>
            </MainLayout>
        </ThemeProvider>
    );
};

export default Calendar;
