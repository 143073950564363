import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Tooltip } from "@mui/material";
import { useAuthSettings } from "./AuthSettingsContext";
import { TwoFactorMethod } from "../../../../../__generated__/globalTypes";

interface Props {
    option: string;
    disable: boolean;
}

// Styled Paper component
const StyledPaper = styled("div")(({ theme }) => ({
    margin: "4px",
    marginLeft: "12px",
}));

const DefaultOption: React.FC<Props> = ({ option, disable }) => {
    const { setDefaultMethod, data_two_factor } = useAuthSettings();

    const hideButton = () => {
        const totp = Boolean(data_two_factor?.currentUser.two_factor_totp_enabled);
        const mail = Boolean(data_two_factor?.currentUser.two_factor_email_enabled);

        if (option === "TOTP" && totp) {
            return true;
        }
        if (option === "MAIL" && mail) {
            return true;
        }
        return;
    };

    const titleToShow =
        option === data_two_factor?.currentUser.two_factor_default_method
            ? "Ingesteld als standaard verificatie"
            : "Instellen als standaard verificatie";

    // if (option === data_two_factor?.currentUser.two_factor_default_method) {
    //     return <div style={{ height: "52px", margin: "4px 0px" }} />;
    // }

    return (
        <StyledPaper>
            {hideButton() && (
                <Tooltip title={titleToShow} placement="top">
                    <Button
                        size="small"
                        onClick={() => setDefaultMethod(option as TwoFactorMethod)}
                        disabled={option === data_two_factor?.currentUser.two_factor_default_method}
                        variant={"text"}
                    >
                        {option === data_two_factor?.currentUser.two_factor_default_method ? "" : "Standaard instellen"}
                    </Button>
                </Tooltip>
            )}
        </StyledPaper>
    );
};

export default DefaultOption;
