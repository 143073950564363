export const handleOppHighlighting = () => {
    const elems = document.getElementsByTagName("tenderguide") as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elems.length; i++) {
        // styling on render
        elems[i].style.textDecoration = `underline 3px green`;
        elems[i].style.cursor = "pointer";
        elems[i].style.fontStyle = "normal";
        elems[i].style.color = "green";

        // // styling on mouse enter (hover)
        // elems[i].onmouseenter = () => {
        //     for (let k = 0; k < collection.length; k++) {
        //         elems[k].style.textDecoration = `underline 5px ${label.color}`;
        //         elems[k].style.fontWeight = "500";
        //     }
        // };

        // // styling on mouse leave
        // elems[i].onmouseleave = () => {
        //     for (let k = 0; k < collection.length; k++) {
        //         elems[k].style.textDecoration = `underline 3px ${label.color}`;
        //         elems[k].style.fontWeight = "normal";
        //     }
        // };
    }
};
