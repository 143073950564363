import React from "react";
import WidgetBox from "../../../components/WidgetBox";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import icons
import BusinessIcon from "@mui/icons-material/Business";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import StarIcon from "@mui/icons-material/Star";
import PeopleIcon from "@mui/icons-material/People";
import DescriptionIcon from "@mui/icons-material/Description";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    color: string;
    errorText: string;
    type: "ca" | "mp" | "tender";
}

const UpdateWidgetError: React.FC<Props> = ({ color, errorText, type }) => {
    const { t } = useTranslation();

    const { trackEvent } = useMatomo();

    // Track event
    const goToList = (cat: string, action: string) => {
        trackEvent({ category: cat, action: action });
    };

    return (
        <WidgetBox
            header={
                <React.Fragment>
                    {/* Type is contracting authority */}
                    {type === "ca" ? (
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <StarIcon style={{ color: `${color}`, marginRight: 4, height: 20 }} />
                                {t("dashboardPage.UpdatesCA")}
                            </div>
                            <Typography>
                                <Link
                                    id="link-to-full-list"
                                    to="/contracting-authorities/starred"
                                    onClick={() => goToList("Ca", "go-to-ca-updateview")}
                                >
                                    {" "}
                                    {t("dashboardPage.ShowAll")}
                                </Link>
                            </Typography>
                        </div>
                    ) : // Type is tenderer
                    type === "mp" ? (
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <StarIcon style={{ color: `${color}`, marginRight: 4, height: 20 }} />
                                {t("dashboardPage.UpdatesOrganizations")}
                            </div>
                            <Typography>
                                <Link
                                    id="link-to-full-list"
                                    to="/organizations/starred"
                                    onClick={() => goToList("Organization", "go-to-organization-updateview")}
                                >
                                    {" "}
                                    {t("dashboardPage.ShowAll")}
                                </Link>
                            </Typography>
                        </div>
                    ) : (
                        // Type is tender
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <ThumbUpIcon style={{ color: `${color}`, marginRight: 4, height: 20 }} />
                                {t("dashboardPage.UpdatesInterestingTenders")}
                            </div>
                            <Typography>
                                <Link
                                    id="link-to-full-list"
                                    to="/tenders/reviews"
                                    onClick={() => {
                                        localStorage.setItem("tenderUpdateView", "event");
                                        goToList("Tender", "go-to-tender-updateview");
                                    }}
                                >
                                    {" "}
                                    {t("dashboardPage.ShowAll")}
                                </Link>
                            </Typography>
                        </div>
                    )}
                </React.Fragment>
            }
            // highlightcolor based on type
            highlight={type === "ca" ? "yellow" : type === "mp" ? "pink" : "blue"}
            padding={0}
        >
            <div style={{ height: 355, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                {type === "ca" && <BusinessIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />}
                {type === "mp" && <PeopleIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />}
                {type === "tender" && <DescriptionIcon style={{ color: "#f2f2f2", height: "9rem", width: "9rem" }} />}
                <Typography variant="h5" style={{ color: "#d4d4d4" }}>
                    {errorText}
                </Typography>
            </div>
        </WidgetBox>
    );
};

export default UpdateWidgetError;
