import React from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Typography } from "@mui/material";
import TooltipOnNewLines from "../TooltipOnNewLines";
import { FindInPage, LocalOffer } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { tenderSearch, tenderSearchVariables } from "../../__generated__/tenderSearch";
import { opportunitySearch, opportunitySearchVariables } from "../../__generated__/opportunitySearch";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(): void;
    type: string | undefined;
}

/**
 * Display a single notification row for the notification Dialog
 */
const RequestTenderSearchline: React.FC<Props> = ({ notification, onClick, type }) => {
    const blue = "#173357";
    const green = "#225E4D";

    /**
     * Get tendersearch name
     */
    const { data: tenderSearch } = useQuery<tenderSearch, tenderSearchVariables>(SINGLE_TENDER_SEARCH, {
        skip: type !== "tender",
        variables: {
            id: notification !== null && notification.data !== null ? (notification.data[0] as string) : "",
        },
    });

    /**
     * Get opportunitysearch name
     */
    const { data: oppSearch } = useQuery<opportunitySearch, opportunitySearchVariables>(SINGLE_OPPORTUNITY_SEARCH, {
        skip: type !== "opportunity",
        variables: {
            id: notification !== null && notification.data !== null ? (notification.data[0] as string) : "",
            user_id: parseInt(localStorage.getItem("user_id") as string),
        },
    });

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Tender search
     */
    if (type === "tender") {
        return (
            <MenuItem
                onClick={onClick}
                style={{
                    whiteSpace: "normal",
                    maxHeight: 250,
                    opacity: notification.read_at !== null ? 0.5 : undefined,
                    position: "relative",
                    padding: "8px 16px",
                }}
            >
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: blue }}>
                        <LocalOffer />
                    </Avatar>
                </ListItemAvatar>

                <ListItemText
                    primary={
                        <TooltipOnNewLines
                            placement={"bottom-start"}
                            text={
                                tenderSearch?.tenderSearch === null ? "Zoekregel gedeeld" : `Zoekregel gedeeld: ${tenderSearch?.tenderSearch?.name}`
                            }
                            variant={"body1"}
                            lines={1}
                            id={`T${notification.id as string}`}
                            fontweight={notification.read_at === null ? 500 : "normal"}
                            fontSize={14}
                        />
                    }
                    secondary={
                        <Typography
                            style={{
                                fontSize: 12,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <LocalOffer
                                style={{
                                    width: "0.75em",
                                    height: "0.75em",
                                    color: "#e1e1e1",
                                    marginRight: 4,
                                }}
                            />
                            {tenderSearch?.tenderSearch === null
                                ? "Verzoek geweigerd"
                                : tenderSearch?.tenderSearch?.state === "PENDING_ACCEPTANCE"
                                ? "Bekijk en accepteer zoekregel"
                                : "Zoekregel geaccepteerd"}
                        </Typography>
                    }
                />
            </MenuItem>
        );
    }

    if (type === "opportunity") {
        return (
            <MenuItem
                onClick={onClick}
                style={{
                    whiteSpace: "normal",
                    maxHeight: 250,
                    opacity: notification.read_at !== null ? 0.5 : undefined,
                    position: "relative",
                    padding: "8px 16px",
                }}
            >
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: green }}>
                        <FindInPage />
                    </Avatar>
                </ListItemAvatar>

                <ListItemText
                    primary={
                        <TooltipOnNewLines
                            placement={"bottom-start"}
                            text={
                                oppSearch?.opportunitySearch === null
                                    ? "Zoekregel gedeeld"
                                    : `Zoekregel gedeeld: ${oppSearch?.opportunitySearch?.name}`
                            }
                            variant={"body1"}
                            lines={1}
                            id={`T${notification.id as string}`}
                            fontweight={notification.read_at === null ? 500 : "normal"}
                            fontSize={14}
                        />
                    }
                    secondary={
                        <Typography
                            style={{
                                fontSize: 12,
                                // When the nofitication is unread, the date-part of the notification is bold and accent colored

                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <FindInPage
                                style={{
                                    width: "0.75em",
                                    height: "0.75em",
                                    color: "#e1e1e1",
                                    marginRight: 4,
                                }}
                            />
                            {oppSearch?.opportunitySearch === null
                                ? "Verzoek geweigerd"
                                : oppSearch?.opportunitySearch?.state === "PENDING_ACCEPTANCE"
                                ? "Bekijk en accepteer zoekregel"
                                : "Zoekregel geaccepteerd"}
                        </Typography>
                    }
                />
            </MenuItem>
        );
    }

    return <></>;
};

export default RequestTenderSearchline;

export const SINGLE_OPPORTUNITY_SEARCH = gql`
    query opportunitySearch($id: ID!, $user_id: Int!) {
        opportunitySearch(id: $id, user_id: $user_id) {
            id
            name
            state
        }
    }
`;

export const SINGLE_TENDER_SEARCH = gql`
    query tenderSearch($id: ID!) {
        tenderSearch(id: $id) {
            id
            name
            state
            color
        }
    }
`;
