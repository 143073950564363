import { createContext, useContext } from "react";

export interface Option {
    id: number;
    label: any;
    key: any;
}
export type TenderCustomOptions = {
    customOptionsWorklist: Option[];
    customOptionsReviews: Option[];
    customOptionsAll: Option[];
    setCustomOptionsWorklist: (customOptions: any) => void;
    setCustomOptionsReviews: (customOptions: any) => void;
    setCustomOptionsAll: (customOptions: any) => void;
};

export const TenderCustomOptionsContext = createContext<TenderCustomOptions>({
    customOptionsWorklist: [],
    customOptionsReviews: [],
    customOptionsAll: [],
    setCustomOptionsWorklist: (customOptions: any) => console.warn("no context provider"),
    setCustomOptionsReviews: (customOptions: any) => console.warn("no context provider"),
    setCustomOptionsAll: (customOptions: any) => console.warn("no context provider"),
});
export const useCustomOptions = () => useContext(TenderCustomOptionsContext);
