import React from "react";
import PollLayout from "./PollLayout";
import { Grid, Typography } from "@mui/material";
import AnswerCard from "./AnswerCard";
import WaitingScreen from "./WaitingScreen";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { usePoll } from "./PollContext";
import Lobby from "./Lobby";
import PollAdminPage from "./PollAdminPage";

interface Props {
    admin?: boolean;
}

const PollDetailPage: React.FC<Props> = ({ admin }) => {
    const { waiting, pollArray, sendingAnswer, loadingQuery, errorQuery, chosenAnswerID, pollStarted, currentPollId, currentQuestion, endOfPoll } =
        usePoll();

    // LOADING
    if (loadingQuery) {
        return (
            <PollLayout admin={admin}>
                <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <FullPageSpinner />
                </div>
            </PollLayout>
        );
    }

    /**
     * Error || no poll active
     */
    if (errorQuery || !pollArray) {
        return (
            <PollLayout hideLogo admin={admin}>
                <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography
                        sx={{
                            fontSize: "2rem !important", // Adjust font size as needed
                            fontWeight: 700, // Adjust font weight as needed
                            textAlign: "center", // Center align the text
                        }}
                    >
                        Er ging iets mis
                    </Typography>
                </div>
            </PollLayout>
        );
    }

    if (admin) {
        return <PollAdminPage admin />;
    }

    /**
     * SHOW LOBBY TO USERS EXCEPT ADMIN
     */
    if (!pollStarted && !admin) {
        return <Lobby endOfPoll={endOfPoll} />;
    }

    if (endOfPoll) {
        return <Lobby endOfPoll={endOfPoll} />;
    }

    return (
        <PollLayout admin={admin}>
            <Grid container spacing={2}>
                {/* QUESTION */}
                <Grid item xs={12} sx={{ margin: "8px", display: "flex", flexDirection: "column", flex: 1 }}>
                    <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography align="center" variant="h1">
                            {currentQuestion?.activeTendenzQuestion?.text}
                        </Typography>
                    </div>
                </Grid>

                <Grid item container spacing={1} sx={{ margin: "16px 8px 16px 0px", alignSelf: "end" }}>
                    {/*
                     * Waiting screen
                     */}
                    {waiting ? (
                        // Waiting screen if waiting is true
                        // => True by default and true when a user refreshes page
                        <WaitingScreen currentQuestionId={currentQuestion?.activeTendenzQuestion?.id as number} userAnswerId={chosenAnswerID} />
                    ) : (
                        <>
                            {/* Show question when waiting is not true */}
                            {currentQuestion?.activeTendenzQuestion?.answers?.map((ans, i) => {
                                if (ans === null) return <></>;
                                return (
                                    // {/* ANSWERS */}
                                    <Grid key={ans.id} item xs={12} md={6}>
                                        <AnswerCard
                                            answer={ans.text as string}
                                            index={i}
                                            onClickAnswer={() => {
                                                sendingAnswer(
                                                    // question id
                                                    currentQuestion?.activeTendenzQuestion?.id?.toString() as string,
                                                    // answer id
                                                    ans.id?.toString() as string,
                                                    // quiz id
                                                    currentPollId.toString()
                                                );
                                            }}
                                        />
                                    </Grid>
                                );
                            })}
                        </>
                    )}
                </Grid>
            </Grid>
        </PollLayout>
    );
};

export default PollDetailPage;
