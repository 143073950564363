/* eslint-disable no-loop-func */
import React, { useState } from "react";
import moment from "moment";
import "./calendar.scss";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MatomoEvent from "../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { SavedTenderEvents_getCalendarEvents } from "../../__generated__/SavedTenderEvents";
import TenderCalendarEvent from "./TenderCalendarEvent";
import UserCalendarEvent from "./UserCalendarEvent";
import TenderDateEvent from "./TenderDateEvent";

interface Props {
    currentMonth: string;
    selectedDate: string;
    data: (SavedTenderEvents_getCalendarEvents | null)[] | undefined;
}

const WeekViewMobile: React.FC<Props> = ({ currentMonth, selectedDate, data }) => {
    const { trackEvent } = useMatomo();
    const [activeTooltip, setActiveTooltip] = useState<string | false>(false);
    // Track event
    const trackCalendarToTender = (event: MatomoEvent) => {
        trackEvent(event);
    };

    //navigate to detailpage
    const setId = (id: string) => {
        // TODO: add navigate() to  detailpage
        trackCalendarToTender({ category: "Calendar", action: "Go-from-calendar-to-tender", value: parseInt(id) });
    };

    return (
        <div>
            <div className="calendar">
                <Days />
                <div style={{ height: "70vh", overflow: "auto" }}>
                    <Cells
                        currentMonth={currentMonth}
                        selectedDate={selectedDate}
                        setId={setId}
                        data={data}
                        setActiveTooltip={setActiveTooltip}
                        activeTooltip={activeTooltip}
                    />
                </div>
            </div>
        </div>
    );
};

// Create small bar with names of each day
const Days = ({ currentMonth }: any) => {
    return (
        <div className="days row" style={{ paddingLeft: 36 }}>
            {
                // eslint-disable-next-line prefer-spread
                Array.apply(null, Array(7)).map(function (_, i) {
                    return (
                        <React.Fragment key={i}>
                            {i < 5 && (
                                <div className="col col-center">
                                    <Typography style={{ fontSize: "100%" }}>
                                        {moment(i, "e")
                                            .startOf("week")
                                            .isoWeekday(i + 1)
                                            .format("ddd")}
                                    </Typography>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })
            }
        </div>
    );
};

// create cell for each day in a week
const Cells = ({ currentMonth, selectedDate, setId, data, setActiveTooltip, activeTooltip }: any) => {
    const monthStart = moment(currentMonth).startOf("week").format();
    const monthEnd = moment(monthStart).endOf("week").format();
    const startDate = moment(monthStart).startOf("week").format();
    const endDate = moment(monthEnd).endOf("week").format();
    const [openId, setOpenId] = useState<string>("");

    const rows = [];

    let days = [];
    let day = startDate;

    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            let dateValue = "";
            dateValue = moment(day).format();
            days.push(
                <React.Fragment>
                    {i < 5 && (
                        <div
                            style={{ height: "100%" }}
                            className={`col cell ${
                                !moment(day).isSame(currentMonth, "week")
                                    ? "disabled"
                                    : moment(day).isSame(selectedDate)
                                    ? "selected"
                                    : moment().isSame(day, "day")
                                    ? "selected"
                                    : ""
                            }`}
                            key={day}
                        >
                            {data
                                .filter((i: any) => i.timeSignificant === false)
                                .map((item: SavedTenderEvents_getCalendarEvents, index: number) => {
                                    // TenderEvent component
                                    if (
                                        item.__typename === "TenderEvent" &&
                                        item.timeSignificant === false &&
                                        moment(item.planned).isSame(dateValue, "day")
                                    ) {
                                        return (
                                            <div
                                                key={item.id}
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 96,
                                                    top: index * 24,
                                                    marginTop: 4,
                                                    // left: index * 8,
                                                    // border: "1px solid white",
                                                    backgroundColor: "#465c84",
                                                    // height: "100%",
                                                }}
                                            >
                                                <TenderCalendarEvent
                                                    event={item}
                                                    setActiveTooltip={setActiveTooltip}
                                                    setId={setId}
                                                    activeTooltip={activeTooltip}
                                                    margin={"0px"}
                                                    openId={openId}
                                                    setOpenId={setOpenId}
                                                />
                                            </div>
                                        );
                                    }

                                    return <></>;
                                })}
                            {/* TODO: Create component for single item */}
                            <div style={{ width: "100%", height: "100%" }}>
                                <Time currentMonth={day} selectedDate={selectedDate} setId={setId} data={data} />
                            </div>
                        </div>
                    )}
                </React.Fragment>
            );
            day = moment(day).clone().add(1, "d").format();
        }

        rows.push(<div className="row">{days}</div>);

        days = [];
    }
    return (
        <React.Fragment>
            <div style={{ display: "flex" }}>
                <div style={{ width: "10%", borderRight: "1px solid #eee" }}>
                    <div>
                        {/* TODO: Create component for single item */}
                        <div style={{ width: "100%", height: "100%", marginTop: -12 }}>
                            <Hours />
                        </div>
                    </div>
                </div>

                <div className="body" style={{ width: "90%" }}>
                    {rows}
                </div>
            </div>
        </React.Fragment>
    );
};

// Create item for every hour in a day
// TODO: get item at the right date and time
// Option to get data in day then in timeframe instead of timeframe directly
const Time = ({ currentMonth, setId, data, openId, setOpenId }: any) => {
    const startDay = moment(currentMonth).startOf("day").format();

    const hours = [];
    let day = startDay;
    let dateValue = "";
    const [activeTooltip, setActiveTooltip] = useState<string | false>(false);
    for (let i = 0; i < 24; i++) {
        dateValue = moment(day).format();
        const formattedDate = moment(day).format("HH");

        hours.push(
            <div id={i.toString()} style={{ height: "75px", width: "100%", position: "relative", borderBottom: "1px solid #eee" }} key={day}>
                {/* TODO: Create component for single item */}
                {moment().isSame(day, "hour") && <span className="today">{formattedDate}</span>}

                <div style={{ width: "100%", height: "100%", paddingTop: "16px", overflow: "auto" }}>
                    <div style={{ zIndex: 95, position: "relative" }}>
                        {data.map((item: SavedTenderEvents_getCalendarEvents, index: number) => {
                            // TenderEvent component
                            if (
                                item.__typename === "TenderEvent" &&
                                item.timeSignificant === true &&
                                moment(item.planned).isSame(dateValue, "hours")
                            ) {
                                return (
                                    <TenderCalendarEvent
                                        openId={openId}
                                        setOpenId={setOpenId}
                                        key={item.id}
                                        event={item}
                                        setActiveTooltip={setActiveTooltip}
                                        setId={setId}
                                        activeTooltip={activeTooltip}
                                    />
                                );
                            }

                            /**
                             * Tender date - deadline / start / end
                             */
                            if (item.__typename === "TenderDate" && moment(item.planned).isSame(dateValue, "hours")) {
                                return (
                                    <TenderDateEvent
                                        openId={openId}
                                        setOpenId={setOpenId}
                                        key={item.id}
                                        event={item}
                                        setActiveTooltip={setActiveTooltip}
                                        setId={setId}
                                        activeTooltip={activeTooltip}
                                    />
                                );
                            }
                            // UserEvent component
                            if (item.__typename === "UserEvent" && moment(item.start).isSame(dateValue, "hours")) {
                                const id = `${item.title}${index}`;
                                return (
                                    <UserCalendarEvent
                                        key={id}
                                        id={id}
                                        event={item}
                                        setActiveTooltip={setActiveTooltip}
                                        setId={setId}
                                        activeTooltip={activeTooltip}
                                    />
                                );
                            }

                            return <></>;
                        })}
                    </div>
                </div>
            </div>
        );
        day = moment(day).clone().add(1, "h").format();
    }
    // useEffect(() => {
    //     // find div with id 13 and center in view
    //     document.getElementById("12")?.scrollIntoView({
    //         block: "center",
    //     });
    // });
    return <div className="body">{hours}</div>;
};

const Hours = ({ currentMonth }: any) => {
    const startDay = moment(currentMonth).startOf("day").format();
    const hours = [];
    let day = startDay;
    let formattedHours = "";
    const { t } = useTranslation();

    for (let i = 0; i < 24; i++) {
        formattedHours = moment(day).format("HH");

        hours.push(
            <div style={{ height: "75px", width: "100%", position: "relative" }} key={day}>
                <span
                    style={{
                        fontSize: "82.5%",
                        fontWeight: 700,
                        marginLeft: "8px",
                        color: moment().isAfter(day, "hour") ? "rgb(216, 216, 216)" : moment().isSame(day, "hour") ? "#04325e" : "#000",
                    }}
                >
                    {formattedHours}
                    {t("Calendar.hour")}
                </span>
            </div>
        );
        day = moment(day).clone().add(1, "h").format();
    }

    return <div className="body">{hours}</div>;
};
export default WeekViewMobile;
