import React from "react";
import { Popover, MenuList, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// import { Share } from "@mui/icons-material";

interface Props {
    openMenu: (item: string) => void;
    menuText: string;
}

const TaskMenu: React.FC<Props> = ({ openMenu, menuText }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    /**
     *
     * @param event Open popover underneath button
     */
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    /**
     * close popover
     */
    const handleClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    return (
        <React.Fragment>
            <Typography
                sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    paddingLeft: "4px",
                    marginLeft: "-4px",
                    fontSize: "1.1rem",

                    "&:hover": {
                        backgroundColor: "#ececec",
                        borderRadius: "4px",
                    },
                }}
                variant="h6"
                onClick={(e) => {
                    handleClick(e);
                }}
            >
                {menuText} <ArrowDropDownIcon />
            </Typography>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                    handleClose(e);
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuList autoFocusItem={open} style={{ padding: 0 }}>
                    <MenuItem
                        selected={menuText === "Taken"}
                        onClick={(e) => {
                            openMenu("Taken");
                            handleClose(e);
                        }}
                    >
                        {/* <ListItemIcon style={{ minWidth: 24 }} /> */}
                        <Typography style={{ padding: "4px 8px" }}>Taken</Typography>
                    </MenuItem>

                    <MenuItem
                        selected={menuText === "Mijn taken"}
                        onClick={(e) => {
                            openMenu("Mijn taken");
                            handleClose(e);
                        }}
                    >
                        {/* <ListItemIcon style={{ minWidth: 24 }}>
                            <LocalOfferIcon fontSize="small" />
                        </ListItemIcon> */}
                        <Typography style={{ padding: "4px 8px" }}>Mijn taken</Typography>
                    </MenuItem>

                    <MenuItem
                        selected={menuText === "Verwijderde taken"}
                        onClick={(e) => {
                            openMenu("Verwijderde taken");
                            handleClose(e);
                        }}
                    >
                        {/* <ListItemIcon style={{ minWidth: 24 }}>
                            <FindInPageIcon fontSize="small" />
                        </ListItemIcon> */}
                        <Typography style={{ padding: "4px 8px" }}>Verwijderde taken</Typography>
                    </MenuItem>
                </MenuList>
            </Popover>
        </React.Fragment>
    );
};

export default TaskMenu;
