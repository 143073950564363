import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import LotStringField from "../../../components/LotStringField";
import {
    GetContractOfTenderOrLotsNewFormat,
    GetContractOfTenderOrLotsNewFormatVariables,
} from "../../../__generated__/GetContractOfTenderOrLotsNewFormat";
import { GET_CONTRACT_OF_TENDER_OR_LOTS_NEW_FORMAT } from "../../../graphql/queryDefinitions";

interface Props {
    tender_id: string;
}

const SplitMetaOptionsNewFormat: React.FC<Props> = ({ tender_id }) => {
    const { t } = useTranslation();

    /**
     * @param content Variable content to show
     * @returns Component
     */
    const renderPage = (content: React.ReactNode) => <div>{content}</div>;

    /**
     * Get dates
     */
    const { data, loading, error } = useQuery<GetContractOfTenderOrLotsNewFormat, GetContractOfTenderOrLotsNewFormatVariables>(
        GET_CONTRACT_OF_TENDER_OR_LOTS_NEW_FORMAT,
        {
            variables: { id: tender_id },
            fetchPolicy: "network-only",
        }
    );

    /**
     * loader
     */
    if (loading) {
        return renderPage(
            <div>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("tenderPage.Options")}
                </Typography>
            </div>
        );
    }

    /**
     * check if error occured or no data is available
     */
    if (data === undefined || data.tender === null || error) {
        return renderPage(
            <>
                <Typography variant="h4" style={{ display: "flex", alignItems: "center" }}>
                    {t("tenderPage.Options")}
                </Typography>
                <Typography>-</Typography>
            </>
        );
    }

    const tndr = data.tender;

    /**
     * Return default version where data of tender object will be used instead of lots object
     */
    return renderPage(
        <div>
            <Typography variant="h4">{t("tenderPage.Options")}</Typography>

            <LotStringField data={tndr.options} whiteSpace="nowrap" noDataSign="-" />
        </div>
    );
};

export default SplitMetaOptionsNewFormat;
