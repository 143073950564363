import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    type: string | null;
    colored: boolean;
    hover?: boolean;
}

const StatusChip: React.FC<Props> = ({ type, colored, hover }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {(() => {
                /**
                 * Switch contains all states a tender/organization could have, for each state a different component.
                 */
                switch (type) {
                    ////////////////////
                    // TENDER STATES //
                    //////////////////

                    case "PRELIMINARY":
                        //  green
                        return (
                            <Chip
                                size="small"
                                label={t("Status.Preliminary")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "green" : "#9e9e9e",
                                }}
                            />
                        );

                    case "OPEN":
                        // green
                        return (
                            <Chip
                                size="small"
                                label={t("Status.Open")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "green" : "#9e9e9e",
                                }}
                            />
                        );

                    case "EXPIRED":
                        // yellow
                        return (
                            <Chip
                                size="small"
                                label={t("Status.Expired")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "#d2d211" : "#9e9e9e",
                                }}
                            />
                        );

                    case "PARTICIPANTS_KNOWN":
                        // Orange
                        return (
                            <Chip
                                size="small"
                                label={t("Status.ParticipantsKnown")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "orange" : "#9e9e9e",
                                }}
                            />
                        );

                    case "PRELIMINARY_AWARDED":
                        // red
                        return (
                            <Chip
                                size="small"
                                label={t("Status.PreliminaryAwarded")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "red" : "#9e9e9e",
                                }}
                            />
                        );

                    case "AWARDED":
                        // red
                        return (
                            <Chip
                                size="small"
                                label={t("Status.Awarded")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "red" : "#9e9e9e",
                                }}
                            />
                        );

                    case "PROCUREMENT_DISCONTINUED":
                        // Element has tooltip with explanation
                        // red, red crossed
                        return (
                            <Tooltip
                                enterNextDelay={100}
                                placement="top-start"
                                title={
                                    <React.Fragment>
                                        <div style={{ fontWeight: "bold" }}>{t("Status.ContextTitle")}</div>
                                        <div>{t("Status.PDcontext")}</div>
                                    </React.Fragment>
                                }
                            >
                                <Chip
                                    size="small"
                                    label={t("Status.ProcurementDiscontinued")}
                                    sx={{
                                        letterSpacing: 0.15,
                                        fontSize: "0.65rem",
                                        fontWeight: 500,
                                        color: "#ffffff",
                                        cursor: hover ? "pointer" : "auto",
                                        backgroundColor: colored ? "red" : "#9e9e9e",
                                    }}
                                />
                            </Tooltip>
                        );

                    case "PROCUREMENT_UNSUCCESSFUL":
                        // element has tooltip with explanation
                        // red
                        return (
                            <Tooltip
                                enterNextDelay={100}
                                placement="top-start"
                                title={
                                    <React.Fragment>
                                        <div style={{ fontWeight: "bold" }}>{t("Status.ContextTitle")}</div>
                                        <div>{t("Status.PUcontext")}</div>
                                    </React.Fragment>
                                }
                            >
                                <Chip
                                    size="small"
                                    label={t("Status.ProcurementUnsuccesful")}
                                    sx={{
                                        letterSpacing: 0.15,
                                        fontSize: "0.65rem",
                                        fontWeight: 500,
                                        color: "#ffffff",
                                        cursor: hover ? "pointer" : "auto",
                                        backgroundColor: colored ? "red" : "#9e9e9e",
                                    }}
                                />
                            </Tooltip>
                        );

                    case "PRELIMINARY_EXPIRED":
                        // red
                        return (
                            <Chip
                                size="small"
                                label={t("Status.PreliminaryExpired")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "red" : "#9e9e9e",
                                }}
                            />
                        );

                    ///////////////////////////
                    //// bid.winner state ////
                    /////////////////////////

                    case "winner":
                        // green
                        return (
                            <Chip
                                size="small"
                                label={t("Status.bidWinner")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "green" : "#9e9e9e",
                                }}
                            />
                        );

                    case "actief":
                        // green
                        return (
                            <Chip
                                size="small"
                                label={t("Status.Active")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "green" : "#9e9e9e",
                                }}
                            />
                        );

                    case "noWinner":
                        // green
                        return (
                            <Chip
                                size="small"
                                label={t("Status.noWinner")}
                                sx={{
                                    letterSpacing: 0.15,
                                    fontSize: "0.65rem",
                                    fontWeight: 500,
                                    color: "#ffffff",
                                    cursor: hover ? "pointer" : "auto",
                                    backgroundColor: colored ? "green" : "#9e9e9e",
                                }}
                            />
                        );

                    default:
                        return null;
                }
            })()}
        </React.Fragment>
    );
};

export default StatusChip;
