import React from "react";
import { Divider, IconButton, Tooltip } from "@mui/material";

//icons in this component
import DownloadIcon from "@mui/icons-material/GetApp";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import Searchbar from "./SearchBar/Searchbar";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    handleMarkedDocs(): void;
    marked: boolean;
    tenderId: string;
    relevantAmount: number;
    onFileClick?: (folder: number) => void;
    relevantDocumentIds?: number[];
    onFileClickData?: (folder: { id: any; label: any; link: any }) => void;
}

const Search: React.FC<Props> = ({ handleMarkedDocs, marked, tenderId, relevantAmount, onFileClick, relevantDocumentIds, onFileClickData }) => {
    const { trackEvent } = useMatomo();

    // Track event
    const trackEvents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <React.Fragment>
            <div style={{ display: "flex", alignItems: "center" }}>
                {/* Searchbar with dropdownlist to show results */}
                <Searchbar
                    tenderId={tenderId}
                    onFileClick={onFileClick}
                    relevantDocumentIds={relevantDocumentIds}
                    onFileClickData={onFileClickData}
                />

                {/* div contains 2 iconbuttons
                 *  - view/hide marked documents
                 *  - download all docs
                 */}
                <div style={{ width: 96, height: 48, display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                    {/* change tooltip based on marked documents. change icon based on marked documents.*/}
                    {relevantAmount > 0 ? (
                        <Tooltip title={marked ? "Verberg gemarkeerde documenten" : "Toon gemarkeerde documenten"} placement="bottom">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    handleMarkedDocs();
                                    trackEvents({ category: "Tender", action: "Click-show-marked-docs" });
                                }}
                            >
                                {marked ? <TurnedInIcon /> : <TurnedInNotIcon />}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <div style={{ width: 24, height: 24 }} />
                    )}
                    {/* download button, */}
                    <Tooltip title="Download alle documenten" placement="bottom">
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                // Stop click on listitem to prevent document-viewer to initiate
                                e.stopPropagation();
                                trackEvents({ category: "Tender", action: "Click-download-all-files", value: parseInt(tenderId) });
                                const element = document.createElement("a");
                                element.setAttribute("href", `${process.env.REACT_APP_API_ROOT}/tenders/${tenderId}/downloadDateZip/`);
                                element.setAttribute("download", `All_docs_zip`);
                                element.style.display = "none";
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <Divider />
        </React.Fragment>
    );
};

export default Search;
