import React from "react";
import { DistributionIntervalInput, SearchDistributionInput, SearchDistributionMoment } from "../../../../__generated__/globalTypes";
import GeneralSelector from "../../../../components/input/GeneralSelector";
import { useTranslation } from "react-i18next";
import DistributionSoloOption from "../../../../components/input/DistributionSoloOption";
import { Chip, Divider, Typography } from "@mui/material";
import { useTour } from "../../../../components/contextProviders/TourContext";
import { chipTrigger } from "../TenderDist";

interface Props {
    moment: SearchDistributionMoment | null;
    setMoment: (s: SearchDistributionMoment) => void;
    dist: SearchDistributionInput;
    setDist: (d: SearchDistributionInput) => void;
    optionIsChosen: boolean;
    setChosenOption: (o: boolean) => void;
    removeMailSetting: boolean;
    setRemoveMailSetting: (b: boolean) => void;
    index: number;
}

const Distribution: React.FC<Props> = ({
    moment,
    setMoment,
    dist,
    setDist,
    optionIsChosen,
    setChosenOption,
    removeMailSetting,
    setRemoveMailSetting,
    index,
}) => {
    const { t } = useTranslation();
    const { currentTour, setCurrentStep, setLockTour } = useTour();

    return (
        <div style={{ margin: "48px 0px" }} data-tut="reactour__distOptions">
            {moment !== null && moment !== undefined ? (
                <Typography variant="h2">
                    {/* activity trigger */}
                    <span>
                        <GeneralSelector
                            openStep={false}
                            type="tender"
                            values={[
                                { value: "AT_PUBLICATION", text: t("AT_PUBLICATION") },
                                { value: "AT_AWARD", text: t("AT_AWARD") },
                                { value: "AT_CONTRACT_EXPIRATION", text: t("AT_CONTRACT_EXPIRATION") },
                            ]}
                            // set distribution moment to chosen moment
                            onSelect={(value) => {
                                setMoment(value as SearchDistributionMoment);
                            }}
                            selectedValue={t(`${moment}`) as string}
                            typoVariant="h2"
                        />
                    </span>{" "}
                    nieuwe resultaten {/* worklist trigger, if false -> dont show text in sentence */}{" "}
                    <span data-tut="reactour__distOptions-worklist-active-trigger">
                        {dist.worklist === true && (
                            <DistributionSoloOption
                                type="tender"
                                selectedValue={dist.worklist === true ? "tonen in werklijst" : ""}
                                onRemove={() => {
                                    setDist({ ...dist, worklist: false });
                                }}
                            />
                        )}
                    </span>
                    {/*
                     * Notification trigger
                     */}
                    {dist.worklist === true && !dist.email_interval && <React.Fragment> en per melding sturen</React.Fragment>}
                    {dist.worklist === true && dist.email_interval && <React.Fragment>, per melding sturen</React.Fragment>}
                    {dist.worklist === false && !dist.email_interval && <React.Fragment> per melding sturen</React.Fragment>}
                    {/*
                     *  Email trigger
                     */}
                    {dist.email_interval && (
                        <React.Fragment>
                            {dist.worklist === true && " en "}
                            {/* option to choose interval for email */}
                            <span data-tut="reactour__distOptions-email-interval">
                                <GeneralSelector
                                    openStep={optionIsChosen}
                                    dataTut="reactour__emailtrigger_interval"
                                    type="tender"
                                    values={[
                                        { value: "HOURLY", text: t("HOURLY") },
                                        { value: "DAILY", text: t("DAILY") },
                                        { value: "WEEKLY", text: t("WEEKLY") },
                                        { value: "MONTHLY", text: t("MONTHLY") },
                                    ]}
                                    onSelect={(value) => {
                                        const test = value.toUpperCase();
                                        setDist({
                                            ...dist,
                                            email_interval: test as DistributionIntervalInput,
                                        });

                                        setChosenOption(false);
                                    }}
                                    selectedValue={t(`${dist.email_interval.toUpperCase()}`) as string}
                                    typoVariant="h2"
                                />
                            </span>{" "}
                            {/* option to receive mail
                             * on remove option -> set emails to null
                             */}
                            <span data-tut="reactour__distOptions-email-cancel">
                                <DistributionSoloOption
                                    type="tender"
                                    selectedValue={dist.email_interval !== null ? "per e-mail" : ""}
                                    onRemove={() => {
                                        setDist({ ...dist, email_interval: null });
                                        setRemoveMailSetting(true);
                                        if (currentTour === "TenderWizardDistribution" && removeMailSetting === false)
                                            setTimeout(function () {
                                                setLockTour(false);
                                                setCurrentStep(6);
                                            }, 100);
                                    }}
                                />{" "}
                            </span>{" "}
                            sturen
                        </React.Fragment>
                    )}
                </Typography>
            ) : (
                /**
                 * Default sentence when no option is chosen
                 */
                <Typography variant="h2">Zodra dit gebeurt...</Typography>
            )}
            <Divider style={{ marginTop: 8 }} />
            {/* Default helper for user to know what we expect */}
            {!moment ? <Typography>Kies een van onderstaande opties</Typography> : <Typography />}

            {/*
             * Check if chosenTrigger is empty or not
             * If it is empty, show triggers (activity)
             */}
            {moment === null || moment === undefined ? (
                <div style={{ margin: 8, height: 40 }}>
                    {chipTrigger.map((item, i) => {
                        return (
                            <Chip
                                id={`chiptochoose${i}`}
                                key={i}
                                clickable
                                variant="outlined"
                                label={t(`${item.value}`) as string}
                                style={{ margin: 4 }}
                                onClick={() => {
                                    setMoment(item.value as SearchDistributionMoment);
                                    if (currentTour === "TenderWizardDistribution" && index === 2) {
                                        setTimeout(function () {
                                            // setLockTour(false);
                                            setCurrentStep(3);
                                        }, 300);
                                    }
                                }}
                            />
                        );
                    })}
                </div>
            ) : (
                /**
                 * If chosenTrigger is not empty, show send options
                 */
                <div style={{ margin: 8, height: 40 }}>
                    {dist.worklist === false && (
                        <Chip
                            clickable
                            variant="outlined"
                            label={"werklijst"}
                            style={{ margin: 4 }}
                            onClick={() => {
                                setDist({ ...dist, worklist: true });
                            }}
                        />
                    )}
                    <span data-tut="reactour__distOptions-email-add">
                        {!dist.email_interval && (
                            <Chip
                                clickable
                                variant="outlined"
                                label={"e-mail"}
                                style={{ margin: 4 }}
                                onClick={() => {
                                    setDist({ ...dist, email_interval: DistributionIntervalInput.DAILY });
                                    if (currentTour === "TenderWizardDistribution")
                                        setTimeout(function () {
                                            // setLockTour(false);
                                            setCurrentStep(7);
                                        }, 300);
                                }}
                            />
                        )}
                    </span>
                </div>
            )}
        </div>
    );
};

export default Distribution;
