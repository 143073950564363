import React from "react";
import { Theme, createStyles } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "tss-react/mui";

interface Props {
    done: number;
    totalAmount: number;
}

const BorderLinearProgress = withStyles(LinearProgress, (theme: Theme) =>
    createStyles({
        root: {
            height: "10px",
            borderRadius: "5px",
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: "#2E76FF",
        },
    })
);

const TaskProgressBar: React.FC<Props> = ({ done, totalAmount }) => {
    return (
        <React.Fragment>
            {/* Progress bar to show visual the amount of tasks that are done */}
            {totalAmount === 0 ? (
                <Box component="div" sx={{ height: "20px", display: "flex", alignItems: "center" }}>
                    <Box
                        width={"100%"}
                        component="div"
                        sx={{
                            height: "10px",
                            borderRadius: "5px",
                            backgroundColor: "#eeeeee",
                            mr: "8px",
                        }}
                    />
                    <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">{`0%`}</Typography>
                    </Box>
                </Box>
            ) : (
                <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                        <BorderLinearProgress variant="determinate" value={done} />
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">{`${Math.round(done)}%`}</Typography>
                    </Box>
                </Box>
            )}
        </React.Fragment>
    );
};

export default TaskProgressBar;
