import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
    GetAllDocsForOpportunitiesOnTenderForTabs,
    GetAllDocsForOpportunitiesOnTenderForTabsVariables,
} from "../../__generated__/GetAllDocsForOpportunitiesOnTenderForTabs";
import { QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE_TO_CREATE_TABS } from "../../graphql/opportunityQueries/queryDefinitions";
import { OpportunityFilterInput, OpportunitySearchSource } from "../../__generated__/globalTypes";
import { GetCasForOpportunityDocs_tender_clients } from "../../__generated__/GetCasForOpportunityDocs";
import WidgetBox from "../WidgetBox";
import { Disable } from "react-disable";
import { AppBar, Box, Tab, Tabs, Theme, ThemeProvider, Typography, createStyles, createTheme } from "@mui/material";

// import WidgetBoxMenu from "../WidgetBoxMenu";
// import OpportunityWidget from "../guides/tenderdetailpage/OpportunityWidget";
import OpportunityOnTenderDetail from "./OpportunityOnTenderDetail";
import { materialTheme } from "../../assets/styles";
import OpportunityReviewsOnTenderDetail from "./OpportunityReviewsOnTenderDetail";
import OpportunityWorklistOnTenderDetail from "./OpportuntiyWorklistOnTenderDetail";
import { withStyles } from "tss-react/mui";

interface Props {
    caIDS: GetCasForOpportunityDocs_tender_clients[];
}

interface TabsToShow {
    all: boolean;
    reviews: boolean;
    worklist: boolean;
}

interface StyledTabsProps {
    children: any;
    value: string;
    onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const StyledTabs = withStyles(
    (props: StyledTabsProps) => (
        <Tabs {...props} variant="scrollable" scrollButtons="auto" TabIndicatorProps={{ children: <span /> }} textColor="primary" />
    ),
    {
        root: {
            minHeight: 29,
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: 75,
                width: "100%",
                backgroundColor: "#225E4D",
            },
        },
    }
);

interface StyledTabProps {
    label: React.ReactNode;
    value: string;
}

const StyledTab = withStyles(
    (props: StyledTabProps) => <Tab disableRipple {...props} />,
    (theme: Theme) =>
        createStyles({
            root: {
                minHeight: 35,
                minWidth: 120,
                maxWidth: 120,
                padding: 0,
                textTransform: "none",
                fontSize: theme.typography.pxToRem(15),
                marginRight: theme.spacing(1),
                "&:focus": {
                    opacity: 1,
                },
            },
        })
);

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const OpportunityOnTenderDetailWrapper: React.FC<Props> = ({ caIDS }) => {
    const [searchInput, setSearchInput] = useState<OpportunityFilterInput>({});
    const [showTabs, setShowTabs] = useState<TabsToShow>({ all: false, reviews: false, worklist: false });
    const [value, setValue] = useState<string>("reviews");

    const [
        oppGuideOpen,
        // setOppGuideOpen
    ] = useState<boolean>(false);

    //submenu
    // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // const handleClickSubMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleCloseSubMenu = () => {
    //     setAnchorEl(null);
    // };

    /**
     * handler to open clicked tab
     * @param event event is change event
     * @param newValue id of lot
     */
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        const ids = caIDS.map((ca) => parseInt(ca.id));
        const uniqueChars = [...new Set(ids)];

        setSearchInput({ contracting_authorities: uniqueChars });
        // Set review as default
        if (showTabs.reviews) {
            setValue("reviews");
        }
        // Set worklist as default
        if (!showTabs.reviews && showTabs.worklist) {
            setValue("worklist");
        }
        // Set all as default
        if (!showTabs.reviews && !showTabs.worklist) {
            setValue("all");
        }
    }, [caIDS, showTabs]);

    const renderSwitch = (val: string) => {
        switch (true) {
            // all tab
            case val === "all":
                return <OpportunityOnTenderDetail caIDS={caIDS || []} oppGuideOpen={oppGuideOpen} />;
            // reviews tab
            case val === "reviews":
                return <OpportunityReviewsOnTenderDetail caIDS={caIDS || []} oppGuideOpen={oppGuideOpen} />;
            // worklist tab
            case val === "worklist":
                return <OpportunityWorklistOnTenderDetail caIDS={caIDS || []} oppGuideOpen={oppGuideOpen} />;

            // Fallback
            default:
                return <></>;
        }
    };

    /**
     * GQL query to get all opportunities, on complete
     * -> set filters to state and data to another state
     */
    const { loading: loadAll } = useQuery<GetAllDocsForOpportunitiesOnTenderForTabs, GetAllDocsForOpportunitiesOnTenderForTabsVariables>(
        QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE_TO_CREATE_TABS,
        {
            variables: {
                source: OpportunitySearchSource.ALL_OPPORTUNITY_FILES,
                search: {
                    filters: searchInput,
                },
            },
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (
                    data &&
                    data.filterOpportunityFiles &&
                    data.filterOpportunityFiles.results?.paginatorInfo?.total &&
                    data.filterOpportunityFiles.results?.paginatorInfo?.total > 0
                ) {
                    setShowTabs({
                        ...showTabs, // Copy the old fields
                        all: true,
                    });
                }
            },
        }
    );
    const { loading: loadReviews } = useQuery<GetAllDocsForOpportunitiesOnTenderForTabs, GetAllDocsForOpportunitiesOnTenderForTabsVariables>(
        QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE_TO_CREATE_TABS,
        {
            variables: {
                source: OpportunitySearchSource.MY_OPPORTUNITY_FILES,

                search: {
                    filters: searchInput,
                },
            },
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (
                    data &&
                    data.filterOpportunityFiles &&
                    data.filterOpportunityFiles.results?.paginatorInfo?.total &&
                    data.filterOpportunityFiles.results?.paginatorInfo?.total > 0
                ) {
                    setShowTabs({
                        ...showTabs, // Copy the old fields
                        reviews: true,
                    });
                }
            },
        }
    );
    const { loading: loadWorklist } = useQuery<GetAllDocsForOpportunitiesOnTenderForTabs, GetAllDocsForOpportunitiesOnTenderForTabsVariables>(
        QUERY_OPPORTUNITY_DOCUMENTS_ON_TENDER_DETAILPAGE_TO_CREATE_TABS,
        {
            variables: {
                source: OpportunitySearchSource.MY_WORKLIST,

                search: {
                    filters: searchInput,
                },
            },
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (
                    data &&
                    data.filterOpportunityFiles &&
                    data.filterOpportunityFiles.results?.paginatorInfo?.total &&
                    data.filterOpportunityFiles.results?.paginatorInfo?.total > 0
                ) {
                    setShowTabs({
                        ...showTabs, // Copy the old fields
                        worklist: true,
                    });
                }
            },
        }
    );

    if (loadAll || loadReviews || loadWorklist) {
        return (
            <Disable disabled>
                <WidgetBox loading header={"Kansen"} highlight="green" padding={0} />
            </Disable>
        );
    }

    if (!showTabs.all && !showTabs.reviews && !showTabs.worklist) {
        return (
            <Disable disabled>
                <WidgetBox loading={false} header={"Kansen"} highlight="green" padding={0} />
            </Disable>
        );
    }

    const getMuiTheme = (theme: Theme) =>
        createTheme({
            palette: {
                ...theme.palette,
                primary: {
                    main: materialTheme.moduleColors?.green || materialTheme.palette.primary.main,
                    light: "#508c79" || materialTheme.palette.primary.light,
                },
                secondary: {
                    main: "#003425" || materialTheme.palette.secondary.main,
                },
            },
            typography: {
                fontSize: 14,
                fontFamily: "Maven Pro, system-ui, sans-serif, Material Icons",
                h1: {
                    fontSize: "1.8rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.4rem",
                    },
                },
                h2: {
                    fontSize: "1.6rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.2rem",
                    },
                },
                h3: {
                    fontSize: "1.4rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.0rem",
                    },
                },
                h4: {
                    fontSize: "0.8rem",
                    spacing: "0.06em",
                    "@media (max-width: 800px)": {
                        fontSize: "0.7rem",
                    },
                },
                h5: {
                    fontSize: "1.2rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.9rem",
                    },
                },
                h6: {
                    fontSize: "0.95rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
                body1: {
                    fontSize: "0.875rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
            },
            components: {
                // MUIDataTable: {
                //     tableRoot: {
                //         width: "100% !important",
                //     },
                // },

                // Hide toolbar
                // MuiToolbar: {
                //     styleOverrides: {
                //         root: {
                //             display: "none !important",
                //         },
                //     },
                // },

                // Custom hovercolor: opacity 7% -> add 12 to hexcode
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            "&:hover": {
                                backgroundColor: `${"#173357"}12 !important`,
                            },
                        },
                    },
                },
                MuiTableHead: {
                    styleOverrides: {
                        root: {
                            textAlign: "start",
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            padding: 8,
                        },
                    },
                },
                // MUIDataTableHeadCell: {
                //     data: {
                //         textAlign: "start",
                //     },
                // },
            },
        });

    return (
        <ThemeProvider theme={getMuiTheme}>
            <WidgetBox
                header={"Kansen"}
                highlight="green"
                padding={0}
                actions={
                    <></>
                    // <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    //     <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClickSubMenu}>
                    //         <OpportunityWidget
                    //             widgetActive={false}
                    //             onClose={() => {
                    //                 handleCloseSubMenu();
                    //                 setOppGuideOpen(true);
                    //             }}
                    //         />
                    //     </WidgetBoxMenu>
                    // </div>
                }
            >
                <AppBar elevation={0} position="static" color="transparent">
                    <StyledTabs value={value} onChange={handleChange}>
                        {showTabs.all && <StyledTab value={"all"} label={"Alle"} />}
                        {showTabs.reviews && <StyledTab value={"reviews"} label={"Beoordelingen"} />}
                        {showTabs.worklist && <StyledTab value={"worklist"} label={"Werklijst"} />}
                    </StyledTabs>
                </AppBar>
                <TabPanel index={value} value={value}>
                    {renderSwitch(value)}
                </TabPanel>
            </WidgetBox>
        </ThemeProvider>
    );
};

export default OpportunityOnTenderDetailWrapper;
