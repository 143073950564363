import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { Check, Clear } from "@mui/icons-material";
import { IconButton, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    columnName: string;
    setColumnName(name: string): void;
    editMode: boolean;
    amount: number;
    setEditMode(editMode: boolean): void;
}

const ColumnName: React.FC<Props> = ({ columnName, setColumnName, editMode, setEditMode, amount }) => {
    const { t } = useTranslation();
    const [name, setName] = useState(columnName);

    useEffect(() => {
        if (columnName === "") {
            setEditMode(true);
        }
    }, [setEditMode, columnName]);

    const saveName = () => {
        setColumnName(name);
        setEditMode(false);
    };

    const cancelName = () => {
        setColumnName(columnName);
        setEditMode(false);
        setName(columnName);
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
            {columnName === "New" || columnName === "Nieuw" ? (
                <Box
                    sx={{
                        padding: "10.5px 14px 10.5px 14px",
                        height: "37.63px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {amount === 0 && columnName !== "New" ? (
                        <Typography sx={{ display: "flex", width: "100%" }}>
                            <span style={{ overflow: "auto", width: "80%" }}>{columnName}</span>
                            <span style={{ width: "20%", marginLeft: "4px" }}>({amount})</span>
                        </Typography>
                    ) : (
                        <Typography>
                            {columnName === "New" ? t("New") : columnName} ({amount})
                        </Typography>
                    )}
                </Box>
            ) : editMode ? (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <TextField
                        placeholder="Voer een kolomnaam in"
                        autoFocus
                        name={"function"}
                        label={``}
                        variant={"outlined"}
                        fullWidth
                        size={"small"}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />

                    {columnName === "" && (
                        <React.Fragment>
                            <IconButton size="small" disabled={name === "" || name === "New" || name === "Nieuw"} onClick={() => setColumnName(name)}>
                                <Check />
                            </IconButton>
                            <IconButton size="small" onClick={() => setEditMode(false)}>
                                <Clear />
                            </IconButton>
                        </React.Fragment>
                    )}
                    {columnName !== "" && (
                        <React.Fragment>
                            <IconButton size="small" onClick={saveName} disabled={columnName === "" || name === "New" || name === "Nieuw"}>
                                <Check />
                            </IconButton>
                            <IconButton size="small" onClick={cancelName}>
                                <Clear />
                            </IconButton>
                        </React.Fragment>
                    )}
                </div>
            ) : (
                <Box
                    onClick={() => setEditMode(true)}
                    sx={{
                        padding: "10.5px 14px 10.5px 14px",
                        height: "37.63px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: "#f4f4f4",
                            cursor: "pointer",
                            borderRadius: "4px",
                            width: "100%",
                        },
                    }}
                >
                    {amount === 0 ? (
                        <Typography sx={{ display: "flex", width: "100%", whiteSpace: "nowrap", overflow: "hidden" }}>
                            <Typography style={{ width: "80%", overflow: "hidden", textOverflow: "ellipsis" }}>{columnName}</Typography>
                            <Typography style={{ width: "20%", marginLeft: "4px" }}>({amount})</Typography>
                        </Typography>
                    ) : (
                        <Typography>
                            {columnName} ({amount})
                        </Typography>
                    )}
                </Box>
            )}
        </div>
    );
};
export default ColumnName;
