import React from "react";
import { Paper, Skeleton } from "@mui/material";

interface Props {
    a?: number;
}

const KnowledgeBaseItemLoader: React.FC<Props> = (props) => {
    return (
        <Paper
            sx={{
                padding: "8px 16px 8px 16px",
                margin: "0px 16px 8px 16px",
                "&:hover": {
                    cursor: "pointer",
                    margin: "0px 16px 8px 20px",
                },
            }}
            elevation={2}
        >
            <Skeleton animation="wave" height={12} width="225px" />
            <Skeleton animation="wave" height={12} width="90%" />
        </Paper>
    );
};

export default KnowledgeBaseItemLoader;
