import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { Check, Clear } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import { Disable } from "react-disable";
import TooltipOnNewLines from "../../../components/TooltipOnNewLines";

interface Props {
    tasktext: string;
    setTasktext(name: string): void;
    taskID: string;
    bossOfProject: boolean;
    disabled: boolean;
}

const EditableTaskField: React.FC<Props> = ({ taskID, tasktext, setTasktext, bossOfProject, disabled }) => {
    const [name, setName] = useState(tasktext);
    const [editMode, setEditMode] = useState<boolean>(false);

    useEffect(() => {
        if (tasktext === "Taak...") {
            setEditMode(true);
        }
    }, [setEditMode, tasktext]);

    /**
     * Set name to new input
     * set editmode => false
     */
    const saveSetting = () => {
        setTasktext(name);
        setEditMode(false);
    };

    /**
     * Set name to new input
     * set editmode => false
     */
    const updateSetting = () => {
        setTasktext(name);
        setEditMode(false);
    };

    /**
     * reset name to last saved name
     * set editmode => false
     */
    const cancelSetting = () => {
        setName(tasktext);
        setEditMode(false);
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {/*
             * Check if editmode is active and check if user is boss of team
             * If both are true => show textfield to change a teamname
             */}
            {editMode && bossOfProject ? (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "90%" }}>
                    <TextField
                        autoFocus
                        name={"function"}
                        variant={"standard"}
                        size={"small"}
                        fullWidth
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                            style: { padding: "1px 0px" },
                        }}
                    />
                    {/* Show button to save new teamname
                     * Button will be disabled as long as teamname = "Voer een teamnaam in" & teamname is less than 3 characters
                     */}
                    {tasktext === "Taak..." && (
                        <Tooltip title="Opslaan" placement="top">
                            <IconButton size="small" disabled={name.length < 3} onClick={() => saveSetting()}>
                                <Check />
                            </IconButton>
                        </Tooltip>
                    )}

                    {/* Show button to save new teamname and a button to cancel namechange if teamname was already changed
                     * Button will be disabled as long as teamname = "Voer een teamnaam in" & teamname is less than 3 characters
                     */}
                    {tasktext !== "Taak..." && name.length > 2 && (
                        <React.Fragment>
                            <Tooltip title="Opslaan" placement="top">
                                <IconButton size="small" onClick={() => updateSetting()}>
                                    <Check fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Annuleren" placement="top">
                                <IconButton size="small" onClick={() => cancelSetting()}>
                                    <Clear fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )}
                </div>
            ) : (
                // Clickable box to activate editmode
                <div style={{ width: "90%" }}>
                    <Disable disabled={disabled}>
                        <Box
                            onClick={() => {
                                if (bossOfProject) {
                                    setEditMode(true);
                                }
                            }}
                            sx={{
                                // padding: "10.5px 14px 10.5px 14px",
                                height: "26.13px",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                "&:hover": {
                                    backgroundColor: bossOfProject ? "#f4f4f4" : "transparent",
                                    cursor: bossOfProject ? "pointer" : "default",
                                    borderRadius: bossOfProject ? "4px" : undefined,
                                    width: "100%",
                                },
                            }}
                        >
                            <TooltipOnNewLines
                                text={tasktext}
                                variant={"body1"}
                                lines={1}
                                id={`task${taskID}`}
                                fontweight={"400"}
                                fontSize={"14px"}
                                placement={"top"}
                            />
                        </Box>
                    </Disable>
                </div>
            )}
        </div>
    );
};

export default EditableTaskField;
