import React, { useState } from "react";
import TenderDetail from "../../components/tenderDetail/TenderDetail";
import FullPageSpinner from "../../components/loading/FullPageSpinner";
import RenderPage from "../../components/loading/RenderPage";
import { useQuery } from "@apollo/client";
import { DynamicTab } from "../../components/ModuleTabs";
import setTitle, { setTitleLoading } from "../../utils/setTitle";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Div404 from "../Div404";
import { GetDefaultSummary, GetDefaultSummaryVariables, GetDefaultSummary_tender } from "../../__generated__/GetDefaultSummary";
import { QUERY_DEFAULT_SUMMARY } from "../../graphql/queryDefinitions";

interface Props {
    registerTab: (tab: DynamicTab) => void;
}
export interface DefaultSummary {
    id: string;
    omschrijving: string | null;
    omvang: string | null;
    titel: string | null;
}

const TenderDetailContainer: React.FC<Props> = ({ registerTab }) => {
    const { id } = useParams();
    const tenderID = id as string;
    const { t } = useTranslation();
    const [normalTitle, setNormalTitle] = useState<string>("");
    const [tenderData, setTenderData] = useState<GetDefaultSummary_tender>();
    const [defaultSummary, setDefaultSummary] = useState<DefaultSummary>({
        id: "",
        omschrijving: "",
        omvang: "",
        titel: "",
    });

    /**
     * Default tender summary query
     */
    const { loading: loadDefault, error: errorDefault } = useQuery<GetDefaultSummary, GetDefaultSummaryVariables>(QUERY_DEFAULT_SUMMARY, {
        variables: { id: tenderID },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.tender) {
                registerTab({ value: `/tenders/${tenderID}`, label: data.tender.namegiventocontract || "" });
                setDefaultSummary({
                    id: data.tender.id || "",
                    omschrijving: data.tender.omschrijving || "",
                    omvang: data.tender.omvang_opdracht || "",
                    titel: data.tender.namegiventocontract || "",
                });
                setTenderData(data.tender);
                setNormalTitle(data.tender.namegiventocontract || "");
            }
        },
    });

    /**
     * If loading -> show fullpagespinner
     */
    if (loadDefault) {
        setTitleLoading(t);
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    /**
     * If error or no data -> show div404
     */
    if (errorDefault || !tenderData) {
        setTitle(t("error-occurred"));
        return (
            <RenderPage>
                <Div404 url="/tenders" />
            </RenderPage>
        );
    }

    /**
     * Set title in tab of browser
     */
    setTitle(normalTitle);

    return (
        <div className="responsive-container topbottom" style={{ height: "100%" }}>
            <TenderDetail data={tenderData} defaultSummary={defaultSummary} tender_id={tenderID} />
        </div>
    );
};

export default TenderDetailContainer;
