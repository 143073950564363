import React from "react";
import { Translation, useTranslation } from "react-i18next";

//Material UI components
import { createTheme, Theme, ThemeProvider, useTheme } from "@mui/material/styles";
import { Paper, Grid, MenuItem, Select, Typography, Pagination, useMediaQuery } from "@mui/material";

//Import Muidatatable
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef } from "mui-datatables";

//icons
import SearchRuleData from "../../../../../components/SearchRuleData";

// import InfoTooltip from "../../../../../components/InfoTooltip";
import SmallTenderMenu from "../../../../../components/SmallTenderMenu";

import TenderTitleWidth from "../../../../../components/customColumns/TenderTitleWidth";

import LinearLoader from "../../../../../components/loading/LinearLoader";
import { Disable } from "react-disable";
import {
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_data,
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_paginatorInfo,
} from "../../../../../__generated__/FilterTendersOverviewOnMarketPartyPageMinimum";
import EndDateOfTenderForMPColumn from "../../../../../components/columns/EndDateOfTenderForMPColumn";
import FirstPossibleEndDateTenderForMP from "../../../../../components/columns/FirstPossibleEndDateTenderForMP";
import WinnerOfTenderForMPColumn from "../../../../../components/columns/WinnerOfTenderForMPColumn";
import { QUERY_TENDERS_OF_MP_WITH_FILTER_MINIMUM } from "../../../../../graphql/queryDefinitions";
import PublishedBidValueColumn from "../../../../../components/columns/PublishedBidValueColumn";
import ScopeColumn from "../../../../../components/columns/ScopeColumn";
import AvatarReviewed from "../../../../../components/AvatarReviewed";
import MobileTableNavigation from "../../../../../components/MobileTableNavigation";

interface Props {
    bids: FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_data[];
    paginatorInfo: FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results_paginatorInfo;
    onChangePagination(page: number, rowsPerPage: number): void;
    loading: boolean;
    org_id: string;
    // onTableChange: MUIDataTableOptions["onTableChange"];
}

// TODO: TRANSLATION
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////// Column setup ///////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const columns: MUIDataTableColumnDef[] = [
    // organization id
    // index 0
    {
        name: "bid_id",
        options: { display: "excluded" },
    },
    // contracting authority
    // index 1
    {
        name: "ca_name",
        options: { display: "excluded" },
    },
    // favicon
    // index 2
    {
        name: "favicon",
        options: { display: "excluded" },
    },
    // tender id
    // index 3
    {
        name: "tender_id",
        options: { display: "excluded" },
    },

    // ca id
    // index 4
    {
        name: "ca_id",
        options: { display: "excluded" },
    },

    // state of tender
    // index 5
    {
        label: "Actions",
        name: "winner",
        options: {
            display: true,
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(tender_id: any, tableMeta) {
                // return tendertitle and second Contracting Authority.
                return <WinnerOfTenderForMPColumn tender_id={tender_id} org_id={tableMeta.rowData[0]} />;
            },
        },
    },
    // tender title
    // index 6
    {
        label: "Title",
        name: "tender_name",
        options: {
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Title")}</>}</Translation>;
            },
            display: true,
            customBodyRender(value: any, tableMeta) {
                return <TenderTitleWidth location="Organizations" tender_name={value} tender_id={tableMeta.rowData[3]} />;
            },
        },
    },

    // looptijd start
    // index 7
    {
        name: "looptijd_start",
        options: { display: "excluded" },
    },

    // scopevalue tender
    // index 8
    // {
    //     label: "",
    //     name: "",
    //     options: {
    //         display: true,
    //         customBodyRender(value: any, tableMeta) {
    //             // return tendertitle and second Contracting Authority.
    //             return (
    //                 <div style={{ paddingLeft: 8, paddingRight: 8 }}>
    //                     <Typography noWrap>Soort waarde</Typography>
    //                     <Typography noWrap>{value}</Typography>
    //                 </div>
    //             );
    //         },
    //     },
    // },

    // first possible enddate tender with tooltip to show other dates
    // index 8
    {
        label: "FirstEndDate",
        name: "next_looptijd_einde",
        options: {
            display: true,
            sort: true,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500 }}>
                                {t("Column_Header.FirstEndDate")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                // return tendertitle and second Contracting Authority.
                return <FirstPossibleEndDateTenderForMP tender_id={tender_id} org_id={tableMeta.rowData[0]} />;
            },
        },
    },

    // userStatus
    // index 9
    {
        name: "userStatus",
        options: { display: "excluded" },
    },
    // bids data + lots
    // index 10
    {
        name: "bids",
        options: {
            display: "excluded",
        },
    },
    // bids data + lots
    // index 11
    {
        name: "lots",
        options: {
            display: "excluded",
        },
    },
    // end date inc options
    // index 12
    {
        label: "Date_incl_options",
        name: "end_date",
        options: {
            display: true,
            sort: true,
            customHeadLabelRender: () => {
                return (
                    <Translation>
                        {(t) => (
                            <Typography noWrap style={{ fontWeight: 500 }}>
                                {t("Column_Header.Date_incl_options")}
                            </Typography>
                        )}
                    </Translation>
                );
            },
            customBodyRender(tender_id: any, tableMeta) {
                // return tendertitle and second Contracting Authority.
                return <EndDateOfTenderForMPColumn tender_id={tender_id} org_id={tableMeta.rowData[0]} />;
            },
        },
    },
    // Geraamde waarde
    // index xx
    {
        label: "scopeValue",
        name: "scopeValue",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.scopeValue")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <ScopeColumn tender_id={tender_id} />;
            },
        },
    },
    // bid.bid
    // index xx
    {
        label: "Bid_value",
        name: "bid",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Bid_value")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <PublishedBidValueColumn tender_id={tender_id} />;
            },
        },
    },

    // User avatars who liked tender
    // index xx
    {
        label: "Reviews",
        name: "review",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Reviews")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return (
                    <>
                        <AvatarReviewed tender_id={tender_id} />
                    </>
                );
            },
        },
    },
    // search rules label with tooltip
    // index 13
    {
        label: "Actions",
        name: "searches",
        options: {
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(data: any, tableMeta) {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <SearchRuleData placement="top" tender_id={tableMeta.rowData[3]} searches={data} />
                    </div>
                );
            },
            sort: false,
        },
    },
    // actions to like, dislike, share tender
    // index 14
    {
        label: "Actions",
        name: "",
        options: {
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(title: any, tableMeta) {
                return (
                    <SmallTenderMenu
                        id={tableMeta.rowData[3]}
                        title={tableMeta.rowData[6]}
                        userStatus={tableMeta.rowData[9]}
                        refetchQueries={[QUERY_TENDERS_OF_MP_WITH_FILTER_MINIMUM]}
                    />
                );
            },
        },
    },
];

/**
 * custom table styling
 */

const getMuiTheme = (theme: Theme) =>
    createTheme({
        palette: {
            primary: {
                main: "#173357",
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro, system-ui, sans-serif",
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },
        components: {
            // MUIDataTable: {
            //     tableRoot: {
            //         width: "100% !important",
            //     },
            // },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "none !important",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: "8px",
                    },
                },
            },
        },
    });

const OrgTenderFullTable: React.FC<Props> = ({ bids, paginatorInfo, onChangePagination, loading, org_id }) => {
    const { t } = useTranslation();
    const amountPages = Math.ceil(paginatorInfo.total / paginatorInfo.perPage);
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const options: MUIDataTableOptions = {
        responsive: "standard",
        elevation: 0,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        filterType: "checkbox",
        draggableColumns: {
            enabled: false,
            transitionTime: 200,
        },
        selectableRowsOnClick: false,
        selectToolbarPlacement: "none",
        serverSide: true,
        selectableRows: "none",

        // Textual helpers with translation
        textLabels: {
            body: {
                noMatch: t("TableHelpers.NoMatch"),
                toolTip: t("TableHelpers.Sort"),
                columnHeaderTooltip: (column) => `${t(`Column_Header.${column.label}`)}`,
            },
        },

        /**
         * custom footer
         */
        customFooter() {
            if (onlyMobile) {
                return (
                    <MobileTableNavigation
                        perPage={paginatorInfo.perPage}
                        total={paginatorInfo.total}
                        firstItemIndex={paginatorInfo.firstItem as number}
                        lastItemIndex={paginatorInfo.lastItem as number}
                        currentPage={paginatorInfo.currentPage}
                        onChangePagination={onChangePagination}
                    />
                );
            }
            return (
                <Grid container spacing={2} style={{ padding: 20 }} alignItems="center" justifyContent="space-between">
                    {/* Left section
                     *  number of total results found
                     */}
                    <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                        {paginatorInfo.total === 0 ? 0 : paginatorInfo?.firstItem?.toLocaleString()}-{paginatorInfo?.lastItem?.toLocaleString()}{" "}
                        {t("of")} {paginatorInfo?.total.toLocaleString()}
                    </Grid>

                    {/* Middle section,
                     *  Show amount of pages with back/next arrow
                     */}
                    <Grid item sm={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Pagination
                            page={paginatorInfo.currentPage}
                            count={amountPages}
                            onChange={(_, page) => onChangePagination(page, paginatorInfo.perPage)}
                        />
                    </Grid>

                    {/* Right section
                     *  Selectable field with option to show more/less rows per page
                     */}
                    <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <Select
                            variant="standard"
                            value={paginatorInfo.perPage}
                            onChange={(e) => onChangePagination(paginatorInfo.currentPage, e.target.value as number)}
                            style={{ marginRight: 4 }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>

                        {t("resultsPerPage")}
                    </Grid>
                </Grid>
            );
        },
    };

    /**
     * data reformat
     */

    if (bids !== null) {
        // create copy of dataset
        const data = [...bids];
        const convertedData = data.map((item) => {
            const container: any = {};
            const tender_name = "tender_name";
            const bid_id = "bid_id";
            const tender_id = "tender_id";
            const winner = "winner";
            const ca_name = "ca_name";
            const ca_id = "ca_id";
            const looptijd_start = "looptijd_start";
            const next_looptijd_einde = "next_looptijd_einde";
            // const primaryCA = item.clientA ? item.clientA[0] : null;
            const userStatus = "userStatus";
            const favicon = "favicon";
            const searches = "searches";
            const bids = "bids";
            const lots = "lots";
            const end_date = "end_date";
            const scopeValue = "scopeValue";
            const bid = "bid";
            const review = "review";

            container[bid_id] = org_id;
            container[tender_id] = item?.id;
            container[ca_name] = "";
            container[ca_id] = "";
            container[favicon] = "";
            container[searches] = item.searchresults.map((i: any) => i);
            container[tender_name] = item?.namegiventocontract;
            container[bids] = item.id;
            container[winner] = item.id;
            container[looptijd_start] = item.id;
            container[lots] = item.id;
            container[next_looptijd_einde] = item.id;
            container[end_date] = item.id;
            container[userStatus] = item.userStatus;
            container[scopeValue] = item.id;
            container[bid] = item.id;
            container[review] = item.id;

            return container;
        });
        return (
            <React.Fragment>
                <LinearLoader loading={loading} color="blue" />
                <Disable disabledOpacity={0.6} disabled={loading}>
                    <Paper square elevation={0}>
                        {/* Added paper element around table to use the square variant instead of the default, 
            - in MuidatatableOptons: elevation -> 0 */}
                        <ThemeProvider theme={getMuiTheme}>
                            <MUIDataTable title="Alle aanbestedingen" data={convertedData} columns={columns} options={options} />
                        </ThemeProvider>
                    </Paper>
                </Disable>
            </React.Fragment>
        );
    }
    return null;
};
export default OrgTenderFullTable;
