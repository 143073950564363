import React, { useRef, useState } from "react";
import { Grid, InputBase, Paper, useMediaQuery, useTheme } from "@mui/material";
import Vcard from "../../../../components/Vcard";
import { Search } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import {
    GetCADetailsContacts,
    GetCADetailsContactsVariables,
    GetCADetailsContacts_contracting_authority_employees,
} from "../../../../__generated__/GetCADetailsContacts";
import { QUERY_CONTACTS } from "../../../../graphql/queryDefCA";

interface Props {
    id: string;
}

const ContactModule: React.FC<Props> = ({ id }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [contacts, setContacts] = useState<GetCADetailsContacts_contracting_authority_employees[] | null | undefined>([]);
    const inputRef = useRef();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useQuery<GetCADetailsContacts, GetCADetailsContactsVariables>(QUERY_CONTACTS, {
        variables: { id: id },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setContacts(data.contracting_authority?.employees);
        },
    });

    if (contacts === null || contacts === undefined) {
        return <></>;
    }

    /**
     * handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */
    const handleSearchUser = (event: any) => {
        const {
            target: { value },
        } = event;

        setSearchTerm(value);
    };

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and first_name or last_name
     */
    const results = !searchTerm
        ? contacts
        : contacts.filter(
              (item: any) =>
                  (item.givenname && item.givenname.toLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
                  (item.familyname && item.familyname.toLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
                  (item.function && item.function.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
          );

    return (
        <React.Fragment>
            {/*
             * Searchbox to type query
             * onSearch -> start search with typed query
             */}
            <div style={{ padding: onlyMobile ? "0px 16px" : 0 }}>
                <Paper
                    square
                    component="form"
                    sx={{
                        padding: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: onlyMobile ? "100%" : "438px",
                        marginLeft: onlyMobile ? 0 : "12px",
                        marginTop: "12px",
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        // onSearch();
                    }}
                >
                    {/* Searchicon */}
                    <Search style={{ margin: "10px 0px 10px 6px", color: "grey" }} />
                    {/* Inputfield query */}
                    <InputBase
                        ref={inputRef}
                        sx={{ marginLeft: (theme) => theme.spacing(1), flex: 1 }}
                        placeholder="Zoeken"
                        autoFocus
                        value={searchTerm}
                        onChange={handleSearchUser}
                    />
                </Paper>
            </div>
            <div style={{ padding: 16 }} />
            <div style={{ height: 500, width: "100%", overflow: "auto" }}>
                <Grid container xs={12} sx={{ display: "flex", flexDirection: "row" }}>
                    {[...results]
                        .sort(
                            (a: any, b: any) =>
                                (a.linkedin || "zzz").localeCompare(b.linkedin || "zzz") ||
                                (a.givenname || "zzz").localeCompare(b.givenname || "zzz") ||
                                (a.email || "zzz").localeCompare(b.email || "zzz")
                        )

                        // .sort(function (a, b) {
                        //     return (a.givenname || "zzz").localeCompare(b.givenname || "zzz");
                        // })
                        .map((person) => {
                            return (
                                <Grid item key={person.id} style={{ margin: 12 }}>
                                    <Vcard
                                        organization={person.organization.name || ""}
                                        showLabel={true}
                                        social={person.linkedin || ""}
                                        phone={person.phonenumber || ""}
                                        mobile={person.cellphone || ""}
                                        first_name={person.givenname || ""}
                                        last_name={person.familyname || ""}
                                        middle_name={person.middlename || ""}
                                        full_name={
                                            `${person.name}` ||
                                            (person.givenname !== null && person.familyname !== null && `${person.givenname} ${person.familyname}`) ||
                                            ""
                                        }
                                        role={person.function || ""}
                                        mail={person.email || ""}
                                        cardVariant={"elevation"}
                                        cardElevation={1}
                                        nameStyle={"h6"}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default ContactModule;
