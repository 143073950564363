// WordModalGuide
import React, { useLayoutEffect } from "react";
import { Button, Chip, IconButton, Typography } from "@mui/material";
import { Assistant, Cancel } from "@mui/icons-material";
import GuideContent from "../elements/GuideContent";
import { useTour } from "../../contextProviders/TourContext";
import { useFirstSearchRuleSetter } from "../../contextProviders/FirstSearchRuleContext";

interface Props {
    hasSearchrules: boolean;
}

/**
 * @param step CurrentStep in tender searchwizard
 * @returns Returns current step as title and a button to open guide/tour
 */
const DistModalGuide: React.FC<Props> = ({ hasSearchrules }) => {
    //accent color
    const blue = "#173357";
    // first searchrule global state
    const { distModalTourOpen, setDistModalTourOpen } = useFirstSearchRuleSetter();
    // tour variables to set
    // locktour => true means item is interactive and no next/prev button will be shown
    const { setTourVariables, setOpenTour, setLockTour } = useTour();

    /**
     * SPLIT IN 2 COMPONENTS
     * BUTTON
     * TOUR TO START
     */
    const steps = () => {
        return [
            /**
             * Step 1: Results
             */
            {
                selector: '[data-tut="reactour__distTitle"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Naam zoekregel invoeren"
                            content={<span>Is een aanbesteding een resultaat van deze zoekregel, dan wordt dit met de gekozen naam aangeduid.</span>}
                        />
                    );
                },
                action: (n: any) => {
                    n.focus();
                    setLockTour(true);
                },
                stepInteraction: true,
            },

            /**
             * Step 2: color
             */
            {
                selector: '[data-tut="reactour__distColor"]',
                content: () => {
                    return <GuideContent title="Kies een kleur" content={<span>Je kunt zelf een kleur kiezen voor de zoekregel</span>} />;
                },
                action: (n: any) => {
                    n.focus();
                    setLockTour(true);
                },
                stepInteraction: true,
            },

            /**
             * Step 3: options
             */
            {
                selector: '[data-tut="reactour__distOptions"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Kies moment"
                            content={
                                <>
                                    <span>
                                        Kies het moment wanneer een aanbesteding een resultaat moet zijn van je zoekregel.
                                        <br />
                                        <br />
                                        Je kunt kiezen uit: <br />
                                    </span>

                                    <div style={{ margin: 8 }}>
                                        <Chip variant="outlined" size="small" label="Na aankondiging aanbesteding" />
                                    </div>

                                    <div style={{ margin: 8 }}>
                                        <Chip variant="outlined" size="small" label="Zodra gunningsinformatie bekend is" />
                                    </div>

                                    <div style={{ margin: 8 }}>
                                        <Chip variant="outlined" size="small" label="Zodra het contract afloopt" />
                                    </div>
                                </>
                            }
                        />
                    );
                },
                action: (n: any) => {
                    n.focus();
                    setLockTour(true);
                    // const btn0 = document.getElementById("chiptochoose0");
                    // const btn1 = document.getElementById("chiptochoose1");
                    // const btn2 = document.getElementById("chiptochoose2");

                    /**
                     * find clicked button and go to next step
                     */
                    // if (btn0) {
                    //     btn0.onclick = () => {
                    //         setTimeout(function () {
                    //             setLockTour(false);
                    //             setCurrentStep(3);
                    //         }, 100);
                    //     };
                    // }
                    // if (btn1) {
                    //     btn1.onclick = () => {
                    //         setTimeout(function () {
                    //             setLockTour(false);
                    //             setCurrentStep(3);
                    //         }, 100);
                    //     };
                    // }
                    // if (btn2) {
                    //     btn2.onclick = () => {
                    //         setTimeout(function () {
                    //             setLockTour(false);
                    //             setCurrentStep(3);
                    //         }, 100);
                    //     };
                    // }
                },
                stepInteraction: true,
            },

            /**
             * Step 4: interval change
             */
            {
                selector: '[data-tut="reactour__emailtrigger_interval"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Interval aanpassen"
                            content={
                                <span>
                                    <span>
                                        De interval kan worden veranderd door op{" "}
                                        <span>
                                            {" "}
                                            <Typography sx={{ color: "#465c84", fontWeight: 600, display: "inline-flex" }}>dagelijks</Typography>{" "}
                                        </span>{" "}
                                        te klikken
                                    </span>
                                </span>
                            }
                        />
                    );
                },
                action: (n: any) => {
                    n.focus();
                    setLockTour(true);
                },
                stepInteraction: true,
            },

            /**
             * Step 5: interval change
             */
            {
                selector: '[data-tut="distribution_container"]',
                content: () => {
                    return <GuideContent title="Moment van e-mail" content={<span>Kies één van de vier opties.</span>} />;
                },

                stepInteraction: true,
            },

            /**
             * Step 6: email remove
             */
            {
                selector: '[data-tut="reactour__distOptions-email-cancel"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Mail uitschakelen"
                            content={
                                <span>
                                    Nieuwe resultaten niet via de mail ontvangen?
                                    <br /> Beweeg de cursor over{" "}
                                    <Typography sx={{ color: "#465c84", fontWeight: 600, display: "inline-flex" }}>per e-mail</Typography> en klik op
                                    <span>
                                        <IconButton size="small" style={{ marginTop: -2 }}>
                                            <Cancel style={{ fontSize: "1rem" }} />
                                        </IconButton>
                                    </span>
                                </span>
                            }
                        />
                    );
                },
                action: (n: any) => {
                    n.focus();
                    setLockTour(true);
                },
                stepInteraction: true,
            },

            /**
             * Step 7: email remove
             */
            {
                selector: '[data-tut="reactour__distOptions-email-add"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Mail toevoegen"
                            content={
                                <span>
                                    Je kunt de optie om resultaten via de mail te ontvangen weer toevoegen door op
                                    <Chip clickable variant="outlined" label={"e-mail"} style={{ margin: 4 }} /> te klikken.
                                </span>
                            }
                        />
                    );
                },
                action: (n: any) => {
                    n.focus();
                    setLockTour(true);
                },
                stepInteraction: true,
            },

            /**
             * Step 8: interval change
             */
            {
                selector: '[data-tut="distribution_container"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Bezorging"
                            content={
                                <span>
                                    Je kunt wijzigingen doorvoeren door op de dikgedrukte woorden te klikken.
                                    <span>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            style={{ backgroundColor: "#173357", color: "#fff", marginTop: "2px", marginRight: "4px" }}
                                        >
                                            volgende stap
                                        </Button>
                                    </span>
                                    om door te gaan
                                </span>
                            }
                        />
                    );
                },

                stepInteraction: true,
            },

            /**
             * Step 9: interval change
             */
            {
                selector: '[data-tut="reactour__distShareCopy"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Delen en/of kopie sturen"
                            content={
                                <span>
                                    Je kunt de zoekregel delen met collega&apos;s of ze een kopie van je zoekregel sturen. Deze stap is optioneel en
                                    kan worden overgeslagen door op volgende stap te klikken.
                                </span>
                            }
                        />
                    );
                },
            },

            /**
             * Step 10: summary
             */
            {
                selector: '[data-tut="reactour__distSummary"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Zoekregel samenvatting"
                            content={
                                <span>
                                    Een overzicht van de zoekregel voordat deze wordt opgeslagen. <br />
                                    Klik rechtsonder op <Button size="small">vorige</Button> om filters aan te passen. <br />
                                    <br />
                                    klik op de bolletjes links van de stappen om een wijziging door te voeren in de bezorging.
                                </span>
                            }
                        />
                    );
                },
            },

            /**
             * Step 11: save
             */
            {
                selector: '[data-tut="reactour__distSave"]',
                content: () => {
                    return <GuideContent title="Zoekregel opslaan" content={<span>De zoekregel kan worden opgeslagen!</span>} />;
                },
                stepInteraction: true,
            },
        ];
    };

    /**
     * Check if user has searchrules and domainModalTourOpen = false
     * true => open tour on default for current step
     */
    useLayoutEffect(() => {
        if (hasSearchrules && !distModalTourOpen) {
            // timeout for smoother transition
            setTimeout(() => {
                setTourVariables(steps, blue, "TenderWizardDistribution");
                setOpenTour(true);
                setDistModalTourOpen(true);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distModalTourOpen, hasSearchrules]);

    return (
        <Button
            data-tut="reactour__tourbtn"
            variant="text"
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                setTourVariables(steps, blue, "TenderWizardDistribution");
                setOpenTour(true);
            }}
            startIcon={<Assistant fontSize="small" />}
        >
            Interactieve assistent
        </Button>
    );
};

export default DistModalGuide;
