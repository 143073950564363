import React from "react";
import FilterDropdownTemplate from "./FilterDropdownTemplate";
import { Theme, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { TenderFilterInput } from "../../__generated__/globalTypes";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import { IntRange } from "../FilterDrawer/IntRangeFilterItem";
import EuroIcon from "@mui/icons-material/Euro";

interface Props {
    onChange(searchInput: TenderFilterInput): void;
    searchInput: TenderFilterInput;
    // Pass through to template component
    label: string;
    borderColor?: keyof Theme["moduleColors"];
    setIntRange(IntRangeState: IntRange): void;
    IntRangeState: IntRange;
    active: boolean | null;
}

const ValueFilter: React.FC<Props> = ({ IntRangeState, setIntRange, onChange, label, borderColor, searchInput, active }) => {
    const { t } = useTranslation();

    /**
     * Error
     */
    const error = IntRangeState.from !== null && IntRangeState.to !== null && IntRangeState.from > IntRangeState.to;

    // Close modal and set state to daterange
    const handleClose = () => {
        setIntRange(IntRangeState);
    };

    const handleChange = (prop: keyof IntRange) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setIntRange({ ...IntRangeState, [prop]: parseInt(event.target.value) });
    };

    /**
     * Handler when the popover gets closed
     * onError => unset filter to avoid errors in GUI
     * onSuccess => set filter to selected Values
     */
    const handleSave = () => {
        if (error) {
            return onChange(update(searchInput, { $unset: [label.toLocaleLowerCase() as keyof TenderFilterInput] }));
        }

        onChange(
            update(searchInput, {
                [label.toLocaleLowerCase()]: {
                    $set: {
                        from: IntRangeState.from,
                        to: IntRangeState.to,
                    },
                },
            })
        );
    };

    /**
     * Template settings
     */
    const labelForTemplate: React.ReactNode = active === true ? <strong>{t(`filter.${label}`)}</strong> : t(`filter.${label}`);

    return (
        <FilterDropdownTemplate
            amountSelectedItems={active === true ? 1 : 0}
            disabled={false}
            label={labelForTemplate}
            borderColor={borderColor}
            onSave={handleSave}
            onClose={handleClose}
            allowReset={active === true}
            onReset={() => {
                setIntRange({ from: 0, to: 0 });
                onChange(update(searchInput, { $unset: [label as keyof TenderFilterInput] }));
            }}
        >
            <div style={{ maxWidth: 400, padding: 12 }}>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label={"Minimale waarde"}
                            type="number"
                            helperText={isNaN(IntRangeState.from) && <Typography variant="caption">Leeg veld niet toegestaan</Typography>}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ color: (theme) => `${"#000000"}8a` }}>
                                        <EuroIcon />
                                    </InputAdornment>
                                ),
                                inputProps: { max: IntRangeState.to },
                            }}
                            value={IntRangeState.from}
                            onChange={handleChange("from")}
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label={"Maximale waarde"}
                            type="number"
                            helperText={isNaN(IntRangeState.to) && <Typography variant="caption">Leeg veld niet toegestaan</Typography>}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ color: (theme) => `${"#000000"}8a` }}>
                                        <EuroIcon />
                                    </InputAdornment>
                                ),
                                inputProps: { min: IntRangeState.from },
                            }}
                            value={IntRangeState.to}
                            onChange={handleChange("to")}
                        />
                    </Grid>
                </Grid>
            </div>
        </FilterDropdownTemplate>
    );
};

export default ValueFilter;
