import React, { useState, useEffect } from "react";

// Import Material UI
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Import components
// import TenderOverview from "./OverviewModule/TenderOverview";
// import Mapview from "./MapViewModule/Mapview";
import OrgTenderFullList from "./ListViewModule/OrgTenderFullList";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";

import WidgetBox from "../../../../components/WidgetBox";
import { SortOrder, TenderFilterInput, TenderFilterSource, TenderFilterTarget, TenderSearchInput } from "../../../../__generated__/globalTypes";
import { MUIDataTableState } from "mui-datatables";
import TenderSearchField from "../../../../components/input/TenderSearchField";
import SplitView from "../../../tenders/splitview/SplitView";
import {
    FilterWorklistSplitView,
    FilterWorklistSplitViewVariables,
    FilterWorklistSplitView_filterTenders_results_data,
} from "../../../../__generated__/FilterWorklistSplitView";
import { FilterWorklistOverview_filterTenders_results_paginatorInfo } from "../../../../__generated__/FilterWorklistOverview";
import OrgTenderFilterBar from "./OrgTenderFilterBar";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { DateRange } from "../../../../components/FilterDrawer/TopFilterBar";
import { Disable } from "react-disable";
import {
    FilterTendersOverviewOnMarketPartyPageMinimum,
    FilterTendersOverviewOnMarketPartyPageMinimumVariables,
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_filters,
    FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results,
} from "../../../../__generated__/FilterTendersOverviewOnMarketPartyPageMinimum";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { QUERY_TENDERS_OF_MP_WITH_FILTER_MINIMUM, QUERY_WORKLIST_SPLIT } from "../../../../graphql/queryDefinitions";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface Props {
    org_id: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`${index}`}>
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

const filters: TenderFilterInput = {};
const defaultSearch: TenderSearchInput = {
    source: TenderFilterSource.ALL_TENDERS,
    target: TenderFilterTarget.SUMMARY,
    filters,
};
const defaultVariables: FilterTendersOverviewOnMarketPartyPageMinimumVariables = {
    page: 1,
    count: 10,
    orderField: "updated_at",
    order: SortOrder.DESC,
    search: defaultSearch,
};

const TenderTabMenuModule: React.FC<Props> = ({ org_id }) => {
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [value, setValue] = useState(0);
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    // setvalue to clicked value, value is number of tab that is clicked
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const [page, setPage] = useState(defaultVariables.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.count);
    const [orderField, setOrderField] = useState<string>(defaultVariables.orderField);
    const [orderDir, setOrderDir] = useState<SortOrder>(defaultVariables.order);
    const [searchInput, setSearchInput] = useState<TenderFilterInput>({ contractors: [parseInt(org_id)] });
    const [nextEndDateContract, setNextEndDateContract] = useState<DateRange>({ from: null, to: null });
    const [endDateInc, setEndDateInc] = useState<DateRange>({ from: null, to: null });
    const [selectedId, setSelectedId] = useState<string>("");

    const [
        source,
        // setSource
    ] = useState<TenderFilterSource>(defaultSearch.source);
    const [query, setQuery] = useState<string>("");
    const [target, setTarget] = useState<TenderFilterTarget>(defaultSearch.target);
    const [filterData, setFilterData] = useState<FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_filters[] | null>([]);

    const [tendersData, setTendersData] = useState<FilterTendersOverviewOnMarketPartyPageMinimum_filterTenders_results | null>();

    // array of tenders based on active filter(s) -> splitview
    const [tendersDataSplit, setTendersDataSplit] = useState<FilterWorklistSplitView_filterTenders_results_data[] | null>();
    // paginator info
    const [filteredPagination, setFilteredPagination] = useState<FilterWorklistOverview_filterTenders_results_paginatorInfo>();

    // Variables for the GQL query
    const variables: FilterTendersOverviewOnMarketPartyPageMinimumVariables = {
        page: page,
        count: rowsPerPage,
        orderField: orderField,
        order: orderDir,
        search: {
            source: source,
            target: target,
            query: query,
            filters: searchInput,
        },
    };

    // Track event
    const trackOrgTenderTab = (event: MatomoEvent) => {
        trackEvent(event);
    };

    useEffect(() => {
        // listview settings
        if (value === 0) {
            setPage(1);
            setRowsPerPage(10);
        }
        // splitview settings
        if (value === 1) {
            setPage(1);
            setRowsPerPage(20);
        }
    }, [value]);

    /**
     * Define the GQL query
     */
    const [run, { loading, data: dataList }] = useLazyQuery<
        FilterTendersOverviewOnMarketPartyPageMinimum,
        FilterTendersOverviewOnMarketPartyPageMinimumVariables
    >(QUERY_TENDERS_OF_MP_WITH_FILTER_MINIMUM, {
        variables: variables,
        fetchPolicy: "network-only",
    });

    // Define the GQL query splitlist
    const [runSplit, { error: errorSplit, loading: loadingSplit, data: dataSplit }] = useLazyQuery<
        FilterWorklistSplitView,
        FilterWorklistSplitViewVariables
    >(QUERY_WORKLIST_SPLIT, {
        variables: variables,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (value === 0) {
            if (dataList && dataList.filterTenders) {
                setFilterData(dataList.filterTenders.filters);
                setTendersData(dataList.filterTenders.results);
            }
        }
        if (value === 1) {
            if (dataSplit && dataSplit.filterTenders !== null && dataSplit.filterTenders.results !== null) {
                setFilterData(dataSplit.filterTenders.filters);
                setTendersDataSplit(dataSplit.filterTenders.results.data);
                setFilteredPagination(dataSplit.filterTenders.results.paginatorInfo);
                if (selectedId === "" && dataSplit.filterTenders.results.data.length > 0) {
                    setSelectedId(dataSplit.filterTenders.results.data[0].id);
                }
            }
        }
    }, [dataList, dataSplit, selectedId, value]);

    /**
     * Run lazyquery based on view
     */
    useEffect(() => {
        // listview
        if (value === 0) {
            run({
                variables: {
                    page: page,
                    count: rowsPerPage,
                    orderField: orderField,
                    order: orderDir,
                    search: {
                        source: source,
                        target: target,
                        query: query,
                        filters: searchInput,
                    },
                },
            });
        }
        // splitview
        if (value === 1) {
            runSplit({
                variables: {
                    page: page,
                    count: rowsPerPage,
                    orderField: orderField,
                    order: orderDir,
                    search: {
                        source: source,
                        target: target,
                        query: query,
                        filters: searchInput,
                    },
                },
            });
        }
    }, [run, runSplit, orderDir, orderField, page, rowsPerPage, searchInput, query, target, source, t, value]);

    /**
     * Handle pagination change: -page change, -rows per page change
     */
    const handlePaginationChange = (page: number, rowsPerPage: number) => {
        setPage(page);
        setRowsPerPage(rowsPerPage);
    };

    /**
     * Handle table change:
     *  -sort column,
     *  -sort direction
     */
    const handleTableChange = (_: string, tableState: MUIDataTableState) => {
        if (tableState.sortOrder.direction) {
            setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
        }
        if (tableState.sortOrder.name) {
            setOrderField(tableState.sortOrder.name);
        }
    };

    const hasKeys = Object.keys(searchInput);
    const showResetBtn = hasKeys.length === 1 && hasKeys.includes("contractors");

    return (
        <WidgetBox header="" highlight="blue" padding={0}>
            <div>
                <AppBar position="static" style={{ background: "white", color: "black", boxShadow: "none" }}>
                    <Tabs
                        sx={{
                            "& .MuiTab-root.Mui-selected": {
                                color: "#173357",
                            },
                        }}
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{ sx: { background: "#173357" } }}
                    >
                        {/* <Tab label={t("Overview")} /> */}
                        <Tab
                            sx={{ color: "#173357", width: "160px" }}
                            label={t("List")}
                            onClick={() => trackOrgTenderTab({ category: "Organizations", action: "Click-organization-tender-listview" })}
                        />
                        {!onlyMobile && (
                            <Tab
                                sx={{ color: "#173357", width: "160px" }}
                                label={t("Split")}
                                onClick={() => trackOrgTenderTab({ category: "Organizations", action: "Click-organization-tender-splitview" })}
                            />
                        )}

                        {/* <Tab label={t("Map")} /> */}
                    </Tabs>
                </AppBar>

                {/* Overview */}
                {/* <TabPanel value={value} index={0}>
                    <TenderOverview org_id={org_id} setValue={setValue} />
                </TabPanel> */}

                {/* ListView */}
                <TabPanel value={value} index={0}>
                    <Disable disabled={loading}>
                        <div style={{ margin: 8, marginTop: 0 }}>
                            <TenderSearchField
                                query={query}
                                target={target}
                                onChangeQuery={(q) => {
                                    setQuery(q);
                                    setPage(1);
                                }}
                                onChangeTarget={(target) => {
                                    setTarget(target);
                                    setPage(1);
                                }}
                                onSearch={() => {
                                    run({
                                        variables,
                                    });
                                }}
                                visible={false}
                            />
                        </div>
                        <div
                            style={
                                onlyMobile
                                    ? { margin: 8, marginTop: 16 }
                                    : { margin: 8, marginTop: 16, display: "flex", flexDirection: "row", alignItems: "center" }
                            }
                        >
                            {/* Filterbar */}
                            <OrgTenderFilterBar
                                filterData={filterData ? filterData : []}
                                resetPage={() => setPage(1)}
                                onChange={setSearchInput}
                                endDateInc={endDateInc}
                                setEndDateInc={setEndDateInc}
                                nextEndDateContract={nextEndDateContract}
                                setNextEndDateContract={setNextEndDateContract}
                                startSearch={() => {
                                    setPage(1);
                                    run({ variables });
                                }}
                                searchInput={searchInput}
                            />
                            {!showResetBtn && (
                                <Button
                                    startIcon={<Close />}
                                    size="small"
                                    onClick={() => {
                                        setSearchInput({ contractors: [parseInt(org_id)] });
                                        setPage(1);

                                        // setCaSearchQuery("");
                                        // setResetCustomFilter(true);
                                    }}
                                    style={{ color: "grey", marginBottom: 8 }}
                                >
                                    {t("filter.reset")}
                                </Button>
                            )}
                        </div>
                        <OrgTenderFullList
                            loading={loading}
                            data={tendersData?.data || []}
                            paginatorInfo={tendersData?.paginatorInfo}
                            onChangePagination={handlePaginationChange}
                            onTableChange={handleTableChange}
                            org_id={org_id}
                        />
                    </Disable>
                </TabPanel>

                {/* Splitview */}
                {!onlyMobile && (
                    <TabPanel value={value} index={1}>
                        <Disable disabled={loadingSplit}>
                            <div style={{ margin: 8, marginTop: 0 }}>
                                <TenderSearchField
                                    query={query}
                                    target={target}
                                    onChangeQuery={(q) => {
                                        setQuery(q);
                                        setPage(1);
                                        setSelectedId("");
                                    }}
                                    onChangeTarget={(target) => {
                                        setTarget(target);
                                        setPage(1);
                                        setSelectedId("");
                                    }}
                                    onSearch={() => {
                                        runSplit({ variables });
                                        setSelectedId("");
                                    }}
                                    visible={false}
                                />
                            </div>

                            <div style={{ margin: 8, marginTop: 16, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {/* Filterbar */}
                                <OrgTenderFilterBar
                                    filterData={filterData ? filterData : []}
                                    resetPage={() => setPage(1)}
                                    onChange={setSearchInput}
                                    endDateInc={endDateInc}
                                    setEndDateInc={setEndDateInc}
                                    nextEndDateContract={nextEndDateContract}
                                    setNextEndDateContract={setNextEndDateContract}
                                    startSearch={() => {
                                        setPage(1);
                                        setSelectedId("");
                                        runSplit({ variables });
                                    }}
                                    searchInput={searchInput}
                                />
                                {!showResetBtn && (
                                    <Button
                                        startIcon={<Close />}
                                        size="small"
                                        onClick={() => {
                                            setSearchInput({ contractors: [parseInt(org_id)] });
                                            setPage(1);

                                            // setCaSearchQuery("");
                                            // setResetCustomFilter(true);
                                        }}
                                        style={{ color: "grey", marginBottom: 8 }}
                                    >
                                        {t("filter.reset")}
                                    </Button>
                                )}
                            </div>
                        </Disable>
                        <div style={{ padding: 8, marginTop: 0 }}>
                            <SplitView
                                elevation={1}
                                setSelectedId={setSelectedId}
                                selectedId={selectedId}
                                page={rowsPerPage as number}
                                setPage={setRowsPerPage}
                                rows={tendersDataSplit}
                                loading={loadingSplit}
                                error={errorSplit}
                                mp={org_id}
                                paginatorInfo={
                                    filteredPagination
                                        ? filteredPagination
                                        : {
                                              count: 0,
                                              currentPage: 0,
                                              hasMorePages: false,
                                              firstItem: null,
                                              lastItem: null,
                                              lastPage: 1,
                                              perPage: 10,
                                              total: 0,
                                              __typename: "PaginatorInfo",
                                          }
                                }
                            />
                        </div>
                    </TabPanel>
                )}

                {/* Mapview */}
                {/* <TabPanel value={value} index={2}>
                <Mapview />
            </TabPanel> */}
            </div>
        </WidgetBox>
    );
};
export default TenderTabMenuModule;
