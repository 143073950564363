import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton } from "@mui/material";
import React, { useContext } from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { ProjectToOpenContext } from "../layout/MainLayout";
import { useQuery } from "@apollo/client";
import { SINGLE_TASK } from "../../graphql/queryDefProject";
import { AccountTree } from "@mui/icons-material";
import TooltipOnNewLines from "../TooltipOnNewLines";
import { single_task, single_taskVariables } from "../../__generated__/single_task";
import TaskPriorityChip from "../../routes/projects/projectModal/task/TaskPriorityChip";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(e: string): void;
}

const AssignedToTaskNotification: React.FC<Props> = ({ notification, onClick }) => {
    const grey = "#707070";
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);

    const { data, loading } = useQuery<single_task, single_taskVariables>(SINGLE_TASK, {
        variables: {
            id: notification.data ? (notification.data[0] as string) : "",
        },
        fetchPolicy: "network-only",
    });

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (loading) {
        return (
            <MenuItem
                style={{
                    whiteSpace: "normal",
                    maxHeight: 250,
                    opacity: notification.read_at !== null ? 0.5 : undefined,
                }}
            >
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    /**
     * if project is deleted hide notification
     */
    if (data === undefined || data.task === null) {
        return <></>;
    }

    const inProject = Boolean(data.task.project?.activeUsers?.find((i) => i.id === localStorage.getItem("user_id")));

    const taskToShow = !inProject ? "Project niet meer beschikbaar" : data.task.task_description;
    return (
        <MenuItem
            onClick={() => {
                if (notification.data) {
                    onClick(notification.data[0] as string);
                    if (inProject) {
                        changeProject({ projectID: data.task?.project?.id as string, projectTitle: data?.task?.project?.title });
                        setOpen(true);
                    }
                }
            }}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: grey }}>
                    <AccountTree fontSize="small" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <div
                        style={{
                            textDecoration: !inProject ? "line-through" : !inProject ? "line-through" : "none",
                        }}
                    >
                        <TooltipOnNewLines
                            showTooltip={false}
                            placement={"bottom-start"}
                            text={`Taak toegewezen in project: ${data?.task?.project?.title}` || ""}
                            variant={"body1"}
                            lines={2}
                            id={`T${notification.id as string}first`}
                            fontweight={notification.read_at === null ? 500 : "normal"}
                            fontSize={14}
                        />
                    </div>
                }
                secondary={
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                        {inProject && <TaskPriorityChip dot={true} disabled={false} priority={data.task.task_priority} />}
                        <TooltipOnNewLines
                            showTooltip={false}
                            placement={"top-start"}
                            text={taskToShow}
                            variant={"body1"}
                            lines={1}
                            id={`T${notification.id as string}second`}
                            fontweight={"normal"}
                            fontSize={12}
                        />
                    </div>
                }
            />
        </MenuItem>
    );
};

export default AssignedToTaskNotification;
