import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, List, ListItem } from "@mui/material";
import { DateRange } from "./TopFilterBar";
import moment from "moment";

interface Props {
    setDateRange(dateRange: DateRange): void;
}

const FutureMonths: React.FC<Props> = ({ setDateRange }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Typography style={{ paddingLeft: 16 }}>Opties:</Typography>
            <List>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({ from: moment(), to: moment().add(1, "months") });
                    }}
                >
                    {t("moments.Next_month")}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({ from: moment(), to: moment().add(3, "months") });
                    }}
                >
                    {t("moments.Next_3months")}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({ from: moment(), to: moment().add(6, "months") });
                    }}
                >
                    {t("moments.Next_6months")}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({ from: moment(), to: moment().add(9, "months") });
                    }}
                >
                    {t("moments.Next_9months")}
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setDateRange({ from: moment(), to: moment().add(12, "months") });
                    }}
                >
                    {t("moments.Next_12months")}
                </ListItem>
            </List>
        </React.Fragment>
    );
};

export default FutureMonths;
