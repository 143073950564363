export function parseComment(comment: string): string {
    if (comment !== "") {
        const regex = /@\[.+?\]\(.+?\)/gm;
        const displayRegex = /@\[.+?\]/g;
        const idRegex = /\(.+?\)/g;
        const matches = comment.match(regex);
        const arr: { id: string; display: string }[] = [];
        matches &&
            matches.forEach((m: string) => {
                if (m !== null) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const id = m.match(idRegex)![0].replace("(", "").replace(")", "");
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const display = m.match(displayRegex)![0].replace("@[", "").replace("]", "");
                    arr.push({ id: id, display: display });
                }
            });
        const newComment = comment.split(regex);
        let output = "";
        for (let i = 0; i < newComment.length; i++) {
            const c = newComment[i];
            if (i === newComment.length - 1) output += c;
            else output += c + `<span style="background-color:#cee4e5; color: #000000; padding:2px; border-radius: 4px;">@${arr[i].display}</span>`;
            // `<a href="/People/${arr[i].id}"><span style="background-color:#cee4e5; color: #000000; padding:2px; border-radius: 4px;">@${arr[i].display}</span></a>`;
        }
        return output;
    }
    return "";
}
