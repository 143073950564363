import React, { useCallback, useEffect, useState } from "react";
import { IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import SendMessageForm from "./SendMessageForm";
import { useQuery, useMutation } from "@apollo/client";
import { GetConversation, GetConversationVariables, GetConversation_conversation_chatMessages } from "../../__generated__/GetConversation";
import ConversationChats from "../tenderNote/ConversationChats";
import { useTenderChat } from "../contextProviders/TenderChatContext";
import ConversationLoader from "./ChatMessenger/ConversationLoader";
import { ArrowBack, Message, Notes } from "@mui/icons-material";
import { GetConversationGroup_conversationGroup_conversations_users } from "../../__generated__/GetConversationGroup";
import { UserSetting } from "./DialogContent";
import { activeConversation } from "./TenderNoteDialog";
import { ContactListItem, ConversationListItem, Team } from "../tenderNote/ConversationsList";
import AvatarTopbar from "./AvatarTopbar";
import { QUERY_CONVERSATION_UNREAD_MESSAGES_COUNT } from "../tenderNote/LastMessageToShow";
import { QUERY_ORG_STATE_FOR_USER } from "../../routes/organizations/detailPage/OrgTopBarModule";
import { QUERY_OPP_STATE_FOR_USER } from "../../routes/opportunities/detailpage/OppDetailTopBar";
import { QUERY_CA_STATE_FOR_USER } from "../../routes/contractingAuthoritiesModule/detailpage/CATopBarModule";
import { SendChatMessage, SendChatMessageVariables } from "../../__generated__/SendChatMessage";
import { QUERY_TENDER_STATE_FOR_USER } from "../../graphql/queryDefinitions";
import { MARKMESSAGEASREAD, QUERY_CONVERSATION, QUERY_CONVERSATION_GROUP, SENDTENDERMESSAGE } from "../../graphql/queryDefChat";
import { useProfile } from "../contextProviders/ProfileOpenerContext";
import { useSocketForChat } from "../../utils/websocketChannels";
import { setChatMessageAsRead, setChatMessageAsReadVariables } from "../../__generated__/setChatMessageAsRead";

interface Props {
    conversationId: string;
    newConversation?: boolean;
    modelType: string;
    users: GetConversationGroup_conversationGroup_conversations_users[] | undefined;
    team: Team | undefined;
    user: ContactListItem | undefined;
    conversation_group_id: string | undefined;
    onSelectNewContact: React.Dispatch<React.SetStateAction<UserSetting | undefined>>;
    setConversation: React.Dispatch<React.SetStateAction<activeConversation | null>>;
    fullConversation: ConversationListItem[];
    staticLoading: boolean;
    setStaticLoading: (boolean: boolean) => void;
}

const ActiveConversation: React.FC<Props> = ({
    fullConversation,
    onSelectNewContact,
    setConversation,
    conversationId,
    newConversation,
    modelType,
    users,
    conversation_group_id,
    team,
    user,
    staticLoading,
    setStaticLoading,
}) => {
    const lightBGcolor = "#f4f4f4";
    const { setMessageSent } = useTenderChat();
    const theme = useTheme();
    const mobileOnly = useMediaQuery(theme.breakpoints.down("md"));
    const [typing, setTyping] = useState<{ typing: boolean; name: string }>({ typing: false, name: "" });
    const { givenname } = useProfile();
    const [markMessageAsRead] = useMutation<setChatMessageAsRead, setChatMessageAsReadVariables>(MARKMESSAGEASREAD);

    /**
     * Handler to set message or conversation to read
     * @param messageID id of single message
     * @param conversationID id of whole conversation
     */
    const markAsRead = async (conversationID: string) => {
        try {
            await markMessageAsRead({
                variables: { conversation_id: conversationID },
                refetchQueries: [
                    QUERY_CONVERSATION_UNREAD_MESSAGES_COUNT,
                    "GetConversation_Unread_Messages_Counter",
                    QUERY_TENDER_STATE_FOR_USER,
                    "GetTenderStateForUser",
                    QUERY_ORG_STATE_FOR_USER,
                    QUERY_OPP_STATE_FOR_USER,
                    QUERY_CA_STATE_FOR_USER,
                ],
                // onCompleted: () => {
                //     toast.info(`in de try conv_id: ${varToUse.conversation_id} msg_id: ${varToUse.message_id}`, { autoClose: false });
                // },
            });
        } catch (e) {
            toast.error(`Er ging iets mis ${conversationID}`, { autoClose: false });
        }
    };

    const handleTyping = (payload: any, eventName: string) => {
        // Catch whisper event
        if (eventName === "typing") {
            setTyping({ typing: true, name: payload.user });
            setTimeout(() => setTyping({ typing: false, name: "" }), 2500); // Typing indicator lasts for 2.5 seconds
        } else {
            //  Refetch the conversation data
            refetch({ id: conversationId })
                .then(({ data }) => {
                    // Assuming the query returns the conversation object with chatMessages and unreadChatMessages
                    if (data && data.conversation) {
                        // Update the messages in the UI
                        setMessages(data.conversation.chatMessages || []);

                        // If there are any unread messages in the conversation, mark them as read
                        if (data.conversation.unreadChatMessages.length > 0) {
                            // Call markAsRead function (uncomment and define it if necessary)
                            markAsRead(conversationId);
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error refetching conversation:", error);
                });
        }
    };

    // Websocket connection to whisper and listen to events (new chatmessages, whisper etc.)
    const { sendWhisper } = useSocketForChat(`conversation.${conversationId}`, ".ChatMessageCreated", handleTyping, "typing");

    // Send whisper to person when user is typing
    const handleInputChange = () => {
        sendWhisper("typing", { user: givenname, typing: true });
    };

    /**
     * Query messages of active conversation
     */
    const { error, loading, refetch } = useQuery<GetConversation, GetConversationVariables>(QUERY_CONVERSATION, {
        variables: { id: conversationId },
        fetchPolicy: "network-only",
        // No data to fetch when its a new conversation
        // TODO: Skip if websockets return state 200
        skip: newConversation === true,
        onCompleted: (data) => {
            setMessages(data.conversation.chatMessages || []);
            if (data.conversation.unreadChatMessages.length > 0) {
                // Mark all messages from active conversation as read
                // markAsRead(null, conversationId);
            }
            handleStaticLoader();
        },
    });

    const [send, { loading: loadingMutation, error: errorMutation }] = useMutation<SendChatMessage, SendChatMessageVariables>(SENDTENDERMESSAGE);

    const [messages, setMessages] = useState<GetConversation_conversation_chatMessages[]>([]);

    const handleStaticLoader = useCallback(() => {
        setTimeout(() => {
            setStaticLoading(false);
        }, 400);
    }, [setStaticLoading]);

    /**
     * Whenever new messages are loaded by the query,
     * Reload the window with the new messages
     */
    useEffect(() => {
        if (newConversation && staticLoading) {
            handleStaticLoader();
            setMessages([]);
        }
    }, [handleStaticLoader, newConversation, staticLoading]);

    /**
     * Handler when the user wants to send a message
     * @param text Message to send
     */
    const onSend = async (text: string) => {
        const varToUse = !newConversation
            ? {
                  conversation: conversationId,
                  message: text,
                  conversation_group_id: conversation_group_id,
              }
            : newConversation && user !== undefined
            ? {
                  message: text,
                  to_user: user.id,
                  conversation_group_id: conversation_group_id,
              }
            : {
                  message: text,
                  to_team: team?.teamId,
                  conversation_group_id: conversation_group_id,
              };

        if (errorMutation) {
            toast.error("Er ging iets mis", { autoClose: 1500 });
        }
        try {
            await send({
                variables: varToUse,
                refetchQueries: [
                    QUERY_TENDER_STATE_FOR_USER,
                    QUERY_CONVERSATION_UNREAD_MESSAGES_COUNT,
                    QUERY_CONVERSATION_GROUP, // DocumentNode object parsed with gql
                    "GetConversationGroup",
                    QUERY_ORG_STATE_FOR_USER,
                    QUERY_OPP_STATE_FOR_USER,
                    QUERY_CA_STATE_FOR_USER,
                ],
                onCompleted: (data) => {
                    // on complete mutation set active conversation to new generated id
                    setConversation({ id: data.sendMessage.id, private: false });
                    // set messages array to new fetched array with latest message sent
                    setMessages(data.sendMessage.chatMessages);
                },
            });

            onSelectNewContact(undefined);
            setMessageSent(true);
        } catch (e) {
            toast.error("Er ging iets mis", { autoClose: 1500 });
        }
    };

    // Loading screen when data is not fetched yet
    if (loading || staticLoading) {
        return <ConversationLoader loading={true} textualHelper="Berichten ophalen..." />;
    }

    // Error screen when error occured
    if (error) {
        setStaticLoading(false);
        return <ConversationLoader loading={false} textualHelper="Er ging iets mis..." />;
    }

    const privateChat = !newConversation && fullConversation.length > 0 && Boolean(fullConversation[0].users.length === 1);

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", backgroundColor: lightBGcolor }}>
            {/* Form to send a message in clicked chat */}
            <React.Fragment>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 8,
                        padding: 10,
                        minHeight: 70,
                        maxHeight: 70,
                    }}
                >
                    {mobileOnly && (
                        <div>
                            <IconButton
                                onClick={() => {
                                    setConversation(null);
                                    onSelectNewContact(undefined);
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                        </div>
                    )}
                    {/*
                     * Topbar with avatars of user(s) in active conversation
                     */}
                    <AvatarTopbar
                        fullConversation={fullConversation}
                        newConversation={newConversation}
                        modelType={modelType}
                        user={user}
                        team={team}
                    />
                </div>
                {/*
                 * Show icon with text when no messages are received/sent to fill empty space
                 */}
                {messages.length === 0 ? (
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        {privateChat ? (
                            <Notes sx={{ color: "#dcdcdc", height: "9rem", width: "9rem" }} />
                        ) : (
                            <Message sx={{ color: "#dcdcdc", height: "9rem", width: "9rem" }} />
                        )}

                        <Typography variant="h5" style={{ color: "#dcdcdc" }}>
                            {privateChat ? "Geen notities gevonden..." : "Stuur een eerste bericht"}
                        </Typography>
                    </div>
                ) : (
                    //    {/* Div shows messages of clicked chat */}
                    <div id="messagebox" style={{ padding: "10px 0px", flexGrow: 1, height: "100%", overflow: "auto" }}>
                        <ConversationChats messages={messages} modelType={modelType} />
                        {/* <div style={{ width: "95%" }}><Documents /></div> */}
                    </div>
                )}
            </React.Fragment>
            <SendMessageForm
                typing={typing}
                onInputChange={handleInputChange}
                loading={loadingMutation}
                onSend={onSend}
                onDocumentClick={
                    // () => setDocumentBox(true)
                    () => console.log("")
                }
                modelType={modelType}
            />
        </div>
    );
};

export default ActiveConversation;
