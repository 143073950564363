import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import setTheme from "../../utils/setTheme";
import { materialTheme } from "../../assets/styles";
import MainLayout from "../../components/layout/MainLayout";

import {
    //  useNavigate, useLocation,
    Routes,
    Route,
} from "react-router-dom";
import NotFound404 from "../NotFound404";
import TendenzModule from "./TendenzModule";

interface Props {
    a?: number;
}

const TendenzContainer: React.FC<Props> = (props) => {
    /**
     * set theme color
     */
    useEffect(() => {
        setTheme("darkGray");
    });

    return (
        <ThemeProvider theme={materialTheme}>
            <MainLayout page="TendenZ">
                <Routes>
                    <Route path="" element={<TendenzModule edition="2" />} />
                    <Route path=":version" element={<TendenzModule />} />
                    <Route path="*" element={<NotFound404 url={"/tendenz"} />} />
                </Routes>
            </MainLayout>
        </ThemeProvider>
    );
};

export default TendenzContainer;
