import React, { useState } from "react";
import { List, ListItem, ListItemText, Typography, Divider, Tooltip, ListItemSecondaryAction, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CountryFlagBox from "../boxes/CountryFlagBox";
import StarIcon from "@mui/icons-material/Star";
import StatusChip from "../StatusChip";
import { GetStarredRelatedOrgs_currentUser_market_parties } from "../../__generated__/GetStarredRelatedOrgs";
import FaviconBox from "../FaviconBox";
import currencyFormatDE from "../../utils/currencyFormatter";
import AddIcon from "@mui/icons-material/Add";
import { GetRelatedTendersForCurrentTender_tender_related_tenders_bids } from "../../__generated__/GetRelatedTendersForCurrentTender";
import { OptieEinde, convertToStringArray } from "../../utils/contracttimeline";
import HorizontalContractTimeline from "../tenderDetail/widgets/contract_timeline/HorizontalContractTimeline";
import moment from "moment";
import { currencyFormat } from "../../utils/currencyFormat";

interface Props {
    bids: GetRelatedTendersForCurrentTender_tender_related_tenders_bids[];
    startDate: string | null;
    endDate: string | null;
    firstEndDate: string | null;
    starredOrgs: GetStarredRelatedOrgs_currentUser_market_parties[];
    looptijd_einde_incl_opties: any;
    tender_id: string;
    opties: OptieEinde[];
    datum_gunning: string | null;
}

//TODO: Show companies that are combined. show those companies together before the divider and add a chip with text "combination"
const TenderersOnly: React.FC<Props> = ({ opties, bids, startDate, endDate, starredOrgs, datum_gunning, tender_id }) => {
    const { t } = useTranslation();
    const starColor = "#9e9e9e";
    const ItemsPerClick = bids.length;
    const pink = "#E03660";
    // state for number of items to show
    const [noOfItems, setnoOfItems] = useState(5);

    // state to show items based on state of items to show
    const itemsToShow = bids.slice(0, noOfItems);

    /**
     * Handler to show more items onclick,
     * get value of noOfItems and add value of ItemsPerClick.
     */
    const handleShowMoreItems = () => {
        setnoOfItems(ItemsPerClick);
    };

    const handleShowLess = () => {
        setnoOfItems(5);
    };

    /**
     * key = property of array that has to be unique
     */
    const key = "combination";

    /**
     * map array with last items that have duplicate values,
     * opposite of JS find() function
     */
    const arrayUniqueByKey = [...new Map(bids.map((item) => [item[key], item])).values()];

    /**
     * get first item that is a duplicate
     */
    const uniqByKeepFirst = (a: any, key: any) => {
        const seen = new Set();
        return a.filter((item: any) => {
            const k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    };
    // check if datum_gunning is same as enddate
    const gunningISeind = Boolean(datum_gunning && endDate && moment(datum_gunning).format("DD-MM-YYYY") === moment(endDate).format("DD-MM-YYYY"));

    return (
        <React.Fragment>
            <div style={{ width: "100%" }}>
                {endDate !== undefined && gunningISeind === false && (
                    <HorizontalContractTimeline
                        start={startDate || datum_gunning}
                        end={endDate}
                        options={convertToStringArray(opties)}
                        datum_gunning_as_start={Boolean(startDate === null || startDate === undefined)}
                        noEndDateAvailable={Boolean(endDate === null)}
                        undetermined={false}
                    />
                )}
            </div>
            {/* list with mapped contractors */}
            <List
                sx={{
                    "& .MuiList-root": {
                        width: "100%",
                        paddingTop: "16px",
                    },
                }}
            >
                {/* map all contractors with logo, winner and more info */}
                {itemsToShow.map((bid, i) => {
                    const filteredNames = bid.marketParty.names.filter((n) => n.type === "trade_name");
                    return (
                        <React.Fragment key={bid.id}>
                            {/* <ListItem style={{ padding: "8px 16px" }}> */}
                            <ListItem
                                sx={{
                                    padding: bid.combination === null ? "8px 16px" : "0px 16px",
                                }} // get first item of combination and add 8px padding top
                                style={{
                                    paddingTop:
                                        bid.combination !== null &&
                                        uniqByKeepFirst(bids, (it: any) => it.combination).find(
                                            (t: any) => t.id === bid.id && t.combination !== null
                                        ) &&
                                        8,

                                    // last item of combination -> paddingBottom 8
                                    paddingBottom: arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && 8,
                                }}
                            >
                                {/* tenderer logo */}
                                <div style={{ marginRight: 8 }}>
                                    <FaviconBox
                                        favicon={bid.marketParty.favicon_resized ?? bid.marketParty.favicon}
                                        name={bid.marketParty.name}
                                        color={pink}
                                        marginTop={0}
                                    />
                                </div>

                                {/* listitemtext with amount and percentage */}
                                <ListItemText
                                    primary={
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <Typography
                                                noWrap
                                                sx={{
                                                    display: "inline",
                                                    maxWidth: (theme) =>
                                                        theme.breakpoints.up("xl") ? "88%" : theme.breakpoints.up("xxl") ? "94%" : undefined,
                                                }}
                                            >
                                                {filteredNames.length > 0 ? (
                                                    <Tooltip
                                                        title={filteredNames
                                                            .sort((a, b) => a.value.localeCompare(b.value))
                                                            .map((item, i) => {
                                                                return (
                                                                    <React.Fragment key={i}>
                                                                        {i !== filteredNames.length - 1 ? (
                                                                            <Typography variant="caption">{item.value} | </Typography>
                                                                        ) : (
                                                                            <Typography variant="caption">{item.value}</Typography>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                    >
                                                        <Link to={`/organizations/${bid.marketParty.id}`}>{bid.marketParty.name} </Link>
                                                    </Tooltip>
                                                ) : (
                                                    <Link to={`/organizations/${bid.marketParty.id}`}>{bid.marketParty.name} </Link>
                                                )}
                                            </Typography>
                                            <CountryFlagBox code={bid.marketParty.country?.alpha2 ? bid.marketParty.country.alpha2 : null} />
                                            {/* TODO: conditon, render only if starred */}
                                            {starredOrgs.find((s) => s.id === bid.marketParty.id) && (
                                                <StarIcon style={{ color: `${starColor}`, marginLeft: 4, fontSize: "1rem" }} />
                                            )}
                                            {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null)
                                                ? null
                                                : bid.combination !== null && <AddIcon style={{ height: "0.7em", width: "0.7em", marginLeft: 16 }} />}
                                        </div>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && bid.bid ? (
                                                <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                    {currencyFormat(bid.totalValue?.currency)} {currencyFormatDE(bid.bid)}
                                                </Typography>
                                            ) : (
                                                bid.bid &&
                                                bid.combination === null && (
                                                    <Typography style={{ display: "inline-block", marginRight: 16 }}>
                                                        {currencyFormat(bid.totalValue?.currency)} {currencyFormatDE(bid.bid)}
                                                    </Typography>
                                                )
                                            )}
                                            {/* Percentage */}
                                            {bid.percentage && <Typography style={{ display: "inline-block" }}>+{bid.percentage}%</Typography>}
                                        </React.Fragment>
                                    }
                                />

                                {/* Chip that shows who won the bid */}
                                <ListItemSecondaryAction style={{ height: "100%", paddingTop: 12 }}>
                                    {bid.winner && bid.combination === null ? (
                                        <div style={{ marginLeft: 4 }}>
                                            <StatusChip colored={false} type="winner" />
                                        </div>
                                    ) : (
                                        bid.winner &&
                                        uniqByKeepFirst(bids, (it: any) => it.combination).find(
                                            (t: any) => t.id === bid.id && t.combination !== null
                                        ) && (
                                            <div style={{ marginLeft: 4 }}>
                                                <StatusChip colored={false} type="winner" />
                                            </div>
                                        )
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>

                            {i !== itemsToShow.length - 1 && bid.combination === null && <Divider />}
                            {/* Check if item is last one AND item is not a combination, last one -> show divider */}

                            {/* Set divider to last index of a combination */}
                            {arrayUniqueByKey.find((t: any) => t.id === bid.id && t.combination !== null) && <Divider />}
                        </React.Fragment>
                    );
                })}
            </List>
            {/*
             * Button to load more contractors
             */}
            {bids.length > 5 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {itemsToShow.length !== bids.length && (
                        <Button size="small" variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={handleShowMoreItems}>
                            {t("tenderPage.showAll")}
                        </Button>
                    )}

                    {itemsToShow.length > 5 && (
                        <Button size="small" variant="text" color="primary" style={{ marginBottom: 8 }} onClick={handleShowLess}>
                            {t("tenderPage.ShowLess")}
                        </Button>
                    )}
                </div>
            )}
            <div />
        </React.Fragment>
    );
};

export default TenderersOnly;
