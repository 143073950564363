import React from "react";
import { Box, IconButton, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ShowTenderSourceText from "./ShowTenderSourceText";
import {
    AddBox,
    CheckBox,
    EventAvailable,
    PriorityHigh,
    // PriorityHigh
} from "@mui/icons-material";

interface Props {
    item: any;
    index: number;
    lastItem: number;
    itemToCal: any[];
    onHandleAdd(item: any, state: boolean): void;
    showSource: boolean;
    tender_id: string;
    host: string | null;
    hostUrl: string | null;
    showHandleAdd: boolean;
    showOptionToAdd: boolean;
    incompatibleDates: (string | null)[];
    deadline: string;
}

interface CheckResult {
    hasIncompatibleDate: boolean;
    tagName?: string;
}

const ScheduleTimeLineItem: React.FC<Props> = ({
    item,
    index,
    lastItem,
    itemToCal,
    onHandleAdd,
    showSource,
    tender_id,
    host,
    hostUrl,
    showHandleAdd,
    showOptionToAdd,
    incompatibleDates,
    deadline,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const afterTime = moment().isAfter(item.planned, "seconds");
    const weeknumber = moment(item.planned).week();
    const chosenLanguage = localStorage.getItem("i18nextLng");
    const grey = "#9e9e9e";
    const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const red = theme.palette.error.main;
    const blue = "#465c84";
    const black = "#000000";

    const namesToFind = ["deadline", "vragen"];

    const checkTagsForNames = (tags: { name: string }[]): boolean => {
        if (tags === undefined || tags.length === 0 || tags === null) {
            return false;
        }

        // Check if any of the tags' names match any names in namesToFind
        return tags.some((tag) => namesToFind.includes(tag.name));
    };

    function capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const checkForIncompatibleDates = (tags: { name: string }[]): CheckResult => {
        // Filter out any null values from the incompatibleDates array first

        if (tags === undefined || tags.length === 0 || tags === null) {
            return { hasIncompatibleDate: false };
        }

        const filteredIncompatibleDates = incompatibleDates.filter((date) => date !== null) as string[];

        // Check if any of the item's tags exist in the filteredIncompatibleDates array
        const hasIncompatibleDate = tags.some((tag) => {
            if (filteredIncompatibleDates.includes(tag.name)) {
                return true;
            }
            return false;
        });

        if (hasIncompatibleDate) {
            // Find the incompatible tag, capitalize its name, and return it
            const incompatibleTag = tags.find((tag) => filteredIncompatibleDates.includes(tag.name));
            const tagName = incompatibleTag ? capitalizeFirstLetter(incompatibleTag.name) : undefined;
            return { hasIncompatibleDate: true, tagName };
        }

        return { hasIncompatibleDate: false };
    };

    // const deadlineIsIncompatible = moment(deadline).format() !== moment(item.planned).format();

    return (
        <TimelineItem>
            {/* Time and weeknumber, show year when its not same as current year */}
            <TimelineOppositeContent sx={{ opacity: afterTime ? 0.3 : 1, color: !afterTime ? black : undefined, minWidth: "100px" }}>
                {moment(item.planned).isSame(moment(), "year") ? (
                    // Current year date notation
                    <Typography sx={{ fontWeight: checkTagsForNames(item.tags) && !item.isReplacedBy ? "bold" : "normal" }}>
                        {/* <Typography sx={{ fontWeight: "normal" }}> */}
                        {moment(item.planned).format("D MMM")}
                    </Typography>
                ) : (
                    /**
                     * Previous or next year date notation
                     */
                    <Typography>{moment(item.planned).format("D MMM YYYY")}</Typography>
                )}
                {/*
                 * Abbreviation day ma/di/wo/do/vr
                 */}
                <Typography>
                    {moment(item.planned).format("dd").toLowerCase()}/wk{weeknumber}
                </Typography>
            </TimelineOppositeContent>

            {/* Middle section with dot and line, dont show line at last item */}
            <TimelineSeparator>
                <TimelineDot
                    sx={{
                        backgroundColor:
                            item.isReplacedBy?.id === item.id && item.isReplacedBy !== undefined
                                ? red
                                : item.isReplacedBy
                                ? red
                                : afterTime
                                ? grey
                                : blue,
                        opacity: "1 !important",
                        margin: "8px 0px",
                    }}
                />
                {index !== lastItem ? (
                    <TimelineConnector
                        sx={{
                            backgroundColor:
                                item.isReplacedBy?.id === item.id && item.isReplacedBy !== undefined
                                    ? red
                                    : item.isReplacedBy
                                    ? red
                                    : afterTime
                                    ? grey
                                    : blue,
                        }}
                    />
                ) : (
                    <></>
                )}
            </TimelineSeparator>

            {/* Paper item with context of data item: title, created at, source, edit at, relative time */}
            <TimelineContent
                style={{ flex: 10 }}
                sx={{
                    opacity: afterTime ? 0.3 : item.isReplacedBy ? 0.3 : 1,
                }}
            >
                <Paper
                    elevation={1}
                    sx={{
                        backgroundColor: checkTagsForNames(item.tags || []) ? "#f4f4f4" : "#ffffff",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                        <div>
                            {/* Check if id is set or undefined, event items have an id, holiday items dont */}
                            {item.id !== undefined ? (
                                <Typography
                                    sx={{
                                        margin: "5px",
                                        fontWeight: checkTagsForNames(item.tags || []) ? "bold" : "normal",
                                        // fontWeight: "normal",
                                        textDecoration: item.isReplacedBy ? "line-through" : "none",
                                    }}
                                >
                                    {/* time and name of event */}
                                    {moment(item.planned).format("HH:mm") === "00:00" ? (
                                        <span>{item.name}</span>
                                    ) : item.timeSignificant ? (
                                        <span>
                                            {moment(item.planned).format("HH:mm")}
                                            {" - "}
                                            {item.name}
                                        </span>
                                    ) : (
                                        <span>{item.name}</span>
                                    )}
                                </Typography>
                            ) : (
                                <Typography
                                    style={{
                                        //  fontWeight: "bold",
                                        margin: "6px",
                                    }}
                                    sx={{
                                        textDecoration: item.isReplacedBy ? "line-through" : "none",
                                    }}
                                >
                                    {/* Check if language is 'nl' and check if name is one of strings underneath. if its one of those, use an translationkey otherwise show original name  */}
                                    {(chosenLanguage === "nl" && item.name === "Tweede Paasdag") ||
                                    item.name === "Koninklijke dag" ||
                                    item.name === "Feest van de hemelvaart van Jezus Christus" ||
                                    item.name === "Pinksterdag" ||
                                    item.name === "kerstdag" ||
                                    item.name === "Tweede kerstdag" ||
                                    item.name === "Wapenstilstand"
                                        ? t(`holidayNames.${item.name}`)
                                        : item.name}
                                </Typography>
                            )}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: onlySmallScreen ? "column" : "row",
                                    alignItems: "center",
                                }}
                            >
                                {/* Shows relative time from now till date */}
                                <Typography
                                    style={{ marginLeft: 5 }}
                                    sx={{
                                        color: "#707070",
                                        marginRight: onlySmallScreen ? "8px" : "16px",
                                        marginLeft: onlySmallScreen ? "5px" : undefined,
                                        marginTop: 0,
                                    }}
                                >
                                    {/* if not time significant and day is today  => "today" */}
                                    {!item.timeSignificant && moment(item.planned).isSame(moment(), "day")
                                        ? t("holidayNames.today")
                                        : moment(item.planned).fromNow()}
                                </Typography>

                                {/* shows created at date when id is set */}
                                {item.id !== undefined && item.isReplacedBy ? (
                                    // Show modified at date when item is replaced
                                    <Typography
                                        sx={{
                                            color: "#707070",
                                            marginRight: onlySmallScreen ? "8px" : "16px",
                                            marginLeft: onlySmallScreen ? "5px" : undefined,
                                            marginTop: 0,
                                        }}
                                    >
                                        ({t("tenderPage.modified")}:{" "}
                                        {moment(item.isReplacedBy.created_at).isSame(moment(), "day")
                                            ? "vandaag"
                                            : moment(item.isReplacedBy.created_at).format("D MMMM")}
                                        )
                                    </Typography>
                                ) : item.id === undefined ? (
                                    // Shows "national holiday" if id is undefined.
                                    <Typography
                                        sx={{
                                            color: "#707070",
                                            marginRight: onlySmallScreen ? "8px" : "16px",
                                            marginLeft: onlySmallScreen ? "5px" : undefined,
                                            marginTop: 0,
                                        }}
                                    >
                                        {t("holidayNames.nationalHoliday")}
                                    </Typography>
                                ) : (
                                    ""
                                )}
                                {showSource && item.id !== undefined && (
                                    // Show source if available
                                    <span style={{ marginLeft: 5, marginRight: 4, display: "flex", alignItems: "center" }}>
                                        <Typography
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: "#707070",
                                                marginRight: onlySmallScreen ? "4px" : "8px",
                                                marginLeft: onlySmallScreen ? "5px" : undefined,
                                                marginTop: 0,
                                            }}
                                        >
                                            ({t("tenderPage.createdAt")}:{" "}
                                            {moment(item.created_at).isSame(moment(), "day") ? "vandaag" : moment(item.created_at).format("D MMMM")},
                                            <div style={{ width: "4px" }} />
                                            <ShowTenderSourceText
                                                variant="body1"
                                                sourceType={item.source?.__typename}
                                                sourceObject={item.source}
                                                tender_id={tender_id}
                                                host={host}
                                                hostUrl={hostUrl}
                                            />
                                            <div style={{ width: "4px" }} />)
                                        </Typography>
                                    </span>
                                )}
                            </Box>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {/*
                             * - Button to add item to calendar
                             * - Icon switch when item is selected
                             * - visual feedback for user
                             */}

                            {checkForIncompatibleDates(item.tags).hasIncompatibleDate &&
                                !afterTime &&
                                item.tags.find((i: string) => i === "deadline") && (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Tooltip
                                            disableInteractive
                                            title={`${checkForIncompatibleDates(item.tags).tagName} wijkt af`}
                                            placement="left"
                                        >
                                            <IconButton size="small">
                                                <PriorityHigh style={{ color: theme.palette.error.main }} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                            {showOptionToAdd && item.id !== undefined && !item.isReplacedBy && (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {itemToCal.includes(item.id) ? (
                                        <Tooltip disableInteractive title="Niet toevoegen aan agenda" placement="left">
                                            <IconButton onClick={() => onHandleAdd(item.id, false)} size="small" style={{ marginRight: 8 }}>
                                                <CheckBox style={{ color: grey }} />
                                            </IconButton>
                                        </Tooltip>
                                    ) : showHandleAdd === true ? (
                                        <Tooltip disableInteractive title="Toegevoegd aan agenda" placement="left">
                                            <div style={{ display: "flex", padding: "5px", marginRight: 8 }}>
                                                <EventAvailable style={{ color: grey }} />
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        !afterTime && (
                                            <Tooltip disableInteractive title="Toevoegen aan agenda" placement="left">
                                                <IconButton onClick={() => onHandleAdd(item.id, true)} size="small" style={{ marginRight: 8 }}>
                                                    <AddBox style={{ color: grey }} />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    )}
                                </div>
                            )}
                            {!showOptionToAdd && item.isStored && (
                                <Tooltip disableInteractive title="Toegevoegd aan agenda" placement="left">
                                    <div style={{ marginRight: 11, display: "flex", alignItems: "center" }}>
                                        <EventAvailable style={{ color: grey }} />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );
};

export default ScheduleTimeLineItem;
