import React from "react";
import { Link, List, ListItem, ListItemIcon } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";

interface Props {
    label: string | null | undefined;
    onDirectoryClick: (folder: string) => void;
}

const Directory: React.FC<Props> = ({ label, children, onDirectoryClick }) => {
    const greyTone = "#9e9e9e";
    const blackTone = "#000000";
    return (
        // TODO: show downloadicon to download the whole folder with content
        // show amount of documents inside folder
        <>
            {/* ListItem to display the folder name in the list */}
            <ListItem
                sx={{
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
                // button => option to show hover effect
                onClick={() => {
                    if (label !== null && label !== undefined) {
                        onDirectoryClick(label);
                    }
                }}
            >
                {/* Show greyish folder icon */}
                <ListItemIcon style={{ minWidth: 32 }}>
                    <FolderIcon style={{ color: `${greyTone}` }} />
                </ListItemIcon>
                {/* Label as link => visible feedback for user that folder is clickable */}
                <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingRight: 20 }}>
                    <Link underline="none" style={{ color: blackTone }}>
                        {label}
                    </Link>
                </div>
            </ListItem>

            {/* List with content inside folder */}
            <List dense component="div" disablePadding style={{ width: "100%", paddingTop: 0, paddingBottom: 0, paddingLeft: "-20px" }}>
                {children}
            </List>
        </>
    );
};

export default Directory;
