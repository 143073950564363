import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    // Active page url, to determine which tab is active
    value: string;
    // Handler when user clicks on a tab
    onChange(url: string): void;
    settlingCount: number;
    policyLength: boolean;
    contacts: number | undefined;
}

const CADetailTabs: React.FC<Props> = ({ value, onChange, settlingCount, policyLength, contacts }) => {
    const { trackEvent } = useMatomo();
    const [tabValue, setTabValue] = useState("");

    useEffect(() => {
        /**
         * Get pathname "/contracting-authorities/{id}"
         * optional: /contacts or /policy
         * Get value after last /
         * If value is id -> set value to empty string
         * else -> set value to /contacts or /policy
         */
        const str = value;
        const n = str.lastIndexOf("/");
        const result = str.substring(n + 1);

        const isnum = /^\d+$/.test(result);
        if (isnum) {
            setTabValue("");
        } else {
            setTabValue(result);
        }
    }, [value]);

    // Track event CA tabs
    const trackCaTabs = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        // tabs inside organization widget
        <Tabs
            value={tabValue}
            onChange={(_, value) => {
                onChange(value);
                setTabValue(value);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            sx={{
                "& .MuiTabs-root": {
                    height: "100%",
                },
                "& .MuiTabs-flexContainer": {
                    height: "100%",
                },
            }}
        >
            {/* Default tabs*/}
            <Tab
                sx={{ minWidth: "150px", maxWidth: "100%", flexBasis: 100, flexShrink: 0 }}
                value={""}
                label="algemeen"
                onClick={() => trackCaTabs({ category: "Ca", action: "Click-general-tab-ca" })}
            />
            {contacts !== undefined && contacts > 0 && (
                <Tab
                    sx={{ minWidth: "150px", maxWidth: "100%", flexBasis: 100, flexShrink: 0 }}
                    value={`contacts`}
                    label="contactpersonen"
                    onClick={() => trackCaTabs({ category: "Ca", action: "Click-contacts-tab-ca" })}
                />
            )}
            {/* {settlingCount > 1 && <Tab sx={{        minWidth: "150px",
        maxWidth: "100%",
        flexBasis: 100,
        flexShrink: 0,}} value={`settlings`} label="vestigingen" />} */}
            {policyLength === true && (
                <Tab
                    sx={{ minWidth: "150px", maxWidth: "100%", flexBasis: 100, flexShrink: 0 }}
                    value={`policy`}
                    label="inkoopbeleid"
                    onClick={() => trackCaTabs({ category: "Ca", action: "Click-purchasing-policy-tab-ca" })}
                />
            )}
            {/* <Tab sx={{        minWidth: "150px",
        maxWidth: "100%",
        flexBasis: 100,
        flexShrink: 0,value={`documents`} label="documenten" /> */}
            {/* <Tab sx={{        minWidth: "150px",
        maxWidth: "100%",
        flexBasis: 100,
        flexShrink: 0,}} value={`collaborations`} label="samenwerkingen" /> */}
            {/* <Tab sx={{        minWidth: "150px",
        maxWidth: "100%",
        flexBasis: 100,
        flexShrink: 0,}} value={`partakers`} label="deelnemers" /> */}
        </Tabs>
    );
};

export default CADetailTabs;
