import React from "react";
import { useTheme } from "@mui/material";
import { useQuery } from "@apollo/client";
import { top5MP } from "../../__generated__/top5MP";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import FullPageSpinner from "../loading/FullPageSpinner";
import FaviconBox from "../FaviconBox";
import { QUERY_TOP_FIVE_MP_SUGGESTIONS } from "../../graphql/queryDefOrg";

interface Props {
    a?: number;
}

const MpContent: React.FC<Props> = (props) => {
    const { loading, data } = useQuery<top5MP>(QUERY_TOP_FIVE_MP_SUGGESTIONS);
    const theme = useTheme();
    const pink = "#E03660";
    const black = "#000000";

    return (
        <React.Fragment>
            <Typography variant="body1" sx={{ margin: (theme) => (theme.breakpoints.down("sm") ? "16px" : "48px") }}>
                Suggesties van marktpartijen voor jou:
                <ul style={{ color: black, listStyleType: "none", padding: 0 }}>
                    {loading ? (
                        <FullPageSpinner />
                    ) : (
                        data?.getTopMarketParties?.map((item) => {
                            return (
                                <li key={item?.id} style={{ display: "flex", flexDirection: "row", margin: "2px 0px" }}>
                                    <FaviconBox favicon={item?.favicon_resized ?? item?.favicon ?? null} name={item?.name} color={pink} />

                                    <Link to={`/organizations/${item?.id}`} style={{ color: theme.palette.primary.main, cursor: "pointer" }}>
                                        {item?.name}
                                    </Link>
                                </li>
                            );
                        })
                    )}
                </ul>
            </Typography>
        </React.Fragment>
    );
};

export default MpContent;
