import React, { useState, useEffect } from "react";
import setTitle from "../../../utils/setTitle";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import OpportunitySearch from "../OpportunitySearch";
import { ColumnContext } from "../../../components/contextProviders/ColumnContext";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";

import { OpportunityFilterInput, OpportunitySearchInput, OpportunitySearchSource, SortOrder } from "../../../__generated__/globalTypes";
import InterestingOppTable from "./InterestingOppTable";
import { ColumnProvider } from "../../../components/contextProviders/ColumnProvider";
import { intOppColumns } from "./OppInterestingcolumns";
import {
    GetRatedDocsForOpportunities,
    GetRatedDocsForOpportunitiesVariables,
    GetRatedDocsForOpportunities_filterOpportunityFiles_filters,
    GetRatedDocsForOpportunities_filterOpportunityFiles_results,
    GetRatedDocsForOpportunities_filterOpportunityFiles_snippets,
} from "../../../__generated__/GetRatedDocsForOpportunities";
import { QUERY_RATED_DOCUMENTS } from "../../../graphql/opportunityQueries/queryDefinitions";
import { handleOppHighlighting } from "../../../components/HighlightOpportunity";
import { Box } from "@mui/material";

interface Props {
    a?: number;
}

const filters: OpportunityFilterInput = {};
const defaultSearch: OpportunitySearchInput = { filters };
const defaultVariables: GetRatedDocsForOpportunitiesVariables = {
    page: 1,
    first: 10,
    orderField: "publication_date",
    order: SortOrder.DESC,
    search: defaultSearch,
    source: OpportunitySearchSource.MY_OPPORTUNITY_FILES,
};
const InterestingOpportunities: React.FC<Props> = (props) => {
    const [searchInput, setSearchInput] = useState<OpportunityFilterInput>({});

    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(defaultVariables.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultVariables.first);
    const [
        orderField,
        // setOrderField
    ] = useState<string>(defaultVariables.orderField);
    const [
        orderDir,
        // setOrderDir
    ] = useState<SortOrder>(defaultVariables.order);

    const [query, setQuery] = useState<string>("");
    // query data
    const [oppData, setOppData] = useState<GetRatedDocsForOpportunities_filterOpportunityFiles_results | null>();
    // query filterdata
    const [filterData, setFilterData] = useState<GetRatedDocsForOpportunities_filterOpportunityFiles_filters[] | null>([]);
    // query snippets
    const [oppSnippets, setOppSnippets] = useState<(GetRatedDocsForOpportunities_filterOpportunityFiles_snippets | null)[] | null>();

    /**
     * Initialize state from url parameters
     */
    useEffect(() => {
        // Initial setup using query parameters
        // get active filterset from session storage
        if (sessionStorage.getItem("rof")) {
            setSearchInput(JSON.parse(sessionStorage.getItem("rof") || "{}"));
        }
        // get query from session storage
        if (sessionStorage.getItem("roq")) {
            setQuery(JSON.parse(sessionStorage.getItem("roq") || ""));
        }
        if (sessionStorage.getItem("rop")) {
            // set current page to sessionstorage value or 1 if sessionstorage isn't set
            setPage(parseInt(JSON.parse(sessionStorage.getItem("rop") || "1")));
        }
    }, [t]);

    const variables: GetRatedDocsForOpportunitiesVariables = {
        source: defaultVariables.source,
        first: rowsPerPage,
        page: page,
        orderField: orderField,
        order: orderDir,
        search: {
            query: query,
            filters: searchInput,
        },
    };

    /**
     * Define the GQL query
     */
    const [run, { error, loading }] = useLazyQuery<GetRatedDocsForOpportunities, GetRatedDocsForOpportunitiesVariables>(QUERY_RATED_DOCUMENTS, {
        variables: variables,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.filterOpportunityFiles) {
                setFilterData(data.filterOpportunityFiles.filters);
                setOppData(data.filterOpportunityFiles.results);
                setOppSnippets(data.filterOpportunityFiles.snippets);
                handleOppHighlighting();
            }
        },
    });

    // Set page title
    useEffect(() => {
        setTitle(t("Reviews"));
        sessionStorage.setItem("rof", JSON.stringify(searchInput));
        sessionStorage.setItem("rop", JSON.stringify(page));
        sessionStorage.setItem("roq", JSON.stringify(query));
        run({
            variables: {
                source: defaultVariables.source,
                page: page,
                first: rowsPerPage,
                orderField: orderField,
                order: orderDir,
                search: {
                    query: query,
                    filters: searchInput,
                },
            },
        });
    }, [run, t, page, rowsPerPage, orderField, orderDir, query, searchInput]);

    // handle pagination change: -page change, -rows per page change
    const handlePaginationChange = (page: number, rows: number) => {
        setPage(page);
        setRowsPerPage(rows);
        if (rows !== rowsPerPage) {
            setPage(1);
        }
    };

    //! handle table change: -sort column, -sort direction
    // const handleTableChange = (_: string, tableState: MUIDataTableState) => {
    //     if (tableState.sortOrder.direction) {
    //         setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
    //     }
    //     if (tableState.sortOrder.name) {
    //         setOrderField(tableState.sortOrder.name);
    //     }
    // };

    const renderPage = (content: React.ReactNode) => (
        <ColumnProvider storeKey="cuocr" defaultState={intOppColumns}>
            <ColumnContext.Provider value={{ selectedRows, setSelectedRows }}>
                <Box component="div" id="tender-root" sx={{ padding: { xs: "20px 8px", md: "20px" }, overflow: "scroll" }}>
                    {/*
                     * // TODO: not connected with GQL yet
                     */}
                    <OpportunitySearch
                        resetFilters={() => {
                            setSearchInput({});
                            setPage(1);
                        }}
                        columns={intOppColumns}
                        orderField={orderField}
                        order={orderDir}
                        total={oppData?.paginatorInfo.total}
                        loading={loading}
                        resetPage={() => setPage(1)}
                        searchInput={searchInput}
                        onChange={setSearchInput}
                        filterData={filterData}
                        visible={false}
                        query={query}
                        setQuery={setQuery}
                        startSearch={() => {
                            setPage(1);
                            run({ variables });
                        }}
                    />
                    {content}
                </Box>
            </ColumnContext.Provider>
        </ColumnProvider>
    );

    if (!oppData && loading) return renderPage(<FullPageSpinner />);
    if (error || !oppData)
        return renderPage(
            <InterestingOppTable
                loading={loading}
                snippets={null}
                error={error}
                rows={[]}
                paginatorInfo={{
                    count: 0,
                    currentPage: 0,
                    hasMorePages: false,
                    firstItem: 0,
                    lastItem: 0,
                    lastPage: 1,
                    perPage: 10,
                    total: 0,
                    __typename: "PaginatorInfo",
                }}
                onChangePagination={handlePaginationChange}
                onTableChange={undefined}
            />
        );

    return renderPage(
        <div id="opportunity-interesting">
            <InterestingOppTable
                loading={loading}
                snippets={oppSnippets ? oppSnippets : null}
                rows={oppData.data}
                paginatorInfo={
                    oppData.paginatorInfo
                        ? oppData.paginatorInfo
                        : {
                              count: 0,
                              currentPage: 0,
                              hasMorePages: false,
                              firstItem: 0,
                              lastItem: 0,
                              lastPage: 1,
                              perPage: 10,
                              total: 0,
                              __typename: "PaginatorInfo",
                          }
                }
                onChangePagination={handlePaginationChange}
                onTableChange={undefined}
            />
        </div>
    );
};

export default InterestingOpportunities;
