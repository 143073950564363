import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { GetCaTresholds_contracting_authority_thresholds } from "../../../../../__generated__/GetCaTresholds";

interface Props {
    data: GetCaTresholds_contracting_authority_thresholds[];
    title: string | null;
}

const ThresholdPaper: React.FC<Props> = ({ data, title }) => {
    return (
        <div style={{ margin: "8px" }}>
            <Typography variant="body2">{title}</Typography>
            <Paper
                square
                sx={{ padding: "16px", display: "flex", flexDirection: "column", borderTop: "4px solid orange", justifyContent: "space-between" }}
            >
                {/* 
                    rows with value(s) 
                */}
                {data.map((threshold, i) => {
                    return (
                        <div key={threshold.id} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                            {threshold.sum_from !== "" && threshold.sum_to !== "" && (
                                <Typography
                                    variant="h4"
                                    sx={{
                                        marginBottom: i === data.length - 1 ? "0px" : "8px",
                                        width: "50%",
                                    }}
                                >
                                    {threshold.subcat}
                                </Typography>
                            )}

                            {threshold.sum_from !== "" && threshold.sum_to !== "" && (
                                <Typography
                                    align="right"
                                    variant="h4"
                                    sx={{
                                        marginBottom: i === data.length - 1 ? "0px" : "8px",
                                        width: "25%",
                                    }}
                                >
                                    {threshold.sum_from === "<" || threshold.sum_from === ">" ? "" : threshold.currency}{" "}
                                    {threshold.sum_from?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                </Typography>
                            )}
                            {threshold.sum_from !== "" && threshold.sum_to !== "" && (
                                <Typography
                                    align="right"
                                    variant="h4"
                                    sx={{
                                        marginBottom: i === data.length - 1 ? "0px" : "8px",
                                        width: "25%",
                                    }}
                                >
                                    {threshold.sum_to === "<" || threshold.sum_to === ">" ? "" : threshold.currency}{" "}
                                    {threshold.sum_to?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                </Typography>
                            )}
                        </div>
                    );
                })}
            </Paper>
        </div>
    );
};

export default ThresholdPaper;
