import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";

// MUI import
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import ThumbUp from "@mui/icons-material/ThumbUp";

// GQL
import { FetchResult, InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { likeTender, likeTenderVariables } from "../__generated__/likeTender";
import { useLocation } from "react-router-dom";
import { LIKETENDER } from "../graphql/mutationDefinitions";

interface Props {
    tender_id: string;
    buttonSize: "small" | "medium";
    onClick?: () => void;
    refetchQueries?: InternalRefetchQueriesInclude | ((result: FetchResult<likeTender>) => InternalRefetchQueriesInclude) | undefined;
}

const LikeTenderButton: React.FC<Props> = ({ tender_id, buttonSize, onClick, refetchQueries }) => {
    const { t } = useTranslation();
    const [save, { loading: loadingMutation, error: errorMutation }] = useMutation<likeTender, likeTenderVariables>(LIKETENDER);
    const { pathname } = useLocation();
    const { trackEvent } = useMatomo();

    // Track event like tender
    const trackLike = () => {
        if (pathname === "/dashboard") trackEvent({ category: "Dashboard", action: "like-tender" });
        if (pathname.includes("tender")) trackEvent({ category: "Tender", action: "like-tender" });
        if (pathname.includes("contracting-authorities")) trackEvent({ category: "Ca", action: "like-tender" });
        if (pathname.includes("organizations")) trackEvent({ category: "Organizations", action: "like-tender" });
    };

    // Function to like a tender
    const likeTender = async () => {
        try {
            await save({
                variables: {
                    tenderID: tender_id,
                    reason: "",
                    remark: "",
                },
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
            if (errorMutation) {
                toast.error("Fout tijdens opslaan (error)");
            }
        }
    };

    return (
        <React.Fragment>
            <Tooltip enterNextDelay={100} title={t("interesting") as string} placement="top">
                <IconButton
                    data-testid="LikeTenderButton"
                    id="LikeTender"
                    disabled={loadingMutation}
                    onClick={(e) => {
                        e.stopPropagation();
                        likeTender();
                        onClick && onClick();
                        trackLike();
                    }}
                    size={buttonSize}
                >
                    <ThumbUp fontSize="small" />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

export default LikeTenderButton;
