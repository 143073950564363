import { createContext, useContext } from "react";

export type oppOpenType = {
    newOpp: boolean;
    setNewOpp: (newOpp: boolean) => void;
};

export const OpenOppContext = createContext<oppOpenType>({
    newOpp: false,
    setNewOpp: (newOpp) => console.warn("no theme provider"),
});
export const useOppOpener = () => useContext(OpenOppContext);
