import { gql } from "@apollo/client";

export const QUERY_ORGANIZATION_CURRENT_USER = gql`
    query OrganizationOfCurrentUser {
        currentUser {
            id
            email
            employee {
                id
                organization {
                    id
                }
            }
        }
    }
`;

export const QUERY_CURRENT_USER_TEST = gql`
    query DataOfCurrentUserToShow {
        currentUser {
            id
            subscription_admin
            employee {
                id
                name
                middlename
                familyname
                email
            }
        }
    }
`;

export const QUERY_CURRENT_USER_IS_SUB_ADMIN = gql`
    query UserIsAD {
        currentUser {
            id
            subscription_admin
        }
    }
`;

export const QUERY_CURRENT_USER_HAS_ID_STORED = gql`
    query UserHasIdStored {
        currentUser {
            id
        }
    }
`;

export const QUERY_SUBSCRIPTION_USERS = gql`
    query GetSubscriptionUsers($org_id: ID!) {
        organization(id: $org_id) {
            id
            name
            users {
                id
                verified
                subscription_admin
                email
                employee {
                    id
                    name
                    middlename
                    familyname
                }
            }
            subscriptions {
                seats
            }
        }
    }
`;

export const QUERY_SUBSCRIPTION_AND_TEAMS = gql`
    query GetScubscriptionAndTeams($org_id: ID!) {
        organization(id: $org_id) {
            id
            name
            teams {
                id
                name
                owner {
                    id
                    name
                }
                users {
                    id
                    subscription_admin
                    employee {
                        id
                        name
                        middlename
                        familyname
                        email
                    }
                }
            }
            subscriptions {
                seats
                allowedDomains {
                    id
                    domain
                }
                availableSeats
                adminUsers {
                    id
                }
                owner {
                    id
                }
                trial_ends_at
                ends_at
                items {
                    id
                }
                owner {
                    id
                    name
                }
            }
        }
    }
`;

export const ADD_USER_TO_TEAM = gql`
    mutation addUserToTeam($user_id: Int!, $team_id: Int!) {
        addUserToTeam(user_id: $user_id, team_id: $team_id)
    }
`;

export const REMOVE_USER_FROM_TEAM = gql`
    mutation removeUserFromTeam($user_id: Int!, $team_id: Int!) {
        removeUserFromTeam(user_id: $user_id, team_id: $team_id)
    }
`;

export const CREATE_NEW_TEAM = gql`
    mutation createTeam($teamName: String!) {
        createTeam(name: $teamName)
    }
`;

export const UPDATE_TEAM = gql`
    mutation updateTeam($teamId: ID!, $teamName: String!) {
        updateTeam(id: $teamId, name: $teamName)
    }
`;

export const DELETE_TEAM = gql`
    mutation deleteTeam($teamId: ID!) {
        deleteTeam(id: $teamId)
    }
`;

export const WEBINAR_LINK = gql`
    query webinar {
        currentUser {
            id
            webinar
        }
    }
`;
