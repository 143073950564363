import { Color } from "./SearchLineItem";

export const presetColors: Color[] = [
    {
        id: "8",
        colors: ["#7F462C", "#8B4513", "#A0522D", "#DEB887", "#F4A460"],
    },
    {
        id: "9",
        colors: ["#B8860B", "#C68E17", "#CCC88E", "#BDB76B", "#F0E68C"],
    },
    {
        id: "1",
        colors: ["#F87217", "#FFA500", "#FBB917", "#FFD700", "#FFE87C"],
    },
    {
        id: "2",
        colors: ["#FF0000", "#FF4500", "#FF7F50", "#FA8072", "#F08080"],
    },
    {
        id: "6",
        colors: ["#B22222", "#A52A2A", "#F75D59", "#FF69B4", "#E4287C"],
    },
    {
        id: "10",
        colors: ["#800080", "#E238EC", "#FF00FF", "#DDA0DD", "#FCC0CB"],
    },
    {
        id: "4",
        colors: ["#4B0082", "#6A5ACD", "#893BFF", "#8A2BE2", "#BA55D3"],
    },
    {
        id: "7",
        colors: ["#0020C2", "#0000FF", "#1F45FC", "#6495ED", "#87CEFA"],
    },
    {
        id: "3",
        colors: ["#008080", "#008B8B", "#20B2AA", "#46C7C7", "#ADD8E6"],
    },
    {
        id: "5",
        colors: ["#008000", "#808000", "#9ACD32", "#7FE817", "#ADFF2F"],
    },
    {
        id: "11",
        colors: ["#1E90FF", "#57FEFF", "#7FFFD4", "#98FB98", "#00FA9A"],
    },
    {
        id: "12",
        colors: ["#778899", "#696969", "#808080", "#A9A9A9", "#D3D3D3"],
    },
];
