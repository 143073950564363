import React from "react";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetDefaultSummary_tender_rawPublications } from "../../__generated__/GetDefaultSummary";

interface Props {
    open: boolean;
    data: GetDefaultSummary_tender_rawPublications[];
    onClose: () => void;
}
const ShowTitleHistory: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const { onClose, data, open } = props;
    const items = [...data];

    //newest date to oldest date
    const sorted = items.sort((a, b) => (moment(a.dispatchdate).isBefore(moment(b.dispatchdate)) ? 1 : -1));

    // handler to close modal
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog maxWidth={"md"} onClose={handleClose} aria-labelledby="simple-dialog" open={open}>
            <DialogTitle style={{ padding: "16px 16px 0 16px" }} id="simple-dialog-title">
                {t("TitleHistory")}
            </DialogTitle>

            <List dense>
                {sorted.map((item) => {
                    return (
                        <ListItem key={item.id}>
                            <ListItemText
                                primary={item.namegiventocontract?.replace(/\s/g, "·")}
                                secondary={
                                    <React.Fragment>
                                        {moment(item.dispatchdate).format("LL")} - {item.host}, {item.publication_type.name}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    );
                })}
            </List>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShowTitleHistory;
