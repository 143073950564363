import React from "react";
import { Grid, Typography } from "@mui/material";
import PollLayout from "./PollLayout";
import { usePoll } from "./PollContext";
import WaitingScreen from "./WaitingScreen";
import AnswerCard from "./AnswerCard";
import PollQuestionTotalAnswers from "./PollQuestionTotalAnswers";
import Lobby from "./Lobby";

interface Props {
    admin: boolean;
}

// Styled Paper component

const PollAdminPage: React.FC<Props> = ({ admin }) => {
    const { waiting, sendingAnswer, timer, chosenAnswerID, currentPollId, currentQuestion, endOfPoll } = usePoll();
    if (currentQuestion?.activeTendenzQuestion === null || endOfPoll) {
        return <Lobby endOfPoll={endOfPoll} admin />;
    }
    return (
        <PollLayout admin={admin}>
            <Grid container spacing={2}>
                {/* QUESTION */}
                <Grid item xs={12} sx={{ margin: "8px", display: "flex", flexDirection: "column", flex: 1 }}>
                    <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography align="center" sx={{ fontSize: "3rem", margin: "0px 96px" }}>
                            {currentQuestion?.activeTendenzQuestion?.text}
                        </Typography>
                    </div>
                </Grid>

                <Grid item container spacing={1} sx={{ margin: "16px 8px 16px 0px", alignSelf: "end", height: "50%" }}>
                    {/* Timer and counter with amount of users that already choose an answer */}
                    <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
                        <div
                            style={{
                                borderRadius: "50%",
                                backgroundColor: "#173357",
                                width: "75px",
                                height: "75px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography align="center" sx={{ fontSize: "3rem", fontWeight: 500, color: "#ffffff", marginTop: "-5px" }}>
                                {waiting && !admin ? 0 : timer}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        {/*
                         * ADMIN SCREEN TO START THE POLL
                         */}
                        {waiting ? (
                            // Waiting screen if waiting is true
                            // => True by default and true when a user refreshes page
                            <div style={{ height: "30vh", width: "100%", display: "flex" }}>
                                <WaitingScreen
                                    admin
                                    currentQuestionId={currentQuestion?.activeTendenzQuestion?.id as number}
                                    userAnswerId={chosenAnswerID}
                                />
                            </div>
                        ) : (
                            <Grid container item spacing={1}>
                                {/* Show question when waiting is not true */}
                                {currentQuestion?.activeTendenzQuestion?.answers?.map((ans, i: any) => {
                                    if (ans === null) return <></>;
                                    return (
                                        // {/* ANSWERS */}
                                        <Grid key={ans.id} item xs={12} md={6}>
                                            <AnswerCard
                                                answer={ans.text as string}
                                                index={i}
                                                onClickAnswer={() => {
                                                    sendingAnswer(
                                                        // question id
                                                        currentQuestion?.activeTendenzQuestion?.id?.toString() as string,
                                                        // answer id
                                                        ans.id?.toString() as string,
                                                        // quiz id
                                                        currentPollId.toString()
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <PollQuestionTotalAnswers currentQuestionId={currentQuestion?.activeTendenzQuestion?.id as number} />
                    </Grid>
                </Grid>
            </Grid>
        </PollLayout>
    );
};

export default PollAdminPage;
