import React from "react";
import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    open: boolean;
    onCancel: () => void;
    onExit: () => void;
    helpText: string;
    closeText: string;
    cancelText: string;
}
const WarningModal: React.FC<Props> = ({ onCancel, open, onExit, helpText, closeText, cancelText }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onCancel} maxWidth="md">
            <DialogTitle id="alert-dialog-title">{t("Warning")}!</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography>{helpText}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    {cancelText}
                </Button>
                <Button onClick={onExit} variant="contained" autoFocus color="primary">
                    {closeText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WarningModal;
