import React from "react";
import TopbarCalendar from "./TopbarCalendar";
import DayView from "./DayView";
import MonthViewMobile from "./MonthViewMobile";
import WeekViewMobile from "./WeekViewMobile";
import { SavedTenderEvents_getCalendarEvents } from "../../__generated__/SavedTenderEvents";

interface Props {
    currentMonth: string;
    loading: boolean;
    selectedDate: string;
    data: (SavedTenderEvents_getCalendarEvents | null)[] | undefined;
    view: number;
    setToday(): void;
    copyCal(): void;
    setView(view: number): void;
    nextHandler: (period: "month" | "week" | "day") => void;
    prevHandler: (period: "month" | "week" | "day") => void;
}

const CalendarMobile: React.FC<Props> = ({
    currentMonth,
    loading,
    selectedDate,
    data,
    view,
    copyCal,
    setView,
    setToday,
    nextHandler,
    prevHandler,
}) => {
    const renderSwitch = (view: number) => {
        switch (view) {
            case 0:
                return <MonthViewMobile currentMonth={currentMonth} selectedDate={selectedDate} data={data || []} />;
            case 1:
                return <WeekViewMobile currentMonth={currentMonth} selectedDate={selectedDate} data={data || []} />;
            default:
                return <DayView currentMonth={currentMonth} selectedDate={selectedDate} data={data || []} />;
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            <TopbarCalendar
                loading={loading}
                prevHandler={prevHandler}
                currentMonth={currentMonth}
                setToday={setToday}
                view={view}
                setView={setView}
                copyCal={copyCal}
                nextHandler={nextHandler}
            />
            {renderSwitch(view)}
        </div>
    );
};

export default CalendarMobile;
