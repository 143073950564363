import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import TotpOption from "./TotpOption";
import MailOption from "./MailOption";
import EnableBothAuthenticationOptions from "./EnableBothAuthenticationOptions";
import { useAuthSettings } from "./AuthSettingsContext";

interface Props {
    cancel: () => void;
}

// Styled Paper component
const StyledPaper = styled("div")(({ theme }) => ({
    padding: `16px`,
    display: "flex",
    justifyContent: "space-evenly",
}));

const ChooseOption: React.FC<Props> = ({ cancel }) => {
    const { data_two_factor } = useAuthSettings();

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <StyledPaper>
                <TotpOption />
                <MailOption />
                {!Boolean(data_two_factor?.currentUser.two_factor_totp_enabled) &&
                    !Boolean(data_two_factor?.currentUser.two_factor_email_enabled) && <EnableBothAuthenticationOptions />}
            </StyledPaper>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={cancel} size="small">
                    Sluiten
                </Button>
            </div>
        </div>
    );
};

export default ChooseOption;
