import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Disable } from "react-disable";
import FaviconBox from "../../../components/FaviconBox";
import { useTranslation, Translation } from "react-i18next";
import LinearLoader from "../../../components/loading/LinearLoader";
import CountryFlagBox from "../../../components/boxes/CountryFlagBox";
import { useColumnSetter } from "../../../components/contextProviders/ColumnContext";
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn } from "mui-datatables";
import { FilterMyTenderers_filterTenderers_results_data } from "../../../__generated__/FilterMyTenderers";
import { Paper, ThemeProvider, Theme, createTheme, Typography, Checkbox, useTheme, useMediaQuery } from "@mui/material";
import SmallOrgMenu from "../SmallOrgMenu";
import { useSelectedColumns } from "../../../components/contextProviders/ColumnProvider";
import MobileTableNavigation from "../../../components/MobileTableNavigation";
import TableNavigation from "../../../components/TableNavigation";
import { QUERY_USER_LIKED_ORGS } from "../../../graphql/queryDefCurrentUser";
import { QUERY_MY_TENDERERS } from "../../../graphql/queryDefOrg";
import LastMessageColumnType from "../../../components/columns/LastMessageColumnType";

interface Props {
    rows: FilterMyTenderers_filterTenderers_results_data[];
    paginatorInfo: any;
    onChangePagination(page: number, rowsPerPage: number): void;
    onTableChange: MUIDataTableOptions["onTableChange"];
    loading: boolean;
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// Column definition ////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

export const starredOrgColumns: MUIDataTableColumn[] = [
    // index 0
    {
        name: "id",
        options: { display: "excluded" },
    },
    // index 1
    {
        name: "favicon",
        options: { display: "excluded" },
    },
    // index 2
    {
        label: "Organization",
        name: "name",
        options: {
            display: true,

            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Organization")}</>}</Translation>;
            },
            customBodyRender(value: any, tableMeta) {
                const id = tableMeta.rowData[0];
                const favicon = tableMeta.rowData[1];
                return (
                    <React.Fragment>
                        {/* return name logo organization. */}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div>
                                {/* Favicon */}
                                <FaviconBox favicon={favicon !== null ? `${favicon}` : ""} name={value} color="#E03660" />
                            </div>
                            <div>
                                {/* link to tenderdetailpage, TODO: get tenderid to link to */}
                                <Typography noWrap>
                                    <Link id="Organization-name" to={`/organizations/${id}`}>
                                        {value}
                                    </Link>
                                </Typography>
                            </div>
                        </div>
                    </React.Fragment>
                );
            },
        },
    },
    // index 3
    {
        label: "Registration_number",
        name: "kvknumber",
        options: {
            display: true,

            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Registration_number")}</>}</Translation>;
            },
        },
    },
    // index 4
    {
        label: "City",
        name: "city",
        options: {
            display: true,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.City")}</>}</Translation>;
            },
            customBodyRender(value: any, tableMeta) {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {value && (
                            <div style={{ marginRight: 8, display: "flex", marginLeft: -4 }}>
                                <CountryFlagBox code={tableMeta.rowData[5]} />
                            </div>
                        )}
                        {value}
                    </div>
                );
            },
        },
    },
    // index 5
    {
        name: "country",
        options: { display: "excluded" },
    },
    // index 6
    {
        label: "Last_update",
        name: "last_update_date_timestamp",
        options: {
            display: true,
            sort: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.Last_update")}</>}</Translation>;
            },
        },
    },
    // index 7
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        label: "LastPrivateNote",
        name: "lastPrivateNote",
        options: {
            sort: false,
            display: true,
            draggable: false,
            customHeadLabelRender: () => {
                return <Translation>{(t) => <>{t("Column_Header.LastPrivateNote")}</>}</Translation>;
            },
            customBodyRender(tender_id: any, tableMeta) {
                return <LastMessageColumnType id={tableMeta.rowData[0]} module={"mp"} />;
            },
        },
    },
    // actions to like, dislike
    // index 8
    {
        // Label actions is an indicator for Columnchanger component to hide from options to show/hide.
        // actions are always visible!
        label: "Actions",
        name: "",
        options: {
            sort: false,
            display: true,
            draggable: false,
            customHeadLabelRender: () => {
                return <div />;
            },
            customBodyRender(title: any, tableMeta) {
                return (
                    <SmallOrgMenu
                        org_id={tableMeta.rowData[0]}
                        org_name={tableMeta.rowData[2]}
                        refetchQueries={[QUERY_USER_LIKED_ORGS, QUERY_MY_TENDERERS]}
                    />
                );
            },
        },
    },
];

/////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// Custom style table ///////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

const getMuiTheme = (theme: Theme) =>
    createTheme({
        palette: {
            primary: {
                main: "#E03660",
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro, system-ui, sans-serif",
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },
        components: {
            // MUIDataTable: {
            //     tableRoot: {
            //         width: "100% !important",
            //     },
            // },

            // Hide toolbar
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "none !important",
                    },
                },
            },

            // Custom hovercolor: opacity 7% -> add 12 to hexcode
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: `${"#E03660"}12 !important`,
                        },
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        textAlign: "start",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: "8px",
                    },
                },
            },
            // MUIDataTableHeadCell: {
            //     data: {
            //         textAlign: "start",
            //     },
            // },
        },
    });

/////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// Table properties /////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

const CustomCheckbox = (props: any) => {
    const newProps = Object.assign({}, props);
    newProps.color = "primary";

    return <Checkbox {...newProps} sx={{ padding: 0 }} />;
};

const StarredOrganizationTable: React.FC<Props> = ({ rows, paginatorInfo, onChangePagination, onTableChange, loading }) => {
    const { t } = useTranslation();
    // contextprovider contains columns and selected rows
    const { setSelectedRows, selectedRows } = useColumnSetter();
    const { items } = useSelectedColumns(starredOrgColumns);
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    /**
     * Data reformat definition, each container is a columnsection in table.
     */
    const convertedData = rows.map((item) => {
        const container: any = {};
        const favicon = "favicon";
        const name = "name";
        const city = "city";
        const id = "id";
        const kvknumber = "kvknumber";
        const country = "country";
        const last_update_date_timestamp = "last_update_date_timestamp";
        // show detail when its not equal to null, if its null -> show nothing
        const cities = item.settlings?.data.find((i) => (i.type === "hoofdvestiging" ? i : i.type === "rechtspersoon" ? i : null));

        container[id] = item.id;
        container[kvknumber] = item.kvknumber;
        container[city] = cities?.visit_address?.city;
        container[name] = item.name;
        container[favicon] = item.favicon_resized ?? item.favicon;
        container[country] = item.country?.alpha2;

        // Show newest date of a bid
        container[last_update_date_timestamp] = item.last_update_date !== null ? moment(item.last_update_date).format("LL") : "";

        return container;
    });

    /**
     * Fuction to get the selected rows and add id's of item inside row to context provider. now its possible to use the data inside to export,archive etc.
     */
    const onRowSelectionChange = (currentRowsSelected: Array<any>, allRowsSelected: Array<any>, rowsSelected: Array<any> | undefined) => {
        if (rowsSelected !== undefined) {
            const ids = rowsSelected.map((index) => convertedData[index].id);
            setSelectedRows(ids);
        }
    };

    const options: MUIDataTableOptions = {
        responsive: "standard",
        elevation: 0,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        filterType: "checkbox",
        draggableColumns: {
            enabled: false,
            transitionTime: 200,
        },
        selectableRowsOnClick: true,
        selectToolbarPlacement: "none",
        onRowSelectionChange,
        rowsSelected: selectedRows.map((id: string) => convertedData.findIndex((item) => item.id === id)),

        // Textual helpers with translation
        textLabels: {
            body: {
                noMatch: t("TableHelpers.NoMatch"),
                toolTip: t("TableHelpers.Sort"),
                columnHeaderTooltip: () => `${t("TableHelpers.Sort")}`,
            },
        },

        customFooter() {
            if (onlyMobile) {
                return (
                    <MobileTableNavigation
                        perPage={paginatorInfo.perPage}
                        total={paginatorInfo.total}
                        firstItemIndex={paginatorInfo.firstItem as number}
                        lastItemIndex={paginatorInfo.lastItem as number}
                        currentPage={paginatorInfo.currentPage}
                        onChangePagination={onChangePagination}
                    />
                );
            }
            return (
                <TableNavigation
                    perPage={paginatorInfo.perPage}
                    total={paginatorInfo.total}
                    firstItemIndex={paginatorInfo.firstItem as number}
                    lastItemIndex={paginatorInfo.lastItem as number}
                    currentPage={paginatorInfo.currentPage}
                    onChangePagination={onChangePagination}
                />
            );
        },
        onTableChange,
    };

    /**
     * Return table inside paper
     */
    return (
        <React.Fragment>
            <LinearLoader loading={loading} color="pink" />
            <Disable disabledOpacity={0.6} disabled={loading}>
                <Paper square>
                    {/* Added paper element around table to use the square variant instead of the default, 
                        - in MuidatatableOptons: elevation -> 0 */}
                    <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable title="" data={convertedData} columns={items} options={options} components={{ Checkbox: CustomCheckbox }} />
                    </ThemeProvider>
                </Paper>
            </Disable>
        </React.Fragment>
    );
};

export default StarredOrganizationTable;
