import React, { useState } from "react";

// import { useTranslation } from "react-i18next";
import { Grid, TextField, Select, FormControl, MenuItem, IconButton, Tooltip } from "@mui/material";
import { Add, Check, Euro, Event, Close } from "@mui/icons-material";
import moment from "moment";
import { GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities } from "../../__generated__/GetopportunityPagePersonalOpportunities";
import update from "immutability-helper";
import AssignmentIcon from "@mui/icons-material/Assignment";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";

interface Props {
    opportunity: GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities;
    setEditMode: (opportunity: GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities) => void;
    onSave: () => void;
}

const OpportunityEditMode: React.FC<Props> = ({ opportunity, setEditMode, onSave }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // const { t } = useTranslation();

    const nextYears = () => {
        const years = [];
        const dateStart = moment().add(1, "year");
        const dateEnd = moment().add(6, "y");
        while (dateEnd.diff(dateStart, "years") >= 0) {
            years.push(parseInt(dateStart.format("YYYY")));
            dateStart.add(1, "year");
        }
        return years;
    };

    const prevYears = () => {
        const years = [];
        const dateStart = moment();
        const dateEnd = moment().subtract(3, "y");
        while (dateStart.diff(dateEnd, "years") >= 0) {
            years.push(parseInt(dateStart.format("YYYY")));
            dateStart.subtract(1, "year");
        }
        return years;
    };

    const years = [...prevYears(), ...nextYears()];

    /**
     * Paste text from clipboard
     */
    const pasteText = async () => {
        setLoading(true);
        const field = document.getElementById(`pasteField${opportunity.id}`) as HTMLInputElement;
        navigator.clipboard.readText().then((text) => {
            if (text !== "") {
                field.value += text;
                // update previous comment with old and new text
                setEditMode(update(opportunity, { comment: { $set: opportunity.comment ? opportunity.comment + text : text } }));
                setLoading(false);
            }
            setLoading(false);
        });
    };

    return (
        <Grid container style={{ display: "flex", alignItems: "center" }}>
            <Grid
                xs={12}
                md={12}
                sx={{ padding: "10.5px 10px 10.5px 10px", width: "100%", display: "flex", alignItems: "center", marginBottom: "4px" }}
            >
                <TextField
                    id={`pasteField${opportunity.id}`}
                    autoFocus
                    fullWidth
                    size="small"
                    placeholder="Omschrijving"
                    multiline
                    maxRows={10}
                    variant="outlined"
                    style={{ marginRight: 8 }}
                    defaultValue={opportunity.comment ? opportunity.comment : ""}
                    InputProps={{
                        endAdornment: (
                            <div style={{ display: "flex" }}>
                                <IconButton disabled={loading} id="pasteBtn" size="small" aria-label="copy text" onClick={pasteText}>
                                    <AssignmentIcon fontSize="small" />
                                </IconButton>
                            </div>
                        ),
                    }}
                    onChange={(event) => setEditMode({ ...opportunity, comment: event.target.value })}
                />
            </Grid>
            {/* Grid with year selector */}
            <Grid
                xs={12}
                md={12}
                sx={{
                    padding: "10.5px 10px 10.5px 10px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {opportunity?.parsedValues?.map((p, index) => {
                        return (
                            <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/*
                                     * Edit field to change year
                                     */}
                                    <Event sx={{ marginRight: "10px", color: "#707070", marginLeft: "8px" }} />
                                    <FormControl>
                                        <Select
                                            displayEmpty
                                            labelId="demo-simple-select-label"
                                            variant="standard"
                                            id="demo-simple-select"
                                            value={p?.year}
                                            onChange={(event) => {
                                                setEditMode({
                                                    ...opportunity,
                                                    parsedValues: update(opportunity.parsedValues, {
                                                        [index]: { year: { $set: event.target.value as number } },
                                                    }),
                                                });
                                            }}
                                            disableUnderline
                                        >
                                            {years
                                                .sort((a, b) => moment(a).diff(b))
                                                .map((m, i) => {
                                                    return (
                                                        <MenuItem key={i} value={m as number}>
                                                            {m}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </div>

                                {/*
                                 * edit field to change value of set year
                                 */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/*
                                     * Grid with value inputfield
                                     */}
                                    <Euro sx={{ marginRight: "10px", color: "#707070", marginLeft: "8px" }} />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        inputProps={{ step: "0,01" }}
                                        size="small"
                                        placeholder="Waarde"
                                        style={{ marginRight: 8 }}
                                        value={p?.value ? p.value : ""}
                                        onChange={(event) => {
                                            // const amount = parseFloat(event.target.value).toFixed(2);
                                            const amount = event.target.value;
                                            setEditMode({
                                                ...opportunity,
                                                parsedValues: update(opportunity.parsedValues, {
                                                    [index]: { value: { $set: Number(amount) } },
                                                }),
                                            });
                                        }}
                                    />
                                </div>

                                {/*
                                 * Add button to add empty object to array
                                 */}
                                {opportunity.parsedValues !== undefined &&
                                    opportunity.parsedValues !== null &&
                                    opportunity?.parsedValues?.length - 1 === index && (
                                        <Tooltip title="" placement="top">
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                onClick={() =>
                                                    setEditMode({
                                                        ...opportunity,
                                                        parsedValues: update(opportunity.parsedValues, {
                                                            $push: [
                                                                {
                                                                    year: parseInt(moment().add(1, "year").format("YYYY")) as number,
                                                                    value: 0 as number,
                                                                    __typename: "PersonalOpportunityValue",
                                                                },
                                                            ],
                                                        }),
                                                    })
                                                }
                                            >
                                                <Add />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                {/* remove value button */}
                                {opportunity.parsedValues !== undefined &&
                                    opportunity.parsedValues !== null &&
                                    opportunity?.parsedValues?.length > 1 && (
                                        <Tooltip title="Regel verwijderen" placement="top">
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                onClick={() =>
                                                    setEditMode({
                                                        ...opportunity,
                                                        parsedValues: update(opportunity.parsedValues, {
                                                            $splice: [[index, 1]],
                                                        }),
                                                    })
                                                }
                                            >
                                                <Close />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </div>
                        );
                    })}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Kans opslaan" placement="top">
                        <IconButton
                            disabled={opportunity?.comment?.length === 0}
                            color="primary"
                            size="small"
                            onClick={() => {
                                onSave();
                                trackevents({ category: "Opportunity", action: "Click-save-edit-opportunity", value: parseInt(opportunity.id) });
                            }}
                        >
                            <Check />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Kans verwijderen" placement="top">
                        <IconButton
                            color="primary"
                            size="small"
                            //  onClick={() => onNoteRemove(item.id)}
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                </div>
            </Grid>
        </Grid>
    );
};

export default OpportunityEditMode;
