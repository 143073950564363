import React from "react";
import { Tooltip, Typography } from "@mui/material";

interface Props {
    data: (dataProp | null)[] | null;
    whiteSpace?: "normal" | "pre" | "nowrap" | "pre-wrap" | "pre-line" | "break-spaces";
    noDataSign?: string;
}

interface dataProp {
    __typename: "LotString";
    lots: (number | null)[];
    value: any | null;
}

const LotStringField: React.FC<Props> = ({ data, whiteSpace = "normal", noDataSign = "" }) => {
    if (!data || data.length === 0 || data?.filter((val) => val?.value !== null).length === 0) {
        return <>{noDataSign}</>;
    }

    return (
        <React.Fragment>
            {data.map((val, i: number) => {
                /**
                 * If lots are available -> show tooltip
                 */
                if (val?.lots !== undefined && val?.lots.length > 1) {
                    return (
                        <div key={i}>
                            <Tooltip
                                disableInteractive
                                data-testid="tooltip"
                                placement={"right"}
                                title={<>Perceel: {val?.lots.map((lot) => lot).join(", ")}</>}
                            >
                                <Typography
                                    style={{
                                        whiteSpace: whiteSpace,
                                        display: "inline-flex",
                                        borderBottom: `1px dashed #9e9e9e`,
                                        textDecoration: "none",
                                        cursor: "pointer",
                                    }}
                                >
                                    {val?.value ? val.value : ""}
                                </Typography>
                            </Tooltip>
                        </div>
                    );
                }

                if (val?.lots !== undefined && val?.lots.length > 0 && data.length > 1) {
                    return (
                        <div key={i}>
                            <Tooltip
                                disableInteractive
                                data-testid="tooltip"
                                placement={"right"}
                                title={<>Perceel: {val?.lots.map((lot) => lot).join(", ")}</>}
                            >
                                <Typography
                                    style={{
                                        whiteSpace: whiteSpace,
                                        display: "inline-flex",
                                        borderBottom: `1px dashed #9e9e9e`,
                                        textDecoration: "none",
                                        cursor: "pointer",
                                    }}
                                >
                                    {val?.value ? val.value : ""}
                                </Typography>
                            </Tooltip>
                        </div>
                    );
                }
                // return if no lots are available
                return (
                    <div key={i}>
                        <Typography style={{ whiteSpace: whiteSpace }}>{val?.value ? val.value : ""}</Typography>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default LotStringField;
