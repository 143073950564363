import React from "react";
import { SvgIcon } from "@mui/material";
import { LocationOn } from "@mui/icons-material";

import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import { renderToString } from "react-dom/server"; // Import renderToString from react-dom/server

import "leaflet/dist/leaflet.css";

// Define a custom icon using Material-UI's SvgIcon
const EventIcon = () => <SvgIcon component={LocationOn} style={{ fontSize: 48, color: "#173357" }} />;

const customMarkerStyle = `
.leaflet-div-icon {
  background-color: transparent; /* Custom background color */
  border: none; /* Custom border */
}
`;

const defaultPosition = {
    lat: 51.519501,
    lng: 5.18275,
    zoom: 12,
};

interface Props {
    a?: number;
}

const LeafletMap: React.FC<Props> = (props) => {
    const eventIcon = L.divIcon({
        html: renderToString(<EventIcon />),
        iconSize: [48, 48],
        iconAnchor: [24, 48], // Position of the icon anchor relative to marker center
    });

    // const LocationMarker = () => {
    //     const map = useMapEvents({
    //         click() {
    //             map.locate();
    //         },
    //         locationfound(e) {
    //             setPosition(e.latlng);
    //             map.flyTo(e.latlng, 8);
    //         },
    //     });

    //     /**
    //      * LocationMarker that pops up when user wants to see
    //      */
    //     return position === null ? null : (
    //         <Marker position={position} icon={customIcon}>
    //             <Popup>Gevonden locatie</Popup>
    //         </Marker>
    //     );
    // };

    const ComponentResize = () => {
        const map = useMap();

        setTimeout(() => {
            map.invalidateSize();
        }, 0);

        return null;
    };

    return (
        <div
            style={{
                width: "100%",
                height: 500,
            }}
        >
            <MapContainer
                // map settings
                center={[defaultPosition.lat, defaultPosition.lng]}
                zoom={defaultPosition.zoom}
                scrollWheelZoom={false}
                style={{
                    height: 500,
                    width: "100%",
                    position: "relative",
                }}
            >
                <ComponentResize />

                <style>{customMarkerStyle}</style>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <LocationMarker /> */}
                <Marker position={[defaultPosition.lat, defaultPosition.lng]} icon={eventIcon} />
            </MapContainer>
        </div>
    );
};

export default LeafletMap;
