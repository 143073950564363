import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear } from "@mui/icons-material";

interface Props {
    onRemove: () => void;
    active: boolean;
}

const RemoveCondition: React.FC<Props> = ({ onRemove, active }) => {
    return (
        <Tooltip placement="right" title="Verwijderen">
            <IconButton
                size="small"
                onClick={onRemove}
                sx={{
                    marginLeft: "2px",
                    color: "#c0392b50",
                    opacity: 0, // Initially hidden
                    visibility: "hidden", // Initially hidden from layout
                    transition: "opacity 0.3s ease, visibility 0s 0.3s", // Transition for opacity with a delay for visibility
                    padding: "2px",

                    "&:hover": {
                        color: "#c0392b",
                    },

                    // Apply when the groupId is active
                    ...(active && {
                        opacity: 1, // Fade in when active
                        visibility: "visible", // Make visible
                        transition: "opacity 0.3s ease, visibility 0s", // Visibility change without delay
                    }),
                }}
            >
                <Clear sx={{ width: "0.5em", height: "0.5em" }} />
            </IconButton>
        </Tooltip>
    );
};

export default RemoveCondition;
