import React, { useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { Typography, Tooltip, Button, IconButton } from "@mui/material";
import RenderPage from "../loading/RenderPage";
import FullPageSpinner from "../loading/FullPageSpinner";
import { GetOpportunityPageResults, GetOpportunityPageResultsVariables } from "../../__generated__/GetOpportunityPageResults";
// import { RatePageOfDocument, RatePageOfDocumentVariables } from "../../__generated__/RatePageOfDocument";
import { handleOppHighlighting } from "../HighlightOpportunity";
import { Visibility } from "@mui/icons-material";
import { ReactComponent as IconSeen } from "../../assets/icons/check-outlined.svg";
import { setPageReadState, setPageReadStateVariables } from "../../__generated__/setPageReadState";
import { QUERY_DETAILPAGE_OF_OPPORTUNITY, QUERY_RESULTS_OF_PAGE } from "../../graphql/queryDefinitions";
import { SET_PAGE_READ_STATE } from "../../graphql/mutationDefinitions";

interface Props {
    page_id: string;
    active: boolean;
    page_number: number | null;
    createNew?: (editMode: boolean) => void;
    onPageClick?: () => void;
    showPageNumber: boolean;
    read: boolean | null;
}

const SinglePageResult: React.FC<Props> = ({ page_number, page_id, createNew, active, onPageClick, showPageNumber, read }) => {
    /**
     * Run highlighting on every render
     * If not, highlighting will be gone on switch
     */
    useEffect(() => {
        handleOppHighlighting();
    }, []);

    /**
     * Get the page data
     */
    const { loading, error, data } = useQuery<GetOpportunityPageResults, GetOpportunityPageResultsVariables>(QUERY_RESULTS_OF_PAGE, {
        variables: { page_id: parseInt(page_id) },
        onCompleted: () => {
            handleOppHighlighting();
        },
    });

    /**
     * mutation to rate page of document
     */
    const [rate, { loading: loadingPageState, error: errorMutation }] = useMutation<setPageReadState, setPageReadStateVariables>(SET_PAGE_READ_STATE);

    /**
     * @param pageid id of page that is being rated
     * @param rated is boolean if rate is true (thumbs up) or false (thumbs down)
     */
    const setPageState = async (pageid: string, rated: boolean) => {
        if (errorMutation) {
        }
        try {
            await rate({
                variables: {
                    id: pageid,
                    status: rated,
                },
                refetchQueries: [QUERY_DETAILPAGE_OF_OPPORTUNITY, "GetOppDetailPage"],
            });
        } catch (e) {}
    };

    /**
     * if page is loading show spinner
     */
    if (loading) {
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    /**
     * if error occurs or no data is found show error
     */
    if (error || !data?.opportunityPageResults) {
        return (
            <RenderPage>
                <p>Fout tijdens laden van pagina</p>
            </RenderPage>
        );
    }

    /**
     * If data is available show component
     */
    return (
        <div
            style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", maxHeight: "200px", overflow: "auto", width: "100%" }}
        >
            <div>
                {showPageNumber && <Typography variant="h6">Pagina {page_number}</Typography>}
                <div id={"result"} style={{ maxHeight: 145, overflow: "auto" }}>
                    {data.opportunityPageResults.map((result) => {
                        return (
                            <Typography
                                key={result.id}
                                style={{ padding: "0px 8px 8px 0" }}
                                dangerouslySetInnerHTML={{
                                    __html: result.snippet ? `...${result.snippet}...` : "",
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginRight: "8px" }}>
                <div>
                    <Tooltip title="Kans toevoegen" placement="top" style={{ marginRight: 4 }}>
                        <Button
                            style={{ marginRight: 8 }}
                            disabled={!active}
                            color="primary"
                            size="small"
                            onClick={() => {
                                if (createNew) {
                                    createNew(true);
                                }
                            }}
                        >
                            Voeg toe
                        </Button>
                    </Tooltip>
                    {/*
                     * Button to set page to read
                     * Don't show button if read = true
                     */}
                    {read !== true && (
                        <Tooltip title="Pagina markeren als gezien" placement="top" style={{ marginRight: 4 }}>
                            <IconButton disabled={loadingPageState} size="small" onClick={() => setPageState(page_id, true)}>
                                <IconSeen style={{ width: "22px", height: "22px", fill: "#707070" }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
                {active === false && onPageClick && (
                    <Tooltip title="Pagina bekijken" placement="top" style={{ marginRight: 4 }}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                onPageClick();
                            }}
                        >
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default SinglePageResult;
