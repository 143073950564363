import React, { useEffect, useState } from "react";
import { Button, FormControlLabel, Stack, styled, Switch, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProfileLayout from "../ProfileLayout";
import setTitle from "../../../utils/setTitle";
import { useLazyQuery } from "@apollo/client";
import { QUERY_CURRENT_USER_TEST, QUERY_SUBSCRIPTION_AND_TEAMS, QUERY_SUBSCRIPTION_USERS } from "../../../graphql/queryDefUserAndTeams";
import { useCurrentUserOrganization } from "../../../components/contextProviders/CurrentUserOrganization";
// import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { GetScubscriptionAndTeams } from "../../../__generated__/GetScubscriptionAndTeams";
import { GetSubscriptionUsers } from "../../../__generated__/GetSubscriptionUsers";
import { DataOfCurrentUserToShow } from "../../../__generated__/DataOfCurrentUserToShow";
import UserAndTeamsContent from "./UserAndTeamsContent";

export interface UserInTeam {
    __typename?: "User" | undefined;
    id: string;
    employee: Employee;
    verified: boolean | null;
    email: string;
    subscription_admin?: any;
}

export interface Employee {
    __typename?: "Employee" | undefined;
    id: string;
    name?: string | null | undefined;
    middlename?: string | null | undefined;
    familyname?: string | null | undefined;
}

export interface TeamInSub {
    __typename?: "Team" | undefined;
    id: string;
    name: string;
    owner?:
        | {
              __typename?: "User" | undefined;
              id: string;
              name: string;
          }
        | null
        | undefined;
    users: User[];
    newTeam?: boolean;
}

export interface User {
    __typename?: "User" | undefined;
    id: string;
    employee: Employee;
    //temporary field
    toBeDeleted?: boolean;
    subscription_admin?: any;
}
const userIcon = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24" fill="none"><path d="M12 12.75c1.63 0 3.07.39 4.24.9 1.08.48 1.76 1.56 1.76 2.73V18H6v-1.61c0-1.18.68-2.26 1.76-2.73 1.17-.52 2.61-.91 4.24-.91M4 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m1.13 1.1c-.37-.06-.74-.1-1.13-.1-.99 0-1.93.21-2.78.58C.48 14.9 0 15.62 0 16.43V18h4.5v-1.61c0-.83.23-1.61.63-2.29M20 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m4 3.43c0-.81-.48-1.53-1.22-1.85-.85-.37-1.79-.58-2.78-.58-.39 0-.76.04-1.13.1.4.68.63 1.46.63 2.29V18H24zM12 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3" fill="%23fff"/></svg>')`;

const teamIcon = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24" fill="none"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4" fill="%23fff"/></svg>')`;

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
        margin: 1,
        padding: 0,
        transform: "translateX(6px)",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(22px)",
            "& .MuiSwitch-thumb:before": {
                backgroundImage: userIcon,
            },
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
        width: 32,
        height: 32,
        "&::before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: teamIcon,
        },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        borderRadius: 20 / 2,
    },
}));

const UsersAndTeams: React.FC = () => {
    const { t } = useTranslation();
    const [toggle, setToggle] = useState<boolean>(true);
    const [teamSubList, setTeamSubList] = useState<TeamInSub[]>([]);
    const [currentUser, setCurrentUser] = useState<User>();
    const { org_id } = useCurrentUserOrganization();
    const [userList, setUserList] = useState<UserInTeam[]>([]);
    const [reset, setReset] = useState<boolean>(false);
    const [mobileView, setMobileView] = useState<"users" | "teams">("users");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMobileView(event.target.checked ? "teams" : "users");
    };

    /**
     * Query current user
     */
    const [runQueryTest, { loading: loadUser, error: errorUser }] = useLazyQuery<DataOfCurrentUserToShow>(QUERY_CURRENT_USER_TEST, {
        onCompleted: (data) => {
            if (data && data.currentUser) {
                setCurrentUser(data.currentUser);
            }
        },
    });

    /**
     * Query organization & teams of current user
     */
    const [runSubAndTeams, { data, loading: loadingTeams, error: errorTeams }] = useLazyQuery<GetScubscriptionAndTeams>(
        QUERY_SUBSCRIPTION_AND_TEAMS,
        {
            variables: {
                org_id: org_id,
            },
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data && data.organization?.teams !== undefined && data.organization?.teams.length > 0) {
                    setTimeout(() => {
                        setToggle(false);
                    }, 600);
                } else if (isMobile) {
                    setToggle(false);
                }
            },
        }
    );

    /**
     * Query users of organization
     */
    const [runUserQuery, { loading: loadingUsers, error: errorUsers, refetch }] = useLazyQuery<GetSubscriptionUsers>(QUERY_SUBSCRIPTION_USERS, {
        variables: {
            org_id: org_id,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.organization && data.organization.users && data.organization.users) {
                setUserList(data.organization.users);
            }
        },
    });

    useEffect(() => {
        setUserList([]);
        runSubAndTeams({
            variables: {
                org_id: org_id,
            },
        });
        runQueryTest();
        runUserQuery({
            variables: {
                org_id: org_id,
            },
        });
        setReset(false);
    }, [runUserQuery, org_id, runSubAndTeams, runQueryTest, reset]);

    /**
     * Set tab title
     */
    useEffect(() => {
        setTitle(t("UsersAndTeams"));
        if (data && data.organization && data.organization.teams) {
            const key = "id";
            const arrayUniqueByKey = [...new Map(data.organization.teams.map((item) => [item[key], item])).values()];

            setTeamSubList(arrayUniqueByKey);
        }
    }, [data, t]);

    /**
     * Add new team to teams array
     */
    const handleNewTeam = () => {
        setToggle(false);
        const teamId = (teamSubList.length + 10).toString();
        setTeamSubList([
            ...teamSubList,
            {
                newTeam: true,
                id: teamId,
                name: "Voer een teamnaam in",
                users: [],
                owner: {
                    id: currentUser?.id || "",
                    name: currentUser?.employee.name || "",
                },
            },
        ]);
    };

    /**
     * Button in animation to create a new team.
     */
    const handleToggle = () => {
        setToggle(!toggle);
    };

    /**
     * Filter teamlist => show teams were user is teamowner or user in team
     * Sort new team first (local property will be set when user adds a new team)
     * Sort on team id (high => low)
     */
    const copyOfTeamSubList = teamSubList
        .filter((team) => team.users.some((u) => u.id === localStorage.getItem("user_id")) || team.owner?.id === localStorage.getItem("user_id"))
        .sort(
            (a, b) =>
                Number(b.newTeam === undefined ? false : true) - Number(a.newTeam === undefined ? false : true) || parseInt(b.id) - parseInt(a.id)
        );

    // get all subscriptions and get only string versions in domain
    const domainss = data?.organization?.subscriptions.flatMap((d) => d.allowedDomains.filter((a) => typeof a?.domain === "string")) || [];

    const adminUsersInSub = data?.organization?.subscriptions.flatMap((t) => t.adminUsers.filter((b) => b?.id));

    const renderPage = (content: React.ReactNode) => {
        return (
            <ProfileLayout
                parentOverflow={false}
                title={
                    isMobile ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <h3>Gebruikers</h3>
                            <FormControlLabel
                                control={<MaterialUISwitch checked={mobileView === "teams" ? true : false} onChange={handleChange} />}
                                label=""
                            />
                            <h3>Teams</h3>
                        </Stack>
                    ) : (
                        <h3>{t("UsersAndTeams")}</h3>
                    )
                }
                componentToPassDown={
                    <React.Fragment>
                        {!isMobile && (
                            <Button onClick={handleToggle} variant="text" style={{ marginRight: 8 }}>
                                {/* Show text based on toggle state */}
                                {toggle ? "Verberg uitleg" : "Bekijk uitleg"}
                            </Button>
                        )}
                        {!isMobile && (
                            <Button
                                color="grey"
                                disabled={copyOfTeamSubList.length > 0 && copyOfTeamSubList[0].name === "Voer een teamnaam in"}
                                onClick={handleNewTeam}
                                variant="contained"
                                style={{ marginRight: 8 }}
                            >
                                maak team
                            </Button>
                        )}
                    </React.Fragment>
                }
            >
                {/* * DndProvider element for drag and drop function in this view. */}
                <div style={{ height: "100%" }}>
                    <DndProvider backend={HTML5Backend}>{content}</DndProvider>
                </div>
            </ProfileLayout>
        );
    };
    // Loading state of multiple queries
    const loading = loadingTeams || loadingUsers || loadUser;

    // Error state of multiple queries
    const errors = errorTeams || errorUsers || errorUser;

    return renderPage(
        <UserAndTeamsContent
            setReset={() => {
                refetch();
                setReset(true);
                setUserList([]);
            }}
            mobileView={mobileView}
            setUserList={setUserList}
            teamSubList={copyOfTeamSubList}
            userList={userList}
            toggle={toggle}
            error={errors !== undefined}
            loading={loading}
            seats={data?.organization?.subscriptions[0].availableSeats}
            totalSeats={data?.organization?.subscriptions[0].seats}
            subOwner={adminUsersInSub}
            allowedDomains={domainss}
        />
    );
};

export default UsersAndTeams;
