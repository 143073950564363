import React from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { useQuery } from "@apollo/client";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton, Typography } from "@mui/material";
import { People } from "@mui/icons-material";
import { ORGANIZATION_DATA_NOTIFICATION } from "../../graphql/queryDefNotifications";
import { GetOrganizationNotificationData, GetOrganizationNotificationDataVariables } from "../../__generated__/GetOrganizationNotificationData";
import FaviconBox from "../FaviconBox";
import TooltipOnNewLines from "../TooltipOnNewLines";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(e: any): void;
    keyIndex: number;
}

/**
 * Display a single notification row for the notification Dialog
 */
const NewAwardForMarketParty: React.FC<Props> = ({ notification, onClick, keyIndex }) => {
    const pink = "#E03660";
    const blue = "#173357";

    // notification.data[0] -> tender id
    // notification.data[1] -> organization id
    // notification.data[2] -> string met message?

    // const { loading, data: tender } = useQuery<GetTenderNotificationData, GetTenderNotificationDataVariables>(TENDER_DATA_NOTIFICATION, {
    //     skip: notification === null || notification.data === null,
    //     variables: {
    //         tender_id: notification !== null && notification.data !== null ? (notification.data[1] as string) : "",
    //     },
    // });

    const { loading: loadOrg, data: org } = useQuery<GetOrganizationNotificationData, GetOrganizationNotificationDataVariables>(
        ORGANIZATION_DATA_NOTIFICATION,
        {
            skip: notification === null || notification.data === null,
            variables: {
                org_id: notification !== null && notification.data !== null ? (notification.data[1] as string) : "",
            },
        }
    );

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (loadOrg) {
        return (
            <MenuItem key={keyIndex} style={{ whiteSpace: "normal", maxHeight: 250, opacity: notification.read_at !== null ? 0.5 : undefined }}>
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    return (
        <MenuItem
            key={keyIndex}
            onClick={() => onClick(org?.organization?.id as string)}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: pink }}>
                    <People />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <TooltipOnNewLines
                        placement={"bottom-start"}
                        text={notification.data[2] || ""}
                        variant={"body1"}
                        lines={2}
                        id={`T${notification.id as string}`}
                        fontweight={notification.read_at === null ? 500 : "normal"}
                        fontSize={14}
                    />
                }
                secondary={
                    // Show message
                    org?.organization?.name && (
                        <Typography
                            style={{
                                fontSize: 12,
                                // When the nofitication is unread, the date-part of the notification is bold and accent colored
                                color: notification?.read_at ? blue : undefined,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <FaviconBox
                                favicon={org.organization.favicon_resized ?? org.organization.favicon}
                                name={org?.organization?.name}
                                color={pink}
                            />{" "}
                            <TooltipOnNewLines
                                placement={"top-start"}
                                text={org?.organization?.name || ""}
                                variant={"body1"}
                                lines={1}
                                id={`T${notification.id as string}${org.organization.id}`}
                                fontweight={"normal"}
                                fontSize={12}
                            />
                        </Typography>
                    )
                }
            />
        </MenuItem>
    );
};

export default NewAwardForMarketParty;
