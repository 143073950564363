import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import WidgetBox from "../WidgetBox";
import "./ContractingAuthority.scss";
import { Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

//icons used in this widget
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PrintIcon from "@mui/icons-material/Print";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import MailIcon from "@mui/icons-material/Mail";
import GeneralInformationModule from "../GeneralInformationModule";
// import StatusChip from "../StatusChip";
import SecondCA from "./SecondCA";
import WidgetBoxMenu from "../WidgetBoxMenu";
import { useQuery } from "@apollo/client";
import { GetStarredCas } from "../../__generated__/GetStarredCas";
import LikeSelectedCas from "../LikeSelectedCas";
import _ from "lodash";
import CABoxPrimary from "./CABoxPrimary";
import anchorNewTab from "../../utils/anchorNewTab";
import { Launch } from "@mui/icons-material";
import CaExpandModal from "./CaExpandModal";
import { GetCaDataForWidgetOfCurrentTender_tender_clients } from "../../__generated__/GetCaDataForWidgetOfCurrentTender";
import { QUERY_GETSTARREDCAS } from "../../graphql/queryDefCurrentUser";

interface Props extends WithTranslation {
    primary?: Record<string, React.ReactNode> | null;
    phones: any[] | undefined;
    emails: any[] | undefined;
    others: Record<string, React.ReactNode>[];
    contact: Record<string, React.ReactNode>;
    clients: GetCaDataForWidgetOfCurrentTender_tender_clients[];
}

const ContractingAuthorityBox: React.FC<Props> = ({ t, primary, others, contact, phones, emails, clients }) => {
    const [openModal, setOpenModal] = useState(false);
    const theme = useTheme();
    const blue = "#465c84";
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    //submenu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { error, data } = useQuery<GetStarredCas>(QUERY_GETSTARREDCAS);

    if (error || data?.currentUser?.contracting_authorities === undefined || data?.currentUser?.contracting_authorities === null) {
        return null;
    }
    /**
     * get data as starredCAS
     */
    const starredCAS = data.currentUser.contracting_authorities;

    /**
     * Get id's of Ca with category 'B'
     */
    const secIds = _.map(others, "id");

    /**
     * Get id of primary Ca and combine with secondary Ca's
     */
    const primaryID = _.concat(primary?.id, secIds);
    const stagingLogo = primary !== null && primary !== undefined && (primary?.logo as string);
    const logourl = stagingLogo && stagingLogo.replace("staging-", "");

    // const handleCopy = () => {
    //     navigator.clipboard.writeText("todo");
    //     enqueueSnackbar(t("Gegevens gekopieërd"), { variant: "success", autoHideDuration: 1500 });
    //     setAnchorEl(null);
    // };

    function validateText(string: string) {
        if (/(http(s?)):\/\//i.test(string)) {
            return string;
        } else {
            return "https://" + string;
        }
    }

    return (
        <React.Fragment>
            <WidgetBox
                className="ContractingAuthorityBox"
                header={t("contractingAuthority")}
                highlight="yellow"
                padding={0}
                actions={
                    // TODO: create function to copy
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {others.length > 0 && (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setOpenModal(!openModal);
                                }}
                            >
                                <Launch fontSize={"small"} />
                            </IconButton>
                        )}
                        <WidgetBoxMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onButtonClick={handleClick}>
                            {primary !== null && primary !== undefined && <LikeSelectedCas menuItem selection={primaryID} onClose={handleClose} />}
                        </WidgetBoxMenu>
                    </div>
                }
            >
                {others.length === 0 ? (
                    <Grid container style={{ padding: "8px 16px" }} spacing={2}>
                        <Grid item xs={12} md={6}>
                            {/* // Primary CA info */}
                            {primary !== null && primary !== undefined && <CABoxPrimary primary={primary} starredCAS={starredCAS} logo={logourl} />}
                        </Grid>
                        {/* Primary contact info */}
                        <Grid item xs={12} md={6}>
                            {/* Primary ca contactperson */}
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", padding: "0px" }}>
                                {/* published address data */}
                                <div style={{ width: "100%" }}>
                                    <div>
                                        <div style={{ marginRight: 8 }}>
                                            <div>
                                                <div style={{ marginBottom: 8 }}>
                                                    {/* PERSON */}
                                                    {contact.name && (
                                                        <GeneralInformationModule icon={<PersonIcon fontSize="small" />}>
                                                            <ul style={{ listStyle: "none", margin: onlyMobile ? "0px" : "0 0 0 16px", padding: 0 }}>
                                                                <li>
                                                                    <Typography noWrap>{contact.name}</Typography>
                                                                </li>
                                                            </ul>
                                                        </GeneralInformationModule>
                                                    )}
                                                    {/* Phone */}
                                                    {contact.phone ? (
                                                        <GeneralInformationModule
                                                            icon={
                                                                contact.phoneType === "mobile" ? (
                                                                    <PhoneIphoneIcon fontSize="small" />
                                                                ) : (
                                                                    <PhoneIcon fontSize="small" />
                                                                )
                                                            }
                                                        >
                                                            <ul style={{ listStyle: "none", margin: onlyMobile ? "0px" : "0 0 0 16px", padding: 0 }}>
                                                                <a href={`tel: ${contact.phone}`} style={{ color: blue }}>
                                                                    <li>
                                                                        <Typography noWrap>{contact.phone}</Typography>
                                                                    </li>
                                                                </a>
                                                            </ul>
                                                        </GeneralInformationModule>
                                                    ) : phones ? (
                                                        phones.map((p) => {
                                                            return (
                                                                <GeneralInformationModule
                                                                    key={p.id}
                                                                    icon={
                                                                        p.type === "PHONE" ? (
                                                                            <PhoneIcon fontSize="small" />
                                                                        ) : (
                                                                            <PrintIcon fontSize="small" />
                                                                        )
                                                                    }
                                                                >
                                                                    <ul
                                                                        style={{
                                                                            listStyle: "none",
                                                                            margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                                            padding: 0,
                                                                        }}
                                                                    >
                                                                        <a href={`tel: ${p.phonenumber}`} style={{ color: blue }}>
                                                                            <li>
                                                                                <Typography noWrap>{p.phonenumber}</Typography>
                                                                            </li>
                                                                        </a>
                                                                    </ul>
                                                                </GeneralInformationModule>
                                                            );
                                                        })
                                                    ) : null}
                                                    {/* EMAIL */}
                                                    {contact.email ? (
                                                        <GeneralInformationModule icon={<MailIcon fontSize="small" />}>
                                                            <ul style={{ listStyle: "none", margin: onlyMobile ? "0px" : "0 0 0 16px", padding: 0 }}>
                                                                <a href={`mailto: ${contact.email}`} style={{ color: blue }}>
                                                                    <li>
                                                                        <Typography noWrap>{contact.email}</Typography>
                                                                    </li>
                                                                </a>
                                                            </ul>
                                                        </GeneralInformationModule>
                                                    ) : emails ? (
                                                        emails.map((e) => {
                                                            return (
                                                                <GeneralInformationModule key={e.id} icon={<MailIcon fontSize="small" />}>
                                                                    <ul
                                                                        style={{
                                                                            listStyle: "none",
                                                                            margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                                            padding: 0,
                                                                        }}
                                                                    >
                                                                        <a href={`mailto: ${e.email}`} style={{ color: blue }}>
                                                                            <li>
                                                                                <Typography noWrap>{e.email}</Typography>
                                                                            </li>
                                                                        </a>
                                                                    </ul>
                                                                </GeneralInformationModule>
                                                            );
                                                        })
                                                    ) : null}
                                                    {/* Linkedin */}
                                                    {/* <GeneralInformationModule icon={<LinkedInIcon fontSize="small" />}>
                                                                <ul     style={{listStyle: "none", margin: onlyMobile ? "0px":"0 0 0 16px",padding: 0}}
> */}
                                                    {/* added https://  */}
                                                    {/* <a href={"#"} target="_blank" style={{ color: blue }} rel="noopener noreferrer"> */}
                                                    {/* <li>
                                                                        <Typography noWrap>{"linkedin"}</Typography>
                                                                    </li> */}
                                                    {/* </a> */}
                                                    {/* </ul>
                                                            </GeneralInformationModule> */}
                                                    {/* WEBSITE */}
                                                    {contact.website ? (
                                                        <GeneralInformationModule icon={<LanguageIcon fontSize="small" />}>
                                                            <ul style={{ listStyle: "none", margin: onlyMobile ? "0px" : "0 0 0 16px", padding: 0 }}>
                                                                {/* added https://  */}

                                                                <a {...anchorNewTab} href={validateText(contact.website as string) || ""}>
                                                                    <li>
                                                                        <Typography noWrap>{contact.website}</Typography>
                                                                    </li>
                                                                </a>
                                                            </ul>
                                                        </GeneralInformationModule>
                                                    ) : (
                                                        <GeneralInformationModule icon={<LanguageIcon fontSize="small" />}>
                                                            <ul style={{ listStyle: "none", margin: onlyMobile ? "0px" : "0 0 0 16px", padding: 0 }}>
                                                                {/* added https://  */}
                                                                <a {...anchorNewTab} href={validateText(primary?.website as string) || ""}>
                                                                    <li>
                                                                        <Typography noWrap>{primary?.website}</Typography>
                                                                    </li>
                                                                </a>
                                                            </ul>
                                                        </GeneralInformationModule>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container style={{ padding: "8px 16px" }} spacing={2}>
                        <Grid container item xs={12} md={6}>
                            <Grid item xs={12}>
                                {primary !== null && primary !== undefined && (
                                    <CABoxPrimary primary={primary} starredCAS={starredCAS} logo={logourl} />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {/* Primary ca contactperson */}
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", padding: "0px" }}>
                                    {/* published address data */}
                                    <div style={{ width: "100%" }}>
                                        <div>
                                            <div style={{ marginRight: 8 }}>
                                                <div>
                                                    <div style={{ marginBottom: 8 }}>
                                                        {/* PERSON */}
                                                        {contact.name && (
                                                            <GeneralInformationModule icon={<PersonIcon fontSize="small" />}>
                                                                <ul
                                                                    style={{
                                                                        listStyle: "none",
                                                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                                        padding: 0,
                                                                    }}
                                                                >
                                                                    <li>
                                                                        <Typography noWrap>{contact.name}</Typography>
                                                                    </li>
                                                                </ul>
                                                            </GeneralInformationModule>
                                                        )}
                                                        {/* Phone */}
                                                        {contact.phone ? (
                                                            <GeneralInformationModule
                                                                icon={
                                                                    contact.phoneType === "mobile" ? (
                                                                        <PhoneIphoneIcon fontSize="small" />
                                                                    ) : (
                                                                        <PhoneIcon fontSize="small" />
                                                                    )
                                                                }
                                                            >
                                                                <ul
                                                                    style={{
                                                                        listStyle: "none",
                                                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                                        padding: 0,
                                                                    }}
                                                                >
                                                                    <a href={`tel: ${contact.phone}`} style={{ color: blue }}>
                                                                        <li>
                                                                            <Typography noWrap>{contact.phone}</Typography>
                                                                        </li>
                                                                    </a>
                                                                </ul>
                                                            </GeneralInformationModule>
                                                        ) : phones ? (
                                                            phones.map((p) => {
                                                                return (
                                                                    <GeneralInformationModule
                                                                        key={p.id}
                                                                        icon={
                                                                            p.type === "PHONE" ? (
                                                                                <PhoneIcon fontSize="small" />
                                                                            ) : (
                                                                                <PrintIcon fontSize="small" />
                                                                            )
                                                                        }
                                                                    >
                                                                        <ul
                                                                            style={{
                                                                                listStyle: "none",
                                                                                margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <a href={`tel: ${p.phonenumber}`} style={{ color: blue }}>
                                                                                <li>
                                                                                    <Typography noWrap>{p.phonenumber}</Typography>
                                                                                </li>
                                                                            </a>
                                                                        </ul>
                                                                    </GeneralInformationModule>
                                                                );
                                                            })
                                                        ) : null}
                                                        {/* EMAIL */}
                                                        {contact.email ? (
                                                            <GeneralInformationModule icon={<MailIcon fontSize="small" />}>
                                                                <ul
                                                                    style={{
                                                                        listStyle: "none",
                                                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                                        padding: 0,
                                                                    }}
                                                                >
                                                                    <a href={`mailto: ${contact.email}`} style={{ color: blue }}>
                                                                        <li>
                                                                            <Typography noWrap>{contact.email}</Typography>
                                                                        </li>
                                                                    </a>
                                                                </ul>
                                                            </GeneralInformationModule>
                                                        ) : emails ? (
                                                            emails.map((e) => {
                                                                return (
                                                                    <GeneralInformationModule key={e.id} icon={<MailIcon fontSize="small" />}>
                                                                        <ul
                                                                            style={{
                                                                                listStyle: "none",
                                                                                margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            <a href={`mailto: ${e.email}`} style={{ color: blue }}>
                                                                                <li>
                                                                                    <Typography noWrap>{e.email}</Typography>
                                                                                </li>
                                                                            </a>
                                                                        </ul>
                                                                    </GeneralInformationModule>
                                                                );
                                                            })
                                                        ) : null}
                                                        {/* Linkedin */}
                                                        {/* <GeneralInformationModule icon={<LinkedInIcon fontSize="small" />}>
                                                                <ul     style={{listStyle: "none", margin: onlyMobile ? "0px":"0 0 0 16px",padding: 0}}
> */}
                                                        {/* added https://  */}
                                                        {/* <a href={"#"} target="_blank" style={{ color: blue }} rel="noopener noreferrer"> */}
                                                        {/* <li>
                                                                        <Typography noWrap>{"linkedin"}</Typography>
                                                                    </li> */}
                                                        {/* </a> */}
                                                        {/* </ul>
                                                            </GeneralInformationModule> */}
                                                        {/* WEBSITE */}
                                                        {contact.website ? (
                                                            <GeneralInformationModule icon={<LanguageIcon fontSize="small" />}>
                                                                <ul
                                                                    style={{
                                                                        listStyle: "none",
                                                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                                        padding: 0,
                                                                    }}
                                                                >
                                                                    {/* added https://  */}

                                                                    <a {...anchorNewTab} href={validateText(contact.website as string) || ""}>
                                                                        <li>
                                                                            <Typography noWrap>{contact.website}</Typography>
                                                                        </li>
                                                                    </a>
                                                                </ul>
                                                            </GeneralInformationModule>
                                                        ) : (
                                                            <GeneralInformationModule icon={<LanguageIcon fontSize="small" />}>
                                                                <ul
                                                                    style={{
                                                                        listStyle: "none",
                                                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                                                        padding: 0,
                                                                    }}
                                                                >
                                                                    {/* added https://  */}
                                                                    <a {...anchorNewTab} href={validateText(primary?.website as string) || ""}>
                                                                        <li>
                                                                            <Typography noWrap>{primary?.website}</Typography>
                                                                        </li>
                                                                    </a>
                                                                </ul>
                                                            </GeneralInformationModule>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* Check if more CA's are involved */}
                            {others.length > 0 && (
                                <React.Fragment>
                                    <SecondCA others={others} starredCAS={starredCAS} />
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                )}
            </WidgetBox>
            <CaExpandModal
                starredCAS={starredCAS}
                handleClose={() => {
                    setOpenModal(!openModal);
                }}
                open={openModal}
                clients={clients}
            />
        </React.Fragment>
    );
};

export default withTranslation("translation")(ContractingAuthorityBox);
