import React from "react";
import { SelectChangeEvent } from "@mui/material";

import { FormControl, MenuItem, Select, Typography, IconButton, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTour } from "../contextProviders/TourContext";

interface Props {
    values: Option[] | null;
    onSelect: (value: string) => void;
    selectedValue: string;
    dataTut?: string;
    /**
     * optional: typography variant
     */
    typoVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button";
    onRemove?: (value: string) => void;
    type: "tender" | "opportunity" | undefined;
    paddingTop?: string;
    openStep: boolean;
    setOpenStep?: (bool: boolean) => void;
}

interface Option {
    value: string;
    text: string;
}

const GeneralSelector: React.FC<Props> = ({
    values,
    selectedValue,
    onSelect,
    typoVariant,
    onRemove,
    type,
    dataTut = "empty",
    openStep,
    paddingTop = "2px",
}) => {
    const { setCurrentStep, setLockTour, currentTour } = useTour();

    const lightBlue = "#465c84";
    const lightGreen = "#508c79";
    const black = "#000000";

    /**
     * @param event selected searchterm
     * add selected searchterm to selection
     */
    const handleChange = (event: SelectChangeEvent) => {
        onSelect(event.target.value as string);
    };

    return (
        <span>
            {onRemove ? (
                <FormControl>
                    <Tooltip
                        placement="top-end"
                        componentsProps={{ tooltip: { sx: { backgroundColor: "transparent", marginBottom: "-18px", marginRight: "-24px" } } }}
                        title={
                            <>
                                <IconButton size="small" onClick={() => onRemove(selectedValue)}>
                                    <CancelIcon style={{ fontSize: "1rem" }} />
                                </IconButton>
                            </>
                        }
                    >
                        <span>
                            {/* Check if values = null to show select or show selectedvalue only */}
                            {values !== null ? (
                                <Select
                                    variant="standard"
                                    style={{ marginRight: "2px" }}
                                    disableUnderline
                                    value={selectedValue}
                                    onChange={handleChange}
                                    displayEmpty
                                    // show default value when no value is selected

                                    sx={{
                                        "& .MuiSelect-root": {
                                            fontWeight: typoVariant === "h2" ? undefined : 700,
                                            paddingTop: typoVariant === "h2" ? 0 : paddingTop,
                                            paddingBottom: 0,
                                            paddingRight: typoVariant === "h2" ? "0px !important" : "4px !important",
                                            paddingLeft: typoVariant === "h2" ? 0 : "4px",
                                            color: "#707070",
                                        },
                                        "& .MuiSelect-icon": { display: "none" },
                                        "& .MuiSelect-select": {
                                            fontWeight: typoVariant === "h2" ? undefined : 700,
                                            paddingTop: typoVariant === "h2" ? 0 : paddingTop,
                                            paddingBottom: 0,
                                            paddingRight: typoVariant === "h2" ? "0px !important" : "4px !important",
                                            paddingLeft: typoVariant === "h2" ? 0 : "4px",
                                            "&:focus": {
                                                background: "none",
                                            },
                                        },
                                    }}
                                    renderValue={(value: any) => {
                                        return (
                                            <React.Fragment>
                                                {
                                                    <Typography
                                                        sx={{
                                                            color: type === "tender" ? lightBlue : type === "opportunity" ? lightGreen : black,
                                                            fontWeight: 600,
                                                            width: "100%",
                                                        }}
                                                        variant={typoVariant ? typoVariant : "body1"}
                                                    >
                                                        {value}
                                                    </Typography>
                                                }
                                            </React.Fragment>
                                        );
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-list": {
                                                padding: 0,
                                            },
                                        },
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                    }}
                                >
                                    {values?.map((v, i) => (
                                        <MenuItem key={i} value={v.value}>
                                            <Typography>{v.text}</Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <Typography
                                    data-tut="selected-item-step"
                                    sx={{
                                        color: type === "tender" ? lightBlue : type === "opportunity" ? lightGreen : black,
                                        fontWeight: 600,
                                        width: "100%",
                                        marginRight: "4px",
                                    }}
                                    variant={typoVariant ? typoVariant : "body1"}
                                >
                                    {selectedValue}
                                </Typography>
                            )}
                        </span>
                    </Tooltip>
                </FormControl>
            ) : (
                <FormControl>
                    {/* Check if values = null to show select or show selectedvalue only */}

                    {values !== null ? (
                        <Select
                            onOpen={() => {
                                if (currentTour === "TenderWizardDistribution" && openStep)
                                    setTimeout(() => {
                                        setCurrentStep(4);
                                    }, 200);
                            }}
                            variant="standard"
                            data-tut={dataTut}
                            id={dataTut}
                            style={{ marginRight: "2px" }}
                            disableUnderline
                            value={selectedValue}
                            onChange={handleChange}
                            displayEmpty
                            // show default value when no value is selected
                            sx={{
                                "& .MuiSelect-root": {
                                    fontWeight: typoVariant === "h2" ? undefined : 700,
                                    paddingTop: typoVariant === "h2" ? 0 : paddingTop,
                                    paddingBottom: 0,
                                    paddingRight: typoVariant === "h2" ? "0px !important" : "4px !important",
                                    paddingLeft: typoVariant === "h2" ? 0 : "4px",
                                    color: "#707070",
                                },
                                "& .MuiSelect-icon": { display: "none" },
                                "& .MuiSelect-select": {
                                    fontWeight: typoVariant === "h2" ? undefined : 700,
                                    paddingTop: typoVariant === "h2" ? 0 : paddingTop,
                                    paddingBottom: 0,
                                    paddingRight: typoVariant === "h2" ? "0px !important" : "4px !important",
                                    paddingLeft: typoVariant === "h2" ? 0 : "4px",
                                    "&:focus": {
                                        background: "none",
                                    },
                                },
                            }}
                            renderValue={(value: any) => {
                                return (
                                    <React.Fragment>
                                        {
                                            <Typography
                                                sx={{
                                                    color: type === "tender" ? lightBlue : type === "opportunity" ? lightGreen : black,
                                                    fontWeight: 600,
                                                    width: "100%",
                                                }}
                                                variant={typoVariant ? typoVariant : "body1"}
                                            >
                                                {value}
                                            </Typography>
                                        }
                                    </React.Fragment>
                                );
                            }}
                            MenuProps={{
                                id: "menuListGeneralSelector",
                                sx: {
                                    "& .MuiMenu-list": {
                                        padding: 0,
                                    },
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                            }}
                        >
                            {values?.map((v, i) => (
                                <MenuItem
                                    key={i}
                                    value={v.value}
                                    data-tut={`${dataTut}list${i}`}
                                    onClick={() => {
                                        if (currentTour === "TenderWizardDistribution" && openStep)
                                            setTimeout(function () {
                                                setLockTour(false);
                                                setCurrentStep(5);
                                            }, 100);
                                    }}
                                >
                                    <Typography>{v.text}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Typography
                            sx={{
                                color: type === "tender" ? lightBlue : type === "opportunity" ? lightGreen : black,
                                fontWeight: 600,
                                width: "100%",
                                marginRight: "4px",
                            }}
                            variant={typoVariant ? typoVariant : "body1"}
                        >
                            {selectedValue}
                        </Typography>
                    )}
                </FormControl>
            )}
        </span>
    );
};

export default GeneralSelector;
