import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_ORGANIZATION_SEARCHES } from "../../../graphql/queryDefCurrentUser";
import { GetOrganizationSearchRules } from "../../../__generated__/GetOrganizationSearchRules";
import { Grid, List, ListItem, Typography } from "@mui/material";
import RenderPage from "../../../components/loading/RenderPage";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { useTranslation } from "react-i18next";
import SearchLineItem from "./SearchLineItem";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import SearchOwnerFilter from "./SearchOwnerFilter";

interface Props {
    openModal: (type: string, item: any) => void;
    admin: boolean;
}

const OrganizationSearches: React.FC<Props> = ({ openModal, admin }) => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const [uniqueOwners, setUniqueOwners] = useState<any[]>([]);
    const [chosenOwners, setChosenOwners] = useState<number[]>([]);

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Define the GQL query
     */
    const { data, loading, error, refetch } = useQuery<GetOrganizationSearchRules>(GET_ORGANIZATION_SEARCHES, {
        skip: !admin,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.getOrganizationSearches) {
                const searchlines = data.getOrganizationSearches as any;
                const uniqueOwnerIds = [...new Set(data.getOrganizationSearches.map((searchline: any) => searchline.owner?.id))];
                const uniqueOwners = uniqueOwnerIds.map((ownerId) => {
                    const ownerSearches = searchlines.filter((searchline: any) => searchline.owner?.id === ownerId);
                    return {
                        ...searchlines.find((searchline: any) => searchline.owner.id === ownerId).owner,
                        count: ownerSearches.length,
                    };
                });
                setUniqueOwners(uniqueOwners);
            }
        },
    });

    if (loading)
        /**
         * if loading -> show loading
         */
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );

    /**
     * if error or empty -> show error
     */
    if (error || !data)
        return (
            <List style={{ padding: 0 }}>
                <ListItem
                    sx={{
                        borderLeft: (theme) => `5px solid ${"#173357"}`,
                        backgroundColor: "#ffffff",
                        height: "75px",
                    }}
                >
                    <Typography variant="h6">{t("Error")}</Typography>
                </ListItem>
            </List>
        );

    // Filter the array based on the chosenOwner IDs
    const filteredSearchlines =
        chosenOwners.length > 0
            ? data.getOrganizationSearches.filter((searchline) => chosenOwners.includes(parseInt(searchline?.owner?.id as string)))
            : data.getOrganizationSearches;

    return (
        <React.Fragment>
            <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "8px" }}>
                <SearchOwnerFilter owners={uniqueOwners} chosenOwners={chosenOwners} setChosenOwners={setChosenOwners} />
            </Grid>
            <List style={{ padding: 0 }}>
                {filteredSearchlines
                    // .filter((search) => search?.state !== "PENDING_ACCEPTANCE")
                    .map((searchline) => {
                        if (searchline === null) {
                            return <></>;
                        }
                        return (
                            <SearchLineItem
                                showLock={false}
                                admin={admin}
                                chosenColors={[]}
                                refetch={refetch}
                                type={"tender"}
                                key={searchline.id}
                                searchline={searchline}
                                openEdit={() => {
                                    openModal("tender", searchline);
                                    trackevents({
                                        category: "Personal",
                                        action: "Click-edit-tender-searchrule-as-admin",
                                        name: searchline.name,
                                        value: parseInt(searchline.id),
                                    });
                                }}
                            />
                        );
                    })}
            </List>
        </React.Fragment>
    );
};

export default OrganizationSearches;
