import React, { useContext } from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton } from "@mui/material";
import { AccountTree } from "@mui/icons-material";
import TooltipOnNewLines from "../TooltipOnNewLines";
import { ProjectToOpenContext } from "../layout/MainLayout";
import { useQuery } from "@apollo/client";
import { SINGLE_PROJECT_NOTIFICATION } from "../../graphql/queryDefProject";
import { single_project_notification, single_project_notificationVariables } from "../../__generated__/single_project_notification";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(e: string): void;
}

const AddedToProjectNotification: React.FC<Props> = ({ notification, onClick }) => {
    const grey = "#707070";
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);

    const { data, loading } = useQuery<single_project_notification, single_project_notificationVariables>(SINGLE_PROJECT_NOTIFICATION, {
        variables: {
            id: notification.data ? (notification.data[0] as string) : "",
        },
        fetchPolicy: "network-only",
    });

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (loading) {
        return (
            <MenuItem
                style={{
                    whiteSpace: "normal",
                    maxHeight: 250,
                    opacity: notification.read_at !== null ? 0.5 : undefined,
                }}
            >
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    /**
     * if project is deleted hide notification
     */
    if (data === null || data?.project === null) {
        return <></>;
    }

    return (
        <MenuItem
            onClick={() => {
                if (notification.data) {
                    onClick(notification.data[0] as string);
                    changeProject({ projectID: notification.data[0] as string, projectTitle: data?.project?.title });
                    setOpen(true);
                }
            }}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: grey }}>
                    <AccountTree fontSize="small" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <TooltipOnNewLines
                        placement={"bottom-start"}
                        text={`Toegevoegd aan project: ${data?.project?.title}` || ""}
                        variant={"body1"}
                        lines={2}
                        id={`T${notification.id as string}first`}
                        fontweight={notification.read_at === null ? 500 : "normal"}
                        fontSize={14}
                    />
                }
                secondary={
                    <TooltipOnNewLines
                        placement={"top-start"}
                        text={"Bekijk project" || ""}
                        variant={"body1"}
                        lines={1}
                        id={`T${notification.id as string}second`}
                        fontweight={"normal"}
                        fontSize={12}
                    />
                }
            />
        </MenuItem>
    );
};

export default AddedToProjectNotification;
