import React from "react";

//pagination
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "react-i18next";

interface Props {
    userSnippets: number;
    currentPage: number;
    handleChangePage(c: number): void;
}

const LabelPagination: React.FC<Props> = ({ userSnippets, currentPage, handleChangePage }) => {
    const { t } = useTranslation();

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 4 }}>
            {currentPage === 1 ? (
                <div style={{ width: 20, height: 20, margin: 3 }} />
            ) : (
                <IconButton size="small" disabled={currentPage === 1} onClick={() => handleChangePage(currentPage - 1)}>
                    <NavigateBeforeIcon fontSize="small" />
                </IconButton>
            )}

            <div style={{ padding: "0 6px", textAlign: "center", fontSize: "0.7rem" }}>
                <div>
                    {currentPage} {t("of")} {userSnippets}
                </div>
            </div>
            {currentPage !== userSnippets ? (
                <IconButton size="small" disabled={currentPage === userSnippets} onClick={() => handleChangePage(currentPage + 1)}>
                    <NavigateNextIcon fontSize="small" />
                </IconButton>
            ) : (
                <div style={{ width: 20, height: 20, margin: 3 }} />
            )}
        </div>
    );
};

export default LabelPagination;
