import React from "react";
import { Dialog, Grid } from "@mui/material";
import { useQueryBuilder } from "./QueryBuilder/QueryBuilderContext";
import QueryBuilder from "./QueryBuilder/QueryBuilder";
import SearchTermList from "../SearchTerm/SearchTermList";

interface Props {
    open: boolean;
    onClose: () => void;
    themeColor: string;
}

const AdvancedQueryModal: React.FC<Props> = ({ open, onClose, themeColor }) => {
    const { addCondition } = useQueryBuilder();

    return (
        <Dialog scroll={"paper"} maxWidth={false} open={open} onClose={onClose} sx={{ "& .MuiDialog-paper": { height: "80vh" } }}>
            <Grid container sx={{ height: "100%", width: "80vw" }}>
                <Grid item xs={12} md={3} sx={{ height: "100%" }}>
                    <div style={{ borderRight: "1px solid #eee", height: "98%" }}>
                        <SearchTermList onSelect={(v) => addCondition(v)} hideTitle />
                    </div>
                </Grid>
                <Grid item xs={12} md={9}>
                    <QueryBuilder onClose={onClose} themeColor={themeColor} />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default AdvancedQueryModal;
