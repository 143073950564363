import React from "react";
import { Typography } from "@mui/material";

interface Props {
    a?: number;
}

const OpportunityContent: React.FC<Props> = (props) => {
    return (
        <React.Fragment>
            <Typography variant="body1" sx={{ margin: (theme) => (theme.breakpoints.down("sm") ? "16px" : "48px") }} />
        </React.Fragment>
    );
};

export default OpportunityContent;
