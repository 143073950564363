import React from "react";
import { Card, Grid, Typography } from "@mui/material";

import {
    GetTileWidgetData_filterTenders_results_data_bids_lot,
    GetTileWidgetData_filterTenders_results_data_bids_marketParty,
} from "../../../../__generated__/GetTileWidgetData";
import LogoBox from "./LogoBox";
import currencyFormatDE from "../../../../utils/currencyFormatter";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetStarredRelatedOrgs } from "../../../../__generated__/GetStarredRelatedOrgs";
import { QUERY_GETSTARREDORGS } from "../../../../graphql/queryDefCurrentUser";
import { Star } from "@mui/icons-material";

interface Props {
    bid: GetTileWidgetData_filterTenders_results_data_bids_marketParty;
    bids: {
        bid: number | null;
        lot: GetTileWidgetData_filterTenders_results_data_bids_lot | null;
    }[];
}

const BidCard: React.FC<Props> = ({ bid, bids }) => {
    const { data } = useQuery<GetStarredRelatedOrgs>(QUERY_GETSTARREDORGS);
    const starredORGS = data?.currentUser.market_parties;

    const sortedBids = [...bids].sort((a, b) => {
        if (a.lot?.number && b.lot?.number) {
            return parseInt(a.lot.number) - parseInt(b.lot.number);
        } else if (!a.lot?.number) {
            return 1;
        } else {
            return -1;
        }
    });

    const noBidLots = sortedBids.filter((bid) => !bid.bid && bid.lot).map((bid) => bid.lot?.number);

    return (
        <Card sx={{ height: "80px", width: "375px", display: "flex", alignItems: "center" }}>
            <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
                <Grid item alignItems={"center"} sx={{ position: "relative" }}>
                    <LogoBox
                        colorDefault
                        logo={bid.logo}
                        favicon={bid.favicon}
                        name={bid.name ?? ""}
                        elevation={0}
                        width="80px"
                        height="80px"
                        textColor="#E03660"
                    />
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            {/* market party name */}
                            <Typography gutterBottom variant="subtitle1" component="div" lineHeight={"normal"}>
                                <Link to={`/organizations/${bid.id}`}>{bid.name}</Link>

                                {/* Add a star to market party */}
                                {starredORGS?.find((s) => s.id === bid.id) && <Star style={{ color: `#E03660`, marginLeft: 4, fontSize: "1rem" }} />}
                            </Typography>

                            {/* Show currency when a bid is available */}
                            <div style={{ display: "flex", flex: "1", overflow: "auto", flexDirection: "column", maxHeight: "45px" }}>
                                <>
                                    {bids.map((bid, index) => {
                                        return (
                                            <Typography key={index} variant="body2" gutterBottom color="text.secondary">
                                                {bid.bid ? `€ ${currencyFormatDE(bid.bid)}` : ""}
                                                {bid.bid && bid.lot && <span> (Perceel {bid.lot.number})</span>}
                                            </Typography>
                                        );
                                    })}
                                    {noBidLots.length > 0 && (
                                        <Typography variant="body2" gutterBottom color="text.secondary">
                                            Perceel {noBidLots.join(", ")}
                                        </Typography>
                                    )}
                                </>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default BidCard;
