import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { GetAllDocsForOpportunities_filterOpportunityFiles_filters } from "../../__generated__/GetAllDocsForOpportunities";
import { FilterTendersOverviewMinimum_filterTenders_filters } from "../../__generated__/FilterTendersOverviewMinimum";

interface Props {
    open: boolean;
    onClose(): void;
    openWizard(): void;
    amount: number | undefined;
    wrongFilters: FilterTendersOverviewMinimum_filterTenders_filters[] | GetAllDocsForOpportunities_filterOpportunityFiles_filters[] | undefined;
}

const TenderSearchRuleWizard: React.FC<Props> = ({ onClose, open, amount, openWizard, wrongFilters }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={() => onClose()} aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">
                    {t("AmountOfFilters")}: {amount}
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {wrongFilters !== undefined && wrongFilters.length > 1 ? t("MultiFilterNotPossible") : t("SingleFilterNotPossible")}:
                    </Typography>
                    {wrongFilters?.map((filter, i) => (
                        <Typography key={i}>- {t(`filter.${filter.title}`)}</Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()} color="primary" variant="text">
                        {t("Cancel")}
                    </Button>
                    <Button
                        onClick={() => {
                            onClose();
                            openWizard();
                        }}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        {t("Continue")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default TenderSearchRuleWizard;
