import React from "react";
import PercentageBar from "./PercentageBar";
import { TendenZ_poll } from "../../../__generated__/TendenZ_poll";
import { useQuery } from "@apollo/client";
import { GET_SINGLE_POLL_TENDENZ } from "../../../graphql/tendenzDefinitions";
import { usePublicSocket } from "../../../utils/websocketChannels";

interface Props {
    userAnswerId: number | null;
    currentQuestionId: number;
    admin?: boolean;
}

const WaitingScreen: React.FC<Props> = ({ currentQuestionId, userAnswerId, admin = false }) => {
    const bgcolor = ["#E03660", "#173357", "#225E4D", "#F57117", "#707070"];

    const { data, refetch } = useQuery<TendenZ_poll>(GET_SINGLE_POLL_TENDENZ);

    /**
     * Whenever new notifications are published on the socket channel,
     * fetch notifications
     */
    usePublicSocket(`poll.1`, ".question.answered", (e) => {
        // Update usersAnswered with the event data
        if (e && e.usersAnswered !== undefined) {
            refetch();
        }
    });

    const current = data?.TendenzPoll?.questions?.find((q) => q?.id === currentQuestionId);
    const totalAnswers = (current?.users_answered as number) || 0;
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column" }}>
            <div style={{ padding: "0xp 16px", width: "100%" }}>
                {current?.answers?.map((answer, i) => {
                    const count = answer?.count || 0;
                    const percentage = totalAnswers > 0 ? Math.round((count / totalAnswers) * 1000) / 10 : 0; // Round to 1 decimal place
                    const isUserAnswer = userAnswerId === answer?.id;
                    return (
                        <PercentageBar
                            admin={admin}
                            key={answer?.id}
                            count={count}
                            percentage={percentage}
                            cuAnswer={isUserAnswer}
                            barColor={bgcolor[i]}
                            answerText={answer?.text?.toString()}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default WaitingScreen;
