import React from "react";

// MUI import
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import UnarchiveIcon from "@mui/icons-material/Unarchive";

// GQL
import { FetchResult, InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { UnArchiveTender, UnArchiveTenderVariables } from "../__generated__/UnArchiveTender";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { UNARCHIVETENDER } from "../graphql/mutationDefinitions";

interface Props {
    tender_id: string;
    buttonSize: "small" | "medium";
    onClick?: () => void;
    refetchQueries?: InternalRefetchQueriesInclude | ((result: FetchResult<UnArchiveTender>) => InternalRefetchQueriesInclude) | undefined;
}

const UnArchiveTenderButton: React.FC<Props> = ({ tender_id, buttonSize, onClick, refetchQueries }) => {
    const { t } = useTranslation();
    const [save, { loading: loadingMutation, error: errorMutation }] = useMutation<UnArchiveTender, UnArchiveTenderVariables>(UNARCHIVETENDER);
    const { pathname } = useLocation();
    const { trackEvent } = useMatomo();

    // Track event unarchive tender
    const trackUnarchive = () => {
        if (pathname === "/dashboard") trackEvent({ category: "Dashboard", action: "unarchive-tender" });
        if (pathname.includes("tender")) trackEvent({ category: "Tender", action: "unarchive-tender" });
        if (pathname.includes("contracting-authorities")) trackEvent({ category: "Ca", action: "unarchive-tender" });
        if (pathname.includes("organizations")) trackEvent({ category: "Organizations", action: "unarchive-tender" });
    };

    // Function to like(unarchive) a tender
    const UnArchiveTenders = async () => {
        if (errorMutation) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await save({
                variables: {
                    tenderID: tender_id,
                    reason: "",
                    remark: "",
                },
                refetchQueries: refetchQueries,
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        <React.Fragment>
            <Tooltip enterNextDelay={100} title={t("Unarchive") as string} placement="top">
                <IconButton
                    disabled={loadingMutation}
                    onClick={(e) => {
                        e.stopPropagation();
                        UnArchiveTenders();
                        onClick && onClick();
                        trackUnarchive();
                    }}
                    size={buttonSize}
                >
                    <UnarchiveIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

export default UnArchiveTenderButton;
