import React, { useRef, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";

interface Props {
    tooltip: string;
    text: string;
}

const OverflowTooltip = (props: Props) => {
    const textElementRef = useRef<HTMLInputElement | null>(null);

    const compareSize = () => {
        if (textElementRef.current) {
            const compare = textElementRef.current.scrollHeight > textElementRef.current.clientHeight;
            setHover(compare);
        }
    };

    useEffect(() => {
        compareSize();
        window.addEventListener("resize", compareSize);
    }, []);

    useEffect(
        () => () => {
            window.removeEventListener("resize", compareSize);
        },
        []
    );

    const [hoverStatus, setHover] = useState(false);

    return (
        <Tooltip
            componentsProps={{ tooltip: { sx: { marginBottom: "3px" } } }}
            title={props.tooltip}
            disableHoverListener={!hoverStatus}
            placement="top"
        >
            <div
                ref={textElementRef}
                style={{
                    color: "grey",
                    cursor: hoverStatus ? "default" : "text",
                    maxHeight: "3em",
                    lineHeight: "1.5em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    width: "fit-content",
                }}
            >
                {props.text}
            </div>
        </Tooltip>
    );
};

export default OverflowTooltip;
