import { createContext, useContext } from "react";

export type CuOrganizationContextType = {
    org_id: string;
    setOrgId: (org_id: string) => void;
};

export const CuOrganizationContext = createContext<CuOrganizationContextType>({
    org_id: "",
    setOrgId: (org_id) => console.warn("no org_id set"),
});
export const useCurrentUserOrganization = () => useContext(CuOrganizationContext);
