import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_SCOPEVALUES_OF_CURRENT_TENDER } from "../../../../graphql/queryDefinitions";
import { GetScopeValuesOfCurrentTender, GetScopeValuesOfCurrentTenderVariables } from "../../../../__generated__/GetScopeValuesOfCurrentTender";
import ScopeWidget from "../../../../components/ScopeModule/ScopeWidget";
import { Grid } from "@mui/material";

interface Props {
    tender_id: string;
}

const TenderScopeWidget: React.FC<Props> = ({ tender_id }) => {
    const { loading, error, data } = useQuery<GetScopeValuesOfCurrentTender, GetScopeValuesOfCurrentTenderVariables>(
        QUERY_SCOPEVALUES_OF_CURRENT_TENDER,
        {
            variables: { id: tender_id },
        }
    );

    if (loading) return null;

    if (error || !data || !data.tender) return null;

    const tender = data.tender;

    return tender.scope !== null ? (
        <Grid item xs={12} md={6}>
            <ScopeWidget scope={tender.scope} lots={tender.lots} tender_id={tender.id} elevation={0} />
        </Grid>
    ) : null;
};

export default TenderScopeWidget;
