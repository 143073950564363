import React, { useEffect } from "react";
import { Badge, useMediaQuery, useTheme } from "@mui/material";
import { GetApp, FindInPageOutlined } from "@mui/icons-material";
import { Tooltip, IconButton, Grid } from "@mui/material";
import InViewPaginationOpp from "../../../components/InViewPaginationOpp";
import { GetOppDetailPageGeneralInfo_opportunityFile_personalResults } from "../../../__generated__/GetOppDetailPageGeneralInfo";
import MarkAllPagesAsReadOpp from "../../../components/MarkAllPagesAsReadOpp";
import ChatButtonTopBar from "../../../components/ChatButtonTopBar";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import SearchRuleDataOpportunity from "../../../components/SearchRuleDataOpportunity";
import { gql, useQuery } from "@apollo/client";
import { GetOppStateForUser } from "../../../__generated__/GetOppStateForUser";

interface Props {
    opp_id: number;
    opp_name: string | null;
    download_name: string | null;
    searchRuleData: (GetOppDetailPageGeneralInfo_opportunityFile_personalResults | null)[] | null;
    download_link: string | null;
    setChosenWords: (value: React.SetStateAction<string[]>) => void;
    onOpenSearchRuleWizard: () => void;
}

const OppDetailTopBar: React.FC<Props> = ({
    opp_id,
    download_name,
    searchRuleData,
    opp_name,
    download_link,
    setChosenWords,
    onOpenSearchRuleWizard,
}) => {
    const { trackEvent } = useMatomo();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Create new array from searchrule query words
     *
     */
    useEffect(() => {
        const searchWords = searchRuleData?.map((d) => {
            return d?.search?.query?.split(" OR ");
        });
        setChosenWords(searchWords?.flat() as string[]);
    }, [searchRuleData, setChosenWords]);

    const { data } = useQuery<GetOppStateForUser>(QUERY_OPP_STATE_FOR_USER, {
        variables: { id: opp_id },
        fetchPolicy: "network-only",
    });

    return (
        <Grid item md={12} container justifyContent="space-between" alignItems="center">
            {/* 
                Show searchrule results in tooltip 
            */}
            <Grid item sm={4} xs={1} style={{ display: "flex", flexDirection: "row" }}>
                <SearchRuleDataOpportunity opp_id={opp_id.toString()} iconSize="small" placement={"right"} marginRight="8px" />
            </Grid>

            {/* 
                Pagination based on the current list context
                TODO: Connect to GQL
            */}
            <Grid item sm={4} xs={4} sx={{ display: onlyMobile ? "none" : "flex", justifyContent: onlyMobile ? undefined : "center" }}>
                <div className="flex centered v-center">
                    <InViewPaginationOpp currentPage={1} pageCount={7} listName="Werklijst" bgColor="#225E4D" />
                </div>
            </Grid>

            {/* 
                Opportunity actions, different based on list context
            */}
            <Grid item sm={4} xs={4}>
                <div className="flex right">
                    <MarkAllPagesAsReadOpp opp_id={opp_id} />
                    <Tooltip title="Maak zoekregel" placement="top">
                        <IconButton
                            onClick={() => {
                                onOpenSearchRuleWizard();
                                trackevents({ category: "Opportunity", action: "Click-create-searchrule-from-opportunity-detail" });
                            }}
                        >
                            <FindInPageOutlined fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {/*
                     *    Add/edit note of the tender
                     */}
                    <Badge
                        sx={{
                            ".MuiBadge-standard": {
                                maxWidth: "16px",
                                minWidth: "16px",
                                minHeight: "16px",
                                maxHeight: "16px",
                                fontSize: "0.6rem",
                                marginRight: "7px",
                                marginTop: "7px",
                            },
                        }}
                        badgeContent={data?.opportunityFile?.unreadChatsCount}
                        color="primary"
                        max={999}
                    >
                        <ChatButtonTopBar
                            id={opp_id.toString()}
                            name={opp_name || ""}
                            module={"App\\Models\\Opportunity\\File"}
                            notesAvailable={Boolean(data?.opportunityFile?.hasConversations)}
                            location="Opportunity"
                        />
                    </Badge>

                    {/*
                     *    Download document
                     */}
                    <Tooltip title="Download document" placement="top">
                        <IconButton
                            component="a"
                            target="_blank"
                            href={`${download_link}`}
                            download={download_name ? download_name : "document"}
                            onClick={() => trackevents({ category: "Opportunity", action: "Click-download-file-from-opportunity-detail" })}
                        >
                            <GetApp fontSize="small" />
                        </IconButton>
                    </Tooltip>

                    {/* 
                        TODO: Connect to GQL, create mutation etc.
                    */}
                    {/* <TenderNoteDialog open={noteModal} handleClose={() => setNoteModal(false)} /> */}
                </div>
            </Grid>
        </Grid>
    );
};

export default OppDetailTopBar;
export const QUERY_OPP_STATE_FOR_USER = gql`
    query GetOppStateForUser($id: ID!) {
        opportunityFile(id: $id) {
            hasConversations
            unreadChatsCount
        }
    }
`;
