import React, { useState, useEffect } from "react";
import ProfileLayout from "../ProfileLayout";
import TenderSettings from "./TenderSettings";
import AdSettings from "./AdSettings";
import OrganizationSettings from "./OrganizationSettings";
import { useTranslation } from "react-i18next";
import setTitle from "../../../utils/setTitle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import OpportunitySettings from "./OpportunitySettings";
import { Button, CircularProgress } from "@mui/material";
import {
    ContractingAuthoritiesModulePreferences,
    DefaultViewPreferenceInput,
    DistributionIntervalInput,
    GridInput,
    GridViewInput,
    MarketPartiesModulePreferences,
    NotificationEventInput,
    ReminderEventInput,
    RemindersPreferenceMomentInput,
    RemindersPreferencePeriodInput,
    TenderModulePreferences,
    // UserProfilePreferences,
} from "../../../__generated__/globalTypes";
import { useQuery, useMutation } from "@apollo/client";
import { GetUserSettings, GetUserSettings_currentUser_settings } from "../../../__generated__/GetUserSettings";
import { updateUserSettings, updateUserSettingsVariables } from "../../../__generated__/updateUserSettings";
import { toast } from "react-toastify";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { QUERY_USER_SETTINGS } from "../../../graphql/queryDefCurrentUser";
import { UPDATE_USER_PREFERENCES } from "../../../graphql/mutationDefinitions";
// import UserProfileSettings from "./UserProfileSettings";

/**
 * Find a setting
 * @param data all settings
 * @param settingName name of setting to find
 * @returns return the value of the setting
 */
export const getSettingValue = (data: (GetUserSettings_currentUser_settings | null)[], settingName: string) => {
    // Find the given settingname in all settings
    const setting = data.find((setting) => setting?.name === settingName);

    // Return undefined if no match is found
    if (!setting) return undefined;

    // Based on the __typename, return the appropriate value type
    switch (setting.__typename) {
        case "StringSetting":
            return setting.string;
        case "IntSetting":
            return setting.Int;
        case "BooleanSetting":
            return setting.Bool;
        case "ArraySetting":
            return setting.Arr;
        default:
            return undefined; // In case there is an unrecognized typename
    }
};

const Settings: React.FC = () => {
    const { t } = useTranslation();
    const [changed, setChanged] = useState<boolean>(false);

    const [defaultView, setDefaultView] = useState<DefaultViewPreferenceInput[]>([
        {
            grid: GridInput.TENDER_WORKLIST,
            view: GridViewInput.LIST,
        },
        {
            grid: GridInput.TENDER_REVIEWS,
            view: GridViewInput.LIST,
        },
        {
            grid: GridInput.STARRED_MARKET_PARTIES,
            view: GridViewInput.LIST,
        },
        {
            grid: GridInput.STARRED_CONTRACTING_AUTHORITIES,
            view: GridViewInput.LIST,
        },
        {
            grid: GridInput.OPPORTUNITY_WORKLIST,
            view: GridViewInput.LIST,
        },
        {
            grid: GridInput.OPPORTUNITY_REVIEWS,
            view: GridViewInput.LIST,
        },
    ]);
    const [tenderSettings, setTenderSettings] = useState<TenderModulePreferences>({
        notifications: [
            {
                email: false,
                emailInterval: DistributionIntervalInput.DAILY,
                event: NotificationEventInput.NEW_DOCUMENT,
                notification: true,
            },
            {
                email: false,
                emailInterval: DistributionIntervalInput.DAILY,
                event: NotificationEventInput.CHANGE,
                notification: true,
            },
            {
                email: false,
                emailInterval: DistributionIntervalInput.DAILY,
                event: NotificationEventInput.AWARD_INFORMATION,
                notification: true,
            },
        ],
        reminders: [
            {
                agenda: true,
                email: false,
                interval: 1,
                event: ReminderEventInput.DEADLINE,
                moment: RemindersPreferenceMomentInput.BEFORE,
                period: RemindersPreferencePeriodInput.DAYS,
                notification: true,
            },
            {
                agenda: true,
                email: false,
                interval: 3,
                event: ReminderEventInput.NEXT_ENDDATE_CONTRACT,
                moment: RemindersPreferenceMomentInput.BEFORE,
                period: RemindersPreferencePeriodInput.DAYS,
                notification: true,
            },
        ],
    });

    const [tendererSettings, setTendererSettings] = useState<MarketPartiesModulePreferences>({
        notifications: [
            {
                email: false,
                emailInterval: DistributionIntervalInput.DAILY,
                event: NotificationEventInput.AWARD_INFORMATION,
                notification: true,
            },
        ],
    });

    const [caSettings, setCaSettings] = useState<ContractingAuthoritiesModulePreferences>({
        notifications: [
            {
                email: false,
                emailInterval: DistributionIntervalInput.DAILY,
                event: NotificationEventInput.NEW_TENDER,
                notification: true,
            },
        ],
    });

    // const [userProfileSettings, setUserProfileSettings] = useState<UserProfilePreferences>({
    //     subsectors: [],
    // });

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Define the GQL query to fetch ical link
     */
    const { data } = useQuery<GetUserSettings>(QUERY_USER_SETTINGS, {
        fetchPolicy: "network-only", // Used for first execution
    });

    useEffect(() => {
        if (data && data.currentUser && data.currentUser.settings) {
            const allsettings = data.currentUser.settings;
            // Worklist setting (chosen view in preferences)
            const worklistView = getSettingValue(allsettings, "default_view_tender_worklist");
            // review tenders setting (chosen view in preferences)
            const reviewView = getSettingValue(allsettings, "default_view_tender_reviews");

            // worklist view
            localStorage.setItem(
                "twv",
                JSON.stringify(
                    worklistView === "SPLIT" ? 3 : worklistView === "UPDATE" ? 2 : worklistView === "LIST" ? 0 : worklistView === "COLUMN" ? 1 : 3
                )
            );
            // review tab view
            localStorage.setItem(
                "rtv",
                JSON.stringify(reviewView === "SPLIT" ? 3 : reviewView === "UPDATE" ? 2 : reviewView === "LIST" ? 0 : reviewView === "COLUMN" ? 1 : 0)
            );
        }
    }, [data]);

    /**
     * set initial data state fetched from settings query
     */
    useEffect(() => {
        if (data && data.currentUser && data.currentUser.settings) {
            const allsettings = data.currentUser.settings;

            setTenderSettings({
                notifications: [
                    // new document setting
                    {
                        email: getSettingValue(allsettings, "tender_new_document_email"),
                        emailInterval: getSettingValue(allsettings, "tender_new_document_email_interval") as DistributionIntervalInput,
                        event: NotificationEventInput.NEW_DOCUMENT,
                        notification: getSettingValue(allsettings, "tender_new_document_notification"),
                    },
                    // change information setting
                    {
                        email: getSettingValue(allsettings, "tender_change_email"),
                        emailInterval: getSettingValue(allsettings, "tender_change_email_interval") as DistributionIntervalInput,
                        event: NotificationEventInput.CHANGE,
                        notification: getSettingValue(allsettings, "tender_change_notification"),
                    },
                    // Award information setting
                    {
                        email: getSettingValue(allsettings, "tender_award_email"),
                        emailInterval: getSettingValue(allsettings, "tender_award_email_interval") as DistributionIntervalInput,
                        event: NotificationEventInput.AWARD_INFORMATION,
                        notification: getSettingValue(allsettings, "tender_award_notification"),
                    },
                ],
                // TODO: connect to default setting(s)
                reminders: [
                    // DEADLINE REMINDER
                    {
                        agenda: getSettingValue(allsettings, "tender_deadline_agenda"),
                        email: getSettingValue(allsettings, "tender_deadline_email"),
                        interval: getSettingValue(allsettings, "tender_deadline_email_interval"),
                        event: ReminderEventInput.DEADLINE,
                        moment: getSettingValue(allsettings, "tender_deadline_email_moment") as RemindersPreferenceMomentInput,
                        period: getSettingValue(allsettings, "tender_deadline_email_period") as RemindersPreferencePeriodInput,
                        notification: getSettingValue(allsettings, "tender_deadline_notification"),
                    },
                    // NEXT ENDDATE CONTRACT REMINDER
                    {
                        agenda: getSettingValue(allsettings, "tender_initial_enddate_contract_agenda"),
                        email: getSettingValue(allsettings, "tender_next_enddate_contract_email"),
                        interval: parseInt(getSettingValue(allsettings, "tender_next_enddate_contract_email_interval")),
                        event: ReminderEventInput.NEXT_ENDDATE_CONTRACT,
                        moment: getSettingValue(allsettings, "tender_next_enddate_contract_email_moment") as RemindersPreferenceMomentInput,
                        period: getSettingValue(allsettings, "tender_next_enddate_contract_email_period") as RemindersPreferencePeriodInput,
                        notification: getSettingValue(allsettings, "tender_next_enddate_contract_notification"),
                    },
                    // STARTDATE REMINDER
                    // {
                    //     agenda: getSettingValue(allsettings, "tender_startdate_contract_agenda"), // setting?.find((s) => s?.name === "tender_startdate_contract_agenda")?.value === "1" ? true : false,
                    //     email: false,
                    //     event: ReminderEventInput.STARTDATE_CONTRACT,
                    //     notification: false,
                    // },
                ],
            });
            setDefaultView([
                {
                    grid: GridInput.TENDER_WORKLIST,
                    view: getSettingValue(allsettings, "default_view_tender_worklist") as GridViewInput,
                },
                {
                    grid: GridInput.TENDER_REVIEWS,
                    view: getSettingValue(allsettings, "default_view_tender_reviews") as GridViewInput,
                },
                {
                    grid: GridInput.STARRED_MARKET_PARTIES,
                    view: getSettingValue(allsettings, "default_view_market_parties_reviews") as GridViewInput,
                },
                {
                    grid: GridInput.STARRED_CONTRACTING_AUTHORITIES,
                    view: getSettingValue(allsettings, "default_view_contracting_authorities_reviews") as GridViewInput,
                },
                {
                    grid: GridInput.OPPORTUNITY_WORKLIST,
                    view: getSettingValue(allsettings, "default_view_opportunities_worklist") as GridViewInput,
                },
                {
                    grid: GridInput.OPPORTUNITY_REVIEWS,
                    view: getSettingValue(allsettings, "default_view_opportunities_reviews") as GridViewInput,
                },
            ]);
            setTendererSettings({
                notifications: [
                    {
                        email: getSettingValue(allsettings, "market_party_award_email"),
                        emailInterval: getSettingValue(allsettings, "market_party_award_email_interval"),
                        event: NotificationEventInput.AWARD_INFORMATION,
                        notification: getSettingValue(allsettings, "market_party_award_notification"),
                    },
                ],
            });
            setCaSettings({
                notifications: [
                    {
                        email: getSettingValue(allsettings, "contracting_authority_new_tender_email"),
                        emailInterval: getSettingValue(allsettings, "contracting_authority_new_tender_email_interval") as DistributionIntervalInput,
                        event: NotificationEventInput.NEW_TENDER,
                        notification: getSettingValue(allsettings, "contracting_authority_new_tender_notification"),
                    },
                ],
            });

            // setUserProfileSettings({
            //     subsectors: JSON.parse(setting?.find((s) => s?.name === "user_profile_subsectors")?.value ?? "[]"),
            // });
        }
    }, [data]);

    /**
     * Define the mutation to save user preferences
     */
    const [savePreferences, { loading: loadingMutation }] = useMutation<updateUserSettings, updateUserSettingsVariables>(UPDATE_USER_PREFERENCES);

    /**
     * Handler to save preferences
     */
    const saveSettings = async () => {
        trackevents({ category: "Personal", action: "Click-save-preferences" });
        try {
            await savePreferences({
                variables: {
                    default_views: defaultView,
                    tenders: tenderSettings,
                    market_parties: tendererSettings,
                    contracting_authorities: caSettings,
                    // user_profile: userProfileSettings,
                },

                refetchQueries: [
                    QUERY_USER_SETTINGS, // DocumentNode object parsed with gql
                    "GetUserSettings", // Query name
                ],
                onCompleted: () => {
                    toast.success("Voorkeuren opgeslagen", { autoClose: 1500 });
                },
            });
            setChanged(false);
        } catch (e) {
            toast.error("Fout tijdens opslaan", { autoClose: 1500 });
        }
    };

    //set tab title
    useEffect(() => {
        setTitle(t("Preferences"));
    }, [t]);

    const [expanded, setExpanded] = useState<string | false>("panel2");

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <ProfileLayout
            title={<h3>{t("Preferences")}</h3>}
            componentToPassDown={
                <React.Fragment>
                    {changed && (
                        <Button color="grey" disabled={loadingMutation} onClick={() => saveSettings()} variant="contained">
                            {"opslaan"} {loadingMutation && <CircularProgress size={15} />}
                        </Button>
                    )}
                </React.Fragment>
            }
        >
            <div>
                {/* accordion tender settings */}
                <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h4" style={{ marginRight: 4 }}>
                                {t("tenders")}
                            </Typography>
                            {/* <InfoButtonTooltip tooltipText="Voorkeuren aanbestedingen" /> */}
                        </div>
                    </AccordionSummary>
                    {/*
                     *  Settingscomponent
                     */}
                    <TenderSettings
                        setChanged={setChanged}
                        settings={tenderSettings}
                        saveSettings={setTenderSettings}
                        defaultView={defaultView}
                        setDefaultView={setDefaultView}
                    />
                </Accordion>

                {/* accordion tenderer settings */}
                <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h4">{t("competitors")}</Typography>
                            {/* <InfoButtonTooltip tooltipText="Voorkeuren marktpartijen" /> */}
                        </div>
                    </AccordionSummary>
                    {/* Settingscomponent */}
                    <OrganizationSettings settings={tendererSettings} saveSettings={setTendererSettings} setChanged={setChanged} />
                </Accordion>

                {/* accordion AD settings */}
                <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h4">{t("contractingAuthorities")}</Typography>
                            {/* <InfoButtonTooltip tooltipText="Voorkeuren aanbestedende diensten" /> */}
                        </div>
                    </AccordionSummary>
                    {/* Settingscomponent */}
                    <AdSettings settings={caSettings} saveSettings={setCaSettings} setChanged={setChanged} />
                </Accordion>

                {/* accordion opportunity settings */}
                <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h4">{t("opportunities")}</Typography>
                            {/* <InfoButtonTooltip tooltipText="Voorkeuren kansen" /> */}
                        </div>
                    </AccordionSummary>
                    {/* Settingscomponent */}
                    <OpportunitySettings />
                </Accordion>

                {/* <Accordion expanded={expanded === "profile"} onChange={handleChange("profile")}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="profilebh-content" id="profilebh-header">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h4" style={{ marginRight: 4 }}>
                                {t("userprofile")}
                            </Typography>
                        </div>
                    </AccordionSummary>

                    <UserProfileSettings setChanged={setChanged} settings={userProfileSettings} saveSettings={setUserProfileSettings} />
                </Accordion> */}

                {/* accordion general settings */}
                {/* <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h4">{t("general")}</Typography>
                            <InfoButtonTooltip tooltipText="Algemene instellingen" />
                        </div>
                    </AccordionSummary>
                    <GeneralSettings />
                </Accordion> */}
            </div>
        </ProfileLayout>
    );
};

export default Settings;
