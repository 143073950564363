import React from "react";
import { presetColors } from "../../SearchRule/presetColors";
import { Check } from "@mui/icons-material";
import { getColors_currentUser_searches } from "../../../../__generated__/getColors";
import { Button } from "@mui/material";

interface Props {
    color: string;
    setColor: (str: string) => void;
    colors: getColors_currentUser_searches[] | undefined;
}

const Color: React.FC<Props> = ({ color, setColor, colors }) => {
    return (
        <div style={{ margin: "48px 0px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                {presetColors.map((presetColor) => (
                    <div key={presetColor.id} style={{ display: "flex", flexDirection: "column" }}>
                        {presetColor.colors.map((colorItem) => (
                            <Button
                                key={colorItem}
                                sx={{
                                    minWidth: "32px",
                                    height: "32px",
                                    margin: "4px",
                                    background: colorItem,
                                    borderRadius: color !== colorItem ? "none" : "25px",
                                    "&:hover": {
                                        opacity: color !== colorItem ? 0.6 : 1,
                                    },
                                }}
                                onClick={() => setColor(colorItem)}
                            >
                                {colors?.find((color) => color.color === colorItem) ? <Check sx={{ fontSize: "14px", color: "white" }} /> : <></>}
                            </Button>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Color;
