// WordModalGuide

import React, { useLayoutEffect } from "react";
import { Button, Typography, IconButton } from "@mui/material";
import { Assistant, Clear } from "@mui/icons-material";
import GuideContent from "../elements/GuideContent";
import { useTour } from "../../contextProviders/TourContext";
import { useFirstSearchRuleSetter } from "../../contextProviders/FirstSearchRuleContext";

interface Props {
    hasSearchrules: boolean;
    type: "tender" | "opportunity";
}

/**
 * @param step CurrentStep in tender searchwizard
 * @returns Returns current step as title and a button to open guide/tour
 */
const WordModalGuide: React.FC<Props> = ({ hasSearchrules, type }) => {
    const blue = "#173357";
    const green = "#225E4D";
    const lightblue = "#465c84";
    const lightgreen = "#508c79";

    const chosenColor = type === "opportunity" ? green : blue;
    const { wordModalTourOpen, setWordModalTourOpen } = useFirstSearchRuleSetter();
    // tour variables to set
    // locktour => true means item is interactive and no next/prev button will be shown
    const { setTourVariables, setOpenTour, setLockTour, setCurrentStep } = useTour();

    /**
     * SPLIT IN 2 COMPONENTS
     * BUTTON
     * TOUR TO START
     */
    const steps = () => {
        return [
            /**
             * Step 1: Add searchterm
             * Focus on textfield
             * User has to add a searchterm and click on '+' button
             */
            {
                selector: '[data-tut="reactour__tourbtn"]',
                content: () => {
                    return <GuideContent title="Interactieve assistent" content={<span>Een eerste zoekregel aanmaken</span>} />;
                },
            },

            /**
             * Step 2
             */
            {
                selector: '[data-tut="reactour__addWord"]',
                content: () => {
                    return (
                        <GuideContent
                            title="Zoekwoord toevoegen"
                            content={
                                <span>
                                    Typ een woord waarmee je wilt zoeken.
                                    <br />
                                    Klik{" "}
                                    <span
                                        style={{
                                            backgroundColor: chosenColor,
                                            color: "#ffffff",
                                            width: "33px",
                                            display: "inline-flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        +
                                    </span>{" "}
                                    om{" "}
                                    <span
                                        style={{
                                            fontWeight: 500,
                                            color: type === "opportunity" ? lightgreen : lightblue,
                                        }}
                                        id="searchtermToReplace"
                                    >
                                        zoekwoord
                                    </span>{" "}
                                    aan je lijst toe te voegen.
                                </span>
                            }
                        />
                    );
                },
                action: (node: any) => {
                    node.focus();
                    setLockTour(true);
                    const btn = document.getElementById("add_searchterm_btn");

                    /**
                     * find add searchterm button and setstep to 1 after 500ms
                     */
                    if (btn) {
                        btn.onclick = () => {
                            setTimeout(function () {
                                setLockTour(false);
                                setCurrentStep(2);
                            }, 500);
                        };
                    }
                },
                stepInteraction: true,
            },

            /**
             * Step 3
             */
            {
                selector: '[data-tut="reactour__showAddWord"]',
                content: () => {
                    return <GuideContent title="Woordenlijst" content={<span>Een overzicht van alle toegevoegde zoekwoorden.</span>} />;
                },
                stepInteraction: false,
            },

            /**
             * Step 4
             * Get searchterm from sessionstorage to use in dialog
             */
            {
                selector: '[data-tut="reactour__showAddWordRemovable"]',
                content: () => {
                    const firstWord = sessionStorage.getItem("lastSearchTerm")
                        ? JSON.parse(sessionStorage.getItem("lastSearchTerm") || "")
                        : "zoekwoord";
                    return (
                        <GuideContent
                            title="Zoekwoord kiezen"
                            content={
                                <span>
                                    Beweeg met de cursor op{" "}
                                    <span
                                        style={{
                                            fontWeight: 500,
                                            color: type === "opportunity" ? lightgreen : lightblue,
                                        }}
                                    >
                                        {firstWord}
                                    </span>{" "}
                                    en klik op{" "}
                                    <span>
                                        <Typography variant="button">voeg toe</Typography>
                                    </span>{" "}
                                    om toe te voegen aan deze zoekregel.
                                </span>
                            }
                        />
                    );
                },
                action: () => {
                    setLockTour(true);
                    const btn = document.querySelector('[data-tut="reactour__showAddWordRemovable"]') as HTMLElement;
                    const btnTwo = document.getElementById("addBtnID");

                    if (btn) {
                        btn.onclick = () => {
                            setTimeout(function () {
                                setLockTour(false);
                                setCurrentStep(4);
                            }, 100);
                        };
                    }
                    if (btnTwo) {
                        btnTwo.onclick = () => {
                            setTimeout(function () {
                                setLockTour(false);
                                // set currentstep to index 4
                                setCurrentStep(4);
                            }, 100);
                        };
                    }
                },
                stepInteraction: true,
            },

            /**
             * Step 5
             */
            {
                selector: '[data-tut="reactour__showChosenWord"]',
                content: () => {
                    const firstWord = sessionStorage.getItem("lastSearchTerm")
                        ? JSON.parse(sessionStorage.getItem("lastSearchTerm") || "")
                        : "zoekwoord";
                    return (
                        <GuideContent
                            title="Gekozen zoekwoord(en)"
                            content={
                                <span>
                                    <span
                                        style={{
                                            fontWeight: 500,
                                            color: type === "opportunity" ? lightgreen : lightblue,
                                        }}
                                    >
                                        {firstWord}
                                    </span>{" "}
                                    is nu toegevoegd aan deze zoekregel. Om{" "}
                                    <span
                                        style={{
                                            fontWeight: 500,
                                            color: type === "opportunity" ? lightgreen : lightblue,
                                        }}
                                    >
                                        {firstWord}
                                    </span>{" "}
                                    te verwijderen, klik
                                    <IconButton size="small" style={{ marginTop: -2 }}>
                                        <Clear style={{ fontSize: "1rem" }} />
                                    </IconButton>
                                </span>
                            }
                        />
                    );
                },
            },

            /**
             * Step 6
             */
            {
                selector: '[data-tut="reactour__nextBtn"]',
                stepInteraction: true,
                content: () => {
                    return (
                        <GuideContent
                            title="Volgende stap"
                            content={
                                <span>
                                    Klik{" "}
                                    <span
                                        style={{
                                            backgroundColor: chosenColor,
                                            color: "#ffffff",
                                            padding: "2px 8px",
                                            display: "inline-flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <Typography style={{ fontSize: "0.7rem" }} variant="button">
                                            volgende
                                        </Typography>
                                    </span>{" "}
                                    om door te gaan of sluit dit venster om meer woorden toe te voegen.
                                    <br />
                                    <br />
                                    <span>
                                        De knop{" "}
                                        <span
                                            style={{
                                                backgroundColor: chosenColor,
                                                color: "#ffffff",
                                                padding: "2px 8px",
                                                display: "inline-flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "4px",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <Typography style={{ fontSize: "0.7rem" }} variant="button">
                                                volgende
                                            </Typography>
                                        </span>{" "}
                                        wordt actief wanneer er minimaal 1 woord is gekozen.
                                    </span>
                                </span>
                            }
                        />
                    );
                },
            },
        ];
    };

    /**
     * Check if user has searchrules and domainModalTourOpen = false
     * true => open tour on default for current step
     */
    useLayoutEffect(() => {
        if (hasSearchrules && !wordModalTourOpen) {
            // timeout for smoother transition
            setTimeout(() => {
                setTourVariables(steps, chosenColor, `${type}WizardWord`);
                setOpenTour(true);
                setWordModalTourOpen(true);
            }, 450);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordModalTourOpen, hasSearchrules]);

    return (
        <Button
            data-tut="reactour__tourbtn"
            variant="text"
            size="small"
            onClick={(e) => {
                // Prevent the tab iself being clicked,
                // we want to execute the click on the close button
                e.stopPropagation();
                setTourVariables(steps, chosenColor, `${type}WizardWord`);
                setOpenTour(true);
            }}
            startIcon={<Assistant fontSize="small" />}
        >
            Interactieve assistent
        </Button>
    );
};

export default WordModalGuide;
