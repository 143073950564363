import React from "react";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { useQuery } from "@apollo/client";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton, Typography } from "@mui/material";
import { LocalOffer } from "@mui/icons-material";
import { GetSearchesOfCurrentUserNotification } from "../../__generated__/GetSearchesOfCurrentUserNotification";
import { GET_SEARCHES_OF_CURRENT_USER } from "../../graphql/queryDefNotifications";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(): void;
    keyIndex: number;
}

/**
 * Display a single notification row for the notification Dialog
 */
const FullSearchRuleRun: React.FC<Props> = ({ notification, onClick, keyIndex }) => {
    const blue = "#173357";

    const { loading, data } = useQuery<GetSearchesOfCurrentUserNotification>(GET_SEARCHES_OF_CURRENT_USER, {
        skip: notification === null || notification.data === null,
    });

    /**
     * Spinner on loading state
     */
    if (loading) {
        return (
            <MenuItem key={keyIndex} style={{ whiteSpace: "normal", maxHeight: 250, opacity: notification.read_at !== null ? 0.5 : undefined }}>
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }
    const getSearch = data?.currentUser.searches.find((search) => search.id === (notification.data && (notification.data[0] as string)));

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null || getSearch === undefined)
        return (
            <MenuItem
                key={keyIndex}
                onClick={() => onClick()}
                style={{
                    whiteSpace: "normal",
                    maxHeight: 250,
                    opacity: notification.read_at !== null ? 0.5 : undefined,
                    position: "relative",
                    padding: "8px 16px",
                }}
            >
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: blue }}>
                        <LocalOffer />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography
                            sx={{
                                maxHeight: "3em",
                                lineHeight: "1.5em",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                width: "fit-content",
                            }}
                            style={{
                                fontSize: 14,
                                fontWeight: notification.read_at === null ? 500 : "normal",
                            }}
                        >
                            Zoekregel succesvol uitgevoerd
                        </Typography>
                    }
                    secondary={
                        <Typography
                            style={{
                                fontSize: 12,
                                // When the nofitication is unread, the date-part of the notification is bold and accent colored

                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <LocalOffer
                                style={{
                                    width: "0.75em",
                                    height: "0.75em",
                                    color: "#e1e1e1",
                                    marginRight: 4,
                                }}
                            />
                            Zoekregel verwijderd
                        </Typography>
                    }
                />
            </MenuItem>
        );

    /**
     * get searchrulename + color to show in notification item
     */
    return (
        <MenuItem
            key={keyIndex}
            onClick={() => onClick()}
            style={{ whiteSpace: "normal", maxHeight: 250, opacity: notification.read_at !== null ? 0.5 : undefined, position: "relative" }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: blue }}>
                    <LocalOffer />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography
                        sx={{
                            maxHeight: "3em",
                            lineHeight: "1.5em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            width: "fit-content",
                        }}
                        style={{
                            fontSize: 14,
                            fontWeight: notification.read_at === null ? 500 : "normal",
                        }}
                    >
                        Zoekregel succesvol uitgevoerd
                    </Typography>
                }
                secondary={
                    // Show colored label and name of searchrule when available

                    <Typography
                        style={{
                            fontSize: 12,
                            // When the nofitication is unread, the date-part of the notification is bold and accent colored
                            color: notification?.read_at ? blue : undefined,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <LocalOffer
                            style={{
                                width: "0.75em",
                                height: "0.75em",
                                color: getSearch.color,
                                marginRight: 4,
                            }}
                        />{" "}
                        {getSearch.name}
                    </Typography>
                }
            />
        </MenuItem>
    );
};

export default FullSearchRuleRun;
