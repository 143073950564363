import React from "react";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
interface Props {
    onClose(): void;
    anchorEl: null | HTMLElement;
    onButtonClick(event: React.MouseEvent<HTMLButtonElement>): void;
}

const WidgetBoxMenu: React.FC<Props> = ({ children, onClose, anchorEl, onButtonClick }) => {
    return (
        <React.Fragment>
            <IconButton size="small" aria-controls="simple-menu" aria-haspopup="true" onClick={onButtonClick}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                onClose={onClose}
                id="widgetboxmenu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {children}
            </Menu>
        </React.Fragment>
    );
};

export default WidgetBoxMenu;
