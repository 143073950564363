import React, { useState, useEffect } from "react";

// MUI import
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import ThumbUp from "@mui/icons-material/ThumbUp";

// GQL
import { useMutation, useQuery } from "@apollo/client";
import { GetLikedTendersOfUser } from "../__generated__/GetLikedTendersOfUser";
import { likeTenderBatch, likeTenderBatchVariables } from "../__generated__/likeTenderBatch";
import {
    GET_DASHBOARD_WORKLIST,
    QUERY_TENDERLIKED,
    QUERY_TENDERS_INTERESTING_MINIMUM,
    QUERY_FULL_WORKLIST_MINIMUM,
} from "../graphql/queryDefinitions";
import { LIKETENDERBATCH } from "../graphql/mutationDefinitions";
import { QUERY_USER_LIKED_TENDERS } from "../graphql/queryDefCurrentUser";

interface Props {
    selection: any;
    buttonSize: "small" | "medium";
    iconSize: string;
    setSelection: any;
}

const LikeSelectedTenderButton: React.FC<Props> = ({ setSelection, selection: selectedArr, buttonSize, iconSize }) => {
    const { t } = useTranslation();
    const [isLoading, setIsloading] = useState<boolean>(false);

    /**
     * Get liked tenders
     */
    const { error: errorQuery, loading: loadQuery, data: dataQuery } = useQuery<GetLikedTendersOfUser>(QUERY_USER_LIKED_TENDERS);
    const [queryData, setQueryData] = useState(dataQuery);

    useEffect(() => {
        if (loadQuery === false && dataQuery) {
            setQueryData(dataQuery);
        }
    }, [loadQuery, dataQuery]);

    const [save, { loading: loadingMutation, error: errorMutation }] = useMutation<likeTenderBatch, likeTenderBatchVariables>(LIKETENDERBATCH);

    if (errorQuery || queryData?.currentUser === undefined || queryData?.currentUser === null) {
        return null;
    }
    const likedTenders = queryData.currentUser.likedTenders;

    /**
     * Like all selected CA's
     */
    const likeSelectedTenders = () => {
        const selection = selectedArr;
        setIsloading(true);
        const arr = selection.filter((i: string) => !likedTenders.some((item) => i === item.id));

        /**
         * if arr is empty, then all CA's are already starred.
         * if user clicks on button, then CA's star will be removed.
         * if arr is > 0 then CA's will be starred.
         */
        if (arr.length > 0) {
            arr.forEach(async (id: string) => {
                // Function to like organization
                if (loadingMutation) {
                }
                if (errorMutation) {
                    // toast.error("Fout tijdens opslaan (error)");
                }
                try {
                    await save({
                        variables: {
                            tenderID: id,
                            reason: "",
                            remark: "",
                        },
                        refetchQueries: [
                            QUERY_FULL_WORKLIST_MINIMUM,
                            "FilterWorklistMinimumOverview",
                            GET_DASHBOARD_WORKLIST,
                            "getWorklist",
                            QUERY_TENDERLIKED,
                            "GetTenderLikes",
                            QUERY_TENDERS_INTERESTING_MINIMUM,
                            "FilterInterestingMinimumTendersOverview",
                        ],
                        awaitRefetchQueries: true,
                    });
                    setSelection([]);
                } catch (e) {
                    // toast.error("Fout tijdens opslaan (catch)");
                } finally {
                    setSelection([]);
                    setIsloading(false);
                }
            });
            // onClose?.();
        }
        // else {
        //     onClose?.();
        // }
    };

    return (
        <React.Fragment>
            <Tooltip enterNextDelay={100} title={t("interesting") as string} placement="top">
                <IconButton
                    disabled={loadingMutation || isLoading}
                    onClick={(e) => {
                        e.stopPropagation();
                        likeSelectedTenders();
                    }}
                    size={buttonSize}
                >
                    <ThumbUp style={{ fontSize: iconSize }} />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

export default LikeSelectedTenderButton;
