import React from "react";
import { Grid, Typography } from "@mui/material";
import DocumentCard from "../../../../../components/DocumentCard";
import { useQuery } from "@apollo/client";
import FullPageSpinner from "../../../../../components/loading/FullPageSpinner";
import { GetCaPurchasingPolicies, GetCaPurchasingPoliciesVariables } from "../../../../../__generated__/GetCaPurchasingPolicies";
import { useTranslation } from "react-i18next";
import { QUERY_PURCHASINGPOLICIES } from "../../../../../graphql/queryDefCA";

interface Props {
    id: string;
}

// TODO: connect to GQL
// TODO: link doorlopen niet nowrap
// TODO: centreer div met icon en titel

export interface DocumentItem {
    id: string;
    url: string;
    type: string;
}
const PurchasingPolicyLinks: React.FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const { loading, error, data } = useQuery<GetCaPurchasingPolicies, GetCaPurchasingPoliciesVariables>(QUERY_PURCHASINGPOLICIES, {
        variables: { id },
    });

    if (loading) {
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    // Errormessage. TODO: fancy error page
    if (error || !data?.contracting_authority) {
        return (
            <RenderPage>
                <p>Error</p>
            </RenderPage>
        );
    }
    /**
     * filter urls, get PurchasingPolicy only
     * policyLinks = 0 ? dont show component
     */
    const policyLinks = data.contracting_authority.urls.filter((u) => u.type === "PURCHASINGPOLICY");

    return (
        <React.Fragment>
            {/* Left column */}
            {policyLinks.length > 0 && (
                <Grid item xs={12} md={12} style={{ width: "80vw", marginBottom: 24 }}>
                    {/* Title for doucments on this page */}
                    <Typography variant="h4" style={{ marginBottom: 8 }}>
                        {t("Documents")}
                    </Typography>

                    {/* 
                    div contains document cards 
                    foreach document a single card
                */}
                    <div style={{ display: "flex", width: "100%", overflow: "auto" }}>
                        {policyLinks.map((doc) => {
                            return (
                                <div
                                    key={doc.id}
                                    onClick={() => {
                                        // onClick open url in new tab
                                        window.open(doc.url, "_blank");
                                    }}
                                >
                                    <DocumentCard title={doc.url} />
                                </div>
                            );
                        })}
                    </div>
                </Grid>
            )}
        </React.Fragment>
    );
};

const RenderPage: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <div className="responsive-container topbottom" style={{ height: "100%" }}>
        {children}
    </div>
);

export default PurchasingPolicyLinks;
