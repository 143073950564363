import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { highlightedSummary, highlightedSummaryVariables } from "../../../__generated__/highlightedSummary";
import { DefaultSummary } from "../../tenders/TenderDetailContainer";
import SummaryWidget from "../../../components/tenderDetail/SummaryWidget";
import { GetDefaultSummary_tender } from "../../../__generated__/GetDefaultSummary";
import { GET_HIGHLIGHTED_SUMMARY } from "../../../graphql/queryDefSearchAndHighlighting";

interface Props {
    tender_id: string;
    data: GetDefaultSummary_tender;
    defaultSummary: DefaultSummary;
}

const ProjectSummaryWidgetContainer: React.FC<Props> = ({ tender_id, data, defaultSummary }) => {
    const [highlighting, setHighlighting] = useState<DefaultSummary>();

    /**
     * Highlighted summary query
     */
    useQuery<highlightedSummary, highlightedSummaryVariables>(GET_HIGHLIGHTED_SUMMARY, {
        variables: {
            id: tender_id,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.highlightedTender) {
                setHighlighting({
                    id: tender_id,
                    titel: data.highlightedTender.namegiventocontract || null,
                    omschrijving: data.highlightedTender.omschrijving || null,
                    omvang: data.highlightedTender.omvang || null,
                });
            }
        },
    });
    return (
        <React.Fragment>
            <SummaryWidget
                elevation={0}
                id={tender_id}
                omschrijving_source={data.omschrijving_source}
                omvang_opdracht_source={data.omvang_opdracht_source}
                description={
                    highlighting !== undefined && highlighting.omschrijving !== null
                        ? highlighting.omschrijving
                        : defaultSummary.omschrijving !== null
                        ? defaultSummary.omschrijving
                        : ""
                }
                qualitativeScope={
                    highlighting !== undefined && highlighting.omvang !== null
                        ? highlighting.omvang
                        : defaultSummary.omvang !== null
                        ? defaultSummary.omvang
                        : ""
                }
                subSectors={data.sectors}
                host={data.published_host}
                hostUrl={data.original_link}
                deadline={data.deadline_inschrijving}
                lastUpdate={data.updated_at}
                firstDispatch={data.first_dispatchdate || ""}
                awardCrit={data.gunningscriteria}
                procedureType={data.type_procedure}
                legalFramework={data.published_juridisch_kader}
                legalBase={data.published_legal_basis}
                type={data.translated_type}
                tender_country={data.country?.name ? data.country.name : null}
                lots={data.lots}
            />
        </React.Fragment>
    );
};

export default ProjectSummaryWidgetContainer;
