import { Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

interface Props {
    bgColor: string;
    title: string;
    marginLeft: number;
    date: moment.Moment;
}

const DateIndicator: React.FC<Props> = ({ bgColor, title, marginLeft, date }) => {
    return (
        <div
            style={{
                alignSelf: "center",
                marginLeft: `${marginLeft}%`,
                position: "absolute",
                marginTop: "30px",
            }}
        >
            <div style={{ height: "46px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Tooltip
                    disableInteractive
                    title={
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="caption" fontWeight={500}>
                                {title}
                            </Typography>
                            <Typography variant="caption" fontWeight={500}>
                                {moment(date).format("DD-MM-YYYY")}
                            </Typography>
                        </div>
                    }
                    placement="top"
                >
                    <div
                        style={{
                            cursor: "pointer",
                            margin: "1px 0px",
                            minHeight: "14px",
                            maxHeight: "14px",
                            minWidth: "4px",
                            maxWidth: "4px",
                            backgroundColor: bgColor,
                            borderRadius: "2px",
                        }}
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export default DateIndicator;
