import React from "react";

interface Props {
    noHeight?: boolean;
    color?: string;
}

const WobbleLine: React.FC<Props> = ({ noHeight = false, color = "#ffffff14" }) => {
    return (
        <div
            style={{
                position: "absolute",
                bottom: "0px",
                height: noHeight ? undefined : "400px",
                width: "100%",
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                    fill={color}
                    fillOpacity="1"
                    d="M0,256L30,229.3C60,203,120,149,180,128C240,107,300,117,360,149.3C420,181,480,235,540,261.3C600,288,660,288,720,277.3C780,267,840,245,900,213.3C960,181,1020,139,1080,133.3C1140,128,1200,160,1260,144C1320,128,1380,64,1410,32L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
                />
            </svg>
        </div>
    );
};

export default WobbleLine;
