import React, { useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, Checkbox, Divider, FormControlLabel, Popover, Theme, Tooltip, Typography } from "@mui/material";
import UserAndTeamsSection from "./UserAndTeamsSection";
import ProjectTitle from "./ProjectTitle";
import { useMutation, useQuery } from "@apollo/client";
import { GetCurrentUserNameToShow } from "../../../__generated__/GetCurrentUserNameToShow";
import { createNewProject, createNewProjectVariables } from "../../../__generated__/createNewProject";
import { CREATE_NEW_PROJECT } from "../../../graphql/mutationDefProjects";
import { ALL_PROJECTS, PROJECT_STATES } from "../../../graphql/queryDefProjects";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import { toast } from "react-toastify";
import { withStyles } from "tss-react/mui";
import { QUERY_TENDER_PROJECTS_FOR_USER } from "../../../components/tenderDetail/OpenOrCreateProject";
import { QUERY_CURRENT_USER_NAME } from "../../../graphql/queryDefCurrentUser";

interface Props {
    openForNewProject: boolean;
    btn: React.ReactNode;
    createnew: (bln: boolean) => void;
    anchor: null | HTMLElement;
    setAnchor: (anchor: null | HTMLElement) => void;
}

const StyledAvatar = withStyles(Avatar, (theme: Theme) => ({
    root: {
        width: "32px",
        height: "32px",
        fontSize: "0.8rem",
        // backgroundColor: "#454545",
        marginTop: "8px",
        border: "2px solid #fff",
        marginLeft: "16px",
        zIndex: 99,
    },
}));

const CreateProjectFromScratch: React.FC<Props> = ({ openForNewProject, btn, anchor, setAnchor, createnew }) => {
    const [saveProject] = useMutation<createNewProject, createNewProjectVariables>(CREATE_NEW_PROJECT);
    const { setOpen, project, changeProject } = useContext(ProjectToOpenContext);
    const [openPopper, setOpenPopper] = useState<boolean>(false);
    const [openProjectAfterShare, setOpenProjectAfterShare] = useState<boolean>(true);
    const [staticLoad, setStaticLoad] = useState<boolean>(false);

    const { data } = useQuery<GetCurrentUserNameToShow>(QUERY_CURRENT_USER_NAME);

    // Check if user opens a new project -> reset localstate to new project
    useEffect(() => {
        if (openForNewProject) {
            setOpenPopper(true);
            changeProject({
                projectID: `newProject`,
                projectTitle: "",
                state: "",
                tasks: [],
                tender_ids: [],
                users: [],
                ca_ids: [],
                notes: [],
                widgets: {
                    summary: true,
                    planning: true,
                    scope: true,
                    documents: true,
                    contract: true,
                    labels: true,
                },
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openForNewProject]);

    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchor(null);
        setOpenPopper(false);
        createnew(false);
    };

    /**
     * Check if selection is a single word without special characters
     * If so, add it to the searchterms
     */

    const createNewProject = async () => {
        try {
            await saveProject({
                variables: {
                    title: project.projectTitle,
                    tender_ids: project.tender_ids,
                    user_ids: project.users.map((i) => i.id).concat(localStorage.getItem("user_id") || ""),
                    contractingAuthority_ids: project.ca_ids,
                    state: project.state,
                    widgets: {
                        summary: true,
                        planning: true,
                        scope: true,
                        documents: true,
                        contract: true,
                        labels: true,
                    },
                },
                refetchQueries: [
                    ALL_PROJECTS, // DocumentNode object parsed with gql
                    "GetAllProjects", // Query name
                    PROJECT_STATES,
                    "projectStates",
                    QUERY_TENDER_PROJECTS_FOR_USER,
                    "GetTenderProjectsForUser",
                ],
                awaitRefetchQueries: true,
                onCompleted: (e) => {
                    changeProject({ projectID: e.createProject?.id });
                    toast.success(`Project aangemaakt`);
                    setOpenPopper(false);
                    setAnchor(null);
                    createnew(false);
                    setStaticLoad(false);

                    if (openProjectAfterShare === true) {
                        setTimeout(() => {
                            setOpen(true);
                        }, 400);
                    }
                },
            });
        } catch (e) {}
    };

    return (
        <React.Fragment>
            <Tooltip title="Maak project" enterNextDelay={100} placement="top">
                <>{btn}</>
            </Tooltip>
            <Popover
                anchorEl={anchor}
                open={openPopper}
                onClose={handleCloseSub}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                /**
                 * Width and height for chat/knowledge base
                 */
                slotProps={{
                    paper: {
                        sx: {
                            width: "600px",
                        },
                    },
                }}
            >
                <div style={{ padding: "16px 16px 0px 16px", maxHeight: "80vh", overflowY: "auto", overflowX: "hidden" }}>
                    <div style={{ marginBottom: "16px" }}>
                        <ProjectTitle />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            Gebruikers toevoegen
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {/* local user added by default */}
                            <StyledAvatar>
                                <Typography>
                                    {data?.currentUser.employee.name?.slice(0, 1)}
                                    {data?.currentUser.employee.familyname?.slice(0, 1)}
                                </Typography>
                            </StyledAvatar>

                            <div style={{ marginLeft: "-24px" }}>
                                <UserAndTeamsSection />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Buttons to cancel or share */}
                <div style={{ margin: "0px 16px" }}>
                    <Divider />
                </div>
                <Box
                    style={{
                        marginTop: 0,
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <Box style={{ display: "flex", justifyContent: "flex-end", marginRight: 8 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={openProjectAfterShare}
                                        onChange={() => setOpenProjectAfterShare(!openProjectAfterShare)}
                                    />
                                }
                                label="Open project"
                                labelPlacement="end"
                            />
                        </Box>
                    </div>
                    <div>
                        <Button
                            variant="text"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCloseSub(e);
                            }}
                        >
                            Annuleren
                        </Button>
                        <Button
                            disabled={staticLoad || project.state === ""}
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                createNewProject();
                                setStaticLoad(true);
                            }}
                        >
                            Maak project
                        </Button>
                    </div>
                </Box>
            </Popover>
        </React.Fragment>
    );
};

export default CreateProjectFromScratch;
