import { Card, CardActionArea, CardContent, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { usePoll } from "./PollContext";
import { Disable } from "react-disable";

interface Props {
    answer: string;
    index: number;
    onClickAnswer: () => void;
}

const AnswerCard: React.FC<Props> = ({ answer, index, onClickAnswer }) => {
    const bgcolor = ["#E03660", "#173357", "#225E4D", "#F57117", "#707070"];
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const { loadingMutation } = usePoll();

    return (
        <Disable disabled={loadingMutation}>
            <Card onClick={onClickAnswer} sx={{ backgroundColor: bgcolor[index], height: noMobile ? "185px" : "100%", display: "flex" }}>
                <CardActionArea>
                    <CardContent sx={{ padding: noMobile ? " 64px" : "16px" }}>
                        <Typography variant="h3" align="center" component="div" sx={{ color: "#ffffff" }}>
                            {answer}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Disable>
    );
};

export default AnswerCard;
